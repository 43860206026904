import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';
export default styled(Box)`
    height: ${({ height }) => height || '100%'};
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;

    ${scroll.custom()};
`;
