import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import selectParent from './selectParent';

export default (item, target) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Add currency'),
        messages: [
            i18next.t(
                'Are you sure you want to add {{name}} {{abbreviation}} as your second currency? Once added you will not be able to disable it',
                { name: item.name, abbreviation: item.abbreviation },
            ),
        ],
        onCancel: () => {
            target.checked = false;
        },
        onSuccess: () => {
            dispatch(selectParent(item.id));
        },
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
