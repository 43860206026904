import { object, string, number } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const {
        REQUIRED_TEXT,
        NUMBER_TYPE_TEXT,
        MIN_ZERO_VALIDATION_TEXT,
        MAX_100_VALIDATION_TEXT,
    } = getValidationTexts();

    return object().shape({
        name: string().required(REQUIRED_TEXT).nullable(),
        parameters_rate: object().shape({
            value: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, MIN_ZERO_VALIDATION_TEXT)
                .max(100, MAX_100_VALIDATION_TEXT)
                .nullable(),
        }),
        parameters: object().shape({
            tax: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, MIN_ZERO_VALIDATION_TEXT)
                .max(100, MAX_100_VALIDATION_TEXT)
                .nullable(),
        }),
        parameters_commission_credit: object().shape({
            unit: number().typeError(NUMBER_TYPE_TEXT).nullable(),
            value: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .when('unit', {
                    is: 0,
                    then: () =>
                        number()
                            .typeError(NUMBER_TYPE_TEXT)
                            .required(REQUIRED_TEXT)
                            .min(0, MIN_ZERO_VALIDATION_TEXT)
                            .max(100, MAX_100_VALIDATION_TEXT),
                })
                .nullable(),
        }),
        parameters_hitch: object().shape({
            unit: number().typeError(NUMBER_TYPE_TEXT).nullable(),
            value: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .when('unit', {
                    is: 0,
                    then: () =>
                        number()
                            .typeError(NUMBER_TYPE_TEXT)
                            .required(REQUIRED_TEXT)
                            .min(0, MIN_ZERO_VALIDATION_TEXT)
                            .max(100, MAX_100_VALIDATION_TEXT),
                })
                .nullable(),
        }),
        parameters_residual: object().shape({
            value: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, MIN_ZERO_VALIDATION_TEXT)
                .max(100, MAX_100_VALIDATION_TEXT)
                .nullable(),
        }),
    });
};
