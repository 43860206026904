import i18next from 'i18next';

export const getCountriesToSelect = (countries, isFetching) => {
    const items =
        countries.length > 0
            ? countries.map((item) => ({
                  label: item.name,
                  value: item.id,
              }))
            : [];

    return [
        {
            label: isFetching
                ? i18next.t('Loading country', { count: 2 }).concat('...')
                : i18next.t('Select your country'),
            value: '',
        },
        ...items,
    ];
};

export const getCountryFromAddress = (items, address) => {
    const country = items.find(
        (item) => item.name == address.formatted_address,
    );
    return country ? country : { id: null };
};
