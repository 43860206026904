import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Grid } from 'sunwise-ui';

const Wrapper = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 250px;
    justify-content: center;

    p {
        font-weight: bold;
        font-size: 12px;
    }
`;

const EmptyMessage = ({ children, text }) => (
    <Grid container>
        <Grid item xs>
            <Wrapper>
                <p>{text}</p>
                {children}
            </Wrapper>
        </Grid>
    </Grid>
);

EmptyMessage.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    text: PropTypes.string,
};

export default EmptyMessage;
