import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        name: string().required(REQUIRED_TEXT).nullable(),
        branch_office: string().when('is_multibranch', {
            is: true,
            then: () => string().required(REQUIRED_TEXT).nullable(),
        }),
        rate_division: string().when('without_consumptions', {
            is: true,
            then: () => string().required(REQUIRED_TEXT).nullable(),
        }),
        political_division1: string().when('without_consumptions', {
            is: true,
            then: () => string().required(REQUIRED_TEXT).nullable(),
        }),
        political_division2: string().when('without_consumptions', {
            is: true,
            then: () => string().required(REQUIRED_TEXT).nullable(),
        }),
    });
};
