import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as energyBackupSetupSelectors from '../energyBackupSetup/selectors';
import setSolarSimulationData from '../proposalGeneratorOneColumn/actions/setSolarSimulationData';
import * as proposalGeneratorOneColumnSelectors from '../proposalGeneratorOneColumn/selectors';
import * as timeShiftingConfigurationSelectors from '../timeShiftingConfiguration/selectors';

import * as actions from './actions';
import Card from './components/Card';
import * as selectors from './selectors';

const Container = ({
    consumptionProjection,
    handleShowConsumptionProfileAlert,
    initialize,
    isDisabled,
    isFetchingConsumptionAndGeneration,
    isFetchingOfferDetails,
    isFetchingOfferProfiles,
    isLoading,
    isSaving,
    offerDetailsPattern,
    offerProfilesData,
    offerStorageData,
    proposalConfiguration,
    proposalId,
    resetForm,
    save,
    setSolarSimulationData,
    solarSimulationData,
    timeShiftingConfiguration,
}) => {
    useEffect(() => () => resetForm(), []);

    useEffect(() => {
        if (
            !isFetchingOfferDetails &&
            !isFetchingOfferProfiles &&
            !offerProfilesData?.consumption?.length
        )
            handleShowConsumptionProfileAlert(offerDetailsPattern);
    }, [isFetchingOfferDetails, isFetchingOfferProfiles, offerProfilesData]);

    useEffect(() => {
        if (
            consumptionProjection?.dataTimeShifting &&
            timeShiftingConfiguration &&
            proposalConfiguration?.previous_rate_object
        ) {
            setSolarSimulationData({
                ...consumptionProjection,
                ...timeShiftingConfiguration,
            });
            initialize({
                ...consumptionProjection.dataTimeShifting,
                rate: proposalConfiguration?.previous_rate_object,
                timeShiftingConfiguration,
            });
        }
    }, [
        consumptionProjection,
        proposalConfiguration,
        timeShiftingConfiguration,
    ]);

    const onSubmit = (values) => save(proposalId, values, offerStorageData);

    return (
        <Card
            consumptionProjection={consumptionProjection}
            isDisabled={isDisabled || isLoading || isSaving}
            isLoading={
                isLoading ||
                isSaving ||
                isFetchingOfferDetails ||
                isFetchingOfferProfiles ||
                isFetchingConsumptionAndGeneration
            }
            offerStorageData={offerStorageData}
            onSubmit={onSubmit}
            proposalConfiguration={proposalConfiguration}
            proposalId={proposalId}
            solarSimulationData={solarSimulationData}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    consumptionProjection:
        proposalGeneratorOneColumnSelectors.getConsumptionProjectionData,
    isFetchingConsumptionAndGeneration:
        proposalGeneratorOneColumnSelectors.getIsFetchingConsumptionAndGeneration,
    isFetchingOfferDetails:
        proposalGeneratorOneColumnSelectors.getIsFetchingOfferDetails,
    isFetchingOfferProfiles:
        proposalGeneratorOneColumnSelectors.getOfferProfilesIsFetching,
    isSaving: selectors.getIsSaving,
    offerDetailsPattern:
        proposalGeneratorOneColumnSelectors.getOfferDetailsPattern,
    offerProfilesData: proposalGeneratorOneColumnSelectors.getOfferProfilesData,
    offerStorageData: energyBackupSetupSelectors.getOfferValuesData,
    proposalConfiguration:
        proposalGeneratorOneColumnSelectors.getProposalConfiguration,
    solarSimulationData:
        proposalGeneratorOneColumnSelectors.getSolarSimulationData,
    timeShiftingConfiguration:
        timeShiftingConfigurationSelectors.getFetchTimeShiftingConfigurationData,
});

const mapDispatchToProps = (dispatch) => ({
    handleShowConsumptionProfileAlert: (pattern) =>
        dispatch(actions.handleShowConsumptionProfileAlert(pattern)),
    initialize: (dataOfferTimeShifting) =>
        dispatch(actions.initialValues(dataOfferTimeShifting)),
    resetForm: () => dispatch(actions.resetForm()),
    save: (proposalId, values, offerStorageData) =>
        dispatch(actions.save(proposalId, values, offerStorageData)),
    setSolarSimulationData: (data) => dispatch(setSolarSimulationData(data)),
});

Container.propTypes = {
    consumptionProjection: PropTypes.object,
    handleShowConsumptionProfileAlert: PropTypes.func,
    initialize: PropTypes.func,
    isDisabled: PropTypes.bool,
    isFetchingConsumptionAndGeneration: PropTypes.bool,
    isFetchingOfferDetails: PropTypes.bool,
    isFetchingOfferProfiles: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSaving: PropTypes.bool,
    offerDetailsPattern: PropTypes.object,
    offerProfilesData: PropTypes.object,
    offerStorageData: PropTypes.object,
    proposalConfiguration: PropTypes.object,
    proposalId: PropTypes.string,
    resetForm: PropTypes.func,
    save: PropTypes.func,
    setSolarSimulationData: PropTypes.func,
    solarSimulationData: PropTypes.object,
    timeShiftingConfiguration: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
