import { NAME } from './constants';

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SET_CURRENT_VALUES = `${NAME}/SET_CURRENT_VALUES`;
