import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import unassignPowerStation from './unassignPowerStation';

export default (id, sunwiseProject) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [
            i18next.t('Are you sure you want to delete this power station?'),
        ],
        onSuccess: () => dispatch(unassignPowerStation(id, sunwiseProject)),
        title: i18next.t('Delete power station'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
