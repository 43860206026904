import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

export default styled(Box)`
    height: calc(100% - 42px);
    overflow: hidden;
    scrollbar-gutter: stable;

    ${scroll.custom()}

    .scroll-loader {
        color: #b6b9ba;
        font-weight: 600;
        font-size: 14px;
    }

    &:hover {
        overflow-y: auto;
        overflow-y: overlay;
    }

    & > div {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    @media (max-width: 576px) {
        overflow-y: auto;
        overflow-y: overlay;
    }
`;
