import React from 'react';

import PropTypes from 'prop-types';

import EnergyField from './EnergyField';

const EnergyFieldsByTier = ({
    concept,
    control,
    disabled,
    errors,
    fieldsKeys,
    handleOnChangePeriodField,
    isNetMetField,
}) => {
    return fieldsKeys.map((key) => (
        <EnergyField
            control={control}
            disabled={disabled}
            error={errors?.[key]?.[concept]}
            key={`${key}-${concept}`}
            name={`energy.${key}.${concept}`}
            onBlur={() => handleOnChangePeriodField(isNetMetField, key)}
        />
    ));
};

EnergyFieldsByTier.propTypes = {
    concept: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    fieldsKeys: PropTypes.array,
};

export default EnergyFieldsByTier;
