import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import yupResolver from 'common/utils/yupResolver';

import * as selectors from '../selectors';
import validate from '../validate';

import Consumption from './Consumption';

const FormContainer = ({
    annualKWh,
    customerEnergyData,
    dailyAverageKWh,
    errors,
    initialValues,
    isLocked,
    onSubmit,
}) => {
    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Consumption
                annualKWh={annualKWh}
                control={control}
                customerEnergyData={customerEnergyData}
                dailyAverageKWh={dailyAverageKWh}
                initialValues={initialValues}
                isLocked={isLocked}
                setValue={setValue}
                submitForm={handleSubmit(onSubmit)}
            />

            {errors && errors.length ? <ShowErrors errors={errors} /> : null}
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    errors: selectors.getErrorsSave,
});

FormContainer.propTypes = {
    annualKWh: PropTypes.number,
    customerEnergyData: PropTypes.object,
    dailyAverageKWh: PropTypes.number,
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    onSubmit: PropTypes.func,
};

export default connect(mapStateToProps)(FormContainer);
