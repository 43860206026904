import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import FolderIcon from '@mui/icons-material/Folder';
import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    Tabs,
    Tab,
    TabPanel,
    List,
} from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { scroll } from 'common/utils/mixins';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ResultItem from './ResultItem';
import TabLabel from './TabLabel';

const StyledTabPanel = styled(TabPanel)`
    max-height: 400px;
    overflow-y: scroll;
    padding: 2px;

    > .MuiBox-root {
        padding: 0;
    }

    ${scroll.custom()}
`;

const DEBOUNCE_TIME = 500;

const ResultsTabs = ({
    canViewContacts = false,
    canViewProjects = false,
    canViewProposals = false,
    clearSearch,
    contactsData,
    fetchAllData,
    loadMore,
    myForwardedRef,
    projectsData,
    proposalsData,
    searchFieldRef,
    searchQuery,
}) => {
    const { t } = useTranslation();
    const tabs = [
        {
            canView: canViewContacts,
            context: 'contacts',
            count: contactsData.total_results,
            dataSource: contactsData,
            label: t('Contact'),
            render: (item) => (
                <ResultItem
                    clearSearch={clearSearch}
                    extraContent={
                        <>
                            {item.emails?.[0]}
                            {item.company_name ? (
                                <> &bull; {item.company_name}</>
                            ) : (
                                ''
                            )}
                        </>
                    }
                    key={item.id}
                    mainContent={item.name}
                    tabIndex="-1"
                    to={`/record/${item.external_uuid}?tab=information`}
                />
            ),
            results: contactsData?.results,
            value: 0,
        },
        {
            canView: canViewProjects,
            context: 'projects',
            count: projectsData.total_results,
            dataSource: projectsData,
            label: t('Project'),
            render: (item) => (
                <ResultItem
                    clearSearch={clearSearch}
                    extraContent={
                        <>
                            {item.contact_name}
                            {item.customer_name ? (
                                <> &bull; {item.customer_name}</>
                            ) : (
                                ''
                            )}
                        </>
                    }
                    icon={<FolderIcon />}
                    key={item.id}
                    mainContent={item.name}
                    to={`/record/${item.contact_external_uuid}?tab=projects&project=${item.external_uuid}&view=proposals&type=proposals`}
                />
            ),
            results: projectsData?.results,
            value: 1,
        },
        {
            canView: canViewProposals,
            context: 'proposals',
            count: proposalsData.total_results,
            dataSource: proposalsData,
            label: t('Proposal'),
            render: (item) => (
                <ResultItem
                    clearSearch={clearSearch}
                    extraContent={
                        <>
                            {item.contact_name}
                            {item.project_name ? (
                                <> &bull; {item.project_name}</>
                            ) : (
                                ''
                            )}
                        </>
                    }
                    icon={<ArticleIcon />}
                    key={item.id}
                    mainContent={item.name}
                    to={`/proposal/${item.external_uuid}`}
                />
            ),
            results: proposalsData?.results,
            value: 2,
        },
    ];
    const initialTab = tabs.filter((tab) => tab.canView)?.[0]?.value;
    const [selectedTab, setSelectedTab] = useState(initialTab);
    const [timer, setTimer] = useState(null);
    const handleChange = (_, newValue) => setSelectedTab(newValue);

    const debounceSearch = (e) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => triggerChange(e), DEBOUNCE_TIME));
    };

    const triggerChange = (e) =>
        fetchAllData(e, { canViewContacts, canViewProjects, canViewProposals });

    const tabRefs = [useRef(null), useRef(null), useRef(null)];

    let indexSelectedItem = 0;

    useImperativeHandle(myForwardedRef, () => ({
        selectFirstItem: () => {
            if (tabRefs[selectedTab].current) {
                const currentTab = tabRefs[selectedTab].current;
                const currentTabItems = currentTab.querySelectorAll('a');
                currentTabItems[0].focus();
            }
        },
    }));

    useEffect(() => {
        debounceSearch(searchQuery);
    }, [searchQuery]);

    const handleKeyDown = (e) => {
        if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
            if (searchFieldRef.current) {
                searchFieldRef.current.focus();
            }
            return;
        }

        if (!['ArrowDown', 'ArrowUp'].includes(e.key)) return;

        const currentTab = tabRefs[selectedTab].current;
        const currentTabItems = currentTab.querySelectorAll('a');

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (currentTabItems.length) {
                if (indexSelectedItem < currentTabItems.length - 1) {
                    indexSelectedItem++;
                } else {
                    indexSelectedItem = 0;
                }
                currentTabItems[indexSelectedItem].focus();
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (currentTabItems.length) {
                if (indexSelectedItem > 0) {
                    indexSelectedItem--;
                } else {
                    indexSelectedItem = currentTabItems.length - 1;
                }
                currentTabItems[indexSelectedItem].focus();
            }
        }
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    onChange={handleChange}
                    scrollButtons="auto"
                    value={selectedTab}
                >
                    {tabs
                        .filter((tab) => tab.canView)
                        .map((tab) => (
                            <Tab
                                key={tab.value}
                                label={
                                    <TabLabel
                                        count={tab.count}
                                        label={tab.label}
                                    />
                                }
                                sx={{ p: 1 }}
                                value={tab.value}
                            />
                        ))}
                </Tabs>
            </Box>

            {tabs
                .filter((tab) => tab.canView)
                .map((tab) => (
                    <StyledTabPanel
                        key={tab.value}
                        selectedTab={selectedTab}
                        value={tab.value}
                    >
                        <List
                            onKeyDown={handleKeyDown}
                            ref={tabRefs[tab.value]}
                            tabIndex="-1"
                        >
                            {tab.results?.map((item) => tab.render(item))}
                        </List>

                        {tab.results.length < tab.dataSource.total_results && (
                            <Button
                                onClick={() => {
                                    loadMore({
                                        context: tab.context,
                                        page: tab.dataSource.page + 1,
                                        q: searchQuery,
                                    });
                                }}
                                size="small"
                            >
                                {t('Load more')}
                            </Button>
                        )}
                    </StyledTabPanel>
                ))}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    contactsData: selectors.getContactsData,
    projectsData: selectors.getProjectsData,
    proposalsData: selectors.getProposalsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAllData: (q, permissions) =>
        dispatch(actions.fetchAllData(q, permissions)),
    loadMore: (values) => dispatch(actions.fetchMore(values)),
});

ResultsTabs.propTypes = {
    canViewContacts: PropTypes.bool,
    canViewProjects: PropTypes.bool,
    canViewProposals: PropTypes.bool,
    clearSearch: PropTypes.func,
    contactsData: PropTypes.object,
    fetchAllData: PropTypes.func,
    loadMore: PropTypes.func,
    myForwardedRef: PropTypes.object,
    projectsData: PropTypes.object,
    proposalsData: PropTypes.object,
    searchFieldRef: PropTypes.object,
    searchQuery: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsTabs);
