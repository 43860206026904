import { ONBOARDING_STEPS } from '../constants';

import setCurrentStep from './setCurrentStep';
import updateSection from './updateSection';

export default (values, callback) => (dispatch) => {
    const { members } = values;
    localStorage.setItem('tempMembers', JSON.stringify(members));
    dispatch(setCurrentStep(values?.step + 1));
    dispatch(updateSection(ONBOARDING_STEPS.PANELS));
    if (callback) callback(ONBOARDING_STEPS.PANELS);
};
