import i18next from 'i18next';

import { resetCompatibilitySettings } from 'common/api/v1/equipmentCompatibility';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import * as selectors from '../selectors';

import fetchCompatibilityData from './fetchCompatibilityData';

/*POSSIBLY POSDEPRECATED */
export default (setValue) => (dispatch, getState) => {
    const state = getState();
    const initialValues = selectors.getInitialValues(state);

    const values = {
        product_panel: initialValues.product_panel,
        product_inverter: initialValues.product_inverter,
        political_division1: initialValues.political_division1,
        political_division2: initialValues.political_division2,
    };

    resetCompatibilitySettings(values)
        .then(() => {
            dispatch(
                fetchCompatibilityData(
                    initialValues.product_panel,
                    initialValues.product_inverter,
                    initialValues.political_division1,
                    initialValues.political_division2,
                    setValue,
                ),
            );

            showToast({ body: i18next.t('It was successfully reset') });
        })
        .catch((error) => showReponseErrorsAsAlert(dispatch, error?.response));
};
