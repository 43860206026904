import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button } from 'sunwise-ui';

import { GENERATION_SOURCES } from 'common/constants';

import PanelNumberWarning from './PanelNumberWarning';

const GENERATION_SOURCE_ALERT = {
    [GENERATION_SOURCES.CSV]:
        'The generation CSV has a {{energySaving}}% energy saving. Please make sure the system size in Sunwise matches the system size from the CSV',
    [GENERATION_SOURCES.DESIGNER]:
        'The simulation generation has a {{energySaving}}% energy saving. Please make sure the system size in Sunwise matches the system size from the simulation',
};

const PanelWarnings = ({
    contractedDemand,
    energySaving,
    hasPanel,
    initialRate,
    isLoading,
    limitKw,
    numOfferPanels = 0,
    offerInverters,
    pvSystemDataSource,
    rateAlertsData,
    showUpdateButton,
    updatePanelsWithSegments,
    systemSize,
}) => {
    const { t } = useTranslation();

    const generationSourceAlert = GENERATION_SOURCE_ALERT[pvSystemDataSource];

    return (
        <>
            <PanelNumberWarning
                contractedDemand={contractedDemand}
                initialRate={initialRate}
                limitKw={limitKw}
                offerInverters={offerInverters}
                rateAlertsData={rateAlertsData}
                systemSize={Number(systemSize)}
            />

            {!isLoading && !hasPanel && numOfferPanels === 0 && (
                <Alert severity="error">{t('Select a panel')}</Alert>
            )}

            {!isLoading && numOfferPanels > 1 && (
                <Alert severity="warning">
                    {t(
                        'This option is only available if there is only one panel in the quote',
                    )}
                </Alert>
            )}

            {!isLoading && generationSourceAlert && (
                <Alert
                    severity="warning"
                    sx={{ '.MuiAlert-message': { width: '100%' } }}
                >
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        gap={1}
                        justifyContent="space-between"
                    >
                        {t(generationSourceAlert, {
                            energySaving: energySaving || 0,
                        })}

                        {showUpdateButton && (
                            <Box
                                textAlign="right"
                                width={{ xs: '100%', md: 'auto' }}
                            >
                                <Button
                                    onClick={updatePanelsWithSegments}
                                    sx={{ m: '0!important' }}
                                >
                                    {t('Update')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Alert>
            )}
        </>
    );
};

PanelWarnings.propTypes = {
    contractedDemand: PropTypes.number,
    energySaving: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    hasPanel: PropTypes.bool,
    initialRate: PropTypes.string,
    isLoading: PropTypes.bool,
    limitKw: PropTypes.number,
    numOfferPanels: PropTypes.number,
    offerInverters: PropTypes.array,
    pvSystemDataSource: PropTypes.number,
    rateAlertsData: PropTypes.array,
    showUpdateButton: PropTypes.bool,
    updatePanelsWithSegments: PropTypes.func,
    systemSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PanelWarnings;
