import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from '../actions';
import * as selectors from '../selectors';

import Financiers from './Financiers';
import TermsConditions from './TermsConditions';

const AddAllianceModal = ({
    fetchRequirements,
    financiers,
    initialValues,
    isFetchingFinanciers,
    isFetchingRequirements,
    isSavingFinancierInstaller,
    onSuccess,
    requirements,
    save,
}) => {
    const [modalStep, setModalStep] = useState(1);
    const [selectedFinancier, setSelectedFinancier] = useState(null);

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: { financiers_products: {} },
    });

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (modalStep === 2) fetchRequirements(selectedFinancier.id);
    }, [modalStep]);

    const handleOnClickSelectFinancier = (financier) => {
        setSelectedFinancier(financier);
        setValue('financier', financier.id);
        setModalStep(2);
    };

    const handleOnSave = (values) => save(values, onSuccess);

    if (modalStep === 2)
        return (
            <TermsConditions
                control={control}
                financierType={selectedFinancier?.type}
                handleOnSave={handleOnSave}
                handleSubmit={handleSubmit}
                isFetching={isFetchingRequirements}
                isSavingFinancierInstaller={isSavingFinancierInstaller}
                registered={watch('company_registered')}
                requirements={requirements}
                selectedFinancier={selectedFinancier}
            />
        );

    return (
        <Financiers
            financiers={financiers}
            handleOnClick={handleOnClickSelectFinancier}
            isFetching={isFetchingFinanciers}
        />
    );
};

AddAllianceModal.propTypes = {
    fetchRequirements: PropTypes.func,
    financiers: PropTypes.array,
    initialValues: PropTypes.object,
    isFetchingFinanciers: PropTypes.bool,
    isFetchingRequirements: PropTypes.bool,
    isSavingFinancierInstaller: PropTypes.bool,
    onSuccess: PropTypes.func,
    requirements: PropTypes.object,
    save: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    financiers: selectors.getFinanciersData,
    initialValues: selectors.getTermInitialValues,
    isFetchingFinanciers: selectors.getFinanciersIsFetching,
    isFetchingRequirements: selectors.getRequirementsIsFetching,
    isSavingFinancierInstaller: selectors.getSaveFinanciersInstallerIsFetching,
    requirements: selectors.getRequirementsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRequirements: (id) => dispatch(actions.fetchRequirements(id)),
    save: (values, callback) =>
        dispatch(actions.saveFinarciersInstaller(values, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAllianceModal);
