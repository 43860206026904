import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import {
    getCountryCurrencyLocale,
    getCountryCurrencySymbol,
} from 'common/utils/helpers/session';

import DetailsPlaceholder from 'resources/onboarding/details.svg';

import CompanyLocation from './CompanyLocation';
import CurrencySettings from './CurrencySettings';
import CustomAccordion from './CustomAccordion';
import ReduxFieldArrayTaxes from './ReduxFieldArrayTaxes';

const DetailsStep = ({
    control,
    currencies,
    formValues,
    getValues,
    setValue,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    py: 1,
                    width: '100%',
                }}
            >
                <img src={DetailsPlaceholder} />
                <Typography variant="h6">
                    {t('Customize every detail')}
                </Typography>
                <Typography variant="body2">
                    {t('This will help us configure your Sunwise space')}
                </Typography>
            </Box>
            <Grid container mt={2}>
                <Grid item xs>
                    <CustomAccordion
                        defaultExpanded={true}
                        title={t('Currency')}
                    >
                        <CurrencySettings
                            control={control}
                            countryCurrencyLocale={getCountryCurrencyLocale()}
                            currencies={currencies}
                            formValues={formValues}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </CustomAccordion>
                    <CustomAccordion
                        defaultExpanded={true}
                        title={t('Tax', { count: 2 })}
                    >
                        <ReduxFieldArrayTaxes
                            control={control}
                            currencySymbol={getCountryCurrencySymbol()}
                            formValues={formValues}
                            getValues={getValues}
                            name="taxes"
                            setValue={setValue}
                        />
                    </CustomAccordion>
                    <CustomAccordion
                        defaultExpanded={true}
                        title={t('Location')}
                    >
                        <CompanyLocation
                            control={control}
                            formValues={formValues}
                            setValue={setValue}
                        />
                    </CustomAccordion>
                </Grid>
            </Grid>
        </>
    );
};

DetailsStep.propTypes = {
    control: PropTypes.object,
    formValues: PropTypes.object,
    currencies: PropTypes.array,
    getValues: PropTypes.func,
    setValue: PropTypes.func,
};

export default DetailsStep;
