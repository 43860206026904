import { ONBOARDING_STEPS } from '../constants';

import setCurrentStep from './setCurrentStep';
import updateSection from './updateSection';

export default (values, callback) => (dispatch) => {
    const { battery_brand, batteries } = values;
    localStorage.setItem(
        'tempBatterySettings',
        JSON.stringify({ battery_brand, batteries }),
    );
    dispatch(setCurrentStep(values?.step + 1));
    dispatch(updateSection(ONBOARDING_STEPS.FINISH));
    if (callback) callback(ONBOARDING_STEPS.FINISH);
};
