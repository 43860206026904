import React, { forwardRef } from 'react';

import InputMask from 'react-input-mask';

const InputMaskForwardRef = forwardRef((props, ref) => (
    <InputMask {...props} ref={ref} />
));

InputMaskForwardRef.displayName = 'InputMaskForwardRef';

export default InputMaskForwardRef;
