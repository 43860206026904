import React, { useEffect, useState } from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

const ReactHookFormMultiSelect = ({
    allTextValue = i18next.t('All text'),
    control,
    disabled,
    fullWidth = true,
    label,
    name,
    options,
    variant,
}) => {
    const [optionsByValue, setOptionsByValue] = useState({});

    useEffect(() => {
        setOptionsByValue(
            options?.reduce(
                (acc, option) => ({ ...acc, [option.value]: option.label }),
                {},
            ) ?? {},
        );
    }, [options]);

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={[]}
            render={({ field, fieldState: { error } }) => (
                <FormControl
                    error={Boolean(error)}
                    fullWidth={fullWidth}
                    size="small"
                    variant={variant}
                >
                    <InputLabel>{label}</InputLabel>
                    <Select
                        disabled={disabled}
                        displayEmpty={label === ''}
                        label={label}
                        multiple
                        {...field}
                        onChange={(e) => {
                            if (e.target.value.includes('all')) {
                                if (field.value?.length === options.length) {
                                    field.onChange([]);
                                } else
                                    field.onChange(
                                        options.map((opt) => opt.value),
                                    );
                            } else {
                                field.onChange(e.target.value);
                            }
                        }}
                        renderValue={(selected) => {
                            if (selected.length === options.length)
                                return allTextValue;

                            return selected
                                .map((value) => optionsByValue[value])
                                .join(', ');
                        }}
                        value={field.value ?? []}
                    >
                        <MenuItem key="multi-select-option-all" value="all">
                            <Checkbox
                                checked={
                                    field?.value?.length === options.length
                                }
                                size="small"
                                sx={{ py: 0.75 }}
                            />
                            {allTextValue}
                        </MenuItem>

                        {options.map(
                            ({
                                disabled: optionDisabled,
                                label,
                                value,
                                visible = true,
                            }) =>
                                visible ? (
                                    <MenuItem
                                        key={`select-${value}-${label}`}
                                        value={value}
                                        disabled={optionDisabled}
                                    >
                                        <Checkbox
                                            checked={field?.value?.includes(
                                                value,
                                            )}
                                            size="small"
                                            sx={{ py: 0.75 }}
                                        />
                                        {label}
                                    </MenuItem>
                                ) : null,
                        )}
                    </Select>
                    {Boolean(error) && (
                        <FormHelperText>{error?.message}</FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};

ReactHookFormMultiSelect.propTypes = {
    allTextValue: PropTypes.string,
    control: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    variant: PropTypes.string,
};

export default ReactHookFormMultiSelect;
