import { checkProposalEditStatus } from 'common/api/v1/sunpay';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { PREPERE_EDIT, PREPERE_EDIT_SUCCESS } from '../actionTypes';
import { proposalGeneratorOneColumnActions } from '../reducer';

import eventsAfterUpdate from './eventsAfterUpdate';
import setIsLocked from './setIsLocked';

export default (proposalId) => (dispatch) => {
    dispatch(proposalGeneratorOneColumnActions[PREPERE_EDIT]());

    dispatch(alerts.actions.disableButtons(true));

    checkProposalEditStatus(proposalId)
        .then((response) => {
            dispatch(
                eventsAfterUpdate(response.data.data, proposalId, () => {
                    dispatch(
                        proposalGeneratorOneColumnActions[
                            PREPERE_EDIT_SUCCESS
                        ](),
                    );
                }),
            );
            dispatch(setIsLocked(false));
        })
        .catch((error) => showReponseErrorsAsAlert(dispatch, error?.response))
        .finally(() => {
            dispatch(alerts.actions.close());
            dispatch(alerts.actions.disableButtons(false));
        });
};
