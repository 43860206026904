import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_CREDENTIAL,
    DELETE_CREDENTIAL_FAILURE,
    DELETE_CREDENTIAL_SUCCESS,
    FETCH_CREDENTIALS,
    FETCH_CREDENTIALS_FAILURE,
    FETCH_CREDENTIALS_SUCCESS,
    FETCH_PROVIDERS,
    FETCH_PROVIDERS_FAILURE,
    FETCH_PROVIDERS_SUCCESS,
    INITIALIZE_FORM,
    RESET,
    RESET_FORM,
    SAVE_CREDENTIAL,
    SAVE_CREDENTIAL_FAILURE,
    SAVE_CREDENTIAL_SUCCESS,
    SET_IS_OPEN_MODAL,
} from './actionTypes';

const INITIAL_STATE = {
    credentials: { data: [], errors: null, isFetching: false },
    deleteCredential: { errors: null, isDeleting: false },
    initialValues: {
        credential: {},
        description: '',
        name: '',
        provider_id: null,
    },
    isOpenModal: false,
    providers: { data: [], errors: null, isFetching: false },
    saveCredential: { errors: null, isSaving: false },
};

const credentialIntegrationsSlice = createSlice({
    name: 'credentialIntegrations',
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_CREDENTIAL]: (state) => {
            state.deleteCredential.errors = null;
            state.deleteCredential.isDeleting = true;
        },
        [DELETE_CREDENTIAL_FAILURE]: (state, action) => {
            state.deleteCredential.errors = action.payload;
            state.deleteCredential.isDeleting = false;
        },
        [DELETE_CREDENTIAL_SUCCESS]: (state) => {
            state.deleteCredential.isDeleting = false;
        },
        [FETCH_CREDENTIALS]: (state) => {
            state.credentials.errors = null;
            state.credentials.isFetching = true;
        },
        [FETCH_CREDENTIALS_FAILURE]: (state, action) => {
            state.credentials.errors = action.payload;
            state.credentials.isFetching = false;
        },
        [FETCH_CREDENTIALS_SUCCESS]: (state, action) => {
            state.credentials.data = action.payload;
            state.credentials.isFetching = false;
        },
        [FETCH_PROVIDERS]: (state) => {
            state.providers.errors = null;
            state.providers.isFetching = true;
        },
        [FETCH_PROVIDERS_FAILURE]: (state, action) => {
            state.providers.errors = action.payload;
            state.providers.isFetching = false;
        },
        [FETCH_PROVIDERS_SUCCESS]: (state, action) => {
            state.providers.data = action.payload;
            state.providers.isFetching = false;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_CREDENTIAL]: (state) => {
            state.saveCredential.errors = null;
            state.saveCredential.isSaving = true;
        },
        [SAVE_CREDENTIAL_FAILURE]: (state, action) => {
            state.saveCredential.errors = action.payload;
            state.saveCredential.isSaving = false;
        },
        [SAVE_CREDENTIAL_SUCCESS]: (state) => {
            state.saveCredential.isSaving = false;
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const credentialIntegrationsActions =
    credentialIntegrationsSlice.actions;

export default credentialIntegrationsSlice.reducer;
