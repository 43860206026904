import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { UiDialog as Dialog } from 'sunwise-ui';

import CardForm from './CardForm';

const CardModal = ({
    currencyIso,
    currencySymbol,
    editionMode,
    handleClickCancel,
    isLoading,
    isLocked,
    isOpenModal,
    isSaving,
    ownFinancialProduct,
    proposalId,
}) => {
    const [title, setTitle] = useState('');
    return (
        <Dialog
            closeButton={isLocked ? false : true}
            isLoading={isLoading}
            onClose={() => handleClickCancel()}
            onExited={() => handleClickCancel()}
            open={isOpenModal}
            title={title}
        >
            <CardForm
                currencyIso={currencyIso}
                currencySymbol={currencySymbol}
                editionMode={editionMode}
                handleClickCancel={handleClickCancel}
                isLocked={isLocked}
                isSaving={isSaving}
                ownFinancialProduct={ownFinancialProduct}
                proposalId={proposalId}
                setTitle={setTitle}
            />
        </Dialog>
    );
};

CardModal.propTypes = {
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    editionMode: PropTypes.bool,
    handleClickCancel: PropTypes.func,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    isSaving: PropTypes.bool,
    ownFinancialProduct: PropTypes.object,
    proposalId: PropTypes.string,
};

export default CardModal;
