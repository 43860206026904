import { NAME } from './constants';

export const ADD_FINANCIAL_PRODUCT = `${NAME}/ADD_FINANCIAL_PRODUCT`;
export const ADD_FINANCIAL_PRODUCT_FAILURE = `${NAME}/ADD_FINANCIAL_PRODUCT_FAILURE`;
export const ADD_FINANCIAL_PRODUCT_SUCCESS = `${NAME}/ADD_FINANCIAL_PRODUCT_SUCCESS`;

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const FETCH_CATALOG_REQUIREMENTS = `${NAME}/FETCH_CATALOG_REQUIREMENTS`;
export const FETCH_CATALOG_REQUIREMENTS_FAILURE = `${NAME}/FETCH_CATALOG_REQUIREMENTS_FAILURE`;
export const FETCH_CATALOG_REQUIREMENTS_SUCCESS = `${NAME}/FETCH_CATALOG_REQUIREMENTS_SUCCESS`;

export const FETCH_FINANCIERS = `${NAME}/FETCH_FINANCIERS`;
export const FETCH_FINANCIERS_FAILURE = `${NAME}/FETCH_FINANCIERS_FAILURE`;
export const FETCH_FINANCIERS_SUCCESS = `${NAME}/FETCH_FINANCIERS_SUCCESS`;

export const FETCH_FINANCIER_INSTALLER_DOCUMENTS = `${NAME}/FETCH_FINANCIERS_INSTALLER_DOCUMENTS`;
export const FETCH_FINANCIER_INSTALLER_DOCUMENTS_FAILURE = `${NAME}/FETCH_FINANCIERS_INSTALLER_DOCUMENTS_FAILURE`;
export const FETCH_FINANCIER_INSTALLER_DOCUMENTS_SUCCESS = `${NAME}/FETCH_FINANCIERS_INSTALLER_DOCUMENTS_SUCCESS`;

export const FETCH_FINANCIER_PRODUCT = `${NAME}/FETCH_FINANCIER_PRODUCT`;
export const FETCH_FINANCIER_PRODUCT_FAILURE = `${NAME}/FETCH_FINANCIER_PRODUCT_FAILURE`;
export const FETCH_FINANCIER_PRODUCT_SUCCESS = `${NAME}/FETCH_FINANCIER_PRODUCT_SUCCESS`;

export const FETCH_FINANCIERS_INSTALLER = `${NAME}/FETCH_FINANCIERS_INSTALLER`;
export const FETCH_FINANCIERS_INSTALLER_FAILURE = `${NAME}/FETCH_FINANCIERS_INSTALLER_FAILURE`;
export const FETCH_FINANCIERS_INSTALLER_SUCCESS = `${NAME}/FETCH_FINANCIERS_INSTALLER_SUCCESS`;

export const FETCH_PRODUCT = `${NAME}/FETCH_PRODUCT`;
export const FETCH_PRODUCT_FAILURE = `${NAME}/FETCH_PRODUCT_FAILURE`;
export const FETCH_PRODUCT_SUCCESS = `${NAME}/FETCH_PRODUCT_SUCCESS`;

export const FETCH_REQUIREMENTS = `${NAME}/FETCH_REQUIREMENTS`;
export const FETCH_REQUIREMENTS_FAILURE = `${NAME}/FETCH_REQUIREMENTS_FAILURE`;
export const FETCH_REQUIREMENTS_SUCCESS = `${NAME}/FETCH_REQUIREMENTS_SUCCESS`;

export const FETCH_TEMPLATE_CATALOGS = `${NAME}/FETCH_TEMPLATE_CATALOGS`;
export const FETCH_TEMPLATE_CATALOGS_FAILURE = `${NAME}/FETCH_TEMPLATE_CATALOGS_FAILURE`;
export const FETCH_TEMPLATE_CATALOGS_SUCCESS = `${NAME}/FETCH_TEMPLATE_CATALOGS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const MODAL_PRODUCT_STATUS = `${NAME}/MODAL_PRODUCT_STATUS`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_FINANCIERS_INSTALLER = `${NAME}/SAVE_FINANCIERS_INSTALLER`;
export const SAVE_FINANCIERS_INSTALLER_FAILURE = `${NAME}/SAVE_FINANCIERS_INSTALLER_FAILURE`;
export const SAVE_FINANCIERS_INSTALLER_SUCCESS = `${NAME}/SAVE_FINANCIERS_INSTALLER_SUCCESS`;

export const TOGGLE_PRODUCT_ENABLE = `${NAME}/TOGGLE_PRODUCT_ENABLE`;
export const TOGGLE_PRODUCT_ENABLE_FAILURE = `${NAME}/TOGGLE_PRODUCT_ENABLE_FAILURE`;
export const TOGGLE_PRODUCT_ENABLE_SUCCESS = `${NAME}/TOGGLE_PRODUCT_ENABLE_SUCCESS`;

export const UPDATE_ITEMS_SUCCESS = `${NAME}/UPDATE_ITEMS_SUCCESS`;

export const UPLOAD_DOCUMENT = `${NAME}/UPLOAD_DOCUMENT`;
export const UPLOAD_DOCUMENT_FAILURE = `${NAME}/UPLOAD_DOCUMENT_FAILURE`;
export const UPLOAD_DOCUMENT_SUCCESS = `${NAME}/UPLOAD_DOCUMENT_SUCCESS`;
