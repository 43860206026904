export const NAME = 'multi-template-review';

export const DATASHEET_PDF_TYPE = 3;
export const ELECTRIC_BILL_TYPE = 4;
export const FINANCING_TYPE = 5;

export const PDF_LOW_QUALITY = 1;
export const PDF_MEDIUM_QUALITY = 2;
export const PDF_HIGHT_QUALITY = 3;
export const CSV_FILE = 4;
export const CSV_HOURLY_FILE = 5;
export const XLS_FILE = 6;
export const XLS_FILE_FORMATTED = 7;
export const PDF_REAL_TEXT = 8;

export const OFFER_SMART_DOCUMENT_FORM_CREATE_MODE = 1;
export const OFFER_SMART_DOCUMENT_FORM_DUPLICATE_MODE = 2;
export const OFFER_SMART_DOCUMENT_FORM_RENAME_MODE = 3;
