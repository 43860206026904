import { updateItem } from 'common/api/v2/offerTaxes';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import {
    SAVE_PRODUCTS_SETTINGS,
    SAVE_PRODUCTS_SETTINGS_FAILURE,
    SAVE_PRODUCTS_SETTINGS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorTaxesTableActions } from '../reducer';

import setProductsSettingsModalIsOpen from './setProductsSettingsModalIsOpen';

export default (values, proposalId) => (dispatch) => {
    dispatch(proposalGeneratorTaxesTableActions[SAVE_PRODUCTS_SETTINGS]());

    const newValues = {
        id: values.id,
        elements:
            values?.products
                ?.filter((product) => product.visible)
                .map((product) => product.value) || [],
    };

    updateItem(newValues, proposalId)
        .then((response) => {
            dispatch(setProductsSettingsModalIsOpen(false));
            dispatch(
                proposalGeneratorTaxesTableActions[
                    SAVE_PRODUCTS_SETTINGS_SUCCESS
                ](response?.data?.data),
            );
            dispatch(eventsAfterUpdate(response?.data?.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorTaxesTableActions[
                    SAVE_PRODUCTS_SETTINGS_FAILURE
                ](error?.response?.data?.errors),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
