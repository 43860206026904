import arrayMove from 'array-move';

import { updateOrderItems } from 'common/api/v1/companiesCatalogs';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { FETCH_COMPANIES_CATALOGS_SUCCESS } from '../actionTypes';
import { listSettingsActions } from '../reducer';

import fetchCatalogs from './fetchCatalogs';

export default (items, newIndex, oldIndex, type) => (dispatch) => {
    const newItems = arrayMove(items, oldIndex, newIndex);

    dispatch(listSettingsActions[FETCH_COMPANIES_CATALOGS_SUCCESS](newItems));

    let catalogs = [];

    newItems.forEach((item, index) => {
        catalogs.push({
            id: item.id,
            order: index + 1,
        });
    });

    const values = {
        items: JSON.stringify(catalogs),
    };

    updateOrderItems(values)
        .then(() => {
            showToast();
            dispatch(fetchCatalogs(type));
        })
        .catch((error) => showReponseErrorsAsAlert(dispatch, error?.response));
};
