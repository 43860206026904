import i18next from 'i18next';
import Papa from 'papaparse';

const handleData = (data, setValue) => {
    if (!data) return;

    const csvData = data.data;
    let termsArray = [];

    for (let i = 0; i < csvData.length; i++) {
        let j = 0;
        let indexTerm = 0;

        while (j < csvData[i].length) {
            if (csvData[i][j] !== '') {
                termsArray[indexTerm] = csvData[i][j];
            }

            indexTerm++;
            j += 2;
        }
    }

    const terms = {};

    termsArray.forEach((item) => (terms[item] = true));

    setValue('parameters.terms', terms);
    setValue('type_algorithm', 'static_capital_payment');
};

export const getAppendText = (currencyIso, parameter) => {
    if (parameter && parseInt(parameter.unit) === 0) return '%';
    return currencyIso;
};

export const getPreAppendText = (currencySymbol, parameter) => {
    if (parameter && parseInt(parameter.unit) === 1) return currencySymbol;
    return null;
};

export const getTemplateOptions = (templates) => {
    if (!templates) return [];
    const options = templates.map((item) => ({
        label: item.title,
        value: item.id,
    }));
    return [
        {
            disabled: true,
            label: i18next.t('Select template'),
            value: '',
        },
        ...options,
    ];
};

export const handleOnChangeFileBuild = (file, setValue) => {
    if (!file) return;

    Papa.parse(file, {
        complete: handleData,
    });

    setValue('parameters_rate.value', 0);
    setValue('parameters.tax', 0);
    setValue('parameters_commission_credit.value', 0);
    setValue('parameters_commission_credit.opening_commission_vat', 0);
    setValue('parameters_rate.isChecked', false);
    setValue('parameters_commission_credit.isChecked', false);
};
