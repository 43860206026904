import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)(
    () =>
        `
            background-color: #000;
            bottom: 0;
            height: 100%;
            left: 0;
            position: relative;
            right: 0;
            top: 0;
            z-index: 1;

            .popup-container {
                cursor: pointer;
                height: 0;
                position: absolute;
                transform-origin: 0% 0%;
                width: 200px;
                z-index: 1;
                &:hover {
                    z-index: 2;
                }
                .popup-bubble-anchor {
                    bottom: 6px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    span {
                        background-color: #002438;
                        border-radius: 2px;
                        color: #ffffff;
                        font-size: 8px;
                        font-weight: 100;
                        height: 13px;
                        left: 0;
                        letter-spacing: 0.2px;
                        line-height: 13px;
                        max-height: 60px;
                        overflow-y: hidden;
                        padding: 0 2px;
                        position: absolute;
                        top: 0;
                        transform: translate(-50%, -100%);
                        user-select: none;
                    }
                }
            }
        `,
);
