import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';

const HeaderForm = ({ title, fontSize }) => (
    <Grid container>
        <Grid item md={13} xs>
            <TitleWithDetail fontSize={fontSize}>{title}</TitleWithDetail>
        </Grid>
    </Grid>
);

HeaderForm.propTypes = {
    fontSize: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default HeaderForm;
