import React from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from 'sunwise-ui';

import DateTooltip from './DateTooltip';
import MenuOptions from './MenuOptions';
import ProposalsTooltip from './ProposalsTooltip';

const Wrapper = styled(Card)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
`,
);

const SummaryItem = ({
    canDelete,
    canModify,
    data,
    handleClickDownloadCSV,
    isArchivedFilter,
    redirect,
}) => {
    const { t } = useTranslation();
    const isArchived = get(data, 'archived', false);
    const isObsolete = get(data, 'obsolete', false);
    const templateName = get(data, 'custom_offer_template.title', null);
    const proposalsNumber = get(
        data,
        'custom_offer_template.proposals_number',
        null,
    );

    const handleNameClick = () => redirect(`/summary-review/${data.id}`);

    return (
        <>
            <Wrapper>
                <Box display="flex">
                    <Box
                        display="flex"
                        flexDirection="column"
                        onClick={handleNameClick}
                        sx={{ width: '100%', cursor: 'pointer' }}
                    >
                        <Typography variant="body2" fontWeight="bold">
                            {data.name}
                        </Typography>
                        <Typography variant="body2">
                            {t('Template')} {templateName}
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 'auto' }}>
                        <MenuOptions
                            canDelete={canDelete}
                            canModify={canModify}
                            data={data}
                            handleClickDownloadCSV={handleClickDownloadCSV}
                            isArchived={isArchived}
                            isArchivedFilter={isArchivedFilter}
                            isObsolete={isObsolete}
                        />
                    </Box>
                </Box>
                <Box display="flex" gap={2} mt={2}>
                    <DateTooltip createdAt={data.created_at} />
                    <ProposalsTooltip
                        isObsolete={isObsolete}
                        proposals={[]}
                        proposalsNumber={proposalsNumber}
                    />
                </Box>
            </Wrapper>
        </>
    );
};

SummaryItem.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    data: PropTypes.object,
    handleClickDownloadCSV: PropTypes.func,
    isArchivedFilter: PropTypes.bool,
    redirect: PropTypes.func,
};

export default SummaryItem;
