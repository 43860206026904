import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { EMAIL_VALIDATION_TEXT, REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        first_name: string().required(REQUIRED_TEXT),
        last_name: string().required(REQUIRED_TEXT),
        email: string()
            .email(EMAIL_VALIDATION_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
    });
};
