import React from 'react';

import PropTypes from 'prop-types';

import { getCountryCurrencySymbol } from 'common/utils/helpers/session';

import PPAFields from '../../proposalGeneratorConfigurationModal/components/PPAFields';
import PowerFactorFields from '../../proposalGeneratorRateConfiguration/components/PowerFactorFields';

const TabSupplier = ({ control, isDisabled }) => {
    const currencySymbol = getCountryCurrencySymbol();

    return (
        <>
            <PowerFactorFields
                control={control}
                isLocked={isDisabled}
                showPowerFactor={true}
            />

            <PPAFields
                control={control}
                currencySymbol={currencySymbol}
                isLocked={isDisabled}
                showPPAPercentageField={false}
            />
        </>
    );
};

TabSupplier.propTypes = {
    control: PropTypes.object,
    isDisabled: PropTypes.bool,
};

export default TabSupplier;
