import { login } from 'common/api/v1/zap';

export default (onSuccess) =>
    login().then((response) => {
        const token = response?.data?.data?.token;

        if (!token) return;

        localStorage.setItem('zap-token', token);

        if (onSuccess) return onSuccess(token);
    });
