import { getLastCommercialOffers } from 'common/api/v1/dashboards';

import {
    FETCH_COMMERCIAL_OFFERS,
    FETCH_COMMERCIAL_OFFERS_FAILURE,
    FETCH_COMMERCIAL_OFFERS_SUCCESS,
} from '../actionTypes';
import { dashboardActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(dashboardActions[FETCH_COMMERCIAL_OFFERS]());

    getLastCommercialOffers()
        .then((response) =>
            dispatch(
                dashboardActions[FETCH_COMMERCIAL_OFFERS_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                dashboardActions[FETCH_COMMERCIAL_OFFERS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
