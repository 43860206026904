import { getPhoneCodes } from 'common/api/v1/phones';

import {
    FETCH_PHONE_CODES,
    FETCH_PHONE_CODES_FAILURE,
    FETCH_PHONE_CODES_SUCCESS,
} from '../actionTypes';
import { contactFormActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(contactFormActions[FETCH_PHONE_CODES]());

    getPhoneCodes()
        .then((response) =>
            dispatch(
                contactFormActions[FETCH_PHONE_CODES_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                contactFormActions[FETCH_PHONE_CODES_FAILURE](
                    error?.response?.data?.message,
                ),
            ),
        );
};
