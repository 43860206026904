import get from 'lodash/get';

import * as chatAllianceApi from 'common/api/v1/chatAlliance';
import * as chatCreditApi from 'common/api/v1/chatCredit';
import * as chatSocket from 'common/sockets/chat';

import {
    FETCH_MESSAGES,
    FETCH_MESSAGES_FAILURE,
    FETCH_MESSAGES_SUCCESS,
} from '../actionTypes';
import { CREDIT_CHAT_TYPE } from '../constants';
import { chatViewActions } from '../reducer';
import * as selectors from '../selectors';

import initializeForm from './initializeForm';

const selectFetchMessages = (entityId, type) => {
    if (type === CREDIT_CHAT_TYPE) return chatCreditApi.fetchMessages(entityId);
    return chatAllianceApi.fetchMessages(entityId);
};

export default (entityId, user, type) => (dispatch, getState) => {
    dispatch(chatViewActions[FETCH_MESSAGES]());
    const state = getState();
    const socketConnected = selectors.getSocketConnected(state);

    selectFetchMessages(entityId, type)
        .then((response) => {
            if (type === CREDIT_CHAT_TYPE) {
                if (socketConnected) dispatch(chatSocket.disconnect());
                dispatch(chatSocket.connect(response.data.id));
            }
            dispatch(
                chatViewActions[FETCH_MESSAGES_SUCCESS]({
                    ...response.data,
                    messages: get(response, 'data.messages', []).filter(
                        (message) => !message.is_comment,
                    ),
                }),
            );

            const values = {
                chatId: response.data.id,
                message: '',
                user: user,
            };
            dispatch(initializeForm(values));
        })
        .catch((error) =>
            dispatch(chatViewActions[FETCH_MESSAGES_FAILURE](error)),
        );
};
