import React, { useEffect, useState } from 'react';

import SpeedIcon from '@mui/icons-material/Speed';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Typography } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';
import { ChartControls, PeriodSelector } from 'common/components/charts';
import KeyIndicator from 'common/components/KeyIndicator';
import {
    DEFAULT_INITIAL_DATE,
    DEFAULT_MONTH_FINAL_DATE,
} from 'common/constants';
import { differenceInWeeksDate, parseDate } from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import {
    getEnergyChartConfig,
    hexToRgba,
    getTooltipDateRange,
} from 'common/utils/helpersChart';

import { getEnergyChartSettings } from '../../projectConsumptionCard/helpers';
import * as selectors from '../selectors';

const handleChartConfig = ({
    consumptionProfile,
    countryCurrencyLocale,
    monthskwh,
    monthskw,
    selectedDays,
    selectedOption,
    selectedPeriod,
    selectedWeeks,
}) => {
    const dataKw = monthskw.map((month) => month.value);
    const dataKwh = monthskwh.map((month) => month.value);
    const categories = monthskwh.map((month) => month.label.toUpperCase());
    const baseChartConfig = getEnergyChartSettings(
        dataKw,
        dataKwh,
        categories,
        countryCurrencyLocale,
        monthskwh,
    );

    if (selectedOption === 0) return baseChartConfig;

    const summary = monthskwh.map((period) => ({
        initial_date: period.initial_date,
        final_date: period.final_date,
        total: { value: period.value },
    }));

    return getEnergyChartConfig({
        baseChartConfig,
        monthskwh,
        consumptionProfile,
        countryCurrencyLocale,
        dataKw,
        dataKwh,
        selectedDays,
        selectedOption,
        selectedPeriod,
        selectedWeeks,
        summary,
    });
};

const EnergyChart = ({
    consumptionProfile,
    countryCurrencyLocale,
    indicatorText,
    monthskw,
    monthskwh,
}) => {
    const { t } = useTranslation();
    const [chartConfig, setChartConfig] = useState({
        options: {},
        series: [{ data: [], name: '' }],
    });
    const [chartType, setChartType] = useState('area');
    const [selectedDays, setSelectedDays] = useState([1]);
    const [selectedOption, setSelectedOption] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState(0);
    const [selectedWeeks, setSelectedWeeks] = useState([0]);

    const weeksToSelect = differenceInWeeksDate(
        parseDate(
            monthskwh?.[selectedPeriod]?.final_date ?? DEFAULT_MONTH_FINAL_DATE,
            'dd/MM/yyyy',
        ),
        parseDate(
            monthskwh?.[selectedPeriod]?.initial_date ?? DEFAULT_INITIAL_DATE,
            'dd/MM/yyyy',
        ),
    );

    useEffect(() => {
        const { options, series } = handleChartConfig({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks,
        });

        setChartConfig({ options, series });
    }, []);

    useEffect(() => {
        const newSelectedWeeks = selectedWeeks.filter(
            (week) => week < weeksToSelect,
        );

        handleChartChange({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks: newSelectedWeeks,
        });
        setSelectedWeeks(newSelectedWeeks);
    }, [weeksToSelect]);

    const handleChartChange = ({
        consumptionProfile,
        countryCurrencyLocale,
        monthskwh,
        monthskw,
        selectedDays,
        selectedOption,
        selectedPeriod,
        selectedWeeks,
    } = {}) => {
        const { options, series } = handleChartConfig({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks,
        });
        setChartConfig(() => ({ options, series }));
    };

    const handleOnChangeSelectedOption = (e) => {
        const value = parseInt(e.target.value);
        setSelectedOption(value);
        handleChartChange({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption: value,
            selectedPeriod,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedPeriod = (e) => {
        const value = parseInt(e.target.value);
        setSelectedPeriod(value);
        handleChartChange({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption,
            selectedPeriod: value,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedDays = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value = selectedDays.length === 7 ? [] : [0, 1, 2, 3, 4, 5, 6];
        setSelectedDays(value);
        handleChartChange({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays: value,
            selectedOption,
            selectedPeriod,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedWeeks = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value =
                selectedWeeks.length === weeksToSelect
                    ? []
                    : [...Array(weeksToSelect).keys()];
        setSelectedWeeks(value);
        handleChartChange({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks: value,
        });
    };

    const getAnnualConsumption = (monthskwh) =>
        monthskwh.reduce((acc, cur) => acc + cur.value, 0);

    const getPeriodConsumption = (data) =>
        data.reduce((acc, cur) => acc + cur, 0);

    const getKeyIndicatorTitle = (value) => {
        switch (value) {
            case 1:
                return t('Monthly consumption');
            case 2:
                return t('Weekly consumption');
            case 3:
                return t('Daily consumption');
            default:
                '';
        }
    };

    const CHART_COLORS = ['#0073d0', '#f2ae47'];

    const y0Label = selectedOption === 3 ? 'kW' : 'kWh';

    const data = {
        datasets: chartConfig.series.map(({ data, name }, index) => ({
            backgroundColor: hexToRgba(CHART_COLORS[index], 0.8),
            borderColor: CHART_COLORS[index],
            data,
            label: selectedOption === 3 ? 'kW' : name,
            yAxisID: `y${index}`,
        })),
        labels: chartConfig.options.xaxis?.categories || [],
    };

    const y1 =
        chartConfig.series.length > 1
            ? {
                  y1: {
                      grid: { drawOnChartArea: false },
                      position: 'right',
                      ticks: {
                          callback: (val) =>
                              numberFormat(val, {
                                  decimals: 0,
                                  locale: countryCurrencyLocale,
                                  style: 'decimal',
                                  unit: 'kW',
                              }),
                      },
                      title: {
                          display: true,
                          text: 'kW',
                      },
                  },
              }
            : {};

    let callbacks = {};

    if (selectedOption === 0) {
        callbacks.title = (context) => {
            const ctx = context[0];
            return `${getTooltipDateRange({
                index: ctx.dataIndex,
                seriesEnergy: monthskwh,
            })}`;
        };
    }

    const options = {
        interaction: { intersect: false, mode: 'nearest' },
        plugins: { tooltip: { callbacks } },
        scales: {
            x: { grid: { drawOnChartArea: false } },
            y0: {
                ticks: {
                    callback: (val) =>
                        numberFormat(val, {
                            decimals: 0,
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                        }),
                },
                title: {
                    display: true,
                    text: y0Label,
                },
            },
            ...y1,
        },
    };

    const type =
        selectedOption === 0
            ? 'bar'
            : chartType === 'area'
              ? 'line'
              : chartType;

    return (
        <>
            {consumptionProfile && (
                <ChartControls
                    chartType={chartType}
                    chartTypeOptions={['area', 'bar']}
                    handleOnChangePeriod={handleOnChangeSelectedOption}
                    handleOnChangeSelectedDays={handleOnChangeSelectedDays}
                    handleOnChangeSelectedWeeks={handleOnChangeSelectedWeeks}
                    hideChangeTypeButtons={selectedOption === 0 ? true : false}
                    monthSelector={
                        <>
                            {[1, 2, 3].includes(selectedOption) && (
                                <PeriodSelector
                                    consumptionHistory={monthskwh}
                                    onChange={handleOnChangeSelectedPeriod}
                                    showHasDropdown
                                    value={selectedPeriod}
                                />
                            )}
                        </>
                    }
                    selectedDays={selectedDays}
                    selectedOption={selectedOption}
                    selectedWeeks={selectedWeeks}
                    setChartType={setChartType}
                    weeksToSelect={weeksToSelect}
                />
            )}

            <ChartJs data={data} options={options} type={type} />

            {indicatorText ? (
                <Grid container justifyContent="center">
                    <Grid item textAlign="center">
                        <Typography variant="body2">
                            {t('Annual consumption')}
                        </Typography>
                        <Typography variant="body2" fontWeight="bold">
                            {numberFormat(getAnnualConsumption(monthskwh), {
                                decimals: 0,
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: 'kWh',
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid item xs>
                        <KeyIndicator
                            svgIcon={<SpeedIcon />}
                            title={t('Annual consumption')}
                            value={numberFormat(
                                getAnnualConsumption(monthskwh),
                                {
                                    decimals: 0,
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                    unit: 'kWh',
                                },
                            )}
                        />
                    </Grid>

                    {[1, 2, 3].includes(selectedOption) && (
                        <Grid item xs>
                            <KeyIndicator
                                svgIcon={<SpeedIcon />}
                                title={getKeyIndicatorTitle(selectedOption)}
                                value={numberFormat(
                                    getPeriodConsumption(
                                        chartConfig.series[0].data,
                                    ),
                                    {
                                        decimals: 0,
                                        locale: countryCurrencyLocale,
                                        style: 'decimal',
                                        unit: 'kWh',
                                    },
                                )}
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};

EnergyChart.propTypes = {
    consumptionProfile: PropTypes.array,
    countryCurrencyLocale: PropTypes.string,
    indicatorText: PropTypes.bool,
    monthskw: PropTypes.array,
    monthskwh: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    consumptionProfile: selectors.getConsumptionProfileFormatted,
});

export default connect(mapStateToProps, null)(EnergyChart);
