import { POLYGON_SEGMENT } from '../constants';
import { getCollidedPolygons, mergeArrays } from '../helpers';
import * as selectors from '../selectors';

import checkSegment from './checkSegment';
import updateSegments from './updateSegments';

export default (config) => async (dispatch, getState) => {
    const state = getState();
    const segments = selectors.getSegmentsData(state);

    const {
        callback = () => {},
        callbackError = () => {},
        commercialOfferId,
        google,
        mapValue,
        obstacle,
    } = config;

    const polygons = segments.filter(
        (segment) => segment.type === POLYGON_SEGMENT,
    );

    if (!Array.isArray(polygons) || polygons.length === 0) return;

    const { id, polygon, prev_location } = obstacle;

    const actualCollidedPolygons = getCollidedPolygons({
        google,
        points: polygon,
        polygons,
    });

    const prevCollidedPolygons = getCollidedPolygons({
        google,
        points: prev_location ? prev_location : polygon,
        polygons,
    });

    const collidedPolygons = mergeArrays(
        actualCollidedPolygons,
        prevCollidedPolygons,
    );

    const newSegments = segments.map((segment) => {
        const _segment = { ...segment };
        if (_segment.id === id) _segment.prev_location = _segment.polygon;
        return _segment;
    });

    dispatch(updateSegments(newSegments));

    collidedPolygons.forEach(async (segment) => {
        const { id, polygon } = segment;

        const config = {
            callback,
            callbackError,
            commercialOfferId,
            evt: 'drag',
            google,
            mapValue,
            polygon,
            segmentId: id,
        };

        dispatch(checkSegment(config));
    });
};
