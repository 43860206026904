import { createSlice } from '@reduxjs/toolkit';

import {
    ADD_PRODUCT_TO_BRANCHES,
    ADD_PRODUCT_TO_BRANCHES_FAILURE,
    ADD_PRODUCT_TO_BRANCHES_SUCCESS,
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    FETCH_BRANDS,
    FETCH_BRANDS_FAILURE,
    FETCH_BRANDS_SUCCESS,
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_PARENT,
    FETCH_ITEMS_PARENT_FAILURE,
    FETCH_ITEMS_PARENT_SUCCESS,
    FETCH_ITEMS_SUCCESS,
    FETCH_SUPPLIERS,
    FETCH_SUPPLIERS_FAILURE,
    FETCH_SUPPLIERS_SUCCESS,
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_SUCCESS,
    INITIALIZE_FORM,
    INITIALIZE_MARGIN_FORM,
    OPEN_MODAL,
    OPEN_MODAL_MARGIN,
    OPEN_MODAL_PRODUCT,
    RESET_FORM,
    RESET_MARGIN_FORM,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SAVE_BULK_MARGIN,
    SAVE_BULK_MARGIN_FAILURE,
    SAVE_BULK_MARGIN_SUCCESS,
    SET_IS_DOWNLOADING,
    SET_IS_NEW_RECORD,
    SET_IS_UPLOADING,
    UPDATE_ITEMS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    bulkUpdateItems: {
        errors: null,
        isSaving: false,
    },
    fetchBrands: {
        data: [],
        errors: null,
        isFetching: false,
    },
    fetchItems: {
        data: {
            data: [],
            pagination: null,
        },
        errors: null,
        isFetching: false,
    },
    fetchItemsParent: {
        data: {
            data: [],
            pagination: null,
        },
        errors: null,
        isFetching: false,
    },
    fetchSuppliers: {
        data: [],
        errors: null,
        isFetching: false,
    },
    initialMarginValues: {
        ids: [],
        margin: 0,
    },
    initialValues: {
        brand: null,
        code: null,
        costing_unit: null,
        currency: null,
        description: null,
        disabled: false,
        id: null,
        image_thumbnail: null,
        name: null,
        product_type: null,
        range_prices: [
            {
                cost: 0,
                final_cost: 0,
                is_unique_price: false,
                margin: 0,
                price_from: 1,
                price_to: 10,
            },
        ],
        status_flag: '',
    },
    isDownloading: false,
    isInitializing: false,
    isNewRecord: true,
    isOpenModal: false,
    isOpenModalMargin: false,
    isOpenModalProduct: false,
    isUploading: false,
    productAddedToBranches: {
        errors: null,
        isSaving: false,
    },
    saveItem: {
        errors: null,
        isSaving: false,
    },
    saveMargin: {
        errors: null,
        isSaving: false,
    },
};

const panelConfigurationSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ADD_PRODUCT_TO_BRANCHES]: (state) => {
            state.productAddedToBranches = {
                ...state.productAddedToBranches,
                errors: [],
                isSaving: true,
            };
        },
        [ADD_PRODUCT_TO_BRANCHES_FAILURE]: (state, action) => {
            state.productAddedToBranches = {
                ...state.productAddedToBranches,
                errors: action.payload,
                isSaving: false,
            };
        },
        [ADD_PRODUCT_TO_BRANCHES_SUCCESS]: (state) => {
            state.productAddedToBranches = {
                ...state.productAddedToBranches,
                isSaving: false,
            };
        },
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isSaving: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isSaving: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                isSaving: false,
            };
        },
        [FETCH_BRANDS]: (state) => {
            state.fetchBrands = {
                ...state.fetchBrands,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_BRANDS_FAILURE]: (state, action) => {
            state.fetchBrands = {
                ...state.fetchBrands,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BRANDS_SUCCESS]: (state, action) => {
            state.fetchBrands = {
                ...state.fetchBrands,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS]: (state) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ITEMS_FAILURE]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_SUCCESS]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_PARENT]: (state) => {
            state.fetchItemsParent = {
                ...state.fetchItemsParent,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ITEMS_PARENT_FAILURE]: (state, action) => {
            state.fetchItemsParent = {
                ...state.fetchItemsParent,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_PARENT_SUCCESS]: (state, action) => {
            state.fetchItemsParent = {
                ...state.fetchItemsParent,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUPPLIERS]: (state) => {
            state.fetchSuppliers = {
                ...state.fetchSuppliers,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_SUPPLIERS_FAILURE]: (state, action) => {
            state.fetchSuppliers = {
                ...state.fetchSuppliers,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUPPLIERS_SUCCESS]: (state, action) => {
            state.fetchSuppliers = {
                ...state.fetchSuppliers,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE]: (state) => {
            state.isInitializing = true;
        },
        [INITIALIZE_FAILURE]: (state) => {
            state.isInitializing = false;
        },
        [INITIALIZE_SUCCESS]: (state) => {
            state.isInitializing = false;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [INITIALIZE_MARGIN_FORM]: (state, action) => {
            state.initialMarginValues = {
                ...state.initialMarginValues,
                ...action.payload,
            };
        },
        [OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
        [OPEN_MODAL_MARGIN]: (state, action) => {
            state.isOpenModalMargin = action.payload;
        },
        [OPEN_MODAL_PRODUCT]: (state, action) => {
            state.isOpenModalProduct = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.saveItem = INITIAL_STATE.saveItem;
        },
        [RESET_MARGIN_FORM]: (state) => {
            state.initialMarginValues = INITIAL_STATE.initialMarginValues;
        },
        [SAVE_BULK_MARGIN]: (state) => {
            state.saveMargin = {
                ...state.saveMargin,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_BULK_MARGIN_FAILURE]: (state, action) => {
            state.saveMargin = {
                ...state.saveMargin,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_BULK_MARGIN_SUCCESS]: (state) => {
            state.saveMargin = {
                ...state.saveMargin,
                isSaving: false,
            };
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state) => {
            state.saveItem = {
                ...state.saveItem,
                isSaving: false,
            };
        },
        [SET_IS_DOWNLOADING]: (state, action) => {
            state.isDownloading = action.payload;
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [SET_IS_UPLOADING]: (state, action) => {
            state.isUploading = action.payload;
        },
        [UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.fetchItems.data.data = action.payload;
        },
    },
});

export const panelConfigurationActions = panelConfigurationSlice.actions;

export default panelConfigurationSlice.reducer;
