import i18next from 'i18next';
import isNumber from 'lodash/isNumber';

import { DAYS_IN_WEEK, HOURS_IN_DAY } from 'common/constants';
import { parseDate } from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import { normalizeCsvDataToHourlyArray } from 'common/utils/helpers/csv';
import {
    createFilledArray,
    getChartCategories,
    getDataProfileFormatted,
    getDaysByWeek,
} from 'common/utils/helpersChart';

const MONTHS_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const getChartOptions = (getCountryCurrencyLocale, month, type, year) => ({
    chart: {
        background: '#ffffff00',
        id: 'chart-self-consumption',
        toolbar: { show: false },
        zoom: { enabled: false },
    },
    colors: ['#45AAFF'],
    dataLabels: { enabled: false },
    fill: { type: 'solid' },
    stroke: { width: 0 },
    tooltip: {
        y: {
            formatter: (val) =>
                numberFormat(val, {
                    locale: getCountryCurrencyLocale(),
                    style: 'decimal',
                }),
        },
    },
    xaxis: {
        categories: getChartCategories(type, month, year),
        labels: { offsetY: 1, rotate: -90 },
    },
    yaxis: {
        floating: false,
        labels: {
            formatter: (val) =>
                numberFormat(val, {
                    locale: getCountryCurrencyLocale(),
                    style: 'decimal',
                }),
        },
        min: 0,
        tickAmount: 4,
        title: { text: type === 3 ? '%kW' : '%kWh' },
    },
});

const getChartSeries = (consumptionProfile, days, month, type, weeks) => {
    if (!consumptionProfile) return [];
    const isAnnualType = month === 0;
    switch (type) {
        case 0:
            return [
                { data: getDataYearlyChart(consumptionProfile), name: '%kWh' },
            ];
        case 1:
            return [
                {
                    data: getDataMonthlyChart(consumptionProfile, month),
                    name: '%kWh',
                },
            ];
        case 2:
            return [
                {
                    data: getWeeklyData(
                        consumptionProfile,
                        isAnnualType ? MONTHS_ARRAY : [month],
                        weeks,
                    ),
                    name: '%kWh',
                },
            ];
        case 3:
            return [
                {
                    data: getDailyData(
                        consumptionProfile,
                        isAnnualType ? MONTHS_ARRAY : [month],
                        days,
                        weeks,
                    ),
                    name: '%kW',
                },
            ];
    }
};

const getDailyData = (data, months, days, weeks) => {
    const daysInMonth = getDaysByWeek(weeks);
    const newValues = createFilledArray(HOURS_IN_DAY, 0);

    const multiplier = months.length * days.length * weeks.length;

    for (const month of months) {
        for (const day of daysInMonth) {
            if (!days.includes(data[month]?.[day]?.day)) continue;

            for (let hour = 0; hour < HOURS_IN_DAY; hour++)
                newValues[hour] += data[month]?.[day]?.[hour] / multiplier || 0;
        }
    }

    return newValues;
};

const getDataMonthlyChart = (data, monthSelected) => {
    const monthlyData = [];
    const monthData = data[monthSelected];
    for (const day in monthData) {
        if (!isNumber(monthData[day]?.total)) continue;
        monthlyData.push(monthData[day].total);
    }
    return monthlyData;
};

const getDataYearlyChart = (data) =>
    Object.values(data).map((month) => month.total);

const getWeeklyData = (data, months, weeks) => {
    const daysInMonth = getDaysByWeek(weeks);
    const multiplier = weeks.length * months.length;
    const newValues = createFilledArray(DAYS_IN_WEEK, 0);

    for (const month of months) {
        for (const day of daysInMonth) {
            if (!data[month]?.[day]) continue;

            const dayType = data[month][day].day;

            newValues[dayType === 0 ? 6 : dayType - 1] +=
                data[month][day]?.total / multiplier || 0;
        }
    }
    return newValues;
};

export const getDataFiltered = ({ data, isArchived, name, type }) =>
    data.filter(
        (item) =>
            item.name.toUpperCase().includes(name.toUpperCase()) &&
            (item.usage_type.toString() === type || type === 'all') &&
            item.is_archived === isArchived,
    );

export const getChartConfig = ({
    consumptionProfile,
    days,
    getCountryCurrencyLocale,
    month,
    type,
    weeks,
    year,
}) => ({
    options: getChartOptions(getCountryCurrencyLocale, month, type, year),
    series: getChartSeries(consumptionProfile, days, month, type, weeks),
});

/** CSV Importer */
export const validateCsvUploadColumn = (value) => {
    const parsedValue = Number(value);

    if ((!parsedValue && parsedValue !== 0) || parsedValue < 0)
        return {
            success: false,
            message: i18next.t('Minimum valid value is 0'),
        };

    return { success: true };
};

export const createConsumptionProfile = (csvData, config) => {
    if (!csvData || !config) return;

    const { columnsFormat } = config;

    let format = columnsFormat?.date || 'dd/MM/yyyy';
    const hasTimeColumn = !format.includes('HH') && columnsFormat?.time;

    if (hasTimeColumn) format = `${format} ${columnsFormat.time}`;

    let firstDate = csvData[0].date;

    if (hasTimeColumn) firstDate = `${firstDate} ${csvData[0].time}`;

    const parsedDate = parseDate(firstDate, format);

    const year = parsedDate.getFullYear() || 2021;

    const { resultData, totals } = normalizeCsvDataToHourlyArray({
        columnsFormat,
        columnsToFill: [
            { name: 'consumption', returnTotal: true, resolution: 'sum' },
        ],
        generateDate: false,
        initialDateUTC: Date.UTC(year, 0, 1, 0, 0, 0, 0),
        rowsData: csvData,
    });

    const totalConsumption = totals?.consumption / 100 || 0;

    const profile = resultData.map((row) => row.consumption / totalConsumption);
    const profileFormatted = getDataProfileFormatted(profile, year);

    return { name: config?.file?.name || '', profile, profileFormatted, year };
};
