import { generatePreview } from 'common/api/v1/financialProposals';

import { FETCH_FINANCING_TEMPLATE } from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

import fetchFinancingTemplate from './fetchFinancingTemplate';

export default ({ financingTemplateId, initTemplate, proposalId }) =>
    (dispatch) => {
        dispatch(multiTemplateReviewActions[FETCH_FINANCING_TEMPLATE]());

        generatePreview(proposalId)
            .then(() =>
                dispatch(
                    fetchFinancingTemplate(financingTemplateId, initTemplate),
                ),
            )
            .catch(() => {});
    };
