import {
    createProjectInfo,
    updateProjectInfo,
} from 'common/api/afterSales/projects';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { transformSunwiseDateToAfterSalesFormat } from '../../afterSalesConsumptionHistory/helpers';
import {
    SAVE_DATA,
    SAVE_DATA_FAILURE,
    SAVE_DATA_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import prepareAddApiSource from './prepareAddApiSource';

const locationProperties = ['address', 'latitude', 'longitude', 'time_zone'];

export default ({ successCallback, sunwiseProjectId, values }) =>
    (dispatch) => {
        if (!values || !sunwiseProjectId) return;

        dispatch(actions[SAVE_DATA]());

        let newValues = {};

        locationProperties.forEach((property) => {
            if (values[property]) newValues[property] = values[property];
        });

        if (values.start_date)
            newValues.start_date = transformSunwiseDateToAfterSalesFormat(
                values.start_date,
            );

        const saveAction = values.id ? updateProjectInfo : createProjectInfo;

        saveAction(sunwiseProjectId, newValues)
            .then((response) => {
                const data = response?.data;
                dispatch(actions[SAVE_DATA_SUCCESS](data));
                showToast();

                if (successCallback) successCallback();
                if (!values?.id)
                    dispatch(prepareAddApiSource(sunwiseProjectId, data?.id));
            })
            .catch((error) => {
                dispatch(
                    actions[SAVE_DATA_FAILURE](error?.response?.data?.errors),
                );
                showFastApiErrorsAsAlert(dispatch, error?.response);
            });
    };
