import React from 'react';

import { TableCell } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';

import { getContrastingTextColor } from 'common/utils/helpers';

const stickyStyle = {
    left: 0,
    position: 'sticky',
    zIndex: 999,
};

const HeaderCell = ({
    align = 'center',
    bgColor,
    colSpan,
    isCollapsible,
    isExpanded,
    isSticky,
    label,
    onClick,
}) => (
    <TableCell
        align={align}
        colSpan={colSpan}
        data-testid={`project-consumptions-modal-header-cell-${label?.toLowerCase()}`}
        key={label}
        onClick={() => isCollapsible && onClick()}
        sx={{
            ...(isSticky && stickyStyle),
            background: bgColor,
            color: bgColor && getContrastingTextColor(bgColor),
            cursor: isCollapsible ? 'pointer' : 'default',
            p: 1,
        }}
    >
        {label}

        {isCollapsible && (
            <span
                style={{ float: 'right', fontSize: '10px', marginRight: '4px' }}
            >
                {isExpanded ? '▶◀' : '◀▶'}
            </span>
        )}
    </TableCell>
);

HeaderCell.propTypes = {
    align: PropTypes.string,
    bgColor: PropTypes.string,
    colSpan: PropTypes.number,
    isCollapsible: PropTypes.bool,
    isExpanded: PropTypes.bool,
    isSticky: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
};

export default HeaderCell;
