import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

import { addDefaultSrc } from 'common/utils/helpers';

import StyledLogo from './StyledLogo';
import WrapperImage from './WrapperImage';

const StyledBox = styled(Box)`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    ${({ disabled }) =>
        disabled && 'opacity: 0.5; pointer-events: none; cursor: default;'}
`;

const ProductItem = ({ disabled, name, onClick, srcImg, custom = null }) => {
    return (
        <StyledBox
            disabled={disabled}
            onClick={!disabled ? onClick : () => {}}
            alignItems="center"
        >
            <WrapperImage
                sx={{
                    width: {
                        xs: '90px',
                        md: '100px',
                        lg: '120px',
                        xl: '150px',
                    },
                    height: {
                        xs: '90px',
                        md: '100px',
                        lg: '120px',
                        xl: '150px',
                    },
                }}
            >
                {srcImg && (
                    <StyledLogo
                        onError={(e) => addDefaultSrc(e, 680)}
                        src={srcImg}
                    />
                )}
                {custom}
            </WrapperImage>
            <Typography
                variant="body2"
                fontWeight="bold"
                textAlign="center"
                mt={1}
            >
                {name}
            </Typography>
        </StyledBox>
    );
};

ProductItem.propTypes = {
    custom: PropTypes.object,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
    srcImg: PropTypes.string,
};

export default ProductItem;
