export const DEFAULT_NO_PERMISSIONS = {
    canDelete: false,
    canModify: false,
    canView: false,
    getPermissionsByCode: () => ({
        canDelete: false,
        canModify: false,
        canView: false,
    }),
};

export const PERMISSION_LEVELS = {
    NONE: 'none',
    VIEW: 'view',
    MODIFY: 'modify',
    ALL: 'all',
};

export const PERMISSION_LIST = {
    ROLES_PERMISSION: 1,
    GENERAL_COMPANY_INFO_PERMISSION: 2,
    COMPANY_USERS_PERMISSION: 3,
    PANELS_PERMISSION: 4,
    INVERTERS_PERMISSION: 5,
    ACCESORIES_PERMISSION: 6,
    STRUCTURES_PERMISSION: 7,
    ADITIONALS_PERMISSION: 8,
    WORKFORCE_PERMISSION: 9,
    SOLAR_GENERATION_PERMISSION: 10,
    PROPOSAL_TEMPLATES_PERMISSION: 11,
    BRANCH_OFFICES_PERMISSION: 12,
    SUBSCRIPTION_PERMISSION: 13,
    PLANS_PERMISSION: 23,
    CONTACTS_PERMISSION: 24,
    DASHBOARD_PERMISSION: 25,
    REPORTS_PERMISSION: 26,
    PROPOSAL_PANELS_PERMISSION: 27,
    PROPOSAL_INVERTERS_PERMISSION: 28,
    PROPOSAL_ACCESORIES_PERMISSION: 29,
    PROPOSAL_STRUCTURES_PERMISSION: 30,
    PROPOSAL_WORKFORCE_PERMISSION: 31,
    PROPOSAL_ADITIONALS_PERMISSION: 32,
    PROPOSAL_SOLAR_GENERATION_PERMISSION: 33,
    PROPOSAL_PLAN_PERMISSION: 34,
    PROPOSAL_CURRENCY_TYPE_PERMISSION: 36,
    PROPOSAL_TAXES_PERMISSION: 37,
    PROPOSAL_EXCHANGE_TYPE_PERMISSION: 38,
    PROPOSAL_ROI_TAX_INCENTIVE_PERMISSION: 39,
    PROPOSAL_ROI_SOLAR_DEGRADATION_PERMISSION: 41,
    PROPOSAL_RATES_DAP_PERMISSION: 42,
    PROPOSAL_DISCOUNTS_PERMISSION: 43,
    PROPOSAL_PRICE_PER_WATT_PERMISSION: 44,
    EQUIPMENT_COMPATIBILITY_PERMISSION: 46,
    CERTIFIED_RATES_PERMISSION: 47,
    CONTACT_PROJECTS_PERMISSION: 50,
    CONTACT_PROPOSALS_PERMISSION: 51,
    PANEL_LAYOUT_PERMISSION: 52,
    ALLIANCES_PERMISSION: 53,
    FINANCIAL_PRODUCTS_PERMISSION: 54,
    CONTRACTS_PERMISSION: 55,
    FINANCING_PERMISSION: 56,
    PROPOSAL_ALLIANCE_PRODUCT_PERMISSION: 57,
    CONTACT_LOCATION_PERMISSION: 58,
    SUPPORT_CONTACTS_PERMISSION: 59,
    PERSONAL_DOCUMENTS_PERMISSION: 60,
    TRACKING_CONTACTS_PERMISSION: 61,
    PROJECT_LOCATION_PERMISSION: 62,
    COMMERCIAL_OFFER_DOCUMENTS_PERMISSION: 63,
    RATES_ANNUAL_CFE_PERMISSION: 64,
    PROPOSAL_IVA_CFE_PERMISSION: 65,
    PROPOSAL_STOCK_EXCHANGE_PERMISSION: 66,
    EDIT_PROPOSAL_PREVIEW_PERMISSION: 67,
    REQUEST_ALLIANCE_PRODUCT_FOR_PROPOSAL_PERMISSION: 69,
    LIST_SETTINGS_PERMISSION: 70,
    PROPOSAL_SUBTOTAL_PERMISSION: 71,
    EMBEDDED_PROPOSAL_PERMISSION: 72,
    TAXES_PERMISSION: 75,
    CURRENCIES_PERMISSION: 76,
    SUMMARY_TEMPLATES_PERMISSION: 77,
    PROPOSAL_SUMMARIES_PERMISSION: 78,
    PROPOSAL_SOLAR_GENERATION_UPLOAD_FILE_PERMISSION: 82,
    INTERACTIVE_PROJECT_PERMISSION: 83,
    TIMELINE_PERMISSION: 84,
    ENERGY_BACKUP_PERMISSION: 85,
    PROPOSAL_ENERGY_BACKUP_PERMISSION: 86,
    PROPOSAL_SOLAR_CONSUMPTION_PERMISSION: 87,
    PROPOSAL_PRODUCTS_MARGIN_PERMISSION: 88,
    PROPOSAL_PRODUCTS_COST_PERMISSION: 89,
    PROPOSAL_PRODUCTS_DISCOUNT_PERMISSION: 90,
    PROPOSAL_TIMESHIFTING_PERMISSION: 91,
    SELFCONSUMPTION_ADMIN_PROFILES_PERMISSION: 92,
    ANALYTICS_PERMISSION: 94,
    BRANDING_PERMISSION: 95,
    FUNNEL_PROJECTS_PERMISSION: 96,
    FUNNEL_FINANCING_PERMISSION: 97,
    ZAPIER_PERMISSION: 98,
    CONSUMPTION_HISTORY_PERMISSION: 99,
    AFTER_SALES_PERMISSION: 100,
    MONITORING_PERMISSION: 101,
    MONITORING_DASHBOARD_PERMISSION: 102,
    CRE_PERMISSION: 103,
    CFE_PERMISSION: 104,
    SMART_DOCUMENT_TEMPLATES_PERMISSION: 105,
    REPORT_TEMPLATES_PERMISSION: 106,
    SUPPLIERS_PERMISSION: 107,
    SEE_UNIT_PRICE: 108,
    SEE_SUBTOTAL_PRODUCTS: 109,
    SEE_TOTAL_PRODUCTS: 110,
    SEE_FINAL_BREAKDOWN: 111,
};

export const ROLE_LEVELS = {
    OWNER: 'owner',
    GENERAL_MANAGER: 'general_manager',
    CUSTOMIZED: 'customized',
    BRANCH_MANAGER: 'branch_manager',
    SALES_AGENT: 'sales_agent',
};
