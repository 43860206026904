import { getRateDivisions } from 'common/api/v1/catalogs';

import {
    FETCH_DIVISIONS,
    FETCH_DIVISIONS_SUCCESS,
    FETCH_DIVISIONS_FAILURE,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

export default (id, selectedRateDivision = null, setValue) =>
    (dispatch) => {
        dispatch(projectCommOfferTableActions[FETCH_DIVISIONS]());

        getRateDivisions({ political_division: id })
            .then((response) => {
                dispatch(
                    projectCommOfferTableActions[FETCH_DIVISIONS_SUCCESS](
                        response.data.data,
                    ),
                );
                if (response.data.data.length > 0) {
                    const selected =
                        selectedRateDivision === null
                            ? response.data.data[0].id
                            : selectedRateDivision;

                    setValue('rate_division', selected);
                }
            })
            .catch((error) =>
                dispatch(
                    projectCommOfferTableActions[FETCH_DIVISIONS_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            );
    };
