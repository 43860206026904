import { updateItem } from 'common/api/v1/workforceAndElectricMaterial';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { workforceConfigurationActions } from '../reducer';
import * as selectors from '../selectors';

export default (values) => (dispatch, getState) => {
    const state = getState();
    const data = selectors.getFetchItemsData(state);

    if (!values.id) return;

    const newValues = {
        brand_name: values.brand_name,
        code: values.code,
        name: values.name,
        range_prices: JSON.stringify(values.range_prices),
    };

    dispatch(workforceConfigurationActions[SAVE_ITEM]());

    updateItem(newValues, values.id)
        .then(() => {
            dispatch(workforceConfigurationActions[SAVE_ITEM_SUCCESS]());
            const newItems = data.map((item) => {
                if (item.id === values.id) {
                    return values;
                }
                return item;
            });
            dispatch(
                workforceConfigurationActions[UPDATE_ITEMS_SUCCESS](newItems),
            );
            showToast();
        })
        .catch((error) => {
            dispatch(
                workforceConfigurationActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
