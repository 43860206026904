export const MAX_CHARACTERS = 250;
export const NAME = 'proposals';
export const COLUMNS_CONFIG = {
    name: { label: 'Name', is_active: true, required: true },
    panels_counter: {
        label: 'Panel_plural',
        is_active: true,
        required: false,
    },
    system_size: {
        append: ' (kW)',
        is_active: true,
        label: 'System',
        required: false,
    },
    storage_cap_max: {
        append: ' (kWh)',
        is_active: false,
        label: 'Storage',
        required: false,
    },
    service_number: {
        label: 'Service number',
        is_active: false,
        required: false,
    },
    project: {
        label: 'Project',
        is_active: true,
        required: false,
    },
    is_financing: {
        label: 'Financing',
        is_active: true,
        required: false,
    },
    contact: {
        label: 'Contact',
        is_active: true,
        required: false,
    },
    branch_office: {
        label: 'Branch office',
        is_active: true,
        required: false,
    },
    created_by: {
        label: 'User',
        is_active: true,
        required: false,
    },
    rate_name: {
        label: 'Rate',
        is_active: true,
        required: false,
    },
    date: { label: 'Date', is_active: true, required: false },
};
export const SYSTEM_RANGES = {
    0: { system_size_start: 0, system_size_end: 5 },
    1: { system_size_start: 5, system_size_end: 10 },
    2: { system_size_start: 10, system_size_end: 30 },
    3: { system_size_start: 30, system_size_end: 50 },
    4: { system_size_start: 50, system_size_end: 100 },
    5: { system_size_start: 100, system_size_end: 250 },
    6: { system_size_start: 250, system_size_end: 500 },
    7: { sytem_size_gt: 500 },
};
export const STORAGE_RANGES = {
    0: { storage_start: 0, storage_end: 5 },
    1: { storage_start: 5, storage_end: 10 },
    2: { storage_start: 10, storage_end: 30 },
    3: { storage_start: 30, storage_end: 50 },
    4: { storage_start: 50, storage_end: 100 },
    5: { storage_start: 100, storage_end: 250 },
    6: { storage_start: 250, storage_end: 500 },
    7: { storage_gt: 500 },
};
