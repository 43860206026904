import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import ZapFullLogo from 'resources/zap_full_logo_beta.svg';

import { ZAP_COLOR } from '../constants';

const getImageWidth = (breakpoint) => {
    if (breakpoint === 'xs') return '200px';
    return '250px';
};

const AccessRestrictedBanner = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    return (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="center"
            textAlign="center"
        >
            <img
                src={ZapFullLogo}
                alt="Zap"
                style={{
                    height: 'auto',
                    maxHeight: '200px',
                    maxWidth: getImageWidth(breakpoint),
                    width: '100%',
                }}
            />

            <Typography variant="h5" maxWidth="600px">
                {t('The easiest way to download and manage your')}{' '}
                <span style={{ color: ZAP_COLOR }}>
                    {t('CFE receipts').toLocaleLowerCase()}
                </span>
            </Typography>

            <Typography variant="caption">
                {t(
                    'Get your history of electricity bills and organize all the information about your electricity consumption',
                )}
            </Typography>

            <Button
                color="secondary"
                component="a"
                href={`${import.meta.env.VITE_ZAP_API_URL}/create-account`}
                sx={{ m: '0 !important' }}
                target="__blank"
            >
                {t('Sign up')}
            </Button>

            <Typography
                fontWeight="bold"
                sx={{ a: { color: ZAP_COLOR } }}
                variant="body2"
            >
                {t('Already have an account?')}{' '}
                <Link to="/suppliers">{t('Link account')}</Link>
            </Typography>
        </Box>
    );
};

export default AccessRestrictedBanner;
