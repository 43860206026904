import React from 'react';

import PropTypes from 'prop-types';
import { Card, Skeleton } from 'sunwise-ui';

const Placeholder = () => (
    <Card>
        <Card.Body>
            <Skeleton variant="rounded" />
        </Card.Body>
    </Card>
);

const PlaceholderCardListItem = ({ children, ready }) => {
    if (ready) return children;
    return <Placeholder />;
};

PlaceholderCardListItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    ready: PropTypes.bool,
};

export default PlaceholderCardListItem;
