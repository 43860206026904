import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'customers-energies-consumptions';

export const create = (data, projectUUID) =>
    API.post(`/api/v2/${ENTITY}/${projectUUID}`, data, config);
export const getConsumptionEnergy = (projectUUID) =>
    API.get(`/api/v2/${ENTITY}/${projectUUID}`);
export const update = (data, projectUUID) =>
    API.put(`/api/v2/${ENTITY}/${projectUUID}`, data, config);
export const uploadCsv = (data) =>
    API.post(`/api/v2/${ENTITY}-csv-cleaner/`, data);
