import { NAME } from './constants';

export const ACTIVATE_ITEM = `${NAME}/ACTIVATE_ITEM`;
export const ACTIVATE_ITEM_FAILURE = `${NAME}/ACTIVATE_ITEM_FAILURE`;
export const ACTIVATE_ITEM_SUCCESS = `${NAME}/ACTIVATE_ITEM_SUCCESS`;

export const CHANGE_SEARCH_TEXT = `${NAME}/CHANGE_SEARCH_TEXT`;
export const CHANGE_SEARCH_TEXT_PARENT = `${NAME}/CHANGE_SEARCH_TEXT_PARENT`;

export const FETCH_ITEMS = `${NAME}/FETCH_ITEMS`;
export const FETCH_ITEMS_FAILURE = `${NAME}/FETCH_ITEMS_FAILURE`;
export const FETCH_ITEMS_SUCCESS = `${NAME}/FETCH_ITEMS_SUCCESS`;

export const FETCH_ITEMS_PARENT = `${NAME}/FETCH_ITEMS_PARENT`;
export const FETCH_ITEMS_PARENT_FAILURE = `${NAME}/FETCH_ITEMS_PARENT_FAILURE`;
export const FETCH_ITEMS_PARENT_SUCCESS = `${NAME}/FETCH_ITEMS_PARENT_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const OPEN_MODAL = `${NAME}/OPEN_MODAL`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SELECT_ITEM = `${NAME}/SELECT_ITEM`;

export const SELECT_PARENT = `${NAME}/SELECT_PARENT`;
export const SELECT_PARENT_FAILURE = `${NAME}/SELECT_PARENT_FAILURE`;
export const SELECT_PARENT_SUCCESS = `${NAME}/SELECT_PARENT_SUCCESS`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;

export const UNSELECT_PARENT = `${NAME}/UNSELECT_PARENT`;
export const UNSELECT_PARENT_FAILURE = `${NAME}/UNSELECT_PARENT_FAILURE`;
export const UNSELECT_PARENT_SUCCESS = `${NAME}/UNSELECT_PARENT_SUCCESS`;
