import get from 'lodash/get';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getFetch = createSelector(getModel, (model) => model.fetch);

export const getIsFetching = createSelector(
    getFetch,
    (fetch) => fetch.isFetching,
);

export const getDataFetch = createSelector(
    getFetch,
    (fetch) => fetch.data || {},
);

export const getUserId = createSelector(getDataFetch, (data) => data.id || {});

export const getSubscription = createSelector(
    getDataFetch,
    (data) => data.subscription || {},
);

export const getCountryCurrencyFromProfile = createSelector(
    getDataFetch,
    (data) => get(data, 'currency_company_locale', {}),
);

export const getErrorsFetch = createSelector(getFetch, (fetch) => fetch.errors);

export const getBranding = createSelector(getModel, (model) => model.branding);

export const getCurrentValues = createSelector(
    getModel,
    (model) => model.currentValues,
);

export const getTheme = createSelector(getModel, (model) => model.theme);

export const getUserCompany = createSelector(
    getDataFetch,
    (data) => data?.company,
);

export const getUserCompanyCountry = createSelector(
    getUserCompany,
    (company) => company?.country_name,
);

export const getUserCompanyId = createSelector(
    getUserCompany,
    (company) => company?.id,
);

export const getPreferences = createSelector(
    getModel,
    (model) => model.preferences,
);
