import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const {
        EMAIL_VALIDATION_TEXT,
        getNumberDigitsText,
        REQUIRED_TEXT,
        TAX_ID_VALIDATION_TEXT,
    } = getValidationTexts();

    return object().shape({
        first_name: string().required(REQUIRED_TEXT).nullable(),
        first_last_name: string().required(REQUIRED_TEXT).nullable(),
        second_last_name: string().required(REQUIRED_TEXT).nullable(),
        rfc: string()
            .required(REQUIRED_TEXT)
            .length(13, getNumberDigitsText(13))
            .matches(
                /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/,
                TAX_ID_VALIDATION_TEXT,
            )
            .nullable(),
        email: string()
            .email(EMAIL_VALIDATION_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        street: string().required(REQUIRED_TEXT).nullable(),
        suburb: string().required(REQUIRED_TEXT).nullable(),
        city: string().required(REQUIRED_TEXT).nullable(),
        state: string().required(REQUIRED_TEXT).nullable(),
        zipcode: string().required(REQUIRED_TEXT).nullable(),
    });
};
