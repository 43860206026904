import i18next from 'i18next';
import get from 'lodash/get';

import { createSegment } from 'common/api/v1/segmentsLayout';
import {
    getPolygonCoordinates,
    getNewSolarModules,
    showReponseErrorsAsAlert,
} from 'common/utils/helpers';

import {
    CREATE_SEGMENT,
    CREATE_SEGMENT_FAILURE,
    CREATE_SEGMENT_SUCCESS,
    UPDATE_SOLAR_MODULES_DESIGN_NUMBER,
} from '../actionTypes';
import { POLYGON_MAP } from '../constants';
import { panelLayoutV2Actions } from '../reducer';
import * as selectors from '../selectors';
import { movePoint } from '../solarGeometryhelpers';

import fetchOfferPanel from './fetchOfferPanel';
import updateSegments from './updateSegments';

export default (config) => (dispatch, getState) => {
    const { commercialOfferId } = config;

    const state = getState();
    const copyBuffer = selectors.getCopyBuffer(state);
    const segments = selectors.getSegmentsData(state);

    let tmpSegments = [...segments];

    dispatch(panelLayoutV2Actions[CREATE_SEGMENT]());

    for (let i = 0; i < copyBuffer.length; i++) {
        const parentFieldSegment = selectors.getParentFieldSegmentData(state);
        const data = { ...copyBuffer[i] };
        const { name, polygon, parent_field_segment, type } = data;
        let solar_modules = [];

        const translatedPolygon = polygon.map((point) =>
            movePoint({ lnglat: [point[1], point[0]] }),
        );

        if (data.solar_modules) {
            let tmp_solarModules = data.solar_modules;

            if (typeof tmp_solarModules !== 'object') {
                tmp_solarModules = JSON.parse(tmp_solarModules);
            }

            solar_modules = tmp_solarModules.map((module) => ({
                cell: module.cell,
                col: module.col,
                group: module.group,
                is_enable: module.enabled,
                row: module.row,
                solar_module_points: JSON.stringify(
                    module.path.map((point) => {
                        const newPoint = movePoint({
                            lnglat: [point[1], point[0]],
                        });
                        return {
                            x: newPoint[1],
                            y: newPoint[0],
                        };
                    }),
                ),
            }));
        }

        const fieldSegments = [
            {
                name: `${i18next.t('Copy of')} ${name}`,
                polygon: translatedPolygon.map((point) => ({
                    x_coordinate: point[1],
                    y_coordinate: point[0],
                })),
                solar_modules,
            },
        ];

        let newData = {
            ...data,
            commercial_offer: commercialOfferId,
            field_segments: fieldSegments,
            id: null,
            name: `${i18next.t('Copy of')} ${name}`,
            open: false,
            polygon: JSON.stringify(translatedPolygon),
            solar_modules: null,
        };

        if (type === POLYGON_MAP) {
            const { image, opacity, rotate } = data;
            newData.image = image;
            newData.opacity = opacity * 100;
            newData.rotate = rotate;
        }

        createSegment(newData)
            .then((response) => {
                const segmentData = get(response, 'data.data', {});
                const {
                    field_segment_points,
                    id: segmentId,
                    solar_modules_number,
                    panel_modules,
                } = segmentData;
                const newPolygon = getPolygonCoordinates(field_segment_points);
                const newSegment = {
                    ...newData,
                    id: segmentId,
                    parent_field_segment,
                    polygon: newPolygon,
                    prev_location: newPolygon,
                    solar_modules: getNewSolarModules(panel_modules),
                    solar_modules_number,
                };

                if (data.type === POLYGON_MAP) {
                    newSegment.image = data.image;
                    newSegment.opacity = data.opacity;
                    newSegment.rotate = data.rotate;
                }

                const newValuesSegments = [...tmpSegments, newSegment];

                dispatch(updateSegments(newValuesSegments));

                dispatch(
                    panelLayoutV2Actions[UPDATE_SOLAR_MODULES_DESIGN_NUMBER](
                        parentFieldSegment.solarModulesDesignNumber +
                            solar_modules_number,
                    ),
                );

                tmpSegments = [...newValuesSegments];
            })
            .catch((error) => {
                dispatch(
                    panelLayoutV2Actions[CREATE_SEGMENT_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error.response);
            })
            .finally(() => {
                dispatch(fetchOfferPanel(commercialOfferId));
            });
    }

    dispatch(panelLayoutV2Actions[CREATE_SEGMENT_SUCCESS]());
};
