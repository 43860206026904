import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '&.StripedRow': {
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgb(47, 51, 53)' : '#eef4fa',
    },
    borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
    padding: '8px',
}));
