import { VALID_PASSWORD, INVALID_PASSWORD } from '../actionTypes';
import { companyUsersActions } from '../reducer';

export default (e) => (dispatch) => {
    const password = e.target.value;

    if (password.length >= 8) {
        dispatch(companyUsersActions[VALID_PASSWORD]());
    } else {
        dispatch(companyUsersActions[INVALID_PASSWORD]());
    }
};
