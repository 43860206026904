import {
    deleteParentFieldSegments,
    createParentFieldSegments,
} from 'common/api/v1/parentFieldSegment';
import { updateOferDetails } from 'common/api/v2/offerDetails';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { proposalGeneratorPanelsLayoutActions } from '../reducer';

export default (
        values,
        successCallback = () => {},
        failureCallback = () => {},
    ) =>
    (dispatch) => {
        dispatch(proposalGeneratorPanelsLayoutActions[SAVE]());

        const { proposalId } = values;
        const newValues = {
            description: values.title,
            latitude: values.position.latitude,
            longitude: values.position.longitude,
        };

        deleteParentFieldSegments(proposalId)
            .then(() => {
                createParentFieldSegments(proposalId, {
                    address: newValues.description,
                    latitude: newValues.latitude,
                    longitude: newValues.longitude,
                }).then((response) => {
                    updateOferDetails(proposalId, {
                        position: JSON.stringify(newValues),
                    }).then(() => {
                        dispatch(
                            proposalGeneratorPanelsLayoutActions[SAVE_SUCCESS](
                                newValues,
                            ),
                        );
                        showToast();
                        successCallback(newValues);
                        dispatch(
                            eventsAfterUpdate(response.data.data, proposalId),
                        );
                    });
                });
            })
            .catch((error) => {
                dispatch(
                    proposalGeneratorPanelsLayoutActions[SAVE_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
                failureCallback();
            });
    };
