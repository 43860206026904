import i18next from 'i18next';

import { updateConfigUser } from 'common/api/v1/users';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { updateSession } from 'common/utils/helpers/session';
import showToast from 'common/utils/showToast';

import {
    UPDATE_CONFIGURATION,
    UPDATE_CONFIGURATION_SUCCESS,
    UPDATE_CONFIGURATION_FAILURE,
} from '../actionTypes';
import { profileConfigurationActions } from '../reducer';

import initializeMultiregionForm from './initializeMultiregionForm';
import resetForm from './resetForm';

export default (values) => (dispatch) => {
    let newValues = {
        language: values.language,
        timezone: values.timezone,
        auto_timezone: values.auto_timezone,
    };

    dispatch(profileConfigurationActions[UPDATE_CONFIGURATION]());

    const message = i18next.t(
        'An error occurred during the update. Please try later',
    );

    updateConfigUser(newValues)
        .then((response) => {
            if (!response.data || !response.data.settings) {
                dispatch(
                    profileConfigurationActions[UPDATE_CONFIGURATION_FAILURE]([
                        message,
                    ]),
                );

                return;
            }

            updateSession({
                settings: response.data.settings,
            });

            dispatch(
                profileConfigurationActions[UPDATE_CONFIGURATION_SUCCESS](
                    response.data.settings,
                ),
            );

            dispatch(resetForm());

            dispatch(
                initializeMultiregionForm({
                    language: response.data.settings.language.key,
                    timezone: response.data.settings.timezone.key,
                    auto_timezone: response.data.settings.auto_timezone,
                }),
            );

            showToast({
                body: i18next.t(
                    'We have detected that your time zone has changed to {{timezone}}',
                    {
                        timezone: response.data.settings.timezone.name,
                    },
                ),
                autoClose: 10000,
            });
        })
        .catch((error) => {
            dispatch(
                profileConfigurationActions[UPDATE_CONFIGURATION_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
