import get from 'lodash/get';

import { getRateDivisions } from 'common/api/v1/catalogs';

import {
    FETCH_DIVISIONS,
    FETCH_DIVISIONS_SUCCESS,
    FETCH_DIVISIONS_FAILURE,
} from '../actionTypes';
import { ratesActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(ratesActions[FETCH_DIVISIONS]());
    getRateDivisions()
        .then(({ data }) =>
            dispatch(ratesActions[FETCH_DIVISIONS_SUCCESS](data.data)),
        )
        .catch((error) =>
            dispatch(
                ratesActions[FETCH_DIVISIONS_FAILURE](
                    get(error, 'response.data.errors', []),
                ),
            ),
        );
};
