import i18next from 'i18next';

import { validateEmail } from 'common/api/v1/onboarding';

import {
    VALIDATE_EMAIL,
    VALIDATE_EMAIL_SUCCESS,
    VALIDATE_EMAIL_FAILURE,
} from '../actionTypes';
import { onboardingWizardActions } from '../reducer';

export default (values, callback) => (dispatch) => {
    dispatch(onboardingWizardActions[VALIDATE_EMAIL]());
    const { email, id, members } = values;

    callback([]);
    validateEmail({ email })
        .then(() => {
            dispatch(onboardingWizardActions[VALIDATE_EMAIL_SUCCESS]());
            const users = members
                .filter((row) => row?.id != id)
                .map((row) => row?.email.toLowerCase());
            const exists = users.includes(email.toLowerCase());
            if (exists) callback([i18next.t('This email is already taken')]);
        })
        .catch((error) => {
            dispatch(
                onboardingWizardActions[VALIDATE_EMAIL_FAILURE](
                    error?.response?.data?.errors || [],
                ),
            );
            callback(error?.response?.data?.errors || []);
        });
};
