import i18next from 'i18next';

import { bulkFinancialPlans } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { financialProductActions } from '../reducer';

import filterProducts from './filterProducts';

export default (ids, value, filterData, callback) => (dispatch) => {
    dispatch(financialProductActions[BULK_UPDATE_ITEMS]());

    const products = ids.map((id) => ({ id, status_flag: value }));

    bulkFinancialPlans({ products })
        .then(() => {
            dispatch(financialProductActions[BULK_UPDATE_ITEMS_SUCCESS]());
            dispatch(filterProducts(filterData));
            if (value === 'deleted')
                showToast({ body: i18next.t('It was successfully removed') });
            showToast();
            callback();
        })
        .catch((error) => {
            dispatch(
                financialProductActions[BULK_UPDATE_ITEMS_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
