import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Menu, Checkbox } from 'sunwise-ui';

import {
    getIsChecked,
    handleOnClickElement,
    handleOnClickAllElements,
    handleUpdateShowDropdown,
} from '../helpers';

import SelectedItem from './SelectedItem';

const DropdownProducts = ({
    companyTaxes,
    disabled,
    handleUpdate,
    productsToSelect,
    setCompanyTaxes,
    setCurrentTax,
    setIsEditing,
    taxIndex,
    taxItem,
}) => {
    const { t } = useTranslation();

    const taxesElements = get(taxItem, 'companies_taxes_elements', []);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsEditing(true);
        setCurrentTax(taxItem);
        handleUpdateShowDropdown(companyTaxes, setCompanyTaxes, taxItem);
        handleUpdate(
            companyTaxes,
            taxIndex,
            'showDropdown',
            !taxItem.showDropdown,
            setCompanyTaxes,
            setCurrentTax,
        );
    };
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <Button
                color="secondary"
                disabled={disabled}
                display="flex"
                endIcon={<KeyboardArrowDownIcon />}
                fullWidth
                onClick={handleClick}
                sx={{ justifyContent: 'space-between', textTransform: 'none' }}
                variant="outlined"
            >
                <SelectedItem
                    productsToSelect={productsToSelect}
                    taxesElements={taxesElements}
                />
            </Button>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() =>
                        handleOnClickAllElements(
                            companyTaxes,
                            handleUpdate,
                            taxIndex,
                            productsToSelect,
                            taxesElements,
                            setCompanyTaxes,
                            setCurrentTax,
                        )
                    }
                >
                    <Checkbox checked={taxesElements.length > 0} />{' '}
                    {t('All text', { context: 'male', count: 2 })}
                </MenuItem>
                {productsToSelect.map((item, index) => {
                    return (
                        <MenuItem
                            key={`dropdown-menuitem-${index}`}
                            onClick={() =>
                                handleOnClickElement(
                                    companyTaxes,
                                    handleUpdate,
                                    taxIndex,
                                    item,
                                    taxesElements,
                                    setCompanyTaxes,
                                    setCurrentTax,
                                )
                            }
                        >
                            <Checkbox
                                id={`dropdown-item-${index}`}
                                checked={getIsChecked(
                                    taxesElements,
                                    item.value,
                                )}
                            />
                            {item.label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

DropdownProducts.propTypes = {
    companyTaxes: PropTypes.array,
    disabled: PropTypes.bool,
    handleUpdate: PropTypes.func,
    productsToSelect: PropTypes.array,
    setCompanyTaxes: PropTypes.func,
    setCurrentTax: PropTypes.func,
    setIsEditing: PropTypes.func,
    taxIndex: PropTypes.number,
    taxItem: PropTypes.object,
};

export default DropdownProducts;
