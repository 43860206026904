import { showFastApiErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_MEASURED_ENERGY,
    FETCH_MEASURED_ENERGY_FAILURE,
    FETCH_MEASURED_ENERGY_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import fetchProjectMeasuredEnergyByDate from './fetchProjectMeasuredEnergyByDate';

export default ({ callback, selectedProjectId, values }) =>
    (dispatch) => {
        dispatch(actions[FETCH_MEASURED_ENERGY]());

        dispatch(fetchProjectMeasuredEnergyByDate(selectedProjectId, values))
            .then((data) => {
                dispatch(actions[FETCH_MEASURED_ENERGY_SUCCESS](data));

                if (callback) callback(data);
            })
            .catch((error) => {
                dispatch(actions[FETCH_MEASURED_ENERGY_FAILURE](error));
                showFastApiErrorsAsAlert(dispatch, error?.response);
                if (callback) callback();
            });
    };
