import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getFetchTimeShiftingConfiguration = createSelector(
    getModel,
    (model) => model.fetchTimeShiftingConfiguration,
);

export const getIsFetchingTimeShiftingConfiguration = createSelector(
    getFetchTimeShiftingConfiguration,
    (value) => value.isFetching,
);

export const getFetchTimeShiftingConfigurationData = createSelector(
    getFetchTimeShiftingConfiguration,
    (value) => value.data,
);

export const getRateTiers = createSelector(
    getFetchTimeShiftingConfigurationData,
    (data) =>
        data?.schedule_rate_configuration?.tiers_energy_distribution?.[0]
            ?.tiers || [],
);

export const getSaveTimeShiftingConfiguration = createSelector(
    getModel,
    (model) => model.saveTimeShiftingConfiguration,
);

export const getIsSavingTimeShiftingConfiguration = createSelector(
    getSaveTimeShiftingConfiguration,
    (value) => value.isSaving,
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);
