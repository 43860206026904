import get from 'lodash/get';

import { getItemsByParent } from 'common/api/v1/politicalDivisions';

import {
    FETCH_POLITICAL_DIVISIONS2,
    FETCH_POLITICAL_DIVISIONS2_FAILURE,
    FETCH_POLITICAL_DIVISIONS2_SUCCESS,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';

import fetchIrradiationByStateAndSources from './fetchIrradiationByStateAndSources';

export default (str, politicalDivision, setValue) => (dispatch) => {
    dispatch(solarGenerationActions[FETCH_POLITICAL_DIVISIONS2]());
    return getItemsByParent(str)
        .then((response) => {
            dispatch(
                solarGenerationActions[FETCH_POLITICAL_DIVISIONS2_SUCCESS](
                    response.data.data,
                ),
            );
            setValue('political_division', str);
            setValue('political_division2', response.data.data[0].id);

            dispatch(
                fetchIrradiationByStateAndSources(
                    response.data.data[0].id,
                    politicalDivision,
                    setValue,
                ),
            );
        })
        .catch((error) =>
            dispatch(
                solarGenerationActions[FETCH_POLITICAL_DIVISIONS2_FAILURE](
                    get(error, 'response.data.errors', []),
                ),
            ),
        );
};
