import i18next from 'i18next';

import { getProjectsCsv } from 'common/api/afterSales/projects';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';

import {
    DOWNLOAD_MONITORING_CSV,
    DOWNLOAD_MONITORING_CSV_FAILURE,
    DOWNLOAD_MONITORING_CSV_SUCCESS,
} from '../actionTypes';
import { getAfterSalesDateRange } from '../helpers';
import { actions } from '../reducer';

export default ({ initial_date, final_date, freq, ...rest } = {}) =>
    (dispatch) => {
        if (!initial_date || !final_date || !freq) return;
        dispatch(actions[DOWNLOAD_MONITORING_CSV]());

        const dateValues = getAfterSalesDateRange({
            final_date,
            freq,
            initial_date,
        });

        getProjectsCsv({ ...dateValues, ...rest })
            .then((response) => {
                const data = response?.data;

                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', `${i18next.t('Monitoring')}.csv`);
                document.body.appendChild(link);
                link.click();

                link.remove();
                window.URL.revokeObjectURL(url);

                dispatch(actions[DOWNLOAD_MONITORING_CSV_SUCCESS](data));
            })
            .catch((error) => {
                dispatch(actions[DOWNLOAD_MONITORING_CSV_FAILURE](error));

                showFastApiErrorsAsAlert(dispatch, error?.response);
            });
    };
