import { getBuildVersion } from 'common/api/v1/version';
import { getIsActiveSunwiseNotification } from 'common/utils/helpers/session';

import {
    FETCH_VERSION,
    FETCH_VERSION_SUCCESS,
    FETCH_VERSION_FAILURE,
} from '../actionTypes';
import { compareVersions } from '../helpers';
import { releaseNotificationActions } from '../reducer';

import setNewVersionAvailable from './setNewVersionAvailable';

const isLocalVersion = import.meta.env.VITE_VERSION === 'local-version';
const getReactVersion = () => {
    if (isLocalVersion) return '0.0.0';
    return import.meta.env.VITE_VERSION;
};

export default () => async (dispatch) => {
    const isActiveSunwiseNotification = getIsActiveSunwiseNotification();

    if (!isActiveSunwiseNotification) return;

    dispatch(releaseNotificationActions[FETCH_VERSION]());

    getBuildVersion()
        .then((response) => {
            const { version } = response.data;
            dispatch(
                releaseNotificationActions[FETCH_VERSION_SUCCESS](
                    response.data,
                ),
            );
            const reactAppVersion = getReactVersion();
            const needToUpgrade = compareVersions(reactAppVersion, version);
            if (!isLocalVersion && needToUpgrade)
                dispatch(setNewVersionAvailable(true));
        })
        .catch((error) => {
            dispatch(releaseNotificationActions[FETCH_VERSION_FAILURE](error));
        });
};
