import API from '../..';

const ENTITY = 'rate-concepts';

export const getRateConcepts = (commercialOffer) =>
    API.get(`/api/v2/${ENTITY}/${commercialOffer}`);

export const saveRateConcepts = (commercialOffer, data) =>
    API.put(`/api/v2/${ENTITY}/${commercialOffer}`, data, {
        headers: { 'Content-Type': 'application/json' },
    });
