import { INITIALIZE, INITIALIZE_SUCCESS } from '../actionTypes';
import { templateRenderActions } from '../reducer';

import fetchCustomTemplate from './fetchCustomTemplate';

export default (id, templateType, initTemplate, token) => async (dispatch) => {
    dispatch(templateRenderActions[INITIALIZE]());

    const templateData = await dispatch(fetchCustomTemplate(id, templateType));
    initTemplate(templateData, id, token);
    dispatch(templateRenderActions[INITIALIZE_SUCCESS]());
};
