import React, { useContext, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import { LoadingContext } from 'common/utils/contexts';

import * as supplierIntegrationsActions from '../supplierIntegrations/actions';

import * as actions from './actions';
import ConsumptionsForm from './components/ConsumptionsForm';
import * as selectors from './selectors';

const Container = ({
    fetchDivisions,
    fetchIntegrations,
    fetchPoliticalDivisions,
    fetchProjectDetails,
    fetchProjects,
    fetchRateDivisions,
    fetchRates,
    fetchRegions,
    fetchSummerMonths,
    initialValues,
    isSaving,
    modalIsOpen,
    resetForm,
    setModalIsOpen,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSaving)
            loadingContext.openLoading(t('Saving consumptions').concat('...'));
        else loadingContext.closeLoading();
    }, [isSaving]);

    const handleOnEnter = () => {
        fetchRates();
        fetchRegions();
        fetchSummerMonths();
        fetchPoliticalDivisions('dp1');
        fetchIntegrations();
        if (initialValues?.political_division1)
            fetchRateDivisions(
                initialValues.political_division1,
                initialValues.rate_division,
            );
        else fetchDivisions();
    };

    return (
        <Dialog
            onClose={() => setModalIsOpen(false)}
            onEnter={handleOnEnter}
            onExited={() => resetForm()}
            open={modalIsOpen}
            size={false}
            title={t('Enter consumption', { count: 2 })}
        >
            <ConsumptionsForm
                fetchProjectDetails={fetchProjectDetails}
                fetchProjects={fetchProjects}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSavingSaveConsumptions,
    modalIsOpen: selectors.getModalIsOpen,
});

const mapDispatchToProps = (dispatch) => ({
    fetchDivisions: () => dispatch(actions.fetchDivisions()),
    fetchIntegrations: () =>
        dispatch(supplierIntegrationsActions.fetchIntegrations()),
    fetchPoliticalDivisions: (str) =>
        dispatch(actions.fetchPoliticalDivisions(str)),
    fetchRateDivisions: (politicalDivision, rateDivision) =>
        dispatch(actions.fetchRateDivisions(politicalDivision, rateDivision)),
    fetchRates: () => dispatch(actions.fetchRates()),
    fetchRegions: () => dispatch(actions.fetchRegions()),
    fetchSummerMonths: () => dispatch(actions.fetchSummerMonths()),
    resetForm: () => dispatch(actions.resetForm()),
    setModalIsOpen: (isOpen) => dispatch(actions.setModalIsOpen(isOpen)),
});

Container.propTypes = {
    fetchDivisions: PropTypes.func,
    fetchIntegrations: PropTypes.func,
    fetchPoliticalDivisions: PropTypes.func,
    fetchProjectDetails: PropTypes.func,
    fetchProjects: PropTypes.func,
    fetchRateDivisions: PropTypes.func,
    fetchRates: PropTypes.func,
    fetchRegions: PropTypes.func,
    fetchSummerMonths: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    modalIsOpen: PropTypes.bool,
    resetForm: PropTypes.func,
    setModalIsOpen: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
