import { generatePreview } from 'common/api/v1/proposals';

import { FETCH_CUSTOM_TEMPLATE } from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

import fetchCustomTemplate from './fetchCustomTemplate';

export default ({ initTemplate, proposalId }) =>
    (dispatch) => {
        dispatch(multiTemplateReviewActions[FETCH_CUSTOM_TEMPLATE]());

        generatePreview({ commercial_offer: proposalId })
            .then(() =>
                dispatch(fetchCustomTemplate(proposalId, initTemplate, 0)),
            )
            .catch(() => {});
    };
