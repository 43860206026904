import React from 'react';

import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import {
    calculateFinalCost,
    calculateMargin,
    getCurrencyIso,
    getLabelWithCurrency,
} from 'common/utils/helpers';

const PriceFields = ({ control, currency, disabled, getValues, setValue }) => {
    const { t } = useTranslation();
    const currencyIso = getCurrencyIso(currency);

    const handleChangeCostValue = () => {
        const value = getValues('cost');
        if (!value) setValue('cost', 0);

        setValue(
            'final_cost',
            calculateFinalCost(
                !isNumber(parseInt(value)) ? 0 : value,
                getValues('margin'),
            ),
        );
    };

    const handleChangeMarginValue = () => {
        let value = getValues('margin');

        if (!value) setValue('margin', 0);
        else if (parseFloat(value) >= 100) {
            setValue('margin', 99.99);
            value = 99.99;
        }

        setValue(
            'final_cost',
            calculateFinalCost(
                getValues('cost'),
                !isNumber(parseInt(value)) ? 0 : value,
            ),
        );
    };

    const handleChangeFinalCostValue = () => {
        let value = getValues('final_cost'),
            costValue = getValues('cost');

        if (!value || parseFloat(value) < parseFloat(costValue)) {
            setValue('final_cost', costValue);
            setValue('margin', 0);
        } else if (parseFloat(costValue) === 0) {
            setValue('margin', 0);
            setValue('cost', value);
        } else if (calculateMargin(value, costValue) >= 100) {
            setValue('margin', 99.99);
            setValue('final_cost', calculateFinalCost(costValue, 99.99));
        } else setValue('margin', calculateMargin(value, costValue));
    };

    return (
        <div>
            <label>{t('Price')}</label>
            <Card>
                <Card.Body>
                    <Grid container>
                        <Grid item xs>
                            <ReactHookFormIntlNumberInput
                                control={control}
                                disabled={disabled}
                                label={getLabelWithCurrency(
                                    currencyIso,
                                    t('Cost'),
                                )}
                                name="cost"
                                onBlur={() => handleChangeCostValue()}
                                prepend="$"
                            />
                        </Grid>
                        <Grid item xs>
                            <ReactHookFormIntlNumberInput
                                append="%"
                                control={control}
                                disabled={disabled}
                                label={t('Margin')}
                                name="margin"
                                onBlur={() => handleChangeMarginValue()}
                            />
                        </Grid>
                        <Grid item xs>
                            <ReactHookFormIntlNumberInput
                                control={control}
                                disabled={disabled}
                                label={getLabelWithCurrency(
                                    currencyIso,
                                    t('Final price'),
                                )}
                                name="final_cost"
                                onBlur={() => handleChangeFinalCostValue()}
                                prepend="$"
                            />
                        </Grid>
                    </Grid>
                </Card.Body>
            </Card>
        </div>
    );
};

PriceFields.propTypes = {
    control: PropTypes.object,
    currency: PropTypes.object,
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    setValue: PropTypes.func,
};

export default PriceFields;
