import { getProjectsSalesStage } from 'common/api/v1/dashboards';

import {
    FETCH_PROJECTS_SALES_STAGE,
    FETCH_PROJECTS_SALES_STAGE_FAILURE,
    FETCH_PROJECTS_SALES_STAGE_SUCCESS,
} from '../actionTypes';
import { dashboardActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(dashboardActions[FETCH_PROJECTS_SALES_STAGE]());

    getProjectsSalesStage()
        .then((response) =>
            dispatch(
                dashboardActions[FETCH_PROJECTS_SALES_STAGE_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                dashboardActions[FETCH_PROJECTS_SALES_STAGE_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
