import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const IconStoragePrice = ({ color, height = '24px', width = '24px' }) => {
    const theme = useTheme();
    const fillColor = color || theme.palette.primary.main;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 19V6C7 5 7 4 9 4H10C9.83333 3.33333 9.8 2 11 2H13C13.5 2 14.4 2.4 14 4H15.5C16.5 4 17 4.5 17 6V19C17 19.1144 17.0003 19.2255 17.0006 19.3333C17.0059 21.1111 17.0085 22 15.5 22H9C8.13434 22 7.64336 22 7.36489 21.7567C7 21.438 7 20.7015 7 19ZM10.38 11.45C10.38 12.02 10.818 12.386 12.18 12.74C13.542 13.094 15 13.676 15 15.38C15 16.61 14.07 17.288 12.9 17.51V17.9C12.9 18.398 12.498 18.8 12 18.8C11.502 18.8 11.1 18.398 11.1 17.9V17.498C10.2 17.306 9.402 16.814 9.06 15.98C8.91 15.608 9.192 15.2 9.594 15.2H9.762C10.008 15.2 10.212 15.368 10.302 15.602C10.5 16.106 11.01 16.46 12 16.46C13.326 16.46 13.62 15.8 13.62 15.386C13.62 14.828 13.32 14.3 11.82 13.94C10.146 13.538 9 12.848 9 11.462C9 10.304 9.936 9.548 11.1 9.296V8.9C11.1 8.402 11.502 8 12 8C12.498 8 12.9 8.402 12.9 8.9V9.314C13.836 9.542 14.424 10.13 14.676 10.838C14.814 11.21 14.532 11.6 14.136 11.6H13.956C13.704 11.6 13.506 11.42 13.434 11.18C13.278 10.664 12.852 10.34 12 10.34C10.986 10.34 10.38 10.796 10.38 11.45Z"
                fill={fillColor}
            />
        </svg>
    );
};

IconStoragePrice.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconStoragePrice;
