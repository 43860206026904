import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_DATASHEET,
    DELETE_DATASHEET_FAILURE,
    DELETE_DATASHEET_SUCCESS,
    DOWNLOAD_DATASHEET,
    DOWNLOAD_DATASHEET_FAILURE,
    DOWNLOAD_DATASHEET_SUCCESS,
    FETCH_PRODUCT_DATASHEETS,
    FETCH_PRODUCT_DATASHEETS_FAILURE,
    FETCH_PRODUCT_DATASHEETS_SUCCESS,
    INITIALIZE_FORM,
    OPEN_CONFIRM_MODAL,
    RESET_FORM,
    RESET_UPLOAD_MODEL,
    SET_DATA_CONFIRM_MODAL,
    SET_ELEMENT_TO_DELETE,
    SHOW_FORM,
    UPLOAD_DATASHEET,
    UPLOAD_DATASHEET_FAILURE,
    UPLOAD_DATASHEET_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    dataConfirmModal: {
        message: '',
        title: null,
        variant: '',
    },
    deleteDatasheet: {
        error: null,
        isLoading: false,
    },
    downloadDatasheet: {
        data: null,
        errors: [],
        isFetching: false,
    },
    elementToDelete: null,
    initialValues: {
        archive: null,
        id: null,
        name: null,
        productId: null,
        productType: null,
    },
    isOpenConfirmModal: null,
    isVisibleForm: false,
    productDatasheets: {
        data: null,
        error: null,
        isLoading: false,
    },
    uploadDatasheet: {
        errors: null,
        isLoading: false,
    },
};

const productDatasheetsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_DATASHEET]: (state) => {
            state.deleteDatasheet.isLoading = true;
        },
        [DELETE_DATASHEET_FAILURE]: (state, action) => {
            state.deleteDatasheet = {
                ...state.deleteDatasheet,
                error: action.payload,
                isLoading: false,
            };
        },
        [DELETE_DATASHEET_SUCCESS]: (state) => {
            state.deleteDatasheet.isLoading = false;
        },
        [DOWNLOAD_DATASHEET]: (state) => {
            state.downloadDatasheet = {
                ...state.downloadDatasheet,
                errors: [],
                isFetching: true,
            };
        },
        [DOWNLOAD_DATASHEET_FAILURE]: (state, action) => {
            state.downloadDatasheet = {
                ...state.downloadDatasheet,
                errors: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_DATASHEET_SUCCESS]: (state, action) => {
            state.downloadDatasheet = {
                ...state.downloadDatasheet,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PRODUCT_DATASHEETS]: (state) => {
            state.productDatasheets.isLoading = true;
        },
        [FETCH_PRODUCT_DATASHEETS_FAILURE]: (state, action) => {
            state.productDatasheets = {
                ...state.productDatasheets,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_PRODUCT_DATASHEETS_SUCCESS]: (state, action) => {
            state.productDatasheets = {
                ...state.productDatasheets,
                data: action.payload,
                error: null,
                isLoading: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [OPEN_CONFIRM_MODAL]: (state, action) => {
            state.isOpenConfirmModal = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = {
                ...state.initialValues,
                archive: null,
                id: null,
                name: null,
            };
        },
        [RESET_UPLOAD_MODEL]: (state) => {
            state.uploadDatasheet = {
                ...state.uploadDatasheet,
                errors: null,
                isLoading: false,
            };
        },
        [SET_DATA_CONFIRM_MODAL]: (state, action) => {
            state.dataConfirmModal = action.payload;
        },
        [SET_ELEMENT_TO_DELETE]: (state, action) => {
            state.elementToDelete = action.payload;
        },
        [SHOW_FORM]: (state, action) => {
            state.isVisibleForm = action.payload;
        },
        [UPLOAD_DATASHEET]: (state) => {
            state.uploadDatasheet.isLoading = true;
        },
        [UPLOAD_DATASHEET_FAILURE]: (state, action) => {
            state.uploadDatasheet = {
                ...state.uploadDatasheet,
                errors: action.payload,
                isLoading: false,
            };
        },
        [UPLOAD_DATASHEET_SUCCESS]: (state) => {
            state.uploadDatasheet.isLoading = false;
        },
    },
});

export const productDatasheetsActions = productDatasheetsSlice.actions;

export default productDatasheetsSlice.reducer;
