import React, { useEffect, useState } from 'react';

import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';

import * as actions from '../../proposalGeneratorFinancingAndInsurance/actions';
import * as selectors from '../../proposalGeneratorFinancingAndInsurance/selectors';

import AllianceReview from './AllianceReview';
import FinancialReview from './FinancialReview';

const getFinancierProductSelected = (productId, products) => {
    return products.find((item) => item.id === productId) || {};
};

const Financing = ({
    currencyIso,
    currencyLocale,
    fetchAllianceProducts,
    fetchFinancialProductsPlanMe,
    financialProductParameters,
    financierInstaller,
    hasFinancing,
    hasFinancingRequest,
    hasOwnFinancing,
    isFetchingAllianceProducts,
    isFetchingFinancialProductsPlan,
    isGeneratedProposal,
    isLoading,
    proposalId,
}) => {
    const { t } = useTranslation();
    const [alliance, setAlliance] = useState({});
    const [ownFinancialProduct, setOwnFinancialProduct] = useState({});

    const productId = get(
        financialProductParameters,
        'product_financial_plan',
        null,
    );

    useEffect(() => {
        if (!isNull(financierInstaller)) {
            setAlliance(financierInstaller);
            fetchAllianceProducts(financierInstaller?.id, proposalId);
        }
    }, [financierInstaller]);

    useEffect(() => {
        if (hasOwnFinancing) {
            fetchFinancialProductsPlanMe(proposalId, (products) => {
                const product = getFinancierProductSelected(
                    productId,
                    products,
                );
                setOwnFinancialProduct({
                    ...product,
                    financingId: financialProductParameters?.id,
                });
            });
        }
    }, [hasOwnFinancing]);

    if (!hasFinancing) return null;

    return (
        <Box>
            <Box mb={2}>
                <TitleWithDetail variant="body2">
                    {t('Financing')}
                </TitleWithDetail>
            </Box>
            <AllianceReview
                alliance={alliance}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                financialProduct={financialProductParameters}
                hasFinancingRequest={hasFinancingRequest}
                isGeneratedProposal={isGeneratedProposal}
                isLoading={isFetchingAllianceProducts || isLoading}
                show={hasFinancing && !isNull(financierInstaller)}
            />
            <FinancialReview
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                financialProductParameters={financialProductParameters}
                isLoading={isFetchingFinancialProductsPlan || isLoading}
                ownFinancialProduct={ownFinancialProduct}
                show={hasOwnFinancing}
            />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    allianceProducts: selectors.getAllianceProductsData,
    isFetchingAllianceProducts: selectors.getIsFetchingAllianceProducts,
    isFetchingFinancialProductsPlan:
        selectors.getIsFetchingFinancialProductsPlan,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAllianceProducts: (allianceId, proposalId) =>
        dispatch(
            actions.fetchAllianceFinancialProducts(allianceId, proposalId),
        ),
    fetchFinancialProductsPlanMe: (proposalId, callback) =>
        dispatch(actions.fetchFinancialProductsPlanMe(proposalId, callback)),
});

Financing.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    fetchAllianceProducts: PropTypes.func,
    fetchFinancialProductsPlanMe: PropTypes.func,
    financialProductParameters: PropTypes.object,
    financierInstaller: PropTypes.object,
    hasFinancing: PropTypes.bool,
    hasFinancingRequest: PropTypes.bool,
    hasOwnFinancing: PropTypes.bool,
    isFetchingAllianceProducts: PropTypes.bool,
    isFetchingFinancialProductsPlan: PropTypes.bool,
    isGeneratedProposal: PropTypes.bool,
    isLoading: PropTypes.bool,
    proposalId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Financing);
