import API from '../..';
const ENTITY = 'cups';

export const getCupsInfo = (cups) =>
    API.get(`/api/v1/${ENTITY}-info`, { params: { cups } });

export const getCupsInfoByDate = ({ cups, final_date, initial_date, rate }) =>
    API.get(`/api/v1/${ENTITY}-historical/`, {
        params: { cups, initial_date, final_date, rate },
    });
