import { ONE_TO_MANY } from '../constants';

import BaseModel from './BaseModel';
export default class SmartDocument extends BaseModel {}
SmartDocument.modelName = 'SmartDocument';
SmartDocument.prepareFields({
    createdAt: 'created_at',
    commercialOfferId: {
        key: 'commercial_offer_id',
        relationType: ONE_TO_MANY,
        options: {
            to: 'CommercialOffer',
            as: 'commercialOffer',
            relatedName: 'smartDocuments',
        },
    },
    customTemplateId: {
        key: 'custom_template_id',
        relationType: ONE_TO_MANY,
        options: {
            to: 'CustomTemplate',
            as: 'customTemplate',
            relatedName: 'smartDocuments',
        },
    },
    id: 'id',
    name: 'name',
    createdBy: 'created_by',
});
