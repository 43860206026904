import { addKey } from 'common/api/v1/integrationsSuppliers';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_INTEGRATION,
    SAVE_INTEGRATION_FAILURE,
    SAVE_INTEGRATION_SUCCESS,
} from '../actionTypes';
import { supplierIntegrationsActions } from '../reducer';

import fetchIntegrations from './fetchIntegrations';
import setIsOpenFormModal from './setIsOpenFormModal';

export default (values, provider) => (dispatch) => {
    dispatch(supplierIntegrationsActions[SAVE_INTEGRATION]());

    if (!provider) return;

    const newValues = { type: provider.type };

    for (const field of provider.fields) {
        newValues[field] = values[field];
    }

    addKey(newValues)
        .then((response) => {
            dispatch(
                supplierIntegrationsActions[SAVE_INTEGRATION_SUCCESS](
                    response?.data,
                ),
            );

            dispatch(setIsOpenFormModal(false));
            dispatch(fetchIntegrations());
            showToast();
        })
        .catch((error) => {
            dispatch(
                supplierIntegrationsActions[SAVE_INTEGRATION_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
