import connectSocket from '../connectSocket';
import { PREFIX_ZAP } from '../constants';
import disconnectSocket from '../disconnectSocket';

const baseURL = import.meta.env.VITE_ZAP_SOCKET_URL;

export const connect = (token) => {
    return connectSocket(`${baseURL}?token=${token}`, PREFIX_ZAP);
};
export const disconnect = () => disconnectSocket(PREFIX_ZAP);
