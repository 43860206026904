import Geocode from 'react-geocode';

import { getIsoCodeCurrentLocale } from 'common/utils/helpers/multiregion';

import {
    FETCH_GEOCODE,
    FETCH_GEOCODE_ERROR,
    FETCH_GEOCODE_SUCCESS,
} from '../actionTypes';
import { branchOfficesActions } from '../reducer';

export default (str) => (dispatch) => {
    Geocode.setApiKey(import.meta.env.VITE_GOOGLE_MAPS_KEY);
    Geocode.setLanguage(getIsoCodeCurrentLocale());
    Geocode.setRegion('mx');

    dispatch(branchOfficesActions[FETCH_GEOCODE]());

    return Geocode.fromAddress(str).then(
        (response) =>
            dispatch(
                branchOfficesActions[FETCH_GEOCODE_SUCCESS](response.results),
            ),
        (error) => dispatch(branchOfficesActions[FETCH_GEOCODE_ERROR](error)),
    );
};
