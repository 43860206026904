import React from 'react';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CategoryIcon from '@mui/icons-material/Category';
import ExploreIcon from '@mui/icons-material/Explore';
import GridGoldenratioSharpIcon from '@mui/icons-material/GridGoldenratioSharp';
import GridOffIcon from '@mui/icons-material/GridOff';
import PanToolIcon from '@mui/icons-material/PanTool';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, IconButton, Paper, Tooltip } from 'sunwise-ui';

import * as actions from '../actions';
import { POLYGON_MAP, POLYGON_OBSTACLE } from '../constants';
import * as selectors from '../selectors';

import ToolbarMoreOptionsMenu from './ToolbarMoreOptionsMenu';

const Toolbar = ({
    isDisablingModules,
    isDrawingMode,
    isEditingSegment,
    isEditionMode,
    isGuideLinesActive,
    mapRef,
    onCloseDrawer,
    setDrawingMode,
    setIsDisablingModules,
    setIsGuideLinesActive,
}) => {
    const { t } = useTranslation();
    const isDisabled = isDrawingMode || isEditionMode || isEditingSegment;

    const handleClickAdd = ({ type = null } = {}) => {
        setDrawingMode(true, type);
        onCloseDrawer();
    };

    const handleClickDisabledModules = () => {
        setIsDisablingModules(!isDisablingModules);
        onCloseDrawer();
    };

    const handleClick = () => {
        setIsDisablingModules(false);
        setDrawingMode(false);
        onCloseDrawer();
    };

    const handleOnClickRotateCounterClockwise = (e) => {
        const map = mapRef.current.self();
        const heading = map.getHeading() || 0;

        let degrees = 1;
        if (e.shiftKey) degrees = degrees * 10;
        if (e.altKey) degrees = degrees * 0.1;
        if (e.shiftKey && e.altKey) degrees = degrees * 0.01;

        map.setHeading(heading + degrees);
    };

    const handleOnClickRotateClockwise = (e) => {
        const map = mapRef.current.self();
        const heading = map.getHeading() || 0;

        let degrees = -1;
        if (e.shiftKey) degrees = degrees * 10;
        if (e.altKey) degrees = degrees * 0.1;
        if (e.shiftKey && e.altKey) degrees = degrees * 0.01;

        map.setHeading(heading + degrees);
    };

    const handleOnClickRestoreRotation = () => {
        const map = mapRef.current.self();
        map.setHeading(0);
    };

    const handleOnClickToggleGuideLines = () =>
        setIsGuideLinesActive((cur) => !cur);

    return (
        <Paper
            elevation={0}
            id="panel-layout-toolbar"
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                mt: 2,
                mx: 2,
                p: 1,
            }}
        >
            <Tooltip title={t('Select')}>
                <Box id="panel-layout-toolbar-select-button">
                    <IconButton onClick={handleClick} size="small">
                        <PanToolIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>

            <Tooltip title={t('Add segment')}>
                <Box id="panel-layout-toolbar-add-segment-button">
                    <IconButton
                        disabled={isDisabled || isDisablingModules}
                        onClick={() => handleClickAdd()}
                        size="small"
                    >
                        <PolylineOutlinedIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>

            <Tooltip title={t('Add obstacle')}>
                <Box id="panel-layout-toolbar-add-obstacle-button">
                    <IconButton
                        disabled={isDisabled || isDisablingModules}
                        onClick={() =>
                            handleClickAdd({ type: POLYGON_OBSTACLE })
                        }
                        size="small"
                    >
                        <CategoryIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>

            <Tooltip title={t('Add image')}>
                <Box id="panel-layout-toolbar-add-image-button">
                    <IconButton
                        disabled={isDisabled || isDisablingModules}
                        onClick={() => handleClickAdd({ type: POLYGON_MAP })}
                        size="small"
                    >
                        <AddPhotoAlternateIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>

            <Tooltip title={t('Enable/Disable modules')}>
                <Box id="panel-layout-toolbar-toggle-modules-button">
                    <IconButton
                        disabled={isDisabled}
                        onClick={handleClickDisabledModules}
                        size="small"
                    >
                        <GridOffIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>

            <Tooltip title={t('Rotate counter clockwise')}>
                <Box
                    id="panel-layout-toolbar-rotate-counter-clockwise-button"
                    sx={{ ml: 'auto' }}
                >
                    <IconButton
                        disabled={isEditionMode || isEditingSegment}
                        onClick={handleOnClickRotateCounterClockwise}
                        size="small"
                    >
                        <RotateLeftIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>

            <Tooltip title={t('Rotate clockwise')}>
                <Box id="panel-layout-toolbar-rotate-clockwise-button">
                    <IconButton
                        disabled={isEditionMode || isEditingSegment}
                        onClick={handleOnClickRotateClockwise}
                        size="small"
                    >
                        <RotateRightIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>

            <Tooltip title={t('Restore rotation')}>
                <Box id="panel-layout-toolbar-restore-rotation-button">
                    <IconButton
                        disabled={isEditionMode || isEditingSegment}
                        onClick={handleOnClickRestoreRotation}
                        size="small"
                    >
                        <ExploreIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>

            <Tooltip title={t('Enable/disable guide lines')}>
                <Box id="panel-layout-toolbar-toggle-guide-lines">
                    <IconButton
                        disabled={isDisabled}
                        onClick={handleOnClickToggleGuideLines}
                        size="small"
                        sx={{
                            backgroundColor: isGuideLinesActive
                                ? 'primary.main'
                                : 'inherit',
                        }}
                    >
                        <GridGoldenratioSharpIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>

            <ToolbarMoreOptionsMenu />
        </Paper>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isDisablingModules: selectors.getIsDisablingModules,
    isDrawingMode: selectors.getIsDrawingMode,
    isEditingSegment: selectors.getUpdateSegmentIsSaving,
    offerPanelsCounters: selectors.getOfferPanelsCounters,
});

const mapDispatchToProps = (dispatch) => ({
    setDrawingMode: (isDrawingMode, type) =>
        dispatch(actions.setDrawingMode(isDrawingMode, type)),
    setIsDisablingModules: (isDisabling) =>
        dispatch(actions.setIsDisablingModules(isDisabling)),
});

Toolbar.propTypes = {
    isDisablingModules: PropTypes.bool,
    isDrawingMode: PropTypes.bool,
    isEditingSegment: PropTypes.bool,
    isEditionMode: PropTypes.bool,
    isGuideLinesActive: PropTypes.bool,
    mapRef: PropTypes.object,
    onCloseDrawer: PropTypes.func,
    setDrawingMode: PropTypes.func,
    setIsDisablingModules: PropTypes.func,
    setIsGuideLinesActive: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
