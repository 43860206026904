import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

import { getProjectColorByIndex } from '../helpers';

import InfoCard from './InfoCard';

const ItemsWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 650px;
    overflow-y: auto;
    padding: 8px;

    ${scroll.custom()}
`;

const ItemsList = ({
    handleSelectProject,
    items,
    selectedProjectId,
    showDot,
}) => {
    return (
        <ItemsWrapper>
            {items?.map((project, index) => (
                <Box key={project.id}>
                    <InfoCard
                        dotColor={getProjectColorByIndex(index)}
                        handleSelectProject={handleSelectProject}
                        project={project}
                        selectedProjectId={selectedProjectId}
                        showDot={showDot}
                    />
                </Box>
            ))}
        </ItemsWrapper>
    );
};

ItemsList.propTypes = {
    handleSelectProject: PropTypes.func,
    items: PropTypes.array,
    selectedProjectId: PropTypes.number,
    showDot: PropTypes.bool,
};

export default ItemsList;
