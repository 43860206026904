import API from '../..';
const ENTITY = '/api/v1/funnel-proposals';

export const getItems = ({ filterQuery, per_page = 7 }) =>
    API.get(`${ENTITY}/`, {
        params: { per_page, ...filterQuery },
    });

export const getNewItems = ({ filterQuery, page, per_page = 7, status }) =>
    API.get(`${ENTITY}/items/`, {
        params: { page, per_page, status, ...filterQuery },
    });
