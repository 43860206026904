import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { types } from 'sunwise-template-core';

import {
    DUPLICATE_TEMPLATE_MODE,
    NEW_FROM_TEMPLATE_MODE,
    NEW_TEMPLATE_MODE,
    UPDATE_TEMPLATE_MODE,
} from './constants';

export const getAvailableLanguagesToSelect = (
    baseTemplateLanguages = [],
    languagesToSelect = [],
) => {
    if (isEmpty(baseTemplateLanguages))
        return [
            {
                label: i18next.t('Select a language'),
                value: '',
                disabled: true,
            },
            ...languagesToSelect,
        ];

    let availableLanguages = [];

    languagesToSelect.forEach((language) => {
        if (baseTemplateLanguages.includes(language.value)) {
            availableLanguages.push(language);
        }
    });

    return [
        {
            label: i18next.t('Select a language'),
            value: '',
            disabled: true,
        },
        ...availableLanguages,
    ];
};

export const getAllTemplates = (data) => {
    const { featuredTemplates, newTemplates, regularTemplates } = data;
    let allTemplates = [];

    if (!isEmpty(featuredTemplates)) allTemplates = [...featuredTemplates];
    if (!isEmpty(newTemplates))
        allTemplates = [...allTemplates, ...newTemplates];
    if (!isEmpty(regularTemplates))
        allTemplates = [...allTemplates, ...regularTemplates];

    return allTemplates;
};

export const getCarouselTemplates = (data, selectedCategory) => {
    const allTemplates = getAllTemplates(data);

    if (!selectedCategory) return allTemplates;

    const filteredTemplates = allTemplates.filter(
        (template) => template.category_id === selectedCategory,
    );

    return filteredTemplates;
};

export const getCategoryTemplatesByLanguage = ({
    category,
    language,
    templates,
}) => {
    if (isEmpty(templates)) return [];
    return templates.filter(
        (item) =>
            item?.category_id === category &&
            item?.languages.map((lang) => lang).includes(language),
    );
};

export const getLanguagesKeys = (languages) => {
    if (!isEmpty(languages)) return languages.map((item) => item.key);
    return [];
};

export const getMainDataButtonText = (templateMode) => {
    switch (templateMode) {
        case NEW_TEMPLATE_MODE:
            return i18next.t('Next');
        case DUPLICATE_TEMPLATE_MODE:
        case NEW_FROM_TEMPLATE_MODE:
            return i18next.t('Create');
        case UPDATE_TEMPLATE_MODE:
            return i18next.t('Save');
        default:
            return '';
    }
};

export const getModalTitle = (mode, type) => {
    switch (mode) {
        case NEW_TEMPLATE_MODE:
        case NEW_FROM_TEMPLATE_MODE:
            switch (type) {
                case types.ONE_PROPOSAL_TYPE:
                    return i18next.t('New proposal template');
                case types.MULTIPROPOSAL_TYPE:
                    return i18next.t('New summary template');
                case types.SMART_DOCUMENTS_TYPE:
                    return i18next.t('New smart document template');
                default:
                    return i18next.t('New template');
            }
        case UPDATE_TEMPLATE_MODE:
            switch (type) {
                case types.ONE_PROPOSAL_TYPE:
                    return i18next.t('Edit proposal template');
                case types.MULTIPROPOSAL_TYPE:
                    return i18next.t('Edit summary template');
                case types.SMART_DOCUMENTS_TYPE:
                    return i18next.t('Edit Smart Document template');
                default:
                    return i18next.t('Edit Template');
            }
        case DUPLICATE_TEMPLATE_MODE:
            switch (type) {
                case types.ONE_PROPOSAL_TYPE:
                    return i18next.t('Duplicate proposal template');
                case types.MULTIPROPOSAL_TYPE:
                    return i18next.t('Duplicate summary template');
                case types.SMART_DOCUMENTS_TYPE:
                    return i18next.t('Duplicate Smart Document template');
                default:
                    return i18next.t('Duplicate Template');
            }
        default:
            return '';
    }
};
