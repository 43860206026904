import axios from 'axios';
import i18next from 'i18next';

import { URL_TYPES } from 'common/constants';
import { downloadFileFromUrl, getFile } from 'common/modules/lisaFiles/actions';

import downloadFile from './downloadFile';

export default (archive, fileName, loadingContext, name, urlType) =>
    async (dispatch) => {
        if (urlType === URL_TYPES.LISA) {
            loadingContext.openLoading(
                i18next.t('Download file').concat('...'),
            );
            dispatch(
                getFile(fileName, (data) => {
                    loadingContext.closeLoading();
                    dispatch(downloadFileFromUrl(data.url));
                }),
            );
            return false;
        }
        await axios({
            url: archive,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            dispatch(downloadFile(name, url, 'pdf'));
        });
    };
