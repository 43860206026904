import initializeForm from './initializeForm';
import resetUploadModel from './resetUploadModel';
import showForm from './showForm';

export default (data) => (dispatch) => {
    const values = {
        archive: data?.archive,
        id: data.id,
        name: data.name,
        productId: data.productId,
        productType: data.productType,
    };

    dispatch(resetUploadModel());
    dispatch(initializeForm(values));
    dispatch(showForm(true));
};
