import React, {
    createRef,
    useContext,
    useEffect,
    useState,
    useRef,
} from 'react';

import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import {
    DEFAULT_CURRENCY,
    PROPOSAL_BRANCH_ACCESS_DENIED,
    STATUS,
} from 'common/constants';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { useDebounce } from 'common/hooks';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { LoadingContext } from 'common/utils/contexts';
import { getCurrencyIso, getCurrencySymbol } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';
import showToast from 'common/utils/showToast';

import editProposalCommentModal from '../editProposalCommentModal';
import * as energyBackupSetupSelectors from '../energyBackupSetup/selectors';
import DuplicateFormModal from '../projectCommercialOfferTable/components/DuplicateFormModal';
import proposalGeneratorConfigurationModal from '../proposalGeneratorConfigurationModal';
import * as timeShiftingConfigurationSelectors from '../timeShiftingConfiguration/selectors';

import * as actions from './actions';
import Header from './components/Header';
import ProposalTabs from './components/ProposalTabs';
import ResponsiveHeader from './components/ResponsiveHeader';
import SupportDrawer from './components/SupportDrawer';
import SupportMenuGrid from './components/SupportMenuGrid';
import WrapperContent from './components/WrapperContent';
import * as selectors from './selectors';

const DEBOUNCE_TIME = 500;
const PROPOSAL_SECTIONS = [
    'analysis',
    'quote',
    'layout',
    'storage',
    'financing',
];

const Container = ({
    batteriesOfferValues,
    branchOfficesDictionary,
    canModify,
    compensationScheme,
    consumptionProjection,
    creditOfferStatus,
    customerEnergyData,
    fetchOfferDetails,
    financialProductParameters,
    financierInstaller,
    handleClickBreadcrumb,
    handleClickOpenConfigurationModal,
    hasSmartDocuments,
    infonavitData,
    initialize,
    irradiationValues,
    isApproved,
    isFetchingConsumptionAndGeneration,
    isFetchingOfferDetails,
    isLocked,
    isPrepareEditFetching,
    match,
    maxDailyConsumption,
    nextRateData,
    offerDetails,
    offerDetailsBranchOffice,
    offerDetailsComplements,
    offerDetailsConsumption,
    offerDetailsLocation,
    offerDetailsPattern,
    proposalConfiguration,
    setIsLocked,
    setSolarSimulationData,
    showAccessDeniedToast,
    timeShiftingConfiguration,
    totalsSolarSimulation,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [customerMode, setCustomerMode] = useState(false);
    const [tabClicked, setTabClicked] = useState(null);
    const [tabSelected, setTabSelected] = useState('analysis');
    const [timer, setTimer] = useState(0);
    const debouncedConsumptionProjection = useDebounce(
        consumptionProjection,
        DEBOUNCE_TIME,
    );
    const mainColumnRef = createRef();
    const refCards = useRef(PROPOSAL_SECTIONS.map(() => createRef()));
    const refTabs = createRef();
    const branchOfficeId = get(offerDetailsBranchOffice, 'id', null);
    const contactId = get(offerDetailsPattern, 'contact.id', null);
    const isArchived = get(offerDetails, 'is_archived', false);
    const offerStatus = get(offerDetails, 'status', null);
    const projectId = get(offerDetailsPattern, 'project_id', null);
    const currentScroll = localStorage.getItem('proposalScrollTop');
    const openedModule = localStorage.getItem('openedProposalModule');

    const offerCurrency = get(
        offerDetailsComplements,
        'currency',
        DEFAULT_CURRENCY,
    );

    const currencyIso = getCurrencyIso(offerCurrency);
    const currencyLocale = getCountryCurrencyLocale();
    const currencySymbol = getCurrencySymbol(offerCurrency);
    const offerId = match.params.uid;

    const handleTop = (value) => {
        if (mainColumnRef && mainColumnRef.current)
            mainColumnRef.current.scrollTop = value;
    };

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isPrepareEditFetching)
            loadingContext.openLoading(
                t('Loading', { count: 2 }).concat('...'),
            );
        else loadingContext.closeLoading();
    }, [isPrepareEditFetching]);

    useEffect(() => {
        handleTop(0);
        initialize(offerId);
    }, [offerId]);

    useEffect(() => {
        setIsLocked(false);
        if (offerStatus)
            setIsLocked(offerStatus === COMMERCIAL_OFFER_STATUS.FINISHED.key);
    }, [offerStatus]);

    useEffect(() => {
        if (
            isNull(branchOfficeId) &&
            !isEmpty(branchOfficesDictionary) &&
            isNull(branchOfficesDictionary[branchOfficeId])
        ) {
            showAccessDeniedToast();
            handleClickBreadcrumb(contactId, projectId);
        }
    }, [branchOfficeId, branchOfficesDictionary]);

    useEffect(() => {
        if (
            currentScroll &&
            ['energyBackup', 'panelLayout'].includes(openedModule)
        ) {
            const scrollTop = parseInt(currentScroll);
            handleTop(scrollTop);
            localStorage.removeItem('proposalScrollTop');
            localStorage.removeItem('openedProposalModule');
        }
    }, []);

    useEffect(() => {
        if (
            !isEmpty(debouncedConsumptionProjection) &&
            !isEmpty(timeShiftingConfiguration)
        )
            setSolarSimulationData({
                ...debouncedConsumptionProjection,
                ...timeShiftingConfiguration,
            });
    }, [debouncedConsumptionProjection, timeShiftingConfiguration]);

    const isClosed = creditOfferStatus === STATUS.CLOSED_STATUS.key;
    const isGenerated = offerStatus === COMMERCIAL_OFFER_STATUS.FINISHED.key;

    const isLoadingDefault =
        isFetchingOfferDetails || isFetchingConsumptionAndGeneration;
    const isLockedDefault = isLoadingDefault || isLocked;

    const getIsScrollInSection = (offset, ref) =>
        offset < ref?.offsetTop + ref?.scrollHeight;

    const handleScroll = (event) => {
        const offsetScroll =
            refTabs.current.offsetTop + refTabs.current.scrollHeight;
        const scrollTop = event?.currentTarget?.scrollTop;
        localStorage.setItem('proposalScrollTop', scrollTop);

        if (tabClicked) {
            clearTimeout(timer);
            setTimer(setTimeout(() => setTabClicked(null), DEBOUNCE_TIME));
            return;
        }

        refCards?.current?.some((ref, index) => {
            if (getIsScrollInSection(offsetScroll, ref.current)) {
                setTabSelected(PROPOSAL_SECTIONS[index]);
                return true;
            }
        });
    };

    return (
        <>
            <Grid container spacing={0}>
                <Grid item lg={14} md={12} xs={18}>
                    <ResponsiveHeader
                        canModify={canModify}
                        customerMode={customerMode}
                        financialProductParameters={financialProductParameters}
                        handleClickBreadcrumb={handleClickBreadcrumb}
                        handleClickOpenConfigurationModal={
                            handleClickOpenConfigurationModal
                        }
                        hasSmartDocuments={hasSmartDocuments}
                        isApproved={isApproved}
                        isClosed={isClosed}
                        isFetchingOfferDetails={isFetchingOfferDetails}
                        isGenerated={isGenerated}
                        offerDetails={offerDetails}
                        offerDetailsPattern={offerDetailsPattern}
                        offerId={offerId}
                        setCustomerMode={setCustomerMode}
                    />

                    <WrapperContent
                        className="one-column-wrapper"
                        onScroll={handleScroll}
                        ref={mainColumnRef}
                    >
                        <Header
                            handleClickBreadcrumb={handleClickBreadcrumb}
                            isApproved={isApproved}
                            isFetchingOfferDetails={isFetchingOfferDetails}
                            isGenerated={isGenerated}
                            offerDetails={offerDetails}
                            offerDetailsBranchOffice={offerDetailsBranchOffice}
                            offerDetailsPattern={offerDetailsPattern}
                        />

                        <ProposalTabs
                            contact={get(offerDetailsPattern, 'contact', {})}
                            currencyIso={currencyIso}
                            currencyLocale={currencyLocale}
                            currencySymbol={currencySymbol}
                            customerEnergyData={customerEnergyData}
                            customerMode={customerMode}
                            fetchOfferDetails={fetchOfferDetails}
                            financialProductParameters={
                                financialProductParameters
                            }
                            financierInstaller={financierInstaller}
                            infonavitData={infonavitData}
                            isArchived={isArchived}
                            isGenerated={isGenerated}
                            isLoadingDefault={isLoadingDefault}
                            isLockedDefault={isLockedDefault}
                            mainColumnRef={mainColumnRef}
                            offerDetails={offerDetails}
                            offerDetailsComplements={offerDetailsComplements}
                            offerDetailsConsumption={offerDetailsConsumption}
                            offerDetailsLocation={offerDetailsLocation}
                            offerId={offerId}
                            projectId={projectId}
                            refCards={refCards}
                            refTabs={refTabs}
                            setTabClicked={setTabClicked}
                            setTabSelected={setTabSelected}
                            sunHoursWithCustomGeneration={
                                irradiationValues?.sunHoursValue || 0
                            }
                            tabs={PROPOSAL_SECTIONS}
                            tabSelected={tabSelected}
                        />
                    </WrapperContent>
                </Grid>

                <SupportMenuGrid
                    batteriesOfferValues={batteriesOfferValues}
                    canModify={canModify}
                    compensationScheme={compensationScheme}
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    customerMode={customerMode}
                    financialProductParameters={financialProductParameters}
                    handleClickOpenConfigurationModal={
                        handleClickOpenConfigurationModal
                    }
                    hasSmartDocuments={hasSmartDocuments}
                    irradiationValues={irradiationValues}
                    isApproved={isApproved}
                    isClosed={isClosed}
                    isFetchingOfferDetails={isFetchingOfferDetails}
                    isGenerated={isGenerated}
                    maxDailyConsumption={maxDailyConsumption}
                    nextRateData={nextRateData}
                    offerDetails={offerDetails}
                    offerDetailsComplements={offerDetailsComplements}
                    offerDetailsConsumption={offerDetailsConsumption}
                    offerId={offerId}
                    proposalConfiguration={proposalConfiguration}
                    setCustomerMode={setCustomerMode}
                    totalsSolarSimulation={totalsSolarSimulation}
                />
            </Grid>

            <SupportDrawer
                batteriesOfferValues={batteriesOfferValues}
                compensationScheme={compensationScheme}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                financialProductParameters={financialProductParameters}
                irradiationValues={irradiationValues}
                isFetchingOfferDetails={isFetchingOfferDetails}
                isGenerated={isGenerated}
                maxDailyConsumption={maxDailyConsumption}
                nextRateData={nextRateData}
                offerDetails={offerDetails}
                offerDetailsComplements={offerDetailsComplements}
                offerDetailsConsumption={offerDetailsConsumption}
                offerId={offerId}
                proposalConfiguration={proposalConfiguration}
                totalsSolarSimulation={totalsSolarSimulation}
            />

            <proposalGeneratorConfigurationModal.Container
                currencyIso={currencyIso}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isLoadingDefault}
                isLocked={isLockedDefault}
                proposalId={offerId}
                systemSize={offerDetailsConsumption.system_size}
            />

            <DuplicateFormModal />
            <editProposalCommentModal.Container />
        </>
    );
};
const mapStateToProps = createStructuredSelector({
    batteriesOfferValues: energyBackupSetupSelectors.getOfferValuesData,
    branchOfficesDictionary: multiBranchesSelectors.getBranchesDictionary,
    compensationScheme: selectors.getCompensationScheme,
    consumptionProjection: selectors.getConsumptionProjectionData,
    creditOfferStatus: selectors.getCreditOfferStatus,
    customerEnergyData: selectors.getCustomerEnergyData,
    financialProductParameters: selectors.getFinancialProductParameters,
    financierInstaller: selectors.getFinancierInstaller,
    hasSmartDocuments: selectors.getHasSmartDocuments,
    infonavitData: selectors.getInfonavitData,
    irradiationValues: selectors.getDataOfferIrradiationValues,
    isApproved: selectors.getHasApproved,
    isFetchingConsumptionAndGeneration:
        selectors.getIsFetchingConsumptionAndGeneration,
    isFetchingOfferDetails: selectors.getIsFetchingOfferDetails,
    isLocked: selectors.getIsLocked,
    isPrepareEditFetching: selectors.getPrepareEditIsFetching,
    maxDailyConsumption: selectors.getMaxDailyConsumption,
    nextRateData: selectors.getNextRateData,
    offerDetails: selectors.getOfferDetailsCommercialOffer,
    offerDetailsBranchOffice: selectors.getOfferDetailsBranchOffice,
    offerDetailsComplements: selectors.getOfferDetailsComplements,
    offerDetailsConsumption: selectors.getOfferDetailsConsumption,
    offerDetailsLocation: selectors.getOfferDetailsLocation,
    offerDetailsPattern: selectors.getOfferDetailsPattern,
    proposalConfiguration: selectors.getProposalConfiguration,
    timeShiftingConfiguration:
        timeShiftingConfigurationSelectors.getFetchTimeShiftingConfigurationData,
    totalsSolarSimulation: selectors.getTotalsSolarSimulation,
});

const mapDispatchToProps = (dispatch) => ({
    fetchOfferDetails: (proposalId) =>
        dispatch(actions.fetchOfferDetails(proposalId)),
    handleClickBreadcrumb: (contactId, projetcId) =>
        dispatch(actions.handleClickBreadcrumb(contactId, projetcId)),
    handleClickOpenConfigurationModal: () =>
        dispatch(
            proposalGeneratorConfigurationModal.actions.setIsOpenModal(true),
        ),
    initialize: (id) => dispatch(actions.initialFetching(id)),
    setSolarSimulationData: (data) =>
        dispatch(actions.setSolarSimulationData(data)),
    setIsLocked: (value) => dispatch(actions.setIsLocked(value)),
    showAccessDeniedToast: () => showToast(PROPOSAL_BRANCH_ACCESS_DENIED),
});

Container.propTypes = {
    batteriesOfferValues: PropTypes.object,
    branchOfficesDictionary: PropTypes.object,
    canModify: PropTypes.bool,
    compensationScheme: PropTypes.object,
    consumptionProjection: PropTypes.object,
    creditOfferStatus: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    customerEnergyData: PropTypes.object,
    fetchOfferDetails: PropTypes.func,
    financialProductParameters: PropTypes.object,
    financierInstaller: PropTypes.object,
    handleClickBreadcrumb: PropTypes.func,
    handleClickOpenConfigurationModal: PropTypes.func,
    hasSmartDocuments: PropTypes.bool,
    infonavitData: PropTypes.string,
    initialize: PropTypes.func,
    irradiationValues: PropTypes.object,
    isApproved: PropTypes.bool,
    isFetchingConsumptionAndGeneration: PropTypes.bool,
    isFetchingOfferDetails: PropTypes.bool,
    isLocked: PropTypes.bool,
    isPrepareEditFetching: PropTypes.bool,
    match: PropTypes.object,
    maxDailyConsumption: PropTypes.number,
    nextRateData: PropTypes.object,
    offerDetails: PropTypes.object,
    offerDetailsBranchOffice: PropTypes.object,
    offerDetailsComplements: PropTypes.object,
    offerDetailsConsumption: PropTypes.object,
    offerDetailsLocation: PropTypes.object,
    offerDetailsPattern: PropTypes.object,
    proposalConfiguration: PropTypes.object,
    setIsLocked: PropTypes.func,
    setSolarSimulationData: PropTypes.func,
    showAccessDeniedToast: PropTypes.func,
    timeShiftingConfiguration: PropTypes.object,
    totalsSolarSimulation: PropTypes.object,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION),
    withRouter,
)(Container);
