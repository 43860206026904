import React, { useState, useEffect } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import { SERFIMEX_UID } from 'common/constants';
import yupResolver from 'common/utils/yupResolver';

import * as contactSelectors from '../../../contactForm/selectors';
import * as actions from '../../actions';
import editFinancingAllianceValidate from '../../editFinancingAllianceValidate';
import {
    getFinancierProducts,
    getFinancierProductsToSelect,
    getTypesBusiness,
    getTypeBusinessToSelect,
    handleOnChangeTermOrHitch,
    handleOnChangeFinancierProduct,
    handleOnChangeRegimen,
} from '../../helpers';
import * as selectors from '../../selectors';

import HitchField from './HitchField';

const CIBancoEditForm = ({
    alliance,
    allianceProducts,
    currencyIso,
    currencySymbol,
    financialProduct,
    initializeCibanco,
    initialValues,
    isLocked,
    isSaving,
    proposalId,
    saveCiBanco,
    total,
}) => {
    const { t } = useTranslation();
    const [productSelected, setProductSelected] = useState({});

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        context: { initialValues },
        defaultValues: initialValues,
        resolver: yupResolver(editFinancingAllianceValidate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (!isEmpty(productSelected)) {
            initializeCibanco({
                ...productSelected,
                financier_installer: alliance.id,
                is_serfimex: isSerfimex,
                total,
            });
        }
    }, [productSelected]);

    useEffect(() => {
        if (!isEmpty(financialProduct)) {
            const product = allianceProducts.find(
                (item) => item.id === financialProduct?.financier_product?.id,
            );

            initializeCibanco({
                ...product,
                financier_product: product?.id,
                financier_installer: alliance.id,
                hitch: financialProduct?.parameters?.hitch,
                hitch_percentage:
                    financialProduct?.parameters?.hitch_percentage,
                is_percentage:
                    financialProduct?.parameters?.is_percentage || false,
                is_serfimex: isSerfimex,
                total,
                term: financialProduct?.parameters?.term?.toString(),
                types_business_company: product?.types_business_company,
            });
        }
    }, [financialProduct]);

    const formValues = watch();
    const typesBusinessCompany = get(formValues, 'types_business_company', '');
    const parameters = get(productSelected, 'financier_product_parameters', {});
    const errors = get(productSelected, 'errors', []);
    const financierId = get(alliance, 'financier.id', null);
    const financierProducts = getFinancierProducts(
        allianceProducts,
        typesBusinessCompany,
    );
    const isSerfimex = financierId === SERFIMEX_UID;
    const typesBusiness = getTypesBusiness(allianceProducts);

    const handleOnClickSave = (values) =>
        saveCiBanco(financialProduct?.id, proposalId, values);

    return (
        <form>
            <Grid container>
                <Grid item xs>
                    <ReactHookFormSelect
                        control={control}
                        disabled={true}
                        label={t('Type of tax system')}
                        name="types_business_company"
                        onChange={() =>
                            handleOnChangeRegimen(setProductSelected, setValue)
                        }
                        options={getTypeBusinessToSelect(typesBusiness)}
                    />
                    <ReactHookFormSelect
                        control={control}
                        disabled={true}
                        label={t('Product')}
                        name="financier_product"
                        onChange={({ target: { value } }) =>
                            handleOnChangeFinancierProduct(
                                financierProducts,
                                setProductSelected,
                                setValue,
                                value,
                            )
                        }
                        options={[
                            {
                                label: t('Select a product'),
                                value: '',
                                disabled: true,
                            },
                            ...getFinancierProductsToSelect(financierProducts),
                        ]}
                        sxFormControl={{ mt: 1 }}
                    />
                </Grid>
            </Grid>

            <Grid container mt={1}>
                <Grid hidden={isSerfimex} item xs={18} lg={9}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isLocked}
                        label={t('Term')}
                        name="term"
                        onChange={() => {
                            handleOnChangeTermOrHitch({
                                parameters,
                                setValue,
                                term: formValues.term,
                                hitch: formValues.hitch_percentage,
                            });
                        }}
                        options={[
                            {
                                label: t('Select a term'),
                                value: '',
                                disabled: true,
                            },
                            ...formValues.termsToSelect,
                        ]}
                        sx={{ mb: '0 !important' }}
                    />
                </Grid>
                <Grid item xs={18} lg={isSerfimex ? 18 : 9}>
                    <HitchField
                        control={control}
                        currencyIso={currencyIso}
                        currencySymbol={currencySymbol}
                        formValues={formValues}
                        isLocked={isLocked}
                        isSerfimex={isSerfimex}
                        parameters={parameters}
                        setValue={setValue}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs>
                    <ShowErrors errors={errors} />
                </Grid>
            </Grid>

            <Grid container mt={1}>
                <Grid item xs sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                    <Button
                        disabled={isLocked || errors.length > 0}
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleSubmit(handleOnClickSave)}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        type="button"
                    >
                        {isSaving ? t('Saving').concat('...') : t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesCiBanco,
    isSavingContact: contactSelectors.getIsSavingContact,
});

const mapDispatchToProps = (dispatch) => ({
    initializeCibanco: (values) =>
        dispatch(actions.initialValuesCiBanco(values)),
    saveCiBanco: (id, proposalId, values) =>
        dispatch(actions.updateCiBanco(id, proposalId, values)),
});

CIBancoEditForm.propTypes = {
    alliance: PropTypes.object,
    allianceProducts: PropTypes.array,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    financialProduct: PropTypes.object,
    initializeCibanco: PropTypes.func,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    proposalId: PropTypes.string,
    saveCiBanco: PropTypes.func,
    total: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(CIBancoEditForm);
