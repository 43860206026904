import { createItem } from 'common/api/v2/generationProfiles';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_GENERATION_PROFILE,
    SAVE_GENERATION_PROFILE_FAILURE,
    SAVE_GENERATION_PROFILE_SUCCESS,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';

import fetchGenerationProfile from './fetchGenerationProfile';
import setGenerationProfileModalIsOpen from './setGenerationProfileModalIsOpen';

export default (getValues, values) => (dispatch) => {
    dispatch(solarGenerationActions[SAVE_GENERATION_PROFILE]());
    const { generation, year, political_division1 } = values;

    const newValues = JSON.stringify({
        political_division1,
        generation,
        year,
    });

    createItem(newValues)
        .then((response) => {
            dispatch(
                solarGenerationActions[SAVE_GENERATION_PROFILE_SUCCESS](
                    response.data.data,
                ),
            );
            dispatch(setGenerationProfileModalIsOpen(false));
            showToast();
            dispatch(fetchGenerationProfile(getValues('political_division')));
        })
        .catch((error) => {
            dispatch(
                solarGenerationActions[SAVE_GENERATION_PROFILE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
