import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    margin-left: 8px;
    & .MuiToggleButton-primary {
        color: #545759;
        padding: 6px 12px;
        &.Mui-selected {
            background-color: #dcdbdc;
        }
    }
`;

const PanelsOrKwInput = ({ control, getValues, offerPanels, setValue }) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState('u');
    const solar_module = useWatch({ control, name: 'solar_module' });
    const selectedModule = offerPanels.find((item) => item.id === solar_module);

    useEffect(() => {
        const kW = getValues('kW');
        handleOnChangeKw({ target: { value: kW } });
    }, [solar_module]);

    const handleOnChangeUnit = ({ target }) => {
        let kW = 0;
        if (selectedModule) {
            const { value } = target;
            const { watts = 0 } = selectedModule;
            if (watts) kW = (value * watts) / 1000;
        }
        setValue('kW', kW);
    };

    const handleOnChangeKw = ({ target }) => {
        let units = 0;
        if (selectedModule) {
            const { value } = target;
            const { watts = 0 } = selectedModule;
            if (watts) units = (value * 1000) / watts;
        }
        setValue('solar_modules_number', Math.ceil(Number(units.toFixed(4))));
    };

    return (
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
            {selected === 'u' && (
                <ReactHookFormIntlNumberInput
                    control={control}
                    fullWidth
                    label={t('Panel', { count: 2 })}
                    name="solar_modules_number"
                    onChange={handleOnChangeUnit}
                />
            )}

            {selected === 'kW' && (
                <ReactHookFormIntlNumberInput
                    control={control}
                    fullWidth
                    label="kW"
                    name="kW"
                    onChange={handleOnChangeKw}
                />
            )}

            <StyledToggleButtonGroup
                color="primary"
                exclusive
                onChange={(e) => setSelected(e.target.value)}
                value={selected}
                size="small"
                sx={{ mb: 2 }}
            >
                <ToggleButton value="u">u</ToggleButton>
                <ToggleButton value="kW">kW</ToggleButton>
            </StyledToggleButtonGroup>
        </Box>
    );
};

PanelsOrKwInput.propTypes = {
    control: PropTypes.object,
    getValues: PropTypes.func,
    offerPanels: PropTypes.array,
    setValue: PropTypes.func,
};

export default PanelsOrKwInput;
