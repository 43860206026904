import get from 'lodash/get';

import { getProjects } from 'common/api/v1/contactProject';

import {
    FETCH_PROJECTS_SUCCESS,
    FETCH_PROJECTS_FAILURE,
    FETCH_PROJECTS,
} from '../actionTypes';
import { projectActions } from '../reducer';

export default (
        { contactId = '', searchText = '', status = 'active' } = {},
        callback,
    ) =>
    (dispatch) => {
        dispatch(projectActions[FETCH_PROJECTS]());
        getProjects({
            contactId,
            searchText,
            status,
        })
            .then((response) => {
                const data = get(response, 'data.data', []);
                dispatch(projectActions[FETCH_PROJECTS_SUCCESS](data));
                if (callback) callback(data);
            })
            .catch((error) =>
                dispatch(projectActions[FETCH_PROJECTS_FAILURE](error)),
            );
    };
