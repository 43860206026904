import BaseModel from './BaseModel';

export default class CompanyCatalog extends BaseModel {}

CompanyCatalog.modelName = 'CompanyCatalog';
CompanyCatalog.prepareFields({
    id: 'id',
    isActive: 'is_active',
    name: 'name',
    order: 'order',
    primaryColor: 'primary_color',
    type: 'type',
});
