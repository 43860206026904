import React from 'react';

import { Grid } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import { COMPENSATION_SCHEMES_WITH_EXPORT_PRICE_BY_BACKEND_KEY } from 'common/constants/rates';
import { getCompensationHasNettedExportedGeneration } from 'common/utils/helpers/rates';
import { getCountryCurrencySymbol } from 'common/utils/helpers/session';

import { getSectionHasErrors } from '../../helpers';

import FormAccordion from './FormAccordion';

const EnergyExportPriceFields = ({
    compensationScheme,
    control,
    disabled,
    errors,
}) => {
    const { t } = useTranslation();

    if (
        !COMPENSATION_SCHEMES_WITH_EXPORT_PRICE_BY_BACKEND_KEY.includes(
            compensationScheme,
        )
    )
        return null;

    const hasNettedExportedGeneration =
        getCompensationHasNettedExportedGeneration(compensationScheme);

    const countryCurrencySymbol = getCountryCurrencySymbol();

    return (
        <FormAccordion
            defaultExpanded
            hasFormErrors={getSectionHasErrors(errors, [
                'exported_energy_price',
                'netted_exported_generation_price',
            ])}
            title={t('Exported solar generation')}
        >
            <Grid container>
                <Grid item xs={18} lg>
                    <ReactHookFormIntlNumberInput
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label={
                            hasNettedExportedGeneration
                                ? t('Excess exported generation price')
                                : t('Price')
                        }
                        name="exported_energy_price"
                        prepend={countryCurrencySymbol}
                        variant="standard"
                    />
                </Grid>

                {hasNettedExportedGeneration && (
                    <Grid item xs={18} lg>
                        <ReactHookFormIntlNumberInput
                            control={control}
                            disabled={disabled}
                            fullWidth
                            label={t('Netted exported generation price')}
                            name="netted_exported_generation_price"
                            prepend={countryCurrencySymbol}
                            variant="standard"
                        />
                    </Grid>
                )}
            </Grid>
        </FormAccordion>
    );
};

EnergyExportPriceFields.propTypes = {
    compensationScheme: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
};

export default EnergyExportPriceFields;
