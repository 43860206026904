import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SelectedItem = ({ productsToSelect, taxesElements }) => {
    const { t } = useTranslation();

    const defaultLabel =
        productsToSelect.length === taxesElements.length
            ? t('All text', { context: 'male' })
            : `${taxesElements.length} ${t('Product', {
                  count: taxesElements.length,
              })}`;

    if (taxesElements.length === 0) return <>{t('Select')}</>;

    return (
        <>
            {t('Applied to')} {defaultLabel}
        </>
    );
};

SelectedItem.propTypes = {
    productsToSelect: PropTypes.array,
    taxesElements: PropTypes.array,
};

export default SelectedItem;
