import React from 'react';

import PropTypes from 'prop-types';

const IconStructure = ({
    color = '#ff7043',
    height = '20px',
    width = '20px',
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 7V0H7C7.55229 0 8 0.447715 8 1C8 1.55228 7.55229 2 7 2H5V4C5 4.55228 4.55228 5 4 5H2V7C2 7.55228 1.55228 8 1 8C0.447715 8 0 7.55228 0 7Z"
            fill={color}
        />
        <path
            d="M20 7V0H13C12.4477 0 12 0.447715 12 1C12 1.55228 12.4477 2 13 2H15V4C15 4.55228 15.4477 5 16 5H18V7C18 7.55228 18.4477 8 19 8C19.5523 8 20 7.55228 20 7Z"
            fill={color}
        />
        <path
            d="M0 13C0 12.4477 0.447715 12 1 12C1.55228 12 2 12.4477 2 13V15H4C4.55228 15 5 15.4477 5 16V18H7C7.55229 18 8 18.4477 8 19C8 19.5523 7.55229 20 7 20H0V13Z"
            fill={color}
        />
        <path
            d="M20 13V20H13C12.4477 20 12 19.5523 12 19C12 18.4477 12.4477 18 13 18H15V16C15 15.4477 15.4477 15 16 15H18V13C18 12.4477 18.4477 12 19 12C19.5523 12 20 12.4477 20 13Z"
            fill={color}
        />
        <path
            d="M6.41888 7.94511C6.158 7.83586 5.85795 7.95878 5.7487 8.21966C5.63945 8.48055 5.76237 8.78059 6.02326 8.88984L7.07503 9.3303C7.02593 9.54563 7 9.76979 7 10C7 10.2221 7.02414 10.4386 7.06993 10.6469L6.01475 11.0792C5.75303 11.1864 5.62778 11.4855 5.735 11.7472C5.84222 12.009 6.14131 12.1342 6.40303 12.027L7.45843 11.5946C7.69467 11.9704 8.01193 12.29 8.38569 12.5291L7.94514 13.5811C7.83589 13.842 7.95881 14.142 8.21969 14.2513C8.48057 14.3605 8.78062 14.2376 8.88987 13.9767L9.33032 12.925C9.54565 12.9741 9.7698 13 10 13C10.2221 13 10.4386 12.9759 10.647 12.9301L11.0792 13.9852C11.1865 14.247 11.4855 14.3722 11.7473 14.265C12.009 14.1578 12.1342 13.8587 12.027 13.597L11.5946 12.5416C11.9704 12.3053 12.29 11.9881 12.5291 11.6143L13.5811 12.0549C13.842 12.1641 14.1421 12.0412 14.2513 11.7803C14.3606 11.5194 14.2376 11.2194 13.9768 11.1101L12.925 10.6697C12.9741 10.4543 13 10.2302 13 10C13 9.77788 12.9759 9.56141 12.9301 9.35305L13.9853 8.92076C14.247 8.81354 14.3722 8.51445 14.265 8.25273C14.1578 7.99101 13.8587 7.86576 13.597 7.97298L12.5416 8.40536C12.3053 8.02964 11.9881 7.70999 11.6143 7.47092L12.0549 6.41886C12.1641 6.15797 12.0412 5.85792 11.7803 5.74867C11.5195 5.63942 11.2194 5.76235 11.1102 6.02323L10.6697 7.07503C10.4544 7.02593 10.2302 7 10 7C9.77789 7 9.56143 7.02414 9.35307 7.06993L8.92079 6.01473C8.81357 5.75301 8.51448 5.62776 8.25276 5.73498C7.99104 5.8422 7.86579 6.14129 7.97301 6.40301L8.40539 7.45843C8.02965 7.69467 7.71 8.01192 7.47093 8.38568L6.41888 7.94511Z"
            fill={color}
        />
    </svg>
);

IconStructure.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconStructure;
