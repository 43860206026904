import { NAME } from './constants';

export const DELETE_PROJECT = `${NAME}/DELETE_PROJECT`;
export const DELETE_PROJECT_FAILURE = `${NAME}/DELETE_PROJECT_FAILURE`;
export const DELETE_PROJECT_SUCCESS = `${NAME}/DELETE_PROJECT_SUCCESS`;

export const FETCH_PROJECT_DETAILS = `${NAME}/FETCH_PROJECT_DETAILS`;
export const FETCH_PROJECT_DETAILS_FAILURE = `${NAME}/FETCH_PROJECT_DETAILS_FAILURE`;
export const FETCH_PROJECT_DETAILS_SUCCESS = `${NAME}/FETCH_PROJECT_DETAILS_SUCCESS`;

export const FETCH_PROJECTS = `${NAME}/FETCH_PROJECTS`;
export const FETCH_PROJECTS_FAILURE = `${NAME}/FETCH_PROJECTS_FAILURE`;
export const FETCH_PROJECTS_SUCCESS = `${NAME}/FETCH_PROJECTS_SUCCESS`;

export const FETCH_RPU = `${NAME}/FETCH_RPU`;
export const FETCH_RPU_FAILURE = `${NAME}/FETCH_RPU_FAILURE`;
export const FETCH_RPU_SUCCESS = `${NAME}/FETCH_RPU_SUCCESS`;

export const FETCH_STATUS_PROJECT = `${NAME}/FETCH_STATUS_PROJECT`;
export const FETCH_STATUS_PROJECT_FAILURE = `${NAME}/FETCH_STATUS_PROJECT_FAILURE`;
export const FETCH_STATUS_PROJECT_SUCCESS = `${NAME}/FETCH_STATUS_PROJECT_SUCCESS`;

export const RENAME_PROJECT = `${NAME}/RENAME_PROJECT`;
export const RENAME_PROJECT_FAILURE = `${NAME}/RENAME_PROJECT_FAILURE`;
export const RENAME_PROJECT_SUCCESS = `${NAME}/RENAME_PROJECT_SUCCESS`;

export const RESET_VALUES = `${NAME}/RESET_VALUES`;

export const SAVE_PROJECT = `${NAME}/SAVE_PROJECT`;
export const SAVE_PROJECT_FAILURE = `${NAME}/SAVE_PROJECT_FAILURE`;
export const SAVE_PROJECT_SUCCESS = `${NAME}/SAVE_PROJECT_SUCCESS`;

export const SAVE_PROJECT_MANUAL = `${NAME}/SAVE_PROJECT_MANUAL`;
export const SAVE_PROJECT_MANUAL_FAILURE = `${NAME}/SAVE_PROJECT_MANUAL_FAILURE`;
export const SAVE_PROJECT_MANUAL_SUCCESS = `${NAME}/SAVE_PROJECT_MANUAL_SUCCESS`;

export const UPDATE_STATUS_PROJECT = `${NAME}/UPDATE_STATUS_PROJECT`;
export const UPDATE_STATUS_PROJECT_FAILURE = `${NAME}/UPDATE_STATUS_PROJECT_FAILURE`;
export const UPDATE_STATUS_PROJECT_SUCCESS = `${NAME}/UPDATE_STATUS_PROJECT_SUCCESS`;
