import { filterFinancierProducts } from 'common/api/v1/sunpay';

import {
    FETCH_FINANCIER_PRODUCT,
    FETCH_FINANCIER_PRODUCT_FAILURE,
    FETCH_FINANCIER_PRODUCT_SUCCESS,
} from '../actionTypes';
import { alliancesActions } from '../reducer';

export default ({ orderBy, page = 0, pageSize, searchText, sortBy } = {}) =>
    (dispatch) => {
        dispatch(alliancesActions[FETCH_FINANCIER_PRODUCT]());

        filterFinancierProducts({
            orderBy,
            page: page + 1,
            perPage: pageSize,
            searchText,
            sortBy,
        })
            .then((response) =>
                dispatch(
                    alliancesActions[FETCH_FINANCIER_PRODUCT_SUCCESS](
                        response.data,
                    ),
                ),
            )
            .catch((error) =>
                dispatch(
                    alliancesActions[FETCH_FINANCIER_PRODUCT_FAILURE](error),
                ),
            );
    };
