import { UPDATE_NOTIFICATIONS } from '../actionTypes';
import { notificationsActions } from '../reducer';
import * as selectors from '../selectors';

export default (callback = null, notification = null, value) =>
    (dispatch, getState) => {
        const groupedNotifications = selectors.getNotificationsData(getState());

        const updateNotifications = (notifications, notification, value) => {
            return notifications.map((current) => {
                if (notification && current.id === notification.id) {
                    return { ...current, is_checked: value };
                }
                if (!notification) {
                    return { ...current, is_checked: value };
                }
                return current;
            });
        };

        const newData = groupedNotifications.map((group) => {
            return {
                ...group,
                notifications: updateNotifications(
                    group.notifications,
                    notification,
                    value,
                ),
            };
        });

        const allNotifications = newData.flatMap(
            (group) => group.notifications,
        );
        const allChecked = allNotifications.filter((not) => not.is_checked);

        dispatch(notificationsActions[UPDATE_NOTIFICATIONS](newData));

        if (notification) {
            if (allChecked.length === 0 && callback) callback(false);
            if (allChecked.length === allNotifications.length && callback)
                callback(true);
        } else {
            if (callback)
                callback(allChecked.length === allNotifications.length);
        }
    };
