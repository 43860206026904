import * as selectors from '../selectors';

import initializeForm from './initializeForm';
import showModal from './showModal';

export default (id) => (dispatch, getState) => {
    const state = getState();
    const supportContacts = selectors.getDataSupportContacts(state);
    const supportContact = supportContacts.find(
        (supportContact) => supportContact.id === id,
    );

    const telephones = supportContact.telephones.map(
        ({ extension, phone_key, phone_type, telephone }) => ({
            extension,
            phone_key,
            phone_type,
            telephone,
        }),
    );

    const emails = supportContact.emails.map(({ email, email_type }) => ({
        email,
        email_type,
    }));

    const values = {
        company_name: supportContact.company_name,
        company_position: supportContact.company_position,
        emails: emails,
        first_lastname: supportContact.first_lastname,
        id: supportContact.id,
        name: supportContact.name,
        second_lastname: supportContact.second_lastname,
        telephones: telephones,
    };

    dispatch(initializeForm(values));
    dispatch(showModal(true));
};
