import i18next from 'i18next';

import { createItem } from 'common/api/v2/consumptionProfiles';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_CONSUMPTION_PROFILE,
    SAVE_CONSUMPTION_PROFILE_FAILURE,
    SAVE_CONSUMPTION_PROFILE_SUCCESS,
} from '../actionTypes';
import { consumptionProfilesActions } from '../reducer';
import * as selectors from '../selectors';

import fetchConsumptionProfiles from './fetchConsumptionProfiles';
import setModalIsOpen from './setModalIsOpen';

export default (values) => (dispatch, getState) => {
    const newValues = JSON.stringify({
        consumption: values.consumption,
        name: values.name,
        usage_type: values.usage_type,
        year: values.year,
    });

    dispatch(consumptionProfilesActions[SAVE_CONSUMPTION_PROFILE]());
    createItem(newValues)
        .then((response) => {
            const filterQuery = selectors.getFilterQuery(getState());
            dispatch(fetchConsumptionProfiles(filterQuery));
            dispatch(setModalIsOpen(false));
            showToast({
                body: i18next.t('Changes were saved'),
            });
            dispatch(
                consumptionProfilesActions[SAVE_CONSUMPTION_PROFILE_SUCCESS](
                    response.data.data,
                ),
            );
        })
        .catch((error) => {
            dispatch(
                consumptionProfilesActions[SAVE_CONSUMPTION_PROFILE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
