import isEqual from 'lodash/isEqual';

import { updateItem } from 'common/api/v2/offerPanel';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import * as proposalGeneratorQuoteSelectors from '../../proposalGeneratorQuote/selectors';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { energySavingActions } from '../reducer';

import preventQuantityInZero from './preventQuantityInZero';

export default (proposalId, values) => (dispatch, getState) => {
    if (!values.panel || isEqual(values.quantity, values.tempQuantity))
        return false;
    if (isNaN(values.quantity) || parseInt(values.quantity) === 0) {
        dispatch(preventQuantityInZero(values.id, proposalId));
        return false;
    }

    const state = getState();
    const offerPanelExpansion =
        proposalGeneratorQuoteSelectors.getOfferPanelExpansion(state);

    const newValues = {
        expansion: offerPanelExpansion,
        id: values.id,
        quantity: Math.ceil(values.quantity),
    };
    dispatch(energySavingActions[SAVE]());

    updateItem(newValues, proposalId)
        .then((response) => {
            dispatch(energySavingActions[SAVE_SUCCESS](response.data.data));
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                energySavingActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
