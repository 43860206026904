import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_BRANCHES,
    FETCH_BRANCHES_FAILURE,
    FETCH_BRANCHES_SUCCESS,
    RESET,
    SELECT_BRANCH,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    branches: {
        data: [],
        error: null,
        isFetching: false,
    },
    branchSelected: null,
};

const multiBranchesSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_BRANCHES]: (state) => {
            state.branches = {
                ...state.branches,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_BRANCHES_FAILURE]: (state, action) => {
            state.branches = {
                ...state.branches,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BRANCHES_SUCCESS]: (state, action) => {
            state.branches = {
                ...state.branches,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [SELECT_BRANCH]: (state, action) => {
            state.branchSelected = action.payload;
        },
    },
});

export const multiBranchesActions = multiBranchesSlice.actions;

export default multiBranchesSlice.reducer;
