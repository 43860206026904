import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Button,
    Card,
    Collapse,
    Menu,
    MenuItem,
    Typography,
} from 'sunwise-ui';

import EmptyState from 'common/components/EmptyState';
import { numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import EmptyLayout from 'resources/EmptyLayout.png';

import {
    orientations,
    POLYGON_MAP,
    POLYGON_OBSTACLE,
    POLYGON_SEGMENT,
} from '../constants';
import { getDictionaryById, handleOpenChangeSegments } from '../helpers';
import * as selectors from '../selectors';

import FilterSegments from './FilterSegments';
import MenuOptions from './MenuOptions';
import SegmentEditCard from './SegmentEditCard';

const filterSegments = (segments, selectedType) => {
    if (selectedType === 'all') return segments;
    return segments.filter(
        (segment) => segment.type === parseInt(selectedType),
    );
};

const SegmentsListCard = ({
    commercialOfferId,
    handleClickAdd,
    handleClickDelete,
    handleClickEditSegment,
    handleOnDuplicateSegment,
    handleSelectSegment,
    initialValues,
    isDrawingMode,
    isEditionMode,
    mapRef,
    offerPanels,
    onCloseDrawer,
    segments,
    selectedSegmentId,
    toggleLockSegment,
    updateSegments,
}) => {
    const { t } = useTranslation();
    const isDisabled = isDrawingMode || isEditionMode;
    const [segmentsList, setSegmentsList] = useState([]);
    const [selectedType, setSelectedType] = useState('all');
    const offerPanelsDictionary = getDictionaryById(offerPanels);
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        const filteredSegments = filterSegments(segments, selectedType);
        setSegmentsList(filteredSegments);
    }, [segments]);

    useEffect(() => {
        if (!isEmpty(initialValues)) {
            const filteredSegments = filterSegments(segments, 'all');
            setSegmentsList(filteredSegments);
            setSelectedType('all');
        }
    }, [initialValues]);

    if (segments?.length === 0) {
        return (
            <EmptyState
                customCaption={
                    <Typography fontWeight="bold" variant="body2">
                        {t("Looks like you don't have segments yet. Add one!")}
                    </Typography>
                }
                onClick={() => handleClickAdd()}
                sizeImage={160}
                srcImage={EmptyLayout}
                sxCard={{
                    border: 0,
                    boxShadow: 'none !important',
                }}
                textButton={t('Add segment')}
            />
        );
    }

    const polygonContent = ({ item }) => (
        <>
            <Typography variant="body2">
                {t('Total area')}{' '}
                {numberFormat(item?.total_area, {
                    decimals: 2,
                    locale: countryCurrencyLocale,
                    style: 'decimal',
                })}
                m<sup>2</sup>
            </Typography>
            <Typography variant="body2">
                {offerPanelsDictionary[item.solar_module]?.name || 'N/A'}
            </Typography>
            <Typography variant="body2">
                {t('Panel', {
                    count: parseInt(item?.quantity_enabled_modules),
                })}
                : {item.quantity_enabled_modules} u
            </Typography>
            <Typography variant="body2">
                {t('Orientation')}: {t(orientations[item?.orientation])}
            </Typography>
            <Typography variant="body2">
                {t('Azimuth')}: {item?.azimuth}°
            </Typography>
            <Typography variant="body2">
                {t('Tilt')}: {item?.tilt}°
            </Typography>
        </>
    );

    const obstacleContent = ({ item }) => (
        <>
            <Typography variant="body2">
                {t('Total area')}{' '}
                {numberFormat(item?.total_area, {
                    decimals: 2,
                    locale: countryCurrencyLocale,
                    style: 'decimal',
                })}
                m<sup>2</sup>
            </Typography>
            <Typography variant="body2">
                {t('Height')}: {item?.height}m
            </Typography>
        </>
    );

    const renderContent = ({ item }) => {
        if (item.type === POLYGON_SEGMENT) return polygonContent({ item });
        if (item.type === POLYGON_OBSTACLE) return obstacleContent({ item });
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleOnClickAdd = (type) => {
        handleClickAdd({ type });
        setAnchorEl(null);
    };

    return (
        <>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ width: '100%' }}>
                    <Button
                        color="secondary"
                        disabled={isDisabled}
                        fullWidth
                        onClick={handleClick}
                        startIcon={<AddIcon />}
                        variant="outlined"
                    >
                        {t('Add')}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        onClose={handleClose}
                        open={open}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        PaperProps={{
                            sx: { width: anchorEl?.clientWidth || 0 },
                        }}
                    >
                        <MenuItem
                            onClick={() => handleOnClickAdd(POLYGON_SEGMENT)}
                        >
                            {t('Segment')}
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleOnClickAdd(POLYGON_OBSTACLE)}
                        >
                            {t('Obstacle')}
                        </MenuItem>
                        <MenuItem onClick={() => handleOnClickAdd(POLYGON_MAP)}>
                            {t('Image')}
                        </MenuItem>
                    </Menu>
                </Box>

                <FilterSegments
                    segments={segments}
                    selectedType={selectedType}
                    setSegmentsList={setSegmentsList}
                    setSelectedType={setSelectedType}
                />
            </Box>

            {segmentsList.map((item) => (
                <Card key={item.id} sx={{ mt: 2 }}>
                    <Card.Body>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                                onClick={() => {
                                    if (!isDisabled) {
                                        handleSelectSegment(item, segments);
                                        handleOpenChangeSegments({
                                            item,
                                            segments,
                                            updateSegments,
                                        });
                                    }
                                }}
                                sx={{
                                    cursor: 'pointer',
                                    flexGrow: 1,
                                    width: '80%',
                                }}
                            >
                                <Typography
                                    fontWeight="bold"
                                    noWrap
                                    sx={{
                                        fontSize: '14px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                    variant="body2"
                                >
                                    {item.locked && (
                                        <LockIcon sx={{ fontSize: 14 }} />
                                    )}{' '}
                                    {item.name}
                                </Typography>
                            </Box>

                            <Box sx={{ ml: 'auto' }}>
                                <MenuOptions
                                    commercialOfferId={commercialOfferId}
                                    handleClickDelete={handleClickDelete}
                                    handleOnDuplicateSegment={
                                        handleOnDuplicateSegment
                                    }
                                    handleClickEditSegment={
                                        handleClickEditSegment
                                    }
                                    isDisabled={isDisabled}
                                    isHidden={
                                        item.id !== selectedSegmentId ||
                                        !isEmpty(initialValues)
                                    }
                                    item={item}
                                    toggleLockSegment={toggleLockSegment}
                                />
                            </Box>
                        </Box>

                        <Collapse
                            key={`collapse-${item.id}-${item.open}`}
                            in={
                                item.open ||
                                (!isEmpty(initialValues) &&
                                    item.id === initialValues.id)
                            }
                            unmountOnExit
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    cursor: 'pointer',
                                }}
                            >
                                {isEmpty(initialValues) &&
                                    renderContent({ item })}

                                {!isEmpty(initialValues) &&
                                    item.id === initialValues.id && (
                                        <SegmentEditCard
                                            commercialOfferId={
                                                commercialOfferId
                                            }
                                            mapRef={mapRef}
                                            offerPanels={offerPanels}
                                            onCloseDrawer={onCloseDrawer}
                                        />
                                    )}
                            </Box>
                        </Collapse>
                    </Card.Body>
                </Card>
            ))}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

SegmentsListCard.propTypes = {
    commercialOfferId: PropTypes.string,
    handleClickAdd: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickEditSegment: PropTypes.func,
    handleOnDuplicateSegment: PropTypes.func,
    handleSelectSegment: PropTypes.func,
    initialValues: PropTypes.object,
    isDrawingMode: PropTypes.bool,
    isEditionMode: PropTypes.bool,
    mapRef: PropTypes.object,
    offerPanels: PropTypes.array,
    onCloseDrawer: PropTypes.func,
    segments: PropTypes.array,
    selectedSegmentId: PropTypes.string,
    toggleLockSegment: PropTypes.func,
    updateSegments: PropTypes.func,
};

export default connect(mapStateToProps, null)(SegmentsListCard);
