import React from 'react';

import PropTypes from 'prop-types';
import { editionLevels, TemplateView } from 'sunwise-template-core';
import { Box, Skeleton } from 'sunwise-ui';

const TEMPLATE_PAGE_HEIGHT = 1122.52;
const TEMPLATE_PAGE_WIDTH = 793.7;

const TemplateSection = ({ editionDisabled, isLoading }) => {
    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" mt={2} px={2}>
                <Skeleton
                    height={TEMPLATE_PAGE_HEIGHT}
                    variant="rounded"
                    width={TEMPLATE_PAGE_WIDTH}
                />
            </Box>
        );
    }

    return (
        <TemplateView
            editionLevel={
                editionDisabled
                    ? editionLevels.NO_EDITION_MODE
                    : editionLevels.PARTIAL_EDITION_MODE
            }
        />
    );
};

TemplateSection.propTypes = {
    editionDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
};

export default TemplateSection;
