import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

const DACAlerts = ({ hasDACRateAlert, hasDomesticRateAlert }) => {
    const { t } = useTranslation();

    if (!hasDACRateAlert && !hasDomesticRateAlert) return null;

    return (
        <>
            {hasDACRateAlert && (
                <Alert severity="warning">
                    {t('The consumptions entered do not represent a DAC rate')}
                </Alert>
            )}

            {hasDomesticRateAlert && (
                <Alert severity="warning">
                    {t('The consumptions entered represent a DAC rate')}
                </Alert>
            )}
        </>
    );
};

DACAlerts.propTypes = {
    hasDACRateAlert: PropTypes.bool,
    hasDomesticRateAlert: PropTypes.bool,
};

export default DACAlerts;
