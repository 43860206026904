import i18next from 'i18next';
import get from 'lodash/get';

export const getCatalogFields = (catalogs, proposalsNumber) =>
    catalogs
        .filter((item) => item.order <= proposalsNumber)
        .map((item) => ({
            company_catalog: item.id,
            name: item.name,
            commercial_offer: null,
        }));

export const getCommercialOffersToSelect = (commercialOffers) => [
    {
        label: i18next.t('Select proposal'),
        value: '',
        disabled: true,
    },
    ...commercialOffers.map((commercialOffer) => ({
        label: commercialOffer.name,
        value: get(commercialOffer, 'commercial_offer[0].id', null),
    })),
];

export const getGeneratedCommercialOffers = (commercialOffers) => {
    return commercialOffers.filter(
        (commercialOffer) =>
            !get(commercialOffer, 'commercial_offer[0].is_archived', false) &&
            get(commercialOffer, 'commercial_offer[0].status', 0) === 1,
    );
};

export const getTemplatesToSelect = (templates) => [
    {
        label: i18next.t('Select a template'),
        value: '',
        disabled: true,
    },
    ...templates.map((item) => ({
        label: item.title,
        value: item.id,
        proposalsNumber: item.proposals_number,
    })),
];

export const getRequirements = (isLoading, commercialOffers, templates) => {
    let data = [];
    if (isLoading) return [];
    if (commercialOffers.length <= 1) {
        data = [
            ...data,
            `${i18next.t('Minimum', {
                context: 'male',
            })} 2 ${i18next
                .t('Generated proposal', { count: 2 })
                .toLowerCase()}`,
        ];
    }
    if (templates.length === 0) {
        data = [
            ...data,
            `${i18next.t('Minimum', {
                context: 'male',
            })} 1 ${i18next.t('Summary template').toLowerCase()}`,
        ];
    }
    return data;
};

export const hasDuplicates = (data) => {
    const dataFiltered = data
        .filter((item) => item.commercial_offer !== null)
        .map((item) => item.commercial_offer);

    let result = false;
    const s = new Set(dataFiltered);
    if (dataFiltered.length !== s.size) {
        result = true;
    }
    return result;
};
