import React from 'react';

import PropTypes from 'prop-types';
import { Box, Skeleton } from 'sunwise-ui';

const renderCols = (numCols) => {
    const cols = [];

    for (let i = 0; i < numCols; i++) {
        cols.push(
            <Box
                key={`placeholder-Grid-card-contact-${i}`}
                sx={{ px: 2, py: 1 }}
            >
                {' '}
                <Skeleton variant="rounded" />
            </Box>,
        );
    }

    return cols;
};

const Placeholder = <>{renderCols(5)}</>;

const PlaceholderCardContactsDashboard = ({ children, ready }) => (
    <>{ready ? Placeholder : children}</>
);

PlaceholderCardContactsDashboard.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
};

export default PlaceholderCardContactsDashboard;
