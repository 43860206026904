import { getTrackings } from 'common/api/v1/contactTrackings';

import {
    FETCH_TRACKINGS_NEXT,
    FETCH_TRACKINGS_NEXT_FAILURE,
    FETCH_TRACKINGS_NEXT_SUCCESS,
} from '../actionTypes';
import { ORDER_BY_NEXT } from '../constants';
import { trackingsActions } from '../reducer';
import * as selectors from '../selectors';

export default (type = null) =>
    (dispatch, getState) => {
        const state = getState();
        const contactId = selectors.getContactId(state);

        dispatch(trackingsActions[FETCH_TRACKINGS_NEXT]());

        const data = {
            contact: contactId,
        };

        if (type !== null) {
            data.type = type;
        }

        data.order = ORDER_BY_NEXT;

        getTrackings(data)
            .then((response) =>
                dispatch(
                    trackingsActions[FETCH_TRACKINGS_NEXT_SUCCESS](
                        response.data.data,
                    ),
                ),
            )
            .catch((error) =>
                dispatch(trackingsActions[FETCH_TRACKINGS_NEXT_FAILURE](error)),
            );
    };
