import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';

import { getHasBagEnergyField, getSectionHasErrors } from '../../helpers';
import * as selectors from '../../selectors';

import FormAccordion from './FormAccordion';

const NetMeteringFields = ({
    compensationScheme,
    control,
    disabled,
    errors,
    isFetchingAvailableBag,
    rate,
}) => {
    const { t } = useTranslation();

    if (!getHasBagEnergyField(rate, compensationScheme)) return null;

    return (
        <FormAccordion
            defaultExpanded
            hasFormErrors={getSectionHasErrors(errors, ['applied_bag'])}
            title={t('Net metering')}
        >
            <Grid container>
                <Grid
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    gap={{ xs: 0, sm: 2 }}
                    item
                    md={9}
                    xs={18}
                >
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="kWh"
                        control={control}
                        disabled={disabled || isFetchingAvailableBag}
                        fullWidth
                        label={t('Consumed pool')}
                        min={0}
                        name="applied_bag"
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </FormAccordion>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingAvailableBag: selectors.getIsFetchingAvailableBag,
});

NetMeteringFields.propTypes = {
    compensationScheme: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    isFetchingAvailableBag: PropTypes.bool,
    rate: PropTypes.object,
};

export default connect(mapStateToProps)(NetMeteringFields);
