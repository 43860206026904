import React, { useEffect, useState } from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { get, isEmpty, toNumber } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Collapse } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { cleanJSON } from 'common/utils/helpers';

import proposalGeneratorAccessoryTable from '../proposalGeneratorAccessoryTable';
import proposalGeneratorAdditionalTable from '../proposalGeneratorAdditionalTable';
import proposalGeneratorEnergyStorageTable from '../proposalGeneratorEnergyStorageTable';
import proposalGeneratorInverterTable from '../proposalGeneratorInverterTable';
import proposalGeneratorPanelTable from '../proposalGeneratorPanelTable';
import proposalGeneratorStructureTable from '../proposalGeneratorStructureTable';
import proposalGeneratorTaxesTable from '../proposalGeneratorTaxesTable';
import proposalGeneratorWorkforceTable from '../proposalGeneratorWorkforceTable';

import * as actions from './actions';
import DonutChart from './components/DonutChart';
import SeeMoreButton from './components/SeeMoreButton';
import Totals from './components/Totals';
import * as selectors from './selectors';

const Container = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    getPermissionsByCode,
    infonavitData,
    isFetchingOfferProducts,
    isFetchingProposalCatalogs,
    isLoading,
    isLocked,
    isUpdatingAccessories,
    isUpdatingAdditionals,
    isUpdatingInverters,
    isUpdatingPanel,
    isUpdatingStructures,
    isUpdatingTaxes,
    isUpdatingWorkforce,
    offerDiscount,
    offerProductsData,
    priceByWatt,
    productsTotals,
    proposalId,
    systemSize,
    totalAccessories,
    totalAdditionals,
    totalEnergyStorage,
    totalInverters,
    totalPanels,
    totalStructure,
    totalWorkforce,
    typeChange,
    updateProductsTotals,
}) => {
    const { t } = useTranslation();

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [infonavitJsonData, setInfonavitJsonData] = useState({});
    const [infonavitAvailableBalance, setInfonavitAvailableBalance] =
        useState(0);

    const hasDiscount = offerDiscount !== 0;

    useEffect(() => {
        updateProductsTotals(infonavitAvailableBalance);
    }, [infonavitAvailableBalance, offerProductsData]);

    useEffect(() => {
        if (infonavitData) {
            const jsonData = JSON.parse(cleanJSON(infonavitData));
            setInfonavitJsonData(jsonData);
            setInfonavitAvailableBalance(
                toNumber(get(jsonData, 'available_balance', 0)),
            );
        }
    }, [infonavitData]);

    const {
        discount,
        profit,
        profitPercentage,
        subtotal,
        subtotalWithDiscount,
        total,
        totalCost,
    } = productsTotals;

    const { canModify: canModifyCost } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
    );
    const { canModify: canModifyMargin } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    );

    const isFetching =
        isLoading ||
        isFetchingOfferProducts ||
        isFetchingProposalCatalogs ||
        isUpdatingAccessories ||
        isUpdatingAdditionals ||
        isUpdatingInverters ||
        isUpdatingPanel ||
        isUpdatingStructures ||
        isUpdatingTaxes ||
        isUpdatingWorkforce;

    const isLockedForm = isFetching || isLocked;

    return (
        <AccordionCard
            cardId="proposal_generator_quote_card"
            classNameCard="__userguiding_proposal_generator_quote_card"
            defaultExpanded={true}
            isLoading={isFetching}
            svgIcon={<AttachMoneyIcon sx={{ color: 'primary.main' }} />}
            title={t('Quotation')}
        >
            <proposalGeneratorPanelTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                proposalId={proposalId}
                typeChange={typeChange}
            />

            <proposalGeneratorInverterTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                proposalId={proposalId}
            />

            <proposalGeneratorEnergyStorageTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                proposalId={proposalId}
                typeChange={typeChange}
            />

            <proposalGeneratorAccessoryTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                proposalId={proposalId}
            />

            <proposalGeneratorStructureTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                proposalId={proposalId}
            />

            <proposalGeneratorWorkforceTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                proposalId={proposalId}
            />

            <proposalGeneratorAdditionalTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                isLoading={isFetching}
                isLocked={isLockedForm}
                proposalId={proposalId}
            />

            <Totals
                canModifyCost={canModifyCost}
                canModifyMargin={canModifyMargin}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                discount={discount}
                hasDiscount={hasDiscount}
                hasInfonavit={!isEmpty(infonavitJsonData)}
                infonavitAvailableBalance={infonavitAvailableBalance}
                isFetching={isFetching}
                isLocked={isLocked}
                profit={profit}
                profitPercentage={toNumber(profitPercentage)}
                proposalId={proposalId}
                subtotal={subtotal}
                subtotalWithDiscount={subtotalWithDiscount}
                total={total}
                totalCost={totalCost}
            />
            <Collapse in={isCollapsed}>
                <DonutChart
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    isFetching={isFetching}
                    priceByWatt={priceByWatt}
                    systemSize={systemSize}
                    totalAccessories={totalAccessories}
                    totalAdditionals={totalAdditionals}
                    totalEnergyStorage={totalEnergyStorage}
                    totalInverters={totalInverters}
                    totalPanels={totalPanels}
                    totalStructure={totalStructure}
                    totalWorkforce={totalWorkforce}
                    typeChange={typeChange}
                />
            </Collapse>
            <SeeMoreButton
                isCollapsed={isCollapsed}
                label={isCollapsed ? t('Hide chart') : t('See chart')}
                setIsCollapsed={setIsCollapsed}
            />
        </AccordionCard>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingOfferProducts: selectors.getIsFetchingOfferProducts,
    isFetchingProposalCatalogs: selectors.getIsFetchingProposalCatalogs,
    isUpdatingAccessories:
        proposalGeneratorAccessoryTable.selectors.getIsUpdating,
    isUpdatingAdditionals:
        proposalGeneratorAdditionalTable.selectors.getIsUpdating,
    isUpdatingInverters: proposalGeneratorInverterTable.selectors.getIsUpdating,
    isUpdatingPanel: proposalGeneratorPanelTable.selectors.getIsSaving,
    isUpdatingStructures:
        proposalGeneratorStructureTable.selectors.getIsUpdating,
    isUpdatingTaxes: proposalGeneratorTaxesTable.selectors.getIsSaving,
    isUpdatingWorkforce:
        proposalGeneratorWorkforceTable.selectors.getIsUpdating,
    offerProductsData: selectors.getOfferProductsData,
    productsTotals: selectors.getProductsTotals,
    totalAccessories: selectors.getFinalCostAccessories,
    totalAdditionals: selectors.getFinalCostAdditionals,
    totalEnergyStorage: selectors.getFinalCostEnergyStorage,
    totalInverters: selectors.getFinalCostInverters,
    totalPanels: selectors.getFinalCostPanels,
    totalStructure: selectors.getFinalCostStructures,
    totalWorkforce: selectors.getFinalCostWorkforce,
});

const mapDispatchToProps = (dispatch) => ({
    updateProductsTotals: (infonavitAvailableBalance) =>
        dispatch(actions.updateProductsTotals(infonavitAvailableBalance)),
});

Container.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    getPermissionsByCode: PropTypes.func,
    infonavitData: PropTypes.string,
    isFetchingOfferProducts: PropTypes.bool,
    isFetchingProposalCatalogs: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isUpdatingAccessories: PropTypes.bool,
    isUpdatingAdditionals: PropTypes.bool,
    isUpdatingInverters: PropTypes.bool,
    isUpdatingPanel: PropTypes.bool,
    isUpdatingStructures: PropTypes.bool,
    isUpdatingTaxes: PropTypes.bool,
    isUpdatingWorkforce: PropTypes.bool,
    offerDiscount: PropTypes.number,
    offerProductsData: PropTypes.object,
    priceByWatt: PropTypes.number,
    productsTotals: PropTypes.object,
    proposalId: PropTypes.string,
    systemSize: PropTypes.number,
    total: PropTypes.number,
    totalAccessories: PropTypes.number,
    totalAdditionals: PropTypes.number,
    totalEnergyStorage: PropTypes.number,
    totalInverters: PropTypes.number,
    totalPanels: PropTypes.number,
    totalStructure: PropTypes.number,
    totalWorkforce: PropTypes.number,
    typeChange: PropTypes.number,
    updateProductsTotals: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
        PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    ]),
)(Container);
