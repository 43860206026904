import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import {
    accesoryTypeToLabel,
    batteryTypeToLabel,
    inverterTypeToLabel,
    panelTypeToLabel,
} from 'common/utils/helpers';

import AddProductActionsFormatter from './AddProductActionsFormatter';

const AddProductColumns = ({
    branchOfficesDictionary,
    branchSelected,
    canModify,
    handleAddProductToBranches,
    productKey,
    productSelected,
    selectProduct,
    setProductBranchOfficesModalPosition,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const maxPowerLabel = () => {
        switch (productKey) {
            case 'batteries':
                return 'kWh';
            case 'inverters':
                return 'kW';
            default:
                return 'W';
        }
    };

    let columns = [
        {
            field: 'brand',
            flex: 1,
            headerName: t('Brand'),
            valueGetter: (params) => params.row.brand.name,
        },
        {
            field: 'code',
            flex: 1,
            headerName: t('Code'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption" fontWeight="bold">
                        {row.code}
                    </Typography>
                );
            },
        },
        {
            field: 'product_type',
            flex: 1,
            headerName: t('Type'),
            renderCell: (params) => {
                const { row } = params;
                switch (productKey) {
                    case 'accessories':
                        return accesoryTypeToLabel(row.product_type);
                    case 'batteries':
                        return batteryTypeToLabel(row.product_type);
                    case 'inverters':
                        return inverterTypeToLabel(row.product_type);
                    case 'panels':
                        return panelTypeToLabel(row.product_type);
                    default:
                        return null;
                }
            },
        },
        {
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption" fontWeight="bold">
                        {row.name}
                    </Typography>
                );
            },
        },
        {
            field: 'max_power',
            flex: 1,
            headerName: maxPowerLabel(),
            hide: productKey === 'accessories',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row.max_power} {maxPowerLabel()}
                    </Typography>
                );
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = [
            {
                field: 'name',
                flex: 1,
                headerName: t('Name'),
                renderCell: (params) => {
                    const { row } = params;
                    return (
                        <Typography variant="caption" fontWeight="bold">
                            {row.name}
                        </Typography>
                    );
                },
            },
        ];
    }

    columns = [
        ...columns,
        {
            field: '',
            flex: 1,
            headerName: t('Add/View'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <AddProductActionsFormatter
                        branchOfficesDictionary={branchOfficesDictionary}
                        branchSelected={branchSelected}
                        canModify={canModify}
                        handleAddProductToBranches={handleAddProductToBranches}
                        productSelected={productSelected}
                        row={row}
                        selectProduct={selectProduct}
                        setProductBranchOfficesModalPosition={
                            setProductBranchOfficesModalPosition
                        }
                    />
                );
            },
            sortable: false,
        },
    ];

    return columns;
};

AddProductColumns.propTypes = {
    branchOfficesDictionary: PropTypes.object,
    branchSelected: PropTypes.string,
    canModify: PropTypes.bool,
    handleAddProductToBranches: PropTypes.func,
    productKey: PropTypes.string,
    productSelected: PropTypes.object,
    selectProduct: PropTypes.func,
    setProductBranchOfficesModalPosition: PropTypes.func,
};

export default AddProductColumns;
