import { types } from 'sunwise-template-core';

import fetchCatalogs from '../../listSettings/actions/fetchCatalogs';
import { PROPOSAL_SUMMARY_TYPE } from '../../listSettings/constants';
import {
    fetchLanguages,
    fetchTemplateCategories,
} from '../../templateLibrary/actions';
import {
    INITIALIZE,
    INITIALIZE_SUCCESS,
    INITIALIZE_FAILURE,
} from '../actionTypes';
import { editTemplateViewActions } from '../reducer';

import fetchCustomTemplate from './fetchCustomTemplate';

export default (id, initTemplate, templateType) => async (dispatch) => {
    dispatch(editTemplateViewActions[INITIALIZE]());

    let fetchList = [
        dispatch(fetchTemplateCategories()),
        dispatch(fetchLanguages()),
    ];

    if (templateType == types.MULTIPROPOSAL_TYPE)
        fetchList.push(fetchCatalogs(PROPOSAL_SUMMARY_TYPE));

    Promise.all(fetchList)
        .then(async () => {
            const templateData = await dispatch(fetchCustomTemplate(id));
            if (templateData) initTemplate(templateData);
            dispatch(editTemplateViewActions[INITIALIZE_SUCCESS]());
        })
        .catch(() => {
            dispatch(editTemplateViewActions[INITIALIZE_FAILURE]());
        });
};
