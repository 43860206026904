import { get, isEmpty } from 'lodash';

import { getTableSettings } from 'common/api/v1/core';

import {
    FETCH_TABLE_SETTINGS,
    FETCH_TABLE_SETTINGS_FAILURE,
    FETCH_TABLE_SETTINGS_SUCCESS,
} from '../actionTypes';
import { handleBuildColumns, handleFormatterColumns } from '../helpers';
import { tableSettingsActions } from '../reducer';

export default (tableKey, columnsConfig = {}) =>
    (dispatch) => {
        dispatch(tableSettingsActions[FETCH_TABLE_SETTINGS]());

        getTableSettings(tableKey)
            .then((response) => {
                const data = get(response, 'data', {});
                let columns = get(data, 'settings', []);
                if (isEmpty(columns)) {
                    columns = handleFormatterColumns(columnsConfig);
                } else {
                    columns = handleBuildColumns(columnsConfig, columns);
                }
                dispatch(
                    tableSettingsActions[FETCH_TABLE_SETTINGS_SUCCESS](columns),
                );
            })
            .catch((error) =>
                dispatch(
                    tableSettingsActions[FETCH_TABLE_SETTINGS_FAILURE](error),
                ),
            );
    };
