import i18next from 'i18next';
import { get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import {
    CONTRACT_TYPE,
    DOCUMENTS_TYPE,
    INSURANCE_DOCUMENTS_TYPE,
    REQUEST_FORM_TYPE,
} from 'common/constants';
import orm from 'common/orm';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getEntitiesSession = createSelector(getModel, (state) =>
    orm.session(state.entities),
);

export const getCommercialOfferInitialValues = createSelector(
    getModel,
    (model) => model.commercialOfferInitialValues,
);

export const getCreateOffer = createSelector(
    getModel,
    (model) => model.createOffer,
);

export const getCreateOfferIsSaving = createSelector(
    getCreateOffer,
    (createOffer) => createOffer.isSaving,
);

export const getCreateOfferData = createSelector(
    getCreateOffer,
    (createOffer) => createOffer.data,
);

export const getCreateOfferErrors = createSelector(
    getCreateOffer,
    (createOffer) => createOffer.errors,
);

export const getCommercialOffers = createSelector(
    getModel,
    (model) => model.commercialOffers,
);

export const getIsFetchingCommercialOffers = createSelector(
    getCommercialOffers,
    (value) => value.isFetching,
);

export const getCommercialOffersData = createSelector(
    getCommercialOffers,
    (value) => value.data,
);

export const getCommercialOffersErrors = createSelector(
    getCommercialOffers,
    (value) => value.errors,
);

export const getCreditDocuments = createSelector(
    getModel,
    (model) => model.creditDocuments,
);

export const getCreditDocumentsIsFetching = createSelector(
    getCreditDocuments,
    (offers) => offers.isFetching,
);

export const getCreditDocumentsData = createSelector(
    getCreditDocuments,
    (creditDocuments) => {
        if (isEmpty(creditDocuments.data)) return [];
        return creditDocuments.data;
    },
);

export const getDocumentsList = createSelector(
    getCreditDocumentsData,
    (model) => model.filter((item) => item.type === DOCUMENTS_TYPE),
);

export const getRequestFormDocumentsList = createSelector(
    getCreditDocumentsData,
    (model) => model.filter((item) => item.type === REQUEST_FORM_TYPE),
);

export const getInsuranceDocumentsList = createSelector(
    getCreditDocumentsData,
    (model) => model.filter((item) => item.type === INSURANCE_DOCUMENTS_TYPE),
);

export const getContractsList = createSelector(
    getCreditDocumentsData,
    (model) => model.filter((item) => item.type === CONTRACT_TYPE),
);

export const getCreditDocumentsErrors = createSelector(
    getCreditDocuments,
    (creditDocuments) => creditDocuments.errors,
);

export const getIsOpenCommercialOfferModal = createSelector(
    getModel,
    (model) => model.isOpenCommercialOfferModal,
);

export const getUploadingFile = createSelector(
    getModel,
    (model) => model.fileUploaded,
);

export const isFileUploading = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.isUploading,
);

export const getUploadedFileErrors = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.errors,
);

export const getLocationsByZipCode = createSelector(
    getModel,
    (model) => model.locationsByZipCode,
);

export const getIsFetchingLocationsByZipCode = createSelector(
    getLocationsByZipCode,
    (data) => data.isFetching,
);

export const getLocationsByZipCodeData = createSelector(
    getLocationsByZipCode,
    (data) => {
        const isMexicanAccount = getIsMexicanAccount();
        if (isEmpty(data.data)) return [];
        return [
            {
                label: `${
                    isMexicanAccount
                        ? i18next.t('City')
                        : i18next.t('Political division of order 2')
                } *`,
                value: '',
            },
            ...data.data.map((location) => ({
                label: get(location, 'municipality.name', ''),
                value: get(location, 'municipality.id', ''),
            })),
        ];
    },
);

/* Divisions */
export const getDivisions = createSelector(
    getModel,
    (model) => model.divisions,
);

export const getIsFetchingDivisions = createSelector(
    getDivisions,
    (divisions) => divisions.isFetching,
);

export const getDivisionsData = createSelector(
    getDivisions,
    (data) => data.data || [],
);

export const getDivisionsForSelect = createSelector(
    getDivisionsData,
    (dataValues) => [
        {
            label: i18next.t('Division').concat(' *'),
            disbaled: true,
            values: '',
        },
        ...dataValues.map((value) => ({
            label: value.name,
            value: value.id,
        })),
    ],
);

/* Political division 1 */
export const getPoliticalDivisions = createSelector(
    getModel,
    (model) => model.politicalDivisions,
);

export const getIsFetchingPoliticalDivisions = createSelector(
    getPoliticalDivisions,
    (data) => data.isFetching,
);

export const getPoliticalDivisionsData = createSelector(
    getPoliticalDivisions,
    (data) => data.data || [],
);

export const getPoliticalDivisionsForSelect = createSelector(
    getPoliticalDivisionsData,
    (dataValues) => {
        const isMexicanAccount = getIsMexicanAccount();
        return [
            {
                label: `${
                    isMexicanAccount
                        ? i18next.t('State')
                        : i18next.t('Political division of order 1')
                } *`,
                disbaled: true,
                values: '',
            },
            ...dataValues.map((value) => ({
                label: value.name,
                value: value.id,
            })),
        ];
    },
);

/* Political division 2 */
export const getPoliticalDivisions2 = createSelector(
    getModel,
    (model) => model.politicalDivisions2,
);

export const getIsFetchingPoliticalDivisions2 = createSelector(
    getPoliticalDivisions2,
    (data) => data.isFetching,
);

export const getPoliticalDivisions2Data = createSelector(
    getPoliticalDivisions2,
    (data) => data.data || [],
);

export const getPoliticalDivisions2ForSelect = createSelector(
    getPoliticalDivisions2Data,
    (dataValues) => {
        const isMexicanAccount = getIsMexicanAccount();
        return [
            {
                label: `${
                    isMexicanAccount
                        ? i18next.t('City')
                        : i18next.t('Political division of order 2')
                } *`,
                disbaled: true,
                values: '',
            },
            ...dataValues.map((value) => ({
                label: value.name,
                value: value.id,
            })),
        ];
    },
);

// DUPLICATE FORM MODAL
export const getIsOpenDuplicateModal = createSelector(
    getModel,
    (model) => model.isOpenDuplicateModal,
);

export const getDuplicateFormInitialValues = createSelector(
    getModel,
    (model) => model.duplicateFormInitialValues,
);

export const getDuplicateOffer = createSelector(
    getModel,
    (model) => model.duplicateOffer,
);

export const getIsDuplicatingOffer = createSelector(
    getDuplicateOffer,
    (values) => values.isDuplicating,
);

/* Views */
export const getViews = createSelector(getModel, (model) => model.fetchViews);

export const getIsFetchingViews = createSelector(
    getViews,
    (value) => value.isFetching,
);

export const getViewsData = createSelector(
    getViews,
    (value) => value.data || [],
);

export const getViewsErrors = createSelector(getViews, (value) => value.errors);

// PLANS

export const getFetchPlans = createSelector(
    getModel,
    (model) => model.fetchPlans,
);

export const getIsFetchingPlans = createSelector(
    getFetchPlans,
    (value) => value.isFetching,
);

export const getPlansData = createSelector(
    getFetchPlans,
    (value) => value.data || [],
);

export const getPlansForSelect = createSelector(getPlansData, (dataValues) => [
    {
        label: '',
        options: dataValues.map((value) => {
            return {
                label: value.name,
                value: value.id,
            };
        }),
    },
]);
