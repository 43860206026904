import React, { useState, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Typography,
} from 'sunwise-ui';

import { DiscountTypeGroup, IntlNumberInputField } from 'common/components';
import EmptyState from 'common/components/EmptyState';
import DropdownMenu from 'common/components/form/bootstrap/ReduxFieldArrayProposalItems/DropdownMenu';
import { DISCOUNT_TYPES } from 'common/constants';

import EmptyTaxes from 'resources/EmptyTaxes.svg';

import {
    getProductsToSelect,
    getTaxes,
    handleClickCancel,
    handleClickCreate,
    handleClickDelete,
    handleUpdate,
    isDisabledCreateButton,
    isDisabledFields,
    isInvalid,
    isVisibleCancelButton,
    isVisibleSaveButton,
} from '../helpers';

import DropdownProducts from './DropdownProducts';
import TaxesCancelButton from './TaxesCancelButton';
import TaxesCreateButton from './TaxesCreateButton';
import TaxesSaveButton from './TaxesSaveButton';

const TaxesList = ({
    canDelete,
    canModify,
    isFetchingItems,
    isSavingTaxes,
    prepareDelete,
    saveTaxes,
    taxes,
}) => {
    const { t } = useTranslation();
    const [companyTaxes, setCompanyTaxes] = useState([]);
    const [currentTax, setCurrentTax] = useState({ id: null });
    const [isEditing, setIsEditing] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        setCurrentTax({ id: null });
        setIsCreating(false);
        setIsEditing(false);
        setCompanyTaxes([...getTaxes(taxes)]);
    }, [taxes]);

    if (isFetchingItems) {
        return (
            <Typography variant="caption">
                {t('Loading tax', { count: 2 }).concat('...')}
            </Typography>
        );
    }

    if (isEmpty(companyTaxes)) {
        return (
            <EmptyState
                canModify={canModify}
                caption={t(
                    'Add your suppliers. You will see a complete list here where you can access them',
                )}
                classNameCard="border-0"
                onClick={() =>
                    handleClickCreate(
                        setCompanyTaxes,
                        setCurrentTax,
                        setIsCreating,
                        setIsEditing,
                        taxes,
                    )
                }
                srcImage={EmptyTaxes}
                sxCard={{ border: 0, boxShadow: 'none' }}
                textButton={t('Add')}
                title={t(
                    'Add to tax percentages. You will see a complete list here where you can access them',
                )}
            />
        );
    }

    return (
        <Box id="tax-container">
            {companyTaxes.map((item, index) => (
                <Grid
                    container
                    key={`tax-box-${index}`}
                    sx={{ mt: index > 0 ? 2 : 0 }}
                >
                    <Box
                        sx={{
                            display: {
                                xs: 'block',
                                md: 'none',
                            },
                            ml: 'auto',
                        }}
                    >
                        <DropdownMenu
                            handleClickDelete={() =>
                                handleClickDelete(
                                    companyTaxes,
                                    handleUpdate,
                                    item.id,
                                    index,
                                    isCreating,
                                    prepareDelete,
                                    setCompanyTaxes,
                                    setCurrentTax,
                                    setIsCreating,
                                )
                            }
                            isLocked={
                                item.isDeleting ||
                                !canModify ||
                                isDisabledFields(
                                    currentTax,
                                    isCreating,
                                    isEditing,
                                    item,
                                    taxes,
                                )
                            }
                        />
                    </Box>
                    <Grid item xs={18} lg={6}>
                        <TextField
                            error={isInvalid(item.tax_name)}
                            disabled={
                                !canModify ||
                                isDisabledFields(
                                    currentTax,
                                    isCreating,
                                    isEditing,
                                    item,
                                    taxes,
                                )
                            }
                            fullWidth
                            label={t('Name')}
                            name={`tax_name_${Math.random()}`}
                            onChange={(e) => {
                                handleUpdate(
                                    companyTaxes,
                                    index,
                                    'tax_name',
                                    e.target.value,
                                    setCompanyTaxes,
                                    setCurrentTax,
                                );
                            }}
                            onFocus={() => {
                                setIsEditing(true);
                                setCurrentTax(item);
                            }}
                            placeholder={t('Name of tax')}
                            value={item.tax_name}
                        />
                        <FormHelperText>
                            {item.isDeleting && t('Removing').concat('...')}
                        </FormHelperText>
                    </Grid>

                    <Grid item xs={18} md={9} lg={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <IntlNumberInputField
                                disabled={
                                    !canModify ||
                                    isDisabledFields(
                                        currentTax,
                                        isCreating,
                                        isEditing,
                                        item,
                                        taxes,
                                    )
                                }
                                fullWidth
                                label={t('Quantity')}
                                min={0}
                                name={`tax_value_${Math.random()}`}
                                onChange={(value) => {
                                    handleUpdate(
                                        companyTaxes,
                                        index,
                                        'tax_value',
                                        value,
                                        setCompanyTaxes,
                                        setCurrentTax,
                                    );
                                }}
                                onFocus={() => {
                                    setIsEditing(true);
                                    setCurrentTax(item);
                                }}
                                value={item.tax_value}
                            />
                            <DiscountTypeGroup
                                disabled={
                                    !canModify ||
                                    isDisabledFields(
                                        currentTax,
                                        isCreating,
                                        isEditing,
                                        item,
                                        taxes,
                                    )
                                }
                                handleOnChange={(value) => {
                                    const isPercentage = value === 1;
                                    handleUpdate(
                                        companyTaxes,
                                        index,
                                        'is_percentage',
                                        isPercentage,
                                        setCompanyTaxes,
                                        setCurrentTax,
                                    );
                                }}
                                selected={
                                    item.is_percentage
                                        ? DISCOUNT_TYPES.PERCENTAGE
                                        : DISCOUNT_TYPES.AMOUNT
                                }
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={18} md={9} lg={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                            }}
                        >
                            {item.is_percentage && (
                                <DropdownProducts
                                    companyTaxes={companyTaxes}
                                    disabled={
                                        !canModify ||
                                        isDisabledFields(
                                            currentTax,
                                            isCreating,
                                            isEditing,
                                            item,
                                            taxes,
                                        )
                                    }
                                    handleUpdate={handleUpdate}
                                    productsToSelect={getProductsToSelect()}
                                    setCompanyTaxes={setCompanyTaxes}
                                    setCurrentTax={setCurrentTax}
                                    setIsEditing={setIsEditing}
                                    taxIndex={index}
                                    taxItem={item}
                                />
                            )}
                            {canDelete && (
                                <Box
                                    sx={{
                                        ml: 1,
                                        display: {
                                            xs: 'none',
                                            md: 'block',
                                        },
                                    }}
                                >
                                    <IconButton
                                        disabled={
                                            item.isDeleting ||
                                            !canModify ||
                                            isDisabledFields(
                                                currentTax,
                                                isCreating,
                                                isEditing,
                                                item,
                                                taxes,
                                            )
                                        }
                                        onClick={() =>
                                            handleClickDelete(
                                                companyTaxes,
                                                handleUpdate,
                                                item.id,
                                                index,
                                                isCreating,
                                                prepareDelete,
                                                setCompanyTaxes,
                                                setCurrentTax,
                                                setIsCreating,
                                            )
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            ))}

            <Grid container mt={2}>
                <Grid item xs={18} textAlign="right">
                    <TaxesCancelButton
                        onClick={() =>
                            handleClickCancel(
                                companyTaxes,
                                currentTax,
                                isCreating,
                                setCompanyTaxes,
                                setCurrentTax,
                                setIsCreating,
                                setIsEditing,
                                taxes,
                            )
                        }
                        visible={
                            canModify &&
                            isVisibleCancelButton(currentTax, isEditing, taxes)
                        }
                    />
                    <TaxesSaveButton
                        disabled={
                            !canModify ||
                            isInvalid(currentTax.tax_name) ||
                            isInvalid(currentTax.tax_value) ||
                            isEmpty(currentTax.companies_taxes_elements)
                        }
                        onClick={() => {
                            saveTaxes(currentTax, false);
                        }}
                        isSavingTaxes={isSavingTaxes}
                        visible={
                            canModify &&
                            isVisibleSaveButton(
                                currentTax,
                                isCreating,
                                isEditing,
                                taxes,
                            )
                        }
                    />
                    {!isEditing && (
                        <TaxesCreateButton
                            disabled={
                                !canModify ||
                                isDisabledCreateButton(
                                    currentTax,
                                    isCreating,
                                    isEditing,
                                    taxes,
                                )
                            }
                            onClick={() =>
                                handleClickCreate(
                                    setCompanyTaxes,
                                    setCurrentTax,
                                    setIsCreating,
                                    setIsEditing,
                                    taxes,
                                )
                            }
                            isSavingTaxes={isSavingTaxes}
                            visible={canModify}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

TaxesList.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    isFetchingItems: PropTypes.bool,
    isSavingTaxes: PropTypes.bool,
    prepareDelete: PropTypes.func,
    saveTaxes: PropTypes.func,
    taxes: PropTypes.array,
};

export default TaxesList;
