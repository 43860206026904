import { getAllSunwiseProjects } from 'common/api/afterSales/projects';
import { getProjects } from 'common/api/v2/projectsByList';

import {
    FETCH_AFTER_SALES_PROJECTS,
    FETCH_AFTER_SALES_PROJECTS_FAILURE,
    FETCH_AFTER_SALES_PROJECTS_SUCCESS,
} from '../actionTypes';
import { powerStationsActions } from '../reducer';

export default (contactId) => (dispatch) => {
    dispatch(powerStationsActions[FETCH_AFTER_SALES_PROJECTS]());

    getAllSunwiseProjects()
        .then((response) => {
            const projects = response?.data || [];
            return projects.map((project) => project.id);
        })
        .then((projects) => {
            if (!projects.length) return null;
            return getProjects(projects, contactId);
        })
        .then((response) =>
            dispatch(
                powerStationsActions[FETCH_AFTER_SALES_PROJECTS_SUCCESS](
                    response?.data?.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                powerStationsActions[FETCH_AFTER_SALES_PROJECTS_FAILURE](error),
            ),
        );
};
