import React, { useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';
import {
    DEFAULT_INITIAL_DATE,
    DEFAULT_MONTH_FINAL_DATE,
} from 'common/constants';
import { useBreakpoint, useDebounce } from 'common/hooks';
import { differenceInWeeksDate, parseDate } from 'common/utils/dates';
import { getCurrencyIso } from 'common/utils/helpers';
import {
    getCompensationHasNettedExportedGeneration,
    getIsSelfConsumption,
} from 'common/utils/helpers/rates';
import {
    getProposalChartConfigEconomic,
    handleProposalEnergyChartConfig,
    buildChartJsProps,
} from 'common/utils/helpersChart';

import ChartControls from './ChartControls';
import PeriodSelector from './PeriodSelector';

const CHART_BASE_CONFIG = {
    ENERGY: { label: 'Energetic', name: 'chartEnergy' },
    CURRENT_ECONOMIC: {
        label: 'Economical',
        name: 'chartCurrentEconomic',
        visibilityLabel: 'Current economic scenario',
    },
    PROPOSED_ECONOMIC: {
        name: 'chartProposedEconomic',
        visibilityLabel: 'Proposed economic scenario',
    },
};
const tabsToShow = {
    ENERGY: CHART_BASE_CONFIG.ENERGY,
    CURRENT_ECONOMIC: CHART_BASE_CONFIG.CURRENT_ECONOMIC,
};
const stacksToSelect = [
    CHART_BASE_CONFIG.CURRENT_ECONOMIC,
    CHART_BASE_CONFIG.PROPOSED_ECONOMIC,
];
const DEBOUNCE_TIME = 500;

const ProposalChartJs = ({
    consumptionProjection,
    countryCurrency,
    countryCurrencyLocale,
    initialChartControlsConfig = {},
    proposalConfiguration,
    solarSimulationData,
}) => {
    const [chartConfigEnergy, setChartConfigEnergy] = useState({
        key: 'energy-chart-NETMET',
        options: {},
        series: [{ data: [], name: '' }],
    });
    const [chartConfigEconomic, setChartConfigEconomic] = useState({
        options: {},
        series: [{ data: [], name: '' }],
    });
    const [selectedStacks, setSelectedStacks] = useState('all');
    const [chartConfigGeneration, setChartConfigGeneration] = useState({
        options: {},
        series: [{ data: [], name: '' }],
    });
    const [chartId, setChartValue] = useState('chartEnergy');

    const [chartControlsConfig, setChartControlsConfig] = useState({
        days: [1],
        option: 0,
        period: 0,
        totalWeeks: 0,
        type: 'area',
        weeks: [0],
        year: 0,
        ...initialChartControlsConfig,
    });
    const [defaultVisibility, setDefaultVisibility] = useState({});
    const breakpoint = useBreakpoint();
    const chartRef = useRef(null);
    const container = useRef(null);
    const debouncedConsumptionProjectionData = useDebounce(
        consumptionProjection,
        DEBOUNCE_TIME,
    );
    const { t } = useTranslation();
    const countryCurrencyIso = getCurrencyIso(countryCurrency);
    const isSelfConsumption = getIsSelfConsumption(
        proposalConfiguration?.compensation_scheme,
    );
    const hasNettedExportedGeneration =
        getCompensationHasNettedExportedGeneration(
            proposalConfiguration?.compensation_scheme,
        );

    const {
        consumptionHistory = [],
        realIndex = {},
        zeroExport,
    } = debouncedConsumptionProjectionData;

    useEffect(() => {
        if (!isEmpty(initialChartControlsConfig)) {
            const totalWeeks = getTotalWeeksInPeriod(
                initialChartControlsConfig?.period ??
                    chartControlsConfig?.period,
            );

            const newChartControlsConfig = {
                ...chartControlsConfig,
                ...initialChartControlsConfig,
                totalWeeks,
            };
            setChartControlsConfig(newChartControlsConfig);
            handleChartChange(newChartControlsConfig);
        }
    }, [initialChartControlsConfig]);

    useEffect(() => {
        handleChartChange(chartControlsConfig);
    }, [solarSimulationData]);

    useEffect(() => {
        if (consumptionHistory?.length) {
            const newYear =
                chartControlsConfig?.year < consumptionHistory?.length
                    ? chartControlsConfig?.year
                    : 0;

            const newChartControlsConfig = {
                ...chartControlsConfig,
                year: newYear || 0,
            };

            setChartControlsConfig(newChartControlsConfig);
            handleChartChangeEconomic(chartControlsConfig.year);
            handleChartChange(newChartControlsConfig);
        }
    }, [consumptionHistory]);

    const getTotalWeeksInPeriod = (period) => {
        const finalDate = consumptionHistory?.[0]?.[period]?.final_date;
        const initialDate = consumptionHistory?.[0]?.[period]?.initial_date;
        const diff = differenceInWeeksDate(
            parseDate(finalDate || DEFAULT_MONTH_FINAL_DATE, 'dd/MM/yyyy'),
            parseDate(initialDate || DEFAULT_INITIAL_DATE, 'dd/MM/yyyy'),
        );
        return (Math.max(diff, 0) || 0) + 1;
    };

    const handleChartChange = (configOptions) => {
        let totalWeeks = configOptions?.totalWeeks;
        if (!totalWeeks) {
            totalWeeks = getTotalWeeksInPeriod(configOptions.period);
            setChartControlsConfig({ ...configOptions, totalWeeks });
        }
        const { key, options, series, type } = handleProposalEnergyChartConfig({
            configOptions,
            consumptionHistory,
            countryCurrencyLocale,
            hasNettedExportedGeneration:
                hasNettedExportedGeneration && configOptions.option === 0,
            isSelfConsumption,
            realIndex,
            solarSimulationData,
            zeroExport,
        });
        setChartConfigEnergy(() => ({ key, options, series, type }));
    };

    const handleChartChangeEconomic = (year) => {
        const yearCategories = consumptionHistory[year]?.map(
            (month) => month.label,
        );
        const newChartConfigEconomic = getProposalChartConfigEconomic({
            consumptionHistory: consumptionHistory[year],
            countryCurrencyIso,
            countryCurrencyLocale,
            infoKey: 'infoPriceConsumption',
            yearCategories,
        });
        const newChartConfigGeneration = getProposalChartConfigEconomic({
            consumptionHistory: consumptionHistory[year],
            countryCurrencyIso,
            countryCurrencyLocale,
            infoKey: 'infoPriceGeneration',
            yearCategories,
        });
        setChartConfigEconomic({
            ...newChartConfigEconomic,
            key: 'chartEconomic',
        });
        setChartConfigGeneration({
            ...newChartConfigGeneration,
            key: 'chartGeneration',
        });
    };

    const handleOnChangeSelectedYear = (e) => {
        const value = parseInt(e.target.value);
        const newChartControlsConfig = { ...chartControlsConfig, year: value };

        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
        handleChartChangeEconomic(value);
    };

    const handleOnChangeOption = (e) => {
        const value = parseInt(e.target.value);
        let newChartControlsConfig = { ...chartControlsConfig, option: value };

        if ([2, 3].includes(value))
            newChartControlsConfig.totalWeeks = getTotalWeeksInPeriod(
                chartControlsConfig.period,
            );

        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
    };

    const handleOnChangeSelectedPeriod = (e) => {
        const value = parseInt(e.target.value);
        let newChartControlsConfig = { ...chartControlsConfig, period: value };

        if ([2, 3].includes(chartControlsConfig.option))
            newChartControlsConfig.totalWeeks = getTotalWeeksInPeriod(value);

        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
    };

    const handleOnChangeSelectedWeeks = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all') {
            const { totalWeeks, weeks } = chartControlsConfig;
            value =
                weeks.length === totalWeeks
                    ? []
                    : [...Array(totalWeeks).keys()];
        }

        const newChartControlsConfig = { ...chartControlsConfig, weeks: value };

        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
    };

    const handleOnChangeSelectedDays = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value =
                chartControlsConfig.days.length === 7
                    ? []
                    : [0, 1, 2, 3, 4, 5, 6];

        const newChartControlsConfig = { ...chartControlsConfig, days: value };

        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
    };

    const handleOnChangeChartType = (value) => {
        const newChartControlsConfig = { ...chartControlsConfig, type: value };

        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
    };

    const handleOnChangeVisibility = (values) => {
        if (!chartId) return;
        setDefaultVisibility((prev) => ({ ...prev, [chartId]: values }));
    };

    const chartConfig = {
        [CHART_BASE_CONFIG.CURRENT_ECONOMIC.name]: chartConfigEconomic,
        [CHART_BASE_CONFIG.ENERGY.name]: chartConfigEnergy,
        [CHART_BASE_CONFIG.PROPOSED_ECONOMIC.name]: chartConfigGeneration,
    };

    const currentChartConfig = chartConfig[chartId];
    const isDefaultEnergyChart =
        !isSelfConsumption && chartControlsConfig.option === 0;
    const showPeriodSelectors =
        !isEmpty(solarSimulationData) &&
        chartId === CHART_BASE_CONFIG.ENERGY.name;
    const showStackSelector =
        chartId === CHART_BASE_CONFIG.CURRENT_ECONOMIC.name;
    const handleOnLegendClick = ({ label, series = [] }) => {
        setDefaultVisibility((prev) => {
            const currentSeries =
                prev[chartId] ?? (series ?? []).map((serie) => serie.label);
            const isCurrentlyVisible = currentSeries.includes(label);
            return {
                ...prev,
                [chartId]: isCurrentlyVisible
                    ? currentSeries.filter((index) => index !== label)
                    : [...currentSeries, label],
            };
        });
    };
    const getfilteredSeries = (series) => {
        let uniqueLabels = [];
        series.forEach((serie) => {
            if (!uniqueLabels.find((_serie) => _serie.name === serie.name)) {
                uniqueLabels.push(serie);
            }
        });
        return uniqueLabels;
    };

    const { data, options, series } = buildChartJsProps({
        chartConfig,
        chartControlsConfig,
        chartId,
        countryCurrencyIso,
        countryCurrencyLocale,
        currentChartConfig,
        defaultVisibility,
        handleOnLegendClick,
        isSelfConsumption,
        selectedStacks,
    });

    return (
        <Box mt={2} ref={container}>
            <Grid container justifyContent="center" mb={2}>
                <Grid item xs={18} md={14}>
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        fullWidth
                        onChange={(e) => setChartValue(e.target.value)}
                        orientation={
                            ['xs', 'sm', 'md'].includes(breakpoint)
                                ? 'vertical'
                                : 'horizontal'
                        }
                        size="small"
                        value={chartId}
                    >
                        {Object.values(tabsToShow).map(({ label, name }) => (
                            <ToggleButton key={name} value={name}>
                                {t(label)}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

            <ChartControls
                chartRef={chartRef}
                chartType={chartControlsConfig.type}
                chartTypeOptions={['area', 'bar']}
                datasets={series}
                defaultVisibility={defaultVisibility[chartId]}
                handleOnChangePeriod={handleOnChangeOption}
                handleOnChangeSelectedDays={handleOnChangeSelectedDays}
                handleOnChangeSelectedWeeks={handleOnChangeSelectedWeeks}
                hideChangeTypeButtons={
                    isDefaultEnergyChart || !showPeriodSelectors
                }
                monthSelector={
                    <>
                        {[1, 2, 3].includes(chartControlsConfig.option) && (
                            <PeriodSelector
                                consumptionHistory={
                                    consumptionHistory[chartControlsConfig.year]
                                }
                                onChange={handleOnChangeSelectedPeriod}
                                showHasDropdown
                                value={chartControlsConfig.period}
                            />
                        )}
                    </>
                }
                onChangeVisibility={handleOnChangeVisibility}
                selectedDays={chartControlsConfig.days}
                selectedOption={chartControlsConfig.option}
                selectedStacks={selectedStacks}
                selectedWeeks={chartControlsConfig.weeks}
                series={getfilteredSeries(series)}
                setChartType={(type) => handleOnChangeChartType(type)}
                setSelectedStacks={setSelectedStacks}
                showPeriodSelectors={showPeriodSelectors}
                showVisibilityControls
                stacksToSelect={
                    chartId === 'chartCurrentEconomic' ? stacksToSelect : null
                }
                weeksToSelect={chartControlsConfig.totalWeeks}
                showStackSelector={showStackSelector}
            />

            {currentChartConfig && (
                <ChartJs
                    chartRef={chartRef}
                    data={data}
                    height="350px"
                    options={options}
                    type={options.type}
                />
            )}

            <Grid container justifyContent="center" my={1}>
                <Grid item xs>
                    <PeriodSelector
                        consumptionHistory={consumptionHistory}
                        onChange={handleOnChangeSelectedYear}
                        isYearSelector
                        value={chartControlsConfig.year}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

ProposalChartJs.propTypes = {
    consumptionProjection: PropTypes.object,
    countryCurrency: PropTypes.object,
    countryCurrencyLocale: PropTypes.string,
    initialChartControlsConfig: PropTypes.object,
    proposalConfiguration: PropTypes.object,
    solarSimulationData: PropTypes.object,
};

export default ProposalChartJs;
