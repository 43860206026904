import React from 'react';

import DescriptionIcon from '@mui/icons-material/Description';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from 'sunwise-ui';

const SmartDocumentsTooltip = ({ isHidden, smartDocuments }) => {
    const { t } = useTranslation();

    if (isHidden) return null;

    return (
        <Tooltip
            placement="top"
            title={
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: 1,
                    }}
                >
                    <Typography variant="caption" fontWeight="bold">
                        {smartDocuments.length}{' '}
                        {t('Smart document', { count: 2 })}
                    </Typography>
                    {smartDocuments.map((document) => (
                        <Typography
                            variant="caption"
                            key={`document-item-${document.id}`}
                        >
                            {document.name}
                        </Typography>
                    ))}
                </Box>
            }
        >
            <DescriptionIcon fontSize="small" />
        </Tooltip>
    );
};

SmartDocumentsTooltip.propTypes = {
    isHidden: PropTypes.bool,
    smartDocuments: PropTypes.array,
};

export default SmartDocumentsTooltip;
