import { styled } from '@mui/material/styles';

const StyledButton = styled('button')`
    background-color: #2ecc71;
    border-radius: 2px;
    border-width: 0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    color: #ecf0f1;
    display: block;
    margin: 30px auto;
    outline: none;
    overflow: hidden;
    padding: 8px;
    position: relative;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
        background-color: #27ae60;
    }

    & > * {
        position: relative;
    }

    & span {
        display: block;
        padding: 12px 24px;
    }

    &:before {
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        background-color: rgba(236, 240, 241, 0.3);
        border-radius: 100%;
        content: '';
        display: block;
        left: 50%;
        padding-top: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0;
    }

    &:active:before {
        padding-top: 120%;
        transition:
            width 0.2s ease-out,
            padding-top 0.2s ease-out;
        width: 120%;
    }
`;

export default StyledButton;
