import { deleteStorageOffer } from 'common/api/v2/storageOffer';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import {
    DELETE_OFFER,
    DELETE_OFFER_FAILURE,
    DELETE_OFFER_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorEnergyBackupActions } from '../reducer';

export default (commercialOfferId) => (dispatch) => {
    dispatch(proposalGeneratorEnergyBackupActions[DELETE_OFFER]());

    deleteStorageOffer(commercialOfferId)
        .then((response) => {
            dispatch(
                proposalGeneratorEnergyBackupActions[DELETE_OFFER_SUCCESS](
                    response.data.data,
                ),
            );
            dispatch(eventsAfterUpdate(response.data.data, commercialOfferId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorEnergyBackupActions[DELETE_OFFER_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
