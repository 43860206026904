import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { DocumentFormContainer } from 'common/components/form/documentsForm';

import * as selectors from '../selectors';

const Form = ({
    documentsTypeDictionary,
    documentsTypeToSelect,
    errors,
    handleSubmitForm,
    handleClickCancel,
    initialValues,
    isUploading,
}) => {
    return (
        <DocumentFormContainer
            documentsTypeDictionary={documentsTypeDictionary}
            documentsTypeToSelect={documentsTypeToSelect}
            errors={errors}
            handleSubmitForm={handleSubmitForm}
            handleClickCancel={handleClickCancel}
            initialValues={initialValues}
            isUploading={isUploading}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    documentsTypeDictionary: selectors.getDocumentsTypeDictionary,
    documentsTypeToSelect: selectors.getDocumentsTypeToSelect,
    errors: selectors.getErrors,
    initialValues: selectors.getInitialValues,
});

Form.propTypes = {
    documentsTypeToSelect: PropTypes.array,
    documentsTypeDictionary: PropTypes.object,
    handleClickCancel: PropTypes.func,
    handleSubmitForm: PropTypes.func,
    initialValues: PropTypes.object,
    isUploading: PropTypes.bool,
    errors: PropTypes.array,
};

export default connect(mapStateToProps)(Form);
