import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

export const formattedCurrenciesData = (items) => {
    if (isEmpty(items)) return [];
    return [
        ...items.map((item) => ({
            ...item,
            abbreviation: item?.currency?.abbreviation,
        })),
    ];
};

export const getMessage = (text) => {
    if (isEmpty(text)) return i18next.t('You have not added currencies');
    return i18next.t('No results');
};

export const onDragEnd = (items, updateOrder, result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
    )
        return;

    updateOrder(source.index, destination.index, items);
};

export const temporalOptions = () => {
    return [
        { label: i18next.t('Select'), value: '', disabled: true },
        { label: i18next.t('Manual'), value: 'MNL' },
        { label: i18next.t('Automatic'), value: 'AUT' },
    ];
};

export const temporalSourceOptions = (isoCode) => {
    if (isoCode === 'MXN') {
        return [
            { label: i18next.t('Select'), value: '', disabled: true },
            { label: 'Google', value: 0 },
            { label: 'DOF', value: 1 },
        ];
    }
    return [
        { label: i18next.t('Select'), value: '', disabled: true },
        { label: 'Google', value: 0 },
    ];
};
