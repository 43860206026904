import i18next from 'i18next';

import { getScheduleRateConfiguration } from 'common/api/v2/scheduleRate';

import {
    FETCH_SCHEDULE_RATE_CONFIGURATION,
    FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE,
    FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS,
} from '../actionTypes';
import { getTierColorByIndex } from '../helpers';
import { ratesActions } from '../reducer';

export default ({
        certified,
        handleSetHourlyDistribution,
        id,
        rate_division,
    }) =>
    (dispatch) => {
        if (!id || (certified && !rate_division)) return;

        dispatch(ratesActions[FETCH_SCHEDULE_RATE_CONFIGURATION]());

        getScheduleRateConfiguration({ certified, rate: id, rate_division })
            .then((response) => {
                const data = response?.data?.data;
                dispatch(
                    ratesActions[FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS](
                        data,
                    ),
                );

                const {
                    saturday_distribution,
                    sunday_distribution,
                    tiers,
                    weekday_distribution,
                } = data?.tiers_energy_distribution?.[0] || {};

                const mappedTiers = tiers?.map((tier) => ({
                    identifier: tier?.identifier,
                    name: certified
                        ? i18next.t(tier?.name, { context: 'rate' })
                        : tier?.name,
                }));

                const getDistributionFormatted = (distribution) =>
                    distribution?.map((value) => ({
                        value: value + 1,
                        color: getTierColorByIndex(value, tiers.length),
                    }));

                handleSetHourlyDistribution({
                    distribution: {
                        saturday: getDistributionFormatted(
                            saturday_distribution,
                        ),
                        sunday: getDistributionFormatted(sunday_distribution),
                        weekday: getDistributionFormatted(weekday_distribution),
                    },
                    tiers: mappedTiers,
                });
            })
            .catch((error) =>
                dispatch(
                    ratesActions[FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            );
    };
