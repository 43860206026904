import React, { useCallback } from 'react';

import SyncIcon from '@mui/icons-material/Sync';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { Button } from 'sunwise-ui';

import { MAX_FILE_SIZE } from 'common/constants';
import showToast from 'common/utils/showToast';

import { ACCEPTED_FILES } from '../constants';

const FileUploaderButton = ({
    control,
    maxSize = MAX_FILE_SIZE,
    maxSizeErrorMessage = i18next.t(
        'The file exceeds the allowable size limit',
    ),
    name,
    setValue,
}) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0 && acceptedFiles[0].size >= maxSize) {
            showToast({
                body: maxSizeErrorMessage,
                type: 'danger',
                autoClose: 3000,
            });
            return;
        }

        setValue(
            name,
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            ),
        );
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Controller
            control={control}
            name={name}
            render={() => (
                <Button
                    {...getRootProps()}
                    color="secondary"
                    endIcon={<SyncIcon />}
                    variant="text"
                >
                    <input {...getInputProps()} accept={ACCEPTED_FILES} />
                    {i18next.t('Change').toUpperCase()}{' '}
                </Button>
            )}
        />
    );
};

FileUploaderButton.propTypes = {
    control: PropTypes.object,
    maxSize: PropTypes.number,
    maxSizeErrorMessage: PropTypes.string,
    name: PropTypes.string,
    setValue: PropTypes.func,
};

export default FileUploaderButton;
