import i18next from 'i18next';
import { createSelector } from 'reselect';

import { getIsMexicanAccount } from 'common/utils/helpers/session';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord,
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal,
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (model) => model.isSaving,
);

export const getSaveItemErrors = createSelector(
    getSaveItem,
    (model) => model.errors,
);

/* Divisiones Políticas */
export const getPoliticalDivisions = createSelector(
    getModel,
    (model) => model.politicalDivisions,
);

export const getPoliticalDivisionsData = createSelector(
    getPoliticalDivisions,
    (data) => data.data || [],
);

export const getPoliticalDivisionsForSelect = createSelector(
    getPoliticalDivisions,
    (dataValues) => {
        const isMexicanAccount = getIsMexicanAccount();
        let items = dataValues.data.map((value) => {
            return {
                label: `${value.name} ${
                    value.has_generation_profile ? '*' : ''
                }`,
                value: value.id,
            };
        });

        items.unshift({
            label: isMexicanAccount
                ? i18next.t('State')
                : i18next.t('Political division of order 1'),
            values: '',
            disabled: true,
        });

        return items;
    },
);

/* Divisiones Políticas 2 */
export const getPoliticalDivisions2 = createSelector(
    getModel,
    (model) => model.politicalDivisions2,
);

export const getPoliticalDivisions2Data = createSelector(
    getPoliticalDivisions2,
    (data) => data.data || [],
);

export const getPoliticalDivisions2ForSelect = createSelector(
    getPoliticalDivisions2,
    (dataValues) => {
        const isMexicanAccount = getIsMexicanAccount();
        let items = dataValues.data.map((value) => {
            return {
                label: `${value.name} ${value.has_own_irradiation ? '*' : ''}`,
                value: value.id,
            };
        });

        items.unshift({
            label: isMexicanAccount
                ? i18next.t('City')
                : i18next.t('Political division of order 2'),
            values: '',
            disabled: true,
        });

        return items;
    },
);

/* Fuentes */
export const getSources = createSelector(getModel, (model) => model.sources);

export const getSourcesData = createSelector(
    getSources,
    (data) => data.data || [],
);

export const getSourceDataWithOwn = createSelector(getSourcesData, (data) => {
    const items = data.map((value) => {
        return {
            key: value.key,
            value: value.value,
        };
    });

    items.push({ key: '3', value: i18next.t('Own', { context: 'female' }) });

    return items;
});

export const getSourcesForSelect = createSelector(getSources, (dataValues) => {
    let items = dataValues.data.map((value) => {
        return {
            label: value.value,
            value: value.key,
        };
    });

    items.unshift({
        label: i18next.t('Source', { count: 2 }),
        values: '',
        disabled: true,
    });

    return items;
});

export const getSelectedSourceName = () =>
    createSelector(getSources, (sources) => (formSource) => {
        const source = sources?.data?.find((item) => item.key === formSource);

        if (!source) return null;
        return source.value;
    });

/* Irradiaciones por estado y fuente */
export const getIrradiationsByStateAndSource = createSelector(
    getModel,
    (model) => model.irradiationsByStateAndSources,
);

export const getIrradiationsByStateAndSourceErrors = createSelector(
    getIrradiationsByStateAndSource,
    (model) => model.errors,
);

export const getIrradiationsByStateAndSourceData = createSelector(
    getIrradiationsByStateAndSource,
    (data) => data.data || [],
);

export const getAvailableSources = createSelector(
    getIrradiationsByStateAndSourceData,
    (data) => {
        if (data.length === 0) {
            return [];
        }

        return data.map((source) => parseInt(source.source));
    },
);

export const getDefaultSourceData = () =>
    createSelector(
        getIrradiationsByStateAndSourceData,
        (irradiationsByStateAndSourceData) => (source) => {
            let defaultSourceData = null;
            if (source && source !== i18next.t('Source', { count: 2 })) {
                defaultSourceData = irradiationsByStateAndSourceData.filter(
                    (data) => data.source === source,
                );
            }

            return defaultSourceData;
        },
    );

export const getOwnSourceData = createSelector(
    getIrradiationsByStateAndSourceData,
    (irradiationsByStateAndSourceData) => {
        const source = irradiationsByStateAndSourceData.find(
            (item) => parseInt(item.source) === 3,
        );

        if (!source) return [];
        return [source];
    },
);

export const getSeries = createSelector(
    getIrradiationsByStateAndSourceData,
    getSourceDataWithOwn,
    (irradiationsByStateAndSourceData, sourceDataWithOwn) => {
        const getNameSource = (key, sources) => {
            if (sources.length === 0) return null;
            const source = sources.find((source) => source.key === key);
            if (!source) return null;
            return source.value;
        };

        const sortMonthlySolarRadiationHrsol = (data) => {
            if (!data || data.monthly_solar_radiation_hrsol.length === 0)
                return [];
            return [...data.monthly_solar_radiation_hrsol].sort((a, b) => {
                return a.month - b.month;
            });
        };

        const series = irradiationsByStateAndSourceData.map((data) => {
            return {
                data: sortMonthlySolarRadiationHrsol(data).map(
                    (hrsol) => hrsol.average,
                ),
                name: getNameSource(data.source, sourceDataWithOwn),
            };
        });

        return series;
    },
);

export const getSourcesToShow = createSelector(
    getSourceDataWithOwn,
    getAvailableSources,
    (sourceDataWithOwn, availableSources) => {
        return sourceDataWithOwn.filter((source) => {
            return availableSources.indexOf(parseInt(source.key)) > -1;
        });
    },
);

/* Fuente default de la empresa */
export const getDefaultCompanySource = createSelector(
    getModel,
    (model) => model.defaultSource,
);

export const getDefaultCompanySourceData = createSelector(
    getDefaultCompanySource,
    (data) => data.data || [],
);

export const getGenerationProfile = createSelector(
    getModel,
    (model) => model.fetchGenerationProfile,
);

export const getGenerationProfileData = createSelector(
    getGenerationProfile,
    (generationProfile) => generationProfile.data,
);

export const getGenerationProfileErrors = createSelector(
    getGenerationProfile,
    (generationProfile) => generationProfile.errors,
);

export const getIsFetchingGenerationProfile = createSelector(
    getGenerationProfile,
    (generationProfile) => generationProfile.isFetching,
);

export const getGenerationProfiles = createSelector(
    getModel,
    (model) => model.fetchGenerationProfiles,
);

export const getGenerationProfilesData = createSelector(
    getGenerationProfiles,
    (generationProfiles) => generationProfiles.data,
);

export const getGenerationProfilesErrors = createSelector(
    getGenerationProfiles,
    (generationProfiles) => generationProfiles.errors,
);

export const getIsFetchingGenerationProfiles = createSelector(
    getGenerationProfiles,
    (generationProfiles) => generationProfiles.isFetching,
);

export const getIsOpenGenerationProfileForm = createSelector(
    getModel,
    (model) => model.isOpenGenerationProfileForm,
);

export const getInitialValuesGenerationProfileForm = createSelector(
    getModel,
    (model) => model.initialValuesGenerationProfile,
);

export const getSaveGenerationProfile = createSelector(
    getModel,
    (model) => model.saveGenerationProfile,
);

export const getSaveGenerationProfileErrors = createSelector(
    getSaveGenerationProfile,
    (saveGenerationProfile) => saveGenerationProfile.errors,
);

export const getIsSavingGenerationProfile = createSelector(
    getSaveGenerationProfile,
    (saveGenerationProfile) => saveGenerationProfile.isSaving,
);
