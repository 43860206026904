import React from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import { tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, Tooltip } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';

import { getTotalWarningIndicator } from '../../helpers';
import {
    AvgConsumptionWarning,
    DaysInPeriodWarning,
    DemandRatioWarning,
    MissingFieldsAlert,
    SeasonChangeWarning,
} from '../warnings';

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.background.paper,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.mode === 'dark' ? '#fff' : null,
        maxWidth: 500,
    },
}));

const RowAlertTooltip = ({ mr = 1, period, periodWarnings }) => {
    const {
        hasAvgConsumptionWarning,
        hasDaysInPeriodWarning,
        inconsistentDemands,
    } = periodWarnings || {};
    const { errorSeverity, total } = getTotalWarningIndicator(periodWarnings);

    if (!total) return null;

    return (
        <CustomWidthTooltip
            title={
                <Box>
                    {period?.season_change_pdf && <SeasonChangeWarning />}

                    <DemandRatioWarning
                        inconsistentDemands={inconsistentDemands}
                    />

                    <DaysInPeriodWarning
                        hasDaysInPeriodWarning={hasDaysInPeriodWarning}
                    />

                    <AvgConsumptionWarning
                        hasAvgConsumptionWarning={hasAvgConsumptionWarning}
                    />

                    <MissingFieldsAlert period={period} />
                </Box>
            }
        >
            <WarningIcon
                color={errorSeverity ? 'error' : 'warning'}
                sx={{ mr, verticalAlign: 'bottom' }}
            />
        </CustomWidthTooltip>
    );
};

RowAlertTooltip.propTypes = {
    mr: PropTypes.number,
    period: PropTypes.object,
    periodWarnings: PropTypes.object,
};

export default RowAlertTooltip;
