import connectSocket from '../connectSocket';
import { PREFIX_AFTERSALES_PROJECTS } from '../constants';
import disconnectSocket from '../disconnectSocket';

const baseURL = import.meta.env.VITE_AFTER_SALES_PROJECTS_SOCKET_URL;

export const connect = (id) =>
    connectSocket(
        `${baseURL}/${id}?token=${localStorage.getItem('token')}`,
        PREFIX_AFTERSALES_PROJECTS,
    );
export const disconnect = () => disconnectSocket(PREFIX_AFTERSALES_PROJECTS);
