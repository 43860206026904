import { parseDate } from 'common/utils/dates';

import {
    INITIALIZE_SHADER_SETTINGS_VALUES,
    SAVE_SHADER_SETTINGS_SUCCESS,
} from '../actionTypes';
import { panelLayoutV2Actions as actions } from '../reducer';
import * as selectors from '../selectors';

import setupSimulation from './setupSimulation';

export default (values) => (dispatch, getState) => {
    const { date, isPlaying } = values;
    const state = getState();
    const prev = selectors.getShaderSetttingsInitialValues(state);
    const parsedDate = parseDate(date, 'dd/MM/yyyy');

    const { interval: myInterval } = selectors.getSimulationStatus(state);

    let _newValues = {
        ...prev,
        ...values,
        day: parsedDate.getDate(),
        month: parsedDate.getMonth() + 1,
    };

    if (isPlaying) {
        let h = values.hour;
        let m = values.minutes;
        const handleInterval = () => {
            m += 10;
            if (m > 59) {
                h++;
                m = 0;
            }
            if (h > 21) h = 5;

            const newValues = {
                ..._newValues,
                hour: h,
                minutes: m,
            };

            dispatch(actions[SAVE_SHADER_SETTINGS_SUCCESS](newValues));
            dispatch(actions[INITIALIZE_SHADER_SETTINGS_VALUES](newValues));
        };

        let interval = setInterval(handleInterval, 60);
        dispatch(setupSimulation({ isPlaying: true, interval }));
    } else {
        if (myInterval) clearInterval(myInterval);
        dispatch(setupSimulation({ isPlaying: false, interval: null }));
        dispatch(actions[SAVE_SHADER_SETTINGS_SUCCESS](_newValues));
    }
};
