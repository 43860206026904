import * as companyGeneralSelectors from '../../companyGeneral/selectors';
import {
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

import fetchCarouselTemplates from './fetchCarouselTemplates';
import fetchCompanyUsers from './fetchCompanyUsers';
import fetchLanguages from './fetchLanguages';
import fetchTemplateCategories from './fetchTemplateCategories';

export default () => (dispatch, getState) => {
    const state = getState();
    const companyCountry = companyGeneralSelectors.getCompanyCountry(state);
    dispatch(templateLibraryActions[INITIALIZE]());
    Promise.all([
        dispatch(fetchLanguages()),
        dispatch(fetchTemplateCategories()),
        dispatch(fetchCompanyUsers()),
        dispatch(fetchCarouselTemplates(companyCountry)),
    ])
        .then(() => dispatch(templateLibraryActions[INITIALIZE_SUCCESS]()))
        .catch(() => dispatch(templateLibraryActions[INITIALIZE_FAILURE]()));
};
