export default (google, map) => {
    class MeterLabelPopup extends google.maps.OverlayView {
        constructor(options = {}) {
            const { position = null, text = '', rotate = 0 } = options;
            super({ ...options, zIndex: 1 });
            this.setMap(map);
            this.position = position;
            this.rotate = rotate;
            this.textSpan = document.createElement('span');
            this.textSpan.innerHTML = text;
            let bubbleAnchor = document.createElement('div');
            bubbleAnchor.classList.add('popup-bubble-anchor');
            bubbleAnchor.appendChild(this.textSpan);

            this.containerDiv = document.createElement('div');
            this.containerDiv.classList.add('popup-container');
            this.containerDiv.appendChild(bubbleAnchor);

            google.maps.OverlayView.preventMapHitsAndGesturesFrom(
                this.containerDiv,
            );
        }

        setPosition(position) {
            this.position = position;
        }

        setRotate(rotate) {
            this.rotate = rotate;
        }

        setText(text) {
            this.textSpan.innerHTML = text;
        }

        onAdd() {
            this.getPanes().floatPane.appendChild(this.containerDiv);
        }

        onRemove() {
            if (this.containerDiv.parentElement) {
                this.containerDiv.parentElement.removeChild(this.containerDiv);
            }
        }

        draw() {
            const divPosition = this.getProjection().fromLatLngToDivPixel(
                this.position,
            );

            const display =
                Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                    ? 'block'
                    : 'none';

            if (display === 'block') {
                this.containerDiv.style.left = divPosition.x + 'px';
                this.containerDiv.style.top = divPosition.y + 'px';
            }
            if (this.containerDiv.style.display !== display) {
                this.containerDiv.style.display = display;
            }
            this.containerDiv.style.transform = `rotate(${this.rotate}deg)`;
        }
    }
    return MeterLabelPopup;
};
