import React from 'react';

import PropTypes from 'prop-types';
import { Badge } from 'sunwise-ui';

import { getTotalWarningIndicator } from '../../helpers';

const AlertBadge = ({ hasFormErrors, label, warnings }) => {
    const { errorSeverity, total } = getTotalWarningIndicator(warnings);

    return (
        <Badge
            badgeContent={hasFormErrors ? total + 1 : total}
            color={hasFormErrors || errorSeverity ? 'error' : 'warning'}
            invisible={total === 0}
        >
            {label}
        </Badge>
    );
};

AlertBadge.propTypes = {
    hasFormErrors: PropTypes.bool,
    label: PropTypes.string,
    warnings: PropTypes.object,
};

export default AlertBadge;
