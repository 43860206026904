import React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, Grid, IconButton } from 'sunwise-ui';

import { ReactHookFormSelect2 } from 'common/components/form/bootstrap';
import { itemsToSelect2WithoutDuplicates } from 'common/utils/helpers';

import InverterDetails from './InverterDetails';

const ModelColumn = ({
    control,
    errorConfig,
    formValues,
    handleChange,
    handleNormalizeModel,
    handleNormalizeModelField,
    index,
    initialValues,
    isDisabled,
    isDisabledField,
    isInverter,
    itemsData,
    name,
    offerItems,
    productItem,
    setValue,
}) => {
    const { t } = useTranslation();

    return (
        <Grid item xs={18} md={15}>
            <Box sx={{ display: 'flex', alignItems: 'start', gap: 1 }}>
                <FormControl variant="standard" sx={{ width: '100%' }}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isDisabled}
                        fullWidth
                        isClearable={true}
                        label={t('Model')}
                        name={`${name}.${index}.item`}
                        onChange={(value) =>
                            handleNormalizeModel(
                                index,
                                handleNormalizeModelField,
                            )(
                                value,
                                initialValues?.[name]?.[index]?.item,
                                formValues,
                                name,
                            )
                        }
                        options={itemsToSelect2WithoutDuplicates(
                            itemsData,
                            productItem.is_new,
                            offerItems,
                        )}
                        variant="standard"
                    />
                    {isInverter && (
                        <InverterDetails
                            errorConfig={errorConfig}
                            inverterItem={productItem}
                        />
                    )}
                </FormControl>
                {isInverter && (
                    <IconButton
                        aria-label="locked"
                        disabled={isDisabledField}
                        onClick={() => {
                            handleChange({
                                ...productItem,
                                locked: !productItem.locked,
                            });
                            setValue(
                                `${name}.${index}.locked`,
                                !productItem.locked,
                            );
                        }}
                        sx={{ mt: 1 }}
                    >
                        {productItem.locked ? (
                            <LockIcon color="#20445a" />
                        ) : (
                            <LockOpenIcon color="#20445a" />
                        )}
                    </IconButton>
                )}
            </Box>
        </Grid>
    );
};

ModelColumn.defaultProps = {
    handleChange: () => {},
    isInverter: false,
};

ModelColumn.propTypes = {
    control: PropTypes.object,
    errorConfig: PropTypes.object,
    formValues: PropTypes.object,
    handleChange: PropTypes.func,
    handleNormalizeModel: PropTypes.func,
    handleNormalizeModelField: PropTypes.func,
    index: PropTypes.number,
    initialValues: PropTypes.object,
    isDisabled: PropTypes.bool,
    isDisabledField: PropTypes.bool,
    isInverter: PropTypes.bool,
    itemsData: PropTypes.array,
    name: PropTypes.string,
    offerItems: PropTypes.array,
    productItem: PropTypes.object,
    setValue: PropTypes.func,
};

export default ModelColumn;
