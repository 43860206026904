import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ApiSourceForm from './ApiSourceForm';

const ModalForm = ({
    apiSourceFormModalOpenStatus,
    resetApiSourceForm,
    setApiSourceFormModalOpenStatus,
    sunwiseProjectId,
}) => {
    const { t } = useTranslation();

    const handleOnClose = () => setApiSourceFormModalOpenStatus(false);

    return (
        <Dialog
            onClose={handleOnClose}
            onExited={() => resetApiSourceForm()}
            open={apiSourceFormModalOpenStatus}
            size="lg"
            title={t('Add source')}
        >
            <ApiSourceForm
                handleOnClose={handleOnClose}
                sunwiseProjectId={sunwiseProjectId}
            />
        </Dialog>
    );
};

ModalForm.propTypes = {
    apiSourceFormModalOpenStatus: PropTypes.bool,
    resetApiSourceForm: PropTypes.func,
    setApiSourceFormModalOpenStatus: PropTypes.func,
    sunwiseProjectId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    apiSourceFormModalOpenStatus: selectors.getApiSourceFormModalOpenStatus,
});

const mapDispatchToProps = (dispatch) => ({
    resetApiSourceForm: () => dispatch(actions.resetApiSourceForm()),
    setApiSourceFormModalOpenStatus: (value) =>
        dispatch(actions.setApiSourceFormModalOpenStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalForm);
