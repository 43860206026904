export const handleChangeBuild = (handleOnSave, proposalId) => (values) =>
    handleOnSave(proposalId, values);

export const handleChangeExpansionFieldBuild =
    (handleOnSaveExpansion, offerPanelId, panelNumber, proposalId) => (value) =>
        handleOnSaveExpansion(proposalId, {
            id: offerPanelId,
            expansion: value,
            quantity: panelNumber,
        });
