import { fetchListCatalogs } from 'common/api/v1/companiesCatalogs';

import {
    FETCH_TRACKINGS_PURPOSES,
    FETCH_TRACKINGS_PURPOSES_FAILURE,
    FETCH_TRACKINGS_PURPOSES_SUCCESS,
} from '../actionTypes';
import { CONTACT_TRACKING_TYPE } from '../constants';
import { trackingFormActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(trackingFormActions[FETCH_TRACKINGS_PURPOSES]());

    fetchListCatalogs(CONTACT_TRACKING_TYPE)
        .then((response) =>
            dispatch(
                trackingFormActions[FETCH_TRACKINGS_PURPOSES_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                trackingFormActions[FETCH_TRACKINGS_PURPOSES_FAILURE](error),
            ),
        );
};
