import React from 'react';

import PropTypes from 'prop-types';

import LabelError from './LabelError';
import StyledGroup from './StyledGroup';

const StyledSelect = (props) => {
    const {
        field: { name, value, onChange },
        label,
        disabled,
        error,
        options = [],
        id,
        required,
        readOnly,
        tabIndex,
        className = '',
    } = props;

    return (
        <StyledGroup
            className={`${className} ${error?.message ? 'error' : ''}`}
        >
            <select
                className={`${value ? 'dirty' : ''}`}
                required={required}
                disabled={disabled}
                id={id}
                name={name}
                readOnly={readOnly}
                tabIndex={tabIndex}
                onChange={onChange}
            >
                {options.map(({ label, value, disabled: optionDisabled }) => (
                    <option
                        key={`select-${value}-${label}`}
                        value={value}
                        disabled={optionDisabled}
                    >
                        {label}
                    </option>
                ))}
            </select>

            <span className="highlight" />
            <span className="bar" />
            <label>{`${required ? '* ' : ''} ${label}`}</label>

            {error?.message && (
                <LabelError type="error">{error?.message} </LabelError>
            )}
        </StyledGroup>
    );
};

StyledSelect.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    field: PropTypes.object,
    error: PropTypes.object,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    options: PropTypes.array,
    className: PropTypes.string,
};

export default StyledSelect;
