import { getRegions } from 'common/api/v1/catalogs';

import {
    FETCH_REGIONS,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_FAILURE,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(projectConsumptionModalActions[FETCH_REGIONS]());
    getRegions()
        .then(({ data }) =>
            dispatch(
                projectConsumptionModalActions[FETCH_REGIONS_SUCCESS](
                    data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                projectConsumptionModalActions[FETCH_REGIONS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
