import React, { useState } from 'react';

import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';
import { Box, Button } from 'sunwise-ui';

import ModalDialog from 'common/components/ModalDialog';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as proposalReviewProActions from '../proposalReviewPro/actions';
import * as proposalReviewProSelectors from '../proposalReviewPro/selectors';

import * as actions from './actions';
import DraftContainer from './components/DraftContainer';
import Preview from './components/Preview';
import SplitButton from './components/SplitButton';
import * as selectors from './selectors';

const Container = ({
    canModify,
    fetchOfferInfoPatern,
    handleCloseModal,
    isFetchingOfferInfoPatern,
    isGenerated,
    isOpenModal,
    offerInfoPatern,
    proposalId,
    proposalName,
    redirect,
    setCurrentProposal,
}) => {
    const { t } = useTranslation();
    const [editionDisabled, setEditionDisabled] = useState(true);

    const callback = () => {
        handleCloseModal();
        setCurrentProposal(null);
    };

    const renderContent = () => {
        if (isGenerated)
            return (
                <Preview
                    canModify={canModify}
                    editionDisabled={editionDisabled}
                    isFetching={isFetchingOfferInfoPatern}
                    offerInfoPatern={offerInfoPatern}
                    proposalId={proposalId}
                    setEditionDisabled={setEditionDisabled}
                    templateType={types.ONE_PROPOSAL_TYPE}
                />
            );
        return <DraftContainer proposalId={proposalId} />;
    };

    return (
        <ModalDialog
            footerComponent={() => (
                <Box display="flex" gap={2} justifyContent="flex-end" py={1}>
                    {isGenerated ? (
                        <SplitButton
                            callback={callback}
                            options={[
                                {
                                    label: t('Go to preview'),
                                    url: `/proposal-review-pro/${proposalId}`,
                                    value: 'preview',
                                },
                                {
                                    label: t('Go to the proposer'),
                                    url: `/proposal/${proposalId}`,
                                    value: 'proposer',
                                },
                            ]}
                            redirect={redirect}
                        />
                    ) : (
                        <Button
                            component={RouterLink}
                            onClick={() => callback()}
                            to={`/proposal/${proposalId}`}
                            variant="outlined"
                            sx={{ textTransform: 'none' }}
                        >
                            {t('Go to the proposer')}
                        </Button>
                    )}
                </Box>
            )}
            onClose={() => callback()}
            onEnter={() => {
                if (isGenerated) fetchOfferInfoPatern(proposalId);
            }}
            open={isOpenModal}
            title={proposalName}
        >
            {renderContent()}
        </ModalDialog>
    );
};

const mapStateToProps = createStructuredSelector({
    offerInfoPatern: proposalReviewProSelectors.getOfferInfoPaternData,
    isFetchingOfferInfoPatern:
        proposalReviewProSelectors.getIsFetchingOfferInfoPatern,
    isOpenModal: selectors.getIsOpenModal,
});

const mapDispatchToProps = (dispatch) => ({
    fetchOfferInfoPatern: (id) =>
        dispatch(proposalReviewProActions.fetchOfferInfoPatern(id)),
    handleCloseModal: () => dispatch(actions.setIsOpenModal(false)),
    redirect: (link) => dispatch(push(link)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    fetchOfferInfoPatern: PropTypes.func,
    handleCloseModal: PropTypes.func,
    isFetchingOfferInfoPatern: PropTypes.bool,
    isGenerated: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    offerInfoPatern: PropTypes.object,
    proposalId: PropTypes.string,
    proposalName: PropTypes.string,
    redirect: PropTypes.func,
    setCurrentProposal: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.EDIT_PROPOSAL_PREVIEW_PERMISSION),
)(Container);
