import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import EmptyMeasurementSource from 'resources/EmptyMeasurementSource.svg?react';

const EmptyState = ({ addButton, canModify }) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ textAlign: 'center' }}>
            <EmptyMeasurementSource height="140" width="140" />
            <Typography variant="h5">
                {t('Start uploading your measurement sources')}
            </Typography>
            <Typography sx={{ mb: 2 }} variant="body2">
                {t(
                    'Add sources of consumption, generation and supplier to be able to monitor the activity of this project and generate reports',
                )}
            </Typography>
            {canModify && addButton}
        </Box>
    );
};

EmptyState.propTypes = { addButton: PropTypes.node, canModify: PropTypes.bool };

export default EmptyState;
