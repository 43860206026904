import React, { useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, Typography } from 'sunwise-ui';

import ReactHookFormSelect2 from 'common/components/form/bootstrap/ReactHookFormSelect2';
import { calculateFinalCost } from 'common/utils/helpers';

import BatteriesPlaceholder from 'resources/onboarding/batteries.svg';

import * as actions from '../actions';
import { getProductBrandsToSelect } from '../helpers';
import * as selectors from '../selectors';

import ReduxFieldArrayBatteries from './ReduxFieldArrayBatteries';

const BatteryStep = ({
    batteryBrands,
    batteryProducts,
    control,
    fetchBatteryProducts,
    formValues,
    getValues,
    isFetchingBatteryBrands,
    isFetchingBatteryProducts,
    setValue,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!localStorage.getItem('tempBatterySettings')) {
            const batteries = batteryProducts.map((item) => ({
                cost: item.cost,
                final_cost: calculateFinalCost(item.cost, 0),
                id: item.id,
                margin: 0,
                name: item.name,
                price: 0,
                selected: true,
            }));
            setValue('batteries', batteries);
        }
    }, [batteryProducts]);

    const handleOnChangeBrand = (brandName) => {
        localStorage.removeItem('tempBatterySettings');
        setValue('batteries', []);
        fetchBatteryProducts(brandName);
    };

    return (
        <>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    mb: 2,
                    py: 1,
                    width: '100%',
                }}
            >
                <img src={BatteriesPlaceholder} />
                <Typography variant="h6">
                    {t('Highlight your proposals with batteries')}
                </Typography>
                <Typography variant="body2">
                    {t(
                        'Because there is nothing like a complete solar system. We pre-select the 5 most used batteries depending on the brand you choose, then you will have more options to further customize your product listings',
                    )}
                    .
                </Typography>
            </Box>
            <Grid container mb={2}>
                <Grid item xs={18} md={8}>
                    <Typography fontWeight="bold" mb={1} variant="body2">
                        {t('Battery brand')}
                    </Typography>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isFetchingBatteryBrands}
                        isClearable={false}
                        label={t('Select your favorite')}
                        name="battery_brand"
                        onChange={(value) => handleOnChangeBrand(value)}
                        options={[
                            {
                                label: '',
                                options:
                                    getProductBrandsToSelect(batteryBrands),
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            {isFetchingBatteryProducts ? (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                    }}
                >
                    <CircularProgress />
                    <Typography fontWeight="bold" variant="caption">
                        {`${t('Loading')} ${t('Battery', { count: 2 })}...`}
                    </Typography>
                </Box>
            ) : (
                <ReduxFieldArrayBatteries
                    control={control}
                    disabled={isFetchingBatteryProducts}
                    formValues={formValues}
                    getValues={getValues}
                    name="batteries"
                    setValue={setValue}
                />
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    batteryBrands: selectors.getBatteryBrandsData,
    batteryProducts: selectors.getBatteryProductsData,
    isFetchingBatteryBrands: selectors.getIsFetchingBatteryBrands,
    isFetchingBatteryProducts: selectors.getIsFetchingBatteryProducts,
});

const mapDispatchToProps = (dispatch) => ({
    fetchBatteryProducts: (brandName) =>
        dispatch(actions.fetchBatteryProducts(brandName)),
});

BatteryStep.propTypes = {
    batteryBrands: PropTypes.array,
    batteryProducts: PropTypes.array,
    control: PropTypes.object,
    fetchBatteryProducts: PropTypes.func,
    formValues: PropTypes.object,
    getValues: PropTypes.func,
    isFetchingBatteryBrands: PropTypes.bool,
    isFetchingBatteryProducts: PropTypes.bool,
    setValue: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatteryStep);
