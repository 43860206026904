import i18next from 'i18next';
import { cloneDeep, set } from 'lodash';

import { uploadCFEFile } from 'common/api/v1/contactProject';
import { URL_TYPES } from 'common/constants';
import alerts from 'common/modules/alerts';

import {
    handleNormalizeFields,
    round,
    updateEnergyValuesByPeriod,
} from '../helpers';

import handleUploadXML from './handleUploadXML';
import setUploadFileOrigin from './setUploadFileOrigin';
import uploadDocumentsAI from './uploadDocumentsAI';

const handleSendFilesToLisa = ({ dispatch, file, index }) => {
    dispatch(setUploadFileOrigin(index));
    dispatch(uploadDocumentsAI(file, false));
};

export default ({
        file,
        getValues,
        index,
        rateConfiguration,
        ratesDictionary,
        sendToLisa,
        setValue,
    }) =>
    (dispatch) => {
        if (!file) return;

        const fileType = file?.[0]?.type;
        if (fileType === 'text/xml') {
            dispatch(handleUploadXML({ file, getValues, index, setValue }));
            return;
        }

        if (fileType === 'image/png' || sendToLisa) {
            handleSendFilesToLisa({ dispatch, file, index });
            return;
        }

        const summary = cloneDeep(getValues('summary'));

        dispatch(
            alerts.actions.show({
                confirmText: i18next.t('Back'),
                hideButtons: true,
                messages: [
                    i18next.t(
                        'Please wait a moment as the information is processed',
                    ),
                ],
                title: i18next.t('Loading').concat('...'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'info',
            }),
        );

        uploadCFEFile({ file })
            .then((response) => {
                dispatch(alerts.actions.close());
                const data = response.data.data;
                const orderedCurrentValues = data.values.sort(
                    (a, b) => parseInt(b.dateFrom) - parseInt(a.dateFrom),
                );
                const currentValues = orderedCurrentValues[0];

                if (!currentValues.monthly_service[0]) {
                    dispatch(alerts.actions.close());
                    dispatch(
                        alerts.actions.show({
                            confirmText: i18next.t('Back'),
                            hideButtons: false,
                            messages: [
                                i18next.t(
                                    'The CFE receipt entered does not contain a history for this type of rate',
                                ),
                            ],
                            onSuccess: () => dispatch(alerts.actions.close()),
                            title: i18next.t('Alert'),
                            type: alerts.ALERT_TYPE_ALERT,
                            variant: 'warning',
                        }),
                    );
                    return;
                }

                const montlyService = currentValues.monthly_service[0];
                const total_kWh =
                    parseFloat(montlyService.base_kwh) +
                    parseFloat(montlyService.middle_kwh) +
                    parseFloat(montlyService.peak_kwh);

                set(summary, `${index}.kW.base`, {
                    label: i18next.t('Base'),
                    tier: 0,
                    value: round(montlyService.base_kw),
                });
                set(summary, `${index}.kW.middle`, {
                    label: i18next.t('Middle', { context: 'rate' }),
                    tier: 1,
                    value: round(montlyService.middle_kw),
                });
                set(summary, `${index}.kW.peak`, {
                    label: i18next.t('Peak'),
                    tier: 2,
                    value: round(montlyService.peak_kw),
                });
                set(summary, `${index}.kWh.base`, {
                    label: i18next.t('Base'),
                    tier: 0,
                    value: round(montlyService.base_kwh),
                });
                set(summary, `${index}.kWh.middle`, {
                    label: i18next.t('Middle', { context: 'rate' }),
                    tier: 1,
                    value: round(montlyService.middle_kwh),
                });
                set(summary, `${index}.kWh.peak`, {
                    label: i18next.t('Peak'),
                    tier: 2,
                    value: round(montlyService.peak_kwh),
                });
                set(summary, `${index}.total`, { value: round(total_kWh) });
                set(summary, `${index}.power_factor`, currentValues.fp);
                set(summary, `${index}.file`, data.file);
                set(summary, `${index}.predicted`, currentValues.predicted);
                set(
                    summary,
                    `${index}.season_change_pdf`,
                    data.season_change_pdf,
                );
                set(summary, `${index}.url_type`, URL_TYPES.SUNWISE);

                const newSummary = handleNormalizeFields({
                    discardValues: true,
                    rate: ratesDictionary[getValues('rate')],
                    rateConfiguration,
                    summary,
                });

                updateEnergyValuesByPeriod({ newSummary, index, setValue });
            })
            .catch(() => {
                dispatch(alerts.actions.close());
                handleSendFilesToLisa({ dispatch, file, index });
            });
    };
