import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Table,
    TableBody,
    TableFooter,
    TableContainer,
    TableRow,
} from 'sunwise-ui';

import { MONTHS } from 'common/constants';
import { useBreakpoint } from 'common/hooks';
import { getMonthLabel } from 'common/utils/helpers';

import MonthsFieldRHF from './MonthsFieldRHF';
import StyledTableCell from './StyledTableCell';

const TableEditConfig = ({ control, setValue }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    return (
        <TableContainer>
            <Table
                className={breakpoint === 'xs' ? 'transposed' : null}
                size="small"
            >
                <TableBody>
                    <MonthsFieldRHF
                        breakpoint={breakpoint}
                        control={control}
                        label={t('Hr Sun')}
                        name="hrsSol"
                        setValue={setValue}
                    />

                    <MonthsFieldRHF
                        breakpoint={breakpoint}
                        control={control}
                        label="Kwh / Kw"
                        name="kwhkw"
                        setValue={setValue}
                    />
                </TableBody>
                {breakpoint !== 'xs' && (
                    <TableFooter>
                        <TableRow>
                            <StyledTableCell />
                            {MONTHS.map((month) => (
                                <StyledTableCell key={`${month}`}>
                                    {getMonthLabel(month)}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    );
};

TableEditConfig.propTypes = {
    control: PropTypes.object,
    setValue: PropTypes.func,
};

export default TableEditConfig;
