import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import * as actions from '../actions';

const MenuOptions = ({
    canDelete,
    canModify,
    data,
    handleClickDownloadCSV,
    isArchived,
    isArchivedFilter,
    isObsolete,
    prepareArchive,
    prepareDuplicate,
    prepareRename,
}) => {
    const { t } = useTranslation();

    return (
        <Grid item xs={3}>
            <DropdownCardMenu
                items={[
                    {
                        handleClick: () =>
                            prepareRename(data, isArchivedFilter),
                        label: t('Rename'),
                        visible: canModify,
                    },
                    {
                        label: t('Download CSV'),
                        handleClick: () => handleClickDownloadCSV(data),
                        key: 'download-action',
                    },
                    {
                        handleClick: () =>
                            prepareDuplicate(data, isArchivedFilter),
                        label: t('Duplicate'),
                        visible: !isArchived && canModify,
                    },
                    {
                        handleClick: () =>
                            prepareArchive(data, isArchivedFilter),
                        label: isArchived ? t('Unarchive') : t('Archive'),
                        visible: canDelete && !isObsolete,
                    },
                ]}
            />
        </Grid>
    );
};

const mapDispatchToProps = (dispatch) => ({
    prepareArchive: (summary, isArchivedFilter) =>
        dispatch(actions.prepareArchive(summary, isArchivedFilter)),
    prepareDelete: (summary, isArchivedFilter) =>
        dispatch(actions.prepareDelete(summary, isArchivedFilter)),
    prepareDuplicate: (summary, isArchivedFilter) =>
        dispatch(actions.prepareDuplicate(summary, isArchivedFilter)),
    prepareRename: (summary, isArchivedFilter) =>
        dispatch(actions.prepareRename(summary, isArchivedFilter)),
});

MenuOptions.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    data: PropTypes.object,
    handleClickDownloadCSV: PropTypes.func,
    isArchived: PropTypes.bool,
    isArchivedFilter: PropTypes.bool,
    isObsolete: PropTypes.bool,
    prepareArchive: PropTypes.func,
    prepareDuplicate: PropTypes.func,
    prepareRename: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(MenuOptions);
