import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { IconButton } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import { PROPOSAL_SUMMARY_TYPE } from '../constants';

const EditButton = ({
    companyCatalog,
    getPermissionsByCode,
    prepareUpdate,
    type,
}) => {
    const { canModify: canModifyCompanyList } = getPermissionsByCode(
        PERMISSION_LIST.LIST_SETTINGS_PERMISSION,
    );
    const { canModify: canModifySummaryList } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
    );

    if (type === PROPOSAL_SUMMARY_TYPE && canModifySummaryList) {
        return (
            <IconButton
                fontSize="medium"
                onClick={() => {
                    prepareUpdate(companyCatalog);
                }}
            >
                <EditIcon />
            </IconButton>
        );
    }
    if (canModifyCompanyList) {
        return (
            <IconButton
                fontSize="medium"
                onClick={() => {
                    prepareUpdate(companyCatalog);
                }}
            >
                <EditIcon />
            </IconButton>
        );
    }

    return null;
};

EditButton.propTypes = {
    companyCatalog: PropTypes.object,
    getPermissionsByCode: PropTypes.func,
    prepareUpdate: PropTypes.func,
    type: PropTypes.number,
};

export default withPermissions([
    PERMISSION_LIST.LIST_SETTINGS_PERMISSION,
    PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
])(EditButton);
