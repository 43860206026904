import { checkProposalEditStatus } from 'common/api/v1/sunpay';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    eventsAfterUpdate,
    setIsLocked,
} from '../../proposalGeneratorOneColumn/actions';
import {
    SAVE_COMMENT,
    SAVE_COMMENT_SUCCESS,
    SAVE_COMMENT_FAILURE,
} from '../actionTypes';
import { commentModalActions } from '../reducer';

import closeModal from './closeModal';

export default (values) => (dispatch) => {
    dispatch(commentModalActions[SAVE_COMMENT]());
    const newValues = {
        justification_message: values.comment,
    };
    checkProposalEditStatus(values.proposalId, newValues)
        .then((response) => {
            dispatch(commentModalActions[SAVE_COMMENT_SUCCESS](response.data));
            dispatch(closeModal());
            dispatch(eventsAfterUpdate(response.data.data, values.proposalId));
            dispatch(setIsLocked(false));
        })
        .catch((error) => {
            dispatch(
                commentModalActions[SAVE_COMMENT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        })
        .finally(() => {
            dispatch(alerts.actions.close());
            dispatch(alerts.actions.disableButtons(false));
        });
};
