import i18next from 'i18next';

import { formatDate, subDate } from 'common/utils/dates';

import { STORAGE_RANGES, SYSTEM_RANGES } from './constants';

export const getTodayDate = (format = 'dd/MM/yyyy') =>
    formatDate(new Date(), format);

export const getPastDate = (value, format = 'dd/MM/yyyy') =>
    formatDate(
        subDate(new Date(), {
            days: value,
        }),
        format,
    );

export const getRateOptions = (rates) => {
    const items = rates.map((rate) => ({
        label: rate.name,
        value: rate.name,
    }));

    return [
        {
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: 'all',
        },
        ...items,
    ];
};

export const getStatusOptions = () => {
    return [
        {
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: 'all',
        },
        {
            label: i18next.t('Draft'),
            value: 0,
        },
        {
            label: i18next.t('Generated'),
            value: 1,
        },
    ];
};

export const getStorageOptions = () => {
    const items = Object.keys(STORAGE_RANGES).map((value) => {
        let label =
            parseInt(value) === 7
                ? i18next.t('More than {{value}}', {
                      value: STORAGE_RANGES[value].storage_gt,
                  })
                : `${STORAGE_RANGES[value].storage_start} - ${STORAGE_RANGES[value].storage_end}`;
        return {
            label,
            value,
        };
    });

    return [
        {
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: 'all',
        },
        ...items,
    ];
};

export const getSystemOptions = () => {
    const items = Object.keys(SYSTEM_RANGES).map((value) => {
        let label =
            parseInt(value) === 7
                ? i18next.t('More than {{value}}', {
                      value: SYSTEM_RANGES[value].sytem_size_gt,
                  })
                : `${SYSTEM_RANGES[value].system_size_start} - ${SYSTEM_RANGES[value].system_size_end}`;
        return {
            label,
            value,
        };
    });

    return [
        {
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: 'all',
        },
        ...items,
    ];
};

export const getUserOptions = (users) => {
    const items = users.map((item) => {
        return {
            label: `${item.first_name} ${item.last_name}`,
            value: item.id,
        };
    });

    return [
        {
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: 'all',
        },
        ...items,
    ];
};
