import React from 'react';

import PropTypes from 'prop-types';

const IconWorkforce = ({
    color = '#e91e63',
    height = '19px',
    width = '19px',
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4476 7.31199C14.0964 7.65982 13.529 7.65982 13.1778 7.31199L12.8626 6.99983L8.22456 11.593V12.7435C8.22456 13.2162 8.03544 13.6621 7.69321 14.0011L3.44247 18.2107C3.09125 18.5585 2.52388 18.5585 2.17265 18.2107L0.26342 16.3199C-0.0878067 15.9721 -0.0878067 15.4102 0.26342 15.0624L4.51416 10.8527C4.84738 10.5138 5.30668 10.3265 5.78398 10.3265H6.95474L7.72023 9.56843L4.00083 5.88499H3.10025C2.85709 5.88499 2.63195 5.7958 2.46084 5.62634L0.641664 3.81584C0.290438 3.46801 0.290438 2.90612 0.641664 2.55829L1.92049 1.29183C2.27172 0.943998 2.83908 0.943998 3.19031 1.29183L5.00948 3.10234C5.17159 3.27179 5.27065 3.49476 5.27065 3.73557V4.62744L8.97204 8.31089L11.5747 5.73337L11.2595 5.42121C10.9083 5.07338 10.9083 4.5115 11.2595 4.16367L11.8989 3.53044H9.61145C9.05309 3.53044 8.7559 2.85261 9.16116 2.45127L11.4486 0.185904C11.8539 -0.21544 12.5383 0.0788787 12.5383 0.631841V2.8972L13.1778 2.26397C13.529 1.91614 14.0964 1.91614 14.4476 2.26397L16.9962 4.78798C18.0499 5.83147 18.0499 7.52604 16.9962 8.56953L15.087 6.67876L14.4476 7.31199ZM18.7373 14.9559C19.0876 15.3028 19.0876 15.8704 18.7373 16.2172C18.3871 16.5641 17.8139 16.5641 17.4637 16.2172L16.1901 14.9559L17.4637 13.6946L18.7373 14.9559ZM16.1901 17.4786L14.9165 16.2173L13.6429 17.4786L14.9165 18.7399C15.2667 19.0867 15.8398 19.0867 16.1901 18.7399C16.5403 18.393 16.5403 17.8254 16.1901 17.4786ZM15.5533 10.5414L16.8269 11.8027C17.1771 12.1496 17.1771 12.7171 16.8269 13.064L13.0061 16.8479C12.6558 17.1948 12.0827 17.1948 11.7324 16.8479L10.4588 15.5866C9.75834 14.8929 9.75834 13.7577 10.4588 13.064L9.82202 12.4333C9.47177 12.0865 9.47177 11.5189 9.82202 11.172L11.0956 9.91074C11.4459 9.56388 12.019 9.56388 12.3692 9.91074L13.0061 10.5414C13.7065 9.84768 14.8528 9.84768 15.5533 10.5414Z"
            fill={color}
        />
    </svg>
);

IconWorkforce.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconWorkforce;
