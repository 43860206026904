import { INITIALIZE_FORM } from '../actionTypes';
import { locationFormActions } from '../reducer';

export default (values, name) =>
    locationFormActions[INITIALIZE_FORM]({
        name,
        payload: {
            ...values,
            position: {
                ...values.position,
                latlng:
                    values.position.latitude && values.position.longitude
                        ? `${values.position?.latitude}, ${values.position.longitude}`
                        : '',
            },
        },
    });
