import proposalGeneratorAccesoryTable from '../../proposalGeneratorAccessoryTable';
import proposalGeneratorAdditionalTable from '../../proposalGeneratorAdditionalTable';
import proposalGeneratorEnergySaving from '../../proposalGeneratorEnergySaving';
import proposalGeneratorInverterTable from '../../proposalGeneratorInverterTable';
import proposalGeneratorPanelTable from '../../proposalGeneratorPanelTable';
import proposalGeneratorStructureTable from '../../proposalGeneratorStructureTable';
import proposalGeneratorTaxesTable from '../../proposalGeneratorTaxesTable';
import proposalGeneratorWorkforceTable from '../../proposalGeneratorWorkforceTable';

export default () => (dispatch) => {
    dispatch(proposalGeneratorEnergySaving.actions.resetForm());
    dispatch(proposalGeneratorAccesoryTable.actions.resetForm());
    dispatch(proposalGeneratorAdditionalTable.actions.resetForm());
    dispatch(proposalGeneratorInverterTable.actions.resetForm());
    dispatch(proposalGeneratorPanelTable.actions.resetForm());
    dispatch(proposalGeneratorStructureTable.actions.resetForm());
    dispatch(proposalGeneratorWorkforceTable.actions.resetForm());
    dispatch(proposalGeneratorTaxesTable.actions.resetForm());
};
