import { styled } from '@mui/material/styles';

export default styled('span')`
    align-items: center;
    border: ${({ variant }) =>
            variant && variant === 'new' ? `3px dashed` : `1px solid`}
        #ecedf0;
    border-radius: 100%;
    color: #ecedf0;
    display: flex;
    font-size: 22px;
    height: 132px;
    justify-content: center;
    margin-bottom: 15px;
    padding: 10px;
    width: 132px;

    picture {
        align-items: center;
        border-radius: 100%;
        display: flex;
        height: 100%;
        overflow: hidden;
    }

    img {
        max-height: 100%;
        width: 100%;
    }

    ${({ variant }) =>
        variant === 'folder' &&
        `
            display: inline-flex;
            height: 60px;
            margin-top: -15px;
            padding: 2px;
            width: 60px;
    `}
`;
