import { push } from 'connected-react-router';

export default (contactId = null, projectId = null, reportId = null) =>
    (dispatch) => {
        if (reportId) dispatch(push(`/report/${reportId}?tab=projects`));
        else {
            let link = `/record/${contactId}?tab=projects`;
            if (projectId) link += `&project=${projectId}&view=reports`;
            dispatch(push(link));
        }
    };
