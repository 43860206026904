import { editReport } from 'common/api/v1/editReporter';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    EDIT_REPORT,
    EDIT_REPORT_FAILURE,
    EDIT_REPORT_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';
import * as selectors from '../selectors';

import fetchHistoricalInfo from './fetchHistoricalInfo';
import fetchPeriodConfig from './fetchPeriodConfig';
import fetchProjectConfiguration from './fetchProjectConfiguration';
import fetchReportComplements from './fetchReportComplements';

export default (reportId) => (dispatch, getState) => {
    dispatch(actions[EDIT_REPORT]());
    editReport(reportId)
        .then(() => {
            dispatch(actions[EDIT_REPORT_SUCCESS]());

            const state = getState();
            const reportDetailsData = selectors.getReportDetailsData(state);
            const projectId = reportDetailsData?.project?.id;

            dispatch(fetchHistoricalInfo(reportId, projectId));
            dispatch(fetchPeriodConfig(reportId));
            dispatch(fetchProjectConfiguration(reportId, projectId));
            dispatch(fetchReportComplements(reportId));
        })
        .catch((error) => {
            dispatch(actions[EDIT_REPORT_FAILURE](error));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
