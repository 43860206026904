import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from 'sunwise-ui';

const SearchBar = ({
    clearSearch,
    handleClick,
    open,
    resultBoxRef,
    searchFieldRef,
    searchQuery,
    setSearchQuery,
}) => {
    const { t } = useTranslation();

    let sx = {
        '& input': { height: '19px', transition: 'width 250ms' },
        '& fieldset': { borderRadius: '20px', borderWidth: '1px!important' },
    };

    if (open) sx = { ...sx, '& input': { ...sx['& input'], width: '400px' } };

    const onInput = (e) => setSearchQuery(e.target.value);

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            clearSearch();
            searchFieldRef.current.blur();
        }
        if (e.key === 'ArrowDown') {
            resultBoxRef?.current?.selectFirstItem();
        }
    };

    return (
        <TextField
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            autoComplete="off"
            focused={true}
            inputRef={searchFieldRef}
            onClick={handleClick}
            onFocus={handleClick}
            onInput={onInput}
            placeholder={t('Search')}
            sx={sx}
            value={searchQuery}
            variant="outlined"
            onKeyDown={handleKeyDown}
        />
    );
};

SearchBar.propTypes = {
    clearSearch: PropTypes.func,
    handleClick: PropTypes.func,
    open: PropTypes.bool,
    resultBoxRef: PropTypes.object,
    searchFieldRef: PropTypes.object,
    searchQuery: PropTypes.string,
    setSearchQuery: PropTypes.func,
};

export default SearchBar;
