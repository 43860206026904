import { updateTemplate } from 'common/api/v1/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    RENAME_ITEM,
    RENAME_ITEM_FAILURE,
    RENAME_ITEM_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

import filterTemplates from './filterTemplates';
import toggleModalTemplate from './toggleModalTemplate';

export default (template, filterData) => (dispatch) => {
    dispatch(templateLibraryActions[RENAME_ITEM]());

    const newValues = { title: template.title };

    updateTemplate(template.id, newValues)
        .then(() => {
            dispatch(templateLibraryActions[RENAME_ITEM_SUCCESS]());
            dispatch(toggleModalTemplate(false));
            dispatch(filterTemplates(filterData));
            showToast();
        })
        .catch((error) => {
            dispatch(
                templateLibraryActions[RENAME_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
