import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import renameSummary from './renameSummary';

export default (summary, isArchivedFilter) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Rename'),
        defaultTextResponse: summary.name,
        haveTextResponse: true,
        messages: [i18next.t('Are you sure you want to rename this element?')],
        onSuccess: (value) => {
            dispatch(
                renameSummary({ ...summary, name: value }, isArchivedFilter),
            );
        },
        title: i18next.t('Rename summary'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
