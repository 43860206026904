import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import {
    PDF_LOW_QUALITY,
    PDF_MEDIUM_QUALITY,
    PDF_REAL_TEXT,
} from './constants';

export const getDownloadFormatSelected = (downloadFormat, downloadOptions) => {
    const format = downloadOptions.find(
        (item) => item.value === downloadFormat && !item.hidden,
    );
    if (format) return format.name;
    return i18next.t('Select');
};

export const getDownloadOptions = () => [
    {
        disabled: true,
        name: i18next.t('Select'),
        value: '',
    },
    {
        name: 'PDF',
        value: PDF_REAL_TEXT,
    },
    {
        name: `PDF - ${i18next.t('Low quality')}`,
        value: PDF_LOW_QUALITY,
    },
    {
        name: `PDF - ${i18next.t('Medium quality')}`,
        value: PDF_MEDIUM_QUALITY,
    },
];

export const getIsLoadingContextMessage = (
    isFetchingCustomTemplate,
    loadingContext,
) => {
    if (isEmpty(loadingContext)) return;
    if (isFetchingCustomTemplate)
        loadingContext.openLoading(i18next.t('Loading').concat('...'));
    else loadingContext.closeLoading();
};

export const getIsUpdatingContextMessage = (
    editionDisabled,
    isUpdatingCustomTemplate,
    loadingContext,
    setEditionDisabled,
) => {
    if (!isEmpty(loadingContext)) return;
    if (isUpdatingCustomTemplate)
        loadingContext.openLoading(i18next.t('Saving').concat('...'));
    else {
        if (!editionDisabled) setEditionDisabled(true);
        loadingContext.closeLoading();
    }
};

export const handleCloseDrawer = (swipeableDrawerRef) => {
    if (swipeableDrawerRef.current) {
        const { handleOpen } = swipeableDrawerRef.current;
        handleOpen(false);
    }
};
