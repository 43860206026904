import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import approveChangesOncredit from './approveChangesOncredit';

export default (creditItem, onSuccess) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: [
            i18next.t(
                'Are you sure you accept the changes to the application?',
            ),
        ],
        onSuccess: () => {
            dispatch(approveChangesOncredit(creditItem, onSuccess));
        },
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
