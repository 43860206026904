import { getDictionaryById } from '../helpers';
import * as selectors from '../selectors';

import updateSegments from './updateSegments';

export default (segmentId) => (dispatch, getState) => {
    const state = getState();
    const segments = selectors.getSegmentsData(state);
    const segmentsDictionary = getDictionaryById(segments);
    const selectedSegment = segmentsDictionary[segmentId];
    const locked = selectedSegment?.locked || false;
    const newSegments = segments.map((item) => {
        if (segmentId === item.id) return { ...item, locked: !locked };
        return item;
    });
    dispatch(updateSegments(newSegments));
};
