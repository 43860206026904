import { uniq } from 'lodash';

import { SET_SELECTED_ITEMS } from '../actionTypes';
import { panelLayoutV2Actions } from '../reducer';
import * as selectors from '../selectors';

export default (config) => (dispatch, getState) => {
    const { isMulti, segmentId } = config;
    const state = getState();
    const selectedItems = selectors.getSelectedItems(state);

    const newSelectedItems = isMulti
        ? [...selectedItems, segmentId]
        : [segmentId];

    dispatch(panelLayoutV2Actions[SET_SELECTED_ITEMS](uniq(newSelectedItems)));
};
