import { styled } from '@mui/material/styles';

export default styled('div')`
    color: ${({ color }) => (color ? color : '#fff')};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '11px')};
    line-height: 11px;
    padding-left: 3px;
    padding-top: 4px;
    ${({ type }) => type === 'error' && `color: #f44336;`};
`;
