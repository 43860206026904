import i18next from 'i18next';

import { logoutActions } from './session';
export const handleInterceptorErrors = (
    cancellableRequests,
    error,
    withLogout,
) => {
    if (
        error.response &&
        cancellableRequests[error?.response?.config?.customId]
    ) {
        delete cancellableRequests[error?.response?.config?.customId];
    }
    if (
        error.code === 'ECONNABORTED' ||
        error.code === 'ERR_INTERNET_DISCONNECTED' ||
        typeof error.response === 'undefined'
    ) {
        const responseError = Object.assign({}, error);

        return Promise.reject({
            ...responseError,
            response: {
                data: { errors: [i18next.t('A connection error occurred')] },
            },
        });
    }

    if (withLogout && [401, 403].includes(error?.response?.status))
        logoutActions();
    return Promise.reject(error);
};
