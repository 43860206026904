import { getTemplates } from 'common/api/v1/templates';

import {
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorQuoteActions } from '../reducer';

export default () => (dispatch) =>
    new Promise((resolve) => {
        dispatch(proposalGeneratorQuoteActions[FETCH_TEMPLATES]());

        getTemplates({ orderBy: 'title', perPage: 100, sortBy: 'asc', type: 0 })
            .then((response) =>
                dispatch(
                    proposalGeneratorQuoteActions[FETCH_TEMPLATES_SUCCESS](
                        response.data.results.map((template) => ({
                            ...template,
                            id: template.external_uuid,
                        })),
                    ),
                ),
            )
            .catch((error) =>
                dispatch(
                    proposalGeneratorQuoteActions[FETCH_TEMPLATES_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            )
            .finally(resolve);
    });
