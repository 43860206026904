import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';

import {
    addDate,
    formatDate,
    parseDate,
    startOfYearDate,
    subDate,
} from 'common/utils/dates';
import { capitalize } from 'common/utils/helpers';
import {
    getCompensationSchemeByBackendKey,
    getCompensationSchemeByValue,
} from 'common/utils/helpers/rates';

import icoCertifiedRate from 'resources/icoCertifiedRate.svg';
import icoCustomRate from 'resources/icoCustomRate.svg';

import {
    ANNUAL_TYPE,
    HOURLY_SELECTION_BASE_COLOR,
    PRICE_REGEX,
    ROUND_VALUE,
    STAGGERED_RATE_TYPE,
} from './constants';

export const getFieldsHasErrors = (errors, fields) => {
    if (!errors || !fields?.length) return false;
    return fields.some((field) => errors[field]);
};

const isValidNumber = (value) => Number.isFinite(value);

const calcPeriodsByPeriodicityType = (periodicityType, startDate) => {
    switch (periodicityType) {
        case 'Monthly':
            return calcPeriods(startDate, 12, 1);
        case 'Bimonthly':
            return calcPeriods(startDate, 6, 2);
        case 'Quarter':
            return calcPeriods(startDate, 4, 3);
        default:
            return [];
    }
};

const changeSumConsumptionRelationAndPrice = ({
    conceptIndex,
    energyConcepts,
    value,
}) => {
    let priceSum = 0;
    let compensationSum = 0;

    energyConcepts.forEach((concept, index) => {
        const { compensation_relation, compensation_relation_price, price } =
            concept || {};
        let parsedPrice, parsedCompensation;

        if (!isNil(conceptIndex) && conceptIndex === index) {
            parsedPrice = parseFloat(value);
            parsedCompensation =
                parsedPrice * parseFloat(compensation_relation);
        } else {
            parsedPrice = parseFloat(price);
            parsedCompensation = parseFloat(compensation_relation_price);
        }

        if (isValidNumber(parsedPrice)) priceSum += parsedPrice;
        if (isValidNumber(parsedCompensation))
            compensationSum += parsedCompensation;
    });
    return {
        compensationSum: round(compensationSum, ROUND_VALUE),
        priceSum: round(priceSum, ROUND_VALUE),
    };
};

const changeTopValue = ({ fieldsName, index, setValue, value, values }) => {
    const cloneValues = cloneDeep(values);
    cloneValues[index].top_value = value;
    for (let i = index + 1; i < cloneValues.length; ++i) {
        const prevItem = cloneValues[i - 1];
        const prevTopValue = parseInt(prevItem?.top_value);
        const prevMinValue = parseInt(prevItem?.min_value);
        const topValue = parseInt(cloneValues[i]?.top_value);
        let minValue = parseInt(cloneValues[i]?.min_value);

        if (
            isValidNumber(prevTopValue) &&
            isValidNumber(minValue) &&
            prevTopValue + 1 !== minValue
        ) {
            cloneValues[i].min_value = `${prevTopValue + 1}`;
            minValue = prevTopValue + 1;
        }
        if (
            isValidNumber(topValue) &&
            isValidNumber(prevMinValue) &&
            (i === cloneValues.length - 1 || topValue <= minValue)
        )
            cloneValues[i].top_value = `${minValue + 1}`;
        if (setValue) setValue(`${fieldsName}.${i}`, cloneValues[i]);
    }
};

const getPreparedConcepts = (concepts, isStaggeredType) => {
    if (isStaggeredType)
        return sortBy(concepts, [(c) => parseInt(c.top_value)]);
    return concepts;
};

export const generatePeriodsByEnergyConcepts = (
    energyConcepts,
    periodicityType,
    paymentType,
) => {
    let periods = [];
    for (const item of energyConcepts) {
        const startDate = parseDate(item.date_start, 'dd/MM/yyyy');
        const endDate = parseDate(item.date_end, 'dd/MM/yyyy');
        const label = getPeriodLabel(startDate, endDate, periodicityType);
        let totalPrice = 0;
        let totalCompensation = 0;
        const currentConcepts = getPreparedConcepts(
            item.concepts,
            paymentType === STAGGERED_RATE_TYPE,
        );
        let tempEnergyConcepts = currentConcepts.map(
            (concept, index, array) => {
                const price = parseFloat(concept.price);
                const roundedPrice = round(price, ROUND_VALUE);
                const compensationValue = round(
                    price * parseFloat(concept.compensation_relation),
                    ROUND_VALUE,
                );
                totalPrice += roundedPrice;
                totalCompensation += compensationValue;
                const tempConcept = {
                    ...concept,
                    compensation_relation: `${concept.compensation_relation}`,
                    compensation_relation_price: `${compensationValue}`,
                    price: `${roundedPrice}`,
                    top_value: `${concept.top_value}`,
                };
                if (paymentType !== STAGGERED_RATE_TYPE) return tempConcept;

                if (index === 0) {
                    tempConcept.min_value = '0';
                } else if (index === array.length - 1) {
                    tempConcept.min_value = `${
                        parseInt(tempConcept.top_value) - 1
                    }`;
                } else {
                    tempConcept.min_value = `${
                        parseInt(array[index - 1].top_value) + 1
                    }`;
                }
                return tempConcept;
            },
        );
        periods.push({
            date_end: item.date_end,
            date_start: item.date_start,
            demand_concepts: { concepts: [] },
            energy_concepts: { concepts: tempEnergyConcepts, id: item.id },
            label,
            other_concepts: { concepts: [] },
            taxes_concepts: { concepts: [] },
            total_compensation: `${round(totalCompensation, ROUND_VALUE)}`,
            total_price: `${round(totalPrice, ROUND_VALUE)}`,
        });
    }
    return periods;
};

const generateConceptsInPeriodsBuild = ({
    isAnnualType,
    periodicityType,
    periods,
}) => {
    const periodLabelList = periods.map((period) => period.label);
    const periodIndexMapper = (item) => {
        const startDate = parseDate(item.date_start, 'dd/MM/yyyy');
        const endDate = parseDate(item.date_end, 'dd/MM/yyyy');
        const label = getPeriodLabel(startDate, endDate, periodicityType);
        return { ...item, periodIndex: periodLabelList.indexOf(label) };
    };
    let tempPeriods = [...periods];
    return {
        generateConceptsInPeriods: (concepts, conceptsTag) => {
            concepts
                .map(periodIndexMapper)
                .forEach(({ concepts, id, periodIndex }) => {
                    if (isAnnualType && periodIndex !== 0)
                        tempPeriods[periodIndex][conceptsTag] = {};
                    else
                        tempPeriods[periodIndex][conceptsTag] = {
                            concepts,
                            id,
                        };
                });
        },
        getPeriodsGenerated: () => tempPeriods,
    };
};

const getPeriodLabel = (startDate, endDate, periodicityType) => {
    if (periodicityType === 'Monthly')
        return capitalize(formatDate(startDate, 'MMMM'));
    return `${capitalize(formatDate(startDate, 'MMMM'))} - ${capitalize(
        formatDate(endDate, 'MMM'),
    )}`;
};

const calcPeriods = (startDate, periodSize, periodMonths) => {
    let currentStartDate = subDate(startDate, { days: 1 });
    const periods = [];
    for (let i = 0; i < periodSize; ++i) {
        currentStartDate = addDate(currentStartDate, { days: 1 });
        let label = capitalize(
            formatDate(currentStartDate, periodMonths > 1 ? 'MMM' : 'MMMM'),
        );
        const dateStart = formatDate(currentStartDate, 'dd/MM/yyyy');
        currentStartDate = addDate(currentStartDate, { months: periodMonths });
        currentStartDate = subDate(currentStartDate, { days: 1 });
        if (periodMonths > 1)
            label += ` - ${capitalize(formatDate(currentStartDate, 'MMM'))}`;
        const dateEnd = formatDate(currentStartDate, 'dd/MM/yyyy');
        periods.push({ date_end: dateEnd, date_start: dateStart, label });
    }
    return periods;
};

const parseConcepts = ({ concepts, isTopValueEnabled, type }) =>
    concepts.map((concept, index) => {
        const { is_default, top_value, ...restConcept } = concept || {};
        let newConcept = { ...restConcept, identifier: index };

        if (isTopValueEnabled) newConcept.top_value = `${top_value}`;
        if (['other_concepts', 'taxes_concepts'].includes(type))
            newConcept.is_default = is_default || false;
        return newConcept;
    });

export const parsePeriods = ({
    periods,
    isAnnualType,
    isTopValueEnabled,
    type,
}) =>
    periods
        .filter((period) => {
            const concepts = get(
                isAnnualType ? periods[0] : period,
                `${type}.concepts`,
                [],
            );
            return !isEmpty(concepts);
        })
        .map((period) => {
            const concepts = get(
                isAnnualType ? periods[0] : period,
                `${type}.concepts`,
                [],
            );
            if (!concepts?.length) return [];
            const id = get(
                isAnnualType ? periods[0] : period,
                `${type}.id`,
                null,
            );
            let newPeriod = {
                concepts: parseConcepts({ concepts, isTopValueEnabled, type }),
                date_end: period.date_end,
                date_start: period.date_start,
            };
            if (id) newPeriod.id = id;
            return newPeriod;
        });

const getDistributionFormatted = (dayDistribution) => {
    if (!dayDistribution) return [];

    return dayDistribution.map((value) => ({ value: value + 1 }));
};

const parseDistributionObjectToValues = (conceptDistribution) => {
    const weekday = getDistributionFormatted(
        conceptDistribution.weekday_distribution,
    );
    const saturday = getDistributionFormatted(
        conceptDistribution.saturday_distribution,
    );
    const sunday = getDistributionFormatted(
        conceptDistribution.sunday_distribution,
    );

    return { weekday, saturday, sunday };
};

export const addConceptInput = ({
    append,
    getValues,
    index,
    insert,
    isConceptsOtherChargesTaxes,
    isEnergyConcept,
    isHourlyRate,
    linkedConceptName,
    linkedConceptParentName,
    name,
    setValue,
    topValueFieldEnabled,
    unitItems,
    values,
}) => {
    const concept = {
        compensation_relation: '1',
        identifier: index + 1,
        price: '',
        tag_name: '',
        unit: unitItems?.[1]?.value ?? '',
    };
    if (isConceptsOtherChargesTaxes) {
        concept.is_default = true;
        concept.unit = '';
    }

    if (linkedConceptName && isHourlyRate) {
        const linkedConceptsValues = getValues(linkedConceptName);

        if (linkedConceptsValues.length) {
            setValue(linkedConceptName, [
                ...linkedConceptsValues,
                { ...concept, unit: linkedConceptsValues[0].unit },
            ]);
        }
    }
    if (isEnergyConcept) {
        concept.compensation_relation_price = '';
    }
    if (linkedConceptParentName && isHourlyRate) {
        const linkedConceptParentValues = getValues(linkedConceptParentName);

        const newFields = linkedConceptParentValues?.map((field) => ({
            ...concept,
            identifier: field.identifier,
            tag_name: field.tag_name,
        }));
        setValue(name, newFields);
        return;
    }

    if (!topValueFieldEnabled) {
        append(concept);
        return;
    }

    if (values.length === 0) {
        append([
            {
                ...concept,
                min_value: '0',
                top_value: '1',
                identifier: index,
                ...(isConceptsOtherChargesTaxes && { is_default: true }),
            },
            {
                ...concept,
                min_value: '2',
                top_value: '3',
                identifier: index + 1,
                ...(isConceptsOtherChargesTaxes && { is_default: true }),
            },
        ]);
    } else {
        setValue(`${name}.${index}`, {
            ...values[index],
            identifier: index,
            min_value: `${parseInt(values[index].top_value) + 1}`,
            top_value: `${parseInt(values[index].top_value) + 2}`,
        });
        insert(index, {
            ...concept,
            identifier: index,
            min_value: `${parseInt(values[index - 1].top_value) + 1}`,
            top_value: `${parseInt(values[index - 1].top_value) + 2}`,
            unit: values[index - 1].unit,
        });
    }
};

export const buildQueryParams = ({
    businessTypeSelected,
    filterName,
    isEnabled,
    page,
    rateTypeSelected,
    voltageTypeSelected,
}) => {
    let query = `page=${page}`;
    if (filterName) query += '&name=' + filterName;
    if (rateTypeSelected !== 'all') query += '&certified=' + rateTypeSelected;
    if (businessTypeSelected !== 'all')
        query += '&business_type=' + businessTypeSelected;
    if (voltageTypeSelected !== 'all')
        query += '&voltage_type=' + voltageTypeSelected;
    if (!isEnabled) query += '&is_enable=' + isEnabled;
    return query;
};

export const changeConsumptionRelationByPrice = ({
    conceptIndex,
    fieldsName,
    getValues,
    setValue,
    value,
}) => {
    const period = getValues(fieldsName);

    const valueValidated = value?.replace(PRICE_REGEX, '$1');
    const { priceSum, compensationSum } = changeSumConsumptionRelationAndPrice({
        conceptIndex,
        energyConcepts: period.energy_concepts.concepts,
        value: valueValidated,
    });
    if (priceSum !== parseFloat(period.total_price))
        setValue(`${fieldsName}.total_price`, `${priceSum}`);
    if (compensationSum !== parseFloat(period.total_compensation))
        setValue(`${fieldsName}.total_compensation`, `${compensationSum}`);
    const compensationRelation = parseFloat(
        period.energy_concepts.concepts[conceptIndex]?.compensation_relation,
    );
    const _valueValidated = parseFloat(valueValidated);

    if (isValidNumber(compensationRelation) && isValidNumber(_valueValidated))
        setValue(
            `${fieldsName}.energy_concepts.concepts.${conceptIndex}.compensation_relation_price`,
            `${round(compensationRelation * _valueValidated, ROUND_VALUE)}`,
            { shouldValidate: true },
        );
    return valueValidated;
};

export const createPeriodsByPeriodicityType = ({
    isAnnualType,
    name = 'settings_data.periods',
    paymentType,
    periodicityType = 'Monthly',
    setValue,
}) => {
    const startDate = startOfYearDate(new Date());
    const periods = calcPeriodsByPeriodicityType(
        periodicityType,
        startDate,
    ).map((period, index) => {
        let tempEnergyConcepts = { concepts: [] };

        if (isAnnualType && index != 0) {
            const concept = {
                compensation_relation: '1',
                compensation_relation_price: '0',
                price: '0',
                tag_name: 'validationFix',
                unit: '-',
            };
            if (paymentType === STAGGERED_RATE_TYPE) {
                concept.min_value = '0';
                concept.top_value = '1';
            }
            tempEnergyConcepts.concepts = [concept];
        }
        return {
            ...period,
            demand_concepts: { concepts: [] },
            energy_concepts: tempEnergyConcepts,
            other_concepts: { concepts: [] },
            taxes_concepts: { concepts: [] },
            total_compensation: '0',
            total_price: '0',
        };
    });

    if (setValue) setValue(name, periods);
    return periods;
};

export const getVoltageDotColor = (tensionType) => {
    switch (tensionType) {
        case 'Low tension':
            return '#caf61b';
        case 'Mid tension':
            return '#FF8F02';
        case 'High tension':
            return '#FA6968';
        default:
            return null;
    }
};

export const getRateTypeIcon = (isCertified) => {
    switch (`${isCertified}`) {
        case 'true':
            return icoCertifiedRate;
        case 'false':
            return icoCustomRate;
        default:
            return null;
    }
};

let topValueInterval;

export const handleNormalizeTopValueBuild = ({
    fieldsName,
    index,
    newValue,
    setValue,
    values,
}) => {
    if (!isNaN(newValue)) {
        clearTimeout(topValueInterval);
        topValueInterval = setTimeout(() => {
            topValueInterval = null;
            changeTopValue({
                fieldsName,
                index,
                setValue,
                value: newValue,
                values,
            });
        }, 500);
    } else {
        clearTimeout(topValueInterval);
        topValueInterval = null;
    }
};

export const handleNormalizeUnitBuild = ({
    fieldsName,
    index,
    onlyUnitEnabled,
    setValue,
    value,
    values,
}) => {
    if (!onlyUnitEnabled) {
        setValue(`${fieldsName}.${index}.unit`, value);
        return;
    }
    for (let i = 0; i < values.length; ++i)
        setValue(`${fieldsName}.${i}.unit`, value, { shouldValidate: true });
};

export const getRatePeriods = ({
    contractedDemandConcepts,
    demandConcepts,
    otherConcepts,
    periodicityType,
    periods,
    spainExcessDemandConcepts,
    taxesConcepts,
    typeOfInputCharges,
}) => {
    const isAnnualType = typeOfInputCharges === ANNUAL_TYPE;

    const { generateConceptsInPeriods, getPeriodsGenerated } =
        generateConceptsInPeriodsBuild({
            isAnnualType,
            periodicityType,
            periods,
        });

    const concepts = [
        { data: contractedDemandConcepts, name: 'contracted_demand_concepts' },
        { data: demandConcepts, name: 'demand_concepts' },
        { data: otherConcepts, name: 'other_concepts' },
        { data: spainExcessDemandConcepts, name: 'excess_demand_concepts' },
        { data: taxesConcepts, name: 'taxes_concepts' },
    ];

    for (const { data, name } of concepts) {
        if (data?.length > 0) generateConceptsInPeriods(data, name);
    }

    return getPeriodsGenerated();
};

export const parseBodyObjectToFormValues = (data) => {
    const {
        annual_percentage_increase,
        business_type,
        certified,
        contracted_demand_concepts,
        country,
        currency,
        default_compensation_scheme,
        demand_concepts,
        energy_concepts,
        energy_export_price,
        id,
        name,
        netted_exported_generation_price,
        other_concepts,
        payback_type,
        payment_type,
        percent_bags_used = 1,
        period_compensation,
        periodicity_type,
        rate_description,
        short_name,
        spain_excess_demand_concepts,
        start_period_comp_mont,
        taxes_concepts,
        tiers_demand_distribution,
        tiers_energy_distribution,
        type_of_input_charges,
        utility_supplier,
        voltage_type,
    } = data;
    let periods = [];
    let hourly_distribution = {};

    if (!certified) {
        const tempPeriods = generatePeriodsByEnergyConcepts(
            energy_concepts,
            periodicity_type,
            payment_type,
        );

        periods = getRatePeriods({
            contractedDemandConcepts: contracted_demand_concepts,
            demandConcepts: demand_concepts,
            otherConcepts: other_concepts,
            periodicityType: periodicity_type,
            periods: tempPeriods,
            spainExcessDemandConcepts: spain_excess_demand_concepts,
            taxesConcepts: taxes_concepts,
            typeOfInputCharges: type_of_input_charges,
        });

        if (tiers_energy_distribution)
            hourly_distribution.energy_concepts =
                parseDistributionObjectToValues(tiers_energy_distribution);
        if (tiers_demand_distribution)
            hourly_distribution.demand_concepts =
                parseDistributionObjectToValues(tiers_demand_distribution);
    }
    return {
        id,
        main_data: {
            annual_percentage_increase,
            business_type,
            certified,
            country,
            currency,
            name,
            name_short: short_name,
            payment_type,
            periodicity_type,
            rate_description,
            type_of_input_charges,
            utility_supplier,
            voltage_type,
        },
        policy_data: {
            default_compensation_scheme,
            energy_export_price: energy_export_price || 0,
            netted_exported_generation_price:
                netted_exported_generation_price || 0,
            payback_type,
            percent_bags_used: parseInt(parseFloat(percent_bags_used) * 100),
            period_compensation,
            start_period_comp_mont,
        },
        settings_data: {
            periods,
            hourly_distribution,
        },
    };
};

export const getJsonValues = (values) => {
    const { main_data, policy_data, settings_data } = values;
    const {
        annual_percentage_increase,
        business_type,
        country,
        currency,
        name,
        name_short,
        payment_type,
        periodicity_type,
        rate_description,
        type_of_input_charges,
        utility_supplier,
        voltage_type,
    } = main_data;

    const {
        default_compensation_scheme,
        energy_export_price,
        netted_exported_generation_price,
        payback_type,
    } = policy_data;
    const { periods, hourly_distribution } = settings_data;
    const { demand_concepts, energy_concepts } = hourly_distribution || {};
    const isAnnualType = type_of_input_charges === ANNUAL_TYPE;

    const contractedDemandConcepts = parsePeriods({
        isAnnualType,
        periods,
        type: 'contracted_demand_concepts',
    });
    const demandConcepts = parsePeriods({
        isAnnualType,
        periods,
        type: 'demand_concepts',
    });
    const energyConcepts = parsePeriods({
        isAnnualType,
        isTopValueEnabled: payment_type === STAGGERED_RATE_TYPE,
        periods,
        type: 'energy_concepts',
    });
    const excessDemandConcepts = parsePeriods({
        isAnnualType,
        periods,
        type: 'excess_demand_concepts',
    });
    const otherConcepts = parsePeriods({
        isAnnualType,
        periods,
        type: 'other_concepts',
    });
    const taxesConcepts = parsePeriods({
        isAnnualType,
        periods,
        type: 'taxes_concepts',
    });

    return handleSchedulePaymentType({
        annual_percentage_increase,
        business_type,
        contracted_demand_concepts: contractedDemandConcepts,
        country,
        currency,
        default_compensation_scheme: getCompensationSchemeByValue(
            default_compensation_scheme,
        )?.backendKey,
        demand_concepts: demandConcepts,
        demand_concepts_distribution: demand_concepts,
        energy_concepts: energyConcepts,
        energy_concepts_distribution: energy_concepts,
        energy_export_price: energy_export_price || 0,
        name,
        name_short,
        netted_exported_generation_price: netted_exported_generation_price || 0,
        other_concepts: otherConcepts,
        payback_type,
        payment_type,
        periodicity_type,
        rate_description,
        spain_excess_demand_concepts: excessDemandConcepts,
        taxes_concepts: taxesConcepts,
        type_of_input_charges,
        utility_supplier,
        voltage_type,
    });
};

export const removeConceptInput = ({
    conceptsFieldsName,
    getValues,
    indexToRemove,
    isEnergyConcept,
    isHourlyRate,
    linkedConceptName,
    periodFieldName,
    setValue,
    topValueFieldEnabled,
    values,
}) => {
    if (!values || indexToRemove > values?.length - 1) return;
    let cloneValues = cloneDeep(values);
    if (topValueFieldEnabled) {
        if (cloneValues?.length <= 2) cloneValues = [];
        else {
            const topValue = parseInt(cloneValues[indexToRemove - 1].top_value);
            if (indexToRemove === 0) cloneValues[1].min_value = '0';
            else if (isValidNumber(topValue)) {
                cloneValues[indexToRemove + 1].min_value = `${topValue + 1}`;

                if (indexToRemove + 1 === cloneValues.length - 1)
                    cloneValues[indexToRemove + 1].top_value = `${
                        parseInt(cloneValues[indexToRemove + 1].min_value) + 1
                    }`;
            }
        }
    }
    cloneValues = cloneValues.filter((_, index) => index !== indexToRemove);

    setValue(conceptsFieldsName, cloneValues);

    if (isEnergyConcept) {
        const { priceSum, compensationSum } =
            changeSumConsumptionRelationAndPrice({
                energyConcepts: cloneValues,
            });
        setValue(`${periodFieldName}.total_compensation`, `${compensationSum}`);
        setValue(`${periodFieldName}.total_price`, `${priceSum}`);
    }

    if (!isHourlyRate || !linkedConceptName) return;

    const linkedConceptsValues = getValues(linkedConceptName);
    const newLinkedConceptsValues = linkedConceptsValues?.filter(
        (_, index) => index !== indexToRemove,
    );
    setValue(linkedConceptName, newLinkedConceptsValues);
};

export const getYearlyDistribution = (distribution) =>
    distribution.map((hour) => hour.value - 1);

export const getConceptDistribution = (concept) => {
    if (!concept) return null;
    const { weekday, saturday, sunday } = concept;
    return {
        weekday_distribution: getYearlyDistribution(weekday),
        saturday_distribution: getYearlyDistribution(saturday),
        sunday_distribution: getYearlyDistribution(sunday),
    };
};

export const handleSchedulePaymentType = (values) => {
    if (values.payment_type !== 'Schedule') return values;

    let _values = cloneDeep(values);

    const tiersEnergyDistribution = getConceptDistribution(
        _values.energy_concepts_distribution,
    );
    const tiersDemandDistribution = getConceptDistribution(
        _values.demand_concepts_distribution,
    );

    delete _values.energy_concepts_distribution;
    delete _values.demand_concepts_distribution;

    let newValues = { ..._values };
    if (tiersEnergyDistribution)
        newValues.tiers_energy_distribution = tiersEnergyDistribution;
    if (tiersDemandDistribution)
        newValues.tiers_demand_distribution = tiersDemandDistribution;

    return newValues;
};

export const getValuesWithCompensationScheme = (data = {}) => {
    const defaultCompensationScheme = getCompensationSchemeByBackendKey({
        backendKey: data.default_compensation_scheme,
        certified: data.certified,
        name: data.name,
        paymentType: data.payment_type,
    });
    return {
        ...data,
        default_compensation_scheme: defaultCompensationScheme?.value,
    };
};

export const getTierColorByIndex = (index, length) => {
    if (!index || !length) return `rgba(${HOURLY_SELECTION_BASE_COLOR}, 1)`;
    return `rgba(${HOURLY_SELECTION_BASE_COLOR}, ${1 - index / length})`;
};
