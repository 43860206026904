import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from 'sunwise-ui';

import { EmptyMessage } from 'common/components';
import ReactHookFormCheck from 'common/components/form/bootstrap/ReactHookFormCheck';
import { INSURANCE_TYPE } from 'common/constants';

import { isDisabledButton } from '../helpers';

const FormContainer = ({
    control,
    financierType,
    handleOnSave,
    handleSubmit,
    isFetching,
    isSavingFinancierInstaller,
    registered,
    requirements,
}) => {
    const { t } = useTranslation();

    if (isFetching) {
        return (
            <EmptyMessage
                text={t('Loading terms and conditions').concat('...')}
            />
        );
    }

    const getContent = () => {
        if (requirements.content.length === 0)
            return <EmptyMessage text={t('No terms and conditions to show')} />;
        return (
            <Box
                dangerouslySetInnerHTML={{
                    __html: requirements.content,
                }}
                sx={{
                    background: '#fff',
                    borderRadius: 1,
                    color: '#000',
                    padding: 2,
                }}
            />
        );
    };

    return (
        <>
            <Grid container>
                <Grid item>{getContent()}</Grid>
            </Grid>
            {financierType === INSURANCE_TYPE && (
                <Box py={2}>
                    <ReactHookFormCheck
                        control={control}
                        label={t('I completed my registration')}
                        id="company_registered"
                        name="company_registered"
                    />
                </Box>
            )}
            <Grid container mt={2}>
                <Grid item textAlign="right" xs={18}>
                    <Button
                        disabled={
                            isDisabledButton(
                                financierType,
                                registered,
                                requirements,
                            ) || isSavingFinancierInstaller
                        }
                        id="alliances-terms-conditions-accept-button"
                        onClick={handleSubmit(handleOnSave)}
                    >
                        {isSavingFinancierInstaller
                            ? t('Saving').concat('...')
                            : t('I Accept Terms')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

FormContainer.propTypes = {
    control: PropTypes.object,
    financierType: PropTypes.number,
    handleOnSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    isFetching: PropTypes.bool,
    isSavingFinancierInstaller: PropTypes.bool,
    registered: PropTypes.bool,
    requirements: PropTypes.object,
};

export default FormContainer;
