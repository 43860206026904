import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as contactFormActions from '../contactForm/actions';
import * as contactFormSelectors from '../contactForm/selectors';

import * as actions from './actions';
import { SupportContactCard, Modal } from './components';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    canView,
    contactId,
    dataSupportContacts,
    errors,
    fetchPhoneCodes,
    getSupportContacts,
    handleClickCloseModal,
    handleClickCreate,
    handleClickDelete,
    initialValues,
    isFetchingSupportContacts,
    isUploading,
    isVisibleModal,
    phoneCodes,
    prepareUpdateForm,
    save,
    setContactId,
}) => {
    useEffect(() => {
        setContactId(contactId);
        getSupportContacts();
        fetchPhoneCodes();
    }, []);

    const handleSubmit = (values) => save(values);

    if (!canView) return null;

    return (
        <Box mb={2}>
            <SupportContactCard
                canDelete={canDelete}
                canModify={canModify}
                handleClickAdd={handleClickCreate}
                handleClickDelete={handleClickDelete}
                handleClickUpdate={prepareUpdateForm}
                isFetchingSupportContacts={isFetchingSupportContacts}
                supportContacts={dataSupportContacts}
            />

            <Modal
                errors={errors}
                handleClickClose={handleClickCloseModal}
                handleSubmit={handleSubmit}
                initialValues={initialValues}
                isUploading={isUploading}
                phoneCodes={phoneCodes}
                show={isVisibleModal}
            />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    dataSupportContacts: selectors.getDataSupportContacts,
    errors: selectors.getErrors,
    initialValues: selectors.getInitialValues,
    isFetchingSupportContacts: selectors.getIsLoadingFetchSupportContacts,
    isVisibleModal: selectors.getIsVisibleModal,
    phoneCodes: contactFormSelectors.getPhoneCodesData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPhoneCodes: () => dispatch(contactFormActions.fetchPhoneCodes()),
    getSupportContacts: () => dispatch(actions.fetchSupportContact()),
    handleClickCloseModal: () => dispatch(actions.closeModal()),
    handleClickCreate: (values) => dispatch(actions.prepareCreateForm(values)),
    handleClickDelete: (item) => dispatch(actions.prepareDelete(item)),
    prepareUpdateForm: (id) => dispatch(actions.prepareUpdateForm(id)),
    save: (values) => dispatch(actions.saveSupportContact(values)),
    setContactId: (id) => dispatch(actions.setContactId(id)),
    showModal: (value) => dispatch(actions.showModal(value)),
});

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    contactId: PropTypes.string,
    dataSupportContacts: PropTypes.array,
    errors: PropTypes.array,
    fetchPhoneCodes: PropTypes.func,
    getSupportContacts: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingSupportContacts: PropTypes.bool,
    isUploading: PropTypes.bool,
    isVisibleModal: PropTypes.bool,
    phoneCodes: PropTypes.object,
    prepareUpdateForm: PropTypes.func,
    save: PropTypes.func,
    setContactId: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.SUPPORT_CONTACTS_PERMISSION),
)(Container);
