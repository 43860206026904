import { getData } from 'common/api/v1/periodReporter';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_PERIOD_SETTINGS,
    FETCH_PERIOD_SETTINGS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import initialValues from './initialValues';

export default (reportId) => (dispatch) => {
    dispatch(actions[FETCH_PERIOD_SETTINGS]());
    getData(reportId)
        .then((response) => {
            const data = response?.data?.data;
            dispatch(actions[FETCH_PERIOD_SETTINGS_SUCCESS](data));

            dispatch(initialValues(data));
        })
        .catch((error) => {
            dispatch(
                actions[FETCH_PERIOD_SETTINGS_SUCCESS](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
