import React from 'react';

import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'sunwise-ui';

const HitchTypeField = ({ formValues, isLocked, setValue }) => {
    return (
        <ToggleButtonGroup
            color="primary"
            disabled={isLocked}
            exclusive
            onChange={(e) => setValue('is_percentage', e.target.value)}
            value={formValues.is_percentage}
            size="small"
            sx={{ ml: 1 }}
        >
            <ToggleButton
                value="false"
                sx={{
                    p: '7px 16px',
                }}
            >
                $
            </ToggleButton>
            <ToggleButton
                value="true"
                sx={{
                    p: '7px 16px',
                }}
            >
                %
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

HitchTypeField.propTypes = {
    formValues: PropTypes.object,
    isLocked: PropTypes.bool,
    setValue: PropTypes.func,
};

export default HitchTypeField;
