import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    align-items: center;
    border-bottom: 1px solid #eff1fb;
    display: flex;
    height: 58px;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
`;
