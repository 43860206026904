import React, { useEffect } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card, Divider, Grid, Typography } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import CurrencySettings from './CurrencySettings';

const FormContainer = ({
    canModify,
    errors,
    initialValues,
    isSaving,
    items,
    save,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    const formValues = watch();

    const isoCode = get(formValues, 'abbreviation', null);
    const exchangeType = get(formValues, 'exchange_type', null);

    const handleClickSave = (values) => save(values, items);

    const isDisabled = !get(formValues, 'is_enabled', false) || !canModify;

    const isDisabledButton = isDisabled || isEqual(initialValues, formValues);

    const isDisabledSource =
        isDisabled || isEmpty(exchangeType) || exchangeType === 'MNL';

    const isDisabledPrice =
        isDisabled || isEmpty(exchangeType) || exchangeType === 'AUT';

    const newPrice = (value) => {
        if (value > 0) return 1 / value;
        return 0;
    };

    const handleChangeDollarPrice = (value) => {
        setValue('dollar_price', value);
        setValue('inverse_dollar_price', newPrice(value));
    };

    const handleChangeInverseDollarPrice = (value) => {
        setValue('inverse_dollar_price', value);
        setValue('dollar_price', newPrice(value));
    };

    return (
        <form onSubmit={handleSubmit(handleClickSave)}>
            <Card>
                <Card.Header>
                    <Typography fontWeight="bold" variant="h5">
                        {formValues.name}
                    </Typography>
                </Card.Header>
                <Card.Body>
                    <Typography fontWeight="bold" variant="body2">
                        {t('General data')}
                    </Typography>

                    <Divider sx={{ my: 2 }} />

                    <Grid container>
                        <Grid item xs={18} md={6}>
                            <ReactHookFormInput
                                control={control}
                                disabled
                                name="name"
                                type="text"
                                label={t('Currency name')}
                            />
                        </Grid>

                        <Grid item xs={18} md={6}>
                            <ReactHookFormInput
                                control={control}
                                disabled
                                name="symbol"
                                type="text"
                                label={t('Symbol')}
                            />
                        </Grid>

                        <Grid item xs={18} md={6}>
                            <ReactHookFormInput
                                control={control}
                                disabled
                                name="abbreviation"
                                type="text"
                                label={t('ISO code')}
                            />
                        </Grid>
                    </Grid>

                    <CurrencySettings
                        control={control}
                        countryCurrencyLocale={getCountryCurrencyLocale()}
                        formValues={formValues}
                        handleChangeDollarPrice={(value) =>
                            handleChangeDollarPrice(value)
                        }
                        handleChangeInverseDollarPrice={(value) =>
                            handleChangeInverseDollarPrice(value)
                        }
                        isDisabled={isDisabled}
                        isDisabledPrice={isDisabledPrice}
                        isDisabledSource={isDisabledSource}
                        isoCode={isoCode}
                        setValue={setValue}
                    />
                </Card.Body>
            </Card>

            <Card>
                <Card.Header>
                    <Typography fontWeight="bold" variant="h5">
                        {t('Financial data')}
                    </Typography>
                </Card.Header>
                <Card.Body>
                    <ReactHookFormIntlNumberInput
                        append={'%'}
                        control={control}
                        disabled={isDisabled}
                        fullWidth
                        label={t('Discount rates')}
                        max={99}
                        min={0}
                        name="discount_rate"
                    />
                </Card.Body>
            </Card>

            <ShowErrors errors={errors} />

            {!isDisabledButton && (
                <Box textAlign="right">
                    <Button
                        disabled={isDisabledButton}
                        endIcon={<ArrowForwardIcon />}
                        type="submit"
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                    >
                        {isSaving
                            ? t('Saving').concat('...')
                            : t('Save changes')}
                    </Button>
                </Box>
            )}
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSavingItem,
    items: selectors.getDataFetchItems,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, items) => dispatch(actions.save(values, items)),
});

FormContainer.propTypes = {
    canModify: PropTypes.bool,
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    items: PropTypes.array,
    save: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
