import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    color: #848bab;
    font-size: 13px;
    line-height: 16px;
    padding: 0 16px;

    a {
        border-bottom: 1px solid #012b43;
        box-shadow: inset 0px -5px 5px -5px rgba(0, 0, 0, 0.75);
        color: #848bab;
        display: block;
        font-size: 13px;
        line-height: 16px;
        padding: 8px 16px;
        position: relative;

        &.selected:before {
            background-color: #2f4dff;
            content: '';
            display: block;
            height: 50%;
            left: 0;
            position: absolute;
            top: 25%;
            width: 2px;
        }
    }
`;
