import Geocode from 'react-geocode';

import { getDataFromGeocode } from 'common/utils/helpers';
import { getIsoCodeCurrentLocale } from 'common/utils/helpers/multiregion';

import {
    FETCH_GEOCODE,
    FETCH_GEOCODE_FAILURE,
    FETCH_GEOCODE_SUCCESS,
} from '../actionTypes';
import { createAccountActions } from '../reducer';

export default (items, lat, lng) => (dispatch) => {
    Geocode.setApiKey(import.meta.env.VITE_GOOGLE_MAPS_KEY);
    Geocode.setLanguage(getIsoCodeCurrentLocale());
    Geocode.setRegion('mx');

    dispatch(createAccountActions[FETCH_GEOCODE]());

    return Geocode.fromLatLng(lat, lng).then(
        (response) => {
            const address = getDataFromGeocode(response.results, 'country');
            dispatch(createAccountActions[FETCH_GEOCODE_SUCCESS](address));
        },
        (error) => dispatch(createAccountActions[FETCH_GEOCODE_FAILURE](error)),
    );
};
