import { bulkMargin } from 'common/api/v1/productsBulk';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_BULK_MARGIN,
    SAVE_BULK_MARGIN_FAILURE,
    SAVE_BULK_MARGIN_SUCCESS,
} from '../actionTypes';
import { workforceConfigurationActions } from '../reducer';

import closeModalMargin from './closeModalMargin';

export default (filterData, values) => (dispatch) => {
    dispatch(workforceConfigurationActions[SAVE_BULK_MARGIN]());

    const products = values.ids.map((id) => ({
        id,
        margin: values.margin,
    }));

    bulkMargin({
        product_type: 'workforces',
        products,
    })
        .then(() => {
            dispatch(workforceConfigurationActions[SAVE_BULK_MARGIN_SUCCESS]());
            dispatch(closeModalMargin(filterData));
            if (values.callback) values.callback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                workforceConfigurationActions[SAVE_BULK_MARGIN_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
