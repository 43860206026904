import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Mention, MentionsInput } from 'react-mentions';
import { Box } from 'sunwise-ui';

import { mentionsMessageStyle, mentionStyle } from '../../trackings/components';
import { getUsersOptions } from '../../trackings/helpers';

const DescriptionFormatter = ({ description, isCompleted, users }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    return (
        <Box display="flex">
            <MentionsInput
                style={{
                    ...mentionsMessageStyle,
                    input: {
                        ...mentionsMessageStyle.input,
                        color: isDarkMode ? '#fff' : '#000',
                        textDecoration: isCompleted ? 'line-through' : 'none',
                    },
                }}
                value={description}
            >
                <Mention
                    data={getUsersOptions(users)}
                    style={
                        isDarkMode
                            ? {
                                  backgroundColor: 'rgba(255, 255, 255, 0.09)',
                              }
                            : mentionStyle
                    }
                />
            </MentionsInput>
        </Box>
    );
};

DescriptionFormatter.propTypes = {
    description: PropTypes.string,
    isCompleted: PropTypes.bool,
    users: PropTypes.array,
};

export default DescriptionFormatter;
