import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_BATTERIES,
    FETCH_BATTERIES_FAILURE,
    FETCH_BATTERIES_SUCCESS,
    GET_OFFER,
    GET_OFFER_FAILURE,
    GET_OFFER_SUCCESS,
    INITIAL_VALUES,
    RESET_DATA,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_STORAGE_PLAN,
} from './actionTypes';
import { DEFAULT_BACKUP_PERCENTAGE, NAME } from './constants';

const INITIAL_STATE = {
    batteries: {
        data: [],
        errors: [],
        isFetching: false,
    },
    initialValues: {
        daily_storage_percentage: null,
        product_branch_office: null,
        quantity: 1,
        storage_percent: DEFAULT_BACKUP_PERCENTAGE,
        top_value_slider: 100,
    },
    offer: {
        data: null,
        errors: null,
        isFetching: false,
    },
    save: {
        data: null,
        errors: [],
        isSaving: false,
    },
    storagePlan: {
        daily_cap_batteries: 0,
        daily_cap_percentage: 0,
        total_backup_days: 0,
    },
};

const energyBackupSetupSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_BATTERIES]: (state) => {
            state.batteries = {
                ...state.batteries,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_BATTERIES_FAILURE]: (state, action) => {
            state.batteries = {
                ...state.batteries,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BATTERIES_SUCCESS]: (state, action) => {
            state.batteries = {
                ...state.batteries,
                data: action.payload,
                isFetching: false,
            };
        },
        [GET_OFFER]: (state) => {
            state.offer = {
                ...state.offer,
                data: null,
                errors: null,
                isFetching: true,
            };
        },
        [GET_OFFER_FAILURE]: (state, action) => {
            state.offer = {
                ...state.offer,
                errors: action.payload,
                isFetching: false,
            };
        },
        [GET_OFFER_SUCCESS]: (state, action) => {
            state.offer = {
                ...state.offer,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_DATA]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_STORAGE_PLAN]: (state, action) => {
            state.storagePlan = action.payload;
        },
    },
});

export const energyBackupSetupActions = energyBackupSetupSlice.actions;

export default energyBackupSetupSlice.reducer;
