import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, FormControl, FormLabel, Grid } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormSelect,
    RHFDateTimePicker,
} from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as actions from './actions';
import mentionsInputStyle from './components/mentionsInputStyle';
import mentionStyle from './components/mentionStyle';
import { getUsersOptions, getTrackingTypesOptions } from './helpers';
import * as selectors from './selectors';
import validate from './validate';

const Wrapper = styled(Box)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px;
    padding: 16px;
`,
);

const TrackingForm = ({
    contactId,
    CustomFooter,
    fetchTrackingsPurposes,
    fetchUsers,
    initialValues,
    trackingsPurposesToSelect,
    users,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        fetchTrackingsPurposes();
    }, []);

    useEffect(() => {
        if (contactId) fetchUsers(contactId);
    }, [contactId]);

    const remember = watch('remember');

    return (
        <Wrapper>
            <form>
                <ReactHookFormSelect
                    control={control}
                    label={t('Tracking type')}
                    name={'tracking_type'}
                    options={getTrackingTypesOptions()}
                />

                <ReactHookFormSelect
                    control={control}
                    label={t('Objective')}
                    name={'tracking_purpose'}
                    options={trackingsPurposesToSelect}
                />

                <FormControl fullWidth sx={{ height: '100px' }}>
                    <FormLabel sx={{ fontSize: '13px', fontWeight: 'bold' }}>
                        {t('Note')}
                    </FormLabel>
                    <MentionsInput
                        onChange={(e) =>
                            setValue('description', e.target.value)
                        }
                        placeholder={t('Add note')}
                        style={{
                            ...mentionsInputStyle,
                            control: {
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                padding: '8.5px 14px',
                                height: '92px',
                            },
                        }}
                        value={watch('description')}
                    >
                        <Mention
                            data={getUsersOptions(users)}
                            style={mentionStyle}
                            trigger="@"
                        />
                    </MentionsInput>
                </FormControl>

                <ReactHookFormCheck
                    control={control}
                    label={t('Remember task')}
                    name={'remember'}
                />
                {remember && (
                    <Grid container>
                        <Grid item xs={18}>
                            <RHFDateTimePicker
                                control={control}
                                disabled={!remember}
                                fullWidth
                                disablePast
                                label={t('Date')}
                                name="notification_at"
                            />
                        </Grid>
                    </Grid>
                )}

                <CustomFooter control={control} handleSubmit={handleSubmit} />
            </form>
        </Wrapper>
    );
};

TrackingForm.propTypes = {
    contactId: PropTypes.string,
    CustomFooter: PropTypes.func,
    fetchTrackingsPurposes: PropTypes.func,
    fetchUsers: PropTypes.func,
    initialValues: PropTypes.object,
    trackingsPurposesToSelect: PropTypes.array,
    users: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    trackingsPurposes: selectors.getTrackingPurpose,
    trackingsPurposesToSelect: selectors.getTrackingPurposeToSelect,
    users: selectors.getUsersData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTrackingsPurposes: () => dispatch(actions.fetchTrackingsPurposes()),
    fetchUsers: (contactId) => dispatch(actions.fetchUsers(contactId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackingForm);
