import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../actions';
import { PROVIDERS_SETTINGS } from '../constants';
import * as selectors from '../selectors';

import Form from './Form';
import ProvidersList from './ProviderList';

const STEPS = {
    PROVIDERS: 0,
    FORM: 1,
};

const IntegrationModal = ({
    isOpenFormModal,
    isSaving,
    providers,
    setIsOpenFormModal,
}) => {
    const [step, setStep] = useState(STEPS.PROVIDERS);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const { t } = useTranslation();

    const handleCancel = () => {
        setIsOpenFormModal(false);
        setSelectedProvider(null);
        setStep(STEPS.PROVIDERS);
    };
    const onBack = () => {
        setStep(STEPS.PROVIDERS);
    };

    const handleSelectProvider = (provider) => setSelectedProvider(provider);

    const providerName = PROVIDERS_SETTINGS[selectedProvider?.type]?.name;

    return (
        <Dialog
            onClose={handleCancel}
            open={isOpenFormModal}
            size="xs"
            title={`${t('Provider', { count: 2 })} ${
                providerName ? `- ${providerName}` : ''
            }`}
        >
            {step === STEPS.PROVIDERS && (
                <ProvidersList
                    handleSelectProvider={handleSelectProvider}
                    onCancel={handleCancel}
                    onClickNext={() => setStep(STEPS.FORM)}
                    providers={providers}
                    selectedProvider={selectedProvider}
                />
            )}

            {step === STEPS.FORM && (
                <Form
                    isSaving={isSaving}
                    onCancel={onBack}
                    provider={selectedProvider}
                />
            )}
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpenFormModal: selectors.getIsOpenFormModal,
    isSaving: selectors.getIsSavingIntegration,
    providers: selectors.getProviders,
});

const mapDispatchToProps = (dispatch) => ({
    setIsOpenFormModal: (isOpen) =>
        dispatch(actions.setIsOpenFormModal(isOpen)),
});

IntegrationModal.propTypes = {
    isOpenFormModal: PropTypes.bool,
    isSaving: PropTypes.bool,
    providers: PropTypes.array,
    setIsOpenFormModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationModal);
