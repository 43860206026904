import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from 'sunwise-ui';

import icoCertifiedRate from 'resources/icoCertifiedRate.svg';
import icoCustomRate from 'resources/icoCustomRate.svg';

const Title = styled(Box)`
    color: #202253;
    font-size: 15px;
    font-weight: bold;
`;

const ItemContainer = styled(Box)`
    align-items: center;
    display: flex;
    gap: 16px;
    max-width: 230px;
    padding: 8px;
`;

const IconImg = styled('img')`
    height: 27px;
    width: 22px;
`;

const ItemText = styled('span')`
    color: #848bab;
    font-size: 14px;
    line-height: 14px;
`;

const InfoList = ({ certifiedProfilesNumber }) => {
    const { t } = useTranslation();

    return (
        <Box padding="8px">
            <Title>{t('Icon', { count: 2 })}</Title>
            {certifiedProfilesNumber > 0 && (
                <>
                    <Divider />
                    <ItemContainer>
                        <Box>
                            <IconImg
                                src={icoCertifiedRate}
                                title={t('Certified consumption profile')}
                            />
                        </Box>
                        <ItemText>
                            {t('Certified consumption profile')}
                        </ItemText>
                    </ItemContainer>
                </>
            )}
            <Divider />
            <ItemContainer>
                <Box>
                    <IconImg
                        src={icoCustomRate}
                        title={t('Custom consumption profile')}
                    />
                </Box>
                <ItemText>{t('Custom consumption profile')}</ItemText>
            </ItemContainer>
            <Divider />
            <ItemContainer>
                <Box>
                    <HomeIcon />
                </Box>
                <ItemText>{t('Residential installation')}</ItemText>
            </ItemContainer>
            <Divider />
            <ItemContainer>
                <Box>
                    <StorefrontIcon />
                </Box>
                <ItemText>{t('Commercial installation')}</ItemText>
            </ItemContainer>
            <Divider />
            <ItemContainer>
                <Box>
                    <BusinessIcon />
                </Box>
                <ItemText>{t('Industrial installation')}</ItemText>
            </ItemContainer>
        </Box>
    );
};

InfoList.propTypes = {
    certifiedProfilesNumber: PropTypes.number,
};

export default InfoList;
