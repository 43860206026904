import React from 'react';

import { Box, Grid } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const GuaranteedGenerationFields = ({ control, isLocked = false }) => {
    const { t } = useTranslation();
    const guaranteedGenerationActive = useWatch({
        control,
        name: 'guaranteed_gen_active',
    });

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Guaranteed generation')}
                </TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item sm={guaranteedGenerationActive ? 9 : 18} xs={18}>
                    <ReactHookFormCheck
                        control={control}
                        disabled={isLocked}
                        label={t('Active')}
                        name="guaranteed_gen_active"
                        sxFormControl={{ flexShrink: 0 }}
                    />
                </Grid>

                {guaranteedGenerationActive && (
                    <Grid item sm={9} xs={18}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append="%"
                            control={control}
                            disabled={isLocked}
                            fullWidth
                            label={t('Percentage')}
                            max={100}
                            min={0}
                            name="guaranteed_gen"
                            placeholder="90"
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

GuaranteedGenerationFields.propTypes = {
    control: PropTypes.object,
    isLocked: PropTypes.bool,
};

export default GuaranteedGenerationFields;
