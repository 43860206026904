export const NAME = 'plan-configuration';
export const COLUMNS_CONFIG = {
    name: {
        label: 'Plan',
        is_active: true,
        required: true,
    },
    installation_type: {
        label: 'Rate',
        is_active: true,
        required: false,
    },
    branch_office: {
        label: 'Branch office',
        is_active: true,
        required: false,
    },
};
