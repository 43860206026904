import { getTemplates } from 'common/api/v1/templates';

import {
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) =>
    new Promise((resolve) => {
        dispatch(actions[FETCH_TEMPLATES]());

        getTemplates({ orderBy: 'title', perPage: 100, sortBy: 'asc', type: 3 })
            .then((response) => {
                const data = response.data.results.map((template) => ({
                    ...template,
                    id: template.external_uuid,
                }));
                dispatch(
                    actions[FETCH_TEMPLATES_SUCCESS]({
                        aftersales_templates: data,
                    }),
                );
            })
            .catch((error) => dispatch(actions[FETCH_TEMPLATES_FAILURE](error)))
            .finally(resolve);
    });
