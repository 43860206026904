import { addProductToBranches } from 'common/api/v1/panelsCompany';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    ADD_PRODUCT_TO_BRANCHES,
    ADD_PRODUCT_TO_BRANCHES_FAILURE,
    ADD_PRODUCT_TO_BRANCHES_SUCCESS,
} from '../actionTypes';
import { panelConfigurationActions } from '../reducer';

export default (id, branches, successCallback) => (dispatch) => {
    dispatch(panelConfigurationActions[ADD_PRODUCT_TO_BRANCHES]());
    addProductToBranches({ parent_panel: id, branch_offices: branches.join() })
        .then(() => {
            successCallback();
            dispatch(
                panelConfigurationActions[ADD_PRODUCT_TO_BRANCHES_SUCCESS](),
            );
            showToast();
        })
        .catch((error) => {
            dispatch(
                panelConfigurationActions[ADD_PRODUCT_TO_BRANCHES_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
