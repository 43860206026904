import i18next from 'i18next';
import { get, isEqual } from 'lodash';

import alerts from 'common/modules/alerts';
import { arraytoDictionary } from 'common/utils/helpers';

import * as proposalGeneratorPanelsLayoutSelectors from '../../proposalGeneratorPanelsLayout/selectors';
import * as selectors from '../selectors';

import save from './save';

export default (proposalId, values) => (dispatch, getState) => {
    const initialValues = selectors.getInitialValues(getState());
    const initialItems = get(initialValues, 'items', []);
    const initialDictionary = arraytoDictionary(initialItems, 'id');
    const initialPanel = initialDictionary[values.id];
    const haveParentFieldSegment =
        proposalGeneratorPanelsLayoutSelectors.getHaveParentFieldSegment(
            getState(),
        );
    if (haveParentFieldSegment && !isEqual(initialPanel?.item, values?.item)) {
        const dataAlert = {
            confirmText: i18next.t('Accept'),
            messages: [
                i18next.t(
                    'If you modify or delete the PV module you will need to redo the PV layout',
                ),
            ],
            onSuccess: () => dispatch(save(proposalId, values)),
            title: i18next.t('Confirmation'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };
        dispatch(alerts.actions.show(dataAlert));
    } else {
        dispatch(save(proposalId, values));
    }
};
