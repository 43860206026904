import get from 'lodash/get';

import { getItemsByLevel } from 'common/api/v1/politicalDivisions';

import {
    FETCH_POLITICAL_DIVISIONS,
    FETCH_POLITICAL_DIVISIONS_FAILURE,
    FETCH_POLITICAL_DIVISIONS_SUCCESS,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';

import fetchGenerationProfile from './fetchGenerationProfile';
import fetchPoliticalDivisionsByState from './fetchPoliticalDivisionsByState';

export default (str, setValue) => (dispatch) => {
    dispatch(solarGenerationActions[FETCH_POLITICAL_DIVISIONS]());
    getItemsByLevel(str)
        .then(({ data }) => {
            dispatch(
                solarGenerationActions[FETCH_POLITICAL_DIVISIONS_SUCCESS](
                    data.data,
                ),
            );
            setValue('political_division', data.data[0].id);
            dispatch(fetchGenerationProfile(data.data[0].id));
            dispatch(fetchPoliticalDivisionsByState(data.data[0].id, setValue));
        })
        .catch((error) =>
            dispatch(
                solarGenerationActions[FETCH_POLITICAL_DIVISIONS_FAILURE](
                    get(error, 'response.data.errors', []),
                ),
            ),
        );
};
