export const getDailyAverage = ({ days, value }) => {
    if (!value || !days) return 0;
    return value / days;
};

export const getQuantityByPercentage = ({ percentage, total, unitTotal }) => {
    if (!percentage || !total || !unitTotal) return 0;
    return (percentage * total) / unitTotal;
};

export const getPercentageByStorageSize = ({ storageSize, total }) => {
    if (!storageSize || !total) return 0;
    return 100 * (storageSize / total);
};
