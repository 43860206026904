import { createSlice } from '@reduxjs/toolkit';

import {
    INITIAL_VALUES,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_IS_INFO_CONSISTENT,
} from './actionTypes';
import {
    DEFAULT_ALGORITHM,
    DEFAULT_DEGRADATION,
    DEFAULT_EFFICIENCY,
    NAME,
} from './constants';

const INITIAL_STATE = {
    isInfoConsistent: true,
    initialValues: {
        algorithm: DEFAULT_ALGORITHM,
        base: { out_summer: 0, summer: 0 },
        c_rate: null,
        degradation_percentage: DEFAULT_DEGRADATION,
        discharge_power: null,
        discharge_power_placeholder: null,
        dod: null,
        efficiency: DEFAULT_EFFICIENCY,
        id: null,
        load_capacity: null,
        load_capacity_placeholder: null,
        max_peak_demand_year: { out_summer: 0, summer: 0 },
        mid: { out_summer: 0, summer: 0 },
        peak: { out_summer: 0, summer: 0 },
        peak_shaving: [],
    },
    save: { data: [], errors: [], isSaving: false },
};

const advancedTimeShiftSettingsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_IS_INFO_CONSISTENT]: (state, action) => {
            state.isInfoConsistent = action.payload;
        },
    },
});

export const advancedTimeShiftSettingsActions =
    advancedTimeShiftSettingsSlice.actions;

export default advancedTimeShiftSettingsSlice.reducer;
