import React from 'react';

import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'sunwise-ui';

const InteractiveLinkTooltip = ({ isActive, isHidden }) => {
    const { t } = useTranslation();

    if (isHidden) return null;

    return (
        <Tooltip
            placement="top"
            title={
                isActive
                    ? t('Active interactive link')
                    : t('Inactive interactive link')
            }
        >
            {isActive ? (
                <LinkIcon fontSize="medium" />
            ) : (
                <LinkOffIcon fontSize="medium" />
            )}
        </Tooltip>
    );
};

InteractiveLinkTooltip.propTypes = {
    isHidden: PropTypes.bool,
    isActive: PropTypes.bool,
};

export default InteractiveLinkTooltip;
