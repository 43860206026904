import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Drawer, IconButton } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

const WrapperContent = styled(Box)`
    overflow-y: scroll;
    padding: 0 10px;

    ${scroll.custom()}
`;

const TemporaryDrawer = ({
    children,
    defaultAnchor,
    myForwardedRef,
    onClose,
    width,
}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) document.body.classList.remove('overflow-hidden');
        else document.body.classList.remove('overflow-hidden');
    }, [open]);

    useImperativeHandle(myForwardedRef, () => ({
        handleOpen(value) {
            setOpen(value);
        },
        getIsOpen() {
            return open;
        },
    }));

    const toggleDrawer = (value) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        )
            return;
        setOpen(value);
        if (!value && onClose) return onClose();
    };

    return (
        <div>
            <React.Fragment key={defaultAnchor}>
                <Drawer
                    anchor={defaultAnchor}
                    open={open}
                    onClose={toggleDrawer(false)}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        p={1}
                        sx={{
                            height: '100%',
                            width: {
                                md:
                                    defaultAnchor === 'top' ||
                                    defaultAnchor === 'bottom'
                                        ? 'auto'
                                        : width,
                                xs: '100%',
                            },
                        }}
                    >
                        <Box>
                            <IconButton onClick={() => setOpen(false)}>
                                <ChevronRightIcon />
                            </IconButton>
                        </Box>
                        <WrapperContent>{children}</WrapperContent>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
};

TemporaryDrawer.defaultProps = {
    defaultAnchor: 'right',
    onClose: () => {},
    width: 480,
};

TemporaryDrawer.propTypes = {
    children: PropTypes.object,
    defaultAnchor: PropTypes.string,
    myForwardedRef: PropTypes.object,
    onClose: PropTypes.func,
    width: PropTypes.number,
};

const TemporaryDrawerForwared = forwardRef((props, ref) => (
    <TemporaryDrawer {...props} myForwardedRef={ref} />
));

TemporaryDrawerForwared.displayName = 'TemporaryDrawerForwared';

export default TemporaryDrawerForwared;
