import { getComplements } from 'common/api/v1/complementsReporter';

import {
    FETCH_REPORT_COMPLEMENTS,
    FETCH_REPORT_COMPLEMENTS_FAILURE,
    FETCH_REPORT_COMPLEMENTS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (reportId) => (dispatch) => {
    dispatch(actions[FETCH_REPORT_COMPLEMENTS]());

    getComplements(reportId)
        .then((response) =>
            dispatch(
                actions[FETCH_REPORT_COMPLEMENTS_SUCCESS](response?.data?.data),
            ),
        )
        .catch((error) =>
            dispatch(actions[FETCH_REPORT_COMPLEMENTS_FAILURE](error)),
        );
};
