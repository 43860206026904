import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, DataGrid, IconButton, Typography } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { useBreakpoint } from 'common/hooks';

const StyledTitle = styled(Typography)`
    cursor: default;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
`;

const StyledText = styled(Typography)`
    cursor: default;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    overflow-wrap: anywhere;
`;

const ListItems = ({ canModify, handleClickUpdate, isFetching, items }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const ActionsFormatter = (_, row) => (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '4px',
                justifyContent: 'end',
                textAlign: 'right',
                width: '100%',
            }}
        >
            {canModify && (
                <IconButton onClick={() => handleClickUpdate(row)} size="small">
                    <EditIcon fontSize="small" />
                </IconButton>
            )}
        </Box>
    );

    const TitleFormatter = (column) => (
        <StyledTitle component="span" title={column || ''}>
            {column || '--'}
        </StyledTitle>
    );

    const FieldFormatter = (column) => (
        <StyledText component="span" title={column || ''}>
            {column || '--'}
        </StyledText>
    );

    let columns = [
        {
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            renderCell: (params) => {
                const { row } = params;
                return TitleFormatter(row.name, row);
            },
        },
        {
            field: 'business_name',
            flex: 1,
            headerName: t('Business name'),
            renderCell: (params) => {
                const { row } = params;
                return TitleFormatter(row.business_name, row);
            },
        },
        {
            field: 'rfc',
            flex: 1,
            headerName: t('Tax ID'),
            renderCell: (params) => {
                const { row } = params;
                return FieldFormatter(row.rfc, row);
            },
        },

        {
            field: 'web_page',
            flex: 1,
            headerName: t('Web page'),
            renderCell: (params) => {
                const { row } = params;
                return FieldFormatter(row.web_page, row);
            },
        },
        {
            field: 'contact_email',
            flex: 1,
            headerName: t('Email'),
            renderCell: (params) => {
                const { row } = params;
                return FieldFormatter(row.contact_email, row);
            },
        },
    ];

    if (breakpoint === 'xs') {
        columns = [
            {
                field: 'name',
                flex: 1,
                headerName: t('Name'),
                renderCell: (params) => {
                    const { row } = params;
                    return TitleFormatter(row.name, row);
                },
            },
        ];
    }

    columns = [
        ...columns,
        {
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return ActionsFormatter(null, row);
            },
            sortable: false,
        },
    ];

    return (
        <Card>
            <Card.Body>
                <DataGrid
                    autoHeight
                    components={{
                        LoadingOverlay: LinearProgress,
                        Pagination: CustomPagination,
                    }}
                    columns={columns}
                    disableColumnMenu
                    disableSelectionOnClick
                    getRowHeight={() => 'auto'}
                    loading={isFetching}
                    pagination
                    rows={items}
                />
            </Card.Body>
        </Card>
    );
};

ListItems.propTypes = {
    canModify: PropTypes.bool,
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
};

export default ListItems;
