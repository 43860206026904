import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SwitchButtons from 'common/components/SwitchButtons';

import MonkeyNoSee from 'resources/monkey-no-see.svg?react';
import Monkey from 'resources/monkey.svg?react';

import * as projectCommercialOfferTableActions from '../../projectCommercialOfferTable/actions';
import * as actions from '../actions';

const SupportControlButtons = ({
    canModify,
    customerMode,
    financialProductParameters,
    handleClickDuplicate,
    handleClickEdit,
    handleClickOpenConfigurationModal,
    hasSmartDocuments,
    isApproved,
    isClosed,
    isGenerated,
    offerDetails,
    offerId,
    setCustomerMode,
}) => {
    const { t } = useTranslation();
    const [count, setCount] = useState(0);
    const financierProduct = get(
        financialProductParameters,
        'financier_product',
        {},
    );
    const hasFinancingRequest = get(
        offerDetails,
        'has_financing_request',
        false,
    );

    const handleClickEditButton = () =>
        handleClickEdit(
            financierProduct,
            hasFinancingRequest,
            hasSmartDocuments,
            isApproved,
            isGenerated,
            offerId,
        );

    const handleClickCustomerMode = () => setCustomerMode(!customerMode);

    const handleClickDuplicateButton = () =>
        handleClickDuplicate(
            get(offerDetails, 'name', null),
            get(offerDetails, 'commercial_offer_parent', null),
        );

    const countClick = () => setCount((count) => count + 1);

    return (
        <SwitchButtons
            items={[
                {
                    handleClick: () => handleClickEditButton(),
                    icon: <EditIcon fontSize="small" />,
                    isDisabled: isClosed || !canModify,
                    isVisible: isGenerated,
                    nameId: 'edit',
                    tooltipLabel: t('Edit'),
                },
                {
                    handleClick: () => {
                        handleClickCustomerMode();
                        countClick();
                    },
                    icon: customerMode ? (
                        count > 10 ? (
                            <MonkeyNoSee width="24" height="24" />
                        ) : (
                            <VisibilityOffIcon fontSize="small" />
                        )
                    ) : count > 10 ? (
                        <Monkey width="24" height="24" />
                    ) : (
                        <VisibilityIcon />
                    ),
                    nameId: 'customer_mode',
                    tooltipLabel: t('Customer mode'),
                },
                {
                    handleClick: () => handleClickDuplicateButton(),
                    icon: <FileCopyIcon fontSize="small" />,
                    isDisabled: !canModify,
                    nameId: 'duplicate',
                    tooltipLabel: t('Duplicate'),
                    isVisible: !isClosed,
                },
                {
                    handleClick: () => handleClickOpenConfigurationModal(true),
                    icon: <SettingsIcon fontSize="small" />,
                    nameId: 'advanced_settings',
                    tooltipLabel: t('Setting'),
                },
            ]}
            sxBox={{ mb: 2 }}
        />
    );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
    handleClickDuplicate: (proposalName, proposalId) =>
        dispatch(
            projectCommercialOfferTableActions.prepareCommercialOfferDuplicate(
                proposalName,
                proposalId,
            ),
        ),
    handleClickEdit: (
        financierProduct,
        hasFinancingRequest,
        hasSmartDocuments,
        isApproved,
        isGenerated,
        offerId,
    ) =>
        dispatch(
            actions.prepareEdit(
                financierProduct,
                hasFinancingRequest,
                hasSmartDocuments,
                isApproved,
                isGenerated,
                offerId,
            ),
        ),
});

SupportControlButtons.propTypes = {
    canModify: PropTypes.bool,
    customerMode: PropTypes.bool,
    financialProductParameters: PropTypes.object,
    handleClickDuplicate: PropTypes.func,
    handleClickEdit: PropTypes.func,
    handleClickOpenConfigurationModal: PropTypes.func,
    hasSmartDocuments: PropTypes.bool,
    isApproved: PropTypes.bool,
    isClosed: PropTypes.bool,
    isGenerated: PropTypes.bool,
    offerDetails: PropTypes.object,
    offerId: PropTypes.string,
    setCustomerMode: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SupportControlButtons);
