import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UiDialog as Dialog } from 'sunwise-ui';

import Form from './../components/Form';

const Modal = ({
    errors,
    handleClickClose,
    handleSubmit,
    initialValues,
    isUploading,
    phoneCodes,
    show,
}) => {
    const { t } = useTranslation();
    return (
        <Dialog
            onClose={handleClickClose}
            open={show}
            title={t('Support contact')}
            size="md"
        >
            <Form
                errors={errors}
                handleClickCancel={handleClickClose}
                initialValues={initialValues}
                isUploading={isUploading}
                onSubmit={handleSubmit}
                phoneCodes={phoneCodes}
            />
        </Dialog>
    );
};

Modal.propTypes = {
    errors: PropTypes.array,
    handleClickClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isUploading: PropTypes.bool,
    phoneCodes: PropTypes.object,
    show: PropTypes.bool,
};

export default Modal;
