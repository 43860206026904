import { ONE_TO_MANY } from '../constants';

import BaseModel from './BaseModel';
export default class LinkedProposal extends BaseModel {}

LinkedProposal.modelName = 'LinkedProposal';
LinkedProposal.prepareFields({
    dataSheetsLinked: 'data_sheets_linked',
    id: 'id',
    proposal: 'proposal',
    proposalContent: 'proposal_content',
    proposalName: 'proposal_name',
    proposalPdfPrinted: 'proposal_pdf_printed',
    smartDocumentsLinked: 'smart_documents_linked',
    interactiveProjectId: {
        key: 'interactive_project_id',
        relationType: ONE_TO_MANY,
        options: {
            to: 'InteractiveProject',
            as: 'interactiveProject',
            relatedName: 'linkedProposals',
        },
    },
});
