import { NAME } from './constants';

export const FETCH_CONSUMPTION_ENERGY = `${NAME}/FETCH_CONSUMPTION_ENERGY`;
export const FETCH_CONSUMPTION_ENERGY_SUCCESS = `${NAME}/FETCH_CONSUMPTION_ENERGY_SUCCESS`;
export const FETCH_CONSUMPTION_ENERGY_FAILURE = `${NAME}/FETCH_CONSUMPTION_ENERGY_FAILURE`;

export const FETCH_LOCATION = `${NAME}/FETCH_LOCATION`;
export const FETCH_LOCATION_ERROR = `${NAME}/FETCH_LOCATION_ERROR`;
export const FETCH_LOCATION_SUCCESS = `${NAME}/FETCH_LOCATION_SUCCESS`;

export const FETCH_PROPOSALS = `${NAME}/FETCH_PROPOSALS`;
export const FETCH_PROPOSALS_FAILURE = `${NAME}/FETCH_PROPOSALS_FAILURE`;
export const FETCH_PROPOSALS_SUCCESS = `${NAME}/FETCH_PROPOSALS_SUCCESS`;

export const FETCH_REPORTS = `${NAME}/FETCH_REPORTS`;
export const FETCH_REPORTS_FAILURE = `${NAME}/FETCH_REPORTS_FAILURE`;
export const FETCH_REPORTS_SUCCESS = `${NAME}/FETCH_REPORTS_SUCCESS`;

export const FETCH_SCHEDULE_RATE_CONFIGURATION = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION`;
export const FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE`;
export const FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS`;

export const FETCH_SUMMARIES = `${NAME}/FETCH_SUMMARIES`;
export const FETCH_SUMMARIES_FAILURE = `${NAME}/FETCH_SUMMARIES_FAILURE`;
export const FETCH_SUMMARIES_SUCCESS = `${NAME}/FETCH_SUMMARIES_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const SET_IS_INITIALIZING = `${NAME}/SET_IS_INITIALIZING`;

export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;
