import { createSlice } from '@reduxjs/toolkit';

import orm from 'common/orm';

import {
    FETCH_CONSUMPTION_PROFILE,
    FETCH_CONSUMPTION_PROFILE_FAILURE,
    FETCH_CONSUMPTION_PROFILE_SUCCESS,
    FETCH_CONSUMPTION_PROFILES,
    FETCH_CONSUMPTION_PROFILES_FAILURE,
    FETCH_CONSUMPTION_PROFILES_SUCCESS,
    FETCH_CUPS_INFO,
    FETCH_CUPS_INFO_FAILURE,
    FETCH_CUPS_INFO_SUCCESS,
    FETCH_DIVISIONS,
    FETCH_DIVISIONS_FAILURE,
    FETCH_DIVISIONS_SUCCESS,
    FETCH_LISA_FILES,
    FETCH_LISA_FILES_FAILURE,
    FETCH_LISA_FILES_SUCCESS,
    FETCH_LOCATIONS_ZIP_CODE,
    FETCH_LOCATIONS_ZIP_CODE_FAILURE,
    FETCH_LOCATIONS_ZIP_CODE_SUCCESS,
    FETCH_POLITICAL_DIVISIONS,
    FETCH_POLITICAL_DIVISIONS_FAILURE,
    FETCH_POLITICAL_DIVISIONS_SUCCESS,
    FETCH_POLITICAL_DIVISIONS2,
    FETCH_POLITICAL_DIVISIONS2_FAILURE,
    FETCH_POLITICAL_DIVISIONS2_SUCCESS,
    FETCH_PROFILE_CONSUMPTION_SUCCESS,
    FETCH_RATES,
    FETCH_RATES_FAILURE,
    FETCH_RATES_SUCCESS,
    FETCH_REGIONS,
    FETCH_REGIONS_FAILURE,
    FETCH_REGIONS_SUCCESS,
    FETCH_SCHEDULE_RATE_CONFIGURATION,
    FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE,
    FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS,
    FETCH_SUMMER_MONTHS,
    FETCH_SUMMER_MONTHS_FAILURE,
    FETCH_SUMMER_MONTHS_SUCCESS,
    INITIALIZE_VALUES,
    RESET_FORM,
    RESET_PDF_FILE,
    SAVE_CONSUMPTIONS,
    SAVE_CONSUMPTIONS_FAILURE,
    SAVE_CONSUMPTIONS_SUCCESS,
    SELECT_PROJECT,
    SET_AUTOMATIC_HAS_CHANGES,
    SET_CONFIG_CSV_IMPORT_MODAL,
    SET_CONSUMPTIONS_CAPTURE_MODE,
    SET_CONSUMPTIONS_RAW_DATA,
    SET_CONSUMPTION_PROFILE_CSV_DATA,
    SET_CONSUMPTION_WITH_CSV_DATA_CALCULATED,
    SET_HAS_MORE_DAYS_THAN_ALLOWED,
    SET_IS_DISABLED,
    SET_IS_FROM_CREATE,
    SET_IS_OPEN_MISSING_FIELDS_MODAL,
    SET_MODAL_IS_OPEN,
    SET_UPLOAD_FILE_ORIGIN,
    UPLOAD_FILE,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS,
    FETCH_ZAP_DATA,
    FETCH_ZAP_DATA_FAILURE,
    FETCH_ZAP_DATA_SUCCESS,
    ADD_ZAP_RPU,
    ADD_ZAP_RPU_FAILURE,
    ADD_ZAP_RPU_SUCCESS,
    SET_ZAP_LOADING,
    FETCH_ZAP_RPUS,
    FETCH_ZAP_RPUS_FAILURE,
    FETCH_ZAP_RPUS_SUCCESS,
} from './actionTypes';
import {
    CONSUMPTIONS_CAPTURE_MODE,
    DEFAULT_DISTRIBUTION,
    NAME,
} from './constants';
import { formatDateDefault, getPeriodicityType } from './helpers';

const INITIAL_STATE = {
    addZapRpu: { data: null, errors: null, isLoading: false },
    automaticHasChanges: false,
    consumptionProfileCsv: {},
    consumptionWithCsvDataCalculated: false,
    consumptionsCaptureMode: CONSUMPTIONS_CAPTURE_MODE.MANUAL,
    consumptionsRawData: null,
    csvImportModal: { file: null, isOpen: false },
    divisions: { data: null, errors: [], isFetching: false },
    entities: orm.getEmptyState(),
    fetchConsumptionProfile: {
        data: null,
        errors: null,
        id: null,
        isFetching: false,
    },
    fetchConsumptionProfiles: { errors: null, id: null, isFetching: false },
    fetchCupsInfo: { data: null, errors: null, isFetching: false },
    fetchLisaFiles: { data: [], errors: null, isFetching: false },
    fetchRates: { errors: null, isFetching: false },
    fetchScheduleRateConfiguration: {
        data: {},
        errors: [],
        isFetching: false,
    },
    fetchZapData: { data: null, errors: null, isFetching: false },
    fetchZapRpus: { data: null, errors: null, isFetching: false },
    hasMoreDaysThanAllowed: false,
    initialValues: {
        address: '',
        cnmc_data_modified: false,
        contracted_demand: '',
        csv_data_modified: false,
        distribution: DEFAULT_DISTRIBUTION,
        file: '',
        file_to_download: null,
        holder: '',
        hourly_contracted_demand: null,
        id: null,
        is_bimonthly: '0',
        last_consumption: formatDateDefault(new Date()),
        periodicity_type: '',
        political_division1: null,
        political_division2: null,
        previous_consumption: '',
        rare_month: null,
        rate: null,
        rate_division: null,
        rate_division_summer: null,
        receipt_origin: 'manual',
        service_number: '',
        spain_meter_type: 1,
        subsidy_rate: null,
        summary: [],
        terms_consumption: false,
        threads_number: null,
        without_consumption: false,
        zip_code: null,
    },
    isDisabled: false,
    isFromCreate: false,
    isZapLoading: false,
    isOpenMissingFieldsModal: false,
    locationsByZipCode: { data: [], errors: null, isFetching: false },
    modalIsOpen: false,
    politicalDivisions2: { data: [], errors: null, isFetching: false },
    politicalDivisions: { data: [], errors: null, isFetching: false },
    profileConsumption: { data: null, errors: null, isFetching: false },
    regions: { data: null, errors: [], isFetching: false },
    saveConsumptions: { data: null, errors: [], isSaving: false },
    selectedProject: {},
    summerMonths: { data: null, errors: [], isFetching: false },
    uploadFileOrigin: null,
    uploadPDFFile: { data: null, errors: null, isUploading: false },
};

const projectConsumptionModalSlice = createSlice({
    initialState: INITIAL_STATE,
    name: NAME,
    reducers: {
        [ADD_ZAP_RPU]: (state) => {
            state.addZapRpu.data = null;
            state.addZapRpu.errors = null;
            state.addZapRpu.isLoading = true;
        },
        [ADD_ZAP_RPU_FAILURE]: (state, action) => {
            state.addZapRpu.errors = action.payload;
            state.addZapRpu.isLoading = false;
        },
        [ADD_ZAP_RPU_SUCCESS]: (state, action) => {
            state.addZapRpu.data = action.payload;
            state.addZapRpu.isLoading = false;
        },
        [FETCH_CONSUMPTION_PROFILE]: (state) => {
            state.fetchConsumptionProfile.errors = null;
            state.fetchConsumptionProfile.isFetching = true;
        },
        [FETCH_CONSUMPTION_PROFILE_FAILURE]: (state, action) => {
            state.fetchConsumptionProfile.data = null;
            state.fetchConsumptionProfile.errors = action.payload;
            state.fetchConsumptionProfile.isFetching = false;
        },
        [FETCH_CONSUMPTION_PROFILE_SUCCESS]: (state, action) => {
            state.fetchConsumptionProfile.data = action.payload;
            state.fetchConsumptionProfile.isFetching = false;
        },
        [FETCH_CONSUMPTION_PROFILES]: (state) => {
            state.fetchConsumptionProfiles.errors = null;
            state.fetchConsumptionProfiles.isFetching = true;
        },
        [FETCH_CONSUMPTION_PROFILES_FAILURE]: (state, action) => {
            state.fetchConsumptionProfiles.errors = action.payload;
            state.fetchConsumptionProfiles.isFetching = false;
        },
        [FETCH_CONSUMPTION_PROFILES_SUCCESS]: (state, action) => {
            state.fetchConsumptionProfiles.data = action.payload;
            state.fetchConsumptionProfiles.isFetching = false;
        },
        [FETCH_CUPS_INFO]: (state) => {
            state.fetchCupsInfo.data = null;
            state.fetchCupsInfo.errors = null;
            state.fetchCupsInfo.isFetching = true;
        },
        [FETCH_CUPS_INFO_FAILURE]: (state, action) => {
            state.fetchCupsInfo.errors = action.payload;
            state.fetchCupsInfo.isFetching = false;
        },
        [FETCH_CUPS_INFO_SUCCESS]: (state, action) => {
            state.fetchCupsInfo.data = action.payload;
            state.fetchCupsInfo.isFetching = false;
        },
        [FETCH_DIVISIONS]: (state) => {
            state.divisions.errors = [];
            state.divisions.isFetching = true;
        },
        [FETCH_DIVISIONS_FAILURE]: (state, action) => {
            state.divisions.errors = action.payload;
            state.divisions.isFetching = false;
        },
        [FETCH_DIVISIONS_SUCCESS]: (state, action) => {
            state.divisions.data = action.payload;
            state.divisions.isFetching = false;
        },
        [FETCH_LISA_FILES]: (state) => {
            state.fetchLisaFiles.errors = [];
            state.fetchLisaFiles.isFetching = true;
        },
        [FETCH_LISA_FILES_FAILURE]: (state, action) => {
            state.fetchLisaFiles.errors = action.payload;
            state.fetchLisaFiles.isFetching = false;
        },
        [FETCH_LISA_FILES_SUCCESS]: (state, action) => {
            state.fetchLisaFiles.data = action.payload;
            state.fetchLisaFiles.isFetching = false;
        },
        [FETCH_RATES]: (state) => {
            state.fetchRates.errors = [];
            state.fetchRates.isFetching = true;
        },
        [FETCH_RATES_FAILURE]: (state, action) => {
            state.fetchRates.errors = action.payload;
            state.fetchRates.isFetching = false;
        },
        [FETCH_RATES_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Rate } = session;

            Rate.all().delete();

            for (const element of action.payload)
                Rate.generate({
                    ...element,
                    periodicity_type: getPeriodicityType(
                        element.periodicity_type,
                    ),
                });

            state.fetchRates.isFetching = false;
            state.entities = session.state;
        },
        [FETCH_REGIONS]: (state) => {
            state.regions.errors = [];
            state.regions.isFetching = true;
        },
        [FETCH_REGIONS_FAILURE]: (state, action) => {
            state.regions.errors = action.payload;
            state.regions.isFetching = false;
        },
        [FETCH_REGIONS_SUCCESS]: (state, action) => {
            state.regions.data = action.payload;
            state.regions.isFetching = false;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION]: (state) => {
            state.fetchScheduleRateConfiguration.errors = [];
            state.fetchScheduleRateConfiguration.isFetching = true;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE]: (state, action) => {
            state.fetchScheduleRateConfiguration.data = {};
            state.fetchScheduleRateConfiguration.errors = action.payload;
            state.fetchScheduleRateConfiguration.isFetching = false;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS]: (state, action) => {
            state.fetchScheduleRateConfiguration.data = action.payload;
            state.fetchScheduleRateConfiguration.isFetching = false;
        },
        [FETCH_SUMMER_MONTHS]: (state) => {
            state.summerMonths.errors = [];
            state.summerMonths.isFetching = true;
        },
        [FETCH_SUMMER_MONTHS_FAILURE]: (state, action) => {
            state.summerMonths.errors = action.payload;
            state.summerMonths.isFetching = false;
        },
        [FETCH_SUMMER_MONTHS_SUCCESS]: (state, action) => {
            state.summerMonths.data = action.payload;
            state.summerMonths.isFetching = false;
        },
        [FETCH_LOCATIONS_ZIP_CODE]: (state) => {
            state.locationsByZipCode.errors = [];
            state.locationsByZipCode.isFetching = true;
        },
        [FETCH_LOCATIONS_ZIP_CODE_FAILURE]: (state, action) => {
            state.locationsByZipCode.errors = action.payload;
            state.locationsByZipCode.isFetching = false;
        },
        [FETCH_LOCATIONS_ZIP_CODE_SUCCESS]: (state, action) => {
            state.locationsByZipCode.data = action.payload;
            state.locationsByZipCode.isFetching = false;
        },
        [FETCH_POLITICAL_DIVISIONS]: (state) => {
            state.politicalDivisions.errors = [];
            state.politicalDivisions.isFetching = true;
        },
        [FETCH_POLITICAL_DIVISIONS_FAILURE]: (state, action) => {
            state.politicalDivisions.errors = action.payload;
            state.politicalDivisions.isFetching = false;
        },
        [FETCH_POLITICAL_DIVISIONS_SUCCESS]: (state, action) => {
            state.politicalDivisions.data = action.payload;
            state.politicalDivisions.isFetching = false;
        },
        [FETCH_POLITICAL_DIVISIONS2]: (state) => {
            state.politicalDivisions2.errors = [];
            state.politicalDivisions2.isFetching = true;
        },
        [FETCH_POLITICAL_DIVISIONS2_FAILURE]: (state, action) => {
            state.politicalDivisions2.errors = action.payload;
            state.politicalDivisions2.isFetching = false;
        },
        [FETCH_POLITICAL_DIVISIONS2_SUCCESS]: (state, action) => {
            state.politicalDivisions2.data = action.payload;
            state.politicalDivisions2.isFetching = false;
        },
        [FETCH_PROFILE_CONSUMPTION_SUCCESS]: (state, action) => {
            state.profileConsumption.data = action.payload;
            state.profileConsumption.isFetching = false;
        },
        [FETCH_ZAP_DATA]: (state) => {
            state.fetchZapData.data = null;
            state.fetchZapData.errors = null;
            state.fetchZapData.isFetching = true;
        },
        [FETCH_ZAP_DATA_FAILURE]: (state, action) => {
            state.fetchZapData.errors = action.payload;
            state.fetchZapData.isFetching = false;
        },
        [FETCH_ZAP_DATA_SUCCESS]: (state, action) => {
            state.fetchZapData.data = action.payload;
            state.fetchZapData.isFetching = false;
        },
        [FETCH_ZAP_RPUS]: (state) => {
            state.fetchZapRpus.data = null;
            state.fetchZapRpus.errors = null;
            state.fetchZapRpus.isFetching = true;
        },
        [FETCH_ZAP_RPUS_FAILURE]: (state, action) => {
            state.fetchZapRpus.errors = action.payload;
            state.fetchZapRpus.isFetching = false;
        },
        [FETCH_ZAP_RPUS_SUCCESS]: (state, action) => {
            state.fetchZapRpus.data = action.payload;
            state.fetchZapRpus.isFetching = false;
        },
        [INITIALIZE_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.addZapRpu = INITIAL_STATE.addZapRpu;
            state.consumptionWithCsvDataCalculated =
                INITIAL_STATE.consumptionWithCsvDataCalculated;
            state.consumptionsCaptureMode =
                INITIAL_STATE.consumptionsCaptureMode;
            state.consumptionsRawData = INITIAL_STATE.consumptionsRawData;
            state.csvImportModal = INITIAL_STATE.csvImportModal;
            state.fetchConsumptionProfile =
                INITIAL_STATE.fetchConsumptionProfile;
            state.fetchCupsInfo = INITIAL_STATE.fetchCupsInfo;
            state.fetchLisaFiles = INITIAL_STATE.fetchLisaFiles;
            state.fetchScheduleRateConfiguration =
                INITIAL_STATE.fetchScheduleRateConfiguration;
            state.fetchZapData = INITIAL_STATE.fetchZapData;
            state.fetchZapRpus = INITIAL_STATE.fetchZapRpus;
            state.hasMoreDaysThanAllowed = INITIAL_STATE.hasMoreDaysThanAllowed;
            state.initialValues = INITIAL_STATE.initialValues;
            state.isDisabled = INITIAL_STATE.isDisabled;
            state.isFromCreate = INITIAL_STATE.isFromCreate;
            state.isZapLoading = INITIAL_STATE.isZapLoading;
            state.locationsByZipCode = INITIAL_STATE.locationsByZipCode;
            state.politicalDivisions2 = INITIAL_STATE.politicalDivisions2;
            state.saveConsumptions = INITIAL_STATE.saveConsumptions;
            state.selectedProject = INITIAL_STATE.selectedProject;
            state.uploadFileOrigin = INITIAL_STATE.uploadFileOrigin;
            state.uploadPDFFile = INITIAL_STATE.uploadPDFFile;
        },
        [RESET_PDF_FILE]: (state) => {
            state.uploadPDFFile = INITIAL_STATE.uploadPDFFile;
        },
        [SAVE_CONSUMPTIONS]: (state) => {
            state.saveConsumptions.errors = [];
            state.saveConsumptions.isSaving = true;
        },
        [SAVE_CONSUMPTIONS_FAILURE]: (state, action) => {
            state.saveConsumptions.errors = action.payload;
            state.saveConsumptions.isSaving = false;
        },
        [SAVE_CONSUMPTIONS_SUCCESS]: (state, action) => {
            state.saveConsumptions.data = action.payload;
            state.saveConsumptions.isSaving = false;
        },
        [SELECT_PROJECT]: (state, action) => {
            state.selectedProject = action.payload;
        },
        [SET_AUTOMATIC_HAS_CHANGES]: (state, action) => {
            state.automaticHasChanges = action.payload;
        },
        [SET_CONSUMPTION_PROFILE_CSV_DATA]: (state, action) => {
            state.consumptionProfileCsv = action.payload;
        },
        [SET_CONSUMPTION_WITH_CSV_DATA_CALCULATED]: (state, action) => {
            state.consumptionWithCsvDataCalculated = action.payload;
        },
        [SET_CONSUMPTIONS_CAPTURE_MODE]: (state, action) => {
            state.consumptionsCaptureMode = action.payload;
        },
        [SET_CONSUMPTIONS_RAW_DATA]: (state, action) => {
            state.consumptionsRawData = action.payload;
        },
        [SET_HAS_MORE_DAYS_THAN_ALLOWED]: (state, action) => {
            state.hasMoreDaysThanAllowed = action.payload;
        },
        [SET_MODAL_IS_OPEN]: (state, action) => {
            state.modalIsOpen = action.payload;
        },
        [SET_IS_DISABLED]: (state, action) => {
            state.isDisabled = action.payload;
        },
        [SET_IS_FROM_CREATE]: (state, action) => {
            state.isFromCreate = action.payload;
        },
        [SET_CONFIG_CSV_IMPORT_MODAL]: (state, action) => {
            state.csvImportModal.file = action.payload.file || null;
            state.csvImportModal.isOpen = action.payload.isOpen || false;
        },
        [SET_IS_OPEN_MISSING_FIELDS_MODAL]: (state, action) => {
            state.isOpenMissingFieldsModal = action.payload;
        },
        [SET_UPLOAD_FILE_ORIGIN]: (state, action) => {
            state.uploadFileOrigin = action.payload;
        },
        [SET_ZAP_LOADING]: (state, action) => {
            state.isZapLoading = action.payload;
        },
        [UPLOAD_FILE]: (state) => {
            state.uploadPDFFile.errors = null;
            state.uploadPDFFile.isUploading = true;
        },
        [UPLOAD_FILE_FAILURE]: (state, action) => {
            state.uploadPDFFile.errors = action.payload;
            state.uploadPDFFile.isUploading = false;
        },
        [UPLOAD_FILE_SUCCESS]: (state, action) => {
            state.uploadPDFFile.data = action.payload;
            state.uploadPDFFile.isUploading = false;
        },
    },
});

export const projectConsumptionModalActions =
    projectConsumptionModalSlice.actions;

export default projectConsumptionModalSlice.reducer;
