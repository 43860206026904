import { get, isEmpty } from 'lodash';

import { getTableSettings } from 'common/api/v1/core';

import {
    FETCH_NOTIFICATIONS_SETTINGS,
    FETCH_NOTIFICATIONS_SETTINGS_FAILURE,
    FETCH_NOTIFICATIONS_SETTINGS_SUCCESS,
} from '../actionTypes';
import { notificationsSettingsActions } from '../reducer';

import initializeForm from './initializeForm';

export default () => (dispatch) => {
    dispatch(notificationsSettingsActions[FETCH_NOTIFICATIONS_SETTINGS]());

    getTableSettings('notifications')
        .then((response) => {
            const data = get(response, 'data', {});
            const settings = get(data, 'settings', []);
            if (!isEmpty(settings)) dispatch(initializeForm({ settings }));
            dispatch(
                notificationsSettingsActions[
                    FETCH_NOTIFICATIONS_SETTINGS_SUCCESS
                ](),
            );
        })
        .catch((error) =>
            dispatch(
                notificationsSettingsActions[
                    FETCH_NOTIFICATIONS_SETTINGS_FAILURE
                ](error?.response?.data?.errors),
            ),
        );
};
