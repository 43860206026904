import React from 'react';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import EditIcon from '@mui/icons-material/Edit';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DropdownCardMenu } from 'common/components/cards';

const ProjectActionsDropdown = ({
    canDelete,
    canModify,
    handleClickArchive,
    handleClickRename,
    isArchived,
}) => {
    const { t } = useTranslation();

    return (
        <DropdownCardMenu
            items={[
                {
                    handleClick: handleClickRename,
                    icon: <EditIcon fontSize="small" />,
                    label: t('Rename'),
                    visible: canModify,
                },
                {
                    handleClick: () => handleClickArchive('archived'),
                    icon: <ArchiveOutlinedIcon fontSize="small" />,
                    label: t('Archive'),
                    visible: canDelete && !isArchived,
                },
                {
                    handleClick: () => handleClickArchive('active'),
                    icon: <UnarchiveOutlinedIcon fontSize="small" />,
                    label: t('Unarchive'),
                    visible: canDelete && isArchived,
                },
            ]}
            visible={canDelete || canModify}
        />
    );
};

ProjectActionsDropdown.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleClickArchive: PropTypes.func,
    handleClickRename: PropTypes.func,
    isArchived: PropTypes.bool,
};

export default ProjectActionsDropdown;
