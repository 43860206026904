import * as React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import ApprovedStatus from 'resources/approvedStatus.svg?react';
import CreditGrantedStatus from 'resources/creditGrantedStatus.svg?react';
import IncompleteStatus from 'resources/incompleteStatus.svg?react';
import InprocessStatus from 'resources/inprocessStatus.svg?react';
import PrefeasibilityStatus from 'resources/prefeasibilityStatus.svg?react';
import RequestingStatus from 'resources/requestingStatus.svg?react';

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#fff',
    zIndex: 1,
    border: '1px solid #d3d7eb',
    boxShadow: 'inset 0 2px 3px 0 rgba(0, 0, 0, 0.02)',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    'svg g': {
        fill: '#d3d7eb',
    },
    ...(ownerState.active && {
        backgroundColor: '#2bf2ab',
        'svg g': {
            fill: '#202253',
        },
    }),
    ...(ownerState.completed && {
        'svg g': {
            fill: '#2bf2ab',
        },
    }),
}));

const getStepIcons = (isAlliance) => {
    if (isAlliance) {
        return {
            1: <RequestingStatus />,
            2: <IncompleteStatus />,
            3: <InprocessStatus />,
            4: <PrefeasibilityStatus />,
            5: <ApprovedStatus />,
        };
    }
    return {
        1: <RequestingStatus />,
        2: <IncompleteStatus />,
        3: <InprocessStatus />,
        4: <PrefeasibilityStatus />,
        5: <ApprovedStatus />,
        6: <CreditGrantedStatus />,
    };
};

const StepperTimelineIcon = (props) => {
    const { active, completed, className, isAlliance } = props;

    const icons = getStepIcons(isAlliance);

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
};

StepperTimelineIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
    isAlliance: PropTypes.bool,
};

export default StepperTimelineIcon;
