import i18next from 'i18next';

import { createItemV2, updateItemV2 } from 'common/api/v2/customRates';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_RATE,
    SAVE_RATE_FAILURE,
    SAVE_RATE_SUCCESS,
} from '../actionTypes';
import { getJsonValues, getValuesWithCompensationScheme } from '../helpers';
import { ratesActions } from '../reducer';
import * as selectors from '../selectors';

import filterRates from './filterRates';
import initializeRateDetailForm from './initializeRateDetailForm';
import setModalIsOpen from './setModalIsOpen';

export default (isNewRate, values) => (dispatch, getState) => {
    const jsonValues = getJsonValues(values);

    if (isNewRate) {
        dispatch(ratesActions[SAVE_RATE]());
        createItemV2(jsonValues)
            .then(() => {
                const filterQuery = selectors.getFilterQuery(getState());
                dispatch(filterRates(filterQuery));
                dispatch(setModalIsOpen(false));
                showToast({ body: i18next.t('Changes were saved') });
                dispatch(ratesActions[SAVE_RATE_SUCCESS]());
            })
            .catch((error) => {
                dispatch(
                    ratesActions[SAVE_RATE_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    } else {
        dispatch(
            alerts.actions.show({
                cancelText: i18next.t('Cancel'),
                confirmText: i18next.t('Save'),
                messages: [
                    i18next.t(
                        'Do you wish to save changes? It will affect the proposals you are making with this rate',
                    ),
                ],
                onSuccess: () => {
                    dispatch(ratesActions[SAVE_RATE]());
                    updateItemV2(values.id, jsonValues)
                        .then((response) => {
                            const newData = getValuesWithCompensationScheme(
                                response?.data?.data,
                            );

                            dispatch(initializeRateDetailForm(newData));
                            dispatch(setModalIsOpen(false));
                            showToast({
                                body: i18next.t('Changes were saved'),
                            });
                            dispatch(ratesActions[SAVE_RATE_SUCCESS](newData));
                        })
                        .catch((error) => {
                            dispatch(
                                ratesActions[SAVE_RATE_FAILURE](
                                    error?.response?.data?.errors,
                                ),
                            );
                            showReponseErrorsAsAlert(dispatch, error?.response);
                        });
                },
                title: i18next.t('Confirmation'),
                type: alerts.ALERT_TYPE_CONFIRM,
                variant: 'warning',
            }),
        );
    }
};
