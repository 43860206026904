import { getItems } from 'common/api/v1/companyTaxes';

import {
    FETCH_TAXES,
    FETCH_TAXES_FAILURE,
    FETCH_TAXES_SUCCESS,
} from '../actionTypes';
import { taxesConfigurationActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(taxesConfigurationActions[FETCH_TAXES]());

    getItems()
        .then((response) => {
            dispatch(
                taxesConfigurationActions[FETCH_TAXES_SUCCESS](
                    response.data.data,
                ),
            );
        })
        .catch((error) =>
            dispatch(taxesConfigurationActions[FETCH_TAXES_FAILURE](error)),
        );
};
