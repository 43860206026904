import { getTemplates } from 'common/api/v1/templates';

import {
    FETCH_CUSTOM_BASE_TEMPLATES,
    FETCH_CUSTOM_BASE_TEMPLATES_FAILURE,
    FETCH_CUSTOM_BASE_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

export default ({
        language,
        orderBy,
        page = 0,
        pageSize,
        proposalsNumber = 1,
        searchText,
        sortBy,
        status,
        type,
    } = {}) =>
    (dispatch) => {
        dispatch(templateLibraryActions[FETCH_CUSTOM_BASE_TEMPLATES]());

        getTemplates({
            language,
            orderBy,
            page: page + 1,
            perPage: pageSize,
            proposalsNumber,
            searchText,
            sortBy,
            status,
            type,
        })
            .then((response) => {
                dispatch(
                    templateLibraryActions[FETCH_CUSTOM_BASE_TEMPLATES_SUCCESS](
                        {
                            data: response?.data?.results,
                            pagination: {
                                current_page: response?.data?.page,
                                per_page: response?.data?.page_size,
                                total_rows: response?.data?.total_documents,
                            },
                        },
                    ),
                );
            })
            .catch((error) => {
                dispatch(
                    templateLibraryActions[FETCH_CUSTOM_BASE_TEMPLATES_FAILURE](
                        error,
                    ),
                );
            });
    };
