import { createSlice } from '@reduxjs/toolkit';

import orm, { modelPopulateByArray } from 'common/orm';

import {
    ARCHIVE_CONSUMPTION_PROFILE,
    ARCHIVE_CONSUMPTION_PROFILE_FAILURE,
    ARCHIVE_CONSUMPTION_PROFILE_SUCCESS,
    DELETE_CONSUMPTION_PROFILE,
    DELETE_CONSUMPTION_PROFILE_FAILURE,
    DELETE_CONSUMPTION_PROFILE_SUCCESS,
    FETCH_CONSUMPTION_PROFILE,
    FETCH_CONSUMPTION_PROFILES,
    FETCH_CONSUMPTION_PROFILES_FAILURE,
    FETCH_CONSUMPTION_PROFILES_SUCCESS,
    FETCH_CONSUMPTION_PROFILE_FAILURE,
    FETCH_CONSUMPTION_PROFILE_SUCCESS,
    FILTER_CONSUMPTION_PROFILES,
    FILTER_CONSUMPTION_PROFILES_FAILURE,
    FILTER_CONSUMPTION_PROFILES_NEW_PAGE,
    FILTER_CONSUMPTION_PROFILES_NEW_PAGE_FAILURE,
    FILTER_CONSUMPTION_PROFILES_NEW_PAGE_SUCCESS,
    FILTER_CONSUMPTION_PROFILES_SUCCESS,
    INITIALIZE_CONSUMPTION_PROFILE_MODAL_FORM,
    RESET_MODAL_FORM,
    SAVE_CONSUMPTION_PROFILE,
    SAVE_CONSUMPTION_PROFILE_FAILURE,
    SAVE_CONSUMPTION_PROFILE_SUCCESS,
    SET_FILTER_QUERY,
    SET_MODAL_IS_CREATE,
    SET_MODAL_IS_OPEN,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    archiveConsumptionProfile: {
        data: null,
        errors: [],
        isArchiving: false,
    },
    deleteConsumptionProfile: {
        errors: [],
        isDeleting: false,
    },
    downloadCSV: {
        errors: [],
        isDownloading: false,
    },
    entities: orm.getEmptyState(),
    fetchConsumptionProfile: {
        errors: [],
        id: null,
        isFetching: false,
    },
    fetchConsumptionProfiles: {
        data: [],
        errors: [],
        isFetching: false,
    },
    filterConsumptionProfiles: {
        errors: [],
        hasNextPage: false,
        isFetching: false,
        isFetchingNextPage: false,
        query: {},
    },
    initialValues: {
        consumption: null,
        consumption_formatted: {},
        consumption_profile_csv: null,
        usage_type: null,
        type: 1,
        name: null,
    },
    modalIsCreate: true,
    modalIsOpen: false,
    saveConsumptionProfile: {
        errors: [],
        isSaving: false,
    },
};

const consumptionProfilesSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ARCHIVE_CONSUMPTION_PROFILE]: (state) => {
            state.archiveConsumptionProfile = {
                ...state.archiveConsumptionProfile,
                errors: [],
                isArchiving: true,
            };
        },
        [ARCHIVE_CONSUMPTION_PROFILE_FAILURE]: (state, action) => {
            state.archiveConsumptionProfile = {
                ...state.archiveConsumptionProfile,
                errors: action.payload,
                isArchiving: false,
            };
        },
        [ARCHIVE_CONSUMPTION_PROFILE_SUCCESS]: (state, action) => {
            state.archiveConsumptionProfile = {
                ...state.archiveConsumptionProfile,
                data: action.payload,
                isArchiving: false,
            };
        },
        [DELETE_CONSUMPTION_PROFILE]: (state) => {
            state.deleteConsumptionProfile = {
                ...state.deleteConsumptionProfile,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_CONSUMPTION_PROFILE_FAILURE]: (state, action) => {
            state.deleteConsumptionProfile = {
                ...state.deleteConsumptionProfile,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_CONSUMPTION_PROFILE_SUCCESS]: (state) => {
            state.deleteConsumptionProfile.isDeleting = false;
        },
        [FETCH_CONSUMPTION_PROFILE]: (state, action) => {
            state.fetchConsumptionProfile = {
                ...state.fetchConsumptionProfile,
                errors: [],
                id: action.payload,
                isFetching: true,
            };
        },
        [FETCH_CONSUMPTION_PROFILE_FAILURE]: (state, action) => {
            state.fetchConsumptionProfile = {
                ...state.fetchConsumptionProfile,
                errors: action.payload,
                id: null,
                isFetching: false,
            };
        },
        [FETCH_CONSUMPTION_PROFILE_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { ConsumptionProfile } = session;
            const profileId = action.payload.id;
            ConsumptionProfile.withId(profileId).modify(action.payload);

            state.fetchConsumptionProfile = {
                ...state.fetchConsumptionProfile,
                id: profileId,
                isFetching: false,
            };
            state.entities = session.state;
        },
        [FETCH_CONSUMPTION_PROFILES]: (state) => {
            state.fetchConsumptionProfiles = {
                ...state.fetchConsumptionProfiles,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CONSUMPTION_PROFILES_FAILURE]: (state, action) => {
            state.fetchConsumptionProfiles = {
                ...state.fetchConsumptionProfiles,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONSUMPTION_PROFILES_SUCCESS]: (state, action) => {
            state.fetchConsumptionProfiles = {
                ...state.fetchConsumptionProfiles,
                data: action.payload,
                isFetching: false,
            };
        },
        [FILTER_CONSUMPTION_PROFILES]: (state) => {
            state.filterConsumptionProfiles = {
                ...state.filterConsumptionProfiles,
                errors: [],
                isFetching: true,
            };
        },
        [FILTER_CONSUMPTION_PROFILES_FAILURE]: (state, action) => {
            state.filterConsumptionProfiles = {
                ...state.filterConsumptionProfiles,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FILTER_CONSUMPTION_PROFILES_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { ConsumptionProfile } = session;
            const { hasNextPage, consumptionProfiles } = action.payload;
            modelPopulateByArray(ConsumptionProfile, consumptionProfiles, true);

            state.filterConsumptionProfiles = {
                ...state.filterConsumptionProfiles,
                hasNextPage: hasNextPage,
                isFetching: false,
            };
            state.entities = session.state;
        },
        [FILTER_CONSUMPTION_PROFILES_NEW_PAGE]: (state) => {
            state.filterConsumptionProfiles = {
                ...state.filterConsumptionProfiles,
                errors: [],
                isFetchingNextPage: true,
            };
        },
        [FILTER_CONSUMPTION_PROFILES_NEW_PAGE_FAILURE]: (state, action) => {
            state.filterConsumptionProfiles = {
                ...state.filterConsumptionProfiles,
                errors: action.payload,
                hasNextPage: false,
                isFetchingNextPage: true,
            };
        },
        [FILTER_CONSUMPTION_PROFILES_NEW_PAGE_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { ConsumptionProfile } = session;
            const { hasNextPage, consumptionProfiles } = action.payload;
            modelPopulateByArray(ConsumptionProfile, consumptionProfiles);

            state.filterConsumptionProfiles = {
                ...state.filterConsumptionProfiles,
                hasNextPage: hasNextPage,
                isFetchingNextPage: false,
            };
            state.entities = session.state;
        },
        [INITIALIZE_CONSUMPTION_PROFILE_MODAL_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_MODAL_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.saveConsumptionProfile.errors = [];
        },
        [SAVE_CONSUMPTION_PROFILE]: (state) => {
            state.saveConsumptionProfile = {
                ...state.saveConsumptionProfile,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_CONSUMPTION_PROFILE_FAILURE]: (state, action) => {
            state.saveConsumptionProfile = {
                ...state.saveConsumptionProfile,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_CONSUMPTION_PROFILE_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { ConsumptionProfile } = session;

            if (action.payload && action.payload.id) {
                ConsumptionProfile.withId(action.payload.id).modify(
                    action.payload,
                );
                state.entities = session.state;
            }
            state.saveConsumptionProfile.isSaving = false;
        },
        [SET_FILTER_QUERY]: (state, action) => {
            state.filterConsumptionProfiles.query = action.payload;
        },
        [SET_MODAL_IS_CREATE]: (state, action) => {
            state.modalIsCreate = action.payload;
        },
        [SET_MODAL_IS_OPEN]: (state, action) => {
            state.modalIsOpen = action.payload;
        },
    },
});

export const consumptionProfilesActions = consumptionProfilesSlice.actions;

export default consumptionProfilesSlice.reducer;
