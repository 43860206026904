import { NAME } from './constants';

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const FETCH_PRODUCT = `${NAME}/FETCH_PRODUCT`;
export const FETCH_PRODUCT_FAILURE = `${NAME}/FETCH_PRODUCT_FAILURE`;
export const FETCH_PRODUCT_SUCCESS = `${NAME}/FETCH_PRODUCT_SUCCESS`;

export const FETCH_PRODUCTS = `${NAME}/FETCH_PRODUCTS`;
export const FETCH_PRODUCTS_FAILURE = `${NAME}/FETCH_PRODUCTS_FAILURE`;
export const FETCH_PRODUCTS_SUCCESS = `${NAME}/FETCH_PRODUCTS_SUCCESS`;

export const FETCH_TEMPLATE_CATALOGS = `${NAME}/FETCH_TEMPLATE_CATALOGS`;
export const FETCH_TEMPLATE_CATALOGS_FAILURE = `${NAME}/FETCH_TEMPLATE_CATALOGS_FAILURE`;
export const FETCH_TEMPLATE_CATALOGS_SUCCESS = `${NAME}/FETCH_TEMPLATE_CATALOGS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const DELETE_PRODUCT = `${NAME}/DELETE_PRODUCT`;
export const DELETE_PRODUCT_FAILURE = `${NAME}/DELETE_PRODUCT_FAILURE`;
export const DELETE_PRODUCT_SUCCESS = `${NAME}/DELETE_PRODUCT_SUCCESS`;

export const MODAL_PRODUCT_STATUS = `${NAME}/MODAL_PRODUCT_STATUS`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_PRODUCT = `${NAME}/SAVE_PRODUCT`;
export const SAVE_PRODUCT_FAILURE = `${NAME}/SAVE_PRODUCT_FAILURE`;
export const SAVE_PRODUCT_SUCCESS = `${NAME}/SAVE_PRODUCT_SUCCESS`;

export const UPDATE_ITEMS_SUCCESS = `${NAME}/UPDATE_ITEMS_SUCCESS`;
