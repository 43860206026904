import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import { STATUS_PROJECT_TYPE } from './constants';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        name: string().required(REQUIRED_TEXT).nullable(),
        primary_color: string().when('type', {
            is: STATUS_PROJECT_TYPE,
            then: () => string().required(REQUIRED_TEXT).nullable(),
        }),
    });
};
