import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Card } from 'sunwise-ui';

import { Switch } from 'common/components/form';

import IcoCertifiedRate from 'resources/icoCertifiedRate.svg?react';
import IcoCustomRate from 'resources/icoCustomRate.svg?react';

import ListItemName from './ListItemName';

const Container = styled(Card)`
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    cursor: pointer;
    display: flex;
    gap: 16px;
    margin-bottom: 0.5rem;
    padding: 1.5rem 16px;
    position: relative;
    transition: opacity 0.2s linear;
    &.active,
    &.selected,
    &:hover {
        box-shadow: 0 0 0 1px #2845ff;
    }
    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }
`;

const WrapperStyledTypeIcon = styled('span')`
    display: block;
    height: 27px;
    width: 22px;
    svg {
        height: 27px;
        max-height: 27px;
        max-width: 22px;
        width: 22px;
        path {
            fill: ${({ iconColor = '#CCD1E8' }) => iconColor};
            filter: contrast(0.8);
            stroke: transparent;
        }
        path:first-child {
            stroke: ${({ iconColor = '#CCD1E8', isCertified }) =>
                isCertified ? iconColor : 'transparent'};
        }
    }
`;

const ClickZone = styled(Box)`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`;

const getCertifiedTypeIcon = (isCertified) => {
    switch (`${isCertified}`) {
        case 'true':
            return <IcoCertifiedRate />;
        case 'false':
            return <IcoCustomRate />;
        default:
            return null;
    }
};

const getVoltageDotColor = (tensionType) => {
    switch (tensionType) {
        case 'Low tension':
            return '#caf61b';
        case 'Mid tension':
            return '#FF8F02';
        case 'High tension':
            return '#FA6968';
        default:
            return null;
    }
};

const ListItem = ({
    businessType,
    canDelete,
    disabled,
    handleOnClick,
    handleSwitchChange,
    hideSwitch = false,
    isCertified,
    isEnabledStatus,
    isSelected,
    name,
    refKey,
    shortName,
    voltageType,
}) => {
    const getBusinessTypeIcon = (type) => {
        switch (type) {
            case 'Commercial':
                return <StorefrontIcon />;
            case 'Industrial':
                return <BusinessIcon />;
            case 'Residential':
                return <HomeIcon />;
            default:
                return null;
        }
    };

    return (
        <Container
            className={`${disabled && 'disabled'} ${isSelected && 'selected'}`}
        >
            <ClickZone onClick={handleOnClick} />
            <ListItemName name={name} shortName={shortName} />
            <Box
                alignItems="center"
                display="flex"
                gap="16px"
                sx={{ marginLeft: 'auto' }}
            >
                {getBusinessTypeIcon(businessType)}
                <WrapperStyledTypeIcon
                    iconColor={getVoltageDotColor(voltageType)}
                    isCertified={isCertified}
                >
                    {getCertifiedTypeIcon(isCertified)}
                </WrapperStyledTypeIcon>

                {!hideSwitch &&
                    !isCertified &&
                    (canDelete || (!disabled && !isEnabledStatus)) && (
                        <Switch
                            checked={isEnabledStatus}
                            disabled={!canDelete || disabled}
                            id={`custom-switch-${refKey}`}
                            onChange={(e) =>
                                handleSwitchChange(e.target.checked)
                            }
                            sx={{ mr: 0, zIndex: 2 }}
                        />
                    )}
            </Box>
        </Container>
    );
};

ListItem.propTypes = {
    canDelete: PropTypes.bool,
    disabled: PropTypes.bool,
    handleOnClick: PropTypes.func,
    handleSwitchChange: PropTypes.func,
    hideSwitch: PropTypes.bool,
    isCertified: PropTypes.bool,
    isEnabledStatus: PropTypes.bool,
    isSelected: PropTypes.bool,
    name: PropTypes.string,
    businessType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    refKey: PropTypes.string,
    shortName: PropTypes.string,
    voltageType: PropTypes.string,
};

export default ListItem;
