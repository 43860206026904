import { updateTracking } from 'common/api/v1/contactTrackings';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import fetchTrackings from './fetchTrackings';
import fetchTrackingsNext from './fetchTrackingsNext';

export default (id) => (dispatch) => {
    updateTracking({ completed: true }, id)
        .then(() => {
            dispatch(fetchTrackings());
            dispatch(fetchTrackingsNext());

            showToast();
        })
        .catch((error) => showReponseErrorsAsAlert(dispatch, error?.response));
};
