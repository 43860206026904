import i18next from 'i18next';

import alerts from 'common/modules/alerts';

export default (isPage, callback) => (dispatch) => {
    const message = isPage
        ? i18next.t('Are you really sure to delete this page?')
        : i18next.t('Are you really sure to delete this page?', { count: 2 });

    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [message],
        onSuccess: () => callback(),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
