import { NAME } from './constants';

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const FETCH_ITEMS = `${NAME}/FETCH_ITEMS`;
export const FETCH_ITEMS_FAILURE = `${NAME}/FETCH_ITEMS_FAILURE`;
export const FETCH_ITEMS_SUCCESS = `${NAME}/FETCH_ITEMS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const INITIALIZE_MARGIN_FORM = `${NAME}/INITIALIZE_MARGIN_FORM`;

export const OPEN_MODAL = `${NAME}/OPEN_MODAL`;
export const OPEN_MODAL_MARGIN = `${NAME}/OPEN_MODAL_MARGIN`;
export const OPEN_MODAL_PRODUCT = `${NAME}/OPEN_MODAL_PRODUCT`;

export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_MARGIN_FORM = `${NAME}/RESET_MARGIN_FORM`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SAVE_BULK_MARGIN = `${NAME}/SAVE_BULK_MARGIN`;
export const SAVE_BULK_MARGIN_FAILURE = `${NAME}/SAVE_BULK_MARGIN_FAILURE`;
export const SAVE_BULK_MARGIN_SUCCESS = `${NAME}/SAVE_BULK_MARGIN_SUCCESS`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
export const SET_IS_DOWNLOADING = `${NAME}/SET_IS_DOWNLOADING`;
export const SET_IS_UPLOADING = `${NAME}/SET_IS_UPLOADING`;

export const UPDATE_ITEMS_SUCCESS = `${NAME}/UPDATE_ITEMS_SUCCESS`;
