import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from 'sunwise-ui';

import ProviderImage from 'common/components/ProviderImage';

import { STATUS_CONFIG } from '../../constants';
import DotIndicator from '../DotIndicator';

const PowerStation = ({ showDivider, powerStation, providersDictionary }) => {
    const { t } = useTranslation();
    if (!powerStation) return null;

    const provider = providersDictionary?.[powerStation?.provider_id];
    const status = STATUS_CONFIG[powerStation?.status];
    const name =
        powerStation?.name || powerStation?.read_requirements?.station_id;

    return (
        <>
            <Typography variant="body2">{name}</Typography>
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                key={powerStation?.id}
                mt={1}
            >
                {provider && (
                    <ProviderImage
                        provider={provider}
                        style={{ maxWidth: '90px' }}
                    />
                )}

                {status && (
                    <Box alignItems="center" display="flex" gap={1}>
                        <DotIndicator color={status.color} />
                        <Typography variant="body2">
                            {t(status.label)}
                        </Typography>
                    </Box>
                )}
            </Box>
            {showDivider && <Divider sx={{ my: 2 }} />}
        </>
    );
};

PowerStation.propTypes = {
    powerStation: PropTypes.object,
    providersDictionary: PropTypes.object,
    showDivider: PropTypes.bool,
};

export default PowerStation;
