import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert } from 'sunwise-ui';

import { getDateIsBeforeProjectStartDate } from '../../helpers';
import * as selectors from '../../selectors';

const InstallationDateWarning = ({ date, projectInstallationDate }) => {
    const { t } = useTranslation();

    if (!date || !projectInstallationDate) return null;

    const isBefore = getDateIsBeforeProjectStartDate({
        date,
        projectInstallationDate,
    });

    if (!isBefore) return null;

    return (
        <Alert severity="warning">
            {t('The selected date is before the project installation date')}
        </Alert>
    );
};

const mapStateToProps = createStructuredSelector({
    projectInstallationDate: selectors.getAfterSalesStartDate,
});

InstallationDateWarning.propTypes = {
    date: PropTypes.string,
    projectInstallationDate: PropTypes.string,
};

export default connect(mapStateToProps)(InstallationDateWarning);
