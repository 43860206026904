import { getItems } from 'common/api/v1/brands';

import {
    FETCH_BRANDS,
    FETCH_BRANDS_FAILURE,
    FETCH_BRANDS_SUCCESS,
} from '../actionTypes';
import { invertersConfigurationActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(invertersConfigurationActions[FETCH_BRANDS]());

    getItems()
        .then((response) =>
            dispatch(
                invertersConfigurationActions[FETCH_BRANDS_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                invertersConfigurationActions[FETCH_BRANDS_FAILURE](error),
            ),
        );
};
