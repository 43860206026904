import { createSlice } from '@reduxjs/toolkit';

import {
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
    FETCH_BLOG_POSTS,
    FETCH_BLOG_POSTS_FAILURE,
    FETCH_BLOG_POSTS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: {
        email: '',
        password: '',
    },
    session: {
        isLoading: false,
        errors: null,
    },
    validPassword: false,
    posts: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
};

const loginSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [LOGIN_USER]: (state) => {
            state.session.isLoading = true;
        },
        [LOGIN_USER_FAILURE]: (state, action) => {
            state.session = {
                ...state.session,
                isLoading: false,
                errors: action.payload,
            };
        },
        [LOGIN_USER_SUCCESS]: (state) => {
            state.session.isLoading = false;
        },
        [FETCH_BLOG_POSTS]: (state) => {
            state.posts.isLoading = true;
        },
        [FETCH_BLOG_POSTS_FAILURE]: (state, action) => {
            state.posts = {
                ...state.posts,
                isLoading: false,
                errors: action.payload,
            };
        },
        [FETCH_BLOG_POSTS_SUCCESS]: (state, action) => {
            state.posts = {
                ...state.posts,
                data: action.payload,
                isEmpty: action.payload.length === 0,
                isLoading: false,
            };
        },
    },
});

export const loginActions = loginSlice.actions;

export default loginSlice.reducer;
