import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import editReport from './editReport';

export default (reportId) => (dispatch) => {
    const messages = [
        i18next.t('Are you sure you want to modify this report?'),
        // i18next.t('You can always DUPLICATE the report'),
    ];

    const dataAlert = {
        title: i18next.t('Alert'),
        messages: messages,
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
        confirmText: i18next.t('Continue'),
        cancelText: i18next.t('Cancel'),
        onSuccess: () => dispatch(editReport(reportId)),
    };
    dispatch(alerts.actions.show(dataAlert));
};
