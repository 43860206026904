import { getContractedDemandUnits } from 'common/api/v1/unitRates';

import {
    FETCH_CONTRACTED_DEMAND_UNITS,
    FETCH_CONTRACTED_DEMAND_UNITS_FAILURE,
    FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS,
} from '../actionTypes';
import { ratesActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(ratesActions[FETCH_CONTRACTED_DEMAND_UNITS]());

    getContractedDemandUnits()
        .then((response) =>
            dispatch(
                ratesActions[FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS](
                    response?.data?.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                ratesActions[FETCH_CONTRACTED_DEMAND_UNITS_FAILURE](error),
            ),
        );
};
