import React from 'react';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';

export const NAME = 'template-library';
export const BLANK_TEMPLATE = 'blank-template';
export const NEW_TEMPLATE_MODE = 'NEW/TEMPLATE/MODE';
export const NEW_FROM_TEMPLATE_MODE = 'NEW/FROM/TEMPLATE/MODE';
export const DUPLICATE_TEMPLATE_MODE = 'DUPLICATE/TEMPLATE/MODE';
export const TEMPLATE_MODAL_MAIN_DATA = 'template-modal-main-data';
export const TEMPLATE_MODAL_SELECT_BASE = 'template-modal-select-base';
export const UPDATE_TEMPLATE_MODE = 'UPDATE/TEMPLATE/MODE';

export const TEMPLATES_URL = {
    PROPOSAL: 'proposal',
    REPORT: 'report',
    SMART_DOCUMENT: 'smart-document',
    SUMMARY: 'summary',
};

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <NavigateNextIcon color="primary" />
        </div>
    );
};

SampleNextArrow.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <NavigateBeforeIcon color="primary" />
        </div>
    );
};

SamplePrevArrow.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

export const sliderSettings = {
    adaptiveHeight: true,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: false,
    infinite: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 1600,
            settings: { slidesToScroll: 1, slidesToShow: 7 },
        },
        {
            breakpoint: 1400,
            settings: { slidesToScroll: 1, slidesToShow: 6 },
        },
        {
            breakpoint: 1200,
            settings: { slidesToScroll: 1, slidesToShow: 4 },
        },
        {
            breakpoint: 992,
            settings: { slidesToScroll: 1, slidesToShow: 3 },
        },
        {
            breakpoint: 768,
            settings: { slidesToScroll: 1, slidesToShow: 2 },
        },
        {
            breakpoint: 576,
            settings: { slidesToScroll: 1, slidesToShow: 1 },
        },
    ],
    swipeToSlide: true,
    slidesToScroll: 1,
    slidesToShow: 8,
    speed: 500,
};

export const COLUMNS_CONFIG = {
    title: {
        label: 'Title',
        is_active: true,
        required: true,
    },
    branch_office: {
        label: 'Branch office',
        is_active: true,
        required: false,
    },
    language: {
        label: 'Language',
        is_active: true,
        required: false,
    },
    created_by: {
        label: 'Created by',
        is_active: true,
        required: false,
    },
    created_at: {
        label: 'Creation date',
        is_active: true,
        required: false,
    },
};
