import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'sunwise-ui';

const TaxesSaveButton = ({ disabled, onClick, isSavingTaxes, visible }) => {
    const { t } = useTranslation();
    if (!visible) return null;
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            sx={{ width: { sx: '100%', md: 'auto' } }}
            variant="outlined"
        >
            {isSavingTaxes ? t('Saving').concat('...') : t('Save')}
        </Button>
    );
};

TaxesSaveButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    isSavingTaxes: PropTypes.bool,
    visible: PropTypes.bool,
};

export default TaxesSaveButton;
