import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'sunwise-ui';

const DefaultButton = ({
    canModify,
    canModifyRemainingLicenses,
    company,
    handleClickCreate,
    handleClickLicenses,
    isDisabledAddMemberButton,
    isHidden,
    isPro,
}) => {
    const { t } = useTranslation();

    if (isHidden) return null;

    if (isDisabledAddMemberButton && isPro)
        return (
            <Button
                endIcon={<AddIcon />}
                onClick={() => handleClickLicenses()}
                sx={{
                    width: {
                        xs: '100%',
                        md: 'auto',
                    },
                }}
                variant="outlined"
                visible={canModifyRemainingLicenses}
            >
                {t('Change to scale plan')}
            </Button>
        );

    return (
        <Button
            disabled={isDisabledAddMemberButton}
            endIcon={<AddIcon />}
            onClick={() => handleClickCreate(get(company, 'id', 0))}
            sx={{
                width: {
                    xs: '100%',
                    md: 'auto',
                },
            }}
            variant="outlined"
            visible={canModify}
        >
            {t('Add user')}
        </Button>
    );
};

DefaultButton.propTypes = {
    canModify: PropTypes.bool,
    canModifyRemainingLicenses: PropTypes.bool,
    company: PropTypes.object,
    handleClickCreate: PropTypes.func,
    handleClickLicenses: PropTypes.func,
    isDisabledAddMemberButton: PropTypes.bool,
    isHidden: PropTypes.bool,
    isPro: PropTypes.bool,
};

export default DefaultButton;
