import { push } from 'connected-react-router';

export default (contactId = null, projectId = null) =>
    (dispatch) => {
        dispatch(
            push(
                `/record/${contactId}${
                    projectId
                        ? `?tab=projects&project=${projectId}&view=reports`
                        : ''
                }`,
            ),
        );
    };
