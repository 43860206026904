import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { getCurrencySymbol, numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

const AmountLabel = ({ comercialOffer, rate }) => {
    const { t } = useTranslation();
    const currencyIso = get(comercialOffer, 'currency', 'USD');
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const currencySymbol = getCurrencySymbol(comercialOffer);

    if (
        isEmpty(rate) ||
        isEmpty(comercialOffer) ||
        comercialOffer.is_archived
    ) {
        return (
            <Typography fontWeight="bold" variant="body2">
                {t('No proposals')}
            </Typography>
        );
    }
    const { total = 0 } = comercialOffer;

    return (
        <Typography fontWeight="bold" variant="body2">
            {numberFormat(total, {
                currency: currencyIso,
                locale: countryCurrencyLocale,
                style: 'currency',
                symbol: currencySymbol,
            })}
        </Typography>
    );
};

AmountLabel.propTypes = {
    comercialOffer: PropTypes.object,
    rate: PropTypes.string,
};

export default AmountLabel;
