import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_ITEM,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_SUCCESS,
    FETCH_SUPPORT_CONTACT,
    FETCH_SUPPORT_CONTACT_FAILURE,
    FETCH_SUPPORT_CONTACT_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SAVE_SUPPORT_CONTACT,
    SAVE_SUPPORT_CONTACT_FAILURE,
    SAVE_SUPPORT_CONTACT_SUCCESS,
    SET_CONTACT_ID,
    SHOW_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    contactId: null,
    initialValues: {
        id: '',
        name: '',
        first_lastname: '',
        second_lastname: '',
        contact_type: 'SUPPORT',
        origin_contact: 'OTHER',
        agent: '',
        contact: '',
        telephones: [],
        emails: [],
    },
    fetchSupportContacts: {
        isLoading: false,
        data: [],
        error: null,
    },
    saveContact: {
        isLoading: false,
        errors: null,
    },
    isVisibleModal: false,
    deleteItem: {
        isDeleting: false,
        data: [],
        error: null,
    },
};

const supportContactFormSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_SUPPORT_CONTACT]: (state) => {
            state.fetchSupportContacts.isLoading = true;
        },
        [FETCH_SUPPORT_CONTACT_FAILURE]: (state, action) => {
            state.fetchSupportContacts = {
                ...state.fetchSupportContacts,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_SUPPORT_CONTACT_SUCCESS]: (state, action) => {
            state.fetchSupportContacts = {
                ...state.fetchSupportContacts,
                data: action.payload,
                error: null,
                isLoading: false,
            };
        },
        [SAVE_SUPPORT_CONTACT]: (state) => {
            state.saveContact.isLoading = true;
        },
        [SAVE_SUPPORT_CONTACT_FAILURE]: (state, action) => {
            state.saveContact = {
                ...state.saveContact,
                errors: action.payload,
                isLoading: false,
            };
        },
        [SAVE_SUPPORT_CONTACT_SUCCESS]: (state) => {
            state.saveContact.isLoading = false;
        },
        [SET_CONTACT_ID]: (state, action) => {
            state.contactId = action.payload;
        },
        [SHOW_MODAL]: (state, action) => {
            state.isVisibleModal = action.payload;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = {
                id: '',
                name: '',
                first_lastname: '',
                second_lastname: '',
                contact_type: 'SUPPORT',
                origin_contact: 'OTHER',
                agent: '',
                contact: '',
                telephones: [],
                emails: [],
            };
        },
        [DELETE_ITEM]: (state) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: [],
                isDeleting: false,
            };
        },
        [DELETE_ITEM_FAILURE]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_ITEM_SUCCESS]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                data: action.payload,
                isDeleting: false,
            };
        },
    },
});

export const supportContactFormActions = supportContactFormSlice.actions;

export default supportContactFormSlice.reducer;
