import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ButtonGroup,
    Divider,
    Grid,
    Typography,
    UiDialog as Dialog,
} from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
    ReactHookFormSlider,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import { numberFormat } from 'common/utils/helpers';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

import SavedByModal from './SavedByModal';
import TableEditConfig from './TableEditConfig';

const CreateSourceModal = ({
    canDelete,
    canModify,
    control,
    countryCurrencyLocale,
    defaultSourceData,
    errors,
    getAvg,
    getValues,
    handleClickDelete,
    handleClickSave,
    handleSubmit,
    isNew,
    isOpenModal,
    onHide,
    setValue,
    source,
}) => {
    const { t } = useTranslation();
    const [isOpenSavedByModal, toggleSavedByModal] = useState(false);
    const [isDiferentSavedBy, setIsDiferentSavedBy] = useState(false);
    const [ownSourceData, setOwnSourceData] = useState();
    const hrSol = useWatch({ control, name: 'hrsSol' });
    const kwhKw = useWatch({ control, name: 'kwhkw' });

    useEffect(() => {
        if (!isNew) setIsDiferentSavedBy(false);
    }, [isOpenModal]);

    const handleOnChange = (value) => {
        let tempHrSol = new Array(12).fill(0);
        let tempKwKwh = new Array(12).fill(0);
        if (
            defaultSourceData &&
            defaultSourceData[0] &&
            defaultSourceData[0].monthly_solar_radiation_hrsol &&
            defaultSourceData[0].monthly_solar_radiation_hrsol.length > 0
        ) {
            tempHrSol = defaultSourceData[0].monthly_solar_radiation_hrsol.map(
                (item) => ({ value: item.average }),
            );
            tempKwKwh = defaultSourceData[0].monthly_solar_radiation_hrsol.map(
                (item) => ({ value: (item.average * 365).toFixed(2) }),
            );
        }

        if (hrSol.length) {
            const delta = 1 + (value - 100) / 100;
            const newHrsSol = [];
            const newKwhkw = [];
            for (let i = 0; i < hrSol.length; i++) {
                newHrsSol.push({ value: tempHrSol[i].value * delta });
                newKwhkw.push({ value: tempKwKwh[i].value * delta });
            }
            setOwnSourceData({ newHrsSol, newKwhkw });
        }
    };
    useEffect(() => {
        if (ownSourceData) {
            setValue('hrsSol', ownSourceData.newHrsSol);
            setValue('kwhkw', ownSourceData.newKwhkw);
        }
    }, [ownSourceData]);

    const handleChangeFieldSavedBy = (value) => {
        if (!isNew) {
            if (value !== source[0].saved_by) {
                setIsDiferentSavedBy(true);
                setValue('increment', 100);
                handleOnChange(100);
            } else {
                setIsDiferentSavedBy(false);
                const increment =
                    (source[0].monthly_solar_radiation_hrsol[0].average * 100) /
                    defaultSourceData[0].monthly_solar_radiation_hrsol[0]
                        .average;

                setValue('increment', increment);
                handleOnChange(increment);
            }
        }
    };

    const handleSave = (values) => {
        if (values.saved_by === 'city') {
            handleClickSave(values, setValue);
        } else {
            toggleSavedByModal(true);
        }
    };

    const sxButton = {
        order: { md: 2, xs: 1 },
        width: { md: 'auto', xs: '100%' },
    };
    const isMexicanAccount = getIsMexicanAccount();

    return (
        <Dialog
            open={isOpenModal}
            onClose={onHide}
            title={
                <>
                    {isNew ? t('Create') : t('Edit')}{' '}
                    {t('Own source').toLowerCase()}
                </>
            }
        >
            <form onSubmit={handleSubmit(handleSave)}>
                <Typography variant="caption">
                    {t('To save your custom settings, you need')}:
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container sx={{ justifyContent: 'center' }}>
                    <Grid item xs={17}>
                        <ReactHookFormSlider
                            control={control}
                            marks={[
                                { label: '0', value: 0 },
                                { label: '100%', value: 100 },
                                { label: '200%', value: 200 },
                            ]}
                            max={200}
                            min={0}
                            name="increment"
                            onChangeCommitted={handleOnChange}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <Grid container alignItems="center">
                    <Grid item xs={18} md={9} order={{ xs: 2, md: 1 }}>
                        <Grid container>
                            <Grid item xs>
                                <ReactHookFormIntlNumberInput
                                    append={'ºC'}
                                    control={control}
                                    fullWidth
                                    label={t('Min. Temp')}
                                    name="temp_min"
                                    placeholder={t('Min. Temp')}
                                    step={0.1}
                                />
                            </Grid>
                            <Grid item xs>
                                <ReactHookFormIntlNumberInput
                                    append={'ºC'}
                                    control={control}
                                    fullWidth
                                    label={t('Max. Temp')}
                                    name="temp_max"
                                    placeholder={t('Max. Temp')}
                                    step={0.1}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        md={9}
                        order={{ xs: 1, md: 2 }}
                        sx={{ textAlign: 'right' }}
                        xs={18}
                    >
                        <ButtonGroup fullWidth variant="outlined">
                            <Button>{t('Average')}</Button>
                            <Button>
                                {numberFormat(getAvg(hrSol), {
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                    unit: t('Hr Sun'),
                                })}
                            </Button>
                            <Button>
                                {numberFormat(getAvg(kwhKw), {
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                    unit: 'Kwh/Kw',
                                })}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <TableEditConfig control={control} setValue={setValue} />
                <Divider sx={{ my: 2 }} />
                <ReactHookFormSelect
                    control={control}
                    name="saved_by"
                    label={t('Apply these settings to')}
                    onChange={(e) => {
                        handleChangeFieldSavedBy(e.target.value);
                    }}
                    options={[
                        {
                            label: isMexicanAccount
                                ? t('City')
                                : t('Political division of order 2'),
                            value: 'city',
                        },
                        {
                            label: isMexicanAccount
                                ? t('State')
                                : t('Political division of order 1'),
                            value: 'state',
                        },
                        { label: t('Country'), value: 'country' },
                    ]}
                />
                {errors && errors.length > 0 && (
                    <>
                        <Divider sx={{ my: 2 }} />

                        <ShowErrors errors={errors} />
                    </>
                )}
                <Grid container>
                    <Grid
                        item
                        xs
                        textAlign="right"
                        sx={{
                            display: 'flex',
                            flexDirection: { md: 'row', xs: 'column' },
                            gap: 1,
                            justifyContent: { md: 'right', xs: 'center' },
                        }}
                    >
                        {isNew === false && isDiferentSavedBy === false && (
                            <Button
                                color="error"
                                onClick={() =>
                                    handleClickDelete(getValues(), setValue)
                                }
                                type="button"
                                variant="text"
                                visible={canDelete}
                                sx={sxButton}
                            >
                                {t('Delete')}
                            </Button>
                        )}
                        <Button
                            type="submit"
                            variant="outlined"
                            visible={canModify}
                            sx={sxButton}
                        >
                            {t('Save')}
                        </Button>
                    </Grid>
                </Grid>
                <SavedByModal
                    control={control}
                    getValues={getValues}
                    handleClickSave={handleClickSave}
                    handleSubmit={handleSubmit}
                    isOpenModal={isOpenSavedByModal}
                    setValue={setValue}
                    toggleModal={toggleSavedByModal}
                />
            </form>
        </Dialog>
    );
};

CreateSourceModal.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    control: PropTypes.object,
    countryCurrencyLocale: PropTypes.string,
    defaultSourceData: PropTypes.array,
    errors: PropTypes.array,
    getAvg: PropTypes.func,
    getValues: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    isNew: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    onHide: PropTypes.func,
    setValue: PropTypes.func,
    source: PropTypes.array,
};

export default CreateSourceModal;
