import { FETCH_ITEMS_SUCCESS } from '../actionTypes';
import { multiCurrencyActions } from '../reducer';

export default (items, data, enabled) => (dispatch) => {
    const newItems = items.map((item) => {
        if (item.id === data.id) {
            return {
                ...item,
                addition_type: data.addition_type,
                addition_value: data.addition_value,
                discount_rate: data.discount_rate,
                dollar_price: data.dollar_price,
                exchange_type: data.exchange_type,
                inverse_dollar_price: data.inverse_dollar_price,
                is_enabled: enabled,
                source: data.source,
            };
        }
        return item;
    });

    dispatch(multiCurrencyActions[FETCH_ITEMS_SUCCESS](newItems));
};
