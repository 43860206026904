import { styled } from '@mui/material/styles';
import { Card } from 'sunwise-ui';

export default styled(Card)`
    display: flex;
    flex-direction: column;
    height: 400px;
    padding: 16px;

    @media (min-width: 900px) and (max-width: 1199px) {
        height: calc(100vh - 374px);
    }

    @media (min-width: 1200px) {
        height: calc(100vh - 385px);
    }
`;
