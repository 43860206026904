import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';

import Label from './Label';
import TextWithLabel from './TextWithLabel';

const getContactName = ({ contactsDictionary, id }) => {
    if (isEmpty(contactsDictionary[id])) return '';
    const { name, first_lastname, second_lastname } = contactsDictionary[id];
    return `${name} ${first_lastname} ${second_lastname}`;
};

const ReassignListItems = ({ contactsDictionary, control, name }) => {
    const { t } = useTranslation();
    const { fields } = useFieldArray({ control, name });

    return (
        <>
            {fields.map((field, index) => {
                const { id, branchName = '', contacts = [] } = field;
                return (
                    <Grid container key={`reassign-list-item-${id}`}>
                        <Grid item xs={18} md={3}>
                            <TextWithLabel
                                label={t('Branch office')}
                                text={branchName}
                            />
                        </Grid>
                        <Grid item xs={18} md={7}>
                            <Label text={t('User', { count: 2 })} />
                            {contacts.map((item, index) => (
                                <Typography
                                    fontWeight="bold"
                                    key={`contact-${index}`}
                                    variant="body2"
                                >
                                    {getContactName({
                                        contactsDictionary,
                                        id: item,
                                    })}
                                </Typography>
                            ))}
                        </Grid>
                        <Grid item xs={18} md={8}>
                            <Label text={t('New solar advisor')} />
                            <ReactHookFormSelect
                                control={control}
                                name={`${name}.${index}.agent`}
                                placeholder={t('Adviser')}
                                options={field?.agentsToSelect}
                            />
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};

ReassignListItems.propTypes = {
    contactsDictionary: PropTypes.object,
    control: PropTypes.object,
    name: PropTypes.string,
};

export default ReassignListItems;
