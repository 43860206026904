import { createSelector } from 'reselect';

import orm from 'common/orm';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getEntitiesSession = createSelector(getModel, (state) =>
    orm.session(state.entities),
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getCompaniesCatalogs = createSelector(
    getModel,
    (model) => model.companiesCatalogs,
);

export const getIsFetchingCompaniesCatalogs = createSelector(
    getCompaniesCatalogs,
    (catalogs) => catalogs.isFetching,
);

export const getDataCompaniesCatalogs = createSelector(
    getEntitiesSession,
    ({ CompanyCatalog }) => {
        return CompanyCatalog.all().toRefArray();
    },
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal,
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord,
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getErrorsSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.errors,
);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving,
);

export const getType = createSelector(getModel, (model) => model.type);

export const getCloseInEscape = createSelector(
    getModel,
    (model) => model.closeInEscape,
);

export const getUpdatingActiveStatusId = createSelector(
    getModel,
    (model) => model.updatingActiveStatusId,
);
