import { connect } from 'common/sockets/afterSalesProjects';

import * as selectors from '../selectors';

export default (projectId) => (dispatch, getState) => {
    if (!projectId) return;

    const state = getState();
    const isConnected = selectors.getSocketConnected(state);

    if (!isConnected) dispatch(connect(projectId));
};
