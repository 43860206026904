import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import archiveSummary from './archiveSummary';

export default (summary, isArchivedFilter) => (dispatch) => {
    const text = summary.archived
        ? i18next.t('Unarchive')
        : i18next.t('Archive');

    const message = summary.archived
        ? i18next.t('Are you sure you want to unarchive this item?')
        : i18next.t('Are you sure you want to archive this item?');

    const dataAlert = {
        confirmText: text,
        messages: [message],
        onSuccess: () => {
            dispatch(archiveSummary(summary, isArchivedFilter));
        },
        title: summary.archived
            ? i18next.t('Unarchive summary')
            : i18next.t('Archive summary'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
