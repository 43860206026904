import i18next from 'i18next';
import { createSelector } from 'reselect';

import orm from 'common/orm';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getEntitiesSession = createSelector(getModel, (state) =>
    orm.session(state.entities),
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getModalIsCreate = createSelector(
    getModel,
    (model) => model.modalIsCreate,
);

export const getModalIsOpen = createSelector(
    getModel,
    (model) => model.modalIsOpen,
);

export const getSaveConsumptionProfile = createSelector(
    getModel,
    (model) => model.saveConsumptionProfile,
);

export const getIsSavingConsumptionProfile = createSelector(
    getSaveConsumptionProfile,
    (saveConsumptionProfile) => saveConsumptionProfile.isSaving,
);

export const getSaveConsumptionProfileErrors = createSelector(
    getSaveConsumptionProfile,
    (saveConsumptionProfile) => saveConsumptionProfile.errors,
);

export const getFetchConsumptionProfiles = createSelector(
    getModel,
    (model) => model.fetchConsumptionProfiles,
);

export const getFetchConsumptionProfilesData = createSelector(
    getFetchConsumptionProfiles,
    (model) => model.data,
);

export const getFetchConsumptionProfilesErrors = createSelector(
    getFetchConsumptionProfiles,
    (model) => model.errors,
);

export const getFilterConsumptionProfiles = createSelector(
    getModel,
    (model) => model.filterConsumptionProfiles,
);

export const getIsFetchingConsumptionProfiles = createSelector(
    getFilterConsumptionProfiles,
    (model) => model.isFetching,
);

export const getFilterConsumptionProfilesHasNextPage = createSelector(
    getFilterConsumptionProfiles,
    (filterConsumptionProfiles) => filterConsumptionProfiles.hasNextPage,
);

export const getIsFetchingNextPage = createSelector(
    getFilterConsumptionProfiles,
    (filterConsumptionProfiles) => filterConsumptionProfiles.isFetchingNextPage,
);

export const getHasNextPage = createSelector(
    getFilterConsumptionProfilesHasNextPage,
    getIsFetchingNextPage,
    (hasNextPage, isFetchingNextPage) => hasNextPage && !isFetchingNextPage,
);

export const getFilterQuery = createSelector(
    getFilterConsumptionProfiles,
    (filterConsumptionProfiles) => filterConsumptionProfiles.query,
);

export const getConsumptionProfilesData = createSelector(
    getEntitiesSession,
    ({ ConsumptionProfile }) => {
        return ConsumptionProfile.all()
            .toRefArray()
            .map((profile) => {
                if (profile.type === 0)
                    return { ...profile, name: i18next.t(profile.name) };
                return profile;
            });
    },
);

export const getCertifiedConsumptionProfilesNumber = createSelector(
    getConsumptionProfilesData,
    (consumptionProfilesData) =>
        consumptionProfilesData.filter(
            (consumptionProfile) => consumptionProfile.type === 0,
        ).length,
);

export const getFetchConsumptionProfile = createSelector(
    getModel,
    (model) => model.fetchConsumptionProfile,
);

export const getIsFetchingConsumptionProfile = createSelector(
    getFetchConsumptionProfile,
    (fetchConsumptionProfile) => fetchConsumptionProfile.isFetching,
);

export const getSelectedItemId = createSelector(
    getFetchConsumptionProfile,
    (fetchConsumptionProfile) => fetchConsumptionProfile.id,
);

export const getSelectedItemModel = createSelector(
    getEntitiesSession,
    getSelectedItemId,
    ({ ConsumptionProfile }, id) => (id ? ConsumptionProfile.withId(id) : null),
);
