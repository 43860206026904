import isArray from 'lodash/isArray';

import { createPosition } from 'common/api/v1/contactPositions';
import { updatePosition } from 'common/api/v1/contacts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_POSITION,
    SAVE_POSITION_ERROR,
    SAVE_POSITION_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import fetchPositions from './fetchPositions';

export default (
        values,
        uid,
        successCallback = () => {},
        failureCallback = () => {},
    ) =>
    (dispatch) => {
        const newValues = {
            contact: uid,
            description: values.title,
            extra_position_data: JSON.stringify(values.extra_position_data),
            title: values.title,
        };

        newValues.position = JSON.stringify({
            additional_information: values.postalAddress,
            city: values.city,
            description: values.title,
            latitude: values.position.latitude,
            longitude: values.position.longitude,
            political_division1: values.political_division1,
            zip_code: values.cp,
        });

        if (isArray(values.file)) {
            newValues.file = values.file && values.file[0];
        }

        dispatch(actions[SAVE_POSITION]());

        const save =
            values.id > 0
                ? () => updatePosition(newValues, uid)
                : () => createPosition(newValues);

        save()
            .then((response) => {
                dispatch(actions[SAVE_POSITION_SUCCESS](response.data));
                dispatch(fetchPositions(uid));
                showToast();
                successCallback();
            })
            .catch((error) => {
                failureCallback();
                dispatch(
                    actions[SAVE_POSITION_ERROR](error?.response?.data?.errors),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
