import { createLisaProject } from 'common/api/v1/project';

export default (project, onUpdatedProject) => {
    if (!project?.id) return;
    return new Promise((resolve, reject) => {
        createLisaProject(project.id)
            .then((response) => {
                const lisa_project = response?.data?.data;
                const updatedProject = { ...project, lisa_project };

                if (onUpdatedProject) onUpdatedProject(updatedProject);
                resolve(lisa_project);
            })
            .catch((error) => reject(error));
    });
};
