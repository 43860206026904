import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import BusinessIcon from '@mui/icons-material/Business';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box, Grid, Skeleton, Typography } from 'sunwise-ui';

import { ChipProposalStatus, ProposalStatusIcon } from 'common/components';
import { getStatusProposalLabel } from 'common/utils/helpers';

import Breadcrumbs from './Breadcrumbs';

const Header = ({
    handleClickBreadcrumb,
    isApproved,
    isFetchingOfferDetails,
    isGenerated,
    offerDetails,
    offerDetailsBranchOffice,
    offerDetailsPattern,
}) => (
    <Grid container sx={{ mb: { md: 2, xs: 0 }, pt: 2, px: 2 }}>
        <Grid
            item
            lg={14}
            sx={{ display: { md: 'block', xs: 'none' } }}
            xs={18}
        >
            <Box alignItems="center" display="flex" gap={1}>
                <ArticleIcon sx={{ color: 'primary.main' }} />
                {isFetchingOfferDetails ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: '1rem', width: '100%' }}
                    />
                ) : (
                    <Typography variant="h4">
                        {get(offerDetails, 'name', null)}
                    </Typography>
                )}
            </Box>

            <Box alignItems="center" display="flex" gap={1} sx={{ ml: '32px' }}>
                <BusinessIcon sx={{ color: 'primary.main' }} />
                {isFetchingOfferDetails ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: '1rem', width: '100%' }}
                    />
                ) : (
                    <Typography fontWeight="bold" variant="body2">
                        {get(offerDetailsBranchOffice, 'name', null)}
                    </Typography>
                )}
            </Box>
            <Box sx={{ ml: { md: '24px' } }}>
                {isFetchingOfferDetails ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: '1rem', width: '100%' }}
                    />
                ) : (
                    <Breadcrumbs
                        handleClickBreadcrumb={handleClickBreadcrumb}
                        offerDetails={offerDetails}
                        offerDetailsPattern={offerDetailsPattern}
                    />
                )}
            </Box>
        </Grid>

        <Grid
            item
            lg={4}
            sx={{ display: { md: 'block', xs: 'none' } }}
            textAlign="right"
            xs={18}
        >
            {isFetchingOfferDetails ? (
                <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem', width: '100%' }}
                />
            ) : (
                <ChipProposalStatus
                    icon={
                        <ProposalStatusIcon
                            isApproved={isApproved}
                            isGenerated={isGenerated}
                        />
                    }
                    label={getStatusProposalLabel(isApproved, isGenerated)}
                />
            )}
        </Grid>
    </Grid>
);

Header.propTypes = {
    handleClickBreadcrumb: PropTypes.func,
    isApproved: PropTypes.bool,
    isFetchingOfferDetails: PropTypes.bool,
    isGenerated: PropTypes.bool,
    offerDetails: PropTypes.object,
    offerDetailsBranchOffice: PropTypes.object,
    offerDetailsPattern: PropTypes.object,
};

export default Header;
