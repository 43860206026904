import { SET_DRAWING_MODE, SET_DRAWING_TYPE } from '../actionTypes';
import { POLYGON_SEGMENT } from '../constants';
import { panelLayoutV2Actions } from '../reducer';

import selectSegment from './selectSegment';
export default (isDrawing, type) => (dispatch) => {
    if (isDrawing) dispatch(selectSegment(null));
    dispatch(panelLayoutV2Actions[SET_DRAWING_MODE](isDrawing));
    dispatch(panelLayoutV2Actions[SET_DRAWING_TYPE](type || POLYGON_SEGMENT));
};
