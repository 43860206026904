import { push } from 'connected-react-router';

import { create } from 'common/api/v2/storageOffer';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    CREATE_OFFER,
    CREATE_OFFER_FAILURE,
    CREATE_OFFER_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorEnergyBackupActions } from '../reducer';

export default (commercialOfferId) => (dispatch) => {
    dispatch(proposalGeneratorEnergyBackupActions[CREATE_OFFER]());

    create(commercialOfferId)
        .then((response) => {
            dispatch(
                proposalGeneratorEnergyBackupActions[CREATE_OFFER_SUCCESS](
                    response.data.data,
                ),
            );
            localStorage.setItem('openedProposalModule', 'energyBackup');
            dispatch(push(`/energy-backup-setup/${commercialOfferId}`));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorEnergyBackupActions[CREATE_OFFER_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
