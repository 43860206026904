import { getRegionsByPoliticalDivision } from 'common/api/v1/catalogs';

import {
    FETCH_REGIONS,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_FAILURE,
} from '../actionTypes';
import { actions } from '../reducer';

export default (id, setValue) => (dispatch) => {
    dispatch(actions[FETCH_REGIONS]());

    return new Promise((resolve) => {
        getRegionsByPoliticalDivision({ political_division: id })
            .then(({ data }) => {
                dispatch(actions[FETCH_REGIONS_SUCCESS](data.data));

                if (data?.data?.length > 0 && setValue)
                    setValue('rate_region', data.data[0].id);
            })
            .catch((error) =>
                dispatch(
                    actions[FETCH_REGIONS_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            )
            .finally(resolve);
    });
};
