import { createSlice } from '@reduxjs/toolkit';

import {
    CREATE,
    CREATE_FAILURE,
    CREATE_SUCCESS,
    DELETE,
    DELETE_FAILURE,
    DELETE_SUCCESS,
    FETCH_OFFER_ENERGY_STORAGE,
    FETCH_OFFER_ENERGY_STORAGE_FAILURE,
    FETCH_OFFER_ENERGY_STORAGE_SUCCESS,
    INITIAL_VALUES,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    create: {
        data: {},
        errors: [],
        isCreating: false,
    },
    delete: {
        data: {},
        errors: [],
        isDeleting: false,
    },
    initialValues: {
        expansion: 0,
        items: [],
    },
    offerEnergyBackup: {
        data: [],
        errors: [],
        isFetching: false,
    },
    save: {
        data: {},
        errors: [],
        isSaving: false,
    },
};

const proposalGeneratorEnergyStorageTableSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [CREATE]: (state) => {
            state.create = { ...state.create, errors: [], isCreating: true };
        },
        [CREATE_FAILURE]: (state, action) => {
            state.create = {
                ...state.create,
                errors: action.payload,
                isCreating: false,
            };
        },
        [CREATE_SUCCESS]: (state, action) => {
            state.create = {
                ...state.create,
                data: action.payload,
                isCreating: false,
            };
        },
        [DELETE]: (state) => {
            state.delete = { ...state.delete, errors: [], isDeleting: true };
        },
        [DELETE_FAILURE]: (state, action) => {
            state.delete = {
                ...state.delete,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_SUCCESS]: (state, action) => {
            state.delete = {
                ...state.delete,
                data: action.payload,
                isDeleting: false,
            };
        },
        [FETCH_OFFER_ENERGY_STORAGE]: (state) => {
            state.offerEnergyBackup = {
                ...state.offerEnergyBackup,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_ENERGY_STORAGE_FAILURE]: (state, action) => {
            state.offerEnergyBackup = {
                ...state.offerEnergyBackup,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_ENERGY_STORAGE_SUCCESS]: (state, action) => {
            state.offerEnergyBackup = {
                ...state.offerEnergyBackup,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
    },
});

export const proposalGeneratorEnergyStorageTableActions =
    proposalGeneratorEnergyStorageTableSlice.actions;

export default proposalGeneratorEnergyStorageTableSlice.reducer;
