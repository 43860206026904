import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'consumption_profile';

export const createItem = (data) =>
    API.post(`/api/v2/${ENTITY}/`, data, config);
export const deleteItem = (id) => API.delete(`/api/v2/${ENTITY}/?id=${id}`);
export const getItem = (id) =>
    API.get(`/api/v2/${ENTITY}/`, { params: { id: id } });
export const getItems = () => API.get(`/api/v2/consumptions_profile_list/`);
export const updateItem = (data) => API.put(`/api/v2/${ENTITY}/`, data, config);
