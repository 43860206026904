import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInputConsumption } from 'common/components/form/bootstrap';

import { getSectionHasErrors } from '../../helpers';

import FormAccordion from './FormAccordion';

const DemandFields = ({ control, disabled, errors }) => {
    const fieldsKeys = useWatch({ control, name: 'fields_keys' });
    const { t } = useTranslation();

    if (!fieldsKeys?.demand?.length) return null;

    return (
        <FormAccordion
            defaultExpanded
            hasFormErrors={getSectionHasErrors(errors, ['demand'])}
            title={t('Demand')}
        >
            <Grid container>
                {fieldsKeys.demand.map((key) => (
                    <Grid key={`demand-${key}`} item xs={18} lg>
                        <ReactHookFormIntlNumberInputConsumption
                            append="kW"
                            control={control}
                            decimalsLimit={2}
                            disabled={disabled}
                            fullWidth
                            name={`demand.${key}`}
                            variant="standard"
                        />
                    </Grid>
                ))}
            </Grid>
        </FormAccordion>
    );
};

DemandFields.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
};

export default DemandFields;
