import React from 'react';

import PropTypes from 'prop-types';

import ItemPreviewPage from './ItemPreviewPage';

const SortableItemContent = ({
    handleClickSelect,
    index,
    item,
    pageSize,
    selectedPageId,
}) => {
    if (item.type === 'group') {
        return item.pages.map((groupedPage, groupedIndex) => (
            <ItemPreviewPage
                key={`preview-page-${index + groupedIndex}`}
                handleClickSelect={() => {
                    handleClickSelect(groupedPage.id);
                }}
                page={groupedPage}
                selected={selectedPageId === groupedPage.id}
            />
        ));
    }

    return (
        <ItemPreviewPage
            handleClickSelect={() => {
                handleClickSelect(item.id);
            }}
            page={item}
            pageSize={pageSize}
            selected={selectedPageId === item.id}
        />
    );
};

SortableItemContent.propTypes = {
    handleClickSelect: PropTypes.func,
    index: PropTypes.number,
    item: PropTypes.object,
    pageSize: PropTypes.string,
    selectedPageId: PropTypes.string,
};

export default SortableItemContent;
