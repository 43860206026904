import React, { useEffect } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './actions';
import { Card } from './components';
import * as selectors from './selectors';

const Container = ({
    anualSolarGeneration,
    canModifySolarGeneration,
    canModifySolarGenerationFile,
    initialize,
    irradiationValues,
    isLoading,
    isLocked,
    isSaving,
    offerConfigGeneration,
    proposalId,
    resetForm,
    save,
    systemSize,
}) => {
    useEffect(() => {
        return () => resetForm();
    }, []);

    useEffect(() => {
        if (!isEmpty(offerConfigGeneration) && !isEmpty(irradiationValues)) {
            const generation = offerConfigGeneration.generation || 0;
            const pvSystemDataSource =
                offerConfigGeneration.pv_system_data_source;

            initialize({
                csvSystemSize: offerConfigGeneration.csv_system_size,
                generation,
                kwhValue: irradiationValues.kWhkWValue,
                pvSystemDataSource,
                source: offerConfigGeneration.source,
                sunHoursValue: irradiationValues.sunHoursValue,
                urlFile: offerConfigGeneration.file,
            });
        }
    }, [irradiationValues, irradiationValues, offerConfigGeneration]);

    const onSubmit = (values) => save(proposalId, values);

    return (
        <Card
            anualSolarGeneration={anualSolarGeneration}
            averageIrradiation={
                offerConfigGeneration?.averages?.average_kwhkw || 0
            }
            canModifySolarGeneration={canModifySolarGeneration}
            canModifySolarGenerationFile={canModifySolarGenerationFile}
            hasDesignerGeneration={
                offerConfigGeneration?.has_designer_generation
            }
            isLocked={isLoading || isLocked || isSaving}
            onSubmit={onSubmit}
            proposalId={proposalId}
            sunHours={offerConfigGeneration?.averages?.average_hr || 0}
            systemSize={systemSize}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    initialize: (values) => dispatch(actions.initialValues(values)),
    resetForm: () => dispatch(actions.resetForm()),
    save: (proposalId, values) => dispatch(actions.save(proposalId, values)),
});

Container.propTypes = {
    anualSolarGeneration: PropTypes.number,
    canModifySolarGeneration: PropTypes.bool,
    canModifySolarGenerationFile: PropTypes.bool,
    initialize: PropTypes.func,
    irradiationValues: PropTypes.object,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    offerConfigGeneration: PropTypes.object,
    proposalId: PropTypes.string,
    resetForm: PropTypes.func,
    save: PropTypes.func,
    systemSize: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
