import { NAME } from './constants';

export const BRANCH_OFFICES_DICTIONARY_BACKUP = `${NAME}/BRANCH_OFFICES_DICTIONARY_BACKUP`;

export const FETCH_ADVISORS = `${NAME}/FETCH_ADVISORS`;
export const FETCH_ADVISORS_FAILURE = `${NAME}/FETCH_ADVISORS_FAILURE`;
export const FETCH_ADVISORS_SUCCESS = `${NAME}/FETCH_ADVISORS_SUCCESS`;

export const FETCH_AGENTS = `${NAME}/FETCH_AGENTS`;
export const FETCH_AGENTS_FAILURE = `${NAME}/FETCH_AGENTS_FAILURE`;
export const FETCH_AGENTS_SUCCESS = `${NAME}/FETCH_AGENTS_SUCCESS`;

export const FETCH_CONTACTS = `${NAME}/FETCH_CONTACTS`;
export const FETCH_CONTACTS_FAILURE = `${NAME}/FETCH_CONTACTS_FAILURE`;
export const FETCH_CONTACTS_SUCCESS = `${NAME}/FETCH_CONTACTS_SUCCESS`;

export const FETCH_FINANCIERS = `${NAME}/FETCH_FINANCIERS`;
export const FETCH_FINANCIERS_FAILURE = `${NAME}/FETCH_FINANCIERS_FAILURE`;
export const FETCH_FINANCIERS_SUCCESS = `${NAME}/FETCH_FINANCIERS_SUCCESS`;

export const FETCH_ITEMS = `${NAME}/FETCH_ITEMS`;
export const FETCH_ITEMS_FAILURE = `${NAME}/FETCH_ITEMS_FAILURE`;
export const FETCH_ITEMS_SUCCESS = `${NAME}/FETCH_ITEMS_SUCCESS`;

export const FETCH_NEXT_PAGE = `${NAME}/FETCH_NEXT_PAGE`;
export const FETCH_NEXT_PAGE_FAILURE = `${NAME}/FETCH_NEXT_PAGE_FAILURE`;
export const FETCH_NEXT_PAGE_SUCCESS = `${NAME}/FETCH_NEXT_PAGE_SUCCESS`;

export const RESET_VALUES = `${NAME}/RESET_VALUES`;

export const SELECT_FILTER_ITEM = `${NAME}/SELECT_FILTER_ITEM`;

export const SET_STATUS_DICTIONARY = `${NAME}/SET_STATUS_DICTIONARY`;

export const UPDATE_COLUMNS = `${NAME}/UPDATE_COLUMNS`;
export const UPDATE_COLUMNS_FAILURE = `${NAME}/UPDATE_COLUMNS_FAILURE`;
export const UPDATE_COLUMNS_SUCCESS = `${NAME}/UPDATE_COLUMNS_SUCCESS`;
