import { NAME } from './constants';

export const BRANCH_OFFICES_DICTIONARY_BACKUP = `${NAME}/BRANCH_OFFICES_DICTIONARY_BACKUP`;

export const FETCH_FUNNEL_PROJECTS = `${NAME}/FETCH_FUNNEL_PROJECTS`;
export const FETCH_FUNNEL_PROJECTS_FAILURE = `${NAME}/FETCH_FUNNEL_PROJECTS_FAILURE`;
export const FETCH_FUNNEL_PROJECTS_SUCCESS = `${NAME}/FETCH_FUNNEL_PROJECTS_SUCCESS`;

export const FETCH_NEXT_PAGE = `${NAME}/FETCH_NEXT_PAGE`;
export const FETCH_NEXT_PAGE_FAILURE = `${NAME}/FETCH_NEXT_PAGE_FAILURE`;
export const FETCH_NEXT_PAGE_SUCCESS = `${NAME}/FETCH_NEXT_PAGE_SUCCESS`;

export const UPDATE_COLUMNS = `${NAME}/UPDATE_COLUMNS`;
export const UPDATE_COLUMNS_FAILURE = `${NAME}/UPDATE_COLUMNS_FAILURE`;
export const UPDATE_COLUMNS_SUCCESS = `${NAME}/UPDATE_COLUMNS_SUCCESS`;
