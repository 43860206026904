import { get, isEmpty, isNull } from 'lodash';
import { createSelector } from 'reselect';

import {
    DEFAULT_CURRENCY,
    HOURS_IN_DAY,
    HOURS_IN_YEAR,
} from 'common/constants';
import { getCompensationSchemeByBackendKey } from 'common/utils/helpers/rates';
import { getDataProfileFormattedByPeriods } from 'common/utils/helpersChart';

import {
    getKWhkWValue,
    getSunHoursValue,
} from '../proposalGeneratorAdvancedGenerationSettings/helpers';
import { getConsumptionHistorySorted } from '../proposalGeneratorConsumptionAnalysisVsGeneration/helpers';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getIsOpenModal = createSelector(getModel, (model) => model.isOpen);

export const getIsLocked = createSelector(getModel, (model) => model.isLocked);

/** OFFER DETAILS */
export const getOfferDetails = createSelector(
    getModel,
    (model) => model.offerDetails,
);

export const getIsFetchingOfferDetails = createSelector(
    getOfferDetails,
    (value) => value.isFetching,
);

export const getOfferDetailsData = createSelector(
    getOfferDetails,
    (value) => value.data,
);

export const getOfferDetailsErrors = createSelector(
    getOfferDetails,
    (value) => value.errors,
);

export const getOfferDetailsConsumption = createSelector(
    getOfferDetailsData,
    (values) => values.value_consumption || {},
);

export const getOfferDetailsPattern = createSelector(
    getOfferDetailsData,
    (data) => data.info_pattern || {},
);

export const getOfferDetailsBranchOffice = createSelector(
    getOfferDetailsData,
    (data) => data.branch_office || {},
);

export const getOfferDetailsCommercialOffer = createSelector(
    getOfferDetailsData,
    (data) => data.commercial_offer || {},
);

export const getOfferDetailsLocation = createSelector(
    getOfferDetailsData,
    (data) => data.location || {},
);

export const getOfferDetailsComplements = createSelector(
    getOfferDetailsData,
    (data) => data.complements_offer || {},
);

export const getOfferCurrency = createSelector(
    getOfferDetailsComplements,
    (data) => get(data, 'currency', DEFAULT_CURRENCY),
);

export const getIsZeroExportActive = createSelector(
    getOfferDetailsComplements,
    (data) => data.zero_export || false,
);

export const getIsLinkedToIntelligentProject = createSelector(
    getOfferDetailsData,
    (data) => data.is_linked_to_iproject,
);

export const getHasApproved = createSelector(
    getOfferDetailsData,
    (data) => data.has_approbed,
);

export const getHasSmartDocuments = createSelector(
    getOfferDetailsData,
    (data) => data.has_smart_documents,
);

export const getOfferDetailsSunpay = createSelector(
    getOfferDetailsData,
    (data) => data.sunpay || {},
);

export const getFinancierInstaller = createSelector(
    getOfferDetailsSunpay,
    (data) => data?.financier_installer,
);

export const getCreditOfferStatus = createSelector(
    getOfferDetailsSunpay,
    (data) => get(data, 'credit_offer_status', null),
);

export const getFinancialProductParameters = createSelector(
    getOfferDetailsSunpay,
    (data) => get(data, 'financial_product_parameters', {}),
);

export const getInfonavitData = createSelector(
    getFinancialProductParameters,
    (data) => get(data, 'infonavit_data', '{}'),
);

/* OFFER CONSUMPTION AND GENERATION */

export const getOfferConsumptionAndGeneration = createSelector(
    getModel,
    (model) => model.offerConsumptionAndGeneration,
);

export const getIsFetchingConsumptionAndGeneration = createSelector(
    getOfferConsumptionAndGeneration,
    (value) => value.isFetching,
);

export const getOfferConsumptionAndGenerationData = createSelector(
    getOfferConsumptionAndGeneration,
    (value) => value.data || {},
);

export const getOfferConsumptionAndGenerationErrors = createSelector(
    getOfferConsumptionAndGeneration,
    (value) => value.errors,
);

export const getCustomerEnergyData = createSelector(
    getOfferConsumptionAndGenerationData,
    (values) => values.customer_energy || {},
);

export const getConsumptionHistory = createSelector(
    getOfferConsumptionAndGenerationData,
    (values) => values.consumption_history || {},
);

export const getProposalConfiguration = createSelector(
    getOfferConsumptionAndGenerationData,
    (values) => values.proposal_configuration || {},
);

export const getProposalTiersConsumptions = createSelector(
    getOfferConsumptionAndGenerationData,
    (values) => values.tiers_consumptions || [],
);

export const getOfferData = createSelector(
    getOfferConsumptionAndGenerationData,
    (values) => values.data_offer || { rate_change: 0 },
);

export const getRateChange = createSelector(
    getOfferData,
    (values) => values.rate_change,
);

export const getDataOfferConfigGeneration = createSelector(
    getOfferConsumptionAndGenerationData,
    (values) => values.offer_generation_settings || {},
);

export const getDataOfferIrradiationValues = createSelector(
    getDataOfferConfigGeneration,
    (data) => {
        const generation = data?.generation;
        const pvSystemDataSource = data?.pv_system_data_source;
        const sunHoursSegments = data?.irradiation_segments_h_sun;
        const averageIrradiation = data?.averages?.average_kwhkw;
        const baseSunHours = data?.averages?.average_hr;

        const sunHoursValue = getSunHoursValue({
            generation,
            pvSystemDataSource,
            sunHours: baseSunHours,
            sunHoursSegments,
        });

        const kWhkWValue = getKWhkWValue({
            averageIrradiation,
            generation,
            sunHoursSegments,
            pvSystemDataSource,
        });

        return { kWhkWValue, sunHoursValue };
    },
);

export const getDataOfferConfigConsumption = createSelector(
    getOfferConsumptionAndGenerationData,
    (values) => values.offer_consumption_settings || {},
);

export const getDataOfferTimeShifting = createSelector(
    getOfferConsumptionAndGenerationData,
    (values) => values.time_shifting || {},
);

export const getNextRateData = createSelector(
    getProposalConfiguration,
    (values) => {
        if (isNull(values.next_rate_object) && !isNull(values.subsidy_rate))
            return values.subsidy_rate;
        if (
            isNull(values.next_rate_object) &&
            !isNull(values.previous_rate_object)
        )
            return values.previous_rate_object;
        return values.next_rate_object;
    },
);

export const getCompensationScheme = createSelector(
    getProposalConfiguration,
    getNextRateData,
    (proposalConfiguration, nextRateData) => {
        if (
            !proposalConfiguration?.compensation_scheme ||
            isEmpty(nextRateData)
        )
            return {};

        return getCompensationSchemeByBackendKey({
            backendKey: proposalConfiguration?.compensation_scheme,
            certified: nextRateData?.certified,
            name: nextRateData?.name,
            paymentType: nextRateData?.payment_type,
        });
    },
);

/** offer profiles */

export const getOfferProfiles = createSelector(
    getModel,
    (model) => model.offerProfiles,
);

export const getOfferProfilesIsFetching = createSelector(
    getOfferProfiles,
    (value) => value.isFetching,
);

export const getOfferProfilesData = createSelector(
    getOfferProfiles,
    (value) => value.data,
);

export const getHasOfferProfiles = createSelector(
    getOfferProfilesData,
    (data) => data?.consumption?.length > 0,
);

export const getOfferProfilesErrors = createSelector(
    getOfferProfiles,
    (value) => value.errors,
);

export const getConsumptionProjectionData = createSelector(
    getIsFetchingConsumptionAndGeneration,
    getIsFetchingOfferDetails,
    getOfferProfilesIsFetching,
    getConsumptionHistory,
    getCustomerEnergyData,
    getDataOfferTimeShifting,
    getOfferDetailsComplements,
    getOfferProfilesData,
    getDataOfferConfigGeneration,
    (
        isFetchingConsumptionAndGeneration,
        isFetchingOfferDetails,
        isFetchingOfferProfiles,
        consumptionHistory,
        customerEnergyData,
        dataTimeShifting,
        complements,
        profilesData,
        offerConfigGeneration,
    ) => {
        if (
            isFetchingConsumptionAndGeneration ||
            isFetchingOfferDetails ||
            isFetchingOfferProfiles ||
            isEmpty(consumptionHistory) ||
            isEmpty(complements)
        )
            return {};

        const sortedConsumptionHistory = getConsumptionHistorySorted(
            customerEnergyData,
            consumptionHistory,
        );

        if (isEmpty(profilesData))
            return { consumptionHistory: sortedConsumptionHistory };

        const { firstDay, pathByHourDictionary, profileFormattedByPeriods } =
            getDataProfileFormattedByPeriods({
                summary: sortedConsumptionHistory[0],
                profile: [...Array(HOURS_IN_YEAR).keys()],
            });

        return {
            consumptionHistory: sortedConsumptionHistory,
            consumptionProfile: profilesData.consumption,
            dataTimeShifting,
            demandArray: profilesData.demand,
            firstDay,
            generationDelay: complements?.generation_delay,
            generationProfile: profilesData.generation,
            hourlyBatteryStatus: profilesData.hourly_battery_status,
            isBimonthly: customerEnergyData?.is_bimonthly,
            panelDegradation: complements?.degradation_percentage,
            pathByHourDictionary,
            pvSystemDataSource: offerConfigGeneration.pv_system_data_source,
            realIndex: profileFormattedByPeriods,
            zeroExport: complements?.zero_export || false,
        };
    },
);

export const getMaxDailyConsumption = createSelector(
    getOfferProfilesData,
    getOfferDetailsConsumption,
    (profilesData, detailsConsumption) => {
        if (!profilesData?.consumption?.length) return 0;
        let max = 0;
        let sum = 0;

        for (let index = 0; index < profilesData.consumption.length; index++) {
            sum += profilesData.consumption[index];

            const isEndOfDay = (index + 1) % HOURS_IN_DAY === 0;
            const isLastIndex = index + 1 === profilesData.consumption.length;

            if (!isEndOfDay && !isLastIndex) continue;
            if (sum > max) max = sum;
            sum = 0;
        }

        return detailsConsumption?.original_consumption * (max / 100) || 0;
    },
);

export const getSolarSimulationData = createSelector(
    getModel,
    (model) => model.solarSimulationData,
);

export const getTotalsSolarSimulation = createSelector(
    getSolarSimulationData,
    (data) => data.totalsYearOne,
);

export const getPrepareEditModel = createSelector(
    getModel,
    (model) => model.prepareEdit,
);

export const getPrepareEditIsFetching = createSelector(
    getPrepareEditModel,
    (model) => model.isFetching,
);
