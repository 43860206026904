import React from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Alert, Box, Button, Typography } from 'sunwise-ui';

import { ReactHookFormInput } from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import validate from '../validate';

const ResetPasswordForm = ({
    errors,
    initialValues,
    isLoadingSession,
    onChangePassword,
    onChangePasswordConfirmation,
    onSubmit,
    showRedirectLogin,
}) => {
    const { t } = useTranslation();

    const { control, getValues, handleSubmit, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    if (showRedirectLogin) {
        return (
            <Box textAlign="center">
                <Typography variant="h4">
                    {t('Your password has been reset')}.{' '}
                </Typography>
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Button
                        component={RouterLink}
                        fullWidth
                        startIcon={<AccountCircleIcon />}
                        to="/login"
                        variant="text"
                        sx={{ textTransform: 'none' }}
                    >
                        {t('Log in')}
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ReactHookFormInput
                control={control}
                name="password"
                onChange={(e) => {
                    setValue('password', e.target.value);
                    onChangePassword(e);
                }}
                label={t('New password')}
                type="password"
            />

            <ReactHookFormInput
                control={control}
                name="confirm_password"
                onChange={(e) => {
                    setValue('confirm_password', e.target.value);
                    onChangePasswordConfirmation(e, getValues());
                }}
                onFocus={(e) => onChangePasswordConfirmation(e, getValues())}
                label={t('Confirm password')}
                type="password"
            />

            {errors &&
                errors.map((error, i) => (
                    <Alert severity="warning" key={`error-reset-${i}`}>
                        * {error}
                    </Alert>
                ))}

            <Box sx={{ my: 2, textAlign: 'center' }}>
                <Button disabled={isLoadingSession} fullWidth type="submit">
                    {isLoadingSession
                        ? t('Loading').concat('...')
                        : t('Restore password').toUpperCase()}
                </Button>
            </Box>
        </form>
    );
};

ResetPasswordForm.propTypes = {
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isLoadingSession: PropTypes.bool,
    onChangePassword: PropTypes.func,
    onChangePasswordConfirmation: PropTypes.func,
    onSubmit: PropTypes.func,
    showRedirectLogin: PropTypes.bool,
};

export default ResetPasswordForm;
