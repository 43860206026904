import { NAME } from './constants';

export const CHANGE_MODULE_STATUS = `${NAME}/CHANGE_MODULE_STATUS`;
export const CHANGE_MODULE_STATUS_SUCCESS = `${NAME}/CHANGE_MODULE_STATUS_SUCCESS`;
export const CHANGE_MODULE_STATUS_FAILURE = `${NAME}/CHANGE_MODULE_STATUS_FAILURE`;

export const CHECK_SEGMENT = `${NAME}/CHECK_SEGMENT`;
export const CHECK_SEGMENT_FAILURE = `${NAME}/CHECK_SEGMENT_FAILURE`;
export const CHECK_SEGMENT_SUCCESS = `${NAME}/CHECK_SEGMENT_SUCCESS`;

export const CREATE_SEGMENT = `${NAME}/CREATE_SEGMENT`;
export const CREATE_SEGMENT_FAILURE = `${NAME}/CREATE_SEGMENT_FAILURE`;
export const CREATE_SEGMENT_SUCCESS = `${NAME}/CREATE_SEGMENT_SUCCESS`;

export const DELETE_SEGMENT = `${NAME}/DELETE_SEGMENT`;
export const DELETE_SEGMENT_FAILURE = `${NAME}/DELETE_SEGMENT_FAILURE`;
export const DELETE_SEGMENT_SUCCESS = `${NAME}/DELETE_SEGMENT_SUCCESS`;

export const FETCH_IRRADIATION_DATA = `${NAME}/FETCH_IRRADIATION_DATA`;
export const FETCH_IRRADIATION_DATA_FAILURE = `${NAME}/FETCH_IRRADIATION_DATA_FAILURE`;
export const FETCH_IRRADIATION_DATA_SUCCESS = `${NAME}/FETCH_IRRADIATION_DATA_SUCCESS`;

export const FETCH_OFFER_INFO_PATERN = `${NAME}/FETCH_OFFER_INFO_PATERN`;
export const FETCH_OFFER_INFO_PATERN_FAILURE = `${NAME}/FETCH_OFFER_INFO_PATERN_FAILURE`;
export const FETCH_OFFER_INFO_PATERN_SUCCESS = `${NAME}/FETCH_OFFER_INFO_PATERN_SUCCESS`;

export const FETCH_OFFER_PANELS = `${NAME}/FETCH_OFFER_PANELS`;
export const FETCH_OFFER_PANELS_FAILURE = `${NAME}/FETCH_OFFER_PANELS_FAILURE`;
export const FETCH_OFFER_PANELS_SUCCESS = `${NAME}/FETCH_OFFER_PANELS_SUCCESS`;

export const FETCH_PARENT_FIELD_SEGMENT = `${NAME}/FETCH_SEGMENTS`;
export const FETCH_PARENT_FIELD_SEGMENT_FAILURE = `${NAME}/FETCH_PARENT_FIELD_SEGMENT_FAILURE`;
export const FETCH_PARENT_FIELD_SEGMENT_SUCCESS = `${NAME}/FETCH_PARENT_FIELD_SEGMENT_SUCCESS`;

export const INITIALIZE_VALUES = `${NAME}/INITIALIZE_VALUES`;
export const INITIALIZE_IRRADIATION_VALUES = `${NAME}/INITIALIZE_IRRADIATION_VALUES`;

export const INITIALIZE_SHADER_SETTINGS_VALUES = `${NAME}/INITIALIZE_SHADER_SETTINGS_VALUES`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const RESET_VALUES = `${NAME}/RESET_VALUES`;

export const SAVE_ALL_SEGMENTS = `${NAME}/SAVE_ALL_SEGMENTS`;
export const SAVE_ALL_SEGMENTS_FAILURE = `${NAME}/SAVE_ALL_SEGMENTS_FAILURE`;
export const SAVE_ALL_SEGMENTS_SUCCESS = `${NAME}/SAVE_ALL_SEGMENTS_SUCCESS`;

export const SAVE_IMAGE = `${NAME}/SAVE_IMAGE`;
export const SAVE_IMAGE_FAILURE = `${NAME}/SAVE_IMAGE_FAILURE`;
export const SAVE_IMAGE_SUCCESS = `${NAME}/SAVE_IMAGE_SUCCESS`;

export const SAVE_SHADER_SETTINGS = `${NAME}/SAVE_SHADER_SETTINGS`;
export const SAVE_SHADER_SETTINGS_FAILURE = `${NAME}/SAVE_SHADER_SETTINGS_FAILURE`;
export const SAVE_SHADER_SETTINGS_SUCCESS = `${NAME}/SAVE_SHADER_SETTINGS_SUCCESS`;

export const SAVE_POSITION = `${NAME}/SAVE_POSITION`;
export const SAVE_POSITION_FAILURE = `${NAME}/SAVE_POSITION_FAILURE`;
export const SAVE_POSITION_SUCCESS = `${NAME}/SAVE_POSITION_SUCCESS`;

export const SELECT_SEGMENT = `${NAME}/SELECT_SEGMENT`;
export const SETUP_SIMULATION = `${NAME}/SETUP_SIMULATION`;
export const SET_COPY_BUFFER = `${NAME}/SET_COPY_BUFFER`;
export const SET_DRAWING_MODE = `${NAME}/SET_DRAWING_MODE`;
export const SET_DRAWING_TYPE = `${NAME}/SET_DRAWING_TYPE`;
export const SET_IS_DISABLING_MODULES = `${NAME}/SET_IS_DISABLING_MODULES`;
export const SET_SEGMENT_FILL_METHOD = `${NAME}/SET_SEGMENT_FILL_METHOD`;
export const SET_SELECTED_ITEMS = `${NAME}/SET_SELECTED_ITEMS`;

export const UPDATE_SEGMENT = `${NAME}/UPDATE_SEGMENT`;
export const UPDATE_SEGMENT_FAILURE = `${NAME}/UPDATE_SEGMENT_FAILURE`;
export const UPDATE_SEGMENT_SUCCESS = `${NAME}/UPDATE_SEGMENT_SUCCESS`;

export const UPDATE_SEGMENTS = `${NAME}/UPDATE_SEGMENTS`;
export const UPDATE_SOLAR_MODULES_DESIGN_NUMBER = `${NAME}/UPDATE_SOLAR_MODULES_DESIGN_NUMBER`;
