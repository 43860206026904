import React from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BoltIcon from '@mui/icons-material/Bolt';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SpeedIcon from '@mui/icons-material/Speed';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import {
    IconPanel,
    IconPanelSystem,
    IconGridConsumption,
    IconSelfConsumption,
    IconExportedGeneration,
    IconSaveMoney,
} from 'common/components/icons';
import KeyIndicator from 'common/components/KeyIndicator';

import { INDICATORS_NAMES } from '../../constants';
import { getIndicatorValue } from '../../helpers';

const propsConfig = {
    accumulative_generation: {
        isNumber: true,
        svgIcon: <IconPanel />,
        unit: 'kWh',
    },
    accumulative_savings: {
        isCurrency: true,
        isNumber: true,
        svgIcon: <IconSaveMoney />,
    },
    actual_consumption: {
        isNumber: true,
        svgIcon: <BoltIcon />,
        unit: 'kWh',
    },
    actual_exported_energy: {
        isNumber: true,
        svgIcon: <IconExportedGeneration />,
        unit: 'kWh',
    },
    actual_generation: {
        isNumber: true,
        svgIcon: <IconPanel />,
        unit: 'kWh',
    },
    actual_grid_consumption: {
        isNumber: true,
        propColor: true,
        svgIcon: IconGridConsumption,
        unit: 'kWh',
    },
    actual_savings: {
        isNumber: true,
        svgIcon: <IconSaveMoney />,
    },
    actual_self_consumption: {
        isNumber: true,
        svgIcon: <IconSelfConsumption />,
        unit: 'kWh',
    },
    comparison_consumption: {
        isNumber: true,
        svgIcon: <BoltIcon />,
        unit: 'kWh',
    },
    comparison_generation: {
        isNumber: true,
        svgIcon: <IconPanel />,
        unit: 'kWh',
    },
    comparison_price: {
        isCurrency: true,
        isNumber: true,
        svgIcon: <AttachMoneyIcon />,
    },
    generation_precision: {
        isNumber: true,
        svgIcon: <SpeedIcon />,
        unit: '%',
    },
    rate: { svgIcon: <SettingsInputComponentIcon /> },
    sun_hrs: {
        isNumber: true,
        svgIcon: <SolarPowerIcon />,
        unit: 'h',
    },
    system_size: {
        isNumber: true,
        svgIcon: <IconPanelSystem />,
        unit: 'kW',
    },
    with_solar_price: {
        isCurrency: true,
        isNumber: true,
        svgIcon: <AttachMoneyIcon />,
    },
    without_solar_price: {
        isCurrency: true,
        isNumber: true,
        svgIcon: <AttachMoneyIcon />,
    },
};

const Indicator = ({
    currencyIso = 'USD',
    currencyLocale = 'es-US',
    indicatorKey,
    showIndicator,
    value,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const propConfig = propsConfig[indicatorKey];

    if (!showIndicator || !propConfig) return null;

    const indicatorValue = getIndicatorValue({
        currencyIso,
        currencyLocale,
        isCurrency: propConfig.isCurrency,
        isNumber: propConfig.isNumber,
        value: indicatorKey === 'system_size' ? value / 1000 : value,
    });

    return (
        <Grid item xs={18} mb={1}>
            <KeyIndicator
                svgIcon={
                    propConfig.propColor ? (
                        <propConfig.svgIcon
                            color={theme?.palette?.primary?.contrastText}
                        />
                    ) : (
                        propConfig.svgIcon
                    )
                }
                title={t(INDICATORS_NAMES[indicatorKey])}
                unit={indicatorValue !== null ? propConfig.unit : null}
                value={indicatorValue ?? '--'}
            />
        </Grid>
    );
};

Indicator.defaultProps = { value: 0 };

Indicator.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    indicatorKey: PropTypes.string,
    showIndicator: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Indicator;
