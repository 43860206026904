import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (_, { fields }) => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        ...(fields?.reduce((acc, field) => {
            acc[field] = string().required(REQUIRED_TEXT);
            return acc;
        }, {}) ?? {}),
    });
};
