import fetchBillingInformation from './fetchBillingInformation';
import fetchPaymentSources from './fetchPaymentSources';
import fetchSubscription from './fetchSubscription';

export default () => (dispatch) => {
    Promise.all([
        dispatch(fetchBillingInformation()),
        dispatch(fetchSubscription()),
        dispatch(fetchPaymentSources()),
    ])
        .then(() => {})
        .catch(() => {});
};
