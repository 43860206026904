import { getMemberRoles } from 'common/api/v1/onboarding';

import {
    FETCH_MEMBER_ROLES,
    FETCH_MEMBER_ROLES_FAILURE,
    FETCH_MEMBER_ROLES_SUCCESS,
} from '../actionTypes';
import { onboardingWizardActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(onboardingWizardActions[FETCH_MEMBER_ROLES]());

    getMemberRoles()
        .then((response) => {
            dispatch(
                onboardingWizardActions[FETCH_MEMBER_ROLES_SUCCESS](
                    response.data,
                ),
            );
        })
        .catch((error) => {
            dispatch(
                onboardingWizardActions[FETCH_MEMBER_ROLES_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
        });
};
