import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import {
    ReactHookFormDragnDropFile,
    ReactHookFormInput,
} from 'common/components/form/bootstrap';
import { FILE_ALLOWED_EXTENSIONS, MAX_SMALL_FILE_SIZE } from 'common/constants';

import CompanyPlaceholder from 'resources/onboarding/company.svg';

const CompanyStep = ({ control, formValues, setValue }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    py: 1,
                    width: '100%',
                }}
            >
                <img src={CompanyPlaceholder} />
                <Typography variant="h6">
                    {t('Tell us about your company')}
                </Typography>
                <Typography variant="body2">
                    {t(
                        'Basic information to help you identify yourself to your competitors',
                    )}
                </Typography>
            </Box>
            <Grid container mt={2}>
                <Grid item xs={18} md={10} lg={12}>
                    <Grid container>
                        <Grid item xs={18}>
                            <ReactHookFormInput
                                control={control}
                                label={t('Tradename').concat('*')}
                                name="name"
                                placeholder={t('Tradename')}
                            />

                            <ReactHookFormInput
                                control={control}
                                label={t('Business name').concat('*')}
                                name="business_name"
                                placeholder={t('Business name')}
                            />

                            <ReactHookFormInput
                                control={control}
                                label={t('Tax ID').concat('*')}
                                name="rfc"
                                placeholder={t('Tax ID')}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={18}
                            md={18}
                            lg={9}
                            sx={{ pt: '0 !important' }}
                        >
                            <ReactHookFormInput
                                control={control}
                                label={t('Email contact company').concat('*')}
                                name="contact_email"
                                placeholder={t('Email contact company')}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={18}
                            md={18}
                            lg={9}
                            sx={{ pt: '0 !important' }}
                        >
                            <ReactHookFormInput
                                control={control}
                                label={t('Phone number company').concat('*')}
                                name="contact_telephone"
                                placeholder={t('Phone number company')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={18} md={8} lg={6}>
                    <ReactHookFormDragnDropFile
                        accept={FILE_ALLOWED_EXTENSIONS.IMAGE}
                        avatarData={{
                            image: formValues?.image,
                            name: formValues?.name,
                        }}
                        control={control}
                        formats="jpg, jpeg, png"
                        id="onboarding_company_image"
                        maxSize={MAX_SMALL_FILE_SIZE}
                        name="image"
                        setValue={setValue}
                        variant="profile"
                    />
                </Grid>
            </Grid>
        </>
    );
};

CompanyStep.propTypes = {
    control: PropTypes.object,
    formValues: PropTypes.object,
    setValue: PropTypes.func,
};

export default CompanyStep;
