import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_VERSION,
    FETCH_VERSION_FAILURE,
    FETCH_VERSION_SUCCESS,
    SET_NEW_VERSION_AVAILABLE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchVersion: {
        data: {},
        errors: [],
        isFetching: false,
    },
    newVersionAvailable: false,
};

const releaseNotificationSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_VERSION]: (state) => {
            state.fetchVersion = {
                ...state.fetchVersion,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_VERSION_FAILURE]: (state, action) => {
            state.fetchVersion = {
                ...state.fetchVersion,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_VERSION_SUCCESS]: (state, action) => {
            state.fetchVersion = {
                ...state.fetchVersion,
                data: action.payload,
                isFetching: false,
            };
        },
        [SET_NEW_VERSION_AVAILABLE]: (state, action) => {
            state.newVersionAvailable = action.payload;
        },
    },
});

export const releaseNotificationActions = releaseNotificationSlice.actions;

export default releaseNotificationSlice.reducer;
