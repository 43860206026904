import React, { useState } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from 'sunwise-ui';

const DropdownCustomCardMenuMap = ({
    canModify,
    handleSelected,
    selected,
    statusToSelect,
}) => {
    const { t } = useTranslation();
    const label =
        selected && selected.value ? selected.value : t('Select status');

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <Button
                color="secondary"
                disabled={!canModify}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}
                variant="outlined"
                startIcon={
                    <CircleIcon
                        sx={{
                            color: selected.color,
                            height: '11px',
                            width: '11px',
                        }}
                    />
                }
                sx={{
                    borderRadius: 0,
                    border: 0,
                    borderBottom: '1px solid',
                    justifyContent: 'start',
                    '.MuiButton-endIcon': { ml: 'auto' },
                    width: '100%',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        border: 0,
                        borderBottom: '1px solid',
                    },
                }}
            >
                <Tooltip title={label}>
                    <Typography
                        component="span"
                        fontWeight="bold"
                        noWrap
                        sx={{
                            overflow: 'hidden',
                            textAlign: 'left',
                            textOverflow: 'ellipsis',
                            width: '85%',
                        }}
                        variant="body2"
                    >
                        {label}
                    </Typography>
                </Tooltip>
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ minWidth: '100%' }}
            >
                {statusToSelect.map((status, index) => {
                    return (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                handleSelected(status);
                                handleClose();
                            }}
                            selected={status.value === selected.value}
                        >
                            <ListItemIcon>
                                <CircleIcon
                                    sx={{
                                        color: status.color,
                                        height: '11px',
                                        width: '11px',
                                    }}
                                />
                            </ListItemIcon>
                            <Typography variant="body2">
                                {status.label}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

DropdownCustomCardMenuMap.propTypes = {
    canModify: PropTypes.bool,
    handleSelected: PropTypes.func,
    selected: PropTypes.object,
    statusToSelect: PropTypes.array,
};

export default DropdownCustomCardMenuMap;
