import { getOppositeCurrency } from 'common/utils/helpers';
import { getCompensationSchemeByBackendKey } from 'common/utils/helpers/rates';

import * as multiCurrencySelectors from '../../multiCurrency/selectors';
import { getHourlyContractedDemandFromValues } from '../helpers';
import * as selectors from '../selectors';

import fetchScheduleRateConfiguration from './fetchScheduleRateConfiguration';
import initializeForm from './initializeForm';
import setModalFormOpenStatus from './setModalFormOpenStatus';

export default () => (dispatch, getState) => {
    const state = getState();

    const customerConsumption = selectors.getCustomerConsumptionData(state);
    const currencies = multiCurrencySelectors.getDataFetchCurrencies(state);
    const oppositeCurrency = getOppositeCurrency(currencies);

    const {
        contrated_demand,
        default_certified_rate_scheme_compensation,
        default_custom_rate_scheme_compensation,
        is_bimonthly,
        political_division1,
        political_division2,
        rate,
        rate_division,
        rate_division_summer,
        service_number,
        subsidy_rate,
        tiers_consumptions,
        zip_code,
    } = customerConsumption || {};

    const compensationScheme = getCompensationSchemeByBackendKey({
        backendKey:
            default_certified_rate_scheme_compensation ||
            default_custom_rate_scheme_compensation,
        certified: rate?.certified,
        name: rate?.name,
        paymentType: rate?.payment_type,
    });
    const newValues = {
        compensation_scheme: compensationScheme?.value,
        contracted_demand: contrated_demand || null,
        is_bimonthly: is_bimonthly ? '1' : '0',
        political_division1: political_division1?.id || '',
        political_division2: political_division2?.id || '',
        rate: rate?.id || '',
        rate_division: rate_division?.id || '',
        rate_division_summer: rate_division_summer?.id || '',
        service_number: service_number || '',
        source: '0',
        subsidy_rate: subsidy_rate?.name || '',
        type_change: oppositeCurrency?.dollar_price ?? null,
        zip_code: zip_code || '',
    };

    const promises = [];

    if (newValues?.rate)
        promises.push(
            dispatch(
                fetchScheduleRateConfiguration({
                    certified: rate?.certified,
                    rate: newValues?.rate,
                    rate_division: newValues?.rate_division,
                }),
            ),
        );

    Promise.all(promises).then((scheduleRateConfiguration) => {
        const demandFields = getHourlyContractedDemandFromValues(
            rate?.certified,
            scheduleRateConfiguration?.[0]?.tiers_demand_distribution?.[0]
                ?.tiers,
            tiers_consumptions?.[0]?.contracted_demand?.map((demand) => ({
                tier: demand?.tier,
                value: demand?.consumption,
            })),
        );

        dispatch(initializeForm({ ...newValues, ...demandFields }));
        dispatch(setModalFormOpenStatus(true));
    });
};
