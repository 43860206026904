import { fetchPriceData } from 'common/api/v1/historical';
import { parseDate } from 'common/utils/dates';

import {
    FETCH_HISTORICAL_INFO,
    FETCH_HISTORICAL_INFO_FAILURE,
    FETCH_HISTORICAL_INFO_SUCCESS,
} from '../actionTypes';
import { DEFAULT_DATE_FORMAT } from '../constants';
import { actions } from '../reducer';

export default (reportId, projectId) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(actions[FETCH_HISTORICAL_INFO]());

        fetchPriceData(projectId, reportId)
            .then((response) => {
                const data = response?.data?.data || [];

                const sortedData = [...data].sort(
                    (a, b) =>
                        parseDate(b?.initial_date, DEFAULT_DATE_FORMAT) -
                        parseDate(a?.initial_date, DEFAULT_DATE_FORMAT),
                );
                dispatch(actions[FETCH_HISTORICAL_INFO_SUCCESS](sortedData));
            })
            .catch((error) =>
                dispatch(actions[FETCH_HISTORICAL_INFO_FAILURE](error)),
            )
            .finally(resolve);
    });
