import { getPositions } from 'common/api/v1/contactPositions';

import {
    FETCH_LOCATION,
    FETCH_LOCATION_ERROR,
    FETCH_LOCATION_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (contactId) => (dispatch) => {
    dispatch(actions[FETCH_LOCATION]());
    getPositions(contactId)
        .then((response) => {
            const data = response.data ? response.data : {};
            dispatch(actions[FETCH_LOCATION_SUCCESS](data));
        })
        .catch((error) => dispatch(actions[FETCH_LOCATION_ERROR](error)));
};
