import { NAME } from './constants';

export const EDIT_REPORT = `${NAME}/EDIT_REPORT`;
export const EDIT_REPORT_FAILURE = `${NAME}/EDIT_REPORT_FAILURE`;
export const EDIT_REPORT_SUCCESS = `${NAME}/EDIT_REPORT_SUCCESS`;

export const FETCH_HISTORICAL_INFO = `${NAME}/FETCH_HISTORICAL_INFO`;
export const FETCH_HISTORICAL_INFO_FAILURE = `${NAME}/FETCH_HISTORICAL_INFO_FAILURE`;
export const FETCH_HISTORICAL_INFO_SUCCESS = `${NAME}/FETCH_HISTORICAL_INFO_SUCCESS`;

export const FETCH_INDICATORS_SETTINGS = `${NAME}/FETCH_INDICATORS_SETTINGS`;
export const FETCH_INDICATORS_SETTINGS_FAILURE = `${NAME}/FETCH_INDICATORS_SETTINGS_FAILURE`;
export const FETCH_INDICATORS_SETTINGS_SUCCESS = `${NAME}/FETCH_INDICATORS_SETTINGS_SUCCESS`;

export const FETCH_PERIOD_SETTINGS = `${NAME}/FETCH_PERIOD_SETTINGS`;
export const FETCH_PERIOD_SETTINGS_FAILURE = `${NAME}/FETCH_PERIOD_SETTINGS_FAILURE`;
export const FETCH_PERIOD_SETTINGS_SUCCESS = `${NAME}/FETCH_PERIOD_SETTINGS_SUCCESS`;

export const FETCH_PROJECT_CONFIGURATION = `${NAME}/FETCH_PROJECT_CONFIGURATION`;
export const FETCH_PROJECT_CONFIGURATION_FAILURE = `${NAME}/FETCH_PROJECT_CONFIGURATION_FAILURE`;
export const FETCH_PROJECT_CONFIGURATION_SUCCESS = `${NAME}/FETCH_PROJECT_CONFIGURATION_SUCCESS`;

export const FETCH_REPORT_COMPLEMENTS = `${NAME}/FETCH_REPORT_COMPLEMENTS`;
export const FETCH_REPORT_COMPLEMENTS_FAILURE = `${NAME}/FETCH_REPORT_COMPLEMENTS_FAILURE`;
export const FETCH_REPORT_COMPLEMENTS_SUCCESS = `${NAME}/FETCH_REPORT_COMPLEMENTS_SUCCESS`;

export const FETCH_REPORT_DETAILS = `${NAME}/FETCH_REPORT_DETAILS`;
export const FETCH_REPORT_DETAILS_FAILURE = `${NAME}/FETCH_REPORT_DETAILS_FAILURE`;
export const FETCH_REPORT_DETAILS_SUCCESS = `${NAME}/FETCH_REPORT_DETAILS_SUCCESS`;

export const FETCH_TEMPLATES = `${NAME}/FETCH_TEMPLATES`;
export const FETCH_TEMPLATES_FAILURE = `${NAME}/FETCH_TEMPLATES_FAILURE`;
export const FETCH_TEMPLATES_SUCCESS = `${NAME}/FETCH_TEMPLATES_SUCCESS`;

export const INITIAL_VALUES = `${NAME}/INITIAL_VALUES`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const RESET_VALUES = `${NAME}/RESET_VALUES`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SAVE_INDICATORS_SETTINGS = `${NAME}/SAVE_INDICATORS_SETTINGS`;
export const SAVE_INDICATORS_SETTINGS_FAILURE = `${NAME}/SAVE_INDICATORS_SETTINGS_FAILURE`;
export const SAVE_INDICATORS_SETTINGS_SUCCESS = `${NAME}/SAVE_INDICATORS_SETTINGS_SUCCESS`;

export const SAVE_TEMPLATE = `${NAME}/SAVE_TEMPLATE`;
export const SAVE_TEMPLATE_FAILURE = `${NAME}/SAVE_TEMPLATE_FAILURE`;
export const SAVE_TEMPLATE_SUCCESS = `${NAME}/SAVE_TEMPLATE_SUCCESS`;

export const SET_IS_OPEN_INDICATORS_SETTINGS_MODAL = `${NAME}/SET_IS_OPEN_INDICATORS_SETTINGS_MODAL`;
export const SET_IS_OPEN_PERIODS_SETTINGS_MODAL = `${NAME}/SET_IS_OPEN_PERIODS_SETTINGS_MODAL`;

export const SET_REPORT_END = `${NAME}/SET_REPORT_END`;
export const SET_REPORT_END_FAILURE = `${NAME}/SET_REPORT_END_FAILURE`;
export const SET_REPORT_END_SUCCESS = `${NAME}/SET_REPORT_END_SUCCESS`;
