import React from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { formatDate } from 'common/utils/dates';
import { fullNameBuild } from 'common/utils/helpers';

import ActionsFormatter from './ActionsFormatter';
import BranchOfficeFormatter from './BranchOfficeFormatter';

const ProductColumns = ({
    branchOfficesDictionary,
    branchSelected,
    canDelete,
    canModify,
    companyUsersData,
    filterData,
    handleClickBulkItems,
    handleClickDelete,
    handleClickDuplicate,
    handleClickUpdate,
    hiddenColumns,
    isForSummaryTemplates,
    languagesDictionary,
    orders,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const nameColumn = [
        {
            align: 'left',
            editable: canModify,
            field: 'title',
            flex: 1,
            headerName: t('Title'),
            hide: hiddenColumns.includes('title'),
            order: orders?.title,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Link
                        component={RouterLink}
                        to={`/edit-template-pro/${row.external_uuid}`}
                        color="secondary"
                    >
                        <Box display="flex" flexDirection="column">
                            <Typography variant="caption" fontWeight="bold">
                                {row.title}
                            </Typography>
                            <Typography variant="caption">
                                {row.total_pages}{' '}
                                {t('Sheet', { count: row.total_pages })}
                            </Typography>
                        </Box>
                    </Link>
                );
            },
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            field: 'branch_office',
            flex: 1,
            headerName: t('Branch office'),
            hide: hiddenColumns.includes('branch_office'),
            order: orders?.branch_office,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <BranchOfficeFormatter
                        branchOfficesDictionary={branchOfficesDictionary}
                        row={row}
                    />
                );
            },
        },
        {
            align: 'left',
            field: 'proposals_number',
            flex: 1,
            headerName: t('Proposal', { count: 2 }),
            hide:
                !isForSummaryTemplates ||
                hiddenColumns.includes('proposals_number'),
            order: orders?.proposals_number,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Box alignItems="center" display="flex" gap={1}>
                        <FileCopyIcon fontSize="small" />
                        <Typography variant="body2">
                            {row?.proposals_number}{' '}
                            {t('Proposal', {
                                count: row?.proposals_number,
                            })}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            align: 'left',
            field: 'language',
            flex: 1,
            headerName: t('Language'),
            hide: hiddenColumns.includes('language'),
            order: orders?.language,
            renderCell: (params) => {
                const { row } = params;
                if (languagesDictionary[row?.language])
                    return languagesDictionary[row?.language]?.language;
                return null;
            },
        },
        {
            align: 'left',
            field: 'created_by',
            flex: 1,
            headerName: t('Created by'),
            hide: hiddenColumns.includes('created_by'),
            order: orders?.created_by,
            renderCell: (params) => {
                const { row } = params;
                const user = companyUsersData.find(
                    (user) => user.id === row.created_by,
                );
                return user
                    ? fullNameBuild({
                          firstName: user.first_name,
                          lastName: user.last_name,
                      })
                    : t('Not available');
            },
        },
        {
            align: 'left',
            field: 'created_at',
            flex: 1,
            headerName: t('Creation date'),
            hide: hiddenColumns.includes('created_at'),
            order: orders?.created_at,
            renderCell: (params) => {
                const { row } = params;
                const date = new Date(row?.created_at);
                return formatDate(date, 'yyyy-MM-dd');
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    columns = [
        ...columns,
        {
            align: 'left',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ActionsFormatter
                        branchSelected={branchSelected}
                        canDelete={canDelete}
                        canModify={canModify}
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickDelete={handleClickDelete}
                        handleClickDuplicate={handleClickDuplicate}
                        handleClickUpdate={handleClickUpdate}
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
        },
    ];

    return orderBy(columns, ['order'], ['asc']);
};

ProductColumns.propTypes = {
    branchSelected: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    companyUsersData: PropTypes.array,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    hiddenColumns: PropTypes.array,
    isForSummaryTemplates: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    orders: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductColumns;
