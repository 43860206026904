import i18next from 'i18next';

import { updateConfigUser } from 'common/api/v1/users';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { profileConfigurationActions } from '../reducer';

import initializeMultiregionForm from './initializeMultiregionForm';

export default (values, callback) => (dispatch) => {
    const newValues = {};

    if (values.mode) newValues.mode = values.mode.toUpperCase();
    if (values.expanded_sidebar)
        newValues.expanded_sidebar = values.expanded_sidebar;
    if (values.autofill_panels !== undefined)
        newValues.autofill_panels = values.autofill_panels;

    dispatch(profileConfigurationActions[SAVE]());

    const message = i18next.t(
        'An error occurred during the update. Please try later',
    );

    updateConfigUser(newValues)
        .then((response) => {
            if (!response.data || !response.data.settings) {
                dispatch(profileConfigurationActions[SAVE_FAILURE]([message]));
                return;
            }
            dispatch(
                profileConfigurationActions[SAVE_SUCCESS](
                    response.data.settings,
                ),
            );
            dispatch(
                initializeMultiregionForm({
                    auto_timezone: response.data.settings.auto_timezone,
                    autofill_panels: response.data.settings.autofill_panels,
                    language: response.data.settings.language.key,
                    mode: response.data.settings.mode,
                    timezone: response.data.settings.timezone.key,
                }),
            );

            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                profileConfigurationActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
