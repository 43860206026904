import { getItems } from 'common/api/v2/offerConsumptionAndGeneration';

import {
    FETCH_OFFER_CONSUMPTION_AND_GENERATION,
    FETCH_OFFER_CONSUMPTION_AND_GENERATION_FAILURE,
    FETCH_OFFER_CONSUMPTION_AND_GENERATION_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorOneColumnActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(
            proposalGeneratorOneColumnActions[
                FETCH_OFFER_CONSUMPTION_AND_GENERATION
            ](),
        );

        getItems(id)
            .then((response) => {
                dispatch(
                    proposalGeneratorOneColumnActions[
                        FETCH_OFFER_CONSUMPTION_AND_GENERATION_SUCCESS
                    ](response.data.data),
                );
            })
            .catch((error) => {
                dispatch(
                    proposalGeneratorOneColumnActions[
                        FETCH_OFFER_CONSUMPTION_AND_GENERATION_FAILURE
                    ](error?.response?.data?.errors),
                );
            })
            .finally(resolve);
    });
