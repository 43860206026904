import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];
export const getState = (state) => state;

export const getFetchCustomTemplate = createSelector(
    getModel,
    (model) => model.fetchCustomTemplate,
);

export const getDataFetchCustomTemplate = createSelector(
    getFetchCustomTemplate,
    (fetchCustomTemplate) => fetchCustomTemplate.data || null,
);

export const getIsInitializing = createSelector(getModel, (data) => {
    return data.initializing;
});
