import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

import TrackingForm from 'common/modules/trackingForm/Container';

const CustomFooter = ({ handleOnSubmit, handleSubmit, setStep }) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, pt: 2 }}>
            <Button onClick={() => setStep(1)} sx={{ ml: 'auto' }}>
                {t('Previous')}
            </Button>

            <Button onClick={handleSubmit(handleOnSubmit)}>
                {t('Create')}
            </Button>
        </Box>
    );
};

CustomFooter.propTypes = {
    handleOnSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    setStep: PropTypes.func,
};

const Step2 = ({ onSave, setStep, values }) => {
    const handleOnSubmit = (formValues) =>
        onSave({ ...values, tracking: formValues });

    return (
        <TrackingForm
            contactId={get(values, 'contact.id', '')}
            CustomFooter={({ control, handleSubmit }) => (
                <CustomFooter
                    control={control}
                    handleOnSubmit={handleOnSubmit}
                    handleSubmit={handleSubmit}
                    setStep={setStep}
                />
            )}
        />
    );
};

Step2.propTypes = {
    onSave: PropTypes.func,
    setStep: PropTypes.func,
    values: PropTypes.object,
};

export default Step2;
