import { getPositions } from 'common/api/v1/projectPositions';

import {
    FETCH_LOCATION,
    FETCH_LOCATION_SUCCESS,
    FETCH_LOCATION_ERROR,
} from '../actionTypes';
import { projectLocationActions } from '../reducer';
import * as selectors from '../selectors';

export default () => {
    return (dispatch, getState) => {
        const state = getState();
        const project = selectors.getSelectedProject(state);

        dispatch(projectLocationActions[FETCH_LOCATION]());
        getPositions(project.id)
            .then((response) => {
                const data = response.data ? response.data : {};
                dispatch(projectLocationActions[FETCH_LOCATION_SUCCESS](data));
            })
            .catch((error) =>
                dispatch(projectLocationActions[FETCH_LOCATION_ERROR](error)),
            );
    };
};
