import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormInputPassword,
} from 'common/components/form/bootstrap';

import { SENSITIVE_FIELDS } from '../constants';

const SolisForm = ({ control, credentialFields, disabled }) => {
    const { t } = useTranslation();

    return (
        <Stack spacing={1} pb={2}>
            <ReactHookFormInput
                control={control}
                disabled={disabled}
                label={t('Name')}
                name="name"
            />

            <ReactHookFormInput
                control={control}
                disabled={disabled}
                label={t('Description')}
                name="description"
            />

            {credentialFields?.map((field) =>
                SENSITIVE_FIELDS[field] ? (
                    <ReactHookFormInputPassword
                        control={control}
                        disabled={disabled}
                        key={`credential.${field}`}
                        label={field}
                        name={`credential.${field}`}
                    />
                ) : (
                    <ReactHookFormInput
                        control={control}
                        disabled={disabled}
                        key={`credential.${field}`}
                        label={field}
                        name={`credential.${field}`}
                    />
                ),
            )}
        </Stack>
    );
};

SolisForm.propTypes = {
    control: PropTypes.object,
    credentialFields: PropTypes.array,
    disabled: PropTypes.bool,
};

export default SolisForm;
