import { INITIALIZE_GENERATION_PROFILE_FORM } from '../actionTypes';
import { solarGenerationActions } from '../reducer';

import setGenerationProfileModalIsOpen from './setGenerationProfileModalIsOpen';

export default (values) => (dispatch) => {
    dispatch(setGenerationProfileModalIsOpen(true));
    dispatch(
        solarGenerationActions[INITIALIZE_GENERATION_PROFILE_FORM](values),
    );
};
