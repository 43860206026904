import API from '../../zapAPI';

const ENTITY = 'periods';

export const getPeriods = ({ credentialId, limit = 12, rpuId }) =>
    API.get(`/api/v1/${ENTITY}/lisa/all`, {
        params: { credential_id: credentialId, limit, rpu_id: rpuId },
    });

export const getPeriodsByRpu = ({ page = 1, pageSize = 12, rpuId }) =>
    API.get(`/api/v1/${ENTITY}/rpu/${rpuId}`, {
        params: { page, page_size: pageSize },
    });

export const getPeriod = (period_id) =>
    API.get(`/api/v1/${ENTITY}/`, { params: { period_id } });
