import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import * as zapActions from 'common/modules/zap/actions';
import AccessRestrictedBanner from 'common/modules/zap/components/AccessRestrictedBanner';
import * as zapSelectors from 'common/modules/zap/selectors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validateZap from '../validateZap';

const ZapForm = ({
    addRpu,
    canUseZap,
    credentials,
    fetchCredentials,
    fetchRpus,
    hasZapData,
    isFetchingRpus,
    isLoading,
    isLoadingCredentials,
    rpus,
}) => {
    const [showFields, setShowFields] = useState(true);
    const { t } = useTranslation();
    const { control, getValues, handleSubmit, setValue } = useForm({
        defaultValues: { cfe_user: '', credential_id: '', service_number: '' },
        resolver: yupResolver(validateZap),
    });

    useEffect(() => {
        if (fetchCredentials && canUseZap) fetchCredentials();
    }, [canUseZap, fetchCredentials]);

    useEffect(() => {
        const currentCredential = getValues('credential_id');

        if (credentials?.length && !currentCredential) {
            const [firstCredential] = credentials;
            setValue('credential_id', firstCredential.value);
        }
    }, [credentials]);

    useEffect(() => {
        setShowFields(!hasZapData);
    }, [hasZapData]);

    useEffect(() => {
        if (!rpus?.length) return;

        const item = rpus.find((rpu) => rpu.credential_id) || rpus[0];

        setValue('cfe_user', item.cfe_user);
        setValue('credential_id', item.credential_id);
        setValue('last_payment', item.last_payment);
    }, [rpus]);

    if (!canUseZap) return <AccessRestrictedBanner />;

    if (!isLoading && !showFields)
        return (
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                gap={2}
            >
                <CheckCircleIcon />

                <Typography variant="caption">
                    {t('Electric bill found successfully')}
                </Typography>

                <Button
                    onClick={() => setShowFields((prev) => !prev)}
                    type="button"
                    variant="outlined"
                >
                    {t('Search another')}
                </Button>
            </Box>
        );

    const isDisabled = isLoading || isFetchingRpus || rpus?.length > 0;

    return (
        <Grid container alignItems="flex-start">
            <Grid item md={9} xs={18}>
                <ReactHookFormInput
                    control={control}
                    disabled={isLoading || isFetchingRpus}
                    label={`${t('Service number')} *`}
                    name="service_number"
                    onBlur={(e) => fetchRpus(e.target.value)}
                    onlyIntegerNumbers
                    placeholder="111222333444"
                    variant="filled"
                />
            </Grid>

            <Grid item md={9} xs={18}>
                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={`${t('Service name')} *`}
                    name="cfe_user"
                    variant="filled"
                />
            </Grid>

            <Grid item md={9} xs={18}>
                <ReactHookFormSelect2
                    control={control}
                    disabled={isLoadingCredentials || isDisabled}
                    label={`${t('Link credential')} *`}
                    name="credential_id"
                    options={[{ label: '', options: credentials }]}
                    variant="filled"
                />
            </Grid>

            <Grid
                alignItems="center"
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                item
                md={9}
                xs={18}
            >
                <ReactHookFormIntlNumberInput
                    allowDecimals={false}
                    allowNegativeValue={false}
                    control={control}
                    disabled={isDisabled}
                    fullWidth
                    label={t('Last payment')}
                    name="last_payment"
                    placeholder="0"
                    prepend="$"
                    variant="filled"
                />

                <Button
                    disabled={isLoading || isFetchingRpus}
                    onClick={handleSubmit(addRpu)}
                    sx={{
                        ml: { xs: 0, sm: 2 },
                        width: { xs: '100%', sm: 'fit-content' },
                    }}
                    type="button"
                >
                    {t('Search')}
                </Button>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    credentials: zapSelectors.getCredentialsForSelect,
    hasZapData: selectors.getHasZapData,
    isFetchingRpus: selectors.getIsFetchingZapRpus,
    isLoading: selectors.getIsLoadingZapIntegration,
    isLoadingCredentials: zapSelectors.getCredentialsIsLoading,
    rpus: selectors.getFetchZapRpusData,
});

const mapDispatchToProps = (dispatch) => ({
    addRpu: (data) => dispatch(actions.addZapRpu(data)),
    fetchCredentials: () => dispatch(zapActions.fetchCredentials()),
    fetchRpus: (serviceNumber) => dispatch(actions.fetchZapRpus(serviceNumber)),
});

ZapForm.propTypes = {
    addRpu: PropTypes.func,
    canUseZap: PropTypes.bool,
    credentials: PropTypes.array,
    fetchCredentials: PropTypes.func,
    fetchRpus: PropTypes.func,
    hasZapData: PropTypes.bool,
    isFetchingRpus: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingCredentials: PropTypes.bool,
    rpus: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZapForm);
