import { NAME } from './constants';

export const FETCH_CUSTOM_TEMPLATE = `${NAME}/FETCH_CUSTOM_TEMPLATE`;
export const FETCH_CUSTOM_TEMPLATE_FAILURE = `${NAME}/FETCH_CUSTOM_TEMPLATE_FAILURE`;
export const FETCH_CUSTOM_TEMPLATE_SUCCESS = `${NAME}/FETCH_CUSTOM_TEMPLATE_SUCCESS`;

export const DOWNLOAD_REPORT_CSV = `${NAME}/DOWNLOAD_REPORT_CSV`;
export const DOWNLOAD_REPORT_CSV_SUCCESS = `${NAME}/DOWNLOAD_REPORT_CSV_SUCCESS`;
export const DOWNLOAD_REPORT_CSV_FAILURE = `${NAME}/DOWNLOAD_REPORT_CSV_FAILURE`;

export const DOWNLOAD_REPORT_PDF = `${NAME}/DONWLOAD_REPORT_PDF`;
export const DOWNLOAD_REPORT_PDF_SUCCESS = `${NAME}/DONWLOAD_REPORT_PDF_SUCCESS`;
export const DOWNLOAD_REPORT_PDF_FAILURE = `${NAME}/DONWLOAD_REPORT_PDF_FAILURE`;

export const INITIALIZE = `${NAME}/INITIALIZE`;
export const INITIALIZE_SUCCESS = `${NAME}/INITIALIZE_SUCCESS`;
export const INITIALIZE_FAILURE = `${NAME}/INITIALIZE_FAILURE`;

export const UPDATE_TEMPLATE = `${NAME}/UPDATE_TEMPLATE`;
export const UPDATE_TEMPLATE_FAILURE = `${NAME}/UPDATE_TEMPLATE_FAILURE`;
export const UPDATE_TEMPLATE_SUCCESS = `${NAME}/UPDATE_TEMPLATE_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const SET_SELECTED_DOCUMENT = `${NAME}/SET_SELECTED_DOCUMENT`;
