import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'ps-ts-hourly-configuration';

export const getConfig = (commercialOfferId) =>
    API.get(`/api/v2/${ENTITY}/${commercialOfferId}`);

export const saveConfig = (commercialOfferId, data) =>
    API.put(`/api/v2/${ENTITY}/${commercialOfferId}`, data, config);
