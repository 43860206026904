import projectConsumptionsModal from '../../projectConsumptionsModal';
import * as selectors from '../selectors';

export default () => (dispatch, getState) => {
    const project = selectors.getSelectedProject(getState());
    dispatch(
        projectConsumptionsModal.actions.prepareConsumptions({
            initialValues: {},
            isOpenModal: true,
            projectData: project,
        }),
    );
};
