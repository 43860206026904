import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    .nav-tabs {
        border-bottom: 0;
        padding-left: 0;
        position: relative;

        .nav-link {
            background-color: #eef4fa;
            border-radius: 8px 8px 0 0;
            color: #b3b7cb;
            font-size: 14px;
            height: 38px;
            line-height: 38px;
            margin-top: 12px;
            padding-bottom: 1px;
            padding: 0;
            text-align: center;
            width: 165px;

            &:hover {
                border-color: #e9ecef #e9ecef transparent;
            }

            &.active {
                background-color: #fff;
                border-bottom-color: #fff;
                border-color: #eff1fb;
                color: #202253;
                font-size: 14px;
                font-weight: 500;
                height: 50px;
                letter-spacing: 0.2px;
                line-height: 50px;
                margin-top: 0;
                position: relative;

                &::after {
                    background-color: ${({ theme }) =>
                        theme.palette.primary.main};
                    bottom: 0;
                    content: ' ';
                    height: 2px;
                    left: 50%;
                    position: absolute;
                    transform: translate(-50%, 0);
                    width: 30px;
                }
            }
        }
    }

    .tab-content {
        background-color: #fff;
        border: solid 1px #eff1fb;
        margin-top: -1px;
        padding: 15px 41px;
        z-index: 1;
    }
`;
