import React from 'react';

import Popper from '@mui/material/Popper';
import { Paper } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';

import ResultsTabs from './ResultsTabs';

const Results = ({
    anchorEl = null,
    canViewContacts = false,
    canViewProjects = false,
    canViewProposals = false,
    clearSearch,
    open = false,
    resultBoxRef,
    searchFieldRef,
    searchQuery = '',
}) => {
    const id = open ? 'global-search-popover' : undefined;

    if (!canViewContacts && !canViewProjects && !canViewProposals) return null;

    return (
        <Popper
            anchorEl={anchorEl}
            disablePortal={true}
            id={id}
            open={open}
            placement="top-start"
        >
            <Paper style={{ padding: 3, width: '570px' }}>
                {searchQuery && (
                    <ResultsTabs
                        canViewContacts={canViewContacts}
                        canViewProjects={canViewProjects}
                        canViewProposals={canViewProposals}
                        clearSearch={clearSearch}
                        myForwardedRef={resultBoxRef}
                        searchFieldRef={searchFieldRef}
                        searchQuery={searchQuery}
                    />
                )}
            </Paper>
        </Popper>
    );
};

Results.propTypes = {
    anchorEl: PropTypes.object,
    canViewContacts: PropTypes.bool,
    canViewProjects: PropTypes.bool,
    canViewProposals: PropTypes.bool,
    clearSearch: PropTypes.func,
    open: PropTypes.bool,
    resultBoxRef: PropTypes.object,
    searchFieldRef: PropTypes.object,
    searchQuery: PropTypes.string,
};

export default Results;
