import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from 'sunwise-ui';

import {
    ReactHookFormDragnDropFile,
    ReactHookFormInput,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import TextVariant from 'common/components/TextVariant';
import { FILE_ALLOWED_EXTENSIONS } from 'common/constants';
import yupResolver from 'common/utils/yupResolver';

import validate from '../validate';

const ContainerForm = styled(Box)`
    margin: 14px 6px;

    .container-title {
        color: #202253;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
        margin-top: 0px;
    }

    .container-buttons {
        margin-top: 5px;
    }
`;

const Form = ({
    handleClickCloseForm,
    initialValues,
    isUploadingDatasheet,
    onSubmit,
    uploadDatasheetErrors,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    return (
        <ContainerForm>
            <div className="container-title">{t('Add data sheet')}</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs>
                        <TextVariant
                            fontSize="15px"
                            text={t('File name')}
                            variant="normal"
                        />
                        <ReactHookFormInput control={control} name="name" />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs>
                        <TextVariant text={t('File')} variant="normal" />
                        <ReactHookFormDragnDropFile
                            accept={FILE_ALLOWED_EXTENSIONS.PDF}
                            control={control}
                            formats="pdf"
                            name="archive"
                            setValue={setValue}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs>
                        <ShowErrors errors={uploadDatasheetErrors} />
                    </Grid>
                </Grid>

                <Grid container mt={1}>
                    <Grid
                        item
                        xs
                        sx={{
                            display: 'flex',
                            flexDirection: { md: 'row', xs: 'column' },
                            justifyContent: { md: 'right', xs: 'center' },
                        }}
                    >
                        <Button
                            color="secondary"
                            onClick={handleClickCloseForm}
                            sx={{
                                mr: { md: 2, xs: 0 },
                                order: { md: 1, xs: 2 },
                                width: { md: 'auto', xs: '100%' },
                            }}
                            type="button"
                            variant="text"
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            disabled={isUploadingDatasheet}
                            sx={{
                                mb: { md: 0, xs: 2 },
                                order: { md: 2, xs: 1 },
                                width: { md: 'auto', xs: '100%' },
                            }}
                            type="submit"
                            variant="outlined"
                        >
                            {t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </ContainerForm>
    );
};

Form.propTypes = {
    handleClickCloseForm: PropTypes.func,
    initialValues: PropTypes.object,
    isUploadingDatasheet: PropTypes.bool,
    onSubmit: PropTypes.func,
    uploadDatasheetErrors: PropTypes.array,
};

export default Form;
