import React from 'react';

import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useBreakpoint } from 'common/hooks';

import ActionsFormatter from './ActionsFormatter';
import BranchOfficeFormatter from './BranchOfficeFormatter';
import NameFormatter from './NameFormatter';
import RoleLevelFormatter from './RoleLevelFormatter';

const ProductColumns = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    let columns = [
        {
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            renderCell: (params) => {
                const { row } = params;
                return <NameFormatter row={row} />;
            },
        },
        {
            field: 'role_name',
            flex: 1,
            headerName: t('Role'),
        },
        {
            field: 'role_level',
            flex: 1,
            headerName: t('Role level'),
            renderCell: (params) => {
                const { row } = params;
                return <RoleLevelFormatter row={row} />;
            },
        },
        {
            field: 'phone',
            flex: 1,
            headerName: t('Telephone'),
        },
        {
            field: 'branch_office',
            flex: 1,
            headerName: t('Branch office'),
            renderCell: (params) => {
                const { row } = params;
                return <BranchOfficeFormatter row={row} />;
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = [
            {
                field: 'name',
                flex: 1,
                headerName: t('Name'),
                renderCell: (params) => {
                    const { row } = params;
                    return <NameFormatter row={row} />;
                },
            },
        ];
    }

    columns = [
        ...columns,
        {
            align: 'left',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return <ActionsFormatter row={row} />;
            },
            sortable: false,
        },
    ];

    return orderBy(columns, ['order'], ['asc']);
};

ProductColumns.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    handleChangeSwitch: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickProduct: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    hiddenColumns: PropTypes.array,
    orders: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductColumns;
