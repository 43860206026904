import { transformAfterSalesDateToSunwiseFormat } from '../helpers';

import handleIntegrationsInfo from './handleIntegrationsInfo';

const cleanDate = (date, defaultDate) => {
    if (!date) return defaultDate || '';
    return transformAfterSalesDateToSunwiseFormat(date);
};

export default ({
        fileInfo,
        getValues,
        projectId,
        rate,
        rateConfiguration,
        setEnergyBalanceErrors,
        setValue,
    }) =>
    (dispatch) => {
        if (!fileInfo?.completion || !getValues) return;
        const { completion, file } = fileInfo;

        const initial_date = cleanDate(
            completion?.values?.initial_date,
            getValues('initial_date'),
        );

        const final_date = cleanDate(
            completion?.values?.final_date,
            getValues('final_date'),
        );

        dispatch(
            handleIntegrationsInfo({
                data: completion,
                file,
                finalDate: final_date,
                getValues,
                initialDate: initial_date,
                projectId,
                rate,
                rateConfiguration,
                setEnergyBalanceErrors,
                setValue,
            }),
        );
    };
