import { getBatteriesStorageCatalog } from 'common/api/v2/batteriesStorageCatalog';

import {
    FETCH_BATTERIES,
    FETCH_BATTERIES_FAILURE,
    FETCH_BATTERIES_SUCCESS,
} from '../actionTypes';
import { energyBackupSetupActions } from '../reducer';

export default (commercialOfferId) => (dispatch) => {
    dispatch(energyBackupSetupActions[FETCH_BATTERIES]());

    getBatteriesStorageCatalog(commercialOfferId)
        .then((response) => {
            dispatch(
                energyBackupSetupActions[FETCH_BATTERIES_SUCCESS](
                    response.data.data,
                ),
            );
        })
        .catch((error) =>
            dispatch(
                energyBackupSetupActions[FETCH_BATTERIES_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
