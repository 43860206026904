import { NAME } from './constants';

export const CREATE_ACCOUNT_USER = `${NAME}/CREATE_ACCOUNT_USER`;
export const CREATE_ACCOUNT_USER_FAILURE = `${NAME}/CREATE_ACCOUNT_USER_FAILURE`;
export const CREATE_ACCOUNT_USER_SUCCESS = `${NAME}/CREATE_ACCOUNT_USER_SUCCESS`;

export const FETCH_COUNTRIES = `${NAME}/FETCH_COUNTRIES`;
export const FETCH_COUNTRIES_FAILURE = `${NAME}/FETCH_COUNTRIES_FAILURE`;
export const FETCH_COUNTRIES_SUCCESS = `${NAME}/FETCH_COUNTRIES_SUCCESS`;

export const FETCH_DATA = `${NAME}/FETCH_DATA`;
export const FETCH_DATA_FAILURE = `${NAME}/FETCH_DATA_FAILURE`;
export const FETCH_DATA_SUCCESS = `${NAME}/FETCH_DATA_SUCCESS`;

export const FETCH_GEOCODE = `${NAME}/FETCH_GEOCODE`;
export const FETCH_GEOCODE_FAILURE = `${NAME}/FETCH_GEOCODE_FAILURE`;
export const FETCH_GEOCODE_SUCCESS = `${NAME}/FETCH_GEOCODE_SUCCESS`;

export const RESET_FORM = `${NAME}/RESET_FORM`;
