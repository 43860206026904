import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import proposalGeneratorTaxesTable from '../../proposalGeneratorTaxesTable';

import TotalWrapper from './TotalWrapper';

const Totals = ({
    canModify,
    canModifyCost,
    canModifyMargin,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    discount,
    hasDiscount,
    hasInfonavit,
    infonavitAvailableBalance,
    isFetching,
    isLocked,
    profit,
    profitPercentage,
    proposalId,
    subtotal,
    subtotalWithDiscount,
    total,
    totalCost,
}) => {
    const { t } = useTranslation();

    if (!canModify) return null;

    return (
        <>
            <TotalWrapper
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                dot={<CircleIcon sx={{ color: '#84878b', fontSize: 25 }} />}
                isFetching={isFetching}
                isHidden={customerMode || !canModifyCost}
                title={t('Cost')}
                value={totalCost}
            />
            <TotalWrapper
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                dot={<CircleIcon sx={{ color: '#84878b', fontSize: 25 }} />}
                isFetching={isFetching}
                title={t('Subtotal')}
                value={subtotal}
            />
            <TotalWrapper
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                dot={<CircleIcon sx={{ color: '#b6bbc1f', ontSize: 25 }} />}
                numericalSign={discount > 0 ? '-' : ''}
                isFetching={isFetching}
                isHidden={customerMode || !hasDiscount}
                title={discount > 0 ? t('Discount') : t('Increase')}
                value={discount > 0 ? discount : discount * -1}
            />
            <TotalWrapper
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                dot={<CircleIcon sx={{ color: '#84878b', fontSize: 25 }} />}
                isFetching={isFetching}
                isHidden={customerMode || !hasDiscount}
                title={`${t('Subtotal')} (2)`}
                value={subtotalWithDiscount}
            />
            <proposalGeneratorTaxesTable.Container
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                isLoading={isFetching}
                isLocked={isFetching || isLocked}
                proposalId={proposalId}
            />
            <TotalWrapper
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                dot={<CircleIcon sx={{ color: '#b6bbc1f', ontSize: 25 }} />}
                isFetching={isFetching}
                isHidden={!hasInfonavit}
                title={t('INFONAVIT discount')}
                value={infonavitAvailableBalance}
            />
            <TotalWrapper
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                dot={<CircleIcon sx={{ color: '#000000', fontSize: 25 }} />}
                isFetching={isFetching}
                title={t('Total')}
                value={total}
            />
            <TotalWrapper
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                dot={<CircleIcon sx={{ color: '#000000', fontSize: 25 }} />}
                isFetching={isFetching}
                isHidden={customerMode || !canModifyMargin}
                title={`${t('Profit')} (${profitPercentage}%)`}
                value={profit}
            />
        </>
    );
};

Totals.propTypes = {
    canModify: PropTypes.bool,
    canModifyCost: PropTypes.bool,
    canModifyMargin: PropTypes.bool,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    discount: PropTypes.number,
    hasDiscount: PropTypes.bool,
    hasInfonavit: PropTypes.bool,
    infonavitAvailableBalance: PropTypes.number,
    isFetching: PropTypes.bool,
    isLocked: PropTypes.bool,
    profit: PropTypes.number,
    profitPercentage: PropTypes.number,
    proposalId: PropTypes.string,
    subtotal: PropTypes.number,
    subtotalWithDiscount: PropTypes.number,
    total: PropTypes.number,
    totalCost: PropTypes.number,
};

export default withPermissions(PERMISSION_LIST.SEE_FINAL_BREAKDOWN)(Totals);
