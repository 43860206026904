import i18next from 'i18next';

export default () => ({
    CALC_REQUIRED_TEXT: i18next.t('The calculator field is required'),
    CONFIRM_PASSWORD: i18next.t('Confirm password does not match'),
    DATE_VALIDATION_TEXT: i18next.t('Enter a valid date'),
    EMAIL_VALIDATION_TEXT: i18next.t('Enter a valid email'),
    EMAILS_DONT_MATCH: i18next.t('Mail must be the same'),
    getBetweenValueText: (min = '', max = '') =>
        i18next.t('The number must be between {{min}} and {{max}}', {
            min,
            max,
        }),
    getMaxValueText: (value = '') =>
        i18next.t('It cannot be greater than {{value}}', { value }),
    getMinValueText: (value = '') =>
        i18next.t('It cannot be less than {{value}}', { value }),
    getNumberDigitsText: (digits = '') =>
        i18next.t('The field must contain {{digits}} digits', {
            digits: digits,
        }),
    INVALID_TEXT: i18next.t('Invalid value'),
    LANGUAGE_REQUIRED_TEXT: i18next.t('Select a language'),
    MAX_100_PERCENTAGE_VALIDATION_TEXT: i18next.t(
        'Maximum percentage discount can not exceed 100%',
    ),
    MAX_100_VALIDATION_TEXT: i18next.t('Maximum valid value is 100'),
    MAX_THAN_PRICE_VALIDATION_TEXT: i18next.t(
        'This value cannot be greater than price',
    ),
    maximumNumberCharacters: (number = '') =>
        i18next.t('Maximum {{number}} characters', { number }),
    MIN_THAN_TOP_VALUE_VALIDATION_TEXT: i18next.t(
        'This value must be greater than the minimum value',
    ),
    MIN_ZERO_VALIDATION_TEXT: i18next.t('Minimum valid value is 0'),
    MISSING_CONCEPTS_IN_DISTRIBUTION_TEXT: i18next.t(
        'Every hourly concept must be in at least one hour',
    ),
    minimumNumberCharacters: (number = '') =>
        i18next.t('Minimum {{number}} characters', { number }),
    MUST_BE_GREATER_THAN_ZERO: i18next.t('Must be greater than zero'),
    MUST_BE_UNIQUE_TEXT: i18next.t('Must be unique'),
    NO_DECIMAL_VALIDATION_TEXT: i18next.t(
        'Value cannot contain decimal places',
    ),
    NUMBER_TYPE_TEXT: i18next.t('Must be a number'),
    PASSWORDS_DONT_MATCH: i18next.t('Confirm password does not match'),
    REQUIRED_BRANCH_OFFICE: i18next.t('You must select at least one branch'),
    REQUIRED_FILE: i18next.t('The file is required'),
    REQUIRED_TEXT: i18next.t('This field is required'),
    REQUIRED_TEXT_MAP: i18next.t(
        'Enter or update the location coordinates of the map',
    ),
    TAX_ID_VALIDATION_TEXT: i18next.t('Enter a valid Tax ID'),
    TIMEZONE_REQUIRED_TEXT: i18next.t('Select a time zone'),
    VALID_LOCATION: i18next.t('Enter a valid location'),
});
