import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { IconButton } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import { PROPOSAL_SUMMARY_TYPE } from '../constants';

const DeleteButton = ({
    canDelete,
    companyCatalog,
    prepareDelete,
    showToggleSwitch,
    type,
}) => {
    if (canDelete && !showToggleSwitch && type !== PROPOSAL_SUMMARY_TYPE) {
        return (
            <IconButton
                fontSize="medium"
                onClick={() => {
                    prepareDelete(companyCatalog.id, companyCatalog.type);
                }}
            >
                <DeleteIcon />
            </IconButton>
        );
    }
    return null;
};

DeleteButton.propTypes = {
    canDelete: PropTypes.bool,
    companyCatalog: PropTypes.object,
    prepareDelete: PropTypes.func,
    showToggleSwitch: PropTypes.bool,
    type: PropTypes.number,
};

export default withPermissions(PERMISSION_LIST.LIST_SETTINGS_PERMISSION)(
    DeleteButton,
);
