import cleanView from './cleanView';
import fetchHistoricalInfo from './fetchHistoricalInfo';
import fetchIndicatorsSettings from './fetchIndicatorsSettings';
import fetchPeriodConfig from './fetchPeriodConfig';
import fetchProjectConfiguration from './fetchProjectConfiguration';
import fetchReportComplements from './fetchReportComplements';
import fetchReportDetails from './fetchReportDetails';
import fetchTemplates from './fetchTemplates';

export default (reportId) => (dispatch) => {
    dispatch(cleanView());

    dispatch(fetchPeriodConfig(reportId));
    dispatch(fetchReportDetails(reportId)).then((data) => {
        const projectId = data?.data?.project?.id;
        if (!projectId) return;
        dispatch(fetchHistoricalInfo(reportId, projectId));
        dispatch(fetchProjectConfiguration(reportId, projectId));
    });

    dispatch(fetchIndicatorsSettings());
    dispatch(fetchReportComplements(reportId));
    dispatch(fetchTemplates(reportId));
};
