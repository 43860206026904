import { deleteAlliance } from 'common/api/v1/sunpay';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';

export default (id, proposalId) => (dispatch) => {
    deleteAlliance(id)
        .then(() => {
            dispatch(eventsAfterUpdate(['offer-details'], proposalId));
        })
        .catch((error) => showReponseErrorsAsAlert(dispatch, error?.response))
        .finally(() => {
            dispatch(alerts.actions.close());
            dispatch(alerts.actions.disableButtons(false));
        });
};
