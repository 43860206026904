import i18next from 'i18next';

import { updateItem } from 'common/api/v1/certifiedRates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { getCompensationSchemeByValue } from 'common/utils/helpers/rates';
import showToast from 'common/utils/showToast';

import {
    SAVE_RATE,
    SAVE_RATE_FAILURE,
    SAVE_RATE_SUCCESS,
} from '../actionTypes';
import { getValuesWithCompensationScheme } from '../helpers';
import { ratesActions } from '../reducer';

import setCertifiedRateModalIsOpen from './setCertifiedRateModalIsOpen';

export default ({
        annual_percentage_increase,
        dap,
        default_compensation_scheme,
        energy_export_price,
        id,
        netted_exported_generation_price,
        other_concepts,
        rate_description,
        tax_rate,
        taxes_concepts,
    }) =>
    (dispatch) => {
        const newValues = {
            annual_percentage_increase,
            dap,
            default_compensation_scheme: getCompensationSchemeByValue(
                default_compensation_scheme,
            )?.backendKey,
            energy_export_price,
            netted_exported_generation_price,
            other_concepts: other_concepts
                ? JSON.stringify(other_concepts)
                : '[]',
            rate_description,
            tax_rate,
            taxes_concepts: taxes_concepts
                ? JSON.stringify(taxes_concepts)
                : '[]',
        };
        dispatch(ratesActions[SAVE_RATE]());
        updateItem(id, newValues)
            .then((response) => {
                const newData = getValuesWithCompensationScheme(
                    response?.data?.data,
                );

                dispatch(ratesActions[SAVE_RATE_SUCCESS](newData));
                dispatch(setCertifiedRateModalIsOpen(false));
                showToast({ body: i18next.t('Changes were saved') });
            })
            .catch((error) => {
                dispatch(
                    ratesActions[SAVE_RATE_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
