import React from 'react';

import PropTypes from 'prop-types';

import ReactHookFormFroala from 'common/components/form/bootstrap/ReactHookFormFroala';

const ConsiderationsFields = ({ control, isLocked }) => (
    <ReactHookFormFroala
        control={control}
        disabled={isLocked}
        name="considerations"
    />
);

ConsiderationsFields.propTypes = {
    isLocked: PropTypes.bool,
    control: PropTypes.object,
};

export default ConsiderationsFields;
