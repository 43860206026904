import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

/* OFFER COMPLEMENTS */
export const getOfferComplements = createSelector(
    getModel,
    (model) => model.offerComplements,
);

export const getIsFetchingOfferComplements = createSelector(
    getOfferComplements,
    (offerComplements) => offerComplements.isFetching,
);

export const getOfferComplementsData = createSelector(
    getOfferComplements,
    (offerComplements) => offerComplements.data,
);

export const getOfferComplementsErrors = createSelector(
    getOfferComplements,
    (offerComplements) => offerComplements.errors,
);

// SAVE

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getSaveData = createSelector(getSave, (save) => save.data || []);

export const getSaveErrors = createSelector(getSave, (save) => save.errors);

// MODAL

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal,
);
