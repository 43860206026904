import arrayMove from 'array-move';
import get from 'lodash/get';

import { arraytoDictionary } from 'common/utils/helpers';

export const getProjectById = (funnelColumnsData, projectId) => {
    let funnelColumns = { ...funnelColumnsData };
    let projectsDictionary = Object.keys(funnelColumns).reduce(
        (accColumn, columnKey) => {
            let tempColumn = funnelColumns[columnKey];
            return {
                ...accColumn,
                ...arraytoDictionary(tempColumn.projects, 'id'),
            };
        },
        {},
    );
    return projectsDictionary[projectId];
};

export const addProjectColumnOnSocket = (funnelColumnsData, project) => {
    let funnelColumns = { ...funnelColumnsData };
    let projectsDictionary = Object.keys(funnelColumns).reduce(
        (accColumn, columnKey) => {
            let tempColumn = funnelColumns[columnKey];
            return {
                ...accColumn,
                ...arraytoDictionary(tempColumn.projects, 'id'),
            };
        },
        {},
    );

    if (
        project.previous ===
        funnelColumns[project.status_project.id]?.previousProject
    ) {
        funnelColumns[project.status_project.id].projects = [
            project,
            ...funnelColumns[project.status_project.id].projects,
        ];
        return funnelColumns;
    }

    if (!projectsDictionary[project.previous]) {
        return null;
    }

    const previousProject = projectsDictionary[project.previous];
    const newIndex =
        funnelColumns[previousProject.status_project.id].projects
            .map((p) => p.id)
            .indexOf(previousProject.id) + 1;

    funnelColumns[previousProject.status_project.id].projects = [
        ...funnelColumns[previousProject.status_project.id].projects,
        project,
    ];
    funnelColumns[previousProject.status_project.id].projects = arrayMove(
        [...funnelColumns[previousProject.status_project.id].projects],
        funnelColumns[previousProject.status_project.id].projects.length - 1,
        newIndex,
    );
    return funnelColumns;
};

export const deleteProjectColumnOnSocket = (funnelColumnsData, project) => {
    let funnelColumns = { ...funnelColumnsData };
    let projectsDictionary = Object.keys(funnelColumns).reduce(
        (accColumn, columnKey) => {
            let tempColumn = funnelColumns[columnKey];
            return {
                ...accColumn,
                ...arraytoDictionary(tempColumn.projects, 'id'),
            };
        },
        {},
    );
    if (
        funnelColumns[project.status_project.id]?.previousProject === project.id
    ) {
        funnelColumns[project.status_project.id].previousProject =
            project.previous;
        return funnelColumns;
    }
    if (!projectsDictionary[project.id]) {
        return null;
    }
    funnelColumns[project.status_project.id].projects = funnelColumns[
        project.status_project.id
    ].projects.filter((p) => p.id !== project.id);
    return funnelColumns;
};

export const orderProjectColumn = ({
    funnelColumnsData,
    fromColumnId,
    toColumnId,
    newIndex,
    oldIndex,
    statusProjectDictionary,
}) => {
    let funnelColumns = {
        ...funnelColumnsData,
        [fromColumnId]: {
            ...funnelColumnsData[fromColumnId],
            projects: [...funnelColumnsData[fromColumnId].projects],
        },
        [toColumnId]: {
            ...funnelColumnsData[toColumnId],
            projects: [...funnelColumnsData[toColumnId].projects],
        },
    };

    //En la misma columna
    if (fromColumnId === toColumnId) {
        funnelColumns[fromColumnId].projects = arrayMove(
            [...funnelColumns[fromColumnId].projects],
            oldIndex,
            newIndex,
        );
        const previous =
            newIndex === 0
                ? funnelColumns[fromColumnId].previousProject
                : funnelColumns[fromColumnId].projects[newIndex - 1].id;
        return {
            funnelColumns,
            previous,
        };
    }

    //En diferente columna
    let selectedProject = { ...funnelColumns[fromColumnId].projects[oldIndex] };

    funnelColumns[fromColumnId].projects.splice(oldIndex, 1);

    const previous =
        newIndex === 0
            ? funnelColumns[toColumnId].previousProject
            : funnelColumns[toColumnId].projects[newIndex - 1].id;

    funnelColumns[toColumnId].projects = [
        ...funnelColumns[toColumnId].projects,
        {
            ...selectedProject,
            status_project: {
                id: statusProjectDictionary[toColumnId].id,
                name: statusProjectDictionary[toColumnId].name,
                primary_color:
                    statusProjectDictionary[toColumnId].primary_color,
            },
        },
    ];

    if (newIndex !== funnelColumns[toColumnId].projects.length - 1) {
        funnelColumns[toColumnId].projects = arrayMove(
            funnelColumns[toColumnId].projects,
            funnelColumns[toColumnId].projects.length - 1,
            newIndex,
        );
    }
    return {
        funnelColumns,
        previous,
    };
};

export const orderProjectStatusOnSocket = (
    funnelColumnsData,
    project,
    filterQuery,
) => {
    const filterSplitted = filterQuery.split('=');
    if (filterSplitted.length === 2) {
        switch (filterSplitted[0]) {
            case 'agent':
                if (get(project, 'agent.id', null) !== filterSplitted[1]) {
                    return null;
                }
                break;
            default:
                //rate
                if (get(project, 'rate.id', null) !== filterSplitted[1]) {
                    return null;
                }
        }
    }
    return orderProjectColumnOnSocket(funnelColumnsData, project);
};

export const orderProjectColumnOnSocket = (funnelColumnsData, project) => {
    let funnelColumns = { ...funnelColumnsData };
    let projectsDictionary = Object.keys(funnelColumns).reduce(
        (accColumn, columnKey) => {
            let tempColumn = funnelColumns[columnKey];
            return {
                ...accColumn,
                ...arraytoDictionary(tempColumn.projects, 'id'),
            };
        },
        {},
    );

    const currentProject = projectsDictionary[project.id];

    //Eliminar proyecto
    if (currentProject) {
        funnelColumns[currentProject.status_project.id].projects =
            funnelColumns[currentProject.status_project.id].projects.filter(
                (p) => p.id !== currentProject.id,
            );
    } else {
        for (const columnKey in funnelColumns) {
            if (
                funnelColumns[columnKey].previousProject === project.id &&
                project.old_previous !== project.previous
            ) {
                funnelColumns[columnKey].previousProject = project.old_previous;
                break;
            }
        }
    }

    //Agregar proyecto
    const previousProject =
        project.previous && projectsDictionary[project.previous];
    if (!previousProject) {
        if (
            project.previous ===
            funnelColumns[project.status_project.id]?.previousProject
        ) {
            funnelColumns[project.status_project.id].projects = [
                project,
                ...funnelColumns[project.status_project.id].projects,
            ];
        }
        return funnelColumns;
    }

    const newIndex =
        funnelColumns[previousProject.status_project.id].projects
            .map((p) => p.id)
            .indexOf(previousProject.id) + 1;

    funnelColumns[previousProject.status_project.id].projects = [
        ...funnelColumns[previousProject.status_project.id].projects,
        project,
    ];
    funnelColumns[previousProject.status_project.id].projects = arrayMove(
        [...funnelColumns[previousProject.status_project.id].projects],
        funnelColumns[previousProject.status_project.id].projects.length - 1,
        newIndex,
    );
    return funnelColumns;
};
