import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const IconExportedGeneration = ({ color, height = '24px', width = '24px' }) => {
    const theme = useTheme();
    const fillColor = color || theme.palette.primary.main;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 137 137"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2142_9343)">
                <path
                    d="M38.9726 27.5613L40.7815 29.4641C42.5905 31.3182 42.5905 34.4409 40.7815 36.295L40.7351 36.3438C38.9262 38.2466 36.0504 38.2466 34.2415 36.3438L32.4325 34.4409C30.6236 32.5868 30.6236 29.5129 32.4325 27.61L32.4789 27.5613C34.2415 25.7072 37.1636 25.6584 38.9726 27.5613Z"
                    fill={fillColor}
                />
                <path
                    d="M23.0239 58.4961H27.755C30.3061 58.4961 32.3469 60.643 32.3469 63.3753V63.4241C32.3469 66.1077 30.3061 68.2545 27.7086 68.2057H23.0239C20.4728 68.2057 18.4319 66.0589 18.4319 63.3753V63.3265C18.4319 60.643 20.4728 58.4961 23.0239 58.4961Z"
                    fill={fillColor}
                />
                <path
                    d="M68.426 13.2158H68.4724C71.0235 13.2158 73.0644 15.3627 73.0644 18.0462V22.779C73.0644 25.4626 71.0235 27.6094 68.426 27.5606H68.3796C65.8286 27.5606 63.7877 25.4138 63.7877 22.7302V18.0462C63.7877 15.3627 65.8286 13.2158 68.426 13.2158Z"
                    fill={fillColor}
                />
                <path
                    d="M97.7867 27.5613C99.5956 25.7072 102.518 25.7072 104.327 27.61C106.136 29.5129 106.136 32.538 104.327 34.4409L102.518 36.3438C100.755 38.2466 97.833 38.2466 96.0241 36.3438L95.9777 36.295C94.1688 34.4409 94.1688 31.367 95.9777 29.4641L97.7867 27.5613Z"
                    fill={fillColor}
                />
                <path
                    d="M105.486 63.3753V63.3265C105.486 60.643 107.527 58.4961 110.078 58.4961H114.763C117.314 58.4961 119.355 60.643 119.355 63.3265V63.3753C119.355 66.0589 117.314 68.2057 114.763 68.2057H110.078C107.527 68.2057 105.486 66.0589 105.486 63.3753Z"
                    fill={fillColor}
                />
                <path
                    d="M68.3796 37.4478C83.7325 37.4478 99.5226 48.7808 99.5226 68.6136C91.7097 68.6514 69.2644 68.6348 52.6814 68.6225C46.2277 68.6177 40.6607 68.6136 37.1911 68.6136C37.1911 48.7808 53.0268 37.4478 68.3796 37.4478Z"
                    fill={fillColor}
                />
                <path
                    d="M52.3102 76.3629C52.4623 75.2566 51.5056 74.2801 50.2696 74.2801H28.9486C27.9548 74.2801 27.1033 74.9211 26.9255 75.8028L25.3632 83.5525H51.3212L52.3102 76.3629Z"
                    fill={fillColor}
                />
                <path
                    d="M50.6835 88.1888H24.4286L21.6246 102.097H48.7703L50.6835 88.1888Z"
                    fill={fillColor}
                />
                <path
                    d="M55.8634 88.1888L53.9503 102.097H82.7616L80.8484 88.1888H55.8634Z"
                    fill={fillColor}
                />
                <path
                    d="M48.1326 106.734H20.69L17.3922 123.092C17.1627 124.231 18.1323 125.279 19.4152 125.279H43.7648C44.8026 125.279 45.6777 124.581 45.8054 123.652L48.1326 106.734Z"
                    fill={fillColor}
                />
                <path
                    d="M83.3993 106.734H53.3126L51.0482 123.196C50.896 124.302 51.8527 125.279 53.0888 125.279H83.6231C84.8591 125.279 85.8158 124.302 85.6637 123.196L83.3993 106.734Z"
                    fill={fillColor}
                />
                <path
                    d="M88.5792 106.734L90.9064 123.652C91.0342 124.581 91.9093 125.279 92.947 125.279H117.299C118.581 125.279 119.551 124.231 119.322 123.092L116.024 106.734H88.5792Z"
                    fill={fillColor}
                />
                <path
                    d="M112.285 88.1888L115.089 102.097H87.9415L86.0283 88.1888H112.285Z"
                    fill={fillColor}
                />
                <path
                    d="M85.3906 83.5525H111.351L109.788 75.8028C109.61 74.921 108.759 74.2801 107.765 74.2801H86.4422C85.2062 74.2801 84.2495 75.2566 84.4017 76.3629L85.3906 83.5525Z"
                    fill={fillColor}
                />
                <path
                    d="M56.5012 83.5525H80.2107L79.1589 75.9063C79.0312 74.9775 78.1561 74.2801 77.1183 74.2801H59.5935C58.5558 74.2801 57.6807 74.9775 57.5529 75.9063L56.5012 83.5525Z"
                    fill={fillColor}
                />
            </g>
            <rect
                x="77"
                y="78"
                width="55.996"
                height="55.996"
                rx="27.998"
                fill={fillColor}
            />
            <mask
                id="mask0_2142_9343"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="77"
                y="78"
                width="56"
                height="56"
            >
                <rect
                    x="77"
                    y="78"
                    width="55.996"
                    height="55.996"
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_2142_9343)">
                <path
                    d="M106.631 113.697L112.697 107.631C113.125 107.203 113.339 106.659 113.339 105.998C113.339 105.337 113.125 104.792 112.697 104.364L106.573 98.2399C106.145 97.8121 105.611 97.6076 104.97 97.6263C104.328 97.6465 103.793 97.8705 103.365 98.2982C102.937 98.726 102.723 99.2704 102.723 99.9314C102.723 100.593 102.937 101.137 103.365 101.565L105.465 103.665H97.9402C97.2791 103.665 96.7347 103.888 96.307 104.334C95.8792 104.782 95.6654 105.337 95.6654 105.998C95.6654 106.659 95.8894 107.212 96.3373 107.659C96.7837 108.107 97.3375 108.331 97.9985 108.331H105.465L103.306 110.489C102.879 110.917 102.674 111.452 102.693 112.094C102.713 112.735 102.937 113.269 103.365 113.697C103.793 114.125 104.337 114.339 104.998 114.339C105.659 114.339 106.204 114.125 106.631 113.697ZM104.998 129.329C101.77 129.329 98.7374 128.716 95.8987 127.491C93.06 126.267 90.5907 124.605 88.4909 122.505C86.391 120.405 84.7291 117.936 83.5049 115.097C82.2792 112.258 81.6664 109.225 81.6664 105.998C81.6664 102.77 82.2792 99.737 83.5049 96.8983C84.7291 94.0596 86.391 91.5904 88.4909 89.4905C90.5907 87.3907 93.06 85.7279 95.8987 84.5022C98.7374 83.2781 101.77 82.666 104.998 82.666C108.226 82.666 111.259 83.2781 114.097 84.5022C116.936 85.7279 119.405 87.3907 121.505 89.4905C123.605 91.5904 125.267 94.0596 126.491 96.8983C127.717 99.737 128.33 102.77 128.33 105.998C128.33 109.225 127.717 112.258 126.491 115.097C125.267 117.936 123.605 120.405 121.505 122.505C119.405 124.605 116.936 126.267 114.097 127.491C111.259 128.716 108.226 129.329 104.998 129.329Z"
                    fill={fillColor}
                />
            </g>
            <defs>
                <clipPath id="clip0_2142_9343">
                    <rect
                        width="135.996"
                        height="135.996"
                        fill={fillColor}
                        transform="translate(0.358887 0.615234)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

IconExportedGeneration.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconExportedGeneration;
