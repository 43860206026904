import { mixed, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (_, context) => {
    const { REQUIRED_TEXT } = getValidationTexts();

    const schema = {
        file: mixed().required(REQUIRED_TEXT).nullable(),
    };

    if (context.hasMissingFields?.rate)
        schema.rate = string().required(REQUIRED_TEXT).nullable();

    if (context.hasMissingFields?.zipCode)
        schema.zip_code = string().required(REQUIRED_TEXT).nullable();

    return object().shape(schema);
};
