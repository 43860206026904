import { getContactsAgentsTrackings } from 'common/api/v1/dashboards';

import {
    FETCH_CONTACTS_TRACKINGS_AGENTS,
    FETCH_CONTACTS_TRACKINGS_AGENTS_FAILURE,
    FETCH_CONTACTS_TRACKINGS_AGENTS_SUCCESS,
} from '../actionTypes';
import { dashboardActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(dashboardActions[FETCH_CONTACTS_TRACKINGS_AGENTS]());

    getContactsAgentsTrackings(id)
        .then((response) =>
            dispatch(
                dashboardActions[FETCH_CONTACTS_TRACKINGS_AGENTS_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                dashboardActions[FETCH_CONTACTS_TRACKINGS_AGENTS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
