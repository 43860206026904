import { getFieldsFromIntegration } from '../helpers';

import handleFetchMeasuredEnergy from './handleFetchMeasuredEnergy';
import initializeForm from './initializeForm';

export default ({
        data,
        file,
        finalDate,
        getValues,
        initialDate,
        projectId,
        rate,
        rateConfiguration,
        setEnergyBalanceErrors,
        setValue,
        uploadOrigin,
    }) =>
    (dispatch) => {
        if (!data || !getValues) return;

        const formValues = getValues();

        const { fields, keys } = getFieldsFromIntegration({
            contractedDemandData: data?.contracted_demand || [],
            demandData: data?.values?.demand,
            energyData: data?.values?.energy,
            finalDate: finalDate,
            generationData: data?.values?.solar_generation,
            initialDate: initialDate,
            rate,
            rateConfiguration,
        });

        const newValues = {
            ...formValues,
            file: file || formValues?.file,
            final_date: finalDate,
            incomplete: Boolean(data?.values?.incomplete),
            initial_date: initialDate,
            last_date: data?.values?.date,
            power_factor: data?.values?.fp || formValues?.power_factor,
            upload_origin: uploadOrigin ?? null,
            ...fields,
            fields_keys: keys,
        };

        dispatch(initializeForm(newValues));
        dispatch(
            handleFetchMeasuredEnergy({
                finalDate: finalDate,
                getValues,
                initialDate: initialDate,
                projectId,
                setEnergyBalanceErrors,
                setValue,
            }),
        );
    };
