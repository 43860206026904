import bulkItems from './bulkItems';
import fetchContactsToReassign from './fetchContactsToReassign';
import prepareBulkDelete from './prepareBulkDelete';

export default (ids, value, filterData = {}, callback = null) =>
    (dispatch) => {
        switch (value) {
            case 'deleted':
                dispatch(prepareBulkDelete(ids, value, filterData, callback));
                break;
            case 'reasign_agent':
                dispatch(fetchContactsToReassign(ids, callback));
                break;
            default:
                dispatch(bulkItems(ids, value, filterData, callback));
                break;
        }
    };
