import { createSlice } from '@reduxjs/toolkit';

import { formatDate, subDate } from 'common/utils/dates';

import { DEFAULT_DATE_FORMAT } from '../afterSalesSettings/constants';

import {
    DELETE_DATA,
    DELETE_DATA_FAILURE,
    DELETE_DATA_SUCCESS,
    EDIT_ITEM,
    FETCH_DATA,
    FETCH_DATA_FAILURE,
    FETCH_DATA_SUCCESS,
    FETCH_INITIAL_DATA,
    INITIALIZE_FORM,
    RESET_FORM,
    SAVE_DATA,
    SAVE_DATA_FAILURE,
    SAVE_DATA_SUCCESS,
    SET_MODAL_FORM_OPEN_STATUS,
} from './actionTypes';
import { NAME } from './constants';

const initialDate = formatDate(
    subDate(new Date(), { months: 1 }),
    DEFAULT_DATE_FORMAT,
);
const finalDate = formatDate(new Date(), DEFAULT_DATE_FORMAT);

const INITIAL_STATE = {
    deleteData: { data: null, errors: null, isLoading: false },
    fetchData: {
        data: { items: [], pagination: {}, years: [] },
        errors: null,
        isLoading: false,
    },
    initialValues: {
        final_date: finalDate,
        id: null,
        initial_date: initialDate,
        name: '',
        project_id: null,
    },
    isModalFormOpen: false,
    saveData: { data: null, errors: null, isLoading: false },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_DATA]: (state) => {
            state.deleteData.errors = null;
            state.deleteData.isLoading = true;
        },
        [DELETE_DATA_FAILURE]: (state, action) => {
            state.deleteData.errors = action.payload;
            state.deleteData.isLoading = false;
        },
        [DELETE_DATA_SUCCESS]: (state) => {
            state.deleteData.isLoading = false;
        },
        [EDIT_ITEM]: (state, action) => {
            const { id, values } = action.payload;
            if (!id) return;

            const index = state.fetchData.data.items.findIndex(
                (item) => item.id === action.payload.id,
            );

            if (index >= 0) state.fetchData.data.items[index] = values;
        },
        [FETCH_DATA]: (state) => {
            state.fetchData.errors = null;
            state.fetchData.isLoading = true;
        },
        [FETCH_DATA_FAILURE]: (state, action) => {
            state.fetchData.errors = action.payload;
            state.fetchData.isLoading = false;
        },
        [FETCH_DATA_SUCCESS]: (state, action) => {
            const { data, pagination, years } = action.payload || {};
            const { current_page, per_page, total_rows } = pagination || {};
            const totalItems = current_page * per_page || 0;
            state.fetchData.data.items.push(...(data || []));
            state.fetchData.data.pagination = {
                hasMore: totalItems < total_rows,
                page: current_page,
                pageSize: per_page,
                total: total_rows,
            };
            state.fetchData.data.years = years || [];

            state.fetchData.isLoading = false;
        },
        [FETCH_INITIAL_DATA]: (state) => {
            state.fetchData.data = INITIAL_STATE.fetchData.data;
            state.fetchData.errors = null;
            state.fetchData.isLoading = true;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_DATA]: (state) => {
            state.saveData.data = null;
            state.saveData.errors = null;
            state.saveData.isLoading = true;
        },
        [SAVE_DATA_FAILURE]: (state, action) => {
            state.saveData.errors = action.payload;
            state.saveData.isLoading = false;
        },
        [SAVE_DATA_SUCCESS]: (state, action) => {
            state.saveData.data = action.payload;
            state.saveData.isLoading = false;
        },
        [SET_MODAL_FORM_OPEN_STATUS]: (state, action) => {
            state.isModalFormOpen = action.payload;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
