import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from 'sunwise-ui';

import BranchOfficesIcon from 'resources/branchOfficesIcon.svg?react';

const BranchOfficeTooltip = ({ branchOffice }) => {
    const { t } = useTranslation();
    return (
        <Tooltip
            placement="top"
            title={`${t('Branch office')} - ${branchOffice?.name}`}
        >
            <Box alignItems="center" display="flex" gap={1}>
                <BranchOfficesIcon />
                <Typography variant="caption">{branchOffice?.name}</Typography>
            </Box>
        </Tooltip>
    );
};

BranchOfficeTooltip.propTypes = {
    branchOffice: PropTypes.object,
};

export default BranchOfficeTooltip;
