import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Chip } from 'sunwise-ui';

import { SOURCE_FIELD_NAMES } from '../constants';

const SourceInfoChip = ({ active, disabled, infoKey, onClick, onDelete }) => {
    const { t } = useTranslation();
    if (!infoKey) return null;

    const sourceInfoName = SOURCE_FIELD_NAMES[infoKey];

    return (
        <Chip
            disabled={disabled}
            label={t(sourceInfoName)}
            onClick={onClick}
            onDelete={active ? onDelete : null}
            variant={active ? 'filled' : 'outlined'}
        />
    );
};

SourceInfoChip.propTypes = {
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    infoKey: PropTypes.string,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
};

export default SourceInfoChip;
