import React, { useContext, useEffect, useState } from 'react';

import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Tab, TabPanel, Tabs } from 'sunwise-ui';

import { GeneralContext } from 'common/utils/contexts';

import * as actions from '../actions';
import { POLYGON_SEGMENT } from '../constants';
import { checkCallback, checkCallbackError } from '../helpers';
import { hasMissingIrradiation } from '../IrradiationHelpers';
import * as selectors from '../selectors';

import IrradiationCard from './Irradiation/IrradiationCard';
import PanelsCard from './PanelsCard';
import SegmentsListCard from './SegmentsListCard';
import ShaderSettingsForm from './ShaderSettingsForm';

const SegmentsBar = ({
    commercialOfferId,
    handleOnDuplicateSegment,
    irradiationData,
    isDrawingMode,
    isEditionMode,
    isMobile,
    mapRef,
    offerPanels,
    offerPanelsCounters,
    onCloseDrawer,
    prepareDeleteSegment,
    prepareEditSegment,
    saveShaderSettings,
    segments,
    selectedSegmentId,
    selectedTab,
    selectSegment,
    setDrawingMode,
    setIsEnabledSimulation,
    setSelectedTab,
    toggleLockSegment,
    updateSegments,
}) => {
    const { t } = useTranslation();
    const { google } = useContext(GeneralContext);
    const [prevValues, setPrevValues] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);

    const handleOnChangeTab = (_, newValue) => {
        setSelectedTab(newValue);
        if (newValue === 'design') {
            setIsEnabledSimulation(false);
        } else {
            setIsEnabledSimulation(true);
        }
    };

    const deleteCallback = (id) => {
        if (mapRef && mapRef.current) {
            mapRef.current.deleteShape(id);
        }
        onCloseDrawer();
    };

    useEffect(() => {
        if (selectedTab === 'design') {
            saveShaderSettings({ isPlaying: false });
        }
    }, [selectedTab]);

    useEffect(() => {
        if (!Array.isArray(segments) || !segments.length) {
            setPrevValues([]);
            return;
        }

        const polygonSegments = segments.filter(
            (segment) => segment.type === POLYGON_SEGMENT,
        );

        const currentValues = polygonSegments.map((segment) => {
            const { solar_modules } = segment;
            return solar_modules.map((solar_module) => {
                const { cell, col, enabled, group, path, row } = solar_module;
                return { cell, col, enabled, group, path, row };
            });
        });

        if (!prevValues.length) {
            setPrevValues(currentValues);
            setHasChanges(false);
            return;
        }

        const dif = differenceWith(currentValues, prevValues, isEqual);
        const missing = hasMissingIrradiation(irradiationData, polygonSegments);

        setHasChanges(Boolean(dif.length || missing));
        setPrevValues(currentValues);
    }, [segments, irradiationData]);

    const showAlertIrradiation =
        hasChanges || hasMissingIrradiation(irradiationData, segments);

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    centered
                    onChange={handleOnChangeTab}
                    scrollButtons="auto"
                    value={selectedTab}
                    variant="fullWidth"
                >
                    <Tab
                        id="tab-design"
                        key="tab-design"
                        label={t('Design')}
                        value="design"
                    />

                    <Tab
                        id="tab-simulation"
                        key="tab-simulation"
                        label={t('Simulation')}
                        value="simulation"
                    />
                </Tabs>
            </Box>

            <TabPanel key="tab-design" selectedTab={selectedTab} value="design">
                <>
                    <PanelsCard offerPanels={offerPanels} segments={segments} />

                    <SegmentsListCard
                        commercialOfferId={commercialOfferId}
                        handleClickAdd={({ type = null } = {}) => {
                            setDrawingMode(true, type);
                            onCloseDrawer();
                        }}
                        handleClickDelete={(id) =>
                            prepareDeleteSegment(
                                deleteCallback,
                                commercialOfferId,
                                id,
                                google,
                                mapRef.current.self(),
                                (id, modules) =>
                                    checkCallback(id, modules, mapRef),
                                (id, values) =>
                                    checkCallbackError(id, values, mapRef),
                            )
                        }
                        handleClickEditSegment={prepareEditSegment}
                        handleOnDuplicateSegment={handleOnDuplicateSegment}
                        handleSelectSegment={selectSegment}
                        isDrawingMode={isDrawingMode}
                        isEditionMode={isEditionMode}
                        mapRef={mapRef}
                        offerPanels={offerPanels}
                        offerPanelsCounters={offerPanelsCounters}
                        onCloseDrawer={onCloseDrawer}
                        segments={segments}
                        selectedSegmentId={selectedSegmentId}
                        toggleLockSegment={toggleLockSegment}
                        updateSegments={updateSegments}
                    />
                </>
            </TabPanel>

            <TabPanel
                key="tab-simulation"
                selectedTab={selectedTab}
                value="simulation"
            >
                <IrradiationCard
                    hasChanges={showAlertIrradiation}
                    offerPanels={offerPanels}
                    segments={segments}
                    setHasChanges={setHasChanges}
                />

                <ShaderSettingsForm
                    isMobile={isMobile}
                    onCloseDrawer={onCloseDrawer}
                />
            </TabPanel>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    irradiationData: selectors.getIrradiationData,
    offerPanelsCounters: selectors.getOfferPanelsCounters,
    parentFieldSegmentCounters: selectors.getParentFieldSegmentCounters,
    selectedSegmentId: selectors.getSelectedSegmentId,
});

const mapDispatchToProps = (dispatch) => ({
    handleOnDuplicateSegment: (item, commercialOfferId) =>
        dispatch(actions.handleOnDuplicateSegment(item, commercialOfferId)),
    prepareDeleteSegment: (
        callback,
        commercialOfferId,
        id,
        google,
        mapValue,
        checkCallback,
        checkCallbackError,
    ) =>
        dispatch(
            actions.prepareDeleteSegment(
                callback,
                commercialOfferId,
                id,
                google,
                mapValue,
                checkCallback,
                checkCallbackError,
            ),
        ),
    prepareEditSegment: (values) => dispatch(actions.setInitialValues(values)),
    saveShaderSettings: (config) =>
        dispatch(actions.saveShaderSettings(config)),
    selectSegment: (segment) => dispatch(actions.selectSegment(segment)),
    toggleLockSegment: (id) => dispatch(actions.toggleLockSegment(id)),
    updateSegments: (values) => dispatch(actions.updateSegments(values)),
});

SegmentsBar.propTypes = {
    commercialOfferId: PropTypes.string,
    handleOnDuplicateSegment: PropTypes.func,
    irradiationData: PropTypes.array,
    isDrawingMode: PropTypes.bool,
    isEditionMode: PropTypes.bool,
    isMobile: PropTypes.bool,
    mapRef: PropTypes.object,
    offerPanels: PropTypes.array,
    offerPanelsCounters: PropTypes.object,
    onCloseDrawer: PropTypes.func,
    prepareDeleteSegment: PropTypes.func,
    prepareEditSegment: PropTypes.func,
    saveShaderSettings: PropTypes.func,
    segments: PropTypes.array,
    selectedSegmentId: PropTypes.string,
    selectedTab: PropTypes.string,
    selectSegment: PropTypes.func,
    setDrawingMode: PropTypes.func,
    setIsEnabledSimulation: PropTypes.func,
    setSelectedTab: PropTypes.func,
    toggleLockSegment: PropTypes.func,
    updateSegments: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentsBar);
