import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_RATE_CONCEPTS,
    FETCH_RATE_CONCEPTS_FAILURE,
    FETCH_RATE_CONCEPTS_SUCCESS,
    INITIALIZE_RATE_FORM,
    INITIALIZE_RATE_TAXES_FORM,
    RESET,
    RESET_RATE_CONCEPTS,
    RESET_RATE_CONCEPTS_FAILURE,
    RESET_RATE_CONCEPTS_SUCCESS,
    RESET_RATE_FORM,
    SAVE_RATE_CONCEPTS,
    SAVE_RATE_CONCEPTS_FAILURE,
    SAVE_RATE_CONCEPTS_SUCCESS,
    SAVE_RATE_TAXES,
    SAVE_RATE_TAXES_FAILURE,
    SAVE_RATE_TAXES_SUCCESS,
    SET_RATE_FORM_MODAL_OPEN,
} from './actionTypes';
import { NAME, RATE_TYPES } from './constants';

const INITIAL_STATE = {
    initialValuesRateConcepts: { periods: [], type: RATE_TYPES.CURRENT },
    initialValuesRateTaxes: {
        apply_desired_power_factor: false,
        dap_value: 0,
        desired_power_factor: 90,
        has_low_tension_concept: false,
        increase_percentage: 0,
        tax_rate_percentage: 0,
    },
    isRateFormModalOpen: false,
    rateConcepts: { data: {}, errors: null, loading: false },
    resetRateConcepts: { data: {}, errors: null, loading: false },
    saveRateConcepts: { data: {}, errors: null, loading: false },
    saveRateTaxes: { data: {}, errors: null, loading: false },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_RATE_CONCEPTS]: (state) => {
            state.rateConcepts.loading = true;
            state.rateConcepts.errors = null;
        },
        [FETCH_RATE_CONCEPTS_FAILURE]: (state, action) => {
            state.rateConcepts.loading = false;
            state.rateConcepts.errors = action.payload;
        },
        [FETCH_RATE_CONCEPTS_SUCCESS]: (state, action) => {
            state.rateConcepts.loading = false;
            state.rateConcepts.data = action.payload;
        },
        [INITIALIZE_RATE_FORM]: (state, action) => {
            state.initialValuesRateConcepts = {
                ...state.initialValuesRateConcepts,
                ...action.payload,
            };
        },
        [INITIALIZE_RATE_TAXES_FORM]: (state, action) => {
            state.initialValuesRateTaxes = {
                ...state.initialValuesRateTaxes,
                ...action.payload,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_RATE_FORM]: (state) => {
            state.initialValuesRateConcepts =
                INITIAL_STATE.initialValuesRateConcepts;
        },
        [RESET_RATE_CONCEPTS]: (state) => {
            state.resetRateConcepts.loading = true;
            state.resetRateConcepts.errors = null;
        },
        [RESET_RATE_CONCEPTS_FAILURE]: (state, action) => {
            state.resetRateConcepts.loading = false;
            state.resetRateConcepts.errors = action.payload;
        },
        [RESET_RATE_CONCEPTS_SUCCESS]: (state, action) => {
            state.resetRateConcepts.loading = false;
            state.resetRateConcepts.data = action.payload;
        },
        [SAVE_RATE_CONCEPTS]: (state) => {
            state.saveRateConcepts.loading = true;
            state.saveRateConcepts.errors = null;
        },
        [SAVE_RATE_CONCEPTS_FAILURE]: (state, action) => {
            state.saveRateConcepts.loading = false;
            state.saveRateConcepts.errors = action.payload;
        },
        [SAVE_RATE_CONCEPTS_SUCCESS]: (state, action) => {
            state.saveRateConcepts.loading = false;
            state.saveRateConcepts.data = action.payload;
        },
        [SAVE_RATE_TAXES]: (state) => {
            state.saveRateTaxes.loading = true;
            state.saveRateTaxes.errors = null;
        },
        [SAVE_RATE_TAXES_FAILURE]: (state, action) => {
            state.saveRateTaxes.loading = false;
            state.saveRateTaxes.errors = action.payload;
        },
        [SAVE_RATE_TAXES_SUCCESS]: (state, action) => {
            state.saveRateTaxes.loading = false;
            state.saveRateTaxes.data = action.payload;
        },
        [SET_RATE_FORM_MODAL_OPEN]: (state, action) => {
            state.isRateFormModalOpen = action.payload;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
