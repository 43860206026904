import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_ITEM,
    DELETE_ITEM_SUCCESS,
    DELETE_ITEM_FAILURE,
    INITIALIZE_FORM,
    RESET_FORM,
    SET_IS_NEW_RECORD,
    FETCH_DEFAULT_SOURCE,
    FETCH_DEFAULT_SOURCE_FAILURE,
    FETCH_DEFAULT_SOURCE_SUCCESS,
    FETCH_IRRADIATION_BY_STATE_AND_SOURCE,
    FETCH_IRRADIATION_BY_STATE_AND_SOURCE_FAILURE,
    FETCH_IRRADIATION_BY_STATE_AND_SOURCE_SUCCESS,
    FETCH_POLITICAL_DIVISIONS,
    FETCH_POLITICAL_DIVISIONS_FAILURE,
    FETCH_POLITICAL_DIVISIONS_SUCCESS,
    FETCH_POLITICAL_DIVISIONS2,
    FETCH_POLITICAL_DIVISIONS2_FAILURE,
    FETCH_POLITICAL_DIVISIONS2_SUCCESS,
    FETCH_SOURCES,
    FETCH_SOURCES_FAILURE,
    FETCH_SOURCES_SUCCESS,
    OPEN_MODAL,
    SAVE_SOURCE,
    SAVE_SOURCE_FAILURE,
    SAVE_SOURCE_SUCCESS,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    FETCH_GENERATION_PROFILE,
    FETCH_GENERATION_PROFILE_FAILURE,
    FETCH_GENERATION_PROFILE_SUCCESS,
    SAVE_GENERATION_PROFILE,
    SAVE_GENERATION_PROFILE_FAILURE,
    SAVE_GENERATION_PROFILE_SUCCESS,
    RESET_GENERATION_PROFILE_FORM,
    SET_GENERATION_PROFILE_MODAL_IS_OPEN,
    INITIALIZE_GENERATION_PROFILE_FORM,
    DELETE_GENERATION_PROFILE,
    DELETE_GENERATION_PROFILE_FAILURE,
    DELETE_GENERATION_PROFILE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    defaultSource: {
        data: [],
        errors: null,
        isLoading: false,
    },
    deleteGenerationProfile: {
        data: null,
        errors: [],
        isDeleting: false,
    },
    deleteItem: {
        data: [],
        errors: null,
        isDeleting: false,
    },
    fetchGenerationProfile: {
        data: {},
        errors: [],
        isFetching: false,
    },
    fetchGenerationProfiles: {
        data: {},
        errors: [],
        isFetching: false,
    },
    initialValues: {
        generation: null,
        generation_csv: null,
        hrsSol: new Array(12).fill(0),
        id: null,
        increment: 100,
        kwhkw: new Array(12).fill(0),
        origin: null,
        originalValues: {},
        political_division: null,
        political_division2: null,
        source: null,
        temp_max: 0,
        temp_min: 0,
        saved_by: 'city',
        keep_or_delete: 'keep',
    },
    initialValuesGenerationProfile: {
        generation: null,
        political_division1: null,
        type: 1,
    },
    isNewRecord: false,
    irradiationsByStateAndSources: {
        data: [],
        errors: null,
        isLoading: false,
    },
    isOpenGenerationProfileForm: false,
    isOpenModal: false,
    politicalDivisions: {
        data: [],
        errors: null,
        isLoading: false,
    },
    politicalDivisions2: {
        data: [],
        errors: null,
        isLoading: false,
    },
    sources: {
        data: [],
        errors: null,
        isLoading: false,
    },
    saveGenerationProfile: {
        data: null,
        errors: [],
        isSaving: false,
    },
    saveItem: {
        data: [],
        errors: null,
        isSaving: false,
    },
    saveSource: {
        data: [],
        errors: null,
        isSaving: false,
    },
};

const solarGenerationSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_GENERATION_PROFILE]: (state) => {
            state.deleteGenerationProfile = {
                ...state.deleteGenerationProfile,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_GENERATION_PROFILE_FAILURE]: (state, action) => {
            state.deleteGenerationProfile = {
                ...state.deleteGenerationProfile,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_GENERATION_PROFILE_SUCCESS]: (state, action) => {
            state.deleteGenerationProfile = {
                ...state.deleteGenerationProfile,
                data: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_ITEM]: (state) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: [],
                isDeleting: false,
            };
        },
        [DELETE_ITEM_FAILURE]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_ITEM_SUCCESS]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                data: action.payload,
                isDeleting: false,
            };
        },
        [FETCH_DEFAULT_SOURCE]: (state) => {
            state.defaultSource = {
                ...state.defaultSource,
                errors: [],
                isLoading: true,
            };
        },
        [FETCH_DEFAULT_SOURCE_FAILURE]: (state, action) => {
            state.defaultSource = {
                ...state.defaultSource,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_DEFAULT_SOURCE_SUCCESS]: (state, action) => {
            state.defaultSource = {
                ...state.defaultSource,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_GENERATION_PROFILE]: (state) => {
            state.fetchGenerationProfile = {
                ...state.fetchGenerationProfile,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_GENERATION_PROFILE_FAILURE]: (state, action) => {
            state.fetchGenerationProfile = {
                ...state.fetchGenerationProfile,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_GENERATION_PROFILE_SUCCESS]: (state, action) => {
            state.fetchGenerationProfile = {
                ...state.fetchGenerationProfile,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_IRRADIATION_BY_STATE_AND_SOURCE]: (state) => {
            state.irradiationsByStateAndSources = {
                ...state.irradiationsByStateAndSources,
                errors: [],
                isLoading: true,
            };
        },
        [FETCH_IRRADIATION_BY_STATE_AND_SOURCE_FAILURE]: (state, action) => {
            state.irradiationsByStateAndSources = {
                ...state.irradiationsByStateAndSources,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_IRRADIATION_BY_STATE_AND_SOURCE_SUCCESS]: (state, action) => {
            state.irradiationsByStateAndSources = {
                ...state.irradiationsByStateAndSources,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS]: (state) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                errors: [],
                isLoading: true,
            };
        },
        [FETCH_POLITICAL_DIVISIONS_FAILURE]: (state, action) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS_SUCCESS]: (state, action) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS2]: (state) => {
            state.politicalDivisions2 = {
                ...state.politicalDivisions2,
                errors: [],
                isLoading: true,
            };
        },
        [FETCH_POLITICAL_DIVISIONS2_FAILURE]: (state, action) => {
            state.politicalDivisions2 = {
                ...state.politicalDivisions2,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS2_SUCCESS]: (state, action) => {
            state.politicalDivisions2 = {
                ...state.politicalDivisions2,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_SOURCES]: (state) => {
            state.sources = { ...state.sources, errors: [], isLoading: true };
        },
        [FETCH_SOURCES_FAILURE]: (state, action) => {
            state.sources = {
                ...state.sources,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_SOURCES_SUCCESS]: (state, action) => {
            state.sources = {
                ...state.sources,
                data: action.payload,
                isLoading: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [INITIALIZE_GENERATION_PROFILE_FORM]: (state, action) => {
            state.initialValuesGenerationProfile = {
                ...state.initialValuesGenerationProfile,
                ...action.payload,
            };
        },
        [OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [RESET_GENERATION_PROFILE_FORM]: (state) => {
            state.initialValuesGenerationProfile =
                INITIAL_STATE.initialValuesGenerationProfile;
        },
        [SAVE_GENERATION_PROFILE]: (state) => {
            state.saveGenerationProfile = {
                ...state.saveGenerationProfile,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_GENERATION_PROFILE_FAILURE]: (state, action) => {
            state.saveGenerationProfile = {
                ...state.saveGenerationProfile,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_GENERATION_PROFILE_SUCCESS]: (state, action) => {
            state.saveGenerationProfile = {
                ...state.saveGenerationProfile,
                data: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                data: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SOURCE]: (state) => {
            state.saveSource = {
                ...state.saveSource,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_SOURCE_FAILURE]: (state, action) => {
            state.saveSource = {
                ...state.saveSource,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SOURCE_SUCCESS]: (state, action) => {
            state.saveSource = {
                ...state.saveSource,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_GENERATION_PROFILE_MODAL_IS_OPEN]: (state, action) => {
            state.isOpenGenerationProfileForm = action.payload;
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
    },
});

export const solarGenerationActions = solarGenerationSlice.actions;

export default solarGenerationSlice.reducer;
