import i18next from 'i18next';

import {
    CONTACT_TRACKING_TYPE,
    DOCUMENTS_CONTACT_TYPE,
    DOCUMENTS_PROJECTS_TYPE,
    ORIGIN_CONTACT_TYPE,
    PROPOSAL_SUMMARY_TYPE,
    STATUS_CONTACT_TYPE,
    STATUS_PROJECT_TYPE,
} from './constants';

export const getMessage = (type) => {
    const status = getStatus(type);
    if (type === STATUS_PROJECT_TYPE) {
        return i18next.t(
            'By deleting this element, the projects assigned to it will be left without assigned status. Are you really sure to delete this item?',
        );
    }
    return i18next.t(
        `By removing this item, it will affect all ${status} that have this element assigned. Are you really sure to delete this item?`,
    );
};

export const getStatus = (type) => {
    const message = {
        [CONTACT_TRACKING_TYPE]: i18next
            .t('Follow-up', { count: 2 })
            .toLowerCase(),
        [STATUS_CONTACT_TYPE]: i18next.t('Contact', { count: 2 }).toLowerCase(),
        [DOCUMENTS_CONTACT_TYPE]: i18next
            .t('Contact document', { count: 2 })
            .toLowerCase(),
        [DOCUMENTS_PROJECTS_TYPE]: i18next
            .t('Contact project', { count: 2 })
            .toLowerCase(),
        [STATUS_PROJECT_TYPE]: i18next.t('Project', { count: 2 }).toLowerCase(),
        [ORIGIN_CONTACT_TYPE]: i18next.t('Contact', { count: 2 }).toLowerCase(),
        [PROPOSAL_SUMMARY_TYPE]: i18next
            .t('Proposal', { count: 2 })
            .toLowerCase(),
    };

    return message[type];
};

export const getTitleCard = (type) => {
    let titles = {
        [CONTACT_TRACKING_TYPE]: i18next.t('Follow-up', { count: 2 }),
        [STATUS_CONTACT_TYPE]: i18next.t('Contact status'),
        [DOCUMENTS_CONTACT_TYPE]: i18next.t('Contact document', { count: 2 }),
        [DOCUMENTS_PROJECTS_TYPE]: i18next.t('Project document', { count: 2 }),
        [STATUS_PROJECT_TYPE]: i18next.t('Project status'),
        [ORIGIN_CONTACT_TYPE]: i18next.t('Contact origin'),
        [PROPOSAL_SUMMARY_TYPE]: i18next.t('Proposal type categories'),
    };
    return titles[type];
};

export const onDragEnd = (items, result, type, updateOrder) => {
    const { destination, source } = result;

    if (!destination) {
        return;
    }

    if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
    ) {
        return;
    }

    updateOrder(items, destination.index, source.index, type);
};
