import React from 'react';

import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import SortableItem from './SortableItem';

const SortableList = ({
    companiesCatalogs,
    id,
    onDragEnd,
    prepareDelete,
    prepareUpdate,
    showToggleSwitch,
    type,
    updateActiveStatus,
    updatingActiveStatusId,
}) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={id}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {companiesCatalogs.map((companyCatalog, index) => (
                            <SortableItem
                                companyCatalog={companyCatalog}
                                id={`item-${companyCatalog.id}`}
                                index={index}
                                key={`item-${companyCatalog.id}`}
                                prepareDelete={prepareDelete}
                                prepareUpdate={prepareUpdate}
                                showToggleSwitch={showToggleSwitch}
                                type={type}
                                updateActiveStatus={updateActiveStatus}
                                updatingActiveStatusId={updatingActiveStatusId}
                            />
                        ))}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

SortableList.propTypes = {
    companiesCatalogs: PropTypes.array,
    id: PropTypes.string,
    onDragEnd: PropTypes.func,
    prepareDelete: PropTypes.func,
    prepareUpdate: PropTypes.func,
    showToggleSwitch: PropTypes.bool,
    type: PropTypes.number,
    updateActiveStatus: PropTypes.func,
    updatingActiveStatusId: PropTypes.string,
};

export default SortableList;
