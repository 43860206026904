import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SwitchButtons from 'common/components/SwitchButtons';

const SupportControlButtons = ({
    handlePrepareEditReport,
    isVisibleEditButton,
}) => {
    const { t } = useTranslation();

    return (
        <SwitchButtons
            hide={!isVisibleEditButton}
            items={[
                {
                    handleClick: handlePrepareEditReport,
                    icon: <EditIcon fontSize="small" />,
                    isVisible: isVisibleEditButton,
                    nameId: 'edit',
                    tooltipLabel: t('Edit'),
                },
                {
                    handleClick: () => {},
                    icon: <SettingsIcon fontSize="small" />,
                    isVisible: false,
                    nameId: 'advanced_settings',
                    tooltipLabel: t('Setting'),
                },
            ]}
            sxBox={{ mb: 2 }}
        />
    );
};

SupportControlButtons.propTypes = {
    handlePrepareEditReport: PropTypes.func,
    isVisibleEditButton: PropTypes.bool,
};

export default SupportControlButtons;
