import { removeBagdeNotification } from 'common/api/v1/notifications';

import {
    REMOVE_NOTIFICATION_COUNTER,
    REMOVE_NOTIFICATION_COUNTER_FAILURE,
    REMOVE_NOTIFICATION_COUNTER_SUCCESS,
} from '../actionTypes';
import { notificationsActions } from '../reducer';

import fetchNavNotifications from './fetchNavNotifications';

export default () => (dispatch) => {
    dispatch(notificationsActions[REMOVE_NOTIFICATION_COUNTER]());

    removeBagdeNotification()
        .then(() => {
            dispatch(
                notificationsActions[REMOVE_NOTIFICATION_COUNTER_SUCCESS](),
            );
            dispatch(fetchNavNotifications());
        })
        .catch((error) => {
            dispatch(
                notificationsActions[REMOVE_NOTIFICATION_COUNTER_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
        });
};
