import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (_, isCreateMode) => {
    const { REQUIRED_TEXT } = getValidationTexts();

    let smartDocumentSchema = {
        name: string().required(REQUIRED_TEXT).nullable(),
    };

    if (isCreateMode)
        smartDocumentSchema = {
            custom_offer_template: string().required(REQUIRED_TEXT).nullable(),
        };
    return object().shape(smartDocumentSchema);
};
