import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

/** Offer Info Patern */
export const getOfferInfoPatern = createSelector(
    getModel,
    (model) => model.offerInfoPatern,
);

export const getIsFetchingOfferInfoPatern = createSelector(
    getOfferInfoPatern,
    (value) => value.isFetching,
);

export const getOfferInfoPaternData = createSelector(
    getOfferInfoPatern,
    (value) => value.data,
);
