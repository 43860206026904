import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ExternalSourceForm from './ExternalSourceForm';

const ModalForm = ({
    externalSourceFormModalOpenStatus,
    resetExternalSourceForm,
    setExternalSourceFormModalOpenStatus,
    sunwiseProjectId,
}) => {
    const { t } = useTranslation();

    const handleOnClose = () => setExternalSourceFormModalOpenStatus(false);

    return (
        <Dialog
            onClose={handleOnClose}
            onExited={() => resetExternalSourceForm()}
            open={externalSourceFormModalOpenStatus}
            size="lg"
            title={t('Add source')}
        >
            <ExternalSourceForm
                handleOnClose={handleOnClose}
                sunwiseProjectId={sunwiseProjectId}
            />
        </Dialog>
    );
};

ModalForm.propTypes = {
    externalSourceFormModalOpenStatus: PropTypes.bool,
    resetExternalSourceForm: PropTypes.func,
    setExternalSourceFormModalOpenStatus: PropTypes.func,
    sunwiseProjectId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    externalSourceFormModalOpenStatus:
        selectors.getExternalSourceFormModalOpenStatus,
});

const mapDispatchToProps = (dispatch) => ({
    resetExternalSourceForm: () => dispatch(actions.resetExternalSourceForm()),
    setExternalSourceFormModalOpenStatus: (value) =>
        dispatch(actions.setExternalSourceFormModalOpenStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalForm);
