import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { DATE_VALIDATION_TEXT, REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        notification_at: string(DATE_VALIDATION_TEXT).when('remember', {
            is: true,
            then: (schema) => schema.required(REQUIRED_TEXT),
            otherwise: (schema) => schema.nullable(),
        }),
    });
};
