import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteItem from './deleteItem';

const getMessage = (proposalPlans = [], title) => {
    if (proposalPlans.length > 0) {
        const plans = proposalPlans
            .map((proposalPlan) => `"${proposalPlan.name}"`)
            .join(', ');
        return [
            i18next.t(
                'Are you really sure to delete this template? It is currently the default for plan {{plan}}. Deleting it could harm your users',
                { count: parseInt(proposalPlans.length), plan: plans },
            ),
        ];
    }
    return [
        i18next.t('Are you sure to delete the item "{{name}}"?', {
            name: title,
        }),
    ];
};

export default (filterData, item) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        haveTextCaptcha: true,
        messages: getMessage(item.proposal_plans, item.title),
        onSuccess: () => dispatch(deleteItem(filterData, item.id)),
        textToValidate: i18next.t('Delete').toUpperCase(),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
