import React from 'react';

import PropTypes from 'prop-types';
import { Alert, Typography } from 'sunwise-ui';

const WarningComponent = ({ body = '', title = '', variant }) => (
    <Alert
        sx={
            variant === 'warning'
                ? {
                      background: 'rgba(255, 112, 67, 0.25)',
                      '& .MuiAlert-icon': {
                          color: '#ff7043',
                      },
                  }
                : {
                      background: 'rgba(132, 139, 171, 0.25)',
                      '& .MuiAlert-icon': {
                          color: '#737fb9',
                      },
                  }
        }
        severity={variant}
    >
        <Typography
            variant="body1"
            fontWeight={600}
            sx={{ color: 'rgba(0, 0, 0, 0.9)' }}
        >
            {title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
            {body}
        </Typography>
    </Alert>
);

WarningComponent.propTypes = {
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    title: PropTypes.string,
    variant: PropTypes.string,
};

export default WarningComponent;
