import React from 'react';

import PropTypes from 'prop-types';

import { Switch } from 'common/components/form';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

const ToggleSwitch = ({
    canDelete,
    canModify,
    companyCatalog,
    showToggleSwitch,
    updateActiveStatus,
    updatingActiveStatusId,
}) => {
    return (
        showToggleSwitch && (
            <Switch
                checked={companyCatalog.isActive}
                disabled={
                    updatingActiveStatusId === companyCatalog.id ||
                    !canModify ||
                    !canDelete
                }
                onChange={(e) => {
                    updateActiveStatus(companyCatalog.id, e.target.checked);
                }}
            />
        )
    );
};

ToggleSwitch.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    companyCatalog: PropTypes.object,
    showToggleSwitch: PropTypes.bool,
    updateActiveStatus: PropTypes.func,
    updatingActiveStatusId: PropTypes.string,
};

export default withPermissions(PERMISSION_LIST.LIST_SETTINGS_PERMISSION)(
    ToggleSwitch,
);
