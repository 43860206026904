import resetContacts from '../../contact/actions/reset';
import resetProjects from '../../project/actions/resetValues';
import { RESET_ASSIGN_PROJECT } from '../actionTypes';
import { powerStationsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(resetContacts());
    dispatch(resetProjects());
    dispatch(powerStationsActions[RESET_ASSIGN_PROJECT]());
};
