import { fetchData } from 'common/api/v1/aftersalesProjectConfiguration';

import {
    FETCH_DATA,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_FAILURE,
} from '../actionTypes';
import { actions } from '../reducer';

export default (uuid) => (dispatch) => {
    dispatch(actions[FETCH_DATA]());

    fetchData(uuid)
        .then((response) => {
            const data = response.data.data;

            if (data) {
                data.source =
                    typeof data.source === 'number'
                        ? data.source.toString()
                        : data.source;
            }
            dispatch(actions[FETCH_DATA_SUCCESS](data));
        })
        .catch((error) =>
            dispatch(
                actions[FETCH_DATA_FAILURE](error?.response?.data?.errors),
            ),
        );
};
