import { getRateDivisions } from 'common/api/v1/catalogs';

import {
    FETCH_DIVISIONS,
    FETCH_DIVISIONS_SUCCESS,
    FETCH_DIVISIONS_FAILURE,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

export default (id, selectedRateDivision = null, setValue) =>
    (dispatch) => {
        dispatch(projectConsumptionModalActions[FETCH_DIVISIONS]());

        return new Promise((resolve, reject) => {
            getRateDivisions({ political_division: id })
                .then(({ data }) => {
                    dispatch(
                        projectConsumptionModalActions[FETCH_DIVISIONS_SUCCESS](
                            data.data,
                        ),
                    );
                    let selected = null;
                    if (data?.data?.length > 0) {
                        selected = selectedRateDivision || data.data[0].id;

                        if (setValue) setValue('rate_division', selected);
                    }
                    resolve(selected);
                })
                .catch((error) => {
                    dispatch(
                        projectConsumptionModalActions[FETCH_DIVISIONS_FAILURE](
                            error?.response?.data?.errors,
                        ),
                    );
                    reject();
                });
        });
    };
