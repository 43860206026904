import i18next from 'i18next';
import { isNaN, isNull } from 'lodash';

export const getIsVisible = (item, value) => {
    if (item?.elements?.length === 0) return true;
    return item?.elements?.includes(value) || false;
};

export const getProducts = (item) => [
    {
        color: '#3f4fae',
        label: i18next.t('Panel', { count: 2 }),
        value: 0,
        visible: getIsVisible(item, 0),
    },
    {
        color: '#41c4d9',
        label: i18next.t('Inverter', { count: 2 }),
        value: 1,
        visible: getIsVisible(item, 1),
    },
    {
        color: '#8bc34a',
        label: i18next.t('Storage'),
        value: 6,
        visible: getIsVisible(item, 6),
    },
    {
        color: '#f9c109',
        label: i18next.t('Accessory', { count: 2 }),
        value: 2,
        visible: getIsVisible(item, 2),
    },
    {
        color: '#f67044',
        label: i18next.t('Structure', { count: 2 }),
        value: 3,
        visible: getIsVisible(item, 3),
    },
    {
        color: '#e91e63',
        label: i18next.t('Workforce and electrical equipment'),
        value: 4,
        visible: getIsVisible(item, 4),
    },
    {
        color: '#7046bc',
        label: i18next.t('Additional', { count: 2 }),
        value: 5,
        visible: getIsVisible(item, 5),
    },
];

export const handleNormalizeFieldsBuild =
    (handleOnSave, proposalId) =>
    (index, value, previousValue, allValues, name) => {
        if (!isNull(value) & !isNaN(value) && value != previousValue) {
            const nameItem = name.split('.');
            handleOnSave(proposalId, {
                ...allValues.items[index],
                [nameItem[1]]: value,
            });
        }

        return Number(value);
    };
