import React from 'react';

import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Button, Typography } from 'sunwise-ui';

import NotFoundIcon from 'resources/notFoundIcon.svg';

const Wrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 30px;
    width: 100%;
`;

const PageNotFoundLayout = ({ redirect }) => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <Box sx={{ margin: 'auto', maxWidth: 480, textAlign: 'center' }}>
                <Typography variant="h3" paragraph>
                    {t('Page not found')}!
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                    {t(
                        "Sorry, we couldn't find the page you are looking for. Maybe you misspelled the url? Make sure you check your spelling",
                    )}
                    .
                </Typography>

                <Box
                    component="img"
                    src={NotFoundIcon}
                    sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
                />

                <Box sx={{ justifyContent: 'center' }}>
                    <Button
                        fullWidth={false}
                        onClick={() => redirect('/')}
                        size="large"
                        variant="filled"
                    >
                        {t('Back')}
                    </Button>
                </Box>
            </Box>
        </Wrapper>
    );
};

PageNotFoundLayout.propTypes = { redirect: PropTypes.func };

const mapDispatchToProps = (dispatch) => ({
    redirect: (link) => dispatch(push(link)),
});

export default connect(null, mapDispatchToProps)(PageNotFoundLayout);
