import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_SUMMARY_PATERN,
    FETCH_SUMMARY_PATERN_FAILURE,
    FETCH_SUMMARY_PATERN_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    summaryInfoPatern: {
        isFetching: false,
        data: {},
        errors: [],
    },
};

const summaryReviewSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_SUMMARY_PATERN]: (state) => {
            state.summaryInfoPatern = {
                ...state.summaryInfoPatern,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_SUMMARY_PATERN_FAILURE]: (state, action) => {
            state.summaryInfoPatern = {
                ...state.summaryInfoPatern,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUMMARY_PATERN_SUCCESS]: (state, action) => {
            state.summaryInfoPatern = {
                ...state.summaryInfoPatern,
                data: action.payload,
                isFetching: false,
            };
        },
    },
});

export const summaryReviewActions = summaryReviewSlice.actions;

export default summaryReviewSlice.reducer;
