import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getIsOpen = createSelector(getModel, (model) => model.isOpen);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues || {},
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getCsvInfo = createSelector(getModel, (model) => model.csvInfo);

export const getFetchLocationsZipCode = createSelector(
    getModel,
    (model) => model.fetchLocationsZipCode,
);

export const getIsFetchingLocationsZipCode = createSelector(
    getFetchLocationsZipCode,
    (fetchLocationsZipCode) => fetchLocationsZipCode.isFetching,
);

export const getIsGettingCsvInfo = createSelector(
    getCsvInfo,
    (csvInfo) => csvInfo.isGetting,
);

export const getCsvInfoData = createSelector(
    getCsvInfo,
    (csvInfo) => csvInfo.data,
);
