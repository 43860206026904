import i18next from 'i18next';

import { deleteDocument } from 'common/api/v1/contactDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_FAILURE,
    DELETE_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { documentContactFormActions } from '../reducer';
import * as selectors from '../selectors';

import openConfirmModal from './openConfirmModal';

export default () => (dispatch, getState) => {
    const state = getState();
    const elemenToDelete = selectors.getElementToDelete(state);

    dispatch(documentContactFormActions[DELETE_DOCUMENT]());

    deleteDocument(elemenToDelete.id)
        .then(() => {
            dispatch(openConfirmModal(false));
            dispatch(
                documentContactFormActions[DELETE_DOCUMENT_SUCCESS](
                    elemenToDelete.id,
                ),
            );
            showToast({ body: i18next.t('Successfully deleted') });
        })
        .catch((error) => {
            dispatch(
                documentContactFormActions[DELETE_DOCUMENT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
