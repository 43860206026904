import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';
import { orm as templatesORM } from 'sunwise-template-core';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getState = (state) => state;

export const getFetchCustomTemplate = createSelector(
    getModel,
    (model) => model.fetchCustomTemplate,
);

export const getIsFetchingCustomTemplate = createSelector(
    getFetchCustomTemplate,
    (model) => model.isFetching,
);

export const getDataFetchCustomTemplate = createSelector(
    getFetchCustomTemplate,
    (fetchCustomTemplate) => fetchCustomTemplate.data || null,
);

export const getIsInitialize = createSelector(getModel, (data) => {
    return data.isInitialize;
});

export const getUpdateCustomTemplate = createSelector(
    getModel,
    (model) => model.updateCustomTemplate,
);

export const getIsUpdatingCustomTemplate = createSelector(
    getUpdateCustomTemplate,
    (model) => model.isUpdating,
);

export const getUpdateCustomTemplateType = createSelector(
    getUpdateCustomTemplate,
    (model) => model.type,
);

export const getFetchClonePages = createSelector(
    getModel,
    (model) => model.fetchClonePages,
);

export const getIsFetchingClonePages = createSelector(
    getFetchClonePages,
    (fetchClonePages) => fetchClonePages.isFetching,
);

export const getFetchClonePagesData = createSelector(
    getFetchClonePages,
    (fetchClonePages) => fetchClonePages.data || [],
);

export const getDefaultClonePagesData = createSelector(
    getFetchClonePagesData,
    (clonePages) => clonePages.filter(({ category_id }) => category_id),
);

export const getCustomClonePagesData = createSelector(
    getFetchClonePagesData,
    (clonePages) =>
        clonePages.filter(
            ({ category_id, company_id }) => !category_id && company_id,
        ),
);

export const getFetchClonePagesErrors = createSelector(
    getFetchClonePages,
    (fetchClonePages) => fetchClonePages.errors || [],
);

export const getPageSize = createSelector(getModel, (model) => model.pageSize);

export const getFetchTemplatePreview = createSelector(
    getModel,
    (model) => model.fetchTemplatePreview,
);

export const getTemplatePreviewData = createSelector(
    getFetchTemplatePreview,
    (fetchTemplatePreview) => fetchTemplatePreview.data || null,
);

export const getFetchTemplatePreviewErrors = createSelector(
    getFetchTemplatePreview,
    (fetchTemplatePreview) => fetchTemplatePreview.errors || [],
);

export const getTemplatePreviewIsFetching = createSelector(
    getFetchTemplatePreview,
    (fetchTemplatePreview) => fetchTemplatePreview.isFetching,
);

export const getEntitiesSession = createSelector(getModel, (state) =>
    templatesORM.session(state.entities),
);

export const getTemplatePreviewId = createSelector(
    getModel,
    (model) => model.currentTemplateId,
);

export const getCurrentTemplateModel = createSelector(
    getEntitiesSession,
    getTemplatePreviewData,
    ({ Template }, templatePreviewData) => {
        if (!templatePreviewData) return null;
        const { id } = templatePreviewData;
        return id === null || !Template.idExists(id)
            ? null
            : Template.withId(id);
    },
);

export const getTemplatePreviewPages = createSelector(
    getCurrentTemplateModel,
    (currentTemplateModel) => {
        if (currentTemplateModel === null) return [];
        const pages = currentTemplateModel.templatePages
            .toModelArray()
            .map((pageModel) => ({
                ...pageModel.ref,
                page: pageModel.page,
                type: 'page',
                widgets: pageModel.widgets.toRefArray(),
            }));
        return sortBy(pages, [(p) => parseInt(p.page)]);
    },
);
