import { NAME } from './constants';

export const ARCHIVE_SUMMARY = `${NAME}/ARCHIVE_SUMMARY`;
export const ARCHIVE_SUMMARY_FAILURE = `${NAME}/ARCHIVE_SUMMARY_FAILURE`;
export const ARCHIVE_SUMMARY_SUCCESS = `${NAME}/ARCHIVE_SUMMARY_SUCCESS`;

export const DELETE_SUMMARY = `${NAME}/DELETE_SUMMARY`;
export const DELETE_SUMMARY_FAILURE = `${NAME}/DELETE_SUMMARY_FAILURE`;
export const DELETE_SUMMARY_SUCCESS = `${NAME}/DELETE_SUMMARY_SUCCESS`;

export const DOWNLOAD_CSV = `${NAME}/DOWNLOAD_CSV`;
export const DOWNLOAD_CSV_FAILURE = `${NAME}/DOWNLOAD_CSV_FAILURE`;

export const DUPLICATE_SUMMARY = `${NAME}/DUPLICATE_SUMMARY`;
export const DUPLICATE_SUMMARY_FAILURE = `${NAME}/DUPLICATE_SUMMARY_FAILURE`;
export const DUPLICATE_SUMMARY_SUCCESS = `${NAME}/DUPLICATE_SUMMARY_SUCCESS`;

export const FETCH_COMPANIES_CATALOGS = `${NAME}/FETCH_COMPANIES_CATALOGS`;
export const FETCH_COMPANIES_CATALOGS_FAILURE = `${NAME}/FETCH_COMPANIES_CATALOGS_FAILURE`;
export const FETCH_COMPANIES_CATALOGS_SUCCESS = `${NAME}/FETCH_COMPANIES_CATALOGS_SUCCESS`;

export const FETCH_OFFER_TEMPLATES = `${NAME}/FETCH_OFFER_TEMPLATES`;
export const FETCH_OFFER_TEMPLATES_FAILURE = `${NAME}/FETCH_OFFER_TEMPLATES_FAILURE`;
export const FETCH_OFFER_TEMPLATES_SUCCESS = `${NAME}/FETCH_OFFER_TEMPLATES_SUCCESS`;

export const FETCH_SUMMARIES = `${NAME}/FETCH_SUMMARIES`;
export const FETCH_SUMMARIES_FAILURE = `${NAME}/FETCH_SUMMARIES_FAILURE`;
export const FETCH_SUMMARIES_SUCCESS = `${NAME}/FETCH_SUMMARIES_SUCCESS`;

export const INITIALIZE_VALUES = `${NAME}/INITIALIZE_VALUES`;

export const OPEN_MODAL = `${NAME}/OPEN_MODAL`;

export const RENAME_SUMMARY = `${NAME}/RENAME_SUMMARY`;
export const RENAME_SUMMARY_FAILURE = `${NAME}/RENAME_SUMMARY_FAILURE`;
export const RENAME_SUMMARY_SUCCESS = `${NAME}/RENAME_SUMMARY_SUCCESS`;

export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_SAVE = `${NAME}/RESET_SAVE`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SET_BRANCH_OFFICE = `${NAME}/SET_BRANCH_OFFICE`;
