import React from 'react';

import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const NameText = styled(Typography)`
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.361765px;
    line-height: 18px;
    min-height: 18px;
    min-width: 58px;
`;

const ResumeText = styled(Typography)`
    font-size: 15px;
    font-weight: 300;
    height: 18px;
    letter-spacing: -0.361765px;
    line-height: 18px;
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ListItemName = ({ name, shortName }) => {
    if (isNil(shortName)) return <NameText>{name}</NameText>;
    return (
        <>
            <NameText component="span">{shortName}</NameText>
            <ResumeText component="span">{name}</ResumeText>
        </>
    );
};

ListItemName.propTypes = {
    name: PropTypes.string,
    shortName: PropTypes.string,
};

export default ListItemName;
