import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { getRoleLevelName } from '../helpers';

const RoleLevelFormatter = ({ row }) => {
    const { t } = useTranslation();
    const { has_limited_contacts = false, role_level } = row;
    return (
        <Box display="flex" flexDirection="column">
            <Typography fontWeight="bold" variant="caption">
                {getRoleLevelName(role_level)}
            </Typography>
            <Typography variant="caption">
                {has_limited_contacts ? t('Only own contacts') : ''}
            </Typography>
        </Box>
    );
};

RoleLevelFormatter.propTypes = {
    row: PropTypes.object,
};

export default RoleLevelFormatter;
