import React from 'react';

import PropTypes from 'prop-types';
import { Tooltip } from 'sunwise-ui';

import { transformDateFormat } from 'common/utils/dates';

import { getPeriodLabel } from '../helpers';

const DateTooltip = ({ dateFormat, finalDate, initialDate, isBimonthly }) => {
    const _initialDate = transformDateFormat(initialDate, dateFormat);
    const _finalDate = transformDateFormat(finalDate, dateFormat);
    return (
        <Tooltip title={`${_initialDate} - ${_finalDate}`}>
            <span>{getPeriodLabel(finalDate, isBimonthly)}</span>
        </Tooltip>
    );
};

DateTooltip.propTypes = {
    dateFormat: PropTypes.string,
    finalDate: PropTypes.string,
    initialDate: PropTypes.string,
    isBimonthly: PropTypes.bool,
};

export default DateTooltip;
