import { push } from 'connected-react-router';
import i18next from 'i18next';
import get from 'lodash/get';

import { finishOnboarding } from 'common/api/v1/onboarding';
import { updateSession } from 'common/utils/helpers/session';
import showToast from 'common/utils/showToast';

import {
    SAVE_AND_FINISH_ONBOARDING,
    SAVE_AND_FINISH_ONBOARDING_SUCCESS,
    SAVE_AND_FINISH_ONBOARDING_FAILURE,
} from '../actionTypes';
import { getMembers, getSelectedProducts } from '../helpers';
import { onboardingWizardActions } from '../reducer';

import resetErrors from './resetErrors';
import setCompletedStep from './setCompletedStep';

export default (values) => (dispatch) => {
    const newValues = {
        batteries: getSelectedProducts(values?.batteries),
        inverters: getSelectedProducts(values?.inverters),
        panels: getSelectedProducts(values?.panels),
        users: getMembers(values?.members),
    };

    dispatch(onboardingWizardActions[SAVE_AND_FINISH_ONBOARDING]());

    finishOnboarding(newValues)
        .then((response) => {
            dispatch(
                onboardingWizardActions[SAVE_AND_FINISH_ONBOARDING_SUCCESS](
                    response.data,
                ),
            );
            dispatch(resetErrors());
            dispatch(setCompletedStep(values?.step));
            updateSession({
                hasOnboarding: false,
                onboarding: null,
            });
            dispatch(push('/'));
            showToast({
                body: i18next.t('Onboarding successfully completed'),
            });
        })
        .catch((error) => {
            const code = get(error, 'response.data.code', 'finish');
            const errors = get(error, 'response.data.errors', []);
            dispatch(
                onboardingWizardActions[SAVE_AND_FINISH_ONBOARDING_FAILURE]({
                    [code]: errors,
                }),
            );
            showToast({
                type: 'danger',
                body: i18next.t('An error occurred during the process'),
            });
        });
};
