export const compareVersions = (localVersion, serverVersion) => {
    const localParts = localVersion.split('.').map(Number);
    const serverParts = serverVersion.split('.').map(Number);

    for (let i = 0; i < 3; i++) {
        if (serverParts[i] > localParts[i]) {
            return true; // Update available
        } else if (serverParts[i] < localParts[i]) {
            return false; // No update
        }
    }
    return false; // Versions are the same
};
