import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import uploadFile from './uploadFile';

export default (file, filterData) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Load'),
        messages: [
            i18next.t(
                'Are you sure about the upload process? The prices will be replaced by the prices established in the file',
            ),
        ],
        onSuccess: () => dispatch(uploadFile(file, filterData)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
