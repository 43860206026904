import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getState = (state) => {
    return state;
};

// FETCH CUSTOM TEMPLATE
export const getFetchCustomTemplate = createSelector(
    getModel,
    (model) => model.fetchCustomTemplate,
);

export const getIsFetchingCustomTemplate = createSelector(
    getFetchCustomTemplate,
    (value) => value.isFetching,
);

export const getUpdateCustomTemplate = createSelector(
    getModel,
    (model) => model.updateCustomTemplate,
);

export const getIsUpdatingCustomTemplate = createSelector(
    getUpdateCustomTemplate,
    (model) => model.isUpdating,
);

export const getUpdateCustomTemplateType = createSelector(
    getUpdateCustomTemplate,
    (model) => model.type,
);
