import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Tab, TabPanel, Tabs } from 'sunwise-ui';

import {
    getCountryCurrencyIso,
    getCountryCurrencyLocale,
    getCountryCurrencySymbol,
} from 'common/utils/helpers/session';

import * as afterSalesSettingsSelectors from '../afterSalesSettings/selectors';

import * as actions from './actions';
import AccumulatedSavingsChart from './components/AccumulatedSavingsChart';
import GenerationAccuracyChart from './components/GenerationAccuracyChart';
import GenerationRatioChart from './components/GenerationRatioChart';
import MeasurementSourceChart from './components/MeasurementSourceChart';
import RoiProgressChart from './components/RoiProgressChart';
import { createPeriodsDateRange } from './helpers';
import * as selectors from './selectors';

const ChartCard = ({
    afterSalesStartDate,
    connectSocket,
    disconnectSocket,
    fetchConsumptionHistory,
    fetchProposalInfo,
    isFetchingData,
    reset,
    selectedProjectId,
}) => {
    const [loadedData, setLoadedData] = useState(false);
    const [dateRangeOptions, setDateRangeOptions] = useState([]);
    const [selectedTab, setSelectedTab] = useState('measurement_source');
    const { t } = useTranslation();
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const countryCurrencyIso = getCountryCurrencyIso();
    const countryCurrencySymbol = getCountryCurrencySymbol();

    useEffect(() => {
        fetchConsumptionHistory(selectedProjectId);
        fetchProposalInfo(selectedProjectId);
        connectSocket(selectedProjectId);

        return () => {
            disconnectSocket();
            reset();
        };
    }, [selectedProjectId]);

    useEffect(() => {
        if (!afterSalesStartDate) return;
        const options = createPeriodsDateRange(afterSalesStartDate);
        setDateRangeOptions(options);
    }, [afterSalesStartDate]);

    const handleChangeTab = (_, newValue) => {
        setLoadedData(false);
        setSelectedTab(newValue);
    };

    const tabsContent = [
        {
            Component: MeasurementSourceChart,
            label: t('Measurement source', { count: 2 }),
            value: 'measurement_source',
        },
        {
            Component: GenerationAccuracyChart,
            label: t('Generation accuracy'),
            value: 'generation_accuracy',
        },
        {
            Component: GenerationRatioChart,
            label: t('Energy compliance'),
            value: 'generation_ratio',
        },
        {
            Component: RoiProgressChart,
            label: t('ROI progress'),
            value: 'roi_progress',
        },
        {
            Component: AccumulatedSavingsChart,
            label: t('Accumulated savings'),
            value: 'accumulated_savings',
        },
    ];

    return (
        <>
            <Box borderBottom={1} borderColor="divider">
                <Tabs
                    onChange={handleChangeTab}
                    value={selectedTab}
                    variant="scrollable"
                >
                    {tabsContent.map(({ label, value }) => (
                        <Tab
                            disabled={isFetchingData}
                            key={value}
                            label={label}
                            value={value}
                        />
                    ))}
                </Tabs>
            </Box>

            {tabsContent.map(({ Component, value }) => (
                <TabPanel key={value} selectedTab={selectedTab} value={value}>
                    <Component
                        afterSalesStartDate={afterSalesStartDate}
                        countryCurrencyIso={countryCurrencyIso}
                        countryCurrencyLocale={countryCurrencyLocale}
                        countryCurrencySymbol={countryCurrencySymbol}
                        dateRangeOptions={dateRangeOptions}
                        loadedData={loadedData}
                        selectedProjectId={selectedProjectId}
                        setLoadedData={setLoadedData}
                    />
                </TabPanel>
            ))}
        </>
    );
};

ChartCard.propTypes = {
    afterSalesStartDate: PropTypes.string,
    connectSocket: PropTypes.func,
    disconnectSocket: PropTypes.func,
    fetchConsumptionHistory: PropTypes.func,
    fetchProposalInfo: PropTypes.func,
    isFetchingData: PropTypes.bool,
    reset: PropTypes.func,
    selectedProjectId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    afterSalesStartDate: afterSalesSettingsSelectors.getAfterSalesStartDate,
    isFetchingData: selectors.getIsFetchingData,
});

const mapDispatchToProps = (dispatch) => ({
    connectSocket: (projectId) => dispatch(actions.connectSocket(projectId)),
    disconnectSocket: () => dispatch(actions.disconnectSocket()),
    fetchConsumptionHistory: (projectId) =>
        dispatch(actions.fetchConsumptionHistory(projectId)),
    fetchProposalInfo: (selectedProjectId) =>
        dispatch(actions.fetchProposalInfo(selectedProjectId)),
    reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartCard);
