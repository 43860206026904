import isNil from 'lodash/isNil';

import { formatNumber } from 'common/utils/helpers';

import {
    POLYGON_MAP,
    POLYGON_OBSTACLE,
    POLYGON_SEGMENT,
    SOLAR_MODULE_COLOR,
    SOLAR_MODULE_STROKE_COLOR,
} from './constants';
import MeterLabelPopupBuilder from './models/MeterLabelPopupBuilder';
import SegmentPolygonBuilder from './models/SegmentPolygonBuilder';
import SegmentRectangleBuilder from './models/SegmentRectangleBuilder';

export const EARTH_RADIUS = 6378137;
export const TWOPI = Math.PI * 2;
export const RAD2DEG = 180 / Math.PI;

const rad = (x) => (x * Math.PI) / 180;

const toWorldCoords = (latLng) => {
    let siny = Math.sin(rad(latLng[0]));
    siny = Math.min(Math.max(siny, -0.9999), 0.9999);
    return [
        256 * (0.5 + latLng[1] / 360),
        256 * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)),
    ];
};

const getBounds = ({ google, polygon }) => {
    let bounds = new google.maps.LatLngBounds();
    const paths = polygon.getPath();
    paths.forEach((latlng) => bounds.extend(latlng));
    return bounds;
};

export const mapValueUseEffect = ({
    google,
    handleCenterChanged,
    handleZoomChanged,
    mapValue,
    projectMarkerCenter,
    scaleControl,
    segmentsDictionary,
    setShapes,
    showLabels,
    showProjectMarker,
    solarModuleFillColor = SOLAR_MODULE_COLOR,
    solarModuleStrokeColor = SOLAR_MODULE_STROKE_COLOR,
    zoomControl,
}) => {
    const buildShapes = (myMapValue) => {
        let shapes = {};
        for (const segmentId in segmentsDictionary) {
            let newShape;
            const segmentValue = segmentsDictionary[segmentId];
            const {
                image,
                opacity,
                polygon,
                rotate,
                segment_color: fillColor,
                segment_stroke_color: strokeColor,
                type,
            } = segmentValue;
            const paths = polygon.map(
                (point) => new google.maps.LatLng(point[0], point[1]),
            );

            if ([POLYGON_OBSTACLE, POLYGON_SEGMENT].includes(type)) {
                const SegmentPolygon = SegmentPolygonBuilder(google);
                newShape = new SegmentPolygon({
                    id: segmentId,
                    options: {
                        draggable: false,
                        editable: false,
                        fillColor,
                        map: myMapValue,
                        paths,
                        strokeColor,
                    },
                    safeZone: segmentValue.safe_zone,
                    showMeterLabels: showLabels,
                    solarModules: segmentValue.solar_modules,
                    solarModulesStyle: {
                        fillColor: solarModuleFillColor,
                        strokeColor: solarModuleStrokeColor,
                    },
                    type,
                    zIndex: segmentValue.zIndex,
                });
            }

            if (POLYGON_MAP === type) {
                const SegmentRectangle = SegmentRectangleBuilder(google);
                const polygon = new google.maps.Polygon({ paths });
                const bounds = getBounds({ google, polygon });

                newShape = new SegmentRectangle({
                    id: segmentId,
                    image,
                    opacity,
                    options: { bounds, map: mapValue, strokeColor },
                    rotate,
                    type,
                });
            }
            shapes[segmentId] = newShape;
        }
        setShapes(shapes);
    };
    return () => {
        if (!mapValue) return;

        mapValue.setOptions({ scaleControl, zoomControl });
        mapValue.setTilt(0);

        if (showProjectMarker) {
            new google.maps.marker.AdvancedMarkerElement({
                position: new google.maps.LatLng(
                    projectMarkerCenter.lat,
                    projectMarkerCenter.lng,
                ),
                map: mapValue,
            });
        }
        if (!isNil(mapValue.getProjection())) buildShapes(mapValue);
        else
            google.maps.event.addListenerOnce(
                mapValue,
                'projection_changed',
                () => buildShapes(mapValue),
            );

        if (handleCenterChanged)
            google.maps.event.addListenerOnce(mapValue, 'dragend', () =>
                handleCenterChanged(mapValue.getCenter()),
            );

        if (handleZoomChanged)
            google.maps.event.addListenerOnce(mapValue, 'zoom_changed', () =>
                handleZoomChanged(mapValue.getZoom()),
            );

        return () => {
            if (mapValue) {
                google.maps.event.clearListeners(mapValue, 'dragend');
                google.maps.event.clearListeners(mapValue, 'zoom_changed');
            }
        };
    };
};

export const getIsClockwise = (polygon = []) => {
    const parsedPolygon = polygon.map((point) => toWorldCoords(point));
    if (!Array.isArray(parsedPolygon) || parsedPolygon.length < 3) return true;
    const val =
        (parsedPolygon[1][1] - parsedPolygon[0][1]) *
            (parsedPolygon[2][0] - parsedPolygon[1][0]) -
        (parsedPolygon[1][0] - parsedPolygon[0][0]) *
            (parsedPolygon[2][1] - parsedPolygon[1][1]);
    if (val > 0) return false;
    return true;
};

export const getLabelOrientation = (lat1, lon1, lat2, lon2, isClockwise) => {
    const point1 = toWorldCoords([lat1, lon1]);
    const point2 = toWorldCoords([lat2, lon2]);
    let theta = Math.atan2(point2[0] - point1[0], point1[1] - point2[1]);
    if (theta < 0.0) theta += TWOPI;
    const inDeegres = RAD2DEG * theta;
    let accuracy = 0;
    if (isClockwise) return inDeegres - (90 + accuracy);
    return inDeegres + 90;
};

export const getMiddleCoords = (lat1, lon1, lat2, lon2, google) =>
    new google.maps.LatLng((lat1 + lat2) / 2, (lon1 + lon2) / 2);

export const getDistance = (lat1, lon1, lat2, lon2) => {
    const dLat = rad(lat2 - lat1);
    const dLong = rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(lat1)) *
            Math.cos(rad(lat2)) *
            Math.sin(dLong / 2) *
            Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = EARTH_RADIUS * c;
    return d;
};

export const createMeterLabelPopup = (paths, google, map) => {
    const MeterLabelPopup = MeterLabelPopupBuilder(google, map);
    const isClockwise = getIsClockwise(
        paths.map((point) => [point.lat(), point.lng()]),
    );
    return paths.map((latLng, index, currentArray) => {
        const toPoint =
            index + 1 >= currentArray.length
                ? currentArray[0]
                : currentArray[index + 1];
        const fromPointLat = latLng.lat();
        const fromPointLng = latLng.lng();
        const toPointLat = toPoint.lat();
        const toPointLng = toPoint.lng();
        return new MeterLabelPopup({
            position: getMiddleCoords(
                fromPointLat,
                fromPointLng,
                toPointLat,
                toPointLng,
                google,
            ),
            rotate: getLabelOrientation(
                fromPointLat,
                fromPointLng,
                toPointLat,
                toPointLng,
                isClockwise,
            ),
            text: `${formatNumber({
                input: getDistance(
                    fromPointLat,
                    fromPointLng,
                    toPointLat,
                    toPointLng,
                ),
                format: '0,0.0',
            })} m`,
        });
    });
};

export const changeListener = ({ google, map, meterLabels, path }) => {
    if (path.getArray().length > meterLabels.length) {
        const MeterLabelPopup = MeterLabelPopupBuilder(google, map);
        meterLabels.push(new MeterLabelPopup());
    } else if (path.getArray().length < meterLabels.length) {
        meterLabels[meterLabels.length - 1].setMap(null);
        meterLabels.pop();
    }
    const isClockwise = getIsClockwise(
        path.getArray().map((point) => [point.lat(), point.lng()]),
    );
    path.getArray().forEach((latLng, index, array) => {
        const toPoint = index + 1 >= array.length ? array[0] : array[index + 1];
        const fromPointLat = latLng.lat();
        const fromPointLng = latLng.lng();
        const toPointLat = toPoint.lat();
        const toPointLng = toPoint.lng();
        let label = meterLabels[index];
        label.setPosition(
            getMiddleCoords(
                fromPointLat,
                fromPointLng,
                toPointLat,
                toPointLng,
                google,
            ),
        );
        label.setRotate(
            getLabelOrientation(
                fromPointLat,
                fromPointLng,
                toPointLat,
                toPointLng,
                isClockwise,
            ),
        );
        label.setText(
            `${formatNumber({
                input: getDistance(
                    fromPointLat,
                    fromPointLng,
                    toPointLat,
                    toPointLng,
                ),
                format: '0,0.00',
            })} m`,
        );
    });
};

export const addPathListeners = ({
    getIsDragging,
    google,
    id,
    initSetBackPolygon,
    map,
    meterLabels,
    paths,
    resetShader,
    setContextSegments,
    setSolarModulesDisabled,
    vertexChange,
}) => {
    paths.forEach((path) => {
        ['remove_at', 'insert_at', 'set_at'].forEach((evt) =>
            google.maps.event.addListener(path, evt, () => {
                const isDragging = getIsDragging();

                setSolarModulesDisabled();
                resetShader();
                initSetBackPolygon();
                setContextSegments(path.getArray());
                changeListener({ google, map, meterLabels, path });

                if (!isDragging) {
                    vertexChange({ polygon: path.getArray(), segmentId: id });
                }
            }),
        );
    });
};

export const addRectanglePathListeners = ({
    google,
    rectangle,
    updateOverlay,
    vertexChange,
}) => {
    rectangle.addListener('bounds_changed', () => {
        const bounds = rectangle.getBounds()?.toJSON();
        updateOverlay({ bounds });

        const northEast = rectangle.getBounds().getNorthEast();
        const southWest = rectangle.getBounds().getSouthWest();

        const polygon = [
            new google.maps.LatLng(northEast.lat(), southWest.lng()),
            new google.maps.LatLng(northEast.lat(), northEast.lng()),
            new google.maps.LatLng(southWest.lat(), northEast.lng()),
            new google.maps.LatLng(southWest.lat(), southWest.lng()),
        ];

        vertexChange({ polygon, segmentId: rectangle.id });
    });
};
