import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, StackItem, Typography } from 'sunwise-ui';

const Page21 = ({ items }) => {
    const { t } = useTranslation();

    if (typeof items == 'undefined') return null;
    if (items.length === 0)
        return (
            <Typography variant="caption">
                {t('No selected products')}
            </Typography>
        );

    return (
        <Stack>
            {items.map((item) => (
                <StackItem key={`item-${item.id}`}>{item.name}</StackItem>
            ))}
        </Stack>
    );
};

Page21.propTypes = {
    items: PropTypes.array,
};

export default Page21;
