import React, { useRef, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UploadIcon from '@mui/icons-material/Upload';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Collapse,
    IconButton,
    List,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from 'sunwise-ui';

import { FILE_ALLOWED_EXTENSIONS, MAX_FILE_SIZE } from 'common/constants';
import showToast from 'common/utils/showToast';

const FileInput = styled('input')`
    display: none;
`;

const ManageCsvDropdown = ({ canModify, handleDownload, handleUpload }) => {
    const { t } = useTranslation();
    const inputFileRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openCollapse, setOpenCollapse] = useState(false);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => {
        setAnchorEl(null);
        setOpenCollapse(false);
    };

    const handleClick2 = () => {
        setOpenCollapse(!openCollapse);
    };

    const onChangeFile = (event) => {
        const { files } = event.target;
        if (files.length > 0) {
            if (files[0].size >= MAX_FILE_SIZE) {
                showToast({
                    body: t('The size is larger than allowed'),
                    type: 'danger',
                });
                return;
            }
            handleUpload(files[0]);
            event.target.value = null;
        }
    };

    return (
        <Box>
            <FileInput
                accept={FILE_ALLOWED_EXTENSIONS}
                onChange={onChangeFile}
                ref={inputFileRef}
                type="file"
            />
            <IconButton onClick={handleClick} sx={{ ml: 'auto' }}>
                <MoreVertIcon fontSize="inherit" />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {canModify && (
                    <MenuItem onClick={() => inputFileRef.current.click()}>
                        <ListItemIcon>
                            <UploadIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={t('Upload CSV')} />
                    </MenuItem>
                )}
                <List disablePadding>
                    <MenuItem onClick={handleClick2}>
                        <ListItemIcon>
                            <DownloadIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={t('Download CSV')} />
                        {openCollapse ? <ExpandLess /> : <ExpandMore />}
                    </MenuItem>
                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <MenuItem
                                onClick={() => handleDownload()}
                                sx={{ pl: 4 }}
                            >
                                <ListItemText primary={t('All text')} />
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleDownload('custom')}
                                sx={{ pl: 4 }}
                            >
                                <ListItemText
                                    onClick={() => {
                                        handleClose();
                                    }}
                                    primary={t('Visible columns')}
                                />
                            </MenuItem>
                        </List>
                    </Collapse>
                </List>
            </Menu>
        </Box>
    );
};

ManageCsvDropdown.propTypes = {
    canModify: PropTypes.bool,
    handleDownload: PropTypes.func,
    handleUpload: PropTypes.func,
};

export default ManageCsvDropdown;
