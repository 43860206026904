import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from './actions';
import { PermissionsForm } from './components';

const Container = ({ reset }) => {
    useEffect(() => {
        () => {
            reset();
        };
    }, []);

    return <PermissionsForm reset={reset} />;
};

Container.propTypes = {
    reset: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    reset: () => dispatch(actions.reset()),
});

export default connect(null, mapDispatchToProps)(Container);
