import i18next from 'i18next';
import { createSelector } from 'reselect';

import { fullNameBuild } from 'common/utils/helpers';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getAgents = createSelector(getModel, (model) => model.agents);

export const getAgentsData = createSelector(getAgents, (model) => model.data);

export const getIsFetchingAgents = createSelector(
    getAgents,
    (model) => model.isLoading,
);

export const getContacts = createSelector(getModel, (model) => model.contacts);

export const getHasNextPage = createSelector(
    getContacts,
    (contacts) => contacts.data.hasNextPage,
);

export const getContactsForSelect = createSelector(getContacts, (contacts) => {
    const items = contacts.data.data.map((item) => {
        return {
            label: fullNameBuild({
                firstName: item.name,
                lastName: item.first_lastname,
            }),
            value: item.id,
        };
    });

    items.unshift({
        label: i18next.t('All text', { context: 'male', count: 2 }),
        value: null,
    });

    return items;
});

export const getIsLoadingContacts = createSelector(
    getContacts,
    (contacts) => contacts.isLoading,
);

export const getFilterItemSelected = createSelector(
    getModel,
    (model) => model.filterItemSelected,
);
