import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Typography } from 'sunwise-ui';

import MissingFields from './MissingFields';

const MissingFieldsAlert = ({ period }) => {
    const { t } = useTranslation();

    if (!period?.hasMissingFields) return null;

    return (
        <Alert severity="warning">
            <Typography variant="subtitle2" pb={1}>
                {t(
                    "The following fields couldn't be displayed because of the rate configuration",
                )}
            </Typography>
            <MissingFields
                missingFields={period?.missingFields?.kWh}
                title={t('Energy')}
            />
            <MissingFields
                missingFields={period?.missingFields?.kW}
                title={t('Demand')}
            />
        </Alert>
    );
};

MissingFieldsAlert.propTypes = {
    period: PropTypes.object,
};

export default MissingFieldsAlert;
