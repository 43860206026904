import i18next from 'i18next';

import initializeDuplicateFormValues from './initializeDuplicateFormValues';
import openDuplicateModal from './openDuplicateModal';

export default (proposalName, proposalId) => (dispatch) => {
    dispatch(
        initializeDuplicateFormValues({
            id: proposalId,
            name: `${i18next.t('Copy of')} ${proposalName}`,
        }),
    );

    return dispatch(openDuplicateModal(true));
};
