import i18next from 'i18next';
import isArray from 'lodash/isArray';

import { uploadDocument, updateDocument } from 'common/api/v1/projectDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { projectDocumentActions } from '../reducer';

import showModal from './showModal';

export default (values, projectId) => (dispatch) => {
    let newValues = {
        project: projectId,
        title: values.name,
        document_type: values.type_document,
        value_user: values.value_user,
    };

    if (isArray(values.file) && !values.file[0].isUrlFile) {
        newValues.file = values.file && values.file[0];
    }

    dispatch(projectDocumentActions[UPLOAD_DOCUMENT]());

    const save = values.id
        ? () => updateDocument(newValues, values.id)
        : () => uploadDocument(newValues);

    save()
        .then((response) => {
            const { created_at, file, id, title, document_type, value_user } =
                response.data;
            dispatch(
                projectDocumentActions[UPLOAD_DOCUMENT_SUCCESS]({
                    created_at,
                    document_type_id: document_type,
                    id,
                    file,
                    name: title,
                    value_user,
                }),
            );
            dispatch(showModal(false));
            showToast({ body: i18next.t('Changes were saved') });
        })
        .catch((error) => {
            dispatch(
                projectDocumentActions[UPLOAD_DOCUMENT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
