import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, Grid, TextField } from 'sunwise-ui';

import BottomActionsForm from '../form/BottomActionsForm';
import SourceInfoFields from '../form/SourceInfoFields';

const ReviewApiInfo = ({
    control,
    disabled,
    onCancel,
    providersDictionary,
}) => {
    const { t } = useTranslation();
    const [credential, powerStation] = useWatch({
        control,
        name: ['credential', 'power_station'],
    });

    const provider = providersDictionary?.[credential?.provider_id || 0] || {};

    return (
        <>
            <Alert severity="info">
                {t(
                    'The commissioning date will be overwritten with the date of the first measurement received',
                )}
            </Alert>

            <Grid container mb={2}>
                <Grid item xs={18} sm={9} lg={6}>
                    <TextField
                        disabled
                        fullWidth
                        label={t('Provider')}
                        value={provider?.name || ''}
                        variant="filled"
                    />
                </Grid>

                <Grid item xs={18} sm={9} lg={6}>
                    <TextField
                        disabled
                        fullWidth
                        label={t('Credential name')}
                        value={credential?.name || ''}
                        variant="filled"
                    />
                </Grid>

                <Grid item xs={18} sm={9} lg={6}>
                    <TextField
                        disabled
                        fullWidth
                        label={t('Power station')}
                        value={
                            powerStation?.station_name || powerStation?.id || ''
                        }
                        variant="filled"
                    />
                </Grid>

                <Grid item xs={18}>
                    <SourceInfoFields
                        control={control}
                        disabled={disabled}
                        validFields={provider?.valid_read_fields}
                    />
                </Grid>
            </Grid>

            <BottomActionsForm
                cancelText={t('Back')}
                continueText={t('Save')}
                disabled={disabled}
                disabledContinue={!credential?.id || !powerStation?.id}
                onCancel={onCancel}
                type="submit"
            />
        </>
    );
};

ReviewApiInfo.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    onCancel: PropTypes.func,
    providersDictionary: PropTypes.object,
};

export default ReviewApiInfo;
