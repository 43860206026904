import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button, MenuItem } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
    ReactHookFormRadioButtonGroup,
} from 'common/components/form/bootstrap';

import {
    ANNUAL_TYPE,
    FIXED_RATE_TYPE,
    HOURLY_RATE_TYPE,
    NAME_LENGTH,
    NAME_SHORT_LENGTH,
    PER_PERIODS,
    RATE_DESCRIPTION_LENGTH,
    STAGGERED_RATE_TYPE,
} from '../../constants';
import { createPeriodsByPeriodicityType } from '../../helpers';
import * as selectors from '../../selectors';
import SectionHeader from '../SectionHeader';
import SupplierUtilityInput from '../supplierUtility/SupplierUtilityInput';

const FieldGroup = styled(Box)`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
`;

const GROUP_NAME = 'main_data';

const MainDataTab = ({
    changeTab,
    control,
    countryItemsToSelect,
    currenciesItemsToSelect,
    getValues,
    isAnnualType,
    isNewRate,
    setValue,
    showMode,
}) => {
    const countryId = useWatch({ control, name: 'main_data.country' });
    const { t } = useTranslation();
    const getPaymentType = () => getValues(`${GROUP_NAME}.payment_type`);

    useEffect(() => {
        const energyConcepts = getValues(
            'settings_data.periods.0.energy_concepts.concepts',
        );
        if (energyConcepts?.length > 0) return;
        if (isNewRate && !showMode)
            createPeriodsByPeriodicityType({
                isAnnualType,
                paymentType: getPaymentType(),
                setValue,
            });
    }, []);

    return (
        <>
            <SectionHeader title={t('General information')} />
            <FieldGroup>
                <ReactHookFormInput
                    control={control}
                    disabled={showMode}
                    label={t('Rate name')}
                    maxLength={NAME_LENGTH}
                    name={`${GROUP_NAME}.name`}
                    placeholder={t('Rate name')}
                />
                <ReactHookFormInput
                    control={control}
                    disabled={showMode}
                    label={t('Short name')}
                    maxLength={NAME_SHORT_LENGTH}
                    name={`${GROUP_NAME}.name_short`}
                    placeholder={t('Short name')}
                />
                <ReactHookFormInput
                    control={control}
                    disabled
                    label={t('Select a country')}
                    name={`${GROUP_NAME}.country`}
                    placeholder={t('Select a country')}
                    select
                >
                    {countryItemsToSelect.map(({ label, value, disabled }) => (
                        <MenuItem
                            key={`country-select-${value}-${label}`}
                            value={value}
                            disabled={disabled}
                        >
                            {label}
                        </MenuItem>
                    ))}
                </ReactHookFormInput>
                <SupplierUtilityInput
                    control={control}
                    countryId={countryId}
                    disabled={showMode}
                    name={`${GROUP_NAME}.utility_supplier`}
                />
                <ReactHookFormInput
                    control={control}
                    disabled={showMode}
                    label={t('Rate description')}
                    maxLength={RATE_DESCRIPTION_LENGTH}
                    multiline
                    name={`${GROUP_NAME}.rate_description`}
                    placeholder={t('Rate description')}
                />
                <ReactHookFormInput
                    control={control}
                    disabled
                    label={t('Currency')}
                    name={`${GROUP_NAME}.currency`}
                    placeholder={t('Currency')}
                    select
                >
                    {currenciesItemsToSelect.map(
                        ({ label, value, disabled }) => (
                            <MenuItem
                                key={`currency-select-${value}-${label}`}
                                value={value}
                                disabled={disabled}
                            >
                                {label}
                            </MenuItem>
                        ),
                    )}
                </ReactHookFormInput>
            </FieldGroup>

            <SectionHeader title={t('Rate type')} />
            <FieldGroup>
                <ReactHookFormRadioButtonGroup
                    control={control}
                    disabled={showMode}
                    items={[
                        {
                            label: t('Residential'),
                            value: 'Residential',
                        },
                        {
                            label: t('Commercial'),
                            value: 'Commercial',
                        },
                        {
                            label: t('Industrial'),
                            value: 'Industrial',
                        },
                    ]}
                    name={`${GROUP_NAME}.business_type`}
                />
            </FieldGroup>

            <SectionHeader title={t('Select the type of electrical voltage')} />
            <FieldGroup>
                <ReactHookFormRadioButtonGroup
                    control={control}
                    disabled={showMode}
                    items={[
                        {
                            label: t('Low tension'),
                            value: 'Low tension',
                        },
                        {
                            label: t('Mid tension'),
                            value: 'Mid tension',
                        },
                        {
                            label: t('High tension'),
                            value: 'High tension',
                        },
                    ]}
                    name={`${GROUP_NAME}.voltage_type`}
                />
            </FieldGroup>

            <SectionHeader title={t('Billing period')} />
            <FieldGroup>
                <ReactHookFormRadioButtonGroup
                    control={control}
                    disabled={showMode}
                    items={[
                        {
                            label: t('Monthly'),
                            value: 'Monthly',
                        },
                        {
                            label: t('Bimonthly'),
                            value: 'Bimonthly',
                        },
                    ]}
                    name={`${GROUP_NAME}.periodicity_type`}
                    onChangedValue={() => {
                        createPeriodsByPeriodicityType({
                            isAnnualType,
                            paymentType: getPaymentType(),
                            setValue,
                        });
                        setValue('settings_data.hourly_distribution', null);
                    }}
                />
            </FieldGroup>

            <SectionHeader title={t('Entry of charges')} />
            <FieldGroup>
                <ReactHookFormRadioButtonGroup
                    control={control}
                    disabled={showMode}
                    items={[
                        {
                            disabled: true,
                            label: `${t('By billing period')} (${t(
                                'Coming soon',
                            )})`,
                            value: PER_PERIODS,
                        },
                        {
                            label: t('Annual'),
                            value: ANNUAL_TYPE,
                        },
                    ]}
                    name={`${GROUP_NAME}.type_of_input_charges`}
                    onChangedValue={(e) => {
                        createPeriodsByPeriodicityType({
                            isAnnualType: e.target.value === ANNUAL_TYPE,
                            paymentType: getPaymentType(),
                            setValue,
                        });
                        setValue('settings_data.hourly_distribution', null);
                    }}
                />
            </FieldGroup>

            <SectionHeader title={t('Rate scheme')} />

            <FieldGroup>
                <ReactHookFormRadioButtonGroup
                    control={control}
                    disabled={showMode}
                    items={[
                        {
                            label: t('Fixed'),
                            value: FIXED_RATE_TYPE,
                        },
                        {
                            label: t('Staggered'),
                            value: STAGGERED_RATE_TYPE,
                        },
                        {
                            label: t('Hourly'),
                            value: HOURLY_RATE_TYPE,
                        },
                    ]}
                    name={`${GROUP_NAME}.payment_type`}
                    onChangedValue={(e) => {
                        createPeriodsByPeriodicityType({
                            isAnnualType,
                            paymentType: e.target.value,
                            setValue,
                        });

                        setValue('settings_data.hourly_distribution', null);
                    }}
                />

                <ReactHookFormIntlNumberInput
                    append="%"
                    control={control}
                    disabled={showMode}
                    fullWidth
                    label={t('Annual percentage increase')}
                    max={100}
                    min={0}
                    name={`${GROUP_NAME}.annual_percentage_increase`}
                    placeholder={t('Annual percentage increase')}
                />
            </FieldGroup>

            {!showMode && (
                <BottomActions>
                    <Button
                        onClick={() => changeTab('settings_data')}
                        sx={{ mt: 2 }}
                        type="button"
                    >
                        {t('Next')}
                    </Button>
                </BottomActions>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    countryItemsToSelect: selectors.getCountriesItemsToSelect,
    currenciesItemsToSelect: selectors.getCurrenciesItemsToSelect,
});

MainDataTab.propTypes = {
    changeTab: PropTypes.func,
    control: PropTypes.object,
    countryItemsToSelect: PropTypes.array,
    currenciesItemsToSelect: PropTypes.array,
    getValues: PropTypes.func,
    isAnnualType: PropTypes.bool,
    isNewRate: PropTypes.bool,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
};

export default connect(mapStateToProps)(MainDataTab);
