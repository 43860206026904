import { NAME } from './constants';

export const FETCH_PRODUCT_DATASHEETS = `${NAME}/FETCH_PRODUCT_DATASHEETS`;
export const FETCH_PRODUCT_DATASHEETS_FAILURE = `${NAME}/FETCH_PRODUCT_DATASHEETS_FAILURE`;
export const FETCH_PRODUCT_DATASHEETS_SUCCESS = `${NAME}/FETCH_PRODUCT_DATASHEETS_SUCCESS`;

export const UPLOAD_DATASHEET = `${NAME}/UPLOAD_DATASHEET`;
export const UPLOAD_DATASHEET_FAILURE = `${NAME}/UPLOAD_DATASHEET_FAILURE`;
export const UPLOAD_DATASHEET_SUCCESS = `${NAME}/UPLOAD_DATASHEET_SUCCESS`;

export const DELETE_DATASHEET = `${NAME}/DELETE_DATASHEET`;
export const DELETE_DATASHEET_FAILURE = `${NAME}/DELETE_DATASHEET_FAILURE`;
export const DELETE_DATASHEET_SUCCESS = `${NAME}/DELETE_DATASHEET_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const SET_ELEMENT_TO_DELETE = `${NAME}/SET_ELEMENT_TO_DELETE`;
export const SHOW_FORM = `${NAME}/SHOW_FORM`;

export const OPEN_CONFIRM_MODAL = `${NAME}/OPEN_CONFIRM_MODAL`;
export const SET_DATA_CONFIRM_MODAL = `${NAME}/SET_DATA_CONFIRM_MODAL`;
export const RESET_UPLOAD_MODEL = `${NAME}/RESET_UPLOAD_MODEL`;

export const DOWNLOAD_DATASHEET = `${NAME}/DOWNLOAD_DATASHEET`;
export const DOWNLOAD_DATASHEET_SUCCESS = `${NAME}/DOWNLOAD_DATASHEET_SUCCESS`;
export const DOWNLOAD_DATASHEET_FAILURE = `${NAME}/DOWNLOAD_DATASHEET_FAILURE`;
