import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Skeleton } from 'sunwise-ui';

const PlaceholderList = ({ children, isEmpty, ready }) => {
    const { t } = useTranslation();

    if (ready) {
        if (isEmpty) return <p>{t('There are no projects')}</p>;
        return children;
    }

    return (
        <Card>
            <Card.Body>
                <Skeleton variant="rounded" />
            </Card.Body>
        </Card>
    );
};

PlaceholderList.propTypes = {
    children: PropTypes.node,
    isEmpty: PropTypes.bool,
    ready: PropTypes.bool,
};

export default PlaceholderList;
