import { activateItem } from 'common/api/v1/product';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    ACTIVATE_ITEM,
    ACTIVATE_ITEM_FAILURE,
    ACTIVATE_ITEM_SUCCESS,
    FETCH_ITEMS_SUCCESS,
} from '../actionTypes';
import { branchOfficesActions } from '../reducer';
import * as selectors from '../selectors';

export default (id) => (dispatch, getState) => {
    dispatch(branchOfficesActions[ACTIVATE_ITEM]());

    const state = getState();
    const data = selectors.getDataFetchItems(state);
    const newItems = data.map((item) => {
        return item.id === id ? { ...item, is_active: !item.is_active } : item;
    });

    dispatch(branchOfficesActions[FETCH_ITEMS_SUCCESS](newItems));

    activateItem(id)
        .then((response) => {
            dispatch(
                branchOfficesActions[ACTIVATE_ITEM_SUCCESS](response.data),
            );

            showToast();
        })
        .catch((error) => {
            dispatch(
                branchOfficesActions[ACTIVATE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
