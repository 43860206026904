import React, { useEffect } from 'react';

import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Alert } from 'sunwise-ui';

import AccordionProduct from 'common/components/accordions/AccordionProduct';
import { IconInverter } from 'common/components/icons';
import { DEBOUNCE_TIME } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { numberFormat } from 'common/utils/helpers';

import * as proposalGeneratorPanelTableActions from '../proposalGeneratorPanelTable/actions';
import {
    handleClickDelete,
    handleNormalizeDiscountField,
    handleNormalizeField,
    handleNormalizeFieldsBuild,
    handleNormalizeMarginField,
    handleNormalizeModel,
    handleNormalizeModelFieldBuild,
    handleNormalizePercentageDiscountField,
    handleRemoveBuild,
} from '../proposalGeneratorQuote/helpers';
import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';

import * as actions from './actions';
import CardForm from './components/CardForm';
import { handleChangeBuild } from './helpers';
import * as selectors from './selectors';

const Container = ({
    createProduct,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    deleteProduct,
    getPermissionsByCode,
    initialize,
    inverters,
    isFetching,
    isLoading,
    isLocked = false,
    isUpdating,
    offerInverters,
    offerPanelExpansion,
    offerPanels,
    proposalId,
    save,
    totalCost,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isEmpty(offerInverters)) {
            initialize(offerInverters, offerPanelExpansion);
        }
    }, [offerInverters]);

    const { canModify: canModifyProduct } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_INVERTERS_PERMISSION,
    );

    const { canModify: canModifyTotal } = getPermissionsByCode(
        PERMISSION_LIST.SEE_TOTAL_PRODUCTS,
    );

    const handleOnSave = React.useRef(debounce(save, DEBOUNCE_TIME)).current;

    const handleChange = handleChangeBuild(handleOnSave, proposalId);

    const handleNormalizeFields = handleNormalizeFieldsBuild(
        createProduct,
        handleOnSave,
        proposalId,
    );

    const handleNormalizeModelField = handleNormalizeModelFieldBuild(
        createProduct,
        handleOnSave,
        proposalId,
    );

    const handleRemove = handleRemoveBuild(deleteProduct, proposalId);

    const isLoadingForm = isFetching || isLoading || isUpdating;

    const isLockedForm = isLoadingForm || isLocked;

    const numOfferPanels = offerPanels?.length || 0;

    return (
        <AccordionProduct
            caption={t('Total price')}
            cardId="proposal_generator_inverter_card"
            isLoading={isLoadingForm}
            svgIcon={<IconInverter />}
            title={t('Inverter', { count: 2 })}
            total={
                canModifyTotal
                    ? numberFormat(totalCost, {
                          currency: currencyIso,
                          locale: currencyLocale,
                          style: 'currency',
                      })
                    : null
            }
        >
            {!isLoading && numOfferPanels > 1 && (
                <Alert severity="warning">
                    {t(
                        'Compatibility calculation is disabled if more than one panel is selected',
                    )}
                </Alert>
            )}

            <CardForm
                canModifyProduct={canModifyProduct}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                handleChange={handleChange}
                handleClickDelete={handleClickDelete}
                handleNormalizeDiscountField={handleNormalizeDiscountField}
                handleNormalizeField={handleNormalizeField}
                handleNormalizeFields={handleNormalizeFields}
                handleNormalizeMarginField={handleNormalizeMarginField}
                handleNormalizeModel={handleNormalizeModel}
                handleNormalizeModelField={handleNormalizeModelField}
                handleNormalizePercentageDiscountField={
                    handleNormalizePercentageDiscountField
                }
                handleRemove={handleRemove}
                isLoading={isLoadingForm}
                isLocked={isLockedForm}
                itemsData={inverters}
                numOfferPanels={numOfferPanels}
                offerItems={
                    offerInverters &&
                    offerInverters.map((item) => item.inverter_branch_office)
                }
                proposalId={proposalId}
            />
        </AccordionProduct>
    );
};

const mapStateToProps = createStructuredSelector({
    inverters: proposalGeneratorQuoteSelectors.getProposalInverterCatalogs,
    isFetching: selectors.getIsFetchingOfferInverters,
    isUpdating: selectors.getIsUpdating,
    offerInverters: proposalGeneratorQuoteSelectors.getOfferInvertersData,
    offerPanelExpansion: proposalGeneratorQuoteSelectors.getOfferPanelExpansion,
    offerPanelId: proposalGeneratorQuoteSelectors.getOfferPanelId,
    offerPanels: proposalGeneratorQuoteSelectors.getOfferPanelsData,
    panelNumber: proposalGeneratorQuoteSelectors.getOfferPanelNumber,
    totalCost: proposalGeneratorQuoteSelectors.getFinalCostInverters,
});

const mapDispatchToProps = (dispatch) => ({
    createProduct: (proposalId, values) =>
        dispatch(actions.create(proposalId, values)),
    deleteProduct: (id, proposalId, successCallback) =>
        dispatch(actions.delete(id, proposalId, successCallback)),
    initialize: (values, offerPanelExpansion) =>
        dispatch(actions.initialValues(values, offerPanelExpansion)),
    save: (proposalId, valuesForm) =>
        dispatch(actions.save(proposalId, valuesForm)),
    saveExpansionField: (proposalId, valuesForm) =>
        dispatch(
            proposalGeneratorPanelTableActions.saveExpansionField(
                proposalId,
                valuesForm,
            ),
        ),
});

Container.propTypes = {
    createProduct: PropTypes.func,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    deleteProduct: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    initialize: PropTypes.func,
    inverters: PropTypes.array,
    isFetching: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isUpdating: PropTypes.bool,
    offerInverters: PropTypes.array,
    offerPanelExpansion: PropTypes.number,
    offerPanels: PropTypes.array,
    proposalId: PropTypes.string,
    save: PropTypes.func,
    totalCost: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.PROPOSAL_INVERTERS_PERMISSION,
        PERMISSION_LIST.SEE_TOTAL_PRODUCTS,
    ]),
)(Container);
