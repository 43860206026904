import i18next from 'i18next';

import alerts from 'common/modules/alerts';

export default () => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: [
            i18next.t(
                'This proposal already has a financial history in progress. Please generate the proposal again to continue with the follow-up',
            ),
        ],
        title: i18next.t('Warning'),
        type: alerts.ALERT_TYPE_ALERT,
        variant: 'warning',
    };
    dispatch(alerts.actions.show(dataAlert));
};
