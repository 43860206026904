import fetchContacts from './fetchContacts';
import fetchProjects from './fetchProjects';
import fetchProposals from './fetchProposals';
import setRecentSearches from './setRecentSearches';

export default (q, permissions) => (dispatch) => {
    if (!q) return;

    let promises = [];

    if (permissions.canViewContacts) promises.push(dispatch(fetchContacts(q)));

    if (permissions.canViewProjects) promises.push(dispatch(fetchProjects(q)));

    if (permissions.canViewProposals)
        promises.push(dispatch(fetchProposals(q)));

    Promise.all(promises)
        .then(() => dispatch(setRecentSearches([q])))
        .catch();
};
