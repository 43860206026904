import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from 'sunwise-ui';

const EnterprisePricing = ({ selectedTheme }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Header
                sx={{
                    display: 'flex',
                    backgroundColor: 'primary.main',
                    color: 'inherit',
                }}
            >
                <Typography
                    variant="h4"
                    color={selectedTheme === 'dark' && '#000'}
                    sx={{ fontSize: { xs: '1.25rem', lg: '1.5rem' } }}
                >
                    Enterprise
                </Typography>
            </Card.Header>
            <Card.Body>
                <Box sx={{ height: 160 }}>
                    <Typography
                        variant="h4"
                        sx={{ fontSize: { xs: '1.25rem', lg: '1.4rem' } }}
                    >
                        {t('Talk to sales')}
                    </Typography>

                    <Typography variant="body2">
                        {t('Unlimited users')}
                    </Typography>
                    <Typography variant="body2">
                        {t('Unlimited projects')}
                    </Typography>
                    <Typography variant="body2">
                        {t('Multi-branch office', { count: 2 })}
                    </Typography>
                </Box>
                <Box sx={{ minHeight: 40 }} mt={2}></Box>
            </Card.Body>
        </Card>
    );
};

EnterprisePricing.propTypes = {
    selectedTheme: PropTypes.string,
};

export default EnterprisePricing;
