import React from 'react';

import PropTypes from 'prop-types';

const IconAdditional = ({
    color = '#7046bc',
    height = '18px',
    width = '18px',
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM14 10H10V14H8V10H4V8H8V4H10V8H14V10Z"
            fill={color}
        />
    </svg>
);

IconAdditional.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconAdditional;
