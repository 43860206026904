import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Divider, Typography } from 'sunwise-ui';

const Container = styled('div')`
    align-items: center;
    display: grid;
    height: ${({ viewHeight }) => viewHeight};
    padding: ${({ padding = '0' }) => padding};
    width: 100%;
`;

const Text = styled(Typography)`
    font-size: 27px;
    font-weight: bold;
`;

const Description = styled(Typography)`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const SectionHeader = ({ description, title }) => (
    <Container viewHeight="auto">
        <Text component="h2">{title}</Text>
        {description && <Description>{description}</Description>}
        <Divider sx={{ mb: 2 }} />
    </Container>
);

SectionHeader.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
};

export default SectionHeader;
