import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Grid, IconButton, Tooltip } from 'sunwise-ui';

import DiscountTypeGroup from 'common/components/DiscountTypeGroup';
import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import { DISCOUNT_TYPES } from 'common/constants';

const ReduxFieldArrayTaxes = ({
    control,
    currencySymbol,
    formValues,
    getValues,
    name,
    setValue,
}) => {
    const { t } = useTranslation();

    const { append, fields, remove } = useFieldArray({ control, name });

    const handleOnBlurTaxValue = (i) => {
        const value = getValues(`${name}.${i}.tax_value`);
        if (!value) setValue(`${name}.${i}.tax_value`, 0);
    };

    return (
        <>
            {fields.map((item, i) => {
                const taxItem = formValues?.taxes?.[i] || {};
                return (
                    <Grid
                        alignItems="center"
                        container
                        key={item.id}
                        mb={2}
                        sx={{
                            '& .MuiGrid-item ': {
                                pt: { xs: 0, lg: 2 },
                            },
                        }}
                    >
                        <Grid item xs={18} md={9}>
                            <ReactHookFormInput
                                control={control}
                                label={t('Name')}
                                name={`${name}.${i}.tax_name`}
                                type="text"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={18} md={7}>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <ReactHookFormIntlNumberInput
                                    control={control}
                                    fullWidth
                                    label={t('Amount')}
                                    min={0}
                                    name={`${name}.${i}.tax_value`}
                                    onBlur={() => handleOnBlurTaxValue(i)}
                                    step="any"
                                    variant="standard"
                                />
                                <DiscountTypeGroup
                                    currencySymbol={currencySymbol}
                                    handleOnChange={(value) => {
                                        const isPercentage = value === 1;
                                        setValue(
                                            `${name}.${i}.is_percentage`,
                                            isPercentage,
                                        );
                                    }}
                                    selected={
                                        taxItem.is_percentage
                                            ? DISCOUNT_TYPES.PERCENTAGE
                                            : DISCOUNT_TYPES.AMOUNT
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={18}
                            md={2}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            <Button
                                color="error"
                                fullWidth
                                onClick={() => remove(i)}
                                variant="outlined"
                            >
                                {t('Delete')}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={18}
                            md={2}
                            sx={{ display: { xs: 'none', md: 'block' } }}
                        >
                            <Tooltip title={t('Delete')}>
                                <IconButton onClick={() => remove(i)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                );
            })}
            <Divider sx={{ display: { xs: 'block', md: 'none' }, mb: 2 }} />
            <Grid container>
                <Grid item xs textAlign="right">
                    <Button
                        color="secondary"
                        endIcon={<AddIcon />}
                        onClick={() =>
                            append({
                                tax_name: '',
                                tax_value: 0,
                                is_percentage: true,
                            })
                        }
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        variant="outlined"
                    >
                        {t('Add')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

ReduxFieldArrayTaxes.propTypes = {
    control: PropTypes.object,
    currencySymbol: PropTypes.string,
    formValues: PropTypes.object,
    getValues: PropTypes.func,
    name: PropTypes.string,
    setValue: PropTypes.func,
};

export default ReduxFieldArrayTaxes;
