import i18next from 'i18next';

import { cancelCreditApp } from 'common/api/v1/sunpay';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

export default (id, onSuccess) => (dispatch) => {
    cancelCreditApp(id)
        .then(() => {
            dispatch(alerts.actions.close());
            showToast({
                body: i18next.t('It was successfully canceled'),
            });

            if (onSuccess) onSuccess();
        })
        .catch((error) => showReponseErrorsAsAlert(dispatch, error?.response));
};
