import { payInvoices } from 'common/api/v1/chargebee';

import {
    PAY_INVOICES,
    PAY_INVOICES_FAILURE,
    PAY_INVOICES_SUCCESS,
} from '../actionTypes';
import { profileInvoiceActions } from '../reducer';

import fetchSubscription from './fetchSubscription';

export default () => (dispatch) => {
    dispatch(profileInvoiceActions[PAY_INVOICES]());

    payInvoices()
        .then((response) => {
            dispatch(
                profileInvoiceActions[PAY_INVOICES_SUCCESS](response.data),
            );
            dispatch(fetchSubscription());
        })
        .catch((error) =>
            dispatch(
                profileInvoiceActions[PAY_INVOICES_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
