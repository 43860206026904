import BaseModel from './BaseModel';

export default class ConsumptionProfile extends BaseModel {
    static generate({ type = 0, ...rest }) {
        super.generate({ ...rest, type });
    }

    isCertified() {
        return this.ref.type === 0;
    }
}

ConsumptionProfile.modelName = 'ConsumptionProfile';
ConsumptionProfile.prepareFields({
    consumption: 'consumption',
    consumption_formatted: 'consumption_formatted',
    consumption_profile_csv: 'consumption_profile_csv',
    id: 'id',
    isArchived: 'is_archived',
    name: 'name',
    type: 'type',
    usage_type: 'usage_type',
    year: 'year',
});
