import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

import { fullNameBuild } from 'common/utils/helpers';

const NameFormatter = ({ row }) => (
    <Box display="flex" flexDirection="column">
        <Typography fontWeight="bold" variant="caption">
            {fullNameBuild({
                firstName: row.first_name,
                lastName: row.last_name,
                secondSurname: row.second_surname,
            })}
        </Typography>
        <Typography variant="caption">{get(row, 'email', '@')}</Typography>
    </Box>
);

NameFormatter.propTypes = {
    row: PropTypes.object,
};

export default NameFormatter;
