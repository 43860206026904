import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

/* SAVE */

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (value) => value.isSaving);

export const getSaveData = createSelector(getSave, (value) => value.data || []);

export const getErrorsSave = createSelector(getSave, (value) => value.errors);

/* OFFER END */

export const getOfferEnd = createSelector(getModel, (model) => model.offerEnd);

export const getIsSavingOfferEnd = createSelector(
    getOfferEnd,
    (value) => value.isSaving,
);

export const getOfferEndData = createSelector(
    getOfferEnd,
    (value) => value.data || [],
);

export const getOfferEndErrors = createSelector(
    getOfferEnd,
    (value) => value.errors,
);

// REFRESH FINANCING

export const getRefreshFinancing = createSelector(
    getModel,
    (model) => model.refreshFinancing,
);

export const getIsRefreshingFinancing = createSelector(
    getRefreshFinancing,
    (value) => value.isFetching,
);
