import { NAME } from './constants';

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const DELETE_NOTIFICATION = `${NAME}/DELETE_NOTIFICATION`;
export const DELETE_NOTIFICATION_FAILURE = `${NAME}/DELETE_NOTIFICATION_FAILURE`;
export const DELETE_NOTIFICATION_SUCCESS = `${NAME}/DELETE_NOTIFICATION_SUCCESS`;

export const FETCH_NAV_NOTIFICATIONS = `${NAME}/FETCH_NAV_NOTIFICATIONS`;
export const FETCH_NAV_NOTIFICATIONS_FAILURE = `${NAME}/FETCH_NAV_NOTIFICATIONS_FAILURE`;
export const FETCH_NAV_NOTIFICATIONS_SUCCESS = `${NAME}/FETCH_NAV_NOTIFICATIONS_SUCCESS`;

export const FETCH_NOTIFICATIONS = `${NAME}/FETCH_NOTIFICATIONS`;
export const FETCH_NOTIFICATIONS_FAILURE = `${NAME}/FETCH_NOTIFICATIONS_FAILURE`;
export const FETCH_NOTIFICATIONS_SUCCESS = `${NAME}/FETCH_NOTIFICATIONS_SUCCESS`;

export const MARK_ALL_NOTIFICATIONS_AS_READ = `${NAME}/MARK_ALL_NOTIFICATIONS_AS_READ`;
export const MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE = `${NAME}/MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE`;
export const MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS = `${NAME}/MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS`;

export const REMOVE_NOTIFICATION_COUNTER = `${NAME}/REMOVE_NOTIFICATION_COUNTER`;
export const REMOVE_NOTIFICATION_COUNTER_FAILURE = `${NAME}/REMOVE_NOTIFICATION_COUNTER_FAILURE`;
export const REMOVE_NOTIFICATION_COUNTER_SUCCESS = `${NAME}/REMOVE_NOTIFICATION_COUNTER_SUCCESS`;

export const UPDATE_NOTIFICATION = `${NAME}/UPDATE_NOTIFICATION`;
export const UPDATE_NOTIFICATION_FAILURE = `${NAME}/UPDATE_NOTIFICATION_FAILURE`;
export const UPDATE_NOTIFICATION_SUCCESS = `${NAME}/UPDATE_NOTIFICATION_SUCCESS`;

export const UPDATE_NOTIFICATIONS = `${NAME}/UPDATE_NOTIFICATIONS`;
