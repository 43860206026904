import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UiDialog as Dialog } from 'sunwise-ui';

import Form from './Form';

const Modal = ({
    handleClickClose,
    handleSubmitForm,
    isEdit,
    isUploading,
    resetForm,
    show,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={handleClickClose}
            onExited={() => resetForm()}
            open={show}
            size="sm"
            title={
                isEdit ? t('Edit project document') : t('Add project document')
            }
        >
            <Form
                handleClickCancel={handleClickClose}
                handleSubmitForm={handleSubmitForm}
                isUploading={isUploading}
            />
        </Dialog>
    );
};

Modal.propTypes = {
    handleClickClose: PropTypes.func,
    handleSubmitForm: PropTypes.func,
    isEdit: PropTypes.bool,
    isUploading: PropTypes.bool,
    resetForm: PropTypes.func,
    show: PropTypes.bool,
};

export default Modal;
