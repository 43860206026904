import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'sunwise-ui';

const TaxesCreateButton = ({ disabled, onClick, isSavingTaxes, visible }) => {
    const { t } = useTranslation();
    if (!visible) return null;
    return (
        <Button
            disabled={disabled}
            endIcon={<AddIcon />}
            onClick={onClick}
            sx={{ width: { xs: '100%', md: 'auto' } }}
            variant="outlined"
        >
            {isSavingTaxes ? t('Adding').concat('...') : t('Add')}
        </Button>
    );
};

TaxesCreateButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    isSavingTaxes: PropTypes.bool,
    visible: PropTypes.bool,
};

export default TaxesCreateButton;
