import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
} from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';

const AccordionForm = ({
    children,
    defaultExpanded = false,
    hasFormErrors = false,
    title,
    titleWithBadge = true,
}) => (
    <Accordion
        defaultExpanded={defaultExpanded}
        disableGutters
        sx={{ boxShadow: 'none!important', '&::before': { display: 'none' } }}
        TransitionProps={{ unmountOnExit: true }}
    >
        <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />}
            sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                flexDirection: 'row-reverse',
                mb: 2,
                p: 0,
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(90deg)',
                },
                '& .MuiAccordionSummary-content': { ml: 1 },
            }}
        >
            <TitleWithDetail variant="body2">
                {titleWithBadge ? (
                    <Badge
                        color="error"
                        content=""
                        invisible={!hasFormErrors}
                        variant="dot"
                    >
                        {title}
                    </Badge>
                ) : (
                    title
                )}
            </TitleWithDetail>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
    </Accordion>
);

AccordionForm.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    defaultExpanded: PropTypes.bool,
    hasFormErrors: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    titleWithBadge: PropTypes.bool,
};

export default AccordionForm;
