import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from 'sunwise-ui';

import InfoToolTip from 'common/components/InfoToolTip';

const TiersTooltip = ({ rateTiers }) => {
    const { t } = useTranslation();
    return (
        <InfoToolTip variant="no-wrapper">
            <Box p={1}>
                <Typography variant="subtitle2">{t('Tiers')}</Typography>

                <Divider />

                {rateTiers?.map(({ identifier, name }) => (
                    <Typography key={identifier} variant="body2" mt={1}>
                        {`${identifier + 1} - ${name}`}
                    </Typography>
                ))}
            </Box>
        </InfoToolTip>
    );
};

TiersTooltip.propTypes = {
    rateTiers: PropTypes.array,
};

export default TiersTooltip;
