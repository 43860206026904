import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'sunwise-ui';

import {
    BulkEditionMenu,
    InputSearch,
    StatusFilterSelect,
} from 'common/components';

const BulkOperationsCard = ({
    canDelete,
    filterData,
    handleClickBulkItems,
    handleOnChangeSearch,
    handleOnSelectStatus,
    prefix,
    selectedBulkOption,
    selectionModel,
    setSelectedBulkOption,
    setSelectionModel,
}) => {
    const { t } = useTranslation();

    const isArchivedList = filterData?.status === 'archived';
    const callback = () => {
        setSelectedBulkOption('');
        setSelectionModel([]);
    };

    return (
        <Card>
            <Card.Body>
                <Grid container alignItems="end" justifyContent="flex-end">
                    <Grid
                        item
                        xs={18}
                        md={8}
                        lg={isEmpty(selectionModel) ? 15 : 11}
                    >
                        <InputSearch
                            onChange={handleOnChangeSearch}
                            placeholder={t('Search')}
                            value={filterData?.searchText}
                        />
                    </Grid>
                    <Grid item xs={18} md={4} lg={3}>
                        <StatusFilterSelect
                            filterBy={filterData?.status}
                            handleSelected={handleOnSelectStatus}
                            variant="standard"
                        />
                    </Grid>
                    {!isEmpty(selectionModel) && (
                        <Grid item xs={18} md={6} lg={4}>
                            <BulkEditionMenu
                                items={[
                                    {
                                        label: t('Archive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'archived',
                                                filterData,
                                                callback,
                                            ),
                                        visible: canDelete && !isArchivedList,
                                    },
                                    {
                                        label: t('Unarchive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'active',
                                                filterData,
                                                callback,
                                            ),
                                        visible: isArchivedList,
                                    },
                                ]}
                                prefix={prefix}
                                selectedBulkOption={selectedBulkOption}
                                selectionModel={selectionModel}
                                setSelectionModel={setSelectionModel}
                            />
                        </Grid>
                    )}
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    canDelete: PropTypes.bool,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    handleOnSelectStatus: PropTypes.func,
    prefix: PropTypes.string,
    selectedBulkOption: PropTypes.string,
    selectionModel: PropTypes.array,
    setSelectedBulkOption: PropTypes.func,
    setSelectionModel: PropTypes.func,
};

export default BulkOperationsCard;
