import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import { DEFAULT_VALUES } from '../constants';

import save from './save';

export default () => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Restore to default'),
        messages: [i18next.t('Are you sure you want to restore to defaults?')],
        onSuccess: () =>
            dispatch(save({ ...DEFAULT_VALUES, restore_to_default: true })),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
