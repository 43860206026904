import { NAME } from './constants';

export const ACTIVATE_ITEM = `${NAME}/ACTIVATE_ITEM`;
export const ACTIVATE_ITEM_FAILURE = `${NAME}/ACTIVATE_ITEM_FAILURE`;
export const ACTIVATE_ITEM_SUCCESS = `${NAME}/ACTIVATE_ITEM_SUCCESS`;

export const CHANGE_SEARCH_TEXT = `${NAME}/CHANGE_SEARCH_TEXT`;

export const DELETE_ITEM = `${NAME}/DELETE_ITEM`;
export const DELETE_ITEM_FAILURE = `${NAME}/DELETE_ITEM_FAILURE`;
export const DELETE_ITEM_SUCCESS = `${NAME}/DELETE_ITEM_SUCCESS`;

export const FETCH_GEOCODE = `${NAME}/FETCH_GEOCODE`;
export const FETCH_GEOCODE_ERROR = `${NAME}/FETCH_GEOCODE_ERROR`;
export const FETCH_GEOCODE_SUCCESS = `${NAME}/FETCH_GEOCODE_SUCCESS`;

export const FETCH_GEOCODE_POINT = `${NAME}/FETCH_GEOCODE_POINT`;
export const FETCH_GEOCODE_POINT_FAILURE = `${NAME}/FETCH_GEOCODE_POINT_FAILURE`;
export const FETCH_GEOCODE_POINT_SUCCESS = `${NAME}/FETCH_GEOCODE_POINT_SUCCESS`;

export const FETCH_ITEMS = `${NAME}/FETCH_ITEMS`;
export const FETCH_ITEMS_FAILURE = `${NAME}/FETCH_ITEMS_FAILURE`;
export const FETCH_ITEMS_SUCCESS = `${NAME}/FETCH_ITEMS_SUCCESS`;

export const FETCH_POLITICAL_DIVISIONS = `${NAME}/FETCH_POLITICAL_DIVISIONS`;
export const FETCH_POLITICAL_DIVISIONS_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS_FAILURE`;
export const FETCH_POLITICAL_DIVISIONS_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const OPEN_MODAL_PRODUCT = `${NAME}/OPEN_MODAL_PRODUCT`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;

export const SET_SHOW_GEOCODE_RESULTS = `${NAME}/SET_SHOW_GEOCODE_RESULTS`;
