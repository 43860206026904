import PropTypes from 'prop-types';

const CollapsedElements = ({ children, isCollapsed, collapsedItem }) => {
    if (isCollapsed) return collapsedItem || null;

    return children;
};

CollapsedElements.propTypes = {
    children: PropTypes.node,
    collapsedItem: PropTypes.node,
    isCollapsed: PropTypes.bool,
};

export default CollapsedElements;
