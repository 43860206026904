import API from '../..';
const ENTITY = 'units-rates';

export const getContractedDemandUnits = () =>
    API.get(`/api/v1/${ENTITY}/ContractedDemand/`);
export const getDemandUnits = () => API.get(`/api/v1/${ENTITY}/Demand/`);
export const getEnergyUnits = () => API.get(`/api/v1/${ENTITY}/Energy/`);
export const getOthersUnits = () => API.get(`/api/v1/${ENTITY}/Others/`);
export const getSpainExcessDemandUnits = () =>
    API.get(`/api/v1/${ENTITY}/SpainExcessDemand/`);
export const getTaxesUnits = () => API.get(`/api/v1/${ENTITY}/Taxes/`);
