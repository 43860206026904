import React from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReorderIcon from '@mui/icons-material/Reorder';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import {
    Box,
    Checkbox,
    Collapse,
    Divider,
    Grid,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from 'sunwise-ui';

import { getIndicatorName } from '../helpers';

import DropdownCurrencies from './DropdownCurrencies';
import DropdownProducts from './DropdownProducts';
import DropdownYear from './DropdownYear';

const SorteableItem = ({
    currenciesForSelect,
    handleOnChangedCurrency,
    handleOnChangedYear,
    handleOnClickSettings,
    handleOnSelectItem,
    handleOnSelectedProducts,
    index,
    item,
}) => {
    const showDropdowns =
        item.is_active &&
        ['storage_price_gross_per_kwh', 'storage_price_per_kwh'].includes(
            item.name,
        );

    const showYearDropdown =
        item.is_active &&
        ['annual_saving_percentage', 'supplier_annual_saving'].includes(
            item.name,
        );

    return (
        <Draggable draggableId={item.id} id={item.id} index={index}>
            {(provided) => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <ListItem
                        disablePadding
                        secondaryAction={
                            <Box alignItems="center" display="flex" gap={2}>
                                {(showDropdowns || showYearDropdown) && (
                                    <IconButton
                                        onClick={() =>
                                            handleOnClickSettings(
                                                item.id,
                                                !item?.is_open,
                                            )
                                        }
                                    >
                                        {item?.is_open ? (
                                            <ExpandLessIcon />
                                        ) : (
                                            <ExpandMoreIcon />
                                        )}
                                    </IconButton>
                                )}
                                <Checkbox
                                    checked={item.is_active}
                                    onChange={(e) =>
                                        handleOnSelectItem(
                                            item.id,
                                            e.target.checked,
                                        )
                                    }
                                />
                            </Box>
                        }
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <ReorderIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={getIndicatorName(item.name)}
                            />
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={item?.is_open}>
                        <Box sx={{ my: 1, pl: 9 }}>
                            <Grid container>
                                {showDropdowns && (
                                    <>
                                        <Grid
                                            item
                                            xs
                                            textAlign="center"
                                            sx={{
                                                maxWidth: '250px !important',
                                            }}
                                        >
                                            <DropdownProducts
                                                defaultValue={
                                                    item?.products || []
                                                }
                                                handleOnSelectedProducts={
                                                    handleOnSelectedProducts
                                                }
                                                id={item.id}
                                                visible={showDropdowns}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                            textAlign="center"
                                            sx={{
                                                maxWidth: '250px !important',
                                            }}
                                        >
                                            <DropdownCurrencies
                                                id={item.id}
                                                options={currenciesForSelect}
                                                value={item.currency}
                                                onChange={
                                                    handleOnChangedCurrency
                                                }
                                            />
                                        </Grid>
                                    </>
                                )}

                                {showYearDropdown && (
                                    <Grid
                                        item
                                        xs
                                        textAlign="center"
                                        sx={{ maxWidth: '250px !important' }}
                                    >
                                        <DropdownYear
                                            id={item.id}
                                            onChange={handleOnChangedYear}
                                            value={item.year}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Collapse>
                    <Divider />
                </Box>
            )}
        </Draggable>
    );
};

SorteableItem.propTypes = {
    currenciesForSelect: PropTypes.array,
    handleOnChangedCurrency: PropTypes.func,
    handleOnChangedYear: PropTypes.func,
    handleOnClickSettings: PropTypes.func,
    handleOnSelectItem: PropTypes.func,
    handleOnSelectedProducts: PropTypes.func,
    index: PropTypes.number,
    item: PropTypes.object,
};

export default SorteableItem;
