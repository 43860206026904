import React, { useEffect, useState } from 'react';

import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { PLAN_TYPES } from 'common/constants';
import { useBreakpoint } from 'common/hooks';

import isotypeSunwise from 'resources/isotypeSunwise.svg';
import christmas_2 from 'resources/season_isotypes/christmas_2.svg';
import halloween1 from 'resources/season_isotypes/halloween_1.svg';
import halloween2 from 'resources/season_isotypes/halloween_2.svg';

import * as profileSelectors from '../../../modules/profile/selectors';

const getSeasonLogo = (isEnterprise) => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    if (!isEnterprise && month === 10 && day == 31)
        return { src: halloween1, width: { sm: '45px', md: '50px' } };
    if (!isEnterprise && month === 11 && day == 1)
        return { src: halloween2, width: { sm: '45px', md: '50px' } };
    if (!isEnterprise && month === 12 && day >= 15 && day <= 26)
        return { src: christmas_2, width: { sm: '45px', md: '50px' } };
    return { src: isotypeSunwise, width: { sm: '35px', md: '45px' } };
};

const Logo = ({ branding, field, isFetching, subscription }) => {
    const breakpoint = useBreakpoint();
    const subscriptionType = get(subscription, 'type.key', null);
    const isEnterprise = subscriptionType === PLAN_TYPES.ENTERPRISE;
    const seasonLogo = getSeasonLogo(isEnterprise);
    const [logo, setLogo] = useState(seasonLogo);
    const easterEgg = null;

    useEffect(() => {
        let _logo = { ...logo, width: { sm: '35px', md: '45px' } };

        if (!isEmpty(branding) && !isNull(branding[field]))
            _logo.src = branding[field];
        else {
            _logo = seasonLogo;

            /** easter egg  */
            if (easterEgg) {
                const r = Math.random();
                if (r > 0.75 && !isEnterprise && field === 'loading_image')
                    _logo.src = easterEgg;
            }
        }

        setLogo(_logo);
    }, [branding]);

    if (isFetching) return null;

    return (
        <img
            src={logo.src}
            style={{
                maxWidth: 'initial',
                width: ['xs', 'sm'].includes(breakpoint)
                    ? logo?.width?.sm
                    : logo?.width?.md,
            }}
        />
    );
};

Logo.propTypes = {
    branding: PropTypes.object,
    field: PropTypes.string,
    isFetching: PropTypes.bool,
    subscription: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    branding: profileSelectors.getBranding,
    isFetching: profileSelectors.getIsFetching,
    subscription: profileSelectors.getSubscription,
});

export default connect(mapStateToProps, null)(Logo);
