import React from 'react';

import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

const SeasonChangeWarning = () => {
    const { t } = useTranslation();
    return (
        <Alert severity="info">{t('This period has a season change')}</Alert>
    );
};

export default SeasonChangeWarning;
