import i18next from 'i18next';

import { exportFile } from 'common/api/v1/energyBackupCompany';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SET_IS_DOWNLOADING } from '../actionTypes';
import { energyBackupConfigurationActions } from '../reducer';

export default (
        { orderBy, page = 0, pageSize, searchText, sortBy, status } = {},
        downloadType = null,
    ) =>
    (dispatch) => {
        dispatch(energyBackupConfigurationActions[SET_IS_DOWNLOADING](true));
        exportFile({
            key: 'batteries',
            orderBy,
            page: page + 1,
            perPage: pageSize,
            personalized: downloadType === 'custom',
            searchText,
            sortBy,
            status,
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${i18next.t('Storage')}.csv`);
                document.body.appendChild(link);
                link.click();
                dispatch(
                    energyBackupConfigurationActions[SET_IS_DOWNLOADING](false),
                );
            })
            .catch((error) => {
                dispatch(
                    energyBackupConfigurationActions[SET_IS_DOWNLOADING](false),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
