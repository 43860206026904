import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const IconReturnInvestment = ({ color, height = '24px', width = '24px' }) => {
    const theme = useTheme();
    const fillColor = color || theme.palette.primary.main;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.66675 9C1.56675 9 0.666748 9.9 0.666748 11V18C0.666748 19.1 1.56675 20 2.66675 20C3.76675 20 4.66675 19.1 4.66675 18V11C4.66675 9.9 3.76675 9 2.66675 9Z"
                fill={fillColor}
            />
            <path
                d="M19.6567 15H12.8267C12.7167 15 12.6067 14.98 12.4967 14.94L11.0267 14.43C10.7667 14.34 10.6367 14.06 10.7267 13.8C10.8167 13.54 11.1067 13.4 11.3667 13.5L12.4867 13.93C12.5967 13.97 12.7267 14 12.8467 14H15.4767C16.1267 14 16.6567 13.47 16.6567 12.82C16.6567 12.33 16.3467 11.89 15.8867 11.71L8.96675 9.13C8.74675 9.04 8.50675 9 8.26675 9H6.66675V18.02L13.0367 19.83C13.4467 19.95 13.8867 19.93 14.2867 19.78L21.6667 17C21.6667 15.89 20.7667 15 19.6567 15Z"
                fill={fillColor}
            />
            <path
                d="M15.6667 0C12.9067 0 10.6667 2.24 10.6667 5C10.6667 7.76 12.9067 10 15.6667 10C18.4267 10 20.6667 7.76 20.6667 5C20.6667 2.24 18.4267 0 15.6667 0ZM16.1067 7.88V8.06C16.1067 8.3 15.9117 8.5 15.6667 8.5C15.4267 8.5 15.2267 8.305 15.2267 8.06V7.85C14.9117 7.775 14.2617 7.545 13.8817 6.8C13.7667 6.58 13.8767 6.305 14.1067 6.21L14.1417 6.195C14.3467 6.11 14.5767 6.195 14.6817 6.39C14.8417 6.695 15.1567 7.075 15.7417 7.075C16.2067 7.075 16.7317 6.835 16.7317 6.27C16.7317 5.79 16.3817 5.54 15.5917 5.255C15.0417 5.06 13.9167 4.74 13.9167 3.6C13.9167 3.55 13.9217 2.4 15.2267 2.12V1.94C15.2267 1.695 15.4267 1.5 15.6667 1.5C15.9067 1.5 16.1067 1.695 16.1067 1.94V2.125C16.6417 2.22 16.9817 2.505 17.1867 2.775C17.3567 2.995 17.2667 3.315 17.0067 3.425C16.8267 3.5 16.6167 3.44 16.4967 3.285C16.3567 3.095 16.1067 2.9 15.6967 2.9C15.3467 2.9 14.7917 3.085 14.7917 3.595C14.7917 4.07 15.2217 4.25 16.1117 4.545C17.3117 4.96 17.6167 5.57 17.6167 6.27C17.6167 7.585 16.3667 7.835 16.1067 7.88Z"
                fill={fillColor}
            />
        </svg>
    );
};

IconReturnInvestment.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconReturnInvestment;
