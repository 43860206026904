import { push } from 'connected-react-router';
import i18next from 'i18next';
import { get, isEmpty, isNull } from 'lodash';

import { login } from 'common/api/v1/auth';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import {
    getRuntimeLanguage,
    initProfileConfigurationActions,
} from 'common/utils/helpers/multiregion';
import { getHasPermission } from 'common/utils/helpers/permissionsV2';
import { initSessionActions } from 'common/utils/helpers/session';

import setBranding from '../../profile/actions/setBranding';
import setCurrentValues from '../../profile/actions/setCurrentValues';
import setTheme from '../../profile/actions/setTheme';
import updateConfiguration from '../../profileConfiguration/actions/updateConfiguration';
import {
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
} from '../actionTypes';
import { loginActions } from '../reducer';

const validateRedirectTo = (dispatch, hasPaymentDue, onboarding) => {
    const hasDashboardPermission = getHasPermission(
        PERMISSION_LIST.DASHBOARD_PERMISSION,
    );
    const hasContactsPermission = getHasPermission(
        PERMISSION_LIST.CONTACTS_PERMISSION,
    );
    if (hasPaymentDue) {
        dispatch(push('/profile/subscription'));
        return false;
    }
    if (!isNull(onboarding)) {
        dispatch(push('/onboarding'));
        return false;
    }
    if (hasContactsPermission) {
        dispatch(push('/contacts'));
        return false;
    } else if (hasDashboardPermission) {
        dispatch(push('/dashboard'));
        return false;
    } else {
        dispatch(push('/profile'));
        return false;
    }
};

export default (data, onSuccessCallback) => (dispatch) => {
    dispatch(loginActions[LOGIN_USER]());

    login(data)
        .then((response) => {
            const data = response.data;
            if (
                !data ||
                !data.token ||
                isEmpty(data.company_group) ||
                isEmpty(data?.permissions?.groups)
            ) {
                dispatch(
                    loginActions[LOGIN_USER_FAILURE]([
                        i18next.t(
                            'An error occurred during login. Please try later',
                        ),
                    ]),
                );

                return;
            }

            const { permissions, token, ...rest } = data;

            const hasActiveSubscription = get(
                data,
                'has_active_subscription',
                false,
            );
            const hasPaymentDue = get(data, 'has_payment_due', false);
            const onboarding = get(data, 'onboarding', null);
            const groups = get(permissions, 'groups', []);

            localStorage.setItem('token', token);
            localStorage.setItem(
                'session',
                JSON.stringify({
                    ...rest,
                    is_multibranches: permissions?.is_multibranches,
                    hasOnboarding: !isNull(onboarding),
                    has_payment_due: hasPaymentDue,
                }),
            );
            localStorage.setItem('permissions', JSON.stringify(groups));

            if (onSuccessCallback)
                onSuccessCallback(
                    rest.settings
                        ? rest.settings?.language?.key
                        : getRuntimeLanguage(),
                );

            dispatch(loginActions[LOGIN_USER_SUCCESS]());

            initSessionActions((data) => dispatch(setCurrentValues(data)));
            initProfileConfigurationActions((data) =>
                dispatch(updateConfiguration(data)),
            );

            if (!isNull(rest.settings)) {
                if (rest.settings?.mode)
                    dispatch(setTheme(rest.settings.mode.toLowerCase()));

                localStorage.setItem(
                    'selectedDrawerSize',
                    rest.settings.expanded_sidebar ? 'normal' : 'small',
                );
            }

            if (
                !isNull(rest.company_branding) &&
                !isEmpty(rest.company_branding)
            )
                dispatch(setBranding(rest.company_branding));

            if (!hasActiveSubscription && !hasPaymentDue) {
                dispatch(push('/wizard'));
                return false;
            }

            validateRedirectTo(dispatch, hasPaymentDue, onboarding);
        })
        .catch((error) =>
            dispatch(
                loginActions[LOGIN_USER_FAILURE](error?.response?.data?.errors),
            ),
        );
};
