import React from 'react';

import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { getMessage, onDragEnd } from '../helpers';

import SorteableItem from './SorteableItem';

const CardList = ({
    handleChangeSwitch,
    handleOnClick,
    isFetching,
    items,
    updateOrder,
    searchText,
    selectedItem,
}) => {
    const { t } = useTranslation();
    if (isFetching)
        return (
            <Box textAlign="center" py={2}>
                <Typography variant="body2">
                    {t('Loading currency', { count: 2 }).concat('...')}
                </Typography>
            </Box>
        );

    if (items.length === 0)
        return (
            <Box textAlign="center" py={2}>
                <Typography variant="body2">
                    {getMessage(searchText, t)}
                </Typography>
            </Box>
        );

    const handleOnDragEnd = (result) => onDragEnd(items, updateOrder, result);

    return (
        <Box display="flex" flexDirection="column">
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="sorteable-list">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {items.map((item, index) => (
                                <SorteableItem
                                    handleChangeSwitch={handleChangeSwitch}
                                    handleOnClick={handleOnClick}
                                    index={index}
                                    item={item}
                                    key={`sorteable-item-${item.id}`}
                                    selectedItem={selectedItem}
                                />
                            ))}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Box>
    );
};

CardList.propTypes = {
    handleChangeSwitch: PropTypes.func,
    handleOnClick: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    updateOrder: PropTypes.func,
    searchText: PropTypes.string,
    selectedItem: PropTypes.object,
};

export default CardList;
