export default (name, url, type) => () => {
    const link = document.createElement('a');
    const fileName = `${name}.${type}`;
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};
