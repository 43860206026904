import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button } from 'sunwise-ui';

import { ShowErrors } from 'common/components';

import * as selectors from '../selectors';

import ReassignListItems from './ReassignListItems';
import TextWithLabel from './TextWithLabel';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    margin: 26px auto;
    max-width: 500px;
    position: relative;
    width: 100%;
`;

const ListContainer = styled('form')`
    display: flex;
    flex-direction: column;
    margin-bottom: 34px;
    padding-left: 20px;
    position: relative;
`;

const ReassignTitle = styled('span')`
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 10px;
    min-height: 20px;
`;

const FormContainer = ({
    branchAgentsDictionary,
    confirmButtonText,
    errorsFetchReassignContacts,
    handleConfirm,
    handleCancel,
    initialValues,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, watch } = useForm({
        defaultValues: initialValues,
    });
    const userName = watch('userName');

    return (
        <Container>
            <TextWithLabel
                label={t('User')}
                margin="0 0 28px 0"
                padding="0 0 0 20px"
                text={userName}
            />
            <ReassignTitle>
                {t('This user has contacts in the following branches')}
            </ReassignTitle>
            <ListContainer>
                <ReassignListItems
                    branchAgentsDictionary={branchAgentsDictionary}
                    component={ReassignListItems}
                    control={control}
                    name="reassignBranches"
                />
            </ListContainer>
            <ShowErrors
                title={t('Could not allocate new agents').concat(':')}
                errors={errorsFetchReassignContacts}
            />
            <Button onClick={handleSubmit(handleConfirm)} type="button">
                {confirmButtonText}
            </Button>
            <Button color="secondary" onClick={handleCancel} variant="text">
                {t('Cancel')}
            </Button>
        </Container>
    );
};

FormContainer.propTypes = {
    branchAgentsDictionary: PropTypes.object,
    confirmButtonText: PropTypes.string,
    errorsFetchReassignContacts: PropTypes.array,
    handleConfirm: PropTypes.func,
    handleCancel: PropTypes.func,
    initialValues: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    branchAgentsDictionary: selectors.getBranchAgentsDictionary,
    errorsFetchReassignContacts: selectors.getErrorsFetchReassignContacts,
    initialValues: selectors.getReassignInitialValues,
});

export default connect(mapStateToProps, null)(FormContainer);
