import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'onboarding';

export const finishOnboarding = (data) =>
    API.post(`/api/v1/${ENTITY}/finish/`, data, config);
export const getAccessoryBrands = () =>
    API.get(`/api/v1/${ENTITY}/accessories-brands/`);
export const getBatteryBrands = () =>
    API.get(`/api/v1/${ENTITY}/batteries-brands/`);
export const getBatteryProductsByBrand = (brandName) =>
    API.get(`/api/v1/${ENTITY}/batteries-products/`, {
        params: {
            brand_name: brandName,
        },
    });
export const getInverterBrands = () =>
    API.get(`/api/v1/${ENTITY}/inverters-brands/`);
export const getInverterProductsByBrand = (brandName) =>
    API.get(`/api/v1/${ENTITY}/inverters-products/`, {
        params: {
            brand_name: brandName,
        },
    });
export const getMemberRoles = () => API.get(`/api/v1/${ENTITY}/members-roles/`);
export const getOnboarding = () => API.get(`/api/v1/${ENTITY}/`);
export const getPanelBrands = () => API.get(`/api/v1/${ENTITY}/panels-brands/`);
export const getPanelProductsByBrand = (brandName) =>
    API.get(`/api/v1/${ENTITY}/panels-products/`, {
        params: {
            brand_name: brandName,
        },
    });
export const saveCompany = (data) =>
    API.put(`/api/v1/${ENTITY}/company/`, data);
export const saveDetails = (data) =>
    API.put(`/api/v1/${ENTITY}/details/`, data, config);
export const updateSection = (data) =>
    API.patch(`/api/v1/${ENTITY}/`, data, config);
export const validateEmail = (data) =>
    API.post(`/api/v1/${ENTITY}/validate-email/`, data);
