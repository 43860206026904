import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Box, Button, UiDialog as Dialog, Typography } from 'sunwise-ui';

import * as actions from '../../actions';
import {
    getActiveIndicators,
    handleOnDragEnd,
    handleOnSelectItem,
} from '../../helpers';
import * as selectors from '../../selectors';

import SortableList from './SortableList';

const IndicatorsSettingsModal = ({
    handleClickCloseModal,
    indicatorsSettingsData,
    isOpenIndicatorsSettingsModal,
    saveIndicatorsSettings,
}) => {
    const { t } = useTranslation();
    const [indicatorsList, setIndicatorsList] = useState([]);

    useEffect(() => {
        if (!isEmpty(indicatorsSettingsData))
            setIndicatorsList(indicatorsSettingsData);
    }, [indicatorsSettingsData]);

    const onDragEnd = (result) =>
        handleOnDragEnd(indicatorsList, result, setIndicatorsList);

    const onSelectItem = (id, value) =>
        handleOnSelectItem(indicatorsList, id, setIndicatorsList, value);

    const activeIndicators = getActiveIndicators(indicatorsList);

    return (
        <Dialog
            onClose={handleClickCloseModal}
            open={isOpenIndicatorsSettingsModal}
            size="md"
            title={t('Indicators settings')}
        >
            <SortableList
                indicators={indicatorsList}
                onDragEnd={onDragEnd}
                onSelectItem={onSelectItem}
            />
            <Box hidden={activeIndicators > 0} my={1}>
                <Alert severity="warning">
                    <Typography variant="caption" fontWeight="bold">
                        {t('At least one active indicator is required')}
                    </Typography>
                </Alert>
            </Box>
            <Box mt={2} textAlign="right">
                <Button
                    disabled={isEmpty(indicatorsList) || activeIndicators === 0}
                    onClick={() => saveIndicatorsSettings(indicatorsList)}
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                >
                    {t('Save changes')}
                </Button>
            </Box>
        </Dialog>
    );
};
const mapStateToProps = createStructuredSelector({
    indicatorsSettingsData: selectors.getIndicatorsSettingsData,
    isOpenIndicatorsSettingsModal: selectors.getIsOpenIndicatorsSettingsModal,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCloseModal: () =>
        dispatch(actions.setIsOpenIndicatorsSettingsModal(false)),
    saveIndicatorsSettings: (values) =>
        dispatch(actions.saveIndicatorsSettings(values)),
});

IndicatorsSettingsModal.propTypes = {
    handleClickCloseModal: PropTypes.func,
    indicatorsSettingsData: PropTypes.array,
    isOpenIndicatorsSettingsModal: PropTypes.bool,
    saveIndicatorsSettings: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IndicatorsSettingsModal);
