import { ORM } from 'redux-orm';

import { modelPopulateByArray } from './helpers';
import CommercialOffer from './models/CommercialOffer';
import CompanyCatalog from './models/CompanyCatalog';
import ConsumptionProfile from './models/ConsumptionProfile';
import Country from './models/Country';
import Currency from './models/Currency';
import CustomTemplate from './models/CustomTemplate';
import Document from './models/Document';
import DocumentType from './models/DocumentType';
import InteractiveProject from './models/InteractiveProject';
import Language from './models/Language';
import LinkedProposal from './models/LinkedProposal';
import ProjectCommercialOffer from './models/ProjectCommercialOffer';
import Rate from './models/Rate';
import SmartDocument from './models/SmartDocument';
import SupplierUtility from './models/SupplierUtility';
import TemplateCategory from './models/TemplateCategory';
import Timeline from './models/Timeline';
import TimelineEvent from './models/TimelineEvent';
import UnitRate from './models/UnitRate';

const orm = new ORM({
    stateSelector: (state) => state.entities,
});

orm.register(
    CommercialOffer,
    CompanyCatalog,
    ConsumptionProfile,
    Country,
    Currency,
    CustomTemplate,
    Document,
    DocumentType,
    InteractiveProject,
    Language,
    LinkedProposal,
    ProjectCommercialOffer,
    Rate,
    SmartDocument,
    SupplierUtility,
    TemplateCategory,
    Timeline,
    TimelineEvent,
    UnitRate,
);
export { orm as default, modelPopulateByArray };
