import React from 'react';

import { Typography } from 'sunwise-ui';

import { COMPANY_BRANCH_NAME } from '../constants';

const renderText = (cell) => {
    if (cell === null) return COMPANY_BRANCH_NAME;
    if (cell && cell.name) return cell.name;
    return '---';
};

const BranchOfficeFormatter = (cell) => (
    <Typography variant="caption">{renderText(cell)}</Typography>
);

export default BranchOfficeFormatter;
