import isNil from 'lodash/isNil';
import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (values) => {
    const {
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
        NO_DECIMAL_VALIDATION_TEXT,
        getMinValueText,
        getMaxValueText,
    } = getValidationTexts();
    return object({
        azimuth: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .max(360, getMaxValueText(360))
                .nullable();
        }),
        col_spacing: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .nullable();
        }),
        frame_col_spacing: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .nullable();
        }),
        frame_cols_number: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(1, getMinValueText(1))
                .integer(NO_DECIMAL_VALIDATION_TEXT)
                .nullable();
        }),
        frame_row_spacing: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .nullable();
        }),
        frame_rows_number: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(1, getMinValueText(1))
                .integer(NO_DECIMAL_VALIDATION_TEXT)
                .nullable();
        }),
        height: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .nullable();
        }),
        name: string().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema.required(REQUIRED_TEXT).nullable();
        }),
        orientation: string().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema.required(REQUIRED_TEXT).nullable();
        }),
        row_spacing: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .nullable();
        }),
        safe_zone: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .nullable();
        }),
        solar_modules_number: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .nullable();
        }),
        tilt: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .max(90, getMaxValueText(90))
                .nullable();
        }),
    });
};
