import React from 'react';

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Tooltip } from 'sunwise-ui';

import StatusIndicatorTooltip from 'common/components/StatusIndicatorTooltip';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';

import StyledName from './StyledName';

const NameFormatter = ({ row }) => {
    const isGenerated = row.status === COMMERCIAL_OFFER_STATUS.FINISHED.key;
    const to = isGenerated
        ? `/proposal-review-pro/${row.id}`
        : `/proposal/${row.id}`;

    return (
        <Link color="inherit" component={RouterLink} to={to} underline="none">
            <Box
                alignItems="center"
                display="flex"
                gap={1}
                sx={{ cursor: 'pointer' }}
            >
                <StatusIndicatorTooltip
                    animated
                    isApproved={row?.has_approbed || false}
                    isGenerated={isGenerated}
                />
                <Tooltip title={row?.name} placement="top">
                    <StyledName
                        variant="caption"
                        fontWeight="bold"
                        sx={{
                            width: '175px',
                            maxWidth: '175px',
                        }}
                    >
                        {row?.name}
                    </StyledName>
                </Tooltip>
            </Box>
        </Link>
    );
};

NameFormatter.propTypes = {
    row: PropTypes.object,
};

export default NameFormatter;
