import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box, Chip } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

import { getProposerIndex } from '../helpers';

import ReduxFieldArray from './ReduxFieldArray';

const CategoryWrapper = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 8px;
    padding-top: 16px;
    width: 100%;
`;

const PermissionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow-y: scroll;
    padding-right: 8px;
    width: 100%;
    ${scroll.custom}
`;

const StyledChip = styled(Chip)(({ theme }) => ({
    '&.MuiChip-outlined': {
        borderColor: '#1f3c53',
        color:
            theme.palette.mode === 'dark'
                ? theme.palette.primary.main
                : '#1f3c53',
        textTransform: 'capitalize',
        fontWeight: 600,
        '&.selected, &:focus, &:hover': {
            backgroundColor:
                theme.palette.mode === 'dark'
                    ? theme.palette.primary.main
                    : 'rgb(169,181,192)',
            color: theme.palette.mode === 'dark' ? '#ffffff' : '#1f3c53',
        },
    },
}));

const PermissionList = ({
    control,
    formValues,
    isDisabled,
    isFetching,
    isOwner,
    setValue,
    subscription,
}) => {
    const categories = get(formValues, 'categories', []);

    if (isFetching)
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );

    return (
        <Box display="flex" flexDirection="column" gap={2} p={2}>
            <CategoryWrapper>
                {categories.map((item) => (
                    <StyledChip
                        className={
                            item.id === formValues?.category ? 'selected' : ''
                        }
                        key={`chip-${item.id}`}
                        label={item?.name?.toLowerCase()}
                        onClick={() => setValue('category', item?.id)}
                        variant="outlined"
                    />
                ))}
            </CategoryWrapper>
            <PermissionWrapper>
                <ReduxFieldArray
                    category={formValues?.category}
                    control={control}
                    disabled={isDisabled}
                    isOwner={isOwner}
                    proposerIndex={getProposerIndex(categories)}
                    setValue={setValue}
                    subscription={subscription}
                />
            </PermissionWrapper>
        </Box>
    );
};

PermissionList.propTypes = {
    control: PropTypes.object,
    formValues: PropTypes.object,
    isDisabled: PropTypes.bool,
    isFetching: PropTypes.bool,
    isOwner: PropTypes.bool,
    setValue: PropTypes.func,
    subscription: PropTypes.object,
};

export default PermissionList;
