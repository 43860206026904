import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_ACCESSORY_BRANDS,
    FETCH_ACCESSORY_BRANDS_FAILURE,
    FETCH_ACCESSORY_BRANDS_SUCCESS,
    FETCH_BATTERY_BRANDS,
    FETCH_BATTERY_BRANDS_FAILURE,
    FETCH_BATTERY_BRANDS_SUCCESS,
    FETCH_BATTERY_PRODUCTS,
    FETCH_BATTERY_PRODUCTS_FAILURE,
    FETCH_BATTERY_PRODUCTS_SUCCESS,
    FETCH_DOLLAR_PRICE,
    FETCH_DOLLAR_PRICE_FAILURE,
    FETCH_DOLLAR_PRICE_SUCCESS,
    FETCH_INVERTER_BRANDS,
    FETCH_INVERTER_BRANDS_FAILURE,
    FETCH_INVERTER_BRANDS_SUCCESS,
    FETCH_INVERTER_PRODUCTS,
    FETCH_INVERTER_PRODUCTS_FAILURE,
    FETCH_INVERTER_PRODUCTS_SUCCESS,
    FETCH_MEMBER_ROLES,
    FETCH_MEMBER_ROLES_FAILURE,
    FETCH_MEMBER_ROLES_SUCCESS,
    FETCH_ONBOARDING,
    FETCH_ONBOARDING_FAILURE,
    FETCH_ONBOARDING_SUCCESS,
    FETCH_PANEL_BRANDS,
    FETCH_PANEL_BRANDS_FAILURE,
    FETCH_PANEL_BRANDS_SUCCESS,
    FETCH_PANEL_PRODUCTS,
    FETCH_PANEL_PRODUCTS_FAILURE,
    FETCH_PANEL_PRODUCTS_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    RESET_SAVE_ERRORS,
    SAVE_AND_FINISH_ONBOARDING,
    SAVE_AND_FINISH_ONBOARDING_FAILURE,
    SAVE_AND_FINISH_ONBOARDING_SUCCESS,
    SAVE_COMPANY,
    SAVE_COMPANY_FAILURE,
    SAVE_COMPANY_SUCCESS,
    SAVE_DETAILS,
    SAVE_DETAILS_FAILURE,
    SAVE_DETAILS_SUCCESS,
    SAVE_INVERTERS_FAILURE,
    SAVE_PANELS_FAILURE,
    SET_COMPLETED_STEP,
    SET_CURRENT_STEP,
    SET_IS_OPEN_MODAL,
    UPDATE_SECTION,
    UPDATE_SECTION_FAILURE,
    UPDATE_SECTION_SUCCESS,
    VALIDATE_EMAIL,
    VALIDATE_EMAIL_FAILURE,
    VALIDATE_EMAIL_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    accessoryBrands: {
        data: [],
        errors: [],
        isFetching: false,
    },
    batteryBrands: {
        data: [],
        errors: [],
        isFetching: false,
    },
    batteryProducts: {
        data: [],
        errors: [],
        isFetching: false,
    },
    completed: {},
    fetchDollarPrice: {
        data: {},
        errors: {},
        isFetching: false,
    },
    finishOnboarding: {
        data: {},
        errors: {},
        isSaving: false,
    },
    initialValues: {
        batteries: [],
        battery_brand: '',
        business_name: '',
        city: '',
        contact_email: '',
        contact_telephone: '',
        currency_obj: {},
        currency: null,
        description: null,
        dollar_price: 0,
        exchange_type: '',
        exchangeRateId: null,
        inverse_dollar_price: 0,
        inverter_brand: '',
        inverters: [],
        is_enabled: false,
        latitude: null,
        longitude: null,
        members: [],
        name: '',
        panel_brand: '',
        panels: [],
        political_division1: null,
        positionId: null,
        rfc: '',
        source: 0,
        step: null,
        taxes: [],
        zip_code: null,
    },
    inverterBrands: {
        data: [],
        errors: [],
        isFetching: false,
    },
    inverterProducts: {
        data: [],
        errors: [],
        isFetching: false,
    },
    isOpenModal: true,
    memberRoles: {
        data: [],
        errors: [],
        isFetching: false,
    },
    onboarding: {
        data: {},
        errors: [],
        isFetching: false,
    },
    panelBrands: {
        data: [],
        errors: [],
        isFetching: false,
    },
    panelProducts: {
        data: [],
        errors: [],
        isFetching: false,
    },
    saveCompany: {
        data: {},
        errors: [],
        isSaving: false,
    },
    saveDetails: {
        data: {},
        errors: [],
        isSaving: false,
    },
    saveInvertersValidationErors: [],
    savePanelsValidationErrors: [],
    step: 0,
    updateSection: {
        errors: [],
        isUpdating: false,
    },
    validateEmail: {
        errors: [],
        isValidating: false,
    },
};

const onboardingWizardSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_ACCESSORY_BRANDS]: (state) => {
            state.accessoryBrands = {
                ...state.accessoryBrands,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ACCESSORY_BRANDS_FAILURE]: (state, action) => {
            state.accessoryBrands = {
                ...state.accessoryBrands,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ACCESSORY_BRANDS_SUCCESS]: (state, action) => {
            state.accessoryBrands = {
                ...state.accessoryBrands,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BATTERY_BRANDS]: (state) => {
            state.batteryBrands = {
                ...state.batteryBrands,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_BATTERY_BRANDS_FAILURE]: (state, action) => {
            state.batteryBrands = {
                ...state.batteryBrands,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BATTERY_BRANDS_SUCCESS]: (state, action) => {
            state.batteryBrands = {
                ...state.batteryBrands,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BATTERY_PRODUCTS]: (state) => {
            state.batteryProducts = {
                ...state.batteryProducts,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_BATTERY_PRODUCTS_FAILURE]: (state, action) => {
            state.batteryProducts = {
                ...state.batteryProducts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BATTERY_PRODUCTS_SUCCESS]: (state, action) => {
            state.batteryProducts = {
                ...state.batteryProducts,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOLLAR_PRICE]: (state) => {
            state.fetchDollarPrice = {
                ...state.fetchDollarPrice,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_DOLLAR_PRICE_FAILURE]: (state, action) => {
            state.fetchDollarPrice = {
                ...state.fetchDollarPrice,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOLLAR_PRICE_SUCCESS]: (state, action) => {
            state.fetchDollarPrice = {
                ...state.fetchDollarPrice,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTER_BRANDS]: (state) => {
            state.inverterBrands = {
                ...state.inverterBrands,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_INVERTER_BRANDS_FAILURE]: (state, action) => {
            state.inverterBrands = {
                ...state.inverterBrands,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTER_BRANDS_SUCCESS]: (state, action) => {
            state.inverterBrands = {
                ...state.inverterBrands,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTER_PRODUCTS]: (state) => {
            state.inverterProducts = {
                ...state.inverterProducts,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_INVERTER_PRODUCTS_FAILURE]: (state, action) => {
            state.inverterProducts = {
                ...state.inverterProducts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTER_PRODUCTS_SUCCESS]: (state, action) => {
            state.inverterProducts = {
                ...state.inverterProducts,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_MEMBER_ROLES]: (state) => {
            state.memberRoles = {
                ...state.memberRoles,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_MEMBER_ROLES_FAILURE]: (state, action) => {
            state.memberRoles = {
                ...state.memberRoles,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_MEMBER_ROLES_SUCCESS]: (state, action) => {
            state.memberRoles = {
                ...state.memberRoles,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ONBOARDING]: (state) => {
            state.onboarding = {
                ...state.onboarding,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ONBOARDING_FAILURE]: (state, action) => {
            state.onboarding = {
                ...state.onboarding,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ONBOARDING_SUCCESS]: (state, action) => {
            state.onboarding = {
                ...state.onboarding,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANEL_BRANDS]: (state) => {
            state.panelBrands = {
                ...state.panelBrands,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PANEL_BRANDS_FAILURE]: (state, action) => {
            state.panelBrands = {
                ...state.panelBrands,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANEL_BRANDS_SUCCESS]: (state, action) => {
            state.panelBrands = {
                ...state.panelBrands,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANEL_PRODUCTS]: (state) => {
            state.panelProducts = {
                ...state.panelProducts,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PANEL_PRODUCTS_FAILURE]: (state, action) => {
            state.panelProducts = {
                ...state.panelProducts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANEL_PRODUCTS_SUCCESS]: (state, action) => {
            state.panelProducts = {
                ...state.panelProducts,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [RESET_SAVE_ERRORS]: (state) => {
            state.finishOnboarding.errors = [];
            state.saveCompany.errors = [];
            state.saveDetails.errors = [];
            state.saveInvertersValidationErors = [];
            state.savePanelsValidationErrors = [];
        },
        [SAVE_AND_FINISH_ONBOARDING]: (state) => {
            state.finishOnboarding = {
                ...state.finishOnboarding,
                errors: {},
                isSaving: true,
            };
        },
        [SAVE_AND_FINISH_ONBOARDING_FAILURE]: (state, action) => {
            state.finishOnboarding = {
                ...state.finishOnboarding,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_AND_FINISH_ONBOARDING_SUCCESS]: (state, action) => {
            state.finishOnboarding = {
                ...state.finishOnboarding,
                data: action.payload,
                isSaving: false,
            };
        },
        [SAVE_COMPANY]: (state) => {
            state.saveCompany = {
                ...state.saveCompany,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_COMPANY_FAILURE]: (state, action) => {
            state.saveCompany = {
                ...state.saveCompany,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_COMPANY_SUCCESS]: (state, action) => {
            state.saveCompany = {
                ...state.saveCompany,
                data: action.payload,
                isSaving: false,
            };
        },
        [SAVE_DETAILS]: (state) => {
            state.saveDetails = {
                ...state.saveDetails,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_DETAILS_FAILURE]: (state, action) => {
            state.saveDetails = {
                ...state.saveDetails,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_DETAILS_SUCCESS]: (state, action) => {
            state.saveDetails = {
                ...state.saveDetails,
                data: action.payload,
                isSaving: false,
            };
        },
        [SAVE_INVERTERS_FAILURE]: (state, action) => {
            state.saveInvertersValidationErors = action.payload;
        },
        [SAVE_PANELS_FAILURE]: (state, action) => {
            state.savePanelsValidationErrors = action.payload;
        },
        [SET_COMPLETED_STEP]: (state, action) => {
            state.completed = action.payload;
        },
        [SET_CURRENT_STEP]: (state, action) => {
            state.step = action.payload;
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
        [UPDATE_SECTION]: (state) => {
            state.updateSection = {
                ...state.updateSection,
                isUpdating: true,
            };
        },
        [UPDATE_SECTION_FAILURE]: (state, action) => {
            state.updateSection = {
                ...state.updateSection,
                errors: action.payload,
                isUpdating: false,
            };
        },
        [UPDATE_SECTION_SUCCESS]: (state) => {
            state.updateSection = {
                ...state.updateSection,
                isUpdating: false,
            };
        },
        [VALIDATE_EMAIL]: (state) => {
            state.validateEmail = {
                ...state.validateEmail,
                isValidating: true,
            };
        },
        [VALIDATE_EMAIL_FAILURE]: (state, action) => {
            state.validateEmail = {
                ...state.validateEmail,
                errors: action.payload,
                isValidating: false,
            };
        },
        [VALIDATE_EMAIL_SUCCESS]: (state) => {
            state.validateEmail = {
                ...state.validateEmail,
                isValidating: false,
            };
        },
    },
});

export const onboardingWizardActions = onboardingWizardSlice.actions;

export default onboardingWizardSlice.reducer;
