import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Card, Typography } from 'sunwise-ui';

import { FlexRow } from 'common/utils/layout';

const StyledTitle = styled(Typography)`
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.15px;
    margin-bottom: 0;
    margin-left: 12px;
`;

const SideMenuCard = ({ children, icon, title }) => (
    <Box>
        <Card>
            <Card.Header>
                <FlexRow alignItems="center">
                    {icon}
                    <StyledTitle className="text-nowrap">{title}</StyledTitle>
                </FlexRow>
            </Card.Header>
            <Card.Body>{children}</Card.Body>
        </Card>
    </Box>
);

SideMenuCard.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    icon: PropTypes.object,
    title: PropTypes.string,
};

export default SideMenuCard;
