import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

export default styled(Box)`
    display: flex;
    flex-wrap: nowrap;
    height: calc(100vh - 257px);
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 0 10px 0;

    .column .drag-funnel-project:last-child {
        margin-bottom: 15px;
    }

    ${scroll.custom()}
`;
