import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import InteractiveNotFound from 'resources/interactiveNotFound.svg?react';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 52px);
    width: 100%;
`;

const NotFound = ({ errors }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Grid container justifyContent="center">
                <Grid item xs={18} md={12} textAlign="center">
                    <InteractiveNotFound />
                </Grid>
            </Grid>
            <Grid container mt={2}>
                <Grid
                    item
                    xs={18}
                    textAlign="center"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        sx={{ color: '#3a647f' }}
                    >
                        {t('Oops! Contact your Installer!')}
                    </Typography>
                    {errors.map((error, index) => (
                        <Typography
                            fontWeight="bold"
                            key={`error-${index}`}
                            variant="body2"
                        >
                            {error}
                        </Typography>
                    ))}
                </Grid>
            </Grid>
        </Container>
    );
};

NotFound.propTypes = {
    errors: PropTypes.array,
};

export default NotFound;
