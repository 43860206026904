import { BUTTONS_ACTIONS } from './constants';

export const getShowMainButton = (
    canModifyContacts,
    canModifyProjects,
    canModifyProposals,
    canModifyTrackings,
    defaultButton,
    masterButtonMode,
    options,
) => {
    if (masterButtonMode) return options.some(({ visible }) => visible);
    switch (defaultButton) {
        case BUTTONS_ACTIONS.CREATE_PROJECT:
            return canModifyProjects;
        case BUTTONS_ACTIONS.CREATE_PROPOSAL:
            return canModifyProposals;
        case BUTTONS_ACTIONS.CREATE_TRACKING:
            return canModifyTrackings;
        default:
            return canModifyContacts;
    }
};

export const handleSelectButton = (
    defaultButton,
    handleValidateAction,
    setSelectedButton,
) => {
    // Define routes and corresponding buttons
    const routeButtonMap = {
        '/proposals': BUTTONS_ACTIONS.CREATE_PROPOSAL,
        '/record': BUTTONS_ACTIONS.CREATE_PROJECT,
        '/contacts': BUTTONS_ACTIONS.CREATE_CONTACT,
        '/follow-ups': BUTTONS_ACTIONS.CREATE_TRACKING,
    };
    // Check if the URL contains the ?tab= parameter
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('tab')) {
        const tabValue = searchParams.get('tab');
        if (tabValue === 'projects')
            setSelectedButton(
                handleValidateAction(BUTTONS_ACTIONS.CREATE_PROPOSAL),
            );
        else if (tabValue === 'tracking')
            setSelectedButton(
                handleValidateAction(BUTTONS_ACTIONS.CREATE_TRACKING),
            );
        else
            setSelectedButton(
                handleValidateAction(BUTTONS_ACTIONS.CREATE_PROJECT),
            );
    } else {
        // Sets the selected button based on the current route
        const currentButton =
            routeButtonMap[location.pathname] || defaultButton;
        setSelectedButton(handleValidateAction(currentButton));
    }
};

export const validateButtonBasedOnPermissions = (
    action,
    canModifyContacts,
    canModifyProjects,
    canModifyProposals,
    canModifyTrackings,
) => {
    switch (action) {
        case BUTTONS_ACTIONS.CREATE_PROPOSAL: {
            if (canModifyProposals) return BUTTONS_ACTIONS.CREATE_PROPOSAL;
            else if (canModifyProjects) return BUTTONS_ACTIONS.CREATE_PROJECT;
            else if (canModifyContacts) return BUTTONS_ACTIONS.CREATE_CONTACT;
            else if (canModifyTrackings) return BUTTONS_ACTIONS.CREATE_TRACKING;
            else return null;
        }
        case BUTTONS_ACTIONS.CREATE_TRACKING: {
            if (canModifyTrackings) return BUTTONS_ACTIONS.CREATE_TRACKING;
            else if (canModifyProjects) return BUTTONS_ACTIONS.CREATE_PROJECT;
            else if (canModifyContacts) return BUTTONS_ACTIONS.CREATE_CONTACT;
            else return null;
        }
        case BUTTONS_ACTIONS.CREATE_PROJECT: {
            if (canModifyProjects) return BUTTONS_ACTIONS.CREATE_PROJECT;
            else if (canModifyContacts) return BUTTONS_ACTIONS.CREATE_CONTACT;
            else if (canModifyTrackings) return BUTTONS_ACTIONS.CREATE_TRACKING;
            return null;
        }
        case BUTTONS_ACTIONS.CREATE_CONTACT: {
            if (canModifyContacts) return BUTTONS_ACTIONS.CREATE_CONTACT;
            else if (canModifyTrackings) return BUTTONS_ACTIONS.CREATE_TRACKING;
            return null;
        }
        default:
            return null;
    }
};
