import API from '../..';
const ENTITY = 'currencies';

export const filterItems = ({
    orderBy = null,
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/${searchText}`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            sort: sortBy,
        },
    });
