import { SET_OFFER_SMART_DOCUMENT_FORM_MODE } from '../../actionTypes';
import { OFFER_SMART_DOCUMENT_FORM_CREATE_MODE } from '../../constants';
import { multiTemplateReviewActions } from '../../reducer';

import initializeSmartDocumentForm from './initializeOfferSmartDocumentForm';
import openOfferSmartDocumentModal from './openOfferSmartDocumentModal';

export default ({ projectId = '', proposalId = '' } = {}) =>
    (dispatch) => {
        dispatch(
            multiTemplateReviewActions[SET_OFFER_SMART_DOCUMENT_FORM_MODE](
                OFFER_SMART_DOCUMENT_FORM_CREATE_MODE,
            ),
        );
        dispatch(
            initializeSmartDocumentForm({
                commercial_offer: proposalId,
                project: projectId,
            }),
        );
        dispatch(openOfferSmartDocumentModal(true));
    };
