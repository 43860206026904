import i18next from 'i18next';

import { deleteItem } from 'common/api/v1/reportCard';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_DATA,
    DELETE_DATA_FAILURE,
    DELETE_DATA_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import fetchData from './fetchData';

export default (id, projectId) => (dispatch) => {
    dispatch(actions[DELETE_DATA]());

    deleteItem(id)
        .then(() => {
            dispatch(actions[DELETE_DATA_SUCCESS]());
            showToast({ body: i18next.t('Successfully deleted') });
            dispatch(fetchData({ isInitialFetch: true, projectId }));
        })
        .catch((error) => {
            dispatch(actions[DELETE_DATA_FAILURE]());
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
