import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'sunwise-ui';

import ProviderImage from 'common/components/ProviderImage';

import ModalButtons from './ModalButtons';

const ProvidersList = ({
    handleSelectProvider,
    onCancel,
    onClickNext,
    providers,
    selectedProvider,
}) => {
    const { t } = useTranslation();
    if (!providers?.length) return null;

    return (
        <>
            <Grid container pb={2} justifyContent="center">
                {providers.map((provider) => (
                    <Grid item key={provider.type} textAlign="center" xs={9}>
                        <Card
                            onClick={() => handleSelectProvider(provider)}
                            sx={{
                                border: (theme) =>
                                    `1px solid ${
                                        selectedProvider?.type === provider.type
                                            ? theme.palette.primary.main
                                            : 'transparent'
                                    }`,
                                height: '100%',
                                '&:hover': {
                                    border: (theme) =>
                                        `1px solid ${theme.palette.primary.main}`,
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            <Card.Body
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <ProviderImage
                                    provider={provider}
                                    style={{
                                        margin: 'auto',
                                        maxHeight: '100px',
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <ModalButtons
                cancelText={t('Cancel')}
                disabledNext={!selectedProvider}
                nextText={t('Next')}
                onCancel={onCancel}
                onClickNext={onClickNext}
            />
        </>
    );
};

ProvidersList.propTypes = {
    handleSelectProvider: PropTypes.func,
    onCancel: PropTypes.func,
    onClickNext: PropTypes.func,
    providers: PropTypes.array,
    selectedProvider: PropTypes.object,
};

export default ProvidersList;
