import { createSlice } from '@reduxjs/toolkit';

import {
    SEND_EMAIL,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAILURE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: {
        email: '',
    },
    session: {
        user: null,
        isLoading: false,
        errors: null,
    },
    showForgotPasswordForm: true,
};

const forgotPasswordSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [SEND_EMAIL]: (state) => {
            state.session.isLoading = true;
        },
        [SEND_EMAIL_FAILURE]: (state, action) => {
            state.session = {
                ...state.session,
                errors: action.payload,
                isLoading: false,
            };
        },
        [SEND_EMAIL_SUCCESS]: (state, action) => {
            state.session = {
                ...state.session,
                isLoading: false,
                user: action.payload,
            };
            state.showForgotPasswordForm = false;
        },
    },
});

export const forgotPasswordActions = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
