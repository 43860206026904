import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { Controller } from 'react-hook-form';
import { Box } from 'sunwise-ui';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/languages/es.js';

const FroalaContainer = styled(Box)`
    .fr-box {
        height: 500px;
        overflow: hidden;
    }

    .fr-wrapper {
        background: #fff !important;
        border-bottom-color: #cccccc !important;
        height: calc(100% - 50px);

        .fr-element {
            height: 100%;
            min-height: 0 !important;

            &.fr-view {
                line-height: normal;
                overflow-y: auto;
            }
        }
    }
    .second-toolbar {
        display: none;
    }
`;

const INITIAL_CONFIG = {
    charCounterCount: false,
    defaultFontFamily: 'Montserrat',
    fontSize: [
        '8',
        '9',
        '10',
        '11',
        '12',
        '14',
        '16',
        '18',
        '20',
        '24',
        '30',
        '36',
        '48',
        '60',
        '72',
        '96',
    ],
    fontSizeDefaultSelection: '14',
    key: import.meta.env.VITE_FROALA_LICENSE,
    language: 'es',
    placeholderText: 'Agrega el texto aquí',
    pluginsEnabled: [
        'align',
        'fontSize',
        'colors',
        'lineBreaker',
        'lineHeight',
        'lists',
        'table',
        'paragraphFormat',
        'paragraphStyle',
        'tagPlugin',
    ],
    toolbarButtons: {
        moreParagraph: {
            buttons: [
                'alignLeft',
                'alignCenter',
                'alignRight',
                'alignJustify',
                'formatOL',
                'formatUL',
                'paragraphFormat',
                'paragraphStyle',
                'lineHeight',
                'outdent',
                'indent',
            ],
        },
        moreRich: { buttons: ['insertTable'] },
        moreText: {
            buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'clearFormatting',
            ],
        },
    },
};

const ReactHookFormFroala = ({ control, disabled, name, onChange }) => {
    const handleOnChange = (model, onChangeField) => {
        onChangeField(model);
        if (onChange) onChange(model);
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                if (disabled)
                    return (
                        <FroalaContainer>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: field.value,
                                }}
                            />
                        </FroalaContainer>
                    );

                return (
                    <FroalaContainer>
                        <FroalaEditorComponent
                            config={INITIAL_CONFIG}
                            model={field.value}
                            onModelChange={(model) =>
                                handleOnChange(model, field.onChange)
                            }
                            tag="textarea"
                        />
                    </FroalaContainer>
                );
            }}
        />
    );
};

ReactHookFormFroala.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
};

export default ReactHookFormFroala;
