import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Typography } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InputSearch from 'common/components/InputSearch';

const CUPSSearch = ({
    disabled,
    fetchCupsInfo,
    getValues,
    hasCupsData,
    setValue,
}) => {
    const [cups, setCups] = useState('');
    const [showSearch, setShowSearch] = useState(true);
    const { t } = useTranslation();

    const handleSearch = () => fetchCupsInfo({ cups, getValues, setValue });

    const handleOnChangeCups = (e) => setCups(e.target.value);

    useEffect(() => {
        setShowSearch(!hasCupsData);
    }, [hasCupsData]);

    if (!showSearch)
        return (
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                gap={2}
            >
                <CheckCircleIcon />

                <Typography variant="caption">
                    {t('CUPS found successfully')}
                </Typography>

                <Button
                    onClick={() => setShowSearch((prev) => !prev)}
                    type="button"
                    variant="outlined"
                >
                    {t('Search another')}
                </Button>
            </Box>
        );

    return (
        <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={1}
            px={1}
        >
            <InputSearch
                disabled={disabled}
                onChange={handleOnChangeCups}
                placeholder="CUPS"
                value={cups}
                variant="filled"
            />

            <Button disabled={disabled} type="button" onClick={handleSearch}>
                {t('Search')}
            </Button>
        </Box>
    );
};

CUPSSearch.propTypes = {
    disabled: PropTypes.bool,
    fetchCupsInfo: PropTypes.func,
    getValues: PropTypes.func,
    hasCupsData: PropTypes.bool,
    setValue: PropTypes.func,
};

export default CUPSSearch;
