import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from '../actions';
import { POLYGON_MAP, POLYGON_OBSTACLE } from '../constants';
import * as selectors from '../selectors';

import ImageEditForm from './ImageEditForm';
import ObstacleEditForm from './ObstacleEditForm';
import SegmentEditForm from './SegmentEditForm';

const SegmentEditCard = ({
    commercialOfferId,
    initialValues,
    mapRef,
    offerPanels,
    onCloseDrawer,
    resetForm,
    sxBox,
    updateSegment,
}) => {
    const escFunction = (event) => {
        if (event.key === 'Escape') resetForm();
    };

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, []);

    const props = {
        commercialOfferId,
        initialValues,
        mapRef,
        offerPanels,
        onCloseDrawer,
        resetForm,
        sxBox,
        updateSegment,
    };

    if (initialValues.type === POLYGON_OBSTACLE)
        return <ObstacleEditForm {...props} />;
    if (initialValues.type === POLYGON_MAP) return <ImageEditForm {...props} />;
    return <SegmentEditForm {...props} />;
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => dispatch(actions.resetForm()),
    updateSegment: (config) => dispatch(actions.updateSegment(config)),
});

SegmentEditCard.propTypes = {
    commercialOfferId: PropTypes.string,
    initialValues: PropTypes.object,
    mapRef: PropTypes.object,
    offerPanels: PropTypes.array,
    onCloseDrawer: PropTypes.func,
    resetForm: PropTypes.func,
    sxBox: PropTypes.object,
    updateSegment: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentEditCard);
