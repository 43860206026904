import booleanClockwise from '@turf/boolean-clockwise';
import { default as turfDistance } from '@turf/distance';
import {
    lineString,
    point,
    polygon as turfPolygon,
    multiPolygon,
} from '@turf/helpers';
import { getGeom } from '@turf/invariant';
import lineIntersect from '@turf/line-intersect';
import midpoint from '@turf/midpoint';
import rhumbBearing from '@turf/rhumb-bearing';
import rhumbDestination from '@turf/rhumb-destination';
import rhumbDistance from '@turf/rhumb-distance';
import turfUnion from '@turf/union';
import polygonClipping from 'polygon-clipping';

const TILE_SIZE = 256;

const PARAMS_POLYGON = {
    clickable: false,
    fillOpacity: 0,
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 1,
};

export const degreesToRadians = (deg) => (deg * Math.PI) / 180;

export const fixOrientation = ({ height, orientation = 'P', width }) => {
    if (orientation === 'L') return { height: width, width: height };
    return { height, width };
};

export const getPolygonPaths = (polygon) => {
    let _paths = [];
    const paths = polygon.getPath();
    if (paths === undefined) return _paths;
    paths.forEach((latlng) =>
        _paths.push({ lat: latlng.lat(), lng: latlng.lng() }),
    );
    return _paths;
};

export const getBounds = ({ google, polygon }) => {
    let bounds = new google.maps.LatLngBounds();
    const paths = polygon.getPath();
    paths.forEach((latlng) => bounds.extend(latlng));
    return bounds;
};

export const getBoxSize = ({ google, height, map, width }) => {
    const zoom = map.getZoom();
    const scale = 1 << zoom;

    const _height = getRadiusInPixels({
        desiredRadiusInInMeters: height,
        google,
        map,
    });

    const _width = getRadiusInPixels({
        desiredRadiusInInMeters: width,
        google,
        map,
    });

    let boxSize = new google.maps.Size(_width, _height);
    boxSize.width /= scale;
    boxSize.height /= scale;

    return boxSize;
};

function bound(value, opt_min, opt_max) {
    if (opt_min !== null) value = Math.max(value, opt_min);
    if (opt_max !== null) value = Math.min(value, opt_max);
    return value;
}

function MercatorProjection({ google }) {
    this.pixelOrigin_ = new google.maps.Point(TILE_SIZE / 2, TILE_SIZE / 2);
    this.pixelsPerLonDegree_ = TILE_SIZE / 360;
    this.pixelsPerLonRadian_ = TILE_SIZE / (2 * Math.PI);
}

MercatorProjection.prototype.fromLatLngToPoint = function ({
    latLng,
    google,
    opt_point,
}) {
    let me = this;
    let point = opt_point || new google.maps.Point(0, 0);
    let origin = me.pixelOrigin_;

    point.x = origin.x + latLng.lng() * me.pixelsPerLonDegree_;

    let siny = bound(Math.sin(degreesToRadians(latLng.lat())), -0.9999, 0.9999);
    point.y =
        origin.y +
        0.5 * Math.log((1 + siny) / (1 - siny)) * -me.pixelsPerLonRadian_;
    return point;
};

export function getRadiusInPixels({ desiredRadiusInInMeters, google, map }) {
    if (desiredRadiusInInMeters === 0) return 0;

    const distance = 10000;
    const numTiles = 1 << map.getZoom();
    const center = map.getCenter();
    const moved = google.maps.geometry.spherical.computeOffset(
        center,
        distance,
        90,
    );
    const projection = new MercatorProjection({ google });
    const initCoord = projection.fromLatLngToPoint({ latLng: center, google });
    const endCoord = projection.fromLatLngToPoint({ latLng: moved, google });
    const initPoint = new google.maps.Point(
        initCoord.x * numTiles,
        initCoord.y * numTiles,
    );
    const endPoint = new google.maps.Point(
        endCoord.x * numTiles,
        endCoord.y * numTiles,
    );
    const pixelsPerMeter = Math.abs(initPoint.x - endPoint.x) / distance;
    return desiredRadiusInInMeters * pixelsPerMeter;
}

export function rotatePolygon({ angle, google, polygon, center }) {
    const vertices = polygon.getPath();
    const newPath = [];
    let paths = [];

    for (let i = 0; i < vertices.getLength(); i++) {
        const vertex = vertices.getAt(i);
        paths.push({ lat: vertex.lat(), lng: vertex.lng() });
    }

    paths.forEach((_, i) => {
        const length = google.maps.geometry.spherical.computeLength([
            center,
            paths[i],
        ]);

        const heading = google.maps.geometry.spherical.computeHeading(
            center,
            paths[i],
        );

        const newPosition = google.maps.geometry.spherical.computeOffset(
            center,
            length,
            heading + angle,
        );

        newPath.push(newPosition);
    });

    polygon.setPath(newPath);
}

export const getCenter = ({ google, paths }) => {
    let center = { lat: 0, lng: 0 };

    for (let i = 0; i < paths.length; i++) {
        center.lat += paths[i].lat;
        center.lng += paths[i].lng;
    }

    center.lat /= paths.length;
    center.lng /= paths.length;

    return new google.maps.LatLng(center.lat, center.lng);
};

export const getPolygonCoords = ({
    boxSize,
    config,
    google,
    projection,
    padding = { height: 0, width: 0 },
    swPoint,
    x,
    y,
}) => {
    const x1 = swPoint.x + boxSize.width * x;
    const y1 = swPoint.y - boxSize.height * y;
    const x2 = swPoint.x + boxSize.width * (x + 1);
    const y2 = swPoint.y - boxSize.height * (y + 1);
    const { height, width } = padding;

    const polygon = new google.maps.Polygon({
        paths: [
            projection.fromPointToLatLng(
                new google.maps.Point(x1 + width, y1 - height),
            ),
            projection.fromPointToLatLng(
                new google.maps.Point(x1 + width, y2 + height),
            ),
            projection.fromPointToLatLng(
                new google.maps.Point(x2 - width, y2 + height),
            ),
            projection.fromPointToLatLng(
                new google.maps.Point(x2 - width, y1 - height),
            ),
        ],
        ...PARAMS_POLYGON,
        ...config,
    });

    return polygon;
};

const getParallelLine = ({ from, isClockwiseRing = true, setback, to }) => {
    const distance = rhumbDistance(from, to, { units: 'meters' });
    const lineMidpoint = midpoint(from, to);
    let bearing = rhumbBearing(from, to);
    const bearingMidPointParallel = !isClockwiseRing
        ? bearing - 90
        : bearing + 90;

    const midPointParallel = rhumbDestination(
        lineMidpoint,
        -1 * setback,
        bearingMidPointParallel,
        { units: 'meters' },
    );

    const fromParallelPonit = rhumbDestination(
        midPointParallel,
        distance * 100,
        bearing,
        { units: 'meters' },
    );

    const toParallelPonit = rhumbDestination(
        midPointParallel,
        distance * 100,
        bearing - 180,
        { units: 'meters' },
    );

    const point1 = fromParallelPonit.geometry.coordinates;
    const point2 = toParallelPonit.geometry.coordinates;

    return [point1, point2];
};

export const getPolygonPointsWithSetback = ({ polygon, setback } = {}) => {
    const polygonPaths = getPolygonPaths(polygon);

    if (setback === 0 || polygonPaths.length < 2) return polygonPaths;

    let newPaths = [];
    let polygonPoints = polygonPaths.map((point) => [point.lng, point.lat]);
    polygonPoints.push(polygonPoints[0]);

    if (polygonPoints.length < 3) return newPaths;

    let lines = [];
    let intsersectPoint = [];

    const clockwiseRing = lineString(polygonPoints);
    const isClockwiseRing = booleanClockwise(clockwiseRing);

    for (let i = 0; i < polygonPoints.length - 1; i++) {
        const from = point([polygonPoints[i][0], polygonPoints[i][1]]);
        const to = point([polygonPoints[i + 1][0], polygonPoints[i + 1][1]]);

        const parallelLine = getParallelLine({
            from,
            isClockwiseRing,
            setback,
            to,
        });

        lines.push(parallelLine);
    }

    lines.push(lines[0]);

    for (let i = 0; i < lines.length - 1; i++) {
        const line1 = lineString(lines[i]);
        const line2 = lineString(lines[i + 1]);
        const intersects = lineIntersect(line1, line2);
        intsersectPoint.push(intersects);
    }

    for (let i = 0; i < intsersectPoint.length; i++) {
        const point = intsersectPoint[i];
        if (!point.features[0]) continue;
        const coords = point.features[0].geometry.coordinates;
        newPaths.push({ lat: coords[1], lng: coords[0] });
    }

    return newPaths;
};

export const checkCollition = ({
    azimuth,
    google,
    obstacles,
    originalCenter,
    paths,
}) => {
    let flag;

    if (!Array.isArray(obstacles) || obstacles.length === 0) return false;

    let groupPolygon = paths.map((point) => [point.lng(), point.lat()]);
    groupPolygon.push(groupPolygon[0]);

    for (let i = 0; i < obstacles.length; i++) {
        if (flag) break;

        const obstacle = obstacles[i];

        if (obstacle.polygon.length < 3) {
            flag = false;
            break;
        }

        const obstaclePaths = obstacle.polygon.map((latlng) => ({
            lat: latlng[0],
            lng: latlng[1],
        }));

        const obstaclePolygon = new google.maps.Polygon({
            paths: obstaclePaths,
        });

        rotatePolygon({
            angle: 180 - azimuth,
            center: originalCenter,
            google,
            polygon: obstaclePolygon,
        });

        const obstaclePathsPlusTolerance = getPolygonPointsWithSetback({
            polygon: obstaclePolygon,
            setback: obstacle.safe_zone,
        });

        let union = null;

        let obstaclePolygonPathsPlusTolerance = obstaclePathsPlusTolerance.map(
            (latlng) => [latlng.lng, latlng.lat],
        );
        obstaclePolygonPathsPlusTolerance.push(
            obstaclePolygonPathsPlusTolerance[0],
        );

        const poly1 = turfPolygon([groupPolygon]);
        const poly2 = turfPolygon([obstaclePolygonPathsPlusTolerance]);

        try {
            union = turfUnion(poly1, poly2);
        } catch (e) {
            union = null;
        }

        if (union && union.geometry.coordinates.length === 1) flag = true;
    }

    return flag;
};

export const drawDebugBoxes = ({
    azimuth,
    debugBoxes,
    google,
    map,
    originalCenter,
}) => {
    debugBoxes.forEach((polygon) => {
        rotatePolygon({
            angle: 180 + azimuth,
            center: originalCenter,
            google,
            polygon,
        });
        polygon.setMap(map);
    });
};

export const difference = (polygon1, polygon2) => {
    let geom1 = getGeom(polygon1);
    let geom2 = getGeom(polygon2);
    let properties = polygon1.properties || {};
    let differenced = polygonClipping.difference(
        geom1.coordinates,
        geom2.coordinates,
    );

    if (differenced.length === 0) return null;

    /** fix para casos extremos */
    if (differenced.length === 1 && differenced[0][0].length < 4) {
        if (differenced[0][0].length === 1) return null;
        if (differenced[0][0].length === 2)
            if (
                JSON.stringify(differenced[0][0][0]) ===
                JSON.stringify(differenced[0][0][1])
            )
                return null;
        return lineString(differenced[0]);
    }
    /** termina fix */

    if (differenced.length === 1)
        return turfPolygon(differenced[0], properties);
    return multiPolygon(differenced, properties);
};

export const movePoint = ({ lnglat, distance = 30, bearing = 135 }) => {
    const pt = point(lnglat);
    const destination = rhumbDestination(pt, distance, bearing, {
        units: 'meters',
    });
    const coordinates = destination.geometry.coordinates;
    return [coordinates[1], coordinates[0]];
};

export const getBoxSizeWithTurf = ({ bbox, height, width }) => {
    const sw = bbox.getSouthWest();
    const ne = bbox.getNorthEast();

    const north = ne.lat();
    const east = ne.lng();
    const south = sw.lat();
    const west = sw.lng();

    const distanceX = turfDistance([west, south], [east, south], {
        units: 'meters',
    });
    const distanceY = turfDistance([west, south], [west, north], {
        units: 'meters',
    });

    const xFraction = width / distanceX;
    const cellWidthDeg = xFraction * (east - west);
    const yFraction = height / distanceY;
    const cellHeightDeg = yFraction * (north - south);

    return { cellWidthDeg, cellHeightDeg };
};
