import { getInverterBrands } from 'common/api/v1/onboarding';

import {
    FETCH_INVERTER_BRANDS,
    FETCH_INVERTER_BRANDS_FAILURE,
    FETCH_INVERTER_BRANDS_SUCCESS,
} from '../actionTypes';
import { onboardingWizardActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(onboardingWizardActions[FETCH_INVERTER_BRANDS]());

    getInverterBrands()
        .then((response) => {
            dispatch(
                onboardingWizardActions[FETCH_INVERTER_BRANDS_SUCCESS](
                    response.data,
                ),
            );
        })
        .catch((error) => {
            dispatch(
                onboardingWizardActions[FETCH_INVERTER_BRANDS_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
        });
};
