import React from 'react';

import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { CircularProgress, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, Tooltip } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as credentialIntegrationsSelectors from '../../credentialIntegrations/selectors';
import * as selectors from '../selectors';

import SyncStationItem from './SyncStationItem';

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.background.paper,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.mode === 'dark' ? '#fff' : null,
        maxWidth: 400,
        minWidth: 150,
    },
}));

const SyncIndicator = ({ projectsSync, providersDictionary }) => {
    const stations = Object.values(projectsSync || {});

    if (!stations?.length) return null;

    return (
        <CustomWidthTooltip
            title={
                <Box>
                    {stations?.map((station) => (
                        <SyncStationItem
                            key={station.id}
                            providersDictionary={providersDictionary}
                            station={station}
                        />
                    ))}
                </Box>
            }
        >
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="indeterminate"
                    sx={{ color: (theme) => theme.palette.success.main }}
                />
                <Box
                    sx={{
                        alignItems: 'center',
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                    }}
                >
                    <CloudSyncIcon
                        sx={{ color: (theme) => theme.palette.success.main }}
                        fontSize="small"
                    />
                </Box>
            </Box>
        </CustomWidthTooltip>
    );
};

const mapStateToProps = createStructuredSelector({
    providersDictionary: credentialIntegrationsSelectors.getProvidersDictionary,
    projectsSync: selectors.getProjectsSync,
});

SyncIndicator.propTypes = {
    projectsSync: PropTypes.object,
    providersDictionary: PropTypes.object,
};

export default connect(mapStateToProps)(SyncIndicator);
