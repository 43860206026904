import API from '../..';
const ENTITY = 'compatibility-settings';

export const getCompatibilityData = (data) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            political_division1: data?.political_division1,
            political_division2: data?.political_division2,
            product_inverter: data?.product_inverter,
            product_panel: data?.product_panel,
        },
    });
export const getDatasheetInverter = (id) =>
    API.get(`/api/v1/inverter-datasheet/${id}`);
export const getDatasheetPanel = (id) =>
    API.get(`/api/v1/panel-datasheet/${id}`);
export const resetCompatibilitySettings = (data) =>
    API.post(`/api/v1/reset-${ENTITY}/`, data);
export const update = (data) =>
    API.put(
        `/api/v1/compatibility-settings-detail/${data.product_panel}/${data.product_inverter}`,
        data,
    );
