import { getConfig } from 'common/api/v2/timeShiftingHourlyConfiguration';

import {
    FETCH_TIME_SHIFTING_CONFIGURATION,
    FETCH_TIME_SHIFTING_CONFIGURATION_FAILURE,
    FETCH_TIME_SHIFTING_CONFIGURATION_SUCCESS,
} from '../actionTypes';
import { getFormattedTimeShiftingConfiguration } from '../helpers';
import { timeShiftingConfigurationActions } from '../reducer';

import initializeValues from './initializeValues';

export default (commercialOfferId) => (dispatch) => {
    new Promise((resolve) => {
        timeShiftingConfigurationActions[FETCH_TIME_SHIFTING_CONFIGURATION]();

        getConfig(commercialOfferId)
            .then((response) => {
                const data = response?.data?.data || {};
                dispatch(
                    timeShiftingConfigurationActions[
                        FETCH_TIME_SHIFTING_CONFIGURATION_SUCCESS
                    ](data),
                );
                const formattedData =
                    getFormattedTimeShiftingConfiguration(data);
                dispatch(initializeValues(formattedData));
            })
            .catch((error) => {
                dispatch(
                    timeShiftingConfigurationActions[
                        FETCH_TIME_SHIFTING_CONFIGURATION_FAILURE
                    ](error?.response?.data?.errors),
                );
            })
            .finally(resolve);
    });
};
