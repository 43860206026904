import React from 'react';

import { useGridApiContext } from '@mui/x-data-grid';
import { isNull, toNumber } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from 'sunwise-ui';

import IntlNumberInputField from 'common/components/IntlNumberInputField';
import { calculateFinalCost } from 'common/utils/helpers';

const MarginRangeEdit = (props) => {
    const { t } = useTranslation();
    const { field, row } = props;
    const apiRef = useGridApiContext();
    const { id, range_prices } = row;
    const value = range_prices[0]?.margin || 0;

    const handleValueChange = (newValue) => {
        const { id: rangeId, cost } = range_prices[0];
        let margin = newValue;
        if (isNull(margin)) margin = 0;
        if (parseFloat(margin) >= 100) {
            margin = 99;
        }
        const finalCost = calculateFinalCost(cost, margin);
        const newRangePrices = range_prices.map((item) => {
            if (item.id === rangeId) {
                return {
                    ...item,
                    final_cost: toNumber(finalCost),
                    margin: toNumber(margin),
                };
            }

            return item;
        });
        apiRef.current.setEditCellValue({ id, field, value: margin });
        apiRef.current.setEditCellValue({
            id,
            field: 'range_prices',
            value: newRangePrices,
        });
    };

    if (range_prices.length > 1) {
        return (
            <Tooltip title={t('This cell is not editable for price ranges')}>
                <Typography
                    variant="caption"
                    fontWeight="bold"
                    sx={{ width: 'calc(100% - 4rem)' }}
                >
                    {t('Per range')}
                </Typography>
            </Tooltip>
        );
    }
    return (
        <IntlNumberInputField
            allowNegativeValue={false}
            append="%"
            max={99}
            min={0}
            onChange={handleValueChange}
            step="any"
            sx={{
                width: 'calc(100% - 10px)',
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: 0,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '0 !important',
                },
            }}
            value={value}
        />
    );
};

MarginRangeEdit.propTypes = {
    field: PropTypes.string,
    id: PropTypes.string,
    row: PropTypes.object,
};

export default MarginRangeEdit;
