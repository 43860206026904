import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Select } from 'sunwise-ui';

const DropdownCurrencies = ({ disabled, id, onChange, options, value }) => {
    const { t } = useTranslation();
    const label = t('Currency');
    return (
        <FormControl fullWidth size="small">
            <InputLabel>{label}</InputLabel>
            <Select
                disabled={disabled}
                label={label}
                onChange={(e) => {
                    if (onChange) onChange(id, e.target.value);
                }}
                sx={{ textAlign: 'left' }}
                value={value ?? ''}
            >
                {options.map(({ label, value, disabled: optionDisabled }) => (
                    <MenuItem
                        key={`select-${value}-${label}`}
                        value={value}
                        disabled={optionDisabled}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

DropdownCurrencies.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.string,
};

export default DropdownCurrencies;
