import React from 'react';

import { Alert, Box, Button } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EditRateWarning = ({ onClickEdit }) => {
    const { t } = useTranslation();

    return (
        <Alert
            severity="warning"
            sx={{ '.MuiAlert-message': { width: '100%' } }}
            variant="filled"
        >
            <Box
                display="flex"
                flexWrap="wrap"
                gap={1}
                justifyContent="space-between"
            >
                {t(
                    'Do you want to modify the rate concepts? This change will only affect this proposal',
                )}

                <Box textAlign="right" width={{ xs: '100%', md: 'auto' }}>
                    <Button
                        color="secondary"
                        onClick={onClickEdit}
                        sx={{ m: '0!important' }}
                    >
                        {t('Edit')}
                    </Button>
                </Box>
            </Box>
        </Alert>
    );
};

EditRateWarning.propTypes = {
    onClickEdit: PropTypes.func,
};

export default EditRateWarning;
