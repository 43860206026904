import i18next from 'i18next';

import { uploadCsv } from 'common/api/v2/costumerEnergyConsumption';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    UPLOAD_FILE,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS,
} from '../actionTypes';
import { UPLOAD_MODES } from '../constants';
import { projectConsumptionModalActions } from '../reducer';
import * as selectors from '../selectors';

import handleReceiptCsvData from './handleReceiptCsvData';

export default ({ file, getValues, setValue }) =>
    (dispatch, getState) => {
        if (!file) return;

        const selectedProject = selectors.getSelectedProject(getState());
        const config = {
            consumption: file[0],
            origin: UPLOAD_MODES.DATADIS,
            project: selectedProject?.id,
        };

        dispatch(projectConsumptionModalActions[UPLOAD_FILE]());

        dispatch(
            alerts.actions.show({
                confirmText: i18next.t('Back'),
                hideButtons: true,
                messages: [
                    i18next.t(
                        'Please wait a moment as the information is processed',
                    ),
                ],
                title: i18next.t('Loading').concat('...'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'info',
            }),
        );

        uploadCsv(config)
            .then((response) => {
                const data = response?.data?.data || {};

                const { consumptions, consumptions_csv, modified } = data;

                const consumption_profile_total = consumptions.reduce(
                    (acc, curr) => acc + curr.total_energy_consumption,
                    0,
                );

                const finalItem = consumptions[consumptions.length - 1];

                let values = {
                    consumptionProfileArray: consumptions.map(
                        (value) =>
                            (100 * value.total_energy_consumption) /
                            consumption_profile_total,
                    ),
                    data: {
                        lastDateConsumption: `${finalItem?.day}/${finalItem?.month}/${finalItem?.year}`,
                        modified,
                        totalConsumption: consumption_profile_total,
                        url: consumptions_csv?.id,
                    },
                };

                dispatch(alerts.actions.close());
                dispatch(
                    projectConsumptionModalActions[UPLOAD_FILE_SUCCESS](data),
                );
                dispatch(handleReceiptCsvData({ getValues, setValue, values }));
            })
            .catch((error) => {
                dispatch(projectConsumptionModalActions[UPLOAD_FILE_FAILURE]());
                dispatch(alerts.actions.close());
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
