import get from 'lodash/get';

import { getRoleLevels } from 'common/api/v1/systemRoles';

import {
    FETCH_ROLE_LEVELS,
    FETCH_ROLE_LEVELS_FAILURE,
    FETCH_ROLE_LEVELS_SUCCESS,
} from '../actionTypes';
import { rolesConfigurationActions } from '../reducer';

import fetchRoleLevelDetails from './fetchRoleLevelDetails';

export default (formValues, isNewRecord) => (dispatch) => {
    dispatch(rolesConfigurationActions[FETCH_ROLE_LEVELS]());

    getRoleLevels()
        .then((response) => {
            const data = get(response, 'data', {});
            const levelId = get(data[1], 'id', null);
            dispatch(
                rolesConfigurationActions[FETCH_ROLE_LEVELS_SUCCESS](data),
            );
            if (isNewRecord && levelId)
                dispatch(fetchRoleLevelDetails(formValues, levelId));
        })
        .catch((error) =>
            dispatch(
                rolesConfigurationActions[FETCH_ROLE_LEVELS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
