import { NAME } from './constants';

export const FETCH_CONTRACTED_DEMAND_UNITS = `${NAME}/FETCH_CONTRACTED_DEMAND_UNITS`;
export const FETCH_CONTRACTED_DEMAND_UNITS_FAILURE = `${NAME}/FETCH_CONTRACTED_DEMAND_UNITS_FAILURE`;
export const FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS = `${NAME}/FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS`;

export const FETCH_COUNTRIES = `${NAME}/FETCH_COUNTRIES`;
export const FETCH_COUNTRIES_FAILURE = `${NAME}/FETCH_COUNTRIES_FAILURE`;
export const FETCH_COUNTRIES_SUCCESS = `${NAME}/FETCH_COUNTRIES_SUCCESS`;

export const FETCH_CURRENCIES = `${NAME}/FETCH_CURRENCIES`;
export const FETCH_CURRENCIES_FAILURE = `${NAME}/FETCH_CURRENCIES_FAILURE`;
export const FETCH_CURRENCIES_SUCCESS = `${NAME}/FETCH_CURRENCIES_SUCCESS`;

export const FETCH_DIVISIONS = `${NAME}/FETCH_DIVISIONS`;
export const FETCH_DIVISIONS_FAILURE = `${NAME}/FETCH_DIVISIONS_FAILURE`;
export const FETCH_DIVISIONS_SUCCESS = `${NAME}/FETCH_DIVISIONS_SUCCESS`;

export const FETCH_RATE = `${NAME}/FETCH_RATE`;
export const FETCH_RATE_FAILURE = `${NAME}/FETCH_RATE_FAILURE`;
export const FETCH_RATE_SUCCESS = `${NAME}/FETCH_RATE_SUCCESS`;

export const FETCH_SCHEDULE_RATE_CONFIGURATION = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION`;
export const FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE`;
export const FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS`;

export const FETCH_SUPPLIER_UTILITIES = `${NAME}/FETCH_SUPPLIER_UTILITIES`;
export const FETCH_SUPPLIER_UTILITIES_FAILURE = `${NAME}/FETCH_SUPPLIER_UTILITIES_FAILURE`;
export const FETCH_SUPPLIER_UTILITIES_SUCCESS = `${NAME}/FETCH_SUPPLIER_UTILITIES_SUCCESS`;

export const FETCH_UNITS = `${NAME}/FETCH_UNITS`;
export const FETCH_UNITS_FAILURE = `${NAME}/FETCH_UNITS_FAILURE`;
export const FETCH_UNITS_SUCCESS = `${NAME}/FETCH_UNITS_SUCCESS`;

export const FILTER_RATES = `${NAME}/FILTER_RATES`;
export const FILTER_RATES_FAILURE = `${NAME}/FILTER_RATES_FAILURE`;
export const FILTER_RATES_SUCCESS = `${NAME}/FILTER_RATES_SUCCESS`;

export const FILTER_RATES_NEW_PAGE = `${NAME}/FILTER_RATES_NEW_PAGE`;
export const FILTER_RATES_NEW_PAGE_FAILURE = `${NAME}/FILTER_RATES_NEW_PAGE_FAILURE`;
export const FILTER_RATES_NEW_PAGE_SUCCESS = `${NAME}/FILTER_RATES_NEW_PAGE_SUCCESS`;

export const FETCH_SPAIN_EXCESS_DEMAND_UNITS = `${NAME}/FETCH_SPAIN_EXCESS_DEMAND_UNITS`;
export const FETCH_SPAIN_EXCESS_DEMAND_UNITS_FAILURE = `${NAME}/FETCH_SPAIN_EXCESS_DEMAND_UNITS_FAILURE`;
export const FETCH_SPAIN_EXCESS_DEMAND_UNITS_SUCCESS = `${NAME}/FETCH_SPAIN_EXCESS_DEMAND_UNITS_SUCCESS`;

export const SET_HAS_ACTIVE_PROJECTS = `${NAME}/SET_HAS_ACTIVE_PROJECTS`;

export const INITIALIZE_CERTIFIED_RATE_MODAL_FORM = `${NAME}/INITIALIZE_CERTIFIED_RATE_MODAL_FORM`;

export const INITIALIZE_RATE_DETAIL_FORM = `${NAME}/INITIALIZE_RATE_DETAIL_FORM`;

export const INITIALIZE_RATE_MODAL_FORM = `${NAME}/INITIALIZE_RATE_MODAL_FORM`;

export const INITIALIZE_SUPPLIER_UTILITY_FORM = `${NAME}/INITIALIZE_SUPPLIER_UTILITY_FORM`;

export const RESET = `${NAME}/RESET`;

export const RESET_CERTIFIED_RATE_MODAL_FORM = `${NAME}/RESET_CERTIFIED_RATE_MODAL_FORM`;

export const RESET_RATE_DETAIL_FORM = `${NAME}/RESET_RATE_DETAIL_FORM`;

export const RESET_RATE_MODAL_FORM = `${NAME}/RESET_RATE_MODAL_FORM`;

export const RESET_SUPPLIER_UTILITY_FORM = `${NAME}/RESET_SUPPLIER_UTILITY_FORM`;

export const SAVE_RATE = `${NAME}/SAVE_RATE`;
export const SAVE_RATE_FAILURE = `${NAME}/SAVE_RATE_FAILURE`;
export const SAVE_RATE_SUCCESS = `${NAME}/SAVE_RATE_SUCCESS`;

export const SAVE_SUPPLIER_UTILITY = `${NAME}/SAVE_SUPPLIER_UTILITY`;
export const SAVE_SUPPLIER_UTILITY_FAILURE = `${NAME}/SAVE_SUPPLIER_UTILITY_FAILURE`;
export const SAVE_SUPPLIER_UTILITY_SUCCESS = `${NAME}/SAVE_SUPPLIER_UTILITY_SUCCESS`;

export const SET_CERTIFIED_RATE_MODAL_IS_OPEN = `${NAME}/SET_CERTIFIED_RATE_MODAL_IS_OPEN`;

export const SET_FILTER_QUERY = `${NAME}/SET_FILTER_QUERY`;

export const SET_MODAL_IS_CREATE = `${NAME}/SET_MODAL_IS_CREATE`;

export const SET_MODAL_IS_OPEN = `${NAME}/SET_MODAL_IS_OPEN`;

export const SET_RATE_ENABLED = `${NAME}/SET_RATE_ENABLED`;
export const SET_RATE_ENABLED_FAILURE = `${NAME}/SET_RATE_ENABLED_FAILURE`;
export const SET_RATE_ENABLED_SUCCESS = `${NAME}/SET_RATE_ENABLED_SUCCESS`;

export const SET_SUPPLIER_UTILITIES_MODAL_IS_OPEN = `${NAME}/SET_SUPPLIER_UTILITIES_MODAL_IS_OPEN`;

export const SET_SUPPLIER_UTILITY_FORM_IS_OPEN = `${NAME}/SET_SUPPLIER_UTILITY_FORM_IS_OPEN`;
