import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const Container = styled('div')`
    align-items: ${({ alignItems }) => alignItems};
    display: flex;
    flex-direction: column;
    text-align: ${({ textAlign }) => textAlign};
`;

const SlideItem = ({ alignItems = 'left', content, textAlign = 'justify' }) => (
    <Container alignItems={alignItems} textAlign={textAlign}>
        {content}
    </Container>
);

SlideItem.propTypes = {
    alignItems: PropTypes.string,
    content: PropTypes.object,
    textAlign: PropTypes.string,
};

export default SlideItem;
