import { createAction } from '@reduxjs/toolkit';

export const generateSocketConstants = (prefix) => ({
    BEGIN_RECONNECT_SOCKET: `${prefix}::BEGIN_RECONNECT`,
    BROKEN_SOCKET: `${prefix}::BROKEN`,
    CLOSED_SOCKET: `${prefix}::CLOSED`,
    ERROR_SOCKET: `${prefix}::ERROR`,
    MESSAGE_SOCKET: `${prefix}::MESSAGE`,
    OPEN_SOCKET: `${prefix}::OPEN`,
    RECONNECTED: `${prefix}::RECONNECTED`,
    RECONNECT_ATTEMPT_SOCKET: `${prefix}::RECONNECT_ATTEMPT`,
});

export const generateSocketActions = (prefix) => {
    const constants = generateSocketConstants(prefix);
    let actions = {};

    for (const key in constants) {
        actions[key] = createAction(constants[key]);
    }

    return actions;
};
