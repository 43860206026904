import {
    DUPLICATE_TEMPLATE_MODE,
    NEW_FROM_TEMPLATE_MODE,
    NEW_TEMPLATE_MODE,
    TEMPLATE_MODAL_MAIN_DATA,
    TEMPLATE_MODAL_SELECT_BASE,
    UPDATE_TEMPLATE_MODE,
} from '../constants';
import * as selectors from '../selectors';

import createTemplate from './createTemplate';
import duplicateTemplate from './duplicateTemplate';
import editTemplate from './editTemplate';
import setWizardStep from './setWizardStep';

export default (values, filterData) => (dispatch, getState) => {
    const state = getState();
    const templateMode = selectors.getTemplateMode(state);
    const wizardStep = selectors.getWizardStep(state);
    switch (templateMode) {
        case NEW_TEMPLATE_MODE:
            if (wizardStep === TEMPLATE_MODAL_MAIN_DATA) {
                dispatch(setWizardStep(TEMPLATE_MODAL_SELECT_BASE));
            } else {
                dispatch(createTemplate(values, filterData));
            }
            break;
        case NEW_FROM_TEMPLATE_MODE:
            dispatch(createTemplate(values, filterData));
            break;
        case UPDATE_TEMPLATE_MODE:
            dispatch(editTemplate(values, filterData));
            break;
        case DUPLICATE_TEMPLATE_MODE:
            dispatch(duplicateTemplate(values, filterData));
            break;
        default:
    }
};
