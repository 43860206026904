import React from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import { CheckAccess } from 'common/components';
import { useBreakpoint } from 'common/hooks';
import { getHasPaymentDue } from 'common/utils/helpers/session';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledStyledListItemText from './StyledStyledListItemText';

const AfterSalesOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
}) => {
    const { t } = useTranslation();
    const hasPaymentDue = getHasPaymentDue();
    const breakpoint = useBreakpoint();

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess requiredPermissions={[]}>
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/monitoring-dashboard');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/monitoring-dashboard' === match.url}
                    variant="horizontal"
                >
                    <DashboardIcon />
                    <StyledStyledListItemText
                        primary={t('Monitoring dashboard')}
                    />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

AfterSalesOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
};

export default AfterSalesOptions;
