import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SearchInput from './SearchInput';

const Filter = ({ handleChangeSearch, show }) => {
    const { t } = useTranslation();
    if (!show) return null;
    return (
        <SearchInput
            handleOnSearchChange={handleChangeSearch}
            placeholderSearch={t('Filter by name')}
        />
    );
};

Filter.propTypes = {
    handleChangeSearch: PropTypes.func,
    show: PropTypes.bool,
};

export default Filter;
