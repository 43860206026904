import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

const ActionsFormatter = ({
    canDelete,
    canModify,
    handleClickDelete,
    handleClickUpdate,
    row,
}) => {
    const { t } = useTranslation();

    const isDefault = row?.is_system_role;
    if (isDefault)
        return (
            <Box sx={{ ml: 'auto' }}>
                <Button
                    onClick={() => handleClickUpdate(row.id)}
                    startIcon={<VisibilityIcon fontSize="small" />}
                    variant="text"
                >
                    {t('View permissions')}
                </Button>
            </Box>
        );
    return (
        <DropdownCardMenu
            items={[
                {
                    handleClick: () => handleClickUpdate(row.id),
                    icon: <EditIcon fontSize="small" />,
                    label: t('Edit'),
                },
                {
                    handleClick: () => handleClickDelete(row),
                    icon: <DeleteIcon fontSize="small" />,
                    label: t('Delete'),
                    visible: canDelete && !isDefault,
                },
            ]}
            sxIconButton={{ ml: 'auto' }}
            visible={canDelete || canModify}
        />
    );
};

ActionsFormatter.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    row: PropTypes.object,
};

export default ActionsFormatter;
