import { INSURANCE_TYPE } from 'common/constants';

export const isDisabledButton = (financierType, registered, requirements) => {
    if (requirements.content.length === 0 && requirements.required) {
        return true;
    }
    if (financierType === INSURANCE_TYPE && !registered) {
        return true;
    }
    return false;
};
