import { INITIALIZE_PRODUCTS_SETTINGS_VALUES } from '../actionTypes';
import { proposalGeneratorTaxesTableActions } from '../reducer';

import setProductsSettingsModalIsOpen from './setProductsSettingsModalIsOpen';

export default (id, products) => (dispatch) => {
    dispatch(
        proposalGeneratorTaxesTableActions[INITIALIZE_PRODUCTS_SETTINGS_VALUES](
            { id, products },
        ),
    );
    dispatch(setProductsSettingsModalIsOpen(true));
};
