import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import HourlyGridWrapper from 'common/components/HourlyGridWrapper';
import InfoToolTip from 'common/components/InfoToolTip';

import HourlyDistribution from './HourlyDistribution';
import InfoTooltipContent from './InfoTooltipContent';

const DistributionSection = ({
    control,
    disabled,
    options,
    rateTiers,
    weekDay,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Grid container padding={1} alignItems="center">
                <Grid item xs={18} md={4}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={disabled}
                        name={`select.${weekDay}`}
                        label={t('Status')}
                        options={options}
                    />
                </Grid>
                <Grid item xs={18} md={1}>
                    <InfoToolTip variant="no-wrapper">
                        <InfoTooltipContent rateTiers={rateTiers} />
                    </InfoToolTip>
                </Grid>
            </Grid>

            <Grid container mt={1} p={1}>
                <Grid item xs={18}>
                    <HourlyGridWrapper>
                        <HourlyDistribution
                            disabled={disabled}
                            weekDay={weekDay}
                            {...rest}
                        />
                    </HourlyGridWrapper>
                </Grid>
            </Grid>
        </>
    );
};

DistributionSection.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    rateTiers: PropTypes.array,
    weekDay: PropTypes.string,
};

export default DistributionSection;
