import { NAME } from './constants';

export const DELETE_ITEM = `${NAME}/DELETE_ITEM`;
export const DELETE_ITEM_FAILURE = `${NAME}/DELETE_ITEM_FAILURE`;
export const DELETE_ITEM_SUCCESS = `${NAME}/DELETE_ITEM_SUCCESS`;

export const FETCH_SUPPORT_CONTACT = `${NAME}/FETCH_SUPPORT_CONTACT`;
export const FETCH_SUPPORT_CONTACT_FAILURE = `${NAME}/FETCH_SUPPORT_CONTACT_FAILURE`;
export const FETCH_SUPPORT_CONTACT_SUCCESS = `${NAME}/FETCH_SUPPORT_CONTACT_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_SUPPORT_CONTACT = `${NAME}/SAVE_SUPPORT_CONTACT`;
export const SAVE_SUPPORT_CONTACT_FAILURE = `${NAME}/SAVE_SUPPORT_CONTACT_FAILURE`;
export const SAVE_SUPPORT_CONTACT_SUCCESS = `${NAME}/SAVE_SUPPORT_CONTACT_SUCCESS`;

export const SET_CONTACT_ID = `${NAME}/SET_CONTACT_ID`;

export const SHOW_MODAL = `${NAME}/SHOW_MODAL`;
