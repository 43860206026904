import { createSlice } from '@reduxjs/toolkit';

import {
    CREATE,
    CREATE_FAILURE,
    CREATE_SUCCESS,
    DELETE,
    DELETE_FAILURE,
    DELETE_SUCCESS,
    FETCH_OFFER_TAXES,
    FETCH_OFFER_TAXES_FAILURE,
    FETCH_OFFER_TAXES_SUCCESS,
    INITIALIZE_PRODUCTS_SETTINGS_VALUES,
    INITIAL_VALUES,
    RESET_FORM,
    RESET_PRODUCTS_SETTINGS_INITIAL_VALUES,
    SAVE,
    SAVE_FAILURE,
    SAVE_PRODUCTS_SETTINGS,
    SAVE_PRODUCTS_SETTINGS_FAILURE,
    SAVE_PRODUCTS_SETTINGS_SUCCESS,
    SAVE_SUCCESS,
    SET_PRODUCTS_SETTINGS_MODAL_IS_OPEN,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    create: {
        data: {},
        errors: [],
        isCreating: false,
    },
    delete: {
        data: {},
        errors: [],
        isDeleting: false,
    },
    initialValues: {
        items: [],
    },
    initialValuesProductsSettings: {
        id: null,
        products: [],
    },
    isProductsSettingsModalOpen: false,
    offerTaxes: {
        data: [],
        errors: [],
        isFetching: false,
    },
    save: {
        data: {},
        errors: [],
        isSaving: false,
    },
    saveProductsSettings: {
        data: {},
        errors: [],
        isSaving: false,
    },
};

const proposalGeneratorTaxesTableSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [CREATE]: (state) => {
            state.create = { ...state.create, errors: [], isCreating: true };
        },
        [CREATE_FAILURE]: (state, action) => {
            state.create = {
                ...state.create,
                errors: action.payload,
                isCreating: false,
            };
        },
        [CREATE_SUCCESS]: (state, action) => {
            state.create = {
                ...state.create,
                data: action.payload,
                isCreating: false,
            };
        },
        [DELETE]: (state) => {
            state.delete = { ...state.delete, errors: [], isDeleting: true };
        },
        [DELETE_FAILURE]: (state, action) => {
            state.delete = {
                ...state.delete,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_SUCCESS]: (state, action) => {
            state.delete = {
                ...state.delete,
                data: action.payload,
                isDeleting: false,
            };
        },
        [FETCH_OFFER_TAXES]: (state) => {
            state.offerTaxes = {
                ...state.offerTaxes,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_TAXES_FAILURE]: (state, action) => {
            state.offerTaxes = {
                ...state.offerTaxes,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_TAXES_SUCCESS]: (state, action) => {
            state.offerTaxes = {
                ...state.offerTaxes,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [INITIALIZE_PRODUCTS_SETTINGS_VALUES]: (state, action) => {
            state.initialValuesProductsSettings = {
                ...state.initialValuesProductsSettings,
                ...action.payload,
            };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [RESET_PRODUCTS_SETTINGS_INITIAL_VALUES]: (state) => {
            state.initialValuesProductsSettings =
                INITIAL_STATE.initialValuesProductsSettings;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [SAVE_PRODUCTS_SETTINGS]: (state) => {
            state.saveProductsSettings = {
                ...state.saveProductsSettings,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_PRODUCTS_SETTINGS_FAILURE]: (state, action) => {
            state.saveProductsSettings = {
                ...state.saveProductsSettings,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_PRODUCTS_SETTINGS_SUCCESS]: (state, action) => {
            state.saveProductsSettings = {
                ...state.saveProductsSettings,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_PRODUCTS_SETTINGS_MODAL_IS_OPEN]: (state, action) => {
            state.isProductsSettingsModalOpen = action.payload;
        },
    },
});

export const proposalGeneratorTaxesTableActions =
    proposalGeneratorTaxesTableSlice.actions;

export default proposalGeneratorTaxesTableSlice.reducer;
