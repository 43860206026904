import { createSlice } from '@reduxjs/toolkit';

import { PREFIX_AFTERSALES_PROJECTS } from 'common/sockets/constants';
import { generateSocketActions } from 'common/sockets/helpers';
import { formatDate } from 'common/utils/dates';
import { parseJSON } from 'common/utils/helpers';

import {
    FETCH_ACCUMULATED_SAVINGS,
    FETCH_ACCUMULATED_SAVINGS_FAILURE,
    FETCH_ACCUMULATED_SAVINGS_SUCCESS,
    FETCH_CONSUMPTION_HISTORY,
    FETCH_CONSUMPTION_HISTORY_FAILURE,
    FETCH_CONSUMPTION_HISTORY_SUCCESS,
    FETCH_MEASURED_ENERGY,
    FETCH_MEASURED_ENERGY_FAILURE,
    FETCH_MEASURED_ENERGY_SUCCESS,
    FETCH_REFERENCE_INFO,
    FETCH_REFERENCE_INFO_FAILURE,
    FETCH_REFERENCE_INFO_SUCCESS,
    FILL_EMPTY_READS,
    FILL_EMPTY_READS_FAILURE,
    FILL_EMPTY_READS_SUCCESS,
    INITIALIZE_GENERATION_ACCURACY,
    INITIALIZE_GENERATION_RATIO,
    INITIALIZE_MEASUREMENT_SOURCE,
    INITIALIZE_ROI_PROGRESS,
    RESET,
} from './actionTypes';
import {
    DATE_OPTIONS,
    NAME,
    SYNC_EVENT_TYPES,
    VIEW_OPTIONS,
} from './constants';
import { getMaxDate } from './helpers';

const INITIAL_STATE = {
    accumulatedSavings: { data: {}, errors: null, isLoading: false },
    consumptionHistory: { data: {}, errors: null, isLoading: false },
    fillEmptyReads: { errors: null, fetched: false, isLoading: false },
    initialValuesGenerationAccuracy: {
        date_range: null,
        view_option: VIEW_OPTIONS.MONTHLY,
    },
    initialValuesGenerationRatio: {
        date_range: null,
        view_option: VIEW_OPTIONS.MONTHLY,
    },
    initialValuesRoiProgress: {
        date_range: null,
        view_option: VIEW_OPTIONS.MONTHLY,
    },
    initialValuesMeasurementSource: {
        date: formatDate(
            getMaxDate(),
            DATE_OPTIONS[VIEW_OPTIONS.DAILY_HOUR].format,
        ),
        view_option: VIEW_OPTIONS.DAILY_HOUR,
    },
    projects_sync: {},
    measuredEnergy: { data: {}, errors: null, isLoading: false },
    referenceInfo: { data: {}, errors: null, isLoading: false },
    socket: { connected: false, error: false },
};

const {
    BROKEN_SOCKET,
    CLOSED_SOCKET,
    ERROR_SOCKET,
    MESSAGE_SOCKET,
    OPEN_SOCKET,
    RECONNECTED,
} = generateSocketActions(PREFIX_AFTERSALES_PROJECTS);

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_ACCUMULATED_SAVINGS]: (state) => {
            state.accumulatedSavings.errors = null;
            state.accumulatedSavings.isLoading = true;
        },
        [FETCH_ACCUMULATED_SAVINGS_FAILURE]: (state, action) => {
            state.accumulatedSavings.errors = action.payload;
            state.accumulatedSavings.isLoading = false;
        },
        [FETCH_ACCUMULATED_SAVINGS_SUCCESS]: (state, action) => {
            state.accumulatedSavings.data = action.payload;
            state.accumulatedSavings.isLoading = false;
        },
        [FETCH_CONSUMPTION_HISTORY]: (state) => {
            state.consumptionHistory.errors = null;
            state.consumptionHistory.isLoading = true;
        },
        [FETCH_CONSUMPTION_HISTORY_FAILURE]: (state, action) => {
            state.consumptionHistory.errors = action.payload;
            state.consumptionHistory.isLoading = false;
        },
        [FETCH_CONSUMPTION_HISTORY_SUCCESS]: (state, action) => {
            state.consumptionHistory.data = action.payload;
            state.consumptionHistory.isLoading = false;
        },
        [FETCH_MEASURED_ENERGY]: (state) => {
            state.measuredEnergy.data = {};
            state.measuredEnergy.errors = null;
            state.measuredEnergy.isLoading = true;
        },
        [FETCH_MEASURED_ENERGY_FAILURE]: (state, action) => {
            state.measuredEnergy.errors = action.payload;
            state.measuredEnergy.isLoading = false;
        },
        [FETCH_MEASURED_ENERGY_SUCCESS]: (state, action) => {
            state.measuredEnergy.data = action.payload;
            state.measuredEnergy.isLoading = false;
        },
        [FETCH_REFERENCE_INFO]: (state) => {
            state.referenceInfo.errors = null;
            state.referenceInfo.isLoading = true;
        },
        [FETCH_REFERENCE_INFO_FAILURE]: (state, action) => {
            state.referenceInfo.errors = action.payload;
            state.referenceInfo.isLoading = false;
        },
        [FETCH_REFERENCE_INFO_SUCCESS]: (state, action) => {
            state.referenceInfo.data = action.payload;
            state.referenceInfo.isLoading = false;
        },
        [FILL_EMPTY_READS]: (state) => {
            state.fillEmptyReads.errors = null;
            state.fillEmptyReads.isLoading = true;
        },
        [FILL_EMPTY_READS_FAILURE]: (state, action) => {
            state.fillEmptyReads.errors = action.payload;
            state.fillEmptyReads.isLoading = false;
        },
        [FILL_EMPTY_READS_SUCCESS]: (state, action) => {
            state.fillEmptyReads.isLoading = false;
            state.fillEmptyReads.fetched = action.payload.fetched || false;
        },
        [INITIALIZE_GENERATION_ACCURACY]: (state, action) => {
            state.initialValuesGenerationAccuracy = action.payload;
        },
        [INITIALIZE_GENERATION_RATIO]: (state, action) => {
            state.initialValuesGenerationRatio = action.payload;
        },
        [INITIALIZE_MEASUREMENT_SOURCE]: (state, action) => {
            state.initialValuesMeasurementSource = action.payload;
        },
        [INITIALIZE_ROI_PROGRESS]: (state, action) => {
            state.initialValuesRoiProgress = action.payload;
        },
        [RESET]: () => INITIAL_STATE,
    },
    extraReducers: (builder) => {
        builder
            .addCase(BROKEN_SOCKET, (state) => {
                state.socket.connected = false;
                state.socket.error = true;
            })
            .addCase(CLOSED_SOCKET, (state) => {
                state.socket.connected = false;
            })
            .addCase(ERROR_SOCKET, (state) => {
                state.socket.connected = false;
                state.socket.error = true;
            })
            .addCase(MESSAGE_SOCKET, (state, action) => {
                const parsedMessage = parseJSON(action?.payload?.message || {});

                const { station_id, event_type } = parsedMessage;
                if (!station_id) return;

                if (
                    [SYNC_EVENT_TYPES.ERROR, SYNC_EVENT_TYPES.SUCCESS].includes(
                        event_type,
                    )
                ) {
                    delete state.projects_sync[station_id];
                } else {
                    state.projects_sync[station_id] = parsedMessage;
                }
            })
            .addCase(OPEN_SOCKET, (state) => {
                state.socket.connected = true;
            })
            .addCase(RECONNECTED, (state) => {
                state.socket.connected = true;
                state.socket.error = false;
            });
    },
});

export const actions = slice.actions;

export default slice.reducer;
