import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from 'sunwise-ui';

const ReactHookFormTextarea = ({
    control,
    name,
    placeholder,
    label,
    rows,
    sx,
}) => (
    <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
            <TextField
                error={Boolean(error)}
                fullWidth
                helperText={error?.message}
                label={label}
                multiline
                placeholder={placeholder}
                rows={rows}
                sx={sx}
                {...field}
            />
        )}
    />
);

ReactHookFormTextarea.propTypes = {
    control: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rows: PropTypes.number,
    sx: PropTypes.object,
};

export default ReactHookFormTextarea;
