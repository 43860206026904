import React from 'react';

import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import SortableItem from './SortableItem';

const SortableList = ({ indicators, onDragEnd, onSelectItem }) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`indicators-items`}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {indicators.map((item, index) => (
                            <SortableItem
                                id={`item-${item.id}`}
                                index={index}
                                item={item}
                                key={`item-${item.id}`}
                                onSelectItem={onSelectItem}
                            />
                        ))}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

SortableList.propTypes = {
    indicators: PropTypes.array,
    onDragEnd: PropTypes.func,
    onSelectItem: PropTypes.func,
};

export default SortableList;
