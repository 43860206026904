import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';

import { temporalSourceOptions } from '../helpers';

const Source = ({ control, isoCode, isDisabledSource, setValue }) => {
    const { t } = useTranslation();
    if (isDisabledSource) return null;
    return (
        <Grid item xs>
            <ReactHookFormSelect
                control={control}
                disabled={isDisabledSource}
                label={t('Source')}
                name="source"
                onChanged={(e) => setValue('source', e.target.value)}
                options={temporalSourceOptions(isoCode)}
                type="text"
            />
        </Grid>
    );
};

Source.propTypes = {
    control: PropTypes.object,
    isDisabledSource: PropTypes.bool,
    isoCode: PropTypes.string,
    setValue: PropTypes.func,
};

export default Source;
