import React, { useContext, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, ConfirmDialog } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { LoadingContext } from 'common/utils/contexts';

import * as actions from './actions';
import { Modal, DocumentsList } from './components';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    canView,
    dataConfirmModal,
    dataDocuments,
    fetchDocumentsProjectType,
    getDocuments,
    handleClickCloseModal,
    handleClickDelete,
    initialValues,
    isDocumentDeleting,
    isFetchingDocuments,
    isOpenConfirmModal,
    isUploading,
    isVisibleModal,
    onSubmitForm,
    openConfirmModal,
    prepareDelete,
    prepareUpdateForm,
    projectId,
    reset,
    resetForm,
    showModal,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        fetchDocumentsProjectType();
        getDocuments(projectId);
        return () => {
            reset();
        };
    }, [projectId]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isUploading)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...'),
            );
        else loadingContext.closeLoading();
    }, [isUploading]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isDocumentDeleting)
            loadingContext.openLoading(t('Removing').concat('...'));
        else loadingContext.closeLoading();
    }, [isDocumentDeleting]);

    if (!canView) return null;

    return (
        <Box mb={2}>
            <DocumentsList
                canDelete={canDelete}
                canModify={canModify}
                documents={dataDocuments}
                handleClickAdd={() => showModal(true)}
                handleClickDelete={prepareDelete}
                handleClickUpdate={prepareUpdateForm}
                isFetchingDocuments={isFetchingDocuments}
            />

            <Modal
                handleClickClose={handleClickCloseModal}
                handleSubmitForm={(values) => onSubmitForm(values, projectId)}
                isEdit={!isEmpty(initialValues?.file)}
                isUploading={isUploading}
                resetForm={resetForm}
                show={isVisibleModal}
            />

            <ConfirmDialog
                handleClickClose={() => openConfirmModal(false)}
                handleClickSuccess={handleClickDelete}
                isOpen={isOpenConfirmModal}
                message={dataConfirmModal && dataConfirmModal.message}
                title={dataConfirmModal && dataConfirmModal.title}
                variant={dataConfirmModal && dataConfirmModal.type}
            />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    dataConfirmModal: selectors.getDataConfirmModal,
    dataDocuments: selectors.getDocumentsData,
    initialValues: selectors.getInitialValues,
    isDocumentDeleting: selectors.getIsDocumentDeleting,
    isFetchingDocuments: selectors.getIsLoadingFetchDocuments,
    isOpenConfirmModal: selectors.getIsOpenConfirmModal,
    isUploading: selectors.getIsUploading,
    isVisibleModal: selectors.getIsVisibleModal,
});

const mapDispatchToProps = (dispatch) => ({
    fetchDocumentsProjectType: () =>
        dispatch(actions.fetchDocumentsProjectType()),
    getDocuments: (projectId) => dispatch(actions.fetchDocuments(projectId)),
    handleClickCloseModal: () => dispatch(actions.closeModal()),
    handleClickDelete: () => dispatch(actions.deleteFile()),
    openConfirmModal: (value) => dispatch(actions.openConfirmModal(value)),
    onSubmitForm: (values, projectId) =>
        dispatch(actions.onSubmitForm(values, projectId)),
    prepareDelete: (element) => dispatch(actions.prepareDelete(element)),
    prepareUpdateForm: (document) =>
        dispatch(actions.prepareUpdateForm(document)),
    reset: () => dispatch(actions.reset()),
    resetForm: () => dispatch(actions.resetForm()),
    showModal: (value) => dispatch(actions.showModal(value)),
});

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    dataConfirmModal: PropTypes.object,
    dataDocuments: PropTypes.array,
    fetchDocumentsProjectType: PropTypes.func,
    getDocuments: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleClickDelete: PropTypes.func,
    initialValues: PropTypes.object,
    isDocumentDeleting: PropTypes.bool,
    isFetchingDocuments: PropTypes.bool,
    isOpenConfirmModal: PropTypes.bool,
    isUploading: PropTypes.bool,
    isVisibleModal: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    openConfirmModal: PropTypes.func,
    prepareDelete: PropTypes.func,
    prepareUpdateForm: PropTypes.func,
    projectId: PropTypes.string,
    reset: PropTypes.func,
    resetForm: PropTypes.func,
    showModal: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.COMMERCIAL_OFFER_DOCUMENTS_PERMISSION),
)(Container);
