import get from 'lodash/get';

import initializeForm from './initializeForm';
import openModalProduct from './openModalProduct';
import setIsNewRecord from './setIsNewRecord';

export default (item, disabled = false) =>
    (dispatch) => {
        const values = {
            branch_offices:
                item.branch_office === null
                    ? 'company'
                    : get(item, 'branch_office.id', ''),
            brand: item.brand,
            brand_name: item.brand_name,
            code: item.code,
            cost: item.cost,
            costing_unit: item.costing_unit,
            currency: item?.currency?.id,
            description: item.description,
            disabled: disabled,
            id: item.id,
            image: item.image,
            name: item.name,
            product_type: item.product_type,
            range_prices:
                item.range_prices &&
                item.range_prices.map((range) => ({
                    cost: range.cost,
                    costing_unit: range.costing_unit,
                    final_cost: range.final_cost,
                    id: range.id,
                    is_unique_price: range.is_unique_price || false,
                    margin: range.margin,
                    price_from: range.price_from,
                    price_to: range.price_to,
                })),
            status_flag: item.status_flag,
        };

        dispatch(setIsNewRecord(false));
        dispatch(initializeForm(values));
        dispatch(openModalProduct(true));
    };
