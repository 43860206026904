import { getOfferInfo } from 'common/api/v1/offerInfoPatern';

import {
    FETCH_OFFER_INFO_PATERN,
    FETCH_OFFER_INFO_PATERN_FAILURE,
    FETCH_OFFER_INFO_PATERN_SUCCESS,
} from '../actionTypes';
import { panelLayoutV2Actions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(panelLayoutV2Actions[FETCH_OFFER_INFO_PATERN]());

        getOfferInfo(id)
            .then((response) => {
                const {
                    contact_id,
                    contact_name,
                    has_approbed,
                    has_permalink,
                    has_signature,
                    permalink,
                    project_id,
                    project_name,
                    proposal_item,
                    proposal_name,
                    status,
                } = response.data;
                dispatch(
                    panelLayoutV2Actions[FETCH_OFFER_INFO_PATERN_SUCCESS]({
                        contactId: contact_id,
                        contactName: contact_name,
                        creditApplication: proposal_item?.credit_application,
                        creditItem: proposal_item?.credit_item,
                        hasPermalink: has_permalink,
                        hasSignature: has_signature,
                        isApproved: has_approbed,
                        permalink: permalink,
                        projectId: project_id,
                        projectName: project_name,
                        proposalName: proposal_name,
                        proposalStatus: status,
                    }),
                );
            })
            .catch((error) =>
                dispatch(
                    panelLayoutV2Actions[FETCH_OFFER_INFO_PATERN_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            )
            .finally(resolve);
    });
