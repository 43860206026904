import fetchProjectDetails from '../../project/actions/fetchProjectDetails';
import { SET_IS_INITIALIZING } from '../actionTypes';
import { quickViewProjectsActions } from '../reducer';

import fetchConsumptionEnergy from './fetchConsumptionEnergy';
import fetchLocation from './fetchLocation';

export default (id) => (dispatch) => {
    dispatch(quickViewProjectsActions[SET_IS_INITIALIZING](true));
    Promise.all([
        dispatch(fetchConsumptionEnergy(id)),
        dispatch(fetchLocation(id)),
        dispatch(fetchProjectDetails(id)),
    ])
        .then(() =>
            dispatch(quickViewProjectsActions[SET_IS_INITIALIZING](false)),
        )
        .catch(() =>
            dispatch(quickViewProjectsActions[SET_IS_INITIALIZING](false)),
        );
};
