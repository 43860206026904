import i18next from 'i18next';

import {
    FILTER_TYPE_CALL,
    FILTER_TYPE_MAIL,
    FILTER_TYPE_MESSAGE,
    FILTER_TYPE_OTHER,
    FILTER_TYPE_VISIT,
} from './constants';

export const getContactsOptions = (contacts) => {
    const options = contacts.map((item) => {
        const label = `${item.name} ${item.first_lastname} ${item.second_lastname}`;
        return {
            label,
            value: item.id,
        };
    });
    return [
        {
            disabled: true,
            label: i18next.t('Select a contact'),
            value: '',
        },
        ...options,
    ];
};

export const getUsersOptions = (users) => {
    return users.map((agent) => {
        let name = `${agent.first_name} ${agent.last_name}`;
        if (!agent.has_access) name = `${name} (${i18next.t('No access')})`;
        return {
            display: name,
            id: agent.email,
        };
    });
};

export const getTrackingTypesOptions = () => {
    return [
        {
            label: i18next.t('Call'),
            value: FILTER_TYPE_CALL,
        },
        {
            label: i18next.t('Mail'),
            value: FILTER_TYPE_MAIL,
        },
        {
            label: i18next.t('Visit'),
            value: FILTER_TYPE_VISIT,
        },
        {
            label: i18next.t('Message'),
            value: FILTER_TYPE_MESSAGE,
        },
        {
            label: i18next.t('Other'),
            value: FILTER_TYPE_OTHER,
        },
    ];
};
