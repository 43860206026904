import React from 'react';

import PropTypes from 'prop-types';
import { BottomActions, Box, Button } from 'sunwise-ui';

const ModalButtons = ({
    cancelText,
    disabledCancel,
    disabledNext,
    nextButtonType,
    nextText,
    onCancel,
    onClickNext,
}) => (
    <BottomActions>
        <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent={{ xs: 'center', md: 'right' }}
            mt={2}
            gap={1}
            width="100%"
        >
            <Button
                color="secondary"
                disabled={disabledCancel}
                onClick={onCancel}
                sx={{
                    order: { xs: 2, md: 1 },
                    width: { xs: '100%', md: 'auto' },
                }}
                type="button"
                variant="text"
            >
                {cancelText}
            </Button>

            <Button
                disabled={disabledNext}
                onClick={onClickNext}
                sx={{
                    order: { xs: 1, md: 2 },
                    width: { xs: '100%', md: 'auto' },
                }}
                type={nextButtonType}
                variant="outlined"
            >
                {nextText}
            </Button>
        </Box>
    </BottomActions>
);

ModalButtons.propTypes = {
    cancelText: PropTypes.string,
    disabledCancel: PropTypes.bool,
    disabledNext: PropTypes.bool,
    nextButtonType: PropTypes.string,
    nextText: PropTypes.string,
    onCancel: PropTypes.func,
    onClickNext: PropTypes.func,
};

export default ModalButtons;
