import { markAllNotificationsAsRead } from 'common/api/v1/notifications';

import {
    MARK_ALL_NOTIFICATIONS_AS_READ,
    MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE,
    MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
} from '../actionTypes';
import { notificationsActions } from '../reducer';

import fetchNavNotifications from './fetchNavNotifications';
import fetchNotifications from './fetchNotifications';

export default (filterData) => (dispatch) => {
    dispatch(notificationsActions[MARK_ALL_NOTIFICATIONS_AS_READ]());

    markAllNotificationsAsRead()
        .then(() => {
            dispatch(
                notificationsActions[MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS](),
            );
            dispatch(fetchNavNotifications());
            dispatch(fetchNotifications(filterData));
        })
        .catch((error) => {
            dispatch(
                notificationsActions[MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
        });
};
