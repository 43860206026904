import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import applyCredit from './applyCredit';

export default (callback, isArchived, offerId, projectId, requestCount) =>
    (dispatch) => {
        let messages = [
            i18next.t(
                'Upon acceptance, the application will be sent and the process will begin',
            ),
        ];

        if (requestCount >= 1) {
            messages.push(
                i18next.t(
                    'A financial request already exists in this project ¿Are you sure you want to remove it and continue with a new one?',
                ),
            );
        }

        const dataAlert = {
            confirmText: i18next.t('Accept'),
            messages: messages,
            onSuccess: () => {
                dispatch(applyCredit(callback, isArchived, offerId, projectId));
            },
            title: i18next.t('Apply for'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };

        dispatch(alerts.actions.show(dataAlert));
    };
