import { updateTimeShiftingOffer } from 'common/api/v2/timeShiftingOffer';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { DEFAULT_DOD } from '../constants';
import { filterValidArrayFields } from '../helpers';
import { advancedTimeShiftSettingsActions } from '../reducer';

export default (proposalId, values, offerStorageData) => (dispatch) => {
    if (!values.id) return;

    const newValues = {
        algorithm: values.algorithm,
        c_rate: values.c_rate,
        charging_algorithm: values.charging_algorithm,
        charge_limits: filterValidArrayFields(values?.charge_limits),
        controlled_charging_is_active: values.controlled_charging_is_active,
        controlled_discharging_is_active:
            values.controlled_discharging_is_active,
        degradation_percentage:
            parseFloat(values.degradation_percentage) / 100 || 0,
        discharge_limits: filterValidArrayFields(values?.discharge_limits),
        discharge_power: parseFloat(values.discharge_power),
        dod:
            parseFloat(values.dod) / 100 ||
            offerStorageData?.battery_offer?.[0]?.dod ||
            DEFAULT_DOD / 100,
        efficiency: parseFloat(values.efficiency) / 100,
        exported_energy_charge: values?.exported_energy_charge || false,
        load_capacity: parseFloat(values.load_capacity || 0),
        peak_shaving: filterValidArrayFields(values?.peak_shaving),
        peak_shaving_is_active: values.peak_shaving_is_active,
        time_shifting: values.id,
    };

    dispatch(advancedTimeShiftSettingsActions[SAVE]());

    updateTimeShiftingOffer(proposalId, newValues)
        .then((response) => {
            dispatch(
                advancedTimeShiftSettingsActions[SAVE_SUCCESS](
                    response.data.data,
                ),
            );
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                advancedTimeShiftSettingsActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
