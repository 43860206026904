import * as selectors from '../selectors';

import initializeForm from './initializeForm';
import showForm from './showForm';

export default (id) => (dispatch, getState) => {
    const state = getState();
    const trackings = selectors.getDataTrackings(state);
    const trackingsNext = selectors.getDataTrackingsNext(state);

    const tracking =
        trackings.find((tracking) => tracking.id === id) ||
        trackingsNext.find((tracking) => tracking.id === id);

    const values = {
        agent: tracking.agent,
        contact: `${tracking.contact.first_name} ${tracking.contact.first_lastname}`,
        description: tracking.description,
        id: tracking.id,
        notification_at: tracking.notification_at,
        objective_tracking: tracking.objective_tracking,
        remember: tracking.remember,
        tracking_type: tracking.tracking_type,
    };

    dispatch(initializeForm(values));
    dispatch(showForm(true));
};
