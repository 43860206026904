import React, { useEffect } from 'react';

import { isEmpty, toNumber } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';

import {
    getHitchToSelect,
    handleOnChangeTermOrHitch,
    hasCommissionDict,
    hasRateDict,
    percentageToValue,
    valueToPercentage,
} from '../../helpers';

import HitchTypeField from './HitchTypeField';

const HitchField = ({
    control,
    currencyIso,
    currencySymbol,
    formValues,
    isLocked,
    isSerfimex = false,
    parameters,
    setValue,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (formValues.is_percentage !== 'true')
            setValue('hitch', formValues.hitch);
        else setValue('hitch_percentage', formValues.hitch_percentage);
    }, [formValues.is_percentage]);

    if (hasCommissionDict(parameters) || hasRateDict(parameters)) {
        return (
            <ReactHookFormSelect
                control={control}
                disabled={isLocked || isEmpty(formValues.financier_product)}
                fullWidth
                label={t('Down payment')}
                name="hitch_percentage"
                onBlur={() => {
                    const hitchValue = percentageToValue(
                        true,
                        toNumber(formValues?.hitch_percentage),
                        formValues?.total_with_dealer_fee,
                    );
                    setValue('hitch', hitchValue);
                    handleOnChangeTermOrHitch({
                        parameters,
                        setValue,
                        term: formValues.term,
                        hitch: toNumber(formValues?.hitch_percentage),
                    });
                }}
                options={[
                    {
                        label: t('Select a down payment'),
                        value: '',
                        disabled: true,
                    },
                    ...getHitchToSelect(
                        hasCommissionDict(parameters),
                        parameters,
                    ),
                ]}
            />
        );
    }

    if (formValues.is_percentage === 'true') {
        return (
            <Box sx={{ alignItems: 'flex-start', display: 'flex' }}>
                <ReactHookFormIntlNumberInput
                    append="%"
                    control={control}
                    disabled={isLocked || isEmpty(formValues.financier_product)}
                    fullWidth
                    label={t('Down payment')}
                    name="hitch_percentage"
                    onBlur={() => {
                        const hitchValue = percentageToValue(
                            true,
                            toNumber(formValues?.hitch_percentage),
                            formValues?.total_with_dealer_fee,
                        );
                        setValue('hitch', hitchValue);
                        handleOnChangeTermOrHitch({
                            parameters,
                            setValue,
                            term: formValues.term,
                            hitch: toNumber(formValues?.hitch_percentage),
                        });
                    }}
                    sx={{ mb: '0 !important' }}
                />
                {!isSerfimex && (
                    <HitchTypeField
                        formValues={formValues}
                        isLocked={isLocked}
                        setValue={setValue}
                    />
                )}
            </Box>
        );
    }

    return (
        <Box sx={{ alignItems: 'flex-start', display: 'flex' }}>
            <ReactHookFormIntlNumberInput
                append={currencyIso}
                control={control}
                disabled={isLocked || isEmpty(formValues.financier_product)}
                fullWidth
                label={t('Down payment')}
                name="hitch"
                onBlur={() => {
                    setValue(
                        'hitch_percentage',
                        valueToPercentage(
                            false,
                            toNumber(formValues?.hitch),
                            formValues?.total_with_dealer_fee,
                        ),
                    );
                }}
                prepend={currencySymbol}
                sx={{ mb: '0 !important' }}
            />
            <HitchTypeField
                formValues={formValues}
                isLocked={isLocked}
                setValue={setValue}
            />
        </Box>
    );
};

HitchField.propTypes = {
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    formValues: PropTypes.object,
    isLocked: PropTypes.bool,
    isSerfimex: PropTypes.bool,
    parameters: PropTypes.object,
    setValue: PropTypes.func,
};

export default HitchField;
