import { types } from 'sunwise-template-core';

import {
    getReportEnded,
    getReportEndedWithoutToken,
} from 'common/api/v1/aftersalesPrinted';
import {
    getOfferEnded,
    getOfferEndedWithoutToken,
} from 'common/api/v1/newOfferEnded';
import {
    getSummaryEnded,
    getSummaryEndedWithoutToken,
} from 'common/api/v1/newSummaryEnded';
import { getTemplatecontent } from 'common/api/v1/renderContent';
import {
    getSmartDocumentEnded,
    getSmartDocumentEndedWithoutToken,
} from 'common/api/v1/smartDocuments';
import { getToken } from 'common/utils/helpers/session';

import { FINANCING_TYPE } from '../../multiTemplateReview/constants';
import {
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
} from '../actionTypes';
import { templateRenderActions } from '../reducer';

const getFetchTemplateAction = (templateType, token) => {
    if (token) {
        switch (templateType) {
            case FINANCING_TYPE:
                return getTemplatecontent;
            case types.MULTIPROPOSAL_TYPE:
                return getSummaryEnded;
            case types.SMART_DOCUMENTS_TYPE:
                return getSmartDocumentEnded;
            case types.REPORT_TYPE:
                return getReportEnded;
            default:
                return getOfferEnded;
        }
    } else {
        switch (templateType) {
            case FINANCING_TYPE:
                return getTemplatecontent;
            case types.MULTIPROPOSAL_TYPE:
                return getSummaryEndedWithoutToken;
            case types.SMART_DOCUMENTS_TYPE:
                return getSmartDocumentEndedWithoutToken;
            case types.REPORT_TYPE:
                return getReportEndedWithoutToken;
            default:
                return getOfferEndedWithoutToken;
        }
    }
};

export default (id, templateType) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch(templateRenderActions[FETCH_CUSTOM_TEMPLATE]());

        const fetchTemplateAction = getFetchTemplateAction(
            templateType,
            getToken(),
        );

        return fetchTemplateAction(id)
            .then((response) => {
                dispatch(
                    templateRenderActions[FETCH_CUSTOM_TEMPLATE_SUCCESS](
                        response.data.id,
                    ),
                );
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(
                    templateRenderActions[FETCH_CUSTOM_TEMPLATE_FAILURE](error),
                );
                reject();
            });
    });
