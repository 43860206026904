import i18next from 'i18next';

import { updateStaffPermission } from 'common/api/v1/security';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { profileSecurityPermissionsActions } from '../reducer';

import initializeForm from './initializeForm';

export default (values) => (dispatch) => {
    let newValues = {
        allow_staff_support: values.allow_staff_support ? 'True' : 'False',
    };

    dispatch(profileSecurityPermissionsActions[SAVE]());

    updateStaffPermission(newValues)
        .then((response) => {
            dispatch(
                profileSecurityPermissionsActions[SAVE_SUCCESS](response.data),
            );

            dispatch(
                initializeForm({
                    allow_staff_support: values.allow_staff_support,
                }),
            );

            if (values.allow_staff_support) {
                showToast({
                    autoClose: 5000,
                    body: i18next.t(
                        'Thank you for trusting Sunwise! You have updated your permissions to give you better attention from Support',
                    ),
                    type: 'success',
                });
            } else {
                showToast();
            }
        })
        .catch((error) => {
            dispatch(
                profileSecurityPermissionsActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
