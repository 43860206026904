import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

const DropdownActions = ({
    canModify,
    credentialId,
    filterData,
    handleOpenAssignProjectModal,
    powerStation,
    prepareUnassignProject,
}) => {
    const { t } = useTranslation();
    return (
        <DropdownCardMenu
            items={[
                {
                    handleClick: () =>
                        handleOpenAssignProjectModal(powerStation, filterData),
                    label: t('Assign project'),
                },
                {
                    handleClick: () =>
                        prepareUnassignProject({
                            credentialId,
                            filterData,
                            powerStation,
                        }),
                    label: t('Unassign project'),
                    visible: false,
                },
            ]}
            visible={canModify && !powerStation.project}
        />
    );
};

DropdownActions.propTypes = {
    canModify: PropTypes.bool,
    credentialId: PropTypes.string,
    filterData: PropTypes.object,
    handleOpenAssignProjectModal: PropTypes.func,
    powerStation: PropTypes.object,
    prepareUnassignProject: PropTypes.func,
};

export default DropdownActions;
