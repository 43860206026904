import React from 'react';

import { Box, Chip } from '@mui/material';
import PropTypes from 'prop-types';

const TabLabel = ({ count = 0, label }) => (
    <Box
        sx={{
            alignItems: 'center',
            display: 'flex',
            fontSize: '10px',
            gap: 1,
        }}
    >
        {label}{' '}
        <Chip
            as="span"
            label={count}
            size="small"
            sx={{
                borderRadius: '8px',
                fontSize: '10px',
                fontWeight: 'bold',
                padding: 0,
            }}
        />
    </Box>
);

TabLabel.propTypes = {
    count: PropTypes.number,
    label: PropTypes.string,
};

export default TabLabel;
