import { getSegments } from 'common/api/v2/offerDesign';

import {
    FETCH_PARENT_FIELD_SEGMENTS,
    FETCH_PARENT_FIELD_SEGMENTS_FAILURE,
} from '../actionTypes';
import { proposalGeneratorPanelsLayoutActions } from '../reducer';

import initialValues from './initialValues';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(
            proposalGeneratorPanelsLayoutActions[FETCH_PARENT_FIELD_SEGMENTS](),
        );

        getSegments(id)
            .then((response) => {
                dispatch(initialValues(response));
            })
            .catch((error) =>
                dispatch(
                    proposalGeneratorPanelsLayoutActions[
                        FETCH_PARENT_FIELD_SEGMENTS_FAILURE
                    ](error?.response?.data?.errors),
                ),
            )
            .finally(resolve);
    });
