import initializeForm from './initializeForm';
import toggleModal from './toggleModal';

export default (formValues, source, isNewRecord) => (dispatch) => {
    const values = Object.assign({}, formValues);
    let hrsSol = [];
    let kwhkw = [];

    for (let i = 0; i < 12; i++) {
        if (source[0].monthly_solar_radiation_hrsol[i]) {
            hrsSol.push({
                value: source[0].monthly_solar_radiation_hrsol[i].average,
            });
            kwhkw.push({
                value: source[0].monthly_solar_radiation_hrsol[i].average * 365,
            });
        } else {
            hrsSol.push({ value: 0 });
            kwhkw.push({ value: 0 });
        }
    }

    values.temp_min = source[0].temp_min;
    values.temp_max = source[0].temp_max;
    values.hrsSol = hrsSol;
    values.kwhkw = kwhkw;

    values.originalValues = {
        hrsSol,
        kwhkw,
    };

    if (!isNewRecord) {
        values.id = source[0].id;
        values.origin = source[0].origin ? source[0].origin.source : null;
    } else {
        values.id = null;
        values.increment = 100;
        values.origin = null;
    }

    dispatch(initializeForm(values));
    dispatch(toggleModal(true));
};
