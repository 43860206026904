import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormIntlNumberInputConsumption,
} from 'common/components/form/bootstrap';
import { HIGH_TENSION_RATES } from 'common/constants/rates';

import { setHourlyContractedDemand } from '../helpers';
import * as selectors from '../selectors';

import FormAccordion from './FormAccordion';

const ContractedDemandContainer = ({
    contractedDemandFields,
    control,
    disabled,
    getValues,
    scheduleRateConfiguration,
    setValue,
    selectedRate,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        setHourlyContractedDemand({
            certified: selectedRate?.certified,
            getValues,
            setValue,
            tiers: scheduleRateConfiguration?.tiers_demand_distribution?.[0]
                ?.tiers,
        });
    }, [scheduleRateConfiguration]);

    const isCertified =
        selectedRate?.certified &&
        HIGH_TENSION_RATES.includes(selectedRate?.name);

    if (!selectedRate || (!isCertified && !contractedDemandFields?.length))
        return null;

    return (
        <FormAccordion hasFormErrors={false} title={t('Contracted demand')}>
            <Grid container>
                {isCertified && (
                    <Grid item xs={18} lg={9}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append="kW"
                            control={control}
                            decimalsLimit={2}
                            disabled={disabled}
                            fullWidth
                            label={t('Contracted demand')}
                            name="contracted_demand"
                            variant="standard"
                        />
                    </Grid>
                )}

                {!isCertified &&
                    contractedDemandFields.map((key) => (
                        <Grid
                            item
                            key={`contracted-demand-${key}`}
                            lg={9}
                            xs={18}
                        >
                            <ReactHookFormIntlNumberInputConsumption
                                append="kW"
                                control={control}
                                decimalsLimit={2}
                                disabled={disabled}
                                fullWidth
                                name={`hourly_contracted_demand.${key}`}
                                variant="standard"
                            />
                        </Grid>
                    ))}
            </Grid>
        </FormAccordion>
    );
};

ContractedDemandContainer.propTypes = {
    contractedDemandFields: PropTypes.array,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    scheduleRateConfiguration: PropTypes.object,
    selectedRate: PropTypes.object,
    setValue: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    scheduleRateConfiguration: selectors.getScheduleRateConfiguration,
});

export default connect(mapStateToProps)(ContractedDemandContainer);
