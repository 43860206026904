import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControl, FormHelperText, Slider } from 'sunwise-ui';

const ReactHookFormSlider = ({
    control,
    defaultValue,
    disabled,
    isLoading,
    marks,
    max,
    min,
    name,
    onChange,
    onChangeCommitted,
    size,
    sxFormControl,
    valueLabelDisplay,
}) => (
    <Controller
        control={control}
        name={name}
        render={({
            field: { onChange: onChangeField, value },
            fieldState: { error },
        }) => (
            <FormControl fullWidth error={Boolean(error)} sx={sxFormControl}>
                <Slider
                    disabled={disabled || isLoading}
                    defaultValue={defaultValue}
                    marks={marks}
                    max={max}
                    min={min}
                    onChange={(e) => {
                        onChangeField(e);
                        if (onChange) onChange(e);
                    }}
                    value={parseFloat(value) || 0}
                    valueLabelDisplay={valueLabelDisplay}
                    onChangeCommitted={(_, value) => {
                        if (onChangeCommitted) onChangeCommitted(value);
                    }}
                    size={size}
                />
                {Boolean(error) && (
                    <FormHelperText>{error?.message}</FormHelperText>
                )}
            </FormControl>
        )}
    />
);

ReactHookFormSlider.defaultProps = {
    sxFormControl: {},
    valueLabelDisplay: 'auto',
};

ReactHookFormSlider.propTypes = {
    control: PropTypes.object,
    defaultValue: PropTypes.number,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    marks: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    max: PropTypes.number,
    min: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onChangeCommitted: PropTypes.func,
    size: PropTypes.string,
    sxFormControl: PropTypes.object,
    valueLabelDisplay: PropTypes.string,
};

export default ReactHookFormSlider;
