import React from 'react';

import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const SystemSizeFormatter = ({ row }) => {
    const { system_size } = row;
    return <Typography variant="caption">{system_size} kW</Typography>;
};

SystemSizeFormatter.propTypes = {
    row: PropTypes.object,
};

export default SystemSizeFormatter;
