import { deleteProject } from 'common/api/afterSales/projects';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    TOGGLE_ASSIGN_PROJECT,
    TOGGLE_ASSIGN_PROJECT_FAILURE,
    TOGGLE_ASSIGN_PROJECT_SUCCESS,
} from '../actionTypes';
import { powerStationsActions } from '../reducer';

import fetchPowerStations from './fetchPowerStations';

export default ({ credentialId, filterData, powerStation }) =>
    (dispatch) => {
        if (!powerStation?.id) return;

        dispatch(powerStationsActions[TOGGLE_ASSIGN_PROJECT]());

        deleteProject(powerStation?.id)
            .then((response) => {
                dispatch(
                    powerStationsActions[TOGGLE_ASSIGN_PROJECT_SUCCESS](
                        response?.data,
                    ),
                );
                dispatch(fetchPowerStations(credentialId, filterData));
                showToast();
            })
            .catch((error) => {
                dispatch(
                    powerStationsActions[TOGGLE_ASSIGN_PROJECT_FAILURE](error),
                );
                showFastApiErrorsAsAlert(dispatch, error?.response);
            });
    };
