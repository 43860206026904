import { fetchListCatalogs } from 'common/api/v1/companiesCatalogs';

import {
    FETCH_STATUS_CONTACT,
    FETCH_STATUS_CONTACT_FAILURE,
    FETCH_STATUS_CONTACT_SUCCESS,
} from '../actionTypes';
import { STATUS_CONTACT_TYPE } from '../constants';
import { contactFormActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(contactFormActions[FETCH_STATUS_CONTACT]());

    fetchListCatalogs(STATUS_CONTACT_TYPE)
        .then((response) =>
            dispatch(
                contactFormActions[FETCH_STATUS_CONTACT_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(contactFormActions[FETCH_STATUS_CONTACT_FAILURE](error)),
        );
};
