import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import { Grid, Button } from 'sunwise-ui';

import ListItems from './ListItems';
import TemplateModal from './TemplateModal';

const TemplateTableSection = ({
    branchOfficesDictionary,
    branchSelected,
    canDelete,
    canModify,
    companyUsersData,
    filterTemplates,
    handleClickBulkItems,
    handleClickCreate,
    handleClickDelete,
    handleClickDuplicate,
    handleClickUpdate,
    handleSaveRow,
    isFetching,
    languagesDictionary,
    paginationData,
    tableSettings,
    templatesData,
    templateType,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        branchOfficeId:
            branchSelected === 'all' || branchOfficesDictionary.length === 0
                ? null
                : branchSelected === 'company'
                  ? null
                  : branchSelected,
        is_global: branchSelected !== 'company' ? null : true,
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
        status: 'active',
        type: templateType,
    });

    useEffect(() => {
        filterTemplates(filterData);
    }, []);

    return (
        <>
            <Grid container>
                <Grid item sx={{ mb: 2 }} textAlign="right" xs={18}>
                    <Button
                        onClick={() =>
                            handleClickCreate(
                                branchSelected,
                                null,
                                templateType,
                            )
                        }
                        sx={{ width: { md: 'auto', xs: '100%' } }}
                        type="button"
                        variant="outlined"
                        visible={canModify}
                    >
                        {t('Create template')}
                    </Button>
                </Grid>
            </Grid>

            <ListItems
                branchOfficesDictionary={branchOfficesDictionary}
                branchSelected={branchSelected}
                canDelete={canDelete}
                canModify={canModify}
                companyUsersData={companyUsersData}
                filterData={filterData}
                filterItems={filterTemplates}
                handleClickBulkItems={handleClickBulkItems}
                handleClickDelete={handleClickDelete}
                handleClickDuplicate={handleClickDuplicate}
                handleClickUpdate={handleClickUpdate}
                handleSaveRow={handleSaveRow}
                isFetching={isFetching}
                isForSummaryTemplates={
                    templateType === types.MULTIPROPOSAL_TYPE
                }
                items={templatesData}
                languagesDictionary={languagesDictionary}
                paginationData={paginationData}
                setFilterData={setFilterData}
                tableSettings={tableSettings}
                templateType={templateType}
            />

            <TemplateModal
                branchSelected={branchSelected}
                filterData={filterData}
                languagesDictionary={languagesDictionary}
            />
        </>
    );
};

TemplateTableSection.propTypes = {
    branchOfficesDictionary: PropTypes.array,
    branchSelected: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    companyUsersData: PropTypes.array,
    filterTemplates: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    paginationData: PropTypes.object,
    tableSettings: PropTypes.array,
    templatesData: PropTypes.array,
    templateType: PropTypes.number,
};

export default TemplateTableSection;
