import React from 'react';

import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const ServiceNumberFormatter = ({ row }) => {
    const { service_number } = row;
    return <Typography variant="caption">{service_number}</Typography>;
};

ServiceNumberFormatter.propTypes = {
    row: PropTypes.object,
};

export default ServiceNumberFormatter;
