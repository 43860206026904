import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_TABLE_SETTINGS,
    FETCH_TABLE_SETTINGS_FAILURE,
    FETCH_TABLE_SETTINGS_SUCCESS,
    RESET,
    SAVE_TABLE_SETTINGS,
    SAVE_TABLE_SETTINGS_FAILURE,
    SAVE_TABLE_SETTINGS_SUCCESS,
    SET_IS_OPEN_TABLE_SETTINGS_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchTableSettings: {
        data: [],
        errors: [],
        isFetching: true,
    },
    isOpenModal: false,
    saveTableSettings: {
        data: {},
        errors: [],
        isSaving: false,
    },
};

const tableSettingsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_TABLE_SETTINGS]: (state) => {
            state.fetchTableSettings.isFetching = true;
        },
        [FETCH_TABLE_SETTINGS_FAILURE]: (state, action) => {
            state.fetchTableSettings = {
                ...state.fetchTableSettings,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TABLE_SETTINGS_SUCCESS]: (state, action) => {
            state.fetchTableSettings = {
                ...state.fetchTableSettings,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [SAVE_TABLE_SETTINGS]: (state) => {
            state.saveTableSettings = {
                ...state.saveTableSettings,
                errors: null,
                isSaving: true,
            };
        },
        [SAVE_TABLE_SETTINGS_FAILURE]: (state, action) => {
            state.saveTableSettings = {
                ...state.saveTableSettings,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_TABLE_SETTINGS_SUCCESS]: (state, action) => {
            state.saveTableSettings = {
                ...state.saveTableSettings,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_IS_OPEN_TABLE_SETTINGS_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const tableSettingsActions = tableSettingsSlice.actions;

export default tableSettingsSlice.reducer;
