import { NAME } from './constants';

export const DELETE_OFFER_SMART_DOCUMENT = `${NAME}/DELETE_OFFER_SMART_DOCUMENT`;
export const DELETE_OFFER_SMART_DOCUMENT_FAILURE = `${NAME}/DELETE_OFFER_SMART_DOCUMENT_FAILURE`;
export const DELETE_OFFER_SMART_DOCUMENT_SUCCESS = `${NAME}/DELETE_OFFER_SMART_DOCUMENT_SUCCESS`;

export const DOWNLOAD_DATASHEET = `${NAME}/DOWNLOAD_DATASHEET`;
export const DOWNLOAD_DATASHEET_FAILURE = `${NAME}/DOWNLOAD_DATASHEET_FAILURE`;
export const DOWNLOAD_DATASHEET_SUCCESS = `${NAME}/DOWNLOAD_DATASHEET_SUCCESS`;

export const DUPLICATE_OFFER_SMART_DOCUMENT = `${NAME}/DUPLICATE_OFFER_SMART_DOCUMENT`;
export const DUPLICATE_OFFER_SMART_DOCUMENT_FAILURE = `${NAME}/DUPLICATE_OFFER_SMART_DOCUMENT_FAILURE`;
export const DUPLICATE_OFFER_SMART_DOCUMENT_SUCCESS = `${NAME}/DUPLICATE_OFFER_SMART_DOCUMENT_SUCCESS`;

export const FETCH_CUSTOM_TEMPLATE = `${NAME}/FETCH_CUSTOM_TEMPLATE`;
export const FETCH_CUSTOM_TEMPLATE_FAILURE = `${NAME}/FETCH_CUSTOM_TEMPLATE_FAILURE`;
export const FETCH_CUSTOM_TEMPLATE_SUCCESS = `${NAME}/FETCH_CUSTOM_TEMPLATE_SUCCESS`;

export const FETCH_DOCUMENT_TEMPLATES = `${NAME}/FETCH_DOCUMENT_TEMPLATES`;
export const FETCH_DOCUMENT_TEMPLATES_FAILURE = `${NAME}/FETCH_DOCUMENT_TEMPLATES_FAILURE`;
export const FETCH_DOCUMENT_TEMPLATES_SUCCESS = `${NAME}/FETCH_DOCUMENT_TEMPLATES_SUCCESS`;

export const FETCH_FINANCING_TEMPLATE = `${NAME}/FETCH_FINANCING_TEMPLATE`;
export const FETCH_FINANCING_TEMPLATE_FAILURE = `${NAME}/FETCH_FINANCING_TEMPLATE_FAILURE`;
export const FETCH_FINANCING_TEMPLATE_SUCCESS = `${NAME}/FETCH_FINANCING_TEMPLATE_SUCCESS`;

export const DOWNLOAD_OFFER_CSV = `${NAME}/DOWNLOAD_OFFER_CSV`;
export const DOWNLOAD_OFFER_CSV_FAILURE = `${NAME}/DOWNLOAD_OFFER_CSV_FAILURE`;
export const DOWNLOAD_OFFER_CSV_SUCCESS = `${NAME}/DOWNLOAD_OFFER_CSV_SUCCESS`;

export const DOWNLOAD_OFFER_HOURLY_CSV = `${NAME}/DOWNLOAD_OFFER_HOURLY_CSV`;
export const DOWNLOAD_OFFER_HOURLY_CSV_FAILURE = `${NAME}/DOWNLOAD_OFFER_HOURLY_CSV_FAILURE`;
export const DOWNLOAD_OFFER_HOURLY_CSV_SUCCESS = `${NAME}/DOWNLOAD_OFFER_HOURLY_CSV_SUCCESS`;

export const DOWNLOAD_OFFER_PDF = `${NAME}/DONWLOAD_OFFER_PDF`;
export const DOWNLOAD_OFFER_PDF_SUCCESS = `${NAME}/DONWLOAD_OFFER_PDF_SUCCESS`;
export const DOWNLOAD_OFFER_PDF_FAILURE = `${NAME}/DONWLOAD_OFFER_PDF_FAILURE`;

export const DOWNLOAD_OFFER_XLS = `${NAME}/DOWNLOAD_OFFER_XLS`;
export const DOWNLOAD_OFFER_XLS_FAILURE = `${NAME}/DOWNLOAD_OFFER_XLS_FAILURE`;
export const DOWNLOAD_OFFER_XLS_SUCCESS = `${NAME}/DOWNLOAD_OFFER_XLS_SUCCESS`;

export const DOWNLOAD_OFFER_SMART_DOCUMENT_CSV = `${NAME}/DOWNLOAD_OFFER_SMART_DOCUMENT_CSV`;
export const DOWNLOAD_OFFER_SMART_DOCUMENT_CSV_FAILURE = `${NAME}/DOWNLOAD_OFFER_SMART_DOCUMENT_CSV_FAILURE`;
export const DOWNLOAD_OFFER_SMART_DOCUMENT_CSV_SUCCESS = `${NAME}/DOWNLOAD_OFFER_SMART_DOCUMENT_CSV_SUCCESS`;

export const FETCH_OFFER_DATASHEETS = `${NAME}/FETCH_OFFER_DATASHEETS`;
export const FETCH_OFFER_DATASHEETS_FAILURE = `${NAME}/FETCH_OFFER_DATASHEETS_FAILURE`;
export const FETCH_OFFER_DATASHEETS_SUCCESS = `${NAME}/FETCH_OFFER_DATASHEETS_SUCCESS`;

export const FETCH_OFFER_SMART_DOCUMENTS = `${NAME}/FETCH_OFFER_SMART_DOCUMENTS`;
export const FETCH_OFFER_SMART_DOCUMENTS_FAILURE = `${NAME}/FETCH_OFFER_SMART_DOCUMENTS_FAILURE`;
export const FETCH_OFFER_SMART_DOCUMENTS_SUCCESS = `${NAME}/FETCH_OFFER_SMART_DOCUMENTS_SUCCESS`;

export const INITIALIZE = `${NAME}/INITIALIZE`;
export const INITIALIZE_SUCCESS = `${NAME}/INITIALIZE_SUCCESS`;
export const INITIALIZE_FAILURE = `${NAME}/INITIALIZE_FAILURE`;
export const INITIALIZE_OFFER_SMART_DOCUMENT_FORM = `${NAME}/INITIALIZE_OFFER_SMART_DOCUMENT_FORM`;

export const UPDATE_TEMPLATE = `${NAME}/UPDATE_TEMPLATE`;
export const UPDATE_TEMPLATE_FAILURE = `${NAME}/UPDATE_TEMPLATE_FAILURE`;
export const UPDATE_TEMPLATE_SUCCESS = `${NAME}/UPDATE_TEMPLATE_SUCCESS`;

export const RENAME_OFFER_SMART_DOCUMENT = `${NAME}/RENAME_OFFER_SMART_DOCUMENT`;
export const RENAME_OFFER_SMART_DOCUMENT_FAILURE = `${NAME}/RENAME_OFFER_SMART_DOCUMENT_FAILURE`;
export const RENAME_OFFER_SMART_DOCUMENT_SUCCESS = `${NAME}/RENAME_OFFER_SMART_DOCUMENT_SUCCESS`;

export const RESET = `${NAME}/RESET`;
export const RESET_OFFER_SMART_DOCUMENT_FORM = `${NAME}/RESET_OFFER_SMART_DOCUMENT_FORM`;

export const REVOKE_APPROVAL = `${NAME}/REVOKE_APPROVAL`;
export const REVOKE_APPROVAL_FAILURE = `${NAME}/REVOKE_APPROVAL_FAILURE`;
export const REVOKE_APPROVAL_SUCCESS = `${NAME}/REVOKE_APPROVAL_SUCCESS`;

export const SAVE_OFFER_SMART_DOCUMENT = `${NAME}/SAVE_OFFER_SMART_DOCUMENT`;
export const SAVE_OFFER_SMART_DOCUMENT_FAILURE = `${NAME}/SAVE_OFFER_SMART_DOCUMENT_FAILURE`;
export const SAVE_OFFER_SMART_DOCUMENT_SUCCESS = `${NAME}/SAVE_OFFER_SMART_DOCUMENT_SUCCESS`;

export const SET_IS_OPEN_OFFER_SMART_DOCUMENT_MODAL = `${NAME}/SET_IS_OPEN_OFFER_SMART_DOCUMENT_MODAL`;
export const SET_OFFER_SMART_DOCUMENT_FORM_MODE = `${NAME}/SET_OFFER_SMART_DOCUMENT_FORM_MODE`;
export const SET_SELECTED_DOCUMENT = `${NAME}/SET_SELECTED_DOCUMENT`;
