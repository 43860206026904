import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Box, Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TextVariant, TitleIcon } from 'common/components';
import ResumeData from 'common/components/ResumeData';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { RESET_PASSWORD_IN_PROFILE } from 'common/modules/managePassword/constants';
import ManagePassword from 'common/modules/managePassword/Container';

import ProfileConfiguration from '../profileConfiguration';
import ProfileInvoice from '../profileInvoice';
import ProfileConfigurationPermission from '../profileSecurityPermissions';

import DataForm from './components/DataForm';

const Container = ({ canView, history, location, match }) => {
    const { t } = useTranslation();

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon title={t('My profile')} />
                </Grid>
            </HeaderContainer>

            <ResumeData
                isOpen={match?.params?.section === 'general'}
                title={t('General information')}
            >
                <DataForm />
            </ResumeData>

            <ResumeData
                isOpen={match?.params?.section === 'security'}
                title={t('Security')}
            >
                <TextVariant
                    fontSize="14px"
                    separatorLeft
                    separatorLeftColor="#202253"
                    separatorLeftWidth="2px"
                    text={t('Password')}
                    variant="bold"
                />

                <Box sx={{ mt: 1 }}>
                    <ManagePassword origin={RESET_PASSWORD_IN_PROFILE} />
                </Box>

                <TextVariant
                    fontSize="14px"
                    separatorLeft
                    separatorLeftColor="#202253"
                    separatorLeftWidth="2px"
                    text={t('Support team permissions')}
                    variant="bold"
                />

                <ProfileConfigurationPermission.Container />
            </ResumeData>

            {canView && (
                <ProfileInvoice.Container
                    history={history}
                    location={location}
                />
            )}

            <ResumeData
                isOpen={match?.params?.section === 'settings'}
                title={t('Setting')}
            >
                <ProfileConfiguration.Container />
            </ResumeData>
        </MaterialContainer>
    );
};

Container.propTypes = {
    canView: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
};

export default compose(
    withPermissions(PERMISSION_LIST.SUBSCRIPTION_PERMISSION),
    withRouter,
)(Container);
