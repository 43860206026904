import { getTemplates } from 'common/api/v1/templates';

import {
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

export default ({
        branchOfficeId,
        orderBy,
        page = 0,
        pageSize,
        searchText,
        sortBy,
        status,
        type,
        is_global,
    } = {}) =>
    (dispatch) => {
        dispatch(templateLibraryActions[FETCH_TEMPLATES]());

        getTemplates({
            branch_office_id: branchOfficeId,
            is_global,
            language: '',
            orderBy,
            page: page + 1,
            perPage: pageSize,
            searchText,
            sortBy,
            status,
            type,
        })
            .then((response) =>
                dispatch(
                    templateLibraryActions[FETCH_TEMPLATES_SUCCESS]({
                        data: response?.data?.results,
                        pagination: {
                            current_page: response?.data?.page,
                            per_page: response?.data?.page_size,
                            total_rows: response?.data?.total_documents,
                        },
                    }),
                ),
            )
            .catch((error) =>
                dispatch(
                    templateLibraryActions[FETCH_TEMPLATES_FAILURE](error),
                ),
            );
    };
