import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PropTypes from 'prop-types';

const TrackingTypeIcon = ({ type }) => {
    switch (type) {
        case 1:
            return <EmailIcon sx={{ color: 'primary.main' }} />;
        case 2:
            return <BusinessIcon sx={{ color: '#785f9d' }} />;
        case 3:
            return <WhatsAppIcon sx={{ color: '#02e675' }} />;
        case 4:
            return <LibraryBooksIcon sx={{ color: '#74ebff' }} />;

        default:
            return <PhoneIcon sx={{ color: '#008dff' }} />;
    }
};

TrackingTypeIcon.propTypes = {
    type: PropTypes.number,
};

export default TrackingTypeIcon;
