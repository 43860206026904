import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import * as proposalGeneratorQuoteSelectors from '../../proposalGeneratorQuote/selectors';

import deleteItem from './delete';
import initialValues from './initialValues';

export default (id, proposalId) => (dispatch, getState) => {
    const state = getState();
    const offerAdditionals =
        proposalGeneratorQuoteSelectors.getOfferAdditionalsData(state);

    const dataAlert = {
        cancelText: i18next.t('Cancel'),
        confirmText: i18next.t('Accept'),
        title: i18next.t('Warning'),
        messages: [
            i18next.t(
                'At least one unit per product is required, if you do not want the product you can remove it',
            ),
        ],
        onCancel: () => dispatch(initialValues(offerAdditionals)),
        onSuccess: () => dispatch(deleteItem(id, proposalId)),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
