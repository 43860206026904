import { NAME } from './constants';

export const DELETE_TAXES = `${NAME}/DELETE_TAXES`;
export const DELETE_TAXES_FAILURE = `${NAME}/DELETE_TAXES_FAILURE`;
export const DELETE_TAXES_SUCCESS = `${NAME}/DELETE_TAXES_SUCCESS`;

export const FETCH = `${NAME}/FETCH`;
export const FETCH_FAILURE = `${NAME}/FETCH_FAILURE`;
export const FETCH_SUCCESS = `${NAME}/FETCH_SUCCESS`;

export const FETCH_GEOCODE = `${NAME}/FETCH_GEOCODE`;
export const FETCH_GEOCODE_ERROR = `${NAME}/FETCH_GEOCODE_ERROR`;
export const FETCH_GEOCODE_SUCCESS = `${NAME}/FETCH_GEOCODE_SUCCESS`;

export const FETCH_GEOCODE_POINT = `${NAME}/FETCH_GEOCODE_POINT`;
export const FETCH_GEOCODE_POINT_FAILURE = `${NAME}/FETCH_GEOCODE_POINT_FAILURE`;
export const FETCH_GEOCODE_POINT_SUCCESS = `${NAME}/FETCH_GEOCODE_POINT_SUCCESS`;

export const FETCH_TAXES = `${NAME}/FETCH_TAXES`;
export const FETCH_TAXES_FAILURE = `${NAME}/FETCH_TAXES_FAILURE`;
export const FETCH_TAXES_SUCCESS = `${NAME}/FETCH_TAXES_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_TAXES = `${NAME}/RESET_TAXES`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SAVE_TAXES = `${NAME}/SAVE_TAXES`;
export const SAVE_TAXES_FAILURE = `${NAME}/SAVE_TAXES_FAILURE`;
export const SAVE_TAXES_SUCCESS = `${NAME}/SAVE_TAXES_SUCCESS`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;

export const SET_SHOW_GEOCODE_RESULTS = `${NAME}/SET_SHOW_GEOCODE_RESULTS`;
