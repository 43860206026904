import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ChartJs from 'common/components/ChartJs';
import { numberFormat } from 'common/utils/helpers';
import {
    handleProposalEnergyChartConfig,
    hexToRgba,
    getGradient,
} from 'common/utils/helpersChart';

import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';

const DailyChart = ({
    consumptionProjection,
    currencyLocale,
    solarSimulationData,
}) => {
    const [chartConfig, setChartConfig] = useState({
        options: {},
        series: [{ data: [], name: '' }],
    });

    const {
        consumptionHistory = [],
        firstDay,
        realIndex = {},
        zeroExport,
    } = consumptionProjection || {};

    useEffect(() => {
        if (!isEmpty(solarSimulationData) && !isEmpty(consumptionHistory))
            handleChartChange({
                days: [firstDay],
                option: 3,
                period: 0,
                type: 'area',
                weeks: [0],
                year: 0,
            });
    }, [firstDay, consumptionHistory, solarSimulationData]);

    const handleChartChange = (configOptions) => {
        const { options, series } = handleProposalEnergyChartConfig({
            configOptions,
            consumptionHistory,
            countryCurrencyLocale: currencyLocale,
            isSelfConsumption: true,
            realIndex,
            solarSimulationData,
            zeroExport,
        });

        setChartConfig(() => ({
            options: {
                ...options,
                tooltip: { ...options.tooltip, followCursor: true },
            },
            series,
        }));
    };

    let extraOptions = {};

    const data = {
        datasets: chartConfig.series.map(
            ({ color, data, name, type }, index) => {
                let _type = type;
                const extraConfig = { fill: true };

                if (type === 'column') _type = 'bar';
                if (type === 'area') _type = 'line';
                let backgroundColor = hexToRgba(color, 0.8);
                if (type === 'area')
                    backgroundColor = function (ctx) {
                        return getGradient({ color, context: ctx });
                    };

                if (name === 'SOC (%)') {
                    extraConfig.fill = false;
                    extraConfig.borderDash = [15, 5];
                    extraConfig.yAxisID = 'y1';

                    if (chartConfig?.options?.yaxis?.[index]) {
                        extraOptions = {
                            ...extraOptions,
                            scales: {
                                ...extraOptions.scales,
                                y1: {
                                    border: { color },
                                    grid: { drawOnChartArea: false },
                                    max: chartConfig?.options?.yaxis[index].max,
                                    min: Math.ceil(
                                        chartConfig?.options?.yaxis[index].min,
                                    ),
                                },
                            },
                        };
                    }
                } else {
                    if (chartConfig?.options?.yaxis?.[index]) {
                        extraOptions = {
                            ...extraOptions,
                            scales: {
                                ...extraOptions.scales,
                                y: {
                                    max: chartConfig?.options?.yaxis[index].max,
                                    min: chartConfig?.options?.yaxis[index].min,
                                },
                            },
                        };
                    }
                }

                return {
                    ...extraConfig,
                    backgroundColor,
                    borderColor: color,
                    data,
                    label: name,
                    type: _type,
                };
            },
        ),
        labels: chartConfig.options.xaxis?.categories || [],
    };

    let options = {
        interaction: { axis: 'x', intersect: false, mode: 'nearest' },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return (
                            context.dataset.label +
                            ': ' +
                            numberFormat(context.parsed.y, {
                                decimals: 3,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: 'kWh',
                            })
                        );
                    },
                },
                mode: 'index',
                position: 'nearest',
            },
        },
        scales: {
            x: { grid: { display: false } },
            y: {
                ...extraOptions.scales?.y,
                ticks: {
                    callback: (val) =>
                        numberFormat(val, {
                            decimals: 3,
                            locale: currencyLocale,
                            style: 'decimal',
                        }),
                },
                title: { display: true, text: 'kW' },
            },
            y1: {
                ...extraOptions.scales?.y1,
                grid: { drawOnChartArea: false },
                position: 'right',
                ticks: { callback: (val) => `${val}%` },
            },
        },
    };

    return <ChartJs data={data} height="330px" options={options} type="line" />;
};

DailyChart.propTypes = {
    consumptionProjection: PropTypes.object,
    currencyLocale: PropTypes.string,
    solarSimulationData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    consumptionProjection:
        proposalGeneratorOneColumnSelectors.getConsumptionProjectionData,
    solarSimulationData:
        proposalGeneratorOneColumnSelectors.getSolarSimulationData,
});

export default connect(mapStateToProps)(DailyChart);
