import i18next from 'i18next';
import { get, isEmpty, isNumber } from 'lodash';

import {
    MAX_USERS_PRO_PLAN,
    MAX_USERS_PRO_SCALE,
    PLAN_TYPES,
} from 'common/constants';
import { calculateFinalCost, dictionaryToArray } from 'common/utils/helpers';

import {
    BATTERIES_STEP,
    DETAILS_STEP,
    INVERTERS_STEP,
    MEMBERS_STEP,
    ONBOARDING_STEPS,
    PANELS_STEP,
} from './constants';

export const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
};

const getActiveProducts = (products) => {
    if (isEmpty(products)) return 0;
    return products.reduce(
        (acc, { selected = false }) => (selected ? acc + 1 : acc),
        0,
    );
};

export const getIsAllChecked = (products) => {
    if (isEmpty(products)) return false;
    return Boolean(
        products.reduce((acc, { selected = false }) => selected * acc, true),
    );
};

export const getIsDisabledAddMemberButton = (
    counters,
    members,
    subscriptionType,
) => {
    switch (subscriptionType) {
        case PLAN_TYPES.PRO:
            return (
                members.length >=
                get(counters, 'maximum_users', MAX_USERS_PRO_PLAN)
            );
        case PLAN_TYPES.SCALE:
            return (
                members.length >=
                get(counters, 'maximum_users', MAX_USERS_PRO_SCALE)
            );
        default:
            return false;
    }
};

export const getIsLockedInverterStep = (inverterBrand, inverters, step) => {
    return (
        step === INVERTERS_STEP &&
        inverterBrand &&
        getActiveProducts(inverters) === 0
    );
};

export const getIsLockedPanelStep = (panelBrand, panels, step) => {
    return (
        step === PANELS_STEP && panelBrand && getActiveProducts(panels) === 0
    );
};

export const getIsShowSkipButton = (step) =>
    [DETAILS_STEP, MEMBERS_STEP, BATTERIES_STEP].includes(step);

export const getMemberRolesToSelect = (roles) => {
    const items = roles.map((item) => ({
        label: item.name,
        value: item.id,
    }));

    return [
        {
            disabled: true,
            label: i18next.t('Select role'),
            value: '',
        },
        ...items,
    ];
};

export const getMembers = (users) => {
    return users
        .filter((_, index) => index > 0)
        .map(({ company_group, email, first_name, last_name }) => ({
            company_group,
            email,
            first_name,
            last_name,
        }));
};

export const getProductBrandsToSelect = (brands) => {
    if (!brands) return [];
    const items = brands.map((item) => ({
        label: item.name,
        value: item.name,
    }));
    return items;
};

export const getStepLabel = (step) => {
    switch (step) {
        case ONBOARDING_STEPS.COMPANY:
            return i18next.t('Business');
        case ONBOARDING_STEPS.DETAILS:
            return i18next.t('Details');
        case ONBOARDING_STEPS.MEMBERS:
            return i18next.t('Member', { count: 2 });
        case ONBOARDING_STEPS.PANELS:
            return i18next.t('Panel', { count: 2 });
        case ONBOARDING_STEPS.INVERTERS:
            return i18next.t('Inverter', { count: 2 });
        case ONBOARDING_STEPS.BATTERIES:
            return i18next.t('Battery', { count: 2 });
        case ONBOARDING_STEPS.FINISH:
            return i18next.t('Finish');
        default:
            return i18next.t('Sign up');
    }
};

export const getTextButton = ({ step, isSaving }) => {
    const arraySteps = dictionaryToArray(ONBOARDING_STEPS);
    if (isSaving) return i18next.t('Saving').concat('...');
    switch (arraySteps[step]) {
        case ONBOARDING_STEPS.REGISTER:
            return i18next.t("I'm ready!");
        case ONBOARDING_STEPS.DETAILS:
            return i18next.t('Save');
        case ONBOARDING_STEPS.FINISH:
            return i18next.t('Finish');
        default:
            return i18next.t('Continue');
    }
};

export const getSelectedProducts = (products) => {
    return products
        .filter((item) => item.selected)
        .map(({ cost, final_cost, id, margin, name, price }) => ({
            cost,
            final_cost,
            id,
            margin,
            name,
            price,
        }));
};

export const getStepIndex = (step) => {
    const arraySteps = dictionaryToArray(ONBOARDING_STEPS);
    return arraySteps.findIndex((item) => item === step);
};

export const getStepKey = (step) => {
    const arraySteps = Object.values(ONBOARDING_STEPS);
    return arraySteps[step];
};

export const handleChangeCheckingAll = (e, fields, name, setValue) => {
    if (e.target.checked) {
        fields.forEach((i, index) => {
            setValue(`${name}.${index}.selected`, true);
        });
    } else {
        fields.forEach((i, index) => {
            setValue(`${name}.${index}.selected`, false);
        });
    }
};

export const handleChangeCostValue = (getValues, i, name, setValue) => {
    const value = getValues(`${name}.${i}.cost`);
    if (!value) setValue(`${name}.${i}.cost`, 0);

    setValue(
        `${name}.${i}.final_cost`,
        calculateFinalCost(
            !isNumber(parseInt(value)) ? 0 : value,
            getValues(`${name}.${i}.margin`),
        ),
    );
};

export const handleChangeMarginValue = (getValues, i, name, setValue) => {
    let value = getValues(`${name}.${i}.margin`);

    if (!value) setValue(`${name}.${i}.margin`, 0);
    else if (parseFloat(value) >= 100) {
        setValue(`${name}.${i}.margin`, 99.99);
        value = 99.99;
    }

    setValue(
        `${name}.${i}.final_cost`,
        calculateFinalCost(
            getValues(`${name}.${i}.cost`),
            !isNumber(parseInt(value)) ? 0 : value,
        ),
    );
};

export const handleExchangeRateBuild = (item, setValue) => {
    const isoCode = get(item, 'abbreviation', null);
    setValue('currency_obj', {
        abbreviation: get(item, 'abbreviation', null),
        symbol: get(item, 'symbol', null),
    });
    setValue('exchangeRateId', item?.exchange_rate_id);
    if (isoCode !== 'USD') {
        setValue('dollar_price', item?.dollar_price);
        setValue('exchange_type', item?.exchange_type);
        setValue('inverse_dollar_price', item?.inverse_dollar_price);
        setValue('source', item?.source);
    }
};
