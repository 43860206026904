import React, { useCallback } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

const WrapperField = styled(Box)`
    cursor: pointer;
    font-size: 12px;
    letter-spacing: -0.29px;
    line-height: 19px;
    margin-bottom: 8px;
    outline: none;
    padding: 8px;

    ${({ variant }) =>
        variant === 'firstLoad' &&
        `
        background: #002438;
        border-radius: 3px;
        border: 3px dashed #a3a6b4;
        color: #ffffff;
        margin-bottom: 16px;
        padding: 24px;
`}
`;

const FileUpload = ({
    accept,
    control,
    disabled,
    name,
    onChange,
    setValue,
}) => {
    const { t } = useTranslation();

    const onDrop = useCallback((acceptedFiles) => {
        setValue(name, acceptedFiles[0]);
        onChange(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value } }) => (
                <WrapperField
                    {...getRootProps()}
                    variant={!value ? 'firstLoad' : 'reload'}
                >
                    <input
                        {...getInputProps()}
                        accept={accept}
                        disabled={disabled}
                    />

                    {!value ? (
                        <Box
                            alignItems="center"
                            display="flex"
                            gap={2}
                            justifyContent="center"
                        >
                            <AttachFileIcon />
                            {t('Upload CSV')}
                        </Box>
                    ) : (
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <CheckCircleIcon />
                            <Button>{t('Change CSV')}</Button>
                        </Box>
                    )}
                </WrapperField>
            )}
        />
    );
};

FileUpload.propTypes = {
    accept: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    setValue: PropTypes.func,
};

export default FileUpload;
