import React from 'react';

import PropTypes from 'prop-types';
import { Box, Skeleton, Typography } from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';

const TotalWrapper = ({
    currencyIso,
    currencyLocale,
    isFetching,
    numericalSign,
    title,
    value,
}) => {
    if (isFetching)
        return (
            <Box sx={{ display: 'flex', ml: 'auto', width: '80%' }}>
                <Skeleton
                    variant="text"
                    sx={{
                        fontSize: '1.2rem',
                        width: '100%',
                    }}
                />
            </Box>
        );

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
                ml: 'auto',
                py: 1,
                width: '80%',
            }}
        >
            <Box flexGrow={1}>
                <Typography fontWeight="bold" variant="body2">
                    {title && <span>{title}</span>}
                </Typography>
            </Box>
            <Typography variant="body2">
                {numericalSign && <span>{numericalSign}</span>}{' '}
                {numberFormat(value, {
                    currency: currencyIso,
                    decimals: 2,
                    locale: currencyLocale,
                    style: 'currency',
                })}
            </Typography>
        </Box>
    );
};

TotalWrapper.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    isFetching: PropTypes.bool,
    numericalSign: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.number,
};

export default TotalWrapper;
