import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';

import { save } from 'common/api/v2/masterButton';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { buildPayload as buildContactPayload } from '../../../../modules/contactForm/helpers';
import { TYPE_PROPOSAL } from '../../../../modules/projectCommercialOfferTable/constants';
import { buildPayload as buildZeroConsumptionPayload } from '../../../../modules/projectCommercialOfferTable/helpers';
import * as projectConsumptionsModalActions from '../../../../modules/projectConsumptionsModal/actions';
import { buildPayload as buildCunsumptionsPayload } from '../../../../modules/projectConsumptionsModal/helpers';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { actions } from '../reducer';

export default (values, callback, redirect = true) =>
    (dispatch, getState) => {
        const state = getState();
        const branchSelected =
            multiBranchesSelectors.getBranchSelected(getState());

        const { contact, consumptions, project, proposal } = values;

        const contactNewValues = contact?.id
            ? contact
            : {
                  ...buildContactPayload(contact),
                  emails: contact.emails,
                  telephones: contact.telephones,
              };

        let projectNewValues = project?.id
            ? { id: project.id }
            : {
                  name: project.name,
                  type:
                      project.type === 'import_csv'
                          ? 'add_consumption'
                          : project.type,
              };

        let consumption = null;
        let proposalNewValues = null;

        if (consumptions === null) {
            consumption = buildZeroConsumptionPayload({
                selectedProject: project,
                values: proposal,
            });
            proposalNewValues = proposal;
            projectNewValues = {
                ...projectNewValues,
                type: 'without_consumption',
            };
        } else {
            if (!isEmpty(consumptions.summary)) {
                consumption = buildCunsumptionsPayload({
                    selectedProject: project,
                    state,
                    values: consumptions,
                });
                projectNewValues.type = 'add_consumption';
            }

            proposalNewValues = {
                branch_office:
                    proposal.branch_office === 'company'
                        ? branchSelected
                        : proposal.branch_office,
                name: proposal.name,
                plan: proposal.proposal_plan,
                project: project?.id ? project?.id : null,
                type: TYPE_PROPOSAL,
            };
        }

        const newValues = {
            action: 'create-proposal',
            consumption,
            contact: contactNewValues,
            project: projectNewValues,
            proposal: proposalNewValues,
        };

        dispatch(actions[SAVE]());

        save(newValues)
            .then((response) => {
                dispatch(actions[SAVE_SUCCESS](response.data.data));
                dispatch(
                    projectConsumptionsModalActions.selectProject(
                        response.data,
                    ),
                );

                if (callback) callback();

                if (redirect) {
                    showToast();
                    dispatch(projectConsumptionsModalActions.resetForm());

                    const { id } = response.data.data;

                    dispatch(push(`/proposal/${id}`));

                    return;
                }
            })
            .catch((error) => {
                dispatch(actions[SAVE_FAILURE](error?.response?.data?.errors));
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
