import { styled } from '@mui/material/styles';
import { AccordionSummary } from 'sunwise-ui';

export default styled(AccordionSummary)`
    min-height: ${({ height = '45px' }) => height};
    &.Mui-expanded {
        min-height: ${({ height = '45px' }) => height};
    }
    & .MuiAccordionSummary-content.Mui-expanded {
        margin: 0;
    }
`;
