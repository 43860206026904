import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Tabs, TabPanel } from 'sunwise-ui';

import { ANNUAL_TYPE } from '../../constants';
import { getFieldsHasErrors } from '../../helpers';
import TabBadge from '../TabBadge';

import MainDataTab from './MainDataTab';
import PolicyDataTab from './PolicyDataTab';
import SettingsDataTab from './SettingsDataTab';

const RateFormFields = ({
    control,
    getValues,
    isNewRate,
    setValue,
    showMode,
    tabKey = '',
    trigger,
}) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(`${tabKey}-main_data`);
    const { errors } = useFormState({ control });
    const typeInputCharges = useWatch({
        control,
        name: 'main_data.type_of_input_charges',
    });

    if (!typeInputCharges) return null;
    const isAnnualType = typeInputCharges === ANNUAL_TYPE;

    const handleOnChangeTab = (_, newValue) => setSelectedTab(newValue);
    const changeTab = (key) => setSelectedTab(`${tabKey}-${key}`);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    onChange={handleOnChangeTab}
                    value={selectedTab}
                    variant="scrollable"
                >
                    <TabBadge
                        hasErrors={getFieldsHasErrors(errors, ['main_data'])}
                        label={t('General')}
                        value={`${tabKey}-main_data`}
                    />
                    <TabBadge
                        hasErrors={getFieldsHasErrors(errors, [
                            'settings_data',
                        ])}
                        label={t('Setting')}
                        value={`${tabKey}-settings_data`}
                    />
                    <TabBadge
                        hasErrors={getFieldsHasErrors(errors, ['policy_data'])}
                        label={t('Policy', { count: 2 })}
                        value={`${tabKey}-policy_data`}
                    />
                </Tabs>
            </Box>

            <TabPanel
                key="tab-panel-general"
                selectedTab={selectedTab}
                value={`${tabKey}-main_data`}
            >
                <MainDataTab
                    changeTab={changeTab}
                    control={control}
                    getValues={getValues}
                    isAnnualType={isAnnualType}
                    isNewRate={isNewRate}
                    setValue={setValue}
                    showMode={showMode}
                />
            </TabPanel>

            <TabPanel
                key="tab-panel-settings"
                selectedTab={selectedTab}
                value={`${tabKey}-settings_data`}
            >
                <SettingsDataTab
                    changeTab={changeTab}
                    control={control}
                    getValues={getValues}
                    isAnnualType={isAnnualType}
                    setValue={setValue}
                    showMode={showMode}
                    trigger={trigger}
                />
            </TabPanel>

            <TabPanel
                key="tab-panel-policies"
                selectedTab={selectedTab}
                value={`${tabKey}-policy_data`}
            >
                <PolicyDataTab
                    control={control}
                    showMode={showMode}
                    trigger={trigger}
                />
            </TabPanel>
        </>
    );
};

RateFormFields.propTypes = {
    control: PropTypes.object,
    getValues: PropTypes.func,
    isNewRate: PropTypes.bool,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
    tabKey: PropTypes.string,
    trigger: PropTypes.func,
};

export default RateFormFields;
