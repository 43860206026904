import React, { useEffect } from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button, Grid } from 'sunwise-ui';

import {
    ReactHookFormDragnDropFile,
    ReactHookFormInput,
    ReactHookFormInputDatePicker,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import { FILE_ALLOWED_EXTENSIONS, MAX_SMALL_FILE_SIZE } from 'common/constants';
import { fullNameBuild } from 'common/utils/helpers';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const DataForm = ({ errors, handleClickSave, initialValues, isSaving }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const formValues = watch();

    const fullName = fullNameBuild({
        firstName: formValues?.first_name,
        lastName: formValues?.last_name,
    });

    return (
        <form onSubmit={handleSubmit(handleClickSave)}>
            <Grid container>
                <Grid item xs={18} md={10} sx={{ order: { xs: 2, md: 1 } }}>
                    <ReactHookFormInput
                        control={control}
                        label={t('Name')}
                        name="first_name"
                        placeholder={t('Name', { count: 2 })}
                    />

                    <ReactHookFormInput
                        control={control}
                        label={t('Last name', { count: 2 })}
                        name="last_name"
                    />

                    <ReactHookFormInput
                        control={control}
                        label={t('Telephone')}
                        name="phone"
                    />

                    <ReactHookFormInputDatePicker
                        control={control}
                        fullWidth
                        label={t('Date of birth')}
                        maxDate={new Date()}
                        name="birth_date"
                        placeholder="MM/DD/AAAA"
                    />

                    <ReactHookFormInput
                        autoComplete="off"
                        control={control}
                        label={t('Email')}
                        name="email"
                        placeholder={t('Email')}
                        readOnly
                        type="email"
                    />
                </Grid>
                <Grid item xs={18} md={8} sx={{ order: { xs: 1, md: 2 } }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={18} md={8}>
                            <ReactHookFormDragnDropFile
                                accept={FILE_ALLOWED_EXTENSIONS.IMAGE}
                                avatarData={{
                                    image: formValues?.profile_image,
                                    name: fullName,
                                }}
                                control={control}
                                formats="jpg, jpeg, png"
                                id="profile_image"
                                maxSize={MAX_SMALL_FILE_SIZE}
                                name="profile_image"
                                setValue={setValue}
                                variant="profile"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <ShowErrors errors={errors} />

            <BottomActions>
                <Box
                    sx={{
                        display: 'flex',
                        pt: 2,
                        width: '100%',
                        gap: '16px',
                        alignItems: 'center',
                    }}
                >
                    <small>
                        <a
                            href="https://www.sunwise.io/terms"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {t('Terms and conditions')}
                        </a>
                    </small>

                    {!isEqual(initialValues, formValues) && (
                        <Button
                            type="submit"
                            disabled={isSaving}
                            sx={{ ml: 'auto' }}
                        >
                            {t('Save')}
                        </Button>
                    )}
                </Box>
            </BottomActions>
        </form>
    );
};

DataForm.propTypes = {
    errors: PropTypes.array,
    handleClickSave: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickSave: (values) => dispatch(actions.save(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataForm);
