import { createSlice } from '@reduxjs/toolkit';

import {
    ARCHIVE_SUMMARY,
    ARCHIVE_SUMMARY_FAILURE,
    ARCHIVE_SUMMARY_SUCCESS,
    DELETE_SUMMARY,
    DELETE_SUMMARY_FAILURE,
    DELETE_SUMMARY_SUCCESS,
    DOWNLOAD_CSV,
    DOWNLOAD_CSV_FAILURE,
    DUPLICATE_SUMMARY,
    DUPLICATE_SUMMARY_FAILURE,
    DUPLICATE_SUMMARY_SUCCESS,
    FETCH_COMPANIES_CATALOGS,
    FETCH_COMPANIES_CATALOGS_FAILURE,
    FETCH_COMPANIES_CATALOGS_SUCCESS,
    FETCH_OFFER_TEMPLATES,
    FETCH_OFFER_TEMPLATES_FAILURE,
    FETCH_OFFER_TEMPLATES_SUCCESS,
    FETCH_SUMMARIES,
    FETCH_SUMMARIES_FAILURE,
    FETCH_SUMMARIES_SUCCESS,
    INITIALIZE_VALUES,
    OPEN_MODAL,
    RENAME_SUMMARY,
    RENAME_SUMMARY_FAILURE,
    RENAME_SUMMARY_SUCCESS,
    RESET_FORM,
    RESET_SAVE,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_BRANCH_OFFICE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    archiveSummary: {
        isLoading: false,
        data: null,
        errors: [],
    },
    branchOffice: null,
    companiesCatalogs: {
        data: [],
        errors: null,
        isFetching: false,
    },
    deleteSummary: {
        isLoading: false,
        data: null,
        errors: [],
    },
    downloadCSV: {
        isFetching: false,
        data: null,
        errors: [],
    },
    duplicateSummary: {
        isLoading: false,
        data: null,
        errors: [],
    },
    initialValues: {
        id: '',
        branch_office: '',
        custom_offer_template: '',
        linked_proposals: [],
        name: '',
        project: '',
        proposalsNumber: 0,
    },
    isOpen: false,
    offerTemplates: {
        data: [],
        errors: [],
        isFetching: false,
    },
    renameSummary: {
        isLoading: false,
        data: null,
        errors: [],
    },
    saveItem: {
        isSaving: false,
        data: {},
        errors: null,
    },
    summaries: {
        isFetching: false,
        data: null,
        errors: [],
    },
};

const proposalSummarySlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ARCHIVE_SUMMARY]: (state) => {
            state.archiveSummary = {
                ...state.archiveSummary,
                errors: [],
                isLoading: true,
            };
        },
        [ARCHIVE_SUMMARY_FAILURE]: (state, action) => {
            state.archiveSummary = {
                ...state.archiveSummary,
                errors: action.payload,
                isLoading: false,
            };
        },
        [ARCHIVE_SUMMARY_SUCCESS]: (state, action) => {
            state.archiveSummary = {
                ...state.archiveSummary,
                data: action.payload,
                isLoading: false,
            };
        },
        [DELETE_SUMMARY]: (state) => {
            state.deleteSummary = {
                ...state.deleteSummary,
                errors: [],
                isLoading: true,
            };
        },
        [DELETE_SUMMARY_FAILURE]: (state, action) => {
            state.deleteSummary = {
                ...state.deleteSummary,
                errors: action.payload,
                isLoading: false,
            };
        },
        [DELETE_SUMMARY_SUCCESS]: (state, action) => {
            state.deleteSummary = {
                ...state.deleteSummary,
                data: action.payload,
                isLoading: false,
            };
        },
        [DOWNLOAD_CSV]: (state) => {
            state.downloadCSV = {
                ...state.downloadCSV,
                errors: [],
                isFetching: true,
            };
        },
        [DOWNLOAD_CSV_FAILURE]: (state, action) => {
            state.downloadCSV = {
                ...state.downloadCSV,
                errors: action.payload,
                isFetching: false,
            };
        },
        [DUPLICATE_SUMMARY]: (state) => {
            state.duplicateSummary = {
                ...state.duplicateSummary,
                errors: [],
                isLoading: true,
            };
        },
        [DUPLICATE_SUMMARY_FAILURE]: (state, action) => {
            state.duplicateSummary = {
                ...state.duplicateSummary,
                errors: action.payload,
                isLoading: false,
            };
        },
        [DUPLICATE_SUMMARY_SUCCESS]: (state, action) => {
            state.duplicateSummary = {
                ...state.duplicateSummary,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_COMPANIES_CATALOGS]: (state) => {
            state.companiesCatalogs = {
                ...state.companiesCatalogs,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_COMPANIES_CATALOGS_FAILURE]: (state, action) => {
            state.companiesCatalogs = {
                ...state.companiesCatalogs,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMPANIES_CATALOGS_SUCCESS]: (state, action) => {
            state.companiesCatalogs = {
                ...state.companiesCatalogs,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_TEMPLATES]: (state) => {
            state.offerTemplates = {
                ...state.offerTemplates,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_TEMPLATES_FAILURE]: (state, action) => {
            state.offerTemplates = {
                ...state.offerTemplates,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_TEMPLATES_SUCCESS]: (state, action) => {
            state.offerTemplates = {
                ...state.offerTemplates,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUMMARIES]: (state) => {
            state.summaries = {
                ...state.summaries,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_SUMMARIES_FAILURE]: (state, action) => {
            state.summaries = {
                ...state.summaries,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUMMARIES_SUCCESS]: (state, action) => {
            state.summaries = {
                ...state.summaries,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [OPEN_MODAL]: (state, action) => {
            state.isOpen = action.payload;
        },
        [RENAME_SUMMARY]: (state) => {
            state.renameSummary = {
                ...state.renameSummary,
                errors: [],
                isLoading: true,
            };
        },
        [RENAME_SUMMARY_FAILURE]: (state, action) => {
            state.renameSummary = {
                ...state.renameSummary,
                errors: action.payload,
                isLoading: false,
            };
        },
        [RENAME_SUMMARY_SUCCESS]: (state, action) => {
            state.renameSummary = {
                ...state.renameSummary,
                data: action.payload,
                isLoading: false,
            };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [RESET_SAVE]: (state) => {
            state.saveItem = INITIAL_STATE.saveItem;
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_BRANCH_OFFICE]: (state, action) => {
            state.branchOffice = action.payload;
        },
    },
});

export const proposalSummaryActions = proposalSummarySlice.actions;

export default proposalSummarySlice.reducer;
