import { getFinarciers } from 'common/api/v1/sunpay';

import {
    FETCH_FINANCIERS,
    FETCH_FINANCIERS_FAILURE,
    FETCH_FINANCIERS_SUCCESS,
} from '../actionTypes';
import { alliancesActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(alliancesActions[FETCH_FINANCIERS]());

    getFinarciers()
        .then((response) => {
            dispatch(alliancesActions[FETCH_FINANCIERS_SUCCESS](response.data));
        })
        .catch((error) =>
            dispatch(alliancesActions[FETCH_FINANCIERS_FAILURE](error)),
        );
};
