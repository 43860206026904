import { getItems } from 'common/api/v2/offerProducts';

import {
    FETCH_OFFER_PRODUCTS,
    FETCH_OFFER_PRODUCTS_FAILURE,
    FETCH_OFFER_PRODUCTS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorQuoteActions } from '../reducer';

import resetProducts from './resetProducts';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(proposalGeneratorQuoteActions[FETCH_OFFER_PRODUCTS]());

        getItems(id)
            .then((response) => {
                dispatch(resetProducts());
                dispatch(
                    proposalGeneratorQuoteActions[FETCH_OFFER_PRODUCTS_SUCCESS](
                        response.data.data,
                    ),
                );
            })
            .catch((error) =>
                dispatch(
                    proposalGeneratorQuoteActions[FETCH_OFFER_PRODUCTS_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            )
            .finally(resolve);
    });
