import { createItem, updateItem } from 'common/api/v1/integrations';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { webIntegrationsActions } from '../reducer';

import fetchIntegrations from './fetchIntegrations';

export default (values, onSuccessCallback) => (dispatch) => {
    const newValues = {
        ...values,
    };

    dispatch(webIntegrationsActions[SAVE]());

    const save = values.id
        ? () => updateItem(newValues, values.id)
        : () => createItem(newValues);

    save()
        .then((response) => {
            dispatch(webIntegrationsActions[SAVE_SUCCESS](response.data));

            if (onSuccessCallback) onSuccessCallback();

            dispatch(fetchIntegrations());
        })
        .catch((error) => {
            dispatch(
                webIntegrationsActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
