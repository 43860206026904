import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'products';

export const bulkUpdate = (data) => API.put(`/api/v1/products/bulk/`, data);

export const bulkActivateOrDelete = (data) =>
    API.put(`/api/v1/products/status-bulk/`, data, config);

export const bulkMargin = (data) =>
    API.put(`/api/v1/${ENTITY}/margin-bulk/`, data, config);

export const bulkProducts = (data) =>
    API.put(`/api/v1/${ENTITY}/bulk-status-flags/`, data, config);
export const filterAccessories = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/accesories/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
        },
    });
export const filterAdditionals = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/additionals/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
        },
    });
export const filterBatteries = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/batteries/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
        },
    });
export const filterInverters = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/inverters/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
        },
    });
export const filterPanels = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/panels/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
        },
    });
export const filterStructures = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/structures/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
        },
    });
export const filterWorkforces = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/workforces/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
        },
    });
