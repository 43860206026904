import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import { useDebounce } from 'common/hooks';

import { DEBOUNCE_TIME } from '../constants';

const Debouncer = ({ control, setFormValues }) => {
    const formValues = useWatch({ control });
    const debouncedFormValues = useDebounce(formValues, DEBOUNCE_TIME);

    useEffect(() => setFormValues(debouncedFormValues), [debouncedFormValues]);

    return null;
};

Debouncer.propTypes = {
    control: PropTypes.object,
    setFormValues: PropTypes.func,
};

export default Debouncer;
