import fetchScheduleRateConfiguration from '../../afterSalesSettings/actions/fetchScheduleRateConfiguration';
import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import { getFieldsObjectFromArray } from '../helpers';

import initializeForm from './initializeForm';
import setModalFormOpenStatus from './setModalFormOpenStatus';

export default () => (dispatch, getState) => {
    const state = getState();

    const afterSalesSettings =
        afterSalesSettingsSelectors.getFetchDataData(state);

    const newValues = {
        contracted_demand:
            afterSalesSettings?.contracted_demand?.[0]?.value || 0,
        exported_energy_price: afterSalesSettings?.exported_energy_price || 0,
        is_bimonthly: afterSalesSettings?.is_bimonthly ? '1' : '0',
        netted_exported_generation_price:
            afterSalesSettings?.netted_exported_generation_price || 0,
        periodicity_type: afterSalesSettings?.periodicity_type,
        ppa_active: afterSalesSettings?.ppa_active || false,
        rate: afterSalesSettings?.rate?.id,
        rate_division_summer: afterSalesSettings?.rate_division_summer?.id,
        subsidy_rate: afterSalesSettings?.subsidy_rate?.name,
    };

    const promises = [];

    if (newValues?.rate)
        promises.push(
            dispatch(
                fetchScheduleRateConfiguration({
                    certified: afterSalesSettings?.rate?.certified,
                    rate: newValues?.rate,
                    rate_division: afterSalesSettings?.rate_division?.id,
                }),
            ),
        );

    Promise.all(promises).then((scheduleRateConfiguration) => {
        newValues.hourly_contracted_demand = getFieldsObjectFromArray(
            afterSalesSettings?.contracted_demand,
            scheduleRateConfiguration?.[0]?.tiers_demand_distribution?.[0]
                ?.tiers,
        );

        dispatch(initializeForm(newValues));
        dispatch(setModalFormOpenStatus(true));
    });
};
