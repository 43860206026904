export const POLYGON_SEGMENT = 0;
export const POLYGON_OBSTACLE = 1;
export const POLYGON_MAP = 2;

export const SEGMENT_COLOR = '#FF9A0080';
export const SEGMENT_STROKE_COLOR = '#ff9a00';

export const SEGMENT_ACTIVE_COLOR = '#FF9A00B3';
export const SEGMENT_STROKE_ACTIVE_COLOR = '#002438';

export const SOLAR_MODULE_COLOR = '#185577';
export const SOLAR_MODULE_STROKE_COLOR = '#002438';

export const SEGMENT_COLORS = [
    {
        color: SEGMENT_COLOR,
        stroke_color: SEGMENT_STROKE_COLOR,
    },
    {
        color: '#42a5f5',
        stroke_color: '#2196f3',
    },
    {
        color: '#26c6da',
        stroke_color: '#00bcd4',
    },
    {
        color: '#26a69a',
        stroke_color: '#009688',
    },
];

export const polygonOptions = {
    clickable: false,
    draggable: false,
    editable: true,
    fillColor: SEGMENT_COLOR,
    fillOpacity: 1,
    strokeColor: SEGMENT_STROKE_COLOR,
    strokeOpacity: 1,
    zIndex: 2,
};

export const solarCharacterization = {
    azimuth: 180,
    col_spacing: 0.02,
    frame_col_spacing: 0.02,
    frame_cols_number: 1,
    frame_horizontal_number: 1,
    frame_row_spacing: 1,
    frame_rows_number: 1,
    frame_vertical_number: 1,
    orientation: 'P',
    panel_company_branch_office: null,
    row_spacing: 0.02,
    safe_zone: 0,
    tilt: 21,
};

export const DELTA = 0.005;
