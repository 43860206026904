import fetchStorageOffer from '../../energyBackupSetup/actions/fetchStorageOffer';
import {
    fetchOfferConsumption,
    fetchOfferDetails,
    fetchProfiles,
} from '../../proposalGeneratorOneColumn/actions';
import fetchSegments from '../../proposalGeneratorPanelsLayout/actions/fetchSegments';
import {
    fetchOfferProducts,
    updateProductsTotals,
} from '../../proposalGeneratorQuote/actions';
import { SET_IS_FETCHING_DRAFT } from '../actionTypes';
import { proposalReviewProActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(proposalReviewProActions[SET_IS_FETCHING_DRAFT](true));
    Promise.all([
        dispatch(fetchOfferConsumption(id)),
        dispatch(fetchOfferDetails(id)),
        dispatch(fetchOfferProducts(id)),
        dispatch(fetchProfiles(id)),
        dispatch(fetchSegments(id)),
        dispatch(fetchStorageOffer(id)),
    ])
        .then(() => {
            dispatch(updateProductsTotals());
            dispatch(proposalReviewProActions[SET_IS_FETCHING_DRAFT](false));
        })
        .catch(() =>
            dispatch(proposalReviewProActions[SET_IS_FETCHING_DRAFT](false)),
        );
};
