import { getUsersList } from 'common/api/v1/users';

import {
    FETCH_USERS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
} from '../actionTypes';
import { trackingFormActions } from '../reducer';

export default (contactId) => (dispatch) => {
    if (!contactId) return false;

    dispatch(trackingFormActions[FETCH_USERS]());

    getUsersList(contactId)
        .then((response) =>
            dispatch(
                trackingFormActions[FETCH_USERS_SUCCESS](response?.data?.data),
            ),
        )
        .catch((error) =>
            dispatch(
                trackingFormActions[FETCH_USERS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
