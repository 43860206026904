import { getItems } from 'common/api/v1/financiers';

import {
    FETCH_FINANCIERS,
    FETCH_FINANCIERS_FAILURE,
    FETCH_FINANCIERS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_FINANCIERS]());

    getItems()
        .then((response) => {
            const data = response.data.data;

            dispatch(actions[FETCH_FINANCIERS_SUCCESS](data));
        })
        .catch((error) => {
            dispatch(actions[FETCH_FINANCIERS_FAILURE](error));
        });
};
