import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UiDialog as Dialog } from 'sunwise-ui';

import SummaryForm from './SummaryForm';

const SummaryModal = ({
    catalogs,
    commercialOffers,
    handleClickClose,
    isLocked,
    isOpen,
    projectId,
    templates,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={handleClickClose}
            open={isOpen}
            size="xs"
            title={t('New summary')}
        >
            <SummaryForm
                catalogs={catalogs}
                commercialOffers={commercialOffers}
                isLocked={isLocked}
                projectId={projectId}
                templates={templates}
            />
        </Dialog>
    );
};

SummaryModal.propTypes = {
    catalogs: PropTypes.array,
    commercialOffers: PropTypes.array,
    handleClickClose: PropTypes.func,
    isLocked: PropTypes.bool,
    isOpen: PropTypes.bool,
    projectId: PropTypes.string,
    templates: PropTypes.array,
};

export default SummaryModal;
