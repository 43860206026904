import React from 'react';

import PropTypes from 'prop-types';
import { TemplateView, editionLevels } from 'sunwise-template-core';

import PdfViewer from 'common/components/PdfViewer';

import {
    DATASHEET_PDF_TYPE,
    ELECTRIC_BILL_TYPE,
    FINANCING_TYPE,
} from '../constants';

import TemplateSection from './TemplateSection';

const PreviewContent = ({
    editionDisabled,
    isFetchingCustomTemplate,
    isFetchingDocumentTemplates,
    isFetchingFinancingTemplate,
    isFetchingOfferSmartDocuments,
    selectedDocument,
}) => {
    switch (selectedDocument.type) {
        case DATASHEET_PDF_TYPE:
        case ELECTRIC_BILL_TYPE:
            return (
                <PdfViewer
                    document={{
                        url: selectedDocument.archive,
                    }}
                    format={selectedDocument?.format}
                />
            );
        case FINANCING_TYPE:
            if (selectedDocument?.archive)
                return (
                    <PdfViewer
                        document={{
                            url: selectedDocument.archive,
                        }}
                        format={selectedDocument?.format}
                    />
                );
            else
                return (
                    <TemplateSection
                        editionDisabled={editionDisabled}
                        isLoading={isFetchingFinancingTemplate}
                    />
                );
        default:
            return (
                <TemplateView
                    editionLevel={
                        editionDisabled
                            ? editionLevels.NO_EDITION_MODE
                            : editionLevels.PARTIAL_EDITION_MODE
                    }
                    isLoading={
                        isFetchingCustomTemplate ||
                        isFetchingDocumentTemplates ||
                        isFetchingOfferSmartDocuments
                    }
                />
            );
    }
};

PreviewContent.propTypes = {
    editionDisabled: PropTypes.bool,
    isFetchingCustomTemplate: PropTypes.bool,
    isFetchingDocumentTemplates: PropTypes.bool,
    isFetchingFinancingTemplate: PropTypes.bool,
    isFetchingOfferSmartDocuments: PropTypes.bool,
    selectedDocument: PropTypes.object,
};

export default PreviewContent;
