import React from 'react';

import ComputerIcon from '@mui/icons-material/Computer';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Skeleton, Tooltip, Typography } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';
import { scroll } from 'common/utils/mixins';

const Wrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: scroll;
    padding: 8px;
    width: 100%;

    ${scroll.custom()}
`;

import * as actions from '../actions';
import * as selectors from '../selectors';

const ViewsTooltip = ({
    fetchViews,
    isFetchingViews,
    isHidden,
    offerId,
    resetViews,
    viewsCounter,
    viewsData,
}) => {
    const { t } = useTranslation();

    if (isHidden) return null;

    const renderContent = () => {
        if (isFetchingViews) {
            return (
                <Wrapper gap={1} sx={{ width: '180px' }}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </Wrapper>
            );
        }
        return (
            <Wrapper gap={1}>
                {viewsData.map((item) => (
                    <Box
                        display="flex"
                        alignItems="center"
                        gap={1}
                        key={`view-${item.id}`}
                    >
                        {item.is_mobile ? (
                            <SmartphoneIcon fontSize="small" />
                        ) : (
                            <ComputerIcon fontSize="small" />
                        )}
                        <Typography variant="caption">
                            {formatDate(
                                parseDate(item.created, 'yyyy/MM/dd HH:mm:ss'),
                                'PPpp',
                            )}
                        </Typography>
                    </Box>
                ))}
            </Wrapper>
        );
    };

    return (
        <Tooltip
            onOpen={() => {
                resetViews();
                if (viewsCounter >= 1) fetchViews(offerId);
            }}
            placement="top"
            title={<>{renderContent()}</>}
        >
            <Typography
                className="__userguiding_interactive_link_count_views_label"
                fontWeight="bold"
                sx={{ cursor: 'default' }}
                variant="caption"
            >
                {viewsCounter} {t('View', { context: 'noun', count: 2 })}
            </Typography>
        </Tooltip>
    );
};

const mapStateToProps = createStructuredSelector({
    viewsData: selectors.getViewsData,
    isFetchingViews: selectors.getIsFetchingViews,
});
const mapDispatchToProps = (dispatch) => ({
    fetchViews: (id) => dispatch(actions.fetchViews(id)),
    resetViews: () => dispatch(actions.resetViews()),
});

ViewsTooltip.propTypes = {
    fetchViews: PropTypes.func,
    isFetchingViews: PropTypes.bool,
    isHidden: PropTypes.bool,
    offerId: PropTypes.string,
    resetViews: PropTypes.func,
    viewsCounter: PropTypes.number,
    viewsData: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewsTooltip);
