import i18next from 'i18next';
import { get, isEmpty, toNumber } from 'lodash';

import { INSURANCE_TYPE } from 'common/constants';
import { getDecimals } from 'common/utils/helpers';

export const getAllianceProductsDisctionary = (allianceProducts) => {
    return allianceProducts.reduce((acc, current) => {
        let temp = { ...acc };
        temp[current.id] = current;
        return temp;
    }, {});
};

export const getFinancierProducts = (
    allianceProducts,
    typesBusinessCompany,
) => {
    if (typesBusinessCompany) {
        return allianceProducts.filter(
            (item) => item.types_business_company === typesBusinessCompany,
        );
    }
    return [];
};

export const getFinancierProductsToSelect = (financierProducts) =>
    financierProducts.map((item) => ({
        label: item.name,
        value: item.id,
    }));

export const getFinancierProductSelected = (
    financialProductParameters,
    financialProducts,
) => {
    const id = get(financialProductParameters, 'product_financial_plan', null);
    if (id) return financialProducts.find((item) => item.id === id);
    return {};
};

export const getTotalWithDealerFee = (dealer_fee, total) => {
    const dealerFee = toNumber(dealer_fee);
    if (dealerFee !== 0) return total * (1 + dealer_fee / 100);
    return total;
};

export const getIsDisabledApplyButton = (
    errors,
    formValues,
    isLocked,
    parameters,
    step,
    typesBusinessCompany,
) => {
    const hasCommisions =
        hasCommissionDict(parameters) || hasRateDict(parameters);
    if (isLocked || errors.length > 0) {
        return true;
    }

    if (
        (step == 1 && isEmpty(typesBusinessCompany)) ||
        (step == 1 && isEmpty(formValues.financier_product))
    ) {
        return true;
    }

    if (step === 2 && isEmpty(formValues.term)) {
        return true;
    }
    if (step === 2 && hasCommisions && isEmpty(formValues.hitch_percentage)) {
        return true;
    }

    if (
        step === 2 &&
        !hasCommisions &&
        formValues.is_percentage == 'true' &&
        isEmpty(formValues.hitch_percentage)
    ) {
        return true;
    }

    if (
        step === 2 &&
        !hasCommisions &&
        formValues.is_percentage == 'false' &&
        isEmpty(formValues.hitch)
    ) {
        return true;
    }
    return false;
};

export const hasCommissionDict = (parameters) => {
    return !isEmpty(get(parameters, 'commission_dict', {}));
};

export const hasRateDict = (parameters) => {
    return !isEmpty(get(parameters, 'rate_dict', {}));
};

export const getHitchToSelect = (hasCommissionDict, parameters) => {
    const dictionary = hasCommissionDict
        ? get(parameters, 'commission_dict', {})
        : get(parameters, 'rate_dict', {});

    const keys = Object.keys(dictionary);

    return keys.map((key) => ({ value: key, label: `${key}%` }));
};

export const getInsuranceConfig = (product) => {
    return get(
        product,
        `financier_product_insurance[0].insurance_product_params`,
        [],
    ).map((value) => ({
        id: value.id,
        value_type: value.value_type,
        type_cost: value.type_cost,
        description: value.description,
        amount: value.amount,
        tax_included: value.tax_included,
    }));
};

export const getLabelButton = ({ isSaving, step }) => {
    if (step === 1) {
        return i18next.t('Continue');
    }
    if (isSaving) {
        return i18next.t('Saving').concat('...');
    }
    return i18next.t('Apply to proposal');
};

export const getTypesBusiness = (allianceProducts) => {
    let typeBusinessOptions = {
        physical_person: {
            label: i18next.t('Physical person'),
            products: [],
        },
        person_company: {
            label: i18next.t('Physical person with activity'),
            products: [],
        },
        company: {
            label: i18next.t('Moral person'),
            products: [],
        },
    };
    allianceProducts.forEach((product) => {
        typeBusinessOptions[product.types_business_company].products.push({
            label: product.name,
            value: product.id,
        });
    });
    return typeBusinessOptions;
};

export const getTypeBusinessToSelect = (typesBusiness) => {
    const data = Object.keys(typesBusiness)
        .filter((key) => !isEmpty(typesBusiness[key].products))
        .map((key) => ({
            label: typesBusiness[key].label,
            value: key,
        }));
    return [
        {
            label: i18next.t('Select a regimen'),
            value: '',
            disabled: true,
        },
        ...data,
    ];
};

export const isDisabledButton = (financierType, registered, requirements) => {
    if (requirements.content.length === 0 && requirements.required) {
        return true;
    }
    if (financierType === INSURANCE_TYPE && !registered) {
        return true;
    }
    return false;
};

export const handleOnChangeTermOrHitch = ({
    parameters,
    setValue,
    term,
    hitch,
}) => {
    if (parameters && !isEmpty(parameters.rate_dict)) {
        const rate = parameters.rate_dict[hitch][term];
        setValue('rate', rate ? rate : 0);
    }

    if (parameters && !isEmpty(parameters.commission_dict)) {
        const commission = parameters.commission_dict[hitch][term];
        setValue('extra_commission', commission ? commission : 0);
    }
};

export const handleOnChangeFinancierProduct = (
    financierProducts,
    setProductSelected,
    setValue,
    value,
) => {
    const allianceProductsDictionary =
        getAllianceProductsDisctionary(financierProducts);
    const product = allianceProductsDictionary[value];
    setProductSelected(product);
    setValue('financier_product_insurance', getInsuranceConfig(product));
    setValue('term', '');
};

export const handleOnChangeRegimen = (setProductSelected, setValue) => {
    setValue('financier_product_insurance', []);
    setValue('financier_product', '');
    setValue('term', '');
    setProductSelected({});
};

export const percentageToValue = (isPercentage, value, total) => {
    if (!isPercentage) return toNumber(value);
    const newValue = total * (toNumber(value) / 100);
    return newValue.toFixed(getDecimals(newValue));
};

export const valueToPercentage = (isPercentage, value, total) => {
    if (isPercentage) return toNumber(value);
    return toNumber((toNumber(value) / total) * 100);
};
