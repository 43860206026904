import get from 'lodash/get';

import initializeForm from './initializeForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModalProduct from './toggleModalProduct';

export default (item) => (dispatch) => {
    const values = {
        business_name: item.business_name,
        company_size: item.company_size,
        contact_cellphone: item.contact_cellphone,
        contact_email: item.contact_email,
        contact_name: item.contact_name,
        contact_telephone: item.contact_telephone,
        id: item.id,
        logo: item.logo,
        name: item.name,
        position: item.position,
        rfc: item.rfc,
        signatory_position: item.signatory_position,
        web_page: item.web_page,
    };

    if (item.position) {
        values.city = item.position.city;
        values.cp = item.position.zip_code;
        values.description = item.position.description;
        values.political_division1 = item.position.political_division1;
        values.position = {
            latitude: get(item, 'position.latitude', null),
            longitude: get(item, 'position.longitude', null),
        };
    }

    dispatch(setIsNewRecord(false));
    dispatch(initializeForm(values));
    dispatch(toggleModalProduct(true));
};
