import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const IconEnergyBackup = ({ color, height = '22px', width = '14px' }) => {
    const theme = useTheme();
    const fillColor = color || theme.palette.primary.main;
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 0H2C.9 0 0 .9 0 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2Zm0 17H2V5h10v12Z"
                fill={fillColor}
            />
            <path
                d="M7.513 7.594v2.223h1.358c.423 0 .697.461.514.852l-1.929 3.997c-.274.567-1.084.366-1.084-.26v-2.224H5.014c-.423 0-.697-.46-.514-.851l1.929-3.997c.274-.568 1.084-.367 1.084.26Z"
                fill={fillColor}
            />
        </svg>
    );
};

IconEnergyBackup.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconEnergyBackup;
