import React from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

const CardFolderContentWrapper = styled(Box)`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 11px;
    line-height: 14px;
    padding: 16px;
    text-align: center;
`;

const ImageContainer = styled(Box)`
    margin-bottom: 16px;
    position: relative;
    width: 100%;

    svg {
        fill: #e2e9f1;
        height: 96px;
        width: 96px;
    }
`;

const TitleText = styled('span')`
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.34px;
    line-height: 22px;
    min-height: 22px;
    text-align: center;
`;

const RateLabel = styled('span')`
    -moz-background-clip: text;
    -webkit-background-clip: text;
    background-clip: text;
    background-color: darkgray;
    color: #d2e1ef;
    color: transparent;
    font-size: 20px;
    font-weight: bold;
    left: 0;
    line-height: 29px;
    min-height: 29px;
    position: absolute;
    text-align: center;
    text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
    text-shadow: inset 2px 2px #000;
    top: 35px;
    width: 100%;
`;

const CardFolderContent = ({ children, onClick, rate, title }) => (
    <CardFolderContentWrapper onClick={onClick}>
        <ImageContainer>
            <FolderIcon />
            <RateLabel>{rate}</RateLabel>
        </ImageContainer>
        <TitleText>{title}</TitleText>
        {children}
    </CardFolderContentWrapper>
);

CardFolderContent.propTypes = {
    children: PropTypes.object,
    onClick: PropTypes.func,
    rate: PropTypes.string,
    title: PropTypes.string,
};

export default CardFolderContent;
