import i18next from 'i18next';
import { get, isArray } from 'lodash';

import { updateAdvancedGenerationSettings } from 'common/api/v2/offerConsumptionAndGeneration';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { advancedGenerationSettingsAction } from '../reducer';

export default (proposalId, values) => (dispatch) => {
    const generation = get(values, 'generation', null);

    if (generation === null) return;

    const newValues = {
        generation: generation,
        source: values.source,
    };

    if (values.csv_config) {
        const { columnsMatch = {}, columnsFormat = {} } = values.csv_config;

        for (const col in columnsMatch)
            newValues[`${col}_column`] = columnsMatch?.[col];

        for (const col in columnsFormat)
            newValues[`${col}_format`] = columnsFormat?.[col];
    }

    if (parseInt(values.source) !== 0 && isArray(values.file))
        newValues.file = values.file && values.file[0];

    dispatch(advancedGenerationSettingsAction[SAVE]());

    updateAdvancedGenerationSettings(newValues, proposalId)
        .then((response) => {
            dispatch(
                advancedGenerationSettingsAction[SAVE_SUCCESS](
                    response.data.data,
                ),
            );
            if (parseInt(values.source) !== 0 && isArray(values.file))
                showToast({
                    body: i18next.t('The file has been uploaded successfully'),
                });

            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                advancedGenerationSettingsAction[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            if (newValues.file) {
                const dataAlert = {
                    confirmText: i18next.t('Accept'),
                    messages: [i18next.t('Invalid file format')],
                    title: i18next.t('Error'),
                    type: alerts.TYPE_ALERT,
                    variant: 'warning',
                };
                dispatch(alerts.actions.show(dataAlert));
                return;
            }

            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
