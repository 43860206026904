import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';

import { updateItem } from 'common/api/v1/companies';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import fetchCurrencies from '../../multiCurrency/actions/filterItems';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import fetchCompanyData from './fetchCompanyData';

export default (values) => (dispatch) => {
    let newValues = {
        business_activity: values.business_activity,
        business_name: values.business_name,
        contact_cellphone: values.contact_cellphone,
        contact_email: values.contact_email,
        contact_telephone: values.contact_telephone,
        currency: values.currency,
        name: values.name,
        rfc: values.rfc,
        web_page: values.web_page,
    };

    if (isArray(values.image)) {
        newValues.logo = values.image && values.image[0];
        newValues.image = values.image && values.image[0];
    }

    if (!isNil(values.latitude) && !isNil(values.longitude)) {
        newValues.position = JSON.stringify({
            latitude: values.latitude,
            longitude: values.longitude,
            description: values.description,
            city: values.city,
            zip_code: values.cp,
            political_division1: values.political_division1,
        });
    }

    dispatch(companyGeneralActions[SAVE]());

    updateItem(newValues, values.id)
        .then((response) => {
            dispatch(companyGeneralActions[SAVE_SUCCESS](response.data));
            dispatch(fetchCompanyData());
            dispatch(fetchCurrencies());
            showToast();
        })
        .catch((error) => {
            dispatch(
                companyGeneralActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
