import {
    FILTER_CONSUMPTION_PROFILES_NEW_PAGE,
    FILTER_CONSUMPTION_PROFILES_NEW_PAGE_FAILURE,
    FILTER_CONSUMPTION_PROFILES_NEW_PAGE_SUCCESS,
} from '../actionTypes';
import { ROWS_PER_PAGE } from '../constants';
import { getDataFiltered } from '../helpers';
import { consumptionProfilesActions } from '../reducer';
import * as selectors from '../selectors';

export default ({ page, ...rest }) =>
    (dispatch, getState) => {
        dispatch(
            consumptionProfilesActions[FILTER_CONSUMPTION_PROFILES_NEW_PAGE](),
        );

        try {
            const state = getState();
            const data = selectors.getFetchConsumptionProfilesData(state);
            const dataFiltered = getDataFiltered({ data, ...rest });
            const maxIndex = page * ROWS_PER_PAGE;
            dispatch(
                consumptionProfilesActions[
                    FILTER_CONSUMPTION_PROFILES_NEW_PAGE_SUCCESS
                ]({
                    hasNextPage: dataFiltered.length > maxIndex,
                    consumptionProfiles: dataFiltered.slice(
                        maxIndex - ROWS_PER_PAGE,
                        maxIndex,
                    ),
                }),
            );
        } catch (error) {
            dispatch(
                consumptionProfilesActions[
                    FILTER_CONSUMPTION_PROFILES_NEW_PAGE_FAILURE
                ](error),
            );
        }
    };
