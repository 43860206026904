import { NAME } from './constants';

export const FETCH_NOTIFICATIONS_SETTINGS = `${NAME}/FETCH_NOTIFICATIONS_SETTINGS`;
export const FETCH_NOTIFICATIONS_SETTINGS_FAILURE = `${NAME}/FETCH_NOTIFICATIONS_SETTINGS_FAILURE`;
export const FETCH_NOTIFICATIONS_SETTINGS_SUCCESS = `${NAME}/FETCH_NOTIFICATIONS_SETTINGS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_NOTIFICATION_SETTINGS = `${NAME}/SAVE_NOTIFICATION_SETTINGS`;
export const SAVE_NOTIFICATION_SETTINGS_FAILURE = `${NAME}/SAVE_NOTIFICATION_SETTINGS_FAILURE`;
export const SAVE_NOTIFICATION_SETTINGS_SUCCESS = `${NAME}/SAVE_NOTIFICATION_SETTINGS_SUCCESS`;

export const SET_IS_OPEN_SETTINGS_MODAL = `${NAME}/SET_IS_OPEN_SETTINGS_MODAL`;
