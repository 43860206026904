import BaseModel from './BaseModel';
export default class Language extends BaseModel {}

Language.modelName = 'Language';
Language.options = {
    idAttribute: 'key',
};
Language.prepareFields({
    key: 'key',
    language: 'language',
});
