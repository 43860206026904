import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from 'sunwise-ui';

const EditionButtons = ({
    canModify,
    editionDisabled,
    handleClickCancel,
    handleClickSave,
    isUpdatingCustomTemplate,
}) => {
    const { t } = useTranslation();

    return (
        <Grid container sx={{ my: 1 }}>
            <Grid item xs sx={{ display: 'flex', flexDirection: 'column' }}>
                {!editionDisabled && (
                    <Button
                        disabled={!canModify}
                        fullWidth
                        onClick={() => handleClickSave()}
                        type="button"
                        sx={{ mb: 2 }}
                        variant="outlined"
                    >
                        {isUpdatingCustomTemplate
                            ? t('Saving').concat('...')
                            : t('Save')}
                    </Button>
                )}
                {!editionDisabled && (
                    <Button
                        color="secondary"
                        fullWidth
                        onClick={() => handleClickCancel()}
                        type="button"
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

EditionButtons.propTypes = {
    canModify: PropTypes.bool,
    editionDisabled: PropTypes.bool,
    handleClickCancel: PropTypes.func,
    handleClickSave: PropTypes.func,
    isUpdatingCustomTemplate: PropTypes.bool,
};

export default EditionButtons;
