import { push } from 'connected-react-router';

import { createParentFieldSegments } from 'common/api/v1/parentFieldSegment';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    CREATE_PARENT_FIELD_SEGMENTS,
    CREATE_PARENT_FIELD_SEGMENTS_FAILURE,
    CREATE_PARENT_FIELD_SEGMENTS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorPanelsLayoutActions } from '../reducer';

import initialValues from './initialValues';

export default (proposalId, { latitude, longitude, description }, callback) =>
    (dispatch) => {
        dispatch(
            proposalGeneratorPanelsLayoutActions[
                CREATE_PARENT_FIELD_SEGMENTS
            ](),
        );

        createParentFieldSegments(proposalId, {
            latitude,
            longitude,
            address: description,
        })
            .then((response) => {
                dispatch(
                    proposalGeneratorPanelsLayoutActions[
                        CREATE_PARENT_FIELD_SEGMENTS_SUCCESS
                    ](response.data.data),
                );
                dispatch(initialValues(response));
                callback();
                localStorage.setItem('openedProposalModule', 'panelLayout');
                dispatch(push(`/panel-layout/${proposalId}`));
            })
            .catch((error) => {
                callback();
                dispatch(
                    proposalGeneratorPanelsLayoutActions[
                        CREATE_PARENT_FIELD_SEGMENTS_FAILURE
                    ](error?.response?.data?.errors),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
