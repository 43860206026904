import { styled } from '@mui/material/styles';

export default styled('span')`
    align-items: center;
    background: #919eab;
    border-radius: 24px;
    display: flex;
    font-size: 13px;
    height: 24px;
    justify-content: center;
    width: 24px;
`;
