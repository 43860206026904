import { FETCH_OFFER_PRODUCTS_SUCCESS } from '../actionTypes';
import { proposalGeneratorQuoteActions } from '../reducer';
import * as selectors from '../selectors';

export default (name, values) => (dispatch, getState) => {
    const state = getState();
    const offerProductsData = selectors.getOfferProductsData(state);

    const model = name.replace(/-/g, '_');

    dispatch(
        proposalGeneratorQuoteActions[FETCH_OFFER_PRODUCTS_SUCCESS]({
            ...offerProductsData,
            [model]: values,
        }),
    );
};
