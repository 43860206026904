import React from 'react';

import { styled } from '@mui/material/styles';
import { Box, Card } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';

const StyledBox = styled(Box)`
    filter: url('#blur');
    filter: blur(3px);

    &:after {
        background-color: rgb(0 0 0 / 25%);
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }
`;

const NoDataIntake = () => {
    const CHART_COLORS = ['#008ffb', '#00e396'];
    const data = {
        datasets: [
            {
                backgroundColor: CHART_COLORS[0],
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 44, 55, 57],
                label: '',
            },
            {
                backgroundColor: CHART_COLORS[1],
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 76, 85, 101],
                label: '',
            },
        ],
        labels: new Array(12).fill('---'),
    };
    const options = {
        plugins: {
            legend: { display: false },
        },
        scales: {
            x: { grid: { display: false }, ticks: { display: false } },
            y: { grid: { display: false }, ticks: { display: false } },
        },
    };

    return (
        <Card sx={{ mb: 2, position: 'relative' }}>
            <StyledBox>
                <Card.Body>
                    <ChartJs data={data} height="150px" options={options} />
                </Card.Body>
            </StyledBox>
        </Card>
    );
};

NoDataIntake.propTypes = {};

export default NoDataIntake;
