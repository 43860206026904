import React from 'react';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import { DEBOUNCE_TIME } from 'common/constants';

import * as proposalGeneratorPanelTableActions from '../../proposalGeneratorPanelTable/actions';
import * as proposalGeneratorQuoteSelectors from '../../proposalGeneratorQuote/selectors';

const ExpansionField = ({
    control,
    initialValues,
    isLocked = false,
    offerPanelId,
    panelNumber,
    proposalId,
    saveExpansionField,
}) => {
    const { t } = useTranslation();

    const handleChangeExpansionField = React.useRef(
        debounce((value, offerPanelId, panelNumber) => {
            saveExpansionField(proposalId, {
                id: offerPanelId,
                expansion: value,
                quantity: panelNumber,
            });
        }, DEBOUNCE_TIME),
    ).current;

    return (
        <Grid container mt={1} justifyContent="end">
            <Grid item xs md={6} lg={4}>
                <ReactHookFormIntlNumberInput
                    allowDecimals={false}
                    allowNegativeValue={false}
                    control={control}
                    disabled={isLocked}
                    fullWidth
                    label={t('Number of additional panels')}
                    min={0}
                    name="expansion"
                    onChange={({ target: { value } }) => {
                        if (parseFloat(value) === initialValues.expansion)
                            return;
                        handleChangeExpansionField(
                            value,
                            offerPanelId,
                            panelNumber,
                        );
                    }}
                />
            </Grid>
        </Grid>
    );
};

ExpansionField.propTypes = {
    control: PropTypes.object,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    offerPanelId: PropTypes.string,
    panelNumber: PropTypes.number,
    proposalId: PropTypes.string,
    saveExpansionField: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    offerPanelExpansion: proposalGeneratorQuoteSelectors.getOfferPanelExpansion,
    offerPanelId: proposalGeneratorQuoteSelectors.getOfferPanelId,
    offerPanels: proposalGeneratorQuoteSelectors.getOfferPanelsData,
    panelNumber: proposalGeneratorQuoteSelectors.getOfferPanelNumber,
});

const mapDispatchToProps = (dispatch) => ({
    saveExpansionField: (proposalId, valuesForm) =>
        dispatch(
            proposalGeneratorPanelTableActions.saveExpansionField(
                proposalId,
                valuesForm,
            ),
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpansionField);
