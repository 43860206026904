import React from 'react';

import ReorderIcon from '@mui/icons-material/Reorder';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Card, Grid, Typography } from 'sunwise-ui';

import { FlexRow } from 'common/utils/layout';

const StyledItem = styled(Card)`
    cursor: pointer;
    margin-bottom: 0.5rem;
    padding: 1rem 15px;

    i {
        color: #2f4dff;
        font-size: 11px;
        margin-right: 1rem;
    }

    &.active,
    &:hover {
        box-shadow: 0 0 0 1px #2845ff;
    }
`;

const Text = styled(Typography)`
    font-weight: bold;
    font-size: ${({ fontSize }) => fontSize};
`;

export const SorteableItem = ({ handleOnClick, index, item, selectedItem }) => (
    <Draggable draggableId={item.id} index={index}>
        {(provided) => (
            <StyledItem
                key={`item-${item.id}`}
                onClick={() => handleOnClick(item)}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={
                    selectedItem && selectedItem.id === item.id && 'active'
                }
            >
                <FlexRow>
                    <Grid
                        item
                        xs
                        md={6}
                        display="flex"
                        alignItems="center"
                        sx={{ gap: '5px' }}
                    >
                        <ReorderIcon />
                        <Text fontSize="15px">{item.currency.name}</Text>
                    </Grid>
                    <Grid item xs md={6}>
                        <Text component="span" fontSize="13px">
                            {item.currency.symbol}
                        </Text>
                    </Grid>
                    <Grid item xs md={6}>
                        <Text component="span" fontSize="13px">
                            {item.currency.abbreviation}
                        </Text>
                    </Grid>
                </FlexRow>
            </StyledItem>
        )}
    </Draggable>
);

SorteableItem.propTypes = {
    handleChangeSwitch: PropTypes.func,
    handleOnClick: PropTypes.func,
    index: PropTypes.number,
    item: PropTypes.object,
    selectedItem: PropTypes.object,
};

export default SorteableItem;
