import { getLanguages } from 'common/api/v1/templates';

import {
    FETCH_LANGUAGES,
    FETCH_LANGUAGES_FAILURE,
    FETCH_LANGUAGES_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

export default () => (dispatch) =>
    new Promise((resolve) => {
        dispatch(templateLibraryActions[FETCH_LANGUAGES]());

        getLanguages()
            .then((response) => {
                dispatch(
                    templateLibraryActions[FETCH_LANGUAGES_SUCCESS](
                        response.data,
                    ),
                );
            })
            .catch((error) =>
                dispatch(
                    templateLibraryActions[FETCH_LANGUAGES_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            )
            .finally(resolve);
    });
