import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'core';

export const getTableSettings = (tableKey) =>
    API.get(`/api/v1/${ENTITY}/settings-configuration/`, {
        params: { key: tableKey },
    });
export const saveTableSettings = (data) =>
    API.post(`/api/v1/${ENTITY}/settings-configuration/`, data, config);
