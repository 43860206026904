import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { STATUS } from 'common/constants';

const CancelDropdown = ({ cancelCreditApp, status }) => {
    const { t } = useTranslation();

    switch (status) {
        case STATUS.CLOSED_STATUS.key:
        case STATUS.CANCELLED_STATUS.key:
        case STATUS.QUOTED_STATUS.key:
            return null;
        default:
            return (
                <DropdownCardMenu
                    items={[
                        {
                            handleClick: cancelCreditApp,
                            label: t('Cancel'),
                        },
                    ]}
                    sxIconButton={{ ml: 'auto' }}
                />
            );
    }
};

CancelDropdown.propTypes = {
    cancelCreditApp: PropTypes.func,
    status: PropTypes.number,
};

export default CancelDropdown;
