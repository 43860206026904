import { NAME } from './constants';

export const FETCH_DATA = `${NAME}/FETCH_DATA`;
export const FETCH_DATA_FAILURE = `${NAME}/FETCH_DATA_FAILURE`;
export const FETCH_DATA_SUCCESS = `${NAME}/FETCH_DATA_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const FETCH_CUSTOM_OFFER_TEMPLATE = `${NAME}/FETCH_CUSTOM_OFFER_TEMPLATE`;
export const FETCH_CUSTOM_OFFER_TEMPLATE_FAILURE = `${NAME}/FETCH_CUSTOM_OFFER_TEMPLATE_FAILURE`;
export const FETCH_CUSTOM_OFFER_TEMPLATE_SUCCESS = `${NAME}/FETCH_CUSTOM_OFFER_TEMPLATE_SUCCESS`;
