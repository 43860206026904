import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_REPORT_DETAILS,
    FETCH_REPORT_DETAILS_FAILURE,
    FETCH_REPORT_DETAILS_SUCCESS,
    SET_HAS_PERMALINK,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    reportDetails: { data: {}, errors: [], isFetching: false },
};

const reportReviewProSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_REPORT_DETAILS]: (state) => {
            state.reportDetails.errors = [];
            state.reportDetails.isFetching = true;
        },
        [FETCH_REPORT_DETAILS_FAILURE]: (state, action) => {
            state.reportDetails.errors = action.payload;
            state.reportDetails.isFetching = false;
        },
        [FETCH_REPORT_DETAILS_SUCCESS]: (state, action) => {
            state.reportDetails.data = action.payload;
            state.reportDetails.isFetching = false;
        },
        [SET_HAS_PERMALINK]: (state, action) => {
            state.reportDetails.data.hasPermalink = action.payload;
        },
    },
});

export const reportReviewProActions = reportReviewProSlice.actions;

export default reportReviewProSlice.reducer;
