export const NAME = 'proposal-generator-quote';
export const PIE_CHART_COLORS = [
    '#3F4FAE',
    '#20C4D9',
    '#FF7043',
    '#E91E63',
    '#FFC107',
    '#7046BC',
    '#8BC34A',
];
export const CHART_SIZE = 250;
