import fetchIrradiationData from './fetchIrradiationData';
import fetchOfferInfoPatern from './fetchOfferInfoPatern';
import fetchOfferPanel from './fetchOfferPanel';
import fetchParentFieldSegment from './fetchParentFieldSegment';

export default (id) => (dispatch) => {
    Promise.all([
        dispatch(fetchOfferInfoPatern(id)),
        dispatch(fetchOfferPanel(id)),
        dispatch(fetchParentFieldSegment(id)),
        dispatch(fetchIrradiationData(id)),
    ]);
};
