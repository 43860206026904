import { styled } from '@mui/material/styles';
import { Typography } from 'sunwise-ui';

export default styled(Typography)`
    font-size: 16px;
    font-weight: bold;
    height: 27px;
    line-height: 27px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
