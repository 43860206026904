import API from '../..';
import openAPI from '../../openAPI';

const ENTITY = 'new-summary-ended';

export const getSummaryEnded = (commercialOfferId) =>
    API.get(`/api/v1/${ENTITY}/${commercialOfferId}`);
export const getSummaryEndedWithoutToken = (commercialOfferId) =>
    openAPI.get(`/api/v1/sunpal-${ENTITY}/${commercialOfferId}`);
export const updateSummaryEnded = (id, data) =>
    API.put(`/api/v1/${ENTITY}/${id}`, data);
