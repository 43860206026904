import React, { useEffect, useRef, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, Link, Tooltip, Typography } from 'sunwise-ui';

import { SinglePagination } from 'common/components';
import { DEBOUNCE_TIME } from 'common/constants';

import StyledName from './StyledName';

const SummariesTable = ({
    filterItems,
    isFetching,
    items,
    paginationData,
    projectId,
}) => {
    const { t } = useTranslation();
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 5,
        projectId,
        sortBy: 'desc',
    });

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME),
    ).current;

    useEffect(() => {
        handleOnFilter(filterData);
    }, [filterData]);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount,
            );
    }, [paginationData?.total_rows, setRowCount]);

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    const columnName = {
        field: 'name',
        flex: 1,
        headerName: t('Name'),
        renderCell: (params) => {
            const { row } = params;
            return (
                <Link
                    color="inherit"
                    component={RouterLink}
                    to={`/summary-review/${row.id}`}
                    underline="none"
                >
                    <Tooltip title={row?.name} placement="top">
                        <StyledName
                            fontWeight="bold"
                            variant="body2"
                            sx={{
                                width: '175px',
                                maxWidth: '175px',
                            }}
                        >
                            {row.name}
                        </StyledName>
                    </Tooltip>
                </Link>
            );
        },
    };

    let columns = [
        columnName,
        {
            field: 'proposals',
            headerName: t('Proposal', { count: 2 }),
            minWidth: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="body2">
                        {row.proposals}{' '}
                        {t('Proposal', { count: row?.proposals })}
                    </Typography>
                );
            },
        },
    ];

    return (
        <DataGrid
            autoHeight
            checkboxSelection={false}
            columns={columns}
            components={{
                LoadingOverlay: LinearProgress,
                Pagination: SinglePagination,
            }}
            componentsProps={{
                pagination: {
                    rowsPerPageOptions: [25, 50, 75, 100],
                },
            }}
            disableColumnMenu
            disableSelectionOnClick
            editMode="row"
            experimentalFeatures={{ newEditingApi: true }}
            getRowHeight={() => 'auto'}
            initialState={{
                pagination: {
                    pageSize: filterData?.pageSize,
                },
            }}
            loading={isFetching}
            onPageChange={handleOnPageChange}
            onPageSizeChange={(newPage) =>
                setFilterData({ ...filterData, pageSize: newPage })
            }
            onSortModelChange={handleOnSortModelChange}
            page={paginationData?.current_page - 1 || 0}
            pageSize={filterData.pageSize}
            pagination
            paginationMode="server"
            rowCount={rowCount}
            rows={items}
            sortingMode="server"
            sx={{
                '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
                    backgroundColor: 'rgba(255, 154, 0, 0.16)',
                },
                '& .MuiDataGrid-row--editing:hover': {
                    backgroundColor: 'rgba(255, 154, 0, 0.08)',
                },
                '& .MuiInputBase-formControl input': {
                    border: 0,
                    outline: 'none',
                    boxShadow: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {
                    height: '210px !important',
                },
                '& .MuiDataGrid-root': {
                    height: '100%',
                },
            }}
        />
    );
};

SummariesTable.propTypes = {
    filterItems: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    projectId: PropTypes.string,
};

export default SummariesTable;
