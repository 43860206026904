import { getProjects } from 'common/api/v2/projectsByList';

import {
    FETCH_PROJECTS,
    FETCH_PROJECTS_FAILURE,
    FETCH_PROJECTS_SUCCESS,
} from '../actionTypes';
import { powerStationsActions } from '../reducer';

export default (projects) => (dispatch) => {
    if (!projects?.length)
        return dispatch(powerStationsActions[FETCH_PROJECTS_SUCCESS]([]));
    dispatch(powerStationsActions[FETCH_PROJECTS]());

    getProjects(projects)
        .then((response) => {
            dispatch(
                powerStationsActions[FETCH_PROJECTS_SUCCESS](
                    response?.data?.data,
                ),
            );
        })
        .catch(() => dispatch(powerStationsActions[FETCH_PROJECTS_FAILURE]()));
};
