import i18next from 'i18next';
import { types } from 'sunwise-template-core';

import { updateOfferEnded } from 'common/api/v1/newOfferEnded';
import { updateSummaryEnded } from 'common/api/v1/newSummaryEnded';
import { updateSmartDocumentEnded } from 'common/api/v1/smartDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
} from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

const getAction = (templateType) => {
    switch (templateType) {
        case types.MULTIPROPOSAL_TYPE:
            return updateSummaryEnded;
        case types.SMART_DOCUMENTS_TYPE:
            return updateSmartDocumentEnded;
        default:
            return updateOfferEnded;
    }
};

export default (
        reviewId,
        templateType,
        getTemplateUpdatedData,
        setTemplateBackup,
    ) =>
    (dispatch) => {
        dispatch(multiTemplateReviewActions[UPDATE_TEMPLATE]());
        const updatedData = getTemplateUpdatedData();

        const updateAction = getAction(templateType);

        updateAction(reviewId, {
            content: JSON.stringify(updatedData),
        })
            .then(() => {
                dispatch(multiTemplateReviewActions[UPDATE_TEMPLATE_SUCCESS]());
                setTemplateBackup(true);

                showToast({
                    body: i18next.t('Changes were saved successfully'),
                });
            })
            .catch((error) => {
                dispatch(
                    multiTemplateReviewActions[UPDATE_TEMPLATE_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
