import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValuesForm = createSelector(
    getModel,
    (model) => model.initialValuesForm,
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

/* DummyForm */

export const getInitialValuesDummyForm = createSelector(
    getModel,
    (model) => model.initialValuesDummyForm,
);

/** TEMPLATES */
export const getFetchTemplates = createSelector(
    getModel,
    (model) => model.fetchCustomOfferTemplates,
);

export const getIsFetchingTemplates = createSelector(
    getFetchTemplates,
    (fetchItems) => fetchItems.isFetching,
);

export const getDataFetchTemplates = createSelector(
    getFetchTemplates,
    (fetchItems) => fetchItems.data || [],
);

export const getErrorsFetchTemplates = createSelector(
    getFetchTemplates,
    (fetchItems) => fetchItems.errors,
);

export const getTemplatesToSelect = createSelector(
    getDataFetchTemplates,
    (fetchTemplates) => {
        let items = fetchTemplates.map((item) => ({
            label: item.title,
            value: item.id,
        }));

        items.unshift({
            label: i18next.t('Select a template'),
            value: '',
            disabled: true,
        });

        return items;
    },
);
