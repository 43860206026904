import { filterTrackings } from 'common/api/v1/followUps';

import {
    FETCH_CONTACTS_TRACKINGS,
    FETCH_CONTACTS_TRACKINGS_FAILURE,
    FETCH_CONTACTS_TRACKINGS_SUCCESS,
} from '../actionTypes';
import { followUpsActions } from '../reducer';

export default ({
        completed,
        orderBy,
        page = 0,
        pageSize,
        sortBy,
        userId,
    } = {}) =>
    (dispatch) => {
        dispatch(followUpsActions[FETCH_CONTACTS_TRACKINGS]());

        filterTrackings({
            completed,
            orderBy,
            page: page + 1,
            perPage: pageSize,
            sortBy,
            userId,
        })
            .then((response) =>
                dispatch(
                    followUpsActions[FETCH_CONTACTS_TRACKINGS_SUCCESS](
                        response.data,
                    ),
                ),
            )
            .catch((error) =>
                dispatch(
                    followUpsActions[FETCH_CONTACTS_TRACKINGS_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            );
    };
