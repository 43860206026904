import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, Typography } from 'sunwise-ui';

import { STATUS } from 'common/constants';

const ConditionalApprovedWarning = ({
    financierName,
    handleOnClickApproveChangesOnCredit,
    status,
}) => {
    const { t } = useTranslation();

    if (status === STATUS.CONDITIONED_APPROVED_STATUS.key) {
        return (
            <Box mb={3}>
                <Alert severity="warning">
                    <Typography variant="body2">
                        {t(
                            '{{financierName}} has modified the down payment and/or term of the request',
                            { financierName: financierName },
                        )}
                    </Typography>

                    <Typography variant="body2">
                        {t(
                            'Check the changes made and make sure notify your client',
                        )}
                    </Typography>

                    <Box mt={1} textAlign="right">
                        <Button
                            onClick={handleOnClickApproveChangesOnCredit}
                            variant="confirm"
                        >
                            {t('Accept')}
                        </Button>
                    </Box>
                </Alert>
            </Box>
        );
    }

    return null;
};

ConditionalApprovedWarning.propTypes = {
    financierName: PropTypes.string,
    handleOnClickApproveChangesOnCredit: PropTypes.func,
    status: PropTypes.number,
};

export default ConditionalApprovedWarning;
