import React from 'react';

import get from 'lodash/get';

import { POINTER_CURSOR } from '../constants';

import LabelName from './LabelName';

const ProjectFormatter = (params) => {
    const { row } = params;
    const label = `${get(row, 'project.name', '-')}`;

    return (
        <LabelName title={label} sx={{ cursor: `${POINTER_CURSOR}` }}>
            {label}
        </LabelName>
    );
};

ProjectFormatter.propTypes = {};

export default ProjectFormatter;
