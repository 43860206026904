import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export default styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: 4,
    },
    [`& .MuiInputBase-input`]: {
        fontSize: 14,
    },
}));
