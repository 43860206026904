import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import get from 'lodash/get';

import { PREFIX } from 'common/sockets/constants';
import { generateSocketActions } from 'common/sockets/helpers';
import {
    formatDate,
    getDateByTimezoneOffset,
    parseDate,
} from 'common/utils/dates';
import {
    getDateFormatByLocale,
    getTimezoneUserSettings,
} from 'common/utils/helpers/multiregion';

import {
    ADD_MESSAGE,
    FETCH_MESSAGES,
    FETCH_MESSAGES_FAILURE,
    FETCH_MESSAGES_SUCCESS,
    INIT_CHAT,
    INIT_CHAT_FAILURE,
    INIT_CHAT_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SEND_MESSAGE,
    SEND_MESSAGE_FAILURE,
    SEND_MESSAGE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initChat: {
        data: {},
        errors: null,
        isFetching: false,
    },
    initialValues: {
        chatId: null,
        message: '',
        message_from: null,
        user: null,
    },
    messages: {
        data: {
            messages: [],
        },
        errors: null,
        isFetching: false,
    },
    send: {
        data: {},
        errors: null,
        isFetching: false,
    },
    socketConnected: false,
    socketError: null,
};

const {
    BROKEN_SOCKET,
    CLOSED_SOCKET,
    ERROR_SOCKET,
    MESSAGE_SOCKET,
    OPEN_SOCKET,
    RECONNECTED,
} = generateSocketActions(PREFIX);

const chatViewSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ADD_MESSAGE]: (state, action) => {
            state.messages.data.messages.push(action.payload);
        },
        [FETCH_MESSAGES]: (state) => {
            state.messages = {
                ...state.messages,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_MESSAGES_FAILURE]: (state, action) => {
            state.messages = {
                ...state.messages,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_MESSAGES_SUCCESS]: (state, action) => {
            state.messages = {
                ...state.messages,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [INIT_CHAT]: (state) => {
            state.initChat = {
                ...state.initChat,
                errors: null,
                isFetching: true,
            };
        },
        [INIT_CHAT_FAILURE]: (state, action) => {
            state.initChat = {
                ...state.initChat,
                errors: action.payload,
                isFetching: false,
            };
        },
        [INIT_CHAT_SUCCESS]: (state, action) => {
            state.initChat = {
                ...state.initChat,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SEND_MESSAGE]: (state) => {
            state.send = { ...state.send, errors: null, isFetching: true };
        },
        [SEND_MESSAGE_FAILURE]: (state, action) => {
            state.send = {
                ...state.send,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SEND_MESSAGE_SUCCESS]: (state, action) => {
            state.send = {
                ...state.send,
                data: action.payload,
                isFetching: false,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(BROKEN_SOCKET, (state) => {
                state.socketError = i18next.t(
                    'Failed to send data. Please restart your session or try later',
                );
            })
            .addCase(ERROR_SOCKET, (state) => {
                state.socketError = i18next.t('Server error. Try again later');
            })
            .addCase(RECONNECTED, (state) => {
                state.socketError = null;
            })
            .addCase(CLOSED_SOCKET, (state) => {
                state.socketConnected = false;
            })
            .addCase(OPEN_SOCKET, (state) => {
                state.socketConnected = true;
            })
            .addCase(MESSAGE_SOCKET, (state, action) => {
                const { message } = JSON.parse(action.payload.message);
                if (!get(message, 'success', true) || message.errors) {
                    state.socketError = i18next.t(
                        'Server error. Try again later',
                    );
                    return;
                }
                const { data, type } = message;
                if (
                    type !== '1' ||
                    data.model !== 'chats/credit/' ||
                    data.is_comment
                )
                    return;

                const { response, message_from } = data;
                const dateFormatByLocale = getDateFormatByLocale();
                const timezoneUserSettings = getTimezoneUserSettings();

                const newMessage = {
                    created_at: formatDate(
                        getDateByTimezoneOffset(
                            new Date(
                                parseDate(
                                    response.created_at,
                                    'dd/MM/yyyy HH:mm:ss',
                                ),
                            ),
                            timezoneUserSettings,
                        ),
                        `${dateFormatByLocale} HH:mm:ss`,
                    ),
                    id: response.id,
                    message: response.message,
                    message_from: response.message_from,
                    name: state.initialValues.user.name,
                    updated_at: formatDate(
                        getDateByTimezoneOffset(
                            new Date(
                                parseDate(
                                    response.updated_at,
                                    'dd/MM/yyyy HH:mm:ss',
                                ),
                            ),
                            timezoneUserSettings,
                        ),
                        `${dateFormatByLocale} HH:mm:ss`,
                    ),
                    username: response.username,
                };

                if (message_from !== state.initialValues.user.id) {
                    state.messages.data.messages.push(newMessage);
                    return;
                }

                state.initialValues = {
                    ...state.initialValues,
                    message: '',
                    timestamp: new Date(),
                };
                state.send = {
                    ...state.send,
                    data: response,
                    isFetching: false,
                };
                state.messages.data.messages.push(newMessage);
            });
    },
});

export const chatViewActions = chatViewSlice.actions;

export default chatViewSlice.reducer;
