import BaseModel from './BaseModel';

export default class Rate extends BaseModel {
    static generate({
        certified = true,
        energy_export_price = 0,
        is_enable = true,
        percent_bags_used = 1,
        ...rest
    }) {
        super.generate({
            ...rest,
            certified,
            energy_export_price,
            is_enable,
            percent_bags_used,
        });
    }

    isCertified() {
        return this.ref.isCertified;
    }

    toggleEnabled() {
        return this.update({ isEnabled: !this.ref.isEnabled });
    }
}

Rate.modelName = 'Rate';
Rate.prepareFields({
    annualPercentageIncrease: 'annual_percentage_increase',
    archived: 'archived',
    businessType: 'business_type',
    contractedDemandConcepts: 'contracted_demand_concepts',
    country: 'country',
    currency: 'currency',
    dap: 'dap',
    defaultCompensationScheme: 'default_compensation_scheme',
    demandConcepts: 'demand_concepts',
    energyConcepts: 'energy_concepts',
    energyExportPrice: 'energy_export_price',
    formType: 'form_type',
    hasActiveProjects: 'has_active_projects',
    hasContractedDemands: 'has_contracted_demands',
    id: 'id',
    isCertified: 'certified',
    isEnabled: 'is_enable',
    limitDac: 'limit_dac',
    name: 'name',
    nettedExportedGenerationPrice: 'netted_exported_generation_price',
    otherConcepts: 'other_concepts',
    paybackType: 'payback_type',
    paymentType: 'payment_type',
    percentBagsUsed: 'percent_bags_used',
    periodCompensation: 'period_compensation',
    periodicityType: 'periodicity_type',
    rateDescription: 'rate_description',
    rateType: 'rate_type',
    shortName: 'short_name',
    spainExcessDemandConcepts: 'spain_excess_demand_concepts',
    startPeriodCompMont: 'start_period_comp_mont',
    taxRate: 'tax_rate',
    taxesConcepts: 'taxes_concepts',
    tiersDemandDistribution: 'tiers_demand_distribution',
    tiersEnergyDistribution: 'tiers_energy_distribution',
    typeOfInputCharges: 'type_of_input_charges',
    utilitySupplier: 'utility_supplier',
    voltageType: 'voltage_type',
});
