import React from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Typography } from 'sunwise-ui';

const ShowErrors = ({
    errors,
    severity = 'warning',
    title = i18next
        .t('Please correct the following error', { count: 2 })
        .concat(':'),
    sxError = {},
    sxTitle = {},
}) => {
    if (!errors || !Array.isArray(errors) || errors.length === 0) return null;
    return (
        <Alert severity={severity}>
            <AlertTitle mb={0} sx={sxTitle}>
                {title}
            </AlertTitle>

            {errors.map((error, index) => (
                <Typography
                    variant="body2"
                    key={index}
                    type="error"
                    sx={sxError}
                >
                    * {error}
                </Typography>
            ))}
        </Alert>
    );
};

ShowErrors.propTypes = {
    errors: PropTypes.array,
    severity: PropTypes.string,
    sxError: PropTypes.object,
    sxTitle: PropTypes.object,
    title: PropTypes.string,
};

export default ShowErrors;
