import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

import ProjectForm from '../../../../../modules/createProjectModal/components/ProjectForm';
import ProjectSelector from '../common/ProjectSelector';

const CustomFooter = ({
    handleOnSubmit,
    handleSubmit,
    isNewContact,
    setIsNewRecord,
    setStep,
}) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, pt: 2 }}>
            <Button
                onClick={() => {
                    if (isNewContact) {
                        setStep(1);
                        return;
                    }
                    setIsNewRecord(false);
                }}
                sx={{ ml: 'auto' }}
            >
                {t('Previous')}
            </Button>

            <Button onClick={handleSubmit(handleOnSubmit)}>{t('Next')}</Button>
        </Box>
    );
};

CustomFooter.propTypes = {
    handleOnSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    isNewContact: PropTypes.bool,
    setIsNewRecord: PropTypes.func,
    setStep: PropTypes.func,
};

const Step2 = ({ saveProject, onSubmit, setProject, setStep, values }) => {
    const { t } = useTranslation();
    const [isNewRecord, setIsNewRecord] = useState(false);
    const { contact, project } = values;

    useEffect(() => {
        if (contact?.isNewRecord) setIsNewRecord(true);
    }, [contact]);

    const handleOnSubmit = (formValues) => {
        const { type } = formValues;

        onSubmit('project', formValues);
        onSubmit('consumptions', null);

        if (type === 'without_consumption') {
            setStep(4);
            return;
        }

        if (isNewRecord) {
            const callback = (values) => {
                onSubmit('project', { ...formValues, id: values.id });
                onSubmit('contact', {
                    branch_office: values.contact.branch_office,
                    id: values.contact.id,
                });
                setStep(3);
            };
            saveProject({ ...values, project: formValues }, callback, false);
        } else {
            setProject({ ...formValues, id: project.id });
            setStep(3);
        }
    };

    const handleNextStep = () => {
        if (project.id === 'new-project') {
            setIsNewRecord(true);
            onSubmit('project', null);
            return;
        }

        const nextStep = project?.id ? 4 : 3;
        setStep(nextStep);
    };

    const props = !isEmpty(values.project)
        ? { initialValues: values.project }
        : {};

    if (isNewRecord || contact?.isNewRecord)
        return (
            <ProjectForm
                {...props}
                CustomFooter={({ handleSubmit }) => (
                    <CustomFooter
                        handleOnSubmit={handleOnSubmit}
                        handleSubmit={handleSubmit}
                        isNewContact={contact?.isNewRecord}
                        setIsNewRecord={setIsNewRecord}
                        setStep={setStep}
                    />
                )}
            />
        );

    return (
        <>
            <ProjectSelector onSubmit={onSubmit} values={values} />

            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 2,
                    pt: 2,
                }}
            >
                <Button onClick={() => setStep(1)} sx={{ ml: 'auto' }}>
                    {t('Back')}
                </Button>

                <Button disabled={isEmpty(project)} onClick={handleNextStep}>
                    {t('Next')}
                </Button>
            </Box>
        </>
    );
};

Step2.propTypes = {
    onSubmit: PropTypes.func,
    saveProject: PropTypes.func,
    setProject: PropTypes.func,
    setStep: PropTypes.func,
    values: PropTypes.object,
};

export default Step2;
