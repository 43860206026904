import React from 'react';

import PropTypes from 'prop-types';
import { BottomActions, Box, Button } from 'sunwise-ui';

const BottomActionsForm = ({
    cancelText,
    continueText,
    disabled,
    disabledContinue,
    onCancel,
    onContinue,
    type = 'button',
}) => {
    return (
        <BottomActions>
            <Box
                display="flex"
                flexDirection={{ md: 'row', xs: 'column' }}
                gap={1}
                justifyContent={{ md: 'right', xs: 'center' }}
                mt={2}
                width="100%"
            >
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onCancel}
                    sx={{
                        order: { md: 1, xs: 1 },
                        width: { md: 'auto', xs: '100%' },
                    }}
                    variant="text"
                >
                    {cancelText}
                </Button>

                <Button
                    disabled={disabledContinue || disabled}
                    onClick={onContinue}
                    sx={{
                        order: { md: 1, xs: 1 },
                        width: { md: 'auto', xs: '100%' },
                    }}
                    variant="outlined"
                    type={type}
                >
                    {continueText}
                </Button>
            </Box>
        </BottomActions>
    );
};

BottomActionsForm.propTypes = {
    cancelText: PropTypes.string,
    continueText: PropTypes.string,
    disabled: PropTypes.bool,
    disabledContinue: PropTypes.bool,
    onCancel: PropTypes.func,
    onContinue: PropTypes.func,
    type: PropTypes.string,
};

export default BottomActionsForm;
