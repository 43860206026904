import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getActivateItem = createSelector(
    getModel,
    (model) => model.activateItem,
);

export const getIsFetchingActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.isFetching,
);
export const getDataActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.data,
);
export const getErrorsActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.erros,
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getSelectedItem = createSelector(
    getModel,
    (model) => model.selectedItem,
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getErrorsSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.errors,
);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving,
);

export const getFetchItems = createSelector(
    getModel,
    (model) => model.fetchItems,
);

export const getIsFetchingCurrencies = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.isFetching,
);

export const getDataFetchItems = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.data || [],
);

export const getDataFetchCurrencies = createSelector(
    getDataFetchItems,
    (currencies) =>
        currencies
            .filter(
                (item) => item.is_enabled && !item.is_deleted && item.currency,
            )
            .map((item) => ({
                ...item.currency,
                dollar_price: item?.dollar_price,
                exchange_rate_id: item.id,
                exchange_type: item?.exchange_type,
            })) || [],
);

export const getDataFetchCurrenciesDictionary = createSelector(
    getDataFetchCurrencies,
    (data) =>
        data.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {}),
);

export const getDataFetchCurrenciesForSelect = createSelector(
    getDataFetchCurrencies,
    (data) => [
        {
            label: '',
            options: data.map((item) => ({
                label: item.name,
                value: item?.id,
            })),
        },
    ],
);

export const getErrorsFetchItems = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.errors,
);

export const getItemsParent = createSelector(
    getModel,
    (model) => model.fetchItemsParent,
);

export const getIsFetchingItemsParent = createSelector(
    getItemsParent,
    (itemsParent) => itemsParent.isFetching,
);

export const getDataItemsParent = createSelector(
    getItemsParent,
    (itemsParent) => itemsParent.data.data,
);

export const getPaginationItemsParent = createSelector(
    getItemsParent,
    (itemsParent) => itemsParent.data.pagination,
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord,
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal,
);

export const getSearchText = createSelector(
    getModel,
    (model) => model.searchText,
);

export const getSearchTextParent = createSelector(
    getModel,
    (model) => model.searchTextParent,
);
