import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, Typography } from 'sunwise-ui';

import { STATUS_CONFIG, STATUS_KEYS } from '../../constants';
import DotIndicator from '../DotIndicator';

const StatusSelect = ({ control, disabled, label, name, variant }) => {
    const { t } = useTranslation();

    const options = [
        {
            color: STATUS_CONFIG[STATUS_KEYS.ONLINE].color,
            label: t(STATUS_CONFIG[STATUS_KEYS.ONLINE].label),
            value: STATUS_KEYS.ONLINE,
        },
        {
            color: STATUS_CONFIG[STATUS_KEYS.UNSTABLE].color,
            label: t(STATUS_CONFIG[STATUS_KEYS.UNSTABLE].label),
            value: STATUS_KEYS.UNSTABLE,
        },
        {
            color: STATUS_CONFIG[STATUS_KEYS.OFFLINE].color,
            label: t(STATUS_CONFIG[STATUS_KEYS.OFFLINE].label),
            value: STATUS_KEYS.OFFLINE,
        },
    ];

    const getValue = (value) => {
        if (value || value === 0)
            return (
                options.find((option) => option.value === Number(value)) ?? null
            );
        return null;
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    blurOnSelect
                    disabled={disabled}
                    getOptionDisabled={(option) =>
                        option?.disabled || option.value === ''
                    }
                    getOptionLabel={(option) => option?.label || ''}
                    groupBy={(option) => option.group}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value?.value
                    }
                    name={field.name}
                    onChange={(_, data) => field.onChange(data?.value ?? '')}
                    options={options}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={Boolean(error)}
                            helperText={error?.message}
                            inputRef={field.ref}
                            label={label}
                            variant={variant}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={`${option.value}-${option.label}`}>
                            <DotIndicator color={option.color} />
                            <Typography variant="body2" sx={{ ml: 1 }}>
                                {option.label}
                            </Typography>
                        </li>
                    )}
                    value={getValue(field.value)}
                />
            )}
        />
    );
};

StatusSelect.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.string,
};

export default StatusSelect;
