import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

const PanelsNumberFormatter = ({ row }) => {
    const { t } = useTranslation();
    const { panels_counter = null } = row;
    if (!panels_counter) return '---';
    return (
        <Typography variant="caption">
            {panels_counter}{' '}
            {t('Panel', {
                count: panels_counter,
            })}
        </Typography>
    );
};

PanelsNumberFormatter.propTypes = {
    row: PropTypes.object,
};

export default PanelsNumberFormatter;
