import { getPricing } from 'common/api/v1/chargebee';

import {
    FETCH_PRICING,
    FETCH_PRICING_FAILURE,
    FETCH_PRICING_SUCCESS,
} from '../actionTypes';
import { selectBillingPlanActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(selectBillingPlanActions[FETCH_PRICING]());

    getPricing()
        .then((response) =>
            dispatch(
                selectBillingPlanActions[FETCH_PRICING_SUCCESS](response.data),
            ),
        )
        .catch((error) =>
            dispatch(selectBillingPlanActions[FETCH_PRICING_FAILURE](error)),
        );
};
