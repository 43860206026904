import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button } from 'sunwise-ui';

import { arraytoDictionary } from 'common/utils/helpers';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import ReassignListItems from './ReassignListItems';

const ReasignForm = ({
    contacts,
    filterData,
    initialValues,
    openReasignModal,
    save,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const contactsDictionary = arraytoDictionary(contacts, 'id');

    const handleOnSave = (values) => save(filterData, values);

    return (
        <form onSubmit={handleSubmit(handleOnSave)}>
            <ReassignListItems
                component={ReassignListItems}
                contactsDictionary={contactsDictionary}
                control={control}
                name="reasignAgents"
            />

            <Box textAlign="right">
                <Button
                    color="secondary"
                    onClick={() => openReasignModal(false)}
                    sx={{
                        width: { xs: '100%', md: 'auto' },
                        order: { xs: 2, md: 1 },
                        mr: { xs: 0, md: 2 },
                    }}
                    variant="outlined"
                >
                    {t('Cancel')}
                </Button>
                <Button
                    sx={{
                        mb: { md: 0, xs: 2 },
                        order: { md: 2, xs: 1 },
                        width: { md: 'auto', xs: '100%' },
                    }}
                    type="submit"
                    variant="outlined"
                >
                    {t('Save')}
                </Button>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    contacts: selectors.getContactsData,
    initialValues: selectors.getReasignInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    openReasignModal: (value) => dispatch(actions.openReasignModal(value)),
    save: (filterData, values) =>
        dispatch(actions.bulkReasignItems(filterData, values)),
});

ReasignForm.propTypes = {
    contacts: PropTypes.array,
    filterData: PropTypes.object,
    initialValues: PropTypes.object,
    openReasignModal: PropTypes.func,
    save: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReasignForm);
