import React from 'react';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Divider } from 'sunwise-ui';

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 8px;
`;

const Title = styled('div')`
    color: #202253;
    font-size: 15px;
    font-weight: bold;
`;

const ItemContainer = styled('div')`
    align-items: center;
    display: flex;
    gap: 16px;
    padding: 8px;
`;

const IconContainer = styled('div')`
    display: flex;
    justify-content: center;
`;

const ItemText = styled('span')`
    color: #848bab;
    font-size: 14px;
    line-height: 14px;
`;

const DotColor = styled('div')`
    background-color: ${({ color }) => color};
    border-radius: 50%;
    height: 14px;
    width: 14px;
`;

const InfoList = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <Title>{t('Days elapsed (since starting the request)')}</Title>
            <Divider />
            <ItemContainer>
                <IconContainer>
                    <DotColor color="#00ff00" />
                </IconContainer>
                <ItemText>0-9 {t('day', { count: 2 })}</ItemText>
            </ItemContainer>
            <Divider />
            <ItemContainer>
                <IconContainer>
                    <DotColor color="#ffa500" />
                </IconContainer>
                <ItemText>10-14 {t('day', { count: 2 })}</ItemText>
            </ItemContainer>
            <Divider />
            <ItemContainer>
                <IconContainer>
                    <DotColor color="#ff0000" />
                </IconContainer>
                <ItemText>{t('More than 15 days')}</ItemText>
            </ItemContainer>
            <ItemContainer>
                <IconContainer>
                    <DotColor color="#0000006b" />
                </IconContainer>
                <ItemText>{t('Ended process')}</ItemText>
            </ItemContainer>
        </Container>
    );
};

export default InfoList;
