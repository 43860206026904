import { createSlice } from '@reduxjs/toolkit';

import {
    INITIALIZE_VALUES,
    RESET,
    RESET_FORM,
    SAVE_PROJECT,
    SAVE_PROJECT_FAILURE,
    SAVE_PROJECT_SUCCESS,
    SET_MODAL_IS_OPEN,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: { contact: '', name: '', type: 'add_consumption' },
    modalIsOpen: false,
    saveProject: { data: null, errors: [], isSaving: false },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIALIZE_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_PROJECT]: (state) => {
            state.saveProject = {
                ...state.saveProject,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_PROJECT_FAILURE]: (state, action) => {
            state.saveProject = {
                ...state.saveProject,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_PROJECT_SUCCESS]: (state, action) => {
            state.saveProject = {
                ...state.saveProject,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_MODAL_IS_OPEN]: (state, action) => {
            state.modalIsOpen = action.payload;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
