import { createSlice } from '@reduxjs/toolkit';

import { formatDate, zonedDate } from 'common/utils/dates';
import { getTimezoneUserSettings } from 'common/utils/helpers/multiregion';

import {
    FETCH_TRACKINGS_PURPOSES,
    FETCH_TRACKINGS_PURPOSES_FAILURE,
    FETCH_TRACKINGS_PURPOSES_SUCCESS,
    FETCH_USERS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
    RESET_FORM,
} from './actionTypes';
import { FILTER_TYPE_CALL, NAME, OBJECTIVE_RECEIPT } from './constants';

const currentDate = zonedDate(new Date(), getTimezoneUserSettings());
const dateTimeToday = formatDate(currentDate, 'yyyy-MM-dd H:mm');

const INITIAL_STATE = {
    initialValues: {
        agent: '',
        description: '',
        id: null,
        notification_at: dateTimeToday,
        objective_tracking: OBJECTIVE_RECEIPT,
        remember: true,
        tracking_purpose: '',
        tracking_type: FILTER_TYPE_CALL,
    },
    trackingPurpose: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    users: {
        data: [],
        errors: [],
        isFetching: false,
    },
};

const trackingFormSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_USERS]: (state) => {
            state.users.isFetching = true;
        },
        [FETCH_USERS_FAILURE]: (state, action) => {
            state.users = {
                ...state.users,
                error: action.payload,
                isFetching: false,
            };
        },
        [FETCH_USERS_SUCCESS]: (state, action) => {
            state.users = {
                ...state.users,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [FETCH_TRACKINGS_PURPOSES]: (state) => {
            state.trackingPurpose.isLoading = true;
        },
        [FETCH_TRACKINGS_PURPOSES_FAILURE]: (state, action) => {
            state.trackingPurpose = {
                ...state.trackingPurpose,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_TRACKINGS_PURPOSES_SUCCESS]: (state, action) => {
            state.trackingPurpose = {
                ...state.trackingPurpose,
                data: action.payload,
                isEmpty: action.payload.length === 0,
                isLoading: false,
            };
        },
    },
});

export const trackingFormActions = trackingFormSlice.actions;

export default trackingFormSlice.reducer;
