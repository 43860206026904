import { showFastApiErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_MEASURED_ENERGY,
    FETCH_MEASURED_ENERGY_FAILURE,
    FETCH_MEASURED_ENERGY_SUCCESS,
} from '../actionTypes';
import { getDateRange } from '../helpers';
import { actions } from '../reducer';

import fetchProjectMeasuredEnergyByDate from './fetchProjectMeasuredEnergyByDate';

export default (projectId, values, callback) => (dispatch) => {
    if (!values) return;

    dispatch(actions[FETCH_MEASURED_ENERGY]());

    const { date, power_stations, view_option } = values;

    const dateValues = getDateRange(date, view_option);

    const newValues = { ...dateValues, projects: power_stations };

    dispatch(fetchProjectMeasuredEnergyByDate(projectId, newValues))
        .then((data) => {
            dispatch(actions[FETCH_MEASURED_ENERGY_SUCCESS](data));
            return data;
        })
        .catch((error) => {
            dispatch(actions[FETCH_MEASURED_ENERGY_FAILURE](error));
            showFastApiErrorsAsAlert(dispatch, error?.response);
        })
        .finally((data) => {
            if (callback) callback(values, data);
        });
};
