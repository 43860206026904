import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getCountryCurrency } from 'common/utils/helpers/session';

import * as advancedGenerationSettingsSelectors from '../proposalGeneratorAdvancedGenerationSettings/selectors';
import * as proposalGeneratorOneColumnSelectors from '../proposalGeneratorOneColumn/selectors';

import * as actions from './actions';
import { Card } from './components';
import * as selectors from './selectors';

const Container = ({
    consumptionProjection,
    irradiationValues,
    isFetchingCompatibleRates,
    isLoading,
    isLocked = false,
    isSaving,
    isSavingAdvancedGeneration,
    offerConfigGeneration,
    offerData,
    proposalConfiguration,
    proposalId,
    reset,
    solarConsumption,
    solarSimulationData,
    systemSize,
}) => {
    useEffect(() => () => reset(), []);

    const isLoadingForm =
        isFetchingCompatibleRates ||
        isLoading ||
        isSaving ||
        isSavingAdvancedGeneration;

    const isLockedForm = isLoadingForm || isLocked;

    return (
        <Card
            consumptionProjection={consumptionProjection}
            countryCurrency={getCountryCurrency()}
            isLoading={isLoadingForm}
            isLocked={isLockedForm}
            offerConfigGeneration={offerConfigGeneration}
            offerData={offerData}
            proposalConfiguration={proposalConfiguration}
            proposalId={proposalId}
            solarConsumption={solarConsumption}
            solarSimulationData={solarSimulationData}
            systemSize={systemSize}
            irradiationValues={irradiationValues}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    consumptionProjection:
        proposalGeneratorOneColumnSelectors.getConsumptionProjectionData,
    irradiationValues:
        proposalGeneratorOneColumnSelectors.getDataOfferIrradiationValues,
    isFetchingCompatibleRates: selectors.getIsFetchingCompatibleRates,
    isSaving: selectors.getIsSaving,
    isSavingAdvancedGeneration: advancedGenerationSettingsSelectors.getIsSaving,
    offerConfigGeneration:
        proposalGeneratorOneColumnSelectors.getDataOfferConfigGeneration,
    offerData: proposalGeneratorOneColumnSelectors.getOfferData,
    proposalConfiguration:
        proposalGeneratorOneColumnSelectors.getProposalConfiguration,
    solarSimulationData:
        proposalGeneratorOneColumnSelectors.getSolarSimulationData,
});

const mapDispatchToProps = (dispatch) => ({
    reset: () => dispatch(actions.reset()),
});

Container.propTypes = {
    consumptionProjection: PropTypes.object,
    irradiationValues: PropTypes.object,
    isFetchingCompatibleRates: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    isSavingAdvancedGeneration: PropTypes.bool,
    offerConfigGeneration: PropTypes.object,
    offerData: PropTypes.object,
    proposalConfiguration: PropTypes.object,
    proposalId: PropTypes.string,
    reset: PropTypes.func,
    solarConsumption: PropTypes.number,
    solarSimulationData: PropTypes.object,
    systemSize: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
