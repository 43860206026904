import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
} from 'sunwise-ui';

import { TitleWithDetail } from 'common/components';

const AccordionComponent = ({ children, defaultExpanded = false, title }) => {
    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            disableGutters
            sx={{ '&::before': { display: 'none' } }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
                <Box sx={{ width: '100%' }}>
                    <TitleWithDetail variant="body2">{title}</TitleWithDetail>

                    <Divider sx={{ my: 1, mr: -3 }} />
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
        </Accordion>
    );
};

AccordionComponent.propTypes = {
    children: PropTypes.node,
    defaultExpanded: PropTypes.bool,
    title: PropTypes.string,
};

export default AccordionComponent;
