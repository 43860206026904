import { styled } from '@mui/material/styles';
import { Drawer } from 'sunwise-ui';

import { SMALL_SIZE_DRAWER } from './constants';
import { getDrawerSizeInPX } from './helpers';

const StyledDrawer = styled(Drawer)(({ drawersize, theme }) => ({
    '& ::-webkit-scrollbar': { width: 0 },
    '& .MuiDrawer-paper': {
        background: theme.palette.mode === 'dark' ? '#1B1C1F' : '#002438',
        borderRight: 0,
        overflowX: 'hidden',
        width: getDrawerSizeInPX(drawersize),
    },
    '&:hover .toggleDrawer': { visibility: 'visible!important' },
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: getDrawerSizeInPX(drawersize),
    [theme.breakpoints.down('sm')]: {
        '& .MuiDrawer-paper': { width: SMALL_SIZE_DRAWER },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        width: SMALL_SIZE_DRAWER,
    },
}));

export default StyledDrawer;
