import { getRates } from 'common/api/v1/rate';

import {
    FETCH_PROFILE_CONSUMPTION_SUCCESS,
    FETCH_RATES,
    FETCH_RATES_SUCCESS,
    FETCH_RATES_FAILURE,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(projectConsumptionModalActions[FETCH_RATES]());
    getRates()
        .then(({ data }) => {
            dispatch(
                projectConsumptionModalActions[
                    FETCH_PROFILE_CONSUMPTION_SUCCESS
                ](data.profile_consumption),
            );
            dispatch(
                projectConsumptionModalActions[FETCH_RATES_SUCCESS](data.data),
            );
        })
        .catch((error) => {
            dispatch(
                projectConsumptionModalActions[FETCH_RATES_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
        });
};
