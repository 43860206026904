import { getDocuments } from 'common/api/v1/projectDocuments';

import {
    FETCH_DOCUMENTS,
    FETCH_DOCUMENTS_FAILURE,
    FETCH_DOCUMENTS_SUCCESS,
} from '../actionTypes';
import { projectDocumentActions } from '../reducer';

export default (projectId) => (dispatch) => {
    dispatch(projectDocumentActions[FETCH_DOCUMENTS]());

    getDocuments(projectId)
        .then((response) =>
            dispatch(
                projectDocumentActions[FETCH_DOCUMENTS_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) => {
            dispatch(projectDocumentActions[FETCH_DOCUMENTS_FAILURE](error));
        });
};
