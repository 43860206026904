import React from 'react';

import PropTypes from 'prop-types';

import SingleAutocomplete from 'common/components/SingleAutocomplete';

const CardGroupList = ({
    equipments,
    isFetching,
    onSelectEquipment,
    title,
}) => {
    const newFilterEquipments = () => {
        if (equipments === null) {
            return [];
        }

        const tempFilteredEquipments = {};
        const ordered = {};

        equipments.forEach((equipment) => {
            if (!tempFilteredEquipments[equipment.brand]) {
                tempFilteredEquipments[equipment.brand] = {
                    brand: equipment.brand,
                    label: equipment.brand,
                    dataSheets: equipment.dataSheets,
                    options: [],
                };
            }

            tempFilteredEquipments[equipment.brand].options.push({
                brand: equipment.brand,
                label: equipment.name,
                value: equipment.id,
                dataSheets: equipment.dataSheets,
            });
        });

        Object.keys(tempFilteredEquipments)
            .sort()
            .forEach((key) => {
                ordered[key] = tempFilteredEquipments[key];
                ordered[key].options =
                    tempFilteredEquipments[key].options.sort();
            });

        return Object.values(ordered);
    };

    return (
        <SingleAutocomplete
            fullWidth
            isLoading={isFetching}
            label={title}
            onChange={onSelectEquipment}
            options={newFilterEquipments()}
            variant="outlined"
        />
    );
};

CardGroupList.propTypes = {
    equipments: PropTypes.array,
    isFetching: PropTypes.bool,
    onSelectEquipment: PropTypes.func,
    title: PropTypes.string,
};

export default CardGroupList;
