import { push } from 'connected-react-router';
import get from 'lodash/get';

import { getSubscription } from 'common/api/v1/companies';
import { SUBSCRIPTION_STATUS } from 'common/constants';
import { updateSession } from 'common/utils/helpers/session';

import {
    FETCH_SUBSCRIPTION,
    FETCH_SUBSCRIPTION_FAILURE,
    FETCH_SUBSCRIPTION_SUCCESS,
} from '../actionTypes';
import { profileInvoiceActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(profileInvoiceActions[FETCH_SUBSCRIPTION]());

    getSubscription()
        .then((response) => {
            const data = response.data;
            if (data) {
                const subscriptionStatus = get(data, 'status.key', null);
                const dueInvoicesCount = get(data, 'due_invoices_count', 0);
                if (dueInvoicesCount === 0) {
                    updateSession({
                        has_payment_due: false,
                    });
                }
                if (subscriptionStatus === SUBSCRIPTION_STATUS.CANCELLED) {
                    dispatch(push('/wizard'));
                    return false;
                }
            }
            dispatch(profileInvoiceActions[FETCH_SUBSCRIPTION_SUCCESS](data));
        })
        .catch((error) =>
            dispatch(
                profileInvoiceActions[FETCH_SUBSCRIPTION_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
