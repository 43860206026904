import React, { useContext, useEffect } from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Avatar, Box, IconButton, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { LoadingContext } from 'common/utils/contexts';
import { stringAvatar } from 'common/utils/helpers';
import { logoutActions } from 'common/utils/helpers/session';

import whiteLogo from 'resources/isotypeSunwise3.svg';
import logo from 'resources/logotipoSunwiseAzul.svg';

import * as profileActions from '../profile/actions';
import * as profileSelectors from '../profile/selectors';

import * as actions from './actions';
import { Step1 } from './components';
import { AVATAR_SIZE } from './constants';
import * as selectors from './selectors';

const StyledHeader = styled(Box)`
    align-items: center;
    box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    padding: 30px;
    position: relative;
    z-index: 100;
`;

const Container = ({
    fetchPricing,
    fetchSubscription,
    fetchUserSettings,
    isFetchingPricing,
    isFetchingSubscription,
    isSavingSubscription,
    pricingData,
    profileValues,
    selectedTheme,
    subscription,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const loadingContext = useContext(LoadingContext);

    const profileName = `${get(profileValues, 'first_name', '')} ${get(
        profileValues,
        'last_name',
        '',
    )}`;

    useEffect(() => {
        fetchPricing();
        fetchSubscription();
        fetchUserSettings();
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingSubscription)
            loadingContext.openLoading(t('Saving').concat('...'));
        else loadingContext.closeLoading();
    }, [isSavingSubscription]);

    return (
        <>
            <StyledHeader>
                <img
                    alt="Sunwise"
                    src={selectedTheme === 'dark' ? whiteLogo : logo}
                    width="140"
                />
                <Box display="flex" gap={1} alignItems="center">
                    {breakpoint !== 'xs' && (
                        <Typography variant="body1">{profileName}</Typography>
                    )}
                    {get(profileValues, 'profile_image', '') ? (
                        <Avatar
                            alt={profileName}
                            src={get(profileValues, 'profile_image', '')}
                            sx={{
                                height: AVATAR_SIZE,
                                width: AVATAR_SIZE,
                            }}
                        />
                    ) : (
                        <Avatar
                            {...stringAvatar(profileName)}
                            sx={{
                                fontSize: '16px',
                                height: AVATAR_SIZE,
                                width: AVATAR_SIZE,
                            }}
                        />
                    )}
                    <IconButton color="secondary" onClick={logoutActions}>
                        <LogoutIcon />
                    </IconButton>
                </Box>
            </StyledHeader>

            <Box p={3}>
                <Step1
                    isFetching={isFetchingPricing || isFetchingSubscription}
                    pricingData={pricingData}
                    selectedTheme={selectedTheme}
                    subscription={subscription}
                />
            </Box>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingPricing: selectors.getIsFetchingPricing,
    isFetchingSubscription: selectors.getIsFetchingSubscription,
    isSavingSubscription: selectors.getIsSavingSubscription,
    pricingData: selectors.getPricingData,
    profileValues: profileSelectors.getCurrentValues,
    selectedTheme: profileSelectors.getTheme,
    subscription: selectors.getSubscriptionData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPricing: () => dispatch(actions.fetchPricing()),
    fetchSubscription: () => dispatch(actions.fetchSubscription()),
    fetchUserSettings: () => dispatch(profileActions.fetchProfileData()),
});

Container.propTypes = {
    fetchPricing: PropTypes.func,
    fetchSubscription: PropTypes.func,
    fetchUserSettings: PropTypes.func,
    isFetchingPricing: PropTypes.bool,
    isFetchingSubscription: PropTypes.bool,
    isSavingSubscription: PropTypes.bool,
    pricingData: PropTypes.object,
    profileValues: PropTypes.object,
    selectedTheme: PropTypes.string,
    subscription: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
