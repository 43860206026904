import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteSummary from './deleteSummary';

export default (summary, isArchivedFilter) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [i18next.t('Are you sure you want to delete this item?')],
        onSuccess: () => {
            dispatch(deleteSummary(summary, isArchivedFilter));
        },
        title: i18next.t('Delete summary'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
