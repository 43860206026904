import React, { useEffect, useState } from 'react';

import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Box, Container, Grid, Tab, Tabs, TabPanel } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { validateAccessWithPermissions } from 'common/utils/helpers/permissionsV2';

const getTabsWithRights = (tabs) =>
    tabs.filter(
        (tab) =>
            !tab.hidden &&
            (!tab.requiredPermissions ||
                validateAccessWithPermissions(tab.requiredPermissions)),
    );

const TabsSystem = ({
    baseUrl,
    hiddenTabs = false,
    match,
    redirect,
    showHeader = true,
    tabs = [],
    title,
}) => {
    const tabsWithRights = getTabsWithRights(tabs);
    const [selectedTab, setSelectedTab] = useState(tabsWithRights[0]?.id);

    useEffect(() => {
        if (!match.params.id) return;
        const links = tabsWithRights.map((tab) => tab.id);
        if (links.includes(match.params.id))
            handleChange(null, match.params.id);
        else handleChange(null, tabsWithRights[0]?.id);
    }, [match.params.id]);

    const handleChange = (_, newValue) => {
        setSelectedTab(newValue);
        redirect(`/${baseUrl}/${newValue}`);
    };

    return (
        <Container maxWidth={false}>
            {showHeader && title && (
                <HeaderContainer>
                    <Grid item xs>
                        <TitleIcon title={title} />
                    </Grid>
                </HeaderContainer>
            )}

            <Box
                hidden={hiddenTabs}
                sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
                <Tabs
                    onChange={handleChange}
                    scrollButtons="auto"
                    value={selectedTab}
                    variant="scrollable"
                >
                    {tabsWithRights.map((tab) => (
                        <Tab
                            id={`tab-${tab.id}`}
                            key={`tab-${tab.id}`}
                            label={tab.label}
                            value={tab.id}
                        />
                    ))}
                </Tabs>
            </Box>

            {tabsWithRights.map((tab) => (
                <TabPanel
                    key={`tab-panel-${tab.id}`}
                    selectedTab={selectedTab}
                    value={tab.id}
                >
                    {tab.content}
                </TabPanel>
            ))}
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => ({
    redirect: (link) => dispatch(push(link)),
});

TabsSystem.propTypes = {
    baseUrl: PropTypes.string,
    hiddenTabs: PropTypes.bool,
    match: PropTypes.object,
    redirect: PropTypes.func,
    showHeader: PropTypes.bool,
    tabs: PropTypes.array,
    title: PropTypes.string,
};

export default compose(
    connect(null, mapDispatchToProps),
    withRouter,
)(TabsSystem);
