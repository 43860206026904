import React from 'react';

import PropTypes from 'prop-types';
import { Card, Grid } from 'sunwise-ui';

import ProviderImage from 'common/components/ProviderImage';

const ProvidersList = ({
    disabled,
    handleSelectProvider,
    providers,
    selectedProvider,
}) => {
    return (
        <Grid container pb={2} justifyContent="center">
            {providers.map((provider) => (
                <Grid
                    key={provider.id}
                    item
                    xs={9}
                    sm={6}
                    md={4.5}
                    textAlign="center"
                >
                    <Card
                        onClick={() => {
                            if (disabled || !provider.active) return;
                            handleSelectProvider(provider.id);
                        }}
                        sx={{
                            border: (theme) =>
                                `1px solid ${
                                    selectedProvider === provider.id
                                        ? theme.palette.primary.main
                                        : 'transparent'
                                }`,
                            height: '100%',
                            ...(provider.active && {
                                '&:hover': {
                                    border: (theme) =>
                                        `1px solid ${theme.palette.primary.main}`,
                                    cursor: 'pointer',
                                },
                            }),
                        }}
                    >
                        <Card.Body sx={{ display: 'flex', height: '100%' }}>
                            <ProviderImage
                                provider={provider}
                                style={{ margin: 'auto' }}
                            />
                        </Card.Body>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

ProvidersList.propTypes = {
    disabled: PropTypes.bool,
    handleSelectProvider: PropTypes.func,
    providers: PropTypes.array,
    selectedProvider: PropTypes.string,
};

export default ProvidersList;
