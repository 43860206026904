import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import ChartJs from 'common/components/ChartJs';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import { createChartConfig } from '../helpers';

const Chart = ({ countryCurrencySymbol, pricingData }) => {
    const chartRef = useRef(null);

    const countryCurrencyLocale = getCountryCurrencyLocale();

    const {
        type,
        options,
        data: chartData,
    } = createChartConfig({
        countryCurrencyLocale,
        countryCurrencySymbol,
        pricingData,
    });

    return (
        <ChartJs
            chartRef={chartRef}
            data={chartData}
            height="300px"
            options={options}
            type={type}
        />
    );
};

Chart.propTypes = {
    countryCurrencySymbol: PropTypes.string,
    pricingData: PropTypes.object,
};

export default Chart;
