import { isEmpty } from 'lodash';

import { formatDate, parseDate, subDate } from 'common/utils/dates';
import {
    calcEnergyDistributionPercentages,
    getDailyAvgFields,
    getEnergyAndDemandFields,
    getFieldsFromTiers,
    getFieldsInPeriod,
    getIsHourlyRate,
} from 'common/utils/helpers/rates';

import * as projectConsumptionCardSelectors from '../../projectConsumptionCard/selectors';
import {
    fetchPoliticalDivisions2,
    fetchScheduleRateConfiguration,
    prepareConsumptions,
} from '../../projectConsumptionsModal/actions';
import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { importConsumptionsModalActions } from '../reducer';
import * as selectors from '../selectors';

import setIsOpen from './setIsOpen';

const DATE_FORMAT = 'dd/MM/yyyy';

export default ({ callbackMasterButton, formValues, rate, rateDivision }) =>
    (dispatch, getState) => {
        dispatch(importConsumptionsModalActions[SAVE]());

        dispatch(fetchScheduleRateConfiguration({ rate, rateDivision }))
            .then((rateConfiguration) => {
                const state = getState();

                const savedConsumption =
                    projectConsumptionCardSelectors.getDataConsumptionEnergy(
                        state,
                    );
                const selectedProject =
                    projectConsumptionCardSelectors.getSelectedProject(state);
                const data = selectors.getCsvInfoData(state) || {};

                let values = {
                    address: data.address,
                    billing_period_end: data.billing_period_end,
                    billing_period_start: data.billing_period_start,
                    branch_office: data.branch_office,
                    company: data.company,
                    consumption_origin: data.consumption_origin,
                    consumption_profile: data.consumption_profile?.[0] || null,
                    contracted_demand: data.contrated_demand,
                    electrical_network: data.electrical_network,
                    holder: data.holder,
                    id: savedConsumption?.id || null,
                    is_bimonthly: data.is_bimonthly ? '1' : '0',
                    last_consumption:
                        data.last_consumption ?? data.billing_period_end,
                    periodicity_type: data.periodicity_type,
                    political_division1: data.political_division1?.id || null,
                    political_division2: data.political_division2?.id || null,
                    rate: rate?.id,
                    rate_division: rateDivision,
                    rate_division_summer: data.rate_division_summer?.id || '',
                    rate_region: data.rate_division?.id || '',
                    service_number: data.service_number,
                    subsidy_rate: data.subsidy_rate?.name || '',
                    terms_consumption: false,
                    threads_number: data.threads_number,
                    without_consumption: false,
                    zip_code: data.zip_code,
                    ...formValues,
                    file: null,
                };

                const months_kWh = data.consumption_lines
                    ?.filter((item) => parseInt(item.type_line) === 0)
                    ?.map((item) => ({
                        ...item,
                        file: item.url,
                    }));

                const months_kW = data.consumption_lines
                    ?.filter((item) => parseInt(item.type_line) === 1)
                    ?.map((item) => ({
                        ...item,
                        file: item.url,
                    }));

                const sortedMonthskWh = months_kWh?.sort(
                    (a, b) =>
                        parseDate(a.initial_date, DATE_FORMAT) -
                        parseDate(b.initial_date, DATE_FORMAT),
                );

                const sortedMonthskW = months_kW?.sort(
                    (a, b) =>
                        parseDate(a.initial_date, DATE_FORMAT) -
                        parseDate(b.initial_date, DATE_FORMAT),
                );

                const { tiers_demand_distribution, tiers_energy_distribution } =
                    rateConfiguration || {};

                let summary = [];

                const sortedTierEnergyConsumptions =
                    [...(data?.tiers_consumptions || [])].sort(
                        (a, b) =>
                            parseDate(a.initial_date, DATE_FORMAT) -
                            parseDate(b.initial_date, DATE_FORMAT),
                    ) || [];

                if (
                    tiers_demand_distribution?.[0]?.tiers &&
                    sortedTierEnergyConsumptions.length > 0 &&
                    getIsHourlyRate(rate)
                ) {
                    const { fields: hourly_contracted_demand } =
                        getFieldsFromTiers({
                            concepts:
                                sortedTierEnergyConsumptions?.[0]
                                    ?.contracted_demand,
                            isCertified: rate?.isCertified,
                            tiers: tiers_demand_distribution?.[0]?.tiers,
                        });
                    values.hourly_contracted_demand = hourly_contracted_demand;
                }

                summary = sortedMonthskWh.map((period, index) => {
                    const finalDate = parseDate(period.final_date, DATE_FORMAT);
                    const label = formatDate(
                        subDate(finalDate, {
                            days: data.is_bimonthly ? 30 : 15,
                        }),
                        'MMM yy',
                    );
                    const totalConsumption = period.consumption.toFixed(0);

                    const { fields: _fields, total: _total } =
                        getEnergyAndDemandFields({
                            isCertified: rate?.isCertified,
                            period: sortedTierEnergyConsumptions[index],
                            tiers_energy_distribution,
                        });

                    const total = _total || totalConsumption;

                    const fields = getFieldsInPeriod({
                        discardValues: true,
                        finalDate: period.final_date,
                        initialDate: period.initial_date,
                        tmp_kW: sortedMonthskW?.[index]?.consumption,
                        period: {
                            ..._fields,
                            total: { placeholder: `${total}`, value: total },
                        },
                        rate,
                        tiers_energy_distribution,
                    });

                    return {
                        dailyAvg: getDailyAvgFields(
                            total,
                            period.final_date,
                            period.initial_date,
                        ),
                        file: period.file,
                        final_date: period.final_date,
                        initial_date: period.initial_date,
                        ...fields,
                        label: period.initial_date ? label : 'Último mes',
                        power_factor: period.power_factor,
                        predicted: period.predicted,
                        url_type: period.url_type,
                    };
                });

                const filteredSummaryData = summary.filter(
                    (item) => item !== null,
                );

                values.summary = filteredSummaryData
                    .sort(
                        (a, b) =>
                            parseDate(b.initial_date, DATE_FORMAT) -
                            parseDate(a.initial_date, DATE_FORMAT),
                    )
                    .slice(0, 12);

                const distribution = calcEnergyDistributionPercentages(
                    values.summary,
                );
                if (!isEmpty(distribution)) values.distribution = distribution;

                values.last_consumption = values.summary[0].final_date;

                if (values.summary[0]?.file)
                    values.file = values.summary[0]?.file;

                dispatch(setIsOpen(false));
                dispatch(importConsumptionsModalActions[SAVE_SUCCESS]());
                if (values.political_division1)
                    dispatch(
                        fetchPoliticalDivisions2(values.political_division1),
                    );
                dispatch(
                    prepareConsumptions({
                        automaticHasChanges: false,
                        callbackMasterButton,
                        initialValues: values,
                        isOpenModal: true,
                        projectData: selectedProject,
                    }),
                );
            })
            .catch((error) =>
                dispatch(importConsumptionsModalActions[SAVE_FAILURE](error)),
            );
    };
