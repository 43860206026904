import React from 'react';

import { FormHelperText } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const InverterDetails = ({ errorConfig, inverterItem }) => {
    const { t } = useTranslation();
    return (
        <>
            <FormHelperText>
                {t('Panel', { count: 2 })}: {t('Min')} {inverterItem.min_panels}{' '}
                -{t('Max')} {inverterItem.max_panels}{' '}
                {`${t('Ratio ACDC')}: ${inverterItem.oversize}%`}
            </FormHelperText>

            {!inverterItem.is_compatible && errorConfig && (
                <FormHelperText>
                    <strong>
                        {t(errorConfig.label)}.{' '}
                        <a
                            href="/equipment-compatibility"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {t('Check compatibility')}.
                        </a>
                    </strong>
                </FormHelperText>
            )}
        </>
    );
};

InverterDetails.propTypes = {
    errorConfig: PropTypes.object,
    inverterItem: PropTypes.object,
};

export default InverterDetails;
