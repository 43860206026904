import React from 'react';

import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import WifiTetheringErrorIcon from '@mui/icons-material/WifiTetheringError';
import { LinearProgress } from '@mui/material';
import { Box, Divider, Typography } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ProviderImage from 'common/components/ProviderImage';

import { SYNC_PROGRESS_STATUS, SYNC_TYPES_LABELS } from '../constants';

const SyncStationItem = ({ providersDictionary, station }) => {
    const { t } = useTranslation();

    if (!station?.station_id) return null;

    const { attempt, progress, provider_id, station_name, status, type } =
        station;

    const typeLabel = SYNC_TYPES_LABELS[type];

    return (
        <>
            <Box display="flex" gap={1}>
                <ProviderImage
                    provider={providersDictionary?.[provider_id]}
                    style={{ maxHeight: '20px', maxWidth: '50px' }}
                />

                <Typography variant="subtitle2">{station_name}</Typography>
            </Box>

            {typeLabel && (
                <Typography variant="body2">
                    {`${t('Type')}: ${t(typeLabel)}`}
                </Typography>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box mr={1} width="100%">
                    <LinearProgress
                        color="success"
                        value={progress || 0}
                        variant="determinate"
                    />
                </Box>

                <Typography color="text.secondary" variant="body2" mr={1}>
                    {`${progress || 0}%`}
                </Typography>

                {status === SYNC_PROGRESS_STATUS.SUCCESS && (
                    <WifiTetheringIcon color="success" size="small" />
                )}

                {status === SYNC_PROGRESS_STATUS.FAILED && (
                    <WifiTetheringErrorIcon color="error" size="small" />
                )}
            </Box>

            {attempt > 0 && (
                <Typography variant="body2">
                    {`${t('Attempt')}: ${attempt + 1}`}
                </Typography>
            )}

            <Divider sx={{ my: 1 }} />
        </>
    );
};

SyncStationItem.propTypes = {
    providersDictionary: PropTypes.object,
    station: PropTypes.object,
};

export default SyncStationItem;
