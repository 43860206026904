import React from 'react';

import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

const Wrapper = styled(Box)`
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
`;

const ProgressBar = styled(Box)`
    @keyframes indeterminateAnimation {
        0% {
            transform: translateX(0) scaleX(0);
        }
        40% {
            transform: translateX(0) scaleX(0.4);
        }
        100% {
            transform: translateX(100%) scaleX(0.5);
        }
    }

    background-color: #e9e9e9;
    height: 4px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    .value {
        animation: indeterminateAnimation 1s infinite linear;
        background-color: #b5b5b5;
        border-radius: 6px;
        height: 100%;
        transform-origin: 0% 50%;
        width: 100%;
    }
`;

const PlaceholderLoadingView = () => (
    <Wrapper>
        <ProgressBar>
            <div className="value" />
        </ProgressBar>
    </Wrapper>
);

export default PlaceholderLoadingView;
