import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ConfirmDialog } from 'sunwise-ui';

import * as actions from './actions';
import { ProductDatasheetsCard, Form } from './components';
import * as selectors from './selectors';

const Container = ({
    dataConfirmModal,
    dataDatasheets,
    disabled,
    fetchProductDatasheets,
    handleClickCloseForm,
    handleClickCreate,
    handleClickDelete,
    handleClickDownload,
    initialValues,
    isFetchingProductDetail,
    isOpenConfirmModal,
    isUploadingDatasheet,
    isVisibleForm,
    openConfirmModal,
    prepareDelete,
    prepareUpdate,
    productValues,
    save,
    setInitDefaultValues,
    uploadDatasheetErrors,
}) => {
    useEffect(() => {
        if (productValues.id) {
            fetchProductDatasheets(productValues.productType, productValues.id);
            setInitDefaultValues({
                productType: productValues.productType,
                productId: productValues.id,
            });
        }
        return () => {
            handleClickCloseForm();
        };
    }, []);

    const handleSubmit = (values) => save(values);

    if (!productValues.id) return null;

    return (
        <>
            <ProductDatasheetsCard
                datasheets={dataDatasheets}
                disabled={disabled}
                handleClickCreate={handleClickCreate}
                handleClickDelete={prepareDelete}
                handleClickDownload={handleClickDownload}
                handleClickUpdate={prepareUpdate}
                isFetchingProductDetail={isFetchingProductDetail}
                isVisibleForm={isVisibleForm}
                values={productValues}
            />
            <br />
            {isVisibleForm && (
                <Form
                    handleClickCloseForm={handleClickCloseForm}
                    initialValues={initialValues}
                    isUploadingDatasheet={isUploadingDatasheet}
                    onSubmit={handleSubmit}
                    uploadDatasheetErrors={uploadDatasheetErrors}
                />
            )}

            <ConfirmDialog
                handleClickClose={() => openConfirmModal(false)}
                handleClickSuccess={handleClickDelete}
                isOpen={isOpenConfirmModal}
                message={dataConfirmModal && dataConfirmModal.message}
                title={dataConfirmModal && dataConfirmModal.title}
                variant={dataConfirmModal && dataConfirmModal.type}
            />
        </>
    );
};

Container.propTypes = {
    dataConfirmModal: PropTypes.object,
    dataDatasheets: PropTypes.array,
    disabled: PropTypes.bool,
    fetchProductDatasheets: PropTypes.func,
    handleClickCloseForm: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickDownload: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingProductDetail: PropTypes.bool,
    isOpenConfirmModal: PropTypes.bool,
    isUploadingDatasheet: PropTypes.bool,
    isVisibleForm: PropTypes.bool,
    openConfirmModal: PropTypes.func,
    prepareDelete: PropTypes.func,
    prepareUpdate: PropTypes.func,
    productValues: PropTypes.object,
    save: PropTypes.func,
    setInitDefaultValues: PropTypes.func,
    uploadDatasheetErrors: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    dataConfirmModal: selectors.getDataConfirmModal,
    dataDatasheets: selectors.getDatasheetsProductDatasheets,
    initialValues: selectors.getInitialValues,
    isFetchingProductDetail: selectors.getIsLoadingProductDatasheets,
    isOpenConfirmModal: selectors.getIsOpenConfirmModal,
    isUploadingDatasheet: selectors.getIsUploadingDatasheet,
    isVisibleForm: selectors.getIsVisibleForm,
    uploadDatasheetErrors: selectors.getUploadDatasheetErrors,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProductDatasheets: (productType, productId) =>
        dispatch(actions.fetchProductDatasheets(productType, productId)),
    handleClickCloseForm: () => dispatch(actions.prepareCloseForm()),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    handleClickDelete: (id) => dispatch(actions.deleteDatasheet(id)),
    handleClickDownload: (datasheet, openLoading, closeLoading) =>
        dispatch(
            actions.downloadDatasheet(datasheet, openLoading, closeLoading),
        ),
    openConfirmModal: (value) => dispatch(actions.openConfirmModal(value)),
    prepareDelete: (element, values) =>
        dispatch(actions.prepareDelete(element, values)),
    prepareUpdate: (data) => dispatch(actions.prepareUpdate(data)),
    save: (values) => dispatch(actions.uploadFile(values)),
    setInitDefaultValues: (values) => dispatch(actions.initializeForm(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
