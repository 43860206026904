import { NAME } from './constants';

export const FETCH_GEOCODE = `${NAME}/FETCH_GEOCODE`;
export const FETCH_GEOCODE_ERROR = `${NAME}/FETCH_GEOCODE_ERROR`;
export const FETCH_GEOCODE_SUCCESS = `${NAME}/FETCH_GEOCODE_SUCCESS`;

export const FETCH_GEOCODE_POINT = `${NAME}/FETCH_GEOCODE_POINT`;
export const FETCH_GEOCODE_POINT_FAILURE = `${NAME}/FETCH_GEOCODE_POINT_FAILURE`;
export const FETCH_GEOCODE_POINT_SUCCESS = `${NAME}/FETCH_GEOCODE_POINT_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SET_SHOW_GEOCODE_RESULTS = `${NAME}/SET_SHOW_GEOCODE_RESULTS`;
