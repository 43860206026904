import { setSource } from 'common/api/v2/SetPVSystemDataSource';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { analysisVsGenerationActions } from '../reducer';

import initializeForm from './initializeForm';

export default (proposalId, source, previousValues) => (dispatch) => {
    dispatch(analysisVsGenerationActions[SAVE]());

    setSource(proposalId, source)
        .then((response) => {
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
            dispatch(
                analysisVsGenerationActions[SAVE_SUCCESS](response.data.data),
            );
        })
        .catch((error) => {
            dispatch(
                analysisVsGenerationActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            dispatch(initializeForm(previousValues));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
