import { NAME } from './constants';

export const FETCH_CUSTOM_TEMPLATE = `${NAME}/FETCH_CUSTOM_TEMPLATE`;
export const FETCH_CUSTOM_TEMPLATE_FAILURE = `${NAME}/FETCH_CUSTOM_TEMPLATE_FAILURE`;
export const FETCH_CUSTOM_TEMPLATE_SUCCESS = `${NAME}/FETCH_CUSTOM_TEMPLATE_SUCCESS`;

export const UPDATE_TEMPLATE = `${NAME}/UPDATE_TEMPLATE`;
export const UPDATE_TEMPLATE_FAILURE = `${NAME}/UPDATE_TEMPLATE_FAILURE`;
export const UPDATE_TEMPLATE_SUCCESS = `${NAME}/UPDATE_TEMPLATE_SUCCESS`;

export const FETCH_PDF_LINK = `${NAME}/FETCH_PDF_LINK`;
export const FETCH_PDF_LINK_SUCCESS = `${NAME}/FETCH_PDF_LINK_SUCCESS`;
export const FETCH_PDF_LINK_FAILURE = `${NAME}/FETCH_PDF_LINK_FAILURE`;

export const RESET = `${NAME}/RESET`;
