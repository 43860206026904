import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_CONTACTS,
    FETCH_CONTACTS_FAILURE,
    FETCH_CONTACTS_SUCCESS,
    FETCH_PROJECTS,
    FETCH_PROJECTS_FAILURE,
    FETCH_PROJECTS_SUCCESS,
    FETCH_PROPOSALS,
    FETCH_PROPOSALS_FAILURE,
    FETCH_PROPOSALS_SUCCESS,
    SET_RECENT_SEARCHES,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    contacts: {
        data: { page: 0, results: [], total_pages: 0, total_results: 0 },
        errors: null,
        isFetching: false,
    },
    projects: {
        data: { page: 0, results: [], total_pages: 0, total_results: 0 },
        errors: null,
        isFetching: false,
    },
    proposals: {
        data: { page: 0, results: [], total_pages: 0, total_results: 0 },
        errors: null,
        isFetching: false,
    },
    recentSearches: [],
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_CONTACTS]: (state) => {
            state.contacts = {
                ...state.contacts,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_CONTACTS_FAILURE]: (state, action) => {
            state.productAddedToBranches = {
                ...state.contacts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONTACTS_SUCCESS]: (state, action) => {
            let results = action.payload.results;

            if (action.payload.page > 1) {
                results = [
                    ...state.contacts.data.results,
                    ...action.payload.results,
                ];
            }

            state.contacts = {
                data: {
                    ...action.payload,
                    results,
                },
                errors: null,
                isFetching: false,
            };
        },
        [FETCH_PROJECTS]: (state) => {
            state.projects = {
                ...state.projects,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_PROJECTS_FAILURE]: (state, action) => {
            state.projects = {
                ...state.projects,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROJECTS_SUCCESS]: (state, action) => {
            let results = action.payload.results;

            if (action.payload.page > 1) {
                results = [
                    ...state.projects.data.results,
                    ...action.payload.results,
                ];
            }

            state.projects = {
                data: {
                    ...action.payload,
                    results,
                },
                errors: null,
                isFetching: false,
            };
        },
        [FETCH_PROPOSALS]: (state) => {
            state.proposals = {
                ...state.proposals,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_PROPOSALS_FAILURE]: (state, action) => {
            state.proposals = {
                ...state.proposals,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSALS_SUCCESS]: (state, action) => {
            let results = action.payload.results;

            if (action.payload.page > 1) {
                results = [
                    ...state.proposals.data.results,
                    ...action.payload.results,
                ];
            }

            state.proposals = {
                data: { ...action.payload, results },
                errors: null,
                isFetching: false,
            };
        },
        [SET_RECENT_SEARCHES]: (state, action) => {
            const values = [
                ...state.recentSearches.filter(
                    (item) => !action.payload.includes(item),
                ),
                ...action.payload,
            ];

            const filteredValues = values.filter(
                (value, index, self) => self.indexOf(value) === index,
            );

            state.recentSearches = filteredValues.splice(-5);
            localStorage.setItem('recentSearches', JSON.stringify(values));
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
