import { array, bool, number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        alerts: array().of(
            object().shape({
                alert_type: number().required(REQUIRED_TEXT).nullable(),
                message: string().when('status', {
                    is: true,
                    then: () => string().required(REQUIRED_TEXT).nullable(),
                }),
                status: bool().required(REQUIRED_TEXT).nullable(),
                value: number().when('status', {
                    is: true,
                    then: () => number().required(REQUIRED_TEXT).nullable(),
                }),
            }),
        ),
    });
};
