import { createSlice } from '@reduxjs/toolkit';

import {
    HIDE_ALERT,
    IS_DISABLED,
    RESET_ALERT,
    SHOW_ALERT,
} from './actionTypes';
import { NAME } from './constants';
const INITIAL_STATE = {
    alert: {
        cancelText: '',
        classNameMessages: '',
        confirmText: '',
        defaultTextResponse: '',
        disabled: false,
        haveTextCaptcha: false,
        haveTextResponse: false,
        hideButtons: false,
        messages: [],
        onCancel: null,
        onSuccess: null,
        textToValidate: '',
        title: null,
        type: 'confirm',
        variant: 'warning',
    },
    isOpen: false,
};

const alertsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [HIDE_ALERT]: (state) => {
            state.isOpen = false;
        },
        [IS_DISABLED]: (state, action) => {
            state.alert.disabled = action.payload;
        },
        [RESET_ALERT]: (state) => {
            state.alert = INITIAL_STATE.alert;
        },
        [SHOW_ALERT]: (state, action) => {
            state.alert = { ...state.alert, ...action.payload };
            state.isOpen = true;
        },
    },
});

export const alertsActions = alertsSlice.actions;

export default alertsSlice.reducer;
