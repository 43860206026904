import { createSlice } from '@reduxjs/toolkit';

import {
    INITIALIZE_FORM,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetch: {
        data: [],
        error: null,
        isFetching: false,
    },
    initialValues: {
        confirm_password: '',
        id: null,
        new_password: '',
        old_password: '',
        origin: 0,
    },
    save: {
        data: {},
        errors: null,
        isSaving: false,
    },
};

const managePasswordSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
    },
});

export const managePasswordActions = managePasswordSlice.actions;

export default managePasswordSlice.reducer;
