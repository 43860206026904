import API from '../..';
const ENTITY = 'report-card';
const ENTITY_PAGINATED = 'paginated-reports';

export const create = (data) => API.post(`/api/v1/${ENTITY}/`, data);
export const fetchData = (uuid) => API.get(`/api/v1/${ENTITY}/${uuid}`);
export const fetchPaginatedData = (project_id, page, page_size, year) =>
    API.get(`/api/v1/${ENTITY_PAGINATED}/`, {
        params: { page, page_size, project_id, year },
    });
export const update = (uuid, data) =>
    API.put(`/api/v1/${ENTITY}/${uuid}`, data);
export const deleteItem = (uuid) => API.delete(`/api/v1/${ENTITY}/${uuid}`);
