import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

const FinancingFormatter = ({ row }) => {
    const { t } = useTranslation();
    const { is_financing } = row;
    if (is_financing) {
        return <Typography variant="caption">{t('Financed')}</Typography>;
    }
    return <Typography variant="caption">{t('Cash')}</Typography>;
};

FinancingFormatter.propTypes = {
    row: PropTypes.object,
};

export default FinancingFormatter;
