import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getAlertsModalIsOpen = createSelector(
    getModel,
    (model) => model.alertsModalIsOpen,
);

export const getAlertsModalInitialValues = createSelector(
    getModel,
    (model) => model.alertsModalInitialValues,
);

export const getFetchAlerts = createSelector(
    getModel,
    (model) => model.fetchAlerts,
);

export const getIsFetchingAlerts = createSelector(
    getFetchAlerts,
    (fetchAlerts) => fetchAlerts.isFetching,
);

export const getFetchAlertsData = createSelector(
    getFetchAlerts,
    (fetchAlerts) => fetchAlerts.data,
);
