import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActions, Box, Button, UiDialog as Dialog } from 'sunwise-ui';

import yupResolver from 'common/utils/yupResolver';

import validate from '../validate';

import CredentialForm from './CredentialForm';
import ProvidersList from './ProvidersList';

const AddSourceModal = ({
    disabled,
    initialValues,
    isFetchingProvidersImages,
    isOpen,
    providers,
    providersDictionary,
    providersImages,
    resetForm,
    saveCredential,
    setIsOpen,
}) => {
    const [selectedProvider, setSelectedProvider] = useState(null);
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const provider = watch('provider_id');

    useEffect(() => {
        reset(initialValues);

        if (initialValues?.provider_id)
            setSelectedProvider(initialValues.provider_id);
    }, [initialValues]);

    if (isEmpty(providers)) return null;

    const handleSelectProvider = (provider) => setSelectedProvider(provider);

    const onClose = () => {
        setIsOpen(false);
    };

    const onSubmit = (data) => saveCredential(data);

    const handlePrepareFields = () => {
        setValue('credential', {});
        setValue('provider_id', selectedProvider);
    };

    const handleResetFields = () => {
        setValue('credential', {});
        setValue('provider_id', null);
    };

    const hasProvider = provider !== null;

    const title = hasProvider
        ? providersDictionary[provider]?.name || ''
        : t('Select a provider');

    return (
        <Dialog
            onClose={onClose}
            onExited={() => {
                reset(initialValues);
                resetForm();
                setSelectedProvider(null);
            }}
            open={isOpen}
            size="md"
            title={title}
        >
            {hasProvider ? (
                <CredentialForm
                    control={control}
                    credentialFields={providersDictionary[provider]?.fields}
                    disabled={disabled}
                />
            ) : (
                <ProvidersList
                    disabled={disabled}
                    handleSelectProvider={handleSelectProvider}
                    isFetchingProvidersImages={isFetchingProvidersImages}
                    providers={providers}
                    providersImages={providersImages}
                    selectedProvider={selectedProvider}
                />
            )}

            <BottomActions>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    gap={2}
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    pt={2}
                    width="100%"
                >
                    <Button
                        color="secondary"
                        disabled={disabled}
                        onClick={hasProvider ? handleResetFields : onClose}
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                        type="button"
                        variant="outlined"
                    >
                        {hasProvider ? t('Back') : t('Cancel')}
                    </Button>
                    <Button
                        disabled={disabled || !selectedProvider}
                        onClick={
                            hasProvider
                                ? handleSubmit(onSubmit)
                                : handlePrepareFields
                        }
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                        type="button"
                    >
                        {hasProvider ? t('Save') : t('Next')}
                    </Button>
                </Box>
            </BottomActions>
        </Dialog>
    );
};

AddSourceModal.propTypes = {
    disabled: PropTypes.bool,
    initialValues: PropTypes.object,
    isFetchingProvidersImages: PropTypes.bool,
    isOpen: PropTypes.bool,
    providers: PropTypes.array,
    providersDictionary: PropTypes.object,
    providersImages: PropTypes.object,
    resetForm: PropTypes.func,
    saveCredential: PropTypes.func,
    setIsOpen: PropTypes.func,
};

export default AddSourceModal;
