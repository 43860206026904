import { SOLAR_MODULE_COLOR, SOLAR_MODULE_STROKE_COLOR } from '../constants';

export default (google) => {
    class SolarModulePolygon extends google.maps.Polygon {
        id = null;
        moduleEnable = true;
        onClick = null;
        parentId = null;

        constructor({
            cell = null,
            col = null,
            group = null,
            id,
            map,
            moduleEnable = true,
            onClick,
            parentId = null,
            paths,
            row = null,
            zIndex = 2,
        } = {}) {
            super({
                clickable: false,
                fillColor: SOLAR_MODULE_COLOR,
                fillOpacity: 1,
                map,
                paths,
                strokeColor: SOLAR_MODULE_STROKE_COLOR,
                strokePosition: google.maps.StrokePosition.INSIDE,
                strokeWeight: 1,
                zIndex,
            });
            this.cell = cell;
            this.col = col;
            this.group = group;
            this.handleModuleEnableStatus(moduleEnable);
            this.id = id;
            this.parentId = parentId;
            this.row = row;
            if (onClick) this.onClick = onClick;
        }

        setDisabled(isDisabled = true) {
            if (isDisabled) {
                this.setOptions({
                    fillOpacity: 0,
                    strokeWeight: 0,
                });
            } else {
                this.setOptions({
                    fillOpacity: 1,
                    strokeWeight: 1,
                });
            }
        }

        handleModuleEnableStatus(moduleEnable) {
            this.moduleEnable = moduleEnable;
            this.setOptions({
                fillOpacity: Number(moduleEnable),
                strokeWeight: moduleEnable ? 1 : 0.5,
            });
        }

        setEnableModulesToEdit(isEnabled = false) {
            this.setOptions({
                clickable: isEnabled,
                fillOpacity: Number(this.moduleEnable),
                strokeWeight: 1,
            });

            if (isEnabled)
                google.maps.event.addListener(this, 'click', () => {
                    if (this.onClick) this.onClick(this);
                });
            else google.maps.event.clearListeners(this, 'click');
        }

        setNewZIndex(zIndex) {
            this.setOptions({ zIndex });
        }
    }

    return SolarModulePolygon;
};
