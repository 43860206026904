import { NAME } from './constants';

export const ARCHIVE_OFFER = `${NAME}/ARCHIVE_OFFER`;
export const ARCHIVE_OFFER_FAILURE = `${NAME}/ARCHIVE_OFFER_FAILURE`;
export const ARCHIVE_OFFER_SUCCESS = `${NAME}/ARCHIVE_OFFER_SUCCESS`;

export const CREATE_OFFER = `${NAME}/CREATE_OFFER`;
export const CREATE_OFFER_FAILURE = `${NAME}/CREATE_OFFER_FAILURE`;
export const CREATE_OFFER_SUCCESS = `${NAME}/CREATE_OFFER_SUCCESS`;

export const DUPLICATE_OFFER = `${NAME}/DUPLICATE_OFFER`;
export const DUPLICATE_OFFER_FAILURE = `${NAME}/DUPLICATE_OFFER_FAILURE`;
export const DUPLICATE_OFFER_SUCCESS = `${NAME}/DUPLICATE_OFFER_SUCCESS`;

export const FETCH_CREDIT_DOCUMENTS = `${NAME}/FETCH_CREDIT_DOCUMENTS`;
export const FETCH_CREDIT_DOCUMENTS_FAILURE = `${NAME}/FETCH_CREDIT_DOCUMENTS_FAILURE`;
export const FETCH_CREDIT_DOCUMENTS_SUCCESS = `${NAME}/FETCH_CREDIT_DOCUMENTS_SUCCESS`;

export const FETCH_DIVISIONS = `${NAME}/FETCH_DIVISIONS`;
export const FETCH_DIVISIONS_FAILURE = `${NAME}/FETCH_DIVISIONS_FAILURE`;
export const FETCH_DIVISIONS_SUCCESS = `${NAME}/FETCH_DIVISIONS_SUCCESS`;

export const FETCH_LOCATIONS_ZIP_CODE = `${NAME}/FETCH_LOCATIONS_ZIP_CODE`;
export const FETCH_LOCATIONS_ZIP_CODE_FAILURE = `${NAME}/FETCH_LOCATIONS_ZIP_CODE_FAILURE`;
export const FETCH_LOCATIONS_ZIP_CODE_SUCCESS = `${NAME}/FETCH_LOCATIONS_ZIP_CODE_SUCCESS`;

export const FETCH_POLITICAL_DIVISIONS = `${NAME}/FETCH_POLITICAL_DIVISIONS`;
export const FETCH_POLITICAL_DIVISIONS_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS_FAILURE`;
export const FETCH_POLITICAL_DIVISIONS_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS_SUCCESS`;

export const FETCH_POLITICAL_DIVISIONS2 = `${NAME}/FETCH_POLITICAL_DIVISIONS2`;
export const FETCH_POLITICAL_DIVISIONS2_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS2_SUCCESS`;
export const FETCH_POLITICAL_DIVISIONS2_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS2_FAILURE`;

export const FETCH_COMMERCIAL_OFFERS = `${NAME}/FETCH_COMMERCIAL_OFFERS`;
export const FETCH_COMMERCIAL_OFFERS_FAILURE = `${NAME}/FETCH_COMMERCIAL_OFFERS_FAILURE`;
export const FETCH_COMMERCIAL_OFFERS_SUCCESS = `${NAME}/FETCH_COMMERCIAL_OFFERS_SUCCESS`;

export const FETCH_PLANS = `${NAME}/FETCH_PLANS`;
export const FETCH_PLANS_FAILURE = `${NAME}/FETCH_PLANS_FAILURE`;
export const FETCH_PLANS_SUCCESS = `${NAME}/FETCH_PLANS_SUCCESS`;

export const FETCH_REGIONS = `${NAME}/FETCH_REGIONS`;
export const FETCH_REGIONS_FAILURE = `${NAME}/FETCH_REGIONS_FAILURE`;
export const FETCH_REGIONS_SUCCESS = `${NAME}/FETCH_REGIONS_SUCCESS`;

export const FETCH_VIEWS = `${NAME}/FETCH_VIEWS`;
export const FETCH_VIEWS_FAILURE = `${NAME}/FETCH_VIEWS_FAILURE`;
export const FETCH_VIEWS_SUCCESS = `${NAME}/FETCH_VIEWS_SUCCESS`;

export const INITIALIZE_DUPLICATE_FORM_VALUES = `${NAME}/INITIALIZE_DUPLICATE_FORM_VALUES`;
export const INITIALIZE_VALUES = `${NAME}/INITIALIZE_VALUES`;

export const OPEN_COMMERCIAL_OFFER_MODAL = `${NAME}/OPEN_COMMERCIAL_OFFER_MODAL`;

export const RENAME_OFFER = `${NAME}/RENAME_OFFER`;
export const RENAME_OFFER_FAILURE = `${NAME}/RENAME_OFFER_FAILURE`;
export const RENAME_OFFER_SUCCESS = `${NAME}/RENAME_OFFER_SUCCESS`;

export const RESET = `${NAME}/RESET`;
export const RESET_COMMERCIAL_OFFER_FORM = `${NAME}/RESET_COMMERCIAL_OFFER_FORM`;
export const RESET_DUPLICATE_FORM = `${NAME}/RESET_DUPLICATE_FORM`;
export const RESET_VIEWS = `${NAME}/RESET_VIEWS`;

export const SET_COMMERCIAL_OFFER_FORM_MODE = `${NAME}/SET_COMMERCIAL_OFFER_FORM_MODE`;
export const SET_IS_OPEN_DUPLICATE_MODAL = `${NAME}/SET_IS_OPEN_DUPLICATE_MODAL`;

export const SET_OFFER_AS_FAVORITE = `${NAME}/SET_OFFER_AS_FAVORITE`;
export const SET_OFFER_AS_FAVORITE_FAILURE = `${NAME}/SET_OFFER_AS_FAVORITE_FAILURE`;
export const SET_OFFER_AS_FAVORITE_SUCCESS = `${NAME}/SET_OFFER_AS_FAVORITE_SUCCESS`;

export const UPDATE_CREDIT_APPLICATION_STATUS = `${NAME}/UPDATE_CREDIT_APPLICATION_STATUS`;
export const UPDATE_CREDIT_APPLICATION_STATUS_FAILURE = `${NAME}/UPDATE_CREDIT_APPLICATION_STATUS_FAILURE`;
export const UPDATE_CREDIT_APPLICATION_STATUS_SUCCESS = `${NAME}/UPDATE_CREDIT_APPLICATION_STATUS_SUCCESS`;

export const UPLOAD_DOCUMENT = `${NAME}/UPLOAD_DOCUMENT`;
export const UPLOAD_DOCUMENT_FAILURE = `${NAME}/UPLOAD_DOCUMENT_FAILURE`;
export const UPLOAD_DOCUMENT_SUCCESS = `${NAME}/UPLOAD_DOCUMENT_SUCCESS`;
