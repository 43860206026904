import { NAME } from './constants';

export const DELETE_DATA = `${NAME}/DELETE_DATA`;
export const DELETE_DATA_FAILURE = `${NAME}/DELETE_DATA_FAILURE`;
export const DELETE_DATA_SUCCESS = `${NAME}/DELETE_DATA_SUCCESS`;

export const EDIT_ITEM = `${NAME}/EDIT_ITEM`;

export const FETCH_DATA = `${NAME}/FETCH_DATA`;
export const FETCH_DATA_FAILURE = `${NAME}/FETCH_DATA_FAILURE`;
export const FETCH_DATA_SUCCESS = `${NAME}/FETCH_DATA_SUCCESS`;
export const FETCH_INITIAL_DATA = `${NAME}/FETCH_INITIAL_DATA`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_DATA = `${NAME}/SAVE_DATA`;
export const SAVE_DATA_FAILURE = `${NAME}/SAVE_DATA_FAILURE`;
export const SAVE_DATA_SUCCESS = `${NAME}/SAVE_DATA_SUCCESS`;

export const SET_MODAL_FORM_OPEN_STATUS = `${NAME}/SET_MODAL_FORM_OPEN_STATUS`;
