import { createSlice } from '@reduxjs/toolkit';

import {
    ACTIVATE_ITEM,
    ACTIVATE_ITEM_FAILURE,
    ACTIVATE_ITEM_SUCCESS,
    CHANGE_SEARCH_TEXT,
    CLOSE_REASSIGN_MODAL,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    FETCH_ROLES,
    FETCH_ROLES_FAILURE,
    FETCH_ROLES_SUCCESS,
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
    FETCH_REASSIGN_CONTACTS,
    FETCH_REASSIGN_CONTACTS_FAILURE,
    FETCH_REASSIGN_CONTACTS_SUCCESS,
    INITIALIZE_FORM,
    INVALID_PASSWORD,
    INVALID_PASSWORD_CONFIRMATION,
    INVALID_PASSWORD_CONFIRMATION_LENGTH,
    OPEN_MODAL_PASSWORD,
    OPEN_MODAL_PRODUCT,
    OPEN_REASSIGN_TYPE_DEACTIVATE_MODAL,
    OPEN_REASSIGN_TYPE_UPDATE_MODAL,
    RESET,
    RESET_FORM,
    RESET_REASSIGN_FORM,
    RESTART_PASSWORD,
    RESTART_PASSWORD_FAILURE,
    RESTART_PASSWORD_SUCCESS,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_IS_NEW_RECORD,
    VALID_PASSWORD,
    VALID_PASSWORD_CONFIRMATION,
    VALID_PASSWORD_CONFIRMATION_LENGTH,
} from './actionTypes';
import {
    NAME,
    REASSIGN_BY_DEACTIVATE_TYPE,
    REASSIGN_BY_UPDATE_TYPE,
} from './constants';

const INITIAL_STATE = {
    initialValues: {
        birth_date: '',
        branch_office: '',
        branch_offices: '',
        company_group: '',
        confirm_password: '',
        email: '',
        first_name: '',
        has_all_branchoffices: false,
        has_limited_contacts: false,
        id: null,
        is_enabled: true,
        last_name: '',
        password: '',
        phone: '',
        position: '',
        role_level: '',
        second_surname: '',
        user_branch_offices: {},
    },
    reassignType: 0,
    reassignInitialValues: {
        userId: '',
        userName: '',
        reassignBranches: [],
        dataToSave: null,
    },
    branchAgentsDictionary: {},
    fetchReassignContacts: {
        isFetching: false,
        errors: null,
    },
    saveItem: {
        isSaving: false,
        data: {},
        errors: null,
    },
    deleteItem: {
        isDeleting: false,
        data: [],
        errors: null,
    },
    fetchItems: {
        isFetching: false,
        data: [],
        errors: null,
    },
    fetchRoles: {
        isFetching: false,
        data: [],
        errors: null,
    },
    activateItem: {
        isFetching: false,
        data: [],
        errors: null,
    },
    isOpenModalProduct: false,
    isOpenModalPassword: false,
    isNewRecord: true,
    searchText: '',
    validPassword: false,
    validPasswordConfirmation: false,
    validPasswordConfirmationLength: false,
};

const companyUsersSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ACTIVATE_ITEM]: (state) => {
            state.activateItem = {
                ...state.activateItem,
                errors: [],
                isFetching: true,
            };
        },
        [ACTIVATE_ITEM_FAILURE]: (state, action) => {
            state.activateItem = {
                ...state.activateItem,
                errors: action.payload,
                isFetching: false,
            };
        },
        [ACTIVATE_ITEM_SUCCESS]: (state, action) => {
            state.activateItem = {
                ...state.activateItem,
                data: action.payload,
                isFetching: false,
            };
        },
        [CHANGE_SEARCH_TEXT]: (state, action) => {
            state.searchText = action.payload;
        },
        [CLOSE_REASSIGN_MODAL]: (state) => {
            state.reassignType = 0;
        },
        [DELETE_USER]: (state) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_USER_FAILURE]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_USER_SUCCESS]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                data: action.payload,
                isDeleting: false,
            };
        },
        [FETCH_ROLES]: (state) => {
            state.fetchRoles = {
                ...state.fetchRoles,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ROLES_FAILURE]: (state, action) => {
            state.fetchRoles = {
                ...state.fetchRoles,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ROLES_SUCCESS]: (state, action) => {
            state.fetchRoles = {
                ...state.fetchRoles,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS]: (state) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ITEMS_FAILURE]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_SUCCESS]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REASSIGN_CONTACTS]: (state) => {
            state.fetchReassignContacts = {
                ...state.fetchReassignContacts,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_REASSIGN_CONTACTS_FAILURE]: (state, action) => {
            state.fetchReassignContacts = {
                ...state.fetchReassignContacts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REASSIGN_CONTACTS_SUCCESS]: (state) => {
            state.fetchReassignContacts.isFetching = false;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [OPEN_MODAL_PASSWORD]: (state, action) => {
            state.isOpenModalPassword = action.payload;
        },
        [OPEN_MODAL_PRODUCT]: (state, action) => {
            state.isOpenModalProduct = action.payload;
        },
        [OPEN_REASSIGN_TYPE_DEACTIVATE_MODAL]: (state, action) => {
            state.branchAgentsDictionary =
                action.payload.branchAgentsDictionary;
            state.reassignInitialValues = {
                ...state.reassignInitialValues,
                dataToSave: action.payload.dataToSave,
                reassignBranches: action.payload.reassignBranches,
                userId: action.payload.userId,
                userName: action.payload.userName,
            };
            state.reassignType = REASSIGN_BY_DEACTIVATE_TYPE;
            state.activateItem.isFetching = false;
        },
        [OPEN_REASSIGN_TYPE_UPDATE_MODAL]: (state, action) => {
            state.branchAgentsDictionary =
                action.payload.branchAgentsDictionary;
            state.reassignInitialValues = {
                ...state.reassignInitialValues,
                userId: action.payload.userId,
                userName: action.payload.userName,
                dataToSave: action.payload.dataToSave,
                reassignBranches: action.payload.reassignBranches,
            };
            state.reassignType = REASSIGN_BY_UPDATE_TYPE;
            state.saveItem.isSaving = false;
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [RESET_REASSIGN_FORM]: (state) => {
            state.auditContactResult = INITIAL_STATE.auditContactResult;
            state.branchAgentsDictionary = INITIAL_STATE.branchAgentsDictionary;
            state.reassignInitialValues = INITIAL_STATE.reassignInitialValues;
        },
        [RESTART_PASSWORD]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [RESTART_PASSWORD_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [RESTART_PASSWORD_SUCCESS]: (state) => {
            state.saveItem.isSaving = false;
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [VALID_PASSWORD]: (state) => {
            state.validPassword = true;
        },
        [INVALID_PASSWORD]: (state) => {
            state.validPassword = false;
        },
        [VALID_PASSWORD_CONFIRMATION]: (state) => {
            state.validPasswordConfirmation = true;
        },
        [INVALID_PASSWORD_CONFIRMATION]: (state) => {
            state.validPasswordConfirmation = false;
        },
        [VALID_PASSWORD_CONFIRMATION_LENGTH]: (state) => {
            state.validPasswordConfirmationLength = true;
        },
        [INVALID_PASSWORD_CONFIRMATION_LENGTH]: (state) => {
            state.validPasswordConfirmationLength = false;
        },
    },
});

export const companyUsersActions = companyUsersSlice.actions;

export default companyUsersSlice.reducer;
