import React, { useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getHasOnboarding } from 'common/utils/helpers/session';

import * as multiCurrencyActions from '../multiCurrency/actions';
import * as multiCurrencySelectors from '../multiCurrency/selectors';
import * as profileSelectors from '../profile/selectors';

import * as actions from './actions';
import StepperForm from './components/StepperForm';
import * as selectors from './selectors';

const Card = ({
    currencies,
    currentStep,
    fetchBatteryBrands,
    fetchOnboarding,
    fetchCurrencies,
    fetchInverterBrands,
    fetchMemberRoles,
    fetchPanelBrands,
    isFetchingOnboarding,
    isFetchingUserSettings,
    isOpenModal,
    redirect,
    subscription,
    userSettings,
}) => {
    const counters = get(subscription, 'counters', {});
    const subscriptionType = get(subscription, 'type.key', null);
    const hasOnboarding = getHasOnboarding();

    useEffect(() => {
        fetchOnboarding(true);
        fetchCurrencies();
        fetchMemberRoles();
        fetchBatteryBrands();
        fetchInverterBrands();
        fetchPanelBrands();
    }, []);

    useEffect(() => {
        if (userSettings && !hasOnboarding) redirect('/');
    }, [userSettings]);

    return (
        <Dialog
            fullWidth
            maxWidth={currentStep === 3 ? 'lg' : 'md'}
            open={isOpenModal}
        >
            <StepperForm
                counters={counters}
                currencies={currencies}
                isLoading={isFetchingUserSettings || isFetchingOnboarding}
                subscriptionType={subscriptionType}
                user={userSettings}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    currencies: multiCurrencySelectors.getDataFetchCurrencies,
    currentStep: selectors.getCurentStep,
    isFetchingOnboarding: selectors.getIsFetchingOnboarding,
    isFetchingUserSettings: profileSelectors.getIsFetching,
    isOpenModal: selectors.getIsOpenModal,
    subscription: profileSelectors.getSubscription,
    userSettings: profileSelectors.getDataFetch,
});

const mapDispatchToProps = (dispatch) => ({
    fetchBatteryBrands: () => dispatch(actions.fetchBatteryBrands()),
    fetchCurrencies: () => dispatch(multiCurrencyActions.filterItems()),
    fetchInverterBrands: () => dispatch(actions.fetchInverterBrands()),
    fetchMemberRoles: () => dispatch(actions.fetchMemberRoles()),
    fetchOnboarding: (updateStep) =>
        dispatch(actions.fetchOnboarding(updateStep)),
    fetchPanelBrands: () => dispatch(actions.fetchPanelBrands()),
    redirect: (link) => dispatch(push(link)),
});

Card.propTypes = {
    currencies: PropTypes.array,
    currentStep: PropTypes.number,
    fetchBatteryBrands: PropTypes.func,
    fetchOnboarding: PropTypes.func,
    fetchCurrencies: PropTypes.func,
    fetchInverterBrands: PropTypes.func,
    fetchMemberRoles: PropTypes.func,
    fetchPanelBrands: PropTypes.func,
    isFetchingOnboarding: PropTypes.bool,
    isFetchingUserSettings: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    redirect: PropTypes.func,
    subscription: PropTypes.object,
    userSettings: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Card);
