import { SET_MODAL_IS_CREATE } from '../actionTypes';
import { parseBodyObjectToFormValues } from '../helpers';
import { ratesActions } from '../reducer';

import initializeRateModalForm from './initializeRateModalForm';
import setHasActiveProjects from './setHasActiveProjects';
import setModalIsOpen from './setModalIsOpen';

export default (rateData) => (dispatch) => {
    dispatch(ratesActions[SET_MODAL_IS_CREATE](false));
    dispatch(setHasActiveProjects(rateData?.has_active_projects));
    dispatch(initializeRateModalForm(parseBodyObjectToFormValues(rateData)));
    dispatch(setModalIsOpen(true));
};
