import { NAME } from './constants';

export const DELETE_ERRORS = `${NAME}/DELETE_ERRORS`;

export const INVALID_PASSWORD = `${NAME}/INVALID_PASSWORD`;
export const INVALID_PASSWORD_CONFIRMATION = `${NAME}/INVALID_PASSWORD_CONFIRMATION`;
export const INVALID_PASSWORD_CONFIRMATION_LENGTH = `${NAME}/INVALID_PASSWORD_CONFIRMATION_LENGTH`;

export const RESET_PASSWORD = `${NAME}/RESET_PASSWORD`;
export const RESET_PASSWORD_FAILURE = `${NAME}/RESET_PASSWORD_FAILURE`;
export const RESET_PASSWORD_SUCCESS = `${NAME}/RESET_PASSWORD_SUCCESS`;

export const SET_UUID_TOKEN = `${NAME}/SET_UUID_TOKEN`;

export const VALID_PASSWORD = `${NAME}/VALID_PASSWORD`;
export const VALID_PASSWORD_CONFIRMATION = `${NAME}/VALID_PASSWORD_CONFIRMATION`;
export const VALID_PASSWORD_CONFIRMATION_LENGTH = `${NAME}/VALID_PASSWORD_CONFIRMATION_LENGTH`;
