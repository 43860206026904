import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SAVE_ITEM, SAVE_ITEM_FAILURE } from '../actionTypes';
import { companyUsersActions } from '../reducer';

import reassignContacts from './reassignContacts';
import updateUser from './updateUser';

export default (userId, reassignBranches, dataToSave) => (dispatch) => {
    dispatch(companyUsersActions[SAVE_ITEM]());
    dispatch(reassignContacts(userId, reassignBranches, dataToSave))
        .then(({ userId, dataToSave }) => {
            dispatch(updateUser(dataToSave, userId, true));
        })
        .catch((error) => {
            dispatch(
                companyUsersActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
