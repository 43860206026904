import { updateTemplate } from 'common/api/v1/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SET_PAGE_SIZE } from '../actionTypes';
import { editTemplateViewActions } from '../reducer';
import { getDataFetchCustomTemplate } from '../selectors';

export default (updatedData) => (dispatch, getState) => {
    dispatch(editTemplateViewActions[SET_PAGE_SIZE](updatedData.page_size));

    const state = getState();
    const templatePrevData = getDataFetchCustomTemplate(state);

    const values = {
        page_size: updatedData.page_size,
        pages: updatedData.pages_template.map((page) => {
            const prevDataPage = templatePrevData.pages_template.find(
                (prevPage) => prevPage.id === page.id,
            );
            return {
                ...prevDataPage,
                content: JSON.parse(page.content),
                id: page.id,
                page: page.page,
            };
        }),
    };

    updateTemplate(updatedData.id, values)
        .then()
        .catch((error) => {
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
