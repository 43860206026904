import React from 'react';

import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const UserFormatter = ({ row }) => {
    const { agent } = row;

    if (isNull(row?.agent)) return '---';

    return (
        <Typography variant="caption">{`${get(agent, 'first_name', '')} ${get(
            agent,
            'last_name',
            '',
        )}`}</Typography>
    );
};

UserFormatter.propTypes = {
    row: PropTypes.object,
};

export default UserFormatter;
