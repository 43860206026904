import { NAME } from './constants';

export const FETCH_OFFER_PRODUCTS = `${NAME}/FETCH_OFFER_PRODUCTS`;
export const FETCH_OFFER_PRODUCTS_FAILURE = `${NAME}/FETCH_OFFER_PRODUCTS_FAILURE`;
export const FETCH_OFFER_PRODUCTS_SUCCESS = `${NAME}/FETCH_OFFER_PRODUCTS_SUCCESS`;

export const FETCH_PROPOSAL_CATALOGS = `${NAME}/FETCH_PROPOSAL_CATALOGS`;
export const FETCH_PROPOSAL_CATALOGS_FAILURE = `${NAME}/FETCH_PROPOSAL_CATALOGS_FAILURE`;
export const FETCH_PROPOSAL_CATALOGS_SUCCESS = `${NAME}/FETCH_PROPOSAL_CATALOGS_SUCCESS`;

export const FETCH_TEMPLATES = `${NAME}/FETCH_TEMPLATES`;
export const FETCH_TEMPLATES_FAILURE = `${NAME}/FETCH_TEMPLATES_FAILURE`;
export const FETCH_TEMPLATES_SUCCESS = `${NAME}/FETCH_TEMPLATES_SUCCESS`;

export const RESET_DATA = `${NAME}/RESET_DATA`;

export const SET_PRODUCTS_TOTALS = `${NAME}/SET_PRODUCTS_TOTALS`;
