import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

export default styled(Box)`
    display: flex;
    flex-direction: column;
    overflow-y: overlay;
    width: 100%;

    ${scroll.custom()}

    &.disabled {
        opacity: 0.6;
        pointer-events: none;
    }
`;
