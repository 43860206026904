import { getItems } from 'common/api/v2/offerComplements';

import {
    FETCH_OFFER_COMPLEMENTS,
    FETCH_OFFER_COMPLEMENTS_FAILURE,
    FETCH_OFFER_COMPLEMENTS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorConfigModalActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(
            proposalGeneratorConfigModalActions[FETCH_OFFER_COMPLEMENTS](),
        );

        getItems(id)
            .then((response) => {
                dispatch(
                    proposalGeneratorConfigModalActions[
                        FETCH_OFFER_COMPLEMENTS_SUCCESS
                    ](response.data.data),
                );
            })
            .catch((error) =>
                dispatch(
                    proposalGeneratorConfigModalActions[
                        FETCH_OFFER_COMPLEMENTS_FAILURE
                    ](error?.response?.data?.errors),
                ),
            )
            .finally(resolve);
    });
