import { setReportEnd } from 'common/api/v1/aftersalesEnd';

import {
    SET_REPORT_END,
    SET_REPORT_END_FAILURE,
    SET_REPORT_END_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import redirectToReportReview from './redirectToReportReview';

export default (uuid, redirect = true, callback) =>
    (dispatch) => {
        dispatch(actions[SET_REPORT_END]());
        setReportEnd(uuid)
            .then((response) => {
                dispatch(actions[SET_REPORT_END_SUCCESS](response?.data?.data));
                if (redirect) dispatch(redirectToReportReview(uuid));
                if (callback) callback();
            })
            .catch((error) => dispatch(actions[SET_REPORT_END_FAILURE](error)));
    };
