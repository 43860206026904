import React, { useContext, useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    Grid,
    Tab,
    TabPanel,
    Tabs,
    Typography,
} from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import { GeneralContext } from 'common/utils/contexts';
import { numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import info1 from 'resources/info_1.png';
import info2 from 'resources/info_2.png';
import info3 from 'resources/info_3.png';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validateSegment';

import InfoPopover from './InfoPopover';
import MaxPanelLabel from './MaxPanelLabel';
import PanelsOrKwInput from './PanelsOrKwInput';

const initInitialValues = ({ initialValues, offerPanels }) => {
    const { solar_module, solar_modules_number } = initialValues;
    const selectedModule = offerPanels.find((item) => item.id === solar_module);
    let kW = 0;

    if (selectedModule) {
        const { watts = 0 } = selectedModule;
        kW = (solar_modules_number * watts) / 1000;
    }

    return { ...initialValues, kW };
};

const SegmentEditForm = ({
    commercialOfferId,
    initialValues,
    mapRef,
    offerPanels,
    onCloseDrawer,
    resetForm,
    segments,
    updateSegment,
}) => {
    const { t } = useTranslation();
    const { google } = useContext(GeneralContext);
    const [selectedTab, setSelectedTab] = useState('rooftop');
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const _initialValues = initInitialValues({ initialValues, offerPanels });
    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: _initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => {
        const _initialValues = initInitialValues({
            initialValues,
            offerPanels,
        });
        reset(_initialValues);
    }, [initialValues]);

    const formValues = getValues();

    const panelsToSelect = offerPanels.map((item) => ({
        label: item.name,
        value: item.id,
    }));

    const handleOnChangeTab = (_, newValue) => setSelectedTab(newValue);

    const handleOnSave = (values) =>
        updateSegment({
            callback: () => {
                onCloseDrawer();
                resetForm();
            },
            commercialOfferId,
            google,
            mapValue: mapRef.current.self(),
            values,
        });

    return (
        <Box>
            <Box>
                <Typography variant="caption">
                    {t('Total area')}{' '}
                    {numberFormat(formValues?.total_area, {
                        decimals: 2,
                        locale: countryCurrencyLocale,
                        style: 'decimal',
                    })}{' '}
                    m<sup>2</sup>
                </Typography>
            </Box>

            <MaxPanelLabel
                control={control}
                formValues={formValues}
                map={mapRef.current.self()}
                offerPanels={offerPanels}
                segments={segments}
            />

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    centered
                    onChange={handleOnChangeTab}
                    scrollButtons="auto"
                    value={selectedTab}
                    variant="fullWidth"
                >
                    <Tab
                        id="tab-rooftop"
                        key="tab-rooftop"
                        label={t('Rooftop')}
                        value="rooftop"
                    />

                    <Tab
                        id="tab-module"
                        key="tab-module"
                        label={t('Module')}
                        value="module"
                    />

                    <Tab
                        id="tab-groups"
                        key="tab-groups"
                        label={t('Groups')}
                        value="groups"
                    />
                </Tabs>
            </Box>

            <form onSubmit={handleSubmit(handleOnSave)}>
                <TabPanel
                    key="tab-rooftop"
                    selectedTab={selectedTab}
                    value="rooftop"
                >
                    <ReactHookFormInput
                        control={control}
                        fullWidth
                        label={t('Name')}
                        name="name"
                    />

                    <Grid container>
                        <Grid item xs>
                            <ReactHookFormIntlNumberInput
                                append="m"
                                control={control}
                                fullWidth
                                label={t('Height')}
                                name="height"
                            />
                        </Grid>

                        <Grid item xs>
                            <ReactHookFormIntlNumberInput
                                append="°"
                                control={control}
                                fullWidth
                                label={t('Tilt')}
                                max="90"
                                min="0"
                                name="tilt"
                                placeholder={t('Tilt')}
                            />
                        </Grid>
                    </Grid>

                    <ReactHookFormIntlNumberInput
                        append="m"
                        control={control}
                        fullWidth
                        label={t('Set back')}
                        name="safe_zone"
                    />
                </TabPanel>

                <TabPanel
                    key="tab-module"
                    selectedTab={selectedTab}
                    value="module"
                >
                    <ReactHookFormSelect2
                        control={control}
                        isClearable={false}
                        label={t('Model')}
                        name="solar_module"
                        options={[{ label: '', options: panelsToSelect }]}
                    />

                    <PanelsOrKwInput
                        control={control}
                        getValues={getValues}
                        offerPanels={offerPanels}
                        setValue={setValue}
                    />

                    <ReactHookFormIntlNumberInput
                        append="°"
                        control={control}
                        fullWidth
                        label={t('Azimuth')}
                        max="360"
                        min="0"
                        name="azimuth"
                        placeholder={t('Azimuth')}
                    />

                    <ReactHookFormSelect
                        control={control}
                        fullWidth
                        label={t('Orientation')}
                        name="orientation"
                        options={[
                            {
                                label: t('Vertical'),
                                value: 'P',
                            },
                            {
                                label: t('Horizontal'),
                                value: 'L',
                            },
                        ]}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-groups"
                    selectedTab={selectedTab}
                    value="groups"
                >
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <Typography variant="caption" fontWeight="bold">
                            {t('Panel group dimension')}
                        </Typography>

                        <InfoPopover title={t('Group size')} image={info2} />
                    </Box>

                    <Grid container mt={1}>
                        <Grid item md={9} xs={18}>
                            <ReactHookFormIntlNumberInput
                                control={control}
                                fullWidth
                                label={t('Row', { count: 2 })}
                                name="frame_rows_number"
                            />
                        </Grid>

                        <Grid item xs={18} md={9}>
                            <ReactHookFormIntlNumberInput
                                control={control}
                                fullWidth
                                label={t('Column', { count: 2 })}
                                name="frame_cols_number"
                            />
                        </Grid>
                    </Grid>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ m: 0, p: 0 }}
                        >
                            <Typography fontWeight="bold" variant="caption">
                                {t('Additional settings')}
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails sx={{ m: 0, p: 0 }}>
                            <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                <Typography fontWeight="bold" variant="caption">
                                    {t('Distance between groups')}
                                </Typography>

                                <InfoPopover
                                    image={info3}
                                    title={t('Distance between groups')}
                                />
                            </Box>

                            <Grid container mt={1}>
                                <Grid item md={9} xs={18}>
                                    <ReactHookFormIntlNumberInput
                                        append="m"
                                        control={control}
                                        fullWidth
                                        label={t('Vertical')}
                                        name="frame_row_spacing"
                                    />
                                </Grid>

                                <Grid item md={9} xs={18}>
                                    <ReactHookFormIntlNumberInput
                                        append="m"
                                        control={control}
                                        fullWidth
                                        label={t('Horizontal')}
                                        name="frame_col_spacing"
                                    />
                                </Grid>
                            </Grid>

                            <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                <Typography fontWeight="bold" variant="caption">
                                    {t('Distance between panels')}
                                </Typography>

                                <InfoPopover
                                    image={info1}
                                    title={t('Distance between panels')}
                                />
                            </Box>

                            <Divider />

                            <Grid container mt={1}>
                                <Grid item md={9} xs={18}>
                                    <ReactHookFormIntlNumberInput
                                        append="m"
                                        control={control}
                                        fullWidth
                                        label={t('Vertical')}
                                        name="row_spacing"
                                    />
                                </Grid>

                                <Grid item md={9} xs={18}>
                                    <ReactHookFormIntlNumberInput
                                        append="m"
                                        control={control}
                                        fullWidth
                                        label={t('Horizontal')}
                                        name="col_spacing"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </TabPanel>

                <Button
                    fullWidth
                    sx={{ '&.MuiButton-root': { mb: 0 } }}
                    type="submit"
                >
                    {t('Save')}
                </Button>

                <Button
                    fullWidth
                    onClick={() => resetForm()}
                    sx={{ '&.MuiButton-root': { mb: 0 } }}
                    variant="text"
                >
                    {t('Cancel')}
                </Button>
            </form>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    segments: selectors.getSegmentsData,
});

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => dispatch(actions.resetForm()),
    updateSegment: (config) => dispatch(actions.updateSegment(config)),
});

SegmentEditForm.propTypes = {
    commercialOfferId: PropTypes.string,
    initialValues: PropTypes.object,
    mapRef: PropTypes.object,
    offerPanels: PropTypes.array,
    onCloseDrawer: PropTypes.func,
    resetForm: PropTypes.func,
    segments: PropTypes.array,
    updateSegment: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentEditForm);
