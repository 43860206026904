import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Grid, ToggleButtonGroup, ToggleButton } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { GENERATION_SOURCES } from 'common/constants';
import { useBreakpoint } from 'common/hooks';

const ManualGeneration = ({
    averageIrradiation,
    canModifySolarGeneration,
    control,
    handleOnChange,
    handleSubmit,
    hasFile,
    initialValues,
    isLocked,
    setValue,
    sunHours,
    sunHoursWithFile,
}) => {
    const [sunHoursMode, setSunHoursMode] = useState('sunHoursValue');
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const preFlight = (newValue, previousValue, payload) => {
        if (parseFloat(newValue) === parseFloat(previousValue)) return;
        handleOnChange(payload);
    };

    const hasSunHoursOutOfRange = sunHoursWithFile < 2 || sunHoursWithFile > 8;
    const isGenerationFromNASA =
        initialValues?.pvSystemDataSource === GENERATION_SOURCES.NASA;

    return (
        <Box px={2}>
            <TitleWithDetail variant="body2">
                {`${t('Generation')} - ${t('Manual').toLowerCase()}`}
            </TitleWithDetail>

            {hasFile && hasSunHoursOutOfRange && (
                <Alert severity="warning">
                    <small>
                        {t(
                            'Make sure that the model and quantity of solar panels in your proposal is the same as in the loaded generation simulation',
                        )}
                    </small>
                </Alert>
            )}

            {hasFile && !hasSunHoursOutOfRange && (
                <Alert severity="info">
                    <small>{t('Make sure the system size is correct')}</small>
                </Alert>
            )}

            <Grid container mt={1}>
                <Grid item xs={18} md={9} lg={6}>
                    {sunHoursMode === 'sunHoursValue' && (
                        <ReactHookFormIntlNumberInput
                            control={control}
                            disabled={
                                !canModifySolarGeneration ||
                                !isGenerationFromNASA ||
                                isLocked
                            }
                            fullWidth
                            label={t('Sun Hours')}
                            name="sunHoursValue"
                            onChange={({ target: { value } }) =>
                                preFlight(value, initialValues?.sunHoursValue, {
                                    base: sunHours,
                                    setValue,
                                    field: 'sunHoursValue',
                                    value: parseFloat(value),
                                    handleSubmit,
                                })
                            }
                        />
                    )}

                    {sunHoursMode === 'kwhValue' && (
                        <ReactHookFormIntlNumberInput
                            control={control}
                            disabled={
                                !canModifySolarGeneration ||
                                !isGenerationFromNASA ||
                                isLocked
                            }
                            fullWidth
                            label="kWh/kw"
                            name="kwhValue"
                            onChange={({ target: { value } }) =>
                                preFlight(value, initialValues?.kwhValue, {
                                    base: averageIrradiation,
                                    setValue,
                                    field: 'kwhValue',
                                    value: parseFloat(value),
                                    handleSubmit,
                                })
                            }
                        />
                    )}
                </Grid>
                <Grid item xs={18} md={9} lg={6} sx={{ pt: { xs: 1, md: 0 } }}>
                    <ToggleButtonGroup
                        color="secondary"
                        disabled={!canModifySolarGeneration || isLocked}
                        fullWidth
                        exclusive
                        onChange={(e) => setSunHoursMode(e.target.value)}
                        orientation={
                            ['xs', 'sm'].includes(breakpoint)
                                ? 'vertical'
                                : 'horizontal'
                        }
                        size="small"
                        value={sunHoursMode}
                    >
                        {[
                            {
                                label: t('Sun Hours'),
                                value: 'sunHoursValue',
                            },
                            {
                                label: 'kWh/kw',
                                value: 'kwhValue',
                            },
                        ].map((item) => (
                            <ToggleButton
                                key={`toggle-buttons-${item.value}`}
                                value={item.value}
                                sx={{
                                    p: '7px 16px',
                                }}
                            >
                                {item.label}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </Box>
    );
};

ManualGeneration.propTypes = {
    averageIrradiation: PropTypes.number,
    canModifySolarGeneration: PropTypes.bool,
    control: PropTypes.object,
    handleOnChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    hasFile: PropTypes.bool,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    kwhValue: PropTypes.number,
    setValue: PropTypes.func,
    sunHours: PropTypes.number,
    sunHoursWithFile: PropTypes.number,
};

export default ManualGeneration;
