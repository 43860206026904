import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import SwipeableDrawer from 'common/components/SwipeableDrawer';

import SidebarIndicators from './indicators/SidebarIndicators';
import Sidebar from './Sidebar';

const SupportDrawer = ({ isGenerated, reportComplements, reportId }) => {
    const swipeableDrawerRef = useRef(null);

    return (
        <SwipeableDrawer ref={swipeableDrawerRef}>
            <>
                <SidebarIndicators reportComplements={reportComplements} />

                <Sidebar
                    isGenerated={isGenerated}
                    reportId={reportId}
                    reverseBtn
                />
            </>
        </SwipeableDrawer>
    );
};

SupportDrawer.propTypes = {
    isGenerated: PropTypes.bool,
    reportComplements: PropTypes.object,
    reportId: PropTypes.string,
};

export default SupportDrawer;
