import React, { useState, useEffect } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { Card, Grid, Collapse } from 'sunwise-ui';

import TextVariant from 'common/components/TextVariant';
import PlaceholderLoadingView from 'common/modules/loadingView/PlaceholderLoadingView';

const ResumeData = ({
    children,
    dot,
    icon,
    isCollapsable = true,
    isLoading,
    isOpen = false,
    title,
}) => {
    const [open, setIsOpen] = useState(isOpen);

    const handleClickToggle = (value) => setIsOpen(value);

    useEffect(() => {
        setIsOpen(isOpen);
    }, [isOpen]);

    const collapsable = isCollapsable ? (
        open ? (
            <ExpandLessIcon />
        ) : (
            <ExpandMoreIcon />
        )
    ) : null;

    return (
        <Card sx={{ position: 'relative' }}>
            {isLoading && <PlaceholderLoadingView />}
            <Card.Header>
                <Grid
                    alignItems="center"
                    container
                    onClick={() =>
                        isCollapsable && !isLoading
                            ? handleClickToggle(!open)
                            : null
                    }
                >
                    <Grid item xs>
                        <TextVariant
                            dot={dot}
                            icon={icon}
                            text={title}
                            variant="bold"
                        />
                    </Grid>

                    <Grid item sx={{ ml: 'auto' }}>
                        {collapsable}
                    </Grid>
                </Grid>
            </Card.Header>

            <Collapse in={open}>
                <Card.Body>{children}</Card.Body>
            </Collapse>
        </Card>
    );
};

ResumeData.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    dot: PropTypes.string,
    icon: PropTypes.string,
    isCollapsable: PropTypes.bool,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    title: PropTypes.string,
};

export default ResumeData;
