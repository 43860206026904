import { createSlice } from '@reduxjs/toolkit';

import {
    INITIAL_VALUES,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: {
        energy_saving: 0,
        id: null,
        panel: null,
        quantity: 0,
        system_size: 0,
        tempQuantity: 0,
        watts: 0,
    },
    save: {
        data: [],
        errors: [],
        isSaving: false,
    },
};

const energySavingSlice = createSlice({
    initialState: INITIAL_STATE,
    name: NAME,
    reducers: {
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
    },
});

export const energySavingActions = energySavingSlice.actions;

export default energySavingSlice.reducer;
