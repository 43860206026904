import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        brand: string().nullable(),
        name: string().required(REQUIRED_TEXT),
        type: string().nullable(),
        url: string().required(REQUIRED_TEXT),
    });
};
