import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';
import { SUPPORTED_LANGUAGES } from 'common/constants/multiregion';

const BenefitsCards = ({ selectedTheme, show }) => {
    const { i18n, t } = useTranslation();
    const isMx = i18n.language === SUPPORTED_LANGUAGES.es;
    const sxExpandMoreIcon = {
        color: selectedTheme === 'dark' ? '#000' : '',
    };
    const sxTypography = {
        color: selectedTheme === 'dark' ? '#000' : '#ffffff',
    };

    if (!show) return null;

    return (
        <Grid container mt={3}>
            <Grid item xs={18}>
                <AccordionCard
                    customTitle={
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            sx={sxTypography}
                        >
                            {t('Additional projects')}
                        </Typography>
                    }
                    sxAccordionSummary={{
                        backgroundColor: `secondary.main`,
                        py: 1,
                        '&.Mui-expanded': {
                            minHeight: '64px',
                        },
                    }}
                    sxExpandMoreIcon={sxExpandMoreIcon}
                >
                    <Box
                        textAlign="center"
                        sx={{ display: { xs: 'block', sm: 'none' } }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/6380f66037ad31d883150f62_Packages_Movil_Esp.svg'
                                    : 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/6387e3ac94ff1fa55793a9bd_Packages_Movil_Eng.svg'
                            }
                        />
                    </Box>
                    <Box
                        textAlign="center"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/6380f65f490c2124e09da59c_Packages_Esp.svg'
                                    : 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/6387e3ab6f4a3b55b61c0b7e_Packages_Eng.svg'
                            }
                        />
                    </Box>
                </AccordionCard>
                <AccordionCard
                    customTitle={
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            sx={{ color: '#ffffff' }}
                        >
                            {t('Included features')}
                        </Typography>
                    }
                    sxAccordionSummary={{
                        backgroundColor: `primary.main`,
                        py: 1,
                        '&.Mui-expanded': {
                            minHeight: '64px',
                        },
                    }}
                    sxCard={{ mt: 2 }}
                >
                    <Box
                        textAlign="center"
                        sx={{ display: { xs: 'block', sm: 'none' } }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/648b5c90d522b252caed2060_Features_Movil_Esp.svg'
                                    : 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/648b5c8f632aa89f30fe16e0_Features_Movil_Eng.svg'
                            }
                        />
                    </Box>
                    <Box
                        textAlign="center"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/648b5c900eaf097d7ce8f061_Features_Esp.svg'
                                    : 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/648b5c8ebf5e3906b9a13288_Features_Eng.svg'
                            }
                        />
                    </Box>
                </AccordionCard>
                <AccordionCard
                    customTitle={
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            sx={sxTypography}
                        >
                            {t('Add-Ons')}
                        </Typography>
                    }
                    sxAccordionSummary={{
                        backgroundColor: `secondary.main`,
                        py: 1,
                        '&.Mui-expanded': {
                            minHeight: '64px',
                        },
                    }}
                    sxCard={{ mt: 2 }}
                    sxExpandMoreIcon={sxExpandMoreIcon}
                >
                    <Box
                        textAlign="center"
                        sx={{ display: { xs: 'block', sm: 'none' } }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/64908aebae44deedade5c742_Addons_Movil_Esp.svg'
                                    : 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/64908aeaadb6c81b5dec92ee_Addons_Movil_Eng.svg'
                            }
                        />
                    </Box>
                    <Box
                        textAlign="center"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/64908aee3980c9d2d17c692e_Addons_Esp.svg'
                                    : 'https://uploads-ssl.webflow.com/5ee80e144bcecc441f0c29be/64908aee07292d2b339dd048_Addons_Eng.svg'
                            }
                        />
                    </Box>
                </AccordionCard>
            </Grid>
        </Grid>
    );
};

BenefitsCards.propTypes = {
    selectedTheme: PropTypes.string,
    show: PropTypes.bool,
};

export default BenefitsCards;
