import React, { useEffect } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BoltIcon from '@mui/icons-material/Bolt';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Drawer, IconButton, Link, Typography } from 'sunwise-ui';

import { transformDateFormat } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';
import { getIsMexicanAccount } from 'common/utils/helpers/session';
import { scroll } from 'common/utils/mixins';

import * as credentialIntegrationSelectors from '../../../credentialIntegrations/selectors';
import * as actions from '../../actions';
import { DATE_FORMATS } from '../../constants';
import { getColorByStatus, getContactName } from '../../helpers';
import * as selectors from '../../selectors';

import ChartJs from './ChartJs';
import InfoAccordion from './InfoAccordion';
import PowerStation from './PowerStation';
import TextInfo from './TextInfo';

const DrawerHeader = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    ...theme.mixins.toolbar,
}));

const WrapperContent = styled(Box)`
    height: ${({ footerheight }) => `calc(100vh - ${footerheight})`};
    overflow-y: auto;
    padding: 0 16px;

    ${scroll.custom()};
`;

const DetailsDrawer = ({
    countryCurrencyLocale,
    fetchProjectById,
    handleClose,
    isOpen,
    projectData,
    providersDictionary,
    selectedProject,
    seriesData,
}) => {
    const { t } = useTranslation();

    const { address, id, last_read, projects, status } = selectedProject || {};
    const { consumptions, contact, is_deleted, name } = projectData || {};
    const dateFormat = getDateFormatByLocale();
    const isMexicanAccount = getIsMexicanAccount();
    const politicalDivisionLabel = isMexicanAccount
        ? t('State')
        : t('Political division of order 1');

    useEffect(() => {
        fetchProjectById(id);
    }, [id]);

    const lastRead = transformDateFormat(
        last_read,
        dateFormat,
        DATE_FORMATS.AFTER_SALES,
    );
    const footerHeight = is_deleted ? 0 : '70px';

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            PaperProps={{ sx: { maxWidth: '100%', width: '400px' } }}
            onClose={handleClose}
        >
            <WrapperContent footerheight={footerHeight}>
                <DrawerHeader>
                    <Typography variant="h6">{name || ''}</Typography>

                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DrawerHeader>

                <Box display="flex" gap={1} width="100%" mb={2}>
                    <BoltIcon sx={{ color: getColorByStatus(status) }} />

                    <Typography variant="body2">
                        {t('Last read')}: {lastRead || '--'}
                    </Typography>
                </Box>

                <InfoAccordion title={t('General information')}>
                    <Box display="flex" flexDirection="column" gap={1}>
                        <TextInfo
                            label={t('Rate')}
                            value={consumptions?.rate}
                        />

                        <TextInfo
                            label={politicalDivisionLabel}
                            value={consumptions?.political_division1}
                        />

                        <TextInfo
                            label={t('Zip code')}
                            value={consumptions?.zip_code}
                        />

                        <TextInfo
                            label={t('Contact')}
                            value={getContactName(contact)}
                        />

                        <TextInfo label={t('Mail')} value={contact?.email} />

                        <TextInfo
                            label={t('Telephone')}
                            value={`${contact?.phone_key || ''} ${
                                contact?.phone || ''
                            }`}
                        />
                    </Box>
                </InfoAccordion>

                <InfoAccordion
                    description={address || 'N/A'}
                    title={t('Location')}
                />

                {projects?.length > 0 && (
                    <InfoAccordion
                        defaultExpanded
                        title={t('Power station', { count: 2 })}
                    >
                        {projects.map((powerStation, index) => (
                            <PowerStation
                                key={powerStation?.id}
                                powerStation={powerStation}
                                providersDictionary={providersDictionary}
                                showDivider={index < projects.length - 1}
                            />
                        ))}
                    </InfoAccordion>
                )}

                <InfoAccordion defaultExpanded title={t('Chart', { count: 2 })}>
                    <ChartJs
                        countryCurrencyLocale={countryCurrencyLocale}
                        selectedProjectId={id}
                        seriesData={seriesData}
                    />
                </InfoAccordion>
            </WrapperContent>

            <Box
                alignItems="center"
                backgroundColor="background.neutral"
                display={is_deleted ? 'none' : 'flex'}
                height={footerHeight}
                justifyContent="center"
            >
                <Link
                    color="inherit"
                    href={`/record/${contact?.id}/?tab=projects&project=${id}&view=monitoring`}
                    sx={{
                        fontSize: '14px',
                        '&:hover': { color: 'primary.main' },
                    }}
                    underline="none"
                    variant="text"
                >
                    {t('Go to project file')}
                    <ArrowForwardIcon sx={{ ml: 1, verticalAlign: 'middle' }} />
                </Link>
            </Box>
        </Drawer>
    );
};

const mapStateToProps = createStructuredSelector({
    projectData: selectors.getProjectData,
    providersDictionary: credentialIntegrationSelectors.getProvidersDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjectById: (projectId) =>
        dispatch(actions.fetchProjectById(projectId)),
});

DetailsDrawer.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    fetchProjectById: PropTypes.func,
    handleClose: PropTypes.func,
    isOpen: PropTypes.bool,
    projectData: PropTypes.object,
    providersDictionary: PropTypes.object,
    selectedProject: PropTypes.object,
    seriesData: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsDrawer);
