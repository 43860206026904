import { NAME } from './constants';

export const INITIAL_VALUES = `${NAME}/INITIAL_VALUES`;

export const REFRES_FINANCING = `${NAME}/REFRES_FINANCING`;
export const REFRES_FINANCING_FAILURE = `${NAME}/REFRES_FINANCING_FAILURE`;
export const REFRES_FINANCING_SUCCESS = `${NAME}/REFRES_FINANCING_SUCCESS`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SET_OFFER_END = `${NAME}/SET_OFFER_END`;
export const SET_OFFER_END_FAILURE = `${NAME}/SET_OFFER_END_FAILURE`;
export const SET_OFFER_END_SUCCESS = `${NAME}/SET_OFFER_END_SUCCESS`;
