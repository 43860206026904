import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { Button } from 'sunwise-ui';

import { getColorButtonByStatus } from 'common/utils/helpers';

import { STATUS } from '../constants';

const CreditStatus = ({ onClick, status, sxButton }) => {
    switch (status) {
        case STATUS.APPROVED_STATUS.key:
            return (
                <Button
                    color="secondary"
                    onClick={onClick}
                    startIcon={<CheckIcon />}
                    sx={{
                        border: `1px solid ${getColorButtonByStatus(status)}`,
                        color: getColorButtonByStatus(status),
                        width: { xs: '100%', md: 'auto' },
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: 'inherit',
                        },
                        ...sxButton,
                    }}
                    variant="outlined"
                >
                    {STATUS.APPROVED_STATUS.value}
                </Button>
            );
        case STATUS.CLOSED_STATUS.key:
            return (
                <Button
                    color="secondary"
                    onClick={onClick}
                    startIcon={<CheckIcon />}
                    sx={{
                        border: `1px solid ${getColorButtonByStatus(status)}`,
                        color: getColorButtonByStatus(status),
                        width: { xs: '100%', md: 'auto' },
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: 'inherit',
                        },
                        ...sxButton,
                    }}
                    variant="outlined"
                >
                    {STATUS.CLOSED_STATUS.value}
                </Button>
            );
        case STATUS.REJECTED_STATUS.key:
            return (
                <Button
                    color="secondary"
                    onClick={onClick}
                    startIcon={<CloseIcon />}
                    sx={{
                        border: `1px solid ${getColorButtonByStatus(status)}`,
                        color: getColorButtonByStatus(status),
                        width: { xs: '100%', md: 'auto' },
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: 'inherit',
                        },
                        ...sxButton,
                    }}
                    variant="outlined"
                >
                    {STATUS.REJECTED_STATUS.value}
                </Button>
            );
        case STATUS.INPROCESS_STATUS.key:
            return (
                <Button
                    color="secondary"
                    onClick={onClick}
                    sx={{
                        border: `1px solid ${getColorButtonByStatus(status)}`,
                        color: getColorButtonByStatus(status),
                        width: { xs: '100%', md: 'auto' },
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: 'inherit',
                        },
                        ...sxButton,
                    }}
                    variant="outlined"
                >
                    {STATUS.INPROCESS_STATUS.value}
                </Button>
            );
        default:
            return (
                <Button
                    color="secondary"
                    onClick={onClick}
                    sx={{
                        border: `1px solid ${getColorButtonByStatus(status)}`,
                        color: getColorButtonByStatus(status),
                        width: { xs: '100%', md: 'auto' },
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: 'inherit',
                        },
                        ...sxButton,
                    }}
                    variant="outlined"
                >
                    {STATUS.REQUESTED_STATUS.value}
                </Button>
            );
    }
};

CreditStatus.propTypes = {
    onClick: PropTypes.func,
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sxButton: PropTypes.object,
};

export default CreditStatus;
