import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card, IconButton, Tooltip } from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';
import { STATUS } from 'common/constants';

import * as actions from './actions';
import CreditBureauForm from './components/CreditBureauForm';
import { getIsShowEditButton } from './helpers';
import * as selectors from './selectors';

const Container = ({
    creditItem,
    fetchBureauData,
    hasRequestBureau,
    initializeForm,
    isArchived,
    isFetching,
    isOpenDrawer,
    offerId,
    projectId,
    proposalId,
    reset,
    resetForm,
    selectCreditItem,
}) => {
    const { t } = useTranslation();
    const [isEnabledEdition, setIsEnabledEdition] = useState(false);

    useEffect(() => {
        resetForm();
        return () => {
            reset();
        };
    }, [isOpenDrawer]);

    useEffect(() => {
        if (!isEmpty(fetchBureauData) && hasRequestBureau) {
            initializeForm(fetchBureauData);
        }
    }, [fetchBureauData]);

    useEffect(() => {
        setIsEnabledEdition(false);
        if (!hasRequestBureau) {
            setIsEnabledEdition(true);
        }
    }, [hasRequestBureau]);

    const creditStatus = get(creditItem, 'status', null);
    const records = get(fetchBureauData, 'records', { current: 0, limit: 1 });
    const status = get(fetchBureauData, 'status', { key: null });
    const hasAuthorization = get(
        fetchBureauData,
        'has_authorized_consultation',
        false,
    );

    return (
        <Card className="mb-3">
            <Card.Header>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '16px',
                    }}
                >
                    <TitleWithDetail className="__userguiding_credit_bureau_card">
                        {t('Credit bureau')}
                    </TitleWithDetail>
                    {records && hasRequestBureau && (
                        <Tooltip
                            title={
                                <>
                                    {records.current}/{records.limit}{' '}
                                    {t('Tries').toLowerCase()}
                                </>
                            }
                        >
                            <InfoIcon sx={{ color: '#637381' }} />
                        </Tooltip>
                    )}
                    {getIsShowEditButton(
                        creditStatus,
                        fetchBureauData,
                        hasRequestBureau,
                        status,
                    ) && (
                        <IconButton
                            onClick={() =>
                                setIsEnabledEdition(!isEnabledEdition)
                            }
                            size="small"
                            sx={{ ml: 'auto' }}
                        >
                            {isEnabledEdition ? (
                                <CloseIcon fontSize="small" />
                            ) : (
                                <ModeEditOutlinedIcon fontSize="small" />
                            )}
                        </IconButton>
                    )}
                </Box>
            </Card.Header>
            <Card.Body
                sx={{
                    opacity:
                        creditStatus === STATUS.CANCELLED_STATUS.key ? 0.6 : 1,
                }}
            >
                <CreditBureauForm
                    creditItem={creditItem}
                    creditStatus={creditStatus}
                    fetchBureauData={fetchBureauData}
                    hasAuthorization={hasAuthorization}
                    isArchived={isArchived}
                    isEnabledEdition={isEnabledEdition}
                    isFetching={isFetching}
                    offerId={offerId}
                    projectId={projectId}
                    proposalId={proposalId}
                    selectCreditItem={selectCreditItem}
                    setIsEnabledEdition={setIsEnabledEdition}
                    status={status}
                />

                <Box mt={2}>
                    <Button
                        color="secondary"
                        href={`${
                            import.meta.env.VITE_ACADEMY_URL
                        }es/articles/4292160-solicitar-credito-cibanco`}
                        sx={{ width: { md: 'auto', xs: '100%' } }}
                        target="_blank"
                        variant="text"
                    >
                        {t('See manual')}
                    </Button>
                </Box>
            </Card.Body>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    fetchBureauData: selectors.getFetchData,
    isFetching: selectors.getIsFetching,
});

const mapDispatchToProps = (dispatch) => ({
    initializeForm: (newValues) => dispatch(actions.initializeForm(newValues)),
    reset: () => dispatch(actions.reset()),
    resetForm: () => dispatch(actions.resetForm()),
});

Container.propTypes = {
    creditItem: PropTypes.object,
    fetchBureauData: PropTypes.object,
    hasRequestBureau: PropTypes.bool,
    initializeForm: PropTypes.func,
    isArchived: PropTypes.bool,
    isFetching: PropTypes.bool,
    isOpenDrawer: PropTypes.bool,
    offerId: PropTypes.string,
    projectId: PropTypes.string,
    proposalId: PropTypes.string,
    reset: PropTypes.func,
    resetForm: PropTypes.func,
    selectCreditItem: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
