import React from 'react';

import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import { CheckAccess } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import { getHasPaymentDue } from 'common/utils/helpers/session';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledListItemText from './StyledListItemText';

const AnalyticsOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
}) => {
    const hasPaymentDue = getHasPaymentDue();
    const breakpoint = useBreakpoint();

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.ANALYTICS_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/analytics');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/analytics' === match.url}
                    variant="horizontal"
                >
                    <SignalCellularAltIcon />
                    <StyledListItemText primary="Sunwise" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess requiredPermissions={[PERMISSION_LIST.CFE_PERMISSION]}>
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/analytics-cfe');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/analytics-cfe' === match.url}
                    variant="horizontal"
                >
                    <SignalCellularAltIcon />
                    <StyledListItemText primary="CFE" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess requiredPermissions={[PERMISSION_LIST.CRE_PERMISSION]}>
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/analytics-cre');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/analytics-cre' === match.url}
                    variant="horizontal"
                >
                    <SignalCellularAltIcon />
                    <StyledListItemText primary="CRE" />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

AnalyticsOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
};

export default AnalyticsOptions;
