import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { EMAIL_VALIDATION_TEXT, REQUIRED_TEXT, minimumNumberCharacters } =
        getValidationTexts();

    return object().shape({
        email: string()
            .email(EMAIL_VALIDATION_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        password: string()
            .min(8, minimumNumberCharacters(8))
            .required(REQUIRED_TEXT)
            .nullable(),
    });
};
