import { getData } from 'common/api/v1/projectedYears';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import {
    FETCH_REFERENCE_INFO,
    FETCH_REFERENCE_INFO_FAILURE,
    FETCH_REFERENCE_INFO_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (projectId) => (dispatch, getState) => {
    const state = getState();
    const startDate = afterSalesSettingsSelectors.getAfterSalesStartDate(state);

    if (!startDate) return;

    dispatch(actions[FETCH_REFERENCE_INFO]());

    getData(projectId, startDate)
        .then((response) =>
            dispatch(
                actions[FETCH_REFERENCE_INFO_SUCCESS](response?.data?.data),
            ),
        )
        .catch((error) => {
            dispatch(actions[FETCH_REFERENCE_INFO_FAILURE](error));

            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
