import { NAME } from './constants';

export const FETCH_CURRENCIES = `${NAME}/FETCH_CURRENCIES`;
export const FETCH_CURRENCIES_FAILURE = `${NAME}/FETCH_CURRENCIES_FAILURE`;
export const FETCH_CURRENCIES_SUCCESS = `${NAME}/FETCH_CURRENCIES_SUCCESS`;

export const FETCH_INDICATORS_SETTINGS = `${NAME}/FETCH_INDICATORS_SETTINGS`;
export const FETCH_INDICATORS_SETTINGS_FAILURE = `${NAME}/FETCH_INDICATORS_SETTINGS_FAILURE`;
export const FETCH_INDICATORS_SETTINGS_SUCCESS = `${NAME}/FETCH_INDICATORS_SETTINGS_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const SAVE_INDICATORS_SETTINGS = `${NAME}/SAVE_INDICATORS_SETTINGS`;
export const SAVE_INDICATORS_SETTINGS_FAILURE = `${NAME}/SAVE_INDICATORS_SETTINGS_FAILURE`;
export const SAVE_INDICATORS_SETTINGS_SUCCESS = `${NAME}/SAVE_INDICATORS_SETTINGS_SUCCESS`;

export const SET_IS_OPEN_INDICATORS_SETTINGS_MODAL = `${NAME}/SET_IS_OPEN_INDICATORS_SETTINGS_MODAL`;
