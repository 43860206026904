import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteItem from './deleteItem';

export default (filterData, item) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        haveTextCaptcha: true,
        messages: [
            i18next.t('Are you sure to delete the item "{{name}}"?', {
                name: item.name,
            }),
        ],
        onSuccess: () => dispatch(deleteItem(item.id)),
        textToValidate: i18next.t('Delete').toUpperCase(),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
