import { save } from 'common/api/v2/storageOffer';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { fetchOfferConsumption } from '../../proposalGeneratorOneColumn/actions';
import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { DEFAULT_BACKUP_PERCENTAGE } from '../constants';
import { energyBackupSetupActions } from '../reducer';

import fetchStorageOffer from './fetchStorageOffer';

export default (commercialOfferId, values = {}, complements = {}) =>
    (dispatch) => {
        dispatch(energyBackupSetupActions[SAVE]());

        const {
            base_day_type,
            product_branch_office,
            quantity,
            storage_percent,
        } = values;

        const backupPercentage = complements?.canModifyTimeShifting
            ? storage_percent
            : DEFAULT_BACKUP_PERCENTAGE;

        const newValues = {
            base_day_type: base_day_type,
            product_branch_office: product_branch_office,
            quantity: Math.ceil(quantity) || 0,
            storage_percent: backupPercentage / 100 || 0,
        };

        save(commercialOfferId, newValues)
            .then((response) => {
                dispatch(
                    energyBackupSetupActions[SAVE_SUCCESS](
                        response?.data?.data,
                    ),
                );
                dispatch(fetchStorageOffer(commercialOfferId));
                dispatch(fetchOfferConsumption(commercialOfferId));
            })
            .catch((error) => {
                dispatch(
                    energyBackupSetupActions[SAVE_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
