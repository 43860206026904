import API from '../../afterSalesAPI';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'projects-configuration/access-keys';

export const createAccessKey = (data) =>
    API.post(`/api/${ENTITY}`, data, config);

export const deleteAccessKey = (id) => API.delete(`/api/${ENTITY}/${id}`);

export const getAccessKeys = () => API.get(`/api/${ENTITY}`);

export const getStations = (accessKeyId, filter) =>
    API.get(`/api/${ENTITY}/${accessKeyId}/station-lists`, { params: filter });

export const updateAccessKey = (id, data) =>
    API.patch(`/api/${ENTITY}/${id}`, data, config);
