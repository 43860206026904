import React from 'react';

import { KBarResults, useMatches } from 'kbar';

import ResultItem from './ResultItem';

const groupNameStyle = {
    fontSize: '10px',
    opacity: 0.5,
    padding: '8px 16px',
    textTransform: 'uppercase',
};

const RenderResults = () => {
    const { results, rootActionId } = useMatches();

    return (
        <KBarResults
            items={results}
            onRender={({ item, active }) =>
                typeof item === 'string' ? (
                    <div style={groupNameStyle}>{item}</div>
                ) : (
                    <ResultItem
                        action={item}
                        active={active}
                        currentRootActionId={rootActionId}
                    />
                )
            }
        />
    );
};

export default RenderResults;
