import React from 'react';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridRowModes } from '@mui/x-data-grid';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { getDatasheetName } from 'common/modules/productDatasheets/helpers';

const ProductActionsFormatter = ({
    canDelete,
    canModify,
    filterData,
    handleClickBulkItems,
    handleClickDownloadDatasheet,
    handleClickUpdate,
    loadingContext,
    row,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const isArchived = row?.status_flag === 'archived';
    const isInEditMode = rowModesModel[row?.id]?.mode === GridRowModes.Edit;

    const handleClickSave = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
        });
        setSelectedRowId(null);
    };

    const handleClickCancel = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        setSelectedRowId(null);
    };

    const renderOptions = () => {
        if (isInEditMode) {
            return (
                <>
                    <Tooltip title={t('Save changes')}>
                        <IconButton onClick={handleClickSave(selectedRowId)}>
                            <SaveIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Cancel')}>
                        <IconButton onClick={handleClickCancel(selectedRowId)}>
                            <CancelIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            );
        }

        return (
            <>
                <IconButton
                    disabled={isEmpty(row.data_sheet)}
                    onClick={() =>
                        handleClickDownloadDatasheet(
                            getDatasheetName(row.data_sheet),
                            (text) => loadingContext.openLoading(text),
                            () => {
                                loadingContext.closeLoading();
                            },
                        )
                    }
                >
                    <VisibilityIcon fontSize="small" />
                </IconButton>

                <DropdownCardMenu
                    items={[
                        {
                            handleClick: () => handleClickUpdate(row),
                            icon: <EditIcon fontSize="small" />,
                            label: t('Edit'),
                            visible: canModify,
                        },
                        {
                            handleClick: () =>
                                handleClickBulkItems(
                                    [row.id],
                                    'archived',
                                    filterData,
                                ),
                            icon: <ArchiveOutlinedIcon fontSize="small" />,
                            label: t('Archive'),
                            visible: canDelete && !isArchived,
                        },
                        {
                            handleClick: () =>
                                handleClickBulkItems(
                                    [row.id],
                                    'active',
                                    filterData,
                                ),
                            icon: <UnarchiveOutlinedIcon fontSize="small" />,
                            label: t('Unarchive'),
                            visible: canDelete && isArchived,
                        },
                        {
                            handleClick: () =>
                                handleClickBulkItems(
                                    [row.id],
                                    'deleted',
                                    filterData,
                                ),
                            icon: <DeleteIcon fontSize="small" />,
                            label: t('Delete'),
                            visible: canDelete && isArchived,
                        },
                    ]}
                    visible={canDelete || canModify}
                />
            </>
        );
    };

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '4px',
                justifyContent: 'end',
                width: '100%',
            }}
        >
            {renderOptions()}
        </Box>
    );
};

ProductActionsFormatter.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickDownloadDatasheet: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    loadingContext: PropTypes.object,
    row: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.string,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductActionsFormatter;
