import React, { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    ClickAwayListener,
    IconButton,
    Tooltip,
    Typography,
} from 'sunwise-ui';

const EnergySavingTitle = ({ svgIcon, title }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => setOpen(false);
    const handleTooltipOpen = () => setOpen(true);

    return (
        <Box display="flex" alignItems="center" gap="8px">
            {svgIcon}
            <Typography variant="body2" ml={1} sx={{ fontWeight: 'bold' }}>
                {title}
            </Typography>

            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <Tooltip
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                            <div>
                                <Typography variant="body2">
                                    {t(
                                        'Values of % of Energy Savings and PV System Power are rounded up according to integer number of solar panels',
                                    )}
                                </Typography>
                            </div>
                        }
                    >
                        <IconButton
                            onClick={handleTooltipOpen}
                            aria-label="info-button"
                            ml={1}
                        >
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </ClickAwayListener>
        </Box>
    );
};

EnergySavingTitle.propTypes = {
    svgIcon: PropTypes.object,
    title: PropTypes.string,
};

export default EnergySavingTitle;
