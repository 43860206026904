import { getScheduleRateConfiguration } from 'common/api/v2/scheduleRate';

import {
    FETCH_SCHEDULE_RATE_CONFIGURATION,
    FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE,
    FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS,
} from '../actionTypes';
import { projectConsumptionCardActions } from '../reducer';

export default (params) => (dispatch) =>
    new Promise((resolve) => {
        const { certified, rate, rate_division } = params;

        if (!rate) {
            resolve(null);
            return;
        }

        dispatch(
            projectConsumptionCardActions[FETCH_SCHEDULE_RATE_CONFIGURATION](),
        );

        getScheduleRateConfiguration({ certified, rate, rate_division })
            .then((response) => {
                dispatch(
                    projectConsumptionCardActions[
                        FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS
                    ](response?.data?.data),
                );
                resolve(response?.data?.data);
            })
            .catch((error) => {
                dispatch(
                    projectConsumptionCardActions[
                        FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE
                    ](error?.response?.data?.errors),
                );
                resolve(null);
            });
    });
