import { number, object, ref, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';
import { getMaxDiscountByUnitPrice } from 'common/utils/helpers';
import { getMaximumDiscount } from 'common/utils/helpers/session';

export default () => {
    const {
        getMaxValueText,
        getMinValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    return object().shape({
        quantity: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        item: string().required(REQUIRED_TEXT).nullable(),
        cost: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        margin: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        discount: number().when('discount_type', {
            is: 1,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .max(
                        getMaxDiscountByUnitPrice(
                            getMaximumDiscount(),
                            ref('unit_price'),
                        ),
                        getMaxValueText(
                            getMaxDiscountByUnitPrice(
                                getMaximumDiscount(),
                                ref('unit_price'),
                            ),
                        ),
                    )
                    .nullable(),
        }),
        percentage_discount: number().when('discount_type', {
            is: 0,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .max(
                        getMaximumDiscount(),
                        getMaxValueText(getMaximumDiscount()),
                    )
                    .nullable(),
        }),
    });
};
