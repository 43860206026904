import i18next from 'i18next';

import { enablePermalink } from 'common/api/v1/interactiveProposal';
import showToast from 'common/utils/showToast';

import setHasPermalink from '../../reportReviewPro/actions/setHasPermalink';

export default (reportId, value) => (dispatch) =>
    enablePermalink(reportId, { status: value }).then(() => {
        dispatch(setHasPermalink(value));
        showToast({
            body: value
                ? i18next.t('Interactive link enabled')
                : i18next.t('Interactive link disabled'),
        });
    });
