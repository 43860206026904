import i18next from 'i18next';
import get from 'lodash/get';

import { INITIAL_VALUES_CI_BANCO, RESET_FORM_CI_BANCO } from '../actionTypes';
import {
    getTotalWithDealerFee,
    hasCommissionDict,
    percentageToValue,
    valueToPercentage,
} from '../helpers';
import { financingAndInsuranceActions } from '../reducer';

export default (values) => (dispatch) => {
    dispatch(financingAndInsuranceActions[RESET_FORM_CI_BANCO]());

    const parameters = get(values, 'financier_product_parameters', {});

    let terms = [];

    if (parameters?.terms) {
        terms = parameters.terms.split(',').map((item) => ({
            label: `${item} ${i18next.t('Month', {
                count: parseInt(item),
            })}`,
            value: item,
        }));
    }

    const totalWithDealerFee = getTotalWithDealerFee(
        parameters?.dealer_fee,
        values.total,
    );

    let newValues = {
        amount: values.total,
        commission_credit: valueToPercentage(
            parameters.is_commission_percentage,
            parameters.commission_credit,
            totalWithDealerFee,
        ),
        dealer_fee: parameters.dealer_fee,
        financier_installer: values.financier_installer,
        financier_product: parameters.financier_product,
        hitch_percentage: valueToPercentage(
            parameters.is_percentage,
            parameters.hitch,
            totalWithDealerFee,
        ),
        hitch: percentageToValue(
            parameters.is_percentage,
            parameters.hitch,
            totalWithDealerFee,
        ),
        insurance: parameters.insurance,
        is_commission_percentage: hasCommissionDict(parameters)
            ? true
            : parameters.is_commission_percentage,
        is_percentage: parameters.is_percentage.toString(),
        is_serfimex: values.is_serfimex,
        rate: parameters.rate,
        residual: parameters.residual,
        term: values.term,
        termsToSelect: terms,
        total_with_dealer_fee: totalWithDealerFee,
        types_business_company: values.types_business_company,
        parameters: parameters,
    };

    if (values.hitch) newValues.hitch = values.hitch;
    if (values.hitch_percentage)
        newValues.hitch_percentage = values.hitch_percentage;

    if (values.is_serfimex) newValues.term = 0;

    dispatch(financingAndInsuranceActions[INITIAL_VALUES_CI_BANCO](newValues));
};
