import { resetRateConcepts } from 'common/api/v2/resetRateConcepts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import {
    RESET_RATE_CONCEPTS,
    RESET_RATE_CONCEPTS_FAILURE,
    RESET_RATE_CONCEPTS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (commercialOfferId, rates) => (dispatch) => {
    if (!commercialOfferId || !rates?.length) return;

    dispatch(actions[RESET_RATE_CONCEPTS]());

    resetRateConcepts(commercialOfferId, { reset: rates })
        .then((response) => {
            const data = response?.data?.data;
            dispatch(eventsAfterUpdate(data, commercialOfferId));
            dispatch(actions[RESET_RATE_CONCEPTS_SUCCESS](data));
        })
        .catch((error) => {
            dispatch(actions[RESET_RATE_CONCEPTS_FAILURE](error));

            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
