import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from 'sunwise-ui';

const CompensationSchemeDescription = ({ compensationScheme }) => {
    const { t } = useTranslation();

    if (isEmpty(compensationScheme)) return null;

    const { description, label, howItWorks } = compensationScheme;

    const hasHowItWorks =
        howItWorks && Array.isArray(howItWorks) && howItWorks.length > 0;

    return (
        <>
            <Typography variant="h6">{t(label)}</Typography>
            <Divider />
            <Box m={{ xs: 0, md: 3 }}>
                <Typography variant="body1">{t(description)}</Typography>

                {hasHowItWorks && (
                    <>
                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            {t('How it works').concat(':')}
                        </Typography>
                        {howItWorks.map((text, index) => (
                            <Typography
                                key={`how-it-works-${index}`}
                                sx={{ ml: 2, mt: 2 }}
                                variant="body1"
                            >
                                {`${index + 1}. ${t(text)}`}
                            </Typography>
                        ))}
                    </>
                )}
            </Box>
        </>
    );
};

CompensationSchemeDescription.propTypes = {
    compensationScheme: PropTypes.object,
};

export default CompensationSchemeDescription;
