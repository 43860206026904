export const FETCH_LOGS = 'FETCH_LOGS';
export const FETCH_LOGS_FAILURE = 'FETCH_LOGS_FAILURE';
export const FETCH_LOGS_SUCCESS = 'FETCH_LOGS_SUCCESS';

export const FETCH_PROJECTS_INFO = 'FETCH_PROJECTS_INFO';
export const FETCH_PROJECTS_INFO_FAILURE = 'FETCH_PROJECTS_INFO_FAILURE';
export const FETCH_PROJECTS_INFO_SUCCESS = 'FETCH_PROJECTS_INFO_SUCCESS';

export const RESET = 'RESET';
export const RESET_LOGS = 'RESET_LOGS';
