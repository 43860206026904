import get from 'lodash/get';

import { INITIAL_VALUES } from '../actionTypes';
import { energySavingActions } from '../reducer';

export default (offerPanels, energySaving, systemSize) => (dispatch) => {
    if (!Array.isArray(offerPanels) || offerPanels.length === 0) return;

    const item = offerPanels[0];
    const { id, watts } = item;
    const panelId = get(item, 'panel_branch_office.id', null);

    const quantity = item.quantity;

    const newValues = {
        energy_saving: energySaving,
        id,
        panel: panelId,
        quantity,
        system_size: systemSize,
        tempQuantity: quantity,
        watts,
    };

    dispatch(energySavingActions[INITIAL_VALUES](newValues));
};
