import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'sunwise-ui';

import FinancialProductImage from 'resources/financialProduct.png';

import ProductItem from './ProductItem';

const CardList = ({ handleClickFinancialProduct, isLocked, items }) => {
    return (
        <Grid container py={2}>
            {items.map((product) => (
                <Grid item xs={9} md={5} lg={3} xl={4} key={product.id}>
                    <ProductItem
                        disabled={isLocked}
                        name={product.name}
                        onClick={() => handleClickFinancialProduct(product)}
                        srcImg={FinancialProductImage}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

CardList.propTypes = {
    handleClickFinancialProduct: PropTypes.func,
    isLocked: PropTypes.bool,
    items: PropTypes.array,
};

export default CardList;
