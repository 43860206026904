import { NAME } from './constants';

export const FETCH_TABLE_SETTINGS = `${NAME}/FETCH_TABLE_SETTINGS`;
export const FETCH_TABLE_SETTINGS_FAILURE = `${NAME}/FETCH_TABLE_SETTINGS_FAILURE`;
export const FETCH_TABLE_SETTINGS_SUCCESS = `${NAME}/FETCH_TABLE_SETTINGS_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const SAVE_TABLE_SETTINGS = `${NAME}/SAVE_TABLE_SETTINGS`;
export const SAVE_TABLE_SETTINGS_FAILURE = `${NAME}/SAVE_TABLE_SETTINGS_FAILURE`;
export const SAVE_TABLE_SETTINGS_SUCCESS = `${NAME}/SAVE_TABLE_SETTINGS_SUCCESS`;

export const SET_IS_OPEN_TABLE_SETTINGS_MODAL = `${NAME}/SET_IS_OPEN_TABLE_SETTINGS_MODAL`;
