import isEmpty from 'lodash/isEmpty';

import filterItems from './filterItems';
import openModalMargin from './openModalMargin';
import resetMarginForm from './resetMarginForm';

export default (filterData = {}) =>
    (dispatch) => {
        dispatch(resetMarginForm());
        if (!isEmpty(filterData)) dispatch(filterItems(filterData));
        dispatch(openModalMargin(false));
    };
