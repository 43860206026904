import { getInverterProductsByBrand } from 'common/api/v1/onboarding';

import {
    FETCH_INVERTER_PRODUCTS,
    FETCH_INVERTER_PRODUCTS_FAILURE,
    FETCH_INVERTER_PRODUCTS_SUCCESS,
} from '../actionTypes';
import { onboardingWizardActions } from '../reducer';

export default (brandName) => (dispatch) => {
    dispatch(onboardingWizardActions[FETCH_INVERTER_PRODUCTS]());

    getInverterProductsByBrand(brandName)
        .then((response) => {
            dispatch(
                onboardingWizardActions[FETCH_INVERTER_PRODUCTS_SUCCESS](
                    response.data,
                ),
            );
        })
        .catch((error) => {
            dispatch(
                onboardingWizardActions[FETCH_INVERTER_PRODUCTS_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
        });
};
