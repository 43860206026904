import { NAME } from './constants';

export const DELETE_GENERATION_PROFILE = `${NAME}/DELETE_GENERATION_PROFILE`;
export const DELETE_GENERATION_PROFILE_FAILURE = `${NAME}/DELETE_GENERATION_PROFILE_FAILURE`;
export const DELETE_GENERATION_PROFILE_SUCCESS = `${NAME}/DELETE_GENERATION_PROFILE_SUCCESS`;

export const DELETE_ITEM = `${NAME}/DELETE_ITEM`;
export const DELETE_ITEM_FAILURE = `${NAME}/DELETE_ITEM_FAILURE`;
export const DELETE_ITEM_SUCCESS = `${NAME}/DELETE_ITEM_SUCCESS`;

export const FETCH_DEFAULT_SOURCE = `${NAME}/FETCH_DEFAULT_SOURCE`;
export const FETCH_DEFAULT_SOURCE_FAILURE = `${NAME}/FETCH_DEFAULT_SOURCE_FAILURE`;
export const FETCH_DEFAULT_SOURCE_SUCCESS = `${NAME}/FETCH_DEFAULT_SOURCE_SUCCESS`;

export const FETCH_GENERATION_PROFILE = `${NAME}/FETCH_GENERATION_PROFILE`;
export const FETCH_GENERATION_PROFILE_FAILURE = `${NAME}/FETCH_GENERATION_PROFILE_FAILURE`;
export const FETCH_GENERATION_PROFILE_SUCCESS = `${NAME}/FETCH_GENERATION_PROFILE_SUCCESS`;

export const FETCH_IRRADIATION_BY_STATE_AND_SOURCE = `${NAME}/FETCH_IRRADIATION_BY_STATE_AND_SOURCE`;
export const FETCH_IRRADIATION_BY_STATE_AND_SOURCE_FAILURE = `${NAME}/FETCH_IRRADIATION_BY_STATE_AND_SOURCE_FAILURE`;
export const FETCH_IRRADIATION_BY_STATE_AND_SOURCE_SUCCESS = `${NAME}/FETCH_IRRADIATION_BY_STATE_AND_SOURCE_SUCCESS`;

export const FETCH_ITEMS = `${NAME}/FETCH_ITEMS`;
export const FETCH_ITEMS_SUCCESS = `${NAME}/FETCH_ITEMS_SUCCESS`;
export const FETCH_ITEMS_FAILURE = `${NAME}/FETCH_ITEMS_FAILURE`;

export const FETCH_POLITICAL_DIVISIONS = `${NAME}/FETCH_POLITICAL_DIVISIONS`;
export const FETCH_POLITICAL_DIVISIONS_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS_SUCCESS`;
export const FETCH_POLITICAL_DIVISIONS_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS_FAILURE`;

export const FETCH_POLITICAL_DIVISIONS2 = `${NAME}/FETCH_POLITICAL_DIVISIONS2`;
export const FETCH_POLITICAL_DIVISIONS2_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS2_SUCCESS`;
export const FETCH_POLITICAL_DIVISIONS2_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS2_FAILURE`;

export const FETCH_SOURCES = `${NAME}/FETCH_SOURCES`;
export const FETCH_SOURCES_SUCCESS = `${NAME}/FETCH_SOURCES_SUCCESS`;
export const FETCH_SOURCES_FAILURE = `${NAME}/FETCH_SOURCES_FAILURE`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const INITIALIZE_GENERATION_PROFILE_FORM = `${NAME}/INITIALIZE_GENERATION_PROFILE_FORM`;

export const OPEN_MODAL = `${NAME}/OPEN_MODAL`;

export const RESET_GENERATION_PROFILE_FORM = `${NAME}/RESET_GENERATION_PROFILE_FORM`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_GENERATION_PROFILE = `${NAME}/SAVE_GENERATION_PROFILE`;
export const SAVE_GENERATION_PROFILE_FAILURE = `${NAME}/SAVE_GENERATION_PROFILE_FAILURE`;
export const SAVE_GENERATION_PROFILE_SUCCESS = `${NAME}/SAVE_GENERATION_PROFILE_SUCCESS`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;

export const SAVE_SOURCE = `${NAME}/SAVE_SOURCE`;
export const SAVE_SOURCE_FAILURE = `${NAME}/SAVE_SOURCE_FAILURE`;
export const SAVE_SOURCE_SUCCESS = `${NAME}/SAVE_SOURCE_SUCCESS`;

export const SET_GENERATION_PROFILE_MODAL_IS_OPEN = `${NAME}/SET_GENERATION_PROFILE_MODAL_IS_OPEN`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
