import React from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Box, Card, Grid, Typography } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';
import { fullNameBuild, handleFileURL } from 'common/utils/helpers';

import AmountLabel from './AmountLabel';
import ProposalsCount from './ProposalsCount';

const DateComponent = styled(Box)`
    align-items: center;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    gap: 8px;
    line-height: 12px;
    text-align: right;
    text-transform: capitalize;
`;

const Title = styled(Typography)`
    font-size: 14px;
    font-weight: 600;
    margin-top: 6px;
`;

const Salesman = styled(Box)`
    align-items: center;
    display: flex;

    figure {
        align-items: center;
        border-radius: 100%;
        border: 1px solid #ecedf0;
        display: inline-flex;
        height: 32px;
        justify-content: center;
        min-height: 32px;
        min-width: 32px;
        overflow: hidden;
        text-align: center;
        width: 32px;

        img {
            height: 100%;
            object-fit: contain;
            width: 100%;
        }
    }
    span {
        font-size: 11px;
        font-weight: 500;
        line-height: 13px;
        min-height: 13px;
    }
`;

const StyledCard = styled(Card)`
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 8px;
    padding: 16px;
    width: 100%;

    &.disabled {
        pointer-events: none;
        user-select: none;
        cursor: not-allowed !important;
    }

    &.dragging {
        opacity: 0.3;
    }
`;

const ItemCard = ({
    canModify,
    disabled,
    handleClickView,
    id,
    index,
    project,
}) => {
    const { t } = useTranslation();

    const imgSrc =
        get(project, 'agent.profile_image', null) === null ? (
            <PersonIcon color="primary" variant="outlined" fontSize="small" />
        ) : (
            <img
                alt="agente"
                src={handleFileURL(
                    project.agent.profile_image,
                    import.meta.env.VITE_S3_MEDIA_PATH,
                )}
                width="26"
            />
        );

    const salesmanName = fullNameBuild({
        firstName: get(project, 'agent.first_name', ''),
        lastName: get(project, 'agent.last_name', ''),
    });

    const contact = fullNameBuild({
        firstName: get(project, 'contact.name', ''),
        lastName: get(project, 'contact.first_lastname', ''),
    });

    const date = formatDate(
        parseDate(project.created_at, 'dd/MM/yyyy HH:mm:ss'),
        'PP',
    );

    const { comercial_offer } = project;

    return (
        <Draggable
            draggableId={id}
            index={index}
            isDragDisabled={!canModify}
            isEnabled={!disabled}
        >
            {(provided, snapshot) => (
                <StyledCard
                    className={`drag-funnel-project ${disabled && 'disabled'} ${
                        snapshot.isDragging && 'dragging'
                    }`}
                    onClick={() => handleClickView(id)}
                    ref={provided.innerRef}
                    sx={{ borderRadius: '6px' }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <ProposalsCount count={project.proposals_count} />

                        <Title>{project.name}</Title>
                    </Box>

                    <Typography fontSize="12px">
                        {t('Contact')}:{' '}
                        <strong>{contact || t('No contact assigned')}</strong>
                    </Typography>

                    <Typography fontSize="12px">
                        {t('Rate')}:{' '}
                        <strong>{get(project, 'rate.name', '')}</strong>
                    </Typography>

                    <Typography fontSize="12px">
                        {t('Service number')}:{' '}
                        <strong>{get(project, 'service_number', 'N/A')}</strong>
                    </Typography>

                    <Typography fontSize="12px">
                        {t('System size')}:{' '}
                        <strong>
                            {get(project, 'comercial_offer.kw_system_size', 0)}{' '}
                            kW
                        </strong>
                    </Typography>

                    <AmountLabel
                        comercialOffer={comercial_offer}
                        rate={project.rate && project.rate.name}
                    />

                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    gap: 1,
                                    my: 2,
                                }}
                            >
                                <Salesman>
                                    <figure>{imgSrc}</figure>
                                </Salesman>

                                <Box>{salesmanName}</Box>

                                <DateComponent>
                                    <CalendarTodayIcon /> {date}
                                </DateComponent>
                            </Box>
                        </Grid>
                    </Grid>
                </StyledCard>
            )}
        </Draggable>
    );
};

ItemCard.propTypes = {
    canModify: PropTypes.bool,
    disabled: PropTypes.bool,
    handleClickView: PropTypes.func,
    id: PropTypes.string,
    index: PropTypes.number,
    project: PropTypes.object,
    selectProject: PropTypes.func,
};

export default ItemCard;
