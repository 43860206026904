export const NAME = 'after-sales-consumption-history';

export const CAPTURE_MODE = {
    AUTOMATIC: 2,
    CALCULATED: 1,
    USER: 0,
};

export const DEBOUNCE_TIME = 1000;

export const DEMAND_METHODS = {
    CURRENT: 2,
    ESTIMATED: 1,
    MANUAL: 3,
    MEASUREMENT_SOURCE: 0,
};

export const DEMAND_CONCEPTS = {
    MEASURED: 'demand',
};

export const ENERGY_CONCEPTS = {
    BAG_ENERGY: 'pool',
    CONSUMPTION: 'consumption',
    EXPORTED_ENERGY: 'exported_energy',
    GENERATION: 'generation',
    GRID_CONSUMPTION: 'grid_consumption',
    NET_CONSUMPTION: 'net_consumption',
    RAW_NET_CONSUMPTION: 'raw_net_consumption',
    SELF_CONSUMPTION: 'selfconsumption',
};

/* 
    The order indicates the priority of the equation. The first equation has the highest priority.
    The values in the equations indicate the side of the equation where the concept is located.
    Example:    generation = exported_energy + selfconsumption  =>  [1, -1, -1]
*/
export const ENERGY_EQUATIONS = [
    {
        [ENERGY_CONCEPTS.CONSUMPTION]: 1,
        [ENERGY_CONCEPTS.GRID_CONSUMPTION]: -1,
        [ENERGY_CONCEPTS.SELF_CONSUMPTION]: -1,
    },
    {
        [ENERGY_CONCEPTS.GENERATION]: 1,
        [ENERGY_CONCEPTS.EXPORTED_ENERGY]: -1,
        [ENERGY_CONCEPTS.SELF_CONSUMPTION]: -1,
    },
    {
        [ENERGY_CONCEPTS.RAW_NET_CONSUMPTION]: 1,
        [ENERGY_CONCEPTS.CONSUMPTION]: -1,
        [ENERGY_CONCEPTS.GENERATION]: 1,
    },
];

export const ALLOWED_NEGATIVE_CONCEPTS = [ENERGY_CONCEPTS.RAW_NET_CONSUMPTION];

export const GROUPED_ENERGY_FIELDS = [
    {
        defaultExpanded: true,
        label: 'Consumption',
        fields: [
            {
                label: 'Total consumption',
                key: ENERGY_CONCEPTS.CONSUMPTION,
            },
            {
                label: 'Consumption from Grid',
                key: ENERGY_CONCEPTS.GRID_CONSUMPTION,
            },
            {
                label: 'Self-consumption',
                key: ENERGY_CONCEPTS.SELF_CONSUMPTION,
            },
        ],
    },
    {
        defaultExpanded: false,
        label: 'Generation',
        fields: [
            {
                label: 'Total generation',
                key: ENERGY_CONCEPTS.GENERATION,
            },
            {
                label: 'Exported solar generation',
                key: ENERGY_CONCEPTS.EXPORTED_ENERGY,
            },
        ],
    },
    {
        defaultExpanded: false,
        label: 'Net metering',
        onlyNetmet: true,
        compensationSchemes: ['NETMET', 'NETMETS'],
        fields: [
            {
                label: 'Net consumption',
                key: ENERGY_CONCEPTS.NET_CONSUMPTION,
            },
            {
                disabled: true,
                label: 'Pool',
                key: ENERGY_CONCEPTS.BAG_ENERGY,
            },
        ],
    },
];

export const FORM_FIELDS_BY_SECTION = {
    GENERAL: [
        'contracted_demand',
        'file',
        'final_date',
        'hourly_contracted_demand',
        'initial_date',
        'is_bimonthly',
        'power_factor',
        'rate',
        'rate_division_summer',
        'subsidy_rate',
    ],
    ENERGY: ['energy', 'total'],
    PAYMENT: ['demand', 'demand_method', 'no_solar_demand'],
};

export const UPLOAD_ORIGINS = {
    CSV: 1,
    LISA: 0,
    XML: 2,
};
