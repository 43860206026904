import API from '../../searchiAPI';
const ENTITY = 'search';

const getSessionParams = () => {
    const session = localStorage.getItem('session');

    if (!session) return {};

    const sessionValues = JSON.parse(session);
    const {
        branch_offices = [],
        role_level = '',
        settings: { has_limited_contacts = false },
    } = sessionValues;

    return {
        branch_ids: branch_offices.map(({ id }) => id).join(','),
        role_type: role_level,
        has_limited_contacts,
    };
};

export const fetchContacts = ({ page = 1, page_size = 25, q = '' }) =>
    API.get(`/api/v1/${ENTITY}/contacts`, {
        params: {
            page,
            page_size,
            q,
            ...getSessionParams(),
        },
    });
export const fetchProjects = ({ page = 1, page_size = 25, q = '' }) =>
    API.get(`/api/v1/${ENTITY}/projects`, {
        params: { page, page_size, q, ...getSessionParams() },
    });
export const fetchProposals = ({ page = 1, page_size = 25, q = '' }) =>
    API.get(`/api/v1/${ENTITY}/proposals`, {
        params: { page, page_size, q, ...getSessionParams() },
    });
