import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import Logo from '../../layouts/defaultLayout/Logo';

const Wrapper = styled('div')`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: ${({ wrapperHeight }) => wrapperHeight};
    justify-content: center;
    opacity: 0.4;
    width: ${({ wrapperWidth }) => wrapperWidth};
`;

const Container = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
`;

const ImageContainer = styled('div')`
    @keyframes PlaceholderProccessViewRotate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    display: flex;
    filter: grayscale(100%);
    height: ${({ iconSize }) => iconSize};
    position: relative;
    width: ${({ iconSize }) => iconSize};

    img,
    svg {
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-name: PlaceholderProccessViewRotate;
    }
`;

const MessageText = styled('span')`
    color: #9a9a9a;
    font-size: 16px;
    letter-spacing: 0.01rem;
    line-height: 18px;
    margin-top: 10px;
    min-height: 18px;
    text-align: center;
    width: 100%;
`;

const PlaceholderProccessView = ({
    iconSize = '64px',
    text = '',
    wrapperHeight = '100%',
    wrapperWidth = '100%',
}) => {
    return (
        <Wrapper {...{ wrapperHeight, wrapperWidth }}>
            <Container>
                <ImageContainer iconSize={iconSize}>
                    <Logo field="loading_image" />
                </ImageContainer>
                {text !== '' && <MessageText>{text}</MessageText>}
            </Container>
        </Wrapper>
    );
};

PlaceholderProccessView.propTypes = {
    iconSize: PropTypes.string,
    text: PropTypes.string,
    wrapperHeight: PropTypes.string,
    wrapperWidth: PropTypes.string,
};

export default PlaceholderProccessView;
