import React from 'react';

import ImageIcon from '@mui/icons-material/Image';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Card, Divider, Grid } from 'sunwise-ui';

import { INFONAVIT_TYPE, INSURANCE_TYPE } from 'common/constants';
import {
    getCurrencyIso,
    getCurrencySymbol,
    handleFileURL,
    numberFormat,
} from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import KeyValue from './KeyValue';

const AllianceDetailsRequest = ({
    creditItem,
    creditType,
    currency,
    infonavitData,
    primaTotal,
    subTotal,
}) => {
    const { t } = useTranslation();

    const { financier_installer } = creditItem;
    const parameters = get(creditItem, 'parameters', {});
    const { amount = 0, rate = 0, term = 0, hitch = 0 } = parameters;

    const currencyIso = getCurrencyIso(currency);
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const currencySymbol = getCurrencySymbol(currency);

    let content = null;

    switch (creditType) {
        case INFONAVIT_TYPE:
            content = (
                <Box sx={{ mt: '20px' }}>
                    <Grid container>
                        <Grid item xs>
                            <KeyValue
                                title={t('Proposal total')}
                                value={numberFormat(amount, {
                                    currency: currencyIso,
                                    locale: countryCurrencyLocale,
                                    style: 'currency',
                                    symbol: currencySymbol,
                                })}
                            />
                        </Grid>

                        <Grid item xs>
                            <KeyValue
                                title={t('INFONAVIT amount')}
                                value={numberFormat(
                                    infonavitData.total_amount,
                                    {
                                        currency: currencyIso,
                                        locale: countryCurrencyLocale,
                                        style: 'currency',
                                        symbol: currencySymbol,
                                    },
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <KeyValue title="NSS" value={infonavitData.nss} />
                        </Grid>

                        <Grid item xs>
                            <KeyValue
                                title={`${t('Retention')} 5%`}
                                value={numberFormat(
                                    infonavitData.retention_value,
                                    {
                                        currency: currencyIso,
                                        locale: countryCurrencyLocale,
                                        style: 'currency',
                                        symbol: currencySymbol,
                                    },
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <KeyValue
                                title={t('Birthday')}
                                value={infonavitData.birthday}
                            />
                        </Grid>

                        <Grid item xs>
                            <KeyValue
                                title={t('Administrative expenses')}
                                value={numberFormat(
                                    infonavitData.administratives_expenses_percentage_value,
                                    {
                                        currency: currencyIso,
                                        locale: countryCurrencyLocale,
                                        style: 'currency',
                                        symbol: currencySymbol,
                                    },
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <KeyValue
                                title={t('Available balance')}
                                value={numberFormat(
                                    infonavitData.available_balance,
                                    {
                                        currency: currencyIso,
                                        locale: countryCurrencyLocale,
                                        style: 'currency',
                                        symbol: currencySymbol,
                                    },
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
            );
            break;
        case INSURANCE_TYPE:
            content = (
                <Box sx={{ mt: '20px' }}>
                    <Grid container>
                        <Grid item xs>
                            <KeyValue
                                title={t('Total to be insured')}
                                value={numberFormat(subTotal, {
                                    currency: currencyIso,
                                    locale: countryCurrencyLocale,
                                    style: 'currency',
                                    symbol: currencySymbol,
                                })}
                            />
                        </Grid>
                        <Grid item xs>
                            <KeyValue
                                append={t('Month', {
                                    count: parseInt(term),
                                })}
                                title={t('Term')}
                                value={term}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container>
                        <Grid item>
                            <KeyValue
                                title={t('Total premium')}
                                value={numberFormat(primaTotal, {
                                    currency: currencyIso,
                                    locale: countryCurrencyLocale,
                                    style: 'currency',
                                    symbol: currencySymbol,
                                })}
                            />
                        </Grid>
                    </Grid>
                </Box>
            );
            break;
        default:
            content = (
                <Box sx={{ mt: '20px' }}>
                    <Grid container>
                        <Grid item xs>
                            <KeyValue
                                title={t('Total')}
                                value={numberFormat(amount, {
                                    currency: currencyIso,
                                    locale: countryCurrencyLocale,
                                    style: 'currency',
                                    symbol: currencySymbol,
                                })}
                            />
                        </Grid>
                        <Grid item xs>
                            <KeyValue
                                title={t('Total to be financed')}
                                value={numberFormat(amount - hitch, {
                                    currency: currencyIso,
                                    locale: countryCurrencyLocale,
                                    style: 'currency',
                                    symbol: currencySymbol,
                                })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container>
                        <Grid item xs>
                            <KeyValue
                                title={t('Down payment')}
                                value={numberFormat(hitch, {
                                    currency: currencyIso,
                                    locale: countryCurrencyLocale,
                                    style: 'currency',
                                    symbol: currencySymbol,
                                })}
                            />
                        </Grid>
                        <Grid item xs>
                            <KeyValue
                                title={t('Interest rate')}
                                value={`${rate}%`}
                            />
                        </Grid>
                        <Grid item xs>
                            <KeyValue
                                append={t('Month', {
                                    count: parseInt(term),
                                })}
                                title={t('Term')}
                                value={term}
                            />
                        </Grid>
                    </Grid>
                </Box>
            );
            break;
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '-28px',
                    left: '16px',
                }}
            >
                <Avatar
                    src={handleFileURL(
                        get(financier_installer, 'financier.image', ''),
                        import.meta.env.VITE_S3_MEDIA_PATH,
                    )}
                    sx={{
                        height: 56,
                        width: 56,
                    }}
                >
                    <ImageIcon />
                </Avatar>
            </Box>
            <Card>
                <Card.Body>{content}</Card.Body>
            </Card>
        </Box>
    );
};

AllianceDetailsRequest.propTypes = {
    creditItem: PropTypes.object,
    creditType: PropTypes.number,
    currency: PropTypes.object,
    infonavitData: PropTypes.object,
    primaTotal: PropTypes.number,
    subTotal: PropTypes.number,
};

export default AllianceDetailsRequest;
