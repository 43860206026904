import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT, TIMEZONE_REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        address: string().nullable(),
        latitude: number().required(REQUIRED_TEXT),
        longitude: number().required(REQUIRED_TEXT),
        start_date: string().required(REQUIRED_TEXT),
        time_zone: string().required(TIMEZONE_REQUIRED_TEXT),
    });
};
