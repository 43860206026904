import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Skeleton } from 'sunwise-ui';

import EmptyMessage from '../EmptyMessage';

const Placeholder = (
    <Card>
        <Card.Body>
            <Skeleton variant="rounded" />
        </Card.Body>
    </Card>
);

const PlaceholderCardOffer = ({ children, ready, hasContent = true }) => {
    const { t } = useTranslation();
    if (ready) return <>{Placeholder}</>;
    if (hasContent) return <>{children}</>;
    return <EmptyMessage text={t('There are no elements to show')} />;
};

PlaceholderCardOffer.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
    hasContent: PropTypes.bool,
};

export default PlaceholderCardOffer;
