import { update } from 'common/api/v1/equipmentCompatibility';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { equipmentCompatibilityActions } from '../reducer';

import fetchCompatibilityData from './fetchCompatibilityData';

export default (values, setValue) => (dispatch) => {
    let newValues = {
        oversize: values.oversize,
        product_panel: values.product_panel,
        product_inverter: values.product_inverter,
        political_division1: values.political_division1,
        political_division2: values.political_division2,
        temp_min: values.temp[0],
        temp_max: values.temp[1],
    };

    dispatch(equipmentCompatibilityActions[SAVE_ITEM]());

    update(newValues)
        .then((response) => {
            dispatch(
                equipmentCompatibilityActions[SAVE_ITEM_SUCCESS](response.data),
            );

            dispatch(
                fetchCompatibilityData(
                    values.product_panel,
                    values.product_inverter,
                    values.political_division1,
                    values.political_division2,
                    setValue,
                ),
            );

            showToast();
        })
        .catch((error) => {
            dispatch(
                equipmentCompatibilityActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
