import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid } from 'sunwise-ui';

import PlaceholderCardListItem from 'common/components/placeholder/PlaceholderCardListItem';

import companyGeneral from '../../companyGeneral';
import * as actions from '../actions';
import * as selectors from '../selectors';

import RateListContent from './RateListContent';

const RateList = ({
    businessTypeSelected,
    canModify,
    canDelete,
    companyCountry,
    filterName,
    isEnabled,
    isFetchingCompany,
    isFetchingCurrencies,
    isFetchingRates,
    isFetchingSupplierUtilities,
    prepareNewRate,
    rateTypeSelected,
    voltageTypeSelected,
}) => {
    const { t } = useTranslation();
    return (
        <Box>
            <Grid container>
                <Grid item xs textAlign="right">
                    <Button
                        disabled={
                            isFetchingCompany ||
                            isFetchingCurrencies ||
                            isFetchingSupplierUtilities ||
                            !companyCountry
                        }
                        onClick={() => prepareNewRate(companyCountry)}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        type="button"
                        visible={canModify}
                    >
                        {t('Add')}
                    </Button>
                </Grid>
            </Grid>

            <PlaceholderCardListItem ready={!isFetchingRates}>
                <RateListContent
                    businessTypeSelected={businessTypeSelected}
                    canDelete={canDelete}
                    canModify={canModify}
                    filterName={filterName}
                    isEnabled={isEnabled}
                    rateTypeSelected={rateTypeSelected}
                    voltageTypeSelected={voltageTypeSelected}
                />
            </PlaceholderCardListItem>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    companyCountry: companyGeneral.selectors.getCompanyCountry,
    isFetchingCompany: companyGeneral.selectors.getIsFetching,
    isFetchingCurrencies: selectors.getIsFetchingCurrencies,
    isFetchingRates: selectors.getIsFetchingRates,
    isFetchingSupplierUtilities: selectors.getIsFetchingSupplierUtilities,
});

const mapDispatchToProps = (dispatch) => ({
    prepareNewRate: (country) => dispatch(actions.prepareNewRate(country)),
});

RateList.propTypes = {
    businessTypeSelected: PropTypes.string,
    canModify: PropTypes.bool,
    canDelete: PropTypes.bool,
    companyCountry: PropTypes.string,
    filterName: PropTypes.string,
    isEnabled: PropTypes.bool,
    isFetchingCompany: PropTypes.bool,
    isFetchingCurrencies: PropTypes.bool,
    isFetchingRates: PropTypes.bool,
    isFetchingSupplierUtilities: PropTypes.bool,
    prepareNewRate: PropTypes.func,
    rateTypeSelected: PropTypes.string,
    voltageTypeSelected: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(RateList);
