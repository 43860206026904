import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import regenerateFinancialTemplate from './regenerateFinancialTemplate';

export default ({
        financingTemplateId,
        initTemplate,
        proposalId,
        resetTemplate,
    }) =>
    (dispatch) => {
        const dataAlert = {
            confirmText: i18next.t('Regenerate'),
            messages: [
                i18next.t('Regenerating the template will restore all changes'),
            ],
            onSuccess: () => {
                if (resetTemplate) resetTemplate();
                dispatch(
                    regenerateFinancialTemplate({
                        financingTemplateId,
                        initTemplate,
                        proposalId,
                    }),
                );
            },
            title: i18next.t('Confirmation'),
            type: alerts.ALERT_TYPE_CONFIRM,
        };

        dispatch(alerts.actions.show(dataAlert));
    };
