import React from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';

const DateTooltip = ({ createdAt }) => {
    const { t } = useTranslation();
    const date = parseDate(createdAt, 'dd/MM/yyyy HH:mm:ss');
    return (
        <Tooltip
            placement="top"
            title={`${t('Created at')} ${formatDate(date, 'PP')}`}
        >
            <Box alignItems="center" display="flex" gap={1}>
                <CalendarTodayIcon fontSize="small" />
                <Typography variant="caption">
                    {formatDate(date, 'LLL dd')}
                </Typography>
            </Box>
        </Tooltip>
    );
};

DateTooltip.propTypes = {
    createdAt: PropTypes.string,
};

export default DateTooltip;
