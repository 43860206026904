import { getPanelBrands } from 'common/api/v1/onboarding';

import {
    FETCH_PANEL_BRANDS,
    FETCH_PANEL_BRANDS_FAILURE,
    FETCH_PANEL_BRANDS_SUCCESS,
} from '../actionTypes';
import { onboardingWizardActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(onboardingWizardActions[FETCH_PANEL_BRANDS]());

    getPanelBrands()
        .then((response) => {
            dispatch(
                onboardingWizardActions[FETCH_PANEL_BRANDS_SUCCESS](
                    response.data,
                ),
            );
        })
        .catch((error) => {
            dispatch(
                onboardingWizardActions[FETCH_PANEL_BRANDS_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
        });
};
