import React from 'react';

import PropTypes from 'prop-types';
import { Skeleton } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';
import { hexToRgba } from 'common/utils/helpersChart';

import EmptyChart from './EmptyChart';
import KeyIndicators from './KeyIndicators';
import Table from './Table';

const hasData = (series) => series?.some((serie) => serie.data.length);

const ChartComponent = ({
    chartConfig,
    chartType,
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    emptyDescription,
    formConfig,
    isCurrency,
    isLoading,
    showIndicators = true,
    tableDateLabelDefault,
    unit,
}) => {
    if (isLoading) return <Skeleton height={500} variant="rounded" />;
    if (!hasData(chartConfig?.series))
        return <EmptyChart description={emptyDescription} />;

    const colors = chartConfig.options.colors;
    const data = {
        datasets: chartConfig.series.map(({ data, name }, index) => ({
            backgroundColor: hexToRgba(colors[index % colors.length], 0.8),
            borderColor: colors[index % colors.length],
            data,
            fill: Boolean(chartConfig.options?.fill),
            label: name,
        })),
        labels: chartConfig.options.xaxis.categories,
    };
    const options = chartConfig.options.chartJsOptions;
    const type = chartType === 'area' ? 'line' : chartType;

    return (
        <>
            {chartType === 'table' ? (
                <Table
                    categories={chartConfig.categories}
                    countryCurrencyLocale={countryCurrencyLocale}
                    series={chartConfig.series}
                    viewOption={
                        tableDateLabelDefault ? null : formConfig?.view_option
                    }
                />
            ) : (
                <ChartJs
                    data={data}
                    height="300px"
                    options={options}
                    type={type}
                />
            )}

            {showIndicators && (
                <KeyIndicators
                    countryCurrencyIso={countryCurrencyIso}
                    countryCurrencyLocale={countryCurrencyLocale}
                    countryCurrencySymbol={countryCurrencySymbol}
                    isCurrency={isCurrency}
                    series={chartConfig.series}
                    unit={unit}
                    viewOption={formConfig?.view_option}
                />
            )}
        </>
    );
};

ChartComponent.propTypes = {
    chartConfig: PropTypes.object,
    chartType: PropTypes.string,
    countryCurrencyIso: PropTypes.string,
    countryCurrencyLocale: PropTypes.string,
    countryCurrencySymbol: PropTypes.string,
    emptyDescription: PropTypes.string,
    formConfig: PropTypes.object,
    isCurrency: PropTypes.bool,
    isLoading: PropTypes.bool,
    showIndicators: PropTypes.bool,
    tableDateLabelDefault: PropTypes.bool,
    unit: PropTypes.string,
};

export default ChartComponent;
