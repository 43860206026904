import React from 'react';

import { Box, Button, Typography } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import NotFoundIcon from 'resources/notFoundIcon.svg';

import { RATE_TYPES } from '../constants';

const DataMismatchWarning = ({
    canModifyRates,
    isLocked,
    onClickResetRateConcepts,
}) => {
    const { t } = useTranslation();
    return (
        <Box alignItems="center" display="flex" flexDirection="column" gap={2}>
            <Typography variant="body2">
                {t(
                    'This data is out of sync. Restore the rate concepts to view them correctly',
                )}
            </Typography>

            <Box component="img" src={NotFoundIcon} sx={{ height: 50 }} />
            {canModifyRates && (
                <Button
                    disabled={isLocked}
                    onClick={() =>
                        onClickResetRateConcepts([
                            RATE_TYPES.CURRENT,
                            RATE_TYPES.NEXT,
                        ])
                    }
                    variant="outlined"
                >
                    {t('Restore')}
                </Button>
            )}
        </Box>
    );
};

DataMismatchWarning.propTypes = {
    canModifyRates: PropTypes.bool,
    isLocked: PropTypes.bool,
    onClickResetRateConcepts: PropTypes.func,
};

export default DataMismatchWarning;
