import { getData } from 'common/api/v1/report';

import {
    FETCH_REPORT_DETAILS,
    FETCH_REPORT_DETAILS_FAILURE,
    FETCH_REPORT_DETAILS_SUCCESS,
} from '../actionTypes';
import { reportReviewProActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(reportReviewProActions[FETCH_REPORT_DETAILS]());

    getData(id)
        .then((response) =>
            dispatch(
                reportReviewProActions[FETCH_REPORT_DETAILS_SUCCESS](
                    response?.data?.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                reportReviewProActions[FETCH_REPORT_DETAILS_FAILURE](error),
            ),
        );
};
