import { updateItem } from 'common/api/v1/companiesCurrencies';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import fetchCompanyData from '../../companyGeneral/actions/fetchCompanyData';
import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { multiCurrencyActions } from '../reducer';

import prepareUpdate from './prepareUpdate';
import updateList from './updateList';

const calculateAdditionValue = (values) => {
    let addition_value = 0;
    if (values.exchange_type === 'AUT')
        addition_value =
            values.addition_type === 'PERC'
                ? (values.addition_value || 0) / 100
                : values.addition_value || 0;
    return addition_value;
};

export default (values, items) => (dispatch) => {
    const id = values.id;
    const newValues = {
        ...values,
        addition_value: calculateAdditionValue(values),
    };

    dispatch(multiCurrencyActions[SAVE_ITEM]());

    updateItem(newValues, id)
        .then((response) => {
            dispatch(multiCurrencyActions[SAVE_ITEM_SUCCESS](response.data));

            dispatch(
                updateList(items, response.data, response.data.is_enabled),
            );

            const item = items.find((item) => item.id === id);

            const {
                addition_type,
                addition_value,
                discount_rate,
                dollar_price,
                exchange_type,
                inverse_dollar_price,
                is_enabled,
                original_dollar_price,
                source,
            } = response.data;

            const newValue = {
                ...item,
                addition_type,
                addition_value,
                discount_rate,
                dollar_price,
                exchange_type,
                inverse_dollar_price,
                is_enabled,
                original_dollar_price,
                source,
            };

            dispatch(prepareUpdate(newValue));

            dispatch(fetchCompanyData());

            showToast();
        })
        .catch((error) => {
            dispatch(
                multiCurrencyActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
