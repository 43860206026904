import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

import * as actions from '../actions';
import { getPlansToSelect } from '../helpers';

const PlanFields = ({ control, fetchPlan, isLocked, plans, setValue }) => {
    const { t } = useTranslation();
    const handleOnChange = ({ target: { value } }) => {
        const plan = plans.find((item) => item.id === value);
        if (plan) {
            setValue('custom_offer_template', plan.custom_offer_template);
            fetchPlan(plan.id, setValue);
        }
    };

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Plan', { count: 2 })}
                </TitleWithDetail>
            </Box>

            <ReactHookFormSelect
                control={control}
                disabled={isLocked}
                label={t('Plan type')}
                name="proposal_plan"
                onChange={handleOnChange}
                options={getPlansToSelect(plans)}
            />
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    fetchPlan: (id, setValue) => dispatch(actions.fetchPlan(id, setValue)),
});

PlanFields.propTypes = {
    control: PropTypes.object,
    fetchPlan: PropTypes.func,
    isLocked: PropTypes.bool,
    plans: PropTypes.array,
    setValue: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(PlanFields);
