import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
    INITIALIZE_FORM,
    INITIALIZE_MARGIN_FORM,
    OPEN_MODAL_MARGIN,
    OPEN_MODAL_PRODUCT,
    RESET_FORM,
    RESET_MARGIN_FORM,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SAVE_BULK_MARGIN,
    SAVE_BULK_MARGIN_FAILURE,
    SAVE_BULK_MARGIN_SUCCESS,
    SET_IS_DOWNLOADING,
    SET_IS_NEW_RECORD,
    SET_IS_UPLOADING,
    UPDATE_ITEMS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    bulkUpdateItems: {
        errors: null,
        isFetching: false,
    },
    fetchItems: {
        data: {
            data: [],
            pagination: null,
        },
        errors: null,
        isFetching: false,
    },
    initialMarginValues: {
        ids: [],
        margin: 0,
    },
    initialValues: {
        branch_offices: '',
        brand_name: '',
        code: null,
        costing_unit: 0,
        currency: null,
        description: null,
        disabled: false,
        id: null,
        image: null,
        name: null,
        range_prices: [
            {
                cost: 0,
                final_cost: 0,
                is_unique_price: false,
                margin: 0,
                price_from: 1,
                price_to: 10,
            },
        ],
        status_flag: '',
    },
    isDownloading: false,
    isNewRecord: true,
    isOpenModalMargin: false,
    isOpenModalProduct: false,
    isUploading: false,
    saveItem: {
        errors: null,
        isSaving: false,
    },
    saveMargin: {
        errors: null,
        isSaving: false,
    },
};

const additionalConfigurationSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isSaving: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isSaving: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                data: action.payload,
                isSaving: false,
            };
        },
        [FETCH_ITEMS]: (state) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ITEMS_FAILURE]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_SUCCESS]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [INITIALIZE_MARGIN_FORM]: (state, action) => {
            state.initialMarginValues = {
                ...state.initialMarginValues,
                ...action.payload,
            };
        },
        [OPEN_MODAL_MARGIN]: (state, action) => {
            state.isOpenModalMargin = action.payload;
        },
        [OPEN_MODAL_PRODUCT]: (state, action) => {
            state.isOpenModalProduct = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.saveItem = INITIAL_STATE.saveItem;
        },
        [RESET_MARGIN_FORM]: (state) => {
            state.initialMarginValues = INITIAL_STATE.initialMarginValues;
        },
        [SAVE_BULK_MARGIN]: (state) => {
            state.saveMargin = {
                ...state.saveMargin,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_BULK_MARGIN_FAILURE]: (state, action) => {
            state.saveMargin = {
                ...state.saveMargin,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_BULK_MARGIN_SUCCESS]: (state) => {
            state.saveMargin = {
                ...state.saveMargin,
                isSaving: false,
            };
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state) => {
            state.saveItem = {
                ...state.saveItem,
                isSaving: false,
            };
        },
        [SET_IS_DOWNLOADING]: (state, action) => {
            state.isDownloading = action.payload;
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [SET_IS_UPLOADING]: (state, action) => {
            state.isUploading = action.payload;
        },
        [UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.fetchItems.data.data = action.payload;
        },
    },
});

export const additionalConfigurationActions =
    additionalConfigurationSlice.actions;

export default additionalConfigurationSlice.reducer;
