import { NAME } from './constants';

export const FETCH_CONTACT = `${NAME}/FETCH_CONTACT`;
export const FETCH_CONTACT_FAILURE = `${NAME}/FETCH_CONTACT_FAILURE`;
export const FETCH_CONTACT_SUCCESS = `${NAME}/FETCH_CONTACT_SUCCESS`;

export const FETCH_LOCATION = `${NAME}/FETCH_LOCATION`;
export const FETCH_LOCATION_ERROR = `${NAME}/FETCH_LOCATION_ERROR`;
export const FETCH_LOCATION_SUCCESS = `${NAME}/FETCH_LOCATION_SUCCESS`;

export const FETCH_STATUS_CONTACT = `${NAME}/FETCH_STATUS_CONTACT`;
export const FETCH_STATUS_CONTACT_FAILURE = `${NAME}/FETCH_STATUS_CONTACT_FAILURE`;
export const FETCH_STATUS_CONTACT_SUCCESS = `${NAME}/FETCH_STATUS_CONTACT_SUCCESS`;

export const MARK_FAVORITE = `${NAME}/MARK_FAVORITE`;
export const MARK_FAVORITE_FAILURE = `${NAME}/MARK_FAVORITE_FAILURE`;
export const MARK_FAVORITE_SUCCESS = `${NAME}/MARK_FAVORITE_SUCCESS`;

export const UPDATE_CONTACTS_TYPE = `${NAME}/UPDATE_CONTACTS_TYPE`;
export const UPDATE_CONTACTS_TYPE_FAILURE = `${NAME}/UPDATE_CONTACTS_TYPE_FAILURE`;
export const UPDATE_CONTACTS_TYPE_SUCCESS = `${NAME}/UPDATE_CONTACTS_TYPE_SUCCESS`;

export const SAVE_POSITION = `${NAME}/SAVE_POSITION`;
export const SAVE_POSITION_ERROR = `${NAME}/SAVE_POSITION_ERROR`;
export const SAVE_POSITION_SUCCESS = `${NAME}/SAVE_POSITION_SUCCESS`;
