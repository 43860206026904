import { bulkTrackings } from 'common/api/v1/followUps';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_BULK_TRACKINGS,
    SAVE_BULK_TRACKINGS_FAILURE,
    SAVE_BULK_TRACKINGS_SUCCESS,
} from '../actionTypes';
import { followUpsActions } from '../reducer';

import closeModalTracking from './closeModalTracking';

export default (filterData, values) => (dispatch) => {
    dispatch(followUpsActions[SAVE_BULK_TRACKINGS]());

    const newValues = {
        notification_at: values.notification_at?.concat(':00'),
        trackings: values.trackings,
    };

    bulkTrackings(newValues)
        .then(() => {
            dispatch(followUpsActions[SAVE_BULK_TRACKINGS_SUCCESS]());
            dispatch(closeModalTracking(filterData));
            if (values.callback) values.callback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                followUpsActions[SAVE_BULK_TRACKINGS_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
