import { deleteItem } from 'common/api/v2/generationProfiles';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SAVE_GENERATION_PROFILE_FAILURE } from '../actionTypes';
import { solarGenerationActions } from '../reducer';

import saveGenerationProfile from './saveGenerationProfile';

export default (getValues, id, values) => (dispatch) => {
    deleteItem(id)
        .then(() => dispatch(saveGenerationProfile(getValues, values)))
        .catch((error) => {
            dispatch(
                solarGenerationActions[SAVE_GENERATION_PROFILE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
