import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getFetchDataModel = createSelector(
    getModel,
    (model) => model.fetchData,
);

export const getFetchDataData = createSelector(
    getFetchDataModel,
    (model) => model.data,
);

export const getAfterSalesSettingsDataId = createSelector(
    getFetchDataData,
    (data) => data?.id,
);

export const getAfterSalesSettingsCommercialOfferId = createSelector(
    getFetchDataData,
    (data) => data?.commercial_offer?.id,
);

export const getAfterSalesSettingsCompensationScheme = createSelector(
    getFetchDataData,
    (data) => data?.compensation_scheme,
);

export const getAfterSalesSettingsCurrency = createSelector(
    getFetchDataData,
    (data) => data?.currency,
);

export const getAfterSalesSettingsExportedEnergyPrice = createSelector(
    getFetchDataData,
    (data) => data?.exported_energy_price,
);

export const getAfterSalesSettingsPPAValues = createSelector(
    getFetchDataData,
    (data) => ({
        active: data?.ppa_active,
        percentageIncrease: data?.ppa_percentage_increase,
        price: data?.ppa_price,
    }),
);

export const getAfterSalesSettingsPoliticalDivision1 = createSelector(
    getFetchDataData,
    (data) => data?.political_division1?.id,
);

export const getAfterSalesRateDivision = createSelector(
    getFetchDataData,
    (data) => data?.rate_division?.id,
);

export const getAfterSalesSettingsRate = createSelector(
    getFetchDataData,
    (data) => data?.rate || null,
);

export const getAfterSalesServiceNumber = createSelector(
    getFetchDataData,
    (data) => data?.service_number?.replace(/ /g, ''),
);

export const getAfterSalesStartDate = createSelector(
    getFetchDataData,
    (data) => data?.start_date,
);

export const getAfterSalesSettingsTotalCost = createSelector(
    getFetchDataData,
    (data) => data?.total_cost || 0,
);

export const getFetchDataErrors = createSelector(
    getFetchDataModel,
    (model) => model.errors,
);

export const getFetchDataIsLoading = createSelector(
    getFetchDataModel,
    (model) => model.isLoading,
);

export const getIsModalFormOpen = createSelector(
    getModel,
    (model) => model.isModalFormOpen,
);

export const getSaveDataModel = createSelector(
    getModel,
    (model) => model.saveData,
);

export const getIsSaving = createSelector(
    getSaveDataModel,
    (model) => model.isLoading,
);

/* Political divisions */
export const getPoliticalDivisions1Model = createSelector(
    getModel,
    (model) => model.politicalDivisions1,
);

export const getPoliticalDivisions1Data = createSelector(
    getPoliticalDivisions1Model,
    (model) => model.data || [],
);

export const getPoliticalDivisions1ForSelect = createSelector(
    getPoliticalDivisions1Data,
    (data) => data.map(({ id, name }) => ({ label: name, value: id })),
);

export const getPoliticalDivisions1Errors = createSelector(
    getPoliticalDivisions1Model,
    (model) => model.errors,
);

export const getPoliticalDivisions1IsLoading = createSelector(
    getPoliticalDivisions1Model,
    (model) => model.isLoading,
);

export const getPoliticalDivisions2Model = createSelector(
    getModel,
    (model) => model.politicalDivisions2,
);

export const getPoliticalDivisions2Data = createSelector(
    getPoliticalDivisions2Model,
    (model) => model.data || [],
);

export const getPoliticalDivisions2ForSelect = createSelector(
    getPoliticalDivisions2Data,
    (data) => data.map(({ id, name }) => ({ label: name, value: id })),
);

export const getPoliticalDivisions2Errors = createSelector(
    getPoliticalDivisions2Model,
    (model) => model.errors,
);

export const getPoliticalDivisions2IsLoading = createSelector(
    getPoliticalDivisions2Model,
    (model) => model.isLoading,
);

/* Location by zip code */
export const getLocationsByZipCodeModel = createSelector(
    getModel,
    (model) => model.locationsByZipCode,
);

export const getLocationsByZipCodeData = createSelector(
    getLocationsByZipCodeModel,
    (model) => model.data || [],
);

export const getLocationsByZipCodeForSelect = createSelector(
    getLocationsByZipCodeData,
    (data) =>
        data.map(({ municipality }) => ({
            label: municipality?.name,
            value: municipality?.id,
        })),
);

export const getLocationsByZipCodeErrors = createSelector(
    getLocationsByZipCodeModel,
    (model) => model.errors,
);

export const getLocationsByZipCodeIsLoading = createSelector(
    getLocationsByZipCodeModel,
    (model) => model.isLoading,
);

/* Schedule rate configuration */
export const getScheduleRateConfigurationModel = createSelector(
    getModel,
    (model) => model.scheduleRateConfiguration,
);

export const getScheduleRateConfiguration = createSelector(
    getModel,
    (model) => model?.scheduleRateConfiguration?.data || {},
);
export const getIsFetchingScheduleRateConfiguration = createSelector(
    getModel,
    (model) => model?.scheduleRateConfiguration?.isFetching,
);

/* Customer consumption */
export const getCustomerConsumptionModel = createSelector(
    getModel,
    (model) => model.customerConsumption,
);

export const getCustomerConsumptionData = createSelector(
    getCustomerConsumptionModel,
    (model) => model?.data || [],
);

/* Rate divisions */
export const getRateDivisionsModel = createSelector(
    getModel,
    (model) => model.rateDivisions,
);

export const getRateDivisionsData = createSelector(
    getRateDivisionsModel,
    (model) => model?.data || [],
);

export const getRateDivisionsErrors = createSelector(
    getRateDivisionsModel,
    (model) => model?.errors,
);

export const getRateDivisionsIsLoading = createSelector(
    getRateDivisionsModel,
    (model) => model?.isFetching,
);

export const getRateDivisionsForSelect = createSelector(
    getRateDivisionsData,
    (data) => data.map(({ id, name }) => ({ label: name, value: id })),
);

/* Regions */
export const getRegionsModel = createSelector(
    getModel,
    (model) => model.regions,
);

export const getRegionsData = createSelector(
    getRegionsModel,
    (model) => model?.data || [],
);

export const getRegionsErrors = createSelector(
    getRegionsModel,
    (model) => model?.errors,
);

export const getRegionsIsLoading = createSelector(
    getRegionsModel,
    (model) => model?.isFetching,
);

export const getRegionsForSelect = createSelector(getRegionsData, (data) =>
    data.map(({ id, name }) => ({ label: name, value: id })),
);

/* Summer months */
export const getSummerMonthsModel = createSelector(
    getModel,
    (model) => model.summerMonths,
);

export const getSummerMonthsData = createSelector(
    getSummerMonthsModel,
    (model) => model?.data || [],
);

export const getSummerMonthsErrors = createSelector(
    getSummerMonthsModel,
    (model) => model?.errors,
);

export const getSummerMonthsIsLoading = createSelector(
    getSummerMonthsModel,
    (model) => model?.isFetching,
);

export const getSummerMonthsForSelect = createSelector(
    getSummerMonthsData,
    (data) => data.map(({ id, name }) => ({ label: name, value: id })),
);

/* Rates */
export const getRatesModel = createSelector(getModel, (model) => model.rates);

export const getRatesData = createSelector(
    getRatesModel,
    (model) => model?.data || [],
);

export const getRatesErrors = createSelector(
    getRatesModel,
    (model) => model?.errors,
);

export const getRatesIsLoading = createSelector(
    getRatesModel,
    (model) => model?.isFetching,
);

export const getRatesForSelect = createSelector(getRatesData, (data) => {
    const { certified_rates, custom_rates } = data;

    const rates = [];

    if (certified_rates?.length)
        rates.push({
            label: i18next.t('Certificated'),
            options: certified_rates
                .filter((rate) => rate.is_enable)
                .map(({ id, name }) => ({
                    label: name,
                    value: id,
                })),
        });

    if (custom_rates?.length)
        rates.push({
            label: i18next.t('Custom', { context: 'female', count: 2 }),
            options: custom_rates
                .filter((rate) => rate?.properties?.is_enable)
                .map(({ id, properties }) => ({
                    label: properties?.name,
                    value: id,
                })),
        });

    return rates;
});

export const getRatesDictionary = createSelector(getRatesData, (data) => {
    const { certified_rates, custom_rates } = data;

    let rates = {};

    if (certified_rates?.length)
        for (const rate of certified_rates) {
            rates[rate.id] = { ...rate };
            rates[rate.id].certified = true;
        }

    if (custom_rates?.length)
        for (const rate of custom_rates) {
            rates[rate.id] = {
                ...rate.properties,
                paymentType: rate.payment_type,
                default_compensation_scheme: rate.default_compensation_scheme,
                formType: rate.form_type,
                id: rate.id,
            };
            rates[rate.id].certified = false;
        }

    return rates;
});

export const getRatesDictionaryByName = createSelector(getRatesData, (data) => {
    const { certified_rates, custom_rates } = data;

    let rates = {};

    if (certified_rates?.length)
        for (const rate of certified_rates) {
            rates[rate.name] = { ...rate };
            rates[rate.name].certified = true;
        }

    if (custom_rates?.length)
        for (const rate of custom_rates) {
            const rateName = rate.properties?.name || rate.name;
            rates[rateName] = {
                ...rate.properties,
                default_compensation_scheme: rate.default_compensation_scheme,
                formType: rate.form_type,
                id: rate.id,
            };
            rates[rateName].certified = false;
        }

    return rates;
});
