import API from '../..';
import openAPI from '../../openAPI';

const ENTITY = 'new-offer-ended';

export const getOfferEnded = (commercialOfferId) =>
    API.get(`/api/v1/${ENTITY}/${commercialOfferId}`);
export const updateOfferEnded = (id, data) =>
    API.put(`/api/v1/${ENTITY}/${id}`, data);
export const getOfferEndedWithoutToken = (commercialOfferId) =>
    openAPI.get(`/api/v1/sunpal-${ENTITY}/${commercialOfferId}`);
