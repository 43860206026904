import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_AFTER_SALES_PROJECTS,
    FETCH_AFTER_SALES_PROJECTS_FAILURE,
    FETCH_AFTER_SALES_PROJECTS_SUCCESS,
    FETCH_POWER_STATIONS,
    FETCH_POWER_STATIONS_FAILURE,
    FETCH_POWER_STATIONS_SUCCESS,
    FETCH_PROJECTS,
    FETCH_PROJECTS_FAILURE,
    FETCH_PROJECTS_SUCCESS,
    RESET,
    RESET_ASSIGN_PROJECT,
    SET_ASSIGN_PROJECT_MODAL_IS_OPEN,
    SET_SELECTED_POWER_STATION,
    TOGGLE_ASSIGN_PROJECT,
    TOGGLE_ASSIGN_PROJECT_FAILURE,
    TOGGLE_ASSIGN_PROJECT_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    afterSalesProjects: { data: [], errors: null, isFetching: false },
    assignProjectModalIsOpen: false,
    powerStations: {
        data: { columns: [], data: [], pagination: {} },
        errors: null,
        isFetching: false,
    },
    projects: { data: [], errors: null, isFetching: false },
    selectedPowerStation: null,
    toggleAssignProject: { errors: null, isSaving: false },
};

const powerStationsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_AFTER_SALES_PROJECTS]: (state) => {
            state.afterSalesProjects.data = [];
            state.afterSalesProjects.isFetching = true;
            state.afterSalesProjects.errors = null;
        },
        [FETCH_AFTER_SALES_PROJECTS_FAILURE]: (state, action) => {
            state.afterSalesProjects.errors = action.payload;
            state.afterSalesProjects.isFetching = false;
        },
        [FETCH_AFTER_SALES_PROJECTS_SUCCESS]: (state, action) => {
            state.afterSalesProjects.data = action.payload || [];
            state.afterSalesProjects.isFetching = false;
        },
        [FETCH_POWER_STATIONS]: (state) => {
            state.powerStations.isFetching = true;
            state.powerStations.errors = null;
        },
        [FETCH_POWER_STATIONS_FAILURE]: (state, action) => {
            state.powerStations.data.data = [];
            state.powerStations.errors = action.payload;
            state.powerStations.isFetching = false;
        },
        [FETCH_POWER_STATIONS_SUCCESS]: (state, action) => {
            state.powerStations.data = action.payload;
            state.powerStations.isFetching = false;
        },
        [FETCH_PROJECTS]: (state) => {
            state.projects.data = [];
            state.projects.isFetching = true;
            state.projects.errors = null;
        },
        [FETCH_PROJECTS_FAILURE]: (state, action) => {
            state.projects.errors = action.payload;
            state.projects.isFetching = false;
        },
        [FETCH_PROJECTS_SUCCESS]: (state, action) => {
            state.projects.data = action.payload;
            state.projects.isFetching = false;
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_ASSIGN_PROJECT]: (state) => {
            state.toggleAssignProject = INITIAL_STATE.toggleAssignProject;
            state.selectedPowerStation = INITIAL_STATE.selectedPowerStation;
        },
        [SET_ASSIGN_PROJECT_MODAL_IS_OPEN]: (state, action) => {
            state.assignProjectModalIsOpen = action.payload;
        },
        [SET_SELECTED_POWER_STATION]: (state, action) => {
            state.selectedPowerStation = action.payload;
        },
        [TOGGLE_ASSIGN_PROJECT]: (state) => {
            state.toggleAssignProject.isSaving = true;
            state.toggleAssignProject.errors = null;
        },
        [TOGGLE_ASSIGN_PROJECT_FAILURE]: (state, action) => {
            state.toggleAssignProject.errors = action.payload;
            state.toggleAssignProject.isSaving = false;
        },
        [TOGGLE_ASSIGN_PROJECT_SUCCESS]: (state) => {
            state.toggleAssignProject.isSaving = false;
        },
    },
});

export const powerStationsActions = powerStationsSlice.actions;

export default powerStationsSlice.reducer;
