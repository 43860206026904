import React from 'react';

import facebook from 'resources/social/facebook.svg';
import instagram from 'resources/social/instagram.svg';
import linkedin from 'resources/social/linkedin.svg';
import twitter from 'resources/social/twitter.svg';
import youtube from 'resources/social/youtube.svg';

const appendDotsFormatter = (dots) => <ul>{dots}</ul>;

export const sliderSettings = {
    appendDots: appendDotsFormatter,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: () => (
        <div className="ft-slick__dots--custom">
            <div className="loading" />
        </div>
    ),
    dots: true,
    infinite: true,
    pauseOnHover: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
};

export const socialMedia = [
    {
        href: 'https://www.facebook.com/sunwise.io/',
        imgUrl: facebook,
        name: 'Facebook',
    },
    {
        href: 'https://twitter.com/sunwise_io',
        imgUrl: twitter,
        name: 'Twitter',
    },
    {
        href: 'https://www.instagram.com/sunwise.io/',
        imgUrl: instagram,
        name: 'Instagram',
    },
    {
        href: 'https://www.youtube.com/channel/UCm2F_RCUDC7BAhOioCJzUug',
        imgUrl: youtube,
        name: 'YouTube',
    },
    {
        href: 'https://www.linkedin.com/company/sunwisesa/?viewAsMember=true',
        imgUrl: linkedin,
        name: 'LinkedIn',
    },
];
