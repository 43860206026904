import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider } from 'sunwise-ui';

import icoCertifiedRate from 'resources/icoCertifiedRate.svg';
import icoCustomRate from 'resources/icoCustomRate.svg';

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 8px;
`;

const Title = styled('div')`
    color: #202253;
    font-size: 15px;
    font-weight: bold;
`;

const ItemContainer = styled('div')`
    align-items: center;
    display: flex;
    gap: 16px;
    padding: 8px;
`;

const IconContainer = styled('div')`
    display: flex;
    justify-content: center;
`;

const IconImg = styled('img')`
    height: 27px;
    width: 22px;
`;

const ItemText = styled('span')`
    color: #848bab;
    font-size: 14px;
    line-height: 14px;
`;

const DotColor = styled('div')`
    background-color: ${({ color }) => color};
    border-radius: 50%;
    height: 14px;
    width: 14px;
`;

const InfoList = ({ certifiedRatesNumber = 0 }) => {
    const { t } = useTranslation();
    return (
        <Container>
            <Title>{t('Icon', { count: 2 })}</Title>
            {certifiedRatesNumber > 0 && (
                <>
                    <Divider />
                    <ItemContainer>
                        <IconContainer>
                            <IconImg
                                src={icoCertifiedRate}
                                title={t('Certified rate')}
                            />
                        </IconContainer>
                        <ItemText>{t('Certified rate')}</ItemText>
                    </ItemContainer>
                </>
            )}

            <Divider />
            <ItemContainer>
                <IconContainer>
                    <IconImg src={icoCustomRate} title={t('Custom rate')} />
                </IconContainer>
                <ItemText>{t('Custom rate')}</ItemText>
            </ItemContainer>
            <Divider />
            <ItemContainer>
                <IconContainer>
                    <HomeIcon />
                </IconContainer>
                <ItemText>{t('Residential installation')}</ItemText>
            </ItemContainer>
            <Divider />
            <ItemContainer>
                <IconContainer>
                    <StorefrontIcon />
                </IconContainer>
                <ItemText>{t('Commercial installation')}</ItemText>
            </ItemContainer>
            <Divider />
            <ItemContainer>
                <IconContainer>
                    <BusinessIcon />
                </IconContainer>
                <ItemText>{t('Industrial installation')}</ItemText>
            </ItemContainer>
            <Title>{t('Color')}</Title>
            <Divider />
            <ItemContainer>
                <IconContainer>
                    <DotColor color="#CAF61B" />
                </IconContainer>
                <ItemText>{t('Low tension')}</ItemText>
            </ItemContainer>
            <Divider />
            <ItemContainer>
                <IconContainer>
                    <DotColor color="#FF8F02" />
                </IconContainer>
                <ItemText>{t('Mid tension')}</ItemText>
            </ItemContainer>
            <Divider />
            <ItemContainer>
                <IconContainer>
                    <DotColor color="#FA6968" />
                </IconContainer>
                <ItemText>{t('High tension')}</ItemText>
            </ItemContainer>
        </Container>
    );
};

InfoList.propTypes = { certifiedRatesNumber: PropTypes.number };

export default InfoList;
