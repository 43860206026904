import i18next from 'i18next';
import isArray from 'lodash/isArray';

import { uploadDocument, updateDocument } from 'common/api/v1/contactDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAILURE,
} from '../actionTypes';
import { documentContactFormActions } from '../reducer';

import showModal from './showModal';

export default (values, contactId) => (dispatch) => {
    let newValues = {
        contact: contactId,
        name: values.name,
        type_document: values.type_document,
        value_user: values.value_user,
    };

    if (isArray(values.file) && !values.file[0].isUrlFile) {
        newValues.file = values.file && values.file[0];
    }

    dispatch(documentContactFormActions[UPLOAD_DOCUMENT]());

    const save = values.id
        ? () => updateDocument(newValues, values.id)
        : () => uploadDocument(newValues, contactId);

    save()
        .then((response) => {
            const { created_at, file, id, name, type_document, value_user } =
                response.data;
            dispatch(
                documentContactFormActions[UPLOAD_DOCUMENT_SUCCESS]({
                    created_at,
                    document_type_id: type_document,
                    id,
                    file,
                    name,
                    value_user,
                }),
            );
            dispatch(showModal(false));
            showToast({ body: i18next.t('Changes were saved') });
        })
        .catch((error) => {
            dispatch(
                documentContactFormActions[UPLOAD_DOCUMENT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
