import { updateStatusOrder } from 'common/api/v3/funnelProjects';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import * as projectSelectors from '../../project/selectors';
import {
    UPDATE_COLUMNS,
    UPDATE_COLUMNS_FAILURE,
    UPDATE_COLUMNS_SUCCESS,
} from '../actionTypes';
import { orderProjectColumn } from '../helpers';
import { funnelProjectsActions } from '../reducer';
import * as selectors from '../selectors';

export default (fromColumnId, toColumnId, projectId, newIndex, oldIndex) =>
    (dispatch, getState) => {
        const state = getState();
        const statusProjectDictionary =
            projectSelectors.getStatusProjectDictionary(state);
        const funnelColumnsData = selectors.getFunnelColumnsData(state);

        if (toColumnId === 'no-status') return;

        const { funnelColumns, previous } = orderProjectColumn({
            funnelColumnsData,
            fromColumnId,
            toColumnId,
            newIndex,
            oldIndex,
            statusProjectDictionary,
        });

        dispatch(
            funnelProjectsActions[UPDATE_COLUMNS]({
                funnelColumnsOrdered: funnelColumns,
                projectId,
            }),
        );

        updateStatusOrder(projectId, {
            prev_project: previous === undefined ? null : previous,
            status_project: toColumnId,
        })
            .then(() =>
                dispatch(funnelProjectsActions[UPDATE_COLUMNS_SUCCESS]()),
            )
            .catch((error) => {
                const backupProjectIndex = funnelColumnsData[
                    fromColumnId
                ].projects
                    .map((p) => p.id)
                    .indexOf(projectId);
                const backupProject = [
                    ...funnelColumnsData[fromColumnId].projects,
                ][backupProjectIndex];
                dispatch(
                    funnelProjectsActions[UPDATE_COLUMNS_FAILURE]({
                        fromColumnId: toColumnId,
                        toColumnId: fromColumnId,
                        project: backupProject,
                        errors: error,
                    }),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
