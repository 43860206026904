import { createSlice } from '@reduxjs/toolkit';

import {
    CREATE_ACCOUNT_USER,
    CREATE_ACCOUNT_USER_FAILURE,
    CREATE_ACCOUNT_USER_SUCCESS,
    FETCH_COUNTRIES,
    FETCH_COUNTRIES_FAILURE,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_DATA,
    FETCH_DATA_FAILURE,
    FETCH_DATA_SUCCESS,
    FETCH_GEOCODE,
    FETCH_GEOCODE_FAILURE,
    FETCH_GEOCODE_SUCCESS,
    RESET_FORM,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    countries: {
        isFetching: false,
        data: [],
        error: null,
    },
    fetchData: {
        data: {},
        errors: null,
        isFetching: false,
    },
    fetchGeocode: {
        isFetching: false,
        data: null,
        errors: null,
    },
    initialValues: {
        first_name: '',
        last_name: '',
        business_name: '',
        email: '',
        country: '',
        dayOfBirth: '',
        monthOfBirth: '',
        yearOfBirth: '',
    },
    session: {
        user: null,
        isLoading: false,
        errors: null,
    },
};

const createAcountSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [CREATE_ACCOUNT_USER]: (state) => {
            state.session = { ...state.session, errors: [], isLoading: true };
        },
        [CREATE_ACCOUNT_USER_FAILURE]: (state, action) => {
            state.session = {
                ...state.session,
                errors: action.payload,
                isLoading: false,
            };
        },
        [CREATE_ACCOUNT_USER_SUCCESS]: (state, action) => {
            state.session = {
                ...state.session,
                isLoading: false,
                user: action.payload,
            };
        },
        [FETCH_COUNTRIES]: (state) => {
            state.countries = {
                ...state.countries,
                errors: [],
                isLoading: true,
            };
        },
        [FETCH_COUNTRIES_FAILURE]: (state, action) => {
            state.countries = {
                ...state.countries,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_COUNTRIES_SUCCESS]: (state, action) => {
            state.countries = {
                ...state.countries,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_DATA]: (state) => {
            state.fetchData = {
                ...state.fetchData,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_DATA_FAILURE]: (state, action) => {
            state.fetchData = {
                ...state.fetchData,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DATA_SUCCESS]: (state, action) => {
            state.fetchData = {
                ...state.fetchData,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_GEOCODE]: (state) => {
            state.fetchGeocode = {
                ...state.fetchGeocode,
                data: null,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_GEOCODE_FAILURE]: (state, action) => {
            state.fetchGeocode = {
                ...state.fetchGeocode,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_GEOCODE_SUCCESS]: (state, action) => {
            state.fetchGeocode = {
                ...state.fetchGeocode,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
    },
});

export const createAccountActions = createAcountSlice.actions;

export default createAcountSlice.reducer;
