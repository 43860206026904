import React from 'react';

import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const StorageCapMaxFormatter = ({ row }) => {
    const { storage_cap_max } = row;
    if (!storage_cap_max) return '---';
    return <Typography variant="caption">{storage_cap_max} kWh</Typography>;
};

StorageCapMaxFormatter.propTypes = {
    row: PropTypes.object,
};

export default StorageCapMaxFormatter;
