import { getBillingInformation } from 'common/api/v1/companies';

import {
    FETCH_BILLING_INFORMATION,
    FETCH_BILLING_INFORMATION_FAILURE,
    FETCH_BILLING_INFORMATION_SUCCESS,
} from '../actionTypes';
import { profileInvoiceActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(profileInvoiceActions[FETCH_BILLING_INFORMATION]());

    getBillingInformation()
        .then((response) =>
            dispatch(
                profileInvoiceActions[FETCH_BILLING_INFORMATION_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                profileInvoiceActions[FETCH_BILLING_INFORMATION_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
