import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, BottomActions, Button } from 'sunwise-ui';

import ReactHookFormIntlNumberInput from 'common/components/form/bootstrap/ReactHookFormIntlNumberInput';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validateMargin from '../validateMargin';

const MarginForm = ({
    canModify,
    errors,
    filterData,
    handleClickSave,
    isSaving,
    initialValues,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateMargin),
    });

    const handleOnSave = (values) => handleClickSave(filterData, values);

    return (
        <form>
            <ReactHookFormIntlNumberInput
                append={'%'}
                control={control}
                disabled={!canModify}
                fullWidth
                label={t('Margin')}
                max={99}
                min={0}
                name="margin"
            />

            <ShowErrors errors={errors} />

            <BottomActions>
                <Box sx={{ pt: 2 }}>
                    <Button
                        disabled={isSaving}
                        onClick={handleSubmit(handleOnSave)}
                        visible={canModify}
                    >
                        {t('Save').toUpperCase()}
                    </Button>
                </Box>
            </BottomActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getSaveMarginErrors,
    initialValues: selectors.getInitialMarginValues,
    isSaving: selectors.getIsSavingMargin,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickSave: (filterData, values) =>
        dispatch(actions.bulkUpdateMargin(filterData, values)),
});

MarginForm.propTypes = {
    canModify: PropTypes.bool,
    errors: PropTypes.array,
    filterData: PropTypes.object,
    handleClickSave: PropTypes.func,
    isSaving: PropTypes.bool,
    initialValues: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarginForm);
