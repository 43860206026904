import React from 'react';

import get from 'lodash/get';

import { POINTER_CURSOR } from '../constants';

import LabelName from './LabelName';

const SalesmanFormatter = (params) => {
    const { row } = params;

    const label = row?.created_by
        ? `${get(row, 'created_by.first_name', '') || ''} ${
              get(row, 'created_by.last_name', '') || ''
          } ${get(row, 'created_by.second_surname', '') || ''}`
        : row?.agent_name;

    return (
        <LabelName
            maxWidth={200}
            title={label}
            sx={{ cursor: `${POINTER_CURSOR}` }}
        >
            {label}
        </LabelName>
    );
};

SalesmanFormatter.propTypes = {};

export default SalesmanFormatter;
