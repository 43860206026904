import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_ALERTS,
    FETCH_ALERTS_FAILURE,
    FETCH_ALERTS_SUCCESS,
    INITIALIZE_ALERTS_MODAL_FORM,
    RESET_ALERTS_MODAL_FORM,
    SAVE_ALERTS,
    SAVE_ALERTS_FAILURE,
    SAVE_ALERTS_SUCCESS,
    SET_ALERTS_MODAL_IS_OPEN,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    alertsModalIsOpen: false,
    fetchAlerts: { data: [], errors: null, isFetching: false },
    initialValues: { alerts: [], id: null },
    saveAlerts: { errors: null, isSaving: false },
};

const rateAlertsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_ALERTS]: (state) => {
            state.fetchAlerts = {
                ...state.fetchAlerts,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ALERTS_FAILURE]: (state, action) => {
            state.fetchAlerts = {
                ...state.fetchAlerts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ALERTS_SUCCESS]: (state, action) => {
            state.fetchAlerts = {
                ...state.fetchAlerts,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_ALERTS_MODAL_FORM]: (state, action) => {
            state.alertsModalInitialValues = {
                ...state.alertsModalInitialValues,
                ...action.payload,
            };
        },
        [RESET_ALERTS_MODAL_FORM]: (state) => {
            state.alertsModalInitialValues =
                INITIAL_STATE.alertsModalInitialValues;
        },
        [SAVE_ALERTS]: (state) => {
            state.saveAlerts = {
                ...state.saveAlerts,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_ALERTS_FAILURE]: (state, action) => {
            state.saveAlerts = {
                ...state.saveAlerts,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ALERTS_SUCCESS]: (state) => {
            state.saveAlerts.isSaving = false;
        },
        [SET_ALERTS_MODAL_IS_OPEN]: (state, action) => {
            state.alertsModalIsOpen = action.payload;
        },
    },
});

export const rateAlertsActions = rateAlertsSlice.actions;

export default rateAlertsSlice.reducer;
