import { getTemplatecontent } from 'common/api/v1/renderContent';

import {
    FETCH_FINANCING_TEMPLATE,
    FETCH_FINANCING_TEMPLATE_SUCCESS,
    FETCH_FINANCING_TEMPLATE_FAILURE,
} from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

export default (id, initTemplate) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(multiTemplateReviewActions[FETCH_FINANCING_TEMPLATE]());

        return getTemplatecontent(id)
            .then((response) => {
                dispatch(
                    multiTemplateReviewActions[
                        FETCH_FINANCING_TEMPLATE_SUCCESS
                    ](response.data),
                );
                initTemplate(response.data, id);
            })
            .catch((error) =>
                dispatch(
                    multiTemplateReviewActions[
                        FETCH_FINANCING_TEMPLATE_FAILURE
                    ](error),
                ),
            )
            .finally(resolve);
    });
