import React, { useEffect } from 'react';

import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import { ReactHookFormInput } from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const Form = ({
    initialValues,
    isSaving,
    onClose,
    redirect,
    resetForm,
    save,
    selectedProjectId,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => {
        return () => resetForm();
    }, []);

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    const handleOnClose = (id, isNew) => {
        onClose();
        if (isNew && id) redirect(`/report/${id}`);
    };

    const handleOnClickSubmit = (values) =>
        save({ ...values, project: selectedProjectId }, handleOnClose);

    return (
        <form onSubmit={handleSubmit(handleOnClickSubmit)}>
            <ReactHookFormInput
                control={control}
                disabled={isSaving}
                label={t('Report name')}
                name="name"
                variant="standard"
            />

            <Grid container mt={2}>
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        justifyContent: { md: 'right', xs: 'center' },
                    }}
                    xs
                >
                    <Button
                        color="secondary"
                        disabled={isSaving}
                        onClick={onClose}
                        sx={{
                            mr: { md: 1, xs: 0 },
                            order: { md: 1, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={isSaving}
                        sx={{
                            mb: { md: 0, xs: 1 },
                            order: { md: 1, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        type="submit"
                        variant="outlined"
                    >
                        {t('Continue')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

Form.propTypes = {
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    onClose: PropTypes.func,
    redirect: PropTypes.func,
    resetForm: PropTypes.func,
    save: PropTypes.func,
    selectedProjectId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSavingData,
});

const mapDispatchToProps = (dispatch) => ({
    redirect: (url) => dispatch(push(url)),
    resetForm: () => dispatch(actions.resetForm()),
    save: (values, successCallback) =>
        dispatch(actions.save(values, successCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
