import React from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListIcon from '@mui/icons-material/List';
import PropTypes from 'prop-types';

const SelectedIcon = ({ branchSelected, productBranches }) => {
    if (branchSelected === 'all') {
        if (productBranches.length === 0) return <AddCircleIcon />;
        return <ListIcon />;
    }
    if (productBranches.indexOf(branchSelected) >= 0)
        return <CheckCircleIcon />;
    return <AddCircleIcon />;
};

SelectedIcon.propTypes = {
    branchSelected: PropTypes.string,
    productBranches: PropTypes.array,
};

export default SelectedIcon;
