import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import PowerStationsFilter from '../../../powerStations/components/PowerStationsFilter';
import {
    STATUS,
    STATION_TYPES,
    STATION_COLUMNS,
} from '../../../powerStations/constants';
import BottomActionsForm from '../form/BottomActionsForm';
import Table from '../Table';

const PowerStationsList = ({
    control,
    disabled,
    fetchPowerStations,
    isFetching,
    onCancelSelection,
    onContinue,
    paginationData,
    powerStations,
    powerStationsColumns,
    resetPowerStations,
    setValue,
}) => {
    const [filterData, setFilterData] = useState({
        pageNo: 1,
        pageSize: 10,
        searchText: '',
    });
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [credential, powerStation] = useWatch({
        control,
        name: ['credential', 'power_station'],
    });
    const isMobile = ['xs', 'sm'].includes(breakpoint);

    useEffect(() => {
        return () => resetPowerStations();
    }, []);

    useEffect(() => {
        if (!credential?.id) return;
        fetchPowerStations(credential?.id, filterData);
    }, [credential?.id, filterData]);

    const onChangeFilter = (key, value) => {
        setFilterData({ ...filterData, [key]: value });
    };

    const columns = STATION_COLUMNS?.reduce((acc, column) => {
        if (
            (isMobile && !['id', 'name', 'city'].includes(column)) ||
            !powerStationsColumns?.includes(column)
        )
            return acc;

        switch (column) {
            case 'id':
                acc.push({
                    align: 'left',
                    field: 'id',
                    flex: 1,
                    headerName: 'Id',
                });
                break;
            case 'station_name':
                acc.push({
                    align: 'left',
                    field: 'station_name',
                    flex: 1,
                    headerName: t('Name'),
                });
                break;
            case 'city':
                acc.push({
                    align: 'left',
                    field: 'city',
                    flex: 1,
                    headerName: t('City'),
                    valueGetter: ({ row }) =>
                        !row.city || row?.city === -1 ? '--' : row?.city,
                });
                break;
            case 'type':
                acc.push({
                    align: 'left',
                    field: 'type',
                    flex: 1,
                    headerName: t('Type'),
                    valueGetter: (params) =>
                        t(STATION_TYPES[params?.row?.type]) || '--',
                });
                break;
            case 'state':
                acc.push({
                    align: 'left',
                    field: 'state',
                    flex: 1,
                    headerName: t('Status'),
                    renderCell: (params) => {
                        const status = params?.row?.state;

                        return (
                            <Box alignItems="center" display="flex" gap={1}>
                                {STATUS[status]?.color && (
                                    <Box
                                        bgcolor={STATUS[status].color}
                                        borderRadius={5}
                                        height={10}
                                        width={10}
                                    />
                                )}
                                <Typography variant="body2" fontWeight="bold">
                                    {t(STATUS[status]?.label || '')}
                                </Typography>
                            </Box>
                        );
                    },
                });
                break;
            default:
                return acc;
        }
        return acc;
    }, []);

    return (
        <>
            <Typography variant="h6" sx={{ mb: 2, ml: 1 }}>
                {t('Select a power station')}
            </Typography>

            <PowerStationsFilter
                disabled={isFetching}
                filterData={filterData}
                setFilterData={setFilterData}
            />

            <Card sx={{ mt: 2 }}>
                <Card.Body>
                    <Table
                        columns={columns}
                        isLoading={isFetching}
                        items={powerStations}
                        onPageChange={(page) =>
                            onChangeFilter('pageNo', page + 1)
                        }
                        onPageSizeChange={(size) =>
                            onChangeFilter('pageSize', size)
                        }
                        onRowClick={(params) => {
                            if (disabled) return;
                            setValue('power_station', params?.row);
                        }}
                        page={filterData.pageNo - 1 || 0}
                        pageSize={filterData.pageSize}
                        paginationMode="server"
                        rowCount={paginationData?.total || 0}
                        rowsPerPageOptions={[10, 20, 50]}
                        selectionModel={[powerStation?.id]}
                    />
                </Card.Body>
            </Card>

            <BottomActionsForm
                cancelText={t('Back')}
                continueText={t('Continue')}
                disabled={disabled}
                disabledContinue={!powerStation?.id}
                onCancel={onCancelSelection}
                onContinue={onContinue}
            />
        </>
    );
};

PowerStationsList.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fetchPowerStations: PropTypes.func,
    isFetching: PropTypes.bool,
    onCancelSelection: PropTypes.func,
    onContinue: PropTypes.func,
    paginationData: PropTypes.object,
    powerStations: PropTypes.array,
    powerStationsColumns: PropTypes.array,
    resetPowerStations: PropTypes.func,
    setValue: PropTypes.func,
};

export default PowerStationsList;
