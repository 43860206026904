import React, { useEffect, useRef } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid } from 'sunwise-ui';

import { StyledH2 } from 'common/components';
import { ReactHookFormInput } from 'common/components/form/bootstrap';
import * as chatSocket from 'common/sockets/chat';
import { getSessionLocalStorage, getToken } from 'common/utils/helpers/session';
import showToast from 'common/utils/showToast';

import * as actions from './actions';
import { ALLIANCE_CHAT_TYPE, CREDIT_CHAT_TYPE } from './constants';
import * as selectors from './selectors';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    max-height: 500px;
    min-height: 300px;
`;

const Section = styled(Box)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
`;

const ScrollableContent = styled(Box)`
    flex-grow: 1;
    min-height: 0;
    overflow: auto;
`;

const Comment = styled('div')`
    display: flex;
    margin-bottom: 15px;

    picture {
        align-items: center;
        border-radius: 100%;
        display: flex;
        height: 35px;
        overflow: hidden;
        width: 35px;

        img {
            width: 100%;
        }
    }

    ${({ variant }) =>
        variant === 'self' &&
        `flex-direction: row-reverse;

        .role-name {
            text-align: right;
        }`}
`;

const Content = styled(Box)`
    flex-grow: 1;
    padding: 0 15px;
`;

const Message = styled('div')`
    border-radius: 0 3px 3px 3px;
    margin-top: 5px;
    padding: 15px;

    ${({ variant }) =>
        variant === 'reject' &&
        `background-color: #f7edf0;

        .title{
            color: #FA6968;
            font-weight: 600;
            margin-bottom: 15px;
        }`}
`;

const MessageLength = styled('span')`
    color: #848bab;
    font-size: 13px;
    margin-left: 13px;
`;

const MAX_LENGTH = 255;

const FormContainer = ({
    callback,
    disconnectSocket,
    entityId,
    handleOnClickSend,
    initChat,
    initialValues,
    isFetching,
    messages,
    sendMessage,
    socketConnected,
    socketError,
    type = ALLIANCE_CHAT_TYPE,
    user,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
    });
    const message = watch('message');

    const session = getSessionLocalStorage();

    useEffect(() => {
        if (entityId && user) initChat(entityId, user, type);
    }, [entityId, user]);

    useEffect(() => {
        if (socketError !== null)
            showToast({
                autoClose: 5000,
                body: socketError,
                type: 'danger',
            });
    }, [socketError]);

    useEffect(() => {
        return () => {
            if (type === CREDIT_CHAT_TYPE && socketConnected) {
                disconnectSocket();
            }
        };
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    const handleOnClickSubmit = (values) => {
        if (type === CREDIT_CHAT_TYPE && socketConnected) {
            const { chatId, message } = values;
            const userId = get(session, 'id', null);
            const installerId = user.id;
            sendMessage(chatId, installerId, message, getToken(), userId);
        } else {
            handleOnClickSend(values, type, callback);
        }
    };

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        if (messagesEndRef.current)
            messagesEndRef.current.scrollTo(
                0,
                messagesEndRef.current.scrollHeight,
            );
    };

    return (
        <Container>
            <Section>
                <ScrollableContent ref={messagesEndRef}>
                    {messages.map((comment) => (
                        <Comment key={`comment-${comment.id}`}>
                            <picture>
                                <img alt="" src={comment.image}></img>
                            </picture>

                            <Content>
                                <div>
                                    <Grid container>
                                        <Grid item xs>
                                            <small>
                                                {comment.name} - {comment.role}
                                            </small>

                                            <StyledH2 size="sm">
                                                {comment.username}
                                            </StyledH2>
                                        </Grid>

                                        <Grid item xs>
                                            <small>{comment.created_at}</small>
                                        </Grid>
                                    </Grid>
                                </div>

                                <Message>
                                    <div>{comment.message}</div>
                                </Message>
                            </Content>
                        </Comment>
                    ))}
                </ScrollableContent>

                <form onSubmit={handleSubmit(handleOnClickSubmit)}>
                    <Box display="flex" gap="16px">
                        <ReactHookFormInput
                            control={control}
                            maxLength={MAX_LENGTH}
                            name="message"
                            placeholder={t('Add comment')}
                        />

                        <Button
                            color="success"
                            disabled={
                                isFetching || message?.trim().length === 0
                            }
                            endIcon={<SendIcon />}
                            type="submit"
                        >
                            {t('Send')}
                        </Button>
                    </Box>
                </form>
                <MessageLength>
                    {message?.length || 0}/{MAX_LENGTH}
                </MessageLength>
            </Section>
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isFetching: selectors.getSendIsFetching,
    messages: selectors.getMessages,
    socketConnected: selectors.getSocketConnected,
    socketError: selectors.getSocketError,
});

const mapDispatchToProps = (dispatch) => ({
    disconnectSocket: () => dispatch(chatSocket.disconnect()),
    handleOnClickSend: (values, type, callback) =>
        dispatch(actions.sendMessage(values, type, callback)),
    initChat: (entityId, user, type) =>
        dispatch(actions.initChat(entityId, user, type)),
    sendMessage: (chatId, installerId, message, token, userId) =>
        dispatch(
            chatSocket.sendMessage(chatId, installerId, message, token, userId),
        ),
});

FormContainer.propTypes = {
    callback: PropTypes.func,
    disconnectSocket: PropTypes.func,
    entityId: PropTypes.string,
    handleOnClickSend: PropTypes.func,
    initChat: PropTypes.func,
    initialValues: PropTypes.object,
    isFetching: PropTypes.bool,
    messages: PropTypes.array,
    sendMessage: PropTypes.func,
    socketConnected: PropTypes.bool,
    socketError: PropTypes.string,
    type: PropTypes.number,
    user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
