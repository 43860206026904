import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs, TabPanel } from 'sunwise-ui';

import Page21 from './Page21';

const Page2 = ({ control, disabled }) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('page2_1');
    const handleChange = (_, newValue) => setSelectedTab(newValue);
    const [accesories, inverters, panels] = useWatch({
        control,
        name: ['accesories', 'inverters', 'panels'],
    });

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab
                        key={`tab-page2_1`}
                        id={`tab-page2_1`}
                        label={t('Panel', { count: 2 })}
                        value={'page2_1'}
                    />

                    <Tab
                        key={`tab-page2_2`}
                        id={`tab-page2_2`}
                        label={t('Inverter', { count: 2 })}
                        value={'page2_2'}
                    />

                    <Tab
                        key={`tab-page2_3`}
                        id={`tab-page2_3`}
                        label={t('Accessory', { count: 2 })}
                        value={'page2_3'}
                    />
                </Tabs>
            </Box>

            <TabPanel
                key={`tab-page2_1`}
                selectedTab={selectedTab}
                value={'page2_1'}
            >
                <Page21 disabled={disabled} items={panels} />
            </TabPanel>

            <TabPanel
                key={`tab-page2_2`}
                selectedTab={selectedTab}
                value={'page2_2'}
            >
                <Page21 disabled={disabled} items={inverters} />
            </TabPanel>

            <TabPanel
                key={`tab-page2_3`}
                selectedTab={selectedTab}
                value={'page2_3'}
            >
                <Page21 disabled={disabled} items={accesories} />
            </TabPanel>
        </>
    );
};

Page2.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
};

export default Page2;
