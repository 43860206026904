import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button, Card } from 'sunwise-ui';

import { EmptyMessage } from 'common/components';
import { INSURANCE_TYPE } from 'common/constants';

import { isDisabledButton } from '../../helpers';
import * as selectors from '../../selectors';

import InsuranceCheck from './InsuranceCheck';

const FormContainer = ({
    handleOnClick,
    initialValues,
    isFetching,
    requirements,
    selectedFinancier,
}) => {
    const { t } = useTranslation();

    const { control, watch } = useForm({ defaultValues: initialValues });

    const financierType = get(selectedFinancier, 'type', null);
    const registered = watch('companyRegistered') || false;

    const buttonText = requirements.required ? t('I Accept Terms') : t('Next');

    if (isFetching) {
        return (
            <EmptyMessage
                text={t('Loading terms and conditions').concat('...')}
            />
        );
    }

    const getContent = () => {
        if (requirements.content.length === 0)
            return <EmptyMessage text={t('No terms and conditions to show')} />;
        return (
            <Box
                dangerouslySetInnerHTML={{
                    __html: requirements.content,
                }}
                sx={{
                    background: '#fff',
                    borderRadius: 1,
                    color: '#000',
                    padding: 2,
                }}
            />
        );
    };

    return (
        <form>
            <Card>
                <Card.Body>{getContent()}</Card.Body>
            </Card>

            <InsuranceCheck
                control={control}
                visible={financierType === INSURANCE_TYPE}
            />

            <BottomActions>
                <Box display="flex" gap="16px" sx={{ pt: 2 }}>
                    <Button
                        disabled={isDisabledButton(
                            financierType,
                            registered,
                            requirements,
                        )}
                        onClick={handleOnClick}
                    >
                        {buttonText}
                    </Button>
                </Box>
            </BottomActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getTermInitialValues,
});

const mapDispatchToProps = () => ({});

FormContainer.propTypes = {
    handleOnClick: PropTypes.func,
    initialValues: PropTypes.object,
    isFetching: PropTypes.bool,
    requirements: PropTypes.object,
    selectedFinancier: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
