export const NAME = 'inverter-configuration';
export const COLUMNS_CONFIG = {
    brand: {
        label: 'Brand',
        is_active: true,
        required: false,
    },
    code: {
        label: 'Code',
        is_active: true,
        required: true,
    },
    product_type: {
        label: 'Type',
        is_active: true,
        required: false,
    },
    branch_office: {
        label: 'Branch office',
        is_active: true,
        required: false,
    },
    name: {
        label: 'Name',
        is_active: true,
        required: false,
    },
    margin: {
        label: 'Margin',
        is_active: true,
        required: false,
    },
    final_cost: {
        label: 'Price',
        is_active: true,
        required: false,
    },
};
