import { filterItems } from 'common/api/v1/panels';

import {
    FETCH_ITEMS_PARENT,
    FETCH_ITEMS_PARENT_FAILURE,
    FETCH_ITEMS_PARENT_SUCCESS,
} from '../actionTypes';
import { panelConfigurationActions } from '../reducer';

export default ({
        brandName = '',
        maxPower = null,
        orderBy,
        page = 0,
        pageSize,
        searchText,
        sortBy,
    } = {}) =>
    (dispatch) => {
        dispatch(panelConfigurationActions[FETCH_ITEMS_PARENT]());

        filterItems({
            brandName,
            maxPower,
            orderBy,
            page: page + 1,
            perPage: pageSize,
            searchText,
            sortBy,
        })
            .then((response) =>
                dispatch(
                    panelConfigurationActions[FETCH_ITEMS_PARENT_SUCCESS](
                        response.data,
                    ),
                ),
            )
            .catch((error) =>
                dispatch(
                    panelConfigurationActions[FETCH_ITEMS_PARENT_FAILURE](
                        error,
                    ),
                ),
            );
    };
