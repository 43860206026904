import API from '../..';
const ENTITY = 'workforce-offer';

export const createItem = (data, offerId) =>
    API.post(`/api/v2/${ENTITY}/${offerId}`, data);
export const deleteItem = (id, offerId) =>
    API.delete(`/api/v2/${ENTITY}/${offerId}?id=${id}`);
export const getItems = (offerId) => API.get(`/api/v2/${ENTITY}/${offerId}`);
export const updateItem = (data, offerId) =>
    API.put(`/api/v2/${ENTITY}/${offerId}`, data);
