import { getSummaryCSV } from 'common/api/v1/proposalsSummary';

import { DOWNLOAD_CSV, DOWNLOAD_CSV_FAILURE } from '../actionTypes';
import { proposalSummaryActions } from '../reducer';

export default (item) => (dispatch) => {
    dispatch(proposalSummaryActions[DOWNLOAD_CSV]());

    getSummaryCSV(item.id)
        .then((response) => {
            const summaryName = item.name;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${summaryName}.csv`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            dispatch(
                proposalSummaryActions[DOWNLOAD_CSV_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
        });
};
