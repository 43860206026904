import i18next from 'i18next';

import { deleteOption } from 'common/api/v1/companiesCatalogs';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_COMPANIES_CATALOGS,
    DELETE_COMPANIES_CATALOGS_FAILURE,
    DELETE_COMPANIES_CATALOGS_SUCCESS,
} from '../actionTypes';
import { listSettingsActions } from '../reducer';

import fetchCatalogs from './fetchCatalogs';

export default (id, type) => (dispatch) => {
    dispatch(listSettingsActions[DELETE_COMPANIES_CATALOGS]());

    deleteOption(id)
        .then(() => {
            dispatch(
                listSettingsActions[DELETE_COMPANIES_CATALOGS_SUCCESS](null),
            );
            dispatch(alerts.actions.close());
            dispatch(fetchCatalogs(type));

            showToast({
                body: i18next.t('It was successfully removed'),
            });
        })
        .catch((error) => {
            dispatch(
                listSettingsActions[DELETE_COMPANIES_CATALOGS_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            dispatch(alerts.actions.close());
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
