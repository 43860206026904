import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../actions';
import * as selectors from '../selectors';

import SettingsForm from './SettingsForm';

const ModalForm = ({
    resetSettingsForm,
    setSettingsFormModalOpenStatus,
    settingsFormModalOpenStatus,
    sunwiseProjectId,
}) => {
    const { t } = useTranslation();

    const handleOnClose = () => setSettingsFormModalOpenStatus(false);

    return (
        <Dialog
            onClose={handleOnClose}
            onExited={() => resetSettingsForm()}
            open={settingsFormModalOpenStatus}
            size="lg"
            title={t('Setting')}
        >
            <SettingsForm
                handleOnClose={handleOnClose}
                sunwiseProjectId={sunwiseProjectId}
            />
        </Dialog>
    );
};

ModalForm.propTypes = {
    resetSettingsForm: PropTypes.func,
    setSettingsFormModalOpenStatus: PropTypes.func,
    settingsFormModalOpenStatus: PropTypes.bool,
    sunwiseProjectId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    settingsFormModalOpenStatus: selectors.getSettingsFormModalOpenStatus,
});

const mapDispatchToProps = (dispatch) => ({
    resetSettingsForm: () => dispatch(actions.resetSettingsForm()),
    setSettingsFormModalOpenStatus: (value) =>
        dispatch(actions.setSettingsFormModalOpenStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalForm);
