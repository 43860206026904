import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import TotalWrapper from './TotalWrapper';

const BreakdownTotals = ({
    currencyIso,
    currencyLocale,
    discount,
    getPermissionsByCode,
    hasDiscount,
    isFetching,
    profit,
    profitPercentage,
    subtotal,
    subtotalWithDiscount,
    total,
    totalCost,
}) => {
    const { t } = useTranslation();

    const { canModify: canModifyCost } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
    );
    const { canModify: canModifyMargin } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    );
    const { canModify: canModifyBreakdown } = getPermissionsByCode(
        PERMISSION_LIST.SEE_FINAL_BREAKDOWN,
    );

    if (!canModifyBreakdown) return null;

    return (
        <>
            {canModifyCost && (
                <TotalWrapper
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    isFetching={isFetching}
                    title={t('Cost')}
                    value={totalCost}
                />
            )}
            <TotalWrapper
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                isFetching={isFetching}
                title={t('Subtotal')}
                value={subtotal}
            />
            {hasDiscount && (
                <>
                    <TotalWrapper
                        currencyIso={currencyIso}
                        currencyLocale={currencyLocale}
                        numericalSign={discount > 0 ? '-' : ''}
                        isFetching={isFetching}
                        title={discount > 0 ? t('Discount') : t('Increase')}
                        value={discount > 0 ? discount : discount * -1}
                    />
                    <TotalWrapper
                        currencyIso={currencyIso}
                        currencyLocale={currencyLocale}
                        isFetching={isFetching}
                        title={`${t('Subtotal')} (2)`}
                        value={subtotalWithDiscount}
                    />
                </>
            )}
            <TotalWrapper
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                isFetching={isFetching}
                title={t('Total')}
                value={total}
            />
            {canModifyMargin && (
                <TotalWrapper
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    isFetching={isFetching}
                    title={`${t('Profit')} (${profitPercentage}%)`}
                    value={profit}
                />
            )}
        </>
    );
};

BreakdownTotals.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    discount: PropTypes.number,
    getPermissionsByCode: PropTypes.func,
    hasDiscount: PropTypes.bool,
    isFetching: PropTypes.bool,
    profit: PropTypes.number,
    profitPercentage: PropTypes.number,
    subtotal: PropTypes.number,
    subtotalWithDiscount: PropTypes.number,
    total: PropTypes.number,
    totalCost: PropTypes.number,
};

export default withPermissions([
    PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
    PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    PERMISSION_LIST.SEE_FINAL_BREAKDOWN,
])(BreakdownTotals);
