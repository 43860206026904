import i18next from 'i18next';
import get from 'lodash/get';
import { types } from 'sunwise-template-core';

import { duplicateSmartDocument } from 'common/api/v1/smartDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DUPLICATE_OFFER_SMART_DOCUMENT,
    DUPLICATE_OFFER_SMART_DOCUMENT_FAILURE,
    DUPLICATE_OFFER_SMART_DOCUMENT_SUCCESS,
} from '../../actionTypes';
import { multiTemplateReviewActions } from '../../reducer';

import fetchOfferSmartDocuments from './fetchOfferSmartDocuments';
import openOfferSmartDocumentModal from './openOfferSmartDocumentModal';

export default (handleClickDocumentItem, proposalId, values) => (dispatch) => {
    dispatch(multiTemplateReviewActions[DUPLICATE_OFFER_SMART_DOCUMENT]());

    const newValues = {
        name: values.name,
    };

    duplicateSmartDocument(values.id, newValues)
        .then((response) => {
            const { id, name } = get(response, 'data.data', {});

            if (id) {
                dispatch(
                    multiTemplateReviewActions[
                        DUPLICATE_OFFER_SMART_DOCUMENT_SUCCESS
                    ](get(response, 'data.data', {})),
                );
                dispatch(fetchOfferSmartDocuments(proposalId));
                handleClickDocumentItem({
                    id,
                    name,
                    type: types.SMART_DOCUMENTS_TYPE,
                });
                dispatch(openOfferSmartDocumentModal(false));
                showToast({
                    body: i18next.t('Successfully duplicated'),
                });
            } else
                dispatch(
                    multiTemplateReviewActions[
                        DUPLICATE_OFFER_SMART_DOCUMENT_FAILURE
                    ]([]),
                );
        })
        .catch((error) => {
            dispatch(
                multiTemplateReviewActions[
                    DUPLICATE_OFFER_SMART_DOCUMENT_FAILURE
                ](error?.response?.data?.errors),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
