import * as React from 'react';

import Step from '@mui/material/Step';
import StepConnector, {
    stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, Tooltip } from 'sunwise-ui';

import { getTranslatedStatusValue } from 'common/utils/helpers';

import { STATUS } from '../constants';

import StepperTimelineIcon from './StepperTimelineIcon';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 15,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'primary.main',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'primary.main',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 4,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#f7f7f7',
        borderRadius: 1,
    },
}));

const getSteps = (isAlliance) => {
    if (isAlliance) {
        return [
            STATUS.REQUESTED_STATUS.key,
            STATUS.INCOMPLETE_STATUS.key,
            STATUS.INPROCESS_STATUS.key,
            STATUS.APPROVED_STATUS.key,
            STATUS.CLOSED_STATUS.key,
        ];
    }
    return [
        STATUS.REQUESTED_STATUS.key,
        STATUS.INCOMPLETE_STATUS.key,
        STATUS.INPROCESS_STATUS.key,
        STATUS.PRE_APPROVED_STATUS.key,
        STATUS.APPROVED_STATUS.key,
        STATUS.CLOSED_STATUS.key,
    ];
};

const StepperTimeline = ({ isAlliance = false, status }) => {
    const { t } = useTranslation();
    const activeStep = getSteps().indexOf(status);

    const getStatusName = (status, stepStatus) => {
        if (status === STATUS.CONDITIONED_APPROVED_STATUS.key) {
            return t(
                getTranslatedStatusValue(
                    STATUS.CONDITIONED_APPROVED_STATUS.key,
                ),
            );
        }
        return t(getTranslatedStatusValue(stepStatus));
    };

    return (
        <Stack sx={{ width: '100%' }} spacing={3}>
            <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
            >
                {getSteps(isAlliance).map((stepStatus) => (
                    <Tooltip
                        key={stepStatus}
                        title={<div>{getStatusName(status, stepStatus)}</div>}
                    >
                        <Step>
                            <StepLabel
                                StepIconComponent={(props) => (
                                    <StepperTimelineIcon
                                        isAlliance={isAlliance}
                                        {...props}
                                    />
                                )}
                            />
                        </Step>
                    </Tooltip>
                ))}
            </Stepper>
        </Stack>
    );
};

StepperTimeline.propTypes = {
    isAlliance: PropTypes.bool,
    status: PropTypes.number,
};

export default StepperTimeline;
