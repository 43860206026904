export const NAME = `project-consumption-card`;

export const STATUS_PROJECT_TYPE = 4;

export const BADGE_CONFIG = {
    manual: { color: 'warning', label: 'Manual' },
    descarga: { color: 'success', label: 'Automatic CFE' },
    automatic_pdf: { color: 'success', label: 'Automatic PDF' },
    semiautomatic_pdf: { color: 'primary', label: 'Semi-automatic PDF' },
    semiautomatic: { color: 'primary', label: 'Semi-automatic' },
};
