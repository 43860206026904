import i18next from 'i18next';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { FILE_ALLOWED_EXTENSIONS } from 'common/constants';

export const canAddOwnDatasheet = (datasheets) => {
    return getNumberOwnDatasheets(datasheets) == 0;
};

export const getDatasheetName = (datasheet) => {
    if (!isNil(datasheet))
        return datasheet.substr(datasheet.lastIndexOf('/') + 1);
    return '';
};

export const getFirstOrDefaultCertifiedDatasheet = (datasheets) => {
    if (getIsEnabledCertifiedDatasheetDownload(datasheets))
        return datasheets.find((d) => d.is_certified) || null;
    return null;
};

export const getIsAllowedFile = (datasheet) => {
    const extensions = FILE_ALLOWED_EXTENSIONS.PDF.split(',');
    return new RegExp(
        '(' + extensions.join('|').replace(/\./g, '\\.') + ')$',
    ).test(datasheet.toLowerCase());
};

export const getIsEnabledCertifiedDatasheetDownload = (datasheets) => {
    if (isArray(datasheets) && !isEmpty(datasheets))
        return datasheets.some((d) => d.is_certified);
    return false;
};

export const getIsEnabledDatasheetDownload = (datasheet) => !isEmpty(datasheet);

export const getLastUpdatedText = (datasheet) => {
    if (datasheet.created_at !== datasheet.updated_at)
        return `${i18next.t('Updated at')} ${datasheet.updated_at}`;
    return `${i18next.t('Added at')} ${datasheet.created_at}`;
};

const getNumberOwnDatasheets = (datasheets) => {
    if (!isEmpty(datasheets))
        return datasheets.filter((datasheet) => !datasheet.is_certified).length;
    return 0;
};
