import React from 'react';

import PropTypes from 'prop-types';
import { Card } from 'sunwise-ui';

import CardContent from './components/CardContent';
import ModalForm from './components/ModalForm';

const Container = ({ canModify, selectedProjectId }) => {
    return (
        <Card>
            <CardContent
                canModify={canModify}
                selectedProjectId={selectedProjectId}
            />

            <ModalForm selectedProjectId={selectedProjectId} />
        </Card>
    );
};

Container.propTypes = {
    canModify: PropTypes.bool,
    selectedProjectId: PropTypes.string,
};

export default Container;
