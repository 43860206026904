import i18next from 'i18next';

import { markAsFavorite } from 'common/api/v1/contactProject';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SET_OFFER_AS_FAVORITE,
    SET_OFFER_AS_FAVORITE_FAILURE,
    SET_OFFER_AS_FAVORITE_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

import fetchCommercialOffers from './fetchCommercialOffers';

export default (isArchivedFilter, projectId, proposalId) => (dispatch) => {
    dispatch(projectCommOfferTableActions[SET_OFFER_AS_FAVORITE]());
    markAsFavorite(proposalId)
        .then((response) => {
            dispatch(
                projectCommOfferTableActions[SET_OFFER_AS_FAVORITE_SUCCESS](
                    response.data,
                ),
            );
            dispatch(
                fetchCommercialOffers({
                    isArchived: isArchivedFilter,
                    projectId,
                }),
            );

            showToast({
                body: i18next.t('The offer was marked as a favorite'),
            });
        })
        .catch((error) => {
            dispatch(
                projectCommOfferTableActions[SET_OFFER_AS_FAVORITE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
