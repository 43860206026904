import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import Logo from '../../layouts/defaultLayout/Logo';

const Wrapper = styled('div')`
    align-items: center;
    background: ${({ mode }) => (mode === 'dark' ? '#000000ee' : '#002438ee')};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: ${({ visible }) => (visible ? '1' : '0')};
    position: fixed;
    top: 0;
    transition: ${({ visible }) =>
        visible
            ? 'opacity 0.25s linear, visibility 0s linear'
            : 'opacity 0.25s linear, visibility 0s linear 0.25s'};
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    width: 100%;
    z-index: 9999;
`;

const Container = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 300px;
`;

const ImageContainer = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 64px;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
    width: 64px;

    img,
    svg {
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-name: RotateAndFade;
        animation-timing-function: linear;
        width: 64px;
    }
`;

const MessageText = styled('span')`
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.01rem;
    line-height: 18px;
    min-height: 18px;
    text-align: center;
    width: 100%;
`;

const ProcessView = ({ myForwardedRef, selectedTheme }) => {
    const [loadingData, setLoadingData] = useState({
        show: false,
        text: i18next.t('Loading').concat('...'),
    });

    useImperativeHandle(myForwardedRef, () => ({
        openLoading(text = i18next.t('Loading').concat('...')) {
            document.body.style.overflowY = 'hidden';
            setLoadingData({ text, show: true });
        },
        closeLoading() {
            document.body.style.overflowY = 'auto';
            setLoadingData({ text: '', show: false });
        },
    }));

    if (!loadingData.show) return null;

    return (
        <Wrapper mode={selectedTheme} visible={loadingData.show}>
            <Container>
                <ImageContainer>
                    <Logo field="loading_image" />
                </ImageContainer>
                <MessageText>{loadingData.text}</MessageText>
            </Container>
        </Wrapper>
    );
};

ProcessView.propTypes = {
    myForwardedRef: PropTypes.object,
    selectedTheme: PropTypes.string,
};

const ProcessViewForwared = forwardRef((props, ref) => (
    <ProcessView {...props} myForwardedRef={ref} />
));

ProcessViewForwared.displayName = 'ProcessViewForwared';

export default ProcessViewForwared;
