import { NAME } from './constants';

export const FETCH_COMPATIBLE_RATES = `${NAME}/FETCH_COMPATIBLE_RATES`;
export const FETCH_COMPATIBLE_RATES_FAILURE = `${NAME}/FETCH_COMPATIBLE_RATES_FAILURE`;
export const FETCH_COMPATIBLE_RATES_SUCCESS = `${NAME}/FETCH_COMPATIBLE_RATES_SUCCESS`;

export const FETCH_SCHEDULE_RATE_CONFIGURATION = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION`;
export const FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE`;
export const FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS`;

export const INITIAL_VALUES = `${NAME}/INITIAL_VALUES`;

export const RESET = `${NAME}/RESET`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const UPSERT_RATE = `${NAME}/UPSERT_RATE`;
