import React, { useRef } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { Box, Card, Chip } from 'sunwise-ui';

import {
    ColumnContainer,
    ColumnTitle,
    ColumnWrapper,
    ItemList,
} from 'common/components/funnel';

import ItemCard from './ItemCard';

const Column = ({
    canModify,
    color,
    fetchNextPage,
    handleClickView,
    id,
    isLoadingCurrentNextPage,
    isOnline,
    nextPage,
    projects,
    refreshingIsNeeded,
    selectProject,
    title,
    totalRows = 0,
    updatingStatusProjectId,
}) => {
    const { t } = useTranslation();
    const columnDivRef = useRef();

    const loadMoreProjects = () => {
        if (nextPage === null) return;
        fetchNextPage(id, nextPage);
        if (columnDivRef.current) {
            columnDivRef.current.scrollTop =
                columnDivRef.current.scrollTop - 600;
        }
    };

    const hasNextPage = nextPage !== null && !isLoadingCurrentNextPage;

    return (
        <ColumnWrapper>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 0.5,
                    mb: 1,
                    mr: 1,
                }}
            >
                {color && <CircleIcon sx={{ color, fontSize: 16 }} />}
                <ColumnTitle>{title}</ColumnTitle>
                <Chip
                    as="span"
                    label={totalRows}
                    size="small"
                    sx={{ fontSize: 10, fontWeight: 'bold' }}
                />
            </Box>

            <ColumnContainer ref={columnDivRef}>
                <InfiniteScroll
                    key={`infinite-${id}`}
                    pageStart={1}
                    loadMore={loadMoreProjects}
                    hasMore={hasNextPage}
                    loader={
                        <div className="scroll-loader" key={0}>
                            {t('Loading')}
                        </div>
                    }
                    useWindow={false}
                    threshold={500}
                    getScrollParent={() => columnDivRef.current}
                >
                    {!projects.length && (
                        <Card
                            sx={{
                                borderRadius: '4px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            <Card.Body>{t('No items')}</Card.Body>
                        </Card>
                    )}

                    <Droppable
                        droppableId={id || 'no-status'}
                        isDropDisabled={!isOnline || refreshingIsNeeded}
                    >
                        {(provided) => (
                            <ItemList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {projects.map((item, index) => (
                                    <ItemCard
                                        canModify={canModify}
                                        disabled={
                                            refreshingIsNeeded ||
                                            !isOnline ||
                                            item.id === updatingStatusProjectId
                                        }
                                        handleClickView={handleClickView}
                                        id={item.id}
                                        index={index}
                                        key={item.id}
                                        project={item}
                                        selectProject={selectProject}
                                    />
                                ))}
                                {provided.placeholder}
                            </ItemList>
                        )}
                    </Droppable>
                </InfiniteScroll>
            </ColumnContainer>
        </ColumnWrapper>
    );
};
Column.propTypes = {
    canModify: PropTypes.bool,
    color: PropTypes.string,
    fetchNextPage: PropTypes.func,
    handleClickView: PropTypes.func,
    id: PropTypes.string,
    isLoadingCurrentNextPage: PropTypes.bool,
    isOnline: PropTypes.bool,
    nextPage: PropTypes.number,
    nextProject: PropTypes.string,
    projects: PropTypes.array,
    refreshingIsNeeded: PropTypes.bool,
    selectProject: PropTypes.func,
    title: PropTypes.string,
    totalRows: PropTypes.number,
    updatingStatusProjectId: PropTypes.string,
};

export default Column;
