export const handleSaveProjectAndShowConsumptionForm =
    (fetchProjects, handleOpenProject, saveProjectAndShowConsumptionForm) =>
    (values) => {
        saveProjectAndShowConsumptionForm(values, (project) => {
            if (fetchProjects) fetchProjects();
            handleOpenProject(project.id);
        });
    };

export const handleSaveProjectWithoutConsumption =
    (fetchProjects, handleOpenProject, saveProjectWithoutConsumption) =>
    (values) => {
        saveProjectWithoutConsumption(values, (project) => {
            if (fetchProjects) fetchProjects();
            handleOpenProject(project.id);
        });
    };
