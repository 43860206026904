import { NAME } from './constants';

export const INVALID_PASSWORD = `${NAME}/INVALID_PASSWORD`;

export const LOGIN_USER = `${NAME}/LOGIN_USER`;
export const LOGIN_USER_FAILURE = `${NAME}/LOGIN_USER_FAILURE`;
export const LOGIN_USER_SUCCESS = `${NAME}/LOGIN_USER_SUCCESS`;

export const FETCH_BLOG_POSTS = `${NAME}/FETCH_BLOG_POSTS`;
export const FETCH_BLOG_POSTS_FAILURE = `${NAME}/FETCH_BLOG_POSTS_FAILURE`;
export const FETCH_BLOG_POSTS_SUCCESS = `${NAME}/FETCH_BLOG_POSTS_SUCCESS`;
