import API from '../..';
const ENTITY = 'customize-rates';

export const getItem = (id) => API.get(`/api/v1/${ENTITY}/${id}`);
export const filterItems = (params) =>
    API.get(`/api/v1/${ENTITY}/${params ? `?${params}` : ''}`);
export const createItem = (data) => API.post(`/api/v1/${ENTITY}/`, data);
export const updateItem = (id, data) =>
    API.put(`/api/v1/${ENTITY}/${id}`, data);
export const setEnableItem = (id, isEnable) =>
    API.put(`/api/v1/${ENTITY}-activate/${id}`, { is_enable: isEnable });
