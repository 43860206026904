import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from 'sunwise-ui';

import AllianceItem from './AllianceItem';
import StyledButtonAddAlliance from './StyledButtonAddAlliance';

const CardList = ({
    canModifyAlliances,
    handleOnClickNewAlliance,
    handleOnClikAlliance,
    isLocked,
    items,
}) => {
    const { t } = useTranslation();
    return (
        <Grid container py={2}>
            {items.map((alliance) => (
                <Grid item xs={9} md={5} lg={3} key={alliance.id}>
                    <AllianceItem
                        alliance={alliance}
                        disabled={isLocked}
                        handleOnClikAlliance={handleOnClikAlliance}
                    />
                </Grid>
            ))}
            <Grid item xs={9} md={5} lg={3}>
                <Button
                    disabled={!canModifyAlliances}
                    onClick={handleOnClickNewAlliance}
                    variant="default"
                >
                    <Box>
                        <StyledButtonAddAlliance variant="new">
                            <AddIcon />
                        </StyledButtonAddAlliance>
                        {t('Add alliance')}
                    </Box>
                </Button>
            </Grid>
        </Grid>
    );
};

CardList.propTypes = {
    canModifyAlliances: PropTypes.bool,
    handleOnClickNewAlliance: PropTypes.func,
    handleOnClikAlliance: PropTypes.func,
    isLocked: PropTypes.bool,
    items: PropTypes.array,
};

export default CardList;
