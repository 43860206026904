import { getRateConcepts } from 'common/api/v2/rateConcepts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_RATE_CONCEPTS,
    FETCH_RATE_CONCEPTS_FAILURE,
    FETCH_RATE_CONCEPTS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import initializeRateTaxesForm from './initializeRateTaxesForm';

export default (commercialOfferId) => (dispatch) => {
    if (!commercialOfferId) return;

    dispatch(actions[FETCH_RATE_CONCEPTS]());

    getRateConcepts(commercialOfferId)
        .then((response) => {
            const data = response?.data?.data;

            dispatch(actions[FETCH_RATE_CONCEPTS_SUCCESS](data));
            dispatch(initializeRateTaxesForm(data?.complements_offer));
        })
        .catch((error) => {
            dispatch(actions[FETCH_RATE_CONCEPTS_FAILURE](error));

            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
