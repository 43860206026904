import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from 'sunwise-ui';

const WarningEdition = ({ handleClickEdit, show }) => {
    const { t } = useTranslation();
    if (!show) return null;
    return (
        <Alert
            action={
                <Button color="secondary" onClick={() => handleClickEdit()}>
                    {t('Edit')}
                </Button>
            }
            severity="warning"
            sx={{ '.MuiAlert-message': { width: '100%' } }}
            variant="filled"
        >
            {t('Do you want to modify the configuration of this product?')}
        </Alert>
    );
};

WarningEdition.propTypes = {
    handleClickEdit: PropTypes.func,
    show: PropTypes.bool,
};

export default WarningEdition;
