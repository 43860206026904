import { getTemplate } from 'common/api/v1/templates';

import {
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
    SET_PAGE_SIZE,
} from '../actionTypes';
import { editTemplateViewActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch(editTemplateViewActions[FETCH_CUSTOM_TEMPLATE]());

        return getTemplate(id)
            .then((response) => {
                const data = {
                    ...response.data,
                    id: response.data.external_uuid,
                    pages: response.data.pages.length,
                    pages_template: response.data.pages.map((page) => ({
                        ...page,
                        content: JSON.stringify(page.content),
                    })),
                };

                dispatch(
                    editTemplateViewActions[FETCH_CUSTOM_TEMPLATE_SUCCESS](
                        data,
                    ),
                );

                dispatch(
                    editTemplateViewActions[SET_PAGE_SIZE](
                        response.data.page_size,
                    ),
                );

                resolve(data);
            })
            .catch((error) => {
                dispatch(
                    editTemplateViewActions[FETCH_CUSTOM_TEMPLATE_FAILURE](
                        error,
                    ),
                );

                reject();
            });
    });
