import React from 'react';

import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

const TextInfo = ({ label, value }) => {
    return (
        <Box display="flex" gap={1}>
            <Typography variant="subtitle2">{label}:</Typography>
            <Typography variant="body2">{value || ''}</Typography>
        </Box>
    );
};

TextInfo.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
};

export default TextInfo;
