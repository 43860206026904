import React from 'react';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import PropTypes from 'prop-types';

const NotificationIcon = ({ category }) => {
    switch (category) {
        case 'contact_assigned':
            return <PersonIcon />;
        case 'contact_support':
            return <GroupIcon />;
        case 'contact_tracking':
            return <CalendarTodayIcon />;
        case 'process_completed':
            return <CloudDoneIcon />;
        case 'proposal':
            return <AssignmentTurnedInIcon />;
        case 'security':
            return <SecurityIcon />;
        default:
            return <NotificationsIcon />;
    }
};

NotificationIcon.defaultProps = { category: 'notification' };
NotificationIcon.propTypes = { category: PropTypes.string };

export default NotificationIcon;
