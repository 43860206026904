import isEmpty from 'lodash/isEmpty';

import filterTrackings from './filterTrackings';
import openModalTracking from './openModalTracking';
import resetTrackingForm from './resetTrackingForm';

export default (filterData = {}) =>
    (dispatch) => {
        dispatch(resetTrackingForm());
        if (!isEmpty(filterData)) dispatch(filterTrackings(filterData));
        dispatch(openModalTracking(false));
    };
