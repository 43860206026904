import i18next from 'i18next';

import { SET_ELEMENT_TO_DELETE } from '../actionTypes';
import { trackingsActions } from '../reducer';

import openConfirmModal from './openConfirmModal';
import setDataConfirmModal from './setDataConfirmModal';

export default (element) => (dispatch) => {
    dispatch(trackingsActions[SET_ELEMENT_TO_DELETE](element));
    dispatch(
        setDataConfirmModal({
            confirmText: i18next.t('Delete'),
            message: i18next.t(
                'Are you really sure to remove the "{{description}}" trace?',
                { description: element.description },
            ),

            title: i18next.t('Confirmation'),
            type: 'warning',
        }),
    );
    dispatch(openConfirmModal(true));
};
