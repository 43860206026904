import get from 'lodash/get';

import { getPositions } from 'common/api/v1/projectPositions';

import {
    FETCH_LOCATION,
    FETCH_LOCATION_SUCCESS,
    FETCH_LOCATION_ERROR,
} from '../actionTypes';
import { quickViewProjectsActions } from '../reducer';

export default (projectId) => {
    return (dispatch) => {
        dispatch(quickViewProjectsActions[FETCH_LOCATION]());
        getPositions(projectId)
            .then((response) => {
                dispatch(
                    quickViewProjectsActions[FETCH_LOCATION_SUCCESS](
                        get(response, 'data', {}),
                    ),
                );
            })
            .catch((error) =>
                dispatch(quickViewProjectsActions[FETCH_LOCATION_ERROR](error)),
            );
    };
};
