import { array, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        generation: array().required(REQUIRED_TEXT).nullable(),
        political_division1: string().required(REQUIRED_TEXT).nullable(),
    });
};
