import React, { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Grid,
    Typography,
} from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import HourlyGridWrapper from 'common/components/HourlyGridWrapper';

const DistributionCard = ({
    conceptName,
    control,
    defaultExpanded = false,
    distributionComponent: DistributionComponent,
    field,
    hourlyDistribution,
    optionsForSelect = [],
    showMode,
    title,
    weekDay,
    ...rest
}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    return (
        <Card>
            <Accordion
                defaultExpanded={defaultExpanded}
                disableGutters
                expanded={isExpanded}
                onChange={(e, expanded) => {
                    if (e.target.nodeName !== 'LI') setIsExpanded(expanded);
                }}
                TransitionProps={{ unmountOnExit: true }}
            >
                <AccordionSummary
                    aria-controls={`hourly-table-content-${conceptName}.${weekDay}`}
                    expandIcon={<ArrowDropDownIcon sx={{ fontSize: '3rem' }} />}
                    id={`hourly-table-header-${conceptName}.${weekDay}`}
                >
                    <Grid
                        alignItems="center"
                        container
                        justifyContent="space-between"
                        padding={1}
                    >
                        <Grid item md={8} xs={18}>
                            <Typography variant="h6">{title}</Typography>
                        </Grid>
                        {!showMode && (
                            <Grid item xs={18} md={4}>
                                <ReactHookFormSelect
                                    control={control}
                                    name={`${field}.select.${conceptName}.${weekDay}`}
                                    options={optionsForSelect || []}
                                    sxFormControl={{
                                        marginBottom: '0!important',
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Box mt={3} mx={1}>
                        <HourlyGridWrapper>
                            <DistributionComponent
                                conceptName={conceptName}
                                field={field}
                                hourlyDistribution={hourlyDistribution}
                                showMode={showMode}
                                weekDay={weekDay}
                                {...rest}
                            />
                        </HourlyGridWrapper>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

DistributionCard.propTypes = {
    conceptName: PropTypes.string,
    control: PropTypes.object,
    defaultExpanded: PropTypes.bool,
    distributionComponent: PropTypes.func,
    field: PropTypes.string,
    hourlyDistribution: PropTypes.array,
    optionsForSelect: PropTypes.array,
    showMode: PropTypes.bool,
    title: PropTypes.string,
    weekDay: PropTypes.string,
};

export default DistributionCard;
