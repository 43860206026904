import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Restore';
import { Box } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import TitleWithDetail from 'common/components/TitleWithDetail';

const RateDetails = ({
    canModifyRates,
    isLocked,
    name,
    onClickEditRate,
    onClickResetRateConcepts,
}) => {
    const { t } = useTranslation();

    return (
        <Box alignItems="center" display="flex">
            <TitleWithDetail
                onClick={onClickEditRate}
                sx={{ cursor: 'pointer', flex: 1 }}
                variant="body2"
            >
                {name}
            </TitleWithDetail>

            <DropdownCardMenu
                items={[
                    {
                        handleClick: onClickEditRate,
                        icon: <EditIcon fontSize="small" />,
                        label: t('Edit'),
                    },
                    {
                        handleClick: onClickResetRateConcepts,
                        icon: <RestoreIcon fontSize="small" />,
                        label: t('Restore'),
                    },
                ]}
                visible={canModifyRates && !isLocked}
            />
        </Box>
    );
};

RateDetails.propTypes = {
    canModifyRates: PropTypes.bool,
    isLocked: PropTypes.bool,
    name: PropTypes.string,
    onClickEditRate: PropTypes.func,
    onClickResetRateConcepts: PropTypes.func,
};

export default RateDetails;
