import { number, object } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { getMinValueText, NUMBER_TYPE_TEXT, REQUIRED_TEXT } =
        getValidationTexts();

    return object().shape({
        annual_kWh: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .nullable(),
        daily_average_kWh: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .nullable(),
        consumption: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        consumption_percentage: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(-100, getMinValueText(-100))
            .nullable(),
    });
};
