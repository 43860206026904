import { createSlice } from '@reduxjs/toolkit';

import {
    EDIT_REPORT,
    EDIT_REPORT_FAILURE,
    EDIT_REPORT_SUCCESS,
    FETCH_HISTORICAL_INFO,
    FETCH_HISTORICAL_INFO_FAILURE,
    FETCH_HISTORICAL_INFO_SUCCESS,
    FETCH_INDICATORS_SETTINGS,
    FETCH_INDICATORS_SETTINGS_FAILURE,
    FETCH_INDICATORS_SETTINGS_SUCCESS,
    FETCH_PERIOD_SETTINGS,
    FETCH_PERIOD_SETTINGS_FAILURE,
    FETCH_PERIOD_SETTINGS_SUCCESS,
    FETCH_PROJECT_CONFIGURATION,
    FETCH_PROJECT_CONFIGURATION_FAILURE,
    FETCH_PROJECT_CONFIGURATION_SUCCESS,
    FETCH_REPORT_COMPLEMENTS,
    FETCH_REPORT_COMPLEMENTS_FAILURE,
    FETCH_REPORT_COMPLEMENTS_SUCCESS,
    FETCH_REPORT_DETAILS,
    FETCH_REPORT_DETAILS_FAILURE,
    FETCH_REPORT_DETAILS_SUCCESS,
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
    INITIAL_VALUES,
    RESET_FORM,
    RESET_VALUES,
    SAVE,
    SAVE_FAILURE,
    SAVE_INDICATORS_SETTINGS,
    SAVE_INDICATORS_SETTINGS_FAILURE,
    SAVE_INDICATORS_SETTINGS_SUCCESS,
    SAVE_SUCCESS,
    SAVE_TEMPLATE,
    SAVE_TEMPLATE_FAILURE,
    SAVE_TEMPLATE_SUCCESS,
    SET_IS_OPEN_INDICATORS_SETTINGS_MODAL,
    SET_IS_OPEN_PERIODS_SETTINGS_MODAL,
    SET_REPORT_END,
    SET_REPORT_END_FAILURE,
    SET_REPORT_END_SUCCESS,
} from './actionTypes';
import { NAME, PERIOD_TYPES, REPORT_TYPES } from './constants';

const INITIAL_STATE = {
    editReport: { errors: null, isSaving: false },
    historicalInfo: { data: [], errors: null, isLoading: false },
    indicatorsSettings: { data: [], errors: null, isFetching: true },
    initialValues: {
        type: REPORT_TYPES.SINGLE_PERIOD,
        groups: [
            {
                name: '1',
                periods: [
                    {
                        comparison_period: null,
                        current_period: null,
                        period_type: PERIOD_TYPES.PROPOSAL,
                    },
                ],
            },
        ],
    },
    isOpenIndicatorsSettingsModal: false,
    isOpenPeriodsSettingsModal: false,
    periodConfig: { data: {}, errors: null, isFetching: true },
    projectConfiguration: { data: {}, errors: null, isFetching: true },
    reportComplements: { data: {}, errors: null, isFetching: true },
    reportDetails: { data: {}, errors: null, isFetching: true },
    reportEnd: { data: {}, errors: null, isSaving: false },
    save: { data: [], errors: null, isSaving: false },
    saveIndicatorsSettings: { data: {}, errors: null, isSaving: false },
    saveTemplate: { data: {}, errors: null, isSaving: false },
    templates: { data: [], errors: null, isFetching: true },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [EDIT_REPORT]: (state) => {
            state.editReport.errors = null;
            state.editReport.isSaving = true;
        },
        [EDIT_REPORT_FAILURE]: (state, action) => {
            state.editReport.errors = action.payload;
            state.editReport.isSaving = false;
        },
        [EDIT_REPORT_SUCCESS]: (state, action) => {
            state.editReport.data = action.payload;
            state.editReport.isSaving = false;
        },
        [FETCH_HISTORICAL_INFO]: (state) => {
            state.historicalInfo.errors = null;
            state.historicalInfo.isLoading = true;
        },
        [FETCH_HISTORICAL_INFO_FAILURE]: (state, action) => {
            state.historicalInfo.errors = action.payload;
            state.historicalInfo.isLoading = false;
        },
        [FETCH_HISTORICAL_INFO_SUCCESS]: (state, action) => {
            state.historicalInfo.data = action.payload;
            state.historicalInfo.isLoading = false;
        },
        [FETCH_INDICATORS_SETTINGS]: (state) => {
            state.indicatorsSettings.errors = null;
            state.indicatorsSettings.isFetching = true;
        },
        [FETCH_INDICATORS_SETTINGS_FAILURE]: (state, action) => {
            state.indicatorsSettings.errors = action.payload;
            state.indicatorsSettings.isFetching = false;
        },
        [FETCH_INDICATORS_SETTINGS_SUCCESS]: (state, action) => {
            state.indicatorsSettings.data = action.payload;
            state.indicatorsSettings.isFetching = false;
        },
        [FETCH_PERIOD_SETTINGS]: (state) => {
            state.periodConfig.errors = null;
            state.periodConfig.isFetching = true;
        },
        [FETCH_PERIOD_SETTINGS_FAILURE]: (state, action) => {
            state.periodConfig.errors = action.payload;
            state.periodConfig.isFetching = false;
        },
        [FETCH_PERIOD_SETTINGS_SUCCESS]: (state, action) => {
            state.periodConfig.data = action.payload;
            state.periodConfig.isFetching = false;
        },
        [FETCH_REPORT_DETAILS]: (state) => {
            state.reportDetails.errors = null;
            state.reportDetails.isFetching = true;
        },
        [FETCH_REPORT_DETAILS_FAILURE]: (state, action) => {
            state.reportDetails.errors = action.payload;
            state.reportDetails.isFetching = false;
        },
        [FETCH_REPORT_DETAILS_SUCCESS]: (state, action) => {
            state.reportDetails.data = action.payload;
            state.reportDetails.isFetching = false;
        },
        [FETCH_PROJECT_CONFIGURATION]: (state) => {
            state.projectConfiguration.errors = null;
            state.projectConfiguration.isFetching = true;
        },
        [FETCH_PROJECT_CONFIGURATION_FAILURE]: (state, action) => {
            state.projectConfiguration.errors = action.payload;
            state.projectConfiguration.isFetching = false;
        },
        [FETCH_PROJECT_CONFIGURATION_SUCCESS]: (state, action) => {
            state.projectConfiguration.data = action.payload;
            state.projectConfiguration.isFetching = false;
        },
        [FETCH_REPORT_COMPLEMENTS]: (state) => {
            state.reportComplements.errors = null;
            state.reportComplements.isFetching = true;
        },
        [FETCH_REPORT_COMPLEMENTS_FAILURE]: (state, action) => {
            state.reportComplements.errors = action.payload;
            state.reportComplements.isFetching = false;
        },
        [FETCH_REPORT_COMPLEMENTS_SUCCESS]: (state, action) => {
            state.reportComplements.data = action.payload;
            state.reportComplements.isFetching = false;
        },
        [FETCH_TEMPLATES]: (state) => {
            state.templates.errors = null;
            state.templates.isFetching = true;
        },
        [FETCH_TEMPLATES_FAILURE]: (state, action) => {
            state.templates.errors = action.payload;
            state.templates.isFetching = false;
        },
        [FETCH_TEMPLATES_SUCCESS]: (state, action) => {
            state.templates.data = action.payload;
            state.templates.isFetching = false;
        },
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [RESET_VALUES]: () => INITIAL_STATE,
        [SAVE]: (state) => {
            state.save.errors = null;
            state.save.isSaving = true;
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save.errors = action.payload;
            state.save.isSaving = false;
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save.data = action.payload;
            state.save.isSaving = false;
        },
        [SAVE_INDICATORS_SETTINGS]: (state) => {
            state.saveIndicatorsSettings.errors = null;
            state.saveIndicatorsSettings.isFetching = true;
        },
        [SAVE_INDICATORS_SETTINGS_FAILURE]: (state, action) => {
            state.saveIndicatorsSettings.errors = action.payload;
            state.saveIndicatorsSettings.isFetching = false;
        },
        [SAVE_INDICATORS_SETTINGS_SUCCESS]: (state, action) => {
            state.saveIndicatorsSettings.data = action.payload;
            state.saveIndicatorsSettings.isFetching = false;
        },
        [SAVE_TEMPLATE]: (state) => {
            state.saveTemplate.errors = null;
            state.saveTemplate.isSaving = true;
        },
        [SAVE_TEMPLATE_FAILURE]: (state, action) => {
            state.saveTemplate.errors = action.payload;
            state.saveTemplate.isSaving = false;
        },
        [SAVE_TEMPLATE_SUCCESS]: (state, action) => {
            state.saveTemplate.data = action.payload;
            state.saveTemplate.isSaving = false;
        },
        [SET_IS_OPEN_INDICATORS_SETTINGS_MODAL]: (state, action) => {
            state.isOpenIndicatorsSettingsModal = action.payload;
        },
        [SET_IS_OPEN_PERIODS_SETTINGS_MODAL]: (state, action) => {
            state.isOpenPeriodsSettingsModal = action.payload;
        },
        [SET_REPORT_END]: (state) => {
            state.reportEnd.errors = null;
            state.reportEnd.isSaving = true;
        },
        [SET_REPORT_END_FAILURE]: (state, action) => {
            state.reportEnd.errors = action.payload;
            state.reportEnd.isSaving = false;
        },
        [SET_REPORT_END_SUCCESS]: (state, action) => {
            state.reportEnd.data = action.payload;
            state.reportEnd.isSaving = false;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
