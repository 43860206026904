import React, { useEffect, useState } from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SpeedIcon from '@mui/icons-material/Speed';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import KeyIndicator from 'common/components/KeyIndicator';
import { numberFormat } from 'common/utils/helpers';

import { STEPS_IN_HOUR } from '../constants';

const getTotals = (series, viewOption) => {
    const totals = [];

    if (!Array.isArray(series) || series?.length === 0) return totals;

    for (let i = 0; i < series.length; i++) {
        const serie = series[i];
        const total = serie?.data?.reduce((acc, cur) => {
            if (Array.isArray(cur)) return acc + (parseFloat(cur[1]) || 0);
            return acc + (parseFloat(cur) || 0);
        }, 0);
        totals.push({
            name: serie.name,
            total: total / (STEPS_IN_HOUR[viewOption] || 1),
        });
    }

    return totals;
};

const getNumberFormatOptions = ({
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    isCurrency,
    unit,
}) => {
    if (isCurrency) {
        return {
            currency: countryCurrencyIso,
            locale: countryCurrencyLocale,
            style: 'currency',
            symbol: countryCurrencySymbol,
        };
    }

    return {
        decimals: 2,
        locale: countryCurrencyLocale,
        style: 'decimal',
        unit,
    };
};

const KeyIndicators = ({
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    isCurrency,
    series,
    unit = 'kWh',
    viewOption,
}) => {
    const [totals, setTotals] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (isEmpty(series)) return;
        setTotals(getTotals(series, viewOption));
    }, [series]);

    const numberFormatOptions = getNumberFormatOptions({
        countryCurrencyIso,
        countryCurrencyLocale,
        countryCurrencySymbol,
        isCurrency,
        unit,
    });
    const Icon = isCurrency ? AttachMoneyIcon : SpeedIcon;

    return (
        <Grid container>
            {totals.map((item, index) => {
                if (item.name === t('Battery power')) return null;
                return (
                    <Grid item key={`${item.name}-${index}`} xs={9}>
                        <KeyIndicator
                            svgIcon={<Icon />}
                            title={item.name}
                            value={numberFormat(
                                item.total || 0,
                                numberFormatOptions,
                            )}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

KeyIndicators.propTypes = {
    countryCurrencyIso: PropTypes.string,
    countryCurrencyLocale: PropTypes.string,
    countryCurrencySymbol: PropTypes.string,
    isCurrency: PropTypes.bool,
    series: PropTypes.array,
    unit: PropTypes.string,
    viewOption: PropTypes.number,
};

export default KeyIndicators;
