import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import Financiers from './Financiers';
import TermsConditions from './TermsConditions';

const AddAllianceModal = ({
    fetchFinarciers,
    fetchRequirements,
    financiers,
    handleClickAlliance,
    isFetchingRequirements,
    isFetchingFinanciers,
    showModal,
    requirements,
    saveAlliance,
    setShowModal,
}) => {
    const { t } = useTranslation();

    const [modalStep, setModalStep] = useState(1);
    const [selectedFinancier, setSelectedFinancier] = useState(null);

    useEffect(() => {
        if (modalStep === 2) fetchRequirements(selectedFinancier.id);
    }, [modalStep]);

    const handleOnClickSelectFinancier = (financier) => {
        setSelectedFinancier(financier);
        setModalStep(2);
    };
    const handleOnClickAcceptRequirements = () => {
        saveAlliance(selectedFinancier, (alliance) => {
            setShowModal(false);
            handleClickAlliance(alliance);
        });
    };

    const handleOnEnterModal = () => fetchFinarciers();

    const getTitle = (step) => {
        switch (step) {
            case 2:
                return t('Terms and conditions');
            default:
                return t('Choose with whom you want to enable your alliance');
        }
    };

    return (
        <Dialog
            onClose={() => setShowModal(false)}
            onEnter={handleOnEnterModal}
            onExited={() => setModalStep(1)}
            open={showModal}
            title={getTitle(modalStep)}
        >
            {modalStep === 1 && (
                <Financiers
                    financiers={financiers}
                    handleOnClick={handleOnClickSelectFinancier}
                    isFetching={isFetchingFinanciers}
                />
            )}

            {modalStep === 2 && (
                <TermsConditions
                    handleOnClick={handleOnClickAcceptRequirements}
                    isFetching={isFetchingRequirements}
                    requirements={requirements}
                    selectedFinancier={selectedFinancier}
                />
            )}
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    financiers: selectors.getFinanciersData,
    isFetchingFinanciers: selectors.getFinanciersIsFetching,
    isFetchingRequirements: selectors.getRequirementsIsFetching,
    requirements: selectors.getRequirementsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchFinarciers: () => dispatch(actions.fetchFinarciers()),
    fetchRequirements: (id) => dispatch(actions.fetchRequirements(id)),
    saveAlliance: (selectedFinancier, callback) =>
        dispatch(actions.saveAlliance(selectedFinancier, callback)),
});

AddAllianceModal.propTypes = {
    fetchFinarciers: PropTypes.func,
    fetchRequirements: PropTypes.func,
    financiers: PropTypes.array,
    handleClickAlliance: PropTypes.func,
    isFetchingFinanciers: PropTypes.bool,
    isFetchingRequirements: PropTypes.bool,
    showModal: PropTypes.bool,
    requirements: PropTypes.object,
    saveAlliance: PropTypes.func,
    setShowModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAllianceModal);
