import { styled } from '@mui/material/styles';
import { Grid } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

export default styled(Grid)(({ theme }) => ({
    height: 'calc(100vh - 56px)',
    marginTop: 1,
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 120px)',
    },
    ...scroll.object,
}));
