import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

export default styled(Box)`
    height: calc(100vh - 96px);
    overflow-x: hidden
    overflow-y: scroll;
    padding: 0 10px;
    
    ${scroll.custom()}
`;
