import React, { useState, useEffect } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as proposalGeneratorOneColumnActions from '../proposalGeneratorOneColumn/actions';
import summaryTemplateReview from '../summaryTemplateReview';

import * as actions from './actions';
import * as selectors from './selectors';

const Container = ({
    canModify,
    canView,
    fetchSummaryInfoPatern,
    handleClickBreadcrumb,
    isFetchingSummaryInfoPatern,
    match,
    summaryInfoPatern,
}) => {
    const [editionDisabled, setEditionDisabled] = useState(true);
    useEffect(() => {
        fetchSummaryInfoPatern(match.params.uid);
    }, []);

    if (isFetchingSummaryInfoPatern || !canView) return null;

    return (
        <summaryTemplateReview.Container
            breadcrumbLocations={[
                {
                    label: get(summaryInfoPatern, 'contactName', null),
                    action: () =>
                        handleClickBreadcrumb(
                            get(summaryInfoPatern, 'contactId', null),
                        ),
                },
                {
                    label: get(summaryInfoPatern, 'projectName', null),
                    action: () =>
                        handleClickBreadcrumb(
                            get(summaryInfoPatern, 'contactId', null),
                            get(summaryInfoPatern, 'projectId', null),
                            null,
                            'summaries',
                        ),
                },
            ]}
            canModify={canModify}
            currentBreadcrumb={summaryInfoPatern.summaryName}
            editionDisabled={editionDisabled}
            isLoading={isFetchingSummaryInfoPatern}
            setEditionDisabled={setEditionDisabled}
            templateName={summaryInfoPatern.summaryName}
            templateType={types.MULTIPROPOSAL_TYPE}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingSummaryInfoPatern: selectors.getIsFetchingSummaryInfoPatern,
    summaryInfoPatern: selectors.getDataSummaryrInfoPatern,
});

const mapDispatchToProps = (dispatch) => ({
    fetchSummaryInfoPatern: (id) =>
        dispatch(actions.fetchSummaryInfoPatern(id)),
    handleClickBreadcrumb: (contactId, projetcId, proposalId, type) =>
        dispatch(
            proposalGeneratorOneColumnActions.handleClickBreadcrumb(
                contactId,
                projetcId,
                proposalId,
                type,
            ),
        ),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    fetchSummaryInfoPatern: PropTypes.func,
    handleClickBreadcrumb: PropTypes.func,
    isFetchingSummaryInfoPatern: PropTypes.bool,
    match: PropTypes.object,
    summaryInfoPatern: PropTypes.object,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION),
    withRouter,
)(Container);
