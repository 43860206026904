export const NAME = 'energy-stations';

export const STATUS_KEYS = {
    ONLINE: 1,
    OFFLINE: 2,
    ALARM: 3,
};

export const STATUS = {
    [STATUS_KEYS.ONLINE]: {
        color: '#00b894',
        label: 'Online',
    },
    [STATUS_KEYS.OFFLINE]: {
        color: '#fdcb6e',
        label: 'Offline',
    },
    [STATUS_KEYS.ALARM]: {
        color: '#d63031',
        label: 'Alarm',
    },
};

export const STATION_COLUMNS = [
    'station_name',
    'id',
    'city',
    'type',
    'project',
    'state',
];

export const STATION_TYPES_KEYS = {
    GRID: 0,
    STORAGE: 1,
    AC_COUPLE: 2,
    EPM: 3,
    BUILT_IN_METER: 4,
    EXTERNAL_METER: 5,
    S5_OFFLINE_PARALLEL_STORAGE: 6,
    S5_GRID_PARALLEL_STORAGE: 7,
    GRID_AC_COUPLE: 8,
    OFF_GRID_STORAGE: 9,
    S6_GRID_PARALLEL_STORAGE: 10,
    S6_OFFLINE_PARALLEL_STORAGE: 11,
};

export const STATION_TYPES = {
    [STATION_TYPES_KEYS.GRID]: 'Grid',
    [STATION_TYPES_KEYS.STORAGE]: 'Storage',
    [STATION_TYPES_KEYS.AC_COUPLE]: 'AC Couple',
    [STATION_TYPES_KEYS.EPM]: 'EPM',
    [STATION_TYPES_KEYS.BUILT_IN_METER]: 'Built-in meter',
    [STATION_TYPES_KEYS.EXTERNAL_METER]: 'External meter',
    [STATION_TYPES_KEYS.S5_OFFLINE_PARALLEL_STORAGE]:
        'S5 offline and parallel energy storage',
    [STATION_TYPES_KEYS.S5_GRID_PARALLEL_STORAGE]:
        'S5 grid and parallel energy storage',
    [STATION_TYPES_KEYS.GRID_AC_COUPLE]: 'Grid+AC Couple',
    [STATION_TYPES_KEYS.OFF_GRID_STORAGE]: 'Off grid energy storage',
    [STATION_TYPES_KEYS.S6_GRID_PARALLEL_STORAGE]:
        'S6 grid and parallel energy storage',
    [STATION_TYPES_KEYS.S6_OFFLINE_PARALLEL_STORAGE]:
        'S6 offline and parallel energy storage',
};
