import React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { PageThumbnail, templateCoreHelpers } from 'sunwise-template-core';
import { Box } from 'sunwise-ui';

const BORDER_WIDTH = 4;
const THUMBNAIL_HEIGHT = 101;
const THUMBNAIL_WIDTH = 71;

const Container = styled('div')`
    align-items: center;
    display: flex;
    position: relative;

    svg {
        position: absolute;
        right: 4px;
        top: 4px;
        z-index: 2;
    }
`;

const PageWrapper = styled('div')`
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    border: ${BORDER_WIDTH}px solid transparent;
    cursor: pointer;
    display: flex;
    height: ${({ orientation, thumbnailHeight, thumbnailWidth }) =>
        orientation === 'portrait' ? thumbnailHeight : thumbnailWidth}px;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: ${({ orientation, thumbnailHeight, thumbnailWidth }) =>
        orientation === 'portrait' ? thumbnailWidth : thumbnailHeight}px;
    z-index: 1;

    &.selected {
        border: ${BORDER_WIDTH}px solid #008dff;
    }
`;

const StyledPageThumbnail = styled(PageThumbnail)`
    && {
        height: ${({ orientation, thumbnailHeight, thumbnailWidth }) =>
            (orientation === 'portrait' ? thumbnailHeight : thumbnailWidth) -
            BORDER_WIDTH * 2}px;
        min-height: initial;
        min-width: initial;
        width: ${({ orientation, thumbnailHeight, thumbnailWidth }) =>
            (orientation === 'portrait' ? thumbnailWidth : thumbnailHeight) -
            BORDER_WIDTH * 2}px;

        & > div {
            transform: scale(
                ${({
                    orientation,
                    pageHeight,
                    pageWidth,
                    thumbnailHeight,
                    thumbnailWidth,
                }) =>
                    orientation === 'portrait'
                        ? (thumbnailWidth - BORDER_WIDTH * 2) / pageWidth
                        : (thumbnailHeight - BORDER_WIDTH * 2) / pageHeight},
                ${({
                    orientation,
                    pageHeight,
                    pageWidth,
                    thumbnailHeight,
                    thumbnailWidth,
                }) =>
                    orientation === 'portrait'
                        ? (thumbnailHeight - BORDER_WIDTH * 2) / pageHeight
                        : (thumbnailWidth - BORDER_WIDTH * 2) / pageWidth}
            );
        }
    }
`;

const ItemPreviewPage = ({
    handleClickSelect,
    location = 'sidebar',
    page,
    pageSize,
    selected,
}) => {
    let thumbnailWidth = THUMBNAIL_WIDTH;
    let thumbnailHeight = THUMBNAIL_HEIGHT;
    if (location === 'pageSelector') {
        thumbnailWidth = 180;
        thumbnailHeight = 250;
    }
    const { height: pageHeight, width: pageWidth } =
        templateCoreHelpers.getPageDimensions(pageSize);
    const orientation = page?.orientation || 'portrait';
    return (
        <Container orientation={page?.orientation}>
            <PageWrapper
                className={selected && 'selected'}
                onClick={handleClickSelect}
                orientation={orientation}
                thumbnailHeight={thumbnailHeight}
                thumbnailWidth={thumbnailWidth}
            >
                <Box sx={{ pointerEvents: 'none' }}>
                    <StyledPageThumbnail
                        orientation={orientation}
                        page={page}
                        pageHeight={pageHeight}
                        pageWidth={pageWidth}
                        thumbnailHeight={thumbnailHeight}
                        thumbnailWidth={thumbnailWidth}
                    />
                </Box>
                {page.blocked === true && <LockIcon />}
            </PageWrapper>
        </Container>
    );
};

ItemPreviewPage.propTypes = {
    handleClickSelect: PropTypes.func,
    location: PropTypes.string,
    page: PropTypes.object,
    pageSize: PropTypes.string,
    selected: PropTypes.bool,
};

export default ItemPreviewPage;
