import { createSlice } from '@reduxjs/toolkit';

import {
    INITIALIZE_VALUES,
    RESET,
    RESET_FORM,
    SAVE_COMMENT,
    SAVE_COMMENT_SUCCESS,
    SAVE_COMMENT_FAILURE,
    SET_IS_OPEN_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: {
        comment: '',
        proposalId: null,
    },
    isOpenModal: false,
    saveComment: {
        isSaving: false,
        data: null,
        errors: [],
    },
};

const commentModalSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIALIZE_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_COMMENT]: (state) => {
            state.saveComment = {
                ...state.saveComment,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_COMMENT_FAILURE]: (state, action) => {
            state.saveComment = {
                ...state.saveComment,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_COMMENT_SUCCESS]: (state, action) => {
            state.saveComment = {
                ...state.saveComment,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const commentModalActions = commentModalSlice.actions;

export default commentModalSlice.reducer;
