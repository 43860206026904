import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from 'sunwise-ui';

import afterSalesMeasurementSource from '../../afterSalesMeasurementSource';
import afterSalesSettings from '../../afterSalesSettings';
import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';

import Providers from './Providers';

const Settings = ({ canDelete, canModify, rate, selectedProjectId }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();

    return (
        <Accordion
            expanded={isExpanded}
            onChange={() => setIsExpanded(!isExpanded)}
            disableGutters
            sx={{
                boxShadow: 'none!important',
                '&::before': { display: 'none' },
            }}
        >
            <AccordionSummary
                expandIcon={null}
                sx={{
                    borderBottom: (theme) =>
                        `1px solid ${theme.palette.divider}`,
                    gap: 2,
                    justifyContent: 'flex-start',
                    p: 0,
                    '& .MuiAccordionSummary-content': {
                        alignItems: 'center',
                        flexGrow: 0,
                        gap: 1,
                        width: '100%',
                    },
                }}
            >
                <SettingsIcon />

                <Typography variant="body2" fontWeight="bold">
                    {t('Setting')}
                </Typography>

                {!isExpanded && rate?.name && (
                    <Typography
                        variant="caption"
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {rate?.name}
                    </Typography>
                )}

                <ExpandMoreIcon sx={{ mr: 'auto' }} />

                {!isExpanded && <Providers />}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
                <afterSalesSettings.Container
                    canModify={canModify}
                    selectedProjectId={selectedProjectId}
                />

                <afterSalesMeasurementSource.Container
                    canDelete={canDelete}
                    canModify={canModify}
                    selectedProjectId={selectedProjectId}
                />
            </AccordionDetails>
        </Accordion>
    );
};

const mapStateToProps = createStructuredSelector({
    rate: afterSalesSettingsSelectors.getAfterSalesSettingsRate,
});

Settings.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    rate: PropTypes.object,
    selectedProjectId: PropTypes.string,
};

export default connect(mapStateToProps)(Settings);
