import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Typography } from 'sunwise-ui';

const DemandRatioWarning = ({ inconsistentDemands }) => {
    const { t } = useTranslation();

    return (
        inconsistentDemands?.length > 0 && (
            <Alert severity="error">
                {t(
                    'The consumption - demand ratio is not congruent for the following fields',
                ).concat(':')}

                {inconsistentDemands.map((key) => (
                    <Typography
                        key={`inconsistent-kWh-${key}`}
                        pl={1}
                        variant="body2"
                    >
                        *{key}
                    </Typography>
                ))}
            </Alert>
        )
    );
};

DemandRatioWarning.propTypes = {
    inconsistentDemands: PropTypes.array,
};

export default DemandRatioWarning;
