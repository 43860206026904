import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import {
    getCurrencyIso,
    getCurrencySymbol,
    getOppositeCurrency,
} from 'common/utils/helpers';

const DiscountTypeFields = ({
    canModifyDiscounts,
    canModifyPricePerWatt,
    canModifySubtotal,
    control,
    currencies,
    currencyIso,
    currencySymbol,
    discountType,
    isLocked,
}) => {
    const { t } = useTranslation();
    const _oppositeCurrency = getOppositeCurrency(currencies);
    const _currencyIso = getCurrencyIso(_oppositeCurrency);
    const _currencySymbol = getCurrencySymbol(_oppositeCurrency);

    switch (discountType) {
        case 0:
            return (
                <ReactHookFormIntlNumberInput
                    append="%"
                    control={control}
                    disabled={
                        parseInt(discountType) !== 0 ||
                        isLocked ||
                        !canModifyDiscounts
                    }
                    fullWidth
                    key="discount"
                    label={t('Quantity')}
                    name="discount"
                    placeholder="%"
                />
            );
        case 1:
            return (
                <ReactHookFormIntlNumberInput
                    append={_currencyIso === 'MXN' ? 'USD' : _currencyIso}
                    control={control}
                    disabled={
                        parseInt(discountType) !== 1 ||
                        isLocked ||
                        !canModifyPricePerWatt
                    }
                    fullWidth
                    key="price_watt"
                    label={t('Quantity')}
                    name="price_watt"
                    placeholder={t('Cost per watt')}
                    prepend={_currencySymbol}
                />
            );
        default:
            return (
                <ReactHookFormIntlNumberInput
                    append={currencyIso}
                    control={control}
                    disabled={
                        parseInt(discountType) !== 2 ||
                        isLocked ||
                        !canModifySubtotal
                    }
                    fullWidth
                    key="price_total"
                    label={t('Quantity')}
                    name="price_total"
                    placeholder={t('Total price')}
                    prepend={currencySymbol}
                />
            );
    }
};

DiscountTypeFields.propTypes = {
    canModifyDiscounts: PropTypes.bool,
    canModifyPricePerWatt: PropTypes.bool,
    canModifySubtotal: PropTypes.bool,
    control: PropTypes.object,
    currencies: PropTypes.array,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    discountType: PropTypes.number,
    isLocked: PropTypes.bool,
};

export default DiscountTypeFields;
