import React from 'react';

import PropTypes from 'prop-types';

import GenerationChartJs from './GenerationChartJs';

const Charts = ({ countryCurrencyLocale, handleSelectProject, seriesData }) => (
    <GenerationChartJs
        countryCurrencyLocale={countryCurrencyLocale}
        handleSelectProject={handleSelectProject}
        seriesData={seriesData}
    />
);

Charts.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    handleSelectProject: PropTypes.func,
    seriesData: PropTypes.object,
};

export default Charts;
