import React from 'react';

import PropTypes from 'prop-types';
import { Box, Skeleton } from 'sunwise-ui';

const items = [];

for (let i = 0; i < 5; i++) {
    items.push(
        <Box key={`content-loader-${i}`} sx={{ my: 1 }}>
            <Skeleton variant="rounded" />
        </Box>,
    );
}

const Placeholder = <>{items}</>;

const PlaceholderTemplateChart = ({ children, ready }) => (
    <>{ready ? Placeholder : children}</>
);

PlaceholderTemplateChart.propTypes = {
    children: PropTypes.object,
    ready: PropTypes.bool,
};

export default PlaceholderTemplateChart;
