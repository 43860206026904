import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Card, Typography } from 'sunwise-ui';

import PlaceholderCardSupportContact from 'common/components/placeholder/PlaceholderCardSupportContact';

import SupportContactItem from './SupportContactItem';

const SupportContactCard = ({
    canDelete,
    canModify,
    handleClickAdd,
    handleClickDelete,
    handleClickUpdate,
    isFetchingSupportContacts,
    supportContacts,
}) => {
    const { t } = useTranslation();

    return (
        <PlaceholderCardSupportContact ready={isFetchingSupportContacts}>
            <Card>
                <Card.Header>
                    <Typography variant="body1" fontWeight="bold">
                        {t('Support contact', { count: 2 })}
                    </Typography>
                </Card.Header>

                <Card.Body>
                    {supportContacts &&
                        supportContacts.map((supportContact, index) => (
                            <SupportContactItem
                                canDelete={canDelete}
                                canModify={canModify}
                                supportContact={supportContact}
                                handleClickUpdate={() =>
                                    handleClickUpdate(supportContact.id)
                                }
                                handleClickDelete={() =>
                                    handleClickDelete(supportContact)
                                }
                                key={index}
                            />
                        ))}

                    {canModify && (
                        <Button
                            color="secondary"
                            endIcon={<AddIcon />}
                            fullWidth
                            onClick={handleClickAdd}
                            sx={{ mt: 1 }}
                            variant="outlined"
                        >
                            {t('Add')}
                        </Button>
                    )}
                </Card.Body>
            </Card>
        </PlaceholderCardSupportContact>
    );
};

SupportContactCard.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleClickAdd: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isFetchingSupportContacts: PropTypes.bool,
    supportContacts: PropTypes.array,
};

export default SupportContactCard;
