import BaseModel from './BaseModel';

export default class TemplateCategory extends BaseModel {}

TemplateCategory.modelName = 'TemplateCategory';
TemplateCategory.prepareFields({
    fileUrl: 'file_url',
    id: 'id',
    name: 'name',
});
