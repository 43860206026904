import React from 'react';

import PropTypes from 'prop-types';

import PasswordForm from './components/PasswordForm';

const Container = ({ callback, origin }) => (
    <PasswordForm callback={callback} origin={origin} />
);

Container.propTypes = {
    callback: PropTypes.func,
    origin: PropTypes.number,
};

export default Container;
