import get from 'lodash/get';

import { handleFileURL } from 'common/utils/helpers';

import * as profileSelectors from '../../profile/selectors';
import { INITIALIZE_FORM } from '../actionTypes';
import { onboardingWizardActions } from '../reducer';

export default (values) => (dispatch, getState) => {
    const userSettings = profileSelectors.getDataFetch(getState());
    const company = get(values, 'company', {});
    const details = get(values, 'details', {});
    const localMembers = localStorage.getItem('tempMembers');
    const localPanelSettings = localStorage.getItem('tempPanelSettings');
    const localInverterSettings = localStorage.getItem('tempInverterSettings');
    const localBatterySettings = localStorage.getItem('tempBatterySettings');
    const isOwner =
        userSettings?.company_group.owner_type === 0 &&
        userSettings?.company_group.type === 0;
    const ownerMember = {
        company_group: userSettings?.company_group?.id,
        email: userSettings?.email,
        first_name: userSettings?.first_name,
        isOwner,
        last_name: userSettings?.last_name,
        roleName: userSettings?.company_group?.name,
    };
    const members = localMembers ? JSON.parse(localMembers) : [ownerMember];
    const panelSettings = localPanelSettings
        ? JSON.parse(localPanelSettings)
        : {};
    const inverterSettings = localInverterSettings
        ? JSON.parse(localInverterSettings)
        : {};
    const batterySettings = localBatterySettings
        ? JSON.parse(localBatterySettings)
        : {};
    const {
        currency,
        dollar_price,
        exchange_type,
        is_enabled,
        id: exchangeRateId,
        inverse_dollar_price,
        source,
    } = get(details, 'exchange_rate', {});

    const {
        id: positionId,
        city,
        zip_code,
        description,
        latitude,
        longitude,
        political_division1,
    } = get(details, 'position', {});

    let newValues = {
        batteries: get(batterySettings, 'batteries', []),
        battery_brand: get(batterySettings, 'battery_brand', ''),
        business_name: company?.business_name,
        city,
        contact_email: company?.contact_email,
        contact_telephone: company?.contact_telephone,
        currency: currency?.id,
        currency_obj: {
            abbreviation: currency?.abbreviation,
            symbol: currency?.symbol,
        },
        description,
        dollar_price,
        exchange_type,
        exchangeRateId,
        image: null,
        inverse_dollar_price,
        inverter_brand: get(inverterSettings, 'inverter_brand', ''),
        inverters: get(inverterSettings, 'inverters', []),
        is_enabled,
        latitude,
        longitude,
        members,
        name: company?.name,
        panel_brand: get(panelSettings, 'panel_brand', ''),
        panels: get(panelSettings, 'panels', []),
        political_division1,
        position: details?.position,
        positionId,
        rfc: company?.rfc,
        source,
        step: values?.section,
        taxes: details?.taxes,
        zip_code,
    };

    if (company.image) {
        newValues.image = handleFileURL(
            company.image,
            import.meta.env.VITE_API_URL_IO,
        );
    }
    dispatch(onboardingWizardActions[INITIALIZE_FORM](newValues));
};
