import { updateProposalSettings } from 'common/api/v2/offerConsumptionAndGeneration';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { getCompensationSchemeByValue } from 'common/utils/helpers/rates';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';
import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { getNormalizedTiersConsumptions } from '../helpers';
import { analysisVsGenerationActions } from '../reducer';

import initializeForm from './initializeForm';

export default (proposalId, values, previousValues) => (dispatch, getState) => {
    if (!values.next_rate || !values.compensation_scheme) return;

    const state = getState();
    const consumptionHistory =
        proposalGeneratorOneColumnSelectors.getConsumptionHistory(state);

    const compensationScheme = getCompensationSchemeByValue(
        values.compensation_scheme,
    );

    const newValues = {
        next_rate: values.next_rate,
        compensation_scheme: compensationScheme.backendKey,
    };

    const nextTiersConsumptions = getNormalizedTiersConsumptions({
        hourlyContractedDemand: values.next_contracted_demand,
        consumptionHistory,
    });

    if (nextTiersConsumptions?.length)
        newValues.next_tiers_consumptions = nextTiersConsumptions;

    dispatch(analysisVsGenerationActions[SAVE]());

    updateProposalSettings(newValues, proposalId)
        .then((response) => {
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
            dispatch(
                analysisVsGenerationActions[SAVE_SUCCESS](response.data.data),
            );
        })
        .catch((error) => {
            dispatch(
                analysisVsGenerationActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            dispatch(initializeForm(previousValues));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
