import React, { useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { IconButton, InputAdornment, TextField } from 'sunwise-ui';

const ReactHookFormInputPassword = ({
    control,
    fullWidth = true,
    label,
    name,
    onChange,
    prepend,
    prependIcon,
    prependSvgIcon,
    variant = 'outlined',
    ...rest
}) => {
    const [type, setType] = useState('password');

    const onChangeType = () =>
        setType((prev) => (prev === 'password' ? 'text' : 'password'));

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    error={Boolean(error)}
                    fullWidth={fullWidth}
                    helperText={error?.message}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={onChangeType}
                                    onMouseDown={(event) =>
                                        event.preventDefault()
                                    }
                                >
                                    {type === 'text' ? (
                                        <VisibilityOffIcon fontSize="small" />
                                    ) : (
                                        <VisibilityIcon fontSize="small" />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                        startAdornment: (prepend ||
                            prependIcon ||
                            prependSvgIcon) && (
                            <InputAdornment position="start">
                                {prependIcon && <i className={prependIcon} />}
                                {prependSvgIcon && (
                                    <img alt="" src={prependSvgIcon} />
                                )}
                                {prepend}
                            </InputAdornment>
                        ),
                    }}
                    label={label}
                    name={name}
                    onChange={(e) => {
                        field.onChange(e);
                        if (onChange) onChange(e);
                    }}
                    type={type}
                    variant={variant}
                    {...rest}
                />
            )}
        />
    );
};

ReactHookFormInputPassword.propTypes = {
    control: PropTypes.object,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    prepend: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.element,
        PropTypes.string,
    ]),
    prependIcon: PropTypes.string,
    prependSvgIcon: PropTypes.string,
    variant: PropTypes.string,
};

export default ReactHookFormInputPassword;
