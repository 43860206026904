import React from 'react';

import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const RateFormatter = ({ row }) => {
    return <Typography variant="caption">{row?.rate_name}</Typography>;
};

RateFormatter.propTypes = {
    row: PropTypes.object,
};

export default RateFormatter;
