import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const IconSelfConsumption = ({ color, height = '24px', width = '24px' }) => {
    const theme = useTheme();
    const fillColor = color || theme.palette.primary.main;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 136 136"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2153_10409)">
                <path
                    d="M105.68 47.3153L89.8708 63.1248C88.0575 64.9381 89.3041 67.998 91.8541 67.998H101.997C101.997 86.7541 86.7542 101.997 67.9981 101.997C63.5216 101.997 59.1584 101.147 55.2485 99.5037C53.2086 98.6538 50.8853 99.2771 49.3553 100.807C46.4654 103.697 47.4854 108.57 51.2819 110.1C56.4385 112.197 62.105 113.33 67.9981 113.33C93.044 113.33 113.33 93.0439 113.33 67.998H123.473C126.023 67.998 127.27 64.9381 125.456 63.1815L109.647 47.3719C108.57 46.2386 106.757 46.2387 105.68 47.3153V47.3153ZM33.9991 67.998C33.9991 49.2419 49.242 33.999 67.9981 33.999C72.4747 33.999 76.8379 34.849 80.7477 36.4923C82.7877 37.3422 85.1109 36.7189 86.6409 35.189C89.5308 32.2991 88.5108 27.4259 84.7143 25.8959C79.5578 23.7993 73.8913 22.666 67.9981 22.666C42.9522 22.666 22.6661 42.9521 22.6661 67.998H12.5231C9.97317 67.998 8.72654 71.0579 10.5398 72.8145L26.3494 88.6241C27.4827 89.7574 29.2393 89.7574 30.3726 88.6241L46.1821 72.8145C47.9387 71.0579 46.6921 67.998 44.1422 67.998H33.9991V67.998Z"
                    fill={fillColor}
                />
            </g>
            <defs>
                <clipPath id="clip0_2153_10409">
                    <rect width="135.996" height="135.996" fill={fillColor} />
                </clipPath>
            </defs>
        </svg>
    );
};

IconSelfConsumption.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconSelfConsumption;
