import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from './actions';
import CommentForm from './components/CommentForm';
import * as selectors from './selectors';

const Container = ({ closeModal, isOpenModal, reset }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={() => closeModal(false)}
            onExited={() => reset()}
            open={isOpenModal}
            title={t('Edit proposal')}
            size="sm"
        >
            <CommentForm closeModal={closeModal} />
        </Dialog>
    );
};

Container.propTypes = {
    closeModal: PropTypes.func,
    isOpenModal: PropTypes.bool,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isOpenModal: selectors.geIsOpenModal,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(actions.closeModal()),
    reset: () => dispatch(actions.reset()),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(Container);
