import { AddAlliance } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SAVE_FINANCIERS_INSTALLER,
    SAVE_FINANCIERS_INSTALLER_FAILURE,
    SAVE_FINANCIERS_INSTALLER_SUCCESS,
} from '../actionTypes';
import { financingAndInsuranceActions } from '../reducer';

import fetchFinancierInstallers from './fetchFinancierInstallers';

export default (selectedFinancier, callback) => (dispatch) => {
    const newValues = {
        financier: selectedFinancier.id,
    };

    dispatch(financingAndInsuranceActions[SAVE_FINANCIERS_INSTALLER]());

    AddAlliance(newValues)
        .then((response) => {
            dispatch(
                financingAndInsuranceActions[SAVE_FINANCIERS_INSTALLER_SUCCESS](
                    response.data,
                ),
            );
            dispatch(fetchFinancierInstallers());
            if (callback) callback(response.data);
        })
        .catch((error) => {
            dispatch(
                financingAndInsuranceActions[SAVE_FINANCIERS_INSTALLER_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
