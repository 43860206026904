import { deleteProject } from 'common/api/afterSales/projects';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_DATA,
    DELETE_DATA_FAILURE,
    DELETE_DATA_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import fetchPowerStations from './fetchPowerStations';

export default (id, sunwiseProject) => (dispatch) => {
    if (!id) return;

    dispatch(actions[DELETE_DATA]());

    deleteProject(id)
        .then((response) => {
            dispatch(actions[DELETE_DATA_SUCCESS](response.data));
            showToast();

            dispatch(fetchPowerStations(sunwiseProject));
        })
        .catch((error) => {
            dispatch(actions[DELETE_DATA_FAILURE](error));
            showFastApiErrorsAsAlert(dispatch, error?.response);
        });
};
