import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActions, Box, Button } from 'sunwise-ui';

import { HOURLY_RATE_TYPE } from '../../../constants';
import SectionHeader from '../../SectionHeader';

import FieldArrayPeriods from './FieldArrayPeriods';
import HourlyRate from './HourlyRate';

const GROUP_NAME = 'settings_data';

const SettingsDataTab = ({
    changeTab,
    control,
    getValues,
    isAnnualType,
    setValue,
    showMode,
    trigger,
}) => {
    const { t } = useTranslation();
    const paymentType = useWatch({ control, name: 'main_data.payment_type' });

    return (
        <Box>
            {!isAnnualType && (
                <SectionHeader title={t('Billing period')} type="2" />
            )}
            {paymentType === HOURLY_RATE_TYPE && (
                <HourlyRate
                    changeTab={changeTab}
                    control={control}
                    getValues={getValues}
                    isAnnualType={isAnnualType}
                    name={`${GROUP_NAME}.periods`}
                    paymentType={paymentType}
                    setValue={setValue}
                    showMode={showMode}
                    trigger={trigger}
                />
            )}

            {paymentType !== HOURLY_RATE_TYPE && (
                <>
                    <FieldArrayPeriods
                        control={control}
                        getValues={getValues}
                        isAnnualType={isAnnualType}
                        name={`${GROUP_NAME}.periods`}
                        paymentType={paymentType}
                        setValue={setValue}
                        showMode={showMode}
                        trigger={trigger}
                    />

                    {!showMode && (
                        <BottomActions>
                            <Button
                                onClick={() => changeTab('policy_data')}
                                sx={{ mt: 2 }}
                                type="button"
                            >
                                {t('Next')}
                            </Button>
                        </BottomActions>
                    )}
                </>
            )}
        </Box>
    );
};

SettingsDataTab.propTypes = {
    changeTab: PropTypes.func,
    control: PropTypes.object,
    getValues: PropTypes.func,
    isAnnualType: PropTypes.bool,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
    trigger: PropTypes.func,
};

export default SettingsDataTab;
