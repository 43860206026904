import React from 'react';

import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import SourceInfoChip from './SourceInfoChip';

const InfoCell = ({ infoKeys, row }) => {
    if (!infoKeys?.length || !row) return null;

    return (
        <Box alignItems="center" display="flex" flexWrap="wrap" gap={2}>
            {infoKeys.map((key) =>
                row[key] ? (
                    <SourceInfoChip active infoKey={key} key={key} />
                ) : null,
            )}
        </Box>
    );
};

InfoCell.propTypes = {
    infoKeys: PropTypes.array,
    row: PropTypes.object,
};

export default InfoCell;
