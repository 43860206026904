import React, { useEffect } from 'react';

import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Link, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import { InfoToolTip, TitleWithDetail } from 'common/components';
import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import { HIGH_TENSION_RATES } from 'common/constants/rates';

const RoiFields = ({
    canModifyRoiSolarDegradation,
    canModifyRoiTaxIncentive,
    canModifyStockExchange,
    clearErrors,
    control,
    isLocked,
    nextRateData,
    setValue,
    showDeprecatedField = false,
    showSolarDegradationField = true,
}) => {
    const { t } = useTranslation();
    const [hasIsr, isrSimulationType] = useWatch({
        control,
        name: ['isr', 'isr_simulation_type'],
    });

    useEffect(() => {
        // Clear errors when isr_simulation_type changes
        clearErrors('isr_simulation_annual');
        clearErrors('isr_simulation_monthly');
    }, [isrSimulationType, clearErrors]);

    const renderIsrSimulation = () => {
        if (parseInt(isrSimulationType) === 1) {
            return (
                <ReactHookFormIntlNumberInput
                    append={t('Year', {
                        count: 2,
                    })}
                    control={control}
                    disabled={isLocked || !canModifyRoiTaxIncentive}
                    fullWidth
                    id="isr_simulation_annual"
                    label={t('Simulation time')}
                    name="isr_simulation_annual"
                    placeholder="0"
                    step="any"
                />
            );
        }
        return (
            <ReactHookFormIntlNumberInput
                append={t('Month', {
                    count: 2,
                })}
                control={control}
                disabled={isLocked || !canModifyRoiTaxIncentive}
                fullWidth
                id="isr_simulation_monthly"
                label={t('Simulation time')}
                name="isr_simulation_monthly"
                placeholder="0"
                step="any"
            />
        );
    };

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">{t('ROI')}</TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} md={6} lg={4}>
                    <ReactHookFormCheck
                        control={control}
                        disabled={isLocked || !canModifyRoiTaxIncentive}
                        label={t('With tax incentive')}
                        name="isr"
                    />
                </Grid>
                {hasIsr && (
                    <>
                        <Grid item xs={18} md={5}>
                            <ReactHookFormIntlNumberInput
                                append="%"
                                control={control}
                                disabled={isLocked || !canModifyRoiTaxIncentive}
                                fullWidth
                                label={t('Percentage')}
                                name="isr_percentage"
                                placeholder="%"
                            />
                        </Grid>
                        <Grid item xs={18} md={5}>
                            {renderIsrSimulation()}
                        </Grid>
                        <Grid item xs={18} md={4}>
                            <ToggleButtonGroup
                                color="primary"
                                disabled={isLocked || !canModifyRoiTaxIncentive}
                                exclusive
                                fullWidth
                                onChange={(e) =>
                                    setValue(
                                        'isr_simulation_type',
                                        e.target.value,
                                    )
                                }
                                value={parseInt(isrSimulationType)}
                                size="small"
                                sx={{ mb: 2 }}
                            >
                                <ToggleButton value={0}>
                                    {t('Monthly')}
                                </ToggleButton>
                                <ToggleButton value={1}>
                                    {t('Yearly')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </>
                )}
            </Grid>

            {((!isNull(nextRateData) &&
                nextRateData?.certified &&
                HIGH_TENSION_RATES.includes(nextRateData.name)) ||
                showDeprecatedField) && (
                <Box alignItems="center" display="flex" gap={1} mb={3}>
                    <ReactHookFormCheck
                        control={control}
                        disabled={isLocked}
                        label={t('Modify calculation to pre-2018 resolution')}
                        name="deprecated"
                        sxFormControl={{ mb: '0px !important' }}
                    />

                    <InfoToolTip variant="no-wrapper">
                        {t(
                            'Affects the calculation formula for "Capacidad" and "Distribución" concepts. Learn more at',
                        )}{' '}
                        <Link
                            href={`${
                                import.meta.env.VITE_ACADEMY_URL
                            }es/articles/9332918-calculos-de-capacidad-y-distribucion-resolucion-pre-2018`}
                            target="_blank"
                        >
                            Sunwise Academy
                        </Link>
                    </InfoToolTip>
                </Box>
            )}

            <Grid container>
                {showSolarDegradationField && (
                    <Grid item sm xs={18}>
                        <ReactHookFormIntlNumberInput
                            append="%"
                            caption={`% ${t('Annual')}`}
                            control={control}
                            disabled={isLocked || !canModifyRoiSolarDegradation}
                            fullWidth
                            label={t('Solar degradation')}
                            name="degradation_percentage"
                            placeholder="%"
                        />
                    </Grid>
                )}

                <Grid item sm={9} xs={18}>
                    <ReactHookFormIntlNumberInput
                        allowDecimals={false}
                        allowNegativeValue={false}
                        append={t('Year', { count: 2 })}
                        control={control}
                        disabled={isLocked || !canModifyStockExchange}
                        fullWidth
                        label={t('Simulation years')}
                        max={50}
                        name="bag_years"
                    />
                </Grid>
            </Grid>
        </>
    );
};

RoiFields.propTypes = {
    canModifyRoiSolarDegradation: PropTypes.bool,
    canModifyRoiTaxIncentive: PropTypes.bool,
    canModifyStockExchange: PropTypes.bool,
    clearErrors: PropTypes.func,
    control: PropTypes.object,
    isLocked: PropTypes.bool,
    nextRateData: PropTypes.object,
    setValue: PropTypes.func,
    showDeprecatedField: PropTypes.bool,
    showSolarDegradationField: PropTypes.bool,
};

export default RoiFields;
