import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getAlert = createSelector(getModel, (model) => model.alert);

export const getIsOpen = createSelector(getModel, (model) => model.isOpen);

export const getTitle = createSelector(getAlert, (alert) => alert.title);

export const getDisabled = createSelector(getAlert, (alert) => alert.disabled);

export const getMessages = createSelector(getAlert, (alert) => alert.messages);

export const getVariant = createSelector(getAlert, (alert) => alert.variant);

export const getType = createSelector(getAlert, (alert) => alert.type);

export const getOnCancel = createSelector(getAlert, (alert) => alert.onCancel);

export const getOnSuccess = createSelector(
    getAlert,
    (alert) => alert.onSuccess,
);

export const getConfirmText = createSelector(
    getAlert,
    (alert) => alert.confirmText || i18next.t('Confirm'),
);

export const getCancelText = createSelector(
    getAlert,
    (alert) => alert.cancelText || i18next.t('Cancel'),
);

export const getClassNameMessages = createSelector(
    getAlert,
    (alert) => alert.classNameMessages,
);

export const getHaveTextCaptcha = createSelector(
    getAlert,
    (alert) => alert.haveTextCaptcha,
);

export const getHaveTextResponse = createSelector(
    getAlert,
    (alert) => alert.haveTextResponse,
);

export const getTextToValidate = createSelector(
    getAlert,
    (alert) => alert.textToValidate,
);

export const getHideButtons = createSelector(
    getAlert,
    (alert) => alert.hideButtons,
);

export const getDefaultTextResponse = createSelector(
    getAlert,
    (alert) => alert.defaultTextResponse,
);

export const getSize = createSelector(getAlert, (alert) => alert.size);
