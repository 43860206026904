import { generateCheckoutNewUrl } from 'common/api/v1/chargebee';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { selectBillingPlanActions } from '../reducer';

import confirmSubscription from './confirmSubscription';

export default (cbInstance, itemPriceId) => (dispatch) => {
    if (!cbInstance || !itemPriceId) return;

    dispatch(selectBillingPlanActions[SAVE_ITEM]());

    cbInstance.openCheckout({
        hostedPage() {
            return generateCheckoutNewUrl({ item_price_id: itemPriceId }).then(
                (response) => response.data,
            );
        },
        success() {
            dispatch(selectBillingPlanActions[SAVE_ITEM_SUCCESS]());
            cbInstance.closeAll();
            dispatch(confirmSubscription());
        },
        close() {
            dispatch(selectBillingPlanActions[SAVE_ITEM_SUCCESS]());
        },
        step() {
            dispatch(selectBillingPlanActions[SAVE_ITEM_FAILURE]());
        },
    });
};
