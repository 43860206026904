import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'sunwise-ui';

import { InputSearch } from 'common/components';

import BulkEditionMenu from './BulkEditionMenu';

const BulkOperationsCard = ({
    canView,
    filterData,
    handleClickActivateItems,
    handleOnChangeSearch,
    selectionModel,
    setSelectionModel,
}) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Body>
                <Grid container alignItems="end">
                    <Grid
                        item
                        xs={18}
                        md={isEmpty(selectionModel) ? 18 : 12}
                        lg={isEmpty(selectionModel) ? 18 : 14}
                    >
                        <InputSearch
                            onChange={handleOnChangeSearch}
                            placeholder={t('Search')}
                            value={filterData?.searchText}
                            visible={canView}
                        />
                    </Grid>
                    {!isEmpty(selectionModel) && (
                        <Grid
                            item
                            xs={18}
                            md={6}
                            lg={4}
                            sx={{ order: { xs: 2, md: 1 } }}
                        >
                            <BulkEditionMenu
                                handleClickActivateItems={
                                    handleClickActivateItems
                                }
                                selectionModel={selectionModel}
                                setSelectionModel={setSelectionModel}
                            />
                        </Grid>
                    )}
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    canView: PropTypes.bool,
    filterData: PropTypes.object,
    handleClickActivateItems: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
};

export default BulkOperationsCard;
