import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, IconButton, Typography } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';

const ReduxFieldArrayEmails = ({ control, isDisabled }) => {
    const { t } = useTranslation();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'emails',
    });
    return (
        <>
            {fields.length > 0 && (
                <Grid item xs={18}>
                    {fields.map((item, i) => (
                        <Grid container key={item.id}>
                            <Grid item xs={18}>
                                <Typography variant="body2" fontWeight="bold">
                                    {t('Email')} {i + 1}
                                </Typography>
                            </Grid>
                            <Grid item xs={18} md={9}>
                                <ReactHookFormInput
                                    control={control}
                                    disabled={isDisabled}
                                    label={t('Email')}
                                    name={`emails.${i}.email`}
                                />
                            </Grid>
                            <Grid item xs={18} md={9}>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        gap: '5px',
                                    }}
                                >
                                    <ReactHookFormSelect
                                        control={control}
                                        disabled={isDisabled}
                                        label={t('Type')}
                                        name={`emails.${i}.email_type`}
                                        options={[
                                            {
                                                disabled: true,
                                                label: t('Select'),
                                                value: '',
                                            },
                                            {
                                                label: t('Work'),
                                                value: 0,
                                            },
                                            {
                                                label: t('Personal'),
                                                value: 1,
                                            },
                                            {
                                                label: t('Other'),
                                                value: 2,
                                            },
                                        ]}
                                    />
                                    <IconButton
                                        onClick={() => remove(i)}
                                        sx={{ mb: 2 }}
                                    >
                                        <DeleteIcon fontSize="medium" />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Grid item xs={18}>
                <Button
                    color="secondary"
                    disabled={isDisabled}
                    endIcon={<AddIcon />}
                    onClick={() => append({ email: '', email_type: 0 })}
                    sx={{ width: { md: 'auto', xs: '100%' } }}
                    type="button"
                    variant="text"
                >
                    {t('Add email')}
                </Button>
            </Grid>
        </>
    );
};

ReduxFieldArrayEmails.propTypes = {
    control: PropTypes.object,
    isDisabled: PropTypes.bool,
};

export default ReduxFieldArrayEmails;
