import i18next from 'i18next';
import { isEmpty, isNull, toNumber } from 'lodash';

import { DAYS_IN_YEAR, GENERATION_SOURCES } from 'common/constants';
import { getDecimals } from 'common/utils/helpers';
import {
    buildCsv,
    normalizeCsvDataToHourlyArray,
} from 'common/utils/helpers/csv';

import { SOURCE_OPTIONS } from './constants';

export const getHasFile = (initialValues) => {
    return (
        parseInt(initialValues.source) !== 0 &&
        (!isNull(initialValues.urlFile) || !isEmpty(initialValues.urlFile))
    );
};

export const getSourceOptions = () => [
    { label: i18next.t('Select a source'), value: 0, disabled: true },
    ...SOURCE_OPTIONS,
];

export const getSunHoursValue = ({
    generation,
    pvSystemDataSource,
    sunHours,
    sunHoursSegments,
}) => {
    if (pvSystemDataSource === GENERATION_SOURCES.DESIGNER)
        return sunHoursSegments;

    const adjustedSunHours = sunHours * (1 + generation / 100) || 0;

    return parseFloat(adjustedSunHours.toFixed(getDecimals(adjustedSunHours)));
};

export const getKWhkWValue = ({
    averageIrradiation,
    generation,
    sunHoursSegments,
    pvSystemDataSource,
}) => {
    if (pvSystemDataSource === GENERATION_SOURCES.DESIGNER)
        return sunHoursSegments * DAYS_IN_YEAR;

    const kWhkWValue = averageIrradiation * (1 + generation / 100) || 0;

    return parseFloat(kWhkWValue.toFixed(getDecimals(kWhkWValue)));
};

export const getSunHoursWithFile = (anualSolarGeneration, systemSize) =>
    systemSize > 0
        ? (anualSolarGeneration / systemSize / DAYS_IN_YEAR).toFixed(2)
        : 0;

export const handleOnChangeBuild =
    (proposalId, save, setTimer, timer) => (values) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => save(proposalId, values), 1000));
    };

export const handleOnChangeValue = ({
    averageIrradiation,
    base,
    setValue,
    field,
    value,
    sunHours,
    handleSubmit,
}) => {
    if (field === 'sunHoursValue' || field === 'kwhValue') {
        const percentage = ((toNumber(value) - base) / base) * 100;
        setValue('generation', percentage);
    }

    if (field === 'generation') {
        const sunHoursValue = parseFloat(sunHours * (1 + value / 100));
        const kwhValue = parseFloat(averageIrradiation * (1 + value / 100));
        setValue(
            'sunHoursValue',
            sunHoursValue.toFixed(getDecimals(sunHoursValue)),
        );
        setValue('kwhValue', kwhValue.toFixed(getDecimals(kwhValue)));
    }
    handleSubmit();
};

export const buildGenerationCsv = (data, config) => {
    const { resultData } = normalizeCsvDataToHourlyArray({
        columnsToFill: [{ name: 'generation', resolution: 'sum' }],
        rowsData: data,
        columnsFormat: config?.columnsFormat,
    });

    const csv = buildCsv(resultData, 'generation.csv');

    return csv;
};

// CSV Importer
export const validateCsvUploadColumn = (value) => {
    const parsedValue = Number(value);

    if ((!parsedValue && parsedValue !== 0) || parsedValue < 0)
        return {
            success: false,
            message: i18next.t('Minimum valid value is 0'),
        };

    return { success: true };
};
