import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import KeyIndicator from 'common/components/KeyIndicator';

import { handleOnChangeTermOrHitch, valueToPercentage } from '../../helpers';

import AllianceItem from './AllianceItem';
import HitchField from './HitchField';

const FormStep2 = ({
    alliance,
    control,
    currencyIso,
    currencySymbol,
    formValues,
    isLocked,
    parameters,
    productSelected,
    setValue,
    show,
}) => {
    const { t } = useTranslation();

    const isSerfimex = formValues?.is_serfimex;

    if (!show) return false;

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={9} md={4} lg={3}>
                    <AllianceItem alliance={alliance} />
                </Grid>
            </Grid>
            {!isSerfimex && (
                <Grid container justifyContent="center" mt={2}>
                    <Grid item xs={18} md={6} lg={4}>
                        <KeyIndicator
                            title={t('Interest rate')}
                            unit="%"
                            value={formValues.rate}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={6} lg={4}>
                        <KeyIndicator
                            title={t('Opening commission')}
                            unit="%"
                            value={valueToPercentage(
                                formValues.is_commission_percentage,
                                formValues.commission_credit,
                                formValues?.total_with_dealer_fee,
                            )}
                            widthIcon="20px"
                        />
                    </Grid>
                    {formValues.residual > 0 && (
                        <Grid item xs={18} md={6} lg={4}>
                            <KeyIndicator
                                title={t('Residual')}
                                unit="%"
                                value={formValues.residual}
                                widthIcon="20px"
                            />
                        </Grid>
                    )}
                </Grid>
            )}
            <Grid container mt={2}>
                <Grid item xs>
                    <Typography variant="body2" fontWeight="bold">
                        {productSelected.name}
                    </Typography>

                    <Typography variant="caption">
                        {productSelected.description}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container mt={1}>
                <Grid hidden={isSerfimex} item xs={18} lg={9}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isLocked}
                        label={t('Term')}
                        name="term"
                        onChange={() => {
                            handleOnChangeTermOrHitch({
                                parameters,
                                setValue,
                                term: formValues.term,
                                hitch: formValues.hitch_percentage,
                            });
                        }}
                        options={[
                            {
                                label: t('Select a term'),
                                value: '',
                                disabled: true,
                            },
                            ...formValues.termsToSelect,
                        ]}
                        sx={{ mb: '0 !important' }}
                    />
                </Grid>
                <Grid item xs={18} lg={isSerfimex ? 18 : 9}>
                    <HitchField
                        control={control}
                        currencyIso={currencyIso}
                        currencySymbol={currencySymbol}
                        formValues={formValues}
                        isLocked={isLocked}
                        isSerfimex={isSerfimex}
                        parameters={parameters}
                        setValue={setValue}
                    />
                </Grid>
            </Grid>
        </>
    );
};

FormStep2.propTypes = {
    alliance: PropTypes.object,
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    formValues: PropTypes.object,
    isLocked: PropTypes.bool,
    parameters: PropTypes.object,
    productSelected: PropTypes.object,
    setValue: PropTypes.func,
    show: PropTypes.bool,
};

export default FormStep2;
