import { array, number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { getMaxValueText, INVALID_TEXT, REQUIRED_TEXT } =
        getValidationTexts();

    return object().shape({
        branch_offices: string().required(REQUIRED_TEXT).nullable(),
        brand: string().required(REQUIRED_TEXT).nullable(),
        code: string().required(REQUIRED_TEXT).nullable(),
        currency: string().required(REQUIRED_TEXT).nullable(),
        range_prices: array().of(
            object().shape({
                cost: number().typeError(REQUIRED_TEXT).required(REQUIRED_TEXT),
                final_cost: number()
                    .typeError(REQUIRED_TEXT)
                    .required(REQUIRED_TEXT),
                margin: number()
                    .max(99.99, getMaxValueText(99.99))
                    .typeError(REQUIRED_TEXT)
                    .required(REQUIRED_TEXT),
                price_from: number().typeError(REQUIRED_TEXT).nullable(),
                price_to: number()
                    .typeError(REQUIRED_TEXT)
                    .test('price_from', INVALID_TEXT, function (value) {
                        const { price_from } = this.parent;
                        if (!price_from && price_from !== 0) return true;
                        return value >= price_from;
                    })
                    .nullable(),
            }),
        ),
        name: string().required(REQUIRED_TEXT).nullable(),
        product_type: string().required(REQUIRED_TEXT).nullable(),
    });
};
