import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    INITIALIZE,
    INITIALIZE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchCustomTemplate: {
        isFetching: true,
        data: null,
        errors: [],
    },
    initializing: true,
};

const templateRenderSlice = createSlice({
    initialState: INITIAL_STATE,
    name: NAME,
    reducers: {
        [FETCH_CUSTOM_TEMPLATE]: (state) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: [],
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_FAILURE]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_SUCCESS]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE]: (state) => {
            state.initializing = true;
        },
        [INITIALIZE_SUCCESS]: (state) => {
            state.initializing = false;
        },
    },
});

export const templateRenderActions = templateRenderSlice.actions;

export default templateRenderSlice.reducer;
