import { saveRateConcepts } from 'common/api/v2/rateConcepts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { STAGGERED_RATE_TYPE } from '../../rates/constants';
import { parsePeriods } from '../../rates/helpers';
import {
    SAVE_RATE_CONCEPTS,
    SAVE_RATE_CONCEPTS_FAILURE,
    SAVE_RATE_CONCEPTS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (commercialOfferId, data, callback) => (dispatch) => {
    const { certified, periods, payment_type, type } = data || {};

    if (!commercialOfferId || !type) return;

    dispatch(actions[SAVE_RATE_CONCEPTS]());

    const newRateData = {
        other_concepts: parsePeriods({
            isAnnualType: true,
            periods: periods,
            type: 'other_concepts',
        }),
        taxes_concepts: parsePeriods({
            isAnnualType: true,
            periods: periods,
            type: 'taxes_concepts',
        }),
    };

    if (!certified) {
        newRateData.contracted_demand_concepts = parsePeriods({
            isAnnualType: true,
            periods,
            type: 'contracted_demand_concepts',
        });
        newRateData.demand_concepts = parsePeriods({
            isAnnualType: true,
            periods,
            type: 'demand_concepts',
        });
        newRateData.energy_concepts = parsePeriods({
            isAnnualType: true,
            isTopValueEnabled: payment_type === STAGGERED_RATE_TYPE,
            periods,
            type: 'energy_concepts',
        });
        newRateData.excess_demand_concepts = parsePeriods({
            isAnnualType: true,
            periods,
            type: 'excess_demand_concepts',
        });
    }

    const newData = { [type]: newRateData };

    saveRateConcepts(commercialOfferId, newData)
        .then((response) => {
            const data = response?.data?.data;
            dispatch(eventsAfterUpdate(data, commercialOfferId));
            dispatch(actions[SAVE_RATE_CONCEPTS_SUCCESS](data));

            if (callback) callback();
        })
        .catch((error) => {
            dispatch(actions[SAVE_RATE_CONCEPTS_FAILURE](error));

            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
