import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActions, Button, Grid } from 'sunwise-ui';

import yupResolver from 'common/utils/yupResolver';

import validate from '../validate';

import AlertItem from './AlertItem';

const CardForm = ({ disabled, initialValues, rate, saveAlerts }) => {
    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    const { t } = useTranslation();

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnSubmit = (values) => saveAlerts(values, rate);

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            {initialValues?.alerts.map((alertItem, index) => (
                <AlertItem
                    alert_type={alertItem.alert_type}
                    control={control}
                    disabled={disabled}
                    index={index}
                    key={index}
                />
            ))}
            <BottomActions>
                <Grid container m={0}>
                    <Grid item xs={18} textAlign="right">
                        <Button type="submit">{t('Save')}</Button>
                    </Grid>
                </Grid>
            </BottomActions>
        </form>
    );
};

CardForm.propTypes = {
    disabled: PropTypes.bool,
    initialValues: PropTypes.object,
    rate: PropTypes.object,
    saveAlerts: PropTypes.func,
};

export default CardForm;
