import React, { useState } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { COMPANY_BRANCH_NAME } from 'common/modules/multiBranches/constants';

const StyledListItemButton = styled(ListItemButton)`
    padding: 8px 16px;
`;
const StyledListItemText = styled(ListItemText)`
    & .MuiListItemText-primary {
        font-weight: bold;
    }
`;

const ProductBranchOfficesModal = ({
    branchOfficesDictionary,
    canModify,
    handleClickClose,
    isOpen,
    productSelected,
    submitChanges,
}) => {
    const { t } = useTranslation();
    const [productBranchesToSelect, setProductBranchesList] = useState([]);

    const onEnter = () => {
        const branchesSavedList =
            productSelected.branch_offices?.map((bo) =>
                bo.branch_id === null ? 'company' : bo.branch_id,
            ) || [];
        setProductBranchesList(
            Object.keys(branchOfficesDictionary)
                .filter((key) => key !== 'all')
                .map((key) => {
                    return {
                        id: key,
                        name: branchOfficesDictionary[key],
                        blocked: branchesSavedList.indexOf(key) >= 0,
                        selected: branchesSavedList.indexOf(key) >= 0,
                    };
                }),
        );
    };

    const handleSubmitChanges = () => {
        submitChanges(
            productBranchesToSelect
                .filter((b) => !b.blocked && b.selected)
                .map((b) => b.id),
            () => {
                let tempProductBranchesListDictionary =
                    productBranchesToSelect.map((branch) => {
                        if (!branch.blocked && branch.selected)
                            return { ...branch, blocked: true };
                        return branch;
                    });
                setProductBranchesList(tempProductBranchesListDictionary);
                handleClickClose();
            },
        );
    };

    const handleSaveTempChanges = (branchId = '') => {
        let tempProductBranchesListDictionary = productBranchesToSelect.map(
            (branch) => {
                if (branchId === branch.id)
                    return { ...branch, selected: !branch.selected };
                return branch;
            },
        );
        setProductBranchesList(tempProductBranchesListDictionary);
    };

    const handleSelectAll = (selected) => {
        let tempProductBranchesListDictionary = productBranchesToSelect.map(
            (branch) => {
                if (branch.id !== 'company' && !branch.blocked)
                    return { ...branch, selected: selected };
                return branch;
            },
        );
        setProductBranchesList(tempProductBranchesListDictionary);
    };

    const company = productBranchesToSelect.find(
        (branch) => branch.id === 'company',
    ) || { selected: false, blocked: false };

    const allBranchesSelected = !productBranchesToSelect.some(
        (branch) =>
            !branch.blocked && branch.id !== 'company' && !branch.selected,
    );

    const getIsChecked = (item) => item.selected && item.blocked;

    const getIsTempChecked = (item) => item.selected && !item.blocked;

    if (productSelected === null) return null;

    return (
        <Dialog
            keyboard
            onEnter={onEnter}
            onClose={handleClickClose}
            open={isOpen}
            size="sm"
        >
            <List
                dense
                sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                }}
            >
                <ListItem
                    disabled={!canModify || company.blocked}
                    disablePadding
                    key="global"
                    secondaryAction={
                        <Checkbox
                            className="__userguiding_add_producto_global_radio_button"
                            checked={
                                getIsChecked(company) ||
                                getIsTempChecked(company)
                            }
                            disabled={!canModify || company.blocked}
                            edge="end"
                            icon={
                                <RadioButtonUncheckedIcon
                                    sx={{ color: '#d3d7eb' }}
                                />
                            }
                            checkedIcon={
                                <CheckCircleIcon
                                    sx={{
                                        color: getIsTempChecked(company)
                                            ? '#f1c417'
                                            : '#16f2ab',
                                    }}
                                />
                            }
                            onChange={() => handleSaveTempChanges('company')}
                        />
                    }
                >
                    <StyledListItemButton>
                        <StyledListItemText
                            id="global-item"
                            primary={COMPANY_BRANCH_NAME}
                        />
                    </StyledListItemButton>
                </ListItem>

                <ListItem
                    disablePadding
                    key="all-branches"
                    secondaryAction={
                        <Checkbox
                            checked={allBranchesSelected}
                            edge="end"
                            icon={
                                <CheckBoxOutlineBlankIcon
                                    sx={{ color: '#d3d7eb' }}
                                />
                            }
                            checkedIcon={
                                <CheckBoxIcon
                                    sx={{
                                        color: '#16f2ab',
                                    }}
                                />
                            }
                            onChange={() =>
                                handleSelectAll(!allBranchesSelected)
                            }
                        />
                    }
                >
                    <StyledListItemButton>
                        <StyledListItemText
                            id="all-branches-item"
                            primary={t('Branch office', { count: 2 })}
                        />
                    </StyledListItemButton>
                </ListItem>

                {productBranchesToSelect.map((branch) => {
                    if (branch.id === 'company') return null;
                    return (
                        <ListItem
                            disabled={branch.blocked}
                            disablePadding
                            key={`branch-office-${branch.id}`}
                            secondaryAction={
                                <Checkbox
                                    checked={
                                        getIsChecked(branch) ||
                                        getIsTempChecked(branch)
                                    }
                                    checkedIcon={
                                        <CheckCircleIcon
                                            sx={{
                                                color: getIsTempChecked(branch)
                                                    ? '#f1c417'
                                                    : '#16f2ab',
                                            }}
                                        />
                                    }
                                    disabled={branch.blocked}
                                    edge="end"
                                    icon={
                                        <RadioButtonUncheckedIcon
                                            sx={{ color: '#d3d7eb' }}
                                        />
                                    }
                                    id={`checkbox-${branch.id}`}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={() =>
                                        handleSaveTempChanges(branch.id)
                                    }
                                />
                            }
                        >
                            <StyledListItemButton>
                                <StyledListItemText
                                    id={`branch-office-item-${branch.id}`}
                                    primary={branch.name}
                                />
                            </StyledListItemButton>
                        </ListItem>
                    );
                })}
            </List>
            <Divider />
            <Grid container mt={1}>
                <Grid item xs={18} textAlign="right">
                    <Button
                        className="__userguiding_save_branch_product_button"
                        disabled={
                            !canModify ||
                            productBranchesToSelect.filter(
                                (b) => !b.blocked && b.selected,
                            ).length === 0
                        }
                        onClick={() => handleSubmitChanges()}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        type="button"
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

ProductBranchOfficesModal.propTypes = {
    branchOfficesDictionary: PropTypes.object,
    canModify: PropTypes.bool,
    handleClickClose: PropTypes.func,
    isOpen: PropTypes.bool,
    productSelected: PropTypes.object,
    submitChanges: PropTypes.func,
};

export default ProductBranchOfficesModal;
