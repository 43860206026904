import { createSlice } from '@reduxjs/toolkit';

import {
    INITIAL_VALUES,
    REFRES_FINANCING,
    REFRES_FINANCING_FAILURE,
    REFRES_FINANCING_SUCCESS,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_OFFER_END,
    SET_OFFER_END_FAILURE,
    SET_OFFER_END_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: {
        external_custom_template_id: null,
        proposal_plan: null,
    },
    offerEnd: {
        errors: [],
        isSaving: false,
    },
    refreshFinancing: {
        errors: [],
        isFetching: false,
    },
    save: {
        data: [],
        errors: [],
        isSaving: false,
    },
};

const proposalGeneratorTemplateSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [REFRES_FINANCING]: (state) => {
            state.refreshFinancing.isFetching = true;
        },
        [REFRES_FINANCING_FAILURE]: (state, action) => {
            state.refreshFinancing = {
                ...state.refreshFinancing,
                errors: action.payload,
                isFetching: false,
            };
        },
        [REFRES_FINANCING_SUCCESS]: (state) => {
            state.refreshFinancing.isFetching = false;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_OFFER_END]: (state) => {
            state.offerEnd.isSaving = true;
        },
        [SET_OFFER_END_FAILURE]: (state, action) => {
            state.offerEnd = {
                ...state.offerEnd,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SET_OFFER_END_SUCCESS]: (state) => {
            state.offerEnd.isSaving = false;
        },
    },
});

export const proposalGeneratorTemplateActions =
    proposalGeneratorTemplateSlice.actions;

export default proposalGeneratorTemplateSlice.reducer;
