import React from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

import * as selectors from '../selectors';

import MainBranchOfficeSelectorRHF from './MainBranchOfficeSelectorRHF';
import ProductBranchOfficesInputRHF from './ProductBranchOfficesInputRHF';

const BranchOfficeSelectTypeRHF = ({
    branchOfficesDictionary,
    branchSelected,
    control,
    disabled,
    label,
    multiMode,
    name,
    value,
}) => {
    if (!getIsMultibranchesAccount()) return null;
    if (multiMode)
        return (
            <ProductBranchOfficesInputRHF
                branchOfficesDictionary={branchOfficesDictionary}
                control={control}
                disabled={disabled}
                label={label}
                name={name}
                value={value}
            />
        );
    return (
        <MainBranchOfficeSelectorRHF
            control={control}
            disabled={disabled || branchSelected !== 'all'}
            label={label}
            name={name}
        />
    );
};

BranchOfficeSelectTypeRHF.defaultProps = {
    label: i18next.t('Branch office'),
    name: 'branch_offices',
    value: '',
};

const mapStateToProps = createStructuredSelector({
    branchOfficesDictionary: selectors.getBranchesDictionary,
    branchSelected: selectors.getBranchSelected,
});

BranchOfficeSelectTypeRHF.propTypes = {
    branchOfficesDictionary: PropTypes.object,
    branchSelected: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    multiMode: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
};

export default connect(mapStateToProps, null)(BranchOfficeSelectTypeRHF);
