import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { STATUS } from '../constants';

const StatusBadge = ({ status }) => {
    const { t } = useTranslation();

    return (
        <Box alignItems="center" display="flex" gap={1}>
            <Box
                bgcolor={STATUS[status]?.color}
                borderRadius={5}
                height={10}
                width={10}
            />
            <Typography variant="body2" fontWeight="bold">
                {t(STATUS[status]?.label || '')}
            </Typography>
        </Box>
    );
};

StatusBadge.propTypes = {
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default StatusBadge;
