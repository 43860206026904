import { getTemplates } from 'common/api/v1/templates';

import {
    FETCH_CUSTOM_OFFER_TEMPLATE,
    FETCH_CUSTOM_OFFER_TEMPLATE_FAILURE,
    FETCH_CUSTOM_OFFER_TEMPLATE_SUCCESS,
} from '../actionTypes';
import { integrationWebActions } from '../reducer';

export default (branchOffice) => (dispatch) => {
    if (!branchOffice || branchOffice === 'company') branchOffice = '';

    dispatch(integrationWebActions[FETCH_CUSTOM_OFFER_TEMPLATE]());

    getTemplates({
        branch_office_id: branchOffice,
        is_global: true,
        orderBy: 'title',
        perPage: 100,
        sortBy: 'asc',
        status: 'active',
        type: 0,
    })
        .then((response) => {
            const data = response.data.results.map((template) => ({
                ...template,
                id: template.external_uuid,
            }));
            dispatch(
                integrationWebActions[FETCH_CUSTOM_OFFER_TEMPLATE_SUCCESS](
                    data,
                ),
            );
        })
        .catch((error) =>
            dispatch(
                integrationWebActions[FETCH_CUSTOM_OFFER_TEMPLATE_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
