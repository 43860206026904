import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import {
    ReactHookFormColorPicker,
    ReactHookFormInput,
} from 'common/components/form/bootstrap';
import { FormWrapper } from 'common/components/productForm';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const FormContainer = ({
    canModify,
    errors,
    initialValues,
    isNewRecord,
    isSaving,
    save,
    setDialogTitle,
    showColor = false,
    type,
}) => {
    const { control, handleSubmit, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    useEffect(() => {
        setValue('type', type);
    }, [isNewRecord, type]);

    useEffect(() => {
        setDialogTitle(
            `${isNewRecord ? t('Create') : t('Update')} ${t(
                'Option',
            ).toLowerCase()}`,
        );
    }, [isNewRecord]);

    const { t } = useTranslation();

    const handleClickSave = (values) => save(values, type);

    return (
        <form>
            <FormWrapper>
                <ReactHookFormInput
                    control={control}
                    label={t('Name')}
                    name="name"
                />

                {showColor ? (
                    <ReactHookFormColorPicker
                        control={control}
                        initialHexColor={initialValues.primary_color}
                        name="primary_color"
                        label={t('Primary color')}
                    />
                ) : null}
            </FormWrapper>

            <ShowErrors errors={errors} />

            <Grid container>
                <Grid item xs textAlign="right">
                    <Button
                        disabled={isSaving}
                        onClick={handleSubmit(handleClickSave)}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        variant="outlined"
                        visible={canModify}
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSaveItem,
    initialValues: selectors.getInitialValues,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, type) => dispatch(actions.save(values, type)),
});

FormContainer.propTypes = {
    canModify: PropTypes.bool,
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    save: PropTypes.func,
    setDialogTitle: PropTypes.func,
    showColor: PropTypes.bool,
    type: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.LIST_SETTINGS_PERMISSION),
)(FormContainer);
