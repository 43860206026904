import API from '../..';
const ENTITY = 'catalogs';

export const getDivisions = () => API.get(`/api/v1/${ENTITY}/0`);
export const getRateDivisions = (data) =>
    API.get(`/api/v1/${ENTITY}/0`, {
        params: { political_division: data?.political_division },
    });
export const getRegions = () => API.get(`/api/v1/${ENTITY}/1`);
export const getRegionsByPoliticalDivision = (data) =>
    API.get(`/api/v1/${ENTITY}/1`, {
        params: { political_division: data?.political_division },
    });
export const getSummerMonths = () => API.get(`/api/v1/${ENTITY}/2`);
