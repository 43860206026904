import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValuesCiBanco = createSelector(
    getModel,
    (model) => model.initialValuesCiBanco,
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getTermInitialValues = createSelector(
    getModel,
    (model) => model.termInitialValues,
);

// DELETE FINANCIAL PRODUCT

export const getDeleteFinancialProduct = createSelector(
    getModel,
    (model) => model.deleteFinancialProduct,
);

export const getIsDeletingFinancialProduct = createSelector(
    getDeleteFinancialProduct,
    (value) => value.isDeleting,
);

// FINANCIAL PRODUCTS PLAN

export const getFinancialProductsPlan = createSelector(
    getModel,
    (model) => model.financialProductsPlan,
);

export const getIsFetchingFinancialProductsPlan = createSelector(
    getFinancialProductsPlan,
    (value) => value.isFetching,
);

export const getFinancialProductsPlanData = createSelector(
    getFinancialProductsPlan,
    (value) => value.data || [],
);

export const getFinancialProductsPlanErrors = createSelector(
    getFinancialProductsPlan,
    (value) => value.errors,
);

// FINANCIER INSTALLERS

export const getFinancierInstallers = createSelector(
    getModel,
    (model) => model.financierInstallers,
);

export const getIsFetchingFinancierInstallers = createSelector(
    getFinancierInstallers,
    (value) => value.isFetching,
);

export const getFinancierInstallersData = createSelector(
    getFinancierInstallers,
    (value) => value.data || [],
);

export const getFinancierInstallersErrors = createSelector(
    getFinancierInstallers,
    (value) => value.errors,
);

/* fetch financiers */
export const getFinanciersModel = createSelector(
    getModel,
    (model) => model.financiers,
);

export const getFinanciersData = createSelector(
    getFinanciersModel,
    (model) => model?.data || [],
);

export const getFinanciersErrors = createSelector(
    getFinanciersModel,
    (model) => model.errors,
);

export const getFinanciersIsFetching = createSelector(
    getFinanciersModel,
    (model) => model.isFetching,
);

// ALIANCE PRODUCTS

export const getAllianceProducts = createSelector(
    getModel,
    (model) => model.allianceProducts,
);

export const getIsFetchingAllianceProducts = createSelector(
    getAllianceProducts,
    (value) => value.isFetching,
);

export const getAllianceProductsData = createSelector(
    getAllianceProducts,
    (value) => value.data || [],
);

export const getAllianceProductsErrors = createSelector(
    getAllianceProducts,
    (value) => value.errors,
);

/* Requirements */
export const getRequirementsModel = createSelector(
    getModel,
    (model) => model.requirements,
);

export const getRequirementsData = createSelector(
    getRequirementsModel,
    (model) => model.data || { content: '' },
);

export const getRequirementsErrors = createSelector(
    getRequirementsModel,
    (model) => model.errors,
);

export const getRequirementsIsFetching = createSelector(
    getRequirementsModel,
    (model) => model.isFetching,
);

export const getProductEnableUpdating = createSelector(
    getModel,
    (model) => model.productEnableUpdating,
);

// SAVE

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (value) => value.isSaving);

export const getSaveData = createSelector(getSave, (value) => value.data || []);

export const getSaveErrors = createSelector(getSave, (value) => value.errors);

// MODAL

export const getIsOpenAddAllianceModal = createSelector(
    getModel,
    (model) => model.isOpenAddAllianceModal,
);

export const getIsOpenAllianceModal = createSelector(
    getModel,
    (model) => model.isOpenAllianceModal,
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal,
);

/* save financiers-installer */
export const getSaveFinanciersInstallerModel = createSelector(
    getModel,
    (model) => model.saveFinanciersInstaller,
);

export const getSaveFinanciersInstallerData = createSelector(
    getSaveFinanciersInstallerModel,
    (model) => model.data,
);

export const getSaveFinanciersInstallerErrors = createSelector(
    getSaveFinanciersInstallerModel,
    (model) => model.errors,
);

export const getSaveFinanciersInstallerIsFetching = createSelector(
    getSaveFinanciersInstallerModel,
    (model) => model.isFetching,
);
