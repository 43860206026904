import React, { useEffect, useRef, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

import { DEBOUNCE_TIME } from 'common/constants';

import { getTotalProjects } from '../helpers';

import FilterCard from './FilterCard';
import ProjectsList from './ProjectsList';

const ListItems = ({
    canDelete,
    canModify,
    fetchProjects,
    filterData,
    handleClickArchive,
    handleClickRename,
    handleOpenProject,
    isLoading,
    prepareFormCreate,
    projects,
    selectedProject,
    setFilterData,
}) => {
    const { t } = useTranslation();
    const projectsViewMode = localStorage.getItem('projectsViewMode');
    const [isExpanded, setIsExpanded] = useState(true);
    const [viewMode, setViewMode] = useState(projectsViewMode || 'list');
    const [currentFilterData, setCurrentFilterData] = useState(filterData);
    const [showLoading, setShowLoading] = useState(isLoading);

    const selectedProjectId = selectedProject?.id;

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            setShowLoading(true);
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    useEffect(() => setShowLoading(isLoading), [isLoading]);

    const handleOnFilter = useRef(
        debounce((filter) => fetchProjects(filter), DEBOUNCE_TIME),
    ).current;

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnSelectStatus = (value) => {
        const filter = { ...filterData, page: 0, status: value };
        setFilterData(filter);
    };

    useEffect(() => {
        if (selectedProjectId) setIsExpanded(false);
        else setIsExpanded(true);
    }, [selectedProjectId]);

    const totalProjects = getTotalProjects(projects);
    const projectsIds = projects.map((project) => project.id);

    const callback = () => {
        fetchProjects(filterData, (data) => {
            const dataIds = data.map((item) => item.id);
            if (!dataIds.includes(selectedProjectId))
                handleOpenProject(dataIds[0]);
        });
    };

    const renderExtraButton = () => (
        <Box my={2} textAlign="center">
            <Button
                onClick={() => setIsExpanded((prev) => !prev)}
                endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
                variant="text"
                visible={totalProjects >= 1}
            >
                {isExpanded ? t('Less') : `${t('More')} (${totalProjects})`}
            </Button>
        </Box>
    );

    return (
        <>
            <FilterCard
                canModify={canModify}
                filterData={filterData}
                handleOnChangeSearch={handleOnChangeSearch}
                handleOnSelectStatus={handleOnSelectStatus}
                prepareFormCreate={prepareFormCreate}
                setViewMode={setViewMode}
                viewMode={viewMode}
            />
            <ProjectsList
                callback={callback}
                canDelete={canDelete}
                canModify={canModify}
                filterData={filterData}
                handleClickArchive={handleClickArchive}
                handleClickRename={handleClickRename}
                handleOpenProject={handleOpenProject}
                isExpanded={isExpanded}
                isLoading={showLoading}
                listModeEnabled={viewMode === 'list'}
                prepareFormCreate={prepareFormCreate}
                projects={projects}
                projectsIds={projectsIds}
                renderExtraButton={renderExtraButton}
                selectedProject={selectedProject}
                selectedProjectId={selectedProjectId}
            />
        </>
    );
};

ListItems.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    fetchProjects: PropTypes.func,
    filterData: PropTypes.object,
    handleClickArchive: PropTypes.func,
    handleClickRename: PropTypes.func,
    handleOpenProject: PropTypes.func,
    isLoading: PropTypes.bool,
    prepareFormCreate: PropTypes.func,
    projects: PropTypes.array,
    selectedProject: PropTypes.object,
    setFilterData: PropTypes.func,
};

export default ListItems;
