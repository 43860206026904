import React from 'react';

import PropTypes from 'prop-types';
import { Badge, Tab } from 'sunwise-ui';

const TabBadge = ({ hasErrors, label, value, ...props }) => (
    <Tab
        {...props}
        label={
            <Badge
                color="error"
                content=""
                invisible={!hasErrors}
                variant="dot"
            >
                {label}
            </Badge>
        }
        value={value}
    />
);

TabBadge.propTypes = {
    hasErrors: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    value: PropTypes.string,
};

export default TabBadge;
