import React, { useEffect } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { ReactHookFormInput } from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validateExternalSource from '../validateExternalSource';

import BottomActionsForm from './form/BottomActionsForm';
import SourceInfoFields from './form/SourceInfoFields';

const ExternalSourceForm = ({
    fetchExternalSources,
    handleOnClose,
    initialValues,
    isSaving,
    saveExternalSource,
    sunwiseProjectId,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateExternalSource),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnClickSubmit = (values) =>
        saveExternalSource({
            successCallback: () => {
                handleOnClose();
                fetchExternalSources(sunwiseProjectId);
            },
            sunwiseProjectId,
            values,
        });

    return (
        <form onSubmit={handleSubmit(handleOnClickSubmit)}>
            <Grid container mb={2}>
                <Grid item xs={18} sm={9}>
                    <ReactHookFormInput
                        control={control}
                        disabled={isSaving}
                        label={t('Name')}
                        name="name"
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} sm={9}>
                    <ReactHookFormInput
                        control={control}
                        disabled={isSaving}
                        label={t('Brand')}
                        name="brand"
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} sm={9}>
                    <ReactHookFormInput
                        control={control}
                        disabled={isSaving}
                        label={t('Type')}
                        name="type"
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} sm={9}>
                    <ReactHookFormInput
                        control={control}
                        disabled={isSaving}
                        label={t('Direct access link')}
                        name="url"
                        variant="standard"
                        prepend={<LinkIcon />}
                    />
                </Grid>

                <Grid item xs={18}>
                    <SourceInfoFields control={control} disabled={isSaving} />
                </Grid>
            </Grid>

            <BottomActionsForm
                cancelText={t('Cancel')}
                continueText={t('Save')}
                disabled={isSaving}
                onCancel={handleOnClose}
                type="submit"
            />
        </form>
    );
};

ExternalSourceForm.propTypes = {
    fetchExternalSources: PropTypes.func,
    handleOnClose: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    saveExternalSource: PropTypes.func,
    sunwiseProjectId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesExternalSource,
    isSaving: selectors.getIsSavingData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchExternalSources: (params) =>
        dispatch(actions.fetchExternalSources(params)),
    saveExternalSource: (params) =>
        dispatch(actions.saveExternalSource(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalSourceForm);
