import axios from 'axios';

import { handleInterceptorErrors } from 'common/utils/helpers/api';

const API = axios.create({
    baseURL: import.meta.env.VITE_S3_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

API.interceptors.response.use(
    (res) => res,
    (error) => handleInterceptorErrors({}, error, false),
);

export default API;
