import { NAME } from './constants';

export const DUPLICATE_OFFER = `${NAME}/DUPLICATE_OFFER`;
export const DUPLICATE_OFFER_FAILURE = `${NAME}/DUPLICATE_OFFER_FAILURE`;
export const DUPLICATE_OFFER_SUCCESS = `${NAME}/DUPLICATE_OFFER_SUCCESS`;

export const EDIT = `${NAME}/EDIT`;
export const EDIT_FAILURE = `${NAME}/EDIT_FAILURE`;
export const EDIT_SUCCESS = `${NAME}/EDIT_SUCCESS`;

export const FETCH_OFFER_CONSUMPTION_AND_GENERATION = `${NAME}/FETCH_OFFER_CONSUMPTION_AND_GENERATION`;
export const FETCH_OFFER_CONSUMPTION_AND_GENERATION_FAILURE = `${NAME}/FETCH_OFFER_CONSUMPTION_AND_GENERATION_FAILURE`;
export const FETCH_OFFER_CONSUMPTION_AND_GENERATION_SUCCESS = `${NAME}/FETCH_OFFER_CONSUMPTION_AND_GENERATION_SUCCESS`;

export const FETCH_OFFER_DETAILS = `${NAME}/OFFER_VALUE`;
export const FETCH_OFFER_DETAILS_FAILURE = `${NAME}/FETCH_OFFER_DETAILS_FAILURE`;
export const FETCH_OFFER_DETAILS_SUCCESS = `${NAME}/FETCH_OFFER_DETAILS_SUCCESS`;

export const FETCH_OFFER_PROFILES = `${NAME}/FETCH_OFFER_PROFILES`;
export const FETCH_OFFER_PROFILES_FAILURE = `${NAME}/FETCH_OFFER_PROFILES_FAILURE`;
export const FETCH_OFFER_PROFILES_SUCCESS = `${NAME}/FETCH_OFFER_PROFILES_SUCCESS`;

export const PREPERE_EDIT = `${NAME}/PREPERE_EDIT`;
export const PREPERE_EDIT_FAILURE = `${NAME}/PREPERE_EDIT_FAILURE`;
export const PREPERE_EDIT_SUCCESS = `${NAME}/PREPERE_EDIT_SUCCESS`;

export const RESET_VALUES = `${NAME}/RESET_VALUES`;

export const SET_IS_LOCKED = `${NAME}/SET_IS_LOCKED`;
export const SET_SOLAR_SIMULATION_DATA = `${NAME}/SET_SOLAR_SIMULATION_DATA`;
