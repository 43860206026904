import React from 'react';

import PropTypes from 'prop-types';

import Loading from './components/Loading';

const Container = ({ onCompleteUpload }) => {
    return <Loading onCompleteUpload={onCompleteUpload} />;
};

Container.propTypes = {
    onCompleteUpload: PropTypes.func,
};

export default Container;
