import i18next from 'i18next';
import { get } from 'lodash';

import { getDuplicatedContact } from 'common/api/v1/contacts';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import saveRow from './saveRow';

export default (values) => (dispatch) => {
    if (!values.id) return;

    const { email, previusEmail } = values;

    if (!email || email === previusEmail) {
        dispatch(saveRow(values));
        return;
    }

    getDuplicatedContact(email)
        .then((response) => {
            const contactData = get(response, 'data.data', false);
            const { contact_exist: contactExist } = contactData;

            if (!contactExist) dispatch(saveRow(values));
            else {
                const {
                    branch_office: { name: branchName },
                    multi_branch_office: isMultiBranch,
                } = contactData;

                let message = i18next.t(
                    'There is already a contact with this email',
                );

                if (isMultiBranch)
                    message = message.concat(
                        ` ${i18next.t('At the branch "{{branch}}"', {
                            branch: branchName,
                        })}`,
                    );

                const dataAlert = {
                    messages: [message],
                    confirmText: i18next.t('Accept'),
                    title: i18next.t('Error'),
                    type: alerts.ALERT_TYPE_ONLY_CONFIRM,
                    variant: 'warning',
                };

                dispatch(alerts.actions.show(dataAlert));
            }
        })
        .catch((error) => {
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
