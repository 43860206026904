import get from 'lodash/get';

import { getContactsWithPaginate } from 'common/api/v1/contacts';

import {
    FETCH_CONTACTS,
    FETCH_CONTACTS_FAILURE,
    FETCH_CONTACTS_SUCCESS,
} from '../actionTypes';
import { projectsActions } from '../reducer';

export default (page = 1) =>
    (dispatch) => {
        dispatch(projectsActions[FETCH_CONTACTS]());

        getContactsWithPaginate(page)
            .then((response) =>
                dispatch(
                    projectsActions[FETCH_CONTACTS_SUCCESS]({
                        contacts: response.data.data,
                        hasNextPage:
                            get(
                                response,
                                'data.pagination.links.next',
                                null,
                            ) !== null,
                    }),
                ),
            )
            .catch((error) =>
                dispatch(projectsActions[FETCH_CONTACTS_FAILURE](error)),
            );
    };
