import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model?.isOpenModal,
);

export const getTableSettings = createSelector(
    getModel,
    (model) => model.fetchTableSettings,
);

export const getIsFetchingTableSettings = createSelector(
    getTableSettings,
    (value) => value?.isFetching || false,
);

export const getTableSettingsData = createSelector(
    getTableSettings,
    (value) => value?.data || [],
);
