import {
    INITIALIZE,
    INITIALIZE_SUCCESS,
    INITIALIZE_FAILURE,
} from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

import fetchCustomTemplate from './fetchCustomTemplate';
import fetchOfferDatasheets from './fetchOfferDatasheets';
import fetchOfferSmartDocuments from './offerSmartDocuments/fetchOfferSmartDocuments';

export default (id, initTemplate, templateType) => (dispatch) => {
    const promisesList = [
        dispatch(fetchCustomTemplate(id, initTemplate, templateType)),
        dispatch(fetchOfferSmartDocuments(id)),
        dispatch(fetchOfferDatasheets(id)),
    ];
    dispatch(multiTemplateReviewActions[INITIALIZE]());

    Promise.all(promisesList)
        .then(() => dispatch(multiTemplateReviewActions[INITIALIZE_SUCCESS]()))
        .catch(() =>
            dispatch(multiTemplateReviewActions[INITIALIZE_FAILURE]()),
        );
};
