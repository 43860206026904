import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import { STATION_TYPES } from '../constants';

import DropdownActions from './DropdownActions';
import StatusBadge from './StatusBadge';

const Wrapper = styled(Box)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px;
`,
);

const sxGridItem = { display: 'flex', gap: 1, flexWrap: 'wrap' };

const PowerStationCard = ({
    canModify,
    credentialId,
    filterData,
    handleOpenAssignProjectModal,
    prepareUnassignProject,
    powerStation,
    projectsDictionary,
}) => {
    const { t } = useTranslation();

    const { city, id, project, state, station_name, type } = powerStation;
    const projectName = projectsDictionary[project]?.name;

    return (
        <Wrapper mb={1} p={2}>
            <Grid container alignItems="center">
                <Grid item sm={16} sx={sxGridItem} xs={15}>
                    <Typography noWrap fontWeight="bold" variant="body2">
                        {t('Name')}:
                    </Typography>

                    <Typography noWrap variant="body2">
                        {station_name || '--'}
                    </Typography>
                </Grid>

                <Grid item textAlign="right" xs={3} sm={2}>
                    <DropdownActions
                        canModify={canModify}
                        credentialId={credentialId}
                        filterData={filterData}
                        handleOpenAssignProjectModal={
                            handleOpenAssignProjectModal
                        }
                        prepareUnassignProject={prepareUnassignProject}
                        powerStation={powerStation}
                    />
                </Grid>

                <Grid item sm={10} sx={sxGridItem} xs={16}>
                    <Typography noWrap fontWeight="bold" variant="body2">
                        Id:
                    </Typography>

                    <Typography noWrap variant="body2">
                        {id || '--'}
                    </Typography>
                </Grid>

                <Grid
                    item
                    order={{ xs: 4, sm: 7 }}
                    sm={8}
                    sx={sxGridItem}
                    xs={9}
                >
                    <Typography fontWeight="bold" variant="body2">
                        {t('Type')}:
                    </Typography>

                    <Typography noWrap variant="body2">
                        {t(STATION_TYPES[type]) || '--'}
                    </Typography>
                </Grid>

                <Grid item sm={6} xs={9} order={{ xs: 5, sm: 4 }}>
                    <StatusBadge status={state} />
                </Grid>

                <Grid
                    item
                    order={{ xs: 6, sm: 5 }}
                    sm={10}
                    sx={sxGridItem}
                    xs={18}
                >
                    <Typography fontWeight="bold" variant="body2">
                        {t('City')}:
                    </Typography>

                    <Typography variant="body2">
                        {!city || city === -1 ? '--' : city}
                    </Typography>
                </Grid>

                <Grid item order={7} sx={sxGridItem} xs={18}>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Project', { count: 2 })}:
                    </Typography>

                    <Typography variant="body2">
                        {projectName || t('There are no projects')}
                    </Typography>
                </Grid>
            </Grid>
        </Wrapper>
    );
};

PowerStationCard.propTypes = {
    canModify: PropTypes.bool,
    credentialId: PropTypes.string,
    handleOpenAssignProjectModal: PropTypes.func,
    prepareUnassignProject: PropTypes.func,
    filterData: PropTypes.object,
    powerStation: PropTypes.object,
    projectsDictionary: PropTypes.object,
};

export default PowerStationCard;
