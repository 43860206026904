import { approveChangesOncredit } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

export default (creditItem, onSuccess) => (dispatch) => {
    approveChangesOncredit(creditItem.offerId)
        .then(() => {
            if (onSuccess) onSuccess();
        })
        .catch((error) => showReponseErrorsAsAlert(dispatch, error?.response));
};
