import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../../actions';
import { getModalTitle } from '../../helpers';
import * as selectors from '../../selectors';

import TemplateForm from './TemplateForm';

const TemplateModal = ({
    branchSelected,
    filterData,
    handleClickCloseModal,
    isOpenModalTemplate,
    languagesDictionary,
    resetForm,
    templateMode,
    templateType,
}) => (
    <Dialog
        onClose={handleClickCloseModal}
        onExited={() => resetForm()}
        open={isOpenModalTemplate}
        size="lg"
        title={getModalTitle(templateMode, templateType)}
    >
        <TemplateForm
            branchSelected={branchSelected}
            filterData={filterData}
            languagesDictionary={languagesDictionary}
        />
    </Dialog>
);

const mapStateToProps = createStructuredSelector({
    isOpenModalTemplate: selectors.getIsOpenModalTemplate,
    templateMode: selectors.getTemplateMode,
    templateType: selectors.getTemplateType,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCloseModal: () => dispatch(actions.toggleModalTemplate(false)),
    resetForm: () => dispatch(actions.resetForm()),
});

TemplateModal.propTypes = {
    branchSelected: PropTypes.string,
    filterData: PropTypes.object,
    handleClickCloseModal: PropTypes.func,
    isOpenModalTemplate: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    resetForm: PropTypes.func,
    templateMode: PropTypes.string,
    templateType: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateModal);
