import { NAME } from './constants';

export const CREATE = `${NAME}/CREATE`;
export const CREATE_FAILURE = `${NAME}/CREATE_FAILURE`;
export const CREATE_SUCCESS = `${NAME}/CREATE_SUCCESS`;

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const DELETE_ITEM = `${NAME}/DELETE_ITEM`;
export const DELETE_ITEM_FAILURE = `${NAME}/DELETE_ITEM_FAILURE`;
export const DELETE_ITEM_SUCCESS = `${NAME}/DELETE_ITEM_SUCCESS`;

export const DUPLICATE = `${NAME}/DUPLICATE`;
export const DUPLICATE_FAILURE = `${NAME}/DUPLICATE_FAILURE`;
export const DUPLICATE_SUCCESS = `${NAME}/DUPLICATE_SUCCESS`;

export const FETCH_CAROUSEL_TEMPLATES = `${NAME}/FETCH_CAROUSEL_TEMPLATES`;
export const FETCH_CAROUSEL_TEMPLATES_FAILURE = `${NAME}/FETCH_CAROUSEL_TEMPLATES_FAILURE`;
export const FETCH_CAROUSEL_TEMPLATES_SUCCESS = `${NAME}/FETCH_CAROUSEL_TEMPLATES_SUCCESS`;

export const FETCH_COMPANY_USERS = `${NAME}/FETCH_COMPANY_USERS`;
export const FETCH_COMPANY_USERS_FAILURE = `${NAME}/FETCH_COMPANY_USERS_FAILURE`;
export const FETCH_COMPANY_USERS_SUCCESS = `${NAME}/FETCH_COMPANY_USERS_SUCCESS`;

export const FETCH_COUNTRIES = `${NAME}/FETCH_COUNTRIES`;
export const FETCH_COUNTRIES_FAILURE = `${NAME}/FETCH_COUNTRIES_FAILURE`;
export const FETCH_COUNTRIES_SUCCESS = `${NAME}/FETCH_COUNTRIES_SUCCESS`;

export const FETCH_CUSTOM_BASE_TEMPLATES = `${NAME}/FETCH_CUSTOM_BASE_TEMPLATES`;
export const FETCH_CUSTOM_BASE_TEMPLATES_FAILURE = `${NAME}/FETCH_CUSTOM_BASE_TEMPLATES_FAILURE`;
export const FETCH_CUSTOM_BASE_TEMPLATES_SUCCESS = `${NAME}/FETCH_CUSTOM_BASE_TEMPLATES_SUCCESS`;

export const FETCH_TEMPLATES = `${NAME}/FETCH_TEMPLATES`;
export const FETCH_TEMPLATES_FAILURE = `${NAME}/FETCH_TEMPLATES_FAILURE`;
export const FETCH_TEMPLATES_SUCCESS = `${NAME}/FETCH_TEMPLATES_SUCCESS`;

export const FETCH_LANGUAGES = `${NAME}/FETCH_LANGUAGES`;
export const FETCH_LANGUAGES_FAILURE = `${NAME}/FETCH_LANGUAGES_FAILURE`;
export const FETCH_LANGUAGES_SUCCESS = `${NAME}/FETCH_LANGUAGES_SUCCESS`;

export const FETCH_TEMPLATE_CATEGORIES = `${NAME}/FETCH_TEMPLATE_CATEGORIES`;
export const FETCH_TEMPLATE_CATEGORIES_FAILURE = `${NAME}/FETCH_TEMPLATE_CATEGORIES_FAILURE`;
export const FETCH_TEMPLATE_CATEGORIES_SUCCESS = `${NAME}/FETCH_TEMPLATE_CATEGORIES_SUCCESS`;

export const INITIALIZE = `${NAME}/INITIALIZE`;
export const INITIALIZE_SUCCESS = `${NAME}/INITIALIZE_SUCCESS`;
export const INITIALIZE_FAILURE = `${NAME}/INITIALIZE_FAILURE`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const OPEN_MODAL_TEMPLATE = `${NAME}/OPEN_MODAL_TEMPLATE`;

export const RENAME_ITEM = `${NAME}/RENAME_ITEM`;
export const RENAME_ITEM_FAILURE = `${NAME}/RENAME_ITEM_FAILURE`;
export const RENAME_ITEM_SUCCESS = `${NAME}/RENAME_ITEM_SUCCESS`;

export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET = `${NAME}/RESET`;

export const SET_ONE_PROPOSAL_TYPE_QUERY = `${NAME}/SET_ONE_PROPOSAL_TYPE_QUERY`;
export const SET_MULTIPROPOSAL_TYPE_QUERY = `${NAME}/SET_MULTIPROPOSAL_TYPE_QUERY`;
export const SET_DOCUMENTS_TYPE_QUERY = `${NAME}/SET_DOCUMENTS_TYPE_QUERY`;

export const SET_WIZARD_STEP = `${NAME}/SET_WIZARD_STEP`;
export const SET_TEMPLATE_MODE = `${NAME}/SET_TEMPLATE_MODE`;

export const UPDATE_TEMPLATES_SUCCESS = `${NAME}/UPDATE_TEMPLATES_SUCCESS`;
