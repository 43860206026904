import { NAME } from './constants';

export const FETCH_BATTERIES = `${NAME}/FETCH_BATTERIES`;
export const FETCH_BATTERIES_FAILURE = `${NAME}/FETCH_BATTERIES_FAILURE`;
export const FETCH_BATTERIES_SUCCESS = `${NAME}/FETCH_BATTERIES_SUCCESS`;

export const GET_OFFER = `${NAME}/GET_OFFER`;
export const GET_OFFER_FAILURE = `${NAME}/GET_OFFER_FAILURE`;
export const GET_OFFER_SUCCESS = `${NAME}/GET_OFFER_SUCCESS`;

export const INITIAL_VALUES = `${NAME}/INITIAL_VALUES`;

export const RESET_DATA = `${NAME}/RESET_DATA`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SET_STORAGE_PLAN = `${NAME}/SET_STORAGE_PLAN`;
