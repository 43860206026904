import { ONE_TO_MANY } from '../constants';

import BaseModel from './BaseModel';
export default class CustomTemplate extends BaseModel {
    isLocked() {
        return this.ref.locked === 1;
    }
}

CustomTemplate.modelName = 'CustomTemplate';
CustomTemplate.prepareFields({
    branchOffices: 'branch_offices',
    content: 'content',
    createdAt: 'created_at',
    createdBy: 'created_by',
    id: 'id',
    imageThumbnail: 'image_thumbnail',
    isGlobal: 'is_global',
    locked: 'locked',
    order: 'order',
    pages: 'pages',
    proposalsNumber: 'proposals_number',
    languageKey: {
        key: 'language_key',
        relationType: ONE_TO_MANY,
        options: {
            to: 'Language',
            as: 'language',
            relatedName: 'customTemplates',
        },
    },
    languages: 'languages',
    title: 'title',
    type: 'type',
    url: 'url',
    version: 'version',
    templateCategoryId: {
        key: 'category_id',
        relationType: ONE_TO_MANY,
        options: {
            to: 'TemplateCategory',
            as: 'templateCategory',
            relatedName: 'customTemplates',
        },
    },
});
