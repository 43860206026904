import API from '../../zapAPI';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'rpus';

export const addRpu = (data) => API.post(`/api/v1/${ENTITY}/`, data, config);

export const getRpu = (rpuId) => API.get(`/api/v1/${ENTITY}/${rpuId}/complete`);

export const getRpus = ({ page, pageSize }) =>
    API.get(`/api/v1/${ENTITY}/`, { params: { page, page_size: pageSize } });

export const getRpusByServiceNumber = (serviceNumber) =>
    API.get(`/api/v1/${ENTITY}/by-service-number/${serviceNumber}`);
