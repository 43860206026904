import toNumber from 'lodash/toNumber';

export const handleOnChangeValue = ({
    annualKWh,
    base,
    dailyAverageKWh,
    field,
    setValue,
    submitForm,
    value,
}) => {
    if (field === 'annual_kWh' || field === 'daily_average_kWh') {
        const percentage = ((toNumber(value) - base) / base) * 100;

        setValue('consumption', percentage);
        setValue('consumption_percentage', percentage);
    }

    if (field === 'consumption' || field === 'consumption_percentage') {
        const newAnnualKWh = parseFloat(annualKWh * (1 + value / 100));
        const newDailyAverageKWh = parseFloat(
            dailyAverageKWh * (1 + value / 100),
        );
        const newConsumption = parseFloat(value);

        setValue('annual_kWh', newAnnualKWh);
        setValue('daily_average_kWh', newDailyAverageKWh);
        setValue('consumption', newConsumption);
        setValue('consumption_percentage', newConsumption);
    }

    submitForm();
};
