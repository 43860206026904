import { createSlice } from '@reduxjs/toolkit';

import orm, { modelPopulateByArray } from 'common/orm';

import {
    FETCH_CONTRACTED_DEMAND_UNITS,
    FETCH_CONTRACTED_DEMAND_UNITS_FAILURE,
    FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS,
    FETCH_COUNTRIES,
    FETCH_COUNTRIES_FAILURE,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_CURRENCIES,
    FETCH_CURRENCIES_FAILURE,
    FETCH_CURRENCIES_SUCCESS,
    FETCH_DIVISIONS,
    FETCH_DIVISIONS_FAILURE,
    FETCH_DIVISIONS_SUCCESS,
    FETCH_RATE,
    FETCH_RATE_FAILURE,
    FETCH_RATE_SUCCESS,
    FETCH_SCHEDULE_RATE_CONFIGURATION,
    FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE,
    FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS,
    FETCH_SPAIN_EXCESS_DEMAND_UNITS,
    FETCH_SPAIN_EXCESS_DEMAND_UNITS_FAILURE,
    FETCH_SPAIN_EXCESS_DEMAND_UNITS_SUCCESS,
    FETCH_SUPPLIER_UTILITIES,
    FETCH_SUPPLIER_UTILITIES_FAILURE,
    FETCH_SUPPLIER_UTILITIES_SUCCESS,
    FETCH_UNITS,
    FETCH_UNITS_FAILURE,
    FETCH_UNITS_SUCCESS,
    FILTER_RATES,
    FILTER_RATES_FAILURE,
    FILTER_RATES_NEW_PAGE,
    FILTER_RATES_NEW_PAGE_FAILURE,
    FILTER_RATES_NEW_PAGE_SUCCESS,
    FILTER_RATES_SUCCESS,
    INITIALIZE_CERTIFIED_RATE_MODAL_FORM,
    INITIALIZE_RATE_DETAIL_FORM,
    INITIALIZE_RATE_MODAL_FORM,
    INITIALIZE_SUPPLIER_UTILITY_FORM,
    RESET,
    RESET_CERTIFIED_RATE_MODAL_FORM,
    RESET_RATE_DETAIL_FORM,
    RESET_RATE_MODAL_FORM,
    RESET_SUPPLIER_UTILITY_FORM,
    SAVE_RATE,
    SAVE_RATE_FAILURE,
    SAVE_RATE_SUCCESS,
    SAVE_SUPPLIER_UTILITY,
    SAVE_SUPPLIER_UTILITY_FAILURE,
    SAVE_SUPPLIER_UTILITY_SUCCESS,
    SET_CERTIFIED_RATE_MODAL_IS_OPEN,
    SET_FILTER_QUERY,
    SET_HAS_ACTIVE_PROJECTS,
    SET_MODAL_IS_CREATE,
    SET_MODAL_IS_OPEN,
    SET_RATE_ENABLED,
    SET_RATE_ENABLED_FAILURE,
    SET_RATE_ENABLED_SUCCESS,
    SET_SUPPLIER_UTILITIES_MODAL_IS_OPEN,
    SET_SUPPLIER_UTILITY_FORM_IS_OPEN,
} from './actionTypes';
import { INIT_VALUES_DEFAULT, NAME } from './constants';

const INITIAL_STATE = {
    archiveRate: { errors: null, isArchiving: false },
    certifiedRateModalInitialValues: {
        annual_percentage_increase: '0',
        business_type: '',
        dap: '',
        default_compensation_scheme: 'NETMET_SS',
        energy_export_price: 0,
        hourly_distribution: {},
        id: '',
        name: '',
        other_concepts: [],
        political_division: '',
        rate_description: '',
        tax_rate: '',
        taxes_concepts: [],
    },
    certifiedRateModalIsOpen: false,
    entities: orm.getEmptyState(),
    fetchContractedDemandUnits: { data: null, errors: null, isFetching: false },
    fetchCountries: { errors: null, isFetching: false },
    fetchCurrencies: { errors: null, isFetching: false },
    fetchDivisions: { data: [], errors: null, isFetching: false },
    fetchRate: { errors: null, id: null, isFetching: false },
    fetchScheduleRateConfiguration: {
        data: null,
        errors: null,
        isFetching: false,
    },
    fetchSpainExcessDemandUnits: {
        data: null,
        errors: null,
        isFetching: false,
    },
    fetchSupplierUtilities: { errors: null, isFetching: false },
    fetchUnits: { errors: null, isFetching: false },
    filterRates: {
        errors: null,
        hasNextPage: false,
        isFetching: false,
        isFetchingNextPage: false,
        query: null,
    },
    hasActiveProjects: false,
    modalIsCreate: true,
    modalIsOpen: false,
    rateDetailInitialValues: INIT_VALUES_DEFAULT,
    rateModalInitialValues: INIT_VALUES_DEFAULT,
    saveCertifiedRate: { errors: null, isSaving: false },
    saveRate: { errors: null, isSaving: false },
    saveSupplierUtility: { errors: null, isSaving: false },
    setRateEnabled: { errors: null, isUpdating: false, rateId: null },
    supplierUtilitiesModalIsOpen: false,
    supplierUtilityFormIsOpen: false,
    supplierUtilityInitialValues: { country: '', name: '' },
};

const ratesSlice = createSlice({
    initialState: INITIAL_STATE,
    name: NAME,
    reducers: {
        [FETCH_CONTRACTED_DEMAND_UNITS]: (state) => {
            state.fetchContractedDemandUnits.errors = [];
            state.fetchContractedDemandUnits.isFetching = true;
        },
        [FETCH_CONTRACTED_DEMAND_UNITS_FAILURE]: (state, action) => {
            state.fetchContractedDemandUnits.errors = action.payload;
            state.fetchContractedDemandUnits.isFetching = false;
        },
        [FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS]: (state, action) => {
            state.fetchContractedDemandUnits.data = action.payload;
            state.fetchContractedDemandUnits.isFetching = false;
        },
        [FETCH_COUNTRIES]: (state) => {
            state.fetchCountries.errors = [];
            state.fetchCountries.isFetching = true;
        },
        [FETCH_COUNTRIES_FAILURE]: (state, action) => {
            state.fetchCountries.errors = action.payload;
            state.fetchCountries.isFetching = false;
        },
        [FETCH_COUNTRIES_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Country } = session;
            modelPopulateByArray(Country, [action.payload]);
            state.fetchCountries.isFetching = false;
            state.entities = session.state;
        },
        [FETCH_CURRENCIES]: (state) => {
            state.fetchCurrencies.errors = [];
            state.fetchCurrencies.isFetching = true;
        },
        [FETCH_CURRENCIES_FAILURE]: (state, action) => {
            state.fetchCurrencies.errors = action.payload;
            state.fetchCurrencies.isFetching = false;
        },
        [FETCH_CURRENCIES_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Currency } = session;

            modelPopulateByArray(Currency, action.payload, true);
            state.fetchCurrencies.isFetching = false;
            state.entities = session.state;
        },
        [FETCH_DIVISIONS]: (state) => {
            state.fetchDivisions.errors = [];
            state.fetchDivisions.isFetching = true;
        },
        [FETCH_DIVISIONS_FAILURE]: (state, action) => {
            state.fetchDivisions.errors = action.payload;
            state.fetchDivisions.isFetching = false;
        },
        [FETCH_DIVISIONS_SUCCESS]: (state, action) => {
            state.fetchDivisions.data = action.payload;
            state.fetchDivisions.isFetching = false;
        },
        [FETCH_RATE]: (state, action) => {
            state.fetchRate.errors = [];
            state.fetchRate.id = action.payload;
            state.fetchRate.isFetching = true;
        },
        [FETCH_RATE_FAILURE]: (state, action) => {
            state.fetchRate.errors = action.payload;
            state.fetchRate.id = null;
            state.fetchRate.isFetching = false;
        },
        [FETCH_RATE_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Rate } = session;

            const { id } = action?.payload || {};
            Rate.withId(id).modify(action.payload);

            state.fetchRate.id = id;
            state.fetchRate.isFetching = false;
            state.entities = session.state;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION]: (state) => {
            state.fetchScheduleRateConfiguration.errors = [];
            state.fetchScheduleRateConfiguration.isFetching = true;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE]: (state, action) => {
            state.fetchScheduleRateConfiguration.errors = action.payload;
            state.fetchScheduleRateConfiguration.isFetching = false;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS]: (state, action) => {
            state.fetchScheduleRateConfiguration.data = action.payload;
            state.fetchScheduleRateConfiguration.isFetching = false;
        },
        [FETCH_SPAIN_EXCESS_DEMAND_UNITS]: (state) => {
            state.fetchSpainExcessDemandUnits.errors = [];
            state.fetchSpainExcessDemandUnits.isFetching = true;
        },
        [FETCH_SPAIN_EXCESS_DEMAND_UNITS_FAILURE]: (state, action) => {
            state.fetchSpainExcessDemandUnits.errors = action.payload;
            state.fetchSpainExcessDemandUnits.isFetching = false;
        },
        [FETCH_SPAIN_EXCESS_DEMAND_UNITS_SUCCESS]: (state, action) => {
            state.fetchSpainExcessDemandUnits.data = action.payload;
            state.fetchSpainExcessDemandUnits.isFetching = false;
        },
        [FETCH_SUPPLIER_UTILITIES]: (state) => {
            state.fetchSupplierUtilities.errors = [];
            state.fetchSupplierUtilities.isFetching = true;
        },
        [FETCH_SUPPLIER_UTILITIES_FAILURE]: (state, action) => {
            state.fetchSupplierUtilities.errors = action.payload;
            state.fetchSupplierUtilities.isFetching = false;
        },
        [FETCH_SUPPLIER_UTILITIES_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { SupplierUtility } = session;
            modelPopulateByArray(SupplierUtility, action.payload, true);

            state.fetchSupplierUtilities.isFetching = false;
            state.entities = session.state;
        },
        [FETCH_UNITS]: (state) => {
            state.fetchUnits.errors = [];
            state.fetchUnits.isFetching = true;
        },
        [FETCH_UNITS_FAILURE]: (state, action) => {
            state.fetchUnits.errors = action.payload;
            state.fetchUnits.isFetching = false;
        },
        [FETCH_UNITS_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { UnitRate } = session;
            modelPopulateByArray(UnitRate, action.payload);

            state.fetchUnits.isFetching = false;
            state.entities = session.state;
        },
        [FILTER_RATES]: (state) => {
            state.filterRates.errors = [];
            state.filterRates.isFetching = true;
        },
        [FILTER_RATES_FAILURE]: (state, action) => {
            state.filterRates.errors = action.payload;
            state.filterRates.isFetching = false;
        },
        [FILTER_RATES_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Rate } = session;
            const { hasNextPage, rates } = action.payload;
            modelPopulateByArray(Rate, rates, true);

            state.filterRates.hasNextPage = hasNextPage;
            state.filterRates.isFetching = false;
            state.entities = session.state;
        },
        [FILTER_RATES_NEW_PAGE]: (state) => {
            state.filterRates.errors = [];
            state.filterRates.isFetchingNextPage = true;
        },
        [FILTER_RATES_NEW_PAGE_FAILURE]: (state, action) => {
            state.filterRates.errors = action.payload;
            state.filterRates.hasNextPage = false;
            state.filterRates.isFetchingNextPage = false;
        },
        [FILTER_RATES_NEW_PAGE_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Rate } = session;
            const { hasNextPage, rates } = action.payload;
            modelPopulateByArray(Rate, rates);

            state.filterRates.hasNextPage = hasNextPage;
            state.filterRates.isFetchingNextPage = false;
            state.entities = session.state;
        },
        [INITIALIZE_CERTIFIED_RATE_MODAL_FORM]: (state, action) => {
            state.certifiedRateModalInitialValues = {
                ...state.certifiedRateModalInitialValues,
                ...action.payload,
            };
        },
        [INITIALIZE_RATE_DETAIL_FORM]: (state, action) => {
            state.rateDetailInitialValues = {
                ...state.rateDetailInitialValues,
                ...action.payload,
            };
        },
        [INITIALIZE_RATE_MODAL_FORM]: (state, action) => {
            state.rateModalInitialValues = {
                ...state.rateModalInitialValues,
                ...action.payload,
            };
        },
        [INITIALIZE_SUPPLIER_UTILITY_FORM]: (state, action) => {
            state.supplierUtilityInitialValues = {
                ...state.supplierUtilityInitialValues,
                ...action.payload,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_CERTIFIED_RATE_MODAL_FORM]: (state) => {
            state.certifiedRateModalInitialValues =
                INITIAL_STATE.certifiedRateModalInitialValues;
        },
        [RESET_RATE_DETAIL_FORM]: (state) => {
            state.rateDetailInitialValues = INIT_VALUES_DEFAULT;
        },
        [RESET_RATE_MODAL_FORM]: (state) => {
            state.rateModalInitialValues = INIT_VALUES_DEFAULT;
        },
        [RESET_SUPPLIER_UTILITY_FORM]: (state) => {
            state.supplierUtilityInitialValues =
                INITIAL_STATE.supplierUtilityInitialValues;
        },
        [SAVE_RATE]: (state) => {
            state.saveRate.errors = [];
            state.saveRate.isSaving = true;
        },
        [SAVE_RATE_FAILURE]: (state, action) => {
            state.saveRate.errors = action.payload;
            state.saveRate.isSaving = false;
        },
        [SAVE_RATE_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Rate } = session;

            if (action.payload) {
                Rate.withId(action.payload.id).modify(action.payload);
                state.entities = session.state;
            }
            state.saveRate.isSaving = false;
        },
        [SAVE_SUPPLIER_UTILITY]: (state) => {
            state.saveSupplierUtility.errors = [];
            state.saveSupplierUtility.isSaving = true;
        },
        [SAVE_SUPPLIER_UTILITY_FAILURE]: (state, action) => {
            state.saveSupplierUtility.errors = action.payload;
            state.saveSupplierUtility.isSaving = false;
        },
        [SAVE_SUPPLIER_UTILITY_SUCCESS]: (state) => {
            state.saveSupplierUtility.isSaving = false;
        },
        [SET_CERTIFIED_RATE_MODAL_IS_OPEN]: (state, action) => {
            state.certifiedRateModalIsOpen = action.payload;
        },
        [SET_FILTER_QUERY]: (state, action) => {
            state.filterRates.query = action.payload;
        },
        [SET_HAS_ACTIVE_PROJECTS]: (state, action) => {
            state.hasActiveProjects = action.payload;
        },
        [SET_MODAL_IS_OPEN]: (state, action) => {
            state.modalIsOpen = action.payload;
        },
        [SET_MODAL_IS_CREATE]: (state, action) => {
            state.modalIsCreate = action.payload;
        },
        [SET_RATE_ENABLED]: (state) => {
            state.setRateEnabled.errors = [];
            state.setRateEnabled.isUpdating = true;
        },
        [SET_RATE_ENABLED_FAILURE]: (state, action) => {
            state.setRateEnabled.errors = action.payload;
            state.setRateEnabled.isUpdating = false;
        },
        [SET_RATE_ENABLED_SUCCESS]: (state, action) => {
            state.setRateEnabled.isUpdating = false;
            state.setRateEnabled.rateId = action.payload;
        },
        [SET_SUPPLIER_UTILITIES_MODAL_IS_OPEN]: (state, action) => {
            state.supplierUtilitiesModalIsOpen = action.payload;
        },
        [SET_SUPPLIER_UTILITY_FORM_IS_OPEN]: (state, action) => {
            state.supplierUtilityFormIsOpen = action.payload;
        },
    },
});

export const ratesActions = ratesSlice.actions;

export default ratesSlice.reducer;
