import { getProviders } from 'common/api/v1/integrationsSuppliers';

import {
    FETCH_PROVIDERS,
    FETCH_PROVIDERS_FAILURE,
    FETCH_PROVIDERS_SUCCESS,
} from '../actionTypes';
import { supplierIntegrationsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(supplierIntegrationsActions[FETCH_PROVIDERS]());

    getProviders()
        .then((response) =>
            dispatch(
                supplierIntegrationsActions[FETCH_PROVIDERS_SUCCESS](
                    response?.data?.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                supplierIntegrationsActions[FETCH_PROVIDERS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
