import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from 'sunwise-ui';

import { capitalize } from 'common/utils/helpers';

const BulkEditionMenu = ({
    callback,
    checkedNotifications,
    filterData,
    handleClickBulkItems,
    handleClickCancel,
    prefix,
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const count = checkedNotifications.length;
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const label = () => {
        return `${count} ${capitalize(prefix)} ${t('Selected', {
            count: count,
        }).toLowerCase()}`;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'start', md: 'center' },
                gap: 1,
            }}
        >
            <>
                <Button
                    color="secondary"
                    endIcon={<KeyboardArrowDownIcon />}
                    fullWidth
                    onClick={handleClick}
                    variant="outlined"
                >
                    <Typography
                        component="span"
                        fontWeight="bold"
                        noWrap
                        sx={{
                            overflow: 'hidden',
                            width: 'calc(100% - 16px)',
                            textAlign: 'left',
                            textOverflow: 'ellipsis',
                            textTransform: 'initial',
                        }}
                        variant="body2"
                    >
                        {label(checkedNotifications.length)}
                    </Typography>
                </Button>

                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                        onClick={() => {
                            handleClickBulkItems(
                                callback,
                                filterData,
                                checkedNotifications,
                                true,
                            );
                            handleClose();
                        }}
                    >
                        <ListItemText primary={t('Mark as read')} />
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClickBulkItems(
                                callback,
                                filterData,
                                checkedNotifications,
                                false,
                            );
                            handleClose();
                        }}
                    >
                        <ListItemText primary={t('Mark as unread')} />
                    </MenuItem>
                </Menu>
            </>
            <Button
                color="secondary"
                onClick={() => handleClickCancel()}
                variant="outlined"
            >
                {t('Cancel')}
            </Button>
        </Box>
    );
};

BulkEditionMenu.propTypes = {
    callback: PropTypes.func,
    checkedNotifications: PropTypes.array,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickCancel: PropTypes.func,
    prefix: PropTypes.string,
};

export default BulkEditionMenu;
