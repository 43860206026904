import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import ReduxFieldArrayItems from './ReduxFieldArrayItems';

const FormContainer = ({
    canModify,
    currencyIso,
    currencyLocale,
    currencySymbol,
    handleNormalizeFields,
    handleSaveProductsSettings,
    initialValues,
    initializeProductsSettingsModal,
    isLoading,
    isLocked,
}) => {
    const { control, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
    });

    useEffect(() => reset(initialValues), [initialValues]);
    return (
        <ReduxFieldArrayItems
            canModify={canModify}
            control={control}
            currencyIso={currencyIso}
            currencyLocale={currencyLocale}
            currencySymbol={currencySymbol}
            formValues={watch()}
            handleNormalizeFields={handleNormalizeFields}
            handleSaveProductsSettings={handleSaveProductsSettings}
            initialValues={initialValues}
            initializeProductsSettingsModal={initializeProductsSettingsModal}
            isLoading={isLoading}
            isLocked={isLocked}
            name="items"
            setValue={setValue}
        />
    );
};

FormContainer.propTypes = {
    canModify: PropTypes.bool,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    handleNormalizeFields: PropTypes.func,
    handleSaveProductsSettings: PropTypes.func,
    initialValues: PropTypes.object,
    initializeProductsSettingsModal: PropTypes.func,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
};

export default FormContainer;
