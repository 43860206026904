import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ToggleButtonGroup, ToggleButton } from 'sunwise-ui';

const PeriodSelector = ({ handleOnChangePeriod, period }) => {
    const { t } = useTranslation();

    return (
        <ToggleButtonGroup
            color="secondary"
            exclusive
            fullWidth
            onChange={(e) => handleOnChangePeriod(e.target.value)}
            size="small"
            value={period}
        >
            <ToggleButton value="summer">{t('Summer')}</ToggleButton>
            <ToggleButton value="out_summer">{t('Out of summer')}</ToggleButton>
        </ToggleButtonGroup>
    );
};

PeriodSelector.propTypes = {
    handleOnChangePeriod: PropTypes.func,
    period: PropTypes.string,
};

export default PeriodSelector;
