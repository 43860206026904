import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, InputLabel, MenuItem, Select } from 'sunwise-ui';

import {
    FILTER_TYPE_CALL,
    FILTER_TYPE_MAIL,
    FILTER_TYPE_MESSAGE,
    FILTER_TYPE_OTHER,
    FILTER_TYPE_VISIT,
} from '../constants';

import PastTrackings from './PastTrackings';

const TrackingList = ({
    canDelete,
    canModify,
    handleClickChangeDate,
    handleClickDelete,
    handleClickFilter,
    handleClickUpdate,
    isLoading,
    session,
    trackings,
    users,
}) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState('ALL');
    const handleChange = (event) => {
        setSelected(event.target.value);
        handleClickFilter(event.target.value);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <FormControl fullWidth>
                    <InputLabel id="select-label">{t('Filter by')}</InputLabel>
                    <Select
                        labelId="select-label"
                        id="simple-select"
                        value={selected}
                        label="Age"
                        onChange={handleChange}
                        variant="standard"
                    >
                        <MenuItem value={'ALL'}>
                            {t('All text', { context: 'male', count: 2 })}
                        </MenuItem>
                        <MenuItem value={FILTER_TYPE_CALL}>
                            {t('Call')}
                        </MenuItem>
                        <MenuItem value={FILTER_TYPE_MAIL}>
                            {t('Mail')}
                        </MenuItem>
                        <MenuItem value={FILTER_TYPE_VISIT}>
                            {t('Visit')}
                        </MenuItem>
                        <MenuItem value={FILTER_TYPE_MESSAGE}>
                            {t('Message')}
                        </MenuItem>
                        <MenuItem value={FILTER_TYPE_OTHER}>
                            {t('Other')}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ py: 2 }}>
                <PastTrackings
                    canDelete={canDelete}
                    canModify={canModify}
                    handleClickChangeDate={handleClickChangeDate}
                    handleClickDelete={handleClickDelete}
                    handleClickUpdate={handleClickUpdate}
                    isLoading={isLoading}
                    isPast
                    session={session}
                    trackings={trackings}
                    users={users}
                />
            </Box>
        </>
    );
};

TrackingList.propTypes = {
    users: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleClickChangeDate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickFilter: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isLoading: PropTypes.bool,
    session: PropTypes.object,
    trackings: PropTypes.array,
};

export default TrackingList;
