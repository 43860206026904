import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';
import { numberFormat } from 'common/utils/helpers';
import { FlexRow } from 'common/utils/layout';

const TableContainer = styled('div')`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
`;

const TableTitle = styled(Typography)`
    background-color: #eef4fa;
    border-radius: 8px;
    color: #003b4b;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 8px;
    margin-left: auto;
    text-align: center;
    width: 66%;
`;

const NameCol = styled('div')`
    display: flex;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 31px;
`;

const ValueCol = styled('div')`
    background: rgba(238, 244, 250, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    flex: 1;
`;

const ValueColHeader = styled('div')`
    background-color: #eff1fb;
    border-radius: 8px 8px 0 0;
    color: #2e315d;
    font-size: 12px;
    font-weight: bold;
    height: 31px;
    letter-spacing: 0;
    line-height: 31px;
    text-align: center;
    width: 100%;
`;

const ValueItem = styled(Typography)`
    font-size: 12px;
    font-weight: ${({ isLast }) => (isLast ? 'bold' : '400')};
    height: 36px;
    letter-spacing: 0;
    line-height: 36px;
    padding: 0 12px;
    position: relative;
    text-align: center;
    width: 100%;
    &:before {
        background-color: #dee2e6;
        bottom: 0;
        content: '';
        display: ${({ isLast }) => (isLast ? 'none' : 'block')};
        height: 1px;
        left: 12px;
        position: absolute;
        width: calc(100% - 24px);
    }
`;

const NameItem = styled(Typography)`
    position: relative;
    height: 36px;
    width: 100%;
    padding: 0 12px;

    font-size: 12px;
    letter-spacing: 0;
    line-height: 36px;
    text-align: center;
    font-weight: ${({ isLast }) => (isLast ? 'bold' : '400')};
`;

const CardRangeDetails = ({
    countryCurrencyLocale,
    panelInverterRangeData,
}) => {
    const { t } = useTranslation();
    if (panelInverterRangeData === null) return null;
    return (
        <Card>
            <Card.Header>
                <TitleWithDetail fontSize="16">
                    {t('Panel range per inverter')}
                </TitleWithDetail>
            </Card.Header>

            <Card.Body>
                <TableContainer>
                    <FlexRow>
                        <TableTitle component="span">
                            {t('Number of panels')}
                        </TableTitle>
                    </FlexRow>
                    <Box display="flex" gap="16px">
                        <NameCol>
                            {panelInverterRangeData.mppts.map((row, index) => {
                                return (
                                    <NameItem
                                        component="span"
                                        key={`mppts-name-${index}`}
                                    >
                                        {row.name}
                                    </NameItem>
                                );
                            })}
                            <NameItem component="span" isLast>
                                {t('Total')}
                            </NameItem>
                        </NameCol>
                        <ValueCol>
                            <ValueColHeader>{t('Min')}</ValueColHeader>

                            {panelInverterRangeData.mppts.map((row, index) => {
                                return (
                                    <ValueItem
                                        component="span"
                                        key={`mppts-min-${index}`}
                                    >
                                        {numberFormat(row.min || 0, {
                                            decimals: 0,
                                            locale: countryCurrencyLocale,
                                            style: 'decimal',
                                        })}
                                    </ValueItem>
                                );
                            })}
                            <ValueItem component="span" isLast>
                                {' '}
                                {numberFormat(panelInverterRangeData.min || 0, {
                                    decimals: 0,
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                })}
                            </ValueItem>
                        </ValueCol>
                        <ValueCol>
                            <ValueColHeader>{t('Max')}</ValueColHeader>
                            {panelInverterRangeData.mppts.map((row, index) => {
                                return (
                                    <ValueItem
                                        component="span"
                                        key={`mppts-max-${index}`}
                                    >
                                        {numberFormat(row.max || 0, {
                                            decimals: 0,
                                            locale: countryCurrencyLocale,
                                            style: 'decimal',
                                        })}
                                    </ValueItem>
                                );
                            })}
                            <ValueItem component="span" isLast>
                                {numberFormat(panelInverterRangeData.max || 0, {
                                    decimals: 0,
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                })}
                            </ValueItem>
                        </ValueCol>
                    </Box>
                </TableContainer>
            </Card.Body>
        </Card>
    );
};

CardRangeDetails.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    panelInverterRangeData: PropTypes.object,
};

export default CardRangeDetails;
