import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import { List, ListItem, ListItemButton, ListItemText } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import SideMenuCard from './SideMenuCard';

const ReportCard = ({
    canModify,
    handleClickDocumentItem,
    handleClickEditButton,
    handleOnRegenerateTemplate,
    isDisabled,
    reportId,
    reportName,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <SideMenuCard
            icon={<ArticleIcon sx={{ color: 'primary.main' }} />}
            title={t('Report')}
        >
            <List disablePadding>
                <ListItem
                    disabled={isDisabled || reportId === selectedDocumentId}
                    disablePadding
                    key={`report-${reportId}`}
                    secondaryAction={
                        <DropdownCardMenu
                            disabled={isDisabled}
                            items={[
                                {
                                    disabled: !canModify,
                                    handleClick: () => handleClickEditButton(),
                                    label: t('Edit'),
                                },
                                {
                                    disabled: !canModify,
                                    handleClick: () =>
                                        handleOnRegenerateTemplate(),
                                    label: t('Regenerate'),
                                },
                            ]}
                            sxIconButton={{
                                '&:hover': { backgroundColor: 'transparent' },
                            }}
                            visible={reportId === selectedDocumentId}
                        />
                    }
                    selected={reportId === selectedDocumentId}
                    sx={{
                        borderRadius: '8px',
                        mb: '5px',
                        '&.Mui-selected': {
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? '#000'
                                    : 'rgba(31, 60, 83, 0.13)',
                        },
                    }}
                >
                    <ListItemButton
                        onClick={() =>
                            handleClickDocumentItem({
                                id: reportId,
                                name: reportName,
                                type: types.REPORT_TYPE,
                            })
                        }
                        sx={{ borderRadius: '8px' }}
                    >
                        <ListItemText
                            primary={reportName}
                            sx={{
                                '.MuiListItemText-primary': {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </SideMenuCard>
    );
};

ReportCard.propTypes = {
    canModify: PropTypes.bool,
    handleClickDocumentItem: PropTypes.func,
    handleClickEditButton: PropTypes.func,
    handleOnRegenerateTemplate: PropTypes.func,
    isDisabled: PropTypes.bool,
    reportId: PropTypes.string,
    reportName: PropTypes.string,
    selectedDocumentId: PropTypes.string,
};

export default ReportCard;
