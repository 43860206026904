import i18next from 'i18next';

import { deleteItem } from 'common/api/v1/branchOffices';
import alerts from 'common/modules/alerts';
import fetchMultiBranches from 'common/modules/multiBranches/actions/fetchBranches';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_ITEM,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_SUCCESS,
} from '../actionTypes';
import { branchOfficesActions } from '../reducer';

import filterItems from './filterItems';
import resetForm from './resetForm';
import toggleModalProduct from './toggleModalProduct';

export default (id) => (dispatch) => {
    dispatch(branchOfficesActions[DELETE_ITEM]());

    deleteItem(id)
        .then(() => {
            dispatch(branchOfficesActions[DELETE_ITEM_SUCCESS](null));
            dispatch(alerts.actions.close());
            dispatch(filterItems());
            dispatch(toggleModalProduct(false));
            dispatch(resetForm());

            showToast({
                body: i18next.t('It was successfully removed'),
            });
            dispatch(fetchMultiBranches());
        })
        .catch((error) => {
            dispatch(
                branchOfficesActions[DELETE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
