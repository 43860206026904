import React from 'react';

import { styled } from '@mui/material/styles';
import { Box, Typography } from 'sunwise-ui';

const Label = styled(Box)`
    cursor: default;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    overflow-wrap: anywhere;
`;

const PercentageFormatter = (cell) => (
    <Typography>
        <Label title={cell}>{cell || 0}%</Label>
    </Typography>
);

export default PercentageFormatter;
