import { getConsumptionEnergyCSV } from 'common/api/v1/contactProject';

import { DOWNLOAD_CSV, DOWNLOAD_CSV_FAILURE } from '../actionTypes';
import { projectConsumptionCardActions } from '../reducer';

export default (service_number, consumptionEnergyId) => (dispatch) => {
    const newValues = {
        customer_energy_consumtion: consumptionEnergyId,
    };

    dispatch(projectConsumptionCardActions[DOWNLOAD_CSV]());

    getConsumptionEnergyCSV(newValues)
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${service_number}.csv`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) =>
            dispatch(
                projectConsumptionCardActions[DOWNLOAD_CSV_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
