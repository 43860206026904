import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';
import { emailRegex } from 'common/utils/helpers';

export default () => {
    const { REQUIRED_TEXT, EMAIL_VALIDATION_TEXT } = getValidationTexts();
    return object().shape({
        email: string()
            .required(REQUIRED_TEXT)
            .matches(emailRegex, EMAIL_VALIDATION_TEXT)
            .nullable(),
    });
};
