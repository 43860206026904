export const NAME = 'alliances';
export const TYPE_PROPOSAL = 0;
export const COLUMNS_CONFIG = {
    name: {
        label: 'Name',
        is_active: true,
        required: true,
    },
    financier: {
        label: 'Ally',
        is_active: true,
        required: false,
    },
    type: {
        label: 'Type',
        is_active: true,
        required: false,
    },
    created_at: {
        label: 'Creation date',
        is_active: true,
        required: false,
    },
};
export const FINANCIAL_STATUS = {
    Solicitado: 0,
    Proceso: 1,
    Rechazado: 2,
    Aprobado: 3,
    Cerrado: 4,
    Cotizado: 5,
    Cancelado: 6,
    Preaprobado: 7,
    Incompleto: 8,
    'Aprobado condicionado': 7,
};
