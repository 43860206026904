import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

const WrapperStep = ({ children, isLoading }) => {
    if (isLoading)
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    return <Box sx={{ mb: '-16px!important' }}>{children}</Box>;
};
WrapperStep.defaultProps = {
    isLoading: false,
};

WrapperStep.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isLoading: PropTypes.bool,
};

export default WrapperStep;
