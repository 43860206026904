import { polygon } from '@turf/helpers';
import union from '@turf/union';
import isEmpty from 'lodash/isEmpty';

import { POLYGON_SEGMENT } from '../../../../modules/multiPanelLayout/constants';
import { getPolygonPointsWithSetback } from '../../../../modules/multiPanelLayout/solarGeometryhelpers';
import {
    SEGMENT_COLOR,
    SEGMENT_STROKE_COLOR,
    SEGMENT_STROKE_ACTIVE_COLOR,
} from '../constants';
import { addPathListeners, createMeterLabelPopup } from '../helpers';

import SolarModulePolygonBuilder from './SolarModulePolygonBuilder';

const getAzimuth = ({ polygon, from, to, google }) => {
    const heading = google.maps.geometry.spherical.computeHeading(from, to);
    const interpolated = google.maps.geometry.spherical.interpolate(
        from,
        to,
        0.5,
    );

    let newPosition = google.maps.geometry.spherical.computeOffset(
        interpolated,
        0.02,
        heading + 90,
    );

    if (google.maps.geometry.poly.containsLocation(newPosition, polygon)) {
        newPosition = google.maps.geometry.spherical.computeOffset(
            interpolated,
            0.02,
            heading - 90,
        );
    }

    let initAzimuth = google.maps.geometry.spherical.computeHeading(
        interpolated,
        newPosition,
    );

    if (initAzimuth < 0) initAzimuth += 360;

    return initAzimuth.toFixed(2);
};

const getPolygonPaths = (polygon) => {
    let _paths = [];
    const paths = polygon.getPath();
    if (paths === undefined) return _paths;
    paths.forEach(function (latlng) {
        _paths.push({ lat: latlng.lat(), lng: latlng.lng() });
    });
    return _paths;
};

const buildContextSegments = ({
    google,
    map,
    onContextMenu,
    segmentId,
    shapeCoords,
}) => {
    if (!Array.isArray(shapeCoords)) return;
    let segments = new google.maps.MVCArray();
    if (!onContextMenu) return null;
    const polygon = new google.maps.Polygon({ paths: shapeCoords });
    const paths = polygon.getPaths();
    const options = {
        map,
        strokeOpacity: 0,
        strokeWeight: 13,
        zIndex: 100,
    };
    let azimuths = [];

    paths.forEach((path) => {
        const coordinates = path.getArray();

        segments.push(
            new google.maps.Polyline({
                ...options,
                path: [coordinates[coordinates.length - 1], coordinates[0]],
            }),
        );

        azimuths.push(
            getAzimuth({
                polygon,
                from: coordinates[coordinates.length - 1],
                to: coordinates[0],
                google,
            }),
        );

        for (let i = 0; i < coordinates.length - 1; i++) {
            segments.push(
                new google.maps.Polyline({
                    ...options,
                    path: [coordinates[i], coordinates[i + 1]],
                }),
            );

            azimuths.push(
                getAzimuth({
                    polygon,
                    from: coordinates[i],
                    to: coordinates[i + 1],
                    google,
                }),
            );
        }
    });

    segments.forEach((segment, index) => {
        google.maps.event.addListener(segment, 'contextmenu', (e) => {
            onContextMenu({
                azimuth: azimuths[index],
                event: e.domEvent,
                segment,
                segmentId: segmentId,
            });
        });
    });

    return segments;
};

const getPartialShadows = ({ polygonPaths, shadowPaths }) => {
    let partialShadows = [];
    for (let i = 0; i < polygonPaths.length - 1; i++) {
        partialShadows.push([
            polygonPaths[i],
            shadowPaths[i],
            shadowPaths[i + 1],
            polygonPaths[i + 1],
        ]);
    }

    partialShadows.push([
        polygonPaths[polygonPaths.length - 1],
        shadowPaths[polygonPaths.length - 1],
        shadowPaths[0],
        polygonPaths[0],
    ]);

    return partialShadows;
};

const getUnionShadow = (partialShadows) => {
    let temp_coords = partialShadows[0].map((point) => [point.lng, point.lat]);
    temp_coords.push(temp_coords[0]);

    for (let i = 1; i < partialShadows.length; i++) {
        let polyunion = null;
        let shadowCoords = partialShadows[i].map((point) => [
            point.lng,
            point.lat,
        ]);
        shadowCoords.push(shadowCoords[0]);

        const poly1 = polygon([temp_coords]);
        const poly2 = polygon([shadowCoords]);

        try {
            polyunion = union(poly1, poly2);
        } catch (e) {
            polyunion = null;
        }

        if (!polyunion) continue;
        temp_coords = polyunion.geometry.coordinates[0];
    }

    return temp_coords;
};

const buildShader = ({ google, height, shapeCoords, sunPosition, zIndex }) => {
    if (
        !Array.isArray(shapeCoords) ||
        !height ||
        height === 0 ||
        typeof height === 'undefined' ||
        sunPosition.elevation <= 0
    )
        return null;
    const polygon = new google.maps.Polygon({ paths: shapeCoords });
    const polygonPaths = getPolygonPaths(polygon);
    const shadowLength =
        height / Math.tan((sunPosition.elevation * Math.PI) / 180);
    const shadowDirection = sunPosition.azimuth - 180;
    let shadowPaths = [];

    polygonPaths.forEach((path) => {
        const newPosition = google.maps.geometry.spherical.computeOffset(
            path,
            shadowLength,
            shadowDirection,
        );
        shadowPaths.push({ lat: newPosition.lat(), lng: newPosition.lng() });
    });

    const partialShadows = getPartialShadows({ polygonPaths, shadowPaths });
    const unionShadows = getUnionShadow(partialShadows);

    const mergedPolygonCoords = unionShadows.map((coord) => ({
        lat: coord[1],
        lng: coord[0],
    }));

    const mergedPolygon = new google.maps.Polygon({
        fillColor: '#000',
        fillOpacity: 0.5,
        paths: mergedPolygonCoords,
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: parseInt(zIndex) - 1,
    });
    return mergedPolygon;
};

export default (google, vertexChange, onContextMenu) => {
    const SolarModulePolygon = SolarModulePolygonBuilder(google);
    class SegmentPolygon extends google.maps.Polygon {
        onClickModule = null;
        constructor({
            height = 0,
            id,
            onClickModule = null,
            options = {},
            safeZone = 0,
            showMeterLabels,
            solarModules = [],
            sunPosition = {},
            type = POLYGON_SEGMENT,
            zIndex = 0,
        } = {}) {
            const newOptions = {
                draggable: options?.draggable || false,
                editable: options?.editable || false,
                fillColor: options.fillColor || SEGMENT_COLOR,
                fillOpacity: options.fillOpacity || 1,
                map: options.map,
                paths: options.paths,
                strokeColor: options.strokeColor || SEGMENT_STROKE_COLOR,
                strokeOpacity: 1,
                strokePosition: google.maps.StrokePosition.OUTSIDE,
                strokeWeight: 4,
                zIndex: parseFloat(height) + zIndex,
            };
            super(newOptions);

            this.contextSegments = null;
            this.disabled = false;
            this.height = parseFloat(height);
            this.id = id;
            this.isDragging = false;
            this.meterLabels = createMeterLabelPopup(
                options.paths,
                google,
                showMeterLabels ? this.map : null,
            );

            if (onClickModule) this.onClickModule = onClickModule;

            this.safeZone = safeZone;
            this.shaderPolygon = null;
            this.sunPosition = sunPosition;
            this.type = type;

            this.initContextMenu(onContextMenu);

            this.initEvents();

            this.initSetBackPolygon();

            this.initVertexChange(vertexChange);

            this.setShader();

            this.setSolarModules(solarModules);
        }

        getIsDragging() {
            return this.isDragging;
        }

        initContextMenu(onContextMenu) {
            if (this.type !== POLYGON_SEGMENT) return;
            this.contextSegments = buildContextSegments({
                google,
                map: this.map,
                onContextMenu,
                segmentId: this.id,
                shapeCoords: this.getPath().getArray(),
            });
        }

        initEvents() {
            google.maps.event.addListener(this, 'dragstart', () => {
                if (this.type === POLYGON_SEGMENT) this.setIsDragging(true);
            });
            google.maps.event.addListener(this, 'dragend', () => {
                this.setIsDragging(false);

                if (this.type === POLYGON_SEGMENT && vertexChange) {
                    vertexChange({
                        evt: 'drag',
                        polygon: this.getPath().getArray(),
                        segmentId: this.id,
                    });
                }
            });
        }

        initSetBackPolygon() {
            if (this.setBackPolygon) this.setBackPolygon.setMap(null);

            let tolerance = this.safeZone;

            if (this.type === POLYGON_SEGMENT) {
                tolerance *= -1;
            }

            const polygonPathsPlusTolerance = getPolygonPointsWithSetback({
                polygon: this,
                setback: tolerance,
            });

            this.setBackPolygon = new google.maps.Polygon({
                clickable: false,
                fillOpacity: 0.2,
                paths: polygonPathsPlusTolerance,
                strokeOpacity: 0,
                strokePosition: google.maps.StrokePosition.OUTSIDE,
                strokeWeight: 1,
                zIndex: parseFloat(this.height) + this.zIndex,
            });

            this.setBackPolygon.setMap(this.map);
        }

        initVertexChange(vertexChange) {
            if (!vertexChange) return;

            addPathListeners({
                getIsDragging: () => this.getIsDragging(),
                google,
                id: this.id,
                initSetBackPolygon: () => this.initSetBackPolygon(),
                map: this.map,
                meterLabels: this.meterLabels,
                paths: this.getPaths(),
                resetShader: () =>
                    this.setShader({
                        height: this.height,
                        paths: this.getPaths(),
                        shaderPolygon: this.shaderPolygon,
                        sunPosition: this.sunPosition,
                    }),
                setContextSegments: (path) => {
                    if (this.type === POLYGON_SEGMENT) {
                        this.setContextSegments(
                            buildContextSegments({
                                google,
                                map: this.map,
                                onContextMenu,
                                segmentId: this.id,
                                shapeCoords: path,
                            }),
                        );
                    }
                },
                setSolarModulesDisabled: () => this.setSolarModulesDisabled(),
                vertexChange,
            });
        }

        resetPosition(segmentValue = {}, showMeterLabels) {
            const { polygon = [], solar_modules: solarModules = [] } =
                segmentValue;
            const shapeCoords = polygon.map(
                (point) => new google.maps.LatLng(point[0], point[1]),
            );
            this.setPaths(shapeCoords);
            this.setSolarModules(solarModules);
            this.setLabelsVisibility(false);
            this.meterLabels = createMeterLabelPopup(
                shapeCoords,
                google,
                showMeterLabels ? this.map : null,
            );
            this.initVertexChange(vertexChange);
        }

        setAditionalOptions(options) {
            this.setOptions({ ...options });
        }

        setContextSegments(polylines) {
            if (this.contextSegments) {
                this.contextSegments.forEach((box) => box.setMap(null));
                this.contextSegments.clear();
            }
            this.contextSegments = polylines;
        }

        setDisabled(isDisabled, isEditable = false) {
            if (isDisabled) {
                this.setOptions({
                    clickable: true,
                    draggable: false,
                    editable: false,
                    fillOpacity: 0.6,
                    strokeOpacity: 0.6,
                });
            } else {
                this.setOptions({
                    clickable: true,
                    draggable: true,
                    editable: isEditable,
                    fillOpacity: 0.6,
                    strokeOpacity: 1,
                });
            }
        }

        setEnableModulesToEdit(isEnabled = true) {
            if (!Array.isArray(this.solarModules)) return;
            this.solarModules.forEach((solarModule) => {
                solarModule.setEnableModulesToEdit(isEnabled);
            });
        }

        setHeight(height, zIndex = 0) {
            this.height = parseFloat(height);
            this.zIndex = this.height + parseFloat(zIndex);
            this.setShader({ paths: this.getPaths() });
            if (Array.isArray(this.solarModules)) {
                this.solarModules.forEach((solarModule) => {
                    solarModule.setNewZIndex(this.zIndex + 1);
                });
            }
        }

        setIsDragging(isDragging) {
            this.isDragging = isDragging;
        }

        setLabelsVisibility(isVisible) {
            this.meterLabels.forEach((meterLabel) =>
                meterLabel.setMap(isVisible ? this.map : null),
            );
        }

        setMap(map) {
            if (map === null) {
                if (this.meterLabels) {
                    this.meterLabels.forEach((meterLabel) =>
                        meterLabel.setMap(null),
                    );
                }
                this.meterLabels = [];
                this.getPaths().forEach((path) => {
                    google.maps.event.clearListeners(path, 'remove_at');
                    google.maps.event.clearListeners(path, 'set_at');
                    google.maps.event.clearListeners(path, 'insert_at');
                });
                google.maps.event.clearListeners(this, 'dragstart');
                google.maps.event.clearListeners(this, 'dragend');
                if (Array.isArray(this.solarModules)) {
                    this.solarModules.forEach((solarModule) => {
                        solarModule.setMap(null);
                    });
                }
                this.solarModules = [];
                if (this.contextSegments) {
                    this.contextSegments.forEach((box) => box.setMap(null));
                    this.contextSegments.clear();
                }
                if (this.shaderPolygon) this.shaderPolygon.setMap(null);
                if (this.setBackPolygon) this.setBackPolygon.setMap(null);
            }
            super.setMap(map);
        }

        setSafeZone(safeZone) {
            this.safeZone = safeZone;
            this.initSetBackPolygon();
        }

        setSelected(isSelected, options = {}) {
            if (isSelected) {
                this.setOptions({
                    draggable: true,
                    editable: true,
                    strokeColor: SEGMENT_STROKE_ACTIVE_COLOR,
                });
                this.meterLabels.forEach((meterLabel) =>
                    meterLabel.setMap(this.map),
                );
            } else {
                this.setOptions({
                    draggable: false,
                    editable: false,
                    ...options,
                });
                this.meterLabels.forEach((meterLabel) =>
                    meterLabel.setMap(null),
                );
            }
        }

        setShader() {
            if (this.shaderPolygon) this.shaderPolygon.setMap(null);
            if (isEmpty(this.sunPosition)) return;
            this.shaderPolygon = buildShader({
                google,
                height: this.height,
                shapeCoords: this.getPaths().getArray(),
                sunPosition: this.sunPosition,
                zIndex: this.zIndex,
            });
            if (this.shaderPolygon) this.shaderPolygon.setMap(this.map);
        }

        setSolarModules(solarModules = []) {
            if (this.solarModules) {
                this.solarModules.forEach((solarModule) =>
                    solarModule.setMap(null),
                );
            }

            if (!Array.isArray(solarModules)) return;

            this.solarModules = solarModules.map((solarModule = {}) => {
                const {
                    cell,
                    col,
                    enabled: moduleEnable = true,
                    group,
                    id,
                    path = [],
                    row,
                } = solarModule;

                const paths = path.map((modulePoint) => ({
                    lat: modulePoint[0],
                    lng: modulePoint[1],
                }));

                return new SolarModulePolygon({
                    cell,
                    col,
                    group,
                    id,
                    map: this.map,
                    moduleEnable,
                    onClick: this.onClickModule,
                    parentId: this.id,
                    paths,
                    row,
                    zIndex: parseInt(this.zIndex) + 1,
                });
            });
        }

        setSolarModulesDisabled(isDisabled = true) {
            if (!Array.isArray(this.solarModules)) return;
            this.solarModules.forEach((solarModule) => {
                solarModule.setDisabled(isDisabled);
            });
        }

        setSolarModulesFillColor(fillColor) {
            this.solarModules.forEach((solarModule) => {
                solarModule.setOptions({ fillColor });
            });
        }

        setSolarModulesStrokeColor(strokeColor) {
            this.solarModules.forEach((solarModule) => {
                solarModule.setOptions({ strokeColor });
            });
        }

        setSunPosition(sunPosition) {
            this.sunPosition = sunPosition;
            this.setShader();
        }
    }

    return SegmentPolygon;
};
