import { array, number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { maximumNumberCharacters, REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        consumption: array().required(REQUIRED_TEXT).nullable(),
        usage_type: number().required(REQUIRED_TEXT).nullable(),
        name: string()
            .required(REQUIRED_TEXT)
            .max(20, maximumNumberCharacters(20))
            .nullable(),
    });
};
