import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, Tab, TabPanel, Tabs } from 'sunwise-ui';

import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import TabConditions from './TabConditions';
import TabGeneral from './TabGeneral';
import TabSupplier from './TabSupplier';
import TabSystem from './TabSystem';
import WarningEdition from './WarningEdition';

const FormContainer = ({
    canModify,
    canModifyEnergyBackup,
    errors,
    filterData,
    handleClickSave,
    initialize,
    initialValues,
    isSaving,
}) => {
    const { t } = useTranslation();
    const {
        clearErrors,
        control,
        getValues,
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    const [selectedTab, setSelectedTab] = useState('general');
    const [branchOffice] = watch(['branch_office']);
    const disabledForm = watch('disabled');
    const isDisabled = !canModify || disabledForm;

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (branchOffice) initialize(branchOffice);
    }, [branchOffice]);

    const handleChange = (_, newValue) => setSelectedTab(newValue);
    const handleOnSave = (values) => handleClickSave(filterData, values);

    return (
        <>
            {disabledForm && (
                <WarningEdition
                    handleClickEdit={() => setValue('disabled', false)}
                    show={canModify}
                />
            )}
            <form>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={handleChange}>
                        <Tab
                            id="tab-general"
                            key="tab-general"
                            label={t('General')}
                            value="general"
                        />
                        <Tab
                            id="tab-system"
                            key="tab-system"
                            label={t('System')}
                            value="system"
                        />
                        <Tab
                            id="tab-supplier"
                            key="tab-supplier"
                            label={t('Supplier')}
                            value="supplier"
                        />
                        <Tab
                            id="tab-conditions"
                            key="tab-conditions"
                            label={t('Terms and considerations')}
                            value="conditions"
                        />
                    </Tabs>
                </Box>

                <TabPanel
                    key="tab-general"
                    selectedTab={selectedTab}
                    value="general"
                >
                    <TabGeneral
                        canModifyEnergyBackup={canModifyEnergyBackup}
                        control={control}
                        getValues={getValues}
                        isDisabled={isDisabled}
                        setValue={setValue}
                        watch={watch}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-system"
                    selectedTab={selectedTab}
                    value="system"
                >
                    <TabSystem
                        clearErrors={clearErrors}
                        control={control}
                        isDisabled={isDisabled}
                        setValue={setValue}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-supplier"
                    selectedTab={selectedTab}
                    value="supplier"
                >
                    <TabSupplier control={control} isDisabled={isDisabled} />
                </TabPanel>

                <TabPanel
                    key="tab-conditions"
                    selectedTab={selectedTab}
                    value="conditions"
                >
                    <TabConditions control={control} isDisabled={isDisabled} />
                </TabPanel>

                <ShowErrors errors={errors} />

                <Grid container mt={1}>
                    <Grid item textAlign="right" xs={18}>
                        <Button
                            disabled={isSaving}
                            onClick={handleSubmit(handleOnSave)}
                            sx={{
                                '&.MuiButton-root': {
                                    mb: { xs: 'auto', md: 0 },
                                },
                                width: { md: 'auto', xs: '100%' },
                            }}
                            type="button"
                            variant="outlined"
                            visible={canModify && !disabledForm}
                        >
                            {t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    accessories: selectors.getFetchAccessoriesData,
    additionals: selectors.getFetchAdditionalsData,
    batteries: selectors.getFetchBatteriesData,
    errors: selectors.getSaveItemErrors,
    initialValues: selectors.getInitialValues,
    inverters: selectors.getFetchInvertersData,
    isFetchingPlan: selectors.getIsFetchingPlan,
    isInitializing: selectors.getIsInitializing,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
    panels: selectors.getFetchPanelsData,
    planData: selectors.getFetchPlanData,
    structures: selectors.getFetchStructuresData,
    templates: selectors.getFetchTemplatesData,
    workforce: selectors.getFetchWorkforceData,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickDelete: () => dispatch(actions.prepareDelete()),
    handleClickSave: (filterData, values) =>
        dispatch(actions.save(filterData, values)),
    initialize: (branchOffice) =>
        dispatch(actions.initialFetching(branchOffice)),
});

FormContainer.propTypes = {
    canModify: PropTypes.bool,
    canModifyEnergyBackup: PropTypes.bool,
    errors: PropTypes.array,
    filterData: PropTypes.object,
    handleClickSave: PropTypes.func,
    initialize: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
