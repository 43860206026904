import React from 'react';

import PropTypes from 'prop-types';

const LayoutPublicComponent = ({
    component: Component,
    layout: Layout,
    matchProps,
    rest,
}) => {
    return (
        <Layout isPublic {...rest}>
            <Component {...matchProps} />
        </Layout>
    );
};

LayoutPublicComponent.propTypes = {
    component: PropTypes.func,
    layout: PropTypes.func,
    matchProps: PropTypes.object,
    rest: PropTypes.object,
};

export default LayoutPublicComponent;
