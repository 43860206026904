import {
    getNewSolarModules,
    getPolygonCoordinates,
    getSolarModules,
} from 'common/utils/helpers';

export const getSegmentsFormatted = (parentFieldSegment) => {
    return parentFieldSegment.field_segments.map(
        ({
            field_segment_points,
            id,
            image,
            name,
            opacity,
            panel_modules,
            rotate,
            segment_color,
            segment_stroke_color,
            solar_modules,
            type,
            zindex,
        }) => {
            const polygon = getPolygonCoordinates(field_segment_points);
            const _solar_modules =
                solar_modules.length > 0 &&
                (!Array.isArray(panel_modules) || panel_modules.length === 0)
                    ? getSolarModules(solar_modules)
                    : getNewSolarModules(panel_modules);
            return {
                id,
                image,
                name,
                opacity,
                polygon,
                rotate,
                segment_color,
                segment_stroke_color,
                solar_modules: _solar_modules,
                type,
                zIndex: zindex,
            };
        },
    );
};

export const goToPanelLayout = (history, uid) => () => {
    localStorage.setItem('openedProposalModule', 'panelLayout');
    history.push({
        pathname: '/panel-layout/' + uid,
        state: { fromDashboard: true },
    });
};
