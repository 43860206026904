import React from 'react';

import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { Box } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';

const ReduxFieldArrayItems = ({ control, name, offersToSelect }) => {
    const { fields } = useFieldArray({ control, name });
    return fields.map((item, i) => (
        <Box key={`catalog-field-${i}`}>
            <ReactHookFormSelect
                classNameInputGroup="input-group-lg"
                control={control}
                label={item?.name}
                name={`${name}.${i}.commercial_offer`}
                options={offersToSelect}
            />
        </Box>
    ));
};

ReduxFieldArrayItems.propTypes = {
    control: PropTypes.object,
    error: PropTypes.object,
    name: PropTypes.string,
    offersToSelect: PropTypes.array,
};

export default ReduxFieldArrayItems;
