import { getReports } from 'common/api/v1/contactProject';

import {
    FETCH_REPORTS,
    FETCH_REPORTS_FAILURE,
    FETCH_REPORTS_SUCCESS,
} from '../actionTypes';
import { quickViewProjectsActions } from '../reducer';

export default ({ orderBy, page = 0, pageSize, projectId, sortBy } = {}) =>
    (dispatch) => {
        dispatch(quickViewProjectsActions[FETCH_REPORTS]());

        getReports({
            orderBy,
            page: page + 1,
            pageSize,
            projectId,
            sortBy,
        })
            .then((response) =>
                dispatch(
                    quickViewProjectsActions[FETCH_REPORTS_SUCCESS](
                        response.data,
                    ),
                ),
            )
            .catch((error) =>
                dispatch(
                    quickViewProjectsActions[FETCH_REPORTS_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            );
    };
