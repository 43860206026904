import API from 'common/api';
import { getItem } from 'common/api/v2/consumptionProfiles';
import { getDataProfileFormatted } from 'common/utils/helpersChart';

import {
    FETCH_CONSUMPTION_PROFILE,
    FETCH_CONSUMPTION_PROFILE_FAILURE,
    FETCH_CONSUMPTION_PROFILE_SUCCESS,
} from '../actionTypes';
import { consumptionProfilesActions } from '../reducer';

export default (profileId) => (dispatch) => {
    API.cancelAll();
    dispatch(consumptionProfilesActions[FETCH_CONSUMPTION_PROFILE](profileId));

    getItem(profileId)
        .then((response) => {
            const data = response?.data?.data || {};
            dispatch(
                consumptionProfilesActions[FETCH_CONSUMPTION_PROFILE_SUCCESS]({
                    ...data,
                    consumption_formatted: getDataProfileFormatted(
                        data.consumption,
                        data.year,
                    ),
                }),
            );
        })
        .catch((error) =>
            dispatch(
                consumptionProfilesActions[FETCH_CONSUMPTION_PROFILE_FAILURE](
                    error,
                ),
            ),
        );
};
