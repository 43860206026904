import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Alert, Box, Button, UiDialog as Dialog, Typography } from 'sunwise-ui';

import * as actions from '../actions';
import {
    getActivedColumns,
    handleOnDragEnd,
    handleOnSelectItemBuild,
} from '../helpers';

import SortableList from './SortableList';

const TableSettingsModal = ({
    columnsConfig,
    fetchTableSettings,
    handleClickCloseModal,
    isOpenModal,
    saveTableSettings,
    tableKey,
    tableSettings,
}) => {
    const { t } = useTranslation();
    const [columnList, setColumnList] = useState([]);

    useEffect(() => {
        if (!isEmpty(tableSettings)) setColumnList(tableSettings);
    }, [tableSettings]);

    const onDragEnd = (result) =>
        handleOnDragEnd(columnList, result, setColumnList);

    const handleOnSelectItem = (name, value) =>
        handleOnSelectItemBuild(columnList, name, setColumnList, value);

    const activedColumns = getActivedColumns(columnList);
    const callback = () => fetchTableSettings(tableKey, columnsConfig);

    return (
        <Dialog
            onClose={handleClickCloseModal}
            open={isOpenModal}
            size="md"
            title={t('Configure columns')}
        >
            <SortableList
                columnsConfig={columnsConfig}
                columns={columnList}
                handleOnSelectItem={handleOnSelectItem}
                onDragEnd={onDragEnd}
            />
            <Box hidden={activedColumns > 0} my={1}>
                <Alert severity="warning">
                    <Typography variant="caption" fontWeight="bold">
                        {t('At least one active column is required')}
                    </Typography>
                </Alert>
            </Box>
            <Box mt={2} textAlign="right">
                <Button
                    disabled={isEmpty(columnList) || activedColumns === 0}
                    onClick={() =>
                        saveTableSettings(callback, tableKey, columnList)
                    }
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                >
                    {t('Save changes')}
                </Button>
            </Box>
        </Dialog>
    );
};

TableSettingsModal.propTypes = {
    columnsConfig: PropTypes.object,
    fetchTableSettings: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    isOpenModal: PropTypes.bool,
    saveTableSettings: PropTypes.func,
    tableKey: PropTypes.string,
    tableSettings: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => ({
    saveTableSettings: (callback, tableKey, values) =>
        dispatch(actions.saveTableSettings(callback, tableKey, values)),
});

export default connect(null, mapDispatchToProps)(TableSettingsModal);
