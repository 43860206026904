import React, { useState } from 'react';

import PropTypes from 'prop-types';

const DetectScrollBottom = (props) => {
    const { Children, injectedProp } = props;
    const [showContent, setShowContent] = useState(false);

    window.onscroll = () => {
        setShowContent(false);
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight)
            setShowContent(true);
    };

    return (
        <Children {...injectedProp} displayOnlyBottom={showContent}></Children>
    );
};

DetectScrollBottom.propTypes = {
    Children: PropTypes.node,
    injectedProp: PropTypes.object,
};

const DetectScrollBottomHOC = () => (Children) => (props) =>
    DetectScrollBottom({
        injectedProp: props,
        Children,
    });

export default DetectScrollBottomHOC;
