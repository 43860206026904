import { styled } from '@mui/material/styles';
import { Typography } from 'sunwise-ui';

export default styled(Typography)(({ theme, ...props }) => {
    const { height = '14px', width = '2px' } = props;
    return `
    font-weight: bold;
    margin: 8px 0;
    padding: 0 15px;
    position: relative;
    &:before {
        background-color: ${theme.palette.primary.main};
        content: '';
        display: block;
        height: ${height};
        left: 0;
        margin-top: -7px;
        position: absolute;
        top: 50%;
        width: ${width};
    }
    `;
});
