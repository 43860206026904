import {
    DELETE_ERRORS,
    INVALID_PASSWORD,
    VALID_PASSWORD,
} from '../actionTypes';
import { resetPasswordActions } from '../reducer';
import * as selectors from '../selectors';

export default (event) => (dispatch, getState) => {
    const state = getState();
    const errors = selectors.getErrorsLogin(state);

    if (errors !== null) {
        dispatch(resetPasswordActions[DELETE_ERRORS]());
    }

    const password = event.target.value;

    if (password.length >= 8) {
        dispatch(resetPasswordActions[VALID_PASSWORD]());
    } else {
        dispatch(resetPasswordActions[INVALID_PASSWORD]());
    }
};
