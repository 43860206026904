import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import OneProposalTemplateIcon from 'resources/icons/oneProposalTemplateIcon.svg?react';

const Container = styled('div')`
    display: block;
    height: 58px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 58px;
    z-index: 2;
`;

const Triangle = styled('div')`
    border-right: 58px solid transparent;
    border-top: 58px solid ${({ isGold }) => (isGold ? '#f69d3c' : '#b4bbdd')};
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
`;

const StyledOneProposalTemplateIcon = styled(OneProposalTemplateIcon)`
    height: 20px;
    left: 8px;
    position: absolute;
    top: 6px;
    width: 16px;
    z-index: 3;

    path {
        fill: #eef4fa;
    }
`;

const DefaultTemplatePreview = ({ isNew, isOustanding }) => (
    <Container>
        <Triangle isGold={isOustanding || isNew} />
        <StyledOneProposalTemplateIcon />
    </Container>
);

DefaultTemplatePreview.propTypes = {
    isNew: PropTypes.bool,
    isOustanding: PropTypes.bool,
};

export default DefaultTemplatePreview;
