import React, { useState } from 'react';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import LinkIcon from '@mui/icons-material/Link';
import SettingsIcon from '@mui/icons-material/Settings';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import SpeedIcon from '@mui/icons-material/Speed';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    Skeleton,
    Typography,
} from 'sunwise-ui';

import CheckAccess from 'common/components/CheckAccess';
import { IconAftersales, IconSales } from 'common/components/icons';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import BranchOfficesDrawer from 'common/modules/multiBranches/components/BranchOfficesDrawer';

import AfterSalesOptions from './AfterSalesOptions';
import AnalyticsOptions from './AnalyticsOptions';
import CompanyOptions from './CompanyOptions';
import ContactOptions from './ContactOptions';
import { handleOnMouseHover } from './helpers';
import IntegrationsOptions from './IntegrationsOptions';
import Logo from './Logo';
import ParametersOptions from './ParametersOptions';
import ProfileButton from './ProfileButton';
import ProfileMenu from './ProfileMenu';
import ProfileMenuItems from './ProfileMenuItems';
import SalesOptions from './SalesOptions';
import SecondaryDrawer from './SecondaryDrawer';
import SettingsOptions from './SettingsOptions';
import StyledListItemButton from './StyledListItemButton';
import StyledListItemText from './StyledListItemText';
import StyledMenu from './StyledMenu';

const Name = styled(Typography)(() => ({
    color: '#EEF4FA',
    display: 'block',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.25px',
    lineHeight: '20px',
    textAlign: 'left',
}));

const StyledStyledListItemText = styled(StyledListItemText)(() => ({
    color: '#fff',
}));

const DrawerContent = ({
    container,
    drawerSize,
    handleDrawerToggle,
    isFetchingUserSettings,
    match,
    mobileOpen,
    multiBranchMode,
    profileValues,
    redirect,
    selectedTheme,
    setTheme,
    subscription,
    toggleDrawerSize,
    userPreferences,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [anchorAfterSalesMenu, setAnchorAfterSalesMenu] = useState(null);
    const [anchorAnalyticsMenu, setAnchorAnalyticsMenu] = useState(null);
    const [anchorCompanyMenu, setAnchorCompanyMenu] = useState(null);
    const [anchorContactsMenu, setAnchorContactsMenu] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorParametersMenu, setAnchorParametersMenu] = useState(null);
    const [anchorProfileMenu, setAnchorProfileMenu] = useState(null);
    const [anchorSalesMenu, setAnchorSalesMenu] = useState(null);
    const [anchorSettingsMenu, setAnchorSettingsMenu] = useState(null);

    const [
        mobileOpenAfterSalesOptionsDrawer,
        setMobileOpenAfterSalesOptionsDrawer,
    ] = useState(false);
    const [
        mobileOpenAnalyticsOptionsDrawer,
        setMobileOpenAnalyticsOptionsDrawer,
    ] = useState(false);
    const [
        mobileOpenParametersOptionsDrawer,
        setMobileOpenParametersOptionsDrawer,
    ] = useState(false);
    const [
        mobileOpenSettingsOptionsDrawer,
        setMobileOpenSettingsOptionsDrawer,
    ] = useState(false);
    const [mobileOpenCompanyOptionsDrawer, setMobileOpenCompanyOptionsDrawer] =
        useState(false);
    const [
        mobileOpenContactsOptionsDrawer,
        setMobileOpenContactsOptionsDrawer,
    ] = useState(false);
    const [mobileOpenMoreOptionsDrawer, setMobileOpenMoreOptionsDrawer] =
        useState(false);
    const [mobileOpenProfileDrawer, setMobileOpenProfileDrawer] =
        useState(false);
    const [mobileOpenSalesOptionsDrawer, setMobileOpenSalesOptionsDrawer] =
        useState(false);

    const open = Boolean(anchorEl);
    const openAfterSalesMenu = Boolean(anchorAfterSalesMenu);
    const openAnalyticsMenu = Boolean(anchorAnalyticsMenu);
    const openCompanyMenu = Boolean(anchorCompanyMenu);
    const openContactsMenu = Boolean(anchorContactsMenu);
    const openParametersMenu = Boolean(anchorParametersMenu);
    const openSalesMenu = Boolean(anchorSalesMenu);
    const openSettingsMenu = Boolean(anchorSettingsMenu);

    const handleAnalyticsOptionsClick = (event) => {
        if (mobileOpen && ['xs', 'sm'].includes(breakpoint)) {
            handleAnalyticsOptionsDrawerToggle();
            return;
        }
        setAnchorAnalyticsMenu(event.currentTarget);
    };
    const handleCompanyOptionsClick = (event) => {
        if (mobileOpen && ['xs', 'sm'].includes(breakpoint)) {
            handleCompanyOptionsDrawerToggle();
            return;
        }
        setAnchorCompanyMenu(event.currentTarget);
    };
    const handleContactsOptionsClick = (event) => {
        if (mobileOpen && ['xs', 'sm'].includes(breakpoint)) {
            handleContactsOptionsDrawerToggle();
            return;
        }
        setAnchorContactsMenu(event.currentTarget);
    };
    const handleMoreOptionsClick = (event) => {
        if (mobileOpen && ['xs', 'sm'].includes(breakpoint)) {
            handleMoreOptionsDrawerToggle();
            return;
        }
        setAnchorEl(event.currentTarget);
    };
    const handleParametersOptionsClick = (event) => {
        if (mobileOpen && ['xs', 'sm'].includes(breakpoint)) {
            handleParametersOptionsDrawerToggle();
            return;
        }
        setAnchorParametersMenu(event.currentTarget);
    };
    const handleProfileMenuOpen = (event) => {
        if (mobileOpen && ['xs', 'sm'].includes(breakpoint)) {
            handleProfileDrawerToggle();
            return;
        }
        setAnchorProfileMenu(event.currentTarget);
    };
    const handleSalesOptionsClick = (event) => {
        if (mobileOpen && ['xs', 'sm'].includes(breakpoint)) {
            handleSalesOptionsDrawerToggle();
            return;
        }
        setAnchorSalesMenu(event.currentTarget);
    };
    const handleSettingsOptionsClick = (event) => {
        if (mobileOpen && ['xs', 'sm'].includes(breakpoint)) {
            handleSettingsOptionsDrawerToggle();
            return;
        }
        setAnchorSettingsMenu(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);
    const handleCloseAfterSalesMenu = () => setAnchorAfterSalesMenu(null);
    const handleCloseAnalyticsMenu = () => setAnchorAnalyticsMenu(null);
    const handleCloseCompanyMenu = () => setAnchorCompanyMenu(null);
    const handleCloseContactsMenu = () => setAnchorContactsMenu(null);
    const handleCloseParametersMenu = () => setAnchorParametersMenu(null);
    const handleCloseSalesMenu = () => setAnchorSalesMenu(null);
    const handleCloseSettingsMenu = () => setAnchorSettingsMenu(null);

    const handleAfterSalesOptionsDrawerToggle = () =>
        setMobileOpenAfterSalesOptionsDrawer(
            !mobileOpenAfterSalesOptionsDrawer,
        );
    const handleAnalyticsOptionsDrawerToggle = () =>
        setMobileOpenAnalyticsOptionsDrawer(!mobileOpenAnalyticsOptionsDrawer);
    const handleCompanyOptionsDrawerToggle = () =>
        setMobileOpenCompanyOptionsDrawer(!mobileOpenCompanyOptionsDrawer);
    const handleContactsOptionsDrawerToggle = () =>
        setMobileOpenContactsOptionsDrawer(!mobileOpenContactsOptionsDrawer);
    const handleMoreOptionsDrawerToggle = () =>
        setMobileOpenMoreOptionsDrawer(!mobileOpenMoreOptionsDrawer);
    const handleParametersOptionsDrawerToggle = () =>
        setMobileOpenParametersOptionsDrawer(
            !mobileOpenParametersOptionsDrawer,
        );
    const handleProfileDrawerToggle = () =>
        setMobileOpenProfileDrawer(!mobileOpenProfileDrawer);
    const handleSalesOptionsDrawerToggle = () =>
        setMobileOpenSalesOptionsDrawer(!mobileOpenSalesOptionsDrawer);
    const handleSettingsOptionsDrawerToggle = () =>
        setMobileOpenSettingsOptionsDrawer(!mobileOpenSettingsOptionsDrawer);

    const { displaySubmenuOnHover = true } = userPreferences;

    const onMouseHover = (e, handler) =>
        handleOnMouseHover({ breakpoint, e, handler, displaySubmenuOnHover });

    const styledMenuCommonProps = {
        anchorOrigin: { horizontal: 'right', vertical: 'center' },
        transformOrigin: { horizontal: 'left', vertical: 'center' },
    };

    const styledMenuExtraProps = displaySubmenuOnHover
        ? {
              disablePortal: !['xs', 'sm', 'md'].includes(breakpoint),
              disableScrollLock: true,
          }
        : {};

    return (
        <>
            <Box
                display="flex"
                sx={{
                    alignItems: 'center',
                    justifyContent: { md: 'center', xs: 'space-between' },
                    px: { md: 0, xs: 2 },
                }}
            >
                <Box sx={{ padding: '8px 4px' }}>
                    <RouterLink to="/dashboard">
                        <Logo field={'logotype_image'} />
                    </RouterLink>
                </Box>

                <IconButton
                    onClick={() => {
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    sx={{ display: { md: 'none' } }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <Divider sx={{ display: { md: 'none', sx: 'block' } }} />

            <Box sx={{ display: { md: 'none', sx: 'block' } }}>
                <BranchOfficesDrawer
                    {...{
                        container,
                        match,
                        mobileOpen: mobileOpenProfileDrawer,
                        multiBranchMode,
                        onClose: handleProfileDrawerToggle,
                        redirect,
                    }}
                />
            </Box>

            <Box
                component="span"
                className="toggleDrawer"
                sx={{
                    color: '#4a4a5d',
                    cursor: 'pointer',
                    textAlign: 'right',
                }}
                onClick={toggleDrawerSize}
            >
                {drawerSize === 'normal' ? (
                    <ArrowLeftIcon />
                ) : (
                    <ArrowRightIcon />
                )}
            </Box>

            {isFetchingUserSettings ? (
                <Skeleton
                    animation="wave"
                    variant="rectangle"
                    sx={{ height: '100%', width: '100%' }}
                />
            ) : (
                <List
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        pb: 0,
                    }}
                >
                    {/* Contacts */}
                    <CheckAccess
                        requiredPermissions={[
                            PERMISSION_LIST.CONTACTS_PERMISSION,
                            PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
                        ]}
                    >
                        <ListItem
                            disablePadding
                            onMouseEnter={(e) =>
                                onMouseHover(e, handleContactsOptionsClick)
                            }
                            onMouseLeave={(e) =>
                                onMouseHover(e, handleCloseContactsMenu)
                            }
                        >
                            <StyledListItemButton
                                onClick={handleContactsOptionsClick}
                                selected={
                                    match.path === '/contacts' ||
                                    /^\/record/i.test(match.path) ||
                                    /^\/proposal-review-pro/i.test(
                                        match.path,
                                    ) ||
                                    '/follow-ups' === match.path
                                }
                                size={drawerSize}
                            >
                                <GroupIcon />
                                <StyledStyledListItemText
                                    primary={t('Contact', { count: 2 })}
                                />
                            </StyledListItemButton>

                            <StyledMenu
                                anchorEl={anchorContactsMenu}
                                onClose={handleCloseContactsMenu}
                                open={openContactsMenu}
                                {...styledMenuCommonProps}
                                {...styledMenuExtraProps}
                            >
                                <ContactOptions
                                    {...{
                                        handleClose: handleCloseContactsMenu,
                                        handleDrawerToggle,
                                        match,
                                        mobileOpen,
                                        redirect,
                                    }}
                                />
                            </StyledMenu>
                        </ListItem>
                    </CheckAccess>

                    {/* Sales */}
                    <CheckAccess
                        requiredPermissions={[
                            PERMISSION_LIST.DASHBOARD_PERMISSION,
                            PERMISSION_LIST.FUNNEL_FINANCING_PERMISSION,
                            PERMISSION_LIST.FUNNEL_PROJECTS_PERMISSION,
                        ]}
                    >
                        <ListItem
                            disablePadding
                            onMouseEnter={(e) =>
                                onMouseHover(e, handleSalesOptionsClick)
                            }
                            onMouseLeave={(e) =>
                                onMouseHover(e, handleCloseSalesMenu)
                            }
                        >
                            <StyledListItemButton
                                onClick={handleSalesOptionsClick}
                                selected={
                                    match.path === '/' ||
                                    match.path === '/dashboard' ||
                                    match.path === '/project-funnel' ||
                                    match.path === '/financing-funnel'
                                }
                                size={drawerSize}
                            >
                                <IconSales />
                                <StyledStyledListItemText
                                    primary={t('Sales')}
                                />
                            </StyledListItemButton>

                            <StyledMenu
                                anchorEl={anchorSalesMenu}
                                onClose={handleCloseSalesMenu}
                                open={openSalesMenu}
                                {...styledMenuCommonProps}
                                {...styledMenuExtraProps}
                            >
                                <SalesOptions
                                    {...{
                                        handleClose: handleCloseSalesMenu,
                                        handleDrawerToggle,
                                        match,
                                        mobileOpen,
                                        redirect,
                                    }}
                                />
                            </StyledMenu>
                        </ListItem>
                    </CheckAccess>

                    {/* After sales */}
                    <CheckAccess
                        requiredPermissions={[
                            PERMISSION_LIST.MONITORING_DASHBOARD_PERMISSION,
                        ]}
                    >
                        <ListItem
                            disablePadding
                            onMouseEnter={(e) =>
                                onMouseHover(e, handleCloseAfterSalesMenu)
                            }
                            onMouseLeave={(e) =>
                                onMouseHover(e, handleCloseAfterSalesMenu)
                            }
                        >
                            <StyledListItemButton
                                onClick={() => {
                                    redirect('/monitoring-dashboard');
                                    if (mobileOpen) handleDrawerToggle();
                                }}
                                selected={
                                    match.path === '/monitoring-dashboard'
                                }
                                size={drawerSize}
                            >
                                <IconAftersales />
                                <StyledStyledListItemText
                                    primary={t('After sales')}
                                />
                            </StyledListItemButton>

                            <StyledMenu
                                anchorEl={anchorAfterSalesMenu}
                                onClose={handleCloseAfterSalesMenu}
                                open={openAfterSalesMenu}
                                {...styledMenuCommonProps}
                                {...styledMenuExtraProps}
                            >
                                <AfterSalesOptions
                                    {...{
                                        handleClose: handleCloseAfterSalesMenu,
                                        handleDrawerToggle,
                                        match,
                                        mobileOpen,
                                        redirect,
                                    }}
                                />
                            </StyledMenu>
                        </ListItem>
                    </CheckAccess>

                    {/* Analytics */}
                    <CheckAccess
                        requiredPermissions={[
                            PERMISSION_LIST.ANALYTICS_PERMISSION,
                            PERMISSION_LIST.CFE_PERMISSION,
                            PERMISSION_LIST.CRE_PERMISSION,
                        ]}
                    >
                        <ListItem
                            className="__userguiding_analytics_module_navbar_item"
                            disablePadding
                            onMouseEnter={(e) => {
                                onMouseHover(e, handleAnalyticsOptionsClick);
                            }}
                            onMouseLeave={(e) => {
                                onMouseHover(e, handleCloseAnalyticsMenu);
                            }}
                        >
                            <StyledListItemButton
                                selected={
                                    '/analytics' === match.path ||
                                    '/analytics-cfe' === match.path ||
                                    '/analytics-cre' === match.path
                                }
                                size={drawerSize}
                                onClick={handleAnalyticsOptionsClick}
                            >
                                <SignalCellularAltIcon />
                                <StyledStyledListItemText
                                    primary={t('Analytics')}
                                />
                            </StyledListItemButton>

                            <StyledMenu
                                anchorEl={anchorAnalyticsMenu}
                                onClose={handleCloseAnalyticsMenu}
                                open={openAnalyticsMenu}
                                {...styledMenuCommonProps}
                                {...styledMenuExtraProps}
                            >
                                <AnalyticsOptions
                                    {...{
                                        handleClose: handleCloseAnalyticsMenu,
                                        handleDrawerToggle,
                                        match,
                                        mobileOpen,
                                        redirect,
                                    }}
                                />
                            </StyledMenu>
                        </ListItem>
                    </CheckAccess>

                    {/* Parameters */}
                    <CheckAccess
                        requiredPermissions={[
                            PERMISSION_LIST.SOLAR_GENERATION_PERMISSION,
                            PERMISSION_LIST.EQUIPMENT_COMPATIBILITY_PERMISSION,
                            PERMISSION_LIST.TAXES_PERMISSION,
                            PERMISSION_LIST.CURRENCIES_PERMISSION,
                            PERMISSION_LIST.CERTIFIED_RATES_PERMISSION,
                            PERMISSION_LIST.SELFCONSUMPTION_ADMIN_PROFILES_PERMISSION,
                        ]}
                    >
                        <ListItem
                            disablePadding
                            onMouseEnter={(e) =>
                                onMouseHover(e, handleParametersOptionsClick)
                            }
                            onMouseLeave={(e) =>
                                onMouseHover(e, handleCloseParametersMenu)
                            }
                        >
                            <StyledListItemButton
                                onClick={handleParametersOptionsClick}
                                selected={
                                    '/solar-generation' === match.path ||
                                    '/equipment-compatibility' === match.path ||
                                    '/rates' === match.path ||
                                    '/consumption-profiles' === match.path ||
                                    '/currencies' === match.path
                                }
                                size={drawerSize}
                            >
                                <SpeedIcon fontSize="small" />
                                <StyledStyledListItemText
                                    primary={t('Parameter', { count: 2 })}
                                />
                            </StyledListItemButton>

                            <StyledMenu
                                anchorEl={anchorParametersMenu}
                                onClose={handleCloseParametersMenu}
                                open={openParametersMenu}
                                {...styledMenuCommonProps}
                                {...styledMenuExtraProps}
                            >
                                <ParametersOptions
                                    {...{
                                        handleClose: handleCloseParametersMenu,
                                        handleDrawerToggle,
                                        match,
                                        mobileOpen,
                                        redirect,
                                    }}
                                />
                            </StyledMenu>
                        </ListItem>
                    </CheckAccess>

                    {/* Catalogs */}
                    <CheckAccess
                        requiredPermissions={[
                            PERMISSION_LIST.ACCESORIES_PERMISSION,
                            PERMISSION_LIST.ADITIONALS_PERMISSION,
                            PERMISSION_LIST.ALLIANCES_PERMISSION,
                            PERMISSION_LIST.ENERGY_BACKUP_PERMISSION,
                            PERMISSION_LIST.FINANCIAL_PRODUCTS_PERMISSION,
                            PERMISSION_LIST.INVERTERS_PERMISSION,
                            PERMISSION_LIST.PANELS_PERMISSION,
                            PERMISSION_LIST.PLANS_PERMISSION,
                            PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
                            PERMISSION_LIST.REPORT_TEMPLATES_PERMISSION,
                            PERMISSION_LIST.SMART_DOCUMENT_TEMPLATES_PERMISSION,
                            PERMISSION_LIST.STRUCTURES_PERMISSION,
                            PERMISSION_LIST.SUMMARY_TEMPLATES_PERMISSION,
                            PERMISSION_LIST.WORKFORCE_PERMISSION,
                        ]}
                    >
                        <ListItem
                            disablePadding
                            onMouseEnter={(e) =>
                                onMouseHover(e, handleSettingsOptionsClick)
                            }
                            onMouseLeave={(e) =>
                                onMouseHover(e, handleCloseSettingsMenu)
                            }
                        >
                            <StyledListItemButton
                                selected={
                                    /^\/products/i.test(match.path) ||
                                    '/plan-configuration' === match.path ||
                                    /^\/template-library/i.test(match.path) ||
                                    /^\/edit-template-pro/i.test(match.path) ||
                                    '/alliances' === match.path ||
                                    '/financial-products' === match.path
                                }
                                size={drawerSize}
                                onClick={handleSettingsOptionsClick}
                            >
                                <BusinessIcon />
                                <StyledStyledListItemText
                                    primary={t('Catalog', { count: 2 })}
                                />
                            </StyledListItemButton>

                            <StyledMenu
                                anchorEl={anchorSettingsMenu}
                                onClose={handleCloseSettingsMenu}
                                open={openSettingsMenu}
                                {...styledMenuCommonProps}
                                {...styledMenuExtraProps}
                            >
                                <SettingsOptions
                                    {...{
                                        handleClose: handleCloseSettingsMenu,
                                        handleDrawerToggle,
                                        match,
                                        mobileOpen,
                                        redirect,
                                    }}
                                />
                            </StyledMenu>
                        </ListItem>
                    </CheckAccess>

                    {/* Integrations */}
                    <CheckAccess
                        requiredPermissions={[
                            PERMISSION_LIST.EMBEDDED_PROPOSAL_PERMISSION,
                            PERMISSION_LIST.REPORTS_PERMISSION,
                            PERMISSION_LIST.SUPPLIERS_PERMISSION,
                            PERMISSION_LIST.ZAPIER_PERMISSION,
                        ]}
                    >
                        <ListItem
                            disablePadding
                            onMouseEnter={(e) =>
                                onMouseHover(e, handleMoreOptionsClick)
                            }
                            onMouseLeave={(e) => onMouseHover(e, handleClose)}
                        >
                            <StyledListItemButton
                                onClick={handleMoreOptionsClick}
                                selected={
                                    /^\/web-quoter/i.test(match.path) ||
                                    '/monitoring-credentials' === match.url ||
                                    '/suppliers' === match.url ||
                                    '/zapier' === match.url
                                }
                                size={drawerSize}
                            >
                                <LinkIcon />
                                <StyledStyledListItemText
                                    primary={t('Integration', { count: 2 })}
                                />
                            </StyledListItemButton>

                            <StyledMenu
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                open={open}
                                {...styledMenuCommonProps}
                                {...styledMenuExtraProps}
                            >
                                <IntegrationsOptions
                                    {...{
                                        handleClose,
                                        handleDrawerToggle,
                                        match,
                                        mobileOpen,
                                        redirect,
                                    }}
                                />
                            </StyledMenu>
                        </ListItem>
                    </CheckAccess>

                    {/* Company */}
                    <CheckAccess
                        requiredPermissions={[
                            PERMISSION_LIST.BRANCH_OFFICES_PERMISSION,
                            PERMISSION_LIST.BRANDING_PERMISSION,
                            PERMISSION_LIST.GENERAL_COMPANY_INFO_PERMISSION,
                            PERMISSION_LIST.COMPANY_USERS_PERMISSION,
                            PERMISSION_LIST.LIST_SETTINGS_PERMISSION,
                            PERMISSION_LIST.ROLES_PERMISSION,
                        ]}
                    >
                        <ListItem
                            disablePadding
                            onMouseEnter={(e) =>
                                onMouseHover(e, handleCompanyOptionsClick)
                            }
                            onMouseLeave={(e) =>
                                onMouseHover(e, handleCloseCompanyMenu)
                            }
                        >
                            <StyledListItemButton
                                onClick={handleCompanyOptionsClick}
                                selected={
                                    '/company' === match.path ||
                                    '/branch-offices' === match.path ||
                                    '/branding' === match.path ||
                                    '/roles' === match.path ||
                                    '/users' === match.path ||
                                    '/lists' === match.path
                                }
                                size={drawerSize}
                            >
                                <SettingsIcon fontSize="small" />
                                <StyledStyledListItemText
                                    primary={t('Setting')}
                                />
                            </StyledListItemButton>

                            <StyledMenu
                                anchorEl={anchorCompanyMenu}
                                onClose={handleCloseCompanyMenu}
                                open={openCompanyMenu}
                                {...styledMenuCommonProps}
                                {...styledMenuExtraProps}
                            >
                                <CompanyOptions
                                    {...{
                                        handleClose: handleCloseCompanyMenu,
                                        handleDrawerToggle,
                                        match,
                                        mobileOpen,
                                        redirect,
                                    }}
                                />
                            </StyledMenu>
                        </ListItem>
                    </CheckAccess>

                    {/* Profile */}
                    <ListItem disablePadding sx={{ marginTop: 'auto' }}>
                        <Box sx={{ width: '100%' }}>
                            <Divider
                                sx={{
                                    display: { md: 'none', sx: 'block' },
                                    marginTop: 'auto',
                                }}
                            />

                            <StyledListItemButton
                                selected={/^\/profile/i.test(match.path)}
                                size={drawerSize}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    p: '8px 4px',
                                }}
                                onClick={handleProfileMenuOpen}
                            >
                                <ProfileButton profileValues={profileValues} />

                                <Name
                                    sx={{
                                        display: { md: 'none', xs: 'block' },
                                    }}
                                >
                                    {profileValues?.first_name}{' '}
                                    {profileValues?.last_name}
                                </Name>
                            </StyledListItemButton>
                        </Box>
                    </ListItem>
                </List>
            )}

            <SecondaryDrawer
                {...{
                    container,
                    match,
                    mobileOpen: mobileOpenProfileDrawer,
                    onClose: handleProfileDrawerToggle,
                    redirect,
                }}
            >
                <ProfileMenuItems
                    {...{
                        handleCloseProfileMenu: () => {
                            handleProfileDrawerToggle();
                            handleDrawerToggle();
                        },
                        match,
                        profileValues,
                        redirect,
                        selectedTheme,
                        setTheme,
                        subscription,
                    }}
                />
            </SecondaryDrawer>

            <SecondaryDrawer
                {...{
                    container,
                    match,
                    mobileOpen: mobileOpenMoreOptionsDrawer,
                    onClose: handleMoreOptionsDrawerToggle,
                    redirect,
                }}
            >
                <IntegrationsOptions
                    {...{
                        handleClose: () => {
                            handleMoreOptionsDrawerToggle();
                            handleDrawerToggle();
                        },
                        handleDrawerToggle,
                        match,
                        mobileOpen,
                        redirect,
                    }}
                />
            </SecondaryDrawer>

            <SecondaryDrawer
                {...{
                    container,
                    match,
                    mobileOpen: mobileOpenAnalyticsOptionsDrawer,
                    onClose: handleAnalyticsOptionsDrawerToggle,
                    redirect,
                }}
            >
                <AnalyticsOptions
                    {...{
                        handleClose: () => {
                            handleAnalyticsOptionsDrawerToggle();
                            handleDrawerToggle();
                        },
                        handleDrawerToggle,
                        match,
                        mobileOpen,
                        redirect,
                    }}
                />
            </SecondaryDrawer>

            <SecondaryDrawer
                {...{
                    container,
                    match,
                    mobileOpen: mobileOpenSettingsOptionsDrawer,
                    onClose: handleSettingsOptionsDrawerToggle,
                    redirect,
                }}
            >
                <SettingsOptions
                    {...{
                        handleClose: () => {
                            handleSettingsOptionsDrawerToggle();
                            handleDrawerToggle();
                        },
                        handleDrawerToggle,
                        match,
                        mobileOpen,
                        redirect,
                    }}
                />
            </SecondaryDrawer>

            <SecondaryDrawer
                {...{
                    container,
                    match,
                    mobileOpen: mobileOpenParametersOptionsDrawer,
                    onClose: handleParametersOptionsDrawerToggle,
                    redirect,
                }}
            >
                <ParametersOptions
                    {...{
                        handleClose: () => {
                            handleParametersOptionsDrawerToggle();
                            handleDrawerToggle();
                        },
                        handleDrawerToggle,
                        match,
                        mobileOpen,
                        redirect,
                    }}
                />
            </SecondaryDrawer>

            <SecondaryDrawer
                {...{
                    container,
                    match,
                    mobileOpen: mobileOpenCompanyOptionsDrawer,
                    onClose: handleCompanyOptionsDrawerToggle,
                    redirect,
                }}
            >
                <CompanyOptions
                    {...{
                        handleClose: () => {
                            handleCompanyOptionsDrawerToggle();
                            handleDrawerToggle();
                        },
                        handleDrawerToggle,
                        match,
                        mobileOpen,
                        redirect,
                    }}
                />
            </SecondaryDrawer>

            <SecondaryDrawer
                {...{
                    container,
                    match,
                    mobileOpen: mobileOpenContactsOptionsDrawer,
                    onClose: handleContactsOptionsDrawerToggle,
                    redirect,
                }}
            >
                <ContactOptions
                    {...{
                        handleClose: () => {
                            handleContactsOptionsDrawerToggle();
                            handleDrawerToggle();
                        },
                        handleDrawerToggle,
                        match,
                        mobileOpen,
                        redirect,
                    }}
                />
            </SecondaryDrawer>

            <SecondaryDrawer
                {...{
                    container,
                    match,
                    mobileOpen: mobileOpenSalesOptionsDrawer,
                    onClose: handleSalesOptionsDrawerToggle,
                    redirect,
                }}
            >
                <SalesOptions
                    {...{
                        handleClose: () => {
                            handleSalesOptionsDrawerToggle();
                            handleDrawerToggle();
                        },
                        handleDrawerToggle,
                        match,
                        mobileOpen,
                        redirect,
                    }}
                />
            </SecondaryDrawer>

            <SecondaryDrawer
                {...{
                    container,
                    match,
                    mobileOpen: mobileOpenAfterSalesOptionsDrawer,
                    onClose: handleAfterSalesOptionsDrawerToggle,
                    redirect,
                }}
            >
                <AfterSalesOptions
                    {...{
                        handleClose: () => {
                            handleAfterSalesOptionsDrawerToggle();
                            handleDrawerToggle();
                        },
                        handleDrawerToggle,
                        match,
                        mobileOpen,
                        redirect,
                    }}
                />
            </SecondaryDrawer>

            <ProfileMenu
                anchorProfileMenu={anchorProfileMenu}
                handleDrawerToggle={handleDrawerToggle}
                match={match}
                mobileOpen={mobileOpen}
                multiBranchMode={multiBranchMode}
                profileValues={profileValues}
                redirect={redirect}
                selectedTheme={selectedTheme}
                setAnchorProfileMenu={setAnchorProfileMenu}
                setTheme={setTheme}
                subscription={subscription}
            />
        </>
    );
};

DrawerContent.propTypes = {
    container: PropTypes.func,
    drawerSize: PropTypes.string,
    handleDrawerToggle: PropTypes.func,
    isFetchingUserSettings: PropTypes.bool,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    multiBranchMode: PropTypes.bool,
    profileValues: PropTypes.object,
    redirect: PropTypes.func,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
    subscription: PropTypes.object,
    toggleDrawerSize: PropTypes.func,
    userPreferences: PropTypes.object,
};

export default DrawerContent;
