import { getConsumptionEnergy } from 'common/api/v2/costumerEnergyConsumption';

import {
    FETCH_CUSTOMER_CONSUMPTION,
    FETCH_CUSTOMER_CONSUMPTION_FAILURE,
    FETCH_CUSTOMER_CONSUMPTION_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (projectUUID) => (dispatch) => {
    dispatch(actions[FETCH_CUSTOMER_CONSUMPTION]());

    getConsumptionEnergy(projectUUID)
        .then((response) =>
            dispatch(
                actions[FETCH_CUSTOMER_CONSUMPTION_SUCCESS](
                    response?.data?.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                actions[FETCH_CUSTOMER_CONSUMPTION_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
