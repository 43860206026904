import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { maximumNumberCharacters, REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        comment: string()
            .required(REQUIRED_TEXT)
            .max(250, maximumNumberCharacters(250))
            .nullable(),
    });
};
