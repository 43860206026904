import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

const CardFolderHeaderWrapper = styled(Box)`
    color: #848bab;
    cursor: pointer;
    line-height: 14px;
    padding: 15px;
`;

const CardFolderHeader = ({ children }) => (
    <CardFolderHeaderWrapper>{children}</CardFolderHeaderWrapper>
);

CardFolderHeader.propTypes = {
    children: PropTypes.node,
};

export default CardFolderHeader;
