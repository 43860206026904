import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH,
    FETCH_FAILURE,
    FETCH_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
} from './actionTypes';
import { NAME, DEFAULT_VALUES } from './constants';

const INITIAL_STATE = {
    fetch: {
        data: { currency: null },
        errors: null,
        isFetching: false,
    },
    initialValues: DEFAULT_VALUES,
    save: {
        data: {},
        errors: null,
        isSaving: false,
    },
};

const companyBrandingSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH]: (state) => {
            state.fetch = { ...state.fetch, errors: [], isFetching: true };
        },
        [FETCH_FAILURE]: (state, action) => {
            state.fetch = {
                ...state.fetch,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUCCESS]: (state, action) => {
            state.fetch = {
                ...state.fetch,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
    },
});

export const actions = companyBrandingSlice.actions;

export default companyBrandingSlice.reducer;
