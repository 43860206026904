import React from 'react';

import { styled } from '@mui/material/styles';
import { Typography } from 'sunwise-ui';

const Code = styled('span')`
    cursor: default;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    overflow-wrap: anywhere;
`;

const ProductCodeFormatter = (code) => (
    <Typography>
        <Code title={code}>{code || '--'}</Code>
    </Typography>
);

export default ProductCodeFormatter;
