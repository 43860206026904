import { push } from 'connected-react-router';
import i18next from 'i18next';

import alerts from 'common/modules/alerts';

export default (contactId, projectId, proposalId) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Continue'),
        messages: [
            i18next.t(
                'You will be redirected to another screen to continue with this process',
            ),
        ],
        onSuccess: () => {
            localStorage.setItem('requestIn', proposalId);
            dispatch(
                push(
                    `/record/${contactId}?tab=projects&project=${projectId}&view=proposals&type=proposals`,
                ),
            );
        },
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'info',
    };

    dispatch(alerts.actions.show(dataAlert));
};
