import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    font-size: 12px;
    line-height: 15px;

    .img-thumbnail {
        padding: 1rem;
    }

    .form-label {
        font-weight: bold;
    }

    .border-left {
        border-left: 1px solid #eff1fb !important;
    }
`;
