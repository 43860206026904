import React from 'react';

import PropTypes from 'prop-types';
import { Divider, Grid } from 'sunwise-ui';

const HeaderContainer = ({
    children,
    hasDivider = true,
    mbDivider = '1.5rem',
    sxHeader,
}) => (
    <>
        <Grid
            container
            sx={{
                alignItems: 'center',
                display: 'flex',
                paddingBottom: '1rem',
                paddingTop: '1rem',
                ...sxHeader,
            }}
        >
            {children}
        </Grid>

        {hasDivider && <Divider sx={{ marginBottom: mbDivider }} />}
    </>
);

HeaderContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    hasDivider: PropTypes.bool,
    mbDivider: PropTypes.string,
    sxHeader: PropTypes.object,
};

export default HeaderContainer;
