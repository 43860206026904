export const NAME = 'proposal-generator-rate-configuration';

export const RATE_TYPES = {
    CURRENT: 'current_rate',
    NEXT: 'next_rate',
};

export const CONCEPT_PRICE_GROUP = {
    ENERGY: '$/kWh',
    OTHER: '$',
};
