import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

import IconPreview from 'common/components/icons/IconPreview';
import { FlexRow } from 'common/utils/layout';

const StyledIcon = styled(IconPreview)`
    @media (max-width: 768px) {
        width: 20px;
        height: 20px;
    }
`;

const StyledText = styled(Typography)`
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    margin-left: 20px;
    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const TitlePreview = ({ name }) => {
    if (!name) return null;
    return (
        <FlexRow alignItems="center">
            <StyledIcon />
            <StyledText>{name}</StyledText>
        </FlexRow>
    );
};

TitlePreview.propTypes = { name: PropTypes.string };

export default TitlePreview;
