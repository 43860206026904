import { createSlice } from '@reduxjs/toolkit';

import {
    INITIALIZE_FORM,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_CURRENT_VALUES,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    currentValues: {
        allow_staff_support: false,
    },
    initialValues: {
        allow_staff_support: false,
        id: null,
    },
    save: {
        data: {},
        errors: null,
        isSaving: false,
    },
};

const profileSecurityPermissionsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_CURRENT_VALUES]: (state, action) => {
            state.currentValues = { ...state.currentValues, ...action.payload };
        },
    },
});

export const profileSecurityPermissionsActions =
    profileSecurityPermissionsSlice.actions;

export default profileSecurityPermissionsSlice.reducer;
