import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

const ProjectColumns = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const columns = [
        {
            align: 'left',
            field: 'name',
            flex: 2,
            headerName: t('Project'),
            minWidth: 180,
            renderCell: (params) => (
                <Typography variant="caption" fontWeight="bold">
                    {params?.row?.name || ''}
                </Typography>
            ),
        },
    ];

    if (breakpoint === 'xs') return columns;

    columns.push({
        align: 'left',
        field: 'rate',
        flex: 1,
        headerName: t('Rate'),
        minWidth: 100,
        valueGetter: (params) => params?.row?.consumptions?.rate || '--',
    });

    if (breakpoint === 'sm') return columns;

    return columns.concat([
        {
            align: 'left',
            field: 'status',
            flex: 1,
            headerName: t('Status'),
            minWidth: 180,
            renderCell: (params) => {
                const status = params?.row?.status_project;
                if (!status) return null;
                return (
                    <Box display="flex" alignItems="center" gap={1}>
                        <Box
                            width="10px"
                            height="10px"
                            backgroundColor={status?.primary_color}
                            borderRadius="5px"
                        />

                        <Typography
                            noWrap
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                            }}
                            variant="body2"
                        >
                            {status?.name}
                        </Typography>
                    </Box>
                );
            },
        },
    ]);
};

export default ProjectColumns;
