import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from 'sunwise-ui';

const ContactFormatter = ({ row }) => {
    const { first_lastname, id, name, second_lastname } = get(
        row,
        'contact',
        {},
    );
    return (
        <Link
            color="inherit"
            component={RouterLink}
            to={`/record/${id}/?tab=tracking`}
            underline="none"
        >
            <Typography variant="caption" fontWeight="bold">
                {`${name} ${first_lastname} ${second_lastname}`}
            </Typography>
        </Link>
    );
};

ContactFormatter.propTypes = {
    row: PropTypes.object,
};

export default ContactFormatter;
