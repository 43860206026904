import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    DELETE_PRODUCT,
    DELETE_PRODUCT_FAILURE,
    DELETE_PRODUCT_SUCCESS,
    FETCH_PRODUCT,
    FETCH_PRODUCT_FAILURE,
    FETCH_PRODUCT_SUCCESS,
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_FAILURE,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_TEMPLATE_CATALOGS,
    FETCH_TEMPLATE_CATALOGS_FAILURE,
    FETCH_TEMPLATE_CATALOGS_SUCCESS,
    INITIALIZE_FORM,
    MODAL_PRODUCT_STATUS,
    RESET_FORM,
    SAVE_PRODUCT,
    SAVE_PRODUCT_FAILURE,
    SAVE_PRODUCT_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    bulkUpdateItems: {
        data: [],
        errors: null,
        isFetching: false,
    },
    deleteProduct: {
        errors: [],
        isDeleting: true,
    },
    initialValues: {
        annual_increase: 0,
        amount_values: { isChecked: false, max: 0, min: 0, unit: 0 },
        blind_discount: { isChecked: false, value: 0, unit: 0 },
        dealer_fee: 0,
        description: '',
        disabled: false,
        file_percentage: null,
        name: '',
        parameters_commission_credit: {
            isChecked: true,
            value: 0,
            unit: 0,
            opening_commission_vat: 20,
        },
        parameters_hitch: { isChecked: false, value: 0, unit: 0 },
        parameters_insurance: { isChecked: false, value: 0, unit: 0 },
        parameters_rate: { isChecked: true, value: 0, unit: 0 },
        parameters_residual: { isChecked: false, value: 0, unit: 0 },
        parameters: {
            amount: 0,
            hitch: 0,
            commission_credit: 0,
            terms: [],
            rate: 0,
            tax: 0,
            insurance: 0,
            residual: 0,
            blind_objects: ['hitch', 'rate'],
        },
        power_values: { isChecked: false, max: 0, min: 0, unit: 0 },
        template: null,
        terms: {
            isChecked: false,
            minimum_hitch: 0,
            minimum_residual: 0,
            unit: 0,
        },
        type_algorithm: 'static_payment',
        type: 0,
        types_business_company: 'physical_person',
    },
    product: {
        data: {},
        errors: [],
        isFetching: true,
    },
    isOpenProductModal: false,
    products: {
        data: {
            data: [],
            pagination: null,
        },
        errors: null,
        isFetching: false,
    },
    saveProduct: {
        errors: [],
        isFetching: true,
    },
    templateCatalogs: {
        isFetching: true,
        data: [],
        errors: [],
    },
};

const financialProductSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isFetching: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                data: action.payload,
                isFetching: false,
            };
        },
        [DELETE_PRODUCT]: (state) => {
            state.deleteProduct = {
                ...state.deleteProduct,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_PRODUCT_FAILURE]: (state, action) => {
            state.deleteProduct = {
                ...state.deleteProduct,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_PRODUCT_SUCCESS]: (state) => {
            state.deleteProduct = {
                ...state.deleteProduct,
                isDeleting: false,
            };
        },
        [FETCH_PRODUCT]: (state) => {
            state.product = { ...state.product, errors: [], isFetching: true };
        },
        [FETCH_PRODUCT_FAILURE]: (state, action) => {
            state.product = {
                ...state.product,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PRODUCT_SUCCESS]: (state, action) => {
            state.product = {
                ...state.product,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PRODUCTS]: (state) => {
            state.products = {
                ...state.products,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PRODUCTS_FAILURE]: (state, action) => {
            state.products = {
                ...state.products,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PRODUCTS_SUCCESS]: (state, action) => {
            state.products = {
                ...state.products,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_CATALOGS]: (state) => {
            state.templateCatalogs.isFetching = true;
        },
        [FETCH_TEMPLATE_CATALOGS_FAILURE]: (state, action) => {
            state.templateCatalogs = {
                ...state.templateCatalogs,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_CATALOGS_SUCCESS]: (state, action) => {
            state.templateCatalogs = {
                ...state.templateCatalogs,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [MODAL_PRODUCT_STATUS]: (state, action) => {
            state.isOpenProductModal = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_PRODUCT]: (state) => {
            state.saveProduct = {
                ...state.saveProduct,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_PRODUCT_FAILURE]: (state, action) => {
            state.saveProduct = {
                ...state.saveProduct,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_PRODUCT_SUCCESS]: (state) => {
            state.saveProduct = {
                ...state.saveProduct,
                isSaving: false,
            };
        },
        [UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.products.data.data = action.payload;
        },
    },
});

export const financialProductActions = financialProductSlice.actions;

export default financialProductSlice.reducer;
