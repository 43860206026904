import { NAME } from './constants';

export const DELETE_TRACKING = `${NAME}/DELETE_TRACKING`;
export const DELETE_TRACKING_FAILURE = `${NAME}/DELETE_TRACKING_FAILURE`;
export const DELETE_TRACKING_SUCCESS = `${NAME}/DELETE_TRACKING_SUCCESS`;

export const FETCH_TRACKINGS = `${NAME}/FETCH_TRACKINGS`;
export const FETCH_TRACKINGS_FAILURE = `${NAME}/FETCH_TRACKINGS_FAILURE`;
export const FETCH_TRACKINGS_SUCCESS = `${NAME}/FETCH_TRACKINGS_SUCCESS`;

export const FETCH_TRACKINGS_NEXT = `${NAME}/FETCH_TRACKINGS_NEXT`;
export const FETCH_TRACKINGS_NEXT_FAILURE = `${NAME}/FETCH_TRACKINGS_NEXT_FAILURE`;
export const FETCH_TRACKINGS_NEXT_SUCCESS = `${NAME}/FETCH_TRACKINGS_NEXT_SUCCESS`;

export const FETCH_TRACKINGS_PURPOSES = `${NAME}/FETCH_TRACKINGS_PURPOSES`;
export const FETCH_TRACKINGS_PURPOSES_FAILURE = `${NAME}/FETCH_TRACKINGS_PURPOSES_FAILURE`;
export const FETCH_TRACKINGS_PURPOSES_SUCCESS = `${NAME}/FETCH_TRACKINGS_PURPOSES_SUCCESS`;

export const FETCH_USERS = `${NAME}/FETCH_USERS`;
export const FETCH_USERS_FAILURE = `${NAME}/FETCH_USERS_FAILURE`;
export const FETCH_USERS_SUCCESS = `${NAME}/FETCH_USERS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const OPEN_CONFIRM_MODAL = `${NAME}/OPEN_CONFIRM_MODAL`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_TRACKING = `${NAME}/SAVE_TRACKING`;
export const SAVE_TRACKING_FAILURE = `${NAME}/SAVE_TRACKING_FAILURE`;
export const SAVE_TRACKING_SUCCESS = `${NAME}/SAVE_TRACKING_SUCCESS`;

export const SET_CONTACT_ID = `${NAME}/SET_CONTACT_ID`;

export const SET_DATA_CONFIRM_MODAL = `${NAME}/SET_DATA_CONFIRM_MODAL`;

export const SET_ELEMENT_TO_DELETE = `${NAME}/SET_ELEMENT_TO_DELETE`;

export const SHOW_FORM = `${NAME}/SHOW_FORM`;
