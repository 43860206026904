import { getReportEnded } from 'common/api/v1/aftersalesPrinted';

import {
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
} from '../actionTypes';
import { reportTemplateActions } from '../reducer';

export default ({ id, initTemplate }) =>
    (dispatch) =>
        new Promise((resolve) => {
            dispatch(reportTemplateActions[FETCH_CUSTOM_TEMPLATE]());

            return getReportEnded(id)
                .then((response) => {
                    dispatch(
                        reportTemplateActions[FETCH_CUSTOM_TEMPLATE_SUCCESS](
                            response?.data,
                        ),
                    );
                    initTemplate(response?.data, id);
                })
                .catch((error) =>
                    dispatch(
                        reportTemplateActions[FETCH_CUSTOM_TEMPLATE_FAILURE](
                            error,
                        ),
                    ),
                )
                .finally(resolve);
        });
