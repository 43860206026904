import initializeForm from './initializeForm';
import resetSave from './resetSave';
import setIsNewRecord from './setIsNewRecord';
import toggleModal from './toggleModal';

export default (item) => (dispatch) => {
    const values = {
        id: item.id,
        name: item.name,
        primary_color: item.primaryColor,
    };
    dispatch(resetSave());
    dispatch(initializeForm(values));
    dispatch(setIsNewRecord(false));
    dispatch(toggleModal(true));
};
