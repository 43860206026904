import i18next from 'i18next';
import { isEmpty, isNull } from 'lodash';
import { types } from 'sunwise-template-core';

import { URL_TYPES } from 'common/constants';
import { getFileExtensionByPath } from 'common/utils/helpers';

import {
    CSV_FILE,
    CSV_HOURLY_FILE,
    DATASHEET_PDF_TYPE,
    ELECTRIC_BILL_TYPE,
    FINANCING_TYPE,
    OFFER_SMART_DOCUMENT_FORM_DUPLICATE_MODE,
    OFFER_SMART_DOCUMENT_FORM_RENAME_MODE,
    PDF_LOW_QUALITY,
    PDF_MEDIUM_QUALITY,
    PDF_REAL_TEXT,
    XLS_FILE,
    XLS_FILE_FORMATTED,
} from './constants';

const getElectricBillImage = async (archiveUrl) => {
    try {
        const response = await fetch(archiveUrl);
        const blob = await response.blob();
        return URL.createObjectURL(blob);
    } catch (error) {
        return error;
    }
};

export const getDatasheetLabel = (key) => {
    const labels = {
        accesories: i18next.t('Accessory', { count: 2 }),
        additionals: i18next.t('Additional', { count: 2 }),
        batteries: i18next.t('Storage'),
        inverters: i18next.t('Inverter', { count: 2 }),
        panels: i18next.t('Panel', { count: 2 }),
        structures: i18next.t('Structure', { count: 2 }),
        workforces: i18next.t('Workforce and electrical equipment'),
    };
    return labels[key];
};

export const getDatasheetsByType = (datasheets, type) =>
    datasheets.filter((datasheet) => datasheet.product_type === type);

export const getDatasheetTypes = (datasheets) => {
    if (!Array.isArray(datasheets)) return [];
    const data = [...datasheets].map((item) => item.product_type);
    return [...new Set(data)];
};

export const getDocumentTemplatesToSelect = (documents) => [
    {
        label: i18next.t('Select a template'),
        value: '',
        disabled: true,
    },
    ...documents.map((item) => ({
        label: item.title,
        value: item.id,
    })),
];

export const getDownloadFormatSelected = (downloadFormat, downloadOptions) => {
    const format = downloadOptions.find(
        (item) => item.value === downloadFormat && !item.hidden,
    );
    if (format) return format.name;
    return i18next.t('Select');
};

export const getDownloadOptions = (
    count,
    hasProjectProfiles,
    selectedDocument,
) => {
    const { archive, format, type, urlType } = selectedDocument;

    const csvFile = {
        label: `CSV - ${i18next.t('Proposal')}`,
        value: CSV_FILE,
    };

    const csvHourlyFile = {
        label: `CSV - ${i18next.t('Hourly calculation', { count: 2 })}`,
        value: CSV_HOURLY_FILE,
        visible: hasProjectProfiles,
    };

    const electricBillType = {
        label: `${i18next.t('Image').toUpperCase()} - ${i18next.t('File')}`,
        value: PDF_MEDIUM_QUALITY,
    };

    const pdfLowQuality = {
        label: `PDF - ${i18next.t('Low quality')}`,
        value: PDF_LOW_QUALITY,
    };

    const pdfMediumQuality = {
        label: `PDF - ${i18next.t('Medium quality')}`,
        value: PDF_MEDIUM_QUALITY,
    };

    const pdfRealText = { label: 'PDF', value: PDF_REAL_TEXT };

    const xlsFile = {
        label: `XLS - ${i18next.t('Calculation', { count: 2 })}`,
        value: XLS_FILE,
    };

    const xlsFileFormatted = {
        label: `XLS - ${i18next.t('Support')}`,
        value: XLS_FILE_FORMATTED,
        visible: count >= 3,
    };

    let options = [];

    switch (type) {
        case DATASHEET_PDF_TYPE:
            options = [pdfMediumQuality];
            break;

        case ELECTRIC_BILL_TYPE:
            options =
                urlType === URL_TYPES.LISA && format === 'png'
                    ? [electricBillType]
                    : [pdfMediumQuality];
            break;

        case FINANCING_TYPE:
            options = archive
                ? [pdfMediumQuality]
                : [pdfRealText, pdfLowQuality, pdfMediumQuality];
            break;

        case types.ONE_PROPOSAL_TYPE:
            options = [
                pdfRealText,
                pdfLowQuality,
                pdfMediumQuality,
                csvFile,
                csvHourlyFile,
                xlsFile,
                xlsFileFormatted,
            ];
            break;

        case types.SMART_DOCUMENTS_TYPE:
            options = [pdfRealText, pdfMediumQuality, csvFile];
            break;

        default:
            options = [pdfRealText, pdfLowQuality, pdfMediumQuality];
            break;
    }

    return [
        { disabled: true, label: i18next.t('Select'), value: '' },
        ...options,
    ];
};

export const getFileNameFromURL = (archive) => {
    if (isNull(archive)) return '';
    return archive.substring(archive.lastIndexOf('/') + 1);
};

export const getIsDownloadingFileContextMessage = ({
    isFetchingOfferCsv,
    isFetchingOfferHourlyCsv,
    isFetchingPdfFile,
    isFetchingOfferXLS,
    isFetchingOfferSmartDocumentCsv,
    loadingContext,
}) => {
    if (isEmpty(loadingContext)) return;
    if (
        isFetchingOfferCsv ||
        isFetchingOfferHourlyCsv ||
        isFetchingOfferSmartDocumentCsv
    )
        loadingContext.openLoading(i18next.t('Downloading CSV').concat('...'));
    else if (isFetchingPdfFile)
        loadingContext.openLoading(i18next.t('Generating PDF').concat('...'));
    else if (isFetchingOfferXLS)
        loadingContext.openLoading(i18next.t('Generating XLS').concat('...'));
    else loadingContext.closeLoading();
};

export const getIsLoadingContextMessage = ({
    isFetching,
    isFetchingCustomTemplate,
    isFetchingDocumentTemplates,
    isFetchingFinancingTemplate,
    isFetchingOfferDatasheets,
    isFetchingOfferSmartDocuments,
    isInitializing,
    loadingContext,
}) => {
    if (isEmpty(loadingContext)) return;
    if (
        isFetching ||
        isFetchingCustomTemplate ||
        isFetchingDocumentTemplates ||
        isFetchingFinancingTemplate ||
        isFetchingOfferDatasheets ||
        isFetchingOfferSmartDocuments ||
        isInitializing
    )
        loadingContext.openLoading(i18next.t('Loading').concat('...'));
    else loadingContext.closeLoading();
};

export const getIsUpdatingContextMessage = (
    editionDisabled,
    isUpdatingCustomTemplate,
    loadingContext,
    setEditionDisabled,
) => {
    if (isEmpty(loadingContext)) return;
    if (isUpdatingCustomTemplate)
        loadingContext.openLoading(i18next.t('Saving').concat('...'));
    else {
        if (!editionDisabled) setEditionDisabled(true);
        loadingContext.closeLoading();
    }
};

export const getShortedLink = (link) => {
    if (link) {
        let maxLength = link.length / 2;
        return link.substring(0, maxLength).concat('...');
    }
    return '';
};

export const getSmartDocumentModalTitle = (offerSmartDocumentFormMode) => {
    switch (offerSmartDocumentFormMode) {
        case OFFER_SMART_DOCUMENT_FORM_DUPLICATE_MODE:
            return i18next.t('Duplicate smart document');
        case OFFER_SMART_DOCUMENT_FORM_RENAME_MODE:
            return i18next.t('Rename smart document');
        default:
            return i18next.t('Create smart document');
    }
};

export const handleCloseDrawer = (swipeableDrawerRef) => {
    if (swipeableDrawerRef.current) {
        const { handleOpen } = swipeableDrawerRef.current;
        handleOpen(false);
    }
};

export const handleClickDocumentItemBuild = (
    document,
    fetchCustomTemplate,
    fetchFinancingTemplate,
    initTemplate,
    resetTemplate,
    selectedDocument,
    setDownloadFormat,
    setSelectedDocument,
    swipeableDrawerRef,
) => {
    const { id, type } = document;
    if (id !== selectedDocument.id) {
        resetTemplate();
        setSelectedDocument(document);
        setDownloadFormat(PDF_MEDIUM_QUALITY);
        if (type === FINANCING_TYPE) fetchFinancingTemplate(id, initTemplate);
        else fetchCustomTemplate(id, initTemplate, type);
    }
    handleCloseDrawer(swipeableDrawerRef);
};

export const handleClickDownloadBuild = (
    downloadDatasheet,
    downloadElectricBill,
    downloadFile,
    downloadFormat,
    downloadOfferCsv,
    downloadOfferHourlyCsv,
    downloadOfferPdf,
    downloadOfferSmartDocumentCsv,
    downloadOfferXLS,
    loadingContext,
    selectedDocument,
) => {
    const { archive, fileName, id, name, type, urlType } = selectedDocument;
    switch (type) {
        case DATASHEET_PDF_TYPE:
            return downloadDatasheet(
                getFileNameFromURL(archive),
                (text) => loadingContext.openLoading(text),
                () => {
                    loadingContext.closeLoading();
                },
            );
        case ELECTRIC_BILL_TYPE:
            return downloadElectricBill(
                archive,
                fileName,
                loadingContext,
                name,
                urlType,
            );
        case FINANCING_TYPE:
            if (archive) return downloadFile(name, archive, 'pdf');
            return downloadOfferPdf(downloadFormat, id, name, type);
        case types.ONE_PROPOSAL_TYPE:
            switch (downloadFormat) {
                case CSV_FILE:
                    return downloadOfferCsv(id, name);
                case CSV_HOURLY_FILE:
                    return downloadOfferHourlyCsv(id, name);
                case XLS_FILE:
                    return downloadOfferXLS(id, name, 'True');
                case XLS_FILE_FORMATTED:
                    return downloadOfferXLS(id, name, 'False');
                default:
                    return downloadOfferPdf(downloadFormat, id, name, type);
            }
        case types.SMART_DOCUMENTS_TYPE:
            if (downloadFormat === CSV_FILE)
                return downloadOfferSmartDocumentCsv(id, name);
            return downloadOfferPdf(downloadFormat, id, name, type);
        default:
            return false;
    }
};

export const handleClickElectricBillBuild = (
    document,
    fetchElectricBill,
    loadingContext,
    resetTemplate,
    setDownloadFormat,
    setSelectedDocument,
    swipeableDrawerRef,
) => {
    resetTemplate();
    if (document.urlType === URL_TYPES.LISA) {
        loadingContext.openLoading(i18next.t('Getting file').concat('...'));
        fetchElectricBill(document?.fileName, async (data) => {
            let archive = data.url;
            const format = getFileExtensionByPath(data.url);
            if (format === 'png')
                archive = await getElectricBillImage(data.url);
            setSelectedDocument({
                ...document,
                archive,
                format,
            });
            loadingContext.closeLoading();
        });
    }
    if (document.urlType === URL_TYPES.SUNWISE) setSelectedDocument(document);
    setDownloadFormat(PDF_MEDIUM_QUALITY);
    handleCloseDrawer(swipeableDrawerRef);
};

export const handleScrollTopSideBar = (sideBarRef) => {
    if (sideBarRef && sideBarRef.current) sideBarRef.current.scrollTop = 0;
};
