import Geocode from 'react-geocode';

import { getAddressData } from 'common/utils/helpers';
import { getIsoCodeCurrentLocale } from 'common/utils/helpers/multiregion';

import {
    FETCH_LOCATION,
    FETCH_LOCATION_SUCCESS,
    FETCH_LOCATION_ERROR,
} from '../actionTypes';
import { projectLocationActions } from '../reducer';

import savePosition from './savePosition';

const isValidZipCode = (zipCode) => {
    if (!zipCode || zipCode === 'None') return false;
    const numericPattern = /^\d+$/;
    return numericPattern.test(zipCode);
};

export default ({
        address,
        projectId,
        politicalDivision1 = '',
        politicalDivision2 = '',
        zipCode = null,
    }) =>
    (dispatch) => {
        dispatch(projectLocationActions[FETCH_LOCATION]());

        const _address =
            address ||
            `${politicalDivision2?.name}, ${politicalDivision1?.name}`;

        const search = !address && isValidZipCode(zipCode) ? zipCode : _address;

        Geocode.setApiKey(import.meta.env.VITE_GOOGLE_MAPS_KEY);
        Geocode.setLanguage(getIsoCodeCurrentLocale());
        Geocode.setRegion('mx');

        Geocode.fromAddress(search).then(
            (response) => {
                const result = response?.results?.[0] || {};

                const { lat, lng } = result.geometry?.location || {};
                const { address_components, formatted_address } = result;

                dispatch(
                    projectLocationActions[FETCH_LOCATION_SUCCESS](result),
                );

                const {
                    external_house_number,
                    internal_house_number,
                    neighborhood,
                    street,
                } = getAddressData(address_components);

                const locationData = {
                    city: politicalDivision2?.name,
                    cp: !zipCode ? parseInt(politicalDivision2?.code) : zipCode,
                    political_division1: politicalDivision1?.id,
                    position: { latitude: lat, longitude: lng },
                    title: formatted_address,
                    extra_position_data: {
                        advanced: false,
                        external_house_number,
                        internal_house_number,
                        neighborhood,
                        street,
                    },
                };
                dispatch(savePosition(locationData, projectId));
            },
            (error) => {
                dispatch(projectLocationActions[FETCH_LOCATION_ERROR](error));
            },
        );
    };
