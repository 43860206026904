import React from 'react';

import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import { CardWrapper } from 'common/components/cards';

import EmptyTemplateList from 'resources/EmptyTemplateList.svg';

const StyledText = styled('div')`
    color: #202253;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.36px;
    line-height: 19px;
    text-align: center;
`;

const StyledImage = styled('img')`
    ${({ clickable }) => clickable && 'cursor: pointer;'}
    margin-bottom: 25px;
    max-width: 300px;
    width: 100%;
`;

const StyledContainer = styled('div')`
    width: 60%;
`;

const StyledEmptyModule = styled('div')`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
`;

const EmptyTemplate = ({
    onClick,
    text = i18next.t('Hey, select or add a page to get started!'),
}) => (
    <StyledEmptyModule>
        <StyledContainer>
            <CardWrapper>
                <StyledImage
                    src={EmptyTemplateList}
                    onClick={onClick}
                    clickable={onClick}
                />
                <StyledText>{text}</StyledText>
            </CardWrapper>
        </StyledContainer>
    </StyledEmptyModule>
);

EmptyTemplate.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
};

export default EmptyTemplate;
