import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const IconSaveEnergy = ({ color, height = '24px', width = '24px' }) => {
    const theme = useTheme();
    const fillColor = color || theme.palette.primary.main;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.56 5.23015L19.83 7.50015H21C21.55 7.50015 22 7.95015 22 8.50015V13.7401C22 14.1701 21.73 14.5501 21.32 14.6901L19.18 15.4101L17.93 19.5701C17.67 20.4201 16.89 21.0001 16.01 21.0001H14C12.9 21.0001 12 20.1001 12 19.0001H10C10 20.1001 9.1 21.0001 8 21.0001H6.01C5.11 21.0001 4.32 20.4001 4.08 19.5401C3.37 16.9801 2 11.7501 2 9.50015C2 6.46015 4.46 4.00015 7.5 4.00015H12.5C13.4 2.81015 14.81 2.03015 16.4 2.00015C17.14 1.99015 17.84 2.49015 17.97 3.21015C18.03 3.52015 17.99 3.82015 17.88 4.08015C17.74 4.42015 17.63 4.81015 17.56 5.23015ZM15 10.0001C15 10.5501 15.45 11.0001 16 11.0001C16.55 11.0001 17 10.5501 17 10.0001C17 9.45015 16.55 9.00015 16 9.00015C15.45 9.00015 15 9.45015 15 10.0001ZM6.2607 16.6886C6.14031 16.9138 6.24697 17.1909 6.48451 17.2773C6.62703 17.3292 6.78315 17.3015 6.89547 17.204C7.06342 17.0621 7.34594 16.8229 7.7433 16.4864C8.79014 15.5999 10.6341 14.0384 13.2803 11.8038C13.5457 11.5391 13.4433 11.3558 13.1719 11.257L10.7897 10.39L12.9666 6.30957C13.087 6.08439 12.9803 5.80731 12.7428 5.72086C12.6071 5.67145 12.4442 5.6967 12.3318 5.79416C11.0614 6.87521 9.93382 7.83214 8.94966 8.66735C7.7775 9.6621 6.80879 10.4842 6.04452 11.1377L6.04111 11.1401C5.99032 11.1759 5.48335 11.5329 6.06223 11.7436L8.43757 12.6082L6.2607 16.6886Z"
                fill={fillColor}
            />
        </svg>
    );
};

IconSaveEnergy.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconSaveEnergy;
