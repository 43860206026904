import { number, object, string } from 'yup';

import {
    DOMESTIC_RATES,
    RATES_WITH_POWER_FACTOR,
} from 'common/constants/rates';
import getValidationTexts from 'common/utils/getValidationTexts';

export default (values, { ratesDictionary }) => {
    const {
        getMaxValueText,
        getMinValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();

    const shape = {
        applied_bag: number()
            .typeError(NUMBER_TYPE_TEXT)
            .min(0, getMinValueText(0))
            .transform((value) => value || 0)
            .nullable(),
        contracted_demand: number()
            .typeError(NUMBER_TYPE_TEXT)
            .min(0, getMinValueText(0))
            .nullable(),
        exported_energy_price: number()
            .typeError(NUMBER_TYPE_TEXT)
            .min(0, getMinValueText(0))
            .nullable()
            .transform((value) => (!value && value !== 0 ? null : value)),
        file: string().nullable(),
        final_date: string().required(REQUIRED_TEXT),
        initial_date: string().required(REQUIRED_TEXT),
        netted_exported_generation_price: number()
            .typeError(NUMBER_TYPE_TEXT)
            .min(0, getMinValueText(0))
            .nullable()
            .transform((value) => (!value && value !== 0 ? null : value)),
        rate: string().required(REQUIRED_TEXT),
    };

    const selectedRate = ratesDictionary[values?.rate];

    if (selectedRate?.certified) {
        if (RATES_WITH_POWER_FACTOR.includes(selectedRate.name))
            shape.power_factor = number()
                .typeError(NUMBER_TYPE_TEXT)
                .min(0, getMinValueText(0))
                .max(100, getMaxValueText(100))
                .required(REQUIRED_TEXT);

        shape.is_bimonthly = string().required(REQUIRED_TEXT);

        if (selectedRate.name === 'DAC')
            shape.subsidy_rate = string().required(REQUIRED_TEXT);

        if (DOMESTIC_RATES.includes(selectedRate.name))
            shape.rate_division_summer = string().required(REQUIRED_TEXT);
    }
    return object().shape(shape);
};
