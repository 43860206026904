import API from '../..';
const ENTITY = 'proposals-plans';

export const createItem = (data) => API.post(`/api/v1/${ENTITY}/`, data);
export const deleteItem = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const filterItems = ({
    agentId = null,
    orderBy = null,
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/proposals-plans/`, {
        params: {
            agent: agentId,
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            sort: sortBy,
        },
    });
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, data);
