import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import regenerateTemplate from './regenerateTemplate';

export default (values) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Regenerate'),
        messages: [
            i18next.t('Regenerating the template will restore all changes'),
        ],
        onSuccess: () => {
            dispatch(regenerateTemplate(values));
        },
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
    };

    dispatch(alerts.actions.show(dataAlert));
};
