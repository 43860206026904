import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import get from 'lodash/get';

import { PREFIX } from 'common/sockets/constants';
import { generateSocketActions } from 'common/sockets/helpers';

import {
    BRANCH_OFFICES_DICTIONARY_BACKUP,
    FETCH_FUNNEL_PROJECTS,
    FETCH_FUNNEL_PROJECTS_FAILURE,
    FETCH_FUNNEL_PROJECTS_SUCCESS,
    FETCH_NEXT_PAGE,
    FETCH_NEXT_PAGE_FAILURE,
    FETCH_NEXT_PAGE_SUCCESS,
    UPDATE_COLUMNS,
    UPDATE_COLUMNS_FAILURE,
    UPDATE_COLUMNS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';
import {
    addProjectColumnOnSocket,
    deleteProjectColumnOnSocket,
    orderProjectColumnOnSocket,
    orderProjectStatusOnSocket,
} from './helpers';

const INITIAL_STATE = {
    branchOfficesDictionaryBackup: {},
    filterQuery: '',
    funnelColumns: {
        data: {},
        errors: [],
        isFetching: true,
    },
    nextPages: {
        errors: [],
        ids: {},
        isFetching: false,
    },
    refreshingIsNeeded: false,
    socketConnected: false,
    socketError: null,
    updatingStatusProject: null,
};

const {
    BROKEN_SOCKET,
    CLOSED_SOCKET,
    ERROR_SOCKET,
    MESSAGE_SOCKET,
    OPEN_SOCKET,
    RECONNECTED,
} = generateSocketActions(PREFIX);

const funnelProjectsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BRANCH_OFFICES_DICTIONARY_BACKUP]: (state, action) => {
            state.branchOfficesDictionaryBackup = action.payload;
        },
        [FETCH_FUNNEL_PROJECTS]: (state) => {
            state.funnelColumns = {
                ...state.funnelColumns,
                errors: [],
                isFetching: true,
            };
        },
        [UPDATE_COLUMNS]: (state, action) => {
            const { funnelColumnsOrdered, projectId } = action.payload;

            state.funnelColumns = {
                ...state.funnelColumns,
                data: funnelColumnsOrdered,
                isFetching: false,
            };
            state.updatingStatusProject = {
                funnelColumns: { ...state.funnelColumns.data },
                projectId,
            };
        },
        [UPDATE_COLUMNS_FAILURE]: (state, action) => {
            let restoredFunnelColumns = {
                ...state.updatingStatusProject.funnelColumns,
            };

            state.funnelColumns = {
                ...state.funnelColumns,
                data: restoredFunnelColumns,
                errors: action.payload.errors,
                isFetching: false,
            };
            state.updatingStatusProject = null;
        },
        [UPDATE_COLUMNS_SUCCESS]: (state) => {
            state.updatingStatusProject = null;
        },
        [FETCH_FUNNEL_PROJECTS_FAILURE]: (state, action) => {
            state.funnelColumns = {
                ...state.funnelColumns,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FUNNEL_PROJECTS_SUCCESS]: (state, action) => {
            state.funnelColumns = {
                ...state.funnelColumns,
                data: action.payload.funnelProjects,
                isFetching: false,
            };
            state.filterQuery = action.payload.filterQuery;
        },
        [FETCH_NEXT_PAGE]: (state, action) => {
            state.nextPages = {
                ...state.nextPages,
                errors: [],
                ids: { ...state.nextPages.ids, [action.payload.id]: true },
                isFetching: true,
            };
        },
        [FETCH_NEXT_PAGE_FAILURE]: (state, action) => {
            let newFunnelColumns2 = { ...state.funnelColumns.data };
            newFunnelColumns2[action.payload.id].nextProject = null;

            state.nextPages = {
                ...state.nextPages,
                errors: action.payload.error,
                ids: { ...state.nextPages.ids, [action.payload.id]: false },
                isFetching: false,
            };
            state.funnelColumns = {
                ...state.funnelColumns,
                data: newFunnelColumns2,
            };
        },
        [FETCH_NEXT_PAGE_SUCCESS]: (state, action) => {
            let newFunnelColumns = { ...state.funnelColumns.data };
            const { nextPage, id, projects } = action.payload;
            newFunnelColumns[id].projects = [
                ...newFunnelColumns[id].projects,
                ...projects,
            ];
            newFunnelColumns[id].nextPage = nextPage;

            state.nextPages = {
                ...state.nextPages,
                ids: { ...state.nextPages.ids, [id]: false },
                isFetching: false,
            };
            state.funnelColumns = {
                ...state.funnelColumns,
                data: newFunnelColumns,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(BROKEN_SOCKET, (state) => {
                state.socketError = i18next.t(
                    'Error sending data. Restart your session or try again later',
                );
            })
            .addCase(ERROR_SOCKET, (state) => {
                state.socketError = i18next.t('Server error. Try again later');
            })
            .addCase(RECONNECTED, (state) => {
                state.socketError = null;
            })
            .addCase(CLOSED_SOCKET, (state) => {
                state.socketConnected = false;
            })
            .addCase(OPEN_SOCKET, (state) => {
                state.socketConnected = true;
            })
            .addCase(MESSAGE_SOCKET, (state, action) => {
                const { message = {} } = JSON.parse(action.payload.message);
                if (!get(message, 'success', true) || message.errors) {
                    state.socketError = i18next.t(
                        'Server error. Try again later',
                    );
                    return;
                }
                const { data = {} } = message;
                if (data.model !== 'funnel') return;
                const contactBranchOffice = get(
                    data,
                    'contact.branch_office',
                    {},
                );
                const contactBranchOfficeId =
                    contactBranchOffice === null ||
                    contactBranchOffice.id === null
                        ? 'company'
                        : contactBranchOffice.id;

                if (
                    !contactBranchOfficeId ||
                    !state.branchOfficesDictionaryBackup ||
                    state.branchOfficesDictionaryBackup[contactBranchOfficeId]
                ) {
                    switch (data.action) {
                        case 'new_project': {
                            const newProjectData = addProjectColumnOnSocket(
                                state.funnelColumns.data,
                                data,
                            );
                            if (newProjectData === null) break;
                            state.funnelColumns = {
                                ...state.funnelColumns,
                                data: newProjectData,
                                isFetching: false,
                            };
                            break;
                        }
                        case 'delete_project': {
                            const deleteProjectData =
                                deleteProjectColumnOnSocket(
                                    state.funnelColumns.data,
                                    data,
                                );
                            if (deleteProjectData === null) break;
                            state.funnelColumns = {
                                ...state.funnelColumns,
                                data: deleteProjectData,
                                isFetching: false,
                            };
                            break;
                        }

                        case 'refresh_catalog': {
                            state.refreshingIsNeeded = true;
                            break;
                        }
                        case 'refresh_project_status': {
                            const orderProjectStatusData =
                                orderProjectStatusOnSocket(
                                    state.funnelColumns.data,
                                    data,
                                    state.filterQuery,
                                );
                            if (orderProjectStatusData === null) break;

                            state.funnelColumns = {
                                ...state.funnelColumns,
                                data: { ...orderProjectStatusData },
                                isFetching: false,
                            };
                            break;
                        }
                        case 'refresh_project': {
                            if (
                                state.updatingStatusProject !== null &&
                                state.updatingStatusProject.projectId ===
                                    data.id
                            ) {
                                state.updatingStatusProject = null;
                                break;
                            }

                            const orderProjectData = orderProjectColumnOnSocket(
                                state.funnelColumns.data,
                                data,
                            );
                            if (orderProjectData === null) break;

                            state.funnelColumns = {
                                ...state.funnelColumns,
                                data: { ...orderProjectData },
                                isFetching: false,
                            };
                            break;
                        }
                        default:
                            break;
                    }
                }
            });
    },
});

export const funnelProjectsActions = funnelProjectsSlice.actions;

export default funnelProjectsSlice.reducer;
