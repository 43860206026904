import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const IconTemplate = ({ color, height = '20px', width = '16px' }) => {
    const theme = useTheme();
    const fillColor = color || theme.palette.primary.main;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.08181 11.1849L7.17843 12.0882L6.29427 11.2041L7.19765 10.3007L8.08181 11.1849Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 0C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6.83C16 6.3 15.79 5.79 15.41 5.42L10.58 0.59C10.21 0.21 9.7 0 9.17 0H2ZM3.18051 8.75019L2.86657 8.07105L2.18743 7.75711C1.93756 7.64178 1.93756 7.2894 2.18743 7.17408L2.86657 6.86013L3.18051 6.1874C3.29583 5.93753 3.64822 5.93753 3.76354 6.1874L4.07748 6.86654L4.75021 7.18048C5.00009 7.29581 5.00009 7.64819 4.75021 7.76352L4.07108 8.07746L3.75713 8.75019C3.64822 9.00006 3.28943 9.00006 3.18051 8.75019ZM10.2346 8.07105L10.5485 8.75019C10.6574 9.00006 11.0162 9.00006 11.1251 8.75019L11.4391 8.07746L12.1182 7.76352C12.3681 7.64819 12.3681 7.29581 12.1182 7.18048L11.4391 6.86654L11.1251 6.1874C11.0162 5.93753 10.6574 5.93753 10.5485 6.1874L10.2346 6.86654L9.55544 7.17408C9.30556 7.2894 9.30556 7.64178 9.55544 7.75711L10.2346 8.07105ZM3.75713 13.5556L4.07108 14.2347L4.75021 14.5487C5.00009 14.664 5.00009 15.0164 4.75021 15.1317L4.07108 15.4456L3.75713 16.1248C3.64181 16.3747 3.28943 16.3747 3.1741 16.1248L2.86016 15.4456L2.18743 15.1253C1.93756 15.01 1.93756 14.6576 2.18743 14.5423L2.86657 14.2283L3.18051 13.5492C3.28943 13.3057 3.64822 13.3057 3.75713 13.5556ZM6.74916 8.93602L4.93599 10.7492C4.68612 10.9991 4.68612 11.4091 4.9424 11.6526L12.099 18.8091C12.3488 19.059 12.7525 19.059 13.0024 18.8091L14.8155 16.996C15.0654 16.7461 15.0654 16.3425 14.8155 16.0926L7.65254 8.93602C7.40267 8.68614 6.99903 8.68614 6.74916 8.93602Z"
                fill={fillColor}
            />
        </svg>
    );
};

IconTemplate.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconTemplate;
