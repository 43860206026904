import React, { useEffect } from 'react';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Card, Typography } from 'sunwise-ui';

import {
    ReactHookFormInputDatePicker,
    ReactHookFormSelect2,
    ReactHookFormSlider,
} from 'common/components/form/bootstrap';

import * as actions from '../actions';
import { timeZones } from '../constants';
import * as selectors from '../selectors';

const ShaderSettingsForm = ({
    initialValues,
    isMobile,
    onCloseDrawer,
    save,
    simulationStatus,
}) => {
    const { t } = useTranslation();
    const { isPlaying } = simulationStatus;

    const { control, handleSubmit, reset, getValues, watch } = useForm({
        defaultValues: initialValues,
    });

    const hour = watch('hour');

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnSave = (values, e) => {
        const {
            nativeEvent: { pointerType },
        } = e;

        if (pointerType === '') return;

        save({ ...values, isPlaying: !isPlaying });

        if (isMobile && !isPlaying) {
            onCloseDrawer();
        }
    };

    const handleOnSliderChange = () => {
        const values = getValues();
        save({ ...values, isPlaying: false });
    };

    const handleOnChangeValues = () => {
        const values = getValues();
        save(values);
    };

    return (
        <>
            <Typography
                fontWeight="bold"
                sx={{ display: 'flex', gap: 1, mb: 2 }}
                variant="body2"
            >
                <Brightness4Icon /> {t('Shadow')}
            </Typography>

            <Card>
                <Card.Body>
                    <form>
                        <ReactHookFormInputDatePicker
                            control={control}
                            disabled={isPlaying}
                            label={t('Date')}
                            name="date"
                            onChange={handleOnChangeValues}
                        />

                        <ReactHookFormSelect2
                            control={control}
                            disabled={isPlaying}
                            isClearable={false}
                            name="timezone"
                            label={t('Timezone')}
                            onChange={handleOnChangeValues}
                            options={[
                                {
                                    label: '',
                                    options: timeZones.map((timezone) => ({
                                        label: `(UTC${
                                            timezone.value >= 0
                                                ? `+${timezone.value}`
                                                : timezone.value
                                        } ) ${timezone.label}`,
                                        value: `${timezone.value}`,
                                    })),
                                },
                            ]}
                        />

                        <Typography sx={{ textAlign: 'right' }}>
                            <small>{hour}:00 hrs</small>
                        </Typography>

                        <ReactHookFormSlider
                            control={control}
                            disabled={isPlaying}
                            name="hour"
                            min={5}
                            max={21}
                            marks
                            onChange={handleOnSliderChange}
                            size="small"
                        />

                        <Button
                            endIcon={
                                !isPlaying ? (
                                    <PlayCircleOutlineIcon />
                                ) : (
                                    <PauseCircleOutlineIcon />
                                )
                            }
                            fullWidth
                            sx={{ '&.MuiButton-root': { mb: 0 } }}
                            onClick={handleSubmit(handleOnSave)}
                            variant="outlined"
                        >
                            {t('Simulate shadows')}{' '}
                        </Button>
                    </form>
                </Card.Body>
            </Card>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getShaderSetttingsInitialValues,
    simulationStatus: selectors.getSimulationStatus,
});

const mapDispatchToProps = (dispatch) => ({
    save: (config) => dispatch(actions.saveShaderSettings(config)),
});

ShaderSettingsForm.propTypes = {
    initialValues: PropTypes.object,
    isMobile: PropTypes.bool,
    onCloseDrawer: PropTypes.func,
    save: PropTypes.func,
    simulationStatus: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShaderSettingsForm);
