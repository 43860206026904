import { object, string, boolean } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { LANGUAGE_REQUIRED_TEXT, REQUIRED_TEXT, TIMEZONE_REQUIRED_TEXT } =
        getValidationTexts();

    return object().shape({
        auto_timezone: boolean().required(REQUIRED_TEXT).nullable(),
        language: string().required(LANGUAGE_REQUIRED_TEXT).nullable(),
        timezone: string()
            .when('auto_timezone', {
                is: false,
                then: () => string().required(TIMEZONE_REQUIRED_TEXT),
            })
            .nullable(),
    });
};
