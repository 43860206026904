import React, { useEffect, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import ShareIcon from '@mui/icons-material/Share';
import { styled } from '@mui/material/styles';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RWebShare } from 'react-web-share';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Card,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { SHARED_OPTIONS } from 'common/constants';

import * as actions from '../actions';
import { getDownloadOptions, getShortedLink } from '../helpers';
import * as selectors from '../selectors';

const TextFieldLink = styled(TextField)(
    ({ theme }) => `
    background: ${theme.palette.mode === 'dark' ? '#282b30' : '#eef4fa'};
    border-radius: 8px;
    & .Mui-disabled {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
    }
    & .MuiOutlinedInput-notchedOutline {
        border: 0 !important;
    }
`,
);

const ShareCard = ({
    canModify,
    downloadFormat,
    handleClickDownload,
    hasProjectProfiles,
    isApproved,
    isDisabled,
    isEnablePermalink,
    permalink,
    prepareRevokeApproval,
    proposalId,
    proposalName,
    selectedDocument,
    setDownloadFormat,
    setEnablePermalink,
}) => {
    const { t } = useTranslation();
    const [count, setCount] = useState(0);
    const [shiftHeld, setShiftHeld] = useState(false);

    const handleChange = (event) => {
        localStorage.setItem('downloadFormat', event.target.value);
        setDownloadFormat(event.target.value);
        event.preventDefault();
    };

    const options = getDownloadOptions(
        count,
        hasProjectProfiles,
        selectedDocument,
    );

    const downHandler = ({ key }) => {
        if (key === 'Shift') setShiftHeld(true);
    };

    const upHandler = ({ key }) => {
        if (key === 'Shift') setShiftHeld(false);
    };

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, []);

    return (
        <Card sx={{ mb: '16px !important' }}>
            <Card.Header
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    p: '8px 16px !important',
                }}
            >
                <SendIcon sx={{ color: 'primary.main' }} />

                <Typography variant="body2" ml={1} fontWeight="bold">
                    {t('Share')}
                </Typography>

                <DropdownCardMenu
                    items={[
                        {
                            disabled: !canModify,
                            handleClick: () =>
                                prepareRevokeApproval(proposalId),
                            label: t('Revoke approval'),
                        },
                    ]}
                    sxIconButton={{ ml: 'auto' }}
                    visible={isApproved}
                />
            </Card.Header>

            <Card.Body sx={{ pt: 1 }}>
                {!isNull(permalink) && (
                    <>
                        <Grid container spacing={0}>
                            <Grid
                                item
                                sx={{ alignItems: 'center', display: 'flex' }}
                                xs={18}
                            >
                                <Typography
                                    className="__userguiding_share_interactive_link"
                                    variant="body2"
                                >
                                    {t('Interactive link')}
                                </Typography>

                                <Box sx={{ ml: 'auto' }}>
                                    <Switch
                                        checked={isEnablePermalink}
                                        disabled={
                                            isApproved ||
                                            !canModify ||
                                            isDisabled
                                        }
                                        onChange={() =>
                                            setEnablePermalink(
                                                proposalId,
                                                !isEnablePermalink,
                                            )
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={0} mt={1}>
                            <Grid
                                item
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    gap: 1,
                                }}
                                xs={18}
                            >
                                <TextFieldLink
                                    disabled
                                    fontWeight="bold"
                                    fullWidth
                                    onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                    value={getShortedLink(permalink)}
                                />

                                <RWebShare
                                    closeText={t('Close')}
                                    data={{
                                        text: proposalName,
                                        title: proposalName,
                                        url: permalink,
                                    }}
                                    disableNative
                                    sites={SHARED_OPTIONS}
                                >
                                    <IconButton
                                        disabled={!isEnablePermalink}
                                        sx={{ ml: 'auto' }}
                                    >
                                        <ShareIcon />
                                    </IconButton>
                                </RWebShare>
                            </Grid>
                        </Grid>
                    </>
                )}

                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 1,
                        mt: 3,
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel id="format-select-label">
                            {t('Download format', { context: 'noun' })}
                        </InputLabel>

                        <Select
                            onChange={handleChange}
                            onOpen={() =>
                                shiftHeld ? setCount((prev) => prev + 1) : {}
                            }
                            sx={{ fontSize: '0.875rem' }}
                            value={downloadFormat}
                            variant="standard"
                        >
                            {options
                                .filter(({ visible = true }) => visible)
                                .map((option) => (
                                    <MenuItem
                                        disabled={option.disabled}
                                        key={`download-item-${option.value}`}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>

                    <IconButton
                        className="__userguiding_generate_proposal_pdf_button"
                        color="primary"
                        disabled={!downloadFormat}
                        onClick={() => handleClickDownload()}
                        variant="default"
                    >
                        <DownloadIcon />
                    </IconButton>
                </Box>
            </Card.Body>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    customTemplateId: selectors.getCustomTemplateId,
});

const mapDispatchToProps = (dispatch) => ({
    prepareRevokeApproval: (proposalId) =>
        dispatch(actions.prepareRevokeApproval(proposalId)),
    setEnablePermalink: (offerId, value) =>
        dispatch(actions.setEnablePermalink(offerId, value)),
});

ShareCard.propTypes = {
    canModify: PropTypes.bool,
    downloadFormat: PropTypes.number,
    handleClickDownload: PropTypes.func,
    hasProjectProfiles: PropTypes.bool,
    isApproved: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isEnablePermalink: PropTypes.bool,
    permalink: PropTypes.string,
    prepareRevokeApproval: PropTypes.func,
    proposalId: PropTypes.string,
    proposalName: PropTypes.string,
    selectedDocument: PropTypes.object,
    setDownloadFormat: PropTypes.func,
    setEnablePermalink: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareCard);
