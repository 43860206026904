import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    FETCH_CATALOG_REQUIREMENTS,
    FETCH_CATALOG_REQUIREMENTS_FAILURE,
    FETCH_CATALOG_REQUIREMENTS_SUCCESS,
    FETCH_FINANCIER_INSTALLER_DOCUMENTS,
    FETCH_FINANCIER_INSTALLER_DOCUMENTS_FAILURE,
    FETCH_FINANCIER_INSTALLER_DOCUMENTS_SUCCESS,
    FETCH_FINANCIER_PRODUCT,
    FETCH_FINANCIER_PRODUCT_FAILURE,
    FETCH_FINANCIER_PRODUCT_SUCCESS,
    FETCH_FINANCIERS_INSTALLER,
    FETCH_FINANCIERS_INSTALLER_FAILURE,
    FETCH_FINANCIERS_INSTALLER_SUCCESS,
    FETCH_FINANCIERS,
    FETCH_FINANCIERS_FAILURE,
    FETCH_FINANCIERS_SUCCESS,
    FETCH_REQUIREMENTS,
    FETCH_REQUIREMENTS_FAILURE,
    FETCH_REQUIREMENTS_SUCCESS,
    FETCH_TEMPLATE_CATALOGS,
    FETCH_TEMPLATE_CATALOGS_FAILURE,
    FETCH_TEMPLATE_CATALOGS_SUCCESS,
    INITIALIZE_FORM,
    MODAL_PRODUCT_STATUS,
    RESET_FORM,
    SAVE_FINANCIERS_INSTALLER,
    SAVE_FINANCIERS_INSTALLER_FAILURE,
    SAVE_FINANCIERS_INSTALLER_SUCCESS,
    TOGGLE_PRODUCT_ENABLE,
    TOGGLE_PRODUCT_ENABLE_FAILURE,
    TOGGLE_PRODUCT_ENABLE_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    bulkUpdateItems: {
        data: [],
        errors: null,
        isFetching: false,
    },
    catalogRequirements: {
        data: null,
        errors: null,
        isFetching: true,
    },
    fetchTemplateCatalogs: {
        isFetching: true,
        data: [],
        errors: [],
    },
    fileUploaded: {
        data: {},
        errors: [],
        isUploaing: false,
    },
    financierInstallerDocuments: {
        data: {
            financier_installer_document: [],
            financier_product: [],
        },
        errors: null,
        isFetching: true,
    },
    financierProduct: {
        data: {
            data: [],
            pagination: null,
        },
        errors: null,
        isFetching: false,
    },
    financiers: {
        data: [],
        errors: null,
        isFetching: true,
    },
    financiersInstaller: {
        data: [],
        errors: null,
        isFetching: true,
    },
    initialValues: {
        administration_commission: {
            isChecked: false,
            unit: 0,
            value: 0,
        },
        annual_insurance: {
            isChecked: false,
            unit: 0,
            value: 0,
        },
        blind_discount: {
            isChecked: false,
            unit: 0,
            value: 0,
        },
        cfe_month_percentage: 50,
        fiscal: 0,
        increase_percentage_cfe: {
            max: 50,
            min: 50,
        },
        insurance: [],
        interest_rate: {
            isChecked: false,
            unit: 0,
            value: 0,
        },
        irradiation_percentage: {
            max: 50,
            min: 50,
        },
        is_serfimex: false,
        limit_amount: {
            isChecked: false,
            max_value: 0,
            min_value: 0,
            unit: 0,
        },
        lost_support: {
            isChecked: false,
            unit: 0,
            value: 0,
        },
        module_degradation_percentage: {
            max: 50,
            min: 50,
        },
        opening_commission: {
            isChecked: false,
            unit: 0,
            value: 0,
        },
        operation_maintenance: {
            isChecked: false,
            unit: 0,
            value: 0,
        },
        parameters_commission_credit: {
            isChecked: false,
            unit: 0,
            value: 0,
            opening_commission_vat: 0,
        },
        parameters_hitch: {
            isChecked: false,
            unit: 0,
            value: 0,
        },
        percentages: {
            accesories: {
                enabled: false,
                value: 0,
            },
            additionals: {
                enabled: false,
                value: 0,
            },
            inverters: {
                enabled: false,
                value: 0,
            },
            manufacture: {
                enabled: false,
                value: 0,
            },
            panels: {
                enabled: false,
                value: 0,
            },
            structures: {
                enabled: false,
                value: 0,
            },
        },
        power_limit: {
            isChecked: false,
            max_value: 0,
            min_value: 0,
            unit: 0,
        },
        power_ranges: [
            {
                max: 1000,
                min: 0,
                price: 0,
            },
        ],
        power_ranges_enabled: false,
        product_information: '',
        roi: {
            enabled: false,
            max: 50,
            min: 50,
        },
        selected_earnings: {
            manual: true,
        },
        selected_rates: {},
        terms: {
            isChecked: false,
            minimum_hitch: 0,
            minimum_residual: 0,
            unit: 0,
        },
        type_term_range: [
            {
                from: 1,
                to: 1,
            },
        ],
        vat_interest: 0,
    },
    product: {
        data: {},
        errors: [],
        isFetching: true,
    },
    productEnableUpdating: null,
    products: {
        data: [],
        errors: [],
        isFetching: false,
    },
    requirements: {
        data: null,
        errors: null,
        isFetching: true,
    },
    saveFinanciersInstaller: {
        data: [],
        errors: null,
        isSaving: false,
    },
    termInitialValues: {
        company_registered: false,
        financier: null,
    },
    toggleProduct: {
        data: {},
        errors: [],
        isUpdating: false,
    },
};

const alliancesSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isFetching: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CATALOG_REQUIREMENTS]: (state) => {
            state.catalogRequirements = {
                ...state.catalogRequirements,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CATALOG_REQUIREMENTS_FAILURE]: (state, action) => {
            state.catalogRequirements = {
                ...state.catalogRequirements,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CATALOG_REQUIREMENTS_SUCCESS]: (state, action) => {
            state.catalogRequirements = {
                ...state.catalogRequirements,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIER_INSTALLER_DOCUMENTS]: (state) => {
            state.financierInstallerDocuments = {
                ...state.financierInstallerDocuments,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FINANCIER_INSTALLER_DOCUMENTS_FAILURE]: (state, action) => {
            state.financierInstallerDocuments = {
                ...state.financierInstallerDocuments,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIER_INSTALLER_DOCUMENTS_SUCCESS]: (state, action) => {
            state.financierInstallerDocuments = {
                ...state.financierInstallerDocuments,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIER_PRODUCT]: (state) => {
            state.financierProduct = {
                ...state.financierProduct,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FINANCIER_PRODUCT_FAILURE]: (state, action) => {
            state.financierProduct = {
                ...state.financierProduct,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIER_PRODUCT_SUCCESS]: (state, action) => {
            state.financierProduct = {
                ...state.financierProduct,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIERS]: (state) => {
            state.financiers = {
                ...state.financiers,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FINANCIERS_FAILURE]: (state, action) => {
            state.financiers = {
                ...state.financiers,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIERS_SUCCESS]: (state, action) => {
            state.financiers = {
                ...state.financiers,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIERS_INSTALLER]: (state) => {
            state.financiersInstaller = {
                ...state.financiersInstaller,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FINANCIERS_INSTALLER_FAILURE]: (state, action) => {
            state.financiersInstaller = {
                ...state.financiersInstaller,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIERS_INSTALLER_SUCCESS]: (state, action) => {
            state.financiersInstaller = {
                ...state.financiersInstaller,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUIREMENTS]: (state) => {
            state.requirements = {
                ...state.requirements,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_REQUIREMENTS_FAILURE]: (state, action) => {
            state.requirements = {
                ...state.requirements,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUIREMENTS_SUCCESS]: (state, action) => {
            state.requirements = {
                ...state.requirements,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_CATALOGS]: (state) => {
            state.fetchTemplateCatalogs.isFetching = true;
        },
        [FETCH_TEMPLATE_CATALOGS_FAILURE]: (state, action) => {
            state.fetchTemplateCatalogs = {
                ...state.fetchTemplateCatalogs,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_CATALOGS_SUCCESS]: (state, action) => {
            state.fetchTemplateCatalogs = {
                ...state.fetchTemplateCatalogs,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [MODAL_PRODUCT_STATUS]: (state, action) => {
            state.productModalIsOpen = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_FINANCIERS_INSTALLER]: (state) => {
            state.saveFinanciersInstaller = {
                ...state.saveFinanciersInstaller,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_FINANCIERS_INSTALLER_FAILURE]: (state, action) => {
            state.saveFinanciersInstaller = {
                ...state.saveFinanciersInstaller,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_FINANCIERS_INSTALLER_SUCCESS]: (state, action) => {
            state.saveFinanciersInstaller = {
                ...state.financiersInstaller,
                data: action.payload,
                isSaving: false,
            };
        },
        [TOGGLE_PRODUCT_ENABLE]: (state) => {
            state.toggleProduct = {
                ...state.toggleProduct,
                errors: [],
                isUpdating: true,
            };
        },
        [TOGGLE_PRODUCT_ENABLE_FAILURE]: (state, action) => {
            state.toggleProduct = {
                ...state.toggleProduct,
                errors: action.payload,
                isUpdating: false,
            };
        },
        [TOGGLE_PRODUCT_ENABLE_SUCCESS]: (state, action) => {
            state.toggleProduct = {
                ...state.toggleProduct,
                data: action.payload,
                isUpdating: false,
            };
        },
        [UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.financierProduct.data.data = action.payload;
        },
        [UPLOAD_DOCUMENT]: (state) => {
            state.fileUploaded = {
                ...state.fileUploaded,
                errors: [],
                isUploaing: true,
            };
        },
        [UPLOAD_DOCUMENT_FAILURE]: (state, action) => {
            state.fileUploaded = {
                ...state.fileUploaded,
                errors: action.payload,
                isUploaing: false,
            };
        },
        [UPLOAD_DOCUMENT_SUCCESS]: (state, action) => {
            state.financierInstallerDocuments.data.financier_installer_document[
                action.payload.index
            ] = {
                ...state.financierInstallerDocuments.data
                    .financier_installer_document[action.payload.index],
                file: action.payload.file,
                status: action.payload.data.status,
            };
            state.fileUploaded = {
                ...state.fileUploaded,
                data: action.payload.data,
                isUploaing: false,
            };
        },
    },
});

export const alliancesActions = alliancesSlice.actions;

export default alliancesSlice.reducer;
