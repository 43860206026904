import { bool, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';
import { locationRegex } from 'common/utils/helpers';

export default () => {
    const { maximumNumberCharacters, REQUIRED_TEXT, VALID_LOCATION } =
        getValidationTexts();

    return object().shape({
        extra_position_data: object()
            .shape({
                advanced: bool().nullable(),
                external_house_number: string()
                    .nullable()
                    .max(20, maximumNumberCharacters(20)),
                internal_house_number: string()
                    .nullable()
                    .max(20, maximumNumberCharacters(20)),
                neighborhood: string()
                    .nullable()
                    .max(50, maximumNumberCharacters(50)),
                street: string()
                    .nullable()
                    .max(50, maximumNumberCharacters(50)),
            })
            .nullable(),
        position: object().shape({
            latlng: string().required(REQUIRED_TEXT).matches(locationRegex, {
                message: VALID_LOCATION,
            }),
        }),
        title: string().required(REQUIRED_TEXT).nullable(),
    });
};
