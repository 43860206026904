import React from 'react';

import { get, isEmpty, isNull, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import BranchOfficeFormatter from 'common/modules/multiBranches/components/BranchOfficeFormatter';
import { formatDistanceDate, parseDate } from 'common/utils/dates';

import DateFormatter from '../../followUps/components/DateFormatter';

import ActionsFormatter from './ActionsFormatter';

const Columns = ({
    canDelete,
    canModify,
    filterData,
    handleClickBulkItems,
    hiddenColumns,
    orders,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const nameColumn = [
        {
            align: 'left',
            editable: canModify,
            field: 'name',
            flex: 1,
            headerName: isEmpty(rowModesModel)
                ? t('Contact')
                : `${t('Name', { count: 2 })}`,
            hide: hiddenColumns.includes('name'),
            minWidth: 180,
            order: orders?.name,
            renderCell: ({ row }) => {
                const { phone_key, telephone } = get(row, 'telephones[0]', {});
                return (
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Link
                            component={RouterLink}
                            to={`/record/${row.id}?tab=projects`}
                            color="secondary"
                        >
                            <Typography variant="caption" fontWeight="bold">
                                {`${row.name} ${row.first_lastname} ${row.second_lastname}`}
                            </Typography>
                        </Link>
                        <Typography variant="caption" fontWeight="bold">
                            {phone_key} {telephone}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            align: 'left',
            editable: canModify,
            field: 'first_lastname',
            flex: 1,
            headerName: t('Last name'),
            hide: isEmpty(rowModesModel),
            minWidth: 180,
            order: orders?.name,
        },
        {
            align: 'left',
            editable: canModify,
            field: 'second_lastname',
            flex: 1,
            headerName: t('Second last name'),
            hide: isEmpty(rowModesModel),
            minWidth: 180,
            order: orders?.name,
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            field: 'tracking',
            flex: 1,
            headerName: t('Follow-up'),
            hide: !isEmpty(rowModesModel) || hiddenColumns.includes('tracking'),
            minWidth: 180,
            order: orders?.tracking,
            renderCell: ({ row }) => {
                const tracking = get(row, 'contacts_trackings[0]', null);
                if (!tracking) return null;
                return <DateFormatter row={tracking} />;
            },
        },
        {
            align: 'left',
            editable: canModify,
            field: 'email',
            flex: 1,
            headerName: t('Mail'),
            hide: hiddenColumns.includes('email'),
            minWidth: 250,
            order: orders?.email,
            renderCell: ({ row }) => {
                const { email } = get(row, 'emails[0]', {});
                return (
                    <Typography variant="caption" fontWeight="bold">
                        {email}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: 'agent',
            flex: 1,
            headerName: t('Solar advisor'),
            hide: !isEmpty(rowModesModel) || hiddenColumns.includes('agent'),
            minWidth: 180,
            order: orders?.agent,
            renderCell: (params) => {
                const { row } = params;
                if (isNull(row?.agent)) return t('No advisor assigned');
                return `${row?.agent?.first_name} ${row?.agent?.last_name}`;
            },
        },
        {
            align: 'left',
            field: 'status_contact',
            flex: 1,
            headerName: t('Type of contact'),
            hide:
                !isEmpty(rowModesModel) ||
                hiddenColumns.includes('status_contact'),
            minWidth: 180,
            order: orders?.status_contact,
            valueGetter: (params) => params.row?.status_contact?.name,
        },
        {
            align: 'left',
            field: 'contact_origin',
            flex: 1,
            headerName: t('Source'),
            hide:
                !isEmpty(rowModesModel) ||
                hiddenColumns.includes('contact_origin'),
            minWidth: 180,
            order: orders?.contact_origin,
            valueGetter: (params) => params.row?.contact_origin?.name,
        },
        {
            align: 'left',
            field: 'company_name',
            flex: 1,
            headerName: t('Business'),
            hide:
                !isEmpty(rowModesModel) ||
                hiddenColumns.includes('company_name'),
            minWidth: 180,
            order: orders?.company_name,
        },
        {
            align: 'left',
            field: 'branch_name',
            flex: 1,
            headerName: t('Branch office'),
            hide:
                !isEmpty(rowModesModel) ||
                hiddenColumns.includes('branch_name'),
            minWidth: 180,
            order: orders?.branch_name,
            renderCell: ({ row }) =>
                BranchOfficeFormatter(row.branch_office, row),
        },
        {
            align: 'left',
            field: 'created_at',
            flex: 1,
            headerName: t('Oldest'),
            hide:
                !isEmpty(rowModesModel) || hiddenColumns.includes('created_at'),
            minWidth: 180,
            order: orders?.created_at,
            renderCell: ({ row }) => {
                if (row?.created_at)
                    return formatDistanceDate(
                        parseDate(row?.created_at, 'dd/MM/yyyy HH:mm:ss'),
                        new Date(),
                        { addSuffix: true },
                    );

                return null;
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    columns = [
        ...columns,
        {
            align: 'left',
            field: '',
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <ActionsFormatter
                        canDelete={canDelete}
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
        },
    ];

    return orderBy(columns, ['order'], ['asc']);
};

Columns.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    hiddenColumns: PropTypes.array,
    orders: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default Columns;
