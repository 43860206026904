import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import Form from './components/Form';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    isNewRecord,
    isOpenModal,
    resetForm,
    showModal,
    handleAfterSave,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={() => {
                showModal(false);
                resetForm();
            }}
            open={isOpenModal}
            size="md"
            title={`${isNewRecord ? t('Add') : t('Update')} ${t(
                'Contact',
            ).toLowerCase()}`}
        >
            <Form
                canDelete={canDelete}
                canModify={canModify}
                handleAfterSave={handleAfterSave}
                isNewRecord={isNewRecord}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    dataDuplicateModal: selectors.getDataDuplicateModal,
    isNewRecord: selectors.getIsNewRecord,
    isOpenDuplicateModal: selectors.getIsOpenDuplicateModal,
    isOpenModal: selectors.getIsOpenModal,
});

const mapDispatchToProps = (dispatch) => ({
    resetDataDuplicateModal: () => dispatch(actions.resetDataDuplicateModal()),
    resetForm: () => dispatch(actions.resetForm()),
    showModal: (isOpen) => dispatch(actions.showModal(isOpen)),
    openDuplicateModal: (value) => dispatch(actions.openDuplicateModal(value)),
});

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleAfterSave: PropTypes.func,
    isNewRecord: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    resetForm: PropTypes.func,
    showModal: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.CONTACTS_PERMISSION),
)(Container);
