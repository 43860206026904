export const NAME = 'follow-ups';
export const COLUMNS_CONFIG = {
    status: {
        label: 'Status',
        is_active: true,
        required: false,
    },
    description: {
        label: 'Follow-up',
        is_active: true,
        required: true,
    },
    contact: {
        label: 'Contact',
        is_active: true,
        required: false,
    },
    type: {
        label: 'Type',
        is_active: true,
        required: false,
    },
    objective: {
        label: 'Objective',
        is_active: true,
        required: false,
    },
    user: {
        label: 'User',
        is_active: true,
        required: false,
    },
    notification_at: {
        label: 'Date',
        is_active: true,
        required: false,
    },
};
