import i18next from 'i18next';

import { updateImage } from 'common/api/v1/segmentsImage';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_IMAGE,
    SAVE_IMAGE_FAILURE,
    SAVE_IMAGE_SUCCESS,
} from '../actionTypes';
import { panelLayoutV2Actions } from '../reducer';

export default (values, setValue) => (dispatch) => {
    dispatch(panelLayoutV2Actions[SAVE_IMAGE]());

    const { id, image } = values;

    updateImage(id, image)
        .then((response) => {
            const { image } = response.data;
            dispatch(panelLayoutV2Actions[SAVE_IMAGE_SUCCESS](response.data));
            showToast({
                body: i18next.t('The image has been saved'),
                position: 'bottom-center',
            });
            setValue('image', image);
        })
        .catch((error) => {
            dispatch(
                panelLayoutV2Actions[SAVE_IMAGE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error.response);
        });
};
