import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Menu, MenuItem, Typography } from 'sunwise-ui';

const BulkEditionMenu = ({
    handleClickActivateItems,
    selectionModel,
    setSelectionModel,
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setOption] = useState('');
    const options = {
        activate: t('Activate'),
        disable: t('Disable'),
        delete: t('Delete'),
    };
    const count = selectionModel.length;
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleOnChangeSelected = (selected) => {
        setOption(selected);
        switch (selected) {
            case 'activate':
                handleClickActivateItems(selectionModel, true, () =>
                    setSelectionModel([]),
                );
                break;
            case 'disable':
                handleClickActivateItems(selectionModel, false, () =>
                    setSelectionModel([]),
                );
                break;
            default:
                break;
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'start', md: 'center' },
                gap: 1,
            }}
        >
            <>
                <Button
                    color="secondary"
                    endIcon={<KeyboardArrowDownIcon />}
                    fullWidth
                    onClick={handleClick}
                    variant="outlined"
                >
                    <Typography
                        component="span"
                        fontWeight="bold"
                        noWrap
                        sx={{
                            overflow: 'hidden',
                            width: 'calc(100% - 16px)',
                            textAlign: 'left',
                            textOverflow: 'ellipsis',
                        }}
                        variant="body2"
                    >
                        {selectedOption
                            ? options[selectedOption]
                            : `${t('Edit')} ${count} ${t('Product', {
                                  count: count,
                              })}`}
                    </Typography>
                </Button>

                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                        onClick={() => {
                            handleOnChangeSelected('activate');
                            handleClose();
                        }}
                    >
                        <Typography variant="body2">{t('Activate')}</Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleOnChangeSelected('disable');
                            handleClose();
                        }}
                    >
                        <Typography variant="body2">{t('Disable')}</Typography>
                    </MenuItem>
                </Menu>
            </>
            <Button
                color="secondary"
                onClick={() => setSelectionModel([])}
                variant="outlined"
            >
                {t('Cancel')}
            </Button>
        </Box>
    );
};

BulkEditionMenu.propTypes = {
    handleClickActivateItems: PropTypes.func,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
};

export default BulkEditionMenu;
