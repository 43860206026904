import { updateNotification } from 'common/api/v1/notifications';

import {
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_FAILURE,
    UPDATE_NOTIFICATION_SUCCESS,
} from '../actionTypes';
import { notificationsActions } from '../reducer';

import fetchNavNotifications from './fetchNavNotifications';
import fetchNotifications from './fetchNotifications';

export default (filterData, notification) => (dispatch) => {
    if (notification && !notification.viewed) {
        dispatch(notificationsActions[UPDATE_NOTIFICATION]());

        const data = { viewed: !notification.viewed };

        updateNotification(notification.id, data)
            .then(() => {
                dispatch(notificationsActions[UPDATE_NOTIFICATION_SUCCESS]());
                dispatch(fetchNavNotifications());
                dispatch(fetchNotifications(filterData));
            })
            .catch((error) => {
                dispatch(
                    notificationsActions[UPDATE_NOTIFICATION_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
            });
    }
};
