import { isEmpty } from 'lodash';

import {
    calculateValues,
    getAvgMultiplier,
    getBaseConsumptionValues,
    getGroupsByPeriods,
    getShiftMonths,
    mergeUserValues,
} from 'common/utils/formula';
import {
    getDaysInPeriod,
    getDistributionRatioByTier,
} from 'common/utils/helpers/rates';

const AVGWORKDAYSPERMONTH = 20;
const AVGWORKHOURSPERDAY = 8;
const AVGWORKHOURSPERMONTH = AVGWORKDAYSPERMONTH * AVGWORKHOURSPERDAY;

const getAvgDemandBykWh = (kWh) =>
    Math.ceil(kWh / AVGWORKHOURSPERMONTH || 0).toString();

const reducePeriods = (period) =>
    period?.reduce((acc, cur) => acc + cur, 0) || 0;

const getProfileConsumption = ({
    ratesDictionary,
    profilesConsumptionData,
    rateId,
    shiftMonths,
} = {}) => {
    const baseValues = getBaseConsumptionValues({ shiftMonths });
    if (!rateId || !profilesConsumptionData || !ratesDictionary)
        return baseValues;
    const profile = profilesConsumptionData.find(
        (profile) => profile.rate_type === ratesDictionary[rateId]?.rateType,
    );

    const reversedProfile = profile?.consumption
        ?.map((item) => item.value)
        .reverse();
    if (!reversedProfile) return baseValues;
    return getShiftMonths({ arr: reversedProfile, shiftMonths });
};

const getNormalizedConsumptionValues = ({
    formValues,
    numPeriods,
    profilesConsumptionData,
    rateId,
    ratesDictionary,
    shiftMonths,
    userValues,
} = {}) => {
    const baseConsumptionArray = getProfileConsumption({
        profilesConsumptionData,
        rateId,
        ratesDictionary,
        shiftMonths,
    });

    const groupsByPeriods = getGroupsByPeriods({
        baseConsumptionArray,
        numPeriods,
    });
    const mergedUserValues = mergeUserValues({
        groupsByPeriods,
        userValues,
    });
    const avgMultiplier = getAvgMultiplier({
        baseConsumptionArray,
        mergedUserValues,
    });
    const newPlaceholderValues = calculateValues({
        avgMultiplier,
        baseConsumptionArray,
        mergedUserValues,
    });
    const newGroupsByPeriods = getGroupsByPeriods({
        baseConsumptionArray: newPlaceholderValues,
        numPeriods,
    });
    const newMonthsKwh = formValues.summary.map((period, index) => {
        const placeholder = reducePeriods(newGroupsByPeriods[index]);
        return {
            ...period,
            total: {
                placeholder: Math.round(placeholder).toString(),
                value: period.total.value,
            },
        };
    });
    return newMonthsKwh;
};

const getNormalizeConsumption = ({
    formValues,
    numPeriods,
    profilesConsumptionData,
    rateId,
    ratesDictionary,
    shiftMonths,
} = {}) => {
    const userValues = formValues.summary.map((period) =>
        period.total.value === '' || period.total.value === null
            ? null
            : period.total.value,
    );
    return getNormalizedConsumptionValues({
        formValues,
        numPeriods,
        profilesConsumptionData,
        rateId,
        ratesDictionary,
        shiftMonths,
        userValues,
    });
};

const getAvgDemandByHours = (value, hours) =>
    Math.ceil(value / hours || 0).toFixed(0);

const normalizeSummary = ({ formValues, setValue, summary } = {}) => {
    if (isEmpty(formValues.summary)) return new Array(summary.length).fill({});
    const newSummary = [...summary];
    summary.forEach((period, index) => {
        let hasKw = false;
        let totalPlaceholder = 0;
        const totalValue = period?.total.value;
        const total = totalValue ?? period?.total.placeholder;

        if (!isEmpty(period.kWh)) {
            const distribution = getDistributionRatioByTier({
                distribution: formValues?.distribution,
                hoursDistribution: period?.hoursDistribution,
            });

            const energyKeys = Object.keys(period.kWh);

            for (const element of energyKeys) {
                const energyField = period.kWh[element];
                const tier = energyField?.tier || 0;

                const value =
                    totalValue || totalValue === 0
                        ? 0
                        : Math.round(distribution[tier] * total || 0);
                totalPlaceholder += value;
                const newElement = {
                    ...energyField,
                    placeholder: value.toString(),
                };
                newSummary[index].kWh[element] = newElement;
                if (setValue)
                    setValue(`summary.${index}.kWh.${element}`, newElement);

                const demandField = period.kW[element];
                if (!demandField) continue;
                if (!hasKw) hasKw = true;
                const newElementKw = {
                    ...demandField,
                    placeholder: getAvgDemandByHours(
                        newElement?.value || value,
                        period?.hoursDistribution?.[tier]?.total,
                    ),
                };
                newSummary[index].kW[element] = newElementKw;
                if (setValue)
                    setValue(`summary.${index}.kW.${element}`, newElementKw);
            }
        } else totalPlaceholder = summary[index].total.placeholder;

        if (!hasKw && !isEmpty(period.kW)) {
            const demandKeys = Object.keys(period.kW);
            for (const element of demandKeys) {
                const newElement = {
                    ...period.kW[element],
                    placeholder: getAvgDemandBykWh(total),
                };
                newSummary[index].kW[element] = newElement;
                if (setValue)
                    setValue(`summary.${index}.kW.${element}`, newElement);
            }
        }

        const days = Math.abs(getDaysInPeriod(period)) || 1;
        const dailyAvgPlaceholder = (total / days).toFixed(2);

        if (newSummary[index].dailyAvg)
            newSummary[index].dailyAvg.placeholder = dailyAvgPlaceholder;

        if (setValue)
            setValue(`summary.${index}.dailyAvg`, {
                ...period.dailyAvg,
                placeholder: dailyAvgPlaceholder,
            });
        newSummary[index].total.placeholder = totalPlaceholder.toString();
        if (setValue)
            setValue(`summary.${index}.total`, {
                ...period.total,
                placeholder: totalPlaceholder.toString(),
            });
    });

    return newSummary;
};

export const updatePlaceholder = ({
    formValues = {},
    profilesConsumptionData,
    rateId,
    ratesDictionary,
    setValue,
} = {}) => {
    if (isEmpty(formValues)) return;
    const parsedPeriodicityType = Number.parseInt(formValues.periodicity_type);
    const numPeriods = 12 / (parsedPeriodicityType + 1);
    let initialMonth = new Date().getMonth() + 1;
    if (formValues?.summary?.[0]?.final_date)
        initialMonth = formValues.summary[0].final_date.split('/')[1];

    const rate = formValues?.rate ?? rateId;
    const shiftMonths = 12 - Number.parseInt(initialMonth);
    const summary = getNormalizeConsumption({
        formValues,
        numPeriods,
        profilesConsumptionData,
        rateId: rate,
        ratesDictionary,
        shiftMonths,
    });
    return normalizeSummary({ formValues, setValue, summary });
};
