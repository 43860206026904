import i18next from 'i18next';
import { get, isNull } from 'lodash';

export const getEditMessages = (
    financierProduct,
    hasSmartDocuments,
    isApproved,
    isGenerated,
) => {
    const financierProductStatus = get(financierProduct, 'status', null);
    const isDeletedFinancierProduct = ['archived', 'deleted'].includes(
        financierProductStatus,
    );
    const isApprovedFinancierProduct = get(financierProduct, 'approved', false);
    const isEnabledFinancierProduct = get(financierProduct, 'enabled', false);
    let messages = [];
    // ALERT 1.6
    if (isApproved && hasSmartDocuments) {
        messages = [
            i18next.t('Are you sure you want to modify this proposal?'),
            i18next.t(
                "By doing so the client's approval, the documents attached will be lost. Also the sharing link will be disabled",
            ),
            i18next.t('You can always DUPLICATE the proposal'),
        ];
    }
    // ALERT 1.5
    if (isApproved) {
        messages = [
            i18next.t('Are you sure you want to modify this proposal?'),
            i18next.t(
                "By doing so the client's approval will be lost. Also the sharing link will be disabled",
            ),
            i18next.t('You can always DUPLICATE the proposal'),
        ];
    }
    // ALERT 1.2
    if (isGenerated && hasSmartDocuments) {
        messages = [
            i18next.t('Are you sure you want to modify this proposal?'),
            i18next.t(
                'By doing so the documents attached will be lost. Also the sharing link will be disabled',
            ),
            i18next.t('You can always DUPLICATE the proposal'),
        ];
    }
    // ALERT 1.1
    if (isGenerated) {
        messages = [
            i18next.t('Are you sure you want to modify this proposal?'),
            i18next.t('By doing so the sharing link will be disabled'),
            i18next.t('You can always DUPLICATE the proposal'),
        ];
    }

    if (!isGenerated)
        messages = [
            i18next.t('Are you sure you want to modify this proposal?'),
            i18next.t('You can always DUPLICATE the proposal'),
        ];
    if (
        !isNull(financierProduct) &&
        (isDeletedFinancierProduct ||
            !isApprovedFinancierProduct ||
            !isEnabledFinancierProduct)
    )
        messages.splice(
            1,
            0,
            i18next.t('Financial product is no longer available'),
        );
    return {
        type: 'warning',
        messages,
    };
};
