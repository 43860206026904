import API from '../..';
import openAPI from '../../openAPI';
const ENTITY = 'aftersales-printed';
const ENTITY2 = 'aftersales-pdf';

export const getReportEnded = (id) => API.get(`/api/v1/${ENTITY}/${id}`);

export const updateReportEnded = (id, data) =>
    API.put(`/api/v1/${ENTITY}/${id}`, data);
export const getReportEndedWithoutToken = (id) =>
    openAPI.get(`/api/v1/${ENTITY2}/${id}`);
