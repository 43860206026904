import i18next from 'i18next';
import { createSelector } from 'reselect';

import { parseDate } from 'common/utils/dates';

import { NAME, PERIOD_TYPES } from './constants';
import { getPeriodLabel } from './helpers';

export const getModel = (state) => state[NAME];

export const getIsOpenModal = createSelector(getModel, (model) => model.isOpen);

export const getIsOpenIndicatorsSettingsModal = createSelector(
    getModel,
    (model) => model.isOpenIndicatorsSettingsModal,
);

export const getIndicatorsSettings = createSelector(
    getModel,
    (model) => model.indicatorsSettings,
);

export const getIndicatorsSettingsData = createSelector(
    getIndicatorsSettings,
    (value) => value.data,
);

export const getIndicatorsSettingsErrors = createSelector(
    getIndicatorsSettings,
    (value) => value.errors,
);

export const getIndicatorsSettingsIsFetching = createSelector(
    getIndicatorsSettings,
    (value) => value.isFetching,
);

/** REPORT COMPLEMENTS **/
export const getReportComplementsModel = createSelector(
    getModel,
    (model) => model.reportComplements,
);

export const getReportComplementsData = createSelector(
    getReportComplementsModel,
    (model) => model.data,
);

export const getTemplateInitialValues = createSelector(
    getReportComplementsData,
    (data) => ({ template: data?.external_custom_template_id || null }),
);

export const getReportComplementsErrors = createSelector(
    getReportComplementsModel,
    (model) => model.errors,
);

export const getReportComplementsIsFetching = createSelector(
    getReportComplementsModel,
    (model) => model.isFetching,
);

/** REPORT DETAILS */
export const getReportDetailsModel = createSelector(
    getModel,
    (model) => model.reportDetails,
);

export const getReportDetailsIsFetching = createSelector(
    getReportDetailsModel,
    (model) => model.isFetching,
);

export const getReportDetailsData = createSelector(
    getReportDetailsModel,
    (model) => model.data,
);

export const getOfferDetailsErrors = createSelector(
    getReportDetailsModel,
    (model) => model.errors,
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

/* PERIOD CONFIG */
export const getPeriodConfigModel = createSelector(
    getModel,
    (model) => model.periodConfig,
);

export const getPeriodConfigData = createSelector(
    getPeriodConfigModel,
    (model) => model.data,
);

export const getPeriodConfigErrors = createSelector(
    getPeriodConfigModel,
    (model) => model.errors,
);

export const getPeriodConfigIsFetching = createSelector(
    getPeriodConfigModel,
    (model) => model.isFetching,
);

/* SAVE */
export const getSaveModel = createSelector(getModel, (model) => model.save);

export const getSaveData = createSelector(
    getSaveModel,
    (value) => value.data || [],
);

export const getSaveErrors = createSelector(
    getSaveModel,
    (value) => value.errors,
);

export const getSaveIsSaving = createSelector(
    getSaveModel,
    (value) => value.isSaving,
);

export const getReportEndModel = createSelector(
    getModel,
    (model) => model.reportEnd,
);

export const getReportEndIsSaving = createSelector(
    getReportEndModel,
    (model) => model.isSaving,
);

export const getIsOpenPeriodsSettingsModal = createSelector(
    getModel,
    (model) => model.isOpenPeriodsSettingsModal,
);

/** TEMPLATES */
export const getTemplatesModel = createSelector(
    getModel,
    (model) => model.templates,
);

export const getTemplatesData = createSelector(
    getTemplatesModel,
    (model) => model.data?.aftersales_templates || [],
);

export const getTemplatesErrors = createSelector(
    getTemplatesModel,
    (model) => model.errors,
);

export const getTemplatesIsFetching = createSelector(
    getTemplatesModel,
    (model) => model.isFetching,
);

/* SAVE TEMPLATE */
export const getSaveTemplateModel = createSelector(
    getModel,
    (model) => model.saveTemplate,
);

export const getSaveTemplateIsSaving = createSelector(
    getSaveTemplateModel,
    (model) => model.isSaving,
);

/*  project configuration */
export const getRerenceInfoModel = createSelector(
    getModel,
    (model) => model.projectConfiguration,
);

export const getIsFetchingProjectConfiguration = createSelector(
    getRerenceInfoModel,
    (model) => model.isFetching,
);

export const getProjectConfigurationData = createSelector(
    getRerenceInfoModel,
    (model) => model.data,
);

export const getProjectConfigurationProposalData = createSelector(
    getProjectConfigurationData,
    (data) => data?.commercial_offer_data,
);

export const getProposalInfoIsBimonthly = createSelector(
    getProjectConfigurationData,
    (data) => data?.customer_energy?.is_bimonthly || false,
);

export const getProposalDataForSelect = createSelector(
    getProjectConfigurationProposalData,
    getProposalInfoIsBimonthly,
    (data, isBimonthly) => {
        const periods = data?.consumption_history;
        if (!periods?.length) return [];
        return (
            [...periods]
                .sort((a, b) => b.date_start - a.date_start)
                .map((item) => ({
                    disabled: item.period_type === PERIOD_TYPES.PROPOSAL_LEGACY,
                    label:
                        getPeriodLabel(item.date_finish, isBimonthly) +
                        ` (${i18next.t('Proposal')})`,
                    period_type: item.period_type ?? PERIOD_TYPES.PROPOSAL,
                    value: item.id,
                })) || []
        );
    },
);

export const getProposalId = createSelector(
    getProjectConfigurationData,
    (data) => data?.commercial_offer?.id,
);

export const getReferencePeriods = createSelector(
    getProjectConfigurationProposalData,
    (data) =>
        (data?.consumption_history || []).filter(
            (item) => item?.period_type !== PERIOD_TYPES.PROPOSAL_LEGACY,
        ),
);

/* Historical Info */
export const getHistoricalInfoModel = createSelector(
    getModel,
    (model) => model.historicalInfo,
);

export const getHistoricalInfoData = createSelector(
    getHistoricalInfoModel,
    (model) => model.data,
);

export const getIsFetchingHistoricalInfo = createSelector(
    getHistoricalInfoModel,
    (model) => model.isFetching,
);

export const getHistoricalInfoForSelect = createSelector(
    getHistoricalInfoData,
    (data) => {
        if (!data?.length) return [];
        const format = 'dd/MM/yyyy';

        return [...data]
            .sort(
                (a, b) =>
                    parseDate(b?.final_date, format) -
                    parseDate(a?.final_date, format),
            )
            .map((item) => ({
                label:
                    getPeriodLabel(item?.final_date, item?.is_bimonthly) +
                    ` (${i18next.t('Historical')})`,
                period_type: PERIOD_TYPES.HISTORICAL,
                value: item.id,
            }));
    },
);
