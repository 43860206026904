import axios from 'axios';
import get from 'lodash/get';

import {
    getUserSettings,
    getIanaTimezoneKey,
    getRuntimeLanguage,
} from 'common/utils/helpers/multiregion';

const API = axios.create({
    baseURL: import.meta.env.VITE_RENDER_API_URL,
    headers: { 'Content-Type': 'application/json' },
});

API.interceptors.request.use((req) => {
    const token = localStorage.getItem('token');
    const branchSelected = localStorage.getItem('branch-selected') || 'all';
    const settings = getUserSettings();
    const language = get(settings, 'language.key', getRuntimeLanguage());
    const timezone = get(settings, 'timezone.key', getIanaTimezoneKey());
    req.headers['Accept-Language'] = language;
    req.headers['X-Timezone-IANA'] = timezone;
    req.headers['Authorization'] = `JWT ${token}`;
    if (branchSelected && branchSelected !== 'all')
        req.headers['X-Branch-Office'] = branchSelected;

    return req;
});

API.interceptors.response.use(
    (res) => res,
    (error) => Promise.reject(error),
);

export default API;
