import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, ToggleButtonGroup, ToggleButton } from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';

import DiscountTypeFields from './DiscountTypeFields';

const DiscountFields = ({
    canModifyDiscounts,
    canModifyPricePerWatt,
    canModifySubtotal,
    control,
    currencies,
    currencyIso,
    currencySymbol,
    isHidden,
    isLocked,
    setValue,
}) => {
    const { t } = useTranslation();
    const discountType = useWatch({ control, name: 'type_discount' });
    if (isHidden) return null;
    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Price Adjustment')}
                </TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} md={9}>
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        fullWidth
                        onChange={(e) =>
                            setValue('type_discount', parseInt(e.target.value))
                        }
                        size="small"
                        sx={{ mb: 2 }}
                        value={parseInt(discountType)}
                    >
                        <ToggleButton
                            disabled={isLocked || !canModifyDiscounts}
                            value={0}
                        >
                            {t('Discount')}
                        </ToggleButton>
                        <ToggleButton
                            disabled={isLocked || !canModifyPricePerWatt}
                            value={1}
                        >
                            {t('Per watt')}
                        </ToggleButton>
                        <ToggleButton
                            disabled={isLocked || !canModifySubtotal}
                            value={2}
                        >
                            {t('Subtotal')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={18} md={9}>
                    <DiscountTypeFields
                        canModifyDiscounts={canModifyDiscounts}
                        canModifyPricePerWatt={canModifyPricePerWatt}
                        canModifySubtotal={canModifySubtotal}
                        control={control}
                        currencies={currencies}
                        currencyIso={currencyIso}
                        currencySymbol={currencySymbol}
                        discountType={parseInt(discountType)}
                        isLocked={isLocked}
                    />
                </Grid>
            </Grid>
        </>
    );
};

DiscountFields.propTypes = {
    canModifyDiscounts: PropTypes.bool,
    canModifyPricePerWatt: PropTypes.bool,
    canModifySubtotal: PropTypes.bool,
    control: PropTypes.object,
    currencies: PropTypes.array,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    isHidden: PropTypes.bool,
    isLocked: PropTypes.bool,
    setValue: PropTypes.func,
};

export default DiscountFields;
