import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';
import { locationRegex } from 'common/utils/helpers';

export default () => {
    const { REQUIRED_TEXT, REQUIRED_TEXT_MAP, VALID_LOCATION } =
        getValidationTexts();

    return object().shape({
        currency: string().required(REQUIRED_TEXT).nullable(),
        latlng: string().required(REQUIRED_TEXT).matches(locationRegex, {
            message: VALID_LOCATION,
        }),
        name: string().required(REQUIRED_TEXT).nullable(),
        position: object().required(REQUIRED_TEXT_MAP).nullable(),
    });
};
