import React from 'react';

import PropTypes from 'prop-types';

const IconInverter = ({
    color = '#20c4d9',
    height = '16px',
    width = '20px',
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 0C0.447715 0 0 0.447716 0 1V17C0 17.5523 0.447715 18 1 18H2V19C2 19.5523 2.44772 20 3 20H7C7.55228 20 8 19.5523 8 19V18H9V19C9 19.5523 9.44771 20 10 20H12C12.5523 20 13 19.5523 13 19V18H15C15.5523 18 16 17.5523 16 17V1C16 0.447715 15.5523 0 15 0H1ZM1 1.75C1 1.33579 1.33579 1 1.75 1H7.25C7.66421 1 8 1.33579 8 1.75C8 2.16421 7.66421 2.5 7.25 2.5H1.75C1.33579 2.5 1 2.16421 1 1.75ZM12.5933 2.14488C12.9417 1.71641 13.5716 1.65157 14 2.00005C14.4285 2.34852 14.4933 2.97836 14.1449 3.40682L3.40674 15.8553C3.05826 16.2837 2.42842 16.3486 1.99996 16.0001C1.57149 15.6516 1.50665 15.0218 1.85513 14.5933L12.5933 2.14488ZM1.75 3.5C1.33579 3.5 1 3.83579 1 4.25C1 4.66421 1.33579 5 1.75 5H3.25C3.66421 5 4 4.66421 4 4.25C4 3.83579 3.66421 3.5 3.25 3.5H1.75Z"
            fill={color}
        />
    </svg>
);

IconInverter.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconInverter;
