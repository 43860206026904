import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActions, Button, TabPanel } from 'sunwise-ui';

import { HOURS_IN_DAY, MONTHS_IN_YEAR } from 'common/constants';

import { getTierColorByIndex } from '../../../../helpers';
import {
    DistributionSection,
    TabsSettings,
} from '../../../HourlyRateComponents';
import FieldArrayPeriods from '../FieldArrayPeriods';

import ConceptDistribution from './ConceptDistribution';
import HeaderDistribution from './HeaderDistribution';

const GROUP_NAME = 'settings_data.hourly_distribution';
const BASE_ARRAY = new Array(HOURS_IN_DAY * MONTHS_IN_YEAR).fill({ value: 1 });
const defaultDistribution = {
    saturday: BASE_ARRAY,
    sunday: BASE_ARRAY,
    weekday: BASE_ARRAY,
};

const HourlyRate = ({
    changeTab: changeMainTab,
    control,
    getValues,
    isAnnualType,
    name,
    paymentType,
    setValue,
    showMode,
    trigger,
}) => {
    const [selectedTab, setSelectedTab] = useState('charges');
    const [hourlyDistributionEnergy, setHourlyDistributionEnergy] =
        useState(defaultDistribution);
    const [
        hourlyDistributionContractedDemand,
        setHourlyDistributionContractedDemand,
    ] = useState(defaultDistribution);

    const [contractedDemandConcepts, energyConcepts, optionDeleted] = useWatch({
        control,
        name: [
            `${name}.0.contracted_demand_concepts.concepts`,
            `${name}.0.energy_concepts.concepts`,
            `${GROUP_NAME}.option_deleted`,
        ],
    });

    const { t } = useTranslation();

    const getNewColors = (prev, optionsLength) =>
        prev.map((hourMonth) => ({
            ...hourMonth,
            color: getTierColorByIndex(hourMonth.value - 1, optionsLength),
        }));

    const saveHourlyDistribution = () => {
        const _select = { saturday: 0, sunday: 0, weekday: 0 };
        setValue(`${GROUP_NAME}.select`, { energy: _select, demand: _select });

        setNewColors({
            distribution: hourlyDistributionEnergy,
            length: energyConcepts?.length,
            name: `${GROUP_NAME}.energy_concepts`,
            setDistribution: setHourlyDistributionEnergy,
        });

        setNewColors({
            distribution: hourlyDistributionContractedDemand,
            length: contractedDemandConcepts?.length,
            name: `${GROUP_NAME}.demand_concepts`,
            setDistribution: setHourlyDistributionContractedDemand,
        });
    };

    const setNewColors = ({ distribution, length, setDistribution, name }) => {
        if (!distribution || !length) return;
        const newDistribution = {
            saturday: getNewColors(distribution.saturday, length),
            sunday: getNewColors(distribution.sunday, length),
            weekday: getNewColors(distribution.weekday, length),
        };
        setDistribution(newDistribution);
        setValue(name, newDistribution);
    };

    const handleOnChangeTab = (newValue) => {
        setSelectedTab(newValue);
        saveHourlyDistribution();
    };

    const getValuesAfterDelete = (prev, index) =>
        prev.map((item) => {
            if (item.value - 1 === index) return { ...item, value: 1 };
            if (item.value - 1 > index)
                return { ...item, value: item.value - 1 };
            return item;
        });

    const setHourlyDistributionAfterDelete = (setStateFunction, index) =>
        setStateFunction((prev) => ({
            saturday: getValuesAfterDelete(prev.saturday, index),
            sunday: getValuesAfterDelete(prev.sunday, index),
            weekday: getValuesAfterDelete(prev.weekday, index),
        }));

    useEffect(() => {
        const energyDistribution = getValues(`${GROUP_NAME}.energy_concepts`);
        const demandDistribution = getValues(`${GROUP_NAME}.demand_concepts`);
        if (energyDistribution) setHourlyDistributionEnergy(energyDistribution);
        if (demandDistribution)
            setHourlyDistributionContractedDemand(demandDistribution);
    }, []);

    useEffect(() => {
        if (!optionDeleted) return;
        const { index, name } = optionDeleted;
        if (name.includes('energy_concepts'))
            setHourlyDistributionAfterDelete(
                setHourlyDistributionEnergy,
                index,
            );
        else if (name.includes('contracted_demand_concepts'))
            setHourlyDistributionAfterDelete(
                setHourlyDistributionContractedDemand,
                index,
            );
        setValue(`${GROUP_NAME}.option_deleted`, null);
    }, [optionDeleted]);

    const hasContractedDemand = !isEmpty(contractedDemandConcepts);
    const hasEnergyConcepts = !isEmpty(energyConcepts);

    return (
        <>
            <TabsSettings
                handleOnChangeTab={(_, newValue) => handleOnChangeTab(newValue)}
                hasContractedDemand={hasContractedDemand}
                hasEnergyConcepts={hasEnergyConcepts}
                selectedTab={selectedTab}
            />

            <TabPanel
                key="tab-panel-charges"
                selectedTab={selectedTab}
                value="charges"
            >
                <FieldArrayPeriods
                    control={control}
                    getValues={getValues}
                    isAnnualType={isAnnualType}
                    isHourlyRate
                    name={name}
                    paymentType={paymentType}
                    setValue={setValue}
                    showMode={showMode}
                    trigger={trigger}
                />
                {!showMode && (
                    <BottomActions>
                        <Button
                            onClick={() =>
                                handleOnChangeTab('energy-distribution')
                            }
                            sx={{ mt: 2 }}
                            type="button"
                        >
                            {t('Next')}
                        </Button>
                    </BottomActions>
                )}
            </TabPanel>

            {hasEnergyConcepts && (
                <TabPanel
                    key="tab-panel-energy-distribution"
                    selectedTab={selectedTab}
                    value="energy-distribution"
                >
                    <HeaderDistribution
                        rateTiers={energyConcepts}
                        title={`${t('Energy')} (kWh)`}
                    />

                    <DistributionSection
                        conceptName="energy"
                        control={control}
                        distributionComponent={ConceptDistribution}
                        field={GROUP_NAME}
                        getValues={getValues}
                        hourlyDistribution={hourlyDistributionEnergy}
                        onClickButton={() => {
                            if (!hasContractedDemand)
                                changeMainTab('policy_data');
                            else handleOnChangeTab('demand-distribution');
                        }}
                        options={energyConcepts}
                        setHourlyDistribution={setHourlyDistributionEnergy}
                        setValue={setValue}
                        showMode={showMode}
                    />
                </TabPanel>
            )}

            {hasContractedDemand && (
                <TabPanel
                    key="tab-panel-demand-distribution"
                    selectedTab={selectedTab}
                    value="demand-distribution"
                >
                    <HeaderDistribution
                        rateTiers={contractedDemandConcepts}
                        title={`${t('Contracted demand')} (kW)`}
                    />

                    <DistributionSection
                        conceptName="demand"
                        control={control}
                        distributionComponent={ConceptDistribution}
                        field={GROUP_NAME}
                        getValues={getValues}
                        hourlyDistribution={hourlyDistributionContractedDemand}
                        onClickButton={() => changeMainTab('policy_data')}
                        options={contractedDemandConcepts}
                        setHourlyDistribution={
                            setHourlyDistributionContractedDemand
                        }
                        setValue={setValue}
                        showMode={showMode}
                    />
                </TabPanel>
            )}
        </>
    );
};

HourlyRate.propTypes = {
    changeTab: PropTypes.func,
    control: PropTypes.object,
    getValues: PropTypes.func,
    isAnnualType: PropTypes.bool,
    name: PropTypes.string,
    paymentType: PropTypes.string,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
    trigger: PropTypes.func,
};

export default HourlyRate;
