import { CHART_COLORS } from 'common/constants';
import { formatDate, parseDate } from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';

import { ANNUAL_TYPE } from '../rates/constants';
import {
    createPeriodsByPeriodicityType,
    generatePeriodsByEnergyConcepts,
    getRatePeriods,
} from '../rates/helpers';

import { CONCEPT_PRICE_GROUP, RATE_TYPES } from './constants';

export const prepareRate = (data, type = RATE_TYPES.CURRENT) => {
    const periodicityType = 'Monthly';

    let periods = [];

    if (data?.energy_concepts?.length) {
        periods = generatePeriodsByEnergyConcepts(
            data.energy_concepts,
            periodicityType,
            data.payment_type,
        );
    } else {
        periods = createPeriodsByPeriodicityType({ periodicityType });
    }

    const ratePeriods = getRatePeriods({
        contractedDemandConcepts: data?.contracted_demand_concepts,
        demandConcepts: data?.demand_concepts,
        otherConcepts: data?.other_concepts,
        periodicityType,
        periods,
        spainExcessDemandConcepts: data?.spain_excess_demand_concepts,
        taxesConcepts: data?.taxes_concepts,
        typeOfInputCharges: data?.type_of_input_charges ?? ANNUAL_TYPE,
    });

    return {
        certified: data?.certified,
        payment_type: data?.payment_type,
        periods: ratePeriods,
        pricing_data: data?.pricing_data,
        type,
    };
};

const getMainDateFormatted = (date) => {
    if (!date) return '';
    return formatDate(parseDate(date, 'dd/MM/yyyy'), 'MMM yy').toUpperCase();
};

const formatUnit = (unit, countryCurrencySymbol) => {
    if (!unit) return '';
    return unit?.replace('$', countryCurrencySymbol);
};

export const createChartConfig = ({
    countryCurrencyLocale,
    countryCurrencySymbol = '$',
    pricingData,
}) => {
    const categories = pricingData?.index?.map(getMainDateFormatted) || [];

    const conceptsMap = {
        [CONCEPT_PRICE_GROUP.ENERGY]: [],
        [CONCEPT_PRICE_GROUP.OTHER]: [],
    };
    const unitMap = {};

    for (const concept of pricingData?.data || []) {
        if (concept.type === CONCEPT_PRICE_GROUP.ENERGY) {
            conceptsMap[CONCEPT_PRICE_GROUP.ENERGY][concept.name] =
                concept.data;
        } else {
            conceptsMap[CONCEPT_PRICE_GROUP.OTHER][concept.name] = concept.data;
        }

        unitMap[concept.name] = formatUnit(concept.type, countryCurrencySymbol);
    }

    const series = Object.keys(conceptsMap).flatMap((unit) =>
        Object.keys(conceptsMap[unit]).map((concept, index) => ({
            name: concept,
            data: conceptsMap[unit][concept],
            type: 'line',
            yAxisID: `y-${unit}`,
            color: CHART_COLORS[index % CHART_COLORS.length],
        })),
    );

    const yAxes = {
        [`y-${CONCEPT_PRICE_GROUP.ENERGY}`]: {
            type: 'linear',
            position: 'left',
            ticks: {
                callback: (val) =>
                    numberFormat(val, {
                        decimals: 0,
                        locale: countryCurrencyLocale,
                        style: 'decimal',
                    }),
            },
            title: { display: true, text: `${countryCurrencySymbol}/kWh` },
        },
        [`y-${CONCEPT_PRICE_GROUP.OTHER}`]: {
            type: 'linear',
            position: 'right',
            maxRotation: 0,
            ticks: {
                callback: (val) =>
                    numberFormat(val, {
                        decimals: 0,
                        locale: countryCurrencyLocale,
                        style: 'decimal',
                    }),
            },
            title: {
                display: true,
                text: countryCurrencySymbol,
                align: 'center',
            },
        },
    };

    return {
        type: 'line',
        interaction: { axis: 'x', intersect: false, mode: 'nearest' },
        data: {
            labels: categories,
            datasets: series.map(({ color, data, name, yAxisID }) => ({
                backgroundColor: color,
                borderColor: color,
                data,
                label: name,
                fill: false,
                yAxisID,
            })),
        },
        options: {
            interaction: { axis: 'x', intersect: false, mode: 'nearest' },
            scales: {
                x: { grid: { drawOnChartArea: false }, stacked: true },
                ...yAxes,
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const unit = unitMap[context.dataset.label];
                            return `${context.dataset.label} (${unit}) : ${numberFormat(
                                context.parsed.y,
                                {
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                },
                            )}`;
                        },
                    },
                    mode: 'index',
                    position: 'nearest',
                },
            },
        },
    };
};
