import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from 'sunwise-ui';

import { ButtonFieldFile } from 'common/components/form';
import { FILE_ALLOWED_EXTENSIONS } from 'common/constants';

const UploadFileButton = ({
    control,
    disabled,
    fileInputRef,
    handleOnChange,
    hasFile,
}) => {
    const { t } = useTranslation();

    if (hasFile) return null;

    return (
        <Tooltip
            placement="top"
            title={
                <>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        textAlign="center"
                    >
                        {`${t('File')} csv`}
                    </Typography>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        textAlign="center"
                    >
                        {t('No more than {{size}} MB', {
                            size: 10,
                        })}
                    </Typography>
                </>
            }
        >
            <div>
                <Controller
                    control={control}
                    name="file"
                    render={({ field }) => (
                        <ButtonFieldFile
                            accept={FILE_ALLOWED_EXTENSIONS.CSV}
                            disabled={disabled}
                            field={field}
                            fileInputRef={fileInputRef}
                            id="upload_file_csv"
                            onChange={handleOnChange}
                            text={t('Load file')}
                            variant="text"
                        />
                    )}
                />
            </div>
        </Tooltip>
    );
};

UploadFileButton.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fileInputRef: PropTypes.object,
    handleOnChange: PropTypes.func,
    hasFile: PropTypes.bool,
};

export default UploadFileButton;
