import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import DiscountTypeGroup from 'common/components/DiscountTypeGroup';
import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import { DISCOUNT_TYPES } from 'common/constants';
import { getMaxDiscountByUnitPrice } from 'common/utils/helpers';
import { getMaximumDiscount } from 'common/utils/helpers/session';

const DiscountColumn = ({
    canModifyDiscount,
    canModifyProduct,
    control,
    currencySymbol,
    formValues,
    handleNormalizeDiscountField,
    handleNormalizeFields,
    handleNormalizePercentageDiscountField,
    index,
    initialValues,
    isDisabledField,
    isHidden,
    name,
    productItem,
    setValue,
    size,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (productItem.discount_type === DISCOUNT_TYPES.AMOUNT)
            setValue(`${name}.${index}.discount`, productItem.discount);
        else
            setValue(
                `${name}.${index}.percentage_discount`,
                productItem.percentage_discount,
            );
    }, [productItem.discount_type]);

    if (isHidden) return null;

    const maximumDiscount = getMaximumDiscount();
    const maxDiscountByUnitPrice = getMaxDiscountByUnitPrice(
        getMaximumDiscount(),
        productItem.quantity * productItem.unit_price,
    );

    const isDisabledDiscount = isDisabledField || !canModifyDiscount;

    if (canModifyDiscount || (!canModifyProduct && canModifyDiscount)) {
        return (
            <Grid item xs={18} lg={size.discount[0]}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {productItem.discount_type === DISCOUNT_TYPES.AMOUNT ? (
                        <ReactHookFormIntlNumberInput
                            control={control}
                            disabled={isDisabledDiscount}
                            fullWidth
                            label={t('Discount')}
                            min={0}
                            name={`${name}.${index}.discount`}
                            onChange={({ target: { value } }) =>
                                handleNormalizeDiscountField(
                                    handleNormalizeFields,
                                    index,
                                    maxDiscountByUnitPrice,
                                    productItem,
                                )(
                                    value,
                                    initialValues?.[name]?.[index]?.discount,
                                    formValues,
                                    name,
                                )
                            }
                            step="any"
                            variant="standard"
                        />
                    ) : (
                        <ReactHookFormIntlNumberInput
                            control={control}
                            disabled={isDisabledDiscount}
                            fullWidth
                            label={t('Discount')}
                            min={0}
                            name={`${name}.${index}.percentage_discount`}
                            onChange={({ target: { value } }) =>
                                handleNormalizePercentageDiscountField(
                                    handleNormalizeFields,
                                    index,
                                    maximumDiscount,
                                )(
                                    value,
                                    initialValues?.[name]?.[index]
                                        ?.percentage_discount,
                                    formValues,
                                    name,
                                )
                            }
                            step="any"
                            variant="standard"
                        />
                    )}
                    <DiscountTypeGroup
                        currencySymbol={currencySymbol}
                        disabled={isDisabledDiscount}
                        handleOnChange={(value) => {
                            setValue(`${name}.${index}.discount_type`, value);
                        }}
                        selected={productItem.discount_type}
                    />
                </Box>
            </Grid>
        );
    }
    return null;
};

DiscountColumn.propTypes = {
    canModifyProduct: PropTypes.bool,
    canModifyDiscount: PropTypes.bool,
    control: PropTypes.object,
    currencySymbol: PropTypes.string,
    formValues: PropTypes.object,
    handleNormalizeDiscountField: PropTypes.func,
    handleNormalizeFields: PropTypes.func,
    handleNormalizePercentageDiscountField: PropTypes.func,
    index: PropTypes.number,
    initialValues: PropTypes.object,
    isDisabledField: PropTypes.bool,
    isHidden: PropTypes.bool,
    name: PropTypes.string,
    productItem: PropTypes.object,
    setValue: PropTypes.func,
    size: PropTypes.object,
};

export default DiscountColumn;
