import i18next from 'i18next';

import { downloadProjectsFile } from 'common/api/v2/funnelProjects';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

export default (downloadFilter, closeLoadingView) => (dispatch) => {
    downloadProjectsFile(downloadFilter)
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${i18next.t('Project funnel')}.csv`);
            document.body.appendChild(link);
            link.click();
            closeLoadingView();
        })
        .catch((error) => {
            closeLoadingView();
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
