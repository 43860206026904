import React, { useEffect } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, Grid } from 'sunwise-ui';

import { ShowErrors, TitleIcon } from 'common/components';
import { ReactHookFormDragnDropFile } from 'common/components/form/bootstrap';
import { FILE_ALLOWED_EXTENSIONS, MAX_SMALL_FILE_SIZE } from 'common/constants';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ThemeFields from './ThemeFields';

const FormContainer = ({
    canModify,
    errors,
    initialValues,
    isSaving,
    prepareRestore,
    save,
}) => {
    const { t } = useTranslation();
    const name = 'B';
    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
    });
    const [loadingImage, logoImage, logotypeImage] = watch([
        'loading_image',
        'logo_image',
        'logotype_image',
    ]);

    useEffect(() => reset(initialValues), [initialValues]);

    return (
        <Box component="form">
            <Grid container>
                <Grid item xs={18} md={18} lg={9} sx={{ pt: { xs: 0, md: 2 } }}>
                    <TitleIcon
                        fontWeight="bold"
                        title={t('Company image')}
                        variant="subtitle"
                    />

                    <Divider sx={{ mb: 2, mt: 1 }} />

                    <ReactHookFormDragnDropFile
                        accept={FILE_ALLOWED_EXTENSIONS.IMAGE}
                        avatarData={{ image: logoImage, name }}
                        control={control}
                        disabled={!canModify}
                        formats="jpg, jpeg, png, svg"
                        maxSize={MAX_SMALL_FILE_SIZE}
                        name="logo_image"
                        setValue={setValue}
                        variant="image"
                    />

                    <TitleIcon
                        fontWeight="bold"
                        title={t('Logotype')}
                        variant="subtitle"
                    />

                    <Divider sx={{ mb: 2, mt: 1 }} />

                    <ReactHookFormDragnDropFile
                        accept={FILE_ALLOWED_EXTENSIONS.IMAGE}
                        avatarData={{ image: logotypeImage, name }}
                        control={control}
                        disabled={!canModify}
                        formats="jpg, jpeg, png, svg"
                        maxSize={MAX_SMALL_FILE_SIZE}
                        name="logotype_image"
                        setValue={setValue}
                        variant="profile"
                    />

                    <TitleIcon
                        fontWeight="bold"
                        title={t('Loading logo')}
                        variant="subtitle"
                    />

                    <Divider sx={{ mb: 2, mt: 1 }} />

                    <ReactHookFormDragnDropFile
                        accept={FILE_ALLOWED_EXTENSIONS.IMAGE}
                        avatarData={{ image: loadingImage, name }}
                        control={control}
                        disabled={!canModify}
                        formats="jpg, jpeg, png, svg"
                        maxSize={MAX_SMALL_FILE_SIZE}
                        name="loading_image"
                        setValue={setValue}
                        variant="profile"
                    />

                    <ThemeFields
                        control={control}
                        disabled={!canModify}
                        group="light"
                        title={t('Light mode')}
                    />

                    <ThemeFields
                        control={control}
                        disabled={!canModify}
                        group="dark"
                        title={t('Dark mode')}
                    />

                    <ShowErrors errors={errors} />

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            justifyContent: 'end',
                        }}
                    >
                        <Button
                            color="error"
                            onClick={prepareRestore}
                            variant="outlined"
                            visible={canModify}
                        >
                            {t('Restore to default')}
                        </Button>
                        <Button
                            endIcon={<ArrowForwardIcon />}
                            id="business_branding_form_save_button"
                            onClick={handleSubmit(save)}
                            sx={{ width: { xs: '100%', md: 'auto' } }}
                            variant="outlined"
                            visible={canModify}
                        >
                            {isSaving ? t('Saving').concat('...') : t('Save')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    prepareRestore: () => dispatch(actions.prepareRestore()),
    save: (values) => dispatch(actions.save(values)),
});

FormContainer.propTypes = {
    canModify: PropTypes.bool,
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    prepareRestore: PropTypes.func,
    save: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
