import { getTemplates } from 'common/api/v1/templates';

import {
    FETCH_CAROUSEL_TEMPLATES,
    FETCH_CAROUSEL_TEMPLATES_FAILURE,
    FETCH_CAROUSEL_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(templateLibraryActions[FETCH_CAROUSEL_TEMPLATES]());

    getTemplates({ is_base: true, perPage: 100 })
        .then((response) => {
            const {
                results: {
                    new: news = { templates: [] },
                    oustanding = { templates: response.data.results },
                    regulars = { templates: [] },
                },
            } = response.data;

            dispatch(
                templateLibraryActions[FETCH_CAROUSEL_TEMPLATES_SUCCESS]({
                    featuredTemplates: oustanding.templates,
                    newTemplates: news.templates,
                    regularTemplates: regulars.templates,
                }),
            );
        })
        .catch((error) => {
            dispatch(
                templateLibraryActions[FETCH_CAROUSEL_TEMPLATES_FAILURE](error),
            );
        });
};
