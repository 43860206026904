import { getItems } from 'common/api/v2/offerEnergyStorage';

import updateProductsData from '../../proposalGeneratorQuote/actions/updateProductsData';
import {
    FETCH_OFFER_ENERGY_STORAGE,
    FETCH_OFFER_ENERGY_STORAGE_FAILURE,
    FETCH_OFFER_ENERGY_STORAGE_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorEnergyStorageTableActions } from '../reducer';

import initialValues from './initialValues';
import resetForm from './resetForm';

export default (id, name) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(
            proposalGeneratorEnergyStorageTableActions[
                FETCH_OFFER_ENERGY_STORAGE
            ](),
        );
        getItems(id)
            .then((response) => {
                dispatch(resetForm());

                const values = response.data.data;

                dispatch(
                    proposalGeneratorEnergyStorageTableActions[
                        FETCH_OFFER_ENERGY_STORAGE_SUCCESS
                    ](response.data.data),
                );

                dispatch(initialValues(values));
                dispatch(updateProductsData(name, values));
            })
            .catch((error) =>
                dispatch(
                    proposalGeneratorEnergyStorageTableActions[
                        FETCH_OFFER_ENERGY_STORAGE_FAILURE
                    ](error?.response?.data?.errors),
                ),
            )
            .finally(resolve);
    });
