import { getNewItems } from 'common/api/v1/funnelProposals';
import { FUNNEL_ITEMS_PER_PAGE as PER_PAGE } from 'common/constants';

import {
    FETCH_NEXT_PAGE,
    FETCH_NEXT_PAGE_FAILURE,
    FETCH_NEXT_PAGE_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default ({ filterQuery, page, status } = {}) =>
    (dispatch) => {
        dispatch(actions[FETCH_NEXT_PAGE]({ id: status }));

        getNewItems({ filterQuery, page, per_page: PER_PAGE, status })
            .then((response) => {
                const {
                    data,
                    pagination: {
                        links: { next },
                    },
                } = response.data;

                const nextPage = next !== null ? page + 1 : null;

                dispatch(
                    actions[FETCH_NEXT_PAGE_SUCCESS]({
                        id: status,
                        nextPage,
                        projects: data,
                    }),
                );
            })
            .catch((error) => {
                dispatch(
                    actions[FETCH_NEXT_PAGE_FAILURE]({ error, id: status }),
                );
            });
    };
