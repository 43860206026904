import {
    getDemandUnits,
    getEnergyUnits,
    getOthersUnits,
} from 'common/api/v1/unitRates';

import {
    FETCH_UNITS,
    FETCH_UNITS_FAILURE,
    FETCH_UNITS_SUCCESS,
} from '../actionTypes';
import { ratesActions } from '../reducer';

import fetchContractedDemandUnits from './fetchContractedDemandUnits';

const createUnitsPromise = (apiCall) =>
    apiCall().then((response) => response?.data?.data || []);

export default () => (dispatch) => {
    dispatch(ratesActions[FETCH_UNITS]());

    dispatch(fetchContractedDemandUnits());
    Promise.all([
        createUnitsPromise(getEnergyUnits),
        createUnitsPromise(getDemandUnits),
        createUnitsPromise(getOthersUnits),
    ])
        .then((response) =>
            dispatch(
                ratesActions[FETCH_UNITS_SUCCESS](
                    response.reduce((acc, current) => [...acc, ...current], []),
                ),
            ),
        )
        .catch((error) => dispatch(ratesActions[FETCH_UNITS_FAILURE](error)));
};
