import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import { List, ListItem, ListItemButton, ListItemText } from 'sunwise-ui';

import SideMenuCard from '../../multiTemplateReview/components/SideMenuCard';

const SmartDocumentsCard = ({
    handleClickDocumentItem,
    isDisabled,
    offerSmartDocuments,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (!offerSmartDocuments.length) return null;

    return (
        <SideMenuCard
            icon={<ArticleIcon sx={{ color: 'primary.main' }} />}
            title={t('Smart document', { count: 2 })}
        >
            <List disablePadding>
                {offerSmartDocuments.map((document) => (
                    <ListItem
                        disabled={
                            isDisabled || document.id === selectedDocumentId
                        }
                        disablePadding
                        key={`smart-document-${document.id}`}
                        selected={document.id === selectedDocumentId}
                        sx={{
                            borderRadius: '8px',
                            mb: '5px',
                            '&.Mui-selected': {
                                backgroundColor:
                                    theme.palette.mode === 'dark'
                                        ? '#000'
                                        : 'rgba(31, 60, 83, 0.13)',
                            },
                        }}
                    >
                        <ListItemButton
                            onClick={() =>
                                handleClickDocumentItem({
                                    id: document.id,
                                    name: document.name,
                                    type: types.SMART_DOCUMENTS_TYPE,
                                })
                            }
                            sx={{ borderRadius: '8px' }}
                        >
                            <ListItemText
                                primary={document.name}
                                sx={{
                                    '.MuiListItemText-primary': {
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </SideMenuCard>
    );
};

SmartDocumentsCard.propTypes = {
    handleClickDocumentItem: PropTypes.func,
    isDisabled: PropTypes.bool,
    offerSmartDocuments: PropTypes.array,
    selectedDocumentId: PropTypes.string,
};

export default SmartDocumentsCard;
