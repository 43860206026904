import i18next from 'i18next';
import { createSelector } from 'reselect';

import {
    differenceInDaysDate,
    formatDate,
    parseDate,
    subDate,
} from 'common/utils/dates';
import { getEstimatedDemand } from 'common/utils/helpers/rates';
import { getDataProfileFormattedByPeriods } from 'common/utils/helpersChart';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getConsumptionEnergy = createSelector(
    getModel,
    (model) => model.consumptionEnergy,
);

export const getIsFetchingConsumptionEnergy = createSelector(
    getConsumptionEnergy,
    (consumptionEnergy) => consumptionEnergy.isFetching,
);

export const getUpdateConsumptionEnergy = createSelector(
    getModel,
    (model) => model.updateConsumptionEnergy,
);

export const getIsUpdatingConsumptionEnergy = createSelector(
    getUpdateConsumptionEnergy,
    (updateConsumptionEnergy) => updateConsumptionEnergy.isFetching,
);

export const getErrorsUpdatingConsumptionEnergy = createSelector(
    getUpdateConsumptionEnergy,
    (updateConsumptionEnergy) => updateConsumptionEnergy.error,
);

export const getDataConsumptionEnergy = createSelector(
    getConsumptionEnergy,
    (consumptionEnergy) => consumptionEnergy.data || {},
);

export const getErrorsConsumptionEnergy = createSelector(
    getConsumptionEnergy,
    (consumptionEnergy) => consumptionEnergy.errors,
);

export const getSelectedProject = createSelector(
    getModel,
    (model) => model.selectedProject,
);

export const getIsBimonthly = createSelector(getConsumptionEnergy, (model) =>
    model.data === null ? true : model.data.is_bimonthly,
);

export const getMonthsToChartKwh = createSelector(
    getDataConsumptionEnergy,
    getIsBimonthly,
    (data, isBimonthly) => {
        if (!data.lines_consumption_energies) return [];

        const filteredKwh = data.lines_consumption_energies.filter(
            (line) => parseInt(line.type_line) === 0,
        );

        const sorted = filteredKwh.sort(
            (a, b) =>
                parseDate(a.initial_date, 'dd/MM/yyyy') -
                parseDate(b.initial_date, 'dd/MM/yyyy'),
        );

        const daysToSubstract = isBimonthly ? 30 : 15;

        return sorted.map((item) => {
            const label = formatDate(
                subDate(parseDate(item.final_date, 'dd/MM/yyyy'), {
                    days: Math.floor(daysToSubstract),
                }),
                'MMM yy',
            );

            return {
                final_date: item.final_date,
                initial_date: item.initial_date,
                label: label,
                url: item.url,
                url_type: item.url_type,
                value: item.consumption,
            };
        });
    },
);

export const getMonthsToChartKw = createSelector(
    getDataConsumptionEnergy,
    getIsBimonthly,
    (data, isBimonthly) => {
        const isCertified = data?.rate?.certified;
        if (!data?.lines_consumption_energies || !isCertified) return [];

        const rateName = data?.rate?.name;
        const daysToSubstract = isBimonthly ? 30 : 15;

        if (['DIST', 'DIT'].includes(rateName)) {
            const sortedTiersConsumptions = [
                ...(data?.tiers_consumptions || []),
            ].sort(
                (a, b) =>
                    parseDate(a.initial_date, 'yyyy-MM-dd') -
                    parseDate(b.initial_date, 'yyyy-MM-dd'),
            );
            return sortedTiersConsumptions.map((period) => {
                const label = formatDate(
                    subDate(parseDate(period.final_date, 'yyyy-MM-dd'), {
                        days: Math.floor(daysToSubstract),
                    }),
                    'MMM yy',
                );
                const total = period?.energy?.reduce(
                    (acc, curr) => acc + curr.consumption,
                    0,
                );
                const maxDemand = period?.demand?.reduce(
                    (acc, curr) => Math.max(acc, curr.consumption),
                    0,
                );
                const diffDays = differenceInDaysDate(
                    parseDate(period.final_date, 'yyyy-MM-dd'),
                    parseDate(period.initial_date, 'yyyy-MM-dd'),
                );
                const estimatedDemand = getEstimatedDemand({
                    diffDays,
                    rateName,
                    total,
                });
                return {
                    label,
                    value: Math.min(estimatedDemand, maxDemand),
                };
            });
        }

        const filteredKw = data.lines_consumption_energies.filter(
            (line) => parseInt(line.type_line) === 1,
        );
        const filteredKwh = data.lines_consumption_energies.filter(
            (line) => parseInt(line.type_line) === 0,
        );

        const sortedKw = filteredKw.sort(
            (a, b) =>
                parseDate(a.initial_date, 'dd/MM/yyyy') -
                parseDate(b.initial_date, 'dd/MM/yyyy'),
        );
        const sortedKwh = filteredKwh.sort(
            (a, b) =>
                parseDate(a.initial_date, 'dd/MM/yyyy') -
                parseDate(b.initial_date, 'dd/MM/yyyy'),
        );

        if (rateName !== 'GDMTH')
            return sortedKw.map((item, index) => {
                const total = sortedKwh[index].consumption;
                const label = formatDate(
                    subDate(parseDate(item.final_date, 'dd/MM/yyyy'), {
                        days: Math.floor(daysToSubstract),
                    }),
                    'MMM yy',
                );
                const diffDays = differenceInDaysDate(
                    parseDate(item.final_date, 'dd/MM/yyyy'),
                    parseDate(item.initial_date, 'dd/MM/yyyy'),
                );
                const estimatedDemand = getEstimatedDemand({
                    diffDays,
                    rateName,
                    total,
                });
                return {
                    label,
                    value: Math.min(estimatedDemand, item.consumption),
                };
            });

        const sortedSummary = [...data.gdmth_summary].sort(
            (a, b) =>
                parseDate(a.initial_date, 'dd/MM/yyyy') -
                parseDate(b.initial_date, 'dd/MM/yyyy'),
        );

        const mappedSummary = sortedSummary.map((period, index) => {
            const total = sortedKwh[index]?.consumption;
            const periodDates = sortedKw[index];
            if (!periodDates) return null;
            const final_date = parseDate(periodDates.final_date, 'dd/MM/yyyy');
            const label = formatDate(
                subDate(final_date, { days: Math.floor(daysToSubstract) }),
                'MMM yy',
            );
            const diffDays = differenceInDaysDate(
                final_date,
                parseDate(periodDates.initial_date, 'dd/MM/yyyy'),
            );
            const estimatedDemand = getEstimatedDemand({
                diffDays,
                rateName,
                total,
            });
            const maxDemand = Math.max(
                period.base_conpsumption_kw,
                period.intermediate_consumption_kw,
                period.peak_consumption_kw,
            );
            return { label, value: Math.min(estimatedDemand, maxDemand) };
        });
        return mappedSummary.filter(Boolean);
    },
);

export const getInfoPriceConsumtion = createSelector(
    getDataConsumptionEnergy,
    getIsBimonthly,
    (data, isBimonthly) => {
        if (!data.lines_consumption_energies) {
            return [];
        }

        const filteredKw = data.lines_consumption_energies.filter(
            (line) => parseInt(line.type_line) === 0,
        );
        const sorted = filteredKw.sort(
            (a, b) =>
                parseDate(a.initial_date, 'dd/MM/yyyy') -
                parseDate(b.initial_date, 'dd/MM/yyyy'),
        );

        const daysToSubstract = isBimonthly ? 30 : 15;

        return sorted.map((item) => {
            const label = formatDate(
                subDate(parseDate(item.final_date, 'dd/MM/yyyy'), {
                    days: Math.floor(daysToSubstract),
                }),
                'MMM yy',
            );

            return {
                label: label,
                value: JSON.parse(item.info_price_consumtion),
            };
        });
    },
);

export const getStatusProject = createSelector(
    getModel,
    (model) => model.statusProject,
);

export const getIsFetchingStatusProject = createSelector(
    getStatusProject,
    (model) => model.isLoading,
);

export const getStatusProjectToSelect = createSelector(
    getStatusProject,
    (statusProjects) => {
        return statusProjects.data
            ? statusProjects.data.map((statusProject) => ({
                  color: statusProject.primary_color,
                  label: statusProject.name,
                  value: statusProject.id,
              }))
            : [];
    },
);

export const getStatusProjectOptions = createSelector(
    getStatusProject,
    (statusProjects) => {
        if (!statusProjects.data) return [];

        const statusProjectOptions = statusProjects.data.map((status) => ({
            value: status.id,
            label: status.name,
        }));

        statusProjectOptions.unshift({
            value: '',
            label: i18next.t('Select status'),
            disabled: true,
        });

        return statusProjectOptions;
    },
);

export const getDownloadHistory = createSelector(
    getModel,
    (model) => model.downloadHistory,
);

export const getErrorsDownloadHistory = createSelector(
    getDownloadHistory,
    (downloadHistory) => downloadHistory.error,
);

export const getIsDownloadingHistory = createSelector(
    getDownloadHistory,
    getDataConsumptionEnergy,
    (downloadHistory, dataConsumptionEnergy) =>
        downloadHistory.isFetching || dataConsumptionEnergy.status === 1,
);

export const getHasCommercialOfferEnded = createSelector(
    getDataConsumptionEnergy,
    (data) => data.has_commercial_offer_ended,
);

/* Data CSV */
export const getDownloadCSV = createSelector(
    getModel,
    (model) => model.downloadCSV,
);

export const getDataDownloadCSV = createSelector(
    getDownloadCSV,
    (model) => model.data || '',
);

/** consumption profile */
export const getConsumptionProfile = createSelector(
    getDataConsumptionEnergy,
    (model) => model.consumption_profile,
);

export const getConsumptionProfileFormatted = createSelector(
    getConsumptionProfile,
    (_, ownProps) => ownProps.monthskwh,
    (consumptionProfile, monthskwh) => {
        if (!consumptionProfile?.[0]) return null;

        const { profileFormattedByPeriods } = getDataProfileFormattedByPeriods({
            profile: consumptionProfile?.[0]?.consumption,
            summary: monthskwh,
        });

        return profileFormattedByPeriods;
    },
);

/* Schedule rate configuration */
export const getScheduleRateConfigurationModel = createSelector(
    getModel,
    (model) => model.scheduleRateConfiguration,
);

export const getScheduleRateConfigurationData = createSelector(
    getModel,
    (model) => model?.scheduleRateConfiguration?.data || {},
);

export const getIsFetchingScheduleRateConfiguration = createSelector(
    getModel,
    (model) => model?.scheduleRateConfiguration?.isFetching,
);
