import React, { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, IconButton, Typography } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { BATTERY_OPERATION_STRATEGY } from 'common/constants';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import { DEFAULT_DOD } from '../constants';
import { applyFormat } from '../helpers';

import AccordionComponent from './Accordion';
import ActiveTierFields from './ActiveTierFields';
import IndicatorsModal from './IndicatorsModal';

const TimeShifting = ({
    control,
    getValues,
    initialValues,
    isDisabled,
    offerStorageData,
    setValue,
}) => {
    const { t } = useTranslation();
    const [showDetails, setShowDetails] = useState(false);
    const { discharge_power_placeholder, load_capacity } = initialValues;
    const countryCurrencyLocale = getCountryCurrencyLocale();

    const handleShowDetails = () => setShowDetails((prev) => !prev);

    const strategyOptions = [
        {
            label: t('Max power'),
            value: BATTERY_OPERATION_STRATEGY.MAX_POWER,
        },
        {
            label: t('Optimal hourly'),
            value: BATTERY_OPERATION_STRATEGY.OPTIMAL,
        },
    ];

    const onChangeActivePeakShaving = (value) => {
        if (!value) return;

        const values = getValues('peak_shaving');

        for (let index = 0; index < (values?.length || 0); index++) {
            if (values?.[index]?.max_power) continue;
            setValue(`peak_shaving.${index}.max_power`, 0);
        }
    };

    return (
        <>
            <Box px={1}>
                <ActiveTierFields
                    activeKey="peak_shaving_is_active"
                    caption={t(
                        'Limit the power demanded in the corresponding contracted demand',
                    )}
                    control={control}
                    disabled={isDisabled}
                    fieldsKey="peak_shaving"
                    onChangeActiveField={onChangeActivePeakShaving}
                    title="Peak Shaving"
                />

                <ActiveTierFields
                    activeKey="controlled_charging_is_active"
                    caption={t(
                        'Limit the maximum power demanded by the storage system in the corresponding contracted demand',
                    )}
                    control={control}
                    disabled={isDisabled}
                    fieldsKey="charge_limits"
                    title={t('Charge power limit')}
                />

                <ActiveTierFields
                    activeKey="controlled_discharging_is_active"
                    caption={t(
                        'Limit the maximum power that the storage system can deliver in the corresponding contracted demand',
                    )}
                    control={control}
                    disabled={isDisabled}
                    fieldsKey="discharge_limits"
                    title={t('Discharge power limit')}
                />

                <AccordionComponent defaultExpanded title={t('Storage system')}>
                    <Typography variant="body2" fontWeight="bold" pl={2} mb={2}>
                        {`${t('Assigned energy')}: ${applyFormat(
                            load_capacity,
                            countryCurrencyLocale,
                            2,
                        )} kWh`}
                    </Typography>

                    <Grid columns={12} container alignItems="center" mb={2}>
                        <Grid item lg={4} sm={6} xs={12}>
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                append="kW"
                                control={control}
                                disabled={isDisabled}
                                fullWidth
                                label={t('Inverter power')}
                                min={0}
                                name="discharge_power"
                                placeholder={discharge_power_placeholder}
                            />
                        </Grid>

                        <Grid item lg={4} sm={6} xs={12}>
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                append="%"
                                control={control}
                                disabled={isDisabled}
                                fullWidth
                                label={t('Efficiency')}
                                max={100}
                                min={0}
                                name="efficiency"
                            />
                        </Grid>

                        <Grid item lg={4} sm={6} xs={12}>
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                append="%"
                                control={control}
                                disabled={isDisabled}
                                fullWidth
                                label={t('Degradation')}
                                max={100}
                                min={0}
                                name="degradation_percentage"
                            />
                        </Grid>

                        <Grid item lg={4} sm={6} xs={12}>
                            <ReactHookFormSelect
                                control={control}
                                disabled={isDisabled}
                                label={t('Charge strategy')}
                                name="charging_algorithm"
                                options={strategyOptions}
                            />
                        </Grid>

                        <Grid item lg={4} sm={6} xs={12}>
                            <ReactHookFormSelect
                                control={control}
                                disabled={isDisabled}
                                label={t('Discharge strategy')}
                                name="algorithm"
                                options={strategyOptions}
                            />
                        </Grid>

                        <Grid item lg={4} sm={6} xs={12}>
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                append="%"
                                control={control}
                                disabled={isDisabled}
                                fullWidth
                                label={t('Depth of discharge')}
                                max={100}
                                min={0}
                                placeholder={`${
                                    offerStorageData?.battery_offer?.[0]?.dod *
                                        100 || DEFAULT_DOD
                                }`}
                                name="dod"
                            />
                        </Grid>

                        <Grid item lg={4} sm={6} xs={12}>
                            <ReactHookFormSelect
                                control={control}
                                disabled={isDisabled}
                                label={t('C-rate')}
                                name="c_rate"
                                options={[
                                    { label: '2C', value: 2 },
                                    { label: '1C', value: 1 },
                                    { label: '0.5C', value: 0.5 },
                                    { label: '0.25C', value: 0.25 },
                                ]}
                            />
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12}>
                            <ReactHookFormCheck
                                control={control}
                                disabled={isDisabled}
                                label={t('Charge with solar surplus')}
                                name="exported_energy_charge"
                            />
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12} textAlign="center">
                            <Button variant="text" onClick={handleShowDetails}>
                                {t('Indicator', { count: 2 })}
                            </Button>
                            <IconButton
                                href="https://academy.sunwise.mx/es/articles/7669413-peak-shaving-time-shifting-summary"
                                target="_blank"
                            >
                                <InfoIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </AccordionComponent>
            </Box>

            <IndicatorsModal
                countryCurrencyLocale={countryCurrencyLocale}
                initialValues={initialValues}
                onClose={handleShowDetails}
                open={showDetails}
                title={t('Indicator', { count: 2 })}
            />
        </>
    );
};

TimeShifting.propTypes = {
    control: PropTypes.object,
    getValues: PropTypes.func,
    initialValues: PropTypes.object,
    isDisabled: PropTypes.bool,
    offerStorageData: PropTypes.object,
    setValue: PropTypes.func,
};

export default TimeShifting;
