import { importFile } from 'common/api/v1/contacts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { SET_IS_UPLOADING } from '../actionTypes';
import { actions } from '../reducer';

import filterContacts from './filterContacts';

export default (file, filterData) => (dispatch) => {
    dispatch(actions[SET_IS_UPLOADING](true));
    importFile({ file })
        .then(() => {
            dispatch(actions[SET_IS_UPLOADING](false));
            dispatch(filterContacts(filterData));
            showToast();
        })
        .catch((error) => {
            dispatch(actions[SET_IS_UPLOADING](false));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
