import React, { useRef } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Box, IconButton } from 'sunwise-ui';

import { URL_TYPES } from 'common/constants';
import { handleFileURL } from 'common/utils/helpers';

const FileCell = ({
    control,
    disabled,
    handleDownloadLisaFile,
    handleUploadFile,
    name,
    urlType,
}) => {
    const fileInputRef = useRef(null);

    const handleIconClick = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    };

    const handleFileChange = (event, onInputChange) => {
        const files = event.target.files;

        const newValue = Array.from(files)?.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                typeFile: file.type,
                sizeFile: file.size,
            }),
        );

        if (onInputChange) onInputChange({ target: { value: newValue } });

        if (handleUploadFile) handleUploadFile(files);
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <Box display="flex" gap={1} justifyContent="center">
                    <input
                        accept=".pdf,.xml,.png"
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(e, onChange)}
                        disabled={disabled}
                    />
                    <IconButton
                        color="secondary"
                        disabled={disabled}
                        onClick={handleIconClick}
                        size="small"
                    >
                        <UploadIcon fontSize="small" />
                    </IconButton>

                    {isString(value) && (
                        <>
                            {urlType !== URL_TYPES.LISA && (
                                <IconButton
                                    color="info"
                                    href={handleFileURL(
                                        value,
                                        import.meta.env.VITE_API_URL,
                                    )}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    size="small"
                                >
                                    <DownloadIcon fontSize="small" />
                                </IconButton>
                            )}

                            {urlType === URL_TYPES.LISA && (
                                <IconButton
                                    color="info"
                                    onClick={() =>
                                        handleDownloadLisaFile(value)
                                    }
                                    size="small"
                                >
                                    <DownloadIcon fontSize="small" />
                                </IconButton>
                            )}
                        </>
                    )}
                </Box>
            )}
        />
    );
};

FileCell.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    handleDownloadLisaFile: PropTypes.func,
    handleUploadFile: PropTypes.func,
    name: PropTypes.string,
    urlType: PropTypes.number,
};

export default FileCell;
