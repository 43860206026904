import React from 'react';

import SelectionArea from '@viselect/react';
import PropTypes from 'prop-types';
import { Grid, Typography } from 'sunwise-ui';

import './index.css';

const SelectionAreaComponent = ({
    columns,
    isSelectable = false,
    onMove,
    onStart,
    onStop,
    selected,
    selectionAreaClass = 'selection-area',
}) => {
    const extractIds = (elements) =>
        elements
            .map((el) => ({
                key: Number(el.getAttribute('data-key')),
                selectable: el.getAttribute('is-selectable'),
            }))
            .filter(
                (el) => (el.key || el.key === 0) && el.selectable === 'true',
            );

    const _onStart = ({ event, selection }) => {
        if (!event?.ctrlKey && !event?.metaKey) {
            //Clear the selection (first true) without triggering the onMove/onStop event (second true)
            selection.clearSelection(true, true);
            if (onStart) onStart({ event, selection });
        }
    };

    const _onMove = ({
        store: {
            changed: { added, removed },
        },
    }) => {
        if (!added?.length && !removed?.length) return;
        const itemsAdded = extractIds(added);
        const itemsRemoved = extractIds(removed);

        if (onMove) onMove({ added: itemsAdded, removed: itemsRemoved });
    };

    return (
        <SelectionArea
            className="selection-area-component"
            onMove={_onMove}
            onStart={_onStart}
            onStop={onStop}
            selectables=".selectable"
            selectionAreaClass={selectionAreaClass}
            selectionContainerClass="selection-area-container"
        >
            <Grid
                columns={columns}
                container
                gap={0}
                m={0}
                textAlign="center"
                width="100%"
            >
                {selected?.map((item, index) => (
                    <Grid
                        className="selectable"
                        data-key={index}
                        is-selectable={`${isSelectable}`}
                        item
                        key={index}
                        sx={{
                            backgroundColor: item.color,
                            '&.MuiGrid-item': { p: 1 },
                        }}
                        xs={1}
                    >
                        <Typography variant="h6">{item?.value}</Typography>
                    </Grid>
                ))}
            </Grid>
        </SelectionArea>
    );
};

SelectionAreaComponent.propTypes = {
    columns: PropTypes.number,
    isSelectable: PropTypes.bool,
    onMove: PropTypes.func,
    onStart: PropTypes.func,
    onStop: PropTypes.func,
    selected: PropTypes.array,
    selectionAreaClass: PropTypes.string,
};

export default SelectionAreaComponent;
