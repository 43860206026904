import { createProject } from 'common/api/v1/contactProject';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import fetchUserSettings from '../../profile/actions/fetchProfileData';
import {
    SAVE_PROJECT,
    SAVE_PROJECT_SUCCESS,
    SAVE_PROJECT_FAILURE,
} from '../actionTypes';
import { actions } from '../reducer';

import setModalIsOpen from './setModalIsOpen';

export default (values, callbackSuccess, onSuccess) => (dispatch) => {
    dispatch(actions[SAVE_PROJECT]());
    createProject(values)
        .then((response) => {
            dispatch(actions[SAVE_PROJECT_SUCCESS](response.data));
            dispatch(setModalIsOpen(false));
            dispatch(fetchUserSettings());
            if (callbackSuccess) callbackSuccess(response.data);
            if (onSuccess) onSuccess(response.data);
        })
        .catch((error) => {
            dispatch(
                actions[SAVE_PROJECT_FAILURE](error?.response?.data?.errors),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
