import React, { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    Container as MaterialContainer,
    Grid,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';
import { LoadingContext } from 'common/utils/contexts';

import contactForm from '../contactForm';

import * as actions from './actions';
import Content from './components/Content';
import ReasignForm from './components/ReasignForm';
import * as selectors from './selectors';

const Container = ({
    agents,
    canDelete,
    canModify,
    canView,
    closeReasignModal,
    contacts,
    downloadFile,
    filterContacts,
    handleClickBulkItems,
    handleSaveRow,
    hasContacts,
    initialize,
    isDownloading,
    isFetchingContacts,
    isFetchingContactsToReasign,
    isFetchingTableSettings,
    isInitializing,
    isOpenReasignModal,
    isUploading,
    openFormNewContact,
    paginationData,
    reset,
    tableSettings,
    uploadFile,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [filterData, setFilterData] = useState({
        agentId: '',
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
        status: 'active',
    });

    useEffect(() => {
        initialize(filterData);
        return () => reset();
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isDownloading)
            loadingContext.openLoading(t('Downloading document').concat('...'));
        else loadingContext.closeLoading();
    }, [isDownloading]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isUploading)
            loadingContext.openLoading(t('Loading document').concat('...'));
        else loadingContext.closeLoading();
    }, [isUploading]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isFetchingContactsToReasign)
            loadingContext.openLoading(t('Loading').concat('...'));
        else loadingContext.closeLoading();
    }, [isFetchingContactsToReasign]);

    const handlePrepareUpload = (file) => uploadFile(file, filterData);

    return (
        <>
            <MaterialContainer maxWidth={false}>
                <HeaderContainer>
                    <Grid item xs>
                        <TitleIcon title={t('Contact', { count: 2 })} />
                    </Grid>

                    <Grid item xs sx={{ textAlign: 'right' }}>
                        <Button
                            className="__intercom_add_contact_button"
                            dataIntercomTarget="Add Contact (Button)"
                            endIcon={<AddIcon />}
                            id="add-contact-button"
                            onClick={() => openFormNewContact()}
                            type="button"
                            variant="outlined"
                            visible={canModify}
                        >
                            {t('Create contact')}
                        </Button>
                    </Grid>
                </HeaderContainer>

                <Content
                    agents={agents}
                    canDelete={canDelete}
                    canModify={canModify}
                    canView={canView}
                    contacts={contacts}
                    downloadFile={(downloadType) =>
                        downloadFile(filterData, downloadType)
                    }
                    filterContacts={filterContacts}
                    filterData={filterData}
                    handleClickBulkItems={handleClickBulkItems}
                    handleSaveRow={handleSaveRow}
                    hasContacts={hasContacts}
                    isFetching={isFetchingContacts || isFetchingTableSettings}
                    isInitializing={isInitializing}
                    openFormNewContact={openFormNewContact}
                    paginationData={paginationData}
                    setFilterData={setFilterData}
                    uploadFile={handlePrepareUpload}
                    tableSettings={tableSettings}
                />
            </MaterialContainer>

            <contactForm.Container
                canDelete={canDelete}
                canModify={canModify}
                handleAfterSave={filterContacts}
            />

            <Dialog
                onClose={() => closeReasignModal()}
                open={isOpenReasignModal}
                size="sm"
                title={t('Assign new agent', { count: 2 })}
            >
                <ReasignForm filterData={filterData} />
            </Dialog>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    agents: selectors.getAgentsData,
    contacts: selectors.getContactsData,
    hasContacts: selectors.getHasContacts,
    isDownloading: selectors.getIsDownloading,
    isFetchingContacts: selectors.getIsFetchingContacts,
    isFetchingContactsToReasign: selectors.getIsFetchingContactsToReasign,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    isInitializing: selectors.getIsInitializing,
    isOpenReasignModal: selectors.getIsOpenReasignModal,
    isUploading: selectors.getIsUploading,
    paginationData: selectors.getContactsPagination,
    tableSettings: customColumnsSelectors.getTableSettingsData,
});

const mapDispatchToProps = (dispatch) => ({
    closeReasignModal: () => dispatch(actions.closeReasignModal()),
    downloadFile: (filterData, type) =>
        dispatch(actions.downloadFile(filterData, type)),
    filterContacts: (filterData) =>
        dispatch(actions.filterContacts(filterData)),
    handleClickBulkItems: (ids, value, filterData) =>
        dispatch(actions.prepareBulkItems(ids, value, filterData)),
    handleSaveRow: (item) => dispatch(actions.prepareUpdateRow(item)),
    initialize: (filterData) => dispatch(actions.initialFetching(filterData)),
    openFormNewContact: () => dispatch(actions.openFormNewContact()),
    reset: () => dispatch(actions.reset()),
    uploadFile: (file, filterData) =>
        dispatch(actions.uploadFile(file, filterData)),
});

Container.propTypes = {
    agents: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    closeReasignModal: PropTypes.func,
    contacts: PropTypes.array,
    downloadFile: PropTypes.func,
    filterContacts: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleSaveRow: PropTypes.func,
    hasContacts: PropTypes.bool,
    initialize: PropTypes.func,
    isDownloading: PropTypes.bool,
    isFetchingContacts: PropTypes.bool,
    isFetchingContactsToReasign: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isOpenReasignModal: PropTypes.bool,
    isUploading: PropTypes.bool,
    openFormNewContact: PropTypes.func,
    paginationData: PropTypes.object,
    reset: PropTypes.func,
    tableSettings: PropTypes.array,
    uploadFile: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.CONTACTS_PERMISSION),
)(Container);
