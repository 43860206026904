import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const PowerFactorFields = ({
    control,
    isLocked,
    setValue,
    showPowerFactor,
}) => {
    const { t } = useTranslation();
    const apply_desired_power_factor = useWatch({
        control,
        name: 'apply_desired_power_factor',
    });

    useEffect(() => {
        if (setValue) setValue('showPowerFactor', showPowerFactor);
    }, [showPowerFactor, setValue]);

    if (!showPowerFactor) return null;

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Power factor')}
                </TitleWithDetail>
            </Box>

            <Box alignItems="center" display="flex">
                <ReactHookFormCheck
                    control={control}
                    disabled={isLocked}
                    name="apply_desired_power_factor"
                />

                <ReactHookFormIntlNumberInput
                    control={control}
                    disabled={isLocked || !apply_desired_power_factor}
                    fullWidth
                    label={t('Target power factor')}
                    max={100}
                    min={0}
                    name="desired_power_factor"
                />
            </Box>
        </>
    );
};

PowerFactorFields.propTypes = {
    control: PropTypes.object,
    isLocked: PropTypes.bool,
    setValue: PropTypes.func,
    showPowerFactor: PropTypes.object,
};

export default PowerFactorFields;
