import BaseModel from './BaseModel';

export default class UnitRate extends BaseModel {}

UnitRate.modelName = 'UnitRate';
UnitRate.prepareFields({
    id: 'id',
    name: 'name',
    typeConcept: 'type_concept',
});
