import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

// PRICING
export const getPricing = createSelector(
    getModel,
    (model) => model.fetchPricing,
);

export const getIsFetchingPricing = createSelector(
    getPricing,
    (value) => value.isFetching,
);

export const getPricingData = createSelector(getPricing, (value) => value.data);

// CONFIRM SUBSCRIPTION
export const getConfirmSubscription = createSelector(
    getModel,
    (model) => model.confirmSubscription,
);

export const getIsSavingSubscription = createSelector(
    getConfirmSubscription,
    (value) => value.isSaving,
);

// SUBSCRIPTION
export const getSubscription = createSelector(
    getModel,
    (model) => model.subscription,
);

export const getIsFetchingSubscription = createSelector(
    getSubscription,
    (value) => value.isFetching,
);

export const getSubscriptionData = createSelector(
    getSubscription,
    (value) => value.data || {},
);

export const getSubscriptionErrors = createSelector(
    getSubscription,
    (value) => value.errors,
);
