import { getIndicatorsSettings } from 'common/api/v1/reporterIndicators';

import {
    FETCH_INDICATORS_SETTINGS,
    FETCH_INDICATORS_SETTINGS_FAILURE,
    FETCH_INDICATORS_SETTINGS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) =>
    new Promise((resolve) => {
        dispatch(actions[FETCH_INDICATORS_SETTINGS]());

        getIndicatorsSettings()
            .then((response) =>
                dispatch(
                    actions[FETCH_INDICATORS_SETTINGS_SUCCESS](
                        response.data.data,
                    ),
                ),
            )
            .catch((error) =>
                dispatch(actions[FETCH_INDICATORS_SETTINGS_FAILURE](error)),
            )
            .finally(resolve);
    });
