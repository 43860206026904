import { generatePreview } from 'common/api/v1/smartDocuments';

import { FETCH_CUSTOM_TEMPLATE } from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

import fetchCustomTemplate from './fetchCustomTemplate';

export default ({ initTemplate, documentId }) =>
    (dispatch) => {
        dispatch(multiTemplateReviewActions[FETCH_CUSTOM_TEMPLATE]());

        generatePreview(documentId)
            .then(() =>
                dispatch(fetchCustomTemplate(documentId, initTemplate, 2)),
            )
            .catch(() => {});
    };
