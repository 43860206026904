import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import BranchOfficeSelectTypeRHF from 'common/modules/multiBranches/components/BranchOfficeSelectTypeRHF';

import { DUPLICATE_TEMPLATE_MODE, UPDATE_TEMPLATE_MODE } from '../../constants';
import {
    getMainDataButtonText,
    getAvailableLanguagesToSelect,
} from '../../helpers';
import * as selectors from '../../selectors';

const MainDataStep = ({
    branchSelected,
    control,
    isForSummaryTemplate,
    languagesToSelect,
    templateMode,
}) => {
    const [baseTemplateLanguages, branchOffices] = useWatch({
        control,
        name: ['baseTemplateLanguages', 'branch_offices'],
    });
    const { t } = useTranslation();
    return (
        <>
            <ReactHookFormInput
                control={control}
                label={t('Title')}
                maxLength="254"
                name="title"
                placeholder={t('Title')}
            />

            <BranchOfficeSelectTypeRHF
                control={control}
                disabled={templateMode === UPDATE_TEMPLATE_MODE}
                label={t('Branch office')}
                multiMode={branchSelected === 'all'}
                name="branch_offices"
                value={branchOffices}
            />

            <ReactHookFormSelect
                control={control}
                disabled={templateMode === UPDATE_TEMPLATE_MODE}
                label={t('Select a language')}
                name="language"
                options={getAvailableLanguagesToSelect(
                    baseTemplateLanguages,
                    languagesToSelect,
                )}
            />

            {isForSummaryTemplate && (
                <ReactHookFormSelect
                    control={control}
                    disabled={
                        templateMode === DUPLICATE_TEMPLATE_MODE ||
                        templateMode === UPDATE_TEMPLATE_MODE
                    }
                    label={t('Select proposal', { count: 2 })}
                    name="proposals_number"
                    options={[
                        {
                            label: t('Select a type'),
                            value: '',
                            disabled: true,
                        },
                        {
                            label: t('Compare {{proposals}} proposals', {
                                proposals: 2,
                            }),
                            value: 2,
                        },
                        {
                            label: t('Compare {{proposals}} proposals', {
                                proposals: 3,
                            }),
                            value: 3,
                        },
                    ]}
                />
            )}

            <BottomActions>
                <Box sx={{ pt: 2, width: '100%', textAlign: 'right' }}>
                    <Button
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        type="submit"
                        variant="outlined"
                    >
                        {getMainDataButtonText(templateMode)}
                    </Button>
                </Box>
            </BottomActions>
        </>
    );
};

MainDataStep.propTypes = {
    branchSelected: PropTypes.string,
    control: PropTypes.object,
    isForSummaryTemplate: PropTypes.bool,
    languagesToSelect: PropTypes.array,
    templateMode: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    languagesToSelect: selectors.getLanguagesToSelect,
});

export default connect(mapStateToProps, null)(MainDataStep);
