import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button } from 'sunwise-ui';

import ImportConsumptionsForm from '../../../../../modules/importConsumptionsModal/components/Form';
import * as projectConsumptionsModalActions from '../../../../../modules/projectConsumptionsModal/actions';
import ConsumptionsForm from '../../../../../modules/projectConsumptionsModal/components/ConsumptionsForm';
import * as projectConsumptionsModalSelectors from '../../../../../modules/projectConsumptionsModal/selectors';
import * as supplierIntegrationsActions from '../../../../../modules/supplierIntegrations/actions';
import * as selectors from '../../selectors';

const CustomSaveButtonConsumptionModal = ({
    handleOnSubmit,
    handleSubmit,
    isSaving,
    ratesDictionary,
}) => {
    const { t } = useTranslation();
    return (
        <Button
            disabled={isSaving}
            onClick={handleSubmit((values) =>
                handleOnSubmit({ values, ratesDictionary }),
            )}
            sx={{ order: { xs: 1, sm: 3 } }}
        >
            {t('Create')}
        </Button>
    );
};

CustomSaveButtonConsumptionModal.propTypes = {
    handleOnSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSaving: PropTypes.bool,
    ratesDictionary: PropTypes.object,
};

const CustomFooterImportCSV = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, pt: 2 }}>
            <Button type="submit" sx={{ ml: 'auto' }}>
                {t('Next')}
            </Button>
        </Box>
    );
};

CustomFooterImportCSV.propTypes = {};

const Step3 = ({
    fetchDivisions,
    fetchIntegrations,
    fetchPoliticalDivisions,
    fetchRateDivisions,
    fetchRates,
    fetchRegions,
    fetchSummerMonths,
    initialValues,
    isSaving,
    onSave,
    values,
}) => {
    const { project: step2Values } = values;
    const { type } = step2Values;
    const [innerType, setInnerType] = useState(type);

    const handleOnSubmit = ({ values: formValues, ratesDictionary }) =>
        onSave({ ...values, consumptions: { ...formValues, ratesDictionary } });

    useEffect(() => {
        if (innerType === 'add_consumption') {
            fetchRates();
            fetchRegions();
            fetchSummerMonths();
            fetchPoliticalDivisions('dp1');
            fetchIntegrations();
            if (initialValues?.political_division1)
                fetchRateDivisions(
                    initialValues.political_division1,
                    initialValues.rate_division,
                );
            else fetchDivisions();
        }
    }, [innerType]);

    if (innerType === 'add_consumption') {
        return (
            <ConsumptionsForm
                CustomSaveButton={({ handleSubmit, ratesDictionary }) => (
                    <CustomSaveButtonConsumptionModal
                        handleOnSubmit={handleOnSubmit}
                        handleSubmit={handleSubmit}
                        isSaving={isSaving}
                        onSave={onSave}
                        ratesDictionary={ratesDictionary}
                    />
                )}
            />
        );
    }

    if (innerType === 'import_csv') {
        return (
            <ImportConsumptionsForm
                callbackMasterButton={() => setInnerType('add_consumption')}
                CustomFooter={() => <CustomFooterImportCSV />}
            />
        );
    }
};

const mapStateToProps = createStructuredSelector({
    initialValues: projectConsumptionsModalSelectors.getInitialValues,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    fetchDivisions: () =>
        dispatch(projectConsumptionsModalActions.fetchDivisions()),
    fetchIntegrations: () =>
        dispatch(supplierIntegrationsActions.fetchIntegrations()),
    fetchPoliticalDivisions: (str) =>
        dispatch(projectConsumptionsModalActions.fetchPoliticalDivisions(str)),
    fetchRateDivisions: (politicalDivision, rateDivision) =>
        dispatch(
            projectConsumptionsModalActions.fetchRateDivisions(
                politicalDivision,
                rateDivision,
            ),
        ),
    fetchRates: () => dispatch(projectConsumptionsModalActions.fetchRates()),
    fetchRegions: () =>
        dispatch(projectConsumptionsModalActions.fetchRegions()),
    fetchSummerMonths: () =>
        dispatch(projectConsumptionsModalActions.fetchSummerMonths()),
    resetForm: () => dispatch(projectConsumptionsModalActions.resetForm()),
});

Step3.propTypes = {
    fetchDivisions: PropTypes.func,
    fetchIntegrations: PropTypes.func,
    fetchPoliticalDivisions: PropTypes.func,
    fetchRateDivisions: PropTypes.func,
    fetchRates: PropTypes.func,
    fetchRegions: PropTypes.func,
    fetchSummerMonths: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    onSave: PropTypes.func,
    values: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
