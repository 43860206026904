import { NAME } from './constants';

export const DELETE_OFFER = `${NAME}/DELETE_OFFER`;
export const DELETE_OFFER_FAILURE = `${NAME}/DELETE_OFFER_FAILURE`;
export const DELETE_OFFER_SUCCESS = `${NAME}/DELETE_OFFER_SUCCESS`;

export const CREATE_OFFER = `${NAME}/CREATE_OFFER`;
export const CREATE_OFFER_FAILURE = `${NAME}/CREATE_OFFER_FAILURE`;
export const CREATE_OFFER_SUCCESS = `${NAME}/CREATE_OFFER_SUCCESS`;

export const RESET_DATA = `${NAME}/RESET_DATA`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
