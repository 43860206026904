import React from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from 'sunwise-ui';

import { updatePlaceholder } from '../actions/updatePlaceholders';
import { handleFormatDates, handleNormalizeFields } from '../helpers';

const Wrapper = styled('span')`
    cursor: pointer;
    line-height: initial;
`;

const FormatDatesButton = ({
    disabled,
    getValues,
    handleNormalizeDates,
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    selectedRate,
    setValue,
}) => {
    const { t } = useTranslation();

    const handleOnClick = () => {
        if (disabled) return;

        const formValues = getValues();
        const newSummary = handleFormatDates({
            isBimonthly: formValues?.is_bimonthly === '1',
            summary: formValues?.summary,
        });
        const normalizedSummary = handleNormalizeDates({
            getValues,
            summary: newSummary,
        });
        const normalizedSummaryFields = handleNormalizeFields({
            discardValues: true,
            distribution: formValues?.distribution,
            rate: selectedRate,
            rateConfiguration,
            summary: normalizedSummary,
        });

        setValue('last_consumption', normalizedSummaryFields[0]?.final_date);
        setValue('summary', normalizedSummaryFields);
        updatePlaceholder({
            formValues: { ...formValues, summary: normalizedSummaryFields },
            profilesConsumptionData,
            ratesDictionary,
            setValue,
        });
    };
    return (
        <Box alignItems="center" display="flex" mb={2}>
            <Tooltip
                data-testid="project-consumptions-modal-format-dates-button"
                title={t('Format dates to the last day of the month')}
            >
                <Wrapper onClick={handleOnClick}>
                    <DateRangeIcon />
                </Wrapper>
            </Tooltip>
        </Box>
    );
};

FormatDatesButton.propTypes = {
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    handleNormalizeDates: PropTypes.func,
    profilesConsumptionData: PropTypes.array,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    selectedRate: PropTypes.object,
    setValue: PropTypes.func,
};

export default FormatDatesButton;
