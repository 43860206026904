import { types } from 'sunwise-template-core';

import { getOfferEnded } from 'common/api/v1/newOfferEnded';
import { getSummaryEnded } from 'common/api/v1/newSummaryEnded';
import { getSmartDocumentEnded } from 'common/api/v1/smartDocuments';

import {
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
} from '../actionTypes';
import { summaryTemplateReviewActions } from '../reducer';

const getAction = (templateType) => {
    switch (templateType) {
        case types.MULTIPROPOSAL_TYPE:
            return getSummaryEnded;
        case types.SMART_DOCUMENTS_TYPE:
            return getSmartDocumentEnded;
        default:
            return getOfferEnded;
    }
};

export default (offerId, initTemplate, templateType) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(summaryTemplateReviewActions[FETCH_CUSTOM_TEMPLATE]());

        const action = getAction(templateType);

        return action(offerId)
            .then((response) => {
                dispatch(
                    summaryTemplateReviewActions[FETCH_CUSTOM_TEMPLATE_SUCCESS](
                        response.data,
                    ),
                );
                initTemplate(response.data, offerId);
            })
            .catch((error) =>
                dispatch(
                    summaryTemplateReviewActions[FETCH_CUSTOM_TEMPLATE_FAILURE](
                        error,
                    ),
                ),
            )
            .finally(resolve);
    });
