import { NAME } from './constants';

export const CREATE_PARENT_FIELD_SEGMENTS = `${NAME}/CREATE_PARENT_FIELD_SEGMENTS`;
export const CREATE_PARENT_FIELD_SEGMENTS_FAILURE = `${NAME}/CREATE_PARENT_FIELD_SEGMENTS_FAILURE`;
export const CREATE_PARENT_FIELD_SEGMENTS_SUCCESS = `${NAME}/CREATE_PARENT_FIELD_SEGMENTS_SUCCESS`;

export const DELETE_PARENT_FIELD_SEGMENTS = `${NAME}/DELETE_PARENT_FIELD_SEGMENTS`;
export const DELETE_PARENT_FIELD_SEGMENTS_FAILURE = `${NAME}/DELETE_PARENT_FIELD_SEGMENTS_FAILURE`;
export const DELETE_PARENT_FIELD_SEGMENTS_SUCCESS = `${NAME}/DELETE_PARENT_FIELD_SEGMENTS_SUCCESS`;

export const FETCH_PARENT_FIELD_SEGMENTS = `${NAME}/FETCH_PARENT_FIELD_SEGMENTS`;
export const FETCH_PARENT_FIELD_SEGMENTS_FAILURE = `${NAME}/FETCH_PARENT_FIELD_SEGMENTS_FAILURE`;
export const FETCH_PARENT_FIELD_SEGMENTS_SUCCESS = `${NAME}/FETCH_PARENT_FIELD_SEGMENTS_SUCCESS`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
