import React, { useEffect, useState } from 'react';

import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Typography } from 'sunwise-ui';

import { POLYGON_SEGMENT } from '../constants';
import { hasMissingIrradiation } from '../IrradiationHelpers';
import * as selectors from '../selectors';

const Alerts = ({ irradiationData, segments }) => {
    const { t } = useTranslation();
    const [prevValues, setPrevValues] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        if (!Array.isArray(segments) || !segments.length) {
            setPrevValues([]);
            return;
        }

        const polygonSegments = segments.filter(
            (segment) => segment.type === POLYGON_SEGMENT,
        );

        if (!polygonSegments.length) {
            setPrevValues([]);
            return;
        }

        const currentValues = polygonSegments.map((segment) => {
            const { solar_modules } = segment;
            return solar_modules.map((solar_module) => {
                const { cell, col, enabled, group, path, row } = solar_module;
                return { cell, col, enabled, group, path, row };
            });
        });

        if (!prevValues.length) {
            setPrevValues(currentValues);
            setHasChanges(false);
            return;
        }

        const dif = differenceWith(currentValues, prevValues, isEqual);
        const missing = hasMissingIrradiation(irradiationData, polygonSegments);

        setHasChanges(Boolean(dif.length || missing));
        setPrevValues(currentValues);
    }, [segments, irradiationData]);

    const showAlertIrradiation =
        Array.isArray(irradiationData) &&
        irradiationData.length &&
        (hasChanges || hasMissingIrradiation(irradiationData, segments));

    if (!showAlertIrradiation) return null;

    return (
        <Alert severity="warning">
            <Typography variant="caption" fontWeight="bold">
                {t('There are unsaved changes in the irradiation simulation')}
            </Typography>
        </Alert>
    );
};

const mapStateToProps = createStructuredSelector({
    irradiationData: selectors.getIrradiationData,
    parentFieldSegmentCounters: selectors.getParentFieldSegmentCounters,
});

Alerts.propTypes = {
    irradiationData: PropTypes.array,
    segments: PropTypes.array,
};

export default connect(mapStateToProps, null)(Alerts);
