import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import IcoEmptyReports from 'resources/icoEmptyReports.svg?react';

const EmptyState = ({ addButton, canModify }) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ textAlign: 'center' }}>
            <IcoEmptyReports width="140" />
            <Typography variant="h5">
                {t('This project has no reports')}
            </Typography>
            <Typography sx={{ mb: 2 }} variant="body2">
                {t(
                    'Here you will see all the project reports. Start by creating one!',
                )}
            </Typography>
            {canModify && addButton}
        </Box>
    );
};

EmptyState.propTypes = { addButton: PropTypes.node, canModify: PropTypes.bool };

export default EmptyState;
