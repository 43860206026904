import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'notifications';

export const bulkNotifications = (data) =>
    API.put(`/api/v1/web-${ENTITY}/`, data, config);
export const deleteNotification = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const filterNotifications = ({
    isOnDropdown,
    page = 1,
    perPage,
    showMentions,
    type,
    viewed = false,
}) =>
    API.get(`/api/v1/web-${ENTITY}/`, {
        params: {
            page,
            mentions: showMentions,
            ...(isOnDropdown ? { is_on_dropdown: isOnDropdown } : {}),
            ...(perPage ? { per_page: perPage } : {}),
            ...(type !== 'all' ? { type } : {}),
            ...(viewed !== 'all' ? { viewed } : {}),
        },
    });
export const markAllNotificationsAsRead = () =>
    API.delete(`/api/v1/web-${ENTITY}/`);
export const removeBagdeNotification = () =>
    API.put(`/api/v1/${ENTITY}-bar/?viewed=true`);
export const updateNotification = (id, data) =>
    API.put(`/api/v1/${ENTITY}/${id}`, data);
