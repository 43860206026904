import { NAME } from './constants';

export const DELETE_INTEGRATION = `${NAME}/DELETE_INTEGRATION`;
export const DELETE_INTEGRATION_FAILURE = `${NAME}/DELETE_INTEGRATION_FAILURE`;
export const DELETE_INTEGRATION_SUCCESS = `${NAME}/DELETE_INTEGRATION_SUCCESS`;

export const FETCH_INTEGRATIONS = `${NAME}/FETCH_INTEGRATIONS`;
export const FETCH_INTEGRATIONS_FAILURE = `${NAME}/FETCH_INTEGRATIONS_FAILURE`;
export const FETCH_INTEGRATIONS_SUCCESS = `${NAME}/FETCH_INTEGRATIONS_SUCCESS`;

export const FETCH_PROVIDERS = `${NAME}/FETCH_PROVIDERS`;
export const FETCH_PROVIDERS_FAILURE = `${NAME}/FETCH_PROVIDERS_FAILURE`;
export const FETCH_PROVIDERS_SUCCESS = `${NAME}/FETCH_PROVIDERS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_INTEGRATION = `${NAME}/SAVE_INTEGRATION`;
export const SAVE_INTEGRATION_FAILURE = `${NAME}/SAVE_INTEGRATION_FAILURE`;
export const SAVE_INTEGRATION_SUCCESS = `${NAME}/SAVE_INTEGRATION_SUCCESS`;

export const SET_IS_OPEN_FORM_MODAL = `${NAME}/SET_IS_OPEN_FORM_MODAL`;
