import bulkItems from './bulkItems';
import prepareBulkDelete from './prepareBulkDelete';

export default (ids, value, filterData, callback) => (dispatch) => {
    if (value === 'deleted') {
        dispatch(prepareBulkDelete(ids, value, filterData, callback));
        return false;
    }
    dispatch(bulkItems(ids, value, filterData, callback));
};
