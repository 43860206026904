import i18next from 'i18next';

import { getPdfFile } from 'common/api/v1/renderTemplate';
import showToast from 'common/utils/showToast';

import {
    FETCH_PDF_LINK,
    FETCH_PDF_LINK_SUCCESS,
    FETCH_PDF_LINK_FAILURE,
} from '../actionTypes';
import { summaryTemplateReviewActions } from '../reducer';

export default (
        templateFinishedId,
        renderName,
        downloadFormat,
        openLoading,
        closeLoading,
        templateType,
    ) =>
    (dispatch) => {
        dispatch({ type: FETCH_PDF_LINK });
        openLoading(i18next.t('Generating PDF').concat('...'));
        return getPdfFile({
            quality: downloadFormat,
            renderName,
            templateFinishedId,
            templateType,
        })
            .then((response) => {
                const url = response.data;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${renderName}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                dispatch(
                    summaryTemplateReviewActions[FETCH_PDF_LINK_SUCCESS](
                        response.data,
                    ),
                );
                closeLoading();
                showToast({ body: i18next.t('It was created successfully') });
            })
            .catch((error) => {
                dispatch(
                    summaryTemplateReviewActions[FETCH_PDF_LINK_FAILURE](error),
                );
                closeLoading();
                showToast({
                    type: 'danger',
                    body: i18next.t('An error occurred'),
                });
            });
    };
