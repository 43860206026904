import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getModalIsOpen = createSelector(
    getModel,
    (model) => model.modalIsOpen,
);

export const getSaveProject = createSelector(
    getModel,
    (model) => model.saveProject,
);

export const getSaveProjectData = createSelector(
    getSaveProject,
    (saveProject) => saveProject.data,
);

export const getProjectIsSaving = createSelector(
    getSaveProject,
    (saveProject) => saveProject.isSaving,
);

export const getSaveProjectErrors = createSelector(
    getSaveProject,
    (saveProject) => saveProject.errors,
);
