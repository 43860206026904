import i18next from 'i18next';

import { DO_NOT_ASSIGN_VALUE } from 'common/constants';

export const getStatusOptions = () => {
    return [
        {
            label: i18next.t('All text'),
            value: 'all',
        },
        {
            label: i18next.t('Pending', { count: 2 }),
            value: 'False',
        },
        {
            label: i18next.t('Completed', { count: 2 }),
            value: 'True',
        },
    ];
};

export const getUserOptions = (users) => {
    const items = users.map((item) => {
        return {
            label: `${item.first_name} ${item.last_name}`,
            value: item.id,
        };
    });

    return [
        {
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: '',
        },
        {
            label: i18next.t('No user assigned'),
            value: DO_NOT_ASSIGN_VALUE,
        },
        ...items,
    ];
};
