import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import convertToTemplate from './convertToTemplate';

export default (values) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Convert'),
        defaultTextResponse: `${i18next.t('Copy of')} ${values.name}`,
        haveTextResponse: true,
        messages: [i18next.t('Template name')],
        onSuccess: (value) => {
            dispatch(convertToTemplate({ ...values, name: value }));
        },
        title: i18next.t('Convert to template'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'info',
    };

    dispatch(alerts.actions.show(dataAlert));
};
