import { auditContact } from 'common/api/v1/contacts';
import { activateItem, getItems } from 'common/api/v1/users';
import { showReponseErrorsAsAlert, fullNameBuild } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import fetchUserSettings from '../../profile/actions/fetchProfileData';
import {
    ACTIVATE_ITEM,
    ACTIVATE_ITEM_FAILURE,
    ACTIVATE_ITEM_SUCCESS,
    FETCH_ITEMS_SUCCESS,
    OPEN_REASSIGN_TYPE_DEACTIVATE_MODAL,
} from '../actionTypes';
import { prepareReassignContactsPayload } from '../helpers';
import { companyUsersActions } from '../reducer';
import * as selectors from '../selectors';

import closeReassignModal from './closeReassignModal';

const activeAction = (
    id,
    newValues,
    newItems,
    dispatch,
    isReassignModalOpen,
) => {
    activateItem(newValues, id)
        .then((response) => {
            dispatch(companyUsersActions[ACTIVATE_ITEM_SUCCESS](response.data));
            dispatch(companyUsersActions[FETCH_ITEMS_SUCCESS](newItems));
            dispatch(fetchUserSettings());
            if (isReassignModalOpen) {
                dispatch(closeReassignModal());
            }
            showToast();
        })
        .catch((error) => {
            dispatch(
                companyUsersActions[ACTIVATE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};

export default (currentItem, isReassignModalOpen) => (dispatch, getState) => {
    if (!isReassignModalOpen) {
        dispatch(companyUsersActions[ACTIVATE_ITEM]());
    }
    const state = getState();
    const data = selectors.getDataFetchItems(state);
    const newItems = data.map((item) => {
        if (item.id === currentItem.id) {
            return { ...item, is_enabled: !item.is_enabled };
        }
        return item;
    });

    const newValues = {
        is_enabled: !currentItem.is_enabled,
    };

    if (!newValues.is_enabled) {
        auditContact(currentItem.id)
            .then(async (response) => {
                if (response.data.data.length > 0) {
                    const payload = await prepareReassignContactsPayload(
                        response.data.data,
                        getItems,
                        currentItem.id,
                    );

                    dispatch(
                        companyUsersActions[
                            OPEN_REASSIGN_TYPE_DEACTIVATE_MODAL
                        ]({
                            ...payload,
                            userId: currentItem.id,
                            userName: fullNameBuild({
                                firstName: currentItem.first_name,
                                lastName: currentItem.last_name,
                                secondSurname: currentItem.second_surname,
                            }),
                            dataToSave: currentItem,
                        }),
                    );
                } else {
                    activeAction(
                        currentItem.id,
                        newValues,
                        newItems,
                        dispatch,
                        isReassignModalOpen,
                    );
                }
            })
            .catch((error) =>
                showReponseErrorsAsAlert(dispatch, error?.response),
            );
    } else {
        activeAction(currentItem.id, newValues, newItems, dispatch);
    }
};
