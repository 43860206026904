import i18next from 'i18next';

export const getCustomOfferTemplatesToSelect = (templates) => {
    if (templates === null) {
        return [];
    }

    const tempFilteredTemplates = {};
    const ordered = {};
    const group = `${i18next.t('Select')} ${i18next
        .t('Template')
        .toLowerCase()}`;

    templates.forEach((template) => {
        if (!tempFilteredTemplates[group]) {
            tempFilteredTemplates[group] = {
                group: group,
                label: group,
                options: [],
            };
        }

        tempFilteredTemplates[group].options.push({
            group: group,
            label: template.title,
            value: template.id,
        });
    });

    Object.keys(tempFilteredTemplates)
        .sort()
        .forEach((key) => {
            ordered[key] = tempFilteredTemplates[key];
            ordered[key].options = tempFilteredTemplates[key].options.sort();
        });

    return Object.values(ordered);
};
