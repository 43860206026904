import { object, ref, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import { RESET_PASSWORD_IN_PROFILE } from './constants';

export default () => {
    const { CONFIRM_PASSWORD, minimumNumberCharacters, REQUIRED_TEXT } =
        getValidationTexts();
    return object().shape({
        confirm_password: string()
            .required(REQUIRED_TEXT)
            .when('new_password', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: () =>
                    string().oneOf([ref('new_password')], CONFIRM_PASSWORD),
            }),
        new_password: string()
            .required(REQUIRED_TEXT)
            .min(8, minimumNumberCharacters(8))
            .nullable(),
        old_password: string().when('origin', {
            is: RESET_PASSWORD_IN_PROFILE,
            then: () => string().required(REQUIRED_TEXT).nullable(),
        }),
    });
};
