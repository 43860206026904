import React from 'react';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { DropdownCardMenu } from 'common/components/cards';

import ChartJsComponent from './ChartJsComponent';

const Header = styled(Box)`
    align-items: center;
    border-bottom: 1px solid #eff1fb;
    display: flex;
    justify-content: space-between;
    min-height: 58px;
    width: 100%;
    z-index: 2;
`;

const Title = styled(Typography)`
    font-size: 26px;
    font-weight: bold;
    line-height: 32px;
`;

const DetailContent = ({
    canDelete,
    canModify,
    deleteConsumptionProfile,
    prepareArchiveConsumptionProfile,
    selectedItemModel,
}) => {
    const { t } = useTranslation();
    const { consumption_formatted, id, isArchived, name, type, year } =
        selectedItemModel.ref;
    const showMenu = type !== 0 && (canModify || canDelete);

    return (
        <>
            <Header>
                <Title component="span">{type === 0 ? t(name) : name}</Title>

                {showMenu && (
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: () =>
                                    prepareArchiveConsumptionProfile(
                                        id,
                                        isArchived,
                                    ),
                                icon: isArchived ? (
                                    <UnarchiveOutlinedIcon fontSize="small" />
                                ) : (
                                    <ArchiveOutlinedIcon fontSize="small" />
                                ),
                                label: t(isArchived ? 'Unarchive' : 'Archive'),
                                visible: canModify,
                            },
                            {
                                handleClick: () =>
                                    deleteConsumptionProfile(id, name),
                                icon: <DeleteIcon fontSize="small" />,
                                label: t('Delete'),
                                visible: canDelete && isArchived,
                            },
                        ]}
                    />
                )}
            </Header>

            <Box mt={2}>
                <ChartJsComponent
                    consumptionProfile={consumption_formatted}
                    year={year}
                />
            </Box>
        </>
    );
};

DetailContent.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    deleteConsumptionProfile: PropTypes.func,
    prepareArchiveConsumptionProfile: PropTypes.func,
    selectedItemModel: PropTypes.object,
};

export default DetailContent;
