import React from 'react';

import PropTypes from 'prop-types';
import { Card, Skeleton } from 'sunwise-ui';

const Placeholder = (
    <Card>
        <Card.Body>
            <Skeleton variant="rounded" />
        </Card.Body>
    </Card>
);

const PlaceholderCardDatasheets = ({ children, ready }) => (
    <>{ready ? Placeholder : children}</>
);

PlaceholderCardDatasheets.propTypes = {
    children: PropTypes.element,
    ready: PropTypes.bool,
};

export default PlaceholderCardDatasheets;
