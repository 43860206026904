import { push } from 'connected-react-router';
import { get, isNull } from 'lodash';

import { getAuthMe } from 'common/api/v1/auth';
import { initProfileConfigurationActions } from 'common/utils/helpers/multiregion';
import { initSessionActions } from 'common/utils/helpers/session';

import setCurrentValues from '../../profile/actions/setCurrentValues';
import setTheme from '../../profile/actions/setTheme';
import updateConfiguration from '../../profileConfiguration/actions/updateConfiguration';
import {
    FETCH_DATA,
    FETCH_DATA_FAILURE,
    FETCH_DATA_SUCCESS,
} from '../actionTypes';
import { createAccountActions } from '../reducer';

export default (callback) => (dispatch) => {
    dispatch(createAccountActions[FETCH_DATA]());

    getAuthMe()
        .then((response) => {
            dispatch(createAccountActions[FETCH_DATA_SUCCESS](response.data));

            const data = response.data;

            const { permissions, ...rest } = data;

            const onboarding = get(data, 'onboarding', null);
            const groups = get(permissions, 'groups', []);

            localStorage.setItem(
                'session',
                JSON.stringify({
                    ...rest,
                    is_multibranches: permissions?.is_multibranches,
                    hasOnboarding: !isNull(onboarding),
                }),
            );
            localStorage.setItem('permissions', JSON.stringify(groups));

            initSessionActions((data) => dispatch(setCurrentValues(data)));
            initProfileConfigurationActions((data) =>
                dispatch(updateConfiguration(data)),
            );

            if (rest.settings !== null) {
                if (rest.settings?.mode)
                    dispatch(setTheme(rest.settings.mode.toLowerCase()));

                localStorage.setItem(
                    'selectedDrawerSize',
                    rest.settings.expanded_sidebar ? 'normal' : 'small',
                );
            }
            dispatch(push('/wizard'));
            callback();
        })
        .catch((error) =>
            dispatch(
                createAccountActions[FETCH_DATA_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
