import React from 'react';

import PropTypes from 'prop-types';

import ProfileMenuItems from './ProfileMenuItems';
import StyledMenu from './StyledMenu';

const ProfileMenu = ({
    anchorProfileMenu,
    match,
    profileValues,
    redirect,
    setAnchorProfileMenu,
    selectedTheme,
    setTheme,
    subscription,
}) => {
    const isMenuOpen = Boolean(anchorProfileMenu);
    const handleCloseProfileMenu = () => setAnchorProfileMenu(null);
    return (
        <StyledMenu
            anchorEl={anchorProfileMenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleCloseProfileMenu}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <ProfileMenuItems
                {...{
                    handleCloseProfileMenu,
                    match,
                    profileValues,
                    redirect,
                    selectedTheme,
                    setTheme,
                    subscription,
                }}
            />
        </StyledMenu>
    );
};

ProfileMenu.propTypes = {
    anchorProfileMenu: PropTypes.object,
    match: PropTypes.object,
    profileValues: PropTypes.object,
    redirect: PropTypes.func,
    setAnchorProfileMenu: PropTypes.func,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
    subscription: PropTypes.object,
};

export default ProfileMenu;
