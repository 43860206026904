import { getProductDatasheets } from 'common/api/v1/datasheets';

import {
    FETCH_PRODUCT_DATASHEETS,
    FETCH_PRODUCT_DATASHEETS_FAILURE,
    FETCH_PRODUCT_DATASHEETS_SUCCESS,
} from '../actionTypes';
import { productDatasheetsActions } from '../reducer';

export default (productKey, productId) => (dispatch) => {
    dispatch(productDatasheetsActions[FETCH_PRODUCT_DATASHEETS]());

    getProductDatasheets(productKey, productId)
        .then((response) =>
            dispatch(
                productDatasheetsActions[FETCH_PRODUCT_DATASHEETS_SUCCESS](
                    response.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                productDatasheetsActions[FETCH_PRODUCT_DATASHEETS_FAILURE](
                    error,
                ),
            ),
        );
};
