import { array, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        battery_hourly_status: object().shape({
            saturday: array().of(
                object().shape({
                    color: string().required(REQUIRED_TEXT).nullable(),
                }),
            ),
            sunday: array().of(
                object().shape({
                    color: string().required(REQUIRED_TEXT).nullable(),
                }),
            ),
            weekday: array().of(
                object().shape({
                    color: string().required(REQUIRED_TEXT).nullable(),
                }),
            ),
        }),
    });
};
