import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TableChartIcon from '@mui/icons-material/TableChart';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import { CheckAccess } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import {
    getHasPaymentDue,
    getIsColombianAccount,
} from 'common/utils/helpers/session';

import IcoAlliance from 'resources/iconAlliance.svg?react';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledStyledListItemText from './StyledStyledListItemText';

const SalesOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const hasPaymentDue = getHasPaymentDue();
    const isColombianAccount = getIsColombianAccount();

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
                    PERMISSION_LIST.DASHBOARD_PERMISSION,
                    PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    className="__userguiding_dashboard_module_navbar_item"
                    disabled={hasPaymentDue}
                    id="navbar-item-dashboard"
                    onClick={() => {
                        redirect('/dashboard');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={match.path === '/' || match.path === '/dashboard'}
                    variant="horizontal"
                >
                    <DashboardIcon />
                    <StyledStyledListItemText primary="Dashboard" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.FUNNEL_PROJECTS_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/project-funnel');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/project-funnel' === match.path}
                    variant="horizontal"
                >
                    <TableChartIcon />
                    <StyledStyledListItemText primary={t('Project funnel')} />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                isHidden={isColombianAccount}
                requiredPermissions={[
                    PERMISSION_LIST.FUNNEL_FINANCING_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/financing-funnel');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/financing-funnel' === match.path}
                    variant="horizontal"
                >
                    <IcoAlliance />
                    <StyledStyledListItemText primary={t('Financing funnel')} />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/proposals');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/proposals' === match.path}
                    variant="horizontal"
                >
                    <ArticleIcon />
                    <StyledStyledListItemText
                        primary={t('Proposal', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

SalesOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
};

export default SalesOptions;
