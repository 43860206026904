import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import {
    arraytoDictionary,
    getCurrenciesToSelect,
    numberFormat,
} from 'common/utils/helpers';

import {
    temporalOptions,
    temporalSourceOptions,
} from '../../multiCurrency/helpers';
import * as actions from '../actions';
import { handleExchangeRateBuild } from '../helpers';
import * as selectors from '../selectors';

const CurrencySettings = ({
    control,
    countryCurrencyLocale,
    currencies,
    dollarPriceData,
    fetchDollarPrice,
    formValues,
    isFetchingDollarPrice,
    setValue,
}) => {
    const { t } = useTranslation();
    const [manualDollarPrice, setManualDollarPrice] = useState(
        formValues?.dollar_price,
    );
    const isoCode = get(formValues, 'currency_obj.abbreviation', null);
    const symbol = get(formValues, 'currency_obj.symbol', null);
    const exchangeType = get(formValues, 'exchange_type', null);
    const isDisabled = !get(formValues, 'is_enabled', false);
    const isAuto = exchangeType === 'AUT';
    const isManual = exchangeType === 'MNL';
    const isDisabledSource = isDisabled || isEmpty(exchangeType) || isManual;
    const isDisabledPrice =
        isDisabled ||
        isFetchingDollarPrice ||
        isEmpty(exchangeType) ||
        exchangeType === 'AUT';

    useEffect(() => {
        if (!isEmpty(dollarPriceData)) {
            const { dollar_price, inverse_dollar_price } = dollarPriceData;
            setValue('dollar_price', dollar_price);
            setValue('inverse_dollar_price', inverse_dollar_price);
        }
    }, [dollarPriceData]);

    const newPrice = (value) => {
        if (value > 0) {
            return 1 / value;
        }
        return 0;
    };

    const handleChangeDollarPrice = (value) => {
        if (value) {
            setValue('inverse_dollar_price', newPrice(value));
            setManualDollarPrice(value);
        }
    };

    const handleOnChangeCurrency = (value) => {
        const curreciesDictionary = arraytoDictionary(currencies, 'id');
        if (curreciesDictionary[value]) {
            handleExchangeRateBuild(curreciesDictionary[value], setValue);
        }
    };

    const dollarPriceLabel = `${t(
        'Equivalence against the Dollar',
    )} (${numberFormat(1, {
        locale: countryCurrencyLocale,
        style: 'decimal',
        unit: 'USD',
    })})`;

    return (
        <Grid container>
            {isFetchingDollarPrice && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
            <Grid item xs={18} md={isoCode === 'USD' ? 18 : 9}>
                <ReactHookFormSelect
                    control={control}
                    label={t('Default', { context: 'female' })}
                    name="currency"
                    onChange={(e) => handleOnChangeCurrency(e?.target?.value)}
                    options={getCurrenciesToSelect(currencies, false)}
                />
            </Grid>
            <Grid hidden={isoCode === 'USD'} item xs={18} md={9}>
                <ReactHookFormSelect
                    control={control}
                    disabled={isDisabled}
                    fullWidth
                    label={t('Exchange rate')}
                    name="exchange_type"
                    onChange={(e) => {
                        if (e?.target?.value === 'MNL')
                            setValue('dollar_price', manualDollarPrice);
                        else fetchDollarPrice(formValues?.source);
                    }}
                    options={temporalOptions(t)}
                />
            </Grid>
            <Grid
                hidden={isDisabledSource || isoCode === 'USD'}
                item
                xs={18}
                md={9}
            >
                <ReactHookFormSelect
                    control={control}
                    label={t('Source')}
                    name="source"
                    onChange={(e) => fetchDollarPrice(e?.target?.value)}
                    options={temporalSourceOptions(isoCode, t)}
                    type="text"
                />
            </Grid>
            <Grid hidden={isoCode === 'USD'} item xs={18} md={isAuto ? 9 : 18}>
                <ReactHookFormIntlNumberInput
                    append={isoCode}
                    control={control}
                    disabled={isDisabledPrice}
                    fullWidth
                    label={dollarPriceLabel}
                    min="0"
                    name="dollar_price"
                    onChange={(e) => handleChangeDollarPrice(e?.target?.value)}
                    prepend={symbol}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingDollarPrice: selectors.getIsFetchingDollarPrice,
    dollarPriceData: selectors.getDollarPriceData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchDollarPrice: (source) => dispatch(actions.fetchDollarPrice(source)),
});

CurrencySettings.propTypes = {
    control: PropTypes.object,
    countryCurrencyLocale: PropTypes.string,
    currencies: PropTypes.array,
    dollarPriceData: PropTypes.object,
    fetchDollarPrice: PropTypes.func,
    formValues: PropTypes.object,
    isFetchingDollarPrice: PropTypes.bool,
    setValue: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySettings);
