import { object, number, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import { VIEW_OPTIONS } from './constants';

export const measurementSourceValidation = () => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        date: string().required(REQUIRED_TEXT).nullable(),
        view_option: number().required(REQUIRED_TEXT).nullable(),
    });
};

export const generationAccuracyValidation = () => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        view_option: number().required(REQUIRED_TEXT).nullable(),
        date_range: string().when('view_option', {
            is: VIEW_OPTIONS.MONTHLY,
            then: () => string().required(REQUIRED_TEXT),
            otherwise: () => string().nullable(),
        }),
    });
};
