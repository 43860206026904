import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Box } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import ResultsBox from './components/ResultsBox';
import SearchField from './components/SearchField';

function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback && callback();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

const Container = ({ getPermissionsByCode, searchFieldRef }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const wrapperRef = useRef(null);
    const resultBoxRef = useRef(null);

    const handleClick = () => setAnchorEl(wrapperRef.current);

    const clearSearch = () => {
        setSearchQuery('');
        setAnchorEl(null);
    };

    const { canView: canViewContacts = false } =
        getPermissionsByCode(PERMISSION_LIST.CONTACTS_PERMISSION) || {};
    const { canView: canViewProjects = false } =
        getPermissionsByCode(PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION) || {};
    const { canView: canViewProposals = false } =
        getPermissionsByCode(PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION) ||
        {};

    useOutsideAlerter(wrapperRef, () => clearSearch());

    if (!canViewContacts && !canViewProjects && !canViewProposals) return null;

    return (
        <Box id="search-wrapper" ref={wrapperRef} sx={{ ml: 1 }}>
            <SearchField
                clearSearch={clearSearch}
                handleClick={handleClick}
                open={open}
                resultBoxRef={resultBoxRef}
                searchFieldRef={searchFieldRef}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            />

            <ResultsBox
                anchorEl={anchorEl}
                canViewContacts={canViewContacts}
                canViewProjects={canViewProjects}
                canViewProposals={canViewProposals}
                clearSearch={clearSearch}
                open={open}
                resultBoxRef={resultBoxRef}
                searchFieldRef={searchFieldRef}
                searchQuery={searchQuery}
                setAnchorEl={setAnchorEl}
            />
        </Box>
    );
};

Container.propTypes = {
    getPermissionsByCode: PropTypes.func,
    searchFieldRef: PropTypes.object,
};

export default compose(
    withPermissions([
        PERMISSION_LIST.CONTACTS_PERMISSION,
        PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION,
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
    ]),
)(Container);
