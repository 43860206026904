import { NAME } from './constants';

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const FETCH_CONTACTS_TRACKINGS = `${NAME}/FETCH_CONTACTS_TRACKINGS`;
export const FETCH_CONTACTS_TRACKINGS_FAILURE = `${NAME}/FETCH_CONTACTS_TRACKINGS_FAILURE`;
export const FETCH_CONTACTS_TRACKINGS_SUCCESS = `${NAME}/FETCH_CONTACTS_TRACKINGS_SUCCESS`;

export const INITIALIZE_TRACKING_FORM = `${NAME}/INITIALIZE_TRACKING_FORM`;

export const OPEN_MODAL_TRACKING = `${NAME}/OPEN_MODAL_TRACKING`;

export const RESET_TRACKING_FORM = `${NAME}/RESET_TRACKING_FORM`;

export const SAVE_BULK_TRACKINGS = `${NAME}/SAVE_BULK_TRACKINGS`;
export const SAVE_BULK_TRACKINGS_FAILURE = `${NAME}/SAVE_BULK_TRACKINGS_FAILURE`;
export const SAVE_BULK_TRACKINGS_SUCCESS = `${NAME}/SAVE_BULK_TRACKINGS_SUCCESS`;

export const UPDATE_ITEMS_SUCCESS = `${NAME}/UPDATE_ITEMS_SUCCESS`;
