import { create } from 'common/api/v2/customerEnergyWithoutConsumption';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import * as projectConsumptionCardActions from '../../projectConsumptionCard/actions';
import * as projectConsumptionCardSelectors from '../../projectConsumptionCard/selectors';
import { CREATE_OFFER, CREATE_OFFER_FAILURE } from '../actionTypes';
import { buildPayload } from '../helpers';
import { projectCommOfferTableActions } from '../reducer';

export default (values, onSuccessCallback) => (dispatch, getState) => {
    const state = getState();
    const selectedProject =
        projectConsumptionCardSelectors.getSelectedProject(state);

    if (!selectedProject?.id) return;

    const newValues = buildPayload({ values, selectedProject });

    dispatch(projectCommOfferTableActions[CREATE_OFFER]());

    create(newValues, selectedProject.id)
        .then(() => {
            dispatch(
                projectConsumptionCardActions.fetchConsumptionEnergy(
                    selectedProject.id,
                ),
            );
            if (onSuccessCallback) onSuccessCallback();
        })
        .catch((error) => {
            dispatch(
                projectCommOfferTableActions[CREATE_OFFER_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
