import React, { useEffect } from 'react';

import { BottomActions, Button } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import yupResolver from 'common/utils/yupResolver';

import * as companyGeneralSelectors from '../../companyGeneral/selectors';
import FieldArrayConcepts from '../../rates/components/CustomRateFormTabs/SettingsDataTab/FieldArrayConcepts';
import SectionHeader from '../../rates/components/SectionHeader';
import { HOURLY_RATE_TYPE, STAGGERED_RATE_TYPE } from '../../rates/constants';
import * as ratesSelectors from '../../rates/selectors';
import * as selectors from '../selectors';
import validate from '../validateRateConcepts';

import Chart from './Chart';

const name = 'periods.0';

const RateForm = ({
    contractedDemandUnitsDataToSelect = [],
    countryCurrencySymbol,
    demandUnitsDataToSelect = [],
    energyUnitsDataToSelect = [],
    excessDemandUnitsDataToSelect = [],
    initialValues,
    isCompanyFromSpain,
    isLoadingUnits,
    isSaving,
    onSubmit,
    otherUnitsDataToSelect = [],
    showMode,
}) => {
    const isHourlyRate = initialValues?.payment_type === HOURLY_RATE_TYPE;
    const isTopValueEnabled =
        initialValues?.payment_type === STAGGERED_RATE_TYPE;

    const { t } = useTranslation();

    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        context: {
            certified: initialValues?.certified,
            isHourlyRate,
            isTopValueEnabled,
        },
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    const getHasConcepts = (conceptName) =>
        initialValues?.periods?.[0]?.[conceptName]?.concepts?.length > 0;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {!initialValues?.certified && (
                <>
                    <SectionHeader
                        title={`${t('Energy charges')} (kWh)`}
                        type="2"
                    />

                    <FieldArrayConcepts
                        control={control}
                        countryCurrencySymbol={countryCurrencySymbol}
                        disableActions
                        disabled={isLoadingUnits || isSaving}
                        getValues={getValues}
                        isEnergyConcept
                        isHourlyRate={isHourlyRate}
                        linkedConceptName={`${name}.demand_concepts.concepts`}
                        name={`${name}.energy_concepts.concepts`}
                        onlyPriceEditable
                        periodFieldName={name}
                        setValue={setValue}
                        showMode={showMode}
                        topValueFieldEnabled={
                            initialValues?.payment_type === STAGGERED_RATE_TYPE
                        }
                        unitItems={energyUnitsDataToSelect}
                    />

                    {getHasConcepts('demand_concepts') && (
                        <>
                            <SectionHeader
                                title={`${t('Measured demand charges')} (kW)`}
                                type="2"
                            />

                            <FieldArrayConcepts
                                control={control}
                                countryCurrencySymbol={countryCurrencySymbol}
                                disableActions
                                getValues={getValues}
                                isHourlyRate={isHourlyRate}
                                linkedConceptParentName={`${name}.energy_concepts.concepts`}
                                name={`${name}.demand_concepts.concepts`}
                                onlyPriceEditable
                                periodFieldName={name}
                                setValue={setValue}
                                showMode={showMode}
                                unitItems={demandUnitsDataToSelect}
                            />
                        </>
                    )}

                    {isHourlyRate &&
                        getHasConcepts('contracted_demand_concepts') && (
                            <>
                                <SectionHeader
                                    title={`${t('Contracted demand charges')} (kW)`}
                                    type="2"
                                />
                                <FieldArrayConcepts
                                    control={control}
                                    countryCurrencySymbol={
                                        countryCurrencySymbol
                                    }
                                    disableActions
                                    getValues={getValues}
                                    isContractedDemandConcept
                                    isHourlyRate={isHourlyRate}
                                    linkedConceptName={
                                        isCompanyFromSpain
                                            ? `${name}.excess_demand_concepts.concepts`
                                            : null
                                    }
                                    name={`${name}.contracted_demand_concepts.concepts`}
                                    onlyPriceEditable
                                    periodFieldName={name}
                                    setValue={setValue}
                                    showMode={showMode}
                                    unitItems={
                                        contractedDemandUnitsDataToSelect
                                    }
                                />
                            </>
                        )}

                    {isHourlyRate &&
                        isCompanyFromSpain &&
                        getHasConcepts('excess_demand_concepts') && (
                            <>
                                <SectionHeader
                                    title={`${t('Excess demand charges')} (kW)`}
                                    type="2"
                                />

                                <FieldArrayConcepts
                                    control={control}
                                    countryCurrencySymbol={
                                        countryCurrencySymbol
                                    }
                                    disableActions
                                    getValues={getValues}
                                    isHourlyRate={isHourlyRate}
                                    linkedConceptParentName={`${name}.contracted_demand_concepts.concepts`}
                                    name={`${name}.excess_demand_concepts.concepts`}
                                    onlyPriceEditable
                                    periodFieldName={name}
                                    setValue={setValue}
                                    showMode={showMode}
                                    unitItems={excessDemandUnitsDataToSelect}
                                />
                            </>
                        )}
                </>
            )}
            <SectionHeader title={t('Other charges')} type="2" />

            <FieldArrayConcepts
                control={control}
                countryCurrencySymbol={countryCurrencySymbol}
                disabled={isLoadingUnits || isSaving}
                getValues={getValues}
                isConceptsOtherChargesTaxes
                name={`${name}.other_concepts.concepts`}
                periodFieldName={name}
                setValue={setValue}
                showMode={showMode}
                unitItems={otherUnitsDataToSelect}
            />
            <SectionHeader title={t('Tax', { count: 2 })} type="2" />

            <FieldArrayConcepts
                control={control}
                countryCurrencySymbol={countryCurrencySymbol}
                disabled={isLoadingUnits || isSaving}
                getValues={getValues}
                isConceptsOtherChargesTaxes
                name={`${name}.taxes_concepts.concepts`}
                periodFieldName={name}
                setValue={setValue}
                showMode={showMode}
                unitItems={otherUnitsDataToSelect}
            />

            <Chart
                countryCurrencySymbol={countryCurrencySymbol}
                pricingData={initialValues?.pricing_data}
            />

            <BottomActions>
                <Button disabled={isSaving} sx={{ mt: 2 }} type="submit">
                    {t('Save')}
                </Button>
            </BottomActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    contractedDemandUnitsDataToSelect:
        ratesSelectors.getContractedDemandUnitsDataToSelect,
    demandUnitsDataToSelect: ratesSelectors.getDemandUnitsDataToSelect,
    energyUnitsDataToSelect: ratesSelectors.getEnergyUnitsDataToSelect,
    excessDemandUnitsDataToSelect:
        ratesSelectors.getSpainExcessDemandUnitsDataToSelect,
    isCompanyFromSpain: companyGeneralSelectors.getCompanyIsFromSpain,
    isLoadingUnits: ratesSelectors.getIsFetchingUnits,
    isSaving: selectors.getSaveRateConceptsLoading,
    otherUnitsDataToSelect: ratesSelectors.getOthersUnitsDataToSelect,
});

RateForm.propTypes = {
    contractedDemandUnitsDataToSelect: PropTypes.array,
    countryCurrencySymbol: PropTypes.string,
    demandUnitsDataToSelect: PropTypes.array,
    energyUnitsDataToSelect: PropTypes.array,
    excessDemandUnitsDataToSelect: PropTypes.array,
    initialValues: PropTypes.object,
    isCompanyFromSpain: PropTypes.bool,
    isLoadingUnits: PropTypes.bool,
    isSaving: PropTypes.bool,
    onSubmit: PropTypes.func,
    otherUnitsDataToSelect: PropTypes.array,
    showMode: PropTypes.bool,
};

export default connect(mapStateToProps)(RateForm);
