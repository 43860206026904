import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const IconPanelSeeding = ({ color, height = '24px', width = '24px' }) => {
    const theme = useTheme();
    const fillColor = color || theme.palette.primary.main;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.16814 9.36756C6.195 9.17233 6.02616 9 5.80803 9H2.04539C1.87002 9 1.71974 9.11311 1.68837 9.26873L1.41266 10.6364H5.99362L6.16814 9.36756Z"
                fill={fillColor}
            />
            <path
                d="M5.88107 11.4545H1.24772L0.75289 13.9091H5.54345L5.88107 11.4545Z"
                fill={fillColor}
            />
            <path
                d="M6.45758 13.9091L6.79521 11.4545H11.2044L11.5421 13.9091H6.45758Z"
                fill={fillColor}
            />
            <path
                d="M5.4309 14.7273H0.587947L0.00595777 17.6142C-0.0345415 17.8151 0.13658 18 0.362978 18H4.6601C4.84323 18 4.99766 17.8769 5.02021 17.713L5.4309 14.7273Z"
                fill={fillColor}
            />
            <path
                d="M6.34504 14.7273H11.6546L12.0542 17.6324C12.0811 17.8277 11.9122 18 11.6941 18H6.30554C6.08741 18 5.91858 17.8277 5.94543 17.6324L6.34504 14.7273Z"
                fill={fillColor}
            />
            <path
                d="M12.5688 14.7273L12.9794 17.713C13.002 17.8769 13.1564 18 13.3396 18H17.637C17.8634 18 18.0345 17.8151 17.994 17.6142L17.4121 14.7273H12.5688Z"
                fill={fillColor}
            />
            <path
                d="M17.2471 13.9091L16.7523 11.4545H12.1186L12.4562 13.9091H17.2471Z"
                fill={fillColor}
            />
            <path
                d="M12.006 10.6364H16.5873L16.3116 9.26873C16.2803 9.11311 16.13 9 15.9546 9H12.1916C11.9735 9 11.8047 9.17233 11.8315 9.36756L12.006 10.6364Z"
                fill={fillColor}
            />
            <path
                d="M11.0919 10.6364H6.90775L7.09336 9.28698C7.11591 9.12307 7.27034 9 7.45347 9H10.5462C10.7293 9 10.8838 9.12307 10.9063 9.28698L11.0919 10.6364Z"
                fill={fillColor}
            />
            <path
                d="M9.0221 0C7.31457 0 5.76965 1.30911 5.76965 3.33376C5.76965 4.6266 6.76572 6.14712 8.75377 7.89938C8.90827 8.03354 9.14 8.03354 9.29449 7.89938C11.2785 6.14712 12.2745 4.6266 12.2745 3.33376C12.2745 1.30911 10.7296 0 9.0221 0ZM9.0221 4.06556C8.57489 4.06556 8.20899 3.69966 8.20899 3.25245C8.20899 2.80523 8.57489 2.43933 9.0221 2.43933C9.46931 2.43933 9.83521 2.80523 9.83521 3.25245C9.83521 3.69966 9.46931 4.06556 9.0221 4.06556Z"
                fill={fillColor}
            />
        </svg>
    );
};

IconPanelSeeding.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconPanelSeeding;
