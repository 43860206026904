import { getItems } from 'common/api/v1/advisors';

import {
    FETCH_ADVISORS,
    FETCH_ADVISORS_FAILURE,
    FETCH_ADVISORS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_ADVISORS]());

    getItems()
        .then((response) => {
            const data = response.data.data;
            dispatch(actions[FETCH_ADVISORS_SUCCESS](data));
        })
        .catch((error) => {
            dispatch(actions[FETCH_ADVISORS_FAILURE](error));
        });
};
