import { mixed, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_FILE, REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        archive: mixed().required(REQUIRED_FILE).nullable(),
        name: string().required(REQUIRED_TEXT).nullable(),
    });
};
