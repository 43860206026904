import i18next from 'i18next';

import { getItems } from 'common/api/v2/generationProfiles';
import alerts from 'common/modules/alerts';

import {
    SAVE_GENERATION_PROFILE,
    SAVE_GENERATION_PROFILE_FAILURE,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';

import replaceGenerationProfile from './replaceGenerationProfile';
import saveGenerationProfile from './saveGenerationProfile';

export default (getValues, isMexicanAccount, values) => (dispatch) =>
    getItems(values.political_division1)
        .then((response) => {
            const customGenerationProfile = response?.data?.data?.find(
                (item) => item.type !== 0,
            );
            if (customGenerationProfile) {
                const message = isMexicanAccount
                    ? i18next.t(
                          'The selected state already has its own generation profile, do you wish to replace it?',
                      )
                    : i18next.t(
                          'The political division of order 1 selected already has its own generation profile, do you wish to replace it?',
                      );
                const dataAlert = {
                    confirmText: i18next.t('Accept'),
                    messages: [message],
                    onSuccess: () => {
                        dispatch(alerts.actions.close());
                        dispatch(
                            solarGenerationActions[SAVE_GENERATION_PROFILE](),
                        );
                        dispatch(
                            replaceGenerationProfile(
                                getValues,
                                customGenerationProfile.id,
                                values,
                            ),
                        );
                    },
                    title: i18next.t('Confirmation'),
                    type: alerts.ALERT_TYPE_CONFIRM,
                    variant: 'warning',
                };

                dispatch(alerts.actions.show(dataAlert));
            } else dispatch(saveGenerationProfile(getValues, values));
        })
        .catch((error) =>
            dispatch(
                solarGenerationActions[SAVE_GENERATION_PROFILE_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
