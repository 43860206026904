import { styled } from '@mui/material/styles';

export default styled('p')`
    color: #f44336;
    font-size: 0.75rem;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.03333em;
    line-height: 1.66;
    margin: 0;
    margin-top: 3px;
    text-align: left;
`;
