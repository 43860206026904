import API from '../..';
const ENTITY = 'accesories-products';

export const filterItems = ({
    brandName = '',
    orderBy = null,
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    type = null,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/paginate/filter/${searchText}`, {
        params: {
            ...(brandName ? { brand: brandName } : {}),
            ...(type ? { type: type } : {}),
            order_by: orderBy,
            page: page,
            per_page: perPage,
            sort: sortBy,
        },
    });
