import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, UiDialog as Dialog } from 'sunwise-ui';

import WarningsList from './WarningsList';

const Warnings = ({
    consumptionProjection,
    disabled,
    setInitialChartControlsConfig,
    solarSimulationData,
}) => {
    const [showAlerts, setShowAlerts] = useState(false);
    const { t } = useTranslation();

    const handleCloseAlerts = () => setShowAlerts(false);
    const handleGoToDay = (config) => {
        setInitialChartControlsConfig((prev) => ({ ...prev, ...config }));
        handleCloseAlerts();
    };

    if (!solarSimulationData?.emptyEnergyIndexes?.length) return null;

    return (
        <>
            <Alert
                severity="warning"
                sx={{ '.MuiAlert-message': { width: '100%' } }}
            >
                <Box
                    display="flex"
                    flexWrap="wrap"
                    gap={1}
                    justifyContent="space-between"
                >
                    {t(
                        'The storage system cannot cover all the consumption with the current configuration',
                    )}

                    <Box textAlign="right" width={{ xs: '100%', md: 'auto' }}>
                        <Button
                            disabled={disabled}
                            onClick={() => setShowAlerts(true)}
                            sx={{ m: '0!important' }}
                        >
                            {t('See details')}
                        </Button>
                    </Box>
                </Box>
            </Alert>

            <Dialog
                onClose={handleCloseAlerts}
                onExited={handleCloseAlerts}
                open={showAlerts}
                title={t('Warning', { count: 2 })}
                size="md"
            >
                <WarningsList
                    consumptionHistory={
                        consumptionProjection?.consumptionHistory
                    }
                    emptyEnergyIndexes={solarSimulationData.emptyEnergyIndexes}
                    handleGoToDay={handleGoToDay}
                    realIndex={consumptionProjection?.realIndex}
                />
            </Dialog>
        </>
    );
};

Warnings.propTypes = {
    consumptionProjection: PropTypes.object,
    disabled: PropTypes.bool,
    setInitialChartControlsConfig: PropTypes.func,
    solarSimulationData: PropTypes.object,
};

export default Warnings;
