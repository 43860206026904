import React, { useEffect, useState } from 'react';

import CloudIcon from '@mui/icons-material/Cloud';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Badge, IconButton } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import StatusDrawer from './components/StatusDrawer';

const Container = ({ canView, reset, sxIcon }) => {
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const theme = useTheme();

    const openProjectStatusDrawer = () => {
        setIsOpenDrawer(true);
    };

    useEffect(() => {
        return () => reset();
    }, [canView]);

    if (!canView) return null;

    return (
        <>
            <IconButton onClick={openProjectStatusDrawer} size="small">
                <Badge
                    badgeContent={0}
                    sx={{
                        '& .MuiBadge-badge': {
                            backgroundColor: theme.palette.primary.light,
                            color:
                                theme.palette.mode === 'dark'
                                    ? theme.palette.primary.contrastText
                                    : '#fff',
                        },
                    }}
                >
                    <CloudIcon sx={sxIcon} />
                </Badge>
            </IconButton>

            <StatusDrawer
                handleToggleDrawer={setIsOpenDrawer}
                isOpenDrawer={isOpenDrawer}
            />
        </>
    );
};

Container.propTypes = {
    canView: PropTypes.bool,
    reset: PropTypes.func,
    sxIcon: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
    reset: () => dispatch(actions.reset()),
});

export default compose(
    connect(null, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.AFTER_SALES_PERMISSION),
)(Container);
