import React, { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, ClickAwayListener, Tooltip } from 'sunwise-ui';

const Wrapper = styled('span')`
    background-color: ${({ bgColor }) => (bgColor ? `${bgColor}` : '#f8fcff')};
    border-radius: 100%;
    border: 1px solid
        ${({ borderColor }) => (borderColor ? `${borderColor}` : '#ecedf0')};
    color: ${({ color, theme }) => color ?? theme.palette.primary.main};
    cursor: pointer;
    display: inline-block;
    font-size: ${({ size }) => (size ? `${size}px` : '12px')};
    height: ${({ width }) => (width ? `${width}px` : '32px')};
    line-height: ${({ width }) => (width ? `${width}px` : '32px')};
    text-align: center;
    width: ${({ width }) => (width ? `${width}px` : '32px')};

    ${({ variant }) =>
        variant === 'no-wrapper' &&
        `
        background-color: transparent;
        border-radius: initial;
        border: 0 none;
        height: auto;
        line-height: initial;
        width: auto;
    `}
`;

const InfoTooltip = ({
    bgColor,
    borderColor,
    children,
    color,
    image,
    size,
    sxBox,
    sxTooltip,
    variant,
    width,
}) => {
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => setOpen(false);
    const handleTooltipOpen = () => setOpen(true);
    const popover = <div>{children}</div>;
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Box alignItems="center" display="flex" sx={sxBox}>
                <Tooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    onClose={handleTooltipClose}
                    open={open}
                    sx={sxTooltip}
                    title={popover}
                >
                    <Wrapper
                        bgColor={bgColor}
                        borderColor={borderColor}
                        color={color}
                        onClick={handleTooltipOpen}
                        size={size}
                        variant={variant}
                        width={width}
                    >
                        {image ? (
                            <img alt="" src={image} width={width} />
                        ) : (
                            <InfoIcon />
                        )}
                    </Wrapper>
                </Tooltip>
            </Box>
        </ClickAwayListener>
    );
};

InfoTooltip.propTypes = {
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    color: PropTypes.string,
    image: PropTypes.string,
    size: PropTypes.string,
    sxBox: PropTypes.object,
    sxTooltip: PropTypes.object,
    variant: PropTypes.string,
    width: PropTypes.string,
};

export default InfoTooltip;
