import { styled } from '@mui/material/styles';

export default styled('div')`
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 2px 12px 0 rgba(129, 158, 200, 0.06);
    margin: 15px 0px;
    padding: 15px;
    width: 100%;

    ${({ borderColor }) => borderColor && `border: 1px solid ${borderColor};`}
`;
