import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

export default styled(Box)(({ theme }) => ({
    height: 'calc(100vh - 56px)',
    marginTop: 1,
    overflowY: 'scroll',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 250px)',
    },
    ...scroll.object,
}));
