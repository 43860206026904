import { saveData } from 'common/api/v1/periodReporter';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_PERIOD_SETTINGS_SUCCESS,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
} from '../actionTypes';
import { PERIOD_TYPES, REPORT_TYPES } from '../constants';
import { actions } from '../reducer';

import fetchReportComplements from './fetchReportComplements';

const getPeriods = (periods) => {
    if (!periods) return [];
    return periods.map((period) => ({
        comparison_period: period.comparison_period || null,
        current_period: period.current_period || null,
        period_type: period.period_type ?? PERIOD_TYPES.PROPOSAL,
    }));
};

export default (reportId, values, callback) => (dispatch) => {
    if (!values) return;
    dispatch(actions[SAVE]());

    const { groups, type } = values;

    const newValues = {
        groups: (groups || []).map((group) => ({
            name: group.name || '',
            periods: getPeriods(group.periods),
        })),
        report: reportId,
        type: type || REPORT_TYPES.SINGLE_PERIOD,
    };

    saveData(reportId, newValues)
        .then((response) => {
            const data = response?.data?.data;
            dispatch(actions[SAVE_SUCCESS](data));
            dispatch(actions[FETCH_PERIOD_SETTINGS_SUCCESS](data));
            dispatch(fetchReportComplements(reportId));

            if (callback) callback();
        })
        .catch((error) => {
            dispatch(actions[SAVE_FAILURE](error?.response?.data?.errors));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
