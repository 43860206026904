import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import ChartJs from 'common/components/ChartJs';

import { buildChartJsConfig } from '../../helpers';

const Generation = ({
    countryCurrencyLocale,
    handleSelectProject,
    seriesData,
}) => {
    const [chartConfig, setChartConfig] = useState({
        data: { datasets: [], labels: [] },
        options: {},
    });

    useEffect(() => {
        const chartOptions = buildChartJsConfig({
            categories: seriesData?.categories,
            colors: seriesData?.colors,
            countryCurrencyLocale,
            handleSelectProject,
            series: seriesData?.series,
        });
        setChartConfig(chartOptions);
    }, [seriesData]);

    const { data, options } = chartConfig;

    return <ChartJs data={data} height="650px" options={options} type="line" />;
};

Generation.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    handleSelectProject: PropTypes.func,
    seriesData: PropTypes.object,
};

export default Generation;
