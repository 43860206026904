import { getItems } from 'common/api/v1/suppliers';

import {
    FETCH_SUPPLIERS,
    FETCH_SUPPLIERS_FAILURE,
    FETCH_SUPPLIERS_SUCCESS,
} from '../actionTypes';
import { invertersConfigurationActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(invertersConfigurationActions[FETCH_SUPPLIERS]());

    getItems()
        .then((response) =>
            dispatch(
                invertersConfigurationActions[FETCH_SUPPLIERS_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                invertersConfigurationActions[FETCH_SUPPLIERS_FAILURE](error),
            ),
        );
};
