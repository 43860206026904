import { array, number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();

    const periodShape = object().shape({
        comparison_period: string().nullable(),
        current_period: string().required(REQUIRED_TEXT),
        period_type: number().nullable(),
    });

    const groupedPeriodShape = object().shape({
        name: string().required(REQUIRED_TEXT).nullable(),
        periods: array().of(periodShape).min(1).required(REQUIRED_TEXT),
    });

    return object().shape({
        groups: array().of(groupedPeriodShape),
        type: number().required(REQUIRED_TEXT),
    });
};
