import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_ERRORS,
    INVALID_PASSWORD,
    INVALID_PASSWORD_CONFIRMATION,
    INVALID_PASSWORD_CONFIRMATION_LENGTH,
    RESET_PASSWORD,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
    SET_UUID_TOKEN,
    VALID_PASSWORD,
    VALID_PASSWORD_CONFIRMATION,
    VALID_PASSWORD_CONFIRMATION_LENGTH,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: {
        password: '',
        confirm_password: '',
    },
    session: {
        user: null,
        isLoading: false,
        errors: null,
    },
    uuid: null,
    token: null,
    showRedirectLogin: false,
    validPassword: false,
    validPasswordConfirmation: false,
    validPasswordConfirmationLength: false,
};

const resetPasswordSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [RESET_PASSWORD]: (state) => {
            state.session.isLoading = true;
        },
        [RESET_PASSWORD_FAILURE]: (state, action) => {
            state.session = {
                ...state.session,
                errors: action.payload,
                isLoading: false,
            };
        },
        [RESET_PASSWORD_SUCCESS]: (state, action) => {
            state.session = {
                ...state.session,
                isLoading: false,
                user: action.payload,
            };
            state.showRedirectLogin = true;
        },
        [SET_UUID_TOKEN]: (state, action) => {
            state.uuid = action.payload.uuid;
            state.token = action.payload.token;
        },
        [DELETE_ERRORS]: (state) => {
            state.session = {
                ...state.session,
                errors: null,
                isLoading: false,
            };
        },
        [VALID_PASSWORD]: (state) => {
            state.validPassword = true;
        },
        [INVALID_PASSWORD]: (state) => {
            state.validPassword = false;
        },
        [VALID_PASSWORD_CONFIRMATION]: (state) => {
            state.validPasswordConfirmation = true;
        },
        [INVALID_PASSWORD_CONFIRMATION]: (state) => {
            state.validPasswordConfirmation = false;
        },
        [VALID_PASSWORD_CONFIRMATION_LENGTH]: (state) => {
            state.validPasswordConfirmationLength = true;
        },
        [INVALID_PASSWORD_CONFIRMATION_LENGTH]: (state) => {
            state.validPasswordConfirmationLength = false;
        },
    },
});

export const resetPasswordActions = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
