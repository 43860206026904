import React, { useEffect } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, Divider, Link, Typography } from 'sunwise-ui';

import { OpacityAnimation } from 'common/components/animations';
import { getIsGuest } from 'common/utils/helpers/session';

import fondoLogin1 from 'resources/fondoLogin1.png';
import fondoLoginCreateAccount from 'resources/fondoLoginCreateAccount.jpg';
import logotipo from 'resources/logotipoSunwiseAzul.svg';

import { sendResetPasswordEmail } from './actions';
import { ForgotPasswordForm } from './components';
import * as selectors from './selectors';

const StyledGrid = styled(Grid)`
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background: #fff url(${fondoLogin1});
`;

const StyledGrid2 = styled(Grid)`
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background: #fff url(${fondoLoginCreateAccount});
`;

const Container = ({
    errors,
    initialValues,
    isLoadingSession,
    redirect,
    showForgotPasswordForm,
    sendResetPasswordEmail,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!getIsGuest()) {
            redirect('/');
        }
    }, []);

    const handleSubmit = (values) => {
        sendResetPasswordEmail(values);
    };

    return (
        <>
            {showForgotPasswordForm ? (
                <Grid
                    container
                    sx={{
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <StyledGrid
                        item
                        lg={9}
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            height: '100%',
                        }}
                        xs={18}
                    >
                        <OpacityAnimation>
                            <Box
                                sx={{
                                    margin: '0 auto',
                                    maxWidth: '670px',
                                    p: 2,
                                }}
                            >
                                <Box sx={{ textAlign: 'center' }}>
                                    <img
                                        alt="Sunwise"
                                        src={logotipo}
                                        width="238"
                                    />
                                </Box>

                                <Box py={2}>
                                    <Typography variant="h4">
                                        {t('Recover password')}
                                    </Typography>
                                    <Typography variant="body2">
                                        {t(
                                            'Enter your email to reset your password',
                                        )}
                                    </Typography>
                                </Box>

                                <Divider sx={{ mb: 2 }} />

                                <ForgotPasswordForm
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                    isLoadingSession={isLoadingSession}
                                    errors={errors}
                                />

                                <Box sx={{ textAlign: 'center' }}>
                                    <Button
                                        component={RouterLink}
                                        fullWidth
                                        startIcon={<AccountCircleIcon />}
                                        to="/login"
                                        variant="text"
                                        sx={{ textTransform: 'none' }}
                                    >
                                        {t('Log in')}
                                    </Button>
                                </Box>
                            </Box>
                        </OpacityAnimation>
                    </StyledGrid>
                    <StyledGrid2
                        item
                        md={9}
                        sx={{
                            display: { lg: 'flex', xs: 'none' },
                            flexDirection: 'column',
                            height: '100%',
                        }}
                    />
                </Grid>
            ) : (
                <Grid
                    container
                    sx={{
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <StyledGrid
                        item
                        md={5}
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            height: '100%',
                        }}
                        xs
                    >
                        <Box sx={{ margin: '0 auto', maxWidth: '670px', p: 2 }}>
                            <Box sx={{ textAlign: 'center' }}>
                                <img alt="Sunwise" src={logotipo} width="238" />
                            </Box>

                            <Box py={2}>
                                <Typography variant="h4">
                                    {t(
                                        'We send you a message to the registered email to reset your password',
                                    )}
                                </Typography>
                            </Box>

                            <Divider sx={{ mb: 3 }} />

                            <Box sx={{ textAlign: 'center' }}>
                                <Typography fontWeight="bold" variant="body2">
                                    {t("Don't have an account?")}{' '}
                                    <Link to="/reset-password">
                                        {t('Resend')}
                                    </Link>
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 2, textAlign: 'center' }}>
                                <Button
                                    component={RouterLink}
                                    fullWidth
                                    startIcon={<AccountCircleIcon />}
                                    to="/login"
                                    variant="text"
                                    sx={{ textTransform: 'none' }}
                                >
                                    {t('Log in')}
                                </Button>
                            </Box>
                        </Box>
                    </StyledGrid>
                </Grid>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    showForgotPasswordForm: selectors.getShowForgotPasswordForm,
    initialValues: selectors.getInitialValues,
    isLoadingSession: selectors.getIsLoadingSession,
    errors: selectors.getErrorsLogin,
});

const mapDispatchToProps = (dispatch) => {
    return {
        sendResetPasswordEmail: (data) =>
            dispatch(sendResetPasswordEmail(data)),
        redirect: (link) => dispatch(push(link)),
    };
};

Container.propTypes = {
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isLoadingSession: PropTypes.bool,
    redirect: PropTypes.func,
    sendResetPasswordEmail: PropTypes.func,
    showForgotPasswordForm: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
