import { NAME } from './constants';

export const FETCH_OFFER_INFO = `${NAME}/FETCH_OFFER_INFO`;
export const FETCH_OFFER_INFO_FAILURE = `${NAME}/FETCH_OFFER_INFO_FAILURE`;
export const FETCH_OFFER_INFO_SUCCESS = `${NAME}/FETCH_OFFER_INFO_SUCCESS`;

export const INITIAL_VALUES = `${NAME}/INITIAL_VALUES`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SET_IS_INFO_CONSISTENT = `${NAME}/SET_IS_INFO_CONSISTENT`;
