import React, { useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, Typography } from 'sunwise-ui';

import ReactHookFormSelect2 from 'common/components/form/bootstrap/ReactHookFormSelect2';
import { calculateFinalCost } from 'common/utils/helpers';

import PanelsPlaceholder from 'resources/onboarding/panels.svg';

import * as actions from '../actions';
import { getProductBrandsToSelect } from '../helpers';
import * as selectors from '../selectors';

import ReduxFieldArrayPanels from './ReduxFieldArrayPanels';

const PanelsStep = ({
    control,
    fetchPanelProducts,
    formValues,
    getValues,
    iFetchingPanelBrands,
    isFetchingPanelProducts,
    panelBrands,
    panelProducts,
    setValue,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!localStorage.getItem('tempPanelSettings')) {
            const panels = panelProducts.map((item) => ({
                cost: item.cost,
                final_cost: calculateFinalCost(item.cost, 0),
                id: item.id,
                margin: 0,
                name: item.name,
                price: 0,
                selected: true,
            }));
            setValue('panels', panels);
        }
    }, [panelProducts]);

    const handleOnChangeBrand = (brandName) => {
        localStorage.removeItem('tempPanelSettings');
        setValue('panels', []);
        fetchPanelProducts(brandName);
    };

    return (
        <>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    mb: 2,
                    py: 1,
                    width: '100%',
                }}
            >
                <img src={PanelsPlaceholder} />
                <Typography variant="h6">
                    {t('Time to customize your panels')}
                </Typography>
                <Typography variant="body2">
                    {t(
                        'To start using your account we pre-select the 5 most used panels depending on the brand you choose, then you will have more options to further customize your product listings',
                    )}
                    .
                </Typography>
            </Box>
            <Grid container mb={2}>
                <Grid item xs={18} md={8}>
                    <Typography fontWeight="bold" mb={1} variant="body2">
                        {t('Panel brand')}
                    </Typography>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={iFetchingPanelBrands}
                        isClearable={false}
                        label={t('Select your favorite')}
                        name="panel_brand"
                        onChange={(value) => handleOnChangeBrand(value)}
                        options={[
                            {
                                label: '',
                                options: getProductBrandsToSelect(panelBrands),
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            {isFetchingPanelProducts ? (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                    }}
                >
                    <CircularProgress />
                    <Typography fontWeight="bold" variant="caption">
                        {`${t('Loading')} ${t('Panel', { count: 2 })}...`}
                    </Typography>
                </Box>
            ) : (
                <ReduxFieldArrayPanels
                    control={control}
                    disabled={isFetchingPanelProducts}
                    formValues={formValues}
                    getValues={getValues}
                    name="panels"
                    setValue={setValue}
                />
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    iFetchingPanelBrands: selectors.getIsFetchingPanelBrands,
    isFetchingPanelProducts: selectors.getIsFetchingPanelProducts,
    panelBrands: selectors.getPanelBrandsData,
    panelProducts: selectors.getPanelProductsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPanelProducts: (brandName) =>
        dispatch(actions.fetchPanelProducts(brandName)),
});

PanelsStep.propTypes = {
    control: PropTypes.object,
    fetchPanelProducts: PropTypes.func,
    formValues: PropTypes.object,
    getValues: PropTypes.func,
    iFetchingPanelBrands: PropTypes.bool,
    isFetchingPanelProducts: PropTypes.bool,
    panelBrands: PropTypes.array,
    panelProducts: PropTypes.array,
    setValue: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelsStep);
