import { updateItem } from 'common/api/v1/irradiationsCompaniesConfiguration';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SAVE_SOURCE,
    SAVE_SOURCE_FAILURE,
    SAVE_SOURCE_SUCCESS,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';
import * as selectors from '../selectors';

export default (id) => (dispatch, getState) => {
    const state = getState();
    const config = selectors.getDefaultCompanySourceData(state);

    const newValues = {
        source: id,
    };

    dispatch(solarGenerationActions[SAVE_SOURCE]());

    updateItem(newValues, config.id)
        .then((response) => {
            dispatch(
                solarGenerationActions[SAVE_SOURCE_SUCCESS](response.data),
            );
        })
        .catch((error) => {
            dispatch(
                solarGenerationActions[SAVE_SOURCE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
