import i18next from 'i18next';

import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import deleteItem from './deleteItem';

export default (item) => (dispatch) => {
    if (item?.users >= 1) {
        let bodyMessage = i18next.t(
            'The role {{name}} cannot be deleted because it is being used by',
            { name: item.name },
        );
        showToast({
            body: `${bodyMessage} ${item.users} ${i18next
                .t('User', {
                    count: item.users,
                })
                .toLowerCase()}`,
            type: 'warning',
            autoClose: 3000,
        });
        return false;
    }
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        haveTextCaptcha: true,
        messages: [
            i18next.t('Are you sure to delete the item "{{name}}"?', {
                name: item.name,
            }),
        ],
        onSuccess: () => dispatch(deleteItem(item.id)),
        textToValidate: i18next.t('Delete').toUpperCase(),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
