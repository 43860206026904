import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionDetails,
    Box,
    Card,
    Divider,
    Typography,
} from 'sunwise-ui';

import StyledAccordionSummary from './StyledAccordionSummary';

const AccordionCard = ({
    cardId,
    children,
    classNameCard,
    classNameExpandIcon,
    customTitle,
    defaultExpanded,
    isHidden,
    isLoading,
    svgIcon,
    sxAccordion,
    sxAccordionSummary,
    sxAccordionDetails,
    sxCard,
    sxExpandMoreIcon,
    title,
}) => {
    const [expanded, setExpanded] = useState(defaultExpanded);

    const handleChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    if (isHidden) return null;

    return (
        <Card className={classNameCard} id={cardId} sx={sxCard}>
            {isLoading && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
            <Accordion
                defaultExpanded={defaultExpanded}
                expanded={expanded}
                onChange={handleChange}
                sx={sxAccordion}
            >
                <StyledAccordionSummary
                    aria-controls={`${cardId}-content`}
                    expandIcon={
                        <ExpandMoreIcon
                            className={classNameExpandIcon}
                            sx={sxExpandMoreIcon}
                        />
                    }
                    id={`${cardId}-header`}
                    sx={sxAccordionSummary}
                >
                    {svgIcon}
                    {title && (
                        <Typography
                            variant="body2"
                            ml={1}
                            sx={{ fontWeight: 'bold' }}
                        >
                            {title}
                        </Typography>
                    )}
                    {customTitle && <>{customTitle}</>}
                </StyledAccordionSummary>
                <Divider />
                <AccordionDetails sx={sxAccordionDetails}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

AccordionCard.defaultProps = {
    classNameCard: '',
    classNameExpandIcon: '',
    customTitle: null,
    defaultExpanded: false,
    isHidden: false,
    numericalSign: '',
    sxAccordion: {},
    sxAccordionSummary: {},
    sxAccordionDetails: {
        p: 2,
    },
    sxCard: {},
    sxExpandMoreIcon: {},
};

AccordionCard.propTypes = {
    cardId: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
    ]),
    classNameCard: PropTypes.string,
    classNameExpandIcon: PropTypes.string,
    customTitle: PropTypes.object,
    defaultExpanded: PropTypes.bool,
    isHidden: PropTypes.bool,
    isLoading: PropTypes.bool,
    svgIcon: PropTypes.object,
    sxAccordion: PropTypes.object,
    sxAccordionSummary: PropTypes.object,
    sxAccordionDetails: PropTypes.object,
    sxCard: PropTypes.object,
    sxExpandMoreIcon: PropTypes.object,
    title: PropTypes.string,
};

export default AccordionCard;
