import { getConsumptionEnergy } from 'common/api/v2/costumerEnergyConsumption';

import {
    FETCH_CONSUMPTION_ENERGY,
    FETCH_CONSUMPTION_ENERGY_FAILURE,
    FETCH_CONSUMPTION_ENERGY_NO_ANIMATION,
    FETCH_CONSUMPTION_ENERGY_SUCCESS,
} from '../actionTypes';
import { projectConsumptionCardActions } from '../reducer';

import fetchScheduleRateConfiguration from './fetchScheduleRateConfiguration';

export default (projectId, animation = true) =>
    (dispatch) => {
        const type =
            animation === true
                ? FETCH_CONSUMPTION_ENERGY
                : FETCH_CONSUMPTION_ENERGY_NO_ANIMATION;

        dispatch(projectConsumptionCardActions[type]());

        getConsumptionEnergy(projectId)
            .then((response) => {
                const data = response.data.data;

                dispatch(
                    projectConsumptionCardActions[
                        FETCH_CONSUMPTION_ENERGY_SUCCESS
                    ](data),
                );

                if (data?.rate?.id) {
                    Promise.all([
                        dispatch(
                            fetchScheduleRateConfiguration({
                                certified: data.rate.certified,
                                rate: data.rate.id,
                                rate_division: data.rate_division?.id,
                            }),
                        ),
                    ]);
                }
            })
            .catch((error) =>
                dispatch(
                    projectConsumptionCardActions[
                        FETCH_CONSUMPTION_ENERGY_FAILURE
                    ](error?.response?.data?.errors),
                ),
            );
    };
