import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import {
    TextField,
    InputAdornment,
    Tooltip,
    ClickAwayListener,
} from 'sunwise-ui';

const Color = styled('div')`
    background-color: ${({ color }) => color && color};
    border-radius: 16px;
    height: 20px;
    width: 20px;
`;

function rgba2hex({ a, b, g, r }) {
    const hex =
        (r | (1 << 8)).toString(16).slice(1) +
        (g | (1 << 8)).toString(16).slice(1) +
        (b | (1 << 8)).toString(16).slice(1) +
        ((a * 255) | (1 << 8)).toString(16).slice(1);
    return '#' + hex;
}

const ColorPicker = React.memo(
    ({
        disabled = false,
        fullWidth,
        initialHexColor,
        input,
        label,
        primaryColor,
        secondaryColor,
    }) => {
        const [open, setOpen] = React.useState(false);
        const color = input.value
            ? input.value
            : initialHexColor
              ? initialHexColor
              : '#5e72e4';

        useEffect(() => {
            if (input.value === 'primary_color') {
                input.onChange(primaryColor);
            } else if (input.value === 'secondary_color') {
                input.onChange(secondaryColor);
            }
        });

        const handleTooltipClose = () => setOpen(false);
        const handleTooltipOpen = () => (disabled ? false : setOpen(true));
        const handleOnTextFieldChange = (e) => input.onChange(e.target.value);
        const handleChange = (color) => {
            const hex = color.rgb.a === 1 ? color.hex : rgba2hex(color.rgb);
            input.onChange(`${hex}`);
        };

        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <Tooltip
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        onClose={handleTooltipClose}
                        open={open}
                        PopperProps={{ disablePortal: true }}
                        title={
                            <ChromePicker
                                color={color}
                                onChange={handleChange}
                            />
                        }
                    >
                        <TextField
                            disabled={disabled}
                            fullWidth={fullWidth}
                            inputProps={{ maxLength: 9 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Color color={color} />
                                    </InputAdornment>
                                ),
                            }}
                            label={label}
                            name={input.name}
                            onChange={handleOnTextFieldChange}
                            onClick={handleTooltipOpen}
                            value={color}
                        />
                    </Tooltip>
                </div>
            </ClickAwayListener>
        );
    },
);

ColorPicker.displayName = 'ColorPicker';

ColorPicker.propTypes = {
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    initialHexColor: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
};

export default ColorPicker;
