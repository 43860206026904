import React from 'react';

import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'sunwise-ui';

import {
    getCurrencyIso,
    getCurrencyLocale,
    getCurrencySymbol,
    numberFormat,
} from 'common/utils/helpers';

const DollarPriceItem = ({ oppositeCurrency }) => {
    if (isEmpty(oppositeCurrency) || isNull(oppositeCurrency)) return null;
    const currencyIso = getCurrencyIso(oppositeCurrency);
    const currencyLocale = getCurrencyLocale(oppositeCurrency);
    const currencySymbol = getCurrencySymbol(oppositeCurrency);
    return (
        <Button
            color="secondary"
            component={RouterLink}
            size="small"
            to={`/currencies/${currencyIso}`}
            variant="text"
        >
            $1 USD ={' '}
            {numberFormat(oppositeCurrency.dollar_price, {
                currency: currencyIso,
                locale: currencyLocale,
                style: 'currency',
                symbol: currencySymbol,
            })}
        </Button>
    );
};

DollarPriceItem.propTypes = { oppositeCurrency: PropTypes.object };

export default DollarPriceItem;
