import React from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './store';
import { initAllThirdParty } from './thirdParty';

const store = configureStore();

Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    appVersion: `${import.meta.env.VITE_VERSION}`,
    enabledReleaseStages: ['test', 'staging', 'prod'],
    plugins: [new BugsnagPluginReact()],
    releaseStage: import.meta.env.VITE_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

initAllThirdParty();

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
);

serviceWorker.unregister();
