import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';

const ProjectStatusNormalTable = ({
    currencyIso,
    currencyLocale,
    projectsSalesStageData,
}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>

                        {projectsSalesStageData.columns.map((column, index) => (
                            <TableCell key={`projectsSalesStageData-${index}`}>
                                <CircleIcon
                                    sx={{
                                        width: '11px',
                                        height: '11px',
                                        color: column.color,
                                        mr: 1,
                                    }}
                                />
                                {column.title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        {projectsSalesStageData.rows.projectsNumber.map(
                            (row, index) => (
                                <TableCell key={`projectsNumber-${index}`}>
                                    {index === 0
                                        ? row
                                        : numberFormat(row, {
                                              decimals: 0,
                                              locale: currencyLocale,
                                              style: 'decimal',
                                          })}
                                </TableCell>
                            ),
                        )}
                    </TableRow>

                    <TableRow>
                        {projectsSalesStageData.rows.projectsTotal.map(
                            (row, index) => (
                                <TableCell key={`projectsTotal-${index}`}>
                                    {index === 0
                                        ? row
                                        : numberFormat(row, {
                                              currency: currencyIso,
                                              locale: currencyLocale,
                                              style: 'currency',
                                          })}
                                </TableCell>
                            ),
                        )}
                    </TableRow>

                    <TableRow>
                        {projectsSalesStageData.rows.projectsKw.map(
                            (row, index) => (
                                <TableCell key={`projectsKw-${index}`}>
                                    <span>
                                        {index === 0
                                            ? row
                                            : numberFormat(row, {
                                                  decimals: 0,
                                                  locale: currencyLocale,
                                                  style: 'decimal',
                                                  unit: 'kW',
                                              })}
                                    </span>
                                </TableCell>
                            ),
                        )}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

ProjectStatusNormalTable.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    projectsSalesStageData: PropTypes.object,
};

export default ProjectStatusNormalTable;
