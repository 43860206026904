import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];
export const getState = (state) => state;

// DOCUMENT TEMPLATES
export const getDocumentTemplates = createSelector(
    getModel,
    (model) => model.documentTemplates,
);

export const getDocumentTemplatesData = createSelector(
    getDocumentTemplates,
    (value) => value.data,
);

export const getIsFetchingDocumentTemplates = createSelector(
    getDocumentTemplates,
    (value) => value.isFetching,
);

// DOWNLOAD REPORT PDF
export const getDownloadReportPdf = createSelector(
    getModel,
    (model) => model.downloadReportPdf,
);

export const getIsFetchingPdfFile = createSelector(
    getDownloadReportPdf,
    (value) => value.isFetching,
);

// FETCH CUSTOM TEMPLATE
export const getFetchCustomTemplate = createSelector(
    getModel,
    (model) => model.fetchCustomTemplate,
);

export const getIsFetchingCustomTemplate = createSelector(
    getFetchCustomTemplate,
    (value) => value.isFetching,
);

export const getCustomTemplateId = createSelector(
    getFetchCustomTemplate,
    (model) => model.data?.external_uuid ?? null,
);

// UPDATE CUSTOM TEMPLATE
export const getUpdateCustomTemplate = createSelector(
    getModel,
    (model) => model.updateCustomTemplate,
);

export const getIsUpdatingCustomTemplate = createSelector(
    getUpdateCustomTemplate,
    (value) => value.isUpdating,
);

export const getUpdateCustomTemplateType = createSelector(
    getUpdateCustomTemplate,
    (value) => value.type,
);

// INITIALIZING
export const getIsInitializing = createSelector(
    getModel,
    (model) => model.initializing,
);

export const getSelectedDocument = createSelector(
    getModel,
    (model) => model.selectedDocument,
);
