import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

const StyledSeparator = styled(Box)`
    background-color: #eff1fb;
    height: 30px;
    margin: 0 10px;
    width: 1px;
`;

const Separator = ({ className }) => <StyledSeparator className={className} />;

Separator.propTypes = { className: PropTypes.string };

export default Separator;
