import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid } from 'sunwise-ui';

import {
    getCountryCurrencyIso,
    getCountryCurrencyLocale,
    getCountryCurrencySymbol,
} from 'common/utils/helpers/session';

import * as selectors from '../selectors';

import CardConsumption from './CardConsumption';
import NoDataIntake from './NoDataIntake';

const ConsumptionsResume = ({
    consumptionEnergy,
    infoPriceConsumtion,
    isFetching,
    isFetchingConsumptionEnergy,
    isFetchingScheduleRateConfiguration,
    monthsToChartKw,
    monthsToChartKwh,
}) => {
    const countryCurrencyIso = getCountryCurrencyIso();
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const countryCurrencySymbol = getCountryCurrencySymbol();

    const renderContent = () => {
        if (
            isFetching ||
            isFetchingConsumptionEnergy ||
            isFetchingScheduleRateConfiguration
        )
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            );
        if (isEmpty(consumptionEnergy)) return <NoDataIntake />;
        return (
            <CardConsumption
                countryCurrencyIso={countryCurrencyIso}
                countryCurrencyLocale={countryCurrencyLocale}
                countryCurrencySymbol={countryCurrencySymbol}
                holder={consumptionEnergy.holder}
                infoPriceConsumtion={infoPriceConsumtion}
                monthskw={monthsToChartKw}
                monthskwh={monthsToChartKwh}
                rate={consumptionEnergy.rate.name}
                region={
                    consumptionEnergy.rate_division !== null
                        ? consumptionEnergy.rate_division.name
                        : ''
                }
                serviceNumber={consumptionEnergy.service_number}
            />
        );
    };

    return (
        <Grid item xs={18} md={9}>
            {renderContent()}
        </Grid>
    );
};

ConsumptionsResume.propTypes = {
    consumptionEnergy: PropTypes.object,
    infoPriceConsumtion: PropTypes.array,
    isFetching: PropTypes.bool,
    isFetchingConsumptionEnergy: PropTypes.bool,
    isFetchingScheduleRateConfiguration: PropTypes.bool,
    monthsToChartKw: PropTypes.array,
    monthsToChartKwh: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    consumptionEnergy: selectors.getDataConsumptionEnergy,
    infoPriceConsumtion: selectors.getInfoPriceConsumtion,
    isFetchingConsumptionEnergy: selectors.getIsFetchingConsumptionEnergy,
    isFetchingScheduleRateConfiguration:
        selectors.getIsFetchingScheduleRateConfiguration,
    monthsToChartKw: selectors.getMonthsToChartKw,
    monthsToChartKwh: selectors.getMonthsToChartKwh,
});

export default connect(mapStateToProps, null)(ConsumptionsResume);
