import React, { useCallback } from 'react';

import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { Box, Button } from 'sunwise-ui';

import LabelError from 'common/components/LabelError';
import { MAX_FILE_SIZE } from 'common/constants';
import { isImageBase64, isImageUrl } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

const StyledDiv = styled(Box)`
    border-radius: 8px;
    border: 1px solid;
    color: #848bab;
    font-size: 14px;
    margin-bottom: 16px;
    padding: 5px 8px;
    width: 100%;
`;

const ReactHookFormFile = ({
    accept = '*',
    buttonText = i18next.t('Upload'),
    control,
    disabled,
    id,
    maxSize = MAX_FILE_SIZE,
    maxSizeErrorMessage = i18next.t(
        'The file exceeds the allowable size limit',
    ),
    name,
    onChange,
    placeholder = i18next.t('File upload'),
    setValue,
    sxButton,
    text = i18next.t('Change image'),
    variant,
    variantButton,
    visible,
}) => {
    if (!visible) return null;

    const onDrop = useCallback((acceptedFiles) => {
        if (!acceptedFiles.length) return;
        if (acceptedFiles.length > 0 && acceptedFiles[0].size >= maxSize) {
            showToast({
                body: maxSizeErrorMessage,
                type: 'danger',
                autoClose: 3000,
            });
            return;
        }

        setValue(
            name,
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            ),
        );

        onChange ? onChange(acceptedFiles) : null;
    }, []);

    const propId = id ? id : 'inputGroup' + Math.random();
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { name, value }, fieldState: { error } }) => (
                <div {...getRootProps} disabled={disabled}>
                    <label htmlFor={`contained-button-file-${propId}`}>
                        <input
                            {...getInputProps()}
                            accept={accept}
                            disabled={disabled}
                            id={`contained-button-file-${propId}`}
                            name={name}
                            type="file"
                            value={value?.name}
                        />
                        <Box
                            display="flex"
                            gap="16px"
                            justifyContent="center"
                            sx={{ flexGrow: 0 }}
                        >
                            {variant !== 'profile' && (
                                <StyledDiv>
                                    {variant !== 'profile'
                                        ? `${
                                              isString(value) &&
                                              !isImageBase64(value) &&
                                              !isImageUrl(value)
                                                  ? value
                                                  : isArray(value) &&
                                                      value.length > 0
                                                    ? value[0].path
                                                    : placeholder
                                          }`
                                        : `${text}`}
                                </StyledDiv>
                            )}
                            <Button
                                color="secondary"
                                component="span"
                                fullWidth
                                variant={variantButton}
                                sx={sxButton}
                            >
                                {buttonText}
                            </Button>
                        </Box>
                        {Boolean(error) && (
                            <LabelError type="error">
                                {error?.message}
                            </LabelError>
                        )}
                    </label>
                </div>
            )}
        />
    );
};

ReactHookFormFile.defaultProps = {
    disabled: false,
    sxButton: {},
    variantButton: 'filled',
    visible: true,
};

ReactHookFormFile.propTypes = {
    accept: PropTypes.string,
    buttonText: PropTypes.string,
    classNameGroup: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.object,
    id: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.string,
    maxSize: PropTypes.number,
    maxSizeErrorMessage: PropTypes.string,
    meta: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setValue: PropTypes.func,
    sxButton: PropTypes.object,
    text: PropTypes.string,
    variant: PropTypes.string,
    variantButton: PropTypes.string,
    visible: PropTypes.bool,
};

export default ReactHookFormFile;
