import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import revokeApproval from './revokeApproval';

export default (proposalId) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Continue'),
        messages: [
            i18next.t(
                'Your client has approved the proposal, are you sure to revoke the approval?',
            ),
        ],
        onSuccess: () => dispatch(revokeApproval(proposalId)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
    };

    dispatch(alerts.actions.show(dataAlert));
};
