import { updateCompanyFinancialProduct } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_PRODUCT,
    SAVE_PRODUCT_FAILURE,
    SAVE_PRODUCT_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { financialProductActions } from '../reducer';
import * as selectors from '../selectors';

export default (values) => (dispatch, getState) => {
    const state = getState();
    const data = selectors.getProductsData(state);

    if (!values.id) return;

    const newValues = {
        id: values.id,
        name: values.name,
    };

    dispatch(financialProductActions[SAVE_PRODUCT]());

    updateCompanyFinancialProduct(newValues)
        .then((response) => {
            dispatch(
                financialProductActions[SAVE_PRODUCT_SUCCESS](response.data),
            );
            const newItems = data.map((item) => {
                if (item.id === values.id) {
                    return values;
                }
                return item;
            });
            dispatch(financialProductActions[UPDATE_ITEMS_SUCCESS](newItems));
            showToast();
        })
        .catch((error) => {
            dispatch(
                financialProductActions[SAVE_PRODUCT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
