import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_AGENTS,
    FETCH_AGENTS_FAILURE,
    FETCH_AGENTS_SUCCESS,
    FETCH_PHONE_CODES,
    FETCH_PHONE_CODES_FAILURE,
    FETCH_PHONE_CODES_SUCCESS,
    FETCH_ORIGIN_CONTACT,
    FETCH_ORIGIN_CONTACT_FAILURE,
    FETCH_ORIGIN_CONTACT_SUCCESS,
    FETCH_STATUS_CONTACT,
    FETCH_STATUS_CONTACT_FAILURE,
    FETCH_STATUS_CONTACT_SUCCESS,
    INITIALIZE_FORM,
    OPEN_DUPLICATE_MODAL,
    RESET_DATA_DUPLICATE_MODAL,
    RESET_FORM,
    SAVE_CONTACT,
    SAVE_CONTACT_FAILURE,
    SAVE_CONTACT_SUCCESS,
    SET_DATA_DUPLICATE_MODAL,
    SET_IS_NEW_RECORD,
    SHOW_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    agents: {
        data: [],
        error: null,
        isLoading: false,
    },
    dataDuplicateModal: {
        showPrimaryAction: false,
        primaryActionText: '',
        primaryActionHandler: null,
        showSecondaryAction: false,
        secondaryActionText: '',
        secondaryActionHandler: null,
        message: '',
    },
    initialValues: {
        advanced: false,
        agent: '',
        branch_office: '',
        company_name: '',
        company_position: '',
        contact_origin: null,
        emails: [],
        first_lastname: '',
        id: '',
        is_global: false,
        name: '',
        rfc: '',
        second_lastname: '',
        status_contact: '',
        status_flag: '',
        telephones: [],
    },
    originContact: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    phoneCodes: {
        data: {},
        error: null,
        isFetching: false,
    },
    isNewRecord: false,
    isOpenDuplicateModal: null,
    isOpenModal: false,
    saveContact: {
        isSaving: false,
        errors: null,
    },
    statusContact: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
};

const contactFormSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_AGENTS]: (state) => {
            state.agents.isLoading = true;
        },
        [FETCH_AGENTS_FAILURE]: (state, action) => {
            state.agents = {
                ...state.agents,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_AGENTS_SUCCESS]: (state, action) => {
            state.agents = {
                ...state.agents,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_PHONE_CODES]: (state) => {
            state.phoneCodes.isFetching = true;
        },
        [FETCH_PHONE_CODES_FAILURE]: (state, action) => {
            state.phoneCodes = {
                ...state.phoneCodes,
                error: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PHONE_CODES_SUCCESS]: (state, action) => {
            state.phoneCodes = {
                ...state.phoneCodes,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ORIGIN_CONTACT]: (state) => {
            state.originContact.isLoading = true;
        },
        [FETCH_ORIGIN_CONTACT_FAILURE]: (state, action) => {
            state.originContact = {
                ...state.originContact,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_ORIGIN_CONTACT_SUCCESS]: (state, action) => {
            state.originContact = {
                ...state.originContact,
                data: action.payload,
                isEmpty: action.payload.length === 0,
                isLoading: false,
            };
        },
        [FETCH_STATUS_CONTACT]: (state) => {
            state.statusContact.isLoading = true;
        },
        [FETCH_STATUS_CONTACT_FAILURE]: (state, action) => {
            state.statusContact = {
                ...state.statusContact,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_STATUS_CONTACT_SUCCESS]: (state, action) => {
            state.statusContact = {
                ...state.statusContact,
                data: action.payload,
                isEmpty: action.payload.length === 0,
                isLoading: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.saveContact.errors = null;
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [OPEN_DUPLICATE_MODAL]: (state, action) => {
            state.isOpenDuplicateModal = action.payload;
        },
        [RESET_DATA_DUPLICATE_MODAL]: (state, action) => {
            state.dataDuplicateModal = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.saveContact = {
                ...state.saveContact,
                errors: null,
                isLoading: false,
            };
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_CONTACT]: (state) => {
            state.saveContact.errors = null;
            state.saveContact.isSaving = true;
        },
        [SAVE_CONTACT_FAILURE]: (state, action) => {
            state.saveContact = {
                ...state.saveContact,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_CONTACT_SUCCESS]: (state) => {
            state.saveContact.isSaving = false;
        },
        [SET_DATA_DUPLICATE_MODAL]: (state, action) => {
            state.dataDuplicateModal = action.payload;
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [SHOW_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const contactFormActions = contactFormSlice.actions;

export default contactFormSlice.reducer;
