import { getProfiles } from 'common/api/v2/proposalProfiles';

import {
    FETCH_OFFER_PROFILES,
    FETCH_OFFER_PROFILES_FAILURE,
    FETCH_OFFER_PROFILES_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorOneColumnActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(proposalGeneratorOneColumnActions[FETCH_OFFER_PROFILES]());

        getProfiles(id)
            .then((response) =>
                dispatch(
                    proposalGeneratorOneColumnActions[
                        FETCH_OFFER_PROFILES_SUCCESS
                    ](response.data.data),
                ),
            )
            .catch((error) =>
                dispatch(
                    proposalGeneratorOneColumnActions[
                        FETCH_OFFER_PROFILES_FAILURE
                    ](error),
                ),
            )
            .finally(resolve);
    });
