import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getFunnelColumns = createSelector(
    getModel,
    (model) => model.funnelColumns,
);

export const getIsFetchingFunnelColumns = createSelector(
    getFunnelColumns,
    (funnelColumns) => funnelColumns.isFetching,
);

export const getFunnelColumnsData = createSelector(
    getFunnelColumns,
    (funnelColumns) => funnelColumns.data,
);

export const getFunnelColumnsErrors = createSelector(
    getFunnelColumns,
    (funnelColumns) => funnelColumns.errors,
);

export const getUpdatingStatusProject = createSelector(
    getModel,
    (model) => model.updatingStatusProject,
);

export const getUpdatingStatusProjectId = createSelector(
    getUpdatingStatusProject,
    (model) => (model ? model.projectId : model),
);

export const getRefreshingIsNeeded = createSelector(
    getModel,
    (model) => model.refreshingIsNeeded,
);

export const getNextPages = createSelector(
    getModel,
    (model) => model.nextPages,
);

export const getNextPagesIds = createSelector(
    getNextPages,
    (model) => model.ids,
);

export const getIsFetchingNextPages = createSelector(
    getNextPages,
    (model) => model.isFetching,
);
