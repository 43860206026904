import { createSlice } from '@reduxjs/toolkit';

import {
    CREATE_PARENT_FIELD_SEGMENTS,
    CREATE_PARENT_FIELD_SEGMENTS_FAILURE,
    CREATE_PARENT_FIELD_SEGMENTS_SUCCESS,
    DELETE_PARENT_FIELD_SEGMENTS,
    DELETE_PARENT_FIELD_SEGMENTS_FAILURE,
    DELETE_PARENT_FIELD_SEGMENTS_SUCCESS,
    FETCH_PARENT_FIELD_SEGMENTS,
    FETCH_PARENT_FIELD_SEGMENTS_FAILURE,
    FETCH_PARENT_FIELD_SEGMENTS_SUCCESS,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    parentFieldSegment: {
        data: {
            haveParentFieldSegment: false,
            segments: [],
            solarModulesDesignNumber: 0,
            solarModulesOfferNumber: 0,
        },
        errors: [],
        isFetching: true,
    },
    save: {
        data: [],
        errors: [],
        isFetching: false,
    },
};

const proposalGeneratorPanelsLayoutSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [CREATE_PARENT_FIELD_SEGMENTS]: (state) => {
            state.parentFieldSegment = {
                ...state.parentFieldSegment,
                errors: [],
                isFetching: true,
            };
        },
        [CREATE_PARENT_FIELD_SEGMENTS_FAILURE]: (state, action) => {
            state.parentFieldSegment = {
                ...state.parentFieldSegment,
                errors: action.payload,
                isFetching: false,
            };
        },
        [CREATE_PARENT_FIELD_SEGMENTS_SUCCESS]: (state, action) => {
            state.parentFieldSegment = {
                ...state.parentFieldSegment,
                data: action.payload,
                isFetching: false,
            };
        },
        [DELETE_PARENT_FIELD_SEGMENTS]: (state) => {
            state.parentFieldSegment = {
                ...state.parentFieldSegment,
                errors: [],
                isFetching: true,
            };
        },
        [DELETE_PARENT_FIELD_SEGMENTS_FAILURE]: (state, action) => {
            state.parentFieldSegment = {
                ...state.parentFieldSegment,
                errors: action.payload,
                isFetching: false,
            };
        },
        [DELETE_PARENT_FIELD_SEGMENTS_SUCCESS]: (state, action) => {
            state.parentFieldSegment = {
                ...state.parentFieldSegment,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PARENT_FIELD_SEGMENTS]: (state) => {
            state.parentFieldSegment = {
                ...state.parentFieldSegment,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PARENT_FIELD_SEGMENTS_FAILURE]: (state, action) => {
            state.parentFieldSegment = {
                ...state.parentFieldSegment,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PARENT_FIELD_SEGMENTS_SUCCESS]: (state, action) => {
            state.parentFieldSegment = {
                ...state.parentFieldSegment,
                data: action.payload,
                isFetching: false,
            };
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isFetching: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isFetching: false,
            };
        },
    },
});

export const proposalGeneratorPanelsLayoutActions =
    proposalGeneratorPanelsLayoutSlice.actions;

export default proposalGeneratorPanelsLayoutSlice.reducer;
