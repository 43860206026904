import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const getImageUrlByStatus = (provider, isDarkMode) => {
    if (!provider) return null;

    const { active, image, image_dark, inactive_image, inactive_image_dark } =
        provider;

    if (isDarkMode) return active ? image_dark : inactive_image_dark;
    return active ? image : inactive_image;
};

const ProviderImage = ({ provider, style }) => {
    const theme = useTheme();
    const url = getImageUrlByStatus(provider, theme.palette.mode === 'dark');

    if (!url) return null;
    return <img alt={provider.name} src={url} style={style} />;
};

ProviderImage.propTypes = {
    provider: PropTypes.object,
    style: PropTypes.object,
};

export default ProviderImage;
