import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button, UiDialog as Dialog } from 'sunwise-ui';

import * as selectors from '../../selectors';

import GroupsArray from './GroupsArray';

const ModalPeriodsSetup = ({
    control,
    disabled,
    handleOnClose,
    handleOnSave,
    isOpen,
    periods,
    proposalPeriods,
    setValue,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={handleOnClose}
            open={isOpen}
            size="lg"
            title={t('Select period', { count: 2 })}
        >
            <GroupsArray
                control={control}
                disabled={disabled}
                name="groups"
                periods={periods}
                proposalPeriods={proposalPeriods}
                setValue={setValue}
            />

            <BottomActions>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        gap: '16px',
                        justifyContent: { md: 'right', xs: 'center' },
                        pt: 2,
                        width: '100%',
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={() => handleOnClose()}
                        sx={{
                            order: { md: 1, xs: 2 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={disabled}
                        sx={{
                            order: { md: 2, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        onClick={handleOnSave}
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </BottomActions>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpen: selectors.getIsOpenPeriodsSettingsModal,
});

ModalPeriodsSetup.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    handleOnClose: PropTypes.func,
    handleOnSave: PropTypes.func,
    isOpen: PropTypes.bool,
    periods: PropTypes.array,
    proposalPeriods: PropTypes.array,
    setValue: PropTypes.func,
};

export default connect(mapStateToProps)(ModalPeriodsSetup);
