import React from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';

const DateTooltip = ({ createdAt }) => {
    const { t } = useTranslation();
    return (
        <Tooltip
            placement="top"
            title={`${t('Created at')} ${formatDate(
                parseDate(createdAt, 'dd/MM/yyyy HH:mm:ss'),
                'PP',
            )}`}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CalendarTodayIcon fontSize="small" />
                <Typography variant="caption">
                    {formatDate(
                        parseDate(createdAt, 'dd/MM/yyyy HH:mm:ss'),
                        'LLL dd',
                    )}
                </Typography>
            </Box>
        </Tooltip>
    );
};

DateTooltip.propTypes = {
    createdAt: PropTypes.string,
};

export default DateTooltip;
