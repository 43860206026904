import React from 'react';

import get from 'lodash/get';

import { POINTER_CURSOR } from '../constants';

import LabelName from './LabelName';

const ContactFormatter = (params) => {
    const { row } = params;
    const label = `${get(row, 'contact.name', '') || ''} ${
        get(row, 'contact.first_lastname', '') || ''
    } ${get(row, 'contact.second_lastname', '') || ''}`;

    return (
        <LabelName title={label} sx={{ cursor: `${POINTER_CURSOR}` }}>
            <strong>{label}</strong>
        </LabelName>
    );
};

ContactFormatter.propTypes = {};

export default ContactFormatter;
