import fetchStorageOffer from '../../energyBackupSetup/actions/fetchStorageOffer';
import fetchOfferAccesories from '../../proposalGeneratorAccessoryTable/actions/fetchOfferAccesories';
import fetchOfferAdditionals from '../../proposalGeneratorAdditionalTable/actions/fetchOfferAdditionals';
import fetchOfferComplements from '../../proposalGeneratorConfigurationModal/actions/fetchOfferComplements';
import fetchCompatibleRates from '../../proposalGeneratorConsumptionAnalysisVsGeneration/actions/fetchCompatibleRates';
import fetchOfferEnergyBackup from '../../proposalGeneratorEnergyStorageTable/actions/fetchOfferEnergyBackup';
import fetchOfferInverters from '../../proposalGeneratorInverterTable/actions/fetchOfferInverters';
import fetchSegments from '../../proposalGeneratorPanelsLayout/actions/fetchSegments';
import fetchOfferPanel from '../../proposalGeneratorPanelTable/actions/fetchOfferPanel';
import fetchOfferProducts from '../../proposalGeneratorQuote/actions/fetchOfferProducts';
import { fetchRateConcepts } from '../../proposalGeneratorRateConfiguration/actions';
import fetchOfferStructures from '../../proposalGeneratorStructureTable/actions/fetchOfferStructures';
import fetchOfferTaxes from '../../proposalGeneratorTaxesTable/actions/fetchOfferTaxes';
import fetchOfferWorkforce from '../../proposalGeneratorWorkforceTable/actions/fetchOfferWorkforce';
import { fetchTimeShiftingConfiguration } from '../../timeShiftingConfiguration/actions';

import fetchOfferConsumption from './fetchOfferConsumption';
import fetchOfferDetails from './fetchOfferDetails';
import fetchProfiles from './fetchProfiles';
import setIsLocked from './setIsLocked';

export default (endpointsName = [], proposalId, callback) =>
    (dispatch) => {
        const promisesList = [];

        for (let index = 0; index < endpointsName.length; index++) {
            switch (endpointsName[index]) {
                case 'accessory-offer':
                    promisesList.push(
                        dispatch(
                            fetchOfferAccesories(
                                proposalId,
                                endpointsName[index],
                            ),
                        ),
                    );
                    break;
                case 'additional-offer':
                    promisesList.push(
                        dispatch(
                            fetchOfferAdditionals(
                                proposalId,
                                endpointsName[index],
                            ),
                        ),
                    );
                    break;
                case 'complements-offer':
                    promisesList.push(
                        dispatch(fetchOfferComplements(proposalId)),
                    );
                    break;
                case 'consumption-and-generation-offer':
                    promisesList.push(
                        dispatch(fetchOfferConsumption(proposalId)),
                    );
                    break;
                case 'inverter-offer':
                    promisesList.push(
                        dispatch(
                            fetchOfferInverters(
                                proposalId,
                                endpointsName[index],
                            ),
                        ),
                    );
                    break;
                case 'next-valid-rate':
                    promisesList.push(
                        dispatch(fetchCompatibleRates(proposalId)),
                    );
                    break;
                case 'offer-design':
                    promisesList.push(dispatch(fetchSegments(proposalId)));
                    break;
                case 'offer-details':
                    promisesList.push(dispatch(fetchOfferDetails(proposalId)));
                    break;
                case 'panel-offer':
                    promisesList.push(
                        dispatch(
                            fetchOfferPanel(proposalId, endpointsName[index]),
                        ),
                    );
                    break;
                case 'products-offer':
                    promisesList.push(dispatch(fetchOfferProducts(proposalId)));
                    break;
                case 'proposal_profiles':
                    promisesList.push(dispatch(fetchProfiles(proposalId)));
                    break;
                case 'structure-offer':
                    promisesList.push(
                        dispatch(
                            fetchOfferStructures(
                                proposalId,
                                endpointsName[index],
                            ),
                        ),
                    );
                    break;
                case 'taxes-offer':
                    promisesList.push(
                        dispatch(
                            fetchOfferTaxes(proposalId, endpointsName[index]),
                        ),
                    );
                    break;
                case 'workforce-offer':
                    promisesList.push(
                        dispatch(
                            fetchOfferWorkforce(
                                proposalId,
                                endpointsName[index],
                            ),
                        ),
                    );
                    break;
                case 'battery-offer':
                    promisesList.push(
                        dispatch(
                            fetchOfferEnergyBackup(
                                proposalId,
                                endpointsName[index],
                            ),
                        ),
                    );
                    break;
                case 'storage-offer':
                    promisesList.push(dispatch(fetchStorageOffer(proposalId)));
                    break;
                case 'ps-ts-hourly-configuration':
                    promisesList.push(
                        dispatch(fetchTimeShiftingConfiguration(proposalId)),
                    );
                    break;
                case 'rate-concepts':
                    promisesList.push(dispatch(fetchRateConcepts(proposalId)));
                    break;
                default:
                    break;
            }
        }

        if (promisesList.length > 0) {
            Promise.all(promisesList)
                .then(() => {
                    if (callback) callback();
                })
                .catch(() => {
                    dispatch(setIsLocked(false));
                });
        }
    };
