import i18next from 'i18next';

import { uploadDocument } from 'common/api/v1/historicalDocument';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import {
    UPLOAD_FILE,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS,
} from '../actionTypes';
import { UPLOAD_ORIGINS } from '../constants';
import { populateFieldsFromCSVData } from '../helpers';
import { actions } from '../reducer';

import setConfigCsvImportModal from './setConfigCsvImportModal';

export default ({
        data,
        getValues,
        rate,
        result,
        setEnergyBalanceErrors,
        setValue,
    }) =>
    (dispatch, getState) => {
        if (!data?.file) return;

        const afterSalesSettingsId =
            afterSalesSettingsSelectors.getAfterSalesSettingsDataId(getState());

        dispatch(actions[UPLOAD_FILE]());

        dispatch(
            alerts.actions.show({
                confirmText: i18next.t('Back'),
                hideButtons: true,
                messages: [
                    i18next.t(
                        'Please wait a moment as the information is processed',
                    ),
                ],
                title: i18next.t('Loading').concat('...'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'info',
            }),
        );

        uploadDocument(afterSalesSettingsId, data?.file)
            .then((response) => {
                const responseData = response?.data?.data || {};

                const rateConfiguration =
                    afterSalesSettingsSelectors.getScheduleRateConfiguration(
                        getState(),
                    );

                if (rate?.id)
                    populateFieldsFromCSVData({
                        columnsFormat: data?.columnsFormat,
                        columnsMatch: data?.columnsMatch,
                        getValues,
                        rate,
                        rateConfiguration,
                        result,
                        setEnergyBalanceErrors,
                        setValue,
                    });

                setValue('file', responseData?.url);
                setValue('upload_origin', UPLOAD_ORIGINS.CSV);

                dispatch(alerts.actions.close());
                dispatch(setConfigCsvImportModal({ isOpen: false }));
                dispatch(actions[UPLOAD_FILE_SUCCESS]());
            })
            .catch((error) => {
                dispatch(actions[UPLOAD_FILE_FAILURE]());
                dispatch(alerts.actions.close());
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
