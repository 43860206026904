import BaseModel from './BaseModel';

export default class DocumentType extends BaseModel {}

DocumentType.modelName = 'DocumentType';
DocumentType.prepareFields({
    id: 'id',
    isActive: 'is_active',
    name: 'name',
});
