import React, { useEffect, useState } from 'react';

import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BottomActions, Box, Button, Tab, TabPanel, Tabs } from 'sunwise-ui';

import CustomTemplatesTab from './CustomTemplatesTab';
import DefaultTemplatesTab from './DefaultTemplatesTab';

const SelectBaseStep = ({
    control,
    isForSummaryTemplate,
    languagesDictionary,
    setValue,
}) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('certified-templates');
    const [categorySelected, selectCategory] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState({});

    const handleSelectTemplate = (template) => {
        const { id = null, locked = 0 } = template;
        setSelectedTemplate(template);
        setValue('custom_offer', id);
        setValue('locked', locked);
    };

    useEffect(() => {
        if (!isEmpty(selectedTemplate)) handleSelectTemplate({});
        if (!isNull(categorySelected)) selectCategory(null);
    }, [selectedTab]);

    const handleOnChangeTab = (_, newValue) => setSelectedTab(newValue);

    return (
        <Box flexDirection="column">
            <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={handleOnChangeTab}>
                        <Tab
                            key={'tab-certified-templates'}
                            id={'tab-certified-templates'}
                            label={t('Default', {
                                context: 'female',
                                count: 2,
                            })}
                            value={'certified-templates'}
                        />

                        <Tab
                            key={'tab-custom-templates'}
                            id={'tab-custom-templates'}
                            label={t('My template', { count: 2 })}
                            value={'custom-templates'}
                        />
                    </Tabs>
                </Box>

                <TabPanel
                    key={'tab-certified-templates'}
                    selectedTab={selectedTab}
                    value={'certified-templates'}
                >
                    <DefaultTemplatesTab
                        categorySelected={categorySelected}
                        control={control}
                        handleSelectTemplate={handleSelectTemplate}
                        isForSummaryTemplate={isForSummaryTemplate}
                        selectCategory={selectCategory}
                        selectedTemplate={selectedTemplate}
                    />
                </TabPanel>

                <TabPanel
                    key={'tab-custom-templates'}
                    selectedTab={selectedTab}
                    value={'custom-templates'}
                >
                    <CustomTemplatesTab
                        control={control}
                        handleSelectTemplate={handleSelectTemplate}
                        isForSummaryTemplate={isForSummaryTemplate}
                        languagesDictionary={languagesDictionary}
                    />
                </TabPanel>
            </>

            <BottomActions>
                <Box sx={{ pt: 2, width: '100%', textAlign: 'right' }}>
                    <Button
                        disabled={isEmpty(selectedTemplate)}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        type="submit"
                        variant="outlined"
                    >
                        {t('Create')}
                    </Button>
                </Box>
            </BottomActions>
        </Box>
    );
};

SelectBaseStep.propTypes = {
    control: PropTypes.object,
    isForSummaryTemplate: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    setValue: PropTypes.func,
};

export default SelectBaseStep;
