import { getRpusByServiceNumber } from 'common/modules/zap/actions';

import {
    FETCH_ZAP_RPUS,
    FETCH_ZAP_RPUS_FAILURE,
    FETCH_ZAP_RPUS_SUCCESS,
} from '../actionTypes';
import { SERVICE_NUMBER_REGEX } from '../constants';
import { projectConsumptionModalActions } from '../reducer';

export default (serviceNumber) => (dispatch) => {
    const isValid = SERVICE_NUMBER_REGEX.test(
        serviceNumber?.replaceAll(' ', ''),
    );

    if (!isValid) return;

    dispatch(projectConsumptionModalActions[FETCH_ZAP_RPUS]());

    getRpusByServiceNumber(serviceNumber.replace(/\s+/g, ''))
        .then((response) =>
            dispatch(
                projectConsumptionModalActions[FETCH_ZAP_RPUS_SUCCESS](
                    response?.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                projectConsumptionModalActions[FETCH_ZAP_RPUS_FAILURE](
                    error?.response?.data?.detail,
                ),
            ),
        );
};
