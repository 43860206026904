import { getData } from 'common/api/v1/accumulatedSavings';
import { formatDate } from 'common/utils/dates';

import { DATE_FORMATS } from '../../afterSalesSettings/constants';
import {
    FETCH_ACCUMULATED_SAVINGS,
    FETCH_ACCUMULATED_SAVINGS_FAILURE,
    FETCH_ACCUMULATED_SAVINGS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (projectId, callback) => (dispatch) => {
    if (!projectId) return;

    dispatch(actions[FETCH_ACCUMULATED_SAVINGS]());
    const finalDate = formatDate(new Date(), DATE_FORMATS.SUNWISE);

    getData(projectId, finalDate)
        .then((response) =>
            dispatch(
                actions[FETCH_ACCUMULATED_SAVINGS_SUCCESS](
                    response?.data?.data || {},
                ),
            ),
        )
        .catch((error) =>
            dispatch(actions[FETCH_ACCUMULATED_SAVINGS_FAILURE](error)),
        )
        .finally(() => {
            if (callback) callback();
        });
};
