import React, { useEffect, useMemo, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DataGrid } from 'sunwise-ui';

import { CustomPagination, SingleAutocomplete } from 'common/components';

import {
    getFilteredPeriodsByYear,
    getUniqueYearsFromPeriods,
} from '../helpers';

const dataGridSx = {
    '& .MuiDataGrid-columnHeaderTitle': {
        lineHeight: 1,
        textAlign: 'center',
        textOverflow: 'clip',
        whiteSpace: 'break-spaces',
    },
    '& .MuiDataGrid-cell:focus': { outline: 'none' },
};

const HistoricalDataGrid = ({ columns, dateFields, isLoading, rows }) => {
    const [filteredPeriods, setFilteredPeriods] = useState([]);
    const { t } = useTranslation();

    useEffect(() => setFilteredPeriods(rows), [rows]);

    const yearsOptions = useMemo(
        () => getUniqueYearsFromPeriods(rows, dateFields),
        [dateFields, rows],
    );

    const handleOnChangeYear = (option) =>
        setFilteredPeriods(
            getFilteredPeriodsByYear(rows, dateFields, option?.value),
        );

    return (
        <>
            {yearsOptions?.length > 1 && (
                <Box mb={2}>
                    <SingleAutocomplete
                        disabled={isLoading}
                        fullWidth
                        isLoading={isLoading}
                        label={t('Year')}
                        onChange={handleOnChangeYear}
                        options={[{ label: '', options: yearsOptions }]}
                    />
                </Box>
            )}

            <DataGrid
                autoHeight
                columns={columns}
                components={{
                    LoadingOverlay: LinearProgress,
                    Pagination: CustomPagination,
                }}
                disableColumnMenu
                disableSelectionOnClick
                getRowHeight={() => 'auto'}
                loading={isLoading}
                pageSize={12}
                rows={filteredPeriods}
                sx={dataGridSx}
            />
        </>
    );
};

HistoricalDataGrid.propTypes = {
    columns: PropTypes.array,
    dateFields: PropTypes.array,
    isLoading: PropTypes.bool,
    rows: PropTypes.array,
};

export default HistoricalDataGrid;
