import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box, Grid, Skeleton, Typography } from 'sunwise-ui';

import { ChipProposalStatus, ProposalStatusIcon } from 'common/components';
import { getStatusProposalLabel } from 'common/utils/helpers';

import Breadcrumbs from './Breadcrumbs';

const Header = ({
    handleClickBreadcrumb,
    isFetchingOfferDetails,
    isGenerated,
    reportDetails,
}) => (
    <Grid container sx={{ mb: { md: 2, xs: 0 }, pt: 2 }}>
        <Grid
            item
            md={14}
            sx={{ display: { md: 'block', xs: 'none' } }}
            xs={18}
        >
            <Box alignItems="center" display="flex" gap={1}>
                <FactCheckIcon sx={{ color: 'primary.main' }} />
                {isFetchingOfferDetails ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: '1rem', width: '100%' }}
                    />
                ) : (
                    <Typography variant="h4">
                        {get(reportDetails, 'name', null)}
                    </Typography>
                )}
            </Box>

            <Box alignItems="center" display="flex" gap={1}>
                <Box sx={{ width: '24px' }} />
                <BusinessIcon sx={{ color: 'primary.main' }} />
                {isFetchingOfferDetails ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: '1rem', width: '100%' }}
                    />
                ) : (
                    <Typography fontWeight="bold" variant="body2">
                        {get(reportDetails, 'branch_office.name', null)}
                    </Typography>
                )}
            </Box>

            {isFetchingOfferDetails ? (
                <Skeleton
                    sx={{ fontSize: '1rem', width: '100%' }}
                    variant="text"
                />
            ) : (
                <Breadcrumbs
                    handleClickBreadcrumb={handleClickBreadcrumb}
                    pl={4}
                    reportDetails={reportDetails}
                />
            )}
        </Grid>
        <Grid
            item
            md={4}
            sx={{ display: { md: 'block', xs: 'none' } }}
            textAlign="right"
            xs={18}
        >
            {isFetchingOfferDetails ? (
                <Skeleton
                    sx={{ fontSize: '1rem', width: '100%' }}
                    variant="text"
                />
            ) : (
                <ChipProposalStatus
                    icon={<ProposalStatusIcon isGenerated={isGenerated} />}
                    label={getStatusProposalLabel(false, isGenerated)}
                />
            )}
        </Grid>
    </Grid>
);

Header.propTypes = {
    handleClickBreadcrumb: PropTypes.func,
    isFetchingOfferDetails: PropTypes.bool,
    isGenerated: PropTypes.bool,
    reportDetails: PropTypes.object,
};

export default Header;
