import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Skeleton, Tooltip, Typography } from 'sunwise-ui';

const Wrapper = styled(Box)`
    border-radius: 8px;
    display: flex;
    width: 100%;
`;

const IconColumn = styled(Box)(
    ({ theme }) =>
        `
        align-items: center;
        background: ${theme.palette.primary.main};
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        display: flex;
        justify-content: center;
        padding: 8px;
        svg path { fill: ${theme.palette.primary.contrastText}; }
        `,
);

const getVariationIcon = (variation) => {
    if (variation > 0) return <ArrowDropUpIcon sx={{ color: '#3CAF5F' }} />;
    if (variation < 0) return <ArrowDropDownIcon sx={{ color: '#DA1E28' }} />;
    return null;
};

const KeyIndicator = ({
    extra,
    height,
    infoTitle,
    isLoading,
    preffix,
    svgIcon,
    title,
    unit,
    value,
    variation,
    variationValue,
    widthIcon,
}) => {
    const displayPreffix = preffix ? <span>{preffix}</span> : null;
    const displayUnit = unit ? <span>{unit}</span> : null;
    return (
        <Wrapper
            sx={{
                backgroundColor: 'background.neutral',
                height,
            }}
        >
            <IconColumn sx={{ width: widthIcon }}>{svgIcon}</IconColumn>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 1,
                    width: '100%',
                }}
            >
                {isLoading ? (
                    <Box display="flex" flexDirection="column">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton
                            variant="text"
                            sx={{ fontSize: '1rem', width: '100px' }}
                        />
                    </Box>
                ) : (
                    <Box display="flex" gap={1}>
                        <Box>
                            <Box display="flex" gap={1}>
                                <Typography variant="caption">
                                    {title}
                                </Typography>
                                {infoTitle && (
                                    <Tooltip placement="top" title={infoTitle}>
                                        <QuestionMarkIcon
                                            fontSize="10px"
                                            sx={{
                                                backgroundColor:
                                                    'secondary.main',
                                                borderRadius: '50%',
                                                color: 'secondary.contrastText',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </Box>

                            <Box alignItems="center" display="flex" gap={1}>
                                <Typography variant="body2" fontWeight="bold">
                                    {displayPreffix} {value} {displayUnit}
                                </Typography>

                                <Box alignItems="center" display="flex">
                                    <Typography variant="caption">
                                        {variation > 0 ? '+' : ''}
                                        {variationValue}
                                        {variationValue ? ' %' : ''}
                                    </Typography>

                                    {getVariationIcon(variation)}
                                </Box>
                            </Box>
                        </Box>
                        {extra && <Box sx={{ ml: 'auto' }}>{extra}</Box>}
                    </Box>
                )}
            </Box>
        </Wrapper>
    );
};

KeyIndicator.defaultProps = {
    height: 'auto',
    widthIcon: '40px',
};

KeyIndicator.propTypes = {
    extra: PropTypes.node,
    height: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    infoTitle: PropTypes.string,
    isLoading: PropTypes.bool,
    preffix: PropTypes.string,
    svgIcon: PropTypes.object,
    title: PropTypes.string,
    unit: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    variation: PropTypes.number,
    variationValue: PropTypes.string,
    widthIcon: PropTypes.string,
};

export default KeyIndicator;
