import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import { useSetChart } from 'common/hooks';

import EconomicChartJs from './EconomicChartJs';
import EnergyChartJs from './EnergyChartJs';

const ChartComponent = ({
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    indicatorText = false,
    infoPriceConsumtion,
    monthskw,
    monthskwh,
}) => {
    const { t } = useTranslation();
    const { value: chartId, setChartValue } = useSetChart('chartEnergy');

    return (
        <>
            <ToggleButtonGroup
                color="secondary"
                exclusive
                fullWidth
                onChange={(e) => setChartValue(e.target.value)}
                size="small"
                sx={{ mb: 2 }}
                value={chartId}
            >
                <ToggleButton value={'chartEnergy'}>
                    {t('Energetic')}
                </ToggleButton>
                {infoPriceConsumtion?.length > 0 && (
                    <ToggleButton value={'chartEconomic'}>
                        {t('Economical')}
                    </ToggleButton>
                )}
            </ToggleButtonGroup>

            <Box>
                {chartId === 'chartEnergy' && (
                    <EnergyChartJs
                        countryCurrencyLocale={countryCurrencyLocale}
                        indicatorText={indicatorText}
                        monthskw={monthskw}
                        monthskwh={monthskwh}
                    />
                )}

                {chartId === 'chartEconomic' && (
                    <EconomicChartJs
                        {...{
                            countryCurrencyIso,
                            countryCurrencyLocale,
                            countryCurrencySymbol,
                            indicatorText,
                            infoPriceConsumtion,
                            monthskwh,
                        }}
                    />
                )}
            </Box>
        </>
    );
};

ChartComponent.propTypes = {
    countryCurrencyIso: PropTypes.string,
    countryCurrencyLocale: PropTypes.string,
    countryCurrencySymbol: PropTypes.string,
    indicatorText: PropTypes.bool,
    infoPriceConsumtion: PropTypes.array,
    monthskw: PropTypes.array,
    monthskwh: PropTypes.array,
};

export default ChartComponent;
