import { getItems } from 'common/api/v2/offerTaxes';

import updateProductsData from '../../proposalGeneratorQuote/actions/updateProductsData';
import {
    FETCH_OFFER_TAXES,
    FETCH_OFFER_TAXES_FAILURE,
    FETCH_OFFER_TAXES_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorTaxesTableActions } from '../reducer';

import initialValues from './initialValues';
import resetForm from './resetForm';

export default (id, name) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(proposalGeneratorTaxesTableActions[FETCH_OFFER_TAXES]());

        getItems(id)
            .then((response) => {
                dispatch(resetForm());

                const values = response.data.data;

                dispatch(
                    proposalGeneratorTaxesTableActions[
                        FETCH_OFFER_TAXES_SUCCESS
                    ](response.data.data),
                );

                dispatch(initialValues(values));
                dispatch(updateProductsData(name, values));
            })
            .catch((error) =>
                dispatch(
                    proposalGeneratorTaxesTableActions[
                        FETCH_OFFER_TAXES_FAILURE
                    ](error?.response?.data?.errors),
                ),
            )
            .finally(resolve);
    });
