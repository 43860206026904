import React, { useEffect } from 'react';

import { useTheme } from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import AccordionCard from 'common/components/accordions/AccordionCard';
import IconSaveEnergy from 'common/components/icons/IconSaveEnergy';
import { DEBOUNCE_TIME } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as proposalGeneratorOneColumnSelectors from '../proposalGeneratorOneColumn/selectors';
import * as proposalGeneratorPanelTableSelectors from '../proposalGeneratorPanelTable/selectors';
import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';
import fetchRateAlerts from '../rateAlerts/actions/fetchAlerts';
import * as rateAlertsSelectors from '../rateAlerts/selectors';

import * as actions from './actions';
import EnergySavingTitle from './components/EnergySavingTitle';
import PanelForm from './components/PanelForm';
import { handleNormalizeBuild } from './helpers';
import * as selectors from './selectors';

const Container = ({
    canModify,
    contractedDemand,
    customerEnergyData,
    dataOfferConfigGeneration,
    energySaving,
    fetchRateAlerts,
    initialize,
    initialRate,
    isFetchingOfferProducts,
    isLoading,
    isLocked,
    isSaving,
    isUpdatingPanel,
    nextRateData,
    offerInverters,
    offerPanels,
    originalConsumption,
    proposalId,
    rateAlertsData,
    save,
    sunHours,
    systemSize,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { pv_system_data_source: pvSystemDataSource } =
        dataOfferConfigGeneration;

    useEffect(() => {
        if (isEmpty(offerPanels)) return;
        initialize(offerPanels, energySaving, systemSize);
    }, [offerPanels, energySaving, systemSize]);

    useEffect(() => {
        if (isEmpty(nextRateData)) return;
        fetchRateAlerts({
            ...nextRateData,
            isCertified: nextRateData?.certified,
        });
    }, [nextRateData]);

    const handleOnSave = React.useRef(debounce(save, DEBOUNCE_TIME)).current;

    const handleNormalize = handleNormalizeBuild(handleOnSave, proposalId);

    const isLoadingForm =
        isFetchingOfferProducts || isUpdatingPanel || isLoading || isSaving;

    const customTitle = (
        <EnergySavingTitle
            svgIcon={<IconSaveEnergy color={theme.palette.primary.main} />}
            title={t('Energy saving')}
        />
    );

    return (
        <AccordionCard
            cardId="proposal_generator_energy_saving_card"
            classNameExpandIcon="__userguiding_energy_saving_button_collapse"
            customTitle={customTitle}
            defaultExpanded={true}
            isLoading={isLoadingForm}
        >
            <PanelForm
                canModify={canModify}
                contractedDemand={contractedDemand}
                customerEnergyData={customerEnergyData}
                handleNormalize={handleNormalize}
                initialRate={initialRate}
                isLoading={isLoadingForm}
                isLocked={isLoadingForm || isLocked}
                isLockedDefault={isLocked}
                numOfferPanels={offerPanels.length}
                offerInverters={offerInverters}
                originalConsumption={originalConsumption}
                pvSystemDataSource={pvSystemDataSource}
                rateAlertsData={rateAlertsData}
                sunHours={sunHours}
            />
        </AccordionCard>
    );
};

const mapStateToProps = createStructuredSelector({
    dataOfferConfigGeneration:
        proposalGeneratorOneColumnSelectors.getDataOfferConfigGeneration,
    isFetchingOfferProducts:
        proposalGeneratorQuoteSelectors.getIsFetchingOfferProducts,
    isSaving: selectors.getIsSaving,
    isUpdatingPanel: proposalGeneratorPanelTableSelectors.getIsUpdating,
    nextRateData: proposalGeneratorOneColumnSelectors.getNextRateData,
    offerInverters: proposalGeneratorQuoteSelectors.getOfferInvertersData,
    offerPanels: proposalGeneratorQuoteSelectors.getOfferPanelsData,
    rateAlertsData: rateAlertsSelectors.getFetchAlertsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRateAlerts: (id) => dispatch(fetchRateAlerts(id)),
    initialize: (values, energySaving, systemSize, pvSystemDataSource) =>
        dispatch(
            actions.initialValues(
                values,
                energySaving,
                systemSize,
                pvSystemDataSource,
            ),
        ),
    save: (id, values) => dispatch(actions.save(id, values)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    contractedDemand: PropTypes.number,
    customerEnergyData: PropTypes.object,
    dataOfferConfigGeneration: PropTypes.object,
    energySaving: PropTypes.number,
    fetchRateAlerts: PropTypes.func,
    initialize: PropTypes.func,
    initialRate: PropTypes.string,
    isFetchingOfferProducts: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    isUpdatingPanel: PropTypes.bool,
    nextRateData: PropTypes.object,
    offerInverters: PropTypes.array,
    offerPanels: PropTypes.array,
    originalConsumption: PropTypes.number,
    proposalId: PropTypes.string,
    rateAlertsData: PropTypes.array,
    save: PropTypes.func,
    sunHours: PropTypes.number,
    systemSize: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROPOSAL_PANELS_PERMISSION),
)(Container);
