import { ONE_TO_MANY } from '../constants';

import BaseModel from './BaseModel';
export default class Document extends BaseModel {}
Document.modelName = 'Document';
Document.prepareFields({
    createdAt: 'created_at',
    documentTypeId: {
        key: 'document_type_id',
        relationType: ONE_TO_MANY,
        options: {
            to: 'DocumentType',
            as: 'documentType',
            relatedName: 'documents',
        },
    },
    file: 'file',
    id: 'id',
    name: 'name',
    valueUser: 'value_user',
});
