import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteReport from './deleteReport';

export default (report, projectId) => (dispatch) => {
    if (!report?.id) return;

    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [
            i18next.t('Are you sure to delete the "{{reportName}}" report?', {
                reportName: report.name,
            }),
        ],
        onSuccess: () => dispatch(deleteReport(report.id, projectId)),
        title: i18next.t('Delete report'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
