import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getShowLisaLoading = createSelector(
    getModel,
    (model) => model.showLisaLoading,
);

export const socketOcr = createSelector(getModel, (model) => model.socketOcr);

export const getFilesSocketOcr = createSelector(
    socketOcr,
    (socketOcr) => socketOcr.files,
);
