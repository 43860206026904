import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model?.isOpenSettingsModal,
);

export const getNotificationSettings = createSelector(
    getModel,
    (model) => model.fetchNotificationSettings,
);

export const getIsFetchingNotificationSettings = createSelector(
    getNotificationSettings,
    (value) => value?.isFetching || false,
);

export const getSaveNotificationSettings = createSelector(
    getModel,
    (model) => model.saveNotificationSettings,
);

export const getIsSavingNotificationSettings = createSelector(
    getSaveNotificationSettings,
    (model) => model.isSaving,
);

export const getSaveNotificationSettingsErrors = createSelector(
    getSaveNotificationSettings,
    (model) => model.errors,
);
