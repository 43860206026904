import React from 'react';

import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import * as actions from './actions';
import CardContent from './components/CardContent';
import ModalForm from './components/ModalForm';
import PoolTag from './components/PoolTag';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    selectedProject,
    prepareRecalculateConsumptionHistory,
}) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Header
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="body1" fontWeight="bold" mr="auto">
                    {t('Historical')}
                </Typography>

                <PoolTag projectId={selectedProject?.id} />

                <DropdownCardMenu
                    items={[
                        {
                            label: t('Recalculate pool'),
                            handleClick: () =>
                                prepareRecalculateConsumptionHistory(
                                    selectedProject?.id,
                                ),
                            icon: <ManageHistoryIcon />,
                            visible: canModify,
                        },
                    ]}
                    visible={canModify}
                />
            </Card.Header>

            <CardContent
                canDelete={canDelete}
                canModify={canModify}
                projectId={selectedProject?.id}
            />

            <ModalForm selectedProject={selectedProject} />
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    availableBag: selectors.getTodayAvailableBag,
    isFetchingAvailableBag: selectors.getIsFetchingTodayAvailableBag,
});

const mapDispatchToProps = (dispatch) => ({
    prepareRecalculateConsumptionHistory: (projectId) =>
        dispatch(actions.prepareRecalculateConsumptionHistory(projectId)),
});

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    prepareRecalculateConsumptionHistory: PropTypes.func,
    selectedProject: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
