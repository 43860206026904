import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton, Box } from 'sunwise-ui';

import ProposalChartJs from 'common/components/charts/ProposalChartJs';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { getCountryCurrency } from 'common/utils/helpers/session';

const ConsumptionAnalysisVsGenerationCharts = ({
    consumptionProjection,
    isFetching,
    proposalConfiguration,
    solarSimulationData,
}) => {
    const { t } = useTranslation();
    const countryCurrency = getCountryCurrency();

    if (isFetching) return <Skeleton variant="rounded" height={400} />;

    return (
        <Box>
            <Box mb={2}>
                <TitleWithDetail variant="body2">
                    {t('Consumption vs Generation Analysis')}
                </TitleWithDetail>
            </Box>
            <ProposalChartJs
                consumptionProjection={consumptionProjection}
                countryCurrency={countryCurrency}
                proposalConfiguration={proposalConfiguration}
                solarSimulationData={solarSimulationData}
            />
        </Box>
    );
};

ConsumptionAnalysisVsGenerationCharts.propTypes = {
    consumptionProjection: PropTypes.object,
    isFetching: PropTypes.bool,
    proposalConfiguration: PropTypes.object,
    solarSimulationData: PropTypes.object,
};

export default ConsumptionAnalysisVsGenerationCharts;
