import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_NOTIFICATIONS_SETTINGS,
    FETCH_NOTIFICATIONS_SETTINGS_FAILURE,
    FETCH_NOTIFICATIONS_SETTINGS_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SAVE_NOTIFICATION_SETTINGS,
    SAVE_NOTIFICATION_SETTINGS_FAILURE,
    SAVE_NOTIFICATION_SETTINGS_SUCCESS,
    SET_IS_OPEN_SETTINGS_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchNotificationSettings: {
        errors: [],
        isFetching: true,
    },
    initialValues: {
        settings: [],
    },
    isOpenSettingsModal: false,
    saveNotificationSettings: {
        isSaving: false,
        errors: [],
    },
};

const notificationsSettingsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_NOTIFICATIONS_SETTINGS]: (state) => {
            state.fetchNotificationSettings.isFetching = true;
        },
        [FETCH_NOTIFICATIONS_SETTINGS_FAILURE]: (state, action) => {
            state.fetchNotificationSettings = {
                ...state.fetchNotificationSettings,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_NOTIFICATIONS_SETTINGS_SUCCESS]: (state) => {
            state.fetchNotificationSettings.isFetching = false;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_NOTIFICATION_SETTINGS]: (state) => {
            state.saveNotificationSettings.isSaving = true;
        },
        [SAVE_NOTIFICATION_SETTINGS_FAILURE]: (state, action) => {
            state.saveNotificationSettings = {
                ...state.saveNotificationSettings,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_NOTIFICATION_SETTINGS_SUCCESS]: (state) => {
            state.saveNotificationSettings.isSaving = false;
        },
        [SET_IS_OPEN_SETTINGS_MODAL]: (state, action) => {
            state.isOpenSettingsModal = action.payload;
        },
    },
});

export const notificationsSettingsActions = notificationsSettingsSlice.actions;

export default notificationsSettingsSlice.reducer;
