import React from 'react';

import PropTypes from 'prop-types';
import { useFieldArray, useWatch } from 'react-hook-form';
import { Box, Collapse, Divider, Grid, Typography } from 'sunwise-ui';

import { TitleWithDetail } from 'common/components';
import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';

const ActiveTierFields = ({
    activeKey,
    caption,
    control,
    disabled,
    fieldsKey,
    onChangeActiveField,
    title,
    valueKey = 'max_power',
}) => {
    const { fields } = useFieldArray({ control, name: fieldsKey });
    const isActive = useWatch({ control, name: activeKey });

    return (
        <>
            <Box display="flex" gap={1}>
                <TitleWithDetail variant="body2">{title}</TitleWithDetail>
                <ReactHookFormSwitch
                    control={control}
                    disabled={disabled}
                    name={activeKey}
                    onChange={onChangeActiveField}
                />
            </Box>

            <Divider sx={{ my: 1 }} />

            <Collapse in={isActive}>
                <Grid container spacing={2}>
                    <Grid item xs={18}>
                        <Typography variant="caption">{caption}</Typography>
                    </Grid>

                    {fields?.map((item, index) => (
                        <Grid item xs={18} sm key={item?.id}>
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                append="kW"
                                control={control}
                                disabled={disabled}
                                fullWidth
                                label={item?.label}
                                min={0}
                                name={`${fieldsKey}.${index}.${valueKey}`}
                                placeholder="0"
                            />
                        </Grid>
                    ))}
                </Grid>
            </Collapse>
        </>
    );
};

ActiveTierFields.propTypes = {
    activeKey: PropTypes.string,
    caption: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fieldsKey: PropTypes.string,
    onChangeActiveField: PropTypes.func,
    title: PropTypes.string,
    valueKey: PropTypes.string,
};

export default ActiveTierFields;
