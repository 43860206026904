import { NAME } from './constants';

export const ACTIVATE_ITEM = `${NAME}/ACTIVATE_ITEM`;
export const ACTIVATE_ITEM_FAILURE = `${NAME}/ACTIVATE_ITEM_FAILURE`;
export const ACTIVATE_ITEM_SUCCESS = `${NAME}/ACTIVATE_ITEM_SUCCESS`;

export const CHANGE_SEARCH_TEXT = `${NAME}/CHANGE_SEARCH_TEXT`;

export const DELETE_USER = `${NAME}/DELETE_USER`;
export const DELETE_USER_FAILURE = `${NAME}/DELETE_USER_FAILURE`;
export const DELETE_USER_SUCCESS = `${NAME}/DELETE_USER_SUCCESS`;

export const FETCH_ROLES = `${NAME}/FETCH_ROLES`;
export const FETCH_ROLES_FAILURE = `${NAME}/FETCH_ROLES_FAILURE`;
export const FETCH_ROLES_SUCCESS = `${NAME}/FETCH_ROLES_SUCCESS`;

export const FETCH_ITEMS = `${NAME}/FETCH_ITEMS`;
export const FETCH_ITEMS_FAILURE = `${NAME}/FETCH_ITEMS_FAILURE`;
export const FETCH_ITEMS_SUCCESS = `${NAME}/FETCH_ITEMS_SUCCESS`;

export const FETCH_LICENSES = `${NAME}/FETCH_LICENSES`;
export const FETCH_LICENSES_FAILURE = `${NAME}/FETCH_LICENSES_FAILURE`;
export const FETCH_LICENSES_SUCCESS = `${NAME}/FETCH_LICENSES_SUCCESS`;

export const FETCH_REASSIGN_CONTACTS = `${NAME}/FETCH_REASSIGN_CONTACTS`;
export const FETCH_REASSIGN_CONTACTS_FAILURE = `${NAME}/FETCH_REASSIGN_CONTACTS_FAILURE`;
export const FETCH_REASSIGN_CONTACTS_SUCCESS = `${NAME}/FETCH_REASSIGN_CONTACTS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const INVALID_PASSWORD = `${NAME}/INVALID_PASSWORD`;
export const INVALID_PASSWORD_CONFIRMATION = `${NAME}/INVALID_PASSWORD_CONFIRMATION`;
export const INVALID_PASSWORD_CONFIRMATION_LENGTH = `${NAME}/INVALID_PASSWORD_CONFIRMATION_LENGTH`;

export const OPEN_MODAL_PRODUCT = `${NAME}/OPEN_MODAL_PRODUCT`;
export const OPEN_MODAL_PASSWORD = `${NAME}/OPEN_MODAL_PASSWORD`;

export const OPEN_REASSIGN_TYPE_UPDATE_MODAL = `${NAME}/OPEN_REASSIGN_TYPE_UPDATE_MODAL`;
export const OPEN_REASSIGN_TYPE_DEACTIVATE_MODAL = `${NAME}/OPEN_REASSIGN_TYPE_DEACTIVATE_MODAL`;

export const CLOSE_REASSIGN_MODAL = `${NAME}/CLOSE_REASSIGN_MODAL`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_REASSIGN_FORM = `${NAME}/RESET_REASSIGN_FORM`;

export const RESTART_PASSWORD = `${NAME}/RESTART_PASSWORD`;
export const RESTART_PASSWORD_FAILURE = `${NAME}/RESTART_PASSWORD_FAILURE`;
export const RESTART_PASSWORD_SUCCESS = `${NAME}/RESTART_PASSWORD_SUCCESS`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;

export const VALID_PASSWORD = `${NAME}/VALID_PASSWORD`;
export const VALID_PASSWORD_CONFIRMATION = `${NAME}/VALID_PASSWORD_CONFIRMATION`;
export const VALID_PASSWORD_CONFIRMATION_LENGTH = `${NAME}/VALID_PASSWORD_CONFIRMATION_LENGTH`;
