import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropDownMenu from 'common/components/DropDownMenu';

const FilterDropdown = ({ defaultValue, handleSelected }) => {
    const { t } = useTranslation();
    return (
        <DropDownMenu
            defaultValue={Number(defaultValue)}
            handleSelected={handleSelected}
            itemsToSelect={[
                {
                    value: false,
                    label: t('Active'),
                },
                {
                    value: true,
                    label: t('Archived'),
                },
            ]}
            sxButton={{ width: { xs: '100%', md: 'auto' } }}
        />
    );
};

FilterDropdown.propTypes = {
    defaultValue: PropTypes.bool,
    handleSelected: PropTypes.func,
};

export default FilterDropdown;
