import i18next from 'i18next';
import { object, string } from 'yup';

import { differenceInDaysDate, parseDate } from 'common/utils/dates';

import { DATE_FORMATS, FILTERS_KEYS, MAX_DAYS_RANGE } from './constants';
import { getAfterSalesDateRange } from './helpers';

const validateDateRange = (initialDate, finalDate, freq) => {
    if (!initialDate || !finalDate || !freq) return true;

    const { final_date, initial_date } = getAfterSalesDateRange({
        final_date: finalDate,
        freq,
        initial_date: initialDate,
    });

    const maxDays = MAX_DAYS_RANGE[freq];

    return (
        differenceInDaysDate(
            parseDate(final_date, DATE_FORMATS.AFTER_SALES),
            parseDate(initial_date, DATE_FORMATS.AFTER_SALES),
        ) <= maxDays
    );
};

export default () => {
    return object().shape({
        [FILTERS_KEYS.INITIAL_DATE]: string().test(
            'min-date',
            i18next.t('Date range is too long for the selected interval'),
            function (value) {
                const finalDate = this.parent[FILTERS_KEYS.FINAL_DATE];
                const freq = this.parent[FILTERS_KEYS.FREQ];

                return validateDateRange(value, finalDate, freq);
            },
        ),
        [FILTERS_KEYS.FINAL_DATE]: string().test(
            'max-date',
            i18next.t('Date range is too long for the selected interval'),
            function (value) {
                const initialDate = this.parent[FILTERS_KEYS.INITIAL_DATE];
                const freq = this.parent[FILTERS_KEYS.FREQ];

                return validateDateRange(initialDate, value, freq);
            },
        ),
    });
};
