import { getItemsByParent } from 'common/api/v1/politicalDivisions';

import {
    FETCH_POLITICAL_DIVISIONS2,
    FETCH_POLITICAL_DIVISIONS2_FAILURE,
    FETCH_POLITICAL_DIVISIONS2_SUCCESS,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';

import fetchIrradiationByStateAndSources from './fetchIrradiationByStateAndSources';

export default (political_division, setValue) => (dispatch) => {
    dispatch(solarGenerationActions[FETCH_POLITICAL_DIVISIONS2]());
    getItemsByParent(political_division)
        .then(({ data }) => {
            dispatch(
                solarGenerationActions[FETCH_POLITICAL_DIVISIONS2_SUCCESS](
                    data.data,
                ),
            );
            setValue('political_division2', data.data[0].id);
            dispatch(
                fetchIrradiationByStateAndSources(
                    data.data[0].id,
                    political_division,
                    setValue,
                ),
            );
        })
        .catch((error) =>
            dispatch(
                solarGenerationActions[FETCH_POLITICAL_DIVISIONS2_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
