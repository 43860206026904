import i18next from 'i18next';

import { addAllianceProduct } from 'common/api/v1/sunpay';
import showToast from 'common/utils/showToast';

export default (id, callback) => () => {
    const newValues = {
        financier_product: id,
    };

    addAllianceProduct(newValues).then(() => {
        showToast({
            body: i18next.t('The product was successfully added'),
        });

        if (callback) callback();
    });
};
