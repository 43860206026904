import API from '../..';

const ENTITY_INTEGRATIONS = 'integrations/integrations';
const ENTITY_SUPLIERS = 'integrations/suppliers';

export const addKey = (data) =>
    API.post(`/api/v1/${ENTITY_INTEGRATIONS}/`, data);
export const deleteKey = (id) =>
    API.delete(`/api/v1/${ENTITY_INTEGRATIONS}/${id}/`);
export const getItems = () => API.get(`/api/v1/${ENTITY_SUPLIERS}/`);
export const getProviders = () => API.get(`/api/v1/${ENTITY_INTEGRATIONS}/`);
export const updateKey = (id, data) =>
    API.put(`/api/v1/${ENTITY_INTEGRATIONS}/${id}/`, data);
