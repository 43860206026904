export const NAME = 'company-branding';
export const DEFAULT_VALUES = {
    dark_primary_color: '#ff9a00',
    dark_primary_contrast_text_color: '#ffffff',
    dark_secondary_color: '#ffffff',
    dark_secondary_contrast_text_color: '#000000',
    light_primary_color: '#ff9a00',
    light_primary_contrast_text_color: '#011e2e',
    light_secondary_color: '#011e2e',
    light_secondary_contrast_text_color: '#ffffff',
    loading_image: null,
    logo_image: null,
    logotype_image: null,
};
