import { getFinancierProductWarningsByInstaller } from 'common/api/v1/sunpay';

import {
    FETCH_ALLIANCE_PRODUCT_WARNINGS,
    FETCH_ALLIANCE_PRODUCT_WARNINGS_FAILURE,
    FETCH_ALLIANCE_PRODUCT_WARNINGS_SUCCESS,
} from '../actionTypes';
import { financingAndInsuranceActions } from '../reducer';

export default (commercialOfferId, productId) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch(
            financingAndInsuranceActions[FETCH_ALLIANCE_PRODUCT_WARNINGS](),
        );

        getFinancierProductWarningsByInstaller(commercialOfferId, productId)
            .then((response) => {
                dispatch(
                    financingAndInsuranceActions[
                        FETCH_ALLIANCE_PRODUCT_WARNINGS_SUCCESS
                    ](response.data.errors),
                );
                resolve();
            })
            .catch((error) => {
                dispatch(
                    financingAndInsuranceActions[
                        FETCH_ALLIANCE_PRODUCT_WARNINGS_FAILURE
                    ](error?.response?.data?.errors),
                );
                reject();
            });
    });
