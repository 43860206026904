import i18next from 'i18next';

import showToast from 'common/utils/showToast';

export default (url, fileName = 'file') => {
    if (!url) return;

    try {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        showToast({
            body: i18next.t('An error occurred while downloading the file'),
            type: 'danger',
        });
    }
};
