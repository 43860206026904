export const NAME = 'companies-catalogs';

export const CONTACT_TRACKING_TYPE = 0;
export const STATUS_CONTACT_TYPE = 1;
export const DOCUMENTS_CONTACT_TYPE = 2;
export const DOCUMENTS_PROJECTS_TYPE = 3;
export const STATUS_PROJECT_TYPE = 4;
export const ORIGIN_CONTACT_TYPE = 5;
export const PROPOSAL_SUMMARY_TYPE = 6;

export const PROPOSAL_SUMMARY_MAX = 3;
