import i18next from 'i18next';
import { isEmpty } from 'lodash';

export default (values = {}) => {
    const errors = {};

    if (values.hrsSol && values.hrsSol.length > 0) {
        const hrsSolArrayErrors = [];

        values.hrsSol.forEach((hrSol, index) => {
            const hrSolErrors = {};

            if (hrSol && (!hrSol.value || parseFloat(hrSol.value) <= 0)) {
                hrSolErrors.value = i18next.t('Must be greater than zero');
                hrsSolArrayErrors[index] = hrSolErrors;
            }
        });

        if (hrsSolArrayErrors.length) {
            errors.hrsSol = {
                message: i18next.t('All values ​​must be greater than zero'),
            };
        }
    }

    if (values.kwhkw && values.kwhkw.length > 0) {
        const kwhkwArrayErrors = [];

        values.kwhkw.forEach((kwhkw, index) => {
            const kwhkwErrors = {};

            if (kwhkw && (!kwhkw.value || parseFloat(kwhkw.value) <= 0)) {
                kwhkwErrors.value = i18next.t('Must be greater than zero');
                kwhkwArrayErrors[index] = kwhkwErrors;
            }
        });

        if (kwhkwArrayErrors.length) {
            errors.kwhkw = {
                message: i18next.t('All values ​​must be greater than zero'),
            };
        }
    }

    if (isEmpty(errors)) return { values: values, errors: {} };

    return { values: {}, errors: errors };
};
