import i18next from 'i18next';

import { numberFormat } from 'common/utils/helpers';

export const getTotalsChartConfig = ({
    currencyIso,
    currencyLocale,
    values,
}) => {
    const labels = [
        i18next.t('Solar panel', { count: 2 }),
        i18next.t('Inverter', { count: 2 }),
        i18next.t('Structure', { count: 2 }),
        i18next.t('Workforce and electrical equipment'),
        i18next.t('Accessory', { count: 2 }),
        i18next.t('Additional', { count: 2 }),
        i18next.t('Storage'),
    ];

    const data = {
        labels,
        datasets: [
            {
                label: i18next.t('Quotation'),
                data: values,
                backgroundColor: [
                    '#3F4FAE', // Panels
                    '#20C4D9', // Inverters
                    '#FF7043', // Structures
                    '#E91E63', // Workforces
                    '#FFC107', // Accessories
                    '#7046BC', // Additionals
                    '#8BC34A', // Batteries
                ],
            },
        ],
    };

    const options = {
        indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return numberFormat(value, {
                            currency: currencyIso,
                            decimals: 2,
                            locale: currencyLocale,
                            style: 'currency',
                        });
                    },
                },
            },
            y: {
                ticks: {
                    callback: function (value) {
                        let label = labels[value];
                        return label.length > 10
                            ? label.substr(0, 10) + '...'
                            : label; // Personaliza las etiquetas del eje y
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: '#333', // Color de las etiquetas de la leyenda
                    font: {
                        size: 14, // Tamaño de fuente de las etiquetas de la leyenda
                        weight: 'bold',
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return numberFormat(context.raw, {
                            currency: currencyIso,
                            decimals: 2,
                            locale: currencyLocale,
                            style: 'currency',
                        });
                    },
                },
            },
        },
    };

    return { data, options };
};
