import { createSlice } from '@reduxjs/toolkit';

import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    save: { errors: null, isSaving: false },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [SAVE]: (state) => {
            state.save = { errors: null, isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = { errors: action.payload, isSaving: false };
        },
        [SAVE_SUCCESS]: (state) => {
            state.save = { errors: null, isSaving: false };
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
