import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, UiDialog as Dialog } from 'sunwise-ui';

import * as actions from './actions';
import SettingsForm from './components/SettingsForm';
import * as selectors from './selectors';

const Container = ({
    closeModal,
    fetchNotificationSettings,
    isFetchingNotificationSettings,
    isOpenModal,
}) => {
    const { t } = useTranslation();

    const renderContent = () => {
        if (isFetchingNotificationSettings) {
            return (
                <Box p={2} sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            );
        }
        return <SettingsForm />;
    };

    return (
        <Dialog
            onClose={() => closeModal()}
            onEnter={() => fetchNotificationSettings()}
            open={isOpenModal}
            title={t('Notification settings')}
        >
            {renderContent()}
        </Dialog>
    );
};

Container.propTypes = {
    closeModal: PropTypes.func,
    fetchNotificationSettings: PropTypes.func,
    isFetchingNotificationSettings: PropTypes.bool,
    isOpenModal: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    isFetchingNotificationSettings: selectors.getIsFetchingNotificationSettings,
    isOpenModal: selectors.getIsOpenModal,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(actions.closeModal()),
    fetchNotificationSettings: () =>
        dispatch(actions.fetchNotificationSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
