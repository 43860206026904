export const NAME = 'proposal-generator-consumption-analysis-vs-generation';

export const NASA_SOURCES_NAMES = {
    0: 'NASA -25%',
    1: 'NASA',
    2: 'NASA -30%',
    4: 'NASA -20%',
    5: 'NASA -35%',
};

export const PV_LAYOUT_SOURCES_NAMES = {
    PV_WATTS: 'PVWatts',
    PV_GIS: 'PVGIS',
};
