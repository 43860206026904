import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

import StatusIndicatorTooltip from 'common/components/StatusIndicatorTooltip';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';

const NameFormatter = ({ handleClickView, row }) => {
    const label = `${get(row, 'parent.name', '-')}`;
    const isGenerated = row.status === COMMERCIAL_OFFER_STATUS.FINISHED.key;

    return (
        <Box
            alignItems="center"
            display="flex"
            gap={1}
            onClick={() =>
                handleClickView({ id: row?.id, isGenerated, name: label })
            }
            sx={{ cursor: 'pointer' }}
        >
            <StatusIndicatorTooltip
                animated
                isApproved={row?.has_approbed || false}
                isGenerated={isGenerated}
            />
            <Typography variant="caption" fontWeight="bold">
                {label}
            </Typography>
        </Box>
    );
};

NameFormatter.propTypes = {
    handleClickView: PropTypes.func,
    row: PropTypes.object,
};

export default NameFormatter;
