import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, InputLabel, MenuItem, Select } from 'sunwise-ui';

import { RATE_TYPE_ITEMS } from '../constants';
import { getRateTypeIcon } from '../helpers';

const DropdownItemIcon = styled('img')`
    height: 14px;
    width: 14px;
`;

const RateTypeFilterDropdown = ({
    disabled,
    rateTypeSelected,
    selectRateType,
}) => {
    const { t } = useTranslation();
    const label = t('Rate type');

    const getSelectedFilterText = (key) => {
        if (key === 'true') return t('Certified', { context: 'female' });
        if (key === 'false') return t('Custom', { context: 'female' });
        return t('All text', {
            context: 'female',
            count: 2,
        });
    };

    const handleOnChange = (e) => selectRateType(e.target.value);

    return (
        <FormControl disabled={disabled} fullWidth size="small">
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                onChange={handleOnChange}
                value={rateTypeSelected}
            >
                {Object.keys(RATE_TYPE_ITEMS).map((rateTypeItemKey) => {
                    const icon = getRateTypeIcon(rateTypeItemKey);
                    return (
                        <MenuItem
                            key={`rate-type-filter-dropdown-item-all-${rateTypeItemKey}`}
                            value={rateTypeItemKey}
                        >
                            <Box display="flex" alignItems="center" gap="16px">
                                {icon && <DropdownItemIcon src={icon} />}
                                {getSelectedFilterText(rateTypeItemKey)}
                            </Box>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

RateTypeFilterDropdown.propTypes = {
    disabled: PropTypes.bool,
    rateTypeSelected: PropTypes.string,
    selectRateType: PropTypes.func,
};

export default RateTypeFilterDropdown;
