import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { ACTIVATE_ITEM, ACTIVATE_ITEM_FAILURE } from '../actionTypes';
import { companyUsersActions } from '../reducer';

import activateItem from './activateItem';
import reassignContacts from './reassignContacts';

export default (userId, reassignBranches, dataToSave) => (dispatch) => {
    dispatch(companyUsersActions[ACTIVATE_ITEM]());
    dispatch(reassignContacts(userId, reassignBranches, dataToSave))
        .then(({ dataToSave }) => {
            dispatch(activateItem(dataToSave, true));
        })
        .catch((error) => {
            dispatch(
                companyUsersActions[ACTIVATE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
