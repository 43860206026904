import * as actions from './actions';
import {
    ALERT_TYPE_ALERT,
    ALERT_TYPE_CONFIRM,
    ALERT_TYPE_ONLY_CONFIRM,
    NAME,
} from './constants';
import Container from './Container';
import reducer from './reducer';

export default {
    actions,
    ALERT_TYPE_ALERT,
    ALERT_TYPE_CONFIRM,
    ALERT_TYPE_ONLY_CONFIRM,
    Container,
    NAME,
    reducer,
};
