import { getItemsByParent } from 'common/api/v1/politicalDivisions';

import {
    FETCH_POLITICAL_DIVISIONS2,
    FETCH_POLITICAL_DIVISIONS2_FAILURE,
    FETCH_POLITICAL_DIVISIONS2_SUCCESS,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

export default (str) => (dispatch) => {
    dispatch(projectConsumptionModalActions[FETCH_POLITICAL_DIVISIONS2]());

    return new Promise((resolve, reject) => {
        getItemsByParent(str)
            .then(({ data }) => {
                dispatch(
                    projectConsumptionModalActions[
                        FETCH_POLITICAL_DIVISIONS2_SUCCESS
                    ](data.data),
                );
                resolve();
            })
            .catch((error) => {
                dispatch(
                    projectConsumptionModalActions[
                        FETCH_POLITICAL_DIVISIONS2_FAILURE
                    ](error?.response?.data?.errors),
                );
                reject();
            });
    });
};
