import { getRegionsByPoliticalDivision } from 'common/api/v1/catalogs';

import {
    FETCH_REGIONS,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_FAILURE,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

export default (id, setValue) => (dispatch) => {
    dispatch(projectConsumptionModalActions[FETCH_REGIONS]());

    return new Promise((resolve, reject) => {
        getRegionsByPoliticalDivision({ political_division: id })
            .then(({ data }) => {
                dispatch(
                    projectConsumptionModalActions[FETCH_REGIONS_SUCCESS](
                        data.data,
                    ),
                );

                if (data.data.length > 0)
                    setValue('rate_region', data.data[0].id);

                resolve();
            })
            .catch((error) => {
                dispatch(
                    projectConsumptionModalActions[FETCH_REGIONS_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                reject();
            });
    });
};
