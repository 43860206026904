import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BottomActions, Box, Button } from 'sunwise-ui';

const FormBottomActions = ({
    disabled,
    handleOnClose,
    hasEnergyBalanceErrors,
    selectedTab,
    handleOnChangeTab,
    tabs,
}) => {
    const { t } = useTranslation();

    const tabIndex = tabs.findIndex((tab) => tab === selectedTab);
    const isLastTab = tabIndex === tabs.length - 1;
    const isFirstTab = tabIndex === 0;

    const isDisabled = isLastTab
        ? disabled || hasEnergyBalanceErrors
        : disabled;

    const handleOnNext = (e) => {
        if (isLastTab) return;
        e.preventDefault();
        e.stopPropagation();
        handleOnChangeTab(tabs[tabIndex + 1]);
    };

    const handleOnBack = () => {
        if (!isFirstTab) handleOnChangeTab(tabs[tabIndex - 1]);
        else handleOnClose();
    };

    return (
        <BottomActions>
            <Box
                display="flex"
                flexDirection={{ md: 'row', xs: 'column' }}
                gap={1}
                justifyContent={{ md: 'right', xs: 'center' }}
                mt={2}
                width="100%"
            >
                <Button
                    color="secondary"
                    onClick={handleOnBack}
                    sx={{
                        order: { md: 1, xs: 1 },
                        width: { md: 'auto', xs: '100%' },
                    }}
                    variant="text"
                >
                    {isFirstTab ? t('Cancel') : t('Back')}
                </Button>

                <Button
                    disabled={isDisabled}
                    onClick={handleOnNext}
                    sx={{
                        order: { md: 1, xs: 1 },
                        width: { md: 'auto', xs: '100%' },
                    }}
                    type={isLastTab ? 'submit' : 'button'}
                    variant="outlined"
                >
                    {isLastTab ? t('Save') : t('Next')}
                </Button>
            </Box>
        </BottomActions>
    );
};

FormBottomActions.propTypes = {
    disabled: PropTypes.bool,
    handleOnChangeTab: PropTypes.func,
    handleOnClose: PropTypes.func,
    hasEnergyBalanceErrors: PropTypes.bool,
    selectedTab: PropTypes.string,
    tabs: PropTypes.array,
};

export default FormBottomActions;
