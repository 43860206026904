import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ReduxFieldArrayProposalItems from 'common/components/form/bootstrap/ReduxFieldArrayProposalItems';

import * as selectors from '../selectors';

import ExpansionField from './ExpansionField';

const FormContainer = ({
    canModifyProduct,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    handleChange,
    handleChangeExpansionField,
    handleClickDelete,
    handleNormalizeDiscountField,
    handleNormalizeField,
    handleNormalizeFields,
    handleNormalizeMarginField,
    handleNormalizeModel,
    handleNormalizeModelField,
    handleNormalizePercentageDiscountField,
    handleRemove,
    initialValues,
    isLoading,
    isLocked,
    itemsData,
    numOfferPanels,
    offerItems,
    proposalId,
}) => {
    const { control, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
    });

    useEffect(() => reset(initialValues), [initialValues]);

    return (
        <>
            <ReduxFieldArrayProposalItems
                canModifyProduct={canModifyProduct}
                control={control}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                formValues={watch()}
                handleChange={handleChange}
                handleClickDelete={handleClickDelete}
                handleNormalizeDiscountField={handleNormalizeDiscountField}
                handleNormalizeField={handleNormalizeField}
                handleNormalizeFields={handleNormalizeFields}
                handleNormalizeMarginField={handleNormalizeMarginField}
                handleNormalizeModel={handleNormalizeModel}
                handleNormalizeModelField={handleNormalizeModelField}
                handleNormalizePercentageDiscountField={
                    handleNormalizePercentageDiscountField
                }
                handleRemove={handleRemove}
                initialValues={initialValues}
                isInverter
                isLoading={isLoading}
                isLocked={isLocked}
                itemsData={itemsData}
                name="items"
                offerItems={offerItems}
                prefixId="inverter"
                setValue={setValue}
            />
            <ExpansionField
                control={control}
                handleChangeExpansionField={handleChangeExpansionField}
                initialValues={initialValues}
                isLocked={isLocked || numOfferPanels > 1}
                proposalId={proposalId}
            />
        </>
    );
};

FormContainer.propTypes = {
    canModifyProduct: PropTypes.bool,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    handleChange: PropTypes.func,
    handleChangeExpansionField: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleNormalizeField: PropTypes.func,
    handleNormalizeFields: PropTypes.func,
    handleNormalizeMarginField: PropTypes.func,
    handleNormalizeModel: PropTypes.func,
    handleNormalizeModelField: PropTypes.func,
    handleNormalizeDiscountField: PropTypes.func,
    handleNormalizePercentageDiscountField: PropTypes.func,
    handleRemove: PropTypes.func,
    initialValues: PropTypes.object,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    itemsData: PropTypes.array,
    numOfferPanels: PropTypes.number,
    offerItems: PropTypes.array,
    proposalId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

export default connect(mapStateToProps)(FormContainer);
