import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, BottomActions, Button } from 'sunwise-ui';

import RHFDateTimePicker from 'common/components/form/bootstrap/RHFDateTimePicker';
import ShowErrors from 'common/components/ShowErrors';
import { formatDate, zonedDate } from 'common/utils/dates';
import { getTimezoneUserSettings } from 'common/utils/helpers/multiregion';
import yupResolver from 'common/utils/yupResolver';

import * as selectors from '../selectors';
import validateReasign from '../validateReasign';

const currentDate = zonedDate(new Date(), getTimezoneUserSettings());
const dateTimeToday = formatDate(currentDate, 'yyyy-MM-dd H:mm');

const ReasignTrackingForm = ({ errors, onSave, isSaving, trackingId }) => {
    const { t } = useTranslation();
    const { control, handleSubmit } = useForm({
        defaultValues: {
            completed: false,
            notification_at: dateTimeToday,
        },
        resolver: yupResolver(validateReasign),
    });

    const handleOnSave = (values) => onSave(trackingId, values);

    return (
        <form>
            <RHFDateTimePicker
                control={control}
                fullWidth
                disablePast
                label={t('Date')}
                name="notification_at"
            />

            <ShowErrors errors={errors} />

            <BottomActions>
                <Box sx={{ pt: 2 }}>
                    <Button
                        disabled={isSaving}
                        onClick={handleSubmit(handleOnSave)}
                    >
                        {t('Save').toUpperCase()}
                    </Button>
                </Box>
            </BottomActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrors,
    isSaving: selectors.getIsSavingTracking,
});

ReasignTrackingForm.propTypes = {
    errors: PropTypes.array,
    onSave: PropTypes.func,
    isSaving: PropTypes.bool,
    trackingId: PropTypes.string,
};

export default connect(mapStateToProps, null)(ReasignTrackingForm);
