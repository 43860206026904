import { styled } from '@mui/material/styles';
import { MenuItem } from 'sunwise-ui';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    gap: '8px',
    padding: '16px',
    '& svg': {
        color: '#848bab',
        height: '24px',
        width: '24px',
    },
    '&:hover': {
        '& svg': {
            transform: 'scale(1.14)',
        },
    },
    [theme.breakpoints.down('md')]: { color: '#fff' },
}));

export default StyledMenuItem;
