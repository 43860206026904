import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card } from 'sunwise-ui';

import * as actions from '../actions';
import * as selectors from '../selectors';

import EmptyState from './EmptyState';
import FilterOptions from './FilterOptions';
import ItemsList from './ItemsList';

const CardContent = ({
    canDelete,
    canModify,
    fetchData,
    projectId,
    reports,
    setModalFormOpenStatus,
    yearsForSelect,
}) => {
    const [year, setYear] = useState('');
    const { t } = useTranslation();
    const handleOnClick = () => setModalFormOpenStatus(true);

    useEffect(
        () => fetchData({ isInitialFetch: true, projectId }),
        [projectId],
    );

    const handleOnFilter = (value) => {
        setYear(value);
        fetchData({ isInitialFetch: true, projectId, year: value });
    };

    const addButton = (
        <Button
            endIcon={<AddIcon />}
            onClick={handleOnClick}
            variant="outlined"
        >
            {t('Create')}
        </Button>
    );

    return (
        <>
            <Card.Body>
                <FilterOptions
                    handleOnFilter={handleOnFilter}
                    options={yearsForSelect}
                />
                {reports?.length > 0 ? (
                    <ItemsList
                        canDelete={canDelete}
                        canModify={canModify}
                        items={reports}
                        projectId={projectId}
                        year={year}
                    />
                ) : (
                    <EmptyState addButton={addButton} canModify={canModify} />
                )}
            </Card.Body>
            {canModify && reports?.length > 0 && (
                <Card.Actions>
                    <Box textAlign="right" sx={{ width: '100%' }}>
                        {addButton}
                    </Box>
                </Card.Actions>
            )}
        </>
    );
};

CardContent.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    fetchData: PropTypes.func,
    projectId: PropTypes.string,
    reports: PropTypes.array,
    setModalFormOpenStatus: PropTypes.func,
    yearsForSelect: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    reports: selectors.getFetchDataItems,
    yearsForSelect: selectors.getFetchDataYearsForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchData: (params) => dispatch(actions.fetchData(params)),
    setModalFormOpenStatus: (value) =>
        dispatch(actions.setModalFormOpenStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardContent);
