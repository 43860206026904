import React from 'react';

import { styled } from '@mui/material/styles';

import { formatDate, parseDate } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import { POINTER_CURSOR } from '../constants';

const Date = styled('div')`
    color: #848bab;
    cursor: ${POINTER_CURSOR};
`;

const DateFormatter = (params) => {
    const { row } = params;
    return (
        <Date>
            {`${formatDate(
                parseDate(row.created_at, 'dd/MM/yyyy HH:mm:ss'),
                getDateFormatByLocale(),
            )}`}
        </Date>
    );
};

export default DateFormatter;
