import React from 'react';

import PropTypes from 'prop-types';
import { Grid, Skeleton } from 'sunwise-ui';

import EnterprisePricing from './EnterprisePricing';
import PricingDetails from './PricingDetails';

const PricingList = ({
    amount,
    handleCheckout,
    isEnterprise,
    isLoading,
    pricings,
    selectedTheme,
    showEnterpriseCard,
}) => {
    if (isLoading) {
        return (
            <Grid container mt={3}>
                <Grid item xs={18} md={9}>
                    <Skeleton height={160} variant="rectangle" />
                </Grid>
                <Grid item xs={18} md={9}>
                    <Skeleton height={160} variant="rectangle" />
                </Grid>
            </Grid>
        );
    }
    return (
        <Grid container justifyContent="center" mt={3}>
            {pricings.map((pricing, index) => (
                <Grid
                    item
                    xs={18}
                    key={`pricing-${index}`}
                    md={9}
                    lg={showEnterpriseCard ? 6 : 9}
                >
                    <PricingDetails
                        amount={amount}
                        isEnterprise={isEnterprise}
                        onClick={handleCheckout}
                        pricing={pricing}
                        selectedTheme={selectedTheme}
                    />
                </Grid>
            ))}
            {showEnterpriseCard && (
                <Grid item xs={18} md={9} lg={6}>
                    <EnterprisePricing selectedTheme={selectedTheme} />
                </Grid>
            )}
        </Grid>
    );
};

PricingList.propTypes = {
    amount: PropTypes.string,
    handleCheckout: PropTypes.func,
    isEnterprise: PropTypes.bool,
    isLoading: PropTypes.bool,
    pricings: PropTypes.array,
    selectedTheme: PropTypes.string,
    showEnterpriseCard: PropTypes.bool,
};

export default PricingList;
