import { getFinarcierInstallers } from 'common/api/v2/sunpay';

import {
    FETCH_FINANCIER_INSTALLERS,
    FETCH_FINANCIER_INSTALLERS_FAILURE,
    FETCH_FINANCIER_INSTALLERS_SUCCESS,
} from '../actionTypes';
import { financingAndInsuranceActions } from '../reducer';

export default () => (dispatch) =>
    new Promise((resolve) => {
        dispatch(financingAndInsuranceActions[FETCH_FINANCIER_INSTALLERS]());

        getFinarcierInstallers()
            .then((response) =>
                dispatch(
                    financingAndInsuranceActions[
                        FETCH_FINANCIER_INSTALLERS_SUCCESS
                    ](response.data.data),
                ),
            )
            .catch((error) =>
                dispatch(
                    financingAndInsuranceActions[
                        FETCH_FINANCIER_INSTALLERS_FAILURE
                    ](error),
                ),
            )
            .finally(resolve);
    });
