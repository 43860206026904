import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_PROJECTS,
    FETCH_PROJECTS_FAILURE,
    FETCH_PROJECTS_SUCCESS,
    FETCH_NEXT_PAGE,
    FETCH_NEXT_PAGE_FAILURE,
    FETCH_NEXT_PAGE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    projects: {
        data: [],
        errors: [],
        isFetching: false,
    },
    nextPage: {
        errors: [],
        isFetching: false,
    },
    hasNextPage: false,
};

const listProjectsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_PROJECTS]: (state) => {
            state.projects = {
                ...state.projects,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PROJECTS_FAILURE]: (state, action) => {
            state.projects = {
                ...state.projects,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROJECTS_SUCCESS]: (state, action) => {
            state.projects = {
                ...state.projects,
                data: action.payload.data,
                isFetching: false,
            };
            state.hasNextPage = action.payload.hasNextPage;
        },
        [FETCH_NEXT_PAGE]: (state) => {
            state.nextPage = {
                ...state.nextPage,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_NEXT_PAGE_FAILURE]: (state, action) => {
            state.nextPage = {
                ...state.nextPage,
                errors: action.payload,
                isFetching: false,
            };
            state.hasNextPage = false;
        },
        [FETCH_NEXT_PAGE_SUCCESS]: (state, action) => {
            state.nextPage.isFetching = false;
            state.projects = {
                ...state.projects,
                data: [...state.projects.data, ...action.payload.projects],
            };
            state.hasNextPage = action.payload.hasNextPage;
        },
    },
});

export const listProjectsActions = listProjectsSlice.actions;

export default listProjectsSlice.reducer;
