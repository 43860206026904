import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const Label = ({ text }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    return (
        <Typography
            variant="caption"
            sx={{ color: isDarkMode ? '#fff' : '#2f4dff' }}
        >
            {text}
        </Typography>
    );
};

Label.propTypes = {
    text: PropTypes.string,
};

export default Label;
