import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UiDialog } from 'sunwise-ui';

import ModalCurrencyList from './ModalCurrencyList';

const Modal = ({
    canDelete,
    data,
    filterItemsParent,
    handleClickClose,
    handleSelectParent,
    handleUnselectParent,
    isFetching,
    isOpen,
    onEnter,
    pagination,
    searchText,
}) => {
    const { t } = useTranslation();
    return (
        <UiDialog
            onClose={handleClickClose}
            onEnter={onEnter}
            open={isOpen}
            title={t('Add currency')}
        >
            <ModalCurrencyList
                canDelete={canDelete}
                data={data}
                filterItemsParent={filterItemsParent}
                handleSelectParent={handleSelectParent}
                handleUnselectParent={handleUnselectParent}
                isFetching={isFetching}
                pagination={pagination}
                searchText={searchText}
            />
        </UiDialog>
    );
};

Modal.propTypes = {
    canDelete: PropTypes.bool,
    data: PropTypes.array,
    filterItemsParent: PropTypes.func,
    handleClickClose: PropTypes.func,
    handleSelectParent: PropTypes.func,
    handleUnselectParent: PropTypes.func,
    isFetching: PropTypes.bool,
    isOpen: PropTypes.bool,
    onEnter: PropTypes.func,
    pagination: PropTypes.object,
    searchText: PropTypes.string,
};

export default Modal;
