import i18next from 'i18next';
import get from 'lodash/get';

import { updateSegment } from 'common/api/v1/segmentsLayout';
import {
    getNewSolarModules,
    getPolygonCoordinates,
    getSegmentArea,
    showReponseErrorsAsAlert,
} from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    CHECK_SEGMENT,
    CHECK_SEGMENT_FAILURE,
    CHECK_SEGMENT_SUCCESS,
    UPDATE_SOLAR_MODULES_DESIGN_NUMBER,
} from '../actionTypes';
import { POLYGON_SEGMENT, POLYGON_MAP, POLYGON_OBSTACLE } from '../constants';
import {
    getDictionaryById,
    getModulesGroups,
    getNewModules,
    getObstaceZIndex,
    getQuantityEnabledModules,
    prepareModulesGroups,
    restoreGroups,
} from '../helpers';
import { panelLayoutV2Actions } from '../reducer';
import * as selectors from '../selectors';

import fetchOfferPanel from './fetchOfferPanel';
import handleCollition from './handleCollition';
import updateSegments from './updateSegments';

const buildSegmentPayload = ({
    commercialOfferId,
    config,
    getState,
    selectedSegment,
}) => {
    const state = getState();
    const segments = selectors.getSegmentsData(state);
    const segmentFillMethod = selectors.getSegmentFillMethod(state);
    const { evt, google, mapValue, polygon } = config;
    const {
        azimuth,
        col_spacing,
        frame_col_spacing,
        frame_cols_number,
        frame_row_spacing,
        frame_rows_number,
        height,
        name,
        orientation,
        row_spacing,
        safe_zone,
        solar_module,
        solar_modules_number,
        tilt,
    } = selectedSegment;

    let assigned = 0;
    let groups = [];
    let module = null;
    let modules_groups = null;
    let quantity = solar_modules_number;
    let solar_modules = [];

    const offerPanels = selectors.getOfferPanelsData(state);
    const offerPanelFiltered = offerPanels?.find(
        (item) => item.id === solar_module,
    );

    if (offerPanelFiltered) {
        module = offerPanelFiltered;
        if (evt !== 'drag' && segmentFillMethod === true) quantity = Infinity;
        if (evt !== 'drag' && segmentFillMethod === false) {
            assigned = module.assigned - selectedSegment.solar_modules_number;
            quantity = module.quantity;
        }
    }

    const solarCharacterization = {
        azimuth,
        col_spacing,
        frame_col_spacing,
        frame_cols_number,
        frame_horizontal_number: frame_cols_number,
        frame_row_spacing,
        frame_rows_number,
        frame_vertical_number: frame_rows_number,
        height,
        orientation,
        row_spacing,
        safe_zone,
        solar_module,
        tilt,
    };

    if (quantity > 0) {
        const obstacles = segments.filter(
            (segment) => segment.type === POLYGON_OBSTACLE,
        );

        const { boxes, grouped } = getNewModules({
            data: {
                ...solarCharacterization,
                assigned,
                polygon: JSON.stringify(polygon),
                quantity,
            },
            google,
            map: mapValue,
            module,
            obstacles,
        });

        const modules_groups = getModulesGroups(grouped);
        groups = prepareModulesGroups(modules_groups);

        solar_modules = boxes.map((module) => ({
            cell: module.cell,
            col: module.col,
            group: module.group,
            is_enable: module.enable,
            row: module.row,
            solar_module_points: JSON.stringify(
                module.paths.map((point) => ({
                    x: point[1],
                    y: point[0],
                })),
            ),
        }));
    }

    const field_segments = [
        {
            name,
            polygon: polygon.map((point) => ({
                x_coordinate: point[1],
                y_coordinate: point[0],
            })),
            solar_modules,
        },
    ];

    const payload = {
        ...selectedSegment,
        commercial_offer: commercialOfferId,
        field_segments,
        groups,
        modules_groups,
        polygon,
        prev_location: selectedSegment.polygon,
    };

    return payload;
};

const buildObstaclePayload = ({
    commercialOfferId,
    config,
    getState,
    selectedSegment,
}) => {
    const state = getState();
    const segments = selectors.getSegmentsData(state);

    const { google, polygon } = config;
    const { name, safe_zone } = selectedSegment;

    const zIndex = getObstaceZIndex({
        google,
        polygon,
        segments,
        selectedSegment,
    });

    const field_segments = [
        {
            name,
            polygon: polygon.map((point) => ({
                x_coordinate: point[1],
                y_coordinate: point[0],
            })),
        },
    ];

    const payload = {
        ...selectedSegment,
        commercial_offer: commercialOfferId,
        field_segments,
        prev_location: selectedSegment.polygon,
        safe_zone: parseFloat(safe_zone) || 0,
        total_area: getSegmentArea(google, polygon),
        zindex: zIndex,
    };

    return payload;
};

const buildImagePayload = ({ commercialOfferId, config, selectedSegment }) => {
    const { google, polygon } = config;
    const { name } = selectedSegment;

    const field_segments = [
        {
            name,
            polygon: polygon.map((point) => ({
                x_coordinate: point[1],
                y_coordinate: point[0],
            })),
        },
    ];

    const payload = {
        ...selectedSegment,
        commercial_offer: commercialOfferId,
        field_segments,
        prev_location: selectedSegment.polygon,
        total_area: getSegmentArea(google, polygon),
    };

    return payload;
};

const handleSegmentResponse = ({
    config,
    dispatch,
    payload,
    segmentData,
    segments,
}) => {
    const { callback, commercialOfferId } = config;
    const { field_segment_points, panel_modules } = segmentData;
    const newPolygon = getPolygonCoordinates(field_segment_points);
    const solarModules = getNewSolarModules(panel_modules);
    const quantityEnabledModules = getQuantityEnabledModules(solarModules);
    const grouped = restoreGroups(solarModules);
    const modules_groups = getModulesGroups(grouped);
    const updatedSegment = {
        ...payload,
        grouped_cells: grouped,
        modules_groups,
        polygon: newPolygon,
        quantity_enabled_modules: quantityEnabledModules,
        solar_modules: solarModules,
        solar_modules_number: solarModules.length,
    };

    panelLayoutV2Actions[CHECK_SEGMENT_SUCCESS](updatedSegment);

    const newValuesSegments = segments.map((segment) =>
        segment.id === payload.id
            ? { ...segment, ...updatedSegment, open: true }
            : { ...segment, open: false },
    );

    dispatch(updateSegments(newValuesSegments));

    const newSolarModulesDesignNumber = newValuesSegments.reduce(
        (acc, current) => acc + current.solar_modules_number,
        0,
    );
    dispatch(
        panelLayoutV2Actions[UPDATE_SOLAR_MODULES_DESIGN_NUMBER](
            newSolarModulesDesignNumber,
        ),
    );

    dispatch(fetchOfferPanel(commercialOfferId));

    if (callback) callback(payload.id, solarModules);
};

const handleObstacleResponse = ({
    config,
    dispatch,
    payload,
    segmentData,
    segments,
}) => {
    const { callback, callbackError, commercialOfferId, google, mapValue } =
        config;
    const { field_segment_points } = segmentData;
    const newPolygon = getPolygonCoordinates(field_segment_points);
    const updatedSegment = {
        ...payload,
        polygon: newPolygon,
    };

    panelLayoutV2Actions[CHECK_SEGMENT_SUCCESS](updatedSegment);

    const newValuesSegments = segments.map((segment) =>
        segment.id === payload.id
            ? { ...segment, ...updatedSegment, open: true }
            : { ...segment, open: false },
    );

    dispatch(updateSegments(newValuesSegments));

    dispatch(
        handleCollition({
            callback,
            callbackError,
            commercialOfferId,
            google,
            mapValue,
            obstacle: updatedSegment,
        }),
    );

    if (callback) callback(payload.id, []);
};

const handleImageResponse = ({ dispatch, payload, segmentData, segments }) => {
    const { field_segment_points } = segmentData;
    const newPolygon = getPolygonCoordinates(field_segment_points);
    const updatedSegment = {
        ...payload,
        polygon: newPolygon,
    };

    panelLayoutV2Actions[CHECK_SEGMENT_SUCCESS](updatedSegment);

    const newValuesSegments = segments.map((segment) =>
        segment.id === payload.id
            ? { ...segment, ...updatedSegment, open: true }
            : { ...segment, open: false },
    );

    dispatch(updateSegments(newValuesSegments));
};

export default (config) => (dispatch, getState) => {
    const state = getState();
    const segments = selectors.getSegmentsData(state);
    const segmentsDictionary = getDictionaryById(segments);
    const { callbackError, commercialOfferId, initialValues, segmentId } =
        config;

    if (!segmentId) return;

    const selectedSegment = segmentsDictionary[segmentId];

    if (!selectedSegment) return;

    const { type } = selectedSegment;

    let payload = null;

    if (type === POLYGON_SEGMENT) {
        payload = buildSegmentPayload({
            commercialOfferId,
            config,
            getState,
            selectedSegment,
        });
    }

    if (type === POLYGON_OBSTACLE) {
        payload = buildObstaclePayload({
            commercialOfferId,
            config,
            getState,
            selectedSegment,
        });
    }

    if (type === POLYGON_MAP) {
        payload = buildImagePayload({
            commercialOfferId,
            config,
            selectedSegment,
        });
    }

    panelLayoutV2Actions[CHECK_SEGMENT]();

    updateSegment(segmentId, payload)
        .then((response) => {
            const segmentData = get(response, 'data.data', {});
            const handlerProps = {
                config,
                dispatch,
                payload,
                segmentData,
                segments,
            };

            if (type === POLYGON_SEGMENT) {
                handleSegmentResponse(handlerProps);
            }

            if (type === POLYGON_OBSTACLE) {
                handleObstacleResponse(handlerProps);
            }

            if (type === POLYGON_MAP) {
                handleImageResponse(handlerProps);
            }

            showToast({
                body: i18next.t('Segment changes have been saved'),
                position: 'bottom-center',
            });
        })
        .catch((error) => {
            if (callbackError) callbackError(segmentId, initialValues);
            dispatch(
                panelLayoutV2Actions[CHECK_SEGMENT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error.response);
        });
};
