import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

const DaysInPeriodWarning = ({ hasDaysInPeriodWarning }) => {
    const { t } = useTranslation();
    if (!hasDaysInPeriodWarning) return null;
    return (
        <Alert severity="warning">
            {t(
                'The Period is significantly shorter/longer than the rest of the periods, we suggest you manually validate this inconsistency',
            )}
        </Alert>
    );
};

DaysInPeriodWarning.propTypes = {
    hasDaysInPeriodWarning: PropTypes.bool,
};

export default DaysInPeriodWarning;
