import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button } from 'sunwise-ui';

import ReactHookFormInput from 'common/components/form/bootstrap/ReactHookFormInput';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { RESET_PASSWORD_IN_PROFILE } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const FormContainer = ({
    callback,
    errors,
    initialValues,
    isSaving,
    origin,
    saveAccountPassword,
    saveUserPassword,
}) => {
    const { t } = useTranslation();
    const {
        control,
        formState: { isDirty },
        handleSubmit,
        setValue,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    useEffect(() => {
        setValue('origin', origin);
    }, [origin]);

    const handleClickSave = (values) =>
        origin === RESET_PASSWORD_IN_PROFILE
            ? saveAccountPassword(values)
            : saveUserPassword(callback, values);

    return (
        <form onSubmit={handleSubmit(handleClickSave)}>
            {origin === RESET_PASSWORD_IN_PROFILE && (
                <ReactHookFormInput
                    autoComplete="off"
                    control={control}
                    label={t('Current password')}
                    name="old_password"
                    type="password"
                />
            )}

            <ReactHookFormInput
                autoComplete="off"
                control={control}
                label={t('New password')}
                name="new_password"
                type="password"
            />

            <ReactHookFormInput
                autoComplete="off"
                control={control}
                label={t('Confirm password')}
                name="confirm_password"
                type="password"
            />

            <ShowErrors errors={errors} />

            <BottomActions>
                <Box sx={{ pt: 2 }}>
                    {isDirty && (
                        <Button type="submit" disabled={isSaving}>
                            {t('Save')}
                        </Button>
                    )}
                </Box>
            </BottomActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    saveAccountPassword: (values) =>
        dispatch(actions.saveAccountPassword(values)),
    saveUserPassword: (callback, values) =>
        dispatch(actions.saveUserPassword(callback, values)),
});

FormContainer.propTypes = {
    callback: PropTypes.func,
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    origin: PropTypes.number,
    saveAccountPassword: PropTypes.func,
    saveUserPassword: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
