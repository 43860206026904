import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from 'sunwise-ui';

import CertifiedRateDetail from './CertifiedRateDetail';
import CustomRateDetailForm from './CustomRateDetailForm';

const CardWrapper = styled(Card)`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: center;
    width: 100%;
    @media (min-width: 900px) and (max-width: 1199px) {
        height: calc(100vh - 374px);
    }
    @media (min-width: 1200px) {
        height: calc(100vh - 385px);
    }
`;

const RateDetail = ({
    canDelete,
    canModify,
    disabled,
    isFetchingRate,
    prepareUpdateCertifiedRate,
    selectedItemId,
    selectedItemIsCertified,
    selectedItemModel,
}) => {
    const { t } = useTranslation();

    if (!selectedItemId || !selectedItemModel)
        return (
            <CardWrapper className={`${disabled && 'disabled'}`}>
                <Card.Body>
                    <Typography variant="body2">
                        {t('Select a rate to start')}
                    </Typography>
                </Card.Body>
            </CardWrapper>
        );

    if (selectedItemIsCertified)
        return (
            <CertifiedRateDetail
                canModify={canModify}
                isFetchingRate={isFetchingRate}
                prepareUpdateCertifiedRate={prepareUpdateCertifiedRate}
                selectedItemModel={selectedItemModel}
            />
        );

    return (
        <CustomRateDetailForm
            canDelete={canDelete}
            canModify={canModify}
            isFetchingRate={isFetchingRate}
            selectedItemDataUnparsed={selectedItemModel.toUnparsed()}
        />
    );
};

RateDetail.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    disabled: PropTypes.bool,
    isFetchingRate: PropTypes.bool,
    prepareUpdateCertifiedRate: PropTypes.func,
    selectedItemId: PropTypes.string,
    selectedItemIsCertified: PropTypes.bool,
    selectedItemModel: PropTypes.object,
};

export default RateDetail;
