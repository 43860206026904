import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'segments-layout';

export const createSegment = (data) =>
    API.post(`/api/v1/${ENTITY}/`, data, config);
export const updateSegment = (fieldSegmentId, data) =>
    API.put(`/api/v1/${ENTITY}/${fieldSegmentId}/`, data, config);
export const deleteSegment = (fieldSegmentId) =>
    API.delete(`/api/v1/${ENTITY}/${fieldSegmentId}/`);
