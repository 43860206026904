import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormSelect2,
    ReactHookFormInput,
} from 'common/components/form/bootstrap';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

import * as actions from '../actions';
import * as selectors from '../selectors';

import FormAccordion from './FormAccordion';

const LocationSection = ({
    control,
    disabled,
    fetchLocationsByZipCode,
    fetchPoliticalDivisions1,
    fetchPoliticalDivisions2,
    fetchRateDivisions,
    fetchRegionsByPoliticalDivision,
    initialValues,
    isLoadingLocationsByZipCode,
    isLoadingPoliticalDivisions,
    isLoadingPoliticalDivisions2,
    locationsByZipCode,
    politicalDivisions,
    politicalDivisions2,
    selectedRate,
    setValue,
}) => {
    const { t } = useTranslation();
    const [timer, setTimer] = useState(null);
    const isMexicanAccount = getIsMexicanAccount();

    useEffect(() => {
        fetchPoliticalDivisions1('dp1');

        if (initialValues?.political_division1) {
            fetchPoliticalDivisions2(initialValues?.political_division1);
            fetchRateDivisions(initialValues?.political_division1);
            fetchRegionsByPoliticalDivision(initialValues?.political_division1);
        }
    }, []);

    const handleChange = (callback) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => callback(), 2000));
    };

    const handleOnChangeZipCode = (e) =>
        handleChange(() => {
            fetchLocationsByZipCode({
                selectedRate,
                value: e?.target?.value,
                setValue,
            });
            setValue('political_division1', null);
            setValue('political_division2', null);
            setValue('rate_division', null);
        });

    return (
        <FormAccordion hasFormErrors={false} title={t('Location')}>
            <Grid container>
                <Grid item xs={18} lg={9}>
                    <ReactHookFormInput
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label={t('Zip code')}
                        name="zip_code"
                        onChange={handleOnChangeZipCode}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} lg={9}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={
                            isLoadingPoliticalDivisions ||
                            isLoadingLocationsByZipCode ||
                            disabled
                        }
                        isClearable={false}
                        isLoading={isLoadingPoliticalDivisions}
                        label={`${
                            isMexicanAccount
                                ? t('State')
                                : t('Political division of order 1')
                        } *`}
                        name="political_division1"
                        onChange={(value) => {
                            setValue('political_division2', null);
                            setValue('rate_division', null);

                            fetchRateDivisions({
                                id: value,
                                selectedRate,
                                setValue,
                            });
                            fetchRegionsByPoliticalDivision(value, setValue);
                            fetchPoliticalDivisions2(value);
                        }}
                        options={[{ label: '', options: politicalDivisions }]}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} lg={9}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={
                            isLoadingPoliticalDivisions ||
                            isLoadingPoliticalDivisions2 ||
                            isLoadingLocationsByZipCode ||
                            disabled
                        }
                        isLoading={isLoadingPoliticalDivisions2}
                        label={`${
                            isMexicanAccount
                                ? t('City')
                                : t('Political division of order 2')
                        } *`}
                        name="political_division2"
                        options={[
                            {
                                label: '',
                                options: !isEmpty(locationsByZipCode)
                                    ? locationsByZipCode
                                    : politicalDivisions2,
                            },
                        ]}
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </FormAccordion>
    );
};

LocationSection.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fetchLocationsByZipCode: PropTypes.func,
    fetchPoliticalDivisions1: PropTypes.func,
    fetchPoliticalDivisions2: PropTypes.func,
    fetchRateDivisions: PropTypes.func,
    fetchRegionsByPoliticalDivision: PropTypes.func,
    initialValues: PropTypes.object,
    isLoadingLocationsByZipCode: PropTypes.bool,
    isLoadingPoliticalDivisions2: PropTypes.bool,
    isLoadingPoliticalDivisions: PropTypes.bool,
    locationsByZipCode: PropTypes.array,
    politicalDivisions2: PropTypes.array,
    politicalDivisions: PropTypes.array,
    selectedRate: PropTypes.object,
    setValue: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isLoadingLocationsByZipCode: selectors.getLocationsByZipCodeIsLoading,
    isLoadingPoliticalDivisions2: selectors.getPoliticalDivisions2IsLoading,
    isLoadingPoliticalDivisions: selectors.getPoliticalDivisions1IsLoading,
    locationsByZipCode: selectors.getLocationsByZipCodeForSelect,
    politicalDivisions2: selectors.getPoliticalDivisions2ForSelect,
    politicalDivisions: selectors.getPoliticalDivisions1ForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLocationsByZipCode: (params) =>
        dispatch(actions.fetchLocationsByZipCode(params)),
    fetchPoliticalDivisions1: (dp1) =>
        dispatch(actions.fetchPoliticalDivisions1(dp1)),
    fetchPoliticalDivisions2: (value) =>
        dispatch(actions.fetchPoliticalDivisions2(value)),
    fetchRateDivisions: (value) => dispatch(actions.fetchRateDivisions(value)),
    fetchRegionsByPoliticalDivision: (value, setValue) =>
        dispatch(actions.fetchRegionsByPoliticalDivision(value, setValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationSection);
