import { getFileNameByPath } from 'common/utils/helpers';

import initializeForm from './initializeForm';
import showModal from './showModal';

export default (document) => (dispatch) => {
    const values = {
        file: document.file && [
            {
                name: getFileNameByPath(document.file),
                path: getFileNameByPath(document.file),
                isUrlFile: true,
            },
        ],
        id: document.id,
        name: document.name,
        document_type: document.documentTypeId,
        value_user: document.valueUser,
    };

    dispatch(initializeForm(values));
    dispatch(showModal(true));
};
