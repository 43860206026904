import { styled } from '@mui/material/styles';
import { Menu } from 'sunwise-ui';

const StyledMenu = styled(Menu)(() => ({
    '& .MuiList-root': {
        'li, span': { fontSize: '14px' },
        padding: 0,
    },
}));

export default StyledMenu;
