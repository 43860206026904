import { NAME } from './constants';

export const FETCH_AGENTS = `${NAME}/FETCH_AGENTS`;
export const FETCH_AGENTS_FAILURE = `${NAME}/FETCH_AGENTS_FAILURE`;
export const FETCH_AGENTS_SUCCESS = `${NAME}/FETCH_AGENTS_SUCCESS`;

export const FETCH_CONTACTS = `${NAME}/FETCH_CONTACTS`;
export const FETCH_CONTACTS_FAILURE = `${NAME}/FETCH_CONTACTS_FAILURE`;
export const FETCH_CONTACTS_SUCCESS = `${NAME}/FETCH_CONTACTS_SUCCESS`;

export const RESET_VALUES = `${NAME}/RESET_VALUES`;

export const SELECT_FILTER_ITEM = `${NAME}/SELECT_FILTER_ITEM`;
