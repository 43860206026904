import React from 'react';

import {
    getCurrencyIso,
    getCurrencySymbol,
    getDecimals,
    numberFormat,
} from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

const CostFormatter = (cell, row) => {
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const currencyIso = getCurrencyIso(row.currency);
    const currencySymbol = getCurrencySymbol(row.currency);
    return (
        <span>
            {numberFormat(cell, {
                currency: currencyIso,
                locale: countryCurrencyLocale,
                style: 'currency',
                symbol: currencySymbol,
                decimals: getDecimals(cell),
            })}
        </span>
    );
};

export default CostFormatter;
