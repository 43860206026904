import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        external_custom_template_id: string()
            .required(REQUIRED_TEXT)
            .nullable(),
    });
};
