import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

import { DAYS_IN_YEAR } from 'common/constants';
import { differenceInDaysDate } from 'common/utils/dates';

import { parseDateDefault } from '../../helpers';

const LessThanOneYearWarning = ({ summary }) => {
    const { t } = useTranslation();

    const finalDate = summary?.[0]?.final_date;
    const initialDate = summary?.[summary?.length - 1]?.initial_date;

    if (!finalDate || !initialDate) return null;

    const parsedFinalDate = parseDateDefault(finalDate);
    const parsedInitialDate = parseDateDefault(initialDate);
    const diffDays = differenceInDaysDate(parsedFinalDate, parsedInitialDate);

    if (diffDays >= DAYS_IN_YEAR) return null;

    return (
        <Alert severity="info">
            {t('The information entered is less than one year')}
        </Alert>
    );
};

LessThanOneYearWarning.propTypes = {
    summary: PropTypes.array,
};

export default LessThanOneYearWarning;
