import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Button, Typography } from 'sunwise-ui';

import { handleFileURL } from 'common/utils/helpers';

import EmptyAlliances from 'resources/EmptyAlliances.png';

import StyledButtonAddAlliance from './StyledButtonAddAlliance';

const buildIdFromName = (name) =>
    `financier-${name.toLowerCase().replace(/ /g, '-')}`;

const ModalTermsConditions = ({ financiers, handleOnClick, isFetching }) => {
    const { t } = useTranslation();

    if (isFetching) {
        return (
            <Typography variant="caption">
                {t('Loading alliance', { count: 2 }).concat('...')}
            </Typography>
        );
    }

    if (financiers.length === 0) {
        return <img alt="" src={EmptyAlliances} />;
    }

    return (
        <>
            <Grid container>
                <Grid item>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Choose with whom you want to enable your alliance')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container mt={2}>
                <Grid item>
                    <Box
                        display="flex"
                        justifyContent="space-around"
                        gap="16px"
                        flexWrap="wrap"
                    >
                        {Array.isArray(financiers) &&
                            financiers.length > 0 &&
                            financiers.map((financier) => (
                                <Button
                                    id={buildIdFromName(financier.name)}
                                    key={`financier-${financier.id}`}
                                    onClick={() => handleOnClick(financier)}
                                    variant="default"
                                >
                                    <Box>
                                        <StyledButtonAddAlliance>
                                            <picture>
                                                <img
                                                    alt=""
                                                    src={handleFileURL(
                                                        financier.image,
                                                        process.env
                                                            .VITE_S3_MEDIA_PATH,
                                                    )}
                                                />
                                            </picture>
                                        </StyledButtonAddAlliance>
                                        {financier.name}
                                    </Box>
                                </Button>
                            ))}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

ModalTermsConditions.propTypes = {
    financiers: PropTypes.array,
    handleOnClick: PropTypes.func,
    isFetching: PropTypes.bool,
};

export default ModalTermsConditions;
