import BaseModel from './BaseModel';

export default class SupplierUtility extends BaseModel {
    static generate(item) {
        return this.create(item);
    }
}

SupplierUtility.modelName = 'SupplierUtility';
SupplierUtility.prepareFields({
    country: 'country',
    id: 'id',
    name: 'name',
});
