import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (_, props) => {
    const {
        getMaxValueText,
        getMinValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    {
        const {
            commission_credit = 0,
            hitch = 0,
            hitch_percentage = 0,
            insurance = 0,
            rate = 0,
            residual = 0,
            total_with_dealer_fee,
        } = props.initialValues;

        return object().shape({
            commission_credit: number().when('is_commission_percentage', {
                is: true,
                then: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(
                            commission_credit,
                            getMinValueText(commission_credit),
                        )
                        .max(100, getMaxValueText(100))
                        .nullable(),
                otherwise: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(0, getMinValueText(0))
                        .max(
                            commission_credit,
                            getMaxValueText(commission_credit),
                        )
                        .nullable(),
            }),
            hitch: number().when('is_percentage', {
                is: false,
                then: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(hitch, getMinValueText(hitch))
                        .max(
                            total_with_dealer_fee,
                            getMaxValueText(total_with_dealer_fee),
                        )
                        .nullable(),
            }),
            hitch_percentage: number().when('is_percentage', {
                is: true,
                then: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(
                            hitch_percentage,
                            getMinValueText(hitch_percentage),
                        )
                        .max(100, getMaxValueText(100))
                        .nullable(),
            }),
            insurance: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .max(insurance, getMaxValueText(insurance))
                .nullable(),
            rate: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .max(rate, getMaxValueText(rate))
                .nullable(),
            residual: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .max(residual, getMaxValueText(residual))
                .nullable(),
            term: string().required(REQUIRED_TEXT).nullable(),
        });
    }
};
