import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../actions';
import * as selectors from '../selectors';

import Form from './Form';

const ModalForm = ({
    isModalFormOpen,
    selectedProjectId,
    setModalFormOpenStatus,
}) => {
    const { t } = useTranslation();
    const handleOnClose = () => setModalFormOpenStatus(false);

    return (
        <Dialog
            onClose={handleOnClose}
            open={isModalFormOpen}
            size="sm"
            title={t('Report')}
        >
            <Form
                onClose={handleOnClose}
                selectedProjectId={selectedProjectId}
            />
        </Dialog>
    );
};

ModalForm.propTypes = {
    isModalFormOpen: PropTypes.bool,
    selectedProjectId: PropTypes.string,
    setModalFormOpenStatus: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isModalFormOpen: selectors.getIsModalFormOpen,
});

const mapDispatchToProps = (dispatch) => ({
    setModalFormOpenStatus: (value) =>
        dispatch(actions.setModalFormOpenStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalForm);
