import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { debounce, isEqual, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { Card, DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { DEBOUNCE_TIME } from 'common/constants';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMNS_CONFIG } from '../constants';

import BulkOperationsCard from './BulkOperationsCard';
import Columns from './Columns';

const ListItems = ({
    agents,
    canDelete,
    canModify,
    downloadFile,
    filterData,
    filterItems,
    handleClickBulkItems,
    handleSaveRow,
    isFetching,
    items,
    paginationData,
    setFilterData,
    tableSettings,
    uploadFile,
}) => {
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedBulkOption, setSelectedBulkOption] = useState('');
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount,
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME),
    ).current;

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnSelectAgent = (item) => {
        const filter = {
            ...filterData,
            agentId: item.value,
            page: 0,
        };
        setFilterData(filter);
    };

    const handleOnSelectStatus = (value) => {
        const filter = { ...filterData, page: 0, status: value };
        setFilterData(filter);
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    const handleRowEditStart = (params, event) => {
        const id = params.row.id;
        if (isNull(selectedRowId)) setSelectedRowId(id);
        else event.defaultMuiPrevented = true;
        return false;
    };

    const handleRowEditStop = (_, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleUpdateEmails = (row) => {
        const { email, emails } = row;
        if (emails?.length === 0) return [{ email_type: '0', email }];
        return emails.map((item, index) =>
            index === 0 ? { ...item, email } : item,
        );
    };

    const processRowUpdate = (newRow, oldRow) => {
        const newEmails = handleUpdateEmails(newRow);
        handleSaveRow({
            ...newRow,
            emails: newEmails,
            previusEmail: oldRow.email,
        });
        return oldRow;
    };

    return (
        <>
            <BulkOperationsCard
                agents={agents}
                canDelete={canDelete}
                canModify={canModify}
                downloadFile={downloadFile}
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                handleOnChangeSearch={handleOnChangeSearch}
                handleOnSelectAgent={handleOnSelectAgent}
                handleOnSelectStatus={handleOnSelectStatus}
                selectedBulkOption={selectedBulkOption}
                selectionModel={selectionModel}
                setSelectedBulkOption={setSelectedBulkOption}
                setSelectionModel={setSelectionModel}
                uploadFile={uploadFile}
            />

            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnsConfig={COLUMNS_CONFIG}
                        tableKey="contacts"
                    />
                    <DataGrid
                        autoHeight
                        checkboxSelection={
                            canDelete || canModify ? true : false
                        }
                        columns={Columns({
                            canDelete,
                            canModify,
                            filterData,
                            handleClickBulkItems,
                            hiddenColumns: getHiddenColumns(tableSettings),
                            orders: getOrderedColumns(tableSettings),
                            rowModesModel,
                            selectedRowId,
                            setRowModesModel,
                            setSelectedRowId,
                        })}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        disableSelectionOnClick
                        editMode="row"
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: { pageSize: filterData?.pageSize },
                            pinnedColumns: { right: ['actions'] },
                        }}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData({ ...filterData, pageSize: newPage })
                        }
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        onRowModesModelChange={(newModel) =>
                            setRowModesModel(newModel)
                        }
                        onSelectionModelChange={(newModel) =>
                            setSelectionModel(newModel)
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        processRowUpdate={processRowUpdate}
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        sortingMode="server"
                        sx={{
                            '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
                                backgroundColor: 'rgba(255, 154, 0, 0.16)',
                            },
                            '& .MuiDataGrid-row--editing:hover': {
                                backgroundColor: 'rgba(255, 154, 0, 0.08)',
                            },
                            '& .MuiInputBase-formControl input': {
                                border: 0,
                                outline: 'none',
                                boxShadow: 'none',
                            },
                        }}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    agents: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    downloadFile: PropTypes.func,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
    uploadFile: PropTypes.func,
};

export default ListItems;
