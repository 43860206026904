import i18next from 'i18next';
import { types } from 'sunwise-template-core';

import { NEW_TEMPLATE_MODE, NEW_FROM_TEMPLATE_MODE } from '../constants';

import initializeForm from './initializeForm';
import setTemplateMode from './setTemplateMode';
import toggleModalTemplate from './toggleModalTemplate';

export default (branchSelected, template = null, templateType) =>
    (dispatch) => {
        let values = {};
        if (branchSelected !== 'all') {
            values.branch_offices = branchSelected;
        }
        if (templateType === types.MULTIPROPOSAL_TYPE) {
            values.proposals_number = 2;
        }
        if (template === null) {
            dispatch(
                initializeForm({
                    ...values,
                    language: i18next.language,
                    type: templateType,
                }),
            );
            dispatch(setTemplateMode(NEW_TEMPLATE_MODE));
        } else {
            dispatch(
                initializeForm({
                    ...values,
                    baseTemplateLanguages: template?.languages,
                    custom_offer: template.id,
                    language: template.language,
                    locked: template.locked,
                    title: template.title,
                    type: templateType,
                }),
            );
            dispatch(setTemplateMode(NEW_FROM_TEMPLATE_MODE));
        }
        dispatch(toggleModalTemplate(true));
    };
