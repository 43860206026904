import { getLanguages } from 'common/api/v1/international';

import {
    FETCH_LANGUAGES,
    FETCH_LANGUAGES_FAILURE,
    FETCH_LANGUAGES_SUCCESS,
} from '../actionTypes';
import { profileConfigurationActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(profileConfigurationActions[FETCH_LANGUAGES]());

    getLanguages()
        .then((response) =>
            dispatch(
                profileConfigurationActions[FETCH_LANGUAGES_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                profileConfigurationActions[FETCH_LANGUAGES_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
