import React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

import { addDefaultSrc } from 'common/utils/helpers';

import PdfDocument from 'resources/pdfDocument.svg';

import DefaultTemplateRibbon from './DefaultTemplateRibbon';

const Container = styled('div')`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 262px;
    position: relative;
    width: 140px;

    ${({ disabled }) =>
        disabled &&
        `
        cursor: default;
        opacity: .5;
    `}
`;

const TemplateImage = styled('img')`
    height: 218px;
    object-fit: cover;
    width: 100%;
    z-index: 1;
`;

const DefaultTemplatePreview = ({
    canModify,
    imgSrc = PdfDocument,
    isLocked,
    isNew,
    isOustanding,
    onClick,
    title,
}) => (
    <Container disabled={!canModify}>
        <DefaultTemplateRibbon isNew={isNew} isOustanding={isOustanding} />
        <TemplateImage
            onError={(e) => addDefaultSrc(e, 160, 280)}
            onClick={onClick}
            src={`${import.meta.env.VITE_S3_MEDIA_PATH}${imgSrc}`}
        />
        <Box display="flex" alignItems="center" gap={1} py={1}>
            {isLocked && <LockIcon fontSize="small" />}
            <Typography
                fontWeight="bold"
                noWrap
                sx={{
                    overflow: 'hidden',
                    width: 'calc(100% - 16px)',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                }}
                variant="body2"
            >
                {title}
            </Typography>
        </Box>
    </Container>
);

DefaultTemplatePreview.propTypes = {
    canModify: PropTypes.bool,
    imgSrc: PropTypes.string,
    isLocked: PropTypes.bool,
    isNew: PropTypes.bool,
    isOustanding: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string,
};

export default DefaultTemplatePreview;
