import { createSlice } from '@reduxjs/toolkit';

import { formatDate, zonedDate } from 'common/utils/dates';
import { getTimezoneUserSettings } from 'common/utils/helpers/multiregion';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    FETCH_CONTACTS_TRACKINGS,
    FETCH_CONTACTS_TRACKINGS_FAILURE,
    FETCH_CONTACTS_TRACKINGS_SUCCESS,
    INITIALIZE_TRACKING_FORM,
    OPEN_MODAL_TRACKING,
    RESET_TRACKING_FORM,
    SAVE_BULK_TRACKINGS,
    SAVE_BULK_TRACKINGS_FAILURE,
    SAVE_BULK_TRACKINGS_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const currentDate = zonedDate(new Date(), getTimezoneUserSettings());
const dateTimeToday = formatDate(currentDate, 'yyyy-MM-dd H:mm');

const INITIAL_STATE = {
    bulkUpdateItems: {
        errors: [],
        isSaving: false,
    },
    contactsTrackings: {
        data: {
            data: [],
            pagination: null,
        },
        errors: [],
        isFetching: false,
    },
    initialTrackingValues: {
        completed: false,
        notification_at: dateTimeToday,
        trackings: [],
    },
    isOpenModalTracking: false,
    saveTracking: {
        errors: [],
        isSaving: false,
    },
};

const followUpsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isSaving: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isSaving: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                isSaving: false,
            };
        },
        [FETCH_CONTACTS_TRACKINGS]: (state) => {
            state.contactsTrackings.isFetching = true;
        },
        [FETCH_CONTACTS_TRACKINGS_FAILURE]: (state, action) => {
            state.contactsTrackings = {
                ...state.contactsTrackings,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONTACTS_TRACKINGS_SUCCESS]: (state, action) => {
            state.contactsTrackings = {
                ...state.contactsTrackings,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_TRACKING_FORM]: (state, action) => {
            state.initialTrackingValues = {
                ...state.initialTrackingValues,
                ...action.payload,
            };
        },
        [OPEN_MODAL_TRACKING]: (state, action) => {
            state.isOpenModalTracking = action.payload;
        },
        [RESET_TRACKING_FORM]: (state) => {
            state.initialTrackingValues = INITIAL_STATE.initialTrackingValues;
        },
        [SAVE_BULK_TRACKINGS]: (state) => {
            state.saveTracking = {
                ...state.saveTracking,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_BULK_TRACKINGS_FAILURE]: (state, action) => {
            state.saveTracking = {
                ...state.saveTracking,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_BULK_TRACKINGS_SUCCESS]: (state, action) => {
            state.saveTracking = {
                ...state.saveTracking,
                data: action.payload,
                isSaving: false,
            };
        },
        [UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.contactsTrackings.data.data = action.payload;
        },
    },
});

export const followUpsActions = followUpsSlice.actions;

export default followUpsSlice.reducer;
