import API from '../..';

export const getScheduleRateConfiguration = ({
    certified,
    rate,
    rate_division,
}) =>
    API.get(`/api/v2/schedule_rate_configuration/`, {
        params: { certified, rate, rate_division },
    });
