import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

const MarginFormatter = ({ row }) => {
    const { t } = useTranslation();
    if (row.range_prices.length > 1) {
        return (
            <Typography variant="caption" fontWeight="bold">
                {t('Per range')}
            </Typography>
        );
    }
    return (
        <Typography variant="caption" fontWeight="bold">
            {row.range_prices[0]?.margin || 0}
        </Typography>
    );
};

MarginFormatter.propTypes = {
    row: PropTypes.object,
};

export default MarginFormatter;
