import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_CONTACT,
    FETCH_CONTACT_FAILURE,
    FETCH_CONTACT_SUCCESS,
    FETCH_LOCATION,
    FETCH_LOCATION_ERROR,
    FETCH_LOCATION_SUCCESS,
    FETCH_STATUS_CONTACT,
    FETCH_STATUS_CONTACT_FAILURE,
    FETCH_STATUS_CONTACT_SUCCESS,
    MARK_FAVORITE,
    MARK_FAVORITE_FAILURE,
    SAVE_POSITION,
    SAVE_POSITION_ERROR,
    SAVE_POSITION_SUCCESS,
    UPDATE_CONTACTS_TYPE,
    UPDATE_CONTACTS_TYPE_FAILURE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    contact: {
        data: {},
        error: null,
        isLoading: false,
    },
    fetchPositions: {
        data: {},
        error: null,
        isLoading: false,
    },
    markFavorite: {
        error: null,
        isLoading: false,
    },
    savePosition: {
        data: {},
        errors: null,
        isLoading: false,
    },
    statusContact: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    updateType: {
        error: null,
        isLoading: false,
    },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_CONTACT]: (state) => {
            state.contact.isLoading = true;
        },
        [FETCH_CONTACT_FAILURE]: (state, action) => {
            state.contact = {
                ...state.contact,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_CONTACT_SUCCESS]: (state, action) => {
            state.contact = {
                ...state.contact,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_LOCATION]: (state) => {
            state.fetchPositions = {
                ...state.fetchPositions,
                data: {},
                isLoading: true,
            };
        },
        [FETCH_LOCATION_ERROR]: (state, action) => {
            state.fetchPositions = {
                ...state.fetchPositions,
                data: {},
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_LOCATION_SUCCESS]: (state, action) => {
            state.fetchPositions = {
                ...state.fetchPositions,
                data: action.payload,
                error: null,
                isLoading: false,
            };
        },
        [MARK_FAVORITE]: (state) => {
            state.markFavorite.isLoading = true;
        },
        [MARK_FAVORITE_FAILURE]: (state, action) => {
            state.markFavorite = { error: action.payload, isLoading: false };
        },
        [UPDATE_CONTACTS_TYPE]: (state) => {
            state.updateType.isLoading = true;
        },
        [UPDATE_CONTACTS_TYPE_FAILURE]: (state, action) => {
            state.updateType = {
                ...state.updateType,
                error: action.payload,
                isLoading: false,
            };
        },
        [SAVE_POSITION]: (state) => {
            state.savePosition.isLoading = true;
        },
        [SAVE_POSITION_ERROR]: (state, action) => {
            state.savePosition = {
                ...state.savePosition,
                errors: action.payload,
                isLoading: false,
            };
        },
        [SAVE_POSITION_SUCCESS]: (state, action) => {
            state.savePosition = {
                ...state.savePosition,
                data: action.payload,
                errors: null,
                isLoading: false,
            };
        },
        [FETCH_STATUS_CONTACT]: (state) => {
            state.statusContact.isLoading = true;
        },
        [FETCH_STATUS_CONTACT_FAILURE]: (state, action) => {
            state.statusContact = {
                ...state.statusContact,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_STATUS_CONTACT_SUCCESS]: (state, action) => {
            state.statusContact = {
                ...state.statusContact,
                data: action.payload,
                isEmpty: action.payload.length === 0,
                isLoading: false,
            };
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
