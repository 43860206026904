import { getData } from 'common/api/v1/monitoringDashboardList';

import {
    FETCH_CONTACTS,
    FETCH_CONTACTS_FAILURE,
    FETCH_CONTACTS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_CONTACTS]());

    getData()
        .then((response) =>
            dispatch(actions[FETCH_CONTACTS_SUCCESS](response?.data?.data)),
        )
        .catch((error) =>
            dispatch(actions[FETCH_CONTACTS_FAILURE](error?.response)),
        );
};
