import React from 'react';

import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

import Periods from './Periods';

const GroupsArray = ({
    control,
    disabled,
    isSinglePeriod,
    name,
    onChangePeriod,
    periods,
    proposalPeriods,
    setValue,
}) => {
    const { fields } = useFieldArray({ control, name });

    return fields.map((item, index) => (
        <Periods
            control={control}
            disabled={disabled}
            isSinglePeriod={isSinglePeriod}
            key={item.id}
            name={`${name}.${index}`}
            onChangePeriod={onChangePeriod}
            periods={periods}
            proposalPeriods={proposalPeriods}
            setValue={setValue}
            showName={fields?.length > 1}
        />
    ));
};

GroupsArray.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChangePeriod: PropTypes.func,
    periods: PropTypes.array,
    proposalPeriods: PropTypes.array,
    setValue: PropTypes.func,
};

export default GroupsArray;
