import React from 'react';

import PropTypes from 'prop-types';

import { DEFAULT_LAT, DEFAULT_LNG } from 'common/constants';

import LabelError from './LabelError';
import MapContainer from './MapContainer';

const ReduxMap = ({
    center,
    disableDefaultUI,
    heightMap,
    input,
    meta: { error, touched },
    readOnly,
    title,
    zoom,
}) => {
    const onMarkerDragend = (_, marker) => {
        input.onChange({
            latitude: marker.position.lat(),
            longitude: marker.position.lng(),
        });
        return;
    };

    const handleOnPositionChanged = (_, map) => {
        input.onChange({
            latitude: map.center.lat(),
            longitude: map.center.lng(),
        });
    };

    const latitude =
        input.value.latitude &&
        -90 < parseFloat(input.value.latitude) &&
        parseFloat(input.value.latitude) < 90
            ? input.value.latitude
            : center.lat;

    const longitude =
        input.value.longitude &&
        -180 < parseFloat(input.value.longitude) &&
        parseFloat(input.value.longitude) < 180
            ? input.value.longitude
            : center.lng;

    const markers = [
        {
            draggable: false,
            lat: latitude,
            lng: longitude,
            name: title,
            onDragend: onMarkerDragend,
            title: title,
        },
    ];

    return (
        <>
            <MapContainer
                center={{
                    lat: latitude,
                    lng: longitude,
                }}
                disableDefaultUI={disableDefaultUI}
                handleOnPositionChanged={handleOnPositionChanged}
                heightMap={heightMap}
                markers={markers}
                readOnly={readOnly}
                zoom={zoom}
            />

            {touched && error && (
                <LabelError type="error" fontSize="13px">
                    {error}
                </LabelError>
            )}
        </>
    );
};

ReduxMap.defaultProps = {
    center: {
        lat: DEFAULT_LAT,
        lng: DEFAULT_LNG,
    },
    disableDefaultUI: false,
    readOnly: false,
};

ReduxMap.propTypes = {
    center: PropTypes.object,
    disableDefaultUI: PropTypes.bool,
    heightMap: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    readOnly: PropTypes.bool,
    title: PropTypes.string,
    zoom: PropTypes.number,
};

export default ReduxMap;
