import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_LOGS,
    FETCH_LOGS_FAILURE,
    FETCH_LOGS_SUCCESS,
    FETCH_PROJECTS_INFO,
    FETCH_PROJECTS_INFO_FAILURE,
    FETCH_PROJECTS_INFO_SUCCESS,
    RESET,
    RESET_LOGS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    logs: {
        data: [],
        error: null,
        loading: false,
        pagination: { page: 0, pages: 1 },
    },
    projects_info: { data: {}, error: null, loading: false },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_LOGS]: (state) => {
            state.logs.loading = true;
            state.logs.error = null;
        },
        [FETCH_LOGS_FAILURE]: (state, action) => {
            state.logs.error = action.payload;
            state.logs.loading = false;
        },
        [FETCH_LOGS_SUCCESS]: (state, action) => {
            const { logs = [], page = 1, pages = 0 } = action.payload || {};

            const newLogs = [...state.logs.data, ...logs];

            const newItems = newLogs.reduce((acc, log) => {
                const lastLog = acc[acc.length - 1];
                if (log.date && lastLog?.date === log.date) {
                    lastLog.reads = lastLog.reads || [];
                    lastLog.reads.push(...log.reads);
                } else {
                    acc.push(log);
                }
                return acc;
            }, []);
            state.logs.data = newItems;
            state.logs.loading = false;
            state.logs.pagination = { page, pages };
        },
        [FETCH_PROJECTS_INFO]: (state, action) => {
            state.projects_info.loading = true;
            state.projects_info.error = null;

            const projects = action.payload;
            for (const project of projects) {
                if (!project) continue;
                state.projects_info.data[project] = {};
            }
        },
        [FETCH_PROJECTS_INFO_FAILURE]: (state, action) => {
            state.projects_info.error = action.payload;
            state.projects_info.loading = false;
        },
        [FETCH_PROJECTS_INFO_SUCCESS]: (state, action) => {
            state.projects_info.loading = false;

            const projects = action.payload;
            for (const project of projects) {
                if (!project?.id) continue;
                state.projects_info.data[project.id] = project;
            }
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_LOGS]: (state) => {
            state.logs = INITIAL_STATE.logs;
        },
    },
});

export const actions = slice.actions;
export default slice.reducer;
