import React from 'react';

import PropTypes from 'prop-types';

import Card from './components/Card';

const Container = ({ canModifyRates, commercialOfferId, isLocked }) => {
    return (
        <Card
            canModifyRates={canModifyRates}
            commercialOfferId={commercialOfferId}
            isLocked={isLocked}
        />
    );
};

Container.propTypes = {
    canModifyRates: PropTypes.bool,
    commercialOfferId: PropTypes.string,
    isLocked: PropTypes.bool,
};

export default Container;
