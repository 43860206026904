import { NAME } from './constants';

export const GET_LISA_FILE = `${NAME}/FETCH_LISA_FILES`;
export const GET_LISA_FILE_FAILURE = `${NAME}/FETCH_LISA_FILES_ERROR`;
export const GET_LISA_FILE_SUCCESS = `${NAME}/FETCH_LISA_FILES_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const SET_SHOW_LISA_LOADING = `${NAME}/SET_SHOW_LISA_LOADING`;

export const UPLOAD_FILES = `${NAME}/UPLOAD_FILES`;
export const UPLOAD_FILES_FAILURE = `${NAME}/UPLOAD_FILES_ERROR`;
export const UPLOAD_FILES_SUCCESS = `${NAME}/UPLOAD_FILES_SUCCESS`;
