import { NAME } from './constants';

export const FETCH_LANGUAGES = `${NAME}/FETCH_LANGUAGES`;
export const FETCH_LANGUAGES_SUCCESS = `${NAME}/FETCH_LANGUAGES_SUCCESS`;
export const FETCH_LANGUAGES_FAILURE = `${NAME}/FETCH_LANGUAGES_FAILURE`;

export const FETCH_TIMEZONES = `${NAME}/FETCH_TIMEZONES`;
export const FETCH_TIMEZONES_SUCCESS = `${NAME}/FETCH_TIMEZONES_SUCCESS`;
export const FETCH_TIMEZONES_FAILURE = `${NAME}/FETCH_TIMEZONES_FAILURE`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const UPDATE_CONFIGURATION = `${NAME}/UPDATE_CONFIGURATION`;
export const UPDATE_CONFIGURATION_SUCCESS = `${NAME}/UPDATE_CONFIGURATION_SUCCESS`;
export const UPDATE_CONFIGURATION_FAILURE = `${NAME}/UPDATE_CONFIGURATION_FAILURE`;
