import { setEnableItem } from 'common/api/v1/customRates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SET_RATE_ENABLED,
    SET_RATE_ENABLED_FAILURE,
    SET_RATE_ENABLED_SUCCESS,
} from '../actionTypes';
import { ratesActions } from '../reducer';
import * as selectors from '../selectors';

import filterRates from './filterRates';

export default (rateId, isEnabled) => (dispatch, getState) => {
    dispatch(ratesActions[SET_RATE_ENABLED]());
    setEnableItem(rateId, isEnabled)
        .then(() => {
            const filterQuery = selectors.getFilterQuery(getState());
            dispatch(ratesActions[SET_RATE_ENABLED_SUCCESS](rateId));
            dispatch(filterRates(filterQuery));
        })
        .catch((error) => {
            dispatch(
                ratesActions[SET_RATE_ENABLED_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
