import { NAME } from './constants';

export const CLONE_POSITION = `${NAME}/CLONE_POSITION`;
export const CLONE_POSITION_ERROR = `${NAME}/CLONE_POSITION_ERROR`;
export const CLONE_POSITION_SUCCESS = `${NAME}/CLONE_POSITION_SUCCESS`;

export const FETCH_LOCATION = `${NAME}/FETCH_LOCATION`;
export const FETCH_LOCATION_ERROR = `${NAME}/FETCH_LOCATION_ERROR`;
export const FETCH_LOCATION_SUCCESS = `${NAME}/FETCH_LOCATION_SUCCESS`;

export const SAVE_POSITION = `${NAME}/SAVE_POSITION`;
export const SAVE_POSITION_ERROR = `${NAME}/SAVE_POSITION_ERROR`;
export const SAVE_POSITION_SUCCESS = `${NAME}/SAVE_POSITION_SUCCESS`;

export const SELECT_PROJECT = `${NAME}/SELECT_PROJECT`;
