import i18next from 'i18next';
import { createSelector } from 'reselect';

import { fullNameBuild } from 'common/utils/helpers';

import { NAME } from './constants';

const sortByName = (items) => {
    const newItems = [...items];
    newItems.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });

    return newItems;
};

export const getModel = (state) => state[NAME];

export const getFunnelColumns = createSelector(
    getModel,
    (model) => model.funnelColumns,
);

export const getIsFetchingFunnelColumns = createSelector(
    getFunnelColumns,
    (funnelColumns) => funnelColumns.isFetching,
);

export const getFunnelColumnsData = createSelector(
    getFunnelColumns,
    (funnelColumns) => funnelColumns.data,
);

export const getFunnelColumnsErrors = createSelector(
    getFunnelColumns,
    (funnelColumns) => funnelColumns.errors,
);

export const getUpdatingStatusProject = createSelector(
    getModel,
    (model) => model.updatingStatusProject,
);

export const getUpdatingStatusProjectId = createSelector(
    getUpdatingStatusProject,
    (model) => (model ? model.projectId : model),
);

export const getRefreshingIsNeeded = createSelector(
    getModel,
    (model) => model.refreshingIsNeeded,
);

export const getNextPages = createSelector(
    getModel,
    (model) => model.nextPages,
);

export const getNextPagesIds = createSelector(
    getNextPages,
    (model) => model.ids,
);

export const getIsFetchingNextPages = createSelector(
    getNextPages,
    (model) => model.isFetching,
);

export const getAgents = createSelector(getModel, (model) => model.agents);

export const getAgentsData = createSelector(getAgents, (model) => model.data);

export const getIsFetchingAgents = createSelector(
    getAgents,
    (model) => model.isLoading,
);

export const getContacts = createSelector(getModel, (model) => model.contacts);

export const getHasNextPage = createSelector(
    getContacts,
    (contacts) => contacts.data.hasNextPage,
);

export const getContactsForSelect = createSelector(getContacts, (contacts) => {
    const items = contacts.data.data.map((item) => {
        return {
            label: fullNameBuild({
                firstName: item.name,
                lastName: item.first_lastname,
            }),
            value: item.id,
        };
    });

    items.unshift({
        label: i18next.t('All text', { context: 'male', count: 2 }),
        value: null,
    });

    return items;
});

export const getIsLoadingContacts = createSelector(
    getContacts,
    (contacts) => contacts.isLoading,
);

export const getFilterItemSelected = createSelector(
    getModel,
    (model) => model.filterItemSelected,
);

export const getAdvisorsModel = createSelector(
    getModel,
    (model) => model.advisors,
);

export const getAdvisorsData = createSelector(
    getAdvisorsModel,
    (model) => model.data || [],
);

export const getAdvisorsIsFetching = createSelector(
    getAdvisorsModel,
    (model) => model.isFetching,
);

export const getAdvisorsToSelect = createSelector(getAdvisorsData, (model) => {
    const items = model.map((item) => {
        const name = fullNameBuild({
            firstName: item.first_name,
            lastName: item.last_name,
        });
        return { label: name, value: name };
    });

    const sortedItems = sortByName(items);

    sortedItems.unshift({
        label: i18next.t('All text', { context: 'male', count: 2 }),
        value: null,
    });

    return sortedItems;
});

export const getAdvisorsErrors = createSelector(
    getAdvisorsModel,
    (model) => model.errors,
);

export const getFinanciersModel = createSelector(
    getModel,
    (model) => model.financiers,
);

export const getFinanciersData = createSelector(
    getFinanciersModel,
    (model) => model.data || [],
);

export const getFinanciersIsFetching = createSelector(
    getFinanciersModel,
    (model) => model.isFetching,
);

export const getFinanciersErrors = createSelector(
    getFinanciersModel,
    (model) => model.errors,
);

export const getFinanciersToSelect = createSelector(
    getFinanciersData,
    (model) => {
        const items = model.map((item) => {
            const name = item.name;
            return { label: name, value: name };
        });

        const sortedItems = sortByName(items);

        sortedItems.unshift({
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: null,
        });

        return sortedItems;
    },
);

export const getStatusDictionary = createSelector(
    getModel,
    (model) => model.statusDictionary || {},
);
