import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Grid } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';
import KeyIndicator from 'common/components/KeyIndicator';
import PlaceholderTemplateChart from 'common/components/placeholder/PlaceholderTemplateChart';
import { numberFormat } from 'common/utils/helpers';

import { getProjectsChartSettings } from '../helpers';
import * as selectors from '../selectors';

const ProjectsTotals = ({ currencyLocale, totals, readyPlaceholder }) => {
    const { t } = useTranslation();
    const { categories, projects_totals, series } = totals;
    const { data, options } = getProjectsChartSettings({
        currencyLocale,
        labels: categories,
        series,
    });

    return (
        <Card.Body>
            <Grid container>
                <Grid item xs={18} lg={13} xl={14}>
                    <PlaceholderTemplateChart ready={readyPlaceholder}>
                        <ChartJs data={data} options={options} />
                    </PlaceholderTemplateChart>
                </Grid>

                <Grid item xs={18} lg={5} xl={4}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        sx={{ width: '100%' }}
                    >
                        <KeyIndicator
                            isLoading={readyPlaceholder}
                            title={t('Total projects')}
                            value={numberFormat(projects_totals, {
                                decimals: 0,
                                locale: currencyLocale,
                                style: 'decimal',
                            })}
                            widthIcon="20px"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Card.Body>
    );
};

const mapStateToProps = createStructuredSelector({
    totals: selectors.getProjectsCountersData,
});

ProjectsTotals.propTypes = {
    currencyLocale: PropTypes.string,
    totals: PropTypes.object,
    readyPlaceholder: PropTypes.bool,
};

export default connect(mapStateToProps, null)(ProjectsTotals);
