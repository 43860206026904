import { createItem, updateItem } from 'common/api/v1/companyTaxes';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_TAXES,
    SAVE_TAXES_FAILURE,
    SAVE_TAXES_SUCCESS,
} from '../actionTypes';
import { taxesConfigurationActions } from '../reducer';

import fetchTaxes from './fetchTaxes';
import resetTaxes from './resetTaxes';

export default (values) => (dispatch) => {
    const newValues = {
        tax_name: values.tax_name,
        tax_value: values.tax_value,
        is_percentage: values.is_percentage,
        companies_taxes_elements: values.is_percentage
            ? values.companies_taxes_elements.join()
            : '',
    };

    dispatch(taxesConfigurationActions[SAVE_TAXES]());

    const save = values.id
        ? () => updateItem(newValues, values.id)
        : () => createItem(newValues);

    save()
        .then((response) => {
            dispatch(
                taxesConfigurationActions[SAVE_TAXES_SUCCESS](response.data),
            );
            dispatch(resetTaxes());
            dispatch(fetchTaxes());

            showToast();
        })
        .catch((error) => {
            dispatch(
                taxesConfigurationActions[SAVE_TAXES_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
