import React from 'react';

import { styled } from '@mui/material/styles';
import { Typography } from 'sunwise-ui';

const Name = styled('span')`
    cursor: default;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    white-space: normal;
`;

const ProductNameFormatter = (cell) => (
    <Typography>
        <Name title={cell}>{cell}</Name>
    </Typography>
);

export default ProductNameFormatter;
