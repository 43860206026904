import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_GEOCODE,
    FETCH_GEOCODE_ERROR,
    FETCH_GEOCODE_POINT,
    FETCH_GEOCODE_POINT_FAILURE,
    FETCH_GEOCODE_POINT_SUCCESS,
    FETCH_GEOCODE_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SET_SHOW_GEOCODE_RESULTS,
} from './actionTypes';
import { NAME } from './constants';
import { getFormName } from './helpers';

const baseState = {
    fetchGeocode: {
        data: [],
        errors: null,
        isLoading: false,
    },
    fetchGeocodePoint: {
        data: null,
        errors: null,
        isFetching: false,
    },
    initialValues: {
        city: '',
        cp: '',
        description: '',
        hasPostalAddress: true,
        id: '',
        political_division1: null,
        position: null,
        postalAddress: '',
        title: '',
    },
    showGeocodeResults: false,
};

const INITIAL_STATE = {};

const locationFormSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIALIZE_FORM]: (state, action) => {
            state[getFormName(action.payload.name)] = {
                fetchGeocode: { ...baseState.fetchGeocode },
                fetchGeocodePoint: { ...baseState.fetchGeocodePoint },
                initialValues: { ...action.payload.payload },
                showGeocodeResults: baseState.showGeocodeResults,
            };
        },
        [RESET_FORM]: (state, action) => {
            state[getFormName(action.payload.name)] = { ...baseState };
        },
        [FETCH_GEOCODE]: (state, action) => {
            state[getFormName(action.payload.name)].fetchGeocode = {
                ...state[getFormName(action.payload.name)].fetchGeocode,
                errors: [],
                isLoading: true,
            };
        },
        [FETCH_GEOCODE_ERROR]: (state, action) => {
            state[getFormName(action.payload.name)].fetchGeocode = {
                ...state[getFormName(action.payload.name)].fetchGeocode,
                data: [],
                errors: action.payload.payload,
                isLoading: false,
            };
        },
        [FETCH_GEOCODE_SUCCESS]: (state, action) => {
            state[getFormName(action.payload.name)].fetchGeocode = {
                ...state[getFormName(action.payload.name)].fetchGeocode,
                data: action?.payload.payload,
                errors: [],
                isLoading: false,
            };
        },
        [FETCH_GEOCODE_POINT]: (state, action) => {
            state[getFormName(action.payload.name)].fetchGeocodePoint = {
                ...state[getFormName(action.payload.name)].fetchGeocodePoint,
                data: null,
                isFetching: true,
            };
        },
        [FETCH_GEOCODE_POINT_FAILURE]: (state, action) => {
            state[getFormName(action.payload.name)].fetchGeocodePoint = {
                ...state[getFormName(action.payload.name)].fetchGeocodePoint,
                errors: action.payload.payload,
                isFetching: false,
            };
        },
        [FETCH_GEOCODE_POINT_SUCCESS]: (state, action) => {
            state[getFormName(action.payload.name)].fetchGeocodePoint = {
                ...state[getFormName(action.payload.name)].fetchGeocodePoint,
                data: action.payload.payload,
                errors: [],
                isFetching: false,
            };
        },
        [SET_SHOW_GEOCODE_RESULTS]: (state, action) => {
            state[getFormName(action.payload.name)].showGeocodeResults =
                action.payload.payload;
        },
    },
});

export const locationFormActions = locationFormSlice.actions;

export default locationFormSlice.reducer;
