import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { changePasswordProfile } from 'common/api/v1/users';
import alerts from 'common/modules/alerts';
import { logoutActions } from 'common/utils/helpers/session';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { managePasswordActions } from '../reducer';

import resetForm from './resetForm';

export default (values) => (dispatch) => {
    const newValues = {
        confirm_new_password: values.confirm_password,
        new_password: values.new_password,
        old_password: values.old_password,
    };

    dispatch(managePasswordActions[SAVE]());

    changePasswordProfile(newValues)
        .then((response) => {
            dispatch(managePasswordActions[SAVE_SUCCESS](response.data));
            dispatch(resetForm);
            showToast();

            const messages = [
                i18next.t(
                    'The password has been updated. For security the current session will be terminated',
                ),
            ];

            if (!isEmpty(messages)) {
                const dataAlert = {
                    messages: messages,
                    onSuccess: () => {
                        dispatch(alerts.actions.close());
                        logoutActions();
                    },
                    textConfirm: i18next.t('Continue'),
                    title: i18next.t('Information'),
                    type: alerts.ALERT_TYPE_ONLY_CONFIRM,
                    variant: 'success',
                };

                dispatch(alerts.actions.show(dataAlert));
            }
        })
        .catch((error) =>
            dispatch(
                managePasswordActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
