import { deleteItem } from 'common/api/v1/measurementSource';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { DELETE_DATA } from '../actionTypes';
import { actions } from '../reducer';

export default (uuid, successCallback) => (dispatch) => {
    if (!uuid) return;

    dispatch(actions[DELETE_DATA]());

    deleteItem(uuid)
        .then((response) => {
            dispatch(actions[DELETE_DATA](response.data));
            showToast();
            if (successCallback) successCallback();
        })
        .catch((error) => {
            dispatch(actions[DELETE_DATA](error));

            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
