import { createPosition } from 'common/api/v1/projectPositions';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { proposalGeneratorPanelsLayoutActions } from '../reducer';

export default (
        projectId,
        proposalId,
        values,
        successCallback = () => {},
        failureCallback = () => {},
    ) =>
    (dispatch) => {
        dispatch(proposalGeneratorPanelsLayoutActions[SAVE]());

        const newValues = {
            description: values.title,
            title: values.title,
            position: JSON.stringify({
                latitude: values.position.latitude,
                longitude: values.position.longitude,
                additional_information: values.postalAddress,
                description: values.title,
                city: values.city,
                zip_code: values.cp,
                political_division1: values.political_division1,
            }),
            project: projectId,
        };

        createPosition(newValues)
            .then((response) => {
                dispatch(
                    proposalGeneratorPanelsLayoutActions[SAVE_SUCCESS](
                        response.data.position,
                    ),
                );
                showToast();
                successCallback();
                dispatch(eventsAfterUpdate(response.data.data, proposalId));
            })
            .catch((error) => {
                dispatch(
                    proposalGeneratorPanelsLayoutActions[SAVE_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
                failureCallback();
            });
    };
