import { createSlice } from '@reduxjs/toolkit';

import { formatDate, subDate } from 'common/utils/dates';

import {
    FETCH_AGENTS,
    FETCH_AGENTS_FAILURE,
    FETCH_AGENTS_SUCCESS,
    FETCH_CONTACTS,
    FETCH_CONTACTS_FAILURE,
    FETCH_CONTACTS_SUCCESS,
    RESET_VALUES,
    SELECT_FILTER_ITEM,
} from './actionTypes';
import { NAME } from './constants';

const initialDate = formatDate(subDate(new Date(), { years: 1 }), 'dd/MM/yyyy');
const finalDate = formatDate(new Date(), 'dd/MM/yyyy');

const INITIAL_STATE = {
    agents: { data: [], error: null, isEmpty: false, isLoading: false },
    contacts: {
        data: { data: [], hasNextPage: false },
        error: null,
        isLoading: false,
    },
    filterItemSelected: {
        final_date: finalDate,
        initial_date: initialDate,
        type: '',
        value: '',
    },
};

const projectsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_AGENTS]: (state) => {
            state.agents.isLoading = true;
        },
        [FETCH_AGENTS_FAILURE]: (state, action) => {
            state.agents = {
                ...state.agents,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_AGENTS_SUCCESS]: (state, action) => {
            state.agents = {
                ...state.agents,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_CONTACTS]: (state) => {
            state.contacts.isLoading = true;
        },
        [FETCH_CONTACTS_FAILURE]: (state, action) => {
            state.contacts = {
                ...state.contacts,
                data: { data: state.contacts.data.data, hasNextPage: false },
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_CONTACTS_SUCCESS]: (state, action) => {
            state.contacts = {
                ...state.contacts,
                data: {
                    data: [
                        ...state.contacts.data.data,
                        ...action.payload.contacts,
                    ],
                    hasNextPage: action.payload.hasNextPage,
                },
                isLoading: false,
            };
        },
        [RESET_VALUES]: () => INITIAL_STATE,
        [SELECT_FILTER_ITEM]: (state, action) => {
            state.filterItemSelected = action.payload;
        },
    },
});

export const projectsActions = projectsSlice.actions;

export default projectsSlice.reducer;
