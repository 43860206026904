import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StyledSection = styled('div')`
    align-items: center;
    display: flex;
    line-height: 44px;
    margin-bottom: ${({ marginBottom }) => marginBottom};
    margin-top: ${({ marginTop }) => marginTop};
`;

const StyledImage = styled('img')`
    margin-right: 15px;
    max-width: 100%;
`;

const StyledLink = styled('a')`
    color: #002438 !important;
    cursor: pointer;
    font-size: ${({ fontSize = '22px' }) => fontSize} !important;
    font-weight: ${({ fontWeight = 'normal' }) => fontWeight} !important;
    line-height: ${({ lineHeight = '29px' }) => lineHeight} !important;

    &:hover {
        color: #ff9a00 !important;
        text-decoration: none;
    }
`;

const FontIcon = styled('i')`
    color: #ff9a00;
    font-size: 21px;
    margin-right: 15px;
`;

const SocialMediaItem = ({
    fontSize,
    fontWeight,
    href,
    imgUrl,
    isFontIcon,
    lineHeight,
    marginBottom = '0',
    marginTop = '0',
    target,
    title,
}) => {
    return (
        <StyledSection marginBottom={marginBottom} marginTop={marginTop}>
            {imgUrl && isFontIcon && <FontIcon className={`fa ${imgUrl}`} />}
            {imgUrl && !isFontIcon && <StyledImage src={imgUrl} width={19} />}
            <StyledLink
                fontSize={fontSize}
                fontWeight={fontWeight}
                lineHeight={lineHeight}
                href={href}
                target={target}
            >
                {title}
            </StyledLink>
        </StyledSection>
    );
};

SocialMediaItem.propTypes = {
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    href: PropTypes.string,
    imgUrl: PropTypes.string,
    isFontIcon: PropTypes.bool,
    lineHeight: PropTypes.string,
    marginBottom: PropTypes.string,
    marginTop: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string,
};

export default SocialMediaItem;
