import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_OFFER_INFO_PATERN,
    FETCH_OFFER_INFO_PATERN_FAILURE,
    FETCH_OFFER_INFO_PATERN_SUCCESS,
    SET_HAS_APPROBED,
    SET_HAS_PERMALINK,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    offerInfoPatern: {
        isFetching: false,
        data: {},
        errors: [],
    },
};

const proposalReviewProSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_OFFER_INFO_PATERN]: (state) => {
            state.offerInfoPatern = {
                ...state.offerInfoPatern,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_INFO_PATERN_FAILURE]: (state, action) => {
            state.offerInfoPatern = {
                ...state.offerInfoPatern,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_INFO_PATERN_SUCCESS]: (state, action) => {
            state.offerInfoPatern = {
                ...state.offerInfoPatern,
                data: action.payload,
                isFetching: false,
            };
        },
        [SET_HAS_APPROBED]: (state, action) => {
            state.offerInfoPatern.data.isApproved = action.payload;
        },
        [SET_HAS_PERMALINK]: (state, action) => {
            state.offerInfoPatern.data.hasPermalink = action.payload;
        },
    },
});

export const proposalReviewProActions = proposalReviewProSlice.actions;

export default proposalReviewProSlice.reducer;
