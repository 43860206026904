import API from '../..';
const ENTITY = 'irradiations';

export const createItem = (data) => API.post(`/api/v1/${ENTITY}/`, data);
export const deleteItem = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const getByStateAndSources = (data) =>
    API.get(`/api/v1/irradiations-by-source/`, {
        params: {
            political_division1: data?.political_division1,
            political_division2: data?.political_division2,
            source_list: data?.source_list,
        },
    });
export const getSources = () => API.get(`/api/v1/list-irradiations-sources/`);
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, data);
