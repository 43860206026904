import React from 'react';

import PropTypes from 'prop-types';

const IconAccessory = ({
    color = '#ffc107',
    height = '20px',
    width = '20px',
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 1C5 0.447715 5.44772 0 6 0C6.55228 0 7 0.447715 7 1V2H9V1C9 0.447715 9.44772 0 10 0C10.5523 0 11 0.447715 11 1V2H13V1C13 0.447715 13.4477 0 14 0C14.5523 0 15 0.447715 15 1V2H17C17.5523 2 18 2.44772 18 3V5H19C19.5523 5 20 5.44772 20 6C20 6.55228 19.5523 7 19 7H18V9H19C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11H18V13H19C19.5523 13 20 13.4477 20 14C20 14.5523 19.5523 15 19 15H18V17C18 17.5523 17.5523 18 17 18H15V19C15 19.5523 14.5523 20 14 20C13.4477 20 13 19.5523 13 19V18H11V19C11 19.5523 10.5523 20 10 20C9.44772 20 9 19.5523 9 19V18H7V19C7 19.5523 6.55229 20 6 20C5.44771 20 5 19.5523 5 19V18H3C2.44772 18 2 17.5523 2 17V15H1C0.447715 15 0 14.5523 0 14C0 13.4477 0.447715 13 1 13H2V11H1C0.447715 11 0 10.5523 0 10C0 9.44772 0.447715 9 1 9H2L2 7H1C0.447715 7 0 6.55229 0 6C0 5.44771 0.447715 5 1 5H2L2 3C2 2.44772 2.44772 2 3 2H5V1ZM4 4H7.5V7.58535C6.9174 7.79127 6.5 8.34689 6.5 9C6.5 9.82843 7.17157 10.5 8 10.5C8.82843 10.5 9.5 9.82843 9.5 9C9.5 8.34689 9.0826 7.79127 8.5 7.58535V4H16V16H13.5V10.4146C14.0826 10.2087 14.5 9.65311 14.5 9C14.5 8.17157 13.8284 7.5 13 7.5C12.1716 7.5 11.5 8.17157 11.5 9C11.5 9.65311 11.9174 10.2087 12.5 10.4146V16H4V14H5.58535C5.79127 14.5826 6.34689 15 7 15C7.82843 15 8.5 14.3284 8.5 13.5C8.5 12.6716 7.82843 12 7 12C6.34689 12 5.79127 12.4174 5.58535 13H4V4ZM13 9.5C13.2761 9.5 13.5 9.27614 13.5 9C13.5 8.72386 13.2761 8.5 13 8.5C12.7239 8.5 12.5 8.72386 12.5 9C12.5 9.27614 12.7239 9.5 13 9.5ZM7.5 9C7.5 8.72386 7.72386 8.5 8 8.5C8.27614 8.5 8.5 8.72386 8.5 9C8.5 9.27614 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.27614 7.5 9ZM7 13C6.72386 13 6.5 13.2239 6.5 13.5C6.5 13.7761 6.72386 14 7 14C7.27614 14 7.5 13.7761 7.5 13.5C7.5 13.2239 7.27614 13 7 13Z"
            fill={color}
        />
    </svg>
);

IconAccessory.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconAccessory;
