import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_CONSUMPTION_ENERGY,
    FETCH_CONSUMPTION_ENERGY_FAILURE,
    FETCH_CONSUMPTION_ENERGY_SUCCESS,
    FETCH_LOCATION,
    FETCH_LOCATION_ERROR,
    FETCH_LOCATION_SUCCESS,
    FETCH_PROPOSALS,
    FETCH_PROPOSALS_FAILURE,
    FETCH_PROPOSALS_SUCCESS,
    FETCH_REPORTS,
    FETCH_REPORTS_FAILURE,
    FETCH_REPORTS_SUCCESS,
    FETCH_SCHEDULE_RATE_CONFIGURATION,
    FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE,
    FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS,
    FETCH_SUMMARIES,
    FETCH_SUMMARIES_FAILURE,
    FETCH_SUMMARIES_SUCCESS,
    RESET,
    SET_IS_INITIALIZING,
    SET_IS_OPEN_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    consumptionEnergy: {
        data: {},
        errors: [],
        isFetching: false,
    },
    fetchLocation: {
        data: {},
        errors: [],
        isFetching: false,
    },
    proposals: {
        data: {
            data: [],
            pagination: null,
        },
        errors: [],
        isFetching: false,
    },
    reports: {
        data: {
            data: [],
            pagination: null,
        },
        errors: [],
        isFetching: false,
    },
    scheduleRateConfiguration: { data: {}, errors: [], isFetching: false },
    summaries: {
        data: {
            data: [],
            pagination: null,
        },
        errors: [],
        isFetching: false,
    },
    isInitializing: false,
    isOpenModal: false,
};

const quickViewProjectsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_CONSUMPTION_ENERGY]: (state) => {
            state.consumptionEnergy = {
                ...state.consumptionEnergy,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CONSUMPTION_ENERGY_FAILURE]: (state, action) => {
            state.consumptionEnergy = {
                ...state.consumptionEnergy,
                data: null,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONSUMPTION_ENERGY_SUCCESS]: (state, action) => {
            state.consumptionEnergy = {
                ...state.consumptionEnergy,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_LOCATION]: (state) => {
            state.fetchLocation.isFetching = true;
        },
        [FETCH_LOCATION_ERROR]: (state, action) => {
            state.fetchLocation = {
                ...state.fetchLocation,
                data: {},
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_LOCATION_SUCCESS]: (state, action) => {
            state.fetchLocation = {
                ...state.fetchLocation,
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [FETCH_PROPOSALS]: (state) => {
            state.proposals.isFetching = true;
        },
        [FETCH_PROPOSALS_FAILURE]: (state, action) => {
            state.proposals = {
                ...state.proposals,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSALS_SUCCESS]: (state, action) => {
            state.proposals = {
                ...state.proposals,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REPORTS]: (state) => {
            state.reports.isFetching = true;
        },
        [FETCH_REPORTS_FAILURE]: (state, action) => {
            state.reports = {
                ...state.reports,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REPORTS_SUCCESS]: (state, action) => {
            state.reports = {
                ...state.reports,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION]: (state) => {
            state.scheduleRateConfiguration = {
                ...state.scheduleRateConfiguration,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE]: (state, action) => {
            state.scheduleRateConfiguration = {
                ...state.scheduleRateConfiguration,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS]: (state, action) => {
            state.scheduleRateConfiguration = {
                ...state.scheduleRateConfiguration,
                errors: null,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUMMARIES]: (state) => {
            state.summaries.isFetching = true;
        },
        [FETCH_SUMMARIES_FAILURE]: (state, action) => {
            state.summaries = {
                ...state.summaries,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUMMARIES_SUCCESS]: (state, action) => {
            state.summaries = {
                ...state.summaries,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [SET_IS_INITIALIZING]: (state, action) => {
            state.isInitializing = action.payload;
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const quickViewProjectsActions = quickViewProjectsSlice.actions;

export default quickViewProjectsSlice.reducer;
