import { createSlice } from '@reduxjs/toolkit';

import { INITIALIZE_FORM } from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = { initialValues: { file: null } };

const fileUploaderSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
    },
});

export const fileUploaderActions = fileUploaderSlice.actions;

export default fileUploaderSlice.reducer;
