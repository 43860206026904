import React, { useState, useEffect } from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Tooltip, Typography } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';

import * as projectActions from '../actions';
import * as selectors from '../selectors';

import DropdownCustomCardMenuMap from './DropdownCustomCardMenuMap';
import ProjectActionsDropdown from './ProjectActionsDropdown';

const Wrapper = styled(Box)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 14px;
`,
);

const ProjectCardItem = ({
    canDelete,
    canModify,
    createdBy,
    date,
    documents,
    handleClickArchive,
    handleClickRename,
    handleClickView,
    id,
    isArchived,
    isSelected,
    rate,
    statusProject,
    statusProjectsToSelect,
    title,
    updateStatusProject,
}) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState({
        color: 'transparent',
        icon: null,
        key: '',
        label: t('Select status'),
        value: t('Select status'),
    });

    useEffect(() => {
        if (statusProject) {
            setSelected({
                color: statusProject.primary_color,
                key: statusProject.id,
                value: statusProject.name,
            });
        }
    }, []);

    const handleSelected = (status) => {
        if (status.key !== '') {
            setSelected(status);
            updateStatusProject(id, status.key, () => setSelected(selected));
        }
    };

    const createdAt = formatDate(
        parseDate(date, 'dd/MM/yyyy HH:mm:ss'),
        'dd MMM yyyy',
    );

    return (
        <Wrapper
            sx={{
                border: (theme) =>
                    `1px solid ${
                        isSelected ? theme.palette.primary.main : 'transparent'
                    }`,
                display: { xs: 'none', md: 'block' },
                width: '100%',
            }}
        >
            <Box display="flex" sx={{ cursor: 'pointer' }}>
                <Box
                    onClick={handleClickView}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        height: 30,
                        justifyContent: 'center',
                        mt: '5px',
                        position: 'relative',
                        width: 40,
                    }}
                >
                    <Tooltip
                        placement="top"
                        title={
                            documents > 0
                                ? t('{{count}} proposal', { count: documents })
                                : t('No proposals')
                        }
                    >
                        <FolderIcon
                            sx={{
                                color: 'rgba(181, 189, 193)',
                                fontSize: '2.5rem',
                            }}
                        />
                    </Tooltip>
                    <Typography
                        component="span"
                        fontWeight="bold"
                        sx={{
                            color: '#011e2e',
                            position: 'absolute',
                            top: '8px',
                        }}
                        variant="caption"
                    >
                        {documents ?? 0}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    onClick={handleClickView}
                    sx={{ width: '100%', ml: 1 }}
                >
                    <Tooltip placement="top" title={title}>
                        <Typography
                            fontWeight="bold"
                            noWrap
                            sx={{
                                fontSize: '14px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: { xs: '7rem' },
                            }}
                            variant="body2"
                        >
                            {title}
                        </Typography>
                    </Tooltip>

                    <Typography variant="body2">{rate}</Typography>
                </Box>
                <Box sx={{ ml: 'auto' }}>
                    <ProjectActionsDropdown
                        canDelete={canDelete}
                        canModify={canModify}
                        handleClickArchive={handleClickArchive}
                        handleClickRename={handleClickRename}
                        isArchived={isArchived}
                    />
                </Box>
            </Box>
            <Box py={2} mt={1}>
                <DropdownCustomCardMenuMap
                    canModify={canModify}
                    handleSelected={handleSelected}
                    selected={selected}
                    statusToSelect={statusProjectsToSelect}
                />
            </Box>
            <Box>
                <Typography variant="body2">
                    {t('Created at')} {createdAt}
                </Typography>
                <Typography variant="body2">
                    {t('By')} {createdBy}
                </Typography>
            </Box>
        </Wrapper>
    );
};

ProjectCardItem.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    createdBy: PropTypes.string,
    date: PropTypes.string,
    documents: PropTypes.number,
    handleClickArchive: PropTypes.func,
    handleClickRename: PropTypes.func,
    handleClickView: PropTypes.func,
    id: PropTypes.string,
    isArchived: PropTypes.bool,
    isSelected: PropTypes.bool,
    rate: PropTypes.string,
    statusProject: PropTypes.object,
    statusProjectsToSelect: PropTypes.array,
    title: PropTypes.string,
    updateStatusProject: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    statusProjectsToSelect: selectors.getStatusProjectToSelect,
});

const mapDispatchToProps = (dispatch) => ({
    updateStatusProject: (id, project_status, onFail) =>
        dispatch(
            projectActions.updateStatusProject(id, project_status, onFail),
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCardItem);
