import { getTemplates } from 'common/api/v1/templates';

import {
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { planConfigurationActions } from '../reducer';

export default (branchOffice) => (dispatch) => {
    dispatch(planConfigurationActions[FETCH_TEMPLATES]());

    getTemplates({
        branch_office_id: branchOffice,
        is_global: true,
        orderBy: 'title',
        perPage: 100,
        sortBy: 'asc',
        type: 0,
    })
        .then((response) => {
            dispatch(
                planConfigurationActions[FETCH_TEMPLATES_SUCCESS](
                    response.data.results,
                ),
            );
        })
        .catch((error) =>
            dispatch(
                planConfigurationActions[FETCH_TEMPLATES_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
