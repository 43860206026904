import { styled } from '@mui/material/styles';

export default styled('img')`
    border-radius: 50%;
    display: block;
    height: 100%;
    object-fit: contain;
    object-position: center;
    overflow: hidden;
    width: 100%;
`;
