import React from 'react';

import ReorderIcon from '@mui/icons-material/Reorder';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Card, Checkbox, IconButton, Typography } from 'sunwise-ui';

import { getIndicatorName } from '../../helpers';

const SorteableItem = ({ item, index, onSelectItem }) => {
    return (
        <Draggable draggableId={item.id} id={item.id} index={index}>
            {(provided) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Card.Body
                        sx={{ alignItems: 'center', display: 'flex', gap: 1 }}
                    >
                        <IconButton>
                            <ReorderIcon />
                        </IconButton>
                        <Typography variant="subtitle">
                            {getIndicatorName(item.name)}
                        </Typography>
                        <Box ml="auto">
                            <Checkbox
                                checked={item.is_active}
                                onChange={(e) =>
                                    onSelectItem(item.id, e.target.checked)
                                }
                            />
                        </Box>
                    </Card.Body>
                </Card>
            )}
        </Draggable>
    );
};

SorteableItem.propTypes = {
    index: PropTypes.number,
    item: PropTypes.object,
    onSelectItem: PropTypes.func,
};

export default SorteableItem;
