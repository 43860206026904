import { sendResetPasswordEmailService } from 'common/api/v1/auth';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SEND_EMAIL,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAILURE,
} from '../actionTypes';
import { forgotPasswordActions } from '../reducer';

const sendResetPasswordEmail = (data) => (dispatch) => {
    dispatch(forgotPasswordActions[SEND_EMAIL]());

    sendResetPasswordEmailService(data)
        .then((response) => {
            dispatch(forgotPasswordActions[SEND_EMAIL_SUCCESS](response.data));
        })
        .catch((error) => {
            dispatch(
                forgotPasswordActions[SEND_EMAIL_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};

export default sendResetPasswordEmail;
