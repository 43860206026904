import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Typography } from 'sunwise-ui';

import SortableItemContent from './SortableItemContent';

const StyledLi = styled('li')`
    padding: 8px;

    &:focus {
        outline: transparent !important;
    }
`;

const SortableItem = ({
    canModify,
    handleClickDelete,
    handleClickDuplicate,
    handleClickSelect,
    index,
    item,
    pageSize,
    refDictionary,
    selectedPageId,
}) => (
    <Draggable
        draggableId={item.id || 'tempPage'}
        index={index}
        isDragDisabled={!canModify}
    >
        {(provided, snapshot) => (
            <StyledLi
                className={`${item.type === 'group' && 'blocked'}`}
                tabIndex={0}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <Box
                    ref={(ref) => {
                        refDictionary[item.id] = ref;
                    }}
                >
                    {canModify && (
                        <Box alignItems="center" display="flex" sx={{ mb: 2 }}>
                            <UnfoldMoreIcon
                                fontSize="small"
                                sx={{ color: '#fff' }}
                            />

                            <Typography
                                fontWeight="bold"
                                sx={{ color: '#ffffff' }}
                                variant="caption"
                            >
                                {item.page}
                            </Typography>

                            <Box
                                alignItems="center"
                                display="flex"
                                gap="5px"
                                sx={{ ml: 'auto' }}
                            >
                                {item.type !== 'group' &&
                                    !snapshot.isDragging && (
                                        <FileCopyIcon
                                            fontSize="small"
                                            onClick={() =>
                                                handleClickDuplicate(item)
                                            }
                                            sx={{ color: '#ffffff' }}
                                        />
                                    )}

                                {!snapshot.isDragging && (
                                    <DeleteIcon
                                        fontSize="small"
                                        onClick={() =>
                                            handleClickDelete(
                                                item.id,
                                                item.type === 'page',
                                            )
                                        }
                                        sx={{ color: '#ffffff' }}
                                    />
                                )}
                            </Box>
                        </Box>
                    )}

                    <SortableItemContent
                        handleClickSelect={handleClickSelect}
                        index={index}
                        isDragging={snapshot}
                        item={item}
                        pageSize={pageSize}
                        selectedPageId={selectedPageId}
                    />
                </Box>
            </StyledLi>
        )}
    </Draggable>
);

SortableItem.propTypes = {
    canModify: PropTypes.bool,
    handleClickDelete: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickSelect: PropTypes.func,
    index: PropTypes.number,
    item: PropTypes.object,
    pageSize: PropTypes.string,
    refDictionary: PropTypes.object,
    selectedPageId: PropTypes.string,
};

export default SortableItem;
