import React, { useContext, useEffect, useState } from 'react';

import NearMeIcon from '@mui/icons-material/NearMe';
import { styled } from '@mui/material/styles';
import { Map } from 'google-maps-react';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useGeolocated } from 'react-geolocated';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, IconButton } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';
import {
    ItemGeocodeResult,
    ListGeocodeResult,
    WrapperGeocodeResult,
} from 'common/components/maps';
import { DEFAULT_LAT, DEFAULT_LNG } from 'common/constants';
import { GeneralContext } from 'common/utils/contexts';
import { getAddressData, handleOnChangeLatLng } from 'common/utils/helpers';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { FETCH_GEOCODE_SUCCESS } from '../actionTypes';
import { locationFormActions } from '../reducer';
import * as selectors from '../selectors';
import validate from '../validate';

const DEBOUNCE_TIME = 1000;

const MapWrapper = styled(Grid)`
    height: 300px;
    position: relative;
    & div:nth-of-type(-n + 1) {
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
`;

const Form = ({
    addressPlaceholder,
    center = { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
    fetchGeocodePoint,
    geocodePointData,
    geocodeResult,
    handleClickCancel,
    handleGeocode,
    initialValues,
    isDisabled,
    isFetchingGeocodePoint,
    isForLayout,
    onSubmit,
    setEmptyGeocodeResult,
    setShowGeocodeResults,
    showDetailedAddressFields,
    showGeocodeResults,
    zoom = 1,
}) => {
    const { t } = useTranslation();
    const { coords, isGeolocationEnabled } = useGeolocated({
        positionOptions: { enableHighAccuracy: false },
        userDecisionTimeout: 5000,
    });
    const { google } = useContext(GeneralContext);
    const [mapValue, setMapValue] = useState(null);
    const [marker, setMarker] = useState(null);
    const [timer, setTimer] = useState(null);
    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const [advanced, positionLatitude, positionLongitude] = watch([
        'extra_position_data.advanced',
        'position.latitude',
        'position.longitude',
    ]);

    useEffect(() => {
        reset(initialValues);
        if (!mapValue) return;

        mapValue.setTilt(0);

        const latlng = new google.maps.LatLng(
            positionLatitude ? positionLatitude : center.lat,
            positionLongitude ? positionLongitude : center.lng,
        );

        mapValue.setCenter(latlng);

        const tempMarker = new google.maps.marker.AdvancedMarkerElement({
            map: mapValue,
            position: latlng,
        });

        tempMarker.addListener('dragend', (evt) => {
            const lat = evt.latLng.lat();
            const lng = evt.latLng.lng();
            setPosition(lat, lng);
        });

        setMarker(tempMarker);
    }, [mapValue]);

    useEffect(() => {
        if (!isNil(geocodePointData)) fillAddressComponents(geocodePointData);
    }, [geocodePointData]);

    useEffect(() => {
        if (!marker || !marker?.position) return;

        const isLatitudeSame =
            positionLatitude === null ||
            positionLatitude === marker.position.lat;
        const isLongitudeSame =
            positionLongitude === null ||
            positionLongitude === marker.position.lng;

        if (isLatitudeSame && isLongitudeSame) return;

        const latlng = new google.maps.LatLng(
            positionLatitude,
            positionLongitude,
        );

        marker.position = latlng;
        mapValue.setCenter(latlng);
    }, [positionLatitude, positionLongitude, marker]);

    useEffect(() => () => clearTimeout(timer), [timer]);

    const setPosition = (lat, lng, fetch = true) => {
        setValue('position', {
            latitude: lat,
            longitude: lng,
            latlng: `${lat}, ${lng}`,
        });
        if (fetch) fetchGeocodePoint(lat, lng);
    };

    const handleOnChange = (e) => {
        setValue('title', e);
        clearTimeout(timer);
        setTimer(setTimeout(() => triggerChange(e), DEBOUNCE_TIME));
    };

    const triggerChange = (value) => {
        if (value.length < 6) {
            setEmptyGeocodeResult();
            return;
        }
        handleGeocode(value);
    };

    const handleOnCenterChanged = (_, map) => {
        const latlng = new google.maps.LatLng(
            map.center.lat(),
            map.center.lng(),
        );
        marker.position = latlng;
    };

    const handleOnPositionChanged = (_, map) => {
        const lat = map.center.lat();
        const lng = map.center.lng();
        setPosition(lat, lng);
    };

    const handleOnZoomChanged = (_, map) => {
        if (isNil(positionLatitude) && isNil(positionLongitude))
            handleOnPositionChanged(_, map);
    };

    const handleOnClickResult = (result) => {
        const { lat, lng } = result.geometry.location;
        setPosition(lat, lng, false);

        fillAddressComponents(result);
    };

    const fillAddressComponents = (data) => {
        const { formatted_address, address_components } = data || {};

        setValue('title', formatted_address);

        const {
            external_house_number,
            internal_house_number,
            neighborhood,
            street,
        } = getAddressData(address_components);

        setValue('extra_position_data.street', street);
        setValue(
            'extra_position_data.external_house_number',
            external_house_number,
        );
        setValue(
            'extra_position_data.internal_house_number',
            internal_house_number,
        );
        setValue('extra_position_data.neighborhood', neighborhood);
    };

    const inputVariant = isForLayout ? 'filled' : 'outlined';

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {showDetailedAddressFields && (
                <Box display="flex" justifyContent="end" sx={{ mb: 1 }}>
                    <ReactHookFormSwitch
                        control={control}
                        fullWidth
                        label={t('Advanced', { count: 2 })}
                        name="extra_position_data.advanced"
                    />
                </Box>
            )}

            <Grid container>
                <Grid item xs={18} lg={isForLayout ? 8 : 18}>
                    {geocodeResult &&
                        geocodeResult.length > 0 &&
                        showGeocodeResults && (
                            <WrapperGeocodeResult>
                                <ListGeocodeResult
                                    style={{
                                        transform: isForLayout
                                            ? 'translate3d(0px, 45px, 0px)'
                                            : 'translate3d(0px, 38px, 0px)',
                                    }}
                                >
                                    {geocodeResult.map((result, i) => (
                                        <ItemGeocodeResult
                                            key={`geocode-result-${i}`}
                                            onClick={() =>
                                                handleOnClickResult(result)
                                            }
                                        >
                                            {result.formatted_address}
                                        </ItemGeocodeResult>
                                    ))}
                                </ListGeocodeResult>
                            </WrapperGeocodeResult>
                        )}

                    <ReactHookFormInput
                        autoComplete="off"
                        color="secondary"
                        control={control}
                        disabled={isDisabled || isFetchingGeocodePoint}
                        label={t('Address')}
                        name="title"
                        onChange={(e) => handleOnChange(e.target.value)}
                        onBlur={() => {
                            setTimeout(() => setShowGeocodeResults(false), 150);
                        }}
                        onFocus={() => setShowGeocodeResults(true)}
                        placeholder={
                            addressPlaceholder ? addressPlaceholder : null
                        }
                        variant={inputVariant}
                    />

                    <Grid container>
                        <Grid item xs sx={{ display: 'flex' }}>
                            <ReactHookFormInput
                                control={control}
                                disabled={isDisabled}
                                fullWidth
                                onChange={(e) => {
                                    handleOnChangeLatLng({
                                        latField: 'position.latitude',
                                        lngField: 'position.longitude',
                                        setValue,
                                        value: e.target.value,
                                    });
                                }}
                                label={`${t('Latitude')}, ${t('Longitude')} (Ej: 19.43290, -99.1332)`}
                                name="position.latlng"
                                variant={inputVariant}
                            />

                            <IconButton
                                disabled={!isGeolocationEnabled}
                                onClick={() => {
                                    if (!isNil(coords))
                                        setPosition(
                                            coords.latitude,
                                            coords.longitude,
                                        );
                                }}
                                sx={{ mb: 2, ml: 2 }}
                            >
                                <NearMeIcon />
                            </IconButton>
                        </Grid>

                        {showDetailedAddressFields && advanced && (
                            <>
                                <Grid item xs={18}>
                                    <ReactHookFormInput
                                        control={control}
                                        disabled={isDisabled}
                                        fullWidth
                                        label={t('Street')}
                                        name="extra_position_data.street"
                                        variant={inputVariant}
                                    />
                                </Grid>

                                <Grid item xs={9}>
                                    <ReactHookFormInput
                                        control={control}
                                        disabled={isDisabled}
                                        fullWidth
                                        label={t('Exterior number')}
                                        name="extra_position_data.external_house_number"
                                        variant={inputVariant}
                                    />
                                </Grid>

                                <Grid item xs={9}>
                                    <ReactHookFormInput
                                        control={control}
                                        disabled={isDisabled}
                                        fullWidth
                                        label={t('Interior number')}
                                        name="extra_position_data.internal_house_number"
                                        variant={inputVariant}
                                    />
                                </Grid>

                                <Grid item xs={18}>
                                    <ReactHookFormInput
                                        control={control}
                                        disabled={isDisabled}
                                        fullWidth
                                        label={t('Neighborhood')}
                                        name="extra_position_data.neighborhood"
                                        variant={inputVariant}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={18} lg={isForLayout ? 10 : 18}>
                    <MapWrapper>
                        <Map
                            disableDefaultUI={isDisabled}
                            draggable={!isDisabled}
                            fullscreenControl={true}
                            google={google}
                            initialCenter={center}
                            mapType="roadmap"
                            mapTypeControl={true}
                            onCenterChanged={handleOnCenterChanged}
                            onDragend={handleOnPositionChanged}
                            onReady={(_, map) => {
                                map.setOptions({
                                    mapId: import.meta.env
                                        .VITE_GOOGLE_MAPS_DEFAULT_ID,
                                });
                                setMapValue(map);
                            }}
                            onZoomChanged={handleOnZoomChanged}
                            readOnly={isDisabled}
                            rotateControl={false}
                            scaleControl={true}
                            streetViewControl={false}
                            zoom={zoom}
                        />
                    </MapWrapper>
                </Grid>
            </Grid>

            <Box
                display="flex"
                flexDirection={{ md: 'row', xs: 'column' }}
                justifyContent={{ md: 'right', xs: 'center' }}
                mt={2}
            >
                <Button
                    color="secondary"
                    onClick={handleClickCancel}
                    sx={{
                        mr: { md: 2, xs: 0 },
                        order: { md: 1, xs: 2 },
                        width: { md: 'auto', xs: '100%' },
                    }}
                    variant="text"
                >
                    {t('Cancel')}
                </Button>

                <Button
                    color="secondary"
                    disabled={isDisabled}
                    sx={{
                        mb: { md: 0, xs: 2 },
                        order: { md: 2, xs: 1 },
                        width: { md: 'auto', xs: '100%' },
                    }}
                    type="submit"
                    variant="outlined"
                >
                    {t('Save')}
                </Button>
            </Box>
        </form>
    );
};

Form.propTypes = {
    addressPlaceholder: PropTypes.string,
    center: PropTypes.object,
    fetchGeocodePoint: PropTypes.func,
    geocodePointData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    geocodeResult: PropTypes.array,
    handleClickCancel: PropTypes.func,
    handleGeocode: PropTypes.func,
    initialValues: PropTypes.object,
    isDisabled: PropTypes.bool,
    isFetchingGeocodePoint: PropTypes.bool,
    isForLayout: PropTypes.bool,
    onSubmit: PropTypes.func,
    setEmptyGeocodeResult: PropTypes.func,
    setShowGeocodeResults: PropTypes.func,
    showDetailedAddressFields: PropTypes.bool,
    showGeocodeResults: PropTypes.bool,
    zoom: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
    geocodePointData: selectors.getGeocodePointData,
    isFetchingGeocodePoint: selectors.getIsFetchingGeocodePoint,
    showGeocodeResults: selectors.showGeocodeResults,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchGeocodePoint: (lat, lng) =>
        dispatch(actions.fetchGeocodePoint(lat, lng, ownProps.name)),
    setEmptyGeocodeResult: () =>
        dispatch(
            locationFormActions[FETCH_GEOCODE_SUCCESS]({
                name: ownProps?.name,
                payload: [],
            }),
        ),
    setShowGeocodeResults: (value) =>
        dispatch(actions.setShowGeocodeResults(value, ownProps.name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
