import { styled } from '@mui/material/styles';

export default styled('div')`
    height: ${({ height = '100%' }) => height};
    position: relative;
    width: ${({ width = '100%' }) => width};
    & div:nth-of-type(-n + 1) {
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }

    &.unclickable {
        pointer-events: none;

        .gmnoprint a,
        .gmnoprint span,
        .gm-style-cc {
            display: none;
        }
        .gmnoprint div {
            background: none !important;
        }
    }

    .popup-container {
        cursor: pointer;
        height: 0;
        position: absolute;
        transform-origin: 0% 0%;
        width: 200px;
        z-index: 1;

        &:hover {
            z-index: 2;
        }

        .popup-bubble-anchor {
            bottom: 6px;
            left: 0;
            position: absolute;
            width: 100%;

            span {
                background-color: #002438;
                border-radius: 2px;
                color: #ffffff;
                font-size: 8px;
                font-weight: 100;
                height: 13px;
                left: 0;
                letter-spacing: 0.2px;
                line-height: 13px;
                max-height: 60px;
                overflow-y: hidden;
                padding: 0 2px;
                position: absolute;
                top: 0;
                transform: translate(-50%, -100%);
                user-select: none;
            }
        }
    }

    & > div {
        height: 100%;
        position: absolute;
        width: 100%;
    }
`;
