import React from 'react';

import PropTypes from 'prop-types';
import { Box, Skeleton, Typography } from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';

const TotalWrapper = ({
    currencyIso,
    currencyLocale,
    dot,
    isFetching,
    isHidden = false,
    numericalSign,
    title,
    value,
}) => {
    if (isHidden) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' },
                alignItems: { xs: 'start', lg: 'center' },
                justifyContent: { xs: 'center', lg: 'start' },
                width: '100%',
                p: 2,
            }}
        >
            <Box display="flex" alignItems="center" gap={2}>
                <Box sx={{ width: '24px', height: '24px' }}>{dot}</Box>
                <Typography fontWeight="bold" variant="body2">
                    {title && <span>{title}</span>}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: { xs: 2, lg: 1 },
                    ml: { xs: 0, lg: 'auto' },
                    mr: '8px',
                }}
            >
                <Box
                    sx={{ display: { xs: 'block', lg: 'none' }, width: '24px' }}
                />
                {isFetching ? (
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: '1rem',
                            width: 200,
                        }}
                    />
                ) : (
                    <Typography
                        variant="body2"
                        sx={{ fontWeight: { xs: 'normal', lg: 'bold' } }}
                    >
                        {numericalSign && <span>{numericalSign}</span>}{' '}
                        {numberFormat(value, {
                            currency: currencyIso,
                            decimals: 2,
                            locale: currencyLocale,
                            style: 'currency',
                        })}
                    </Typography>
                )}
                <Box sx={{ width: '16px' }} />
            </Box>
        </Box>
    );
};

TotalWrapper.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    dot: PropTypes.object,
    isFetching: PropTypes.bool,
    isHidden: PropTypes.bool,
    numericalSign: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.number,
};

export default TotalWrapper;
