import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from 'sunwise-ui';

const TextCaptcha = ({ isValid, onValidate, textToValidate }) => {
    const { t } = useTranslation();
    const [text, setText] = useState('');

    useEffect(() => {
        if ((text === textToValidate) !== isValid)
            onValidate(text === textToValidate);
    }, [text]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={1}
            mt={1}
            sx={{ width: '100%' }}
        >
            <Typography variant="caption">
                {t(
                    'Type the word {{textToValidate}} to confirm the operation',
                    { textToValidate },
                )}
            </Typography>
            <TextField
                fullWidth
                value={text}
                onChange={(event) => setText(event.target.value)}
            />
        </Box>
    );
};

TextCaptcha.propTypes = {
    isValid: PropTypes.bool,
    onValidate: PropTypes.func,
    textToValidate: PropTypes.string,
};

export default TextCaptcha;
