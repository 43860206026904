import { getItems } from 'common/api/v1/supplierUtility';

import {
    FETCH_SUPPLIER_UTILITIES,
    FETCH_SUPPLIER_UTILITIES_FAILURE,
    FETCH_SUPPLIER_UTILITIES_SUCCESS,
} from '../actionTypes';
import { ratesActions } from '../reducer';

export default (countryId) => (dispatch) => {
    dispatch(ratesActions[FETCH_SUPPLIER_UTILITIES]());
    getItems(countryId)
        .then((response) =>
            dispatch(
                ratesActions[FETCH_SUPPLIER_UTILITIES_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(ratesActions[FETCH_SUPPLIER_UTILITIES_FAILURE](error)),
        );
};
