import React from 'react';

import PropTypes from 'prop-types';
import { Skeleton } from 'sunwise-ui';

const Placeholder = <Skeleton variant="text" sx={{ fontSize: '1rem' }} />;

const PlaceholderText = ({ children, ready }) => (
    <>{ready ? Placeholder : children}</>
);

PlaceholderText.propTypes = {
    children: PropTypes.object,
    ready: PropTypes.bool,
};

export default PlaceholderText;
