import { NAME } from './constants';

export const DOWNLOAD_MONITORING_CSV = `${NAME}/DOWNLOAD_MONITORING_CSV`;
export const DOWNLOAD_MONITORING_CSV_FAILURE = `${NAME}/DOWNLOAD_MONITORING_CSV_FAILURE`;
export const DOWNLOAD_MONITORING_CSV_SUCCESS = `${NAME}/DOWNLOAD_MONITORING_CSV_SUCCESS`;

export const FETCH_CONTACTS = `${NAME}/FETCH_CONTACTS`;
export const FETCH_CONTACTS_FAILURE = `${NAME}/FETCH_CONTACTS_FAILURE`;
export const FETCH_CONTACTS_SUCCESS = `${NAME}/FETCH_CONTACTS_SUCCESS`;

export const FETCH_INDICATORS_DATA = `${NAME}/FETCH_INDICATORS_DATA`;
export const FETCH_INDICATORS_DATA_FAILURE = `${NAME}/FETCH_INDICATORS_DATA_FAILURE`;
export const FETCH_INDICATORS_DATA_SUCCESS = `${NAME}/FETCH_INDICATORS_DATA_SUCCESS`;

export const FETCH_MONITORING_DATA = `${NAME}/FETCH_MONITORING_DATA`;
export const FETCH_MONITORING_DATA_FAILURE = `${NAME}/FETCH_MONITORING_DATA_FAILURE`;
export const FETCH_MONITORING_DATA_SUCCESS = `${NAME}/FETCH_MONITORING_DATA_SUCCESS`;

export const FETCH_POLITICAL_DIVISIONS = `${NAME}/FETCH_POLITICAL_DIVISIONS`;
export const FETCH_POLITICAL_DIVISIONS_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS_FAILURE`;
export const FETCH_POLITICAL_DIVISIONS_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS_SUCCESS`;

export const FETCH_PROJECT_BY_ID = `${NAME}/FETCH_PROJECT_BY_ID`;
export const FETCH_PROJECT_BY_ID_FAILURE = `${NAME}/FETCH_PROJECT_BY_ID_FAILURE`;
export const FETCH_PROJECT_BY_ID_SUCCESS = `${NAME}/FETCH_PROJECT_BY_ID_SUCCESS`;

export const FETCH_RATES = `${NAME}/FETCH_RATES`;
export const FETCH_RATES_FAILURE = `${NAME}/FETCH_RATES_FAILURE`;
export const FETCH_RATES_SUCCESS = `${NAME}/FETCH_RATES_SUCCESS`;

export const INITIALIZE_FILTERS = `${NAME}/INITIALIZE_FILTERS`;

export const RESET = `${NAME}/RESET`;

export const SET_IS_OPEN_FILTERS_MODAL = `${NAME}/SET_IS_OPEN_FILTERS_MODAL`;
