import BaseModel from './BaseModel';

export default class Currency extends BaseModel {}

Currency.modelName = 'Currency';
Currency.prepareFields({
    abbreviation: 'abbreviation',
    id: 'id',
    name: 'name',
    symbol: 'symbol',
});
