import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteConsumptionProfile from './deleteConsumptionProfile';

export default (id, consumptionProfileName) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [
            i18next.t(
                'Are you sure to delete the "{{consumptionProfileName}}" consumption profile?',
                { consumptionProfileName },
            ),
        ],
        onSuccess: () => dispatch(deleteConsumptionProfile(id)),
        title: i18next.t('Delete consumption profile'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
