import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import { URL_TYPES } from 'common/constants';
import setShowLisaLoading from 'common/modules/lisaFiles/actions/setShowLisaLoading';

import { getEnergyFields, updateEnergyValuesByPeriod } from '../helpers';

export default ({
        fileInfo,
        fileOrigin,
        getValues,
        rateConfiguration,
        ratesDictionary,
        setValue,
    }) =>
    (dispatch) => {
        dispatch(setShowLisaLoading(false));
        const rate = getValues('rate');
        if (isEmpty(fileInfo) || !rate) return;

        const { tiers_energy_distribution } = rateConfiguration || {};

        let newSummary = cloneDeep(getValues('summary'));

        const hourlyFieldsFromLisa = getEnergyFields({
            demand: fileInfo?.completion?.values?.demand,
            energy: fileInfo?.completion?.values?.energy,
            final_date: newSummary[fileOrigin].final_date,
            initial_date: newSummary[fileOrigin].initial_date,
            rate: ratesDictionary[rate],
            tiers_energy_distribution,
        });

        newSummary[fileOrigin] = {
            ...newSummary[fileOrigin],
            ...hourlyFieldsFromLisa,
            file: fileInfo?.file,
            power_factor: fileInfo?.completion?.values?.fp,
            predicted: 0,
            season_change_pdf: fileInfo?.completion?.values?.season_change,
            url_type: fileInfo.url_type ?? URL_TYPES.LISA,
        };

        updateEnergyValuesByPeriod({ newSummary, index: fileOrigin, setValue });
    };
