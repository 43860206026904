import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Typography } from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';

import CreateSourceModal from './CreateSourceModal';
import TableConfig from './TableConfig';

const OwnSourceCard = ({
    availableSources,
    canDelete,
    canModify,
    control,
    countryCurrencyLocale,
    defaultSourceData,
    errors,
    getAvg,
    getValues,
    handleClickDelete,
    handleClickSave,
    handleSubmit,
    initValues,
    isOpenModal,
    setValue,
    source,
    toggleModal,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Card>
                <Card.Header>
                    <TitleWithDetail variant="body2">
                        {t('Own source')}
                    </TitleWithDetail>
                    <Typography variant="caption">
                        {t('Generation source')}: {t('Custom')}
                    </Typography>
                </Card.Header>
                <Card.Body>
                    {source.length > 0 && (
                        <Box sx={{ mb: 2 }}>
                            <TableConfig
                                availableSources={availableSources}
                                countryCurrencyLocale={countryCurrencyLocale}
                                getAvg={getAvg}
                                sourceData={source}
                            />
                        </Box>
                    )}
                    <Box sx={{ pt: 2, textAlign: 'right' }}>
                        <Button
                            onClick={() => {
                                let dataSource = defaultSourceData;
                                let isNewRecord = true;

                                if (source.length > 0) {
                                    dataSource = source;
                                    isNewRecord = false;
                                }

                                initValues(
                                    getValues(),
                                    dataSource,
                                    isNewRecord,
                                );

                                if (!isNewRecord) {
                                    const increment =
                                        (source[0]
                                            .monthly_solar_radiation_hrsol[0]
                                            .average *
                                            100) /
                                        defaultSourceData[0]
                                            .monthly_solar_radiation_hrsol[0]
                                            .average;

                                    setTimeout(() => {
                                        setValue(
                                            'saved_by',
                                            source[0].saved_by,
                                        );
                                        setValue('increment', increment);
                                    }, 10);
                                }

                                toggleModal(true);
                            }}
                            visible={canModify}
                        >{`${source.length === 0 ? t('Create') : t('Edit')} ${t(
                            'Own source',
                        ).toLowerCase()}`}</Button>
                    </Box>
                </Card.Body>
            </Card>

            <CreateSourceModal
                defaultSourceData={defaultSourceData}
                availableSources={availableSources}
                canDelete={canDelete}
                canModify={canModify}
                control={control}
                countryCurrencyLocale={countryCurrencyLocale}
                errors={errors}
                getAvg={getAvg}
                getValues={getValues}
                handleClickDelete={handleClickDelete}
                handleClickSave={handleClickSave}
                handleSubmit={handleSubmit}
                isNew={source.length === 0 ? true : false}
                isOpenModal={isOpenModal}
                onHide={() => toggleModal(false)}
                setValue={setValue}
                source={source}
            />
        </>
    );
};

OwnSourceCard.propTypes = {
    availableSources: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    control: PropTypes.object,
    countryCurrencyLocale: PropTypes.string,
    defaultSourceData: PropTypes.array,
    errors: PropTypes.array,
    getAvg: PropTypes.func,
    getValues: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    initValues: PropTypes.func,
    isOpenModal: PropTypes.bool,
    setValue: PropTypes.func,
    source: PropTypes.array,
    toggleModal: PropTypes.func,
};

export default OwnSourceCard;
