import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import showToast from 'common/utils/showToast';

const buildConnectionToast =
    ({ offlineToast, setOfflineToast, t } = {}) =>
    (evt) => {
        if (evt.type === 'online') {
            if (offlineToast && toast.isActive(offlineToast)) {
                toast.dismiss(offlineToast);
            }
            setTimeout(() => {
                showToast({
                    autoClose: 3000,
                    body: t(
                        'Now you can save your changes. Your connection has been reestablished',
                    ),
                    title: t('Online'),
                    type: 'online',
                });
            }, 300);
        } else {
            setOfflineToast(
                showToast({
                    autoClose: false,
                    body: t(
                        'You will not be able to save changes until your connection is reestablished',
                    ),
                    closeOnClick: false,
                    draggable: false,
                    title: t('Offline'),
                    type: 'offline',
                }),
            );
        }
    };

const OnLineListener = () => {
    const { t } = useTranslation();
    const [offlineToast, setOfflineToast] = useState(null);

    useEffect(() => {
        const handlerToast = buildConnectionToast({
            offlineToast,
            setOfflineToast,
            t,
        });
        window.addEventListener('offline', handlerToast);
        window.addEventListener('online', handlerToast);
        return () => {
            window.removeEventListener('offline', handlerToast);
            window.removeEventListener('online', handlerToast);
        };
    }, [offlineToast, setOfflineToast]);

    return null;
};

export default OnLineListener;
