import React from 'react';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
    Badge,
    Box,
    Card,
    Grid,
    IconButton,
    Link,
    Tooltip,
    Typography,
} from 'sunwise-ui';

import { STATUS } from 'common/constants';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';
import {
    formatDate,
    parseDate,
    differenceInDaysDate,
} from 'common/utils/dates';
import { handleFileURL } from 'common/utils/helpers';

import Share from './Share';

const getCardStyles = ({ days, status }) => {
    const common = { borderLeft: '6px solid', borderRadius: '6px' };
    if (status === 'Cerrado' || status === 'Cancelado')
        return { ...common, borderColor: '#f8f8f86b' };
    if (days < 10) return { ...common, borderColor: '#00ff006b' };
    if (days >= 10 && days < 15) return { ...common, borderColor: '#ffa5006b' };
    if (days >= 15) return { ...common, borderColor: '#ff00006b' };
};

const getDate = (requested_at) => {
    const date = !requested_at ? '01/01/1970 00:00:00' : requested_at;
    return parseDate(date, 'dd/MM/yyyy HH:mm:ss');
};

const getPreviewId = (permalink) => {
    if (!permalink) return null;
    const splited = permalink.split('/');
    return splited?.[splited.length - 2] || null;
};

const DateComponent = styled(Box)`
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    text-transform: capitalize;
`;

const LogoCompany = styled(Box)`
    align-items: center;
    display: flex;

    figure {
        align-items: center;
        border-radius: 100%;
        border: 1px solid #ecedf0;
        display: inline-flex;
        height: 32px;
        justify-content: center;
        min-height: 32px;
        min-width: 32px;
        overflow: hidden;
        text-align: center;
        width: 32px;

        img {
            height: 100%;
            object-fit: contain;
            width: 100%;
        }
    }

    span {
        font-size: 11px;
        font-weight: 500;
        line-height: 13px;
        min-height: 13px;
    }
`;

const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: -5,
        padding: '5px',
    },
}));

const StyledCard = styled(Card)`
    font-size: 12px;
    margin-bottom: 8px;
    padding: 16px;
    width: 100%;
`;

const Title = styled(Link)`
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
`;

const ItemCard = ({
    canModify,
    handleToggleDrawer,
    project,
    setSelectedCreditItem,
    setProjectId,
    status,
}) => {
    const { t } = useTranslation();
    const {
        badge,
        contact,
        credit_application,
        credit_item,
        currency,
        financier: { image: financierImage = null },
        has_credit_close,
        has_request_buro,
        id,
        is_archived = false,
        permalink,
        project_name,
        proposal_name,
        rate_name,
        requested_at,
        status: proposalStatus,
        system_size,
        types_business_company,
    } = project;

    const imgSrc =
        financierImage === null ? (
            <PersonIcon color="primary" variant="outlined" fontSize="small" />
        ) : (
            <img
                alt="financier"
                src={handleFileURL(
                    financierImage,
                    import.meta.env.VITE_S3_MEDIA_PATH,
                )}
                width="26"
            />
        );

    const paseDateRequestedAt = getDate(requested_at);
    const date = formatDate(paseDateRequestedAt, 'PP');
    const today = new Date();
    const days = differenceInDaysDate(today, paseDateRequestedAt);
    const cardStyles = getCardStyles({ days, status });
    const previewId = getPreviewId(permalink);
    const isGenerated = proposalStatus === COMMERCIAL_OFFER_STATUS.FINISHED.key;
    const creditStatus = get(credit_application, 'status', null);
    const isQuoted = creditStatus === STATUS.QUOTED_STATUS.key;
    const isDisabledButton =
        !canModify ||
        !isGenerated ||
        is_archived ||
        (isQuoted && has_credit_close);

    const buildLink = (previewId) => {
        if (!previewId) return { to: '#' };
        return { to: `/proposal-review-pro/${previewId}` };
    };

    return (
        <StyledCard sx={cardStyles}>
            <Grid container sx={{ mb: 1 }}>
                <Grid item xs>
                    <Title
                        color="inherit"
                        component={RouterLink}
                        {...buildLink(previewId)}
                        underline="none"
                    >
                        {proposal_name}
                    </Title>

                    <Box>
                        <i>
                            {t('Project')}: {project_name}
                        </i>
                    </Box>

                    <Box>{contact.agent_name}</Box>

                    <Typography fontSize="12px">
                        {t('Rate')}: <strong>{rate_name}</strong>
                    </Typography>

                    <Box>
                        {t('System size')}: {system_size} kW
                    </Box>
                </Grid>

                {credit_item && (
                    <Grid item sx={{ ml: 'auto' }}>
                        <IconButton
                            disabled={isDisabledButton}
                            onClick={() => {
                                handleToggleDrawer(true);
                                setProjectId(project.project_id);
                                setSelectedCreditItem({
                                    ...credit_item,
                                    creditAppId: id,
                                    currency,
                                    offerId: previewId,
                                    status,
                                    hasRequestBureau: has_request_buro,
                                    typesBusiness: types_business_company,
                                });
                            }}
                        >
                            <StyledBadge
                                color="warning"
                                invisible={!badge}
                                variant="dot"
                            >
                                <ArrowCircleRightIcon />
                            </StyledBadge>
                        </IconButton>
                    </Grid>
                )}
            </Grid>

            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs>
                    <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
                        <LogoCompany>
                            <figure>{imgSrc}</figure>
                        </LogoCompany>

                        <Tooltip
                            title={`${t('Elapsed days')}: ${
                                requested_at ? days : '---'
                            }`}
                        >
                            <DateComponent
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    gap: 1,
                                    textAlign: 'right',
                                }}
                            >
                                <CalendarTodayIcon />

                                {requested_at ? date : '---'}
                            </DateComponent>
                        </Tooltip>

                        <Share permalink={permalink} />
                    </Box>
                </Grid>
            </Grid>
        </StyledCard>
    );
};

ItemCard.propTypes = {
    canModify: PropTypes.bool,
    handleToggleDrawer: PropTypes.func,
    project: PropTypes.object,
    setSelectedCreditItem: PropTypes.func,
    setProjectId: PropTypes.func,
    status: PropTypes.number,
};

export default ItemCard;
