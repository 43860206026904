import { INITIALIZE_RATE_TAXES_FORM } from '../actionTypes';
import { actions } from '../reducer';

export default (data) => (dispatch) => {
    const values = {
        apply_desired_power_factor: data?.apply_desired_power_factor || false,
        dap_value: data?.dap_value || 0,
        desired_power_factor: data?.desired_power_factor || 90,
        has_low_tension_concept: data?.has_low_tension_concept || false,
        increase_percentage: data?.increase_percentage || 0,
        tax_rate_percentage: data?.tax_rate_percentage || 0,
    };

    dispatch(actions[INITIALIZE_RATE_TAXES_FORM](values));
};
