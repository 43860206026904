import { createSlice } from '@reduxjs/toolkit';

import {
    CHANGE_MODULE_STATUS,
    CHANGE_MODULE_STATUS_FAILURE,
    CHANGE_MODULE_STATUS_SUCCESS,
    CHECK_SEGMENT,
    CHECK_SEGMENT_FAILURE,
    CHECK_SEGMENT_SUCCESS,
    CREATE_SEGMENT,
    CREATE_SEGMENT_FAILURE,
    CREATE_SEGMENT_SUCCESS,
    DELETE_SEGMENT,
    DELETE_SEGMENT_FAILURE,
    DELETE_SEGMENT_SUCCESS,
    FETCH_IRRADIATION_DATA,
    FETCH_IRRADIATION_DATA_FAILURE,
    FETCH_IRRADIATION_DATA_SUCCESS,
    FETCH_OFFER_INFO_PATERN,
    FETCH_OFFER_INFO_PATERN_FAILURE,
    FETCH_OFFER_INFO_PATERN_SUCCESS,
    FETCH_OFFER_PANELS,
    FETCH_OFFER_PANELS_FAILURE,
    FETCH_OFFER_PANELS_SUCCESS,
    FETCH_PARENT_FIELD_SEGMENT,
    FETCH_PARENT_FIELD_SEGMENT_FAILURE,
    FETCH_PARENT_FIELD_SEGMENT_SUCCESS,
    INITIALIZE_SHADER_SETTINGS_VALUES,
    INITIALIZE_VALUES,
    RESET_FORM,
    RESET_VALUES,
    SAVE_IMAGE,
    SAVE_IMAGE_FAILURE,
    SAVE_IMAGE_SUCCESS,
    SAVE_SHADER_SETTINGS_SUCCESS,
    SELECT_SEGMENT,
    SET_COPY_BUFFER,
    SET_DRAWING_MODE,
    SET_DRAWING_TYPE,
    SET_IS_DISABLING_MODULES,
    SET_SEGMENT_FILL_METHOD,
    SET_SELECTED_ITEMS,
    SETUP_SIMULATION,
    UPDATE_SEGMENT,
    UPDATE_SEGMENT_FAILURE,
    UPDATE_SEGMENT_SUCCESS,
    UPDATE_SEGMENTS,
    UPDATE_SOLAR_MODULES_DESIGN_NUMBER,
    INITIALIZE_IRRADIATION_VALUES,
} from './actionTypes';
import { NAME, POLYGON_SEGMENT } from './constants';

const datetime = new Date();

const SHADER_SETTINGS_DEFAULT_VALUES = {
    date: `${datetime.getDate()}/${
        datetime.getMonth() + 1
    }/${datetime.getFullYear()}`,
    day: datetime.getDate(),
    hour: datetime.getHours(),
    minutes: datetime.getMinutes(),
    month: datetime.getMonth() + 1,
    timezone: `${(-1 * datetime.getTimezoneOffset()) / 60}`,
    year: datetime.getFullYear(),
};

const INITIAL_STATE = {
    changeModuleStatus: { data: {}, errors: null, isSaving: false },
    checkSegment: { data: {}, errors: null, isSaving: false },
    copyBuffer: [],
    createSegment: { data: {}, errors: null, isSaving: false },
    currentProjectLocation: { data: {} },
    deleteSegment: { errors: null, isDeleting: false },
    drawingMode: false,
    drawingType: POLYGON_SEGMENT,
    fetchParentFieldSegment: {
        data: {
            location: null,
            segments: [],
            solarModulesDesignNumber: 0,
            solarModulesOfferNumber: 0,
        },
        errors: [],
        isFetching: false,
    },
    irradiation: { data: [], errors: null, isFetching: false },
    isDisablingModules: false,
    initialIrradiationValues: {
        bifaciality: false,
        bifaciality_param: '90',
        losses: '25',
        source: '',
    },
    initialValues: {},
    offerInfoPatern: { data: {}, errors: [], isFetching: false },
    offerPanels: {
        counters: { assigned: 0, available: 0 },
        data: [],
        errors: [],
        isFetching: true,
    },
    saveImage: { data: {}, errors: [], isSaving: false },
    saveShaderSettings: {
        data: SHADER_SETTINGS_DEFAULT_VALUES,
        errors: null,
        isSaving: false,
    },
    segmentFillMethod: true,
    selectedItems: [],
    selectedSegment: null,
    shaderSettingsInitialValues: SHADER_SETTINGS_DEFAULT_VALUES,
    simulation: { interval: null, isPlaying: false },
    updateSegment: { data: {}, errors: null, isSaving: false },
};

const panelLayoutV2Slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [CHANGE_MODULE_STATUS]: (state) => {
            state.changeModuleStatus.isSaving = true;
        },
        [CHANGE_MODULE_STATUS_FAILURE]: (state, action) => {
            state.changeModuleStatus = {
                ...state.changeModuleStatus,
                errors: action.payload,
                isSaving: false,
            };
        },
        [CHANGE_MODULE_STATUS_SUCCESS]: (state, action) => {
            state.changeModuleStatus = {
                ...state.changeModuleStatus,
                data: action.payload,
                errors: [],
                isSaving: false,
            };
        },
        [CHECK_SEGMENT]: (state) => {
            state.checkSegment.isSaving = true;
        },
        [CHECK_SEGMENT_FAILURE]: (state, action) => {
            state.checkSegment = {
                ...state.checkSegment,
                errors: action.payload,
                isSaving: false,
            };
        },
        [CHECK_SEGMENT_SUCCESS]: (state, action) => {
            state.checkSegment = {
                ...state.checkSegment,
                data: action.payload,
                errors: [],
                isSaving: false,
            };
        },
        [CREATE_SEGMENT]: (state) => {
            state.createSegment.isSaving = true;
        },
        [CREATE_SEGMENT_FAILURE]: (state, action) => {
            state.createSegment = {
                ...state.createSegment,
                errors: action.payload,
                isSaving: false,
            };
        },
        [CREATE_SEGMENT_SUCCESS]: (state, action) => {
            state.drawingMode = false;
            state.createSegment = {
                ...state.createSegment,
                data: action.payload,
                errors: [],
                isSaving: false,
            };
        },
        [DELETE_SEGMENT]: (state) => {
            state.deleteSegment.isDeleting = true;
        },
        [DELETE_SEGMENT_FAILURE]: (state, action) => {
            state.deleteSegment = {
                ...state.deleteSegment,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_SEGMENT_SUCCESS]: (state) => {
            state.selectedSegment = null;
            state.deleteSegment = {
                ...state.deleteSegment,
                errors: [],
                isDeleting: false,
            };
        },
        [FETCH_OFFER_INFO_PATERN]: (state) => {
            state.offerInfoPatern = {
                ...state.offerInfoPatern,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_INFO_PATERN_FAILURE]: (state, action) => {
            state.offerInfoPatern = {
                ...state.offerInfoPatern,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_INFO_PATERN_SUCCESS]: (state, action) => {
            state.offerInfoPatern = {
                ...state.offerInfoPatern,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_IRRADIATION_DATA]: (state) => {
            state.irradiation = {
                ...state.irradiation,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_IRRADIATION_DATA_FAILURE]: (state, action) => {
            state.irradiation = {
                ...state.irradiation,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_IRRADIATION_DATA_SUCCESS]: (state, action) => {
            state.irradiation = {
                ...state.irradiation,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_PANELS]: (state) => {
            state.offerPanels = {
                ...state.offerPanels,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_PANELS_FAILURE]: (state, action) => {
            state.offerPanels = {
                ...state.offerPanels,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_PANELS_SUCCESS]: (state, action) => {
            state.offerPanels = {
                ...state.offerPanels,
                counters: action.payload.counters,
                data: action.payload.panels,
                isFetching: false,
            };
        },
        [FETCH_PARENT_FIELD_SEGMENT]: (state) => {
            state.fetchParentFieldSegment = {
                ...state.fetchParentFieldSegment,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PARENT_FIELD_SEGMENT_FAILURE]: (state, action) => {
            state.fetchParentFieldSegment = {
                ...state.fetchParentFieldSegment,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PARENT_FIELD_SEGMENT_SUCCESS]: (state, action) => {
            state.fetchParentFieldSegment = {
                ...state.fetchParentFieldSegment,
                data: action.payload,
                isFetching: false,
            };
            state.currentProjectLocation.data = action.payload.location;

            let newShadderSettings = {};

            const newDateTime = new Date();

            newShadderSettings = {
                day: newDateTime.getDate(),
                hour: newDateTime.getHours(),
                minutes: newDateTime.getMinutes(),
                month: newDateTime.getMonth() + 1,
                timezone: `${(-1 * datetime.getTimezoneOffset()) / 60}`,
                year: newDateTime.getFullYear(),
            };

            state.shaderSettingsInitialValues = {
                ...state.shaderSettingsInitialValues,
                id: action.payload.id,
                lat: action.payload.location.latitude,
                lng: action.payload.location.longitude,
                ...newShadderSettings,
            };
            state.saveShaderSettings = {
                ...state.saveShaderSettings,
                data: {
                    ...state.saveShaderSettings.data,
                    id: action.payload.id,
                    lat: action.payload.location.latitude,
                    lng: action.payload.location.longitude,
                    ...newShadderSettings,
                },
            };
        },
        [INITIALIZE_IRRADIATION_VALUES]: (state, action) => {
            state.initialIrradiationValues = {
                ...state.initialIrradiationValues,
                ...action.payload,
            };
        },
        [INITIALIZE_SHADER_SETTINGS_VALUES]: (state, action) => {
            state.shaderSettingsInitialValues = {
                ...state.shaderSettingsInitialValues,
                ...action.payload,
            };
        },
        [INITIALIZE_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [RESET_VALUES]: () => INITIAL_STATE,
        [SAVE_IMAGE]: (state) => {
            state.saveImage.isSaving = true;
        },
        [SAVE_IMAGE_FAILURE]: (state, action) => {
            state.saveImage = {
                ...state.saveImage,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_IMAGE_SUCCESS]: (state, action) => {
            state.saveImage = {
                ...state.saveImage,
                data: action.payload,
                errors: [],
                isSaving: false,
            };
        },
        [SAVE_SHADER_SETTINGS_SUCCESS]: (state, action) => {
            state.saveShaderSettings = {
                ...state.saveShaderSettings,
                data: action.payload,
                errors: [],
                isSaving: false,
            };
        },
        [SELECT_SEGMENT]: (state, action) => {
            state.selectedSegment = action.payload;
        },
        [SET_COPY_BUFFER]: (state, action) => {
            state.copyBuffer = action.payload;
        },
        [SET_DRAWING_MODE]: (state, action) => {
            state.drawingMode = action.payload;
        },
        [SET_DRAWING_TYPE]: (state, action) => {
            state.drawingType = action.payload;
        },
        [SET_IS_DISABLING_MODULES]: (state, action) => {
            state.isDisablingModules = action.payload;
        },
        [SET_SEGMENT_FILL_METHOD]: (state, action) => {
            state.segmentFillMethod = action.payload;
        },
        [SET_SELECTED_ITEMS]: (state, action) => {
            state.selectedItems = action.payload;
        },
        [SETUP_SIMULATION]: (state, action) => {
            state.simulation = { ...state.simulation, ...action.payload };
        },
        [UPDATE_SEGMENT]: (state) => {
            state.updateSegment.isSaving = true;
        },
        [UPDATE_SEGMENT_FAILURE]: (state, action) => {
            state.updateSegment = {
                ...state.updateSegment,
                errors: action.payload,
                isSaving: false,
            };
        },
        [UPDATE_SEGMENT_SUCCESS]: (state, action) => {
            state.updateSegment = {
                ...state.updateSegment,
                data: action.payload,
                errors: [],
                isSaving: false,
            };
        },
        [UPDATE_SEGMENTS]: (state, action) => {
            state.fetchParentFieldSegment.data.segments = action.payload;
        },
        [UPDATE_SOLAR_MODULES_DESIGN_NUMBER]: (state, action) => {
            state.fetchParentFieldSegment.data = {
                ...state.fetchParentFieldSegment.data,
                solarModulesDesignNumber: action.payload,
            };
        },
    },
});

export const panelLayoutV2Actions = panelLayoutV2Slice.actions;

export default panelLayoutV2Slice.reducer;
