import get from 'lodash/get';

import { getStorageOffer } from 'common/api/v2/storageOffer';

import {
    GET_OFFER,
    GET_OFFER_FAILURE,
    GET_OFFER_SUCCESS,
    INITIAL_VALUES,
    SET_STORAGE_PLAN,
} from '../actionTypes';
import { energyBackupSetupActions } from '../reducer';

export default (commercialOfferId) => (dispatch) => {
    new Promise((resolve) => {
        dispatch(energyBackupSetupActions[GET_OFFER]());
        getStorageOffer(commercialOfferId)
            .then((response) => {
                const data = get(response, 'data.data', null);

                dispatch(
                    energyBackupSetupActions[GET_OFFER_SUCCESS](
                        Array.isArray(data) ? null : data,
                    ),
                );
                const battery = get(data, 'battery_offer[0]', {});
                const storage_percent =
                    data?.storage_offer?.storage_percent * 100 || 0;
                const newValues = {
                    base_day_type: data?.storage_offer?.base_day_type ?? 1,
                    product_branch_office:
                        battery?.product_branch_office?.id || null,
                    quantity: battery?.quantity || null,
                    storage_percent,
                    time_shifting_percentage: 100 - storage_percent,
                    storage_size:
                        (battery?.quantity * battery?.storage_cap) / 1000,
                };

                dispatch(energyBackupSetupActions[INITIAL_VALUES](newValues));

                dispatch(
                    energyBackupSetupActions[SET_STORAGE_PLAN](
                        data?.storage_offer?.storage_plan,
                    ),
                );
            })
            .catch((error) =>
                dispatch(
                    energyBackupSetupActions[GET_OFFER_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            )
            .finally(resolve);
    });
};
