import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'sunwise-ui';

import { getIsMexicanAccount } from 'common/utils/helpers/session';

import ChartComponent from './ChartComponent';

const TextContent = styled(Typography)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const CardConsumption = ({
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    holder,
    infoPriceConsumtion,
    monthskw,
    monthskwh,
    rate,
    region,
    serviceNumber,
}) => {
    const { t } = useTranslation();

    const showRegion = getIsMexicanAccount() && region;

    return (
        <>
            <Grid container mb={3}>
                <Grid item xs={18} md={9}>
                    <Typography variant="body2">{t('Name')}</Typography>
                    <Typography variant="body2" fontWeight="bold">
                        {holder}
                    </Typography>
                </Grid>

                <Grid item xs={18} md={9}>
                    <Typography variant="body2">
                        {t('Service number')}
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                        {serviceNumber}
                    </Typography>
                </Grid>

                <Grid item xs={18} md={9}>
                    <Typography variant="body2">{t('Rate')}</Typography>
                    <Typography variant="body2" fontWeight="bold">
                        {rate}
                    </Typography>
                </Grid>

                {showRegion && (
                    <Grid item xs={18} md={9}>
                        <Typography variant="body2">{t('Region')}</Typography>
                        <TextContent variant="body2" fontWeight="bold">
                            {region}
                        </TextContent>
                    </Grid>
                )}
            </Grid>

            <ChartComponent
                countryCurrencyIso={countryCurrencyIso}
                countryCurrencyLocale={countryCurrencyLocale}
                countryCurrencySymbol={countryCurrencySymbol}
                indicatorText={true}
                infoPriceConsumtion={infoPriceConsumtion}
                monthskw={monthskw}
                monthskwh={monthskwh}
            />
        </>
    );
};

CardConsumption.propTypes = {
    countryCurrencyIso: PropTypes.string,
    countryCurrencyLocale: PropTypes.string,
    countryCurrencySymbol: PropTypes.string,
    holder: PropTypes.string,
    infoPriceConsumtion: PropTypes.array,
    monthskw: PropTypes.array,
    monthskwh: PropTypes.array,
    rate: PropTypes.string,
    region: PropTypes.string,
    serviceNumber: PropTypes.string,
};

export default CardConsumption;
