import { INITIALIZE_SUCCESS, INITIALIZE_FAILURE } from '../actionTypes';
import { reportTemplateActions } from '../reducer';

import fetchCustomTemplate from './fetchCustomTemplate';

export default ({ id, initTemplate }) =>
    (dispatch) => {
        dispatch(fetchCustomTemplate({ id, initTemplate }))
            .then(() => dispatch(reportTemplateActions[INITIALIZE_SUCCESS]()))
            .catch(() => dispatch(reportTemplateActions[INITIALIZE_FAILURE]()));
    };
