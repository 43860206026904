import { recalculate } from 'common/api/v1/recalculateBag';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    RECALCULATE_CONSUMPTION_HISTORY,
    RECALCULATE_CONSUMPTION_HISTORY_FAILURE,
    RECALCULATE_CONSUMPTION_HISTORY_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import fetchData from './fetchData';
import fetchTodayAvailableBag from './fetchTodayAvailableBag';

export default (projectId) => (dispatch) => {
    dispatch(actions[RECALCULATE_CONSUMPTION_HISTORY]());

    recalculate(projectId)
        .then(() => {
            dispatch(actions[RECALCULATE_CONSUMPTION_HISTORY_SUCCESS]());
            showToast();
            dispatch(fetchData({ isInitialFetch: true, projectId }));
            dispatch(fetchTodayAvailableBag(projectId));
        })
        .catch((error) => {
            dispatch(actions[RECALCULATE_CONSUMPTION_HISTORY_FAILURE](error));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
