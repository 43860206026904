import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    Container as MaterialContainer,
    Grid,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import AccessRestrictedBanner from './components/AccessRestrictedBanner';
import CreateForm from './components/CreateForm';
import Integrations from './components/Integrations';
import { INTEGRATION_WEB } from './constants';
import * as selectors from './selectors';

const MainContainer = ({ canDelete, canModify, canView, reset }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        return () => reset();
    }, []);

    const prepareFormCreate = () => {
        setSelectedIntegration(INTEGRATION_WEB);
        setShowModal(true);
    };

    if (!canView) return null;

    return (
        <AccessRestrictedBanner>
            <MaterialContainer maxWidth={false}>
                <HeaderContainer>
                    <Grid
                        display="flex"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        gap={1}
                        item
                        justifyContent="space-between"
                        xs={18}
                    >
                        <TitleIcon fontWeight="bold" title={t('Web quoter')} />

                        {canModify && (
                            <Button
                                endIcon={<AddIcon />}
                                onClick={prepareFormCreate}
                            >
                                {t('Add integration')}
                            </Button>
                        )}
                    </Grid>
                </HeaderContainer>

                <Integrations canDelete={canDelete} canModify={canModify} />

                <Dialog
                    onClose={() => setShowModal(false)}
                    open={showModal}
                    size="xs"
                    title={t('Create integration')}
                >
                    <CreateForm
                        integrationType={selectedIntegration}
                        onHide={() => setShowModal(false)}
                    />
                </Dialog>
            </MaterialContainer>
        </AccessRestrictedBanner>
    );
};

const mapStateToProps = createStructuredSelector({
    chargebeeIntegration: selectors.getChargebeeIntegrationData,
});

const mapDispatchToProps = (dispatch) => ({
    reset: () => dispatch(actions.reset()),
});

MainContainer.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    reset: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.EMBEDDED_PROPOSAL_PERMISSION),
)(MainContainer);
