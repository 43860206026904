import initializeMarginForm from './initializeMarginForm';
import openModalMargin from './openModalMargin';

export default (ids, callback) => (dispatch) => {
    const values = {
        ids,
        callback,
    };

    dispatch(initializeMarginForm(values));
    dispatch(openModalMargin(true));
};
