import { getTrackings } from 'common/api/v1/contactTrackings';

import {
    FETCH_TRACKINGS,
    FETCH_TRACKINGS_FAILURE,
    FETCH_TRACKINGS_SUCCESS,
} from '../actionTypes';
import { ORDER_BY_PAST } from '../constants';
import { trackingsActions } from '../reducer';
import * as selectors from '../selectors';

export default (type = null) => {
    return (dispatch, getState) => {
        const state = getState();
        const contactId = selectors.getContactId(state);

        dispatch(trackingsActions[FETCH_TRACKINGS]());

        const data = {
            contact: contactId,
        };

        if (type !== null) {
            if (type === 'ALL') {
                data.type = '';
            } else if (type === 0) {
                data.type = '0';
            } else {
                data.type = type;
            }
        }

        data.order = ORDER_BY_PAST;

        getTrackings(data)
            .then((response) =>
                dispatch(
                    trackingsActions[FETCH_TRACKINGS_SUCCESS]({
                        data: response.data.data,
                        filterType: type,
                    }),
                ),
            )
            .catch((error) =>
                dispatch(trackingsActions[FETCH_TRACKINGS_FAILURE](error)),
            );
    };
};
