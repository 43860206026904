export const NAME = 'financial-products';
export const COLUMNS_CONFIG = {
    name: {
        label: 'Plan',
        is_active: true,
        required: true,
    },
    template: {
        label: 'Template',
        is_active: true,
        required: false,
    },
};
