import React, { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ClickAwayListener, IconButton, Tooltip } from 'sunwise-ui';

const InfoImage = styled('img')`
    height: 92px;
    width: 95px;
`;

const InfoPopover = ({ title, image }) => {
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => setOpen(false);
    const handleTooltipOpen = () => setOpen(true);
    const popover = (
        <div>
            <div>{title}</div>
            <div>
                <InfoImage src={image} />
            </div>
        </div>
    );
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={popover}
            >
                <IconButton onClick={handleTooltipOpen} sx={{ ml: 'auto' }}>
                    <InfoIcon fontSize="small" sx={{ color: '#2f4dff' }} />
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    );
};

InfoPopover.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,
};

export default InfoPopover;
