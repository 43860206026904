import React, { useContext, useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Alert, Box, Button, Grid, IconButton, Typography } from 'sunwise-ui';

import { EmptyState, KeyIndicator, SegmentsMap } from 'common/components';
import locationForm from 'common/modules/locationForm';
import { GeneralContext, LoadingContext } from 'common/utils/contexts';
import {
    getAddresFormatted,
    getSegmentArea,
    numberFormat,
} from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import EmptyLayout from 'resources/EmptyLayout.png';

import { POLYGON_SEGMENT } from '../../multiPanelLayout/constants';
import * as actions from '../actions';

import ProjectLocationSection from './ProjectLocationSection';

const PanelLayoutSection = ({
    canModify,
    defaultProjectLocation,
    goToPanelLayout,
    handleCreateParentFieldSegments,
    handleDeleteParentFieldSegments,
    haveParentFieldSegment,
    isLocked,
    parentFieldSegmentCounters,
    projectId,
    projectLocation,
    proposalId,
    segments,
    updateProposalPosition,
}) => {
    const { t } = useTranslation();
    const { google } = useContext(GeneralContext);
    const [isEnebledEdition, setIsEnebledEdition] = useState(false);
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const [currentProjectLocation, setCurrentProjectLocation] = useState({});
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        setCurrentProjectLocation(projectLocation);
    }, [projectLocation]);

    const { latitude = null, longitude = null } = currentProjectLocation;

    const handleOnClickEnebledEdition = () => setIsEnebledEdition(true);

    if (isNull(latitude) || isNull(longitude)) {
        return (
            <ProjectLocationSection
                canModifyLayout={canModify}
                defaultProjectLocation={defaultProjectLocation}
                handleCreateParentFieldSegments={
                    handleCreateParentFieldSegments
                }
                isLocked={isLocked}
                projectId={projectId}
                proposalId={proposalId}
            />
        );
    }

    if (!haveParentFieldSegment) {
        return (
            <EmptyState
                canModify={canModify}
                caption={t(
                    'Plan, build confidence and increase your sales closings',
                )}
                disabled={isLocked}
                onClick={() => handleCreateParentFieldSegments()}
                srcImage={EmptyLayout}
                sxCard={{ border: 0, boxShadow: 'none' }}
                textButton={t('Add')}
                title={t('Generate a preliminary sketch of the project!')}
            />
        );
    }

    const totalAssignedSolarModules = segments.reduce((acc, current) => {
        if (current.type === POLYGON_SEGMENT)
            return (
                acc +
                current.solar_modules.filter((module) => module.enabled).length
            );
        return acc + 0;
    }, 0);

    const showAlert =
        totalAssignedSolarModules !==
        parentFieldSegmentCounters.solarModulesOfferNumber;
    const totalArea = segments.reduce((acc, current) => {
        if (get(current, 'polygon', [])) {
            const area = getSegmentArea(google, get(current, 'polygon', []));
            if (current.type === POLYGON_SEGMENT) return acc + area;
            return acc + 0;
        }
        return acc;
    }, 0);

    if (isEnebledEdition)
        return (
            <locationForm.Container
                addressPlaceholder={t(
                    'Enter here your project address or zip code',
                )}
                canModify={canModify}
                center={defaultProjectLocation}
                editMode={true}
                initPosition={
                    currentProjectLocation
                        ? {
                              position: {
                                  latitude: currentProjectLocation.latitude,
                                  longitude: currentProjectLocation.longitude,
                              },
                              title: currentProjectLocation.description,
                          }
                        : {}
                }
                handleSubmit={(values) => {
                    loadingContext.openLoading(
                        t('Saving change', { count: 2 }).concat('...'),
                    );
                    updateProposalPosition(
                        { ...values, proposalId },
                        (location) => {
                            setCurrentProjectLocation(location);
                            setIsEnebledEdition(false);
                            loadingContext.closeLoading();
                        },
                        () => {
                            loadingContext.closeLoading();
                        },
                    );
                }}
                isForLayout
                name="location-project"
                setEditMode={() => setIsEnebledEdition(false)}
            />
        );

    return (
        <>
            {showAlert && (
                <Alert severity="warning">
                    <Typography variant="caption" fontWeight="bold">
                        {t(
                            'The number of panels does not match those available in the proposal, adjust your PV layout',
                        )}
                    </Typography>
                </Alert>
            )}

            <Grid container>
                <Grid item xs={18} lg={8}>
                    <Grid container>
                        <Grid item xs={18}>
                            <KeyIndicator
                                extra={
                                    <IconButton
                                        onClick={handleOnClickEnebledEdition}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                }
                                title={t('Address')}
                                value={getAddresFormatted(
                                    currentProjectLocation,
                                )}
                                widthIcon="20px"
                            />
                        </Grid>

                        <Grid item xs={18} md={9}>
                            {}
                            <KeyIndicator
                                title={t('Number of modules')}
                                unit="u"
                                value={totalAssignedSolarModules}
                                widthIcon="20px"
                            />
                        </Grid>

                        <Grid item xs={18} md={9}>
                            <KeyIndicator
                                title={t('Number of segments')}
                                unit="u"
                                value={
                                    segments.filter(
                                        (segment) =>
                                            segment.type === POLYGON_SEGMENT,
                                    ).length
                                }
                                widthIcon="20px"
                            />
                        </Grid>

                        <Grid item xs={18} md={9}>
                            <KeyIndicator
                                title={t('Total area')}
                                unit="m2"
                                value={numberFormat(totalArea, {
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                })}
                                widthIcon="20px"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {!isNull(latitude) && !isNull(longitude) && (
                    <Grid item xs={18} lg={10}>
                        <Box>
                            <SegmentsMap
                                center={{
                                    lat: get(
                                        currentProjectLocation,
                                        'latitude',
                                    ),
                                    lng: get(
                                        currentProjectLocation,
                                        'longitude',
                                    ),
                                }}
                                fullscreenControl
                                height="250px"
                                initCenter={currentProjectLocation}
                                projectLocation={{
                                    ...currentProjectLocation,
                                    address: currentProjectLocation.description,
                                }}
                                segments={segments}
                                showLabels={false}
                                width="100%"
                                zoom={18}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>

            <Grid container mt={1}>
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        justifyContent: { md: 'right', xs: 'center' },
                    }}
                    xs
                >
                    <Button
                        color="secondary"
                        disabled={!canModify || isLocked}
                        onClick={handleDeleteParentFieldSegments}
                        sx={{
                            mr: { md: 2, xs: 0 },
                            order: { md: 1, xs: 2 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="text"
                    >
                        {t('Delete')}
                    </Button>

                    <Button
                        disabled={!canModify || isLocked}
                        onClick={goToPanelLayout}
                        sx={{
                            mb: { md: 0, xs: 2 },
                            order: { md: 2, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="outlined"
                    >
                        {t('Edit')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

PanelLayoutSection.propTypes = {
    canModify: PropTypes.bool,
    defaultProjectLocation: PropTypes.object,
    goToPanelLayout: PropTypes.func,
    handleCreateParentFieldSegments: PropTypes.func,
    handleDeleteParentFieldSegments: PropTypes.func,
    haveParentFieldSegment: PropTypes.bool,
    isLocked: PropTypes.bool,
    parentFieldSegmentCounters: PropTypes.object,
    projectId: PropTypes.string,
    projectLocation: PropTypes.object,
    proposalId: PropTypes.string,
    segments: PropTypes.array,
    updateProposalPosition: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    updateProposalPosition: (values, successCallback, failureCallback) =>
        dispatch(
            actions.updateProposalPosition(
                values,
                successCallback,
                failureCallback,
            ),
        ),
});

export default compose(connect(null, mapDispatchToProps))(PanelLayoutSection);
