import React from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    editionLevels,
    PageToolbar,
    TemplateView,
} from 'sunwise-template-core';
import { Box } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

import EmptyTemplate from './EmptyTemplate';

const PageWrapper = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-y: scroll;
    padding-top: 16px;

    .apexcharts-theme-light {
        color: black;
    }

    ${scroll.custom()}
`;

const PageContainer = ({
    handleClickBulkItems,
    isArchived = false,
    isEmptyPages,
    selectedPageId,
    setArchived,
    showNewPageModal,
    templateId,
}) => {
    const { t } = useTranslation();
    const editionLevel = isArchived
        ? editionLevels.NO_EDITION_MODE
        : editionLevels.FULL_EDITION_MODE;

    if (isEmptyPages)
        return <EmptyTemplate onClick={() => showNewPageModal()} />;

    if (isNil(selectedPageId))
        return <TemplateView editionLevel={editionLevels.FULL_EDITION_MODE} />;

    const handleOnClickUnarchive = () => {
        handleClickBulkItems([templateId], 'active', null, () =>
            setArchived(false),
        );
    };

    return (
        <>
            {!isArchived ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <PageToolbar />
                </Box>
            ) : (
                <Box sx={{ textAlign: 'right', px: 2, py: 1 }}>
                    <Button size="small" onClick={handleOnClickUnarchive}>
                        {t('Unarchive')}
                    </Button>
                </Box>
            )}

            <PageWrapper>
                <TemplateView editionLevel={editionLevel} />
            </PageWrapper>
        </>
    );
};

PageContainer.propTypes = {
    handleClickBulkItems: PropTypes.func,
    isArchived: PropTypes.bool,
    isEmptyPages: PropTypes.bool,
    selectedPageId: PropTypes.string,
    setArchived: PropTypes.func,
    showNewPageModal: PropTypes.func,
    templateId: PropTypes.string,
};

export default PageContainer;
