import React from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupIcon from '@mui/icons-material/Group';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import { CheckAccess } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import { getHasPaymentDue } from 'common/utils/helpers/session';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledStyledListItemText from './StyledStyledListItemText';

const ContactOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
}) => {
    const { t } = useTranslation();
    const hasPaymentDue = getHasPaymentDue();
    const breakpoint = useBreakpoint();

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.CONTACTS_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/contacts');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={
                        match.path === '/contacts' ||
                        /^\/record/i.test(match.path) ||
                        /^\/proposal-review-pro/i.test(match.path)
                    }
                    variant="horizontal"
                >
                    <GroupIcon />
                    <StyledStyledListItemText primary={t('Contact list')} />
                </StyledListItemButton>
            </CheckAccess>
            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/follow-ups');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/follow-ups' === match.path}
                    variant="horizontal"
                >
                    <CalendarTodayIcon />
                    <StyledStyledListItemText
                        primary={t('Follow-up', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

ContactOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
};

export default ContactOptions;
