import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_FAILURE,
    DELETE_NOTIFICATION_SUCCESS,
    FETCH_NAV_NOTIFICATIONS,
    FETCH_NAV_NOTIFICATIONS_FAILURE,
    FETCH_NAV_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS,
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_FAILURE,
    MARK_ALL_NOTIFICATIONS_AS_READ,
    MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE,
    MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
    REMOVE_NOTIFICATION_COUNTER,
    REMOVE_NOTIFICATION_COUNTER_FAILURE,
    REMOVE_NOTIFICATION_COUNTER_SUCCESS,
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILURE,
    UPDATE_NOTIFICATIONS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    deleteNotification: {
        isDeleting: false,
        errors: [],
    },
    fetchNavNotifications: {
        isFetching: false,
        data: {
            data: [],
            pagination: {},
        },
        errors: [],
    },
    fetchNotifications: {
        isFetching: false,
        data: {
            data: [],
            pagination: {},
        },
        errors: [],
    },
    markAllNotificationsAsRead: {
        isUpdating: false,
        errors: [],
    },
    removeNotificationCounter: {
        isRemoving: false,
        errors: [],
    },
    updateNotification: {
        isUpdating: false,
        errors: [],
    },
};

const notificationsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isSaving: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isSaving: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                isSaving: false,
            };
        },
        [DELETE_NOTIFICATION]: (state) => {
            state.deleteNotification.isDeleting = true;
        },
        [DELETE_NOTIFICATION_FAILURE]: (state, action) => {
            state.deleteNotification = {
                ...state.deleteNotification,
                error: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_NOTIFICATION_SUCCESS]: (state) => {
            state.deleteNotification.isDeleting = false;
        },
        [FETCH_NAV_NOTIFICATIONS]: (state) => {
            state.fetchNavNotifications = {
                ...state.fetchNavNotifications,
                data: {},
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_NAV_NOTIFICATIONS_FAILURE]: (state, action) => {
            state.fetchNavNotifications = {
                ...state.fetchNavNotifications,
                data: {},
                error: action.payload,
                isFetching: false,
            };
        },
        [FETCH_NAV_NOTIFICATIONS_SUCCESS]: (state, action) => {
            state.fetchNavNotifications = {
                ...state.fetchNavNotifications,
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [FETCH_NOTIFICATIONS]: (state) => {
            state.fetchNotifications = {
                ...state.fetchNotifications,
                data: {},
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_NOTIFICATIONS_FAILURE]: (state, action) => {
            state.fetchNotifications = {
                ...state.fetchNotifications,
                data: {},
                error: action.payload,
                isFetching: false,
            };
        },
        [FETCH_NOTIFICATIONS_SUCCESS]: (state, action) => {
            state.fetchNotifications = {
                ...state.fetchNotifications,
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [MARK_ALL_NOTIFICATIONS_AS_READ]: (state) => {
            state.markAllNotificationsAsRead.isUpdating = true;
        },
        [MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE]: (state, action) => {
            state.markAllNotificationsAsRead = {
                ...state.markAllNotificationsAsRead,
                error: action.payload,
                isUpdating: false,
            };
        },
        [MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS]: (state) => {
            state.markAllNotificationsAsRead.isUpdating = false;
        },
        [REMOVE_NOTIFICATION_COUNTER]: (state) => {
            state.removeNotificationCounter.isRemoving = true;
        },
        [REMOVE_NOTIFICATION_COUNTER_FAILURE]: (state, action) => {
            state.removeNotificationCounter = {
                ...state.removeNotificationCounter,
                errors: action.payload,
                isRemoving: false,
            };
        },
        [REMOVE_NOTIFICATION_COUNTER_SUCCESS]: (state) => {
            state.removeNotificationCounter.isRemoving = false;
        },
        [UPDATE_NOTIFICATION]: (state) => {
            state.updateNotification.isUpdating = true;
        },
        [UPDATE_NOTIFICATION_FAILURE]: (state, action) => {
            state.updateNotification = {
                ...state.updateNotification,
                error: action.payload,
                isUpdating: false,
            };
        },
        [UPDATE_NOTIFICATION_SUCCESS]: (state) => {
            state.updateNotification.isUpdating = false;
        },
        [UPDATE_NOTIFICATIONS]: (state, action) => {
            state.fetchNotifications.data.data = action.payload;
        },
    },
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice.reducer;
