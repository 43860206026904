import { number, object } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { getMaxValueText, getMinValueText, REQUIRED_TEXT } =
        getValidationTexts();
    return object().shape({
        margin: number()
            .min(0, getMinValueText(0))
            .max(99.99, getMaxValueText(99.99))
            .typeError(REQUIRED_TEXT)
            .required(REQUIRED_TEXT),
    });
};
