import { NAME } from './constants';

export const INITIALIZE_VALUES = `${NAME}/INITIALIZE_VALUES`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_PROJECT = `${NAME}/SAVE_PROJECT`;
export const SAVE_PROJECT_FAILURE = `${NAME}/SAVE_PROJECT_FAILURE`;
export const SAVE_PROJECT_SUCCESS = `${NAME}/SAVE_PROJECT_SUCCESS`;

export const SET_MODAL_IS_OPEN = `${NAME}/SET_MODAL_IS_OPEN`;
