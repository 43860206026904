import { SAVE_ITEM } from '../actionTypes';
import { getIsCustomOrGM } from '../helpers';
import { companyUsersActions } from '../reducer';

import createUser from './createUser';
import updateUser from './updateUser';

export default (values) => (dispatch) => {
    let newValues = {
        birth_date: values.birth_date,
        company_group: values.company_group,
        company: values.company,
        confirm_password: values.confirm_password,
        email: values.email,
        first_name: values.first_name,
        has_all_branchoffices: values.has_all_branchoffices,
        is_enabled: values.is_enabled,
        last_name: values.last_name,
        has_limited_contacts: values.has_limited_contacts,
        password: values.password,
        phone: values.phone,
        position: values.position,
        role_level: values.role_level,
        second_surname: values.second_surname,
    };

    const isCustomOrGM = getIsCustomOrGM(values.role_level);

    if (isCustomOrGM) newValues.branch_offices = values.branch_offices;
    else newValues.branch_office = values.branch_office;

    dispatch(companyUsersActions[SAVE_ITEM]());
    if (values.id) dispatch(updateUser(newValues, values.id));
    else dispatch(createUser(newValues));
};
