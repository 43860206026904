import React from 'react';

import PropTypes from 'prop-types';

import LabelError from './LabelError';
import StyledGroup from './StyledGroup';

const Input = ({
    as = 'input',
    id,
    className = '',
    error,
    field,
    label,
    onlyNumbers = false,
    required,
    type,
    disabled,
    readOnly,
    max,
    maxLength,
    min,
    step,
    tabIndex,
    rows,
}) => {
    const TagName = as;

    const { value, onChange } = field;

    const onChangeAction = (event) => {
        if (onlyNumbers) {
            event.target.value = event.target.value.replace(/[A-Za-z]/gi, '');
        }

        onChange(event);
    };

    return (
        <StyledGroup
            className={`${className} ${error?.message ? 'error' : ''}`}
        >
            <TagName
                className={`${value ? 'dirty' : ''}`}
                type={type}
                required={required}
                onChange={onChangeAction}
                id={id}
                disabled={disabled}
                readOnly={readOnly}
                max={max}
                maxLength={maxLength}
                min={min}
                step={step}
                tabIndex={tabIndex}
                value={value}
                rows={rows}
            />

            <span className="highlight" />
            <span className="bar" />
            <label>{`${required ? '* ' : ''} ${label}`}</label>

            {error?.message && <LabelError>{error?.message}</LabelError>}
        </StyledGroup>
    );
};

Input.propTypes = {
    as: PropTypes.elementType,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.object,
    id: PropTypes.string,
    field: PropTypes.object,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    min: PropTypes.number,
    step: PropTypes.number,
    onlyNumbers: PropTypes.bool,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    rows: PropTypes.number,
};

export default Input;
