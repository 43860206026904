import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from 'sunwise-ui';

import { TitleIcon } from 'common/components';

import ProductsList from '../components/ProductsList';

const AllianceProducts = ({
    canModify,
    canView,
    fetchFinarcierProduct,
    filterData,
    handleChangeSwitch,
    handleClickActivateItems,
    handleClickProduct,
    isFetching,
    items,
    paginationData,
    setFilterData,
    tableSettings,
}) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Header>
                <TitleIcon
                    fontWeight="bold"
                    title={t('Alliance product', { count: 2 })}
                    variant="subtitle"
                />
                <Typography variant="body2">
                    {t(
                        'Here is a list of your approved financial products ready to be applied to your proposals!',
                    )}
                </Typography>
            </Card.Header>
            <Card.Body>
                <ProductsList
                    canModify={canModify}
                    canView={canView}
                    fetchFinarcierProduct={fetchFinarcierProduct}
                    filterData={filterData}
                    handleChangeSwitch={handleChangeSwitch}
                    handleClickActivateItems={handleClickActivateItems}
                    handleClickProduct={handleClickProduct}
                    isFetching={isFetching}
                    items={items}
                    paginationData={paginationData}
                    setFilterData={setFilterData}
                    tableSettings={tableSettings}
                />
            </Card.Body>
        </Card>
    );
};

AllianceProducts.propTypes = {
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    fetchFinarcierProduct: PropTypes.func,
    filterData: PropTypes.object,
    handleChangeSwitch: PropTypes.func,
    handleClickActivateItems: PropTypes.func,
    handleClickProduct: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
};

export default AllianceProducts;
