import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from 'sunwise-ui';

import WelcomePlaceholder from 'resources/onboarding/welcome.svg';

const WelcomeStep = ({ isLoading, user }) => {
    const { t } = useTranslation();
    const name = `${user.first_name} ${user.last_name}`;
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                py: 1,
                width: '100%',
            }}
        >
            {isLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            ) : (
                <Typography variant="h6">
                    {t('Hello {{name}}! Welcome', { name })}
                </Typography>
            )}
            <Typography variant="body2">
                {t('You are about to start setting up your new Sunwise space')}
            </Typography>
            <img src={WelcomePlaceholder} />
        </Box>
    );
};

WelcomeStep.propTypes = {
    isLoading: PropTypes.bool,
    user: PropTypes.object,
};

export default WelcomeStep;
