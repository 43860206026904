import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import { POLYGON_MAP } from '../constants';

const MenuOptions = ({
    commercialOfferId,
    handleClickDelete,
    handleClickEditSegment,
    handleOnDuplicateSegment,
    isDisabled,
    isHidden,
    item,
    toggleLockSegment,
}) => {
    const { t } = useTranslation();

    if (isHidden) return null;

    let items = [];

    const editItem = {
        disabled: isDisabled,
        handleClick: () => handleClickEditSegment(item),
        icon: <EditIcon fontSize="small" />,
        label: t('Edit'),
    };
    items.push(editItem);

    if (item.type !== POLYGON_MAP) {
        const duplicateItem = {
            disabled: isDisabled,
            handleClick: () =>
                handleOnDuplicateSegment(item, commercialOfferId),
            icon: <ContentCopyIcon fontSize="small" />,
            label: t('Duplicate'),
        };
        items.push(duplicateItem);
    } else {
        const lockItem = {
            disabled: isDisabled,
            handleClick: () => toggleLockSegment(item.id),
            icon: item?.locked ? (
                <LockOpenIcon fontSize="small" />
            ) : (
                <LockIcon fontSize="small" />
            ),
            label: item?.locked ? t('Unlock') : t('Lock'),
        };
        items.push(lockItem);
    }

    const deleteItem = {
        disabled: isDisabled,
        handleClick: () => handleClickDelete(item.id),
        icon: <DeleteIcon fontSize="small" />,
        label: t('Delete'),
    };
    items.push(deleteItem);

    return (
        <DropdownCardMenu
            items={items}
            sxIconButton={{
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}
        />
    );
};

MenuOptions.propTypes = {
    commercialOfferId: PropTypes.string,
    handleClickDelete: PropTypes.func,
    handleClickEditSegment: PropTypes.func,
    handleOnDuplicateSegment: PropTypes.func,
    isDisabled: PropTypes.bool,
    isHidden: PropTypes.bool,
    item: PropTypes.object,
    toggleLockSegment: PropTypes.func,
};

export default MenuOptions;
