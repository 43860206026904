import { SET_COPY_BUFFER } from '../actionTypes';
import { POLYGON_MAP } from '../constants';
import { getDictionaryById } from '../helpers';
import { panelLayoutV2Actions } from '../reducer';
import * as selectors from '../selectors';

export default () => (dispatch, getState) => {
    const state = getState();
    const selectedItems = selectors.getSelectedItems(state);

    if (selectedItems.length === 0) return;

    const segments = selectors.getSegmentsData(state);

    if (segments.length === 0) return;

    const segmentsDictionary = getDictionaryById(segments);

    let buffer = [];

    selectedItems.forEach((id) => {
        if (
            segmentsDictionary[id] &&
            segmentsDictionary[id].type !== POLYGON_MAP
        )
            buffer.push(segmentsDictionary[id]);
    });

    dispatch(panelLayoutV2Actions[SET_COPY_BUFFER](buffer));
};
