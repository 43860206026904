import { activateItem } from 'common/api/v1/companiesCurrencies';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    ACTIVATE_ITEM,
    ACTIVATE_ITEM_FAILURE,
    ACTIVATE_ITEM_SUCCESS,
} from '../actionTypes';
import { multiCurrencyActions } from '../reducer';

import prepareUpdate from './prepareUpdate';
import updateList from './updateList';

export default (id, items) => (dispatch) => {
    dispatch(multiCurrencyActions[ACTIVATE_ITEM]());

    const newItem = items.find((item) => item.id === id);

    activateItem(id)
        .then((response) => {
            dispatch(
                multiCurrencyActions[ACTIVATE_ITEM_SUCCESS](response.data),
            );
            dispatch(updateList(items, newItem, !newItem.is_enabled));
            dispatch(
                prepareUpdate({ ...newItem, is_enabled: !newItem.is_enabled }),
            );
            showToast();
        })
        .catch((error) => {
            dispatch(
                multiCurrencyActions[ACTIVATE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
