import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_LOCATIONS_ZIP_CODE,
    FETCH_LOCATIONS_ZIP_CODE_FAILURE,
    FETCH_LOCATIONS_ZIP_CODE_SUCCESS,
    GET_CSV_INFO,
    GET_CSV_INFO_FAILURE,
    GET_CSV_INFO_SUCCESS,
    INITIALIZE,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_IS_OPEN,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    csvInfo: { data: null, error: null, isFetching: false },
    fetchLocationsZipCode: { error: null, isFetching: false },
    initialValues: { file: null },
    isOpen: false,
    save: { error: null, isSaving: false },
};

const importConsumptionsModalSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_LOCATIONS_ZIP_CODE]: (state) => {
            state.fetchLocationsZipCode.error = null;
            state.fetchLocationsZipCode.isFetching = true;
        },
        [FETCH_LOCATIONS_ZIP_CODE_FAILURE]: (state, action) => {
            state.fetchLocationsZipCode.error = action.payload;
            state.fetchLocationsZipCode.isFetching = false;
        },
        [FETCH_LOCATIONS_ZIP_CODE_SUCCESS]: (state) => {
            state.fetchLocationsZipCode.isFetching = false;
        },
        [GET_CSV_INFO]: (state) => {
            state.csvInfo.error = null;
            state.csvInfo.isFetching = true;
        },
        [GET_CSV_INFO_FAILURE]: (state, action) => {
            state.csvInfo.error = action.payload;
            state.csvInfo.isFetching = false;
        },
        [GET_CSV_INFO_SUCCESS]: (state, action) => {
            state.csvInfo.isFetching = false;
            state.csvInfo.data = action.payload;
        },
        [INITIALIZE]: (state, action) => {
            state.initialValues = action.payload;
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save.isSaving = true;
            state.save.error = null;
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save.error = action.payload;
            state.save.isSaving = false;
        },
        [SAVE_SUCCESS]: (state) => {
            state.save.isSaving = false;
        },
        [SET_IS_OPEN]: (state, action) => {
            state.isOpen = action.payload;
        },
    },
});

export const importConsumptionsModalActions =
    importConsumptionsModalSlice.actions;

export default importConsumptionsModalSlice.reducer;
