import i18next from 'i18next';
import get from 'lodash/get';

import { getLocationsByZipCode } from 'common/api/v1/politicalDivisions';
import alerts from 'common/modules/alerts';

import {
    FETCH_LOCATIONS_BY_ZIP_CODE,
    FETCH_LOCATIONS_BY_ZIP_CODE_SUCCESS,
    FETCH_LOCATIONS_BY_ZIP_CODE_FAILURE,
} from '../actionTypes';
import { actions } from '../reducer';

import fetchPoliticalDivisions2 from './fetchPoliticalDivisions2';
import fetchRateDivisions from './fetchRateDivisions';
import fetchRegionsByPoliticalDivision from './fetchRegionsByPoliticalDivision';

export default ({ selectedRate, setValue, value } = {}) =>
    (dispatch) => {
        if (!value) return;

        dispatch(actions[FETCH_LOCATIONS_BY_ZIP_CODE]());

        getLocationsByZipCode(value)
            .then(({ data }) => {
                dispatch(
                    actions[FETCH_LOCATIONS_BY_ZIP_CODE_SUCCESS](data || []),
                );

                if (!data?.length) return;

                const values = data[0];
                setValue('political_division1', values.state?.id);

                dispatch(fetchPoliticalDivisions2(values.state?.id));

                if (data.length === 1)
                    setValue('political_division2', values.municipality?.id);

                dispatch(
                    fetchRateDivisions({
                        id: values.state?.id,
                        selectedRate,
                        selectedRateDivision: values.rate_division?.id || null,
                        setValue,
                    }),
                );

                dispatch(
                    fetchRegionsByPoliticalDivision(values.state.id, setValue),
                );
            })
            .catch((error) => {
                const errors = get(error, ['response', 'data', 'errors'], []);
                dispatch(actions[FETCH_LOCATIONS_BY_ZIP_CODE_FAILURE](errors));
                if (errors?.length > 0)
                    dispatch(
                        alerts.actions.show({
                            confirmText: i18next.t('Close'),
                            hideButtons: false,
                            messages: errors,
                            title: i18next.t('Message'),
                            type: alerts.ALERT_TYPE_ALERT,
                            variant: 'warning',
                        }),
                    );
            });
    };
