import { NAME } from './constants';

export const FETCH_TIME_SHIFTING_CONFIGURATION = `${NAME}/FETCH_TIME_SHIFTING_CONFIGURATION`;
export const FETCH_TIME_SHIFTING_CONFIGURATION_FAILURE = `${NAME}/FETCH_TIME_SHIFTING_CONFIGURATION_FAILURE`;
export const FETCH_TIME_SHIFTING_CONFIGURATION_SUCCESS = `${NAME}/FETCH_TIME_SHIFTING_CONFIGURATION_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const SAVE_TIME_SHIFTING_CONFIGURATION = `${NAME}/SAVE_TIME_SHIFTING_CONFIGURATION`;
export const SAVE_TIME_SHIFTING_CONFIGURATION_FAILURE = `${NAME}/SAVE_TIME_SHIFTING_CONFIGURATION_FAILURE`;
export const SAVE_TIME_SHIFTING_CONFIGURATION_SUCCESS = `${NAME}/SAVE_TIME_SHIFTING_CONFIGURATION_SUCCESS`;

export const SET_INITIAL_VALUES = `${NAME}/SET_INITIAL_VALUES`;
