import {
    OFFER_SMART_DOCUMENT_FORM_DUPLICATE_MODE,
    OFFER_SMART_DOCUMENT_FORM_RENAME_MODE,
} from '../../constants';

import createOfferSmartDocument from './createOfferSmartDocument';
import duplicateOfferSmartDocument from './duplicateOfferSmartDocument';
import renameOfferSmartDocument from './renameOfferSmartDocument';

export default (
        handleClickDocumentItem,
        offerSmartDocumentFormMode,
        proposalId,
        values,
    ) =>
    (dispatch) => {
        switch (offerSmartDocumentFormMode) {
            case OFFER_SMART_DOCUMENT_FORM_DUPLICATE_MODE:
                dispatch(
                    duplicateOfferSmartDocument(
                        handleClickDocumentItem,
                        proposalId,
                        values,
                    ),
                );
                break;
            case OFFER_SMART_DOCUMENT_FORM_RENAME_MODE:
                dispatch(renameOfferSmartDocument(proposalId, values));
                break;
            default:
                dispatch(
                    createOfferSmartDocument(
                        handleClickDocumentItem,
                        proposalId,
                        values,
                    ),
                );
                break;
        }
    };
