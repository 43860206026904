import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import SortableItem from './SortableItem';

const StyledList = styled('ul')`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const SortableList = ({
    canModify,
    handleClickDelete,
    handleClickDuplicate,
    handleClickSelect,
    id,
    items,
    onDragEnd,
    pageSize,
    refDictionary,
    selectedPageId,
}) => (
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={id}>
            {(provided) => (
                <StyledList
                    className="sortable"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {items.map((item, index) => (
                        <SortableItem
                            canModify={canModify}
                            handleClickDelete={handleClickDelete}
                            handleClickDuplicate={handleClickDuplicate}
                            handleClickSelect={handleClickSelect}
                            index={index}
                            item={item}
                            key={`sortable-item-${item.id}`}
                            pageSize={pageSize}
                            refDictionary={refDictionary}
                            selectedPageId={selectedPageId}
                        />
                    ))}

                    {provided.placeholder}
                </StyledList>
            )}
        </Droppable>
    </DragDropContext>
);

SortableList.propTypes = {
    canModify: PropTypes.bool,
    handleClickDelete: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickSelect: PropTypes.func,
    id: PropTypes.string,
    items: PropTypes.array,
    onDragEnd: PropTypes.func,
    pageSize: PropTypes.string,
    refDictionary: PropTypes.object,
    selectedPageId: PropTypes.string,
};

export default SortableList;
