import { create, update } from 'common/api/v1/historical';
import {
    DOMESTIC_RATES,
    HIGH_TENSION_RATES,
    RATES_WITH_POWER_FACTOR,
} from 'common/constants/rates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import {
    EDIT_CONSUMPTION_HISTORY,
    SAVE_DATA,
    SAVE_DATA_FAILURE,
    SAVE_DATA_SUCCESS,
} from '../actionTypes';
import { CAPTURE_MODE, DEMAND_METHODS, ENERGY_CONCEPTS } from '../constants';
import { cleanValue } from '../helpers';
import { actions } from '../reducer';
import * as selectors from '../selectors';

import fetchData from './fetchData';
import fetchTodayAvailableBag from './fetchTodayAvailableBag';

const getFieldFormatted = (field, allowDecimals) => {
    const value = cleanValue(field?.value, allowDecimals);
    const placeholder = cleanValue(field?.placeholder, allowDecimals);

    return {
        capture_mode:
            value === null ? CAPTURE_MODE.CALCULATED : field?.capture_mode,
        tier: field?.tier ?? 0,
        value: value ?? placeholder,
    };
};

const getEnergyConceptsFormatted = (concepts) => {
    return {
        consumption: getFieldFormatted(concepts[ENERGY_CONCEPTS.CONSUMPTION]),
        exported_energy: getFieldFormatted(
            concepts[ENERGY_CONCEPTS.EXPORTED_ENERGY],
        ),
        generation: getFieldFormatted(concepts[ENERGY_CONCEPTS.GENERATION]),
        grid_consumption: getFieldFormatted(
            concepts[ENERGY_CONCEPTS.GRID_CONSUMPTION],
        ),
        selfconsumption: getFieldFormatted(
            concepts[ENERGY_CONCEPTS.SELF_CONSUMPTION],
        ),
    };
};

export default ({ callback, project, values }) =>
    (dispatch, getState) => {
        dispatch(actions[SAVE_DATA]());

        if (!values) return;

        const state = getState();
        const measuredEnergy = selectors.getMeasuredEnergyData(state);
        const ratesDictionary =
            afterSalesSettingsSelectors.getRatesDictionary(state);

        const rate = ratesDictionary[values.rate];

        if (!rate)
            return dispatch(
                actions[SAVE_DATA_FAILURE]({ rate: ['Rate not found'] }),
            );

        const newValues = {
            applied_bag: values.applied_bag || 0,
            demand: Object.values(values.demand).map((field) =>
                getFieldFormatted(field, true),
            ),
            demand_method:
                values.demand_method || DEMAND_METHODS.MEASUREMENT_SOURCE,
            energy: Object.values(values.energy).map(
                getEnergyConceptsFormatted,
            ),
            exported_energy_price: values.exported_energy_price || 0,
            file: values.file || null,
            final_date: values.final_date,
            initial_date: values.initial_date,
            is_bimonthly: values.is_bimonthly || false,
            netted_exported_generation_price:
                values.netted_exported_generation_price || 0,
            no_solar_demand: Object.values(values.no_solar_demand).map(
                (field) => getFieldFormatted(field, true),
            ),
            periodicity_type: values.periodicityType || 0,
            ppa_active: values.ppa_active || false,
            ppa_price: values.ppa_price || 0,
            project,
            provider_data: values.provider_data || measuredEnergy,
            rate: rate.id,
            total: getEnergyConceptsFormatted(values.total),
            upload_origin: values.upload_origin ?? null,
        };

        if (rate.certified) {
            if (rate.name === 'DAC') {
                const ratesDictionaryByName =
                    afterSalesSettingsSelectors.getRatesDictionaryByName(state);
                const subsidyRate = ratesDictionaryByName[values.subsidy_rate];
                newValues.subsidy_rate = subsidyRate?.id || null;
            }

            if (DOMESTIC_RATES.includes(rate.name))
                newValues.rate_division_summer = values.rate_division_summer;
            if (HIGH_TENSION_RATES.includes(rate.name))
                newValues.contracted_demand = [
                    {
                        capture_mode: CAPTURE_MODE.USER,
                        tier: 0,
                        value: cleanValue(values?.contracted_demand, true),
                    },
                ];

            if (RATES_WITH_POWER_FACTOR.includes(rate.name))
                newValues.power_factor = values.power_factor;
        } else if (values.hourly_contracted_demand) {
            newValues.contracted_demand = Object.values(
                values.hourly_contracted_demand,
            ).map((field) => getFieldFormatted(field, true));
        }

        const save = values.id
            ? () => update(values.id, newValues)
            : () => create(newValues);

        save()
            .then((response) => {
                const data = response?.data?.data;
                dispatch(actions[SAVE_DATA_SUCCESS](data));
                showToast();

                if (!values.id)
                    dispatch(
                        fetchData({ isInitialFetch: true, projectId: project }),
                    );
                else
                    dispatch(
                        actions[EDIT_CONSUMPTION_HISTORY]({
                            id: values.id,
                            values: data,
                        }),
                    );

                dispatch(fetchTodayAvailableBag(project));

                if (callback) callback();
            })
            .catch((error) => {
                dispatch(
                    actions[SAVE_DATA_FAILURE](error?.response?.data?.errors),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
