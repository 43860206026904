import React, { useContext, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActions, Box, Button, TabPanel, Tabs, Alert } from 'sunwise-ui';

import { ReactHookFormInput } from 'common/components/form/bootstrap';
import { LoadingContext } from 'common/utils/contexts';
import {
    getCompatibleCompensationSchemes,
    getCompensationSchemeByValue,
} from 'common/utils/helpers/rates';

import { getFieldsHasErrors } from '../../helpers';
import {
    CompensationSchemeDescription,
    DefaultSettings,
} from '../PolicyComponents';
import SectionHeader from '../SectionHeader';
import { Label } from '../styledComponents';
import TabBadge from '../TabBadge';

import ChargesTab from './ChargesTab';
import HourlyRateTabs from './HourlyRateTabs';

const CertidiedRateFormTabs = ({
    control,
    getValues,
    handleSubmit,
    isSavingRate,
    othersUnitsDataToSelect,
    rateName,
    saveRate,
    setValue,
    showMode,
}) => {
    const tabKey = 'certified-rate-modal-form-tab';
    const { t } = useTranslation();
    const { defaultValues, errors } = useFormState({ control });
    const [hourlyRateData, setHourlyRateData] = useState({
        division: {},
        hourly_distribution: {},
    });
    const [selectedTab, setSelectedTab] = useState(`${tabKey}-main_data`);
    const loadingContext = useContext(LoadingContext);
    const defaultCompensationScheme = useWatch({
        control,
        name: 'default_compensation_scheme',
    });

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingRate) loadingContext.openLoading(t('Saving').concat('...'));
        else loadingContext.closeLoading();
    }, [isSavingRate]);

    const handleOnChangeTab = (_, newValue) => {
        setSelectedTab(newValue);
    };

    const disabled = showMode;

    const itemsToSelect = getCompatibleCompensationSchemes({
        canViewSelfConsumption: true,
        certified: true,
        name: rateName,
    });

    const selectedScheme = getCompensationSchemeByValue(
        defaultCompensationScheme,
    );

    return (
        <form onSubmit={handleSubmit((values) => saveRate(values))}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleOnChangeTab}>
                    <TabBadge
                        hasErrors={getFieldsHasErrors(errors, [
                            'rate_description',
                        ])}
                        label={t('General')}
                        value={`${tabKey}-main_data`}
                    />
                    <TabBadge
                        hasErrors={getFieldsHasErrors(errors, [
                            'annual_percentage_increase',
                            'dap',
                            'other_concepts',
                            'tax_rate',
                            'taxes_concepts',
                        ])}
                        label={t('Setting')}
                        value={`${tabKey}-settings_data`}
                    />
                    <TabBadge
                        hasErrors={getFieldsHasErrors(errors, [
                            'default_compensation_scheme',
                            'energy_export_price',
                        ])}
                        label={t('Policy', { count: 2 })}
                        value={`${tabKey}-policy_data`}
                    />
                </Tabs>
            </Box>

            <TabPanel
                key="tab-panel-general"
                selectedTab={selectedTab}
                value={`${tabKey}-main_data`}
            >
                <SectionHeader title={t('General information')} />
                <Label>{t('Description')}:</Label>
                <ReactHookFormInput
                    control={control}
                    disabled={disabled}
                    maxLength="1000"
                    multiline
                    name="rate_description"
                    placeholder={t('Rate description')}
                    rows="5"
                />
            </TabPanel>

            <TabPanel
                key="tab-panel-settings"
                selectedTab={selectedTab}
                value={`${tabKey}-settings_data`}
            >
                {['GDMTH', 'DIST', 'DIT'].includes(rateName) ? (
                    <HourlyRateTabs
                        control={control}
                        defaultValues={defaultValues}
                        disabled={disabled}
                        getValues={getValues}
                        hourlyRateData={hourlyRateData}
                        othersUnitsDataToSelect={othersUnitsDataToSelect}
                        setHourlyRateData={setHourlyRateData}
                        setValue={setValue}
                        showMode={showMode}
                    />
                ) : (
                    <ChargesTab
                        control={control}
                        disabled={disabled}
                        getValues={getValues}
                        othersUnitsDataToSelect={othersUnitsDataToSelect}
                        setValue={setValue}
                        showMode={showMode}
                    />
                )}
            </TabPanel>

            <TabPanel
                key="tab-panel-policies"
                selectedTab={selectedTab}
                value={`${tabKey}-policy_data`}
            >
                <DefaultSettings
                    compensationSchemesOptions={itemsToSelect}
                    control={control}
                    initialValues={defaultValues}
                    showMode={showMode}
                />

                {selectedScheme && (
                    <CompensationSchemeDescription
                        compensationScheme={selectedScheme}
                    />
                )}
            </TabPanel>

            {!isEmpty(errors) && (
                <Alert severity="warning">
                    {t('Complete all fields with the correct information')}
                </Alert>
            )}

            {!showMode && (
                <BottomActions>
                    <Box sx={{ pt: 2 }}>
                        <Button disabled={isSavingRate} type="submit">
                            {t('Save')}
                        </Button>
                    </Box>
                </BottomActions>
            )}
        </form>
    );
};

CertidiedRateFormTabs.propTypes = {
    control: PropTypes.object,
    getValues: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSavingRate: PropTypes.bool,
    othersUnitsDataToSelect: PropTypes.array,
    rateName: PropTypes.string,
    saveRate: PropTypes.func,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
};

export default CertidiedRateFormTabs;
