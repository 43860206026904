import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'sunwise-ui';

import { getColorButtonByStatus } from 'common/utils/helpers';

import { DOCUMENT_STATUS } from '../constants';

const DocumentStatus = ({ disabled = false, onClick, status, sxButton }) => {
    const { t } = useTranslation();

    switch (status) {
        case DOCUMENT_STATUS.APPROVED_STATUS.key:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    startIcon={<CheckIcon />}
                    sx={{
                        width: { xs: '100%', md: 'auto' },
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: 'inherit',
                        },
                        ...sxButton,
                    }}
                    variant="outlined"
                >
                    {t(DOCUMENT_STATUS.APPROVED_STATUS.value)}
                </Button>
            );
        case DOCUMENT_STATUS.REJECTED_STATUS.key:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    startIcon={<CloseIcon />}
                    sx={{
                        color: getColorButtonByStatus(status),
                        width: { xs: '100%', md: 'auto' },
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: 'inherit',
                        },
                        ...sxButton,
                    }}
                    variant="outlined"
                >
                    {t(DOCUMENT_STATUS.REJECTED_STATUS.value)}
                </Button>
            );
        case DOCUMENT_STATUS.REQUESTED_STATUS.key:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    sx={{
                        width: { xs: '100%', md: 'auto' },
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: 'inherit',
                        },
                        ...sxButton,
                    }}
                    variant="outlined"
                >
                    {t(DOCUMENT_STATUS.REQUESTED_STATUS.value)}
                </Button>
            );
        case DOCUMENT_STATUS.DOWNLOAD_STATUS.key:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    sx={{
                        width: { xs: '100%', md: 'auto' },
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: 'inherit',
                        },
                        ...sxButton,
                    }}
                    variant="outlined"
                >
                    {t(DOCUMENT_STATUS.DOWNLOAD.value)}
                </Button>
            );
        case DOCUMENT_STATUS.INPROCESS_STATUS.key:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    sx={{
                        width: { xs: '100%', md: 'auto' },
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: 'inherit',
                        },
                        ...sxButton,
                    }}
                    variant="outlined"
                >
                    {t(DOCUMENT_STATUS.INPROCESS_STATUS.value)}
                </Button>
            );
        default:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    sx={{
                        width: { xs: '100%', md: 'auto' },
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: 'inherit',
                        },
                        ...sxButton,
                    }}
                    variant="outlined"
                >
                    {t(DOCUMENT_STATUS.NO_DOCUMENT_STATUS.value)}
                </Button>
            );
    }
};

DocumentStatus.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sxButton: PropTypes.object,
};

export default DocumentStatus;
