import API from '../..';

export const createUserAccount = (data) =>
    API.post('/api/v1/register-user/', data);
export const getAuthMe = () => API.get('/api/v1/me/');
export const login = (data) => API.post('/api/v1/login/', data);
export const resetPasswordService = (data, id, token) =>
    API.post(`/api/v1/users/change-password/${id}/${token}/`, data);
export const sendResetPasswordEmailService = (data) =>
    API.post('/api/v1/reset-password/', data);
