import { NAME } from './constants';

export const FETCH_ACCESSORY_BRANDS = `${NAME}/FETCH_ACCESSORY_BRANDS`;
export const FETCH_ACCESSORY_BRANDS_FAILURE = `${NAME}/FETCH_ACCESSORY_BRANDS_FAILURE`;
export const FETCH_ACCESSORY_BRANDS_SUCCESS = `${NAME}/FETCH_ACCESSORY_BRANDS_SUCCESS`;

export const FETCH_BATTERY_BRANDS = `${NAME}/FETCH_BATTERY_BRANDS`;
export const FETCH_BATTERY_BRANDS_FAILURE = `${NAME}/FETCH_BATTERY_BRANDS_FAILURE`;
export const FETCH_BATTERY_BRANDS_SUCCESS = `${NAME}/FETCH_BATTERY_BRANDS_SUCCESS`;

export const FETCH_BATTERY_PRODUCTS = `${NAME}/FETCH_BATTERY_PRODUCTS`;
export const FETCH_BATTERY_PRODUCTS_FAILURE = `${NAME}/FETCH_BATTERY_PRODUCTS_FAILURE`;
export const FETCH_BATTERY_PRODUCTS_SUCCESS = `${NAME}/FETCH_BATTERY_PRODUCTS_SUCCESS`;

export const FETCH_DOLLAR_PRICE = `${NAME}/FETCH_DOLLAR_PRICE`;
export const FETCH_DOLLAR_PRICE_FAILURE = `${NAME}/FETCH_DOLLAR_PRICE_FAILURE`;
export const FETCH_DOLLAR_PRICE_SUCCESS = `${NAME}/FETCH_DOLLAR_PRICE_SUCCESS`;

export const FETCH_INVERTER_BRANDS = `${NAME}/FETCH_INVERTER_BRANDS`;
export const FETCH_INVERTER_BRANDS_FAILURE = `${NAME}/FETCH_INVERTER_BRANDS_FAILURE`;
export const FETCH_INVERTER_BRANDS_SUCCESS = `${NAME}/FETCH_INVERTER_BRANDS_SUCCESS`;

export const FETCH_ONBOARDING = `${NAME}/FETCH_ONBOARDING`;
export const FETCH_ONBOARDING_FAILURE = `${NAME}/FETCH_ONBOARDING_FAILURE`;
export const FETCH_ONBOARDING_SUCCESS = `${NAME}/FETCH_ONBOARDING_SUCCESS`;

export const FETCH_INVERTER_PRODUCTS = `${NAME}/FETCH_INVERTER_PRODUCTS`;
export const FETCH_INVERTER_PRODUCTS_FAILURE = `${NAME}/FETCH_INVERTER_PRODUCTS_FAILURE`;
export const FETCH_INVERTER_PRODUCTS_SUCCESS = `${NAME}/FETCH_INVERTER_PRODUCTS_SUCCESS`;

export const FETCH_MEMBER_ROLES = `${NAME}/FETCH_MEMBER_ROLES`;
export const FETCH_MEMBER_ROLES_FAILURE = `${NAME}/FETCH_MEMBER_ROLES_FAILURE`;
export const FETCH_MEMBER_ROLES_SUCCESS = `${NAME}/FETCH_MEMBER_ROLES_SUCCESS`;

export const FETCH_PANEL_BRANDS = `${NAME}/FETCH_PANEL_BRANDS`;
export const FETCH_PANEL_BRANDS_FAILURE = `${NAME}/FETCH_PANEL_BRANDS_FAILURE`;
export const FETCH_PANEL_BRANDS_SUCCESS = `${NAME}/FETCH_PANEL_BRANDS_SUCCESS`;

export const FETCH_PANEL_PRODUCTS = `${NAME}/FETCH_PANEL_PRODUCTS`;
export const FETCH_PANEL_PRODUCTS_FAILURE = `${NAME}/FETCH_PANEL_PRODUCTS_FAILURE`;
export const FETCH_PANEL_PRODUCTS_SUCCESS = `${NAME}/FETCH_PANEL_PRODUCTS_SUCCESS`;

export const SAVE_AND_FINISH_ONBOARDING = `${NAME}/SAVE_AND_FINISH_ONBOARDING`;
export const SAVE_AND_FINISH_ONBOARDING_FAILURE = `${NAME}/SAVE_AND_FINISH_ONBOARDING_FAILURE`;
export const SAVE_AND_FINISH_ONBOARDING_SUCCESS = `${NAME}/SAVE_AND_FINISH_ONBOARDING_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_SAVE_ERRORS = `${NAME}/RESET_SAVE_ERRORS`;

export const SAVE_COMPANY = `${NAME}/SAVE_COMPANY`;
export const SAVE_COMPANY_FAILURE = `${NAME}/SAVE_COMPANY_FAILURE`;
export const SAVE_COMPANY_SUCCESS = `${NAME}/SAVE_COMPANY_SUCCESS`;

export const SAVE_DETAILS = `${NAME}/SAVE_DETAILS`;
export const SAVE_DETAILS_FAILURE = `${NAME}/SAVE_DETAILS_FAILURE`;
export const SAVE_DETAILS_SUCCESS = `${NAME}/SAVE_DETAILS_SUCCESS`;

export const SAVE_INVERTERS_FAILURE = `${NAME}/SAVE_INVERTERS_FAILURE`;

export const SAVE_PANELS_FAILURE = `${NAME}/SAVE_PANELS_FAILURE`;

export const SET_COMPLETED_STEP = `${NAME}/SET_COMPLETED_STEP`;
export const SET_CURRENT_STEP = `${NAME}/SET_CURRENT_STEP`;
export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;

export const UPDATE_SECTION = `${NAME}/UPDATE_SECTION`;
export const UPDATE_SECTION_FAILURE = `${NAME}/UPDATE_SECTION_FAILURE`;
export const UPDATE_SECTION_SUCCESS = `${NAME}/UPDATE_SECTION_SUCCESS`;

export const UPDATE_STEP = `${NAME}/UPDATE_STEP`;

export const VALIDATE_EMAIL = `${NAME}/VALIDATE_EMAIL`;
export const VALIDATE_EMAIL_FAILURE = `${NAME}/VALIDATE_EMAIL_FAILURE`;
export const VALIDATE_EMAIL_SUCCESS = `${NAME}/VALIDATE_EMAIL_SUCCESS`;
