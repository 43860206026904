import i18next from 'i18next';

import { deleteItem } from 'common/api/v1/integrations';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { DELETE, DELETE_FAILURE, DELETE_SUCCESS } from '../actionTypes';
import { webIntegrationsActions } from '../reducer';

import fetchIntegrations from './fetchIntegrations';

export default (id) => (dispatch) => {
    dispatch(webIntegrationsActions[DELETE]());

    deleteItem(id)
        .then(() => {
            dispatch(webIntegrationsActions[DELETE_SUCCESS]());
            dispatch(alerts.actions.close());
            dispatch(fetchIntegrations());

            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                webIntegrationsActions[DELETE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
