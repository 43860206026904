import React from 'react';

import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

import { LabelDecorator } from 'common/components';
import { numberFormat } from 'common/utils/helpers';

const TitleWithDecorator = ({ color, currencyLocale, title, value }) => {
    return (
        <Box display="flex" alignItems="center" gap={1}>
            <LabelDecorator color={color} sx={{ width: '20px' }} />
            <Typography variant="body2">
                {`${title}: ${numberFormat(value, {
                    decimals: 2,
                    locale: currencyLocale,
                    style: 'decimal',
                })} %`}
            </Typography>
        </Box>
    );
};

TitleWithDecorator.propTypes = {
    color: PropTypes.string,
    currencyLocale: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.number,
};

export default TitleWithDecorator;
