import React, { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import {
    Box,
    ClickAwayListener,
    IconButton,
    Tooltip,
    Typography,
} from 'sunwise-ui';

const TooltipTitle = ({ svgIcon, title, tooltipContent }) => {
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => setOpen(false);
    const handleTooltipOpen = () => setOpen(true);

    return (
        <Box display="flex" alignItems="center" gap="8px">
            {svgIcon}
            <Typography variant="body2" ml={1} sx={{ fontWeight: 'bold' }}>
                {title}
            </Typography>

            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <Tooltip
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        onClose={handleTooltipClose}
                        open={open}
                        title={tooltipContent}
                    >
                        <IconButton
                            aria-label="info-button"
                            ml={1}
                            onClick={handleTooltipOpen}
                        >
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </ClickAwayListener>
        </Box>
    );
};

TooltipTitle.propTypes = {
    svgIcon: PropTypes.object,
    title: PropTypes.string,
    tooltipContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default TooltipTitle;
