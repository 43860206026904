import { getNewPageProjects } from 'common/api/v3/funnelProjects';

import {
    FETCH_NEXT_PAGE,
    FETCH_NEXT_PAGE_FAILURE,
    FETCH_NEXT_PAGE_SUCCESS,
} from '../actionTypes';
import { funnelProjectsActions } from '../reducer';

export default (status, page, filterQuery) => (dispatch) => {
    dispatch(funnelProjectsActions[FETCH_NEXT_PAGE]({ id: status }));

    getNewPageProjects({ filterQuery, page, status })
        .then((response) => {
            const {
                data = [],
                pagination: {
                    links: { next },
                },
            } = response.data;

            const nextPage = next !== null ? page + 1 : null;

            dispatch(
                funnelProjectsActions[FETCH_NEXT_PAGE_SUCCESS]({
                    id: status,
                    nextPage,
                    projects: data,
                }),
            );
        })
        .catch((error) => {
            dispatch(
                funnelProjectsActions[FETCH_NEXT_PAGE_FAILURE]({
                    error,
                    id: status,
                }),
            );
        });
};
