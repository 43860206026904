import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'sunwise-ui';

import EmptyState from 'common/components/EmptyState';

import EmptyContacts from 'resources/EmptyContacts.png';

import ListItems from './ListItems';

const Content = ({
    agents,
    canDelete,
    canModify,
    canView,
    contacts,
    downloadFile,
    filterContacts,
    filterData,
    handleClickBulkItems,
    handleSaveRow,
    hasContacts,
    isFetching,
    isInitializing,
    openFormNewContact,
    paginationData,
    setFilterData,
    tableSettings,
    uploadFile,
}) => {
    const { t } = useTranslation();

    if (!canView || isInitializing) return null;

    if (!isFetching && !isInitializing && !hasContacts)
        return (
            <Card>
                <Card.Body>
                    <Grid container justifyContent="center">
                        <Grid item xs md={10} lg={6}>
                            <EmptyState
                                canModify={canModify}
                                caption={t(
                                    'Add your prospects or customers with their contact information. You will see a complete list here where you can access them',
                                )}
                                classNameCardBody="__intercom_add_contact_area"
                                dataIntercomTarget="Add Contact (Area)"
                                onClick={() => openFormNewContact()}
                                srcImage={EmptyContacts}
                                sxCard={{
                                    border: 0,
                                    boxShadow: 'none !important',
                                }}
                                textButton={t('Add')}
                                title={t('Your directory is empty')}
                            />
                        </Grid>
                    </Grid>
                </Card.Body>
            </Card>
        );

    return (
        <ListItems
            agents={agents}
            canDelete={canDelete}
            canModify={canModify}
            downloadFile={downloadFile}
            filterData={filterData}
            filterItems={filterContacts}
            handleClickBulkItems={handleClickBulkItems}
            handleSaveRow={handleSaveRow}
            isFetching={isFetching}
            items={contacts}
            paginationData={paginationData}
            setFilterData={setFilterData}
            tableSettings={tableSettings}
            uploadFile={uploadFile}
        />
    );
};

Content.propTypes = {
    agents: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    contacts: PropTypes.array,
    downloadFile: PropTypes.func,
    filterContacts: PropTypes.func,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleSaveRow: PropTypes.func,
    hasContacts: PropTypes.bool,
    isFetching: PropTypes.bool,
    isInitializing: PropTypes.bool,
    openFormNewContact: PropTypes.func,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
    uploadFile: PropTypes.func,
};

export default Content;
