import { CONSUMPTIONS_CAPTURE_MODE } from '../constants';
import { getConsumptionsCaptureMode } from '../selectors';

import changeMonths from './changeMonths';
import changeMonthsWithCsvData from './changeMonthsWithCsvData';
import handleCupsResponse from './handleCupsResponse';
import handleLisaResponse from './handleLisaResponse';
import handleReciptData from './handleReciptData';

const getContractedDemandFields = (rateConfiguration, rate) => {
    if (rate?.isCertified) return {};

    const { tiers } = rateConfiguration?.tiers_demand_distribution?.[0] || [];

    if (!tiers?.length) return {};

    const hourlyContractedDemand = {};

    for (const tier of tiers) {
        hourlyContractedDemand[tier.identifier] = {
            label: tier.name,
            placeholder: '99',
            tier: tier.identifier,
            value: null,
        };
    }

    return hourlyContractedDemand;
};

export default ({
        calculateConsumptionWithCsvData = true,
        getValues,
        rate,
        rateConfiguration,
        setValue,
    }) =>
    (dispatch, getState) => {
        if (!rateConfiguration) return;

        const hourlyContractedDemand = getContractedDemandFields(
            rateConfiguration,
            rate,
        );

        const state = getState();
        const consumptionsCaptureMode = getConsumptionsCaptureMode(state);

        const formValues = getValues ? getValues() : {};

        switch (consumptionsCaptureMode) {
            case CONSUMPTIONS_CAPTURE_MODE.LISA:
            case CONSUMPTIONS_CAPTURE_MODE.XML:
            case CONSUMPTIONS_CAPTURE_MODE.ZAP:
                dispatch(
                    handleLisaResponse({
                        formValues,
                        hourlyContractedDemand,
                        rate,
                        rateConfiguration,
                    }),
                );
                break;
            case CONSUMPTIONS_CAPTURE_MODE.SCRAPPER:
                dispatch(handleReciptData({ formValues, rateConfiguration }));
                break;
            case CONSUMPTIONS_CAPTURE_MODE.CSV:
                if (calculateConsumptionWithCsvData)
                    dispatch(
                        changeMonthsWithCsvData({
                            formValues: {
                                ...formValues,
                                hourly_contracted_demand:
                                    hourlyContractedDemand,
                            },
                            rate,
                            rateConfiguration,
                        }),
                    );

                break;
            case CONSUMPTIONS_CAPTURE_MODE.CUPS:
                dispatch(
                    handleCupsResponse({
                        formValues,
                        hourlyContractedDemand,
                        rate,
                        rateConfiguration,
                    }),
                );
                break;
            default:
                dispatch(
                    changeMonths({
                        formValues: {
                            ...formValues,
                            hourly_contracted_demand: hourlyContractedDemand,
                        },
                        periodicityType: !rate.isCertified
                            ? Number.parseInt(rate.periodicityType)
                            : Number.parseInt(formValues.is_bimonthly),
                        rate,
                        rateConfiguration,
                        setValue,
                    }),
                );
                break;
        }
    };
