import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: calc(100% - 15px);
    width: 100%;
`;
