import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

const SupportControlDropdown = ({
    handlePrepareEditReport,
    isVisibleEditButton,
}) => {
    const { t } = useTranslation();
    return (
        <DropdownCardMenu
            items={[
                {
                    handleClick: handlePrepareEditReport,
                    icon: <EditIcon fontSize="small" />,
                    label: t('Edit'),
                    visible: isVisibleEditButton,
                },
                {
                    handleClick: () => {},
                    icon: <SettingsIcon fontSize="small" />,
                    label: t('Setting'),
                },
            ]}
            sxIconButton={{ ml: 'auto' }}
        />
    );
};

SupportControlDropdown.propTypes = {
    handlePrepareEditReport: PropTypes.func,
    isVisibleEditButton: PropTypes.bool,
};

export default SupportControlDropdown;
