import isArray from 'lodash/isArray';

import initializeForm from './initializeForm';
import toggleModalProduct from './toggleModalProduct';

const handleLists = (list) => {
    const temp = {};
    if (list) list.split(',').forEach((item) => (temp[item] = true));
    return temp;
};

const handleMaxMin = (limits) => (limits ? limits.split(',') : [0, 0]);

export default (values, disabled = false) =>
    (dispatch) => {
        const parameters = values.parameters;

        const rules = {};

        values.financier_product_rules.forEach((rule) => {
            rules[rule.key] = rule.value;
        });

        const amount_values = handleMaxMin(rules.amount_values);
        const power_values = handleMaxMin(rules.power_values);
        const parameters_terms = handleLists(
            isArray(parameters) ? parameters[0].terms : '',
        );

        const newValues = {
            amount_values: {
                isChecked: parameters.length
                    ? parameters[0].blind_objects.includes('amount_values')
                    : false,
                max: amount_values[1],
                min: amount_values[0],
            },
            annual_increase: values.parameters[0].annual_increase,
            dealer_fee: values.parameters[0].dealer_fee,
            description: values.description,
            disabled: disabled,
            external_custom_template_id: values?.external_custom_template_id,
            file_percentage: parameters[0].file_percentage
                ? parameters[0].file_percentage
                : null,
            id: values.id,
            name: values.name,
            parameters_commission_credit: {
                isChecked: parameters.length
                    ? parameters[0].blind_objects.includes('commission_credit')
                    : false,
                opening_commission_vat: parameters.length
                    ? parameters[0].opening_commission_vat
                    : 0,
                value: parameters.length ? parameters[0].commission_credit : 0,
                unit: parameters.length
                    ? parameters[0].is_commission_percentage
                        ? 0
                        : 1
                    : 1,
            },
            parameters_hitch: {
                isChecked: parameters.length
                    ? parameters[0].blind_objects.includes('hitch')
                    : false,
                value: parameters.length ? parameters[0].hitch : 0,
                unit: parameters.length
                    ? parameters[0].is_percentage
                        ? 0
                        : 1
                    : 1,
            },
            parameters_insurance: {
                isChecked: parameters.length
                    ? parameters[0].blind_objects.includes('insurance')
                    : false,
                value: parameters.length ? parameters[0].insurance : 0,
            },
            parameters_rate: {
                isChecked: parameters.length
                    ? parameters[0].blind_objects.includes('rate')
                    : false,
                value: parameters.length ? parameters[0].rate : 0,
            },
            parameters_residual: {
                isChecked: parameters.length
                    ? parameters[0].blind_objects.includes('residual')
                    : false,
                value: parameters.length ? parameters[0].residual : 0,
            },
            parameters: parameters.length
                ? { ...parameters[0], terms: parameters_terms }
                : {},
            power_values: {
                isChecked: parameters.length
                    ? parameters[0].blind_objects.includes('power_values')
                    : false,
                max: power_values[1],
                min: power_values[0],
            },
            type_algorithm: values.type_algorithm,
            type: values.type,
            types_business_company: values.types_business_company,
        };

        dispatch(initializeForm(newValues));
        dispatch(toggleModalProduct(true));
    };
