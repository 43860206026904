import { SET_COMPLETED_STEP } from '../actionTypes';
import { onboardingWizardActions } from '../reducer';
import * as selectors from '../selectors';

export default (step) => (dispatch, getState) => {
    const state = getState();
    const completed = selectors.getCompletedSteps(state);
    let newCompleted = Object.assign({}, completed);
    for (let index = 0; index <= 7; index++) {
        if (index <= step) newCompleted[index] = true;
        else newCompleted[index] = false;
    }
    dispatch(onboardingWizardActions[SET_COMPLETED_STEP](newCompleted));
};
