import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box, Skeleton, Typography } from 'sunwise-ui';

import StatusIndicatorTooltip from 'common/components/StatusIndicatorTooltip';

import Breadcrumbs from './Breadcrumbs';
import SupportControlDropdown from './SupportControlDropdown';

const ResponsiveHeader = ({
    handleClickBreadcrumb,
    handlePrepareEditReport,
    isFetchingReportDetails,
    isGenerated,
    isVisibleEditButton,
    reportDetails,
}) => (
    <Box
        display="flex"
        flexDirection="column"
        sx={{ display: { md: 'none', xs: 'block' }, p: 2 }}
    >
        <Box display="flex" alignItems="center" gap={2}>
            <StatusIndicatorTooltip isGenerated={isGenerated} />
            {isFetchingReportDetails ? (
                <Skeleton
                    sx={{ fontSize: '1rem', width: '100%' }}
                    variant="text"
                />
            ) : (
                <Typography variant="h4">
                    {get(reportDetails, 'name', null)}
                </Typography>
            )}
            <SupportControlDropdown
                handlePrepareEditReport={handlePrepareEditReport}
                isVisibleEditButton={isVisibleEditButton}
            />
        </Box>

        {isFetchingReportDetails ? (
            <Skeleton sx={{ fontSize: '1rem', width: '100%' }} variant="text" />
        ) : (
            <Breadcrumbs
                handleClickBreadcrumb={handleClickBreadcrumb}
                pl={5}
                reportDetails={reportDetails}
            />
        )}
    </Box>
);

ResponsiveHeader.propTypes = {
    handleClickBreadcrumb: PropTypes.func,
    handlePrepareEditReport: PropTypes.func,
    isFetchingReportDetails: PropTypes.bool,
    isGenerated: PropTypes.bool,
    isVisibleEditButton: PropTypes.bool,
    reportDetails: PropTypes.object,
};

export default ResponsiveHeader;
