import { NAME } from './constants';

export const CREATE = `${NAME}/CREATE`;
export const CREATE_FAILURE = `${NAME}/CREATE_FAILURE`;
export const CREATE_SUCCESS = `${NAME}/CREATE_SUCCESS`;

export const DELETE = `${NAME}/DELETE`;
export const DELETE_FAILURE = `${NAME}/DELETE_FAILURE`;
export const DELETE_SUCCESS = `${NAME}/DELETE_SUCCESS`;

export const FETCH_OFFER_TAXES = `${NAME}/FETCH_OFFER_TAXES`;
export const FETCH_OFFER_TAXES_FAILURE = `${NAME}/FETCH_OFFER_TAXES_FAILURE`;
export const FETCH_OFFER_TAXES_SUCCESS = `${NAME}/FETCH_OFFER_TAXES_SUCCESS`;

export const INITIAL_VALUES = `${NAME}/INITIAL_VALUES`;
export const INITIALIZE_PRODUCTS_SETTINGS_VALUES = `${NAME}/INITIALIZE_PRODUCTS_SETTINGS_VALUES`;

export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_PRODUCTS_SETTINGS_INITIAL_VALUES = `${NAME}/RESET_PRODUCTS_SETTINGS_INITIAL_VALUES`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SAVE_PRODUCTS_SETTINGS = `${NAME}/SAVE_PRODUCTS_SETTINGS`;
export const SAVE_PRODUCTS_SETTINGS_FAILURE = `${NAME}/SAVE_PRODUCTS_SETTINGS_FAILURE`;
export const SAVE_PRODUCTS_SETTINGS_SUCCESS = `${NAME}/SAVE_PRODUCTS_SETTINGS_SUCCESS`;

export const SET_PRODUCTS_SETTINGS_MODAL_IS_OPEN = `${NAME}/SET_PRODUCTS_SETTINGS_MODAL_IS_OPEN`;
