import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteItem from './deleteItem';

export default (values, setValue) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [i18next.t('Are you really sure to delete the item?')],
        onSuccess: () => dispatch(deleteItem(values, setValue)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
