import i18next from 'i18next';
import get from 'lodash/get';

import { getLocationsByZipCode } from 'common/api/v1/politicalDivisions';
import alerts from 'common/modules/alerts';

import {
    fetchRateDivisions,
    fetchRegionsByPoliticalDivision,
} from '../../projectConsumptionsModal/actions';
import * as projectConsumptionsModalSelectors from '../../projectConsumptionsModal/selectors';
import {
    FETCH_LOCATIONS_ZIP_CODE,
    FETCH_LOCATIONS_ZIP_CODE_SUCCESS,
    FETCH_LOCATIONS_ZIP_CODE_FAILURE,
} from '../actionTypes';
import { importConsumptionsModalActions } from '../reducer';

import prepareConsumptions from './prepareConsumptions';

const onFailure = (setValue, setHasMissingFields, rate) => {
    setValue('political_division1', null);
    setValue('political_division2', null);
    setValue('zip_code', null);
    setValue('rate_division', null);
    setHasMissingFields(() => ({ rate: !rate, zipCode: true }));
};

export default ({
        callbackMasterButton,
        formValues,
        setHasMissingFields,
        setValue,
    }) =>
    (dispatch, getState) => {
        if (!formValues?.zip_code) {
            dispatch(
                importConsumptionsModalActions[
                    FETCH_LOCATIONS_ZIP_CODE_SUCCESS
                ]([]),
            );
            onFailure(setValue, setHasMissingFields, formValues?.rate);
            return;
        }

        if (!formValues?.rate) {
            setHasMissingFields((prev) => ({ ...prev, rate: true }));
            return;
        }

        dispatch(importConsumptionsModalActions[FETCH_LOCATIONS_ZIP_CODE]());

        getLocationsByZipCode(formValues.zip_code)
            .then(({ data }) => {
                dispatch(
                    importConsumptionsModalActions[
                        FETCH_LOCATIONS_ZIP_CODE_SUCCESS
                    ](data || []),
                );

                if (data.length > 0) {
                    const ratesDictionary =
                        projectConsumptionsModalSelectors.getRatesDictionary(
                            getState(),
                        );
                    const rate = ratesDictionary[formValues.rate];

                    const values = data[0];
                    setValue('political_division1', values.state.id);

                    if (data.length === 1)
                        setValue('political_division2', values.municipality.id);

                    let promises = [];

                    if (rate?.isCertified)
                        promises.push(
                            dispatch(
                                fetchRateDivisions(
                                    values.state.id,
                                    values?.rate_division?.id || null,
                                    setValue,
                                ),
                            ),
                            dispatch(
                                fetchRegionsByPoliticalDivision(
                                    values.state.id,
                                    setValue,
                                ),
                            ),
                        );

                    Promise.all(promises).then(([rateDivision]) => {
                        dispatch(
                            prepareConsumptions({
                                callbackMasterButton,
                                formValues,
                                rate,
                                rateDivision,
                            }),
                        );
                    });
                }
            })
            .catch((error) => {
                const errors = get(error, ['response', 'data', 'errors'], []);

                dispatch(
                    importConsumptionsModalActions[
                        FETCH_LOCATIONS_ZIP_CODE_FAILURE
                    ](errors),
                );
                dispatch(
                    importConsumptionsModalActions[
                        FETCH_LOCATIONS_ZIP_CODE_SUCCESS
                    ]([]),
                );

                if (errors?.length > 0)
                    dispatch(
                        alerts.actions.show({
                            confirmText: i18next.t('Close'),
                            hideButtons: false,
                            messages: errors,
                            title: i18next.t('Message'),
                            type: alerts.ALERT_TYPE_ALERT,
                            variant: 'warning',
                        }),
                    );

                onFailure(setValue, setHasMissingFields, formValues.rate);
            });
    };
