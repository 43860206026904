import { createSlice } from '@reduxjs/toolkit';

import orm, { modelPopulateByArray } from 'common/orm';

import {
    DELETE_COMPANIES_CATALOGS,
    DELETE_COMPANIES_CATALOGS_FAILURE,
    DELETE_COMPANIES_CATALOGS_SUCCESS,
    FETCH_COMPANIES_CATALOGS,
    FETCH_COMPANIES_CATALOGS_FAILURE,
    FETCH_COMPANIES_CATALOGS_SUCCESS,
    INITIALIZE_FORM,
    OPEN_MODAL,
    RESET_FORM,
    RESET_SAVE,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_IS_NEW_RECORD,
    SET_TYPE,
    UPDATE_ACTIVE_STATUS,
    UPDATE_ACTIVE_STATUS_FAILURE,
    UPDATE_ACTIVE_STATUS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    entities: orm.getEmptyState(),
    closeInEscape: false,
    companiesCatalogs: {
        errors: null,
        isFetching: false,
    },
    deleteItem: {
        data: [],
        errors: null,
        isDeleting: false,
    },
    initialValues: {
        name: '',
        primary_color: '#f5a623',
        type: 5,
    },
    isOpenModal: false,
    isNewRecord: true,
    saveItem: {
        data: {},
        errors: null,
        isSaving: false,
    },
    updatingActiveStatusId: null,
    type: null,
};

const listSettingsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_COMPANIES_CATALOGS]: (state) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_COMPANIES_CATALOGS_FAILURE]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_COMPANIES_CATALOGS_SUCCESS]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                data: action.payload,
                isDeleting: false,
            };
        },
        [FETCH_COMPANIES_CATALOGS]: (state) => {
            state.companiesCatalogs = {
                ...state.companiesCatalogs,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_COMPANIES_CATALOGS_FAILURE]: (state, action) => {
            state.companiesCatalogs = {
                ...state.companiesCatalogs,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMPANIES_CATALOGS_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { CompanyCatalog } = session;
            modelPopulateByArray(CompanyCatalog, action.payload, true);

            state.companiesCatalogs.isFetching = false;
            state.entities = session.state;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [RESET_SAVE]: (state) => {
            state.saveItem = INITIAL_STATE.initialValues;
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [SET_TYPE]: (state, action) => {
            state.type = action.payload;
        },
        [UPDATE_ACTIVE_STATUS]: (state, action) => {
            state.updatingActiveStatusId = action.payload;
        },
        [UPDATE_ACTIVE_STATUS_FAILURE]: (state) => {
            state.updatingActiveStatusId = null;
        },
        [UPDATE_ACTIVE_STATUS_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { CompanyCatalog } = session;
            CompanyCatalog.withId(state.updatingActiveStatusId).modify({
                is_active: action.payload,
            });

            state.updatingActiveStatusId = null;
            state.entities = session.state;
        },
    },
});

export const listSettingsActions = listSettingsSlice.actions;

export default listSettingsSlice.reducer;
