import { filterNotifications } from 'common/api/v1/notifications';

import {
    FETCH_NAV_NOTIFICATIONS,
    FETCH_NAV_NOTIFICATIONS_FAILURE,
    FETCH_NAV_NOTIFICATIONS_SUCCESS,
} from '../actionTypes';
import { notificationsActions } from '../reducer';

export default (showMentions = false) =>
    (dispatch) => {
        dispatch(notificationsActions[FETCH_NAV_NOTIFICATIONS]());

        filterNotifications({ isOnDropdown: true, page: 1, showMentions })
            .then((response) =>
                dispatch(
                    notificationsActions[FETCH_NAV_NOTIFICATIONS_SUCCESS](
                        response.data,
                    ),
                ),
            )
            .catch((error) =>
                dispatch(
                    notificationsActions[FETCH_NAV_NOTIFICATIONS_FAILURE](
                        error,
                    ),
                ),
            );
    };
