import get from 'lodash/get';

import { convertCurrency } from 'common/utils/helpers';

import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';
import { INITIAL_VALUES } from '../actionTypes';
import { proposalGeneratorPanelTableActions } from '../reducer';

export default (offerPanels) => (dispatch, getState) => {
    const offerDetailsComplements =
        proposalGeneratorOneColumnSelectors.getOfferDetailsComplements(
            getState(),
        );

    const conversionFactor = convertCurrency({
        amount: 1,
        exchangeRate: offerDetailsComplements?.type_change,
        fromCurrency: offerDetailsComplements?.currency?.abbreviation,
        toCurrency: 'USD',
    });

    const items = offerPanels.map((item) => {
        return {
            cost: item.cost,
            cost_by_size: (conversionFactor * item?.cost) / item?.watts || 0,
            discount: item.discount,
            discount_type: item.discount_type,
            expansion: item.expansion,
            final_cost: item.final_cost,
            id: item.id,
            item: get(item, 'panel_branch_office.id', null),
            margin: item.margin,
            percentage_discount: item.percentage_discount,
            quantity: item.quantity,
            selectedProduct: get(item, 'panel_branch_office.id', null),
            slider_top_value: item.slider_top_value,
            temp_cost: item.cost,
            temp_discount: item.discount,
            temp_margin: item.margin,
            temp_quantity: item.quantity,
            total_size: item.watts,
            unit_price: item.unit_price,
            watts: get(item, 'panel_branch_office.watts', 0),
        };
    });

    dispatch(proposalGeneratorPanelTableActions[INITIAL_VALUES]({ items }));
};
