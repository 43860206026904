import { createItem, updateItem } from 'common/api/v1/irradiations';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';
import * as selectors from '../selectors';

import fetchIrradiationByStateAndSources from './fetchIrradiationByStateAndSources';
import toggleModal from './toggleModal';

export default (values, setValue) => (dispatch, getState) => {
    const newValues = {
        keep_or_delete: values.keep_or_delete,
        temp_max: values.temp_max,
        temp_min: values.temp_min,
        political_division1: values.political_division,
        political_division2: values.political_division2,
        saved_by: values.saved_by,
        source: 3,
        monthly_solar_radiation_hrsol: JSON.stringify(
            values.hrsSol.map((data, index) => {
                return {
                    unit: 2,
                    month: index + 1,
                    year: 2019,
                    average: data.value,
                };
            }),
        ),
        monthly_solar_radiation_kwhkw: JSON.stringify(
            values.kwhkw.map((data, index) => {
                return {
                    unit: 0,
                    month: index + 1,
                    year: 2019,
                    average: data.value,
                };
            }),
        ),
    };

    if (!values.id) {
        const state = getState();
        const defaultSourceData = selectors.getDefaultSourceData();

        newValues.origin = defaultSourceData(state)(values.source)[0].id;
    }

    dispatch(solarGenerationActions[SAVE_ITEM]());
    const save = values.id
        ? () => updateItem(newValues, values.id)
        : () => createItem(newValues);

    save()
        .then((response) => {
            dispatch(solarGenerationActions[SAVE_ITEM_SUCCESS](response.data));
            dispatch(toggleModal(false));
            showToast();
            dispatch(
                fetchIrradiationByStateAndSources(
                    values.political_division2,
                    newValues.political_division1,
                    setValue,
                ),
            );
        })
        .catch((error) => {
            dispatch(
                solarGenerationActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
