import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import initializeForm from './initializeForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModalProduct from './toggleModalProduct';

const mapItems = (items) => {
    if (isEmpty(items)) return [{ item: '' }];
    return items.map((item) => ({ item: item.id }));
};

const handleLists = (list) => {
    const temp = {};
    if (list) list.forEach((item) => (temp[item] = true));
    return temp;
};

export default (item, disabled = false) =>
    (dispatch) => {
        if (item) {
            const newValues = {
                accessories: mapItems(item.accessories_branch_office),
                additionals: mapItems(item.additionals_branch_office),
                battery: get(item, ['battery_branch_office', 'id'], ''),
                branch_office: get(item, ['branch_office', 'id'], ''),
                considerations: item.considerations,
                disabled: disabled,
                external_custom_template_id: get(
                    item,
                    ['external_custom_template_id'],
                    '',
                ),
                guaranteed_gen: item.guaranteed_gen,
                guaranteed_gen_active: item.guaranteed_gen_active || false,
                id: item.id,
                installation_type: handleLists(item.installation_type),
                inverter_brand: get(item, ['inverter_brand', 'id'], ''),
                name: item.name,
                panel: get(item, ['panel_branch_office', 'id'], ''),
                saving_percentage: item.saving_percentage,
                structures: mapItems(item.structures),
                validity: item.validity,
                workforce_and_electric_materials: mapItems(
                    item.workforce_and_electric_materials_branch_office,
                ),

                bag_years: item.simulation_years,
                deprecated: item.pre_2018_enabled,
                generation_delay: item.start_in_months,
                isr: item.tax_incentive_enabled,
                isr_percentage: item.tax_incentive_percentage,
                isr_simulation_type: item.isr_simulation_type,
                isr_simulation_annual: item.isr_simulation,
                isr_simulation_monthly: item.isr_simulation,
                opex: item.opex,
                opex_percentage_increase: item.opex_percentage,
                opex_periodicity: item.opex_periodicity,

                apply_desired_power_factor: item.desired_power_factor_enabled,
                desired_power_factor: item.desired_power_factor,
                ppa_active: item.ppa_active,
                ppa_price: item.ppa_price,
            };
            dispatch(initializeForm(newValues));
        }
        dispatch(setIsNewRecord(false));
        dispatch(toggleModalProduct(true));
    };
