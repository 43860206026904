import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from 'sunwise-ui';

import { getDownloadFormatSelected, getDownloadOptions } from '../helpers';

const SideMenuQualityDropdown = ({
    downloadFormat,
    editionDisabled,
    setDownloadFormat,
}) => {
    const downloadOptions = getDownloadOptions();

    const format = getDownloadFormatSelected(downloadFormat, downloadOptions);

    const handleSelectDownloadFormat = (e, format) => {
        localStorage.setItem('downloadFormat', format);
        setDownloadFormat(format);
        e.preventDefault();
        handleClose();
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <Button
                disabled={!editionDisabled || downloadOptions.length === 0}
                endIcon={<KeyboardArrowDownIcon />}
                fullWidth
                onClick={handleClick}
                variant="outlined"
            >
                {format}
            </Button>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {downloadOptions.map(
                    (item) =>
                        !item.hidden && (
                            <MenuItem
                                disabled={item.disabled}
                                key={`download-item-${item.value}`}
                                onClick={(e) =>
                                    handleSelectDownloadFormat(e, item.value)
                                }
                            >
                                {item.name}
                            </MenuItem>
                        ),
                )}
            </Menu>
        </>
    );
};

SideMenuQualityDropdown.propTypes = {
    downloadFormat: PropTypes.string,
    editionDisabled: PropTypes.bool,
    setDownloadFormat: PropTypes.func,
};

export default SideMenuQualityDropdown;
