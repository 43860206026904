import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import bulkItems from './bulkItems';

export default (ids, value, filterData, callback) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        haveTextCaptcha: true,
        messages: [
            i18next.t('Are you sure to remove {{items}} items?', {
                items: ids.length,
            }),
        ],
        onCancel: () => callback(),
        onSuccess: () => dispatch(bulkItems(ids, value, filterData, callback)),
        textToValidate: i18next.t('Delete').toUpperCase(),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
