import API from '../..';
const ENTITY = 'panels';

export const filterItems = ({
    brandName = '',
    maxPower = null,
    orderBy = null,
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/paginate/filter/${searchText}`, {
        params: {
            ...(brandName ? { brand: brandName } : {}),
            ...(maxPower ? { max_power: maxPower } : {}),
            order_by: orderBy,
            page: page,
            per_page: perPage,
            sort: sortBy,
        },
    });
