import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const IconPanelSystem = ({ color, height = '24px', width = '24px' }) => {
    const theme = useTheme();
    const fillColor = color || theme.palette.primary.main;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.81438 4.75526L7.13362 5.09107C7.45285 5.41827 7.45285 5.96934 7.13362 6.29655L7.12543 6.30516C6.8062 6.64097 6.29869 6.64097 5.97946 6.30516L5.66022 5.96935C5.34098 5.64214 5.34098 5.09968 5.66022 4.76387L5.6684 4.75526C5.97946 4.42805 6.49514 4.41944 6.81438 4.75526Z"
                fill={fillColor}
            />
            <path
                d="M3.99982 10.2145H4.83475C5.28495 10.2145 5.64511 10.5934 5.64511 11.0756V11.0842C5.64511 11.5578 5.28495 11.9366 4.82656 11.928H3.99982C3.54962 11.928 3.18945 11.5492 3.18945 11.0756V11.067C3.18945 10.5934 3.54962 10.2145 3.99982 10.2145Z"
                fill={fillColor}
            />
            <path
                d="M12.0122 2.22363H12.0204C12.4706 2.22363 12.8308 2.6025 12.8308 3.07608V3.9113C12.8308 4.38489 12.4706 4.76375 12.0122 4.75514H12.004C11.5538 4.75514 11.1936 4.37627 11.1936 3.90269V3.07608C11.1936 2.6025 11.5538 2.22363 12.0122 2.22363Z"
                fill={fillColor}
            />
            <path
                d="M17.1936 4.75526C17.5129 4.42805 18.0286 4.42805 18.3478 4.76387C18.667 5.09968 18.667 5.63353 18.3478 5.96935L18.0286 6.30516C17.7175 6.64097 17.2018 6.64097 16.8826 6.30516L16.8744 6.29655C16.5552 5.96934 16.5552 5.42688 16.8744 5.09107L17.1936 4.75526Z"
                fill={fillColor}
            />
            <path
                d="M18.5524 11.0756V11.067C18.5524 10.5934 18.9126 10.2145 19.3628 10.2145H20.1896C20.6398 10.2145 20.9999 10.5934 20.9999 11.067V11.0756C20.9999 11.5492 20.6398 11.928 20.1896 11.928H19.3628C18.9126 11.928 18.5524 11.5492 18.5524 11.0756Z"
                fill={fillColor}
            />
            <path
                d="M12.004 6.5C14.7134 6.5 17.5 8.5 17.5 12C16.1212 12.0067 12.1602 12.0037 9.23366 12.0016C8.09473 12.0007 7.1123 12 6.5 12C6.5 8.5 9.2946 6.5 12.004 6.5Z"
                fill={fillColor}
            />
            <path
                d="M9.16814 13.3676C9.195 13.1723 9.02616 13 8.80803 13H5.04539C4.87002 13 4.71974 13.1131 4.68837 13.2687L4.41266 14.6364H8.99362L9.16814 13.3676Z"
                fill={fillColor}
            />
            <path
                d="M8.88107 15.4545H4.24772L3.75289 17.9091H8.54345L8.88107 15.4545Z"
                fill={fillColor}
            />
            <path
                d="M9.79521 15.4545L9.45758 17.9091H14.5421L14.2044 15.4545H9.79521Z"
                fill={fillColor}
            />
            <path
                d="M8.4309 18.7273H3.58795L3.00596 21.6142C2.96546 21.8151 3.13658 22 3.36298 22H7.6601C7.84323 22 7.99766 21.8769 8.02021 21.713L8.4309 18.7273Z"
                fill={fillColor}
            />
            <path
                d="M14.6546 18.7273H9.34504L8.94543 21.6324C8.91858 21.8277 9.08741 22 9.30554 22H14.6941C14.9122 22 15.0811 21.8277 15.0542 21.6324L14.6546 18.7273Z"
                fill={fillColor}
            />
            <path
                d="M15.5688 18.7273L15.9794 21.713C16.002 21.8769 16.1564 22 16.3396 22H20.637C20.8634 22 21.0345 21.8151 20.994 21.6142L20.4121 18.7273H15.5688Z"
                fill={fillColor}
            />
            <path
                d="M19.7523 15.4545L20.2471 17.9091H15.4562L15.1186 15.4545H19.7523Z"
                fill={fillColor}
            />
            <path
                d="M15.006 14.6364H19.5873L19.3116 13.2687C19.2803 13.1131 19.13 13 18.9546 13H15.1916C14.9735 13 14.8047 13.1723 14.8315 13.3676L15.006 14.6364Z"
                fill={fillColor}
            />
            <path
                d="M9.90775 14.6364H14.0919L13.9063 13.287C13.8838 13.1231 13.7293 13 13.5462 13H10.4535C10.2703 13 10.1159 13.1231 10.0934 13.287L9.90775 14.6364Z"
                fill={fillColor}
            />
        </svg>
    );
};

IconPanelSystem.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconPanelSystem;
