import React from 'react';

import { Box, Divider } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getCountryCurrencySymbol } from 'common/utils/helpers/session';

import * as analysisConsumptionVsGenerationSelectors from '../../proposalGeneratorConsumptionAnalysisVsGeneration/selectors';
import * as actions from '../actions';
import { RATE_TYPES } from '../constants';
import * as selectors from '../selectors';

import DataMismatchWarning from './DataMismatchWarning';
import RateDetails from './RateDetails';
import RateFormModal from './RateFormModal';
import RateTaxes from './RateTaxes';

const FormContainer = ({
    canModifyRates,
    commercialOfferId,
    initializeRateForm,
    isLocked,
    nextRateData,
    prevRateData,
    rateConcepts,
    resetRateConcepts,
    setRateFormModalOpen,
}) => {
    const { t } = useTranslation();
    const countryCurrencySymbol = getCountryCurrencySymbol();

    const onClickEditRate = (rate, rateName) => {
        initializeRateForm({ ...rate, rate_name: rateName });
        setRateFormModalOpen(true);
    };

    const onClickResetRateConcepts = (rates = []) =>
        resetRateConcepts(commercialOfferId, rates);

    if (!rateConcepts?.has_extra_concepts_alignment)
        return (
            <DataMismatchWarning
                canModifyRates={canModifyRates}
                isLocked={isLocked}
                onClickResetRateConcepts={onClickResetRateConcepts}
            />
        );

    return (
        <Box mx={2}>
            <RateTaxes
                commercialOfferId={commercialOfferId}
                nextRateData={nextRateData}
                prevRateData={prevRateData}
            />

            {rateConcepts?.current_rate && (
                <>
                    <RateDetails
                        canModifyRates={canModifyRates}
                        isLocked={isLocked}
                        name={`${prevRateData?.name} (${t('Initial rate')})`}
                        onClickEditRate={() =>
                            onClickEditRate(
                                rateConcepts?.current_rate,
                                prevRateData?.name,
                            )
                        }
                        onClickResetRateConcepts={() =>
                            onClickResetRateConcepts([RATE_TYPES.CURRENT])
                        }
                    />

                    <Divider sx={{ my: 1 }} />
                </>
            )}

            {rateConcepts?.next_rate && (
                <RateDetails
                    canModifyRates={canModifyRates}
                    isLocked={isLocked}
                    name={`${nextRateData?.name} (${t('Proposed rate')})`}
                    onClickEditRate={() =>
                        onClickEditRate(
                            rateConcepts?.next_rate,
                            nextRateData?.name,
                        )
                    }
                    onClickResetRateConcepts={() =>
                        onClickResetRateConcepts([RATE_TYPES.NEXT])
                    }
                />
            )}

            <RateFormModal
                commercialOfferId={commercialOfferId}
                countryCurrencySymbol={countryCurrencySymbol}
            />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    nextRateData:
        analysisConsumptionVsGenerationSelectors.getSelectedNextRateData,
    prevRateData:
        analysisConsumptionVsGenerationSelectors.getSelectedPrevRateData,
    rateConcepts: selectors.getRateConceptsFormattedData,
});

const mapDispatchToProps = (dispatch) => ({
    initializeRateForm: (values) =>
        dispatch(actions.initializeRateForm(values)),
    resetRateConcepts: (commercialOfferId, rates) =>
        dispatch(actions.prepareResetRateConcepts(commercialOfferId, rates)),
    setRateFormModalOpen: (isOpen) =>
        dispatch(actions.setRateFormModalOpen(isOpen)),
});

FormContainer.propTypes = {
    canModifyRates: PropTypes.bool,
    commercialOfferId: PropTypes.string,
    initializeRateForm: PropTypes.func,
    isLocked: PropTypes.bool,
    nextRateData: PropTypes.object,
    prevRateData: PropTypes.object,
    rateConcepts: PropTypes.object,
    resetRateConcepts: PropTypes.func,
    setRateFormModalOpen: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
