import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Divider, Grid, IconButton, Typography } from 'sunwise-ui';

import {
    ReactHookFormCheckboxButtonGroup,
    ReactHookFormInput,
    ReactHookFormSelect,
    ReactHookFormSlider,
} from 'common/components/form/bootstrap';
import TextVariant from 'common/components/TextVariant';
import MainBranchOfficeSelectorRHF from 'common/modules/multiBranches/components/MainBranchOfficeSelectorRHF';

import {
    getOptionsToSelect,
    getOptionsToSelectForRHFArray,
    getTemplatesToSelect,
} from '../helpers';
import * as selectors from '../selectors';

import ReactHookFormArray from './ReactHookFormArray';

const FieldContainer = styled('div')`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 22px;
`;

const StyledLabel = styled(Typography)`
    align-items: center;
    color: #848bab;
    cursor: pointer;
    display: inline-flex;
    gap: 8px;
`;

const TabGeneral = ({
    accessories,
    additionals,
    batteries,
    canModifyEnergyBackup,
    control,
    getValues,
    inverters,
    isDisabled,
    isFetchingPlan,
    isInitializing,
    isNewRecord,
    panels,
    planData,
    setValue,
    structures,
    templates,
    watch,
    workforce,
}) => {
    const { t } = useTranslation();

    const [id, battery, branchOffice] = watch([
        'id',
        'battery',
        'branch_office',
    ]);

    const isDisabledBatteryField =
        !branchOffice ||
        !canModifyEnergyBackup ||
        isDisabled ||
        isFetchingPlan ||
        isInitializing;

    const isDisabledCatalogFields =
        !branchOffice || isDisabled || isFetchingPlan || isInitializing;

    return (
        <>
            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                name="name"
                label={t('Name')}
                placeholder={t('Plan name')}
            />

            <MainBranchOfficeSelectorRHF
                control={control}
                disabled={id !== null || isDisabled}
                hideCompany
                label={t('Branch office')}
                name="branch_office"
            />

            <ReactHookFormSelect
                control={control}
                disabled={isDisabledCatalogFields}
                label={t('Template')}
                name="external_custom_template_id"
                options={getTemplatesToSelect(templates, isNewRecord, planData)}
            />

            <Divider sx={{ mb: 2 }} />

            <p>{t('Choose the type of installation')}</p>

            <ReactHookFormCheckboxButtonGroup
                control={control}
                disabled={isDisabledCatalogFields}
                items={[
                    {
                        label: (
                            <StyledLabel component="span">
                                <HomeIcon />
                                {t('Residential')}
                            </StyledLabel>
                        ),
                        value: 0,
                    },
                    {
                        label: (
                            <StyledLabel component="span">
                                <StorefrontIcon />
                                {t('Commercial')}
                            </StyledLabel>
                        ),
                        value: 1,
                    },
                    {
                        label: (
                            <StyledLabel component="span">
                                <BusinessIcon />
                                {t('Industrial')}
                            </StyledLabel>
                        ),
                        value: 2,
                    },
                ]}
                name="installation_type"
                row
            />

            <Divider sx={{ mb: 2 }} />

            <FieldContainer>
                <Box sx={{ mb: 2 }}>
                    <TextVariant
                        dot="#3f4fae"
                        text={t('Panel model')}
                        variant="bold"
                    />
                </Box>

                <ReactHookFormSelect
                    control={control}
                    disabled={isDisabledCatalogFields}
                    name="panel"
                    options={getOptionsToSelect({
                        data: panels,
                        isNewRecord,
                        label: t('Select panel'),
                        planData,
                        prefix: 'panel_branch_office',
                    })}
                />
            </FieldContainer>

            <Divider sx={{ mb: 2 }} />

            <FieldContainer>
                <Box sx={{ mb: 2 }}>
                    <TextVariant
                        dot="#41c4d9"
                        text={t('Inverter brand')}
                        variant="bold"
                    />
                </Box>

                <ReactHookFormSelect
                    control={control}
                    disabled={isDisabledCatalogFields}
                    name="inverter_brand"
                    options={getOptionsToSelect({
                        data: inverters,
                        isNewRecord,
                        label: t('Select inverter'),
                        planData,
                        prefix: 'inverter_brand',
                    })}
                />
            </FieldContainer>

            <Divider sx={{ mb: 2 }} />

            <FieldContainer>
                <Box sx={{ mb: 2 }}>
                    <TextVariant
                        dot="#8bc34a"
                        text={t('Storage model')}
                        variant="bold"
                    />
                </Box>

                <Box display="flex" gap={1}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isDisabledBatteryField}
                        name="battery"
                        options={getOptionsToSelect({
                            data: batteries,
                            isNewRecord,
                            label: t('Select an storage'),
                            planData,
                            prefix: 'battery_branch_office',
                        })}
                    />
                    <Box hidden={isEmpty(battery)}>
                        <IconButton
                            disabled={isDisabledBatteryField}
                            onClick={() => setValue('battery', '')}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            </FieldContainer>

            <Divider sx={{ mb: 2 }} />

            <ReactHookFormArray
                control={control}
                disabled={isDisabledCatalogFields}
                dot="#f9c109"
                getValues={getValues}
                groupName="accessories"
                itemsToSelect={getOptionsToSelectForRHFArray({
                    data: accessories,
                    isNewRecord,
                    label: t('Select accessories'),
                    planData,
                    prefix: 'accessories_branch_office',
                })}
                label={t('Accessory', { count: 2 })}
            />

            <Divider sx={{ mb: 2 }} />

            <ReactHookFormArray
                control={control}
                disabled={isDisabledCatalogFields}
                dot="#f67044"
                getValues={getValues}
                groupName="structures"
                itemsToSelect={getOptionsToSelectForRHFArray({
                    data: structures,
                    isNewRecord,
                    label: t('Select structure'),
                    planData,
                    prefix: 'structures',
                })}
                label={t('Structure', { count: 2 })}
            />

            <Divider sx={{ mb: 2 }} />

            <ReactHookFormArray
                control={control}
                disabled={isDisabledCatalogFields}
                dot="#e91e63"
                getValues={getValues}
                groupName="workforce_and_electric_materials"
                itemsToSelect={getOptionsToSelectForRHFArray({
                    data: workforce,
                    isNewRecord,
                    label: t('Select workforce and electrical material'),
                    planData,
                    prefix: 'workforce_and_electric_materials_branch_office',
                })}
                label={t('Workforce and electrical equipment')}
            />

            <Divider sx={{ mb: 2 }} />

            <ReactHookFormArray
                control={control}
                disabled={isDisabledCatalogFields}
                dot="#7046bc"
                getValues={getValues}
                groupName="additionals"
                itemsToSelect={getOptionsToSelectForRHFArray({
                    data: additionals,
                    isNewRecord,
                    label: t('Select additional products'),
                    planData,
                    prefix: 'additionals_branch_office',
                })}
                label={t('Additional', { count: 2 })}
            />

            <Divider sx={{ mb: 2 }} />

            <div>
                <label>{t('Choose energy saving percentage')}</label>
            </div>

            <Grid container sx={{ justifyContent: 'center' }}>
                <Grid item xs={17}>
                    <ReactHookFormSlider
                        control={control}
                        disabled={isDisabled}
                        marks={[
                            { label: '0', value: 0 },
                            { label: '50%', value: 50 },
                            { label: '100%', value: 100 },
                        ]}
                        max={100}
                        min={0}
                        name="saving_percentage"
                    />
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    accessories: selectors.getFetchAccessoriesData,
    additionals: selectors.getFetchAdditionalsData,
    batteries: selectors.getFetchBatteriesData,
    inverters: selectors.getFetchInvertersData,
    isFetchingPlan: selectors.getIsFetchingPlan,
    isInitializing: selectors.getIsInitializing,
    isNewRecord: selectors.getIsNewRecord,
    panels: selectors.getFetchPanelsData,
    planData: selectors.getFetchPlanData,
    structures: selectors.getFetchStructuresData,
    templates: selectors.getFetchTemplatesData,
    workforce: selectors.getFetchWorkforceData,
});

TabGeneral.propTypes = {
    accessories: PropTypes.array,
    additionals: PropTypes.array,
    batteries: PropTypes.array,
    canModifyEnergyBackup: PropTypes.bool,
    inverters: PropTypes.array,
    isDisabled: PropTypes.bool,
    isFetchingPlan: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    panels: PropTypes.array,
    planData: PropTypes.object,
    structures: PropTypes.array,
    templates: PropTypes.array,
    workforce: PropTypes.array,
    control: PropTypes.object,
    getValues: PropTypes.func,
    setValue: PropTypes.func,
    watch: PropTypes.func,
};

export default connect(mapStateToProps, null)(TabGeneral);
