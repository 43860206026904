import { updateItem } from 'common/api/v2/offerPanel';
import { DISCOUNT_TYPES } from 'common/constants';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { proposalGeneratorPanelTableActions } from '../reducer';

import preventQuantityInZero from './preventQuantityInZero';

export default (proposalId, values) => (dispatch) => {
    if (!values.id) return false;
    if (isNaN(values.quantity) || parseInt(values.quantity) === 0) {
        dispatch(preventQuantityInZero(values.id, proposalId));
        return false;
    }

    let newValues = {};

    const discount =
        values.discount_type === DISCOUNT_TYPES.AMOUNT
            ? values.discount
            : values.percentage_discount;

    if (values.selectedProduct !== values.item) {
        newValues = {
            id: values.id,
            product_branch_office: values.item,
        };
    } else {
        newValues = {
            cost: values.cost,
            discount_type: values.discount_type,
            discount: Number(discount),
            expansion: values.expansion,
            final_cost: values.final_cost,
            id: values.id,
            margin: values.margin,
            quantity: parseInt(values.quantity),
            unit_price: values.unit_price,
        };
    }

    dispatch(proposalGeneratorPanelTableActions[SAVE]());

    updateItem(newValues, proposalId)
        .then((response) => {
            dispatch(
                proposalGeneratorPanelTableActions[SAVE_SUCCESS](
                    response.data.data,
                ),
            );
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorPanelTableActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
