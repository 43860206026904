import React from 'react';

import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { TextField, InputAdornment } from 'sunwise-ui';

import {
    getCountryCurrencyDecimalSeparator,
    getCountryCurrencyThousandSeparator,
} from 'common/utils/helpers/session';

const IntlNumberInputField = (props) => {
    const {
        allowDecimals,
        allowNegativeValue,
        append,
        autoFocus,
        dataIntercomTarget,
        decimalsLimit,
        max,
        min,
        onChange,
        onFocus,
        onKeyDown,
        prepend,
        readOnly,
        value,
        ...rest
    } = props;

    const thousandSeparator = getCountryCurrencyThousandSeparator();
    const decimalSeparator =
        getCountryCurrencyDecimalSeparator(thousandSeparator);

    const onChangeAction = ({ value }) => {
        const newValue = value === undefined ? '' : value;
        if (getIsAllowedValue({ float: newValue })) onChange(newValue);
    };

    const getIsAllowedValue = ({ floatValue }) => {
        const _min = parseFloat(min);
        const _max = parseFloat(max);
        if (
            (_min && !isNaN(_min) && floatValue < _min) ||
            (_max && !isNaN(_max) && floatValue > _max)
        )
            return false;
        return true;
    };

    return (
        <NumberFormat
            isAllowed={getIsAllowedValue}
            allowNegative={allowNegativeValue}
            autoFocus={autoFocus}
            customInput={TextField}
            data-intercom-target={dataIntercomTarget}
            decimalSeparator={decimalSeparator}
            decimalScale={!allowDecimals ? 0 : decimalsLimit}
            InputProps={{
                endAdornment: append && (
                    <InputAdornment position="end">{append}</InputAdornment>
                ),
                readOnly: readOnly,
                startAdornment: prepend && (
                    <InputAdornment position="start">{prepend}</InputAdornment>
                ),
            }}
            isNumericString
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onValueChange={onChangeAction}
            thousandSeparator={thousandSeparator}
            value={value}
            {...rest}
        />
    );
};

IntlNumberInputField.defaultProps = {
    allowDecimals: true,
    allowNegativeValue: true,
    autoFocus: false,
    decimalsLimit: 16,
};

IntlNumberInputField.propTypes = {
    allowDecimals: PropTypes.bool,
    allowNegativeValue: PropTypes.bool,
    append: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoFocus: PropTypes.bool,
    dataIntercomTarget: PropTypes.string,
    decimalsLimit: PropTypes.number,
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxLength: PropTypes.number,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    readOnly: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default IntlNumberInputField;
