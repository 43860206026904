import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, InputLabel, MenuItem, Select } from 'sunwise-ui';

const getBusinessTypeIcon = (profileType) => {
    switch (profileType) {
        case 'Commercial':
            return <StorefrontIcon />;
        case 'Industrial':
            return <BusinessIcon />;
        case 'Residential':
            return <HomeIcon />;
        default:
            return null;
    }
};

const BusinessTypeFilterDropdown = ({
    disabled,
    label,
    selectType,
    typeItems,
    typeSelected,
}) => {
    const { t } = useTranslation();
    const handleOnChange = (e) => selectType(e.target.value);

    return (
        <FormControl disabled={disabled} fullWidth size="small">
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                onChange={handleOnChange}
                value={typeSelected}
            >
                <MenuItem value="all">
                    <Box>
                        {t('All text', {
                            context: 'male',
                            count: 2,
                        })}
                    </Box>
                </MenuItem>
                {Object.keys(typeItems).map((typeItemKey) => {
                    return (
                        <MenuItem
                            key={`type-filter-dropdown-item-all-${typeItemKey}`}
                            value={typeItemKey}
                        >
                            <Box alignItems="center" display="flex" gap="16px">
                                {getBusinessTypeIcon(typeItems[typeItemKey])}
                                {t(typeItems[typeItemKey])}
                            </Box>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

BusinessTypeFilterDropdown.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    selectType: PropTypes.func,
    typeItems: PropTypes.object,
    typeSelected: PropTypes.string,
};

export default BusinessTypeFilterDropdown;
