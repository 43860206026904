import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, TextField } from 'sunwise-ui';

import { getProjects } from 'common/api/v1/contactProject';

const ProjectSelector = ({ onSubmit, values }) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);
    const { contact } = values;

    useEffect(() => {
        if (contact?.id) getItems(contact?.id);
    }, []);

    const filterOptions = (x) => x;
    const getItems = async (contactId) => {
        if (!contactId) {
            setOptions([]);
            return;
        }
        getProjects({ contactId }).then((projects) => {
            const map = projects.data.data.map((item) => ({
                label: item.name,
                rate: item.rate,
                value: item.id,
            }));
            setOptions(map);
        });
    };
    const getOptionLabel = (option) => option?.label || '';
    const isOptionEqualToValue = (option, selected) =>
        selected && !isEmpty(selected) && option?.value === selected.value;
    const onChange = (_, value) => {
        let project = null;
        if (!value) onSubmit('project', null);
        if (value && !isEmpty(value))
            project = { ...values.project, id: value.value, name: value.label };
        onSubmit('project', project);
        if (value?.rate && !isEmpty(value.rate))
            onSubmit('consumptions', { rate: value.rate });
        else onSubmit('consumptions', null);
    };
    const renderInput = (params) => (
        <TextField
            {...params}
            inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
            }}
            label={t('Select a project')}
        />
    );
    const renderOption = (props, option) => (
        <Box component="li" {...props} key={option.value}>
            {option.label}
        </Box>
    );

    return (
        <Autocomplete
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={onChange}
            options={[
                { label: t('Create project'), value: 'new-project' },
                ...options,
            ]}
            renderInput={renderInput}
            renderOption={renderOption}
        />
    );
};

ProjectSelector.propTypes = {
    onSubmit: PropTypes.func,
    values: PropTypes.object,
};

export default ProjectSelector;
