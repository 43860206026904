import { NAME } from './constants';

export const FETCH_COMPATIBILITY_DATA = `${NAME}/FETCH_COMPATIBILITY_DATA`;
export const FETCH_COMPATIBILITY_DATA_FAILURE = `${NAME}/FETCH_COMPATIBILITY_DATA_FAILURE`;
export const FETCH_COMPATIBILITY_DATA_SUCCESS = `${NAME}/FETCH_COMPATIBILITY_DATA_SUCCESS`;

export const FETCH_DATASHEET_INVERTER = `${NAME}/FETCH_DATASHEET_INVERTER`;
export const FETCH_DATASHEET_INVERTER_FAILURE = `${NAME}/FETCH_DATASHEET_INVERTER_FAILURE`;
export const FETCH_DATASHEET_INVERTER_SUCCESS = `${NAME}/FETCH_DATASHEET_INVERTER_SUCCESS`;

export const FETCH_DATASHEET_PANEL = `${NAME}/FETCH_DATASHEET_PANEL`;
export const FETCH_DATASHEET_PANEL_FAILURE = `${NAME}/FETCH_DATASHEET_PANEL_FAILURE`;
export const FETCH_DATASHEET_PANEL_SUCCESS = `${NAME}/FETCH_DATASHEET_PANEL_SUCCESS`;

export const FETCH_INVERTERS = `${NAME}/FETCH_INVERTERS`;
export const FETCH_INVERTERS_FAILURE = `${NAME}/FETCH_INVERTERS_FAILURE`;
export const FETCH_INVERTERS_SUCCESS = `${NAME}/FETCH_INVERTERS_SUCCESS`;

export const FETCH_PANELS = `${NAME}/FETCH_PANELS`;
export const FETCH_PANELS_FAILURE = `${NAME}/FETCH_PANELS_FAILURE`;
export const FETCH_PANELS_SUCCESS = `${NAME}/FETCH_PANELS_SUCCESS`;

export const FETCH_POLITICAL_DIVISIONS = `${NAME}/FETCH_POLITICAL_DIVISIONS`;
export const FETCH_POLITICAL_DIVISIONS_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS_FAILURE`;
export const FETCH_POLITICAL_DIVISIONS_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS_SUCCESS`;

export const FETCH_POLITICAL_DIVISIONS_BY_PARENT = `${NAME}/FETCH_POLITICAL_DIVISIONS_BY_PARENT`;
export const FETCH_POLITICAL_DIVISIONS_BY_PARENT_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS_BY_PARENT_FAILURE`;
export const FETCH_POLITICAL_DIVISIONS_BY_PARENT_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS_BY_PARENT_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;
