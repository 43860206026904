import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PROPOSAL_SUMMARY_TYPE } from '../constants';

import CatalogsCard from './CatalogsCard';

const CardList = ({ canView, getIsSelected, fetchCatalogs }) => {
    const { t } = useTranslation();
    return (
        canView && (
            <CatalogsCard
                onClick={() => fetchCatalogs(PROPOSAL_SUMMARY_TYPE)}
                selected={getIsSelected(PROPOSAL_SUMMARY_TYPE)}
                title={t('Proposal type categories')}
            />
        )
    );
};

CardList.propTypes = {
    canView: PropTypes.bool,
    fetchCatalogs: PropTypes.func,
    getIsSelected: PropTypes.func,
};

export default CardList;
