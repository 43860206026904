import React, { useEffect } from 'react';

import SavingsIcon from '@mui/icons-material/Savings';
import { Skeleton } from '@mui/material';
import { Box, Tooltip, Typography } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import * as actions from '../actions';
import { getHasBagEnergyField } from '../helpers';
import * as selectors from '../selectors';
const PoolTag = ({
    availableBag,
    compensationScheme,
    fetchTodayAvailableBag,
    isFetchingAvailableBag,
    projectId,
    rate,
}) => {
    const { t } = useTranslation();
    const countryCurrencyLocale = getCountryCurrencyLocale();

    useEffect(() => {
        if (projectId && getHasBagEnergyField(rate, compensationScheme))
            fetchTodayAvailableBag(projectId);
    }, [compensationScheme, projectId, rate?.id]);

    if (!getHasBagEnergyField(rate, compensationScheme)) return null;

    if (isFetchingAvailableBag) return <Skeleton variant="text" width={100} />;

    const value = numberFormat(availableBag, {
        decimals: 0,
        locale: countryCurrencyLocale,
        style: 'decimal',
    });

    return (
        <Tooltip title={t('Available pool')}>
            <Box alignItems="center" display="flex" gap={1}>
                <SavingsIcon color="primary" />

                <Typography variant="subtitle2">{value} kWh</Typography>
            </Box>
        </Tooltip>
    );
};

const mapStateToProps = createStructuredSelector({
    availableBag: selectors.getTodayAvailableBag,
    compensationScheme:
        afterSalesSettingsSelectors.getAfterSalesSettingsCompensationScheme,
    rate: afterSalesSettingsSelectors.getAfterSalesSettingsRate,
    isFetchingAvailableBag: selectors.getIsFetchingTodayAvailableBag,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTodayAvailableBag: (projectId) =>
        dispatch(actions.fetchTodayAvailableBag(projectId)),
});

PoolTag.propTypes = {
    availableBag: PropTypes.number,
    compensationScheme: PropTypes.string,
    fetchTodayAvailableBag: PropTypes.func,
    isFetchingAvailableBag: PropTypes.bool,
    projectId: PropTypes.string,
    rate: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PoolTag);
