import { getListProjects } from 'common/api/v3/funnelProjects';

import {
    FETCH_PROJECTS,
    FETCH_PROJECTS_FAILURE,
    FETCH_PROJECTS_SUCCESS,
} from '../actionTypes';
import { listProjectsActions } from '../reducer';

export default (filterQuery) => (dispatch) => {
    dispatch(listProjectsActions[FETCH_PROJECTS]());

    getListProjects({ filterQuery })
        .then((response) => {
            dispatch(
                listProjectsActions[FETCH_PROJECTS_SUCCESS]({
                    data: response.data.data,
                    hasNextPage: response.data.pagination.links.next !== null,
                }),
            );
        })
        .catch((error) =>
            dispatch(listProjectsActions[FETCH_PROJECTS_FAILURE](error)),
        );
};
