import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import { RESET_PASSWORD_IN_USERS } from 'common/modules/managePassword/constants';
import ManagePassword from 'common/modules/managePassword/Container';

import * as actions from '../actions';
import * as selectors from '../selectors';

const ResetPasswordModal = ({ handleClickCloseModal, isOpenModal }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={handleClickCloseModal}
            open={isOpenModal}
            title={t('Restore password')}
        >
            <ManagePassword
                callback={handleClickCloseModal}
                origin={RESET_PASSWORD_IN_USERS}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpenModal: selectors.getIsOpenModalPassword,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCloseModal: () => dispatch(actions.toggleModalPassword(false)),
});

ResetPasswordModal.propTypes = {
    handleClickCloseModal: PropTypes.func,
    isOpenModal: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);
