import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import {
    ReactHookFormInputDatePicker,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { getAgentOptions } from 'common/utils/helpers';
import { getIsOnlyContacts } from 'common/utils/helpers/session';

import * as actions from '../actions';
import * as selectors from '../selectors';

const ProposalsFilter = ({
    agents,
    fetchProjectsCounters,
    fetchProposalsTotals,
    filterBy,
    initialValues,
    setFilterBy,
    showProposals,
}) => {
    const { t } = useTranslation();
    const isOnlyContacts = getIsOnlyContacts();

    const { control, getValues, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
    });

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        const values = getValues();
        if (showProposals) fetchProposalsTotals(values);
        else fetchProjectsCounters(values);
    }, [filterBy]);

    const handleClickFilter = (values) =>
        showProposals
            ? fetchProposalsTotals(values)
            : fetchProjectsCounters(values);

    return (
        <Grid item xs>
            <Grid alignItems="center" container justifyContent="flex-end">
                <Grid item xs={18} lg={4}>
                    <FormControl fullWidth size="small">
                        <InputLabel>{t('Filter by')}</InputLabel>
                        <Select
                            label={t('Filter by')}
                            onChange={(e) => setFilterBy(e.target.value)}
                            value={filterBy}
                        >
                            <MenuItem value="proposals">
                                {t('Proposal', { count: 2 })}
                            </MenuItem>
                            <MenuItem value="projects">
                                {t('Project', { count: 2 })}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={18} lg={4}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        label={t('Initial date')}
                        maxDate={new Date()}
                        name="initial_date"
                    />
                </Grid>

                <Grid item xs={18} lg={4}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        label={t('Final date')}
                        maxDate={new Date()}
                        name="final_date"
                    />
                </Grid>
                {!isOnlyContacts && (
                    <Grid item xs={18} lg={3}>
                        <FormControl fullWidth size="small">
                            <ReactHookFormSelect
                                control={control}
                                name="agent"
                                label={t('User')}
                                options={getAgentOptions(agents)}
                            />
                        </FormControl>
                    </Grid>
                )}

                <Grid item xs={18} lg={3}>
                    <Button fullWidth onClick={handleSubmit(handleClickFilter)}>
                        <strong>{t('Filter')}</strong>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjectsCounters: (values) =>
        dispatch(actions.fetchProjectsCounters(values)),
    fetchProposalsTotals: (values) =>
        dispatch(actions.fetchProposalsTotals(values)),
});

ProposalsFilter.propTypes = {
    agents: PropTypes.array,
    fetchProjectsCounters: PropTypes.func,
    fetchProposalsTotals: PropTypes.func,
    filterBy: PropTypes.string,
    initialValues: PropTypes.object,
    setFilterBy: PropTypes.func,
    showProposals: PropTypes.bool,
};

const FormContainer = ProposalsFilter;

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
