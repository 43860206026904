import React from 'react';

import PropTypes from 'prop-types';

import { validateAccessWithPermissions } from 'common/utils/helpers/permissionsV2';

const CheckAccess = ({
    children,
    isHidden = false,
    requiredPermissions = [],
}) => {
    const hasAccess = validateAccessWithPermissions(requiredPermissions);
    if (isHidden || !hasAccess) return null;
    return <>{children}</>;
};

CheckAccess.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isHidden: PropTypes.bool,
    requiredPermissions: PropTypes.array,
};

export default CheckAccess;
