import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { Switch } from 'common/components/form';

const ReactHookFormSwitch = ({
    control,
    disabled,
    label,
    name,
    onChange,
    sx,
}) => (
    <Controller
        control={control}
        name={name}
        render={({ field: { onChange: onChangeField, value } }) => (
            <Switch
                checked={value ?? false}
                disabled={disabled}
                label={label}
                onChange={(e) => {
                    onChangeField(e.target.checked);
                    if (onChange) onChange(e.target.checked);
                }}
                sx={sx}
            />
        )}
    />
);

ReactHookFormSwitch.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    sx: PropTypes.object,
};
export default ReactHookFormSwitch;
