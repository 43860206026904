import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';

import Label from './Label';
import TextWithLabel from './TextWithLabel';

const Container = styled('div')`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    ${({ isLast }) => !isLast && 'border-bottom: 1px solid #eff1fb;'}
    padding-bottom: 8px;
`;

const SelectWrapper = styled('span')`
    width: calc(50% - 18px);
    select {
        font-size: 13px;
        font-weight: 600;
    }
`;

const ReassignListItems = ({ branchAgentsDictionary = {}, control, name }) => {
    const { t } = useTranslation();
    const { fields } = useFieldArray({ control, name });
    return (
        <>
            {fields.map((field, index) => {
                return (
                    <Container
                        key={`reassign-list-item-${field.id}`}
                        isLast={fields.length === index + 1}
                    >
                        <TextWithLabel
                            label={t('Branch office')}
                            text={field?.branchName}
                            margin="6px 0 0 0"
                        />
                        <SelectWrapper>
                            <Label text={t('New solar advisor')} />
                            <ReactHookFormSelect
                                control={control}
                                name={`${name}.${index}.newAgent`}
                                placeholder={t('Adviser')}
                                options={
                                    branchAgentsDictionary[field?.branchId] ||
                                    []
                                }
                            />
                        </SelectWrapper>
                    </Container>
                );
            })}
        </>
    );
};

ReassignListItems.propTypes = {
    branchAgentsDictionary: PropTypes.object,
    control: PropTypes.object,
    name: PropTypes.string,
};

export default ReassignListItems;
