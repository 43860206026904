import i18next from 'i18next';
import get from 'lodash/get';

import { toggleFinancierProductEnable } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    TOGGLE_PRODUCT_ENABLE,
    TOGGLE_PRODUCT_ENABLE_SUCCESS,
    TOGGLE_PRODUCT_ENABLE_FAILURE,
} from '../actionTypes';
import { alliancesActions } from '../reducer';

import fetchFinarcierProduct from './fetchFinarcierProduct';

export default (filterData, financierProductId) => (dispatch) => {
    dispatch(alliancesActions[TOGGLE_PRODUCT_ENABLE](financierProductId));

    toggleFinancierProductEnable(financierProductId)
        .then((response) => {
            const isEnabled = get(response, 'data.data.enabled', false);
            dispatch(
                alliancesActions[TOGGLE_PRODUCT_ENABLE_SUCCESS](response.data),
            );
            dispatch(fetchFinarcierProduct(filterData));

            showToast({
                body: isEnabled
                    ? i18next.t('The product was successfully enabled')
                    : i18next.t('The product was successfully disabled'),
            });
        })
        .catch((error) => {
            dispatch(
                alliancesActions[TOGGLE_PRODUCT_ENABLE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
