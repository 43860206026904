import { NAME } from './constants';

export const DELETE_DATA = `${NAME}/DELETE_DATA`;
export const DELETE_DATA_FAILURE = `${NAME}/DELETE_DATA_FAILURE`;
export const DELETE_DATA_SUCCESS = `${NAME}/DELETE_DATA_SUCCESS`;

export const EDIT_CONSUMPTION_HISTORY = `${NAME}/EDIT_CONSUMPTION_HISTORY`;

export const FETCH_AVAILABLE_BAG = `${NAME}/FETCH_AVAILABLE_BAG`;
export const FETCH_AVAILABLE_BAG_FAILURE = `${NAME}/FETCH_AVAILABLE_BAG_FAILURE`;
export const FETCH_AVAILABLE_BAG_SUCCESS = `${NAME}/FETCH_AVAILABLE_BAG_SUCCESS`;

export const FETCH_CONSUMPTION_HISTORY = `${NAME}/FETCH_CONSUMPTION_HISTORY`;
export const FETCH_CONSUMPTION_HISTORY_FAILURE = `${NAME}/FETCH_CONSUMPTION_HISTORY_FAILURE`;
export const FETCH_CONSUMPTION_HISTORY_SUCCESS = `${NAME}/FETCH_CONSUMPTION_HISTORY_SUCCESS`;
export const FETCH_INITIAL_CONSUMPTION_HISTORY = `${NAME}/FETCH_INITIAL_CONSUMPTION_HISTORY`;

export const FETCH_CUPS_INFO = `${NAME}/FETCH_CUPS_INFO`;
export const FETCH_CUPS_INFO_FAILURE = `${NAME}/FETCH_CUPS_INFO_FAILURE`;
export const FETCH_CUPS_INFO_SUCCESS = `${NAME}/FETCH_CUPS_INFO_SUCCESS`;

export const FETCH_LISA_FILES = `${NAME}/FETCH_LISA_FILES`;
export const FETCH_LISA_FILES_FAILURE = `${NAME}/FETCH_LISA_FILES_FAILURE`;
export const FETCH_LISA_FILES_SUCCESS = `${NAME}/FETCH_LISA_FILES_SUCCESS`;

export const FETCH_MEASURED_ENERGY = `${NAME}/FETCH_MEASURED_ENERGY`;
export const FETCH_MEASURED_ENERGY_FAILURE = `${NAME}/FETCH_MEASURED_ENERGY_FAILURE`;
export const FETCH_MEASURED_ENERGY_SUCCESS = `${NAME}/FETCH_MEASURED_ENERGY_SUCCESS`;

export const FETCH_TODAY_AVAILABLE_BAG = `${NAME}/FETCH_TODAY_AVAILABLE_BAG`;
export const FETCH_TODAY_AVAILABLE_BAG_FAILURE = `${NAME}/FETCH_TODAY_AVAILABLE_BAG_FAILURE`;
export const FETCH_TODAY_AVAILABLE_BAG_SUCCESS = `${NAME}/FETCH_TODAY_AVAILABLE_BAG_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RECALCULATE_CONSUMPTION_HISTORY = `${NAME}/RECALCULATE_CONSUMPTION_HISTORY`;
export const RECALCULATE_CONSUMPTION_HISTORY_FAILURE = `${NAME}/RECALCULATE_CONSUMPTION_HISTORY_FAILURE`;
export const RECALCULATE_CONSUMPTION_HISTORY_SUCCESS = `${NAME}/RECALCULATE_CONSUMPTION_HISTORY_SUCCESS`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_DATA = `${NAME}/SAVE_DATA`;
export const SAVE_DATA_FAILURE = `${NAME}/SAVE_DATA_FAILURE`;
export const SAVE_DATA_SUCCESS = `${NAME}/SAVE_DATA_SUCCESS`;

export const SET_CONFIG_CSV_IMPORT_MODAL = `${NAME}/SET_CONFIG_CSV_IMPORT_MODAL`;
export const SET_MODAL_FORM_OPEN_STATUS = `${NAME}/SET_MODAL_FORM_OPEN_STATUS`;

export const UPLOAD_FILE = `${NAME}/UPLOAD_FILE`;
export const UPLOAD_FILE_FAILURE = `${NAME}/UPLOAD_FILE_FAILURE`;
export const UPLOAD_FILE_SUCCESS = `${NAME}/UPLOAD_FILE_SUCCESS`;
