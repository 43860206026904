import React from 'react';

import PropTypes from 'prop-types';

import ChartJs from 'common/components/ChartJs';

import { getTotalsChartConfig } from '../helpers';

const TotalsBarChart = ({
    totalAccessories,
    totalAdditionals,
    totalEnergyStorage,
    totalInverters,
    totalPanels,
    totalStructure,
    totalWorkforce,
}) => {
    const { data, options } = getTotalsChartConfig({
        currencyIso: 'MXN',
        currencyLocale: 'es-MX',
        values: [
            totalPanels,
            totalInverters,
            totalStructure,
            totalWorkforce,
            totalAccessories,
            totalAdditionals,
            totalEnergyStorage,
        ],
    });
    return <ChartJs data={data} height="427px" options={options} />;
};

TotalsBarChart.propTypes = {
    totalAccessories: PropTypes.number,
    totalAdditionals: PropTypes.number,
    totalEnergyStorage: PropTypes.number,
    totalInverters: PropTypes.number,
    totalPanels: PropTypes.number,
    totalStructure: PropTypes.number,
    totalWorkforce: PropTypes.number,
};

export default TotalsBarChart;
