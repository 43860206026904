import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_OFFER_CONSUMPTION_AND_GENERATION,
    FETCH_OFFER_CONSUMPTION_AND_GENERATION_FAILURE,
    FETCH_OFFER_CONSUMPTION_AND_GENERATION_SUCCESS,
    FETCH_OFFER_DETAILS,
    FETCH_OFFER_DETAILS_FAILURE,
    FETCH_OFFER_DETAILS_SUCCESS,
    FETCH_OFFER_PROFILES,
    FETCH_OFFER_PROFILES_FAILURE,
    FETCH_OFFER_PROFILES_SUCCESS,
    PREPERE_EDIT,
    PREPERE_EDIT_SUCCESS,
    RESET_VALUES,
    SET_IS_LOCKED,
    SET_SOLAR_SIMULATION_DATA,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    isLocked: true,
    offerConsumptionAndGeneration: { data: {}, errors: [], isFetching: true },
    offerDetails: { data: {}, errors: [], isFetching: true },
    offerProfiles: { data: null, errors: null, isFetching: true },
    prepareEdit: { isFetching: false },
    solarSimulationData: {},
};

const proposalGeneratorOneColumnSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_OFFER_CONSUMPTION_AND_GENERATION]: (state) => {
            state.offerConsumptionAndGeneration = {
                ...state.offerConsumptionAndGeneration,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_CONSUMPTION_AND_GENERATION_FAILURE]: (state, action) => {
            state.offerConsumptionAndGeneration = {
                ...state.offerConsumptionAndGeneration,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_CONSUMPTION_AND_GENERATION_SUCCESS]: (state, action) => {
            state.offerConsumptionAndGeneration = {
                ...state.offerConsumptionAndGeneration,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_DETAILS]: (state) => {
            state.offerDetails = {
                ...state.offerDetails,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_DETAILS_FAILURE]: (state, action) => {
            state.offerDetails = {
                ...state.offerDetails,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_DETAILS_SUCCESS]: (state, action) => {
            state.offerDetails = {
                ...state.offerDetails,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_PROFILES]: (state) => {
            state.offerProfiles = {
                ...state.offerProfiles,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_OFFER_PROFILES_FAILURE]: (state, action) => {
            state.offerProfiles = {
                ...state.offerProfiles,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_PROFILES_SUCCESS]: (state, action) => {
            state.offerProfiles = {
                ...state.offerProfiles,
                data: action.payload,
                isFetching: false,
            };
        },
        [PREPERE_EDIT]: (state) => {
            state.prepareEdit = { isFetching: true };
        },
        [PREPERE_EDIT_SUCCESS]: (state) => {
            state.prepareEdit = { isFetching: false };
        },
        [RESET_VALUES]: () => INITIAL_STATE,
        [SET_IS_LOCKED]: (state, action) => {
            state.isLocked = action.payload;
        },
        [SET_SOLAR_SIMULATION_DATA]: (state, action) => {
            state.solarSimulationData = action.payload;
        },
    },
});

export const proposalGeneratorOneColumnActions =
    proposalGeneratorOneColumnSlice.actions;

export default proposalGeneratorOneColumnSlice.reducer;
