import { NAME } from './constants';

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const FETCH_AGENTS = `${NAME}/FETCH_AGENTS`;
export const FETCH_AGENTS_FAILURE = `${NAME}/FETCH_AGENTS_FAILURE`;
export const FETCH_AGENTS_SUCCESS = `${NAME}/FETCH_AGENTS_SUCCESS`;

export const FETCH_CONTACTS = `${NAME}/FETCH_CONTACTS`;
export const FETCH_CONTACTS_FAILURE = `${NAME}/FETCH_CONTACTS_FAILURE`;
export const FETCH_CONTACTS_SUCCESS = `${NAME}/FETCH_CONTACTS_SUCCESS`;

export const FETCH_CONTACTS_TO_REASING = `${NAME}/FETCH_CONTACTS_TO_REASING`;
export const FETCH_CONTACTS_TO_REASING_FAILURE = `${NAME}/FETCH_CONTACTS_TO_REASING_FAILURE`;
export const FETCH_CONTACTS_TO_REASING_SUCCESS = `${NAME}/FETCH_CONTACTS_TO_REASING_SUCCESS`;

export const FETCH_FILTER_CONTACTS = `${NAME}/FETCH_FILTER_CONTACTS`;
export const FETCH_FILTER_CONTACTS_FAILURE = `${NAME}/FETCH_FILTER_CONTACTS_FAILURE`;
export const FETCH_FILTER_CONTACTS_SUCCESS = `${NAME}/FETCH_FILTER_CONTACTS_SUCCESS`;

export const INITIALIZE = `${NAME}/INITIALIZE`;
export const INITIALIZE_SUCCESS = `${NAME}/INITIALIZE_SUCCESS`;
export const INITIALIZE_FAILURE = `${NAME}/INITIALIZE_FAILURE`;

export const INITIALIZE_REASIGN_FORM = `${NAME}/INITIALIZE_REASIGN_FORM`;

export const OPEN_MODAL = `${NAME}/OPEN_MODAL`;

export const RESET = `${NAME}/RESET`;

export const SAVE_CONTACT = `${NAME}/SAVE_CONTACT`;
export const SAVE_CONTACT_FAILURE = `${NAME}/SAVE_CONTACT_FAILURE`;
export const SAVE_CONTACT_SUCCESS = `${NAME}/SAVE_CONTACT_SUCCESS`;

export const SET_IS_DOWNLOADING = `${NAME}/SET_IS_DOWNLOADING`;
export const SET_IS_OPEN_REASIGN_MODAL = `${NAME}/SET_IS_OPEN_REASIGN_MODAL`;
export const SET_IS_UPLOADING = `${NAME}/SET_IS_UPLOADING`;

export const UPDATE_ITEMS_SUCCESS = `${NAME}/UPDATE_ITEMS_SUCCESS`;

export const SET_HAS_CONTACTS = `${NAME}/SET_HAS_CONTACTS`;
