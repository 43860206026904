import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

const PeriodSelector = ({
    consumptionHistory = [],
    isYearSelector = false,
    onChange,
    showHasDropdown = false,
    value,
}) => {
    const breakpoint = useBreakpoint();
    const { t } = useTranslation();

    const label = isYearSelector ? t('Year') : t('Period');
    const isMobile = ['xs', 'sm', 'md'].includes(breakpoint);

    if (!isMobile && isYearSelector && !showHasDropdown)
        return (
            <Box
                alignItems="center"
                display="flex"
                gap={2}
                justifyContent="center"
                my={1}
            >
                <Typography>{label}:</Typography>
                <Pagination
                    count={consumptionHistory?.length || 0}
                    color="primary"
                    onChange={(_, page) =>
                        onChange({ target: { value: page - 1 } })
                    }
                    siblingCount={1}
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={2}
                    page={(value || 0) + 1}
                />
            </Box>
        );

    if (isMobile || showHasDropdown)
        return (
            <FormControl size="small">
                <InputLabel>{label}</InputLabel>
                <Select label={label} onChange={onChange} value={value}>
                    {consumptionHistory?.map((period, index) => (
                        <MenuItem key={`period-${index}`} value={index}>
                            {isYearSelector ? `${index + 1}` : period.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );

    return (
        <ToggleButtonGroup
            color="primary"
            exclusive
            fullWidth
            onChange={onChange}
            size="small"
            sx={{ mb: 2 }}
            value={value}
        >
            {consumptionHistory?.map((period, index) => (
                <ToggleButton key={`period-selector-${index}`} value={index}>
                    {isYearSelector
                        ? `${t('Year')} ${index + 1}`
                        : period.label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

PeriodSelector.propTypes = {
    consumptionHistory: PropTypes.array,
    isYearSelector: PropTypes.bool,
    onChange: PropTypes.func,
    showHasDropdown: PropTypes.bool,
    value: PropTypes.number,
};

export default PeriodSelector;
