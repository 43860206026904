import { NAME } from './constants';

export const CONFIRM_SUBSCRIPTION = `${NAME}/CONFIRM_SUBSCRIPTION`;
export const CONFIRM_SUBSCRIPTION_FAILURE = `${NAME}/CONFIRM_SUBSCRIPTION_FAILURE`;
export const CONFIRM_SUBSCRIPTION_SUCCESS = `${NAME}/CONFIRM_SUBSCRIPTION_SUCCESS`;

export const FETCH_PRICING = `${NAME}/FETCH_PRICING`;
export const FETCH_PRICING_FAILURE = `${NAME}/FETCH_PRICING_FAILURE`;
export const FETCH_PRICING_SUCCESS = `${NAME}/FETCH_PRICING_SUCCESS`;

export const FETCH_SUBSCRIPTION = `${NAME}/FETCH_SUBSCRIPTION`;
export const FETCH_SUBSCRIPTION_FAILURE = `${NAME}/FETCH_SUBSCRIPTION_FAILURE`;
export const FETCH_SUBSCRIPTION_SUCCESS = `${NAME}/FETCH_SUBSCRIPTION_SUCCESS`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;
