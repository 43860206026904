import { NAME } from './constants';

export const FETCH_CUSTOM_TEMPLATE = `${NAME}/FETCH_CUSTOM_TEMPLATE`;
export const FETCH_CUSTOM_TEMPLATE_SUCCESS = `${NAME}/FETCH_CUSTOM_TEMPLATE_SUCCESS`;
export const FETCH_CUSTOM_TEMPLATE_FAILURE = `${NAME}/FETCH_CUSTOM_TEMPLATE_FAILURE`;

export const FETCH_TABLE_WIDGETS = `${NAME}/FETCH_TABLE_WIDGETS`;
export const FETCH_TABLE_WIDGETS_SUCCESS = `${NAME}/FETCH_TABLE_WIDGETS_SUCCESS`;
export const FETCH_TABLE_WIDGETS_FAILURE = `${NAME}/FETCH_TABLE_WIDGETS_FAILURE`;

export const FETCH_CHART_WIDGETS = `${NAME}/FETCH_CHART_WIDGETS`;
export const FETCH_CHART_WIDGETS_SUCCESS = `${NAME}/FETCH_CHART_WIDGETS_SUCCESS`;
export const FETCH_CHART_WIDGETS_FAILURE = `${NAME}/FETCH_CHART_WIDGETS_FAILURE`;

export const FETCH_PDF_LINK = `${NAME}/FETCH_PDF_LINK`;
export const FETCH_PDF_LINK_SUCCESS = `${NAME}/FETCH_PDF_LINK_SUCCESS`;
export const FETCH_PDF_LINK_FAILURE = `${NAME}/FETCH_PDF_LINK_FAILURE`;

export const FETCH_SUMMARY_PATERN = `${NAME}/FETCH_SUMMARY_PATERN`;
export const FETCH_SUMMARY_PATERN_FAILURE = `${NAME}/FETCH_SUMMARY_PATERN_FAILURE`;
export const FETCH_SUMMARY_PATERN_SUCCESS = `${NAME}/FETCH_SUMMARY_PATERN_SUCCESS`;

export const UPDATE_PROPOSAL = `${NAME}/UPDATE_PROPOSAL`;
export const UPDATE_PROPOSAL_SUCCESS = `${NAME}/UPDATE_PROPOSAL_SUCCESS`;
export const UPDATE_PROPOSAL_FAILURE = `${NAME}/UPDATE_PROPOSAL_FAILURE`;

export const RESET = `${NAME}/RESET`;
