import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import alerts from 'common/modules/alerts';
import * as multiBranchesActions from 'common/modules/multiBranches/actions';
import releaseNotification from 'common/modules/releaseNotification';
import { getMainBranchOffice } from 'common/utils/helpers/session';

import Footer from './defaultLayout/Footer';

const LoginLayout = ({ children, isPublic, selectBranch }) => {
    useEffect(() => {
        if (!isPublic) selectBranch(getMainBranchOffice());
    }, []);

    return (
        <>
            {children}
            <Footer showPlaylist={false} />
            <alerts.Container />
            <releaseNotification.Container />
        </>
    );
};

LoginLayout.propTypes = {
    children: PropTypes.element,
    isPublic: PropTypes.bool,
    selectBranch: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    selectBranch: (branchId) =>
        dispatch(multiBranchesActions.selectBranch(branchId)),
});

export default connect(null, mapDispatchToProps)(LoginLayout);
