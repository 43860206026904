import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import { Box, Divider, IconButton, List } from 'sunwise-ui';

import Logo from './Logo';
import StyledDrawer from './StyledDrawer';

const SecondaryDrawer = ({ children, container, mobileOpen, onClose }) => (
    <StyledDrawer
        container={container}
        ModalProps={{ keepMounted: true }}
        open={mobileOpen}
        onClose={onClose}
        sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: '100%',
            },
        }}
        variant="temporary"
    >
        <Box
            display="flex"
            sx={{
                alignItems: 'center',
                justifyContent: { md: 'center', xs: 'space-between' },
                px: { md: 0, xs: 2 },
            }}
        >
            <Box sx={{ p: 1 }}>
                <Logo field={'logotype_image'} />
            </Box>

            <IconButton onClick={onClose}>
                <ArrowBackIcon />
            </IconButton>
        </Box>

        <Divider />

        <List>{children}</List>
    </StyledDrawer>
);

SecondaryDrawer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    container: PropTypes.func,
    mobileOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

export default SecondaryDrawer;
