import i18next from 'i18next';

export const getUserOptions = (users) => {
    const items = users
        .filter(({ is_active, is_enabled }) => is_active && is_enabled)
        .map((item) => {
            return {
                label: `${item.first_name} ${item.last_name}`,
                value: item.id,
            };
        });

    return [
        {
            disabled: true,
            label: i18next.t('Select a user'),
            value: '',
        },
        ...items,
    ];
};

export const buildPayload = (values) => ({
    advanced: values.advanced,
    agent: values.agent,
    branch_office: values.branch_office,
    company_name: values.company_name,
    company_position: values.company_position,
    contact_origin: values.contact_origin,
    emails: JSON.stringify(values.emails),
    first_lastname: values.first_lastname,
    name: values.name,
    rfc: values.rfc,
    second_lastname: values.second_lastname,
    status_contact: values.status_contact,
    telephones: JSON.stringify(values.telephones),
});
