import { getItemsByLevel } from 'common/api/v1/politicalDivisions';

import {
    FETCH_POLITICAL_DIVISIONS,
    FETCH_POLITICAL_DIVISIONS_FAILURE,
    FETCH_POLITICAL_DIVISIONS_SUCCESS,
} from '../actionTypes';
import { branchOfficesActions } from '../reducer';

export default (str) => (dispatch) => {
    dispatch(branchOfficesActions[FETCH_POLITICAL_DIVISIONS]());

    return getItemsByLevel(str)
        .then((response) =>
            dispatch(
                branchOfficesActions[FETCH_POLITICAL_DIVISIONS_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                branchOfficesActions[FETCH_POLITICAL_DIVISIONS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
