import { getStations } from 'common/api/afterSales/accessConfig';

import {
    FETCH_POWER_STATIONS,
    FETCH_POWER_STATIONS_FAILURE,
    FETCH_POWER_STATIONS_SUCCESS,
} from '../actionTypes';
import { powerStationsActions } from '../reducer';

import fetchProjectsByIds from './fetchProjectsByIds';

export default (accessKeyId, filter) => (dispatch) => {
    dispatch(powerStationsActions[FETCH_POWER_STATIONS]());

    getStations(accessKeyId, filter)
        .then((response) => {
            dispatch(
                powerStationsActions[FETCH_POWER_STATIONS_SUCCESS](
                    response?.data,
                ),
            );

            const projects = response?.data?.data
                ?.filter((station) => station.project)
                ?.map((station) => station.project);

            dispatch(fetchProjectsByIds(projects));
        })
        .catch(() =>
            dispatch(powerStationsActions[FETCH_POWER_STATIONS_FAILURE]()),
        );
};
