import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import StyledLabel from './StyledLabel';

const Label = styled('label')`
    color: #848bab;
    font-size: 12px;
    margin-bottom: 0;
    width: 100%;
`;

const Card = styled(Box)`
    background-clip: border-box;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #ecedf0;
    box-shadow: 0 2px 12px 0 rgb(129 158 200 / 6%);
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    min-width: 0;
    position: relative;
    word-wrap: break-word;

    &.border-primary {
        background-color: #eef4fa;
        box-shadow: 0 2px 12px 0 rgb(129 158 200 / 6%);
    }
`;

Card.Body = styled(Box)`
    flex: 1 1 auto;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 18px;
    padding: 11px;
`;

const WrapperItems = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
`;

const ContainerRadioButton = styled('div')`
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;

    @media all and (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 1000%;
    }

    [type='radio']:checked,
    [type='radio']:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
        color: #666;
        cursor: pointer;
        display: inline-block;
        line-height: 20px;
        padding-left: 28px;
        position: relative;
    }

    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
        background: #fff;
        border-radius: 100%;
        border: 1px solid #ddd;
        content: '';
        height: 16px;
        left: 0;
        position: absolute;
        top: 0;
        width: 16px;
    }

    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
        background: #002438;
        border-radius: 100%;
        content: '';
        height: 6px;
        left: 5px;
        position: absolute;
        top: 5px;
        transition: all 0.2s ease;
        width: 6px;
    }

    [type='radio']:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }

    [type='radio']:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }

    label {
        cursor: pointer;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;
    }

    ${({ disabled }) =>
        disabled &&
        `
        opacity: .5;
    `}

    ${({ radioVisible }) =>
        !radioVisible &&
        `
        [type='radio']:checked + label:before,
        [type='radio']:not(:checked) + label:before,
        [type='radio']:checked + label:after,
        [type='radio']:not(:checked) + label:after {
            visibility: hidden;
        }

        [type='radio']:checked + label,
        [type='radio']:not(:checked) + label{
            padding-left: 0;
        }        
    `}
`;

const StyledRadioButtonField = ({
    className,
    classNameItems,
    disabled,
    error,
    field: { onChange, value: selectedValue },
    items,
    prefixId,
    radioVisible,
    label,
    readOnly,
}) => {
    return (
        <>
            <StyledLabel>{label}</StyledLabel>

            <WrapperItems className={className}>
                {items.map((item, index) => {
                    return (
                        <ContainerRadioButton
                            className={classNameItems}
                            key={`${prefixId}-radio-field-${index}`}
                            disabled={disabled}
                            radioVisible={radioVisible}
                        >
                            <Card
                                className={`${
                                    selectedValue && selectedValue == item.value
                                        ? 'border-primary'
                                        : ''
                                }`}
                            >
                                <label>
                                    <Card.Body>
                                        <input
                                            checked={
                                                selectedValue &&
                                                selectedValue == item.value
                                            }
                                            disabled={disabled}
                                            id={`${prefixId}-radio-field-${index}`}
                                            onChange={onChange}
                                            type="radio"
                                            value={item.value}
                                            readOnly={readOnly}
                                        />

                                        <Label
                                            htmlFor={`${prefixId}-radio-field-${index}`}
                                            title={item.label}
                                        >
                                            {item.label}
                                        </Label>
                                    </Card.Body>
                                </label>
                            </Card>
                        </ContainerRadioButton>
                    );
                })}

                {error?.message}
            </WrapperItems>
        </>
    );
};

StyledRadioButtonField.propTypes = {
    className: PropTypes.string,
    classNameItems: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.object,
    field: PropTypes.object,
    items: PropTypes.array,
    prefixId: PropTypes.string,
    radioVisible: PropTypes.bool,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
};

export default StyledRadioButtonField;
