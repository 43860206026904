import { fetchListCatalogs } from 'common/api/v1/companiesCatalogs';

import {
    FETCH_STATUS_PROJECT_FAILURE,
    FETCH_STATUS_PROJECT,
    FETCH_STATUS_PROJECT_SUCCESS,
} from '../actionTypes';
import { STATUS_PROJECT_TYPE } from '../constants';
import { projectActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(projectActions[FETCH_STATUS_PROJECT]());

    fetchListCatalogs(STATUS_PROJECT_TYPE)
        .then((response) =>
            dispatch(
                projectActions[FETCH_STATUS_PROJECT_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(projectActions[FETCH_STATUS_PROJECT_FAILURE](error)),
        );
};
