import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {
    AccordionDetails,
    Box,
    Divider,
    Skeleton,
    Typography,
} from 'sunwise-ui';

import StyledAccordion from './StyledAccordion';
import StyledAccordionSummary from './StyledAccordionSummary';

const AccordionProduct = ({
    caption,
    cardId,
    children,
    classNameCard,
    classNameExpandIcon,
    defaultExpanded,
    isHidden,
    isLoading,
    svgIcon,
    title,
    total,
}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    if (isHidden) return null;
    return (
        <Box className={classNameCard} id={cardId} md={2}>
            <StyledAccordion
                defaultExpanded={defaultExpanded}
                onChange={(e, expanded) => {
                    setIsExpanded(expanded);
                }}
            >
                <StyledAccordionSummary
                    expandIcon={
                        <ExpandMoreIcon className={classNameExpandIcon} />
                    }
                    aria-controls={`${cardId}-content`}
                    id={`${cardId}-header`}
                    height={'50px'}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', lg: 'row' },
                            alignItems: { xs: 'start', lg: 'center' },
                            justifyContent: { xs: 'center', lg: 'start' },
                            width: '100%',
                        }}
                    >
                        <Box display="flex" alignItems="center" gap={2}>
                            <Box
                                display="flex"
                                alignItems="center"
                                sx={{ width: '24px' }}
                            >
                                {svgIcon}
                            </Box>
                            <Typography fontWeight="bold" variant="body2">
                                {title && <span>{title}</span>}
                            </Typography>
                        </Box>
                        {!isExpanded && (
                            <>
                                <Box
                                    sx={{
                                        display: { xs: 'flex', lg: 'none' },
                                        alignItems: 'center',
                                        gap: 2,
                                        width: '100%',
                                    }}
                                >
                                    <Box sx={{ width: '24px' }} />
                                    {isLoading ? (
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: 200,
                                            }}
                                        />
                                    ) : (
                                        <Typography variant="body2">
                                            {total}
                                        </Typography>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: { xs: 'none', lg: 'flex' },
                                        alignItems: 'center',
                                        gap: 2,
                                        ml: 'auto',
                                        mr: '8px',
                                    }}
                                >
                                    {isLoading ? (
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: 200,
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    pl: '5px',
                                                    borderLeft:
                                                        '1px solid #b6bbc1',
                                                }}
                                            >
                                                {caption}
                                            </Typography>
                                            <Typography
                                                fontWeight="bold"
                                                variant="body2"
                                            >
                                                {total}
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                </StyledAccordionSummary>
                <Divider />
                <AccordionDetails sx={{ p: 0, py: 2 }}>
                    {children}
                </AccordionDetails>
            </StyledAccordion>
        </Box>
    );
};

AccordionProduct.defaultProps = {
    classNameCard: '',
    classNameExpandIcon: '',
    defaultExpanded: false,
    isHidden: false,
    numericalSign: '',
};

AccordionProduct.propTypes = {
    caption: PropTypes.string,
    cardId: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
    ]),
    classNameCard: PropTypes.string,
    classNameExpandIcon: PropTypes.string,
    defaultExpanded: PropTypes.bool,
    isHidden: PropTypes.bool,
    isLoading: PropTypes.bool,
    svgIcon: PropTypes.object,
    title: PropTypes.string,
    total: PropTypes.string,
};

export default AccordionProduct;
