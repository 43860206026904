import { styled } from '@mui/material/styles';
import { Grid } from 'sunwise-ui';

export default styled(Grid)`
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;

    @media all and (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 1000%;
    }
`;
