import React from 'react';

import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const UserFormatter = ({ row }) => {
    const { created_by } = row;
    return (
        <Typography variant="caption">
            {created_by?.first_name} {created_by?.last_name}
        </Typography>
    );
};

UserFormatter.propTypes = {
    row: PropTypes.object,
};

export default UserFormatter;
