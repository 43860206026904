import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    background: #fff;
    line-break: auto;
    max-width: 100%;
    padding: 0.375rem 0.75rem;
    white-space: normal;

    &.active,
    &:active,
    &:hover {
        color: #848bab;
        background-color: rgba(239, 241, 251, 0.41);
    }
`;
