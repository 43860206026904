import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Grid, Tooltip, Typography } from 'sunwise-ui';

import { CB_PORTAL_SECTIONS } from 'common/constants';
import { getTranslatedAddonLabel } from 'common/utils/helpers';

const ProjectAddons = ({ canModify, handlePortal, subscription }) => {
    const { t } = useTranslation();

    if (isEmpty(subscription)) return null;

    const addons = get(subscription, 'addons', []);
    const counters = get(subscription, 'counters', {});

    return (
        <Card>
            <Card.Header>
                <Typography variant="body1" fontWeight="bold">
                    {t('Project', { count: 2 })} / {t('Add-Ons')}
                </Typography>
            </Card.Header>
            <Card.Body sx={{ minHeight: '487px' }}>
                <Grid container>
                    <Grid item xs={18} display="flex" flexDirection="column">
                        <Box hidden={isNull(subscription?.counters)}>
                            <Box display="flex" alignItems="center" gap={2}>
                                <Typography variant="body2" fontWeight="bold">
                                    {t('Total available projects')}:{' '}
                                    {counters?.available_projects}
                                </Typography>
                                <Tooltip
                                    title={
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gap={2}
                                            p={1}
                                            sx={{
                                                width: {
                                                    xs: 'auto',
                                                    lg: '160px',
                                                },
                                            }}
                                        >
                                            <Typography variant="caption">
                                                {t(
                                                    'Projects included in your plan expire upon renewal',
                                                )}
                                                .
                                            </Typography>
                                            <Typography variant="caption">
                                                {t(
                                                    'Additional projects have no expiration date',
                                                )}
                                                .
                                            </Typography>
                                        </Box>
                                    }
                                    placement="bottom"
                                >
                                    <InfoIcon sx={{ color: 'primary.main' }} />
                                </Tooltip>
                            </Box>
                            <Box ml={3}>
                                <Typography variant="body2" mt={1}>
                                    {`${t('Included in your plan')}: ${
                                        counters?.available_current
                                    }/${counters?.maximum_current}`}
                                </Typography>
                                <Typography variant="body2" mt={1}>
                                    {`${t('Additional projects')}: ${
                                        counters?.available_purchased
                                    }`}
                                </Typography>
                            </Box>
                        </Box>
                        <Typography
                            fontWeight="bold"
                            mt={isNull(subscription?.counters) ? 0 : 3}
                            variant="body2"
                        >
                            {t('Add-Ons')}
                        </Typography>

                        <Box ml={3} mt={1}>
                            {addons.map((addon) => (
                                <Typography variant="body2" key={addon}>
                                    {getTranslatedAddonLabel(addon)}
                                </Typography>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Card.Body>
            <Card.Actions>
                <Box p={1} textAlign="right" sx={{ width: '100%' }}>
                    <Button
                        onClick={() =>
                            handlePortal(CB_PORTAL_SECTIONS.EDIT_SUBSCRIPTION)
                        }
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        visible={canModify}
                    >
                        {t('Buy additionals')}
                    </Button>
                </Box>
            </Card.Actions>
        </Card>
    );
};

ProjectAddons.propTypes = {
    canModify: PropTypes.bool,
    handlePortal: PropTypes.func,
    subscription: PropTypes.object,
};

export default ProjectAddons;
