import { getItemsByLevel } from 'common/api/v1/politicalDivisions';

import {
    FETCH_POLITICAL_DIVISIONS,
    FETCH_POLITICAL_DIVISIONS_SUCCESS,
    FETCH_POLITICAL_DIVISIONS_FAILURE,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

export default (str) => (dispatch) => {
    dispatch(projectCommOfferTableActions[FETCH_POLITICAL_DIVISIONS]());

    getItemsByLevel(str)
        .then((response) =>
            dispatch(
                projectCommOfferTableActions[FETCH_POLITICAL_DIVISIONS_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                projectCommOfferTableActions[FETCH_POLITICAL_DIVISIONS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
