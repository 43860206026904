import { number, object } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { getMinValueText, NUMBER_TYPE_TEXT, REQUIRED_TEXT } =
        getValidationTexts();
    return object().shape({
        generation: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        sunHoursValue: number().when('sunHoursMode', {
            is: 'sunHoursValue',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .min(0, getMinValueText(0))
                    .nullable(),
        }),
        kwhValue: number().when('sunHoursMode', {
            is: 'kwhValue',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .nullable(),
        }),
    });
};
