import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_OFFER_PATERN,
    FETCH_OFFER_PATERN_FAILURE,
    FETCH_OFFER_PATERN_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    offerInfoPatern: {
        data: {},
        errors: [],
        isFetching: false,
    },
};

const proposalGeneratorSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_OFFER_PATERN]: (state) => {
            state.offerInfoPatern = {
                ...state.offerInfoPatern,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_PATERN_FAILURE]: (state, action) => {
            state.offerInfoPatern = {
                ...state.offerInfoPatern,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_PATERN_SUCCESS]: (state, action) => {
            state.offerInfoPatern = {
                ...state.offerInfoPatern,
                data: action.payload,
                isFetching: false,
            };
        },
    },
});

export const proposalGeneratorActions = proposalGeneratorSlice.actions;

export default proposalGeneratorSlice.reducer;
