import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Card, Grid } from 'sunwise-ui';

import addProjectImage from 'resources/addproject.svg';

const CardWrapper = styled(Card)`
    .card-body {
        padding: 100px 15px;
    }
`;

const EmptyContainer = ({ toggleModal }) => {
    const { t } = useTranslation();
    return (
        <CardWrapper>
            <Card.Body onClick={() => toggleModal(true)}>
                <Grid container>
                    <Grid item lg={2} xs={4}>
                        <img alt="" src={addProjectImage} />
                    </Grid>
                </Grid>

                <p>
                    <Trans
                        defaults="<bold>Hears! You do not have products.<br/>Click to create one</bold>"
                        components={{ bold: <strong /> }}
                    />
                </p>

                <Button type="submit" startIcon={<AddIcon />}>
                    {t('Create product')}
                </Button>
            </Card.Body>
        </CardWrapper>
    );
};

EmptyContainer.propTypes = {
    toggleModal: PropTypes.func,
};

export default EmptyContainer;
