import { createItem, updateItem } from 'common/api/v1/structures';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { structuresConfigurationActions } from '../reducer';

import closeModalProduct from './closeModalProduct';

export default (filterData, values) => (dispatch) => {
    const newValues = {
        brand_name: values.brand_name,
        code: values.code,
        costing_unit: values.costing_unit,
        currency: values.currency,
        description: values.description,
        image: values.image,
        name: values.name,
        range_prices: JSON.stringify(values.range_prices),
    };

    dispatch(structuresConfigurationActions[SAVE_ITEM]());

    const save = values.id
        ? () => updateItem(newValues, values.id)
        : () =>
              createItem({
                  ...newValues,
                  branch_offices: values.branch_offices,
              });

    save()
        .then(() => {
            dispatch(structuresConfigurationActions[SAVE_ITEM_SUCCESS]());
            dispatch(closeModalProduct(filterData));
            showToast();
        })
        .catch((error) => {
            dispatch(
                structuresConfigurationActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
