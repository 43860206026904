import React, { useContext, useEffect, useState } from 'react';

import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { styled } from '@mui/material/styles';
import { Map } from 'google-maps-react';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import { DEFAULT_LAT, DEFAULT_LNG } from 'common/constants';
import { GeneralContext } from 'common/utils/contexts';

import { SVG_BASE_CLUSTER } from '../constants';

const MapWrapper = styled(Box)`
    background-color: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: relative;
    min-height: 400px;
    right: 0;
    top: 0;
    z-index: 1;
`;

const MapContainer = ({
    companyPositionLatLng,
    handleSelectProject,
    monitoringProjects,
}) => {
    const { google } = useContext(GeneralContext);
    const [mapValue, setMapValue] = useState(null);
    const initialCenter = {
        lat: Number(companyPositionLatLng?.latitude) || DEFAULT_LAT,
        lng: Number(companyPositionLatLng?.longitude) || DEFAULT_LNG,
    };

    useEffect(() => {
        const markers = [];
        let markerClusterer = null;
        if (google && mapValue && monitoringProjects?.length > 0) {
            const bounds = new google.maps.LatLngBounds();

            for (const project of monitoringProjects) {
                if (!project?.latitude || !project?.longitude) continue;
                const markerPosition = new google.maps.LatLng(
                    project?.latitude,
                    project?.longitude,
                );
                const marker = new google.maps.marker.AdvancedMarkerElement({
                    map: mapValue,
                    position: markerPosition,
                });

                markers.push(marker);
                bounds.extend(markerPosition);

                marker.addListener('click', () => {
                    handleSelectProject(project?.id);
                });
            }
            mapValue.fitBounds(bounds, 15);

            markerClusterer = new MarkerClusterer({
                map: mapValue,
                markers,
                renderer: {
                    render({ count, position }) {
                        const parser = new DOMParser();
                        const svgEl = parser.parseFromString(
                            SVG_BASE_CLUSTER(count),
                            'image/svg+xml',
                        ).documentElement;
                        svgEl.setAttribute('transform', 'translate(0 25)');

                        const clusterOptions = {
                            content: svgEl,
                            position,
                            zIndex:
                                Number(google.maps.Marker.MAX_ZINDEX) + count,
                        };
                        return new google.maps.marker.AdvancedMarkerElement(
                            clusterOptions,
                        );
                    },
                },
            });
        }
        return () => {
            markers.forEach((marker) => marker.setMap(null));
            markerClusterer?.clearMarkers();
        };
    }, [google, mapValue, monitoringProjects]);

    return (
        <MapWrapper>
            <Map
                disableDefaultUI={false}
                disableDoubleClickZoom={true}
                fullscreenControl={false}
                google={google}
                initialCenter={initialCenter}
                mapTypeControl
                onReady={(_, map) => {
                    map.setOptions({
                        mapId: import.meta.env.VITE_GOOGLE_MAPS_DEFAULT_ID,
                    });
                    setMapValue(map);
                    if (map?.setMapTypeId && google?.maps?.MapTypeId?.SATELLITE)
                        map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
                }}
                rotateControl={false}
                streetViewControl={false}
            />
        </MapWrapper>
    );
};

MapContainer.propTypes = {
    companyPositionLatLng: PropTypes.object,
    handleSelectProject: PropTypes.func,
    monitoringProjects: PropTypes.array,
};

export default MapContainer;
