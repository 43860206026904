import React, { useState } from 'react';

import ListIcon from '@mui/icons-material/List';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem, Typography } from 'sunwise-ui';

import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

const BranchOfficesDropdown = ({ branchOffices = [], keyName, label = '' }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    if (!getIsMultibranchesAccount())
        return (
            <Typography variant="caption">{branchOffices[0]?.name}</Typography>
        );

    return (
        <>
            <Button
                color="secondary"
                endIcon={<ListIcon />}
                onClick={handleClick}
                variant="outlined"
                size="small"
                sx={{ textTransform: 'none' }}
            >
                {label ? label : t('Branch office', { count: 2 })}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {branchOffices.map((branchOffice) => (
                    <MenuItem
                        key={`${keyName}-options-${branchOffice.id}`}
                        sx={{ '&.MuiMenuItem-root': { fontSize: '14px' } }}
                    >
                        {branchOffice.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

BranchOfficesDropdown.propTypes = {
    branchOffices: PropTypes.array,
    keyName: PropTypes.string,
    label: PropTypes.string,
};

export default BranchOfficesDropdown;
