import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem, Typography } from 'sunwise-ui';

const getInstallationTypeText = (installationType, t) => {
    switch (installationType) {
        case 0:
            return t('Residential');
        case 1:
            return t('Commercial');
        case 2:
            return t('Industrial');
        default:
            return t('No installation type');
    }
};

const InstallationTypeFormatter = ({ row }) => {
    const { t } = useTranslation();
    const installationTypes = get(row, 'installation_type', []);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    if (installationTypes.length === 0)
        return (
            <Typography variant="caption">
                {t('No installation type')}
            </Typography>
        );

    if (installationTypes.length === 1)
        return (
            <Typography variant="caption">
                {getInstallationTypeText(installationTypes[0], t)}
            </Typography>
        );

    return (
        <>
            <Button
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}
                variant="outlined"
            >
                {t('View rates')}
            </Button>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {installationTypes.map((item, index) => (
                    <MenuItem key={`${row.id}-dropdown-item-${index}`}>
                        {getInstallationTypeText(item, t)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

InstallationTypeFormatter.propTypes = { row: PropTypes.object };

export default InstallationTypeFormatter;
