import { fetchListCatalogs } from 'common/api/v1/companiesCatalogs';

import {
    FETCH_DOCUMENTS_TYPE_FAILURE,
    FETCH_DOCUMENTS_TYPE,
    FETCH_DOCUMENTS_TYPE_SUCCESS,
} from '../actionTypes';
import { DOCUMENTS_TYPE } from '../constants';
import { documentContactFormActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(documentContactFormActions[FETCH_DOCUMENTS_TYPE]());

    fetchListCatalogs(DOCUMENTS_TYPE)
        .then((response) =>
            dispatch(
                documentContactFormActions[FETCH_DOCUMENTS_TYPE_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                documentContactFormActions[FETCH_DOCUMENTS_TYPE_FAILURE](error),
            ),
        );
};
