import { push } from 'connected-react-router';

import { generateCheckoutNewUrl } from 'common/api/v1/chargebee';

import fetchUserSettings from '../../profile/actions/fetchProfileData';

export default ({ cbInstance, itemPriceId, redirectTo }) =>
    (dispatch) => {
        if (!cbInstance || !itemPriceId) return;

        cbInstance.openCheckout({
            hostedPage() {
                return generateCheckoutNewUrl({
                    item_price_id: itemPriceId,
                    is_addon: false,
                }).then((response) => response.data);
            },
            success: () => {},
            close: () => {
                dispatch(fetchUserSettings());
                if (redirectTo) dispatch(push(redirectTo));
            },
        });
    };
