import { object, number } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const {
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
        getMaxValueText,
        getMinValueText,
    } = getValidationTexts();

    return object().shape({
        degradation_percentage: number()
            .min(0, getMinValueText(0))
            .max(100, getMaxValueText(100))
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        discharge_power: number()
            .min(0, getMinValueText(0))
            .max(100000, getMaxValueText(100000))
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        dod: number()
            .min(0, getMinValueText(0))
            .max(100, getMaxValueText(100))
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        efficiency: number()
            .min(1, getMinValueText(1))
            .max(100, getMaxValueText(100))
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
    });
};
