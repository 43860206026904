import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import EmptyMessage from 'common/components/EmptyMessage';
import KeyIndicator from 'common/components/KeyIndicator';
import { numberFormat } from 'common/utils/helpers';

import FinancialProductImage from 'resources/financialProduct.png';

import ProductItem from './ProductItem';

const FinancialReview = ({
    currencyIso,
    currencyLocale,
    financialProductParameters,
    handleClickDeleteOwnFinancing,
    handleClickFinancialProduct,
    isLoading,
    isLocked,
    ownFinancialProduct,
    proposalId,
    show,
}) => {
    const { t } = useTranslation();

    if (!show) return null;
    if (isLoading) {
        return (
            <EmptyMessage>
                <Typography variant="body2">
                    {t('Loading').concat('...')}
                </Typography>
            </EmptyMessage>
        );
    }

    const productParameters = get(ownFinancialProduct, 'parameters', {});
    const {
        id,
        monthly_payment = 0,
        total_cost_financing = 0,
    } = financialProductParameters;
    const parameters = get(financialProductParameters, 'parameters', {});

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={9} md={4} lg={3}>
                <ProductItem
                    disabled
                    name={ownFinancialProduct.name}
                    onClick={() => {}}
                    srcImg={FinancialProductImage}
                />
            </Grid>
            <Grid item xs={18} lg={15}>
                <Box display="flex" mb={2}>
                    <DropdownCardMenu
                        items={[
                            {
                                disabled: isLocked,
                                handleClick: () =>
                                    handleClickFinancialProduct(
                                        {
                                            ...ownFinancialProduct,
                                            hitch: parameters.hitch,
                                            hitch_percentage:
                                                parameters.hitch_percentage,
                                            residual: parameters.residual,
                                            term: parameters.term,
                                        },
                                        true,
                                    ),
                                icon: <EditIcon fontSize="small" />,
                                label: t('Edit'),
                            },
                            {
                                disabled: isLocked,
                                handleClick: () =>
                                    handleClickDeleteOwnFinancing(
                                        id,
                                        proposalId,
                                    ),
                                icon: <DeleteIcon fontSize="small" />,
                                label: t('Delete'),
                            },
                        ]}
                        sxIconButton={{ ml: 'auto' }}
                    />
                </Box>
                <Grid container sx={{ pl: { xs: 2, lg: 3 } }}>
                    <Grid item xs={18} md={9} lg={6}>
                        <KeyIndicator
                            title={t('Interest rate')}
                            value={numberFormat(parameters.rate, {
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: '%',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={6}>
                        {productParameters.is_commission_percentage ? (
                            <KeyIndicator
                                title={t('Opening commission')}
                                value={numberFormat(
                                    parameters.commission_credit,
                                    {
                                        decimals: 2,
                                        locale: currencyLocale,
                                        style: 'decimal',
                                        unit: '%',
                                    },
                                )}
                                widthIcon="20px"
                            />
                        ) : (
                            <KeyIndicator
                                title={t('Opening commission')}
                                value={numberFormat(
                                    parameters.commission_credit,
                                    {
                                        currency: currencyIso,
                                        decimals: 2,
                                        locale: currencyLocale,
                                        style: 'currency',
                                    },
                                )}
                                widthIcon="20px"
                            />
                        )}
                    </Grid>
                    <Grid item xs={18} md={9} lg={6}>
                        <KeyIndicator
                            title={t('Term')}
                            value={`${parameters.term} ${t('Month', {
                                count: 2,
                            })}`}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={6}>
                        {productParameters.is_percentage ? (
                            <KeyIndicator
                                title={t('Down payment')}
                                value={numberFormat(
                                    parameters.hitch_percentage,
                                    {
                                        decimals: 2,
                                        locale: currencyLocale,
                                        style: 'decimal',
                                        unit: '%',
                                    },
                                )}
                                widthIcon="20px"
                            />
                        ) : (
                            <KeyIndicator
                                title={t('Down payment')}
                                value={numberFormat(parameters.hitch, {
                                    currency: currencyIso,
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'currency',
                                })}
                                widthIcon="20px"
                            />
                        )}
                    </Grid>
                    {parameters.residual > 0 && (
                        <Grid item xs={18} md={9} lg={6}>
                            <KeyIndicator
                                title={t('Residual')}
                                value={numberFormat(parameters.residual, {
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'decimal',
                                    unit: '%',
                                })}
                                widthIcon="20px"
                            />
                        </Grid>
                    )}
                    <Grid item xs={18} md={9} lg={6}>
                        <KeyIndicator
                            title={t('Average monthly payment')}
                            value={numberFormat(monthly_payment, {
                                currency: currencyIso,
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'currency',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={6}>
                        <KeyIndicator
                            title={t('Financing amount')}
                            value={numberFormat(total_cost_financing, {
                                currency: currencyIso,
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'currency',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

FinancialReview.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    financialProductParameters: PropTypes.object,
    handleClickDeleteOwnFinancing: PropTypes.func,
    handleClickFinancialProduct: PropTypes.func,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    ownFinancialProduct: PropTypes.object,
    proposalId: PropTypes.string,
    show: PropTypes.bool,
};

export default FinancialReview;
