import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Typography } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import { GeneralContext } from 'common/utils/contexts';
import { numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validateObstacle';

const ObstacleEditForm = ({
    commercialOfferId,
    initialValues,
    mapRef,
    onCloseDrawer,
    resetForm,
    sxBox,
    updateSegment,
}) => {
    const { t } = useTranslation();
    const { google } = useContext(GeneralContext);
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const { control, getValues, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const formValues = getValues();

    const zoomLevel = mapRef.current ? mapRef.current.getZoomMap() : 20;

    const handleOnSave = (values) =>
        updateSegment({
            callback: () => {
                onCloseDrawer();
                resetForm();
            },
            commercialOfferId,
            google,
            mapRef,
            mapValue: mapRef.current.self(),
            values,
            zoomLevel,
        });

    return (
        <Box sx={sxBox}>
            <Box sx={{ mb: 2 }}>
                <Typography variant="caption">
                    {t('Total area')}{' '}
                    {numberFormat(formValues?.total_area, {
                        decimals: 2,
                        locale: countryCurrencyLocale,
                        style: 'decimal',
                    })}{' '}
                    m2
                </Typography>
            </Box>

            <form onSubmit={handleSubmit(handleOnSave)}>
                <ReactHookFormInput
                    control={control}
                    fullWidth
                    label={t('Name')}
                    name="name"
                />

                <ReactHookFormIntlNumberInput
                    append="m"
                    control={control}
                    fullWidth
                    label={t('Height')}
                    name="height"
                />

                <ReactHookFormIntlNumberInput
                    append="m"
                    control={control}
                    fullWidth
                    label={t('Set back')}
                    name="safe_zone"
                />

                <Button
                    fullWidth
                    sx={{ '&.MuiButton-root': { mb: 0 } }}
                    type="submit"
                >
                    {t('Save')}
                </Button>

                <Button
                    fullWidth
                    onClick={() => resetForm()}
                    sx={{ '&.MuiButton-root': { mb: 0 } }}
                    variant="text"
                >
                    {t('Cancel')}
                </Button>
            </form>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => dispatch(actions.resetForm()),
    updateSegment: (config) => dispatch(actions.updateSegment(config)),
});

ObstacleEditForm.propTypes = {
    commercialOfferId: PropTypes.string,
    initialValues: PropTypes.object,
    mapRef: PropTypes.object,
    onCloseDrawer: PropTypes.func,
    resetForm: PropTypes.func,
    sxBox: PropTypes.object,
    updateSegment: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ObstacleEditForm);
