import { styled } from '@mui/material/styles';

const StyledGroup = styled('div')`
    position: relative;
    margin: 16px 0 32px;

    textarea {
        resize: none;
    }

    input,
    textarea,
    select {
        background: none;
        border-bottom: 1px solid #c6c6c6;
        border-left: none;
        border-radius: 0;
        border-right: none;
        border-top: none;
        color: #c6c6c6;
        display: block;
        font-size: 18px;
        margin-top: 16px;
        padding: 10px 10px 10px 5px;
        width: 100%;

        &:focus {
            outline: none;
        }

        &:focus ~ label,
        &.dirty ~ label {
            color: #2196f3;
            font-size: 12px;
            top: -14px;
        }

        &:focus ~ .bar:before {
            width: 100%;
        }
    }

    input[type='password'] {
        letter-spacing: 0.3em;
    }

    label {
        color: #c6c6c6;
        font-size: 16px;
        font-weight: normal;
        left: 5px;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        text-overflow: ellipsis;
        top: 10px;
        transition: 300ms ease all;
        white-space: nowrap;
        width: 90%;
    }

    .bar {
        display: block;
        position: relative;
        width: 100%;

        &:before {
            background: #2196f3;
            bottom: 0px;
            content: '';
            height: 2px;
            left: 0%;
            position: absolute;
            transition: 300ms ease all;
            width: 0;
        }
    }

    &.error {
        input,
        select {
            border-bottom: 1px solid #f44336;
        }

        input ~ label,
        select ~ label {
            color: #f44336;
        }
    }
`;

export default StyledGroup;
