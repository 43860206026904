import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

const UnitPriceColumn = ({
    canModify,
    control,
    customerMode,
    index,
    name,
    size,
}) => {
    const { t } = useTranslation();

    if (!canModify) return null;

    return (
        <Grid item xs lg={customerMode ? 18 : size.unit_price[0]}>
            <ReactHookFormIntlNumberInput
                control={control}
                disabled
                fullWidth
                label={t('Unit Price')}
                name={`${name}.${index}.unit_price`}
                variant="standard"
            />
        </Grid>
    );
};

UnitPriceColumn.propTypes = {
    canModify: PropTypes.bool,
    control: PropTypes.object,
    customerMode: PropTypes.bool,
    index: PropTypes.number,
    name: PropTypes.string,
    size: PropTypes.object,
};

export default withPermissions(PERMISSION_LIST.SEE_UNIT_PRICE)(UnitPriceColumn);
