import isEmpty from 'lodash/isEmpty';

import { getCountryCurrency } from 'common/utils/helpers/session';

import { SET_MODAL_IS_CREATE } from '../actionTypes';
import { INIT_VALUES_DEFAULT } from '../constants';
import { ratesActions } from '../reducer';

import initializeRateModalForm from './initializeRateModalForm';
import setHasActiveProjects from './setHasActiveProjects';
import setModalIsOpen from './setModalIsOpen';

export default (country) => (dispatch) => {
    dispatch(ratesActions[SET_MODAL_IS_CREATE](true));
    dispatch(setHasActiveProjects(false));
    const currency = getCountryCurrency();
    dispatch(
        initializeRateModalForm({
            ...INIT_VALUES_DEFAULT,
            main_data: {
                ...INIT_VALUES_DEFAULT.main_data,
                country,
                currency: !isEmpty(currency) && currency.id,
            },
        }),
    );
    dispatch(setModalIsOpen(true));
};
