import { createSlice } from '@reduxjs/toolkit';

import orm, { modelPopulateByArray } from 'common/orm';

import {
    FETCH_COMPATIBLE_RATES,
    FETCH_COMPATIBLE_RATES_FAILURE,
    FETCH_COMPATIBLE_RATES_SUCCESS,
    FETCH_SCHEDULE_RATE_CONFIGURATION,
    FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE,
    FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS,
    INITIAL_VALUES,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    UPSERT_RATE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    entities: orm.getEmptyState(),
    initialValues: {
        compensation_scheme: null,
        previous_contracted_demand: null,
        initial_rate_name: '',
        next_contracted_demand: {},
        next_rate: null,
        previous_rate: '',
    },
    compatibleRates: { data: [], errors: [], isFetching: false },
    save: { data: [], errors: [], isSaving: false },
    scheduleRateConfiguration: { data: {}, errors: [], isFetching: false },
};

const analysisVsGenerationSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_COMPATIBLE_RATES]: (state) => {
            state.compatibleRates.errors = [];
            state.compatibleRates.isFetching = true;
        },
        [FETCH_COMPATIBLE_RATES_FAILURE]: (state, action) => {
            state.compatibleRates.errors = action.payload;
            state.compatibleRates.isFetching = false;
        },
        [FETCH_COMPATIBLE_RATES_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Rate } = session;
            modelPopulateByArray(Rate, action.payload);

            state.compatibleRates.data = action.payload.map(({ id }) => id);
            state.compatibleRates.isFetching = false;

            state.entities = session.state;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION]: (state) => {
            state.scheduleRateConfiguration.errors = [];
            state.scheduleRateConfiguration.isFetching = true;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE]: (state, action) => {
            state.scheduleRateConfiguration.errors = action.payload;
            state.scheduleRateConfiguration.isFetching = false;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS]: (state, action) => {
            state.scheduleRateConfiguration.data = action.payload;
            state.scheduleRateConfiguration.isFetching = false;
        },
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save.errors = [];
            state.save.isSaving = true;
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save.errors = action.payload;
            state.save.isSaving = false;
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save.data = action.payload;
            state.save.isSaving = false;
        },
        [UPSERT_RATE]: (state, action) => {
            const session = orm.session(state.entities);
            const { Rate } = session;
            Rate.generate(action.payload);

            state.entities = session.state;
        },
    },
});

export const analysisVsGenerationActions = analysisVsGenerationSlice.actions;

export default analysisVsGenerationSlice.reducer;
