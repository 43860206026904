import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from 'sunwise-ui';

import PlaceholderConsumptionProfileDetail from 'common/components/placeholder/PlaceholderConsumptionProfileDetail';
import { scroll } from 'common/utils/mixins';

import DetailContent from './DetailContent';

const CardWrapper = styled(Card)`
    display: flex;
    flex-direction: column;
    overflow-y: overlay;
    padding: 16px;

    ${scroll.custom()}

    &.firstLoad {
        align-items: center;
        height: 400px;
        justify-content: center;

        @media (min-width: 900px) and (max-width: 1199px) {
            height: calc(100vh - 374px);
        }

        @media (min-width: 1200px) {
            height: calc(100vh - 385px);
        }
    }

    @media (min-width: 900px) and (max-width: 1199px) {
        max-height: calc(100vh - 374px);
    }
`;

const ConsumptionProfileDetail = ({
    canModifyCustomConsumptionProfile,
    canDeleteCustomConsumptionProfile,
    deleteConsumptionProfile,
    isFetchingConsumptionProfile,
    prepareArchiveConsumptionProfile,
    selectedItemId,
    selectedItemModel,
}) => {
    const { t } = useTranslation();

    if (!selectedItemId || !selectedItemModel)
        return (
            <CardWrapper className="firstLoad">
                <Card.Body>
                    <Typography variant="body2">
                        {t('Select a consumption profile to start')}
                    </Typography>
                </Card.Body>
            </CardWrapper>
        );
    return (
        <PlaceholderConsumptionProfileDetail
            ready={isFetchingConsumptionProfile}
        >
            <CardWrapper>
                <Card.Body>
                    <DetailContent
                        canModify={canModifyCustomConsumptionProfile}
                        canDelete={canDeleteCustomConsumptionProfile}
                        deleteConsumptionProfile={deleteConsumptionProfile}
                        prepareArchiveConsumptionProfile={
                            prepareArchiveConsumptionProfile
                        }
                        selectedItemModel={selectedItemModel}
                    />
                </Card.Body>
            </CardWrapper>
        </PlaceholderConsumptionProfileDetail>
    );
};

ConsumptionProfileDetail.propTypes = {
    canModifyCustomConsumptionProfile: PropTypes.bool,
    canDeleteCustomConsumptionProfile: PropTypes.bool,
    deleteConsumptionProfile: PropTypes.func,
    isFetchingConsumptionProfile: PropTypes.bool,
    prepareArchiveConsumptionProfile: PropTypes.func,
    selectedItemId: PropTypes.string,
    selectedItemModel: PropTypes.object,
};

export default ConsumptionProfileDetail;
