import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import BarChartIcon from '@mui/icons-material/BarChart';
import FilterListIcon from '@mui/icons-material/FilterList';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ViewListIcon from '@mui/icons-material/ViewList';
import { styled } from '@mui/material/styles';
import { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Divider,
    Grid,
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';

import { VIEWS } from '../constants';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: theme.shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
        {
            marginLeft: -1,
            borderLeft: '1px solid transparent',
        },
}));

const tabButtons = [
    { Icon: LocationOnIcon, label: 'Map', value: VIEWS.MAP },
    { Icon: BarChartIcon, label: 'Chart', value: VIEWS.CHART },
    { Icon: ViewListIcon, label: 'Table', value: VIEWS.TABLE },
];

const Header = ({ handleOnChangeView, handleOpenFiltersModal, view }) => {
    const { t } = useTranslation();

    return (
        <HeaderContainer>
            <Grid item xs>
                <TitleIcon
                    icon={<ArticleIcon color="primary" fontSize="large" />}
                    title={t('Monitoring')}
                />
            </Grid>

            <Grid display="flex" gap={1} justifyContent="flex-end" item xs>
                <StyledToggleButtonGroup
                    aria-label="view"
                    exclusive
                    onChange={(_, value) => handleOnChangeView(value)}
                    value={view}
                    size="small"
                >
                    {tabButtons.map(({ Icon, label, value }) => (
                        <ToggleButton
                            aria-label={label}
                            key={value}
                            value={value}
                        >
                            <Tooltip title={t(label)} placement="bottom">
                                <Icon />
                            </Tooltip>
                        </ToggleButton>
                    ))}
                </StyledToggleButtonGroup>

                <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mx: 0.5, my: 1 }}
                />
                <Tooltip title={t('Filter', { count: 2 })} placement="bottom">
                    <IconButton onClick={handleOpenFiltersModal}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </HeaderContainer>
    );
};

Header.propTypes = {
    handleOnChangeView: PropTypes.func,
    handleOpenFiltersModal: PropTypes.func,
    view: PropTypes.string,
};

export default Header;
