import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import RemoveIcon from '@mui/icons-material/Remove';
import WarningIcon from '@mui/icons-material/Warning';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

const ToastNotification = ({ title, body, type, content = null }) => {
    const renderIcon = (type) => {
        switch (type) {
            case 'danger':
                return <RemoveIcon sx={{ color: '#fa6968' }} />;
            case 'offline':
                return <WifiOffIcon sx={{ color: '#bfd3da' }} />;
            case 'online':
                return <NetworkWifiIcon sx={{ color: '#2bf2ab' }} />;
            case 'success':
                return <CheckCircleIcon sx={{ color: '#00b667' }} />;
            case 'warning':
                return <WarningIcon sx={{ color: '#bfd3da' }} />;
            default:
                return <HelpCenterIcon sx={{ color: '#008dff' }} />;
        }
    };

    return (
        <Box display="flex">
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    p: 0.5,
                    width: '60px',
                }}
            >
                {renderIcon(type)}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 0.5,
                }}
            >
                <Typography variant="caption" fontWeight="bold">
                    {title}
                </Typography>
                <Typography variant="caption">{body}</Typography>
                {content}
            </Box>
        </Box>
    );
};

ToastNotification.propTypes = {
    content: PropTypes.object,
    body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    type: PropTypes.string,
};

export default ToastNotification;
