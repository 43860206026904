import { getItems } from 'common/api/v1/users';

import {
    FETCH_AGENTS,
    FETCH_AGENTS_FAILURE,
    FETCH_AGENTS_SUCCESS,
} from '../actionTypes';
import { projectsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(projectsActions[FETCH_AGENTS]());

    getItems()
        .then((response) =>
            dispatch(projectsActions[FETCH_AGENTS_SUCCESS](response.data.data)),
        )
        .catch((error) =>
            dispatch(
                projectsActions[FETCH_AGENTS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
