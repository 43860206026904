import { NAME } from './constants';

export const FETCH_BILLING_INFORMATION = `${NAME}/FETCH_BILLING_INFORMATION`;
export const FETCH_BILLING_INFORMATION_FAILURE = `${NAME}/FETCH_BILLING_INFORMATION_FAILURE`;
export const FETCH_BILLING_INFORMATION_SUCCESS = `${NAME}/FETCH_BILLING_INFORMATION_SUCCESS`;

export const FETCH_PAYMENT_SOURCES = `${NAME}/FETCH_PAYMENT_SOURCES`;
export const FETCH_PAYMENT_SOURCES_FAILURE = `${NAME}/FETCH_PAYMENT_SOURCES_FAILURE`;
export const FETCH_PAYMENT_SOURCES_SUCCESS = `${NAME}/FETCH_PAYMENT_SOURCES_SUCCESS`;

export const FETCH_SUBSCRIPTION = `${NAME}/FETCH_SUBSCRIPTION`;
export const FETCH_SUBSCRIPTION_FAILURE = `${NAME}/FETCH_SUBSCRIPTION_FAILURE`;
export const FETCH_SUBSCRIPTION_SUCCESS = `${NAME}/FETCH_SUBSCRIPTION_SUCCESS`;

export const PAY_INVOICES = `${NAME}/PAY_INVOICES`;
export const PAY_INVOICES_FAILURE = `${NAME}/PAY_INVOICES_FAILURE`;
export const PAY_INVOICES_SUCCESS = `${NAME}/PAY_INVOICES_SUCCESS`;
