import { isToday, isYesterday } from 'date-fns';
import i18next from 'i18next';

import { formatDate, parseDate } from 'common/utils/dates';

import { DATE_FORMATS } from './constants';

export const getDateTitle = (date, dateFormat = 'yyyy-MM-dd') => {
    if (!date) return '';

    const parsedDate = parseDate(date, dateFormat);

    if (isToday(parsedDate)) return i18next.t('Today');
    if (isYesterday(parsedDate)) return i18next.t('Yesterday');
    return formatDate(parsedDate, DATE_FORMATS.TITLE);
};

export const getProjectsWithoutInfo = ({ logsData, projectsInfo }) => {
    const projectsWithoutInfoSet = new Set();

    for (const log of logsData) {
        if (!log?.reads?.length) continue;
        for (const read of log.reads) {
            if (projectsInfo?.[read?.sunwise_project_id]) continue;
            projectsWithoutInfoSet.add(read.sunwise_project_id);
        }
    }
    return Array.from(projectsWithoutInfoSet);
};
