import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

const StyledContainer = styled(Box)`
    & {
        animation: fadeIn 0.25s;
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    width: 100%;
`;

const OpacityAnimation = ({ children, className, sx }) => (
    <StyledContainer className={className} sx={sx}>
        {children}
    </StyledContainer>
);

OpacityAnimation.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    className: PropTypes.string,
    sx: PropTypes.object,
};

export default OpacityAnimation;
