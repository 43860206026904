import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_TAXES,
    DELETE_TAXES_FAILURE,
    DELETE_TAXES_SUCCESS,
    FETCH_TAXES,
    FETCH_TAXES_FAILURE,
    FETCH_TAXES_SUCCESS,
    RESET_TAXES,
    SAVE_TAXES,
    SAVE_TAXES_FAILURE,
    SAVE_TAXES_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    deleteTaxes: {
        data: [],
        errors: null,
        isDeleting: false,
    },
    fetchTaxes: {
        data: [],
        errors: null,
        isFetching: false,
    },
    saveTaxes: {
        data: {},
        errors: null,
        isSaving: false,
    },
};

const taxesConfigurationSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_TAXES]: (state) => {
            state.deleteTaxes = {
                ...state.deleteTaxes,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_TAXES_FAILURE]: (state, action) => {
            state.deleteTaxes = {
                ...state.deleteTaxes,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_TAXES_SUCCESS]: (state, action) => {
            state.deleteTaxes = {
                ...state.deleteTaxes,
                data: action.payload,
                isDeleting: false,
            };
        },
        [FETCH_TAXES]: (state) => {
            state.fetchTaxes = {
                ...state.fetchTaxes,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_TAXES_FAILURE]: (state, action) => {
            state.fetchTaxes = {
                ...state.fetchTaxes,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TAXES_SUCCESS]: (state, action) => {
            state.fetchTaxes = {
                ...state.fetchTaxes,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET_TAXES]: (state) => {
            state.fetchTaxes = INITIAL_STATE.fetchTaxes;
        },
        [SAVE_TAXES]: (state) => {
            state.saveTaxes = {
                ...state.saveTaxes,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_TAXES_FAILURE]: (state, action) => {
            state.saveTaxes = {
                ...state.saveTaxes,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_TAXES_SUCCESS]: (state, action) => {
            state.saveTaxes = {
                ...state.saveTaxes,
                data: action.payload,
                isSaving: false,
            };
        },
    },
});

export const taxesConfigurationActions = taxesConfigurationSlice.actions;

export default taxesConfigurationSlice.reducer;
