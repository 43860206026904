import { updateItem } from 'common/api/v1/companiesCatalogs';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPDATE_ACTIVE_STATUS,
    UPDATE_ACTIVE_STATUS_FAILURE,
    UPDATE_ACTIVE_STATUS_SUCCESS,
} from '../actionTypes';
import { listSettingsActions } from '../reducer';

export default (id, isActive) => (dispatch) => {
    dispatch(listSettingsActions[UPDATE_ACTIVE_STATUS](id));

    const values = {
        id,
        is_active: isActive,
    };
    updateItem(values, id)
        .then(() => {
            dispatch(
                listSettingsActions[UPDATE_ACTIVE_STATUS_SUCCESS](isActive),
            );
            showToast();
        })
        .catch((error) => {
            dispatch(
                listSettingsActions[UPDATE_ACTIVE_STATUS_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
