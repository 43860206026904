import { DEFAULT_SCHEDULE_RATE_CONFIGURATION } from 'common/constants/rates';

import { BATTERY_STATUS } from './constants';

export const getFormattedTimeShiftingConfiguration = ({
    saturday_distribution,
    schedule_rate_configuration,
    sunday_distribution,
    weekday_distribution,
}) => {
    const configuration =
        schedule_rate_configuration || DEFAULT_SCHEDULE_RATE_CONFIGURATION;

    const {
        saturday_distribution: rateSaturdayDistribution,
        sunday_distribution: rateSundayDistribution,
        weekday_distribution: rateWeekdayDistribution,
    } = configuration?.tiers_energy_distribution?.[0] || {};

    const battery_hourly_status = {
        saturday: getDistributionArray(
            saturday_distribution,
            rateSaturdayDistribution,
        ),
        sunday: getDistributionArray(
            sunday_distribution,
            rateSundayDistribution,
        ),
        weekday: getDistributionArray(
            weekday_distribution,
            rateWeekdayDistribution,
        ),
    };

    return { battery_hourly_status };
};

const getDistributionArray = (batteryDistribution, rateDistribution) => {
    if (!batteryDistribution?.length || !rateDistribution?.length) return [];

    return rateDistribution.map((tier, index) => ({
        value: tier + 1,
        color: BATTERY_STATUS[batteryDistribution?.[index] || 0].color,
    }));
};

export const getFlatTimeShiftingConfiguration = (distribution) => {
    const colorToValue = BATTERY_STATUS.reduce((acc, item) => {
        acc[item.color] = item.value;
        return acc;
    }, {});

    return distribution.map((item) => colorToValue[item.color]);
};
