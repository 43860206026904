import { INITIALIZE_VALUES } from '../actionTypes';
import { getDictionaryById } from '../helpers';
import { panelLayoutV2Actions } from '../reducer';
import * as selectors from '../selectors';

export default (segmentId) => (dispatch, getState) => {
    const state = getState();
    const segments = selectors.getSegmentsData(state);
    const segmentsDictionary = getDictionaryById(segments);
    if (segmentsDictionary[segmentId]) {
        dispatch(
            panelLayoutV2Actions[INITIALIZE_VALUES](
                segmentsDictionary[segmentId],
            ),
        );
    }
};
