import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

const LowReliabilityWarning = ({ warnings }) => {
    const { t } = useTranslation();

    const {
        hasAvgConsumptionWarning,
        hasInconsistentDemands,
        hasMissingFields,
        hasPredictedValues,
    } = warnings || {};

    if (
        !hasAvgConsumptionWarning &&
        !hasInconsistentDemands &&
        !hasMissingFields &&
        !hasPredictedValues
    )
        return null;

    return (
        <Alert severity="warning">
            {t(
                "The provided data contains warnings. It is strongly recommended to review it using AI for a more reliable assessment. It is also recommended to have a visual inspection after AI's data recollection",
            )}
        </Alert>
    );
};

LowReliabilityWarning.propTypes = {
    warnings: PropTypes.object,
};

export default LowReliabilityWarning;
