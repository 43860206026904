import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_TIME_SHIFTING_CONFIGURATION,
    FETCH_TIME_SHIFTING_CONFIGURATION_FAILURE,
    FETCH_TIME_SHIFTING_CONFIGURATION_SUCCESS,
    RESET,
    SAVE_TIME_SHIFTING_CONFIGURATION,
    SAVE_TIME_SHIFTING_CONFIGURATION_FAILURE,
    SAVE_TIME_SHIFTING_CONFIGURATION_SUCCESS,
    SET_INITIAL_VALUES,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchTimeShiftingConfiguration: {
        data: {},
        errors: null,
        isFetching: false,
    },
    initialValues: {
        battery_hourly_status: {},
        select: { saturday: 0, sunday: 0, weekday: 0 },
    },
    saveTimeShiftingConfiguration: {
        data: {},
        errors: null,
        isSaving: false,
    },
};

const timeShiftingConfigurationSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_TIME_SHIFTING_CONFIGURATION]: (state) => {
            state.fetchTimeShiftingConfiguration = {
                ...state.fetchTimeShiftingConfiguration,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_TIME_SHIFTING_CONFIGURATION_FAILURE]: (state, action) => {
            state.fetchTimeShiftingConfiguration = {
                ...state.fetchTimeShiftingConfiguration,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TIME_SHIFTING_CONFIGURATION_SUCCESS]: (state, action) => {
            state.fetchTimeShiftingConfiguration = {
                ...state.fetchTimeShiftingConfiguration,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [SAVE_TIME_SHIFTING_CONFIGURATION]: (state) => {
            state.saveTimeShiftingConfiguration = {
                ...state.saveTimeShiftingConfiguration,
                errors: null,
                isSaving: true,
            };
        },
        [SAVE_TIME_SHIFTING_CONFIGURATION_FAILURE]: (state, action) => {
            state.saveTimeShiftingConfiguration = {
                ...state.saveTimeShiftingConfiguration,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_TIME_SHIFTING_CONFIGURATION_SUCCESS]: (state, action) => {
            state.saveTimeShiftingConfiguration = {
                ...state.saveTimeShiftingConfiguration,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
    },
});

export const timeShiftingConfigurationActions =
    timeShiftingConfigurationSlice.actions;

export default timeShiftingConfigurationSlice.reducer;
