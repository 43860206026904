import React from 'react';

import PropTypes from 'prop-types';
import { TemplateView, editionLevels } from 'sunwise-template-core';

import PdfViewer from 'common/components/PdfViewer';

import { ELECTRIC_BILL_TYPE } from '../constants';

const PreviewContent = ({ editionDisabled, selectedDocument }) => {
    switch (selectedDocument?.type) {
        case ELECTRIC_BILL_TYPE:
            return (
                <PdfViewer
                    document={{ url: selectedDocument.archive }}
                    format={selectedDocument?.format}
                />
            );
        default:
            return (
                <TemplateView
                    editionLevel={
                        editionDisabled
                            ? editionLevels.NO_EDITION_MODE
                            : editionLevels.PARTIAL_EDITION_MODE
                    }
                />
            );
    }
};

PreviewContent.propTypes = {
    editionDisabled: PropTypes.bool,
    selectedDocument: PropTypes.object,
};

export default PreviewContent;
