import React from 'react';

import { useTranslation } from 'react-i18next';
import { Alert, Link } from 'sunwise-ui';

const YouNoNotSeeTheProduct = () => {
    const { t } = useTranslation();
    return (
        <Alert fontSize="small" severity="info">
            {t(
                'You do not see the product you want to add here ?. Email us at',
            )}{' '}
            <Link
                href={`mailto:${import.meta.env.VITE_DATA_SHEET_EMAIL}`}
                rel="noopener noreferrer"
                target="_blank"
                underline="hover"
                variant="text"
            >
                {import.meta.env.VITE_DATA_SHEET_EMAIL}
            </Link>
        </Alert>
    );
};

YouNoNotSeeTheProduct.propTypes = {};

export default YouNoNotSeeTheProduct;
