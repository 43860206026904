import get from 'lodash/get';

import { updateAdvancedConsumptionSettings } from 'common/api/v2/offerConsumptionAndGeneration';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { advancedConsumptionSettingsAction } from '../reducer';

export default (proposalId, values) => (dispatch) => {
    const consumption = get(values, 'consumption', null);

    if (consumption === null) return;

    const newValues = { consumption };

    dispatch(advancedConsumptionSettingsAction[SAVE]());

    updateAdvancedConsumptionSettings(proposalId, newValues)
        .then((response) => {
            dispatch(
                advancedConsumptionSettingsAction[SAVE_SUCCESS](
                    response.data.data,
                ),
            );
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                advancedConsumptionSettingsAction[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
