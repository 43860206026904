import { getItems } from 'common/api/v1/integrationsSuppliers';

import {
    FETCH_INTEGRATIONS,
    FETCH_INTEGRATIONS_FAILURE,
    FETCH_INTEGRATIONS_SUCCESS,
} from '../actionTypes';
import { supplierIntegrationsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(supplierIntegrationsActions[FETCH_INTEGRATIONS]());

    getItems()
        .then((response) =>
            dispatch(
                supplierIntegrationsActions[FETCH_INTEGRATIONS_SUCCESS](
                    response?.data?.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                supplierIntegrationsActions[FETCH_INTEGRATIONS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
