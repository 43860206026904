import React from 'react';

import PropTypes from 'prop-types';
import { Box, Card, Divider, Grid, Skeleton } from 'sunwise-ui';

const buildSkeletonStructChart = (rows) => {
    const items = [];

    for (let i = 0; i < rows; i++) {
        items.push(
            <Box key={`placeholder-card-chart-${i}`} sx={{ my: 1 }}>
                <Skeleton variant="rounded" />
            </Box>,
        );
    }
    return items;
};

const buildSkeletonStructColumns = (columns) => {
    const items = [];
    for (let i = 0; i < columns; i++) {
        items.push(
            <Grid item xs={3} key={`placeholder-card-column-${i}`}>
                <Skeleton variant="rounded" />
            </Grid>,
        );
    }

    return items;
};

const Placeholder = (
    <Card>
        <Card.Body>
            <Grid container mt={1}>
                <Grid item xs>
                    <Skeleton variant="rounded" />
                </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Grid container>{buildSkeletonStructColumns(1)}</Grid>

            {buildSkeletonStructChart(8)}

            <Grid container alignItems="center" justifyContent="space-between">
                {buildSkeletonStructColumns(6)}
            </Grid>
        </Card.Body>
    </Card>
);

const PlaceholderConsumptionProfileDetail = ({ children, ready }) => (
    <>{ready ? Placeholder : children}</>
);

PlaceholderConsumptionProfileDetail.propTypes = {
    children: PropTypes.element,
    ready: PropTypes.bool,
};

export default PlaceholderConsumptionProfileDetail;
