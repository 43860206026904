import { NAME } from './constants';

export const FETCH_RATE_CONCEPTS = `${NAME}/FETCH_RATE_CONCEPTS`;
export const FETCH_RATE_CONCEPTS_FAILURE = `${NAME}/FETCH_RATE_CONCEPTS_FAILURE`;
export const FETCH_RATE_CONCEPTS_SUCCESS = `${NAME}/FETCH_RATE_CONCEPTS_SUCCESS`;

export const INITIALIZE_RATE_FORM = `${NAME}/INITIALIZE_RATE_FORM`;
export const INITIALIZE_RATE_TAXES_FORM = `${NAME}/INITIALIZE_RATE_TAXES_FORM`;

export const RESET_RATE_CONCEPTS = `${NAME}/RESET_RATE_CONCEPTS`;
export const RESET_RATE_CONCEPTS_FAILURE = `${NAME}/RESET_RATE_CONCEPTS_FAILURE`;
export const RESET_RATE_CONCEPTS_SUCCESS = `${NAME}/RESET_RATE_CONCEPTS_SUCCESS`;

export const RESET = `${NAME}/RESET`;
export const RESET_RATE_FORM = `${NAME}/RESET_RATE_FORM`;

export const SAVE_RATE_CONCEPTS = `${NAME}/SAVE_RATE_CONCEPTS`;
export const SAVE_RATE_CONCEPTS_FAILURE = `${NAME}/SAVE_RATE_CONCEPTS_FAILURE`;
export const SAVE_RATE_CONCEPTS_SUCCESS = `${NAME}/SAVE_RATE_CONCEPTS_SUCCESS`;

export const SAVE_RATE_TAXES = `${NAME}/SAVE_RATE_TAXES`;
export const SAVE_RATE_TAXES_FAILURE = `${NAME}/SAVE_RATE_TAXES_FAILURE`;
export const SAVE_RATE_TAXES_SUCCESS = `${NAME}/SAVE_RATE_TAXES_SUCCESS`;

export const SET_RATE_FORM_MODAL_OPEN = `${NAME}/SET_RATE_FORM_MODAL_OPEN`;
