import { updateTemplate } from 'common/api/v1/templateReporter';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SAVE_TEMPLATE,
    SAVE_TEMPLATE_FAILURE,
    SAVE_TEMPLATE_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import redirectToReportReview from './redirectToReportReview';
import setReportEnd from './setReportEnd';

export default (values) => (dispatch) => {
    if (!values?.reportId || !values?.template) return;

    if (values?.isGenerated)
        return dispatch(redirectToReportReview(values.reportId));

    dispatch(actions[SAVE_TEMPLATE]());

    updateTemplate(values.reportId, values.template)
        .then((response) => {
            dispatch(actions[SAVE_TEMPLATE_SUCCESS](response?.data?.data));
            dispatch(setReportEnd(values.reportId));
        })
        .catch((error) => {
            dispatch(actions[SAVE_TEMPLATE_FAILURE](error));
            showReponseErrorsAsAlert(error?.response);
        });
};
