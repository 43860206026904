import { createSlice } from '@reduxjs/toolkit';

import {
    CREATE_OFFER,
    CREATE_OFFER_FAILURE,
    CREATE_OFFER_SUCCESS,
    DELETE_OFFER,
    DELETE_OFFER_FAILURE,
    DELETE_OFFER_SUCCESS,
    RESET_DATA,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    create: {
        data: null,
        errors: [],
        isFetching: false,
    },
    deleteStorageOffer: {
        data: null,
        errors: [],
        isDeleting: false,
    },
    save: {
        data: null,
        errors: [],
        isFetching: false,
    },
};

const proposalGeneratorEnergyBackupSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [CREATE_OFFER]: (state) => {
            state.create = { ...state.create, errors: [], isFetching: true };
        },
        [CREATE_OFFER_FAILURE]: (state, action) => {
            state.create = {
                ...state.create,
                errors: action.payload,
                isFetching: false,
            };
        },
        [CREATE_OFFER_SUCCESS]: (state, action) => {
            state.create = {
                ...state.create,
                data: action.payload,
                isFetching: false,
            };
        },
        [DELETE_OFFER]: (state) => {
            state.deleteStorageOffer = {
                ...state.deleteStorageOffer,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_OFFER_FAILURE]: (state, action) => {
            state.deleteStorageOffer = {
                ...state.deleteStorageOffer,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_OFFER_SUCCESS]: (state, action) => {
            state.deleteStorageOffer = {
                ...state.deleteStorageOffer,
                data: action.payload,
                isDeleting: false,
            };
        },
        [RESET_DATA]: () => INITIAL_STATE,
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isFetching: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isFetching: false,
            };
        },
    },
});

export const proposalGeneratorEnergyBackupActions =
    proposalGeneratorEnergyBackupSlice.actions;

export default proposalGeneratorEnergyBackupSlice.reducer;
