import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { Card, Chip } from 'sunwise-ui';

import {
    ColumnTitle,
    ColumnContainer,
    ColumnWrapper,
    ItemList,
} from 'common/components/funnel';

import ItemCard from './ItemCard';

const Column = ({
    canModify,
    fetchNextPage,
    handleToggleDrawer,
    id,
    isLoadingCurrentNextPage,
    isOnline,
    nextPage,
    projects,
    refreshingIsNeeded,
    setProjectId,
    setSelectedCreditItem,
    title,
    totalRows = 0,
    updatingStatusProjectId,
}) => {
    const { t } = useTranslation();
    const columnDivRef = useRef();

    const loadMoreProjects = () => {
        fetchNextPage({ page: nextPage, status: id });

        if (columnDivRef.current) {
            columnDivRef.current.scrollTop =
                columnDivRef.current.scrollTop - 60;
        }
    };

    const hasNextPage = nextPage !== null && !isLoadingCurrentNextPage;

    return (
        <ColumnWrapper>
            <ColumnTitle>
                {title} <Chip as="span" label={totalRows} size="small" />
            </ColumnTitle>

            <ColumnContainer ref={columnDivRef}>
                <InfiniteScroll
                    key={`infinite-${id}`}
                    pageStart={1}
                    loadMore={loadMoreProjects}
                    hasMore={hasNextPage}
                    loader={
                        <div className="scroll-loader" key={0}>
                            {t('Loading')}
                        </div>
                    }
                    useWindow={false}
                    threshold={50}
                    getScrollParent={() => columnDivRef.current}
                >
                    <ItemList>
                        {!projects.length && (
                            <Card
                                sx={{
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                <Card.Body>{t('No items')}</Card.Body>
                            </Card>
                        )}
                        {projects.map((item, index) => (
                            <ItemCard
                                canModify={canModify}
                                disabled={
                                    refreshingIsNeeded ||
                                    !isOnline ||
                                    item.id === updatingStatusProjectId
                                }
                                id={item.id}
                                index={index}
                                key={item.id}
                                project={item}
                                setProjectId={setProjectId}
                                handleToggleDrawer={handleToggleDrawer}
                                setSelectedCreditItem={setSelectedCreditItem}
                                status={id}
                            />
                        ))}
                    </ItemList>
                </InfiniteScroll>
            </ColumnContainer>
        </ColumnWrapper>
    );
};

Column.propTypes = {
    canModify: PropTypes.bool,
    fetchNextPage: PropTypes.func,
    handleToggleDrawer: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoadingCurrentNextPage: PropTypes.bool,
    isOnline: PropTypes.bool,
    nextPage: PropTypes.number,
    projects: PropTypes.array,
    refreshingIsNeeded: PropTypes.bool,
    setProjectId: PropTypes.func,
    setSelectedCreditItem: PropTypes.func,
    title: PropTypes.string,
    totalRows: PropTypes.number,
    updatingStatusProjectId: PropTypes.string,
};

export default Column;
