import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import DataSheetsCard from '../../multiTemplateReview/components/DataSheetsCard';
import ElectricBillCard from '../../multiTemplateReview/components/ElectricBillCard';
import ShareCard from '../../multiTemplateReview/components/ShareCard';

import FinancialCard from './FinancialCard';
import ProposalCard from './ProposalCard';
import SmartDocumentsCard from './SmartDocumentsCard';

const SupportMenuContent = ({
    canModify,
    downloadFormat,
    editionDisabled,
    handleClickDatasheetItem,
    handleClickDocumentItem,
    handleClickDownload,
    handleClickElectricBillItem,
    isApproved,
    isDownloading,
    isFetching,
    isUpdatingCustomTemplate,
    offerDatasheets,
    offerInfoPatern,
    offerSmartDocuments,
    proposalId,
    proposalName,
    selectedDocument,
    setDownloadFormat,
}) => {
    const isDisabled =
        !editionDisabled ||
        isDownloading ||
        isFetching ||
        isUpdatingCustomTemplate;

    return (
        <>
            <ShareCard
                canModify={canModify}
                downloadFormat={downloadFormat}
                handleClickDownload={handleClickDownload}
                hasProjectProfiles={get(
                    offerInfoPatern,
                    'hasProjectProfiles',
                    false,
                )}
                isApproved={isApproved}
                isDisabled={isDisabled}
                isEnablePermalink={get(offerInfoPatern, 'hasPermalink', false)}
                permalink={get(offerInfoPatern, 'permalink', null)}
                proposalId={proposalId}
                proposalName={proposalName}
                selectedDocument={selectedDocument}
                setDownloadFormat={setDownloadFormat}
            />

            <ProposalCard
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                proposalId={proposalId}
                proposalName={proposalName}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <ElectricBillCard
                electricBills={get(offerInfoPatern, 'electricBills', [])}
                handleClickElectricBillItem={handleClickElectricBillItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <FinancialCard
                financing={get(offerInfoPatern, 'financing', {})}
                handleClickDatasheetItem={handleClickDatasheetItem}
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <SmartDocumentsCard
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                offerSmartDocuments={offerSmartDocuments}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <DataSheetsCard
                handleClickDatasheetItem={handleClickDatasheetItem}
                isDisabled={isDisabled}
                offerDatasheets={offerDatasheets}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
        </>
    );
};

SupportMenuContent.propTypes = {
    canModify: PropTypes.bool,
    downloadFormat: PropTypes.number,
    editionDisabled: PropTypes.bool,
    handleClickDatasheetItem: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    handleClickDownload: PropTypes.func,
    handleClickElectricBillItem: PropTypes.func,
    isApproved: PropTypes.bool,
    isDownloading: PropTypes.bool,
    isFetching: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    offerDatasheets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    offerInfoPatern: PropTypes.object,
    offerSmartDocuments: PropTypes.array,
    proposalId: PropTypes.string,
    proposalName: PropTypes.string,
    selectedDocument: PropTypes.object,
    setDownloadFormat: PropTypes.func,
};

export default SupportMenuContent;
