import get from 'lodash/get';
import { array, number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import {
    ALLOWED_REPEATED_CONCEPTS_NAMES,
    CONCEPTS_NAMES,
} from '../rates/constants';

const getTotalConceptsWithSameTagName = ({ path, value, values }) => {
    const concepts = get(values, path.split('[').slice(0, -1).join('['), []);
    return concepts.filter((concept) => concept?.tag_name === value).length;
};

const getIsUniqueTagName = ({
    currentConcept,
    isHourlyRate,
    path,
    tagName,
    values,
}) => {
    let total = 0;
    const allowedConcepts = ALLOWED_REPEATED_CONCEPTS_NAMES[currentConcept];

    for (const concept of CONCEPTS_NAMES) {
        if (
            isHourlyRate &&
            currentConcept !== concept &&
            allowedConcepts?.includes(concept)
        )
            continue;

        total += getTotalConceptsWithSameTagName({
            path: path.replace(currentConcept, concept),
            value: tagName,
            values,
        });

        if (total > 1) return false;
    }
    return true;
};

export const getConceptsSchemaValidation = ({
    conceptName,
    isHourlyRate,
    values,
} = {}) => {
    const {
        maximumNumberCharacters,
        MIN_ZERO_VALIDATION_TEXT,
        MUST_BE_UNIQUE_TEXT,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    return array().of(
        object().shape({
            price: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .when('unit', {
                    is: 'MINIMUM',
                    then: () => number().min(0, MIN_ZERO_VALIDATION_TEXT),
                }),
            tag_name: string()
                .required(REQUIRED_TEXT)
                .max(124, maximumNumberCharacters(124))
                .test({
                    name: 'duplicate_tag_name',
                    message: MUST_BE_UNIQUE_TEXT,
                    test: function (tagName) {
                        return getIsUniqueTagName({
                            currentConcept: conceptName,
                            isHourlyRate,
                            path: this.path,
                            tagName,
                            values,
                        });
                    },
                }),
            unit: string().required(REQUIRED_TEXT),
        }),
    );
};

const getEnergyConceptFields = ({
    isHourlyRate,
    isTopValueEnabled,
    values,
}) => {
    const {
        maximumNumberCharacters = () => {},
        MIN_THAN_TOP_VALUE_VALIDATION_TEXT,
        MIN_ZERO_VALIDATION_TEXT,
        MUST_BE_UNIQUE_TEXT,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    const energyFields = {
        price: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, MIN_ZERO_VALIDATION_TEXT),
        tag_name: string()
            .required(REQUIRED_TEXT)
            .max(124, maximumNumberCharacters(124))
            .test({
                name: 'duplicate_tag_name',
                message: MUST_BE_UNIQUE_TEXT,
                test: function (tagName) {
                    return getIsUniqueTagName({
                        currentConcept: 'energy_concepts',
                        isHourlyRate,
                        path: this.path,
                        tagName,
                        values,
                    });
                },
            }),
        unit: string().required(REQUIRED_TEXT),
    };
    if (isTopValueEnabled) {
        return {
            ...energyFields,
            top_value: number()
                .required(REQUIRED_TEXT)
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .test({
                    name: 'min',
                    exclusive: false,
                    message: MIN_THAN_TOP_VALUE_VALIDATION_TEXT,
                    test: function (value) {
                        return value > parseInt(this.parent.min_value);
                    },
                }),
        };
    }
    return energyFields;
};

export const getEnergyConceptsSchemaValidation = ({
    isHourlyRate,
    isTopValueEnabled,
    values,
}) => {
    return array()
        .min(1)
        .required()
        .of(
            object(
                getEnergyConceptFields({
                    isHourlyRate,
                    isTopValueEnabled,
                    values,
                }),
            ),
        );
};

export default (values, { certified, isHourlyRate, isTopValueEnabled }) => {
    const periodValidation = {
        other_concepts: object().shape({
            concepts: getConceptsSchemaValidation({
                conceptName: 'other_concepts',
                values,
            }),
        }),
        taxes_concepts: object().shape({
            concepts: getConceptsSchemaValidation({
                conceptName: 'taxes_concepts',
                values,
            }),
        }),
    };

    if (!certified) {
        periodValidation.demand_concepts = object().shape({
            concepts: getConceptsSchemaValidation({
                conceptName: 'demand_concepts',
                isHourlyRate,
                values,
            }),
        });
        periodValidation.contracted_demand_concepts = object().shape({
            concepts: getConceptsSchemaValidation({
                conceptName: 'contracted_demand_concepts',
                isHourlyRate,
                values,
            }),
        });
        periodValidation.energy_concepts = object().shape({
            concepts: getEnergyConceptsSchemaValidation({
                isHourlyRate,
                isTopValueEnabled,
                values,
            }),
        });
        periodValidation.excess_demand_concepts = object().shape({
            concepts: getConceptsSchemaValidation({
                conceptName: 'excess_demand_concepts',
                isHourlyRate,
                values,
            }),
        });
    }

    return object().shape({
        periods: array().of(object().shape(periodValidation)),
    });
};
