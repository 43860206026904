import React from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from 'sunwise-ui';

const ProposalsCount = ({ count }) => {
    const { t } = useTranslation();
    const title =
        count > 0 ? t('{{count}} proposal', { count }) : t('No proposals');

    const sxBox = {
        alignItems: 'center',
        display: 'flex',
        height: 32,
        justifyContent: 'center',
        position: 'relative',
        width: 32,
    };
    const sxFolderIcon = { color: 'rgba(181, 189, 193)', fontSize: '2rem' };
    const sxTypography = {
        color: '#011e2e',
        fontWeight: 'bold',
        position: 'absolute',
        top: 8,
    };

    return (
        <Box sx={sxBox}>
            <Tooltip placement="top" title={title}>
                <FolderIcon sx={sxFolderIcon} />
            </Tooltip>
            <Typography component="span" sx={sxTypography} variant="caption">
                {count ?? 0}
            </Typography>
        </Box>
    );
};

ProposalsCount.propTypes = {
    count: PropTypes.number.isRequired,
};

export default ProposalsCount;
