import { getFinancialProductsPlanMe } from 'common/api/v2/sunpay';

import {
    FETCH_FINANCIAL_PRODUCTS_PLAN,
    FETCH_FINANCIAL_PRODUCTS_PLAN_FAILURE,
    FETCH_FINANCIAL_PRODUCTS_PLAN_SUCCESS,
} from '../actionTypes';
import { financingAndInsuranceActions } from '../reducer';

export default (commercialOfferId, callback = null) =>
    (dispatch) =>
        new Promise((resolve) => {
            dispatch(
                financingAndInsuranceActions[FETCH_FINANCIAL_PRODUCTS_PLAN](),
            );

            getFinancialProductsPlanMe(commercialOfferId)
                .then((response) => {
                    dispatch(
                        financingAndInsuranceActions[
                            FETCH_FINANCIAL_PRODUCTS_PLAN_SUCCESS
                        ](response.data.data),
                    );
                    if (callback) callback(response.data.data);
                })
                .catch((error) =>
                    dispatch(
                        financingAndInsuranceActions[
                            FETCH_FINANCIAL_PRODUCTS_PLAN_FAILURE
                        ](error?.response?.data?.errors),
                    ),
                )
                .finally(resolve);
        });
