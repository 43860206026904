import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import archiveProject from './archiveProject';
import bulkProject from './bulkProject';

export default (project, status, callback) => (dispatch) => {
    const projectName = project.name;

    if (status === 'archived') {
        const dataAlert = {
            confirmText: i18next.t('Archive'),
            messages: [
                i18next.t(
                    'Are you sure to archive the {{ projectName }} project?',
                    {
                        projectName: projectName,
                    },
                ),
                i18next.t(
                    'Once archived, all project information will be lost',
                ),
            ],
            onSuccess: () => dispatch(archiveProject(project.id, callback)),
            title: i18next.t('Confirmation'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };
        dispatch(alerts.actions.show(dataAlert));
    } else {
        dispatch(bulkProject(project.id, status, callback));
    }
};
