import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, UiDialog as Dialog, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { trackingTypeToLabel, truncateString } from 'common/utils/helpers';

import * as profileSelectors from '../../profile/selectors';
import { MAX_CHARACTERS } from '../constants';
import { getUsersOptions } from '../helpers';

import mentionsMessageStyle from './mentionsMessageStyle';
import mentionStyle from './mentionStyle';
import ReasignTrackingForm from './ReasignTrackingForm';
import TrackingTypeIcon from './TrackingTypeIcon';

const Wrapper = styled(Box)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 8px;
`,
);

const TrackingItem = ({
    agent,
    canDelete,
    canModify,
    completed,
    createdAt,
    description,
    goal,
    handleClickChangeDate,
    handleClickDelete,
    handleClickMarkAsCompleted,
    hasExpired,
    id,
    remember,
    trackingDate,
    trackingType,
    users,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [maxCharacters, setMaxCharacters] = useState(MAX_CHARACTERS);
    const [isOpenModalTracking, setIsOpenModalTracking] = useState(false);

    return (
        <Wrapper>
            <Box
                display="flex"
                alignItems="center"
                gap={2}
                sx={{
                    backgroundColor: 'rgb(238 244 250 / 35%)',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    p: 2,
                    width: '100%',
                }}
            >
                <TrackingTypeIcon type={trackingType} />
                <Box display="flex" flexDirection="column">
                    <Typography variant="body2" fontWeight="bold">
                        {trackingTypeToLabel(trackingType)}
                    </Typography>
                    {remember && (
                        <Typography variant="body2">
                            {trackingDate} -{' '}
                            {hasExpired ? t('Expired') : t('Expires')}{' '}
                            {createdAt}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ ml: 'auto' }}>
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: handleClickDelete,
                                icon: <DeleteIcon fontSize="small" />,
                                label: t('Delete'),
                            },
                        ]}
                        visible={canDelete}
                    />
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={2} p="8px 16px">
                <Box>
                    <Typography variant="body2" fontWeight="bold">
                        {t('Objective')}
                    </Typography>
                    <Typography variant="body2">{goal}</Typography>
                </Box>

                <Box>
                    <Typography variant="body2" fontWeight="bold">
                        {t('Note')}
                    </Typography>
                    <Box
                        component="div"
                        sx={{
                            overflowWrap: 'break-word',
                            inlineSize: '100%',
                        }}
                    >
                        <MentionsInput
                            style={{
                                ...mentionsMessageStyle,
                                input: {
                                    ...mentionsMessageStyle.input,
                                    color: isDarkMode ? '#fff' : '#000',
                                },
                            }}
                            value={truncateString(description, maxCharacters)}
                        >
                            <Mention
                                data={getUsersOptions(users)}
                                style={
                                    isDarkMode
                                        ? {
                                              backgroundColor:
                                                  'rgba(255, 255, 255, 0.09)',
                                          }
                                        : mentionStyle
                                }
                            />
                        </MentionsInput>
                    </Box>
                    {description?.length > MAX_CHARACTERS && (
                        <Box textAlign="center">
                            <Button
                                color="secondary"
                                variant="text"
                                onClick={() =>
                                    maxCharacters > MAX_CHARACTERS
                                        ? setMaxCharacters(MAX_CHARACTERS)
                                        : setMaxCharacters(description.length)
                                }
                                sx={{ p: 0 }}
                            >
                                {maxCharacters > MAX_CHARACTERS ? (
                                    <ExpandLess
                                        title={t('See less information')}
                                    />
                                ) : (
                                    <ExpandMore
                                        title={t('See more information')}
                                    />
                                )}
                            </Button>
                        </Box>
                    )}
                </Box>

                <Box>
                    <Typography variant="body2" fontWeight="bold">
                        {t('User')}
                    </Typography>
                    <Typography variant="body2">{agent}</Typography>
                </Box>

                <Box hidden={!canModify}>
                    {!completed ? (
                        <Button
                            color="secondary"
                            fullWidth
                            onClick={handleClickMarkAsCompleted}
                            variant="text"
                        >
                            {t('Mark as completed')}
                        </Button>
                    ) : (
                        <Button
                            color="secondary"
                            fullWidth
                            onClick={() => setIsOpenModalTracking(true)}
                            variant="text"
                        >
                            {t('Remember again')}
                        </Button>
                    )}
                </Box>
                <Dialog
                    onClose={() => setIsOpenModalTracking(false)}
                    open={isOpenModalTracking}
                    size="sm"
                    title={t('Remember again')}
                >
                    <ReasignTrackingForm
                        onSave={handleClickChangeDate}
                        trackingId={id}
                    />
                </Dialog>
            </Box>
        </Wrapper>
    );
};

TrackingItem.propTypes = {
    agent: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    completed: PropTypes.bool,
    createdAt: PropTypes.string,
    description: PropTypes.string,
    goal: PropTypes.string,
    handleClickChangeDate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickMarkAsCompleted: PropTypes.func,
    hasExpired: PropTypes.bool,
    id: PropTypes.string,
    remember: PropTypes.bool,
    trackingDate: PropTypes.string,
    trackingType: PropTypes.number,
    users: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    profileValues: profileSelectors.getCurrentValues,
});

export default connect(mapStateToProps, null)(TrackingItem);
