export const ARCHIVE_CONSUMPTION_PROFILE = 'ARCHIVE_CONSUMPTION_PROFILE';
export const ARCHIVE_CONSUMPTION_PROFILE_FAILURE =
    'ARCHIVE_CONSUMPTION_PROFILE_FAILURE';
export const ARCHIVE_CONSUMPTION_PROFILE_SUCCESS =
    'ARCHIVE_CONSUMPTION_PROFILE_SUCCESS';

export const DELETE_CONSUMPTION_PROFILE = 'DELETE_CONSUMPTION_PROFILE';
export const DELETE_CONSUMPTION_PROFILE_FAILURE =
    'DELETE_CONSUMPTION_PROFILE_FAILURE';
export const DELETE_CONSUMPTION_PROFILE_SUCCESS =
    'DELETE_CONSUMPTION_PROFILE_SUCCESS';

export const FETCH_CONSUMPTION_PROFILE = 'FETCH_CONSUMPTION_PROFILE';
export const FETCH_CONSUMPTION_PROFILE_FAILURE =
    'FETCH_CONSUMPTION_PROFILE_FAILURE';
export const FETCH_CONSUMPTION_PROFILE_SUCCESS =
    'FETCH_CONSUMPTION_PROFILE_SUCCESS';

export const FETCH_CONSUMPTION_PROFILES = 'FETCH_CONSUMPTION_PROFILES';
export const FETCH_CONSUMPTION_PROFILES_FAILURE =
    'FETCH_CONSUMPTION_PROFILES_FAILURE';
export const FETCH_CONSUMPTION_PROFILES_SUCCESS =
    'FETCH_CONSUMPTION_PROFILES_SUCCESS';

export const FILTER_CONSUMPTION_PROFILES = 'FILTER_CONSUMPTION_PROFILES';
export const FILTER_CONSUMPTION_PROFILES_FAILURE =
    'FILTER_CONSUMPTION_PROFILESFAILURE';
export const FILTER_CONSUMPTION_PROFILES_SUCCESS =
    'FILTER_CONSUMPTION_PROFILES_SUCCESS';

export const FILTER_CONSUMPTION_PROFILES_NEW_PAGE =
    'FILTER_CONSUMPTION_PROFILES_NEW_PAGE';
export const FILTER_CONSUMPTION_PROFILES_NEW_PAGE_FAILURE =
    'FILTER_CONSUMPTION_PROFILES_NEW_PAGE_FAILURE';
export const FILTER_CONSUMPTION_PROFILES_NEW_PAGE_SUCCESS =
    'FILTER_CONSUMPTION_PROFILES_NEW_PAGE_SUCCESS';

export const INITIALIZE_CONSUMPTION_PROFILE_MODAL_FORM =
    'INITIALIZE_CUSTOM_CONSUMPTION_PROFILE_MODAL_FORM';

export const RESET = 'RESET';
export const RESET_MODAL_FORM = 'RESET_MODAL_FORM';

export const SAVE_CONSUMPTION_PROFILE = 'SAVE_CONSUMPTION_PROFILE';
export const SAVE_CONSUMPTION_PROFILE_FAILURE =
    'SAVE_CONSUMPTION_PROFILE_FAILURE';
export const SAVE_CONSUMPTION_PROFILE_SUCCESS =
    'SAVE_CONSUMPTION_PROFILE_SUCCESS';

export const SET_FILTER_QUERY = 'SET_FILTER_QUERY';
export const SET_MODAL_IS_CREATE = 'SET_MODAL_IS_CREATE';
export const SET_MODAL_IS_OPEN = 'SET_MODAL_IS_OPEN';
