import React from 'react';

import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

import { TiersTooltip } from '../../../HourlyRateComponents';

const HeaderDistribution = ({ rateTiers, title }) => {
    const rateTiersMapped = rateTiers.map((concept, index) => ({
        name: concept.tag_name,
        identifier: index,
    }));

    return (
        <Box display="flex" alignItems="center" gap={1} p={1}>
            <Typography variant="h4" pl={1}>
                {title}
            </Typography>

            <TiersTooltip rateTiers={rateTiersMapped} />
        </Box>
    );
};

HeaderDistribution.propTypes = {
    rateTiers: PropTypes.array,
    title: PropTypes.string,
};

export default HeaderDistribution;
