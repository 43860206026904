import React from 'react';

import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

const MissingFields = ({ missingFields, title }) => {
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const missingFieldsContent = Object.entries(missingFields ?? {});

    if (missingFieldsContent.length === 0) return null;

    return (
        <Box display="flex" gap={2} flexWrap="wrap" pl={1}>
            <Typography variant="subtitle2">*{title}:</Typography>

            {missingFieldsContent.map(([key, value]) => (
                <Typography key={`missing-fields-kWh-${key}`} variant="body2">
                    {key}:{' '}
                    {numberFormat(value, {
                        decimals: 0,
                        locale: countryCurrencyLocale,
                        style: 'decimal',
                    })}
                </Typography>
            ))}
        </Box>
    );
};

MissingFields.propTypes = {
    missingFields: PropTypes.object,
    title: PropTypes.string,
};

export default MissingFields;
