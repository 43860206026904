import React, { useEffect } from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Container as MaterialContainer,
    Grid,
    UiDialog as Dialog,
} from 'sunwise-ui';

import {
    CollapsedBreadcrumbs,
    HeaderContainer,
    TitleIcon,
} from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import AssignProjectModal from './components/AssignProjectModal';
import PowerStationsTable from './components/PowerStationsTable';
import * as selectors from './selectors';

const Container = ({
    assignProjectModalIsOpen,
    canModify,
    columnsData,
    credentialName,
    fetchPowerStations,
    isFetchingPowerStations,
    match,
    paginationData,
    powerStations,
    prepareUnassignProject,
    projectsDictionary,
    redirect,
    reset,
    resetAssignProject,
    setAssignProjectModalIsOpen,
    setSelectedPowerStation,
}) => {
    const { t } = useTranslation();

    useEffect(() => () => reset(), [match.params.id]);

    const handleOnCloseAssignProjectModal = () => {
        setAssignProjectModalIsOpen(false);
    };

    const handleOpenAssignProjectModal = (station, filterData) => {
        setSelectedPowerStation(station, filterData);
        setAssignProjectModalIsOpen(true);
    };

    return (
        <MaterialContainer maxWidth={false} sx={{ mb: 2 }}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon
                        icon={<BusinessIcon sx={{ color: 'primary.main' }} />}
                        title={t('Power station', { count: 2 })}
                    />
                    <Box sx={{ pl: { md: 2 } }}>
                        <CollapsedBreadcrumbs
                            current={credentialName}
                            locations={[
                                {
                                    label: t('Monitoring'),
                                    action: () =>
                                        redirect('/monitoring-credentials'),
                                },
                            ]}
                        />
                    </Box>
                </Grid>
            </HeaderContainer>

            <PowerStationsTable
                canModify={canModify}
                columnsData={columnsData}
                credentialId={match.params.id}
                fetchPowerStations={fetchPowerStations}
                handleOpenAssignProjectModal={handleOpenAssignProjectModal}
                isFetching={isFetchingPowerStations}
                paginationData={paginationData}
                powerStations={powerStations}
                prepareUnassignProject={prepareUnassignProject}
                projectsDictionary={projectsDictionary}
            />

            <Dialog
                onClose={handleOnCloseAssignProjectModal}
                onExited={resetAssignProject}
                open={assignProjectModalIsOpen}
                title={t('Assign project')}
            >
                <AssignProjectModal
                    credentialId={match.params.id}
                    onClose={handleOnCloseAssignProjectModal}
                />
            </Dialog>
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    assignProjectModalIsOpen: selectors.getAssignProjectModalIsOpen,
    columnsData: selectors.getPowerStationsColumns,
    credentialName: selectors.getCredentialName,
    isFetchingPowerStations: selectors.getPowerStationsIsFetching,
    paginationData: selectors.getPowerStationsPagination,
    powerStations: selectors.getPowerStationsData,
    projectsDictionary: selectors.getProjectsDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPowerStations: (accessKeyId, filter) =>
        dispatch(actions.fetchPowerStations(accessKeyId, filter)),
    prepareUnassignProject: (params) =>
        dispatch(actions.prepareUnassignProject(params)),
    redirect: (url) => dispatch(push(url)),
    reset: () => dispatch(actions.reset()),
    resetAssignProject: () => dispatch(actions.resetAssignProject()),
    setAssignProjectModalIsOpen: (isOpen) =>
        dispatch(actions.setAssignProjectModalIsOpen(isOpen)),
    setSelectedPowerStation: (station, filterData) =>
        dispatch(actions.setSelectedPowerStation(station, filterData)),
});

Container.propTypes = {
    assignProjectModalIsOpen: PropTypes.bool,
    canModify: PropTypes.bool,
    columnsData: PropTypes.array,
    credentialName: PropTypes.string,
    fetchPowerStations: PropTypes.func,
    isFetchingPowerStations: PropTypes.bool,
    match: PropTypes.object,
    paginationData: PropTypes.object,
    powerStations: PropTypes.array,
    prepareUnassignProject: PropTypes.func,
    projectsDictionary: PropTypes.object,
    redirect: PropTypes.func,
    reset: PropTypes.func,
    resetAssignProject: PropTypes.func,
    setAssignProjectModalIsOpen: PropTypes.func,
    setSelectedPowerStation: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.REPORTS_PERMISSION),
)(Container);
