import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'company';

export const createRole = (data) =>
    API.post(`/api/v1/${ENTITY}/roles/`, data, config);
export const deleteRole = (id) => API.delete(`/api/v1/${ENTITY}/roles/${id}`);
export const filterRoles = ({
    orderBy = null,
    page = 1,
    perPage = 25,
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/roles/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            sort: sortBy,
        },
    });
export const getRoleDetails = (roleId) =>
    API.get(`/api/v1/${ENTITY}/roles/${roleId}/`);
export const getRoleList = () => API.get(`/api/v1/roles-${ENTITY}/`);
export const updateRole = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/roles/${id}/`, data, config);
