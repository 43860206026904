import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getShowForgotPasswordForm = createSelector(
    getModel,
    (model) => model.showForgotPasswordForm,
);

export const getSession = createSelector(getModel, (model) => model.session);

export const getIsLoadingSession = createSelector(
    getSession,
    (session) => session.isLoading,
);

export const getErrorsLogin = createSelector(
    getSession,
    (session) => session.errors,
);
