import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';

const getRowStyles = (selectable) => {
    const styles = { '& .MuiDataGrid-cell:focus': { outline: 'none' } };
    if (selectable) styles['& .MuiDataGrid-row'] = { cursor: 'pointer' };
    return styles;
};

const Table = ({
    columns,
    isLoading,
    items,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    page,
    pageSize,
    paginationMode = 'client',
    rowCount,
    rowsPerPageOptions = [15, 25, 50, 75, 100],
    selectable = true,
    selectionModel,
}) => (
    <DataGrid
        autoHeight
        checkboxSelection={false}
        components={{
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
        }}
        componentsProps={{ pagination: { rowsPerPageOptions } }}
        columns={columns || []}
        disableColumnMenu
        disableSelectionOnClick
        getRowHeight={() => 'auto'}
        hideFooterSelectedRowCount
        initialState={{ pagination: { pageSize: pageSize || 15 } }}
        loading={isLoading}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onRowClick={onRowClick}
        page={page}
        pageSize={pageSize}
        pagination
        paginationMode={paginationMode}
        rowCount={rowCount}
        rows={items || []}
        selectionModel={selectionModel}
        sx={getRowStyles(selectable)}
    />
);

Table.propTypes = {
    columns: PropTypes.array,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    onRowClick: PropTypes.func,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    paginationMode: PropTypes.string,
    rowCount: PropTypes.number,
    rowsPerPageOptions: PropTypes.array,
    selectable: PropTypes.bool,
    selectionModel: PropTypes.array,
};

export default Table;
