import { createPosition, updatePosition } from 'common/api/v1/projectPositions';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import fetchProjectDetails from '../../project/actions/fetchProjectDetails';
import {
    SAVE_POSITION,
    SAVE_POSITION_SUCCESS,
    SAVE_POSITION_ERROR,
} from '../actionTypes';
import { projectLocationActions } from '../reducer';

import fetchPositions from './fetchPositions';

export default (
        values,
        uid,
        successCallback = () => {},
        failureCallback = () => {},
    ) =>
    (dispatch) => {
        const newValues = {
            description: values.title,
            extra_position_data: JSON.stringify(values.extra_position_data),
            position: JSON.stringify({
                additional_information: values.postalAddress,
                city: values.city,
                description: values.title,
                latitude: values.position.latitude,
                longitude: values.position.longitude,
                political_division1: values.political_division1,
                zip_code: values.cp,
            }),
            project: uid,
            title: values.title,
        };

        dispatch(projectLocationActions[SAVE_POSITION]());

        const save = values.id
            ? () => updatePosition(newValues, uid)
            : () => createPosition(newValues);

        save()
            .then((response) => {
                dispatch(
                    projectLocationActions[SAVE_POSITION_SUCCESS](
                        response.data,
                    ),
                );
                dispatch(fetchPositions());
                dispatch(fetchProjectDetails(uid));
                showToast();
                successCallback();
            })
            .catch((error) => {
                failureCallback();
                dispatch(
                    projectLocationActions[SAVE_POSITION_ERROR](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
