import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import {
    getCurrencyIso,
    getCurrencyLocale,
    getCurrencySymbol,
    numberFormat,
} from 'common/utils/helpers';

import * as companyGeneralSelectors from '../companyGeneral/selectors';

import * as actions from './actions';
import CardCommercialOffers from './components/CardCommercialOffers';
import CardContactsTrackingsData from './components/CardContactsTrackingsData';
import CardKeyValue from './components/CardKeyValue';
import CardProjectSalesStages from './components/CardProjectSalesStages';
import CardTotalProposalIssued from './components/CardTotalProposalIssued';
import { getReadyPlaceholder } from './helpers';
import * as selectors from './selectors';

const Container = ({
    agents,
    defaultCurrency,
    fetchAgents,
    fetchRates,
    getPermissionsByCode,
    groupedRates,
    isLoading,
    totalKwProjects,
    totalProjectsIssued,
    totalMoneyProjects,
}) => {
    useEffect(() => {
        fetchAgents();
        fetchRates();
    }, []);

    const { t } = useTranslation();

    const { canView: canViewProposals } = getPermissionsByCode(
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
    );
    const { canView: canViewDashboard } = getPermissionsByCode(
        PERMISSION_LIST.DASHBOARD_PERMISSION,
    );
    const { canView: canViewTrackings } = getPermissionsByCode(
        PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
    );

    const currencyIso = getCurrencyIso(defaultCurrency);
    const currencyLocale = getCurrencyLocale(defaultCurrency);
    const currencySymbol = getCurrencySymbol(defaultCurrency);
    const readyPlaceholder = getReadyPlaceholder(defaultCurrency, isLoading);

    if (!canViewDashboard) return null;

    return (
        <MaterialContainer maxWidth={false} sx={{ mb: 2 }}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon title={t('Dashboard')} />
                </Grid>
            </HeaderContainer>

            {canViewDashboard && (
                <Grid container>
                    <Grid item xs={18} lg={6}>
                        <CardKeyValue
                            id="card-total-proposals-issued"
                            readyPlaceholder={readyPlaceholder}
                            title={t('Total proposals issued')}
                            value={numberFormat(totalProjectsIssued, {
                                decimals: 0,
                                locale: currencyLocale,
                                style: 'decimal',
                            })}
                        />
                    </Grid>

                    <Grid item xs={18} lg={6}>
                        <CardKeyValue
                            readyPlaceholder={readyPlaceholder}
                            title={t('Total value of the proposals issued')}
                            value={numberFormat(totalMoneyProjects, {
                                currency: currencyIso,
                                locale: currencyLocale,
                                style: 'currency',
                                symbol: currencySymbol,
                            })}
                        />
                    </Grid>

                    <Grid item xs={18} lg={6}>
                        <CardKeyValue
                            readyPlaceholder={readyPlaceholder}
                            title={t('Total power of the proposals issued')}
                            value={numberFormat(totalKwProjects, {
                                decimals: 0,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: 'kWp',
                            })}
                        />
                    </Grid>

                    <Grid item xs={18}>
                        <CardTotalProposalIssued
                            agents={agents}
                            currencyIso={currencyIso}
                            currencyLocale={currencyLocale}
                            currencySymbol={currencySymbol}
                            defaultCurrency={defaultCurrency}
                            isLoading={isLoading}
                            readyPlaceholder={readyPlaceholder}
                        />
                    </Grid>

                    <Grid item xs={18} lg={canViewProposals ? 9 : 18}>
                        <CardContactsTrackingsData
                            agents={agents}
                            visible={canViewTrackings}
                        />
                    </Grid>

                    <Grid item xs={18} lg={canViewTrackings ? 9 : 18}>
                        <CardCommercialOffers
                            agents={agents}
                            groupedRates={groupedRates}
                            visible={canViewProposals}
                        />
                    </Grid>

                    <Grid item xs={18} sx={{ overflow: 'auto' }}>
                        <CardProjectSalesStages
                            currencyIso={currencyIso}
                            currencyLocale={currencyLocale}
                            readyPlaceholder={readyPlaceholder}
                        />
                    </Grid>
                </Grid>
            )}
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    agents: selectors.getAgentsData,
    defaultCurrency: companyGeneralSelectors.getCurrentCompanyCurrency,
    groupedRates: selectors.getGroupedRatesForSelect,
    isLoading: selectors.getProposalsTotalsIsLoading,
    totalKwProjects: selectors.getTotalKwProjects,
    totalProjectsIssued: selectors.getTotalProjectsIssued,
    totalMoneyProjects: selectors.getTotalMoneyProjects,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAgents: () => dispatch(actions.fetchAgents()),
    fetchRates: () => dispatch(actions.fetchRates()),
});

Container.propTypes = {
    agents: PropTypes.array,
    defaultCurrency: PropTypes.object,
    fetchAgents: PropTypes.func,
    fetchRates: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    groupedRates: PropTypes.array,
    isLoading: PropTypes.bool,
    totalKwProjects: PropTypes.number,
    totalProjectsIssued: PropTypes.number,
    totalMoneyProjects: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
        PERMISSION_LIST.DASHBOARD_PERMISSION,
        PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
    ]),
)(Container);
