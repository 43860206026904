import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_DATA,
    DELETE_DATA_FAILURE,
    DELETE_DATA_SUCCESS,
    FETCH_DEFAULT_LOCATION,
    FETCH_DEFAULT_LOCATION_FAILURE,
    FETCH_DEFAULT_LOCATION_SUCCESS,
    FETCH_EXTERNAL_SOURCES,
    FETCH_EXTERNAL_SOURCES_FAILURE,
    FETCH_EXTERNAL_SOURCES_SUCCESS,
    FETCH_POWER_STATIONS,
    FETCH_POWER_STATIONS_FAILURE,
    FETCH_POWER_STATIONS_SUCCESS,
    INITIALIZE_API_SOURCE_FORM,
    INITIALIZE_EXTERNAL_SOURCE_FORM,
    INITIALIZE_SETTINGS_FORM,
    RESET,
    RESET_API_SOURCE_FORM,
    RESET_EXTERNAL_SOURCE_FORM,
    RESET_SETTINGS_FORM,
    SAVE_DATA,
    SAVE_DATA_FAILURE,
    SAVE_DATA_SUCCESS,
    SET_API_SOURCE_FORM_MODAL_OPEN_STATUS,
    SET_EXTERNAL_SOURCE_MODAL_FORM_OPEN_STATUS,
    SET_SETTINGS_FORM_MODAL_OPEN_STATUS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    apiSourceFormModalOpenStatus: false,
    defaultLocation: { data: {}, errors: null, isLoading: false },
    deleteData: { data: null, errors: null, isLoading: false },
    externalSourceFormModalOpenStatus: false,
    externalSources: { data: [], errors: null, isLoading: false },
    initialValuesApiSource: { credential: null, power_station: null },
    initialValuesExternalSource: {
        brand: null,
        id: null,
        name: null,
        type: null,
        url: null,
    },
    initialValuesSettings: {
        address: null,
        latitude: null,
        longitude: null,
        position: { latitude: null, longitude: null },
        start_date: null,
        time_zone: null,
    },
    powerStations: { data: {}, errors: null, isLoading: false },
    saveData: { data: null, errors: null, isLoading: false },
    settingsFormModalOpenStatus: false,
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_DATA]: (state) => {
            state.deleteData.errors = null;
            state.deleteData.isLoading = true;
        },
        [DELETE_DATA_FAILURE]: (state, action) => {
            state.deleteData.errors = action.payload;
            state.deleteData.isLoading = false;
        },
        [DELETE_DATA_SUCCESS]: (state) => {
            state.deleteData.isLoading = false;
        },
        [FETCH_DEFAULT_LOCATION]: (state) => {
            state.defaultLocation.errors = null;
            state.defaultLocation.isLoading = true;
        },
        [FETCH_DEFAULT_LOCATION_FAILURE]: (state, action) => {
            state.defaultLocation.errors = action.payload;
            state.defaultLocation.isLoading = false;
        },
        [FETCH_DEFAULT_LOCATION_SUCCESS]: (state, action) => {
            state.defaultLocation.data = action.payload;
            state.defaultLocation.isLoading = false;
        },
        [FETCH_EXTERNAL_SOURCES]: (state) => {
            state.externalSources.errors = null;
            state.externalSources.isLoading = true;
        },
        [FETCH_EXTERNAL_SOURCES_FAILURE]: (state, action) => {
            state.externalSources.errors = action.payload;
            state.externalSources.isLoading = false;
        },
        [FETCH_EXTERNAL_SOURCES_SUCCESS]: (state, action) => {
            state.externalSources.data = action.payload;
            state.externalSources.isLoading = false;
        },
        [FETCH_POWER_STATIONS]: (state) => {
            state.powerStations.errors = null;
            state.powerStations.isLoading = true;
        },
        [FETCH_POWER_STATIONS_FAILURE]: (state, action) => {
            state.powerStations.errors = action.payload;
            state.powerStations.isLoading = false;
        },
        [FETCH_POWER_STATIONS_SUCCESS]: (state, action) => {
            state.powerStations.data = action.payload;
            state.powerStations.isLoading = false;
        },
        [INITIALIZE_API_SOURCE_FORM]: (state, action) => {
            state.initialValuesApiSource = {
                ...state.initialValuesApiSource,
                ...action.payload,
            };
        },
        [INITIALIZE_EXTERNAL_SOURCE_FORM]: (state, action) => {
            state.initialValuesExternalSource = {
                ...state.initialValuesExternalSource,
                ...action.payload,
            };
        },
        [INITIALIZE_SETTINGS_FORM]: (state, action) => {
            state.initialValuesSettings = {
                ...state.initialValuesSettings,
                ...action.payload,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_API_SOURCE_FORM]: (state) => {
            state.initialValuesApiSource = INITIAL_STATE.initialValuesApiSource;
        },
        [RESET_EXTERNAL_SOURCE_FORM]: (state) => {
            state.initialValuesExternalSource =
                INITIAL_STATE.initialValuesExternalSource;
        },
        [RESET_SETTINGS_FORM]: (state) => {
            state.initialValuesSettings = INITIAL_STATE.initialValuesSettings;
        },
        [SAVE_DATA]: (state) => {
            state.saveData = {
                data: null,
                errors: [],
                isLoading: true,
            };
        },
        [SAVE_DATA_FAILURE]: (state, action) => {
            state.saveData = {
                data: null,
                errors: action.payload,
                isLoading: false,
            };
        },
        [SAVE_DATA_SUCCESS]: (state, action) => {
            state.saveData = {
                data: action.payload,
                errors: [],
                isLoading: false,
            };
        },
        [SET_API_SOURCE_FORM_MODAL_OPEN_STATUS]: (state, action) => {
            state.apiSourceFormModalOpenStatus = action.payload;
        },
        [SET_EXTERNAL_SOURCE_MODAL_FORM_OPEN_STATUS]: (state, action) => {
            state.externalSourceFormModalOpenStatus = action.payload;
        },
        [SET_SETTINGS_FORM_MODAL_OPEN_STATUS]: (state, action) => {
            state.settingsFormModalOpenStatus = action.payload;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
