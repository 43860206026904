import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getLogsModel = createSelector(getModel, (model) => model.logs);

export const getLogsData = createSelector(getLogsModel, (model) => model.data);

export const getLogsPagination = createSelector(
    getLogsModel,
    (data) => data?.pagination || {},
);

export const getLogsError = createSelector(
    getLogsModel,
    (model) => model.error,
);

export const getLogsLoading = createSelector(
    getLogsModel,
    (model) => model.loading,
);

export const getProjectsInfo = createSelector(
    getModel,
    (model) => model.projects_info,
);

export const getProjectsInfoData = createSelector(
    getProjectsInfo,
    (model) => model.data,
);

export const getProjectsInfoError = createSelector(
    getProjectsInfo,
    (model) => model.error,
);

export const getProjectsInfoLoading = createSelector(
    getProjectsInfo,
    (model) => model.loading,
);
