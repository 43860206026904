import i18next from 'i18next';

import { getCupsInfo } from 'common/api/v1/cups';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { doesRateRequireDivision } from 'common/utils/helpers/rates';

import {
    FETCH_CUPS_INFO,
    FETCH_CUPS_INFO_FAILURE,
    FETCH_CUPS_INFO_SUCCESS,
} from '../actionTypes';
import { CONSUMPTIONS_CAPTURE_MODE } from '../constants';
import { projectConsumptionModalActions } from '../reducer';
import { getRatesDictionary, getRatesNameIdDictionary } from '../selectors';

import fetchLocationsByZipCode from './fetchLocationsByZipCode';
import setConsumptionsCaptureMode from './setConsumptionsCaptureMode';
import setConsumptionsRawData from './setConsumptionsRawData';
import setIsOpenMissingFieldsModal from './setIsOpenMissingFieldsModal';

const CUPS_MIN_LENGTH = 20;

export default ({ cups, getValues, setValue }) =>
    (dispatch, getState) => {
        if (!cups || cups?.length < CUPS_MIN_LENGTH) return;
        dispatch(projectConsumptionModalActions[FETCH_CUPS_INFO]());

        dispatch(
            alerts.actions.show({
                hideButtons: true,
                messages: [
                    i18next.t(
                        'Please wait a moment as the information is processed',
                    ),
                ],
                title: i18next.t('Loading').concat('...'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'info',
            }),
        );

        getCupsInfo(cups)
            .then((response) => {
                dispatch(alerts.actions.close());
                const data = response?.data?.data;
                dispatch(
                    projectConsumptionModalActions[FETCH_CUPS_INFO_SUCCESS](
                        data,
                    ),
                );
                dispatch(
                    setConsumptionsCaptureMode(CONSUMPTIONS_CAPTURE_MODE.CUPS),
                );
                dispatch(setConsumptionsRawData(data));

                const state = getState();
                const ratesDictionary = getRatesDictionary(state);
                const ratesNameIdDictionary = getRatesNameIdDictionary(state);
                const rateId = ratesNameIdDictionary[data?.rate];

                const selectedRate = ratesDictionary?.[rateId];

                const zipCodeRequired = doesRateRequireDivision(
                    selectedRate?.name,
                    selectedRate?.isCertified,
                );

                setValue('political_division1', null);
                setValue('political_division2', null);
                setValue('rate_division', null);
                setValue('rate', rateId || null);
                setValue('zip_code', data?.zip_code || null);

                if (!selectedRate || (zipCodeRequired && !data?.zip_code)) {
                    dispatch(setIsOpenMissingFieldsModal(true));
                    return;
                }

                dispatch(
                    fetchLocationsByZipCode({
                        getValues,
                        rate: selectedRate,
                        setValue,
                        value: data?.zip_code,
                    }),
                );
            })
            .catch((error) => {
                dispatch(alerts.actions.close());
                dispatch(
                    projectConsumptionModalActions[FETCH_CUPS_INFO_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
