import { NAME } from './constants';

export const CREATE = `${NAME}/CREATE`;
export const CREATE_FAILURE = `${NAME}/CREATE_FAILURE`;
export const CREATE_SUCCESS = `${NAME}/CREATE_SUCCESS`;

export const DELETE = `${NAME}/DELETE`;
export const DELETE_FAILURE = `${NAME}/DELETE_FAILURE`;
export const DELETE_SUCCESS = `${NAME}/DELETE_SUCCESS`;

export const FETCH_OFFER_STRUCTURES = `${NAME}/FETCH_OFFER_STRUCTURES`;
export const FETCH_OFFER_STRUCTURES_FAILURE = `${NAME}/FETCH_OFFER_STRUCTURES_FAILURE`;
export const FETCH_OFFER_STRUCTURES_SUCCESS = `${NAME}/FETCH_OFFER_STRUCTURES_SUCCESS`;

export const INITIAL_VALUES = `${NAME}/INITIAL_VALUES`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
