import { SET_MODAL_IS_CREATE } from '../actionTypes';
import { consumptionProfilesActions } from '../reducer';

import initializeModalForm from './initializeModalForm';
import setModalIsOpen from './setModalIsOpen';

export default (values) => (dispatch) => {
    dispatch(consumptionProfilesActions[SET_MODAL_IS_CREATE](true));

    dispatch(initializeModalForm(values));

    dispatch(setModalIsOpen(true));
};
