import React from 'react';

import PropTypes from 'prop-types';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';

import ProductColumns from './ProductColumns';

const ListItems = ({ isFetching, isFetchingRoles, items }) => {
    return (
        <DataGrid
            autoHeight
            components={{
                Pagination: CustomPagination,
            }}
            columns={ProductColumns()}
            disableColumnMenu
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
            initialState={{
                pagination: {
                    pageSize: 20,
                },
            }}
            loading={isFetching || isFetchingRoles}
            pagination
            rows={items}
        />
    );
};

ListItems.propTypes = {
    isFetching: PropTypes.bool,
    isFetchingRoles: PropTypes.bool,
    items: PropTypes.array,
};

export default ListItems;
