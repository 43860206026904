import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

const DetectOnline = (props) => {
    const { Children, injectedProp } = props;

    const [isOnline, setIsOnline] = useState(true);
    const handleOffline = () => setIsOnline(false);
    const handleOnline = () => setIsOnline(true);

    useEffect(() => {
        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return <Children {...injectedProp} isOnline={isOnline}></Children>;
};

DetectOnline.propTypes = {
    Children: PropTypes.node,
    injectedProp: PropTypes.object,
};

const DetectOnlineHOC = () => (Children) => (props) =>
    DetectOnline({
        injectedProp: props,
        Children,
    });

export default DetectOnlineHOC;
