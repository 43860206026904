import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from 'sunwise-ui';

const TextAccordion = ({ children, defaultExpanded, description, title }) => {
    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            disableGutters
            sx={{
                boxShadow: 'none!important',
                '&::before': { display: 'none' },
            }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">{title}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                {description && (
                    <Typography variant="body2">{description}</Typography>
                )}

                {children}
            </AccordionDetails>
        </Accordion>
    );
};

TextAccordion.propTypes = {
    children: PropTypes.node,
    defaultExpanded: PropTypes.bool,
    description: PropTypes.string,
    title: PropTypes.string,
};

export default TextAccordion;
