import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import { Map } from 'google-maps-react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Menu, MenuItem } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { GeneralContext } from 'common/utils/contexts';

import * as actions from '../actions';
import { POLYGON_MAP, POLYGON_OBSTACLE } from '../constants';
import {
    buildShapes,
    checkCallback,
    checkCallbackError,
    copyPasteEventsUseEffect,
    drawingModeUseEffect,
    editionModeUseEffect,
    getDictionaryById,
    mapValueUseEffect,
    selectedSegmentIdUseEffect,
    updateSegmentDataUseEffect,
} from '../helpers';
import * as selectors from '../selectors';

import StyledMap from './StyledMap';

const MapWrapper = ({
    checkSegment,
    commercialOfferId,
    createSegment,
    currentProjectLocation,
    drawingType,
    handleOnClickModule,
    handleOnCopy,
    handleOnPaste,
    isDisablingModules,
    isDrawingMode,
    isEditionMode,
    isEnabledSimulation,
    isGuideLinesActive,
    myForwardedRef,
    onOpenDrawer,
    prepareEditSegment,
    resetForm,
    segments,
    selectedSegmentId,
    selectSegmentById,
    setSelectedItems,
    simulationStatus,
    sunPosition,
    updateAzimuth,
    updateSegmentData,
}) => {
    const { t } = useTranslation();
    const { google } = useContext(GeneralContext);
    const center = {
        lat: get(currentProjectLocation, 'latitude', 20.9664281),
        lng: get(currentProjectLocation, 'longitude', -89.6239336),
    };
    const refBox = useRef(null);
    const [mapValue, setMapValue] = useState(null);
    const [tempSegments, setTempSegments] = useState({});
    const [drawingManager, setDrawingManager] = useState(null);
    const [contextMenu, setContextMenu] = useState(null);
    const [menuOptions, setMenuOptions] = useState([]);
    const breakpoint = useBreakpoint();
    const { isPlaying } = simulationStatus;

    const isDay = !isEnabledSimulation || sunPosition.elevation > 0;
    const opacity = !isPlaying ? 1 : isDay ? 1 : 0.5;

    const handleClose = () => setContextMenu(null);

    const handleContextMenu = ({ azimuth, segmentId, event }) => {
        const { clientX, clientY } = event;
        event.preventDefault();
        setMenuOptions([
            {
                label: t('Set azimuth to this edge ({{azimuth}}°)', {
                    azimuth,
                }),
                onClick: () =>
                    updateAzimuth({
                        azimuth,
                        callback: handleClose,
                        commercialOfferId,
                        google,
                        mapValue,
                        segmentId,
                    }),
            },
        ]);
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: clientX + 2,
                      mouseY: clientY - 6,
                  }
                : null,
        );
    };

    const handleCheckSegment = ({ evt, polygon, segmentId } = {}) => {
        checkSegment({
            callback: (id, modules) =>
                checkCallback(id, modules, myForwardedRef),
            callbackError: (id, values) =>
                checkCallbackError(id, values, myForwardedRef),
            commercialOfferId,
            evt,
            google,
            mapValue,
            polygon,
            segmentId,
        });
    };

    useImperativeHandle(myForwardedRef, () => ({
        deleteShape: (id) => {
            if (tempSegments[id]) tempSegments[id]?.setMap(null);
            const newSegments = Object.keys(tempSegments).reduce((acc, key) => {
                if (key !== id) {
                    acc[key] = tempSegments[key];
                }
                return acc;
            }, {});
            setTempSegments(newSegments);
        },
        getZoomMap: () => mapValue && mapValue.getZoom(),
        resetSegmentPosition: (id, values) =>
            tempSegments[id]?.resetPosition(values, true),
        setModulesToSegment: (id, solarModules) =>
            tempSegments[id]?.setSolarModules(solarModules),
        self: () => mapValue,
    }));

    useEffect(
        copyPasteEventsUseEffect({
            commercialOfferId,
            handleOnCopy,
            handleOnPaste,
            mapValue,
            refBox,
        }),
        [refBox, mapValue],
    );

    useEffect(
        mapValueUseEffect({
            currentProjectLocation,
            google,
            mapValue,
            setDrawingManager,
        }),
        [currentProjectLocation, mapValue],
    );

    useEffect(() => {
        for (const [, segment] of Object.entries(tempSegments)) {
            segment.setSunPosition(sunPosition);
        }
    }, [sunPosition]);

    useEffect(() => {
        if (!segments || !mapValue) return;
        segments.forEach((segment) => {
            if (segment.type === POLYGON_OBSTACLE) {
                tempSegments[segment.id]?.setHeight(
                    segment.height,
                    segment?.zIndex || 0,
                );
            }

            if (segment.type === POLYGON_MAP) {
                tempSegments[segment.id]?.setOptions({
                    draggable: !segment.locked,
                    editable: !segment.locked,
                });
            }
        });

        buildShapes({
            checkSegment: handleCheckSegment,
            google,
            handleOnClickModule,
            mapRef: myForwardedRef,
            onContextMenu: handleContextMenu,
            prepareEditSegment,
            segments,
            selectSegmentById,
            setSelectedItems,
            setTempSegments,
            sunPosition,
            tempSegments,
        });
    }, [segments, mapValue]);

    useEffect(
        drawingModeUseEffect({
            breakpoint,
            createSegment: (polygon) => {
                createSegment({
                    commercialOfferId,
                    drawingType,
                    google,
                    mapRef: myForwardedRef,
                    polygon,
                });
            },
            drawingManager,
            drawingType,
            google,
            isDrawingMode,
            isGuideLinesActive,
            map: mapValue,
            onOpenDrawer,
        }),
        [drawingManager, isDrawingMode, isGuideLinesActive, refBox],
    );

    useEffect(
        selectedSegmentIdUseEffect({
            segmentsDictionary: getDictionaryById(segments),
            selectedSegmentId,
            tempSegments,
        }),
        [selectedSegmentId],
    );

    useEffect(
        updateSegmentDataUseEffect({
            tempSegments,
            updateSegmentData,
        }),
        [updateSegmentData],
    );

    useEffect(
        editionModeUseEffect({
            breakpoint,
            isDisablingModules,
            isEditionMode,
            onOpenDrawer,
            selectedSegmentId,
            tempSegments,
        }),
        [isEditionMode, selectedSegmentId, isDisablingModules],
    );

    return (
        <>
            <StyledMap>
                <Box
                    onContextMenu={(e) => e.preventDefault()}
                    ref={refBox}
                    sx={{
                        opacity,
                        transition: 'opacity 0.2s linear',
                    }}
                >
                    <Map
                        disableDefaultUI={isEditionMode}
                        disableDoubleClickZoom={true}
                        fullscreenControl={false}
                        google={google}
                        initialCenter={center}
                        mapType="satellite"
                        mapTypeControl={false}
                        onDblclick={() => resetForm()}
                        onReady={(_, map) => {
                            map.setOptions({
                                mapId: import.meta.env
                                    .VITE_GOOGLE_MAPS_CUSTOM_ID,
                            });
                            setMapValue(map);
                        }}
                        rotateControl={false}
                        streetViewControl={false}
                        zoom={18}
                    />
                </Box>
            </StyledMap>

            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                {menuOptions.map((option, index) => (
                    <MenuItem
                        key={`context-option-${index}`}
                        onClick={option.onClick}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    currentProjectLocation: selectors.getCurrentProjectLocationData,
    selectedSegmentId: selectors.getSelectedSegmentId,
    simulationStatus: selectors.getSimulationStatus,
    updateSegmentData: selectors.getUpdateSegmentData,
});

const mapDispatchToProps = (dispatch) => ({
    checkSegment: (config) => dispatch(actions.checkSegment(config)),
    createSegment: (config) => dispatch(actions.createSegment(config)),
    handleOnClickModule: (config) =>
        dispatch(actions.handleOnClickModule(config)),
    handleOnCopy: () => dispatch(actions.handleOnCopy()),
    handleOnPaste: (config) => dispatch(actions.handleOnPaste(config)),
    prepareEditSegment: (segmentId) =>
        dispatch(actions.prepareEditSegment(segmentId)),
    resetForm: () => dispatch(actions.resetForm()),
    selectSegmentById: (id) => dispatch(actions.selectSegmentById(id)),
    setSelectedItems: (config) => dispatch(actions.setSelectedItems(config)),
    updateAzimuth: (values) => dispatch(actions.updateAzimuth(values)),
});

MapWrapper.propTypes = {
    checkSegment: PropTypes.func,
    commercialOfferId: PropTypes.string,
    createSegment: PropTypes.func,
    currentProjectLocation: PropTypes.object,
    drawingType: PropTypes.number,
    handleOnClickModule: PropTypes.func,
    handleOnCopy: PropTypes.func,
    handleOnPaste: PropTypes.func,
    isDisablingModules: PropTypes.bool,
    isDrawingMode: PropTypes.bool,
    isEditionMode: PropTypes.bool,
    isEnabledSimulation: PropTypes.bool,
    isGuideLinesActive: PropTypes.bool,
    myForwardedRef: PropTypes.object,
    onOpenDrawer: PropTypes.func,
    prepareEditSegment: PropTypes.func,
    resetForm: PropTypes.func,
    segments: PropTypes.array,
    selectedSegmentId: PropTypes.string,
    selectSegmentById: PropTypes.func,
    setSelectedItems: PropTypes.func,
    simulationStatus: PropTypes.object,
    sunPosition: PropTypes.object,
    updateAzimuth: PropTypes.func,
    updateSegmentData: PropTypes.object,
};

const ComposedMyComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MapWrapper);

const MapWrapperForwared = forwardRef((props, ref) => (
    <ComposedMyComponent {...props} myForwardedRef={ref} />
));

MapWrapperForwared.displayName = 'MapWrapperForwared';

export default MapWrapperForwared;
