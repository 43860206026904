import { styled } from '@mui/material/styles';
import { Typography } from 'sunwise-ui';

export default styled(Typography)`
    font-size: 22px;
    font-weight: normal;
    line-height: 27px;
    margin-bottom: 16px;
    margin-top: 16px;
`;
