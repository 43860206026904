import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Card, Grid, Typography } from 'sunwise-ui';

import { DropdownCardMenu } from 'common/components/cards';
import ProviderImage from 'common/components/ProviderImage';

const CredentialLists = ({
    canModify,
    credentials,
    disabled,
    handleClickUpdate,
    handleOpenPowerStation,
    prepareDeleteCredential,
    providersDictionary,
}) => {
    const { t } = useTranslation();

    if (!credentials?.length) return <Typography>{t('No items')}</Typography>;

    return (
        <Grid container>
            {credentials?.map((data) => {
                const provider = providersDictionary?.[data?.provider_id];
                if (!provider) return null;

                return (
                    <Grid key={data.id} item xs={18} sm={9} lg={6} xl={4.5}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                p: 1,
                            }}
                        >
                            <Card.Header
                                sx={{
                                    alignItems: 'flex-start',
                                    display: 'flex',
                                    gap: 2,
                                    p: 0,
                                    py: 1,
                                }}
                            >
                                <ProviderImage
                                    provider={provider}
                                    style={{
                                        flex: '1 1 auto',
                                        maxHeight: '60px',
                                        objectFit: 'contain',
                                        minWidth: '60px',
                                    }}
                                />

                                <DropdownCardMenu
                                    items={[
                                        {
                                            disabled,
                                            handleClick: () =>
                                                handleClickUpdate(data),
                                            icon: <EditIcon fontSize="small" />,
                                            label: t('Edit'),
                                            visible: canModify,
                                        },
                                        {
                                            disabled,
                                            handleClick: () =>
                                                prepareDeleteCredential(
                                                    data.id,
                                                ),
                                            icon: (
                                                <DeleteIcon fontSize="small" />
                                            ),
                                            label: t('Delete'),
                                            visible: false,
                                        },
                                    ]}
                                    visible={canModify}
                                />
                            </Card.Header>

                            <Card.Body>
                                <Typography variant="subtitle2">
                                    {data.name}
                                </Typography>

                                <Typography variant="body2">
                                    <span style={{ fontWeight: 600 }}>
                                        {t('Last update')}:
                                    </span>{' '}
                                    {data.updated_at || '--/--/--'}
                                </Typography>

                                <Typography variant="body2" mt={2}>
                                    {data.description || ''}
                                </Typography>
                            </Card.Body>

                            <Button
                                disabled={disabled}
                                variant="outlined"
                                fullWidth
                                onClick={() => handleOpenPowerStation(data.id)}
                                sx={{ mt: 'auto', textTransform: 'none' }}
                            >
                                {t('View power station', { count: 2 })}
                            </Button>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

CredentialLists.propTypes = {
    canModify: PropTypes.bool,
    credentials: PropTypes.array,
    disabled: PropTypes.bool,
    handleClickUpdate: PropTypes.func,
    handleOpenPowerStation: PropTypes.func,
    prepareDeleteCredential: PropTypes.func,
    providersDictionary: PropTypes.object,
};

export default CredentialLists;
