import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import SourceInfoChip from '../SourceInfoChip';

const ControlledInfoChip = ({ control, disabled, name }) => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <SourceInfoChip
                active={field.value}
                disabled={disabled}
                infoKey={name}
                onClick={() => !disabled && field.onChange(!field.value)}
                onDelete={() => !disabled && field.onChange(false)}
            />
        )}
    />
);

ControlledInfoChip.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    name: PropTypes.string,
};

export default ControlledInfoChip;
