import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getProjects = createSelector(getModel, (model) => model.projects);

export const getIsFetchingProjects = createSelector(
    getProjects,
    (projects) => projects.isFetching,
);

export const getProjectsData = createSelector(
    getProjects,
    (projects) => projects.data,
);

export const getProjectsErrors = createSelector(
    getProjects,
    (projects) => projects.errors,
);

export const getHasNextPage = createSelector(
    getModel,
    (model) => model.hasNextPage,
);

export const getNextPage = createSelector(getModel, (model) => model.nextPage);

export const getIsFetchingNextPage = createSelector(
    getNextPage,
    (model) => model.isFetching,
);
