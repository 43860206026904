import get from 'lodash/get';

import { convertCurrency, parseExponentialNumber } from 'common/utils/helpers';

import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';
import { INITIAL_VALUES } from '../actionTypes';
import { proposalGeneratorEnergyStorageTableActions } from '../reducer';

export default (offerEnergyStorage) => (dispatch, getState) => {
    const offerDetailsComplements =
        proposalGeneratorOneColumnSelectors.getOfferDetailsComplements(
            getState(),
        );

    const conversionFactor = convertCurrency({
        amount: 1000,
        exchangeRate: offerDetailsComplements?.type_change,
        fromCurrency: offerDetailsComplements?.currency?.abbreviation,
        toCurrency: 'USD',
    });

    const items = offerEnergyStorage.map((product) => {
        return {
            cost: product.cost,
            cost_by_size:
                (conversionFactor * product?.cost) / product?.load_capacity ||
                0,
            discount: product.discount,
            discount_type: product.discount_type,
            final_cost: product.final_cost,
            id: product.id,
            item: get(product, 'product_branch_office.id', null),
            margin: parseExponentialNumber(product.margin),
            percentage_discount: product.percentage_discount,
            quantity: product.quantity,
            selectedProduct: get(product, 'product_branch_office.id', null),
            temp_cost: product.cost,
            temp_discount: product.discount,
            temp_margin: product.margin,
            temp_quantity: product.quantity,
            total_size: product.load_capacity / 1000 || 0,
            unit_price: product.unit_price,
        };
    });

    dispatch(
        proposalGeneratorEnergyStorageTableActions[INITIAL_VALUES]({
            items,
        }),
    );
};
