import i18next from 'i18next';

import { deleteItem } from 'common/api/v1/proposalsPlans';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_ITEM,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { planConfigurationActions } from '../reducer';
import * as selectors from '../selectors';

import toggleModalProduct from './toggleModalProduct';

export default (id) => (dispatch, getState) => {
    const state = getState();
    const data = selectors.getFetchItemsData(state);
    dispatch(planConfigurationActions[DELETE_ITEM]());

    deleteItem(id)
        .then(() => {
            dispatch(planConfigurationActions[DELETE_ITEM_SUCCESS]());
            const newItems = data.filter((item) => item.id !== id);
            dispatch(planConfigurationActions[UPDATE_ITEMS_SUCCESS](newItems));
            dispatch(alerts.actions.close());
            dispatch(toggleModalProduct(false));

            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                planConfigurationActions[DELETE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
