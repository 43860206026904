import { set, get } from 'react-hook-form';

import { validateFieldsNatively } from './validateFieldsNatively';

export const toNestError = (errors, options) => {
    options.shouldUseNativeValidation &&
        validateFieldsNatively(errors, options);
    const fieldErrors = {};
    for (const path in errors) {
        const field = get(options.fields, path);
        set(
            fieldErrors,
            path,
            Object.assign(errors[path], { ref: field && field.ref }),
        );
    }
    return fieldErrors;
};
