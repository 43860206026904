import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
    Box,
    Checkbox,
    ListItem,
    ListItemIcon,
    ListItemText,
} from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';
const StyledListItemText = styled(ListItemText)`
    & .MuiListItemText-primary {
        font-size: 13px;
        font-weight: 600;
    }
`;
const StyledListItemTextDate = styled(ListItemText)`
    & .MuiListItemText-secondary {
        font-size: 13px;
        text-align: right;
    }
`;
const StyledListItemTextSecondary = styled(ListItemText)`
    & .MuiListItemText-secondary {
        font-size: 13px;
    }
`;
const StyledListItem = styled(ListItem)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        color:
            theme.palette.mode === 'dark'
                ? '#fff'
                : theme.palette.secondary.main,
    },
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 1,
}));

import NotificationIcon from './NotificationIcon';

const NotificationItem = ({
    handleClickNotification,
    notification,
    setCheckedAll,
    updateNotificationsTemp,
    withCheckbox,
}) => {
    const {
        category,
        created_at,
        heading,
        is_checked = false,
        message,
        viewed,
    } = notification;

    const handleChange = (event) => {
        updateNotificationsTemp(
            (value) => setCheckedAll(value),
            notification,
            event.target.checked,
        );
    };

    const parsedDate = parseDate(created_at, 'dd/MM/yyyy HH:mm:ss');
    const formattedDate = formatDate(parsedDate, 'hh:mm a');

    return (
        <StyledListItem selected={!viewed} divider>
            <StyledListItemTextDate secondary={formattedDate} />
            <Box alignItems="center" display="flex" gap={1}>
                {withCheckbox && (
                    <Checkbox
                        color="primary"
                        checked={is_checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                )}
                <ListItemIcon sx={{ minWidth: '38px' }}>
                    <NotificationIcon category={category} />
                </ListItemIcon>
                <Box
                    sx={{
                        cursor: 'pointer',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                    onClick={() => {
                        handleClickNotification(notification);
                    }}
                >
                    <StyledListItemText primary={heading} />
                    <StyledListItemTextSecondary secondary={message} />
                </Box>
            </Box>
        </StyledListItem>
    );
};

NotificationItem.propTypes = {
    handleClickNotification: PropTypes.func,
    notification: PropTypes.object,
    setCheckedAll: PropTypes.func,
    updateNotificationsTemp: PropTypes.func,
    withCheckbox: PropTypes.bool,
};

NotificationItem.defaultProps = {
    setCheckedAll: () => {},
    showMessage: false,
    withCheckbox: false,
};

export default NotificationItem;
