import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    background-clip: padding-box;
    background-color: #ffffff;
    border-radius: 3px;
    border: 0.5px solid #d3d7eb;
    box-shadow: 0 2px 24px 0 rgba(13, 13, 54, 0.06);
    color: #848bab;
    display: block;
    float: left;
    font-size: 13px;
    left: 0px;
    line-height: 16px;
    list-style: none;
    margin: 0.125rem 0 0;
    min-width: 10rem;
    padding: 5px;
    position: absolute;
    text-align: left;
    top: 0px;
    width: 100%;
    will-change: transform;
    z-index: 1000;
`;
