import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

import ErrorItem from 'common/components/ErrorItem';
import { ReactHookFormInput } from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import validate from '../validate';

const ForgotPasswordForm = ({
    errors,
    initialValues,
    isLoadingSession,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ReactHookFormInput
                control={control}
                name="email"
                placeholder={t('Email')}
                type="email"
            />

            {errors &&
                errors.map((error, index) => (
                    <ErrorItem key={index}>{`*${error}`}</ErrorItem>
                ))}

            <Box sx={{ my: 2, textAlign: 'center' }}>
                <Button
                    disabled={isLoadingSession}
                    fullWidth
                    sx={{ textTransform: 'none' }}
                    type="submit"
                >
                    {isLoadingSession
                        ? t('Loading').concat('...')
                        : t('Send email')}
                </Button>
            </Box>
        </form>
    );
};

ForgotPasswordForm.propTypes = {
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isLoadingSession: PropTypes.bool,
    onSubmit: PropTypes.func,
};

export default ForgotPasswordForm;
