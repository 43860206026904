import React, { useState } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import { Box, IconButton, Menu, MenuItem, Typography } from 'sunwise-ui';

const DropdownProducts = ({ items }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <Box ml={2} textAlign="right">
            <IconButton onClick={handleClick} aria-label="more-button">
                <MenuIcon />
            </IconButton>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {items.map(
                    (item, i) =>
                        item.visible && (
                            <MenuItem key={i} onClick={handleClose}>
                                <CircleIcon
                                    sx={{ color: item.color, fontSize: 25 }}
                                />
                                <Typography variant="body2" ml={1}>
                                    {item.label}
                                </Typography>
                            </MenuItem>
                        ),
                )}
            </Menu>
        </Box>
    );
};

DropdownProducts.propTypes = {
    items: PropTypes.array,
};

export default DropdownProducts;
