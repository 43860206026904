import i18next from 'i18next';

import { getFunnelProjects } from 'common/api/v3/funnelProjects';
import { FUNNEL_ITEMS_PER_PAGE as PER_PAGE } from 'common/constants';
import alerts from 'common/modules/alerts';

import {
    FETCH_FUNNEL_PROJECTS,
    FETCH_FUNNEL_PROJECTS_FAILURE,
    FETCH_FUNNEL_PROJECTS_SUCCESS,
} from '../actionTypes';

import { funnelProjectsActions } from '../reducer';

const NEXT_PAGE = 2;

export default (filterQuery) => (dispatch) => {
    dispatch(funnelProjectsActions[FETCH_FUNNEL_PROJECTS]());

    getFunnelProjects({ filterQuery })
        .then((response) => {
            const funnelProjects = response.data.reduce(
                (accColumns, column) => {
                    const { id, name, projects, total_rows } = column;
                    const nextPage = total_rows > PER_PAGE ? NEXT_PAGE : null;
                    return {
                        ...accColumns,
                        [id]: {
                            id,
                            name,
                            nextPage,
                            projects,
                            total_rows,
                        },
                    };
                },
                {},
            );

            dispatch(
                funnelProjectsActions[FETCH_FUNNEL_PROJECTS_SUCCESS]({
                    funnelProjects,
                    filterQuery,
                }),
            );
        })
        .catch((error) => {
            if (error.response.data && error.response.data?.errors?.length) {
                const dataAlert = {
                    messages: error.response.data.errors,
                    title: i18next.t('Message'),
                    type: alerts.ALERT_TYPE_ALERT,
                    variant: 'warning',
                };

                dispatch(alerts.actions.show(dataAlert));
            }
            dispatch(
                funnelProjectsActions[FETCH_FUNNEL_PROJECTS_FAILURE](error),
            );
        });
};
