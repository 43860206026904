import React from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

const SelectedText = ({
    branchOfficesDictionary,
    branchSelected,
    placeholder = i18next.t('Add'),
    productBranches,
}) => {
    const { t } = useTranslation();

    if (branchSelected === 'all') {
        switch (productBranches.length) {
            case 0:
                return (
                    <Typography
                        component="span"
                        fontWeight="bold"
                        noWrap
                        variant="body2"
                    >
                        {placeholder}
                    </Typography>
                );
            case 1:
                return (
                    <Typography
                        component="span"
                        fontWeight="bold"
                        noWrap
                        variant="body2"
                    >
                        {branchOfficesDictionary[productBranches[0]]}
                    </Typography>
                );
            default:
                return (
                    <Typography
                        component="span"
                        fontWeight="bold"
                        noWrap
                        variant="body2"
                    >
                        {t('Multi-branch office', { count: 2 })}
                    </Typography>
                );
        }
    }

    const branchSelectedIndex = productBranches.indexOf(branchSelected);

    if (branchSelectedIndex >= 0)
        return (
            <Typography
                component="span"
                fontWeight="bold"
                noWrap
                variant="body2"
            >
                {branchOfficesDictionary[
                    productBranches[branchSelectedIndex]
                ] || t('Selected')}
            </Typography>
        );

    return (
        <Typography component="span" fontWeight="bold" noWrap variant="body2">
            {t('Add')}
        </Typography>
    );
};

SelectedText.propTypes = {
    branchOfficesDictionary: PropTypes.object,
    branchSelected: PropTypes.string,
    placeholder: PropTypes.string,
    productBranches: PropTypes.array,
};

export default SelectedText;
