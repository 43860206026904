import i18next from 'i18next';
import { createSelector } from 'reselect';

import { getIsMexicanAccount } from 'common/utils/helpers/session';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving,
);

export const getDataSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.data,
);

export const getErrorsSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.errors,
);

export const getDeleteItem = createSelector(
    getModel,
    (model) => model.deleteItem,
);

export const getIsDeletingItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.isDeleting,
);

export const getDataDeleteItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.data,
);

export const getErrorsDeleteItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.errors,
);

export const getFetchItems = createSelector(
    getModel,
    (model) => model.fetchItems,
);

export const getIsFetchingItems = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.isFetching,
);

export const getDataFetchItems = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.data || [],
);

export const getErrorsFetchItems = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.errors,
);

export const getActivateItem = createSelector(
    getModel,
    (model) => model.activateItem,
);

export const getIsFetchingActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.isFetching,
);
export const getDataActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.data,
);
export const getErrorsActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.erros,
);

export const getIsOpenModalProduct = createSelector(
    getModel,
    (model) => model.isOpenModalProduct,
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord,
);

export const getSearchText = createSelector(
    getModel,
    (model) => model.searchText,
);

/* Divisiones Políticas */
export const getPoliticalDivisions = createSelector(
    getModel,
    (model) => model.politicalDivisions,
);

export const getPoliticalDivisionsData = createSelector(
    getPoliticalDivisions,
    (data) => data.data || [],
);

export const getPoliticalDivisionsForSelect = createSelector(
    getPoliticalDivisions,
    (dataValues) => {
        let items = dataValues.data.map((value) => {
            return {
                label: value.name,
                value: value.id,
            };
        });

        items.unshift({
            label: `${
                getIsMexicanAccount()
                    ? i18next.t('State')
                    : i18next.t('Political division of order 1')
            }`,
            values: '',
        });

        return items;
    },
);

/* Geocode */
export const getFetchGeocode = createSelector(
    getModel,
    (model) => model.fetchGeocode,
);

export const getFetchGeocodeData = createSelector(
    getFetchGeocode,
    (data) => data.data || [],
);

export const showGeocodeResults = createSelector(
    getModel,
    (model) => model.showGeocodeResults,
);

export const getGeocodePointModel = createSelector(
    getModel,
    (model) => model.fetchGeocodePoint,
);

export const getIsFetchingGeocodePoint = createSelector(
    getGeocodePointModel,
    (model) => model.isFetching,
);

export const getGeocodePointData = createSelector(
    getGeocodePointModel,
    (model) => model.data,
);

export const getGeocodePointErrors = createSelector(
    getGeocodePointModel,
    (model) => model.errors,
);
