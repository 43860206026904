import React from 'react';

import PropTypes from 'prop-types';

import GuaranteedGenerationFields from '../../proposalGeneratorConfigurationModal/components/GuaranteedGenerationFields';
import OpexFields from '../../proposalGeneratorConfigurationModal/components/OpexFields';
import RoiFields from '../../proposalGeneratorConfigurationModal/components/RoiFields';
import TimeToCommissioningFields from '../../proposalGeneratorConfigurationModal/components/TimeToCommissioningFields';

const TabSystem = ({ clearErrors, control, isDisabled, setValue }) => {
    return (
        <>
            <TimeToCommissioningFields
                control={control}
                isLocked={isDisabled}
                isHidden={false}
            />

            <GuaranteedGenerationFields control={control} />

            <RoiFields
                canModifyRoiSolarDegradation={true}
                canModifyRoiTaxIncentive={true}
                canModifyStockExchange={true}
                clearErrors={clearErrors}
                control={control}
                isLocked={isDisabled}
                nextRateData={{}}
                setValue={setValue}
                showSolarDegradationField={false}
                showDeprecatedField={true}
            />

            <OpexFields
                canModify={true}
                control={control}
                isLocked={isDisabled}
                setValue={setValue}
            />
        </>
    );
};

TabSystem.propTypes = {
    clearErrors: PropTypes.func,
    control: PropTypes.object,
    isDisabled: PropTypes.bool,
    setValue: PropTypes.func,
};

export default TabSystem;
