import { useState } from 'react';

export default (initialValue = {}) => {
    const [value, setValue] = useState(initialValue);

    const set = (value) => setValue(value);

    return {
        set,
        setChartValue: (value) => set(value),
        value,
    };
};
