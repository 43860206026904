import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { useBreakpoint } from 'common/hooks';

import { STATION_COLUMNS, STATION_TYPES } from '../constants';

import DropdownActions from './DropdownActions';
import PowerStationCardList from './PowerStationCardList';
import PowerStationsFilter from './PowerStationsFilter';
import StatusBadge from './StatusBadge';

const PowerStationsList = ({
    canModify,
    columnsData,
    credentialId,
    fetchPowerStations,
    handleOpenAssignProjectModal,
    isFetching,
    paginationData,
    powerStations,
    prepareUnassignProject,
    projectsDictionary,
}) => {
    const [filterData, setFilterData] = useState({
        pageNo: 1,
        pageSize: 10,
        searchText: '',
    });
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    useEffect(() => {
        fetchPowerStations(credentialId, filterData);
    }, [credentialId, filterData]);

    const onChangeFilter = (key, value) => {
        setFilterData({ ...filterData, [key]: value });
    };

    if (['xs', 'sm'].includes(breakpoint))
        return (
            <>
                <PowerStationsFilter
                    disabled={isFetching}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />

                <PowerStationCardList
                    canModify={canModify}
                    credentialId={credentialId}
                    filterData={filterData}
                    handleOpenAssignProjectModal={handleOpenAssignProjectModal}
                    isFetching={isFetching}
                    onChangeFilter={onChangeFilter}
                    paginationData={paginationData}
                    powerStations={powerStations}
                    prepareUnassignProject={prepareUnassignProject}
                    projectsDictionary={projectsDictionary}
                />
            </>
        );

    const columns = STATION_COLUMNS?.reduce((acc, column) => {
        if (!columnsData.includes(column)) return acc;
        switch (column) {
            case 'id':
                acc.push({
                    align: 'left',
                    field: 'id',
                    flex: 1,
                    headerName: 'Id',
                });
                break;
            case 'station_name':
                acc.push({
                    align: 'left',
                    field: 'station_name',
                    flex: 1,
                    headerName: t('Name'),
                });
                break;
            case 'city':
                acc.push({
                    align: 'left',
                    field: 'city',
                    flex: 1,
                    headerName: t('City'),
                    valueGetter: ({ row }) =>
                        !row.city || row?.city === -1 ? '--' : row?.city,
                });
                break;
            case 'type':
                acc.push({
                    align: 'left',
                    field: 'type',
                    flex: 1,
                    headerName: t('Type'),
                    valueGetter: (params) =>
                        t(STATION_TYPES[params?.row?.type]) || '--',
                });
                break;
            case 'project':
                acc.push({
                    align: 'left',
                    field: 'project',
                    flex: 1,
                    headerName: t('Project'),
                    valueGetter: (params) => {
                        const projectId = params?.row?.project;
                        return projectsDictionary[projectId]?.name || '--';
                    },
                });
                break;
            case 'state':
                acc.push({
                    align: 'left',
                    field: 'state',
                    flex: 1,
                    headerName: t('Status'),
                    renderCell: (params) => (
                        <StatusBadge status={params?.row?.state} />
                    ),
                });
                break;

            default:
                return acc;
        }

        return acc;
    }, []);

    columns.push({
        align: 'right',
        field: 'actions',
        headerName: '',
        renderCell: (params) => (
            <DropdownActions
                canModify={canModify}
                credentialId={credentialId}
                filterData={filterData}
                handleOpenAssignProjectModal={handleOpenAssignProjectModal}
                powerStation={params?.row}
                prepareUnassignProject={prepareUnassignProject}
            />
        ),
        width: 50,
    });

    return (
        <>
            <PowerStationsFilter
                disabled={isFetching}
                filterData={filterData}
                setFilterData={setFilterData}
            />

            <Card>
                <Card.Body>
                    <DataGrid
                        autoHeight
                        checkboxSelection={false}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: { rowsPerPageOptions: [10, 20, 50] },
                        }}
                        columns={columns || []}
                        disableColumnMenu
                        disableSelectionOnClick
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: { pageSize: filterData?.pageSize },
                        }}
                        loading={isFetching}
                        onPageChange={(page) =>
                            onChangeFilter('pageNo', page + 1)
                        }
                        onPageSizeChange={(size) =>
                            onChangeFilter('pageSize', size)
                        }
                        page={filterData.pageNo - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        rowCount={paginationData?.total || 0}
                        rows={powerStations}
                        sx={{
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                        }}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

PowerStationsList.propTypes = {
    canModify: PropTypes.bool,
    columnsData: PropTypes.array,
    credentialId: PropTypes.string,
    fetchPowerStations: PropTypes.func,
    handleOpenAssignProjectModal: PropTypes.func,
    isFetching: PropTypes.bool,
    paginationData: PropTypes.object,
    powerStations: PropTypes.array,
    prepareUnassignProject: PropTypes.func,
    projectsDictionary: PropTypes.object,
};

export default PowerStationsList;
