import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from 'sunwise-ui';

import { MONTHLY_FREQUENCY, YEARLY_FREQUENCY } from 'common/constants';

const FrequencyOptions = ({
    billingFrequency,
    isHidden,
    setBillingFrequency,
}) => {
    const { t } = useTranslation();

    const sxProps = {
        width: { xs: '100%', md: '100px' },
        background: '#b1b1b1',
        boxShadow: 'none',
        color: '#616161',
        '&:hover': {
            color: '#011e2e',
        },
    };

    if (isHidden) return null;

    return (
        <>
            <Typography
                variant="body1"
                fontWeight="bold"
                mt={2}
                textAlign="center"
            >
                {t('Choose billing frequency')}
            </Typography>
            <Box
                display="flex"
                gap={1}
                mt={1}
                sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'center',
                    alignItems: { xs: 'start', md: 'center' },
                }}
            >
                <Button
                    onClick={() => setBillingFrequency(MONTHLY_FREQUENCY)}
                    sx={
                        billingFrequency !== MONTHLY_FREQUENCY
                            ? sxProps
                            : { width: { xs: '100%', md: '100px' } }
                    }
                >
                    {t('Monthly')}
                </Button>
                <Button
                    onClick={() => setBillingFrequency(YEARLY_FREQUENCY)}
                    sx={
                        billingFrequency !== YEARLY_FREQUENCY
                            ? sxProps
                            : { width: { xs: '100%', md: '100px' } }
                    }
                >
                    {t('Yearly')}
                </Button>
            </Box>
        </>
    );
};

FrequencyOptions.propTypes = {
    billingFrequency: PropTypes.string,
    isHidden: PropTypes.bool,
    setBillingFrequency: PropTypes.func,
};

export default FrequencyOptions;
