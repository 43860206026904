import React from 'react';

import InsertLinkIcon from '@mui/icons-material/InsertLink';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RWebShare } from 'react-web-share';
import { IconButton } from 'sunwise-ui';

import { SHARED_OPTIONS } from 'common/constants';

const Share = ({ permalink, proposalName }) => {
    const { t } = useTranslation();
    if (isNull(permalink)) return null;
    return (
        <RWebShare
            closeText={t('Close')}
            data={{
                text: proposalName,
                url: permalink,
                title: proposalName,
            }}
            disableNative
            sites={SHARED_OPTIONS}
        >
            <IconButton>
                <InsertLinkIcon />
            </IconButton>
        </RWebShare>
    );
};

Share.propTypes = {
    permalink: PropTypes.string,
    proposalName: PropTypes.string,
};

export default Share;
