import { FUNNEL_ITEMS_PER_PAGE } from 'common/constants';

import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };

const ENTITY = 'api/v3/funnel-projects';

export const getFunnelProjects = ({
    filterQuery,
    per_page = FUNNEL_ITEMS_PER_PAGE,
} = {}) =>
    API.get(`/${ENTITY}/`, {
        params: { per_page: per_page, ...filterQuery },
    });

export const getNewPageProjects = ({
    filterQuery,
    status,
    page,
    per_page = FUNNEL_ITEMS_PER_PAGE,
} = {}) =>
    API.get(`/${ENTITY}/pagination/`, {
        params: { page, per_page, status, ...filterQuery },
    });

export const updateStatusOrder = (projectUUID, data) =>
    API.put(`/${ENTITY}/${projectUUID}/`, data, config);

export const getListProjects = ({
    filterQuery,
    perPage = FUNNEL_ITEMS_PER_PAGE,
}) =>
    API.get(`api/v3/funnel/listview/`, {
        params: { per_page: perPage, ...filterQuery },
    });

export const getNextPageListProjects = ({
    page,
    perPage = FUNNEL_ITEMS_PER_PAGE,
    filterQuery,
}) =>
    API.get(`/api/v3/funnel/listview/`, {
        params: { page, per_page: perPage, ...filterQuery },
    });
