import connectSocket from '../connectSocket';
import { PREFIX } from '../constants';
import disconnectSocket from '../disconnectSocket';
import sendMessageSocket from '../sendMessageSocket';

const ENTITY = 'sunpay/chat/chat_credit';
const baseURL = import.meta.env.VITE_SOCKET_URL;

export const connect = (chatId) =>
    connectSocket(`${baseURL}/${ENTITY}/${chatId}`, PREFIX);
export const disconnect = () => disconnectSocket(PREFIX);
export const sendMessage = (chatId, installerId, message, token, userId) =>
    sendMessageSocket(
        {
            message: {
                data: {
                    id: chatId,
                    message: message,
                    message_from: installerId,
                    model: 'chats/credit/',
                },
                device: '2',
                token,
                type: '0',
                user_id: userId,
                'user-agent': navigator.userAgent,
            },
            type: 'send_message',
        },
        PREFIX,
    );
