import { getDictionaryById } from '../helpers';
import * as selectors from '../selectors';

import updateSegment from './updateSegment';

export default (values) => (dispatch, getState) => {
    const {
        azimuth,
        callback,
        commercialOfferId,
        google,
        mapValue,
        segmentId,
    } = values;
    const state = getState();
    const segments = selectors.getSegmentsData(state);
    const segmentsDictionary = getDictionaryById(segments);
    const selectedSegment = segmentsDictionary[segmentId];
    if (!selectedSegment) return;

    const newValues = { ...selectedSegment, azimuth };

    dispatch(
        updateSegment({
            callback,
            commercialOfferId,
            google,
            mapValue,
            values: newValues,
        }),
    );

    if (callback) callback();
};
