import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import { handleFileURL } from 'common/utils/helpers';

import ProductItem from '../ProductItem';

const AllianceItem = ({ alliance, disabled, handleOnClikAlliance }) => {
    const name = get(alliance, 'financier.name', '');
    const image = get(alliance, 'financier.image', null);
    return (
        <ProductItem
            disabled={disabled}
            name={name}
            onClick={() => {
                if (handleOnClikAlliance) handleOnClikAlliance(alliance);
            }}
            srcImg={handleFileURL(image, import.meta.env.VITE_S3_MEDIA_PATH)}
        />
    );
};

AllianceItem.propTypes = {
    alliance: PropTypes.object,
    disabled: PropTypes.bool,
    handleOnClikAlliance: PropTypes.func,
};

export default AllianceItem;
