import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip } from 'sunwise-ui';

const StatusFormatter = ({ callback, handleClickBulkItems, row }) => {
    const { t } = useTranslation();
    const renderAction = (completed) => {
        if (completed)
            return (
                <Tooltip title={t('Completed')}>
                    <IconButton
                        onClick={() =>
                            handleClickBulkItems([row.id], callback, false)
                        }
                    >
                        <CheckCircleIcon color="success" />
                    </IconButton>
                </Tooltip>
            );
        return (
            <Tooltip title={t('Complete')}>
                <IconButton
                    onClick={() => handleClickBulkItems([row.id], callback)}
                >
                    <CheckCircleIcon />
                </IconButton>
            </Tooltip>
        );
    };
    return (
        <Box alignItems="center" display="flex" gap={1}>
            {renderAction(row?.completed)}
        </Box>
    );
};

StatusFormatter.propTypes = {
    callback: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    row: PropTypes.object,
};

export default StatusFormatter;
