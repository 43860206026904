import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from 'sunwise-ui';

import InputMaskForwardRef from 'common/components/InputMaskForwardRef';

const ReactHookFormInputMask = (props) => {
    const {
        alwaysShowMask,
        control,
        dataIntercomTarget,
        disabled,
        mask,
        maskPlaceholder,
        name,
        readOnly,
        ...rest
    } = props;

    const onChangeAction = (event, onChange) => onChange(event.target.value);

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { name, onChange, value },
                fieldState: { error },
            }) => (
                <TextField
                    disabled={disabled}
                    data-intercom-target={dataIntercomTarget}
                    error={Boolean(error)}
                    helperText={error?.message}
                    name={name}
                    InputProps={{
                        inputComponent: InputMaskForwardRef,
                        inputProps: {
                            alwaysShowMask,
                            disabled,
                            mask,
                            maskPlaceholder,
                            onChange: (e) => onChangeAction(e, onChange),
                            value,
                        },
                        readOnly: readOnly,
                    }}
                    value={value || ''}
                    {...rest}
                />
            )}
        />
    );
};

ReactHookFormInputMask.defaultProps = {
    alwaysShowMask: false,
    mask: '',
    maskPlaceholder: '',
};

ReactHookFormInputMask.propTypes = {
    alwaysShowMask: PropTypes.bool,
    control: PropTypes.object,
    dataIntercomTarget: PropTypes.string,
    disabled: PropTypes.bool,
    input: PropTypes.object,
    mask: PropTypes.string,
    maskPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    meta: PropTypes.object,
    name: PropTypes.string,
    readOnly: PropTypes.bool,
};

export default ReactHookFormInputMask;
