import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    ToggleButton,
    ToggleButtonGroup,
} from 'sunwise-ui';

import { MONTHS } from 'common/constants';
import { useBreakpoint } from 'common/hooks';
import { getMonthLabel } from 'common/utils/helpers';

const MonthSelector = ({ onChange, showYearlyOption, value }) => {
    const breakpoint = useBreakpoint();
    const { t } = useTranslation();
    if (['xs', 'sm', 'md'].includes(breakpoint))
        return (
            <FormControl fullWidth sx={{ my: 2 }}>
                <InputLabel>{t('Month')}</InputLabel>
                <Select label={t('Month')} onChange={onChange} value={value}>
                    {showYearlyOption && (
                        <MenuItem key="yearly-average" value={0}>
                            {t('Yearly')}
                        </MenuItem>
                    )}
                    {MONTHS.map((month, index) => (
                        <MenuItem key={`month-${index}`} value={index + 1}>
                            {getMonthLabel(month)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    return (
        <ToggleButtonGroup
            color="primary"
            exclusive
            fullWidth
            onChange={onChange}
            size="small"
            sx={{ my: 1 }}
            value={value}
        >
            {showYearlyOption && (
                <ToggleButton value={0}>{t('Yearly')}</ToggleButton>
            )}
            {MONTHS.map((month, index) => (
                <ToggleButton key={`month-selector-${index}`} value={index + 1}>
                    {t(month)}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

MonthSelector.propTypes = {
    onChange: PropTypes.func,
    showYearlyOption: PropTypes.bool,
    value: PropTypes.number,
};

export default MonthSelector;
