import { NAME } from './constants';

export const FETCH_AFTER_SALES_PROJECTS = `${NAME}/FETCH_AFTER_SALES_PROJECTS`;
export const FETCH_AFTER_SALES_PROJECTS_FAILURE = `${NAME}/FETCH_AFTER_SALES_PROJECTS_FAILURE`;
export const FETCH_AFTER_SALES_PROJECTS_SUCCESS = `${NAME}/FETCH_AFTER_SALES_PROJECTS_SUCCESS`;

export const FETCH_POWER_STATIONS = `${NAME}/FETCH_POWER_STATIONS`;
export const FETCH_POWER_STATIONS_FAILURE = `${NAME}/FETCH_POWER_STATIONS_FAILURE`;
export const FETCH_POWER_STATIONS_SUCCESS = `${NAME}/FETCH_POWER_STATIONS_SUCCESS`;

export const FETCH_PROJECTS = `${NAME}/FETCH_PROJECTS`;
export const FETCH_PROJECTS_FAILURE = `${NAME}/FETCH_PROJECTS_FAILURE`;
export const FETCH_PROJECTS_SUCCESS = `${NAME}/FETCH_PROJECTS_SUCCESS`;

export const SET_ASSIGN_PROJECT_MODAL_IS_OPEN = `${NAME}/SET_ASSIGN_PROJECT_MODAL_IS_OPEN`;
export const SET_SELECTED_POWER_STATION = `${NAME}/SET_SELECTED_POWER_STATION`;

export const RESET = `${NAME}/RESET`;
export const RESET_ASSIGN_PROJECT = `${NAME}/RESET_ASSIGN_PROJECT`;

export const TOGGLE_ASSIGN_PROJECT = `${NAME}/TOGGLE_ASSIGN_PROJECT`;
export const TOGGLE_ASSIGN_PROJECT_FAILURE = `${NAME}/TOGGLE_ASSIGN_PROJECT_FAILURE`;
export const TOGGLE_ASSIGN_PROJECT_SUCCESS = `${NAME}/TOGGLE_ASSIGN_PROJECT_SUCCESS`;
