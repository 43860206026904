import { getDatasheetInverter } from 'common/api/v1/equipmentCompatibility';

import {
    FETCH_DATASHEET_INVERTER,
    FETCH_DATASHEET_INVERTER_FAILURE,
    FETCH_DATASHEET_INVERTER_SUCCESS,
} from '../actionTypes';
import { equipmentCompatibilityActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(equipmentCompatibilityActions[FETCH_DATASHEET_INVERTER]());

    getDatasheetInverter(id)
        .then((response) =>
            dispatch(
                equipmentCompatibilityActions[FETCH_DATASHEET_INVERTER_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                equipmentCompatibilityActions[FETCH_DATASHEET_INVERTER_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
