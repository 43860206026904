import { updateCreditApplicationStatus } from 'common/api/v1/sunpay';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    UPDATE_CREDIT_APPLICATION_STATUS,
    UPDATE_CREDIT_APPLICATION_STATUS_FAILURE,
    UPDATE_CREDIT_APPLICATION_STATUS_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

import fetchCommercialOffers from './fetchCommercialOffers';

export default (callback, isArchived, offerId, projectId) => (dispatch) => {
    dispatch(projectCommOfferTableActions[UPDATE_CREDIT_APPLICATION_STATUS]());

    updateCreditApplicationStatus(offerId)
        .then((response) => {
            dispatch(
                projectCommOfferTableActions[
                    UPDATE_CREDIT_APPLICATION_STATUS_SUCCESS
                ](response.data),
            );
            dispatch(fetchCommercialOffers({ isArchived, projectId }));

            dispatch(alerts.actions.close());

            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                projectCommOfferTableActions[
                    UPDATE_CREDIT_APPLICATION_STATUS_FAILURE
                ](error?.response?.data?.errors),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
