import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Button, Grid } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormInputDatePicker,
    ReactHookFormInputMask,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as projectCommercialOfferTableActions from '../../projectCommercialOfferTable/actions';
import * as projectCommercialOfferTableSelectors from '../../projectCommercialOfferTable/selectors';
import * as supplierIntegrationsSelectors from '../../supplierIntegrations/selectors';
import * as actions from '../actions';
import {
    COMMERCIAL_OFFER_GENERATED_STATUS,
    DEFAULT_SERVICE_NUMBER_MASK,
    MIN_DATE,
} from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

import ContractedDemandConcepts from './ContractedDemandConcepts';
import CurrencyFields from './CurrencyFields';
import LocationSection from './LocationSection';
import PPASection from './PPASection';
import RateSection from './RateSection';

const Form = ({
    commercialOffers,
    fetchCommercialOffers,
    initialValues,
    isSaving,
    onClose,
    ratesDictionary,
    resetForm,
    save,
    selectedProjectId,
    sipsIntegration,
}) => {
    const [isLegacyCommercialOffer, setIsLegacyCommercialOffer] =
        useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const { t } = useTranslation();

    const {
        control,
        formState: { isSubmitted, isValid },
        getValues,
        handleSubmit,
        watch,
        reset,
        setValue,
    } = useForm({
        context: { ratesDictionary },
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const [source, rateId, contractedDemandFields] = watch([
        'source',
        'rate',
        'hourly_contracted_demand_keys',
    ]);
    const selectedRate = ratesDictionary[rateId];

    useEffect(() => {
        fetchCommercialOffers({
            projectId: selectedProjectId,
            status: COMMERCIAL_OFFER_GENERATED_STATUS,
        });

        return () => resetForm();
    }, []);

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnClose = () => onClose();

    const handleOnClickSubmit = (values) => {
        const newValues = { ...values, project: selectedProjectId };

        if (isLegacyCommercialOffer) newValues.commercial_offer = null;

        save(newValues, handleOnClose);
    };

    const canUseCups = Boolean(sipsIntegration?.id);
    const disabled = isSaving;

    return (
        <form onSubmit={handleSubmit(handleOnClickSubmit)}>
            <Grid container>
                <Grid item sm={9} xs={18}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={disabled}
                        label={t('Source')}
                        name="source"
                        options={[
                            {
                                label: '',
                                options: [{ label: 'Sunwise', value: '0' }],
                            },
                        ]}
                        variant="standard"
                    />
                </Grid>

                {source === '0' && (
                    <Grid item sm={9} xs={18}>
                        <ReactHookFormSelect2
                            control={control}
                            disabled={disabled}
                            label={t('Proposal')}
                            name="commercial_offer"
                            onChange={(value) => {
                                const commercialOfferParent =
                                    commercialOffers.find(
                                        (offer) =>
                                            offer.commercial_offer?.[0]?.id ===
                                            value,
                                    );
                                const commercialOffer =
                                    commercialOfferParent
                                        ?.commercial_offer?.[0];

                                setIsLegacyCommercialOffer(
                                    commercialOffer?.legacy || false,
                                );
                                if (!commercialOffer) return;

                                const {
                                    currency,
                                    ppa_values,
                                    system_size,
                                    total,
                                } = commercialOffer;

                                setValue(
                                    'ppa_active',
                                    ppa_values?.ppa_active || false,
                                );
                                setValue(
                                    'ppa_price',
                                    ppa_values?.ppa_price || 0,
                                );

                                setValue(
                                    'ppa_percentage_increase',
                                    ppa_values?.ppa_percentage_increase || 0,
                                );

                                setValue('system_size', system_size || 0);

                                setValue('total_cost', total || 0);

                                if (!currency?.id) return;

                                setValue('currency', currency.id);
                            }}
                            options={[
                                {
                                    label: '',
                                    options: commercialOffers.map((offer) => ({
                                        label: offer.name,
                                        value: offer.commercial_offer[0].id,
                                    })),
                                },
                            ]}
                            variant="standard"
                        />
                    </Grid>
                )}

                {isLegacyCommercialOffer && (
                    <Alert severity="warning">
                        {t(
                            'This proposal is outdated and not compatible with the current report generation. It is recommended to update the consumption data to proceed and make comparisons with the proposal. If you continue without updating, the After Sales functionalities will be limited',
                        )}
                    </Alert>
                )}

                <Grid item xs={18} sm={9}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disabled={isSaving}
                        disableFuture
                        minDate={MIN_DATE}
                        name="start_date"
                        label={t('Installation date')}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md={9}>
                    <ReactHookFormIntlNumberInput
                        append={selectedCurrency?.abbreviation}
                        control={control}
                        decimalsLimit={2}
                        disabled={disabled}
                        fullWidth
                        label={t('Total cost')}
                        name="total_cost"
                        prepend={selectedCurrency?.symbol}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md={9}>
                    <ReactHookFormInputMask
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label={canUseCups ? 'CUPS' : t('Service number')}
                        mask={DEFAULT_SERVICE_NUMBER_MASK}
                        name="service_number"
                        variant="standard"
                    />
                </Grid>

                <Grid item sm={9} xs={18}>
                    <ReactHookFormIntlNumberInput
                        allowDecimals
                        allowNegativeValue={false}
                        append="kW"
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label={t('System size')}
                        name="system_size"
                        variant="standard"
                    />
                </Grid>

                {source === '1' && (
                    <Grid item sm={9} xs={18}>
                        <ReactHookFormInput
                            control={control}
                            disabled={disabled}
                            label={t('Number of modules')}
                            name="modules_quantity"
                            variant="standard"
                        />
                    </Grid>
                )}
            </Grid>

            <CurrencyFields
                control={control}
                disabled={disabled}
                getValues={getValues}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                setValue={setValue}
            />

            <LocationSection
                control={control}
                disabled={disabled}
                initialValues={initialValues}
                selectedRate={selectedRate}
                setValue={setValue}
            />

            <RateSection
                control={control}
                disabled={disabled}
                getValues={getValues}
                ratesDictionary={ratesDictionary}
                selectedRate={selectedRate}
                setValue={setValue}
            />

            <ContractedDemandConcepts
                contractedDemandFields={contractedDemandFields}
                control={control}
                disabled={disabled}
                getValues={getValues}
                selectedRate={selectedRate}
                setValue={setValue}
            />

            <PPASection
                control={control}
                currencySymbol={selectedCurrency?.symbol}
                disabled={disabled}
            />

            {isSubmitted && !isValid && (
                <Alert severity="error">
                    {t('Complete all fields with the correct information')}
                </Alert>
            )}

            <Grid container mt={2}>
                <Grid
                    display="flex"
                    flexDirection={{ md: 'row', xs: 'column' }}
                    item
                    justifyContent={{ md: 'right', xs: 'center' }}
                    xs
                >
                    <Button
                        color="secondary"
                        disabled={disabled}
                        onClick={handleOnClose}
                        sx={{
                            mr: { md: 1, xs: 0 },
                            order: { md: 1, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={disabled}
                        sx={{
                            mb: { md: 0, xs: 1 },
                            order: { md: 1, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        type="submit"
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

Form.propTypes = {
    commercialOffers: PropTypes.array,
    fetchCommercialOffers: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    onClose: PropTypes.func,
    ratesDictionary: PropTypes.object,
    resetForm: PropTypes.func,
    save: PropTypes.func,
    selectedProjectId: PropTypes.string,
    sipsIntegration: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    commercialOffers:
        projectCommercialOfferTableSelectors.getCommercialOffersData,
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSaving,
    ratesDictionary: selectors.getRatesDictionary,
    sipsIntegration: supplierIntegrationsSelectors.getSipsIntegration,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCommercialOffers: (params) =>
        dispatch(
            projectCommercialOfferTableActions.fetchCommercialOffers(params),
        ),
    fetchScheduleRateConfiguration: (params) =>
        dispatch(actions.fetchScheduleRateConfiguration(params)),
    resetForm: () => dispatch(actions.resetForm()),
    save: (values, successCallback) =>
        dispatch(actions.save(values, successCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
