import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ROLE_LEVELS } from 'common/constants/permissionsV2';
import {
    UserBranchOfficeSelect,
    UserBranchOfficesMultipleSelect,
} from 'common/modules/multiBranches/components';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { getIsOwnerUser } from 'common/utils/helpers/session';

import { getIsCustomOrGM } from '../helpers';

const BranchOfficeSelect = ({
    branchOffices,
    branchOfficesDictionary,
    branchSelected,
    control,
    disabled,
    hasAllBranchOfficesAuthUser,
    hasAllBranchOfficesCurrentUser,
    isNewRecord,
    roleLevel,
    setValue,
    userBranchOffices,
}) => {
    const { t } = useTranslation();
    const isCustomOrGM = getIsCustomOrGM(roleLevel);
    const isOwnerUser = getIsOwnerUser();

    if (roleLevel === ROLE_LEVELS.OWNER) return null;

    if (isCustomOrGM)
        return (
            <UserBranchOfficesMultipleSelect
                branchOfficesDictionary={
                    isNewRecord ? branchOfficesDictionary : userBranchOffices
                }
                branchOfficesIdsAuthUser={Object.keys(branchOfficesDictionary)}
                control={control}
                disabled={disabled}
                hasAllBranchOfficesCurrentUser={hasAllBranchOfficesCurrentUser}
                label={t('Branch office', { count: 2 })}
                onChange={(_, isAllSelected) => {
                    setValue('has_all_branchoffices', isAllSelected);
                }}
                name="branch_offices"
                showAllBranchesOption={
                    hasAllBranchOfficesAuthUser || isOwnerUser
                }
                value={branchOffices}
            />
        );
    return (
        <UserBranchOfficeSelect
            branchOfficesDictionary={
                isNewRecord ? branchOfficesDictionary : userBranchOffices
            }
            branchOfficesIdsAuthUser={Object.keys(branchOfficesDictionary)}
            control={control}
            disabled={disabled || !['all', 'company'].includes(branchSelected)}
            label={t('Branch office')}
            name="branch_office"
        />
    );
};

BranchOfficeSelect.propTypes = {
    branchOffices: PropTypes.string,
    branchOfficesDictionary: PropTypes.object,
    branchSelected: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    hasAllBranchOfficesAuthUser: PropTypes.bool,
    hasAllBranchOfficesCurrentUser: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    roleLevel: PropTypes.string,
    setValue: PropTypes.func,
    userBranchOffices: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    branchOfficesDictionary: multiBranchesSelectors.getBranchesDictionary,
    branchSelected: multiBranchesSelectors.getBranchSelected,
});

export default connect(mapStateToProps)(BranchOfficeSelect);
