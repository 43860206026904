import { ONBOARDING_STEPS } from '../constants';

import setCurrentStep from './setCurrentStep';
import updateSection from './updateSection';

export default (step, callback) => (dispatch) => {
    dispatch(setCurrentStep(step + 1));
    dispatch(updateSection(ONBOARDING_STEPS.COMPANY));
    if (callback) callback(ONBOARDING_STEPS.COMPANY);
};
