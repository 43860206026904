import i18next from 'i18next';

import { getItem } from 'common/api/v1/integrations';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { assignBranchOffice } from 'common/utils/helpers';
import {
    getIsMexicanAccount,
    getSessionLocalStorage,
} from 'common/utils/helpers/session';

import {
    FETCH_INTEGRATION,
    FETCH_INTEGRATION_FAILURE,
    FETCH_INTEGRATION_SUCCESS,
} from '../actionTypes';
import { webIntegrationsActions } from '../reducer';
import initializeForm from '../web/actions/initializeForm';
import { handleLists } from '../web/helpers';
import * as selectors from '../web/selectors';

export default (uuid) => (dispatch, getState) => {
    const branchOffices =
        multiBranchesSelectors.getFilteredBranchesData(getState());
    const isMexicanAccount = getIsMexicanAccount();
    const session = getSessionLocalStorage();
    const values = selectors.getInitialValuesForm(getState());

    dispatch(webIntegrationsActions[FETCH_INTEGRATION]());

    getItem(uuid)
        .then((response) => {
            dispatch(
                webIntegrationsActions[FETCH_INTEGRATION_SUCCESS](
                    response.data,
                ),
            );
            if (!isMexicanAccount) {
                dispatch(
                    initializeForm({
                        ...values,
                        agent: session.id,
                        branch_office: assignBranchOffice(branchOffices),
                        install_type: handleLists(['INSTALLATION_TYPE']),
                        method_consumtion: handleLists(['ENERGY']),
                        style: {
                            ...values.style,
                            button: {
                                ...values.style.button,
                                text: i18next.t('Generate express quote'),
                            },
                        },
                    }),
                );
            } else {
                dispatch(
                    initializeForm({
                        agent: session.id,
                        branch_office: assignBranchOffice(branchOffices),
                    }),
                );
            }
        })
        .catch((error) =>
            dispatch(webIntegrationsActions[FETCH_INTEGRATION_FAILURE](error)),
        );
};
