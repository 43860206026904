export const NAME = 'report-preview';

export const CHART_COLORS = {
    CONSUMPTION: ['#20C4D9', '#1695FF'],
    GENERATION: ['#FFC107', '#FF7043'],
    PAYMENT: ['#E53935', '#8BC34A', '#3CAF5F'],
};

export const DATE_FORMATS = {
    SUNWISE: 'dd/MM/yyyy',
    LABEL: 'MMM yy',
    LABEL_2: 'dd/MMM',
};

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';

export const REPORT_TYPES = {
    SINGLE_PERIOD: 0,
    MULTIPERIOD: 1,
    GROUPED_PERIODS: 2,
};

export const PERIOD_TYPES = {
    HISTORICAL: 0,
    PROPOSAL: 2,
    PROPOSAL_LEGACY: 1,
};

export const INDICATORS_NAMES = {
    accumulative_generation: 'Accumulated generation',
    accumulative_savings: 'Accumulated savings',
    actual_consumption: 'Current consumption',
    actual_exported_energy: 'Current exported energy',
    actual_generation: 'Current generation',
    actual_grid_consumption: 'Current grid consumption',
    actual_savings: 'Current savings',
    actual_self_consumption: 'Current self-consumption',
    comparison_consumption: 'Compared consumption',
    comparison_generation: 'Compared generation',
    comparison_price: 'Compared price',
    generation_precision: 'Generation accuracy',
    rate: 'Rate',
    system_size: 'System size',
    with_solar_price: 'Price with solar',
    without_solar_price: 'Price without solar',
    sun_hrs: 'Daily average sun hours',
};
