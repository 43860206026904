import React from 'react';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ImageIcon from '@mui/icons-material/Image';
import { useTheme } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Avatar,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
} from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import IconFinancial from 'common/components/icons/IconFinancial';
import { STATUS } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { getTranslatedStatusValue, handleFileURL } from 'common/utils/helpers';

import FinancialProductImage from 'resources/financialProduct.png';

import { getSxRequestButton } from '../../projectCommercialOfferTable/helpers';
import { FINANCING_TYPE } from '../constants';

import SideMenuCard from './SideMenuCard';

const FinancialCard = ({
    canModify,
    contactId,
    creditStatus,
    financing,
    handleClickDatasheetItem,
    handleClickDocumentItem,
    handleClickPrepareRegenerateFinancingTemplate,
    hasClosedCredit,
    isArchived,
    isDisabled,
    prepareRequest,
    projectId,
    proposalId,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    if (isEmpty(financing)) return null;

    const content = get(financing, 'content', {});
    const currentId = isEmpty(content) ? financing.name : content?.id;
    const extFinancingFile = get(financing, 'proposal_financing_file', null);
    const isQuoted = creditStatus === STATUS.QUOTED_STATUS.key;
    const isDisabledButton =
        !canModify || isArchived || isDisabled || (isQuoted && hasClosedCredit);

    return (
        <SideMenuCard icon={<IconFinancial />} title={t('Financing')}>
            <List disablePadding>
                <ListItem
                    disabled={isDisabled || currentId === selectedDocumentId}
                    disablePadding
                    selected={currentId === selectedDocumentId}
                    sx={{
                        borderRadius: '8px',
                        mb: '5px',
                        '&.Mui-selected': {
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? '#000'
                                    : 'rgba(31, 60, 83, 0.13)',
                        },
                    }}
                    secondaryAction={
                        <DropdownCardMenu
                            items={[
                                {
                                    disabled: !canModify,
                                    handleClick: () =>
                                        handleClickPrepareRegenerateFinancingTemplate(
                                            currentId,
                                        ),
                                    label: t('Regenerate'),
                                },
                            ]}
                            sxIconButton={{
                                '&:hover': { backgroundColor: 'transparent' },
                            }}
                            visible={currentId === selectedDocumentId}
                        />
                    }
                >
                    <ListItemButton
                        onClick={() => {
                            if (extFinancingFile)
                                handleClickDatasheetItem({
                                    id: financing?.name,
                                    name: financing?.name,
                                    archive: extFinancingFile,
                                    type: FINANCING_TYPE,
                                });
                            else if (!isEmpty(content))
                                handleClickDocumentItem({
                                    id: content?.id,
                                    name: content?.name,
                                    type: FINANCING_TYPE,
                                });
                            return false;
                        }}
                        sx={{ borderRadius: '8px' }}
                    >
                        <ListItemAvatar>
                            {financing?.is_own_financing ? (
                                <Avatar
                                    src={FinancialProductImage}
                                    sx={{ height: 32, width: 32 }}
                                >
                                    <ImageIcon />
                                </Avatar>
                            ) : (
                                <Avatar
                                    src={handleFileURL(
                                        get(financing, 'image', ''),
                                        import.meta.env.VITE_S3_MEDIA_PATH,
                                    )}
                                    sx={{ height: 32, width: 32 }}
                                >
                                    <ImageIcon />
                                </Avatar>
                            )}
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${financing?.name} (${t('Proposal')})`}
                            sx={{
                                '.MuiListItemText-primary': {
                                    fontSize: '14px',
                                },
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                {!financing?.is_own_financing && (
                    <ListItem mt={2} sx={{ width: '100%' }}>
                        <Button
                            color="secondary"
                            disabled={isDisabledButton}
                            endIcon={<ArrowCircleRightIcon fontSize="medium" />}
                            onClick={() =>
                                prepareRequest(contactId, projectId, proposalId)
                            }
                            type="button"
                            sx={getSxRequestButton(
                                creditStatus,
                                isDarkMode,
                                true,
                            )}
                            variant="outlined"
                        >
                            {isQuoted
                                ? t('Start request')
                                : t(getTranslatedStatusValue(creditStatus))}
                        </Button>
                    </ListItem>
                )}
            </List>
        </SideMenuCard>
    );
};

FinancialCard.propTypes = {
    canModify: PropTypes.bool,
    contactId: PropTypes.string,
    creditStatus: PropTypes.number,
    financing: PropTypes.object,
    handleClickDatasheetItem: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    handleClickPrepareRegenerateFinancingTemplate: PropTypes.func,
    hasClosedCredit: PropTypes.bool,
    isArchived: PropTypes.bool,
    isDisabled: PropTypes.bool,
    prepareRequest: PropTypes.func,
    projectId: PropTypes.string,
    proposalId: PropTypes.string,
    selectedDocumentId: PropTypes.string,
};

export default withPermissions(
    PERMISSION_LIST.REQUEST_ALLIANCE_PRODUCT_FOR_PROPOSAL_PERMISSION,
)(FinancialCard);
