import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getIsFetchingDraft = createSelector(
    getModel,
    (model) => model.isFetchingDraft,
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal,
);
