import { getConsumptionEnergy } from 'common/api/v2/costumerEnergyConsumption';

import {
    FETCH_CONSUMPTION_ENERGY,
    FETCH_CONSUMPTION_ENERGY_FAILURE,
    FETCH_CONSUMPTION_ENERGY_SUCCESS,
} from '../actionTypes';
import { quickViewProjectsActions } from '../reducer';

import fetchScheduleRateConfiguration from './fetchScheduleRateConfiguration';

export default (projectId) => (dispatch) => {
    dispatch(quickViewProjectsActions[FETCH_CONSUMPTION_ENERGY]());

    getConsumptionEnergy(projectId)
        .then((response) => {
            const data = response.data.data;

            dispatch(
                quickViewProjectsActions[FETCH_CONSUMPTION_ENERGY_SUCCESS](
                    data,
                ),
            );

            if (data?.rate?.id) {
                Promise.all([
                    dispatch(
                        fetchScheduleRateConfiguration({
                            certified: data.rate.certified,
                            rate: data.rate.id,
                            rate_division: data.rate_division?.id,
                        }),
                    ),
                ]);
            }
        })
        .catch((error) =>
            dispatch(
                quickViewProjectsActions[FETCH_CONSUMPTION_ENERGY_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
