import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import { getMessage } from '../helpers';

import deleteOption from './deleteOption';

export default (id, type) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        haveTextCaptcha: true,
        messages: [getMessage(type)],
        onSuccess: () => dispatch(deleteOption(id, type)),
        title: i18next.t('Confirmation'),
        textToValidate: i18next.t('Delete').toUpperCase(),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
