import React from 'react';

import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Grid, FormControlLabel, Typography } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import { DEFAULT_CURRENCY } from 'common/constants';
import {
    getCurrencyIso,
    getCurrencyLocale,
    getCurrencySymbol,
    getLabelWithCurrency,
    numberFormat,
} from 'common/utils/helpers';
import { getCountryCurrency } from 'common/utils/helpers/session';

import {
    getIsAllChecked,
    handleChangeCheckingAll,
    handleChangeCostValue,
    handleChangeMarginValue,
} from '../helpers';

const ReduxFieldArrayPanels = ({
    control,
    disabled,
    formValues,
    getValues,
    name,
    setValue,
}) => {
    const { t } = useTranslation();
    const { fields } = useFieldArray({ control, name });
    const countryCurrency = getCountryCurrency();
    const currencyConfig = countryCurrency || DEFAULT_CURRENCY;
    const currencyLocale = getCurrencyLocale(currencyConfig);
    const currencyIso = getCurrencyIso(DEFAULT_CURRENCY);
    const currencySymbol = getCurrencySymbol(currencyConfig);

    return (
        <>
            <Grid
                alignItems="center"
                container
                sx={{
                    mb: { xs: 0, md: 2 },
                    '& .MuiGrid-item ': {
                        display: { xs: 'none', md: 'block' },
                    },
                }}
            >
                <Grid item md={2} textAlign="center">
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="secondary"
                                checked={getIsAllChecked(formValues?.panels)}
                                onChange={(e) =>
                                    handleChangeCheckingAll(
                                        e,
                                        fields,
                                        name,
                                        setValue,
                                    )
                                }
                            />
                        }
                        fontWeight="bold"
                        sx={{
                            m: 1,
                        }}
                    />
                </Grid>
                <Grid item md={7}>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Name')}
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Cost')}
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Margin')}
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Price')}
                    </Typography>
                </Grid>
            </Grid>
            {fields.map((item, i) => {
                const panel = formValues?.panels?.[i] || {};
                return (
                    <Grid
                        alignItems="center"
                        container
                        key={item.id}
                        sx={{
                            '& .MuiGrid-item ': {
                                pt: { xs: 0, lg: 2 },
                            },
                        }}
                    >
                        <Grid
                            item
                            xs={18}
                            md={2}
                            sx={{
                                my: { xs: 2, md: 0 },
                                textAlign: { xs: 'left', md: 'center' },
                            }}
                        >
                            <ReactHookFormCheck
                                color="secondary"
                                control={control}
                                name={`${name}.${i}.selected`}
                            />
                        </Grid>
                        <Grid item xs={18} md={7}>
                            <ReactHookFormInput
                                control={control}
                                disabled={disabled}
                                label={t('Name')}
                                name={`${name}.${i}.name`}
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={18} md={3}>
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                control={control}
                                fullWidth
                                label={getLabelWithCurrency('USD', t('Cost'))}
                                min="0"
                                name={`${name}.${i}.cost`}
                                onBlur={() =>
                                    handleChangeCostValue(
                                        getValues,
                                        i,
                                        name,
                                        setValue,
                                    )
                                }
                                prepend="$"
                            />
                        </Grid>
                        <Grid item xs={18} md={3}>
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                append="%"
                                control={control}
                                fullWidth
                                label={t('Margin')}
                                max={99}
                                min={0}
                                name={`${name}.${i}.margin`}
                                onBlur={() =>
                                    handleChangeMarginValue(
                                        getValues,
                                        i,
                                        name,
                                        setValue,
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={18} md={3}>
                            <Typography fontWeight="bold" mb={2}>
                                {numberFormat(panel?.final_cost, {
                                    currency: currencyIso,
                                    locale: currencyLocale,
                                    style: 'currency',
                                    symbol: currencySymbol,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};

ReduxFieldArrayPanels.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    formValues: PropTypes.object,
    getValues: PropTypes.func,
    name: PropTypes.string,
    setValue: PropTypes.func,
};

export default ReduxFieldArrayPanels;
