import { NAME } from './constants';

export const INITIALIZE_VALUES = `${NAME}/INITIALIZE_VALUES`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_COMMENT = `${NAME}/SAVE_COMMENT`;
export const SAVE_COMMENT_FAILURE = `${NAME}/SAVE_COMMENT_FAILURE`;
export const SAVE_COMMENT_SUCCESS = `${NAME}/SAVE_COMMENT_SUCCESS`;

export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;
