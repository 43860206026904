import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../../actions';
import Step1 from '../common/Step1';

import Step2 from './Step2';

const CreateTracking = ({
    isModalOpenInitialState = true,
    onExited,
    onSucccess,
    save,
}) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(isModalOpenInitialState);
    const [dialogSize, setDialogSize] = useState('md');
    const [step, setStep] = useState(1);
    const [values, setValues] = useState({ action: 'create-tracking' });

    const onSave = (values) => {
        const callback = () => {
            setIsModalOpen(false);
            if (onSucccess) onSucccess();
        };
        save(values, callback, onSucccess ? false : true);
    };

    const onSubmitStep = (key, values) =>
        setValues((prev) => ({ ...prev, [key]: values }));

    return (
        <Dialog
            onClose={() => setIsModalOpen(false)}
            onExited={onExited}
            open={isModalOpen}
            size={dialogSize}
            title={t('Create tracking')}
        >
            {step === 1 && (
                <Step1
                    onSubmit={onSubmitStep}
                    setDialogSize={setDialogSize}
                    setStep={setStep}
                    values={values}
                />
            )}

            {step === 2 && (
                <Step2
                    onSave={onSave}
                    onSubmit={onSubmitStep}
                    setDialogSize={setDialogSize}
                    setStep={setStep}
                    values={values}
                />
            )}
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch) => ({
    save: (values, callback, redirect) =>
        dispatch(actions.saveTracking(values, callback, redirect)),
});

CreateTracking.propTypes = {
    isModalOpenInitialState: PropTypes.bool,
    onExited: PropTypes.func,
    onSucccess: PropTypes.func,
    save: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(CreateTracking);
