import { NAME } from './constants';

export const FETCH_LOCATIONS_ZIP_CODE = `${NAME}/FETCH_LOCATIONS_ZIP_CODE`;
export const FETCH_LOCATIONS_ZIP_CODE_FAILURE = `${NAME}/FETCH_LOCATIONS_ZIP_CODE_FAILURE`;
export const FETCH_LOCATIONS_ZIP_CODE_SUCCESS = `${NAME}/FETCH_LOCATIONS_ZIP_CODE_SUCCESS`;

export const GET_CSV_INFO = `${NAME}/GET_CSV_INFO`;
export const GET_CSV_INFO_FAILURE = `${NAME}/GET_CSV_INFO_FAILURE`;
export const GET_CSV_INFO_SUCCESS = `${NAME}/GET_CSV_INFO_SUCCESS`;

export const INITIALIZE = `${NAME}/INITIALIZE`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SET_IS_OPEN = `${NAME}/SET_IS_OPEN`;
