import React from 'react';

import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import SortableItem from './SortableItem';

const SortableList = ({
    currenciesForSelect,
    handleOnChangedCurrency,
    handleOnChangedYear,
    handleOnClickSettings,
    handleOnSelectItem,
    handleOnSelectedProducts,
    indicators,
    onDragEnd,
}) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`indicators-items`}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {indicators.map((item, index) => (
                            <SortableItem
                                currenciesForSelect={currenciesForSelect}
                                handleOnChangedCurrency={
                                    handleOnChangedCurrency
                                }
                                handleOnChangedYear={handleOnChangedYear}
                                handleOnClickSettings={handleOnClickSettings}
                                handleOnSelectItem={handleOnSelectItem}
                                handleOnSelectedProducts={
                                    handleOnSelectedProducts
                                }
                                id={`item-${item.id}`}
                                index={index}
                                item={item}
                                key={`item-${item.id}`}
                            />
                        ))}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

SortableList.propTypes = {
    currenciesForSelect: PropTypes.array,
    handleOnChangedCurrency: PropTypes.func,
    handleOnChangedYear: PropTypes.func,
    handleOnClickSettings: PropTypes.func,
    handleOnSelectItem: PropTypes.func,
    handleOnSelectedProducts: PropTypes.func,
    indicators: PropTypes.array,
    onDragEnd: PropTypes.func,
};

export default SortableList;
