import { CHART_COLORS } from 'common/constants';
import {
    addDate,
    differenceInMinutesDate,
    formatDate,
    parseDate,
    subDate,
} from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import {
    getDailyAvgFields,
    getEnergyAndDemandFields,
    getFieldsInPeriod,
} from 'common/utils/helpers/rates';
import {
    getEconomicChartTooltipMessage,
    getTooltipDateRange,
} from 'common/utils/helpersChart';

const getEconomicChatSeries = (infoPriceConsumtion) => {
    let conceptsDictionary = infoPriceConsumtion.reduce(
        (acc, month, index, array) => {
            let tempConcepts = { ...acc };
            const monthConcepts = month.value || {};
            Object.keys(monthConcepts).forEach((key) => {
                if (!tempConcepts[key]) {
                    let tempArray = Array(array.length).fill(0);
                    tempArray[index] = monthConcepts[key];
                    tempConcepts = { ...tempConcepts, [key]: tempArray };
                }
                tempConcepts[key][index] = monthConcepts[key];
            });
            return tempConcepts;
        },
        {},
    );
    return Object.keys(conceptsDictionary).map((conceptsDictionaryKey) => {
        return {
            name: conceptsDictionaryKey,
            data: conceptsDictionary[conceptsDictionaryKey],
        };
    });
};

const getEnergyChartSeries = (dataKw, dataKwh) => {
    if (dataKw.length > 0) {
        return [
            { name: 'kWh', data: dataKwh },
            { name: 'kW', data: dataKw },
        ];
    }
    return [{ name: 'kWh', data: dataKwh }];
};

const getEnergyChatYaxis = (dataKw, countryCurrencyLocale) => {
    if (dataKw.length > 0) {
        return [
            {
                axisBorder: { show: true, color: '#0073d0' },
                decimalsInFloat: 0,
                labels: {
                    formatter: (val) =>
                        numberFormat(val, {
                            decimals: 0,
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                            unit: 'kWh',
                        }),
                },
                title: { text: 'kWh' },
            },
            {
                axisBorder: { show: true, color: '#f2ae47' },
                decimalsInFloat: 0,
                labels: {
                    formatter: (val) =>
                        numberFormat(val, {
                            decimals: 0,
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                            unit: 'kW',
                        }),
                },
                opposite: true,
                title: { text: 'kW' },
            },
        ];
    }
    return {
        decimalsInFloat: 0,
        labels: {
            formatter: (val) =>
                numberFormat(val, {
                    decimals: 0,
                    locale: countryCurrencyLocale,
                    style: 'decimal',
                    unit: 'kWh',
                }),
        },
    };
};

export const getEnergyChartSettings = (
    dataKw,
    dataKwh,
    categories,
    countryCurrencyLocale,
    monthskwh,
) => {
    return {
        id: 'chartEnergy',
        options: {
            chart: { background: '#ffffff00', toolbar: { show: false } },
            colors: ['#0073d0', '#f2ae47'],
            dataLabels: { enabled: false },
            legend: { show: false },
            tooltip: {
                x: {
                    formatter: (_, { dataPointIndex }) =>
                        getTooltipDateRange({
                            index: dataPointIndex,
                            seriesEnergy: monthskwh,
                        }),
                },
                y: {
                    formatter: (val = 0) =>
                        numberFormat(val.toFixed(0), {
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                        }),
                },
            },
            xaxis: { categories },
            yaxis: getEnergyChatYaxis(dataKw, countryCurrencyLocale),
        },
        series: getEnergyChartSeries(dataKw, dataKwh),
    };
};

export const getEconomicChartSettings = (
    infoPriceConsumtion,
    categories,
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    monthskwh,
) => {
    return {
        id: 'chartEconomic',
        options: {
            chart: {
                background: '#ffffff00',
                stacked: true,
                toolbar: { show: false },
            },
            colors: CHART_COLORS,
            dataLabels: { enabled: false },
            legend: { show: true, showForSingleSeries: true },
            tooltip: {
                x: {
                    formatter: (value, { dataPointIndex, w }) =>
                        getEconomicChartTooltipMessage({
                            consumptionHistory: monthskwh,
                            countryCurrencyIso,
                            countryCurrencyLocale,
                            dataPointIndex,
                            value,
                            w,
                        }),
                },
                y: {
                    formatter: (val) =>
                        numberFormat(val, {
                            currency: countryCurrencyIso,
                            locale: countryCurrencyLocale,
                            style: 'currency',
                        }),
                },
            },
            xaxis: { categories },
            yaxis: {
                decimalsInFloat: 0,
                labels: {
                    formatter: (val) =>
                        numberFormat(val, {
                            currency: countryCurrencyIso,
                            locale: countryCurrencyLocale,
                            style: 'currency',
                            symbol: countryCurrencySymbol,
                        }),
                },
            },
        },
        series: getEconomicChatSeries(infoPriceConsumtion),
    };
};

export const getShowUpdateButton = (finalDate, periodicityType) => {
    const dateToCompareByPeriodicity = addDate(
        parseDate(finalDate, 'dd/MM/yyyy'),
        { days: (periodicityType + 1) * 34 },
    );

    return (
        differenceInMinutesDate(
            new Date(),
            dateToCompareByPeriodicity,
            'minutes',
        ) > 0
    );
};

export const handleStatusSelected = (handleClickStatus) => (item) => {
    if (item.value !== '') {
        handleClickStatus(item);
    }
};

export const buildSummary = ({ data = {}, rateConfiguration } = {}) => {
    const DATE_FORMAT = 'dd/MM/yyyy';

    const months_kWh = data.lines_consumption_energies
        .filter((item) => parseInt(item.type_line) === 0)
        .map((item) => ({ ...item, file: item.url }));

    const months_kW = data.lines_consumption_energies
        .filter((item) => parseInt(item.type_line) === 1)
        .map((item) => ({ ...item, file: item.url }));

    const sortedMonthskWh = months_kWh.sort(
        (a, b) =>
            parseDate(a.initial_date, DATE_FORMAT) -
            parseDate(b.initial_date, DATE_FORMAT),
    );

    const sortedMonthskW = months_kW.sort(
        (a, b) =>
            parseDate(a.initial_date, DATE_FORMAT) -
            parseDate(b.initial_date, DATE_FORMAT),
    );

    const { tiers_energy_distribution } = rateConfiguration || {};

    let summary = [];

    const rateObject = {
        formType: data.rate.form_type,
        isCertified: data.rate.certified,
        name: data.rate.name,
        paymentType: data.rate.payment_type,
    };

    const labelBase = 'Base';
    const labelMiddle = 'Middle';
    const lebelPeak = 'Peak';

    if (data?.gdmth_summary?.length > 0) {
        const gdmthSummary = [...data.gdmth_summary];
        const sortedSummary = gdmthSummary.sort(
            (a, b) =>
                parseDate(a.initial_date, DATE_FORMAT) -
                parseDate(b.initial_date, DATE_FORMAT),
        );

        summary = sortedSummary.map((period, index) => {
            const kWhData = sortedMonthskWh[index];
            if (!kWhData) return null;
            const label = formatDate(
                subDate(parseDate(kWhData.final_date, DATE_FORMAT), {
                    days: data.is_bimonthly ? 30 : 15,
                }),
                'MMM yy',
            );
            const baseConsumption = parseInt(period.base_conpsumption);
            const middleConsumption = parseInt(period.intermediate_consumption);
            const peakConsumption = parseInt(period.peak_consumption);
            const total = baseConsumption + middleConsumption + peakConsumption;

            const fields = getFieldsInPeriod({
                discardValues: true,
                finalDate: kWhData.final_date,
                initialDate: kWhData.initial_date,
                period: {
                    kW: {
                        base: {
                            label: labelBase,
                            placeholder: Math.round(
                                period.base_conpsumption_kw,
                            ).toString(),
                            value: Math.round(period.base_conpsumption_kw),
                        },
                        middle: {
                            label: labelMiddle,
                            placeholder: Math.round(
                                period.intermediate_consumption_kw,
                            ).toString(),
                            value: Math.round(
                                period.intermediate_consumption_kw,
                            ),
                        },
                        peak: {
                            label: lebelPeak,
                            placeholder: Math.round(
                                period.peak_consumption_kw,
                            ).toString(),
                            value: Math.round(period.peak_consumption_kw),
                        },
                    },
                    kWh: {
                        base: {
                            label: labelBase,
                            placeholder: baseConsumption.toString(),
                            value: baseConsumption,
                        },
                        middle: {
                            label: labelMiddle,
                            placeholder: middleConsumption.toString(),
                            value: middleConsumption,
                        },
                        peak: {
                            label: lebelPeak,
                            placeholder: peakConsumption.toString(),
                            value: peakConsumption,
                        },
                    },
                    total: {
                        placeholder: total.toString(),
                        value: total,
                    },
                },
                rate: rateObject,
                tiers_energy_distribution,
            });

            return {
                ...fields,
                file: kWhData.file,
                final_date: kWhData.final_date,
                id: period.id,
                initial_date: kWhData.initial_date,
                label: period.initial_date
                    ? label?.toUpperCase()
                    : 'Último mes',
                power_factor: period.power_factor,
                predicted: period.predicted,
                url_type: kWhData.url_type,
            };
        });
    } else {
        const sortedTierEnergyConsumptions =
            [...(data?.tiers_consumptions || [])].sort(
                (a, b) =>
                    parseDate(a.initial_date, DATE_FORMAT) -
                    parseDate(b.initial_date, DATE_FORMAT),
            ) || [];

        summary = sortedMonthskWh.map((period, index) => {
            const finalDate = parseDate(period.final_date, DATE_FORMAT);
            const label = formatDate(
                subDate(finalDate, { days: data.is_bimonthly ? 30 : 15 }),
                'MMM yy',
            );
            const totalConsumption = period.consumption.toFixed(0);

            const { fields: _fields, total: _total } = getEnergyAndDemandFields(
                {
                    isCertified: rateObject.isCertified,
                    period: sortedTierEnergyConsumptions[index],
                    tiers_energy_distribution,
                },
            );

            const total = _total || totalConsumption;

            const fields = getFieldsInPeriod({
                discardValues: true,
                finalDate: period.final_date,
                initialDate: period.initial_date,
                tmp_kW: sortedMonthskW?.[index]?.consumption,
                period: {
                    ..._fields,
                    total: { placeholder: `${total}`, value: total },
                },
                rate: rateObject,
                tiers_energy_distribution,
            });

            return {
                dailyAvg: getDailyAvgFields(
                    total,
                    period.final_date,
                    period.initial_date,
                ),
                file: period.file,
                final_date: period.final_date,
                id: period.id,
                initial_date: period.initial_date,
                ...fields,
                label: period.initial_date ? label : 'Último mes',
                power_factor: period.power_factor,
                predicted: period.predicted,
                url_type: period.url_type,
            };
        });
    }

    const filteredSummaryData = summary.filter((item) => item !== null);

    const sortedSummary = filteredSummaryData
        .sort(
            (a, b) =>
                parseDate(b.initial_date, DATE_FORMAT) -
                parseDate(a.initial_date, DATE_FORMAT),
        )
        .slice(0, 12);

    return sortedSummary;
};
