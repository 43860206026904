import { ONE_TO_MANY } from '../constants';

import BaseModel from './BaseModel';
export default class ProjectCommercialOffer extends BaseModel {}

ProjectCommercialOffer.modelName = 'ProjectCommercialOffer';
ProjectCommercialOffer.prepareFields({
    branchOffice: 'branch_office',
    createdAt: 'created_at',
    id: 'id',
    isFavorite: 'favorite',
    name: 'name',
    projectId: 'project',
    commercialOfferId: {
        key: 'commercial_offer_id',
        relationType: ONE_TO_MANY,
        options: {
            to: 'CommercialOffer',
            as: 'commercialOffer',
            relatedName: 'projectCommercialOffers',
        },
    },
});
