import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

const getTierFieldsValidation = (values, { REQUIRED_TEXT }) => {
    const keys = Object.keys(values || {});
    if (!keys.length) return null;
    const validations = {};

    for (const key of keys)
        validations[key] = object().shape({
            value: number().required(REQUIRED_TEXT),
            tier: number().required(REQUIRED_TEXT),
        });

    return object().shape(validations);
};

export default (values) => {
    const { REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        next_rate: string().required(REQUIRED_TEXT),
        compensation_scheme: string().required(REQUIRED_TEXT),
        next_contracted_demand: getTierFieldsValidation(
            values?.next_contracted_demand,
            { REQUIRED_TEXT },
        ),
    });
};
