import { NAME } from './constants';

export const DELETE_DOCUMENT = `${NAME}/DELETE_DOCUMENT`;
export const DELETE_DOCUMENT_FAILURE = `${NAME}/DELETE_DOCUMENT_FAILURE`;
export const DELETE_DOCUMENT_SUCCESS = `${NAME}/DELETE_DOCUMENT_SUCCESS`;

export const FETCH_DOCUMENTS = `${NAME}/FETCH_DOCUMENTS`;
export const FETCH_DOCUMENTS_FAILURE = `${NAME}/FETCH_DOCUMENTS_FAILURE`;
export const FETCH_DOCUMENTS_SUCCESS = `${NAME}/FETCH_DOCUMENTS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET = `${NAME}/RESET`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SET_ELEMENT_TO_DELETE = `${NAME}/SET_ELEMENT_TO_DELETE`;

export const SHOW_MODAL = `${NAME}/SHOW_MODAL`;

export const UPDATE_STATUS_PROJECT = `${NAME}/UPDATE_STATUS_PROJECT`;
export const UPDATE_STATUS_PROJECT_FAILURE = `${NAME}/UPDATE_STATUS_PROJECT_FAILURE`;
export const UPDATE_STATUS_PROJECT_SUCCESS = `${NAME}/UPDATE_STATUS_PROJECT_SUCCESS`;

export const UPLOAD_DOCUMENT = `${NAME}/UPLOAD_DOCUMENT`;
export const UPLOAD_DOCUMENT_FAILURE = `${NAME}/UPLOAD_DOCUMENT_FAILURE`;
export const UPLOAD_DOCUMENT_SUCCESS = `${NAME}/UPLOAD_DOCUMENT_SUCCESS`;

export const FETCH_DOCUMENTS_PROJECT_TYPE = `${NAME}/FETCH_DOCUMENTS_PROJECT_TYPE`;
export const FETCH_DOCUMENTS_PROJECT_TYPE_FAILURE = `${NAME}/FETCH_DOCUMENTS_PROJECT_TYPE_FAILURE`;
export const FETCH_DOCUMENTS_PROJECT_TYPE_SUCCESS = `${NAME}/FETCH_DOCUMENTS_PROJECT_TYPE_SUCCESS`;

export const OPEN_CONFIRM_MODAL = `${NAME}/OPEN_CONFIRM_MODAL`;
export const SET_DATA_CONFIRM_MODAL = `${NAME}/SET_DATA_CONFIRM_MODAL`;
