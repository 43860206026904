import { createSlice } from '@reduxjs/toolkit';

import {
    CLEAR_DOWNLOAD_CSV,
    DOWNLOAD_CSV,
    DOWNLOAD_CSV_FAILURE,
    DOWNLOAD_CSV_SUCCESS,
    DOWNLOAD_HISTORY,
    DOWNLOAD_HISTORY_FAILURE,
    DOWNLOAD_HISTORY_SUCCESS,
    FETCH_CONSUMPTION_ENERGY,
    FETCH_CONSUMPTION_ENERGY_FAILURE,
    FETCH_CONSUMPTION_ENERGY_NO_ANIMATION,
    FETCH_CONSUMPTION_ENERGY_SUCCESS,
    FETCH_SCHEDULE_RATE_CONFIGURATION,
    FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE,
    FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS,
    FETCH_STATUS_PROJECT,
    FETCH_STATUS_PROJECT_FAILURE,
    FETCH_STATUS_PROJECT_SUCCESS,
    SELECT_PROJECT,
    UPDATE_CONSUMPTION_ENERGY,
    UPDATE_CONSUMPTION_ENERGY_FAILURE,
    UPDATE_CONSUMPTION_ENERGY_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    consumptionEnergy: {
        isFetching: false,
        data: null,
        errors: [],
    },
    downloadCSV: {
        isFetching: false,
        data: null,
        errors: [],
    },
    downloadHistory: {
        isFetching: false,
        data: null,
        error: null,
    },
    scheduleRateConfiguration: { data: {}, errors: null, isFetching: false },
    selectedProject: {},
    statusProject: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    updateConsumptionEnergy: {
        isFetching: false,
        data: null,
        error: null,
    },
};

const projectConsumptionCardSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DOWNLOAD_CSV]: (state) => {
            state.downloadCSV = {
                ...state.downloadCSV,
                errors: [],
                isFetching: true,
            };
        },
        [DOWNLOAD_CSV_FAILURE]: (state, action) => {
            state.downloadCSV = {
                ...state.downloadCSV,
                errors: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_CSV_SUCCESS]: (state, action) => {
            state.downloadCSV = {
                ...state.downloadCSV,
                data: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_HISTORY]: (state) => {
            state.downloadHistory.isFetching = true;
        },
        [DOWNLOAD_HISTORY_FAILURE]: (state, action) => {
            state.downloadHistory = {
                ...state.downloadHistory,
                error: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_HISTORY_SUCCESS]: (state, action) => {
            state.downloadHistory = {
                ...state.downloadHistory,
                data: action.payload,
                error: null,
                isFetching: false,
            };
        },
        [CLEAR_DOWNLOAD_CSV]: (state) => {
            state.downloadCSV = {
                ...state.downloadCSV,
                data: null,
                isFetching: false,
            };
        },
        [FETCH_CONSUMPTION_ENERGY]: (state) => {
            state.consumptionEnergy = {
                ...state.consumptionEnergy,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CONSUMPTION_ENERGY_NO_ANIMATION]: (state) => {
            state.consumptionEnergy = {
                ...state.consumptionEnergy,
                errors: [],
                isFetching: false,
            };
        },
        [FETCH_CONSUMPTION_ENERGY_FAILURE]: (state, action) => {
            state.consumptionEnergy = {
                ...state.consumptionEnergy,
                data: null,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONSUMPTION_ENERGY_SUCCESS]: (state, action) => {
            state.consumptionEnergy = {
                ...state.consumptionEnergy,
                data: action.payload,
                isFetching: false,
            };
        },
        [SELECT_PROJECT]: (state, action) => {
            state.selectedProject = action.payload;
            state.downloadHistory.isFetching = false;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION]: (state) => {
            state.scheduleRateConfiguration = {
                ...state.scheduleRateConfiguration,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE]: (state, action) => {
            state.scheduleRateConfiguration = {
                ...state.scheduleRateConfiguration,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS]: (state, action) => {
            state.scheduleRateConfiguration = {
                ...state.scheduleRateConfiguration,
                errors: null,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_STATUS_PROJECT]: (state) => {
            state.statusProject.isLoading = true;
        },
        [FETCH_STATUS_PROJECT_FAILURE]: (state, action) => {
            state.statusProject = {
                ...state.statusProject,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_STATUS_PROJECT_SUCCESS]: (state, action) => {
            state.statusProject = {
                ...state.statusProject,
                data: action.payload,
                isEmpty: action.payload.length === 0,
                isLoading: false,
            };
        },
        [UPDATE_CONSUMPTION_ENERGY]: (state) => {
            state.updateConsumptionEnergy.isFetching = true;
        },
        [UPDATE_CONSUMPTION_ENERGY_FAILURE]: (state, action) => {
            state.updateConsumptionEnergy = {
                ...state.updateConsumptionEnergy,
                error: action.payload,
                isFetching: false,
            };
        },
        [UPDATE_CONSUMPTION_ENERGY_SUCCESS]: (state, action) => {
            state.updateConsumptionEnergy = {
                ...state.updateConsumptionEnergy,
                data: action.payload,
                error: null,
                isFetching: false,
            };
        },
    },
});

export const projectConsumptionCardActions =
    projectConsumptionCardSlice.actions;

export default projectConsumptionCardSlice.reducer;
