import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    BottomActions,
    Box,
    Button,
    Grid,
    UiDialog as Dialog,
    Typography,
    Alert,
} from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import { doesRateRequireDivision } from 'common/utils/helpers/rates';

import * as actions from '../actions';
import * as selectors from '../selectors';

const MissingFieldsModal = ({
    control,
    fetchLocationsByZipCode,
    getValues,
    groupedRatesForSelect,
    isFetchingLocationsByZip,
    isFetchingRates,
    isOpen,
    ratesDictionary,
    setModalIsOpen,
    setValue,
}) => {
    const { t } = useTranslation();
    const [hasErrors, setHasErrors] = useState(false);
    const [fields, setFields] = useState({ rate: false, zip_code: false });

    useEffect(() => {
        if (isOpen)
            setFields({
                rate: !getValues('rate'),
                zip_code: !getValues('zip_code'),
            });
    }, [isOpen]);

    const handleOnClick = () => {
        const zipCode = getValues('zip_code');
        const rateId = getValues('rate');

        const selectedRate = ratesDictionary?.[rateId];

        const zipCodeRequired = doesRateRequireDivision(
            selectedRate?.name,
            selectedRate?.isCertified,
        );

        if (!selectedRate?.id || (zipCodeRequired && !zipCode)) {
            setHasErrors(true);
            return;
        }

        if (zipCode) {
            setValue('political_division1', null);
            setValue('political_division2', null);
            setValue('rate_division', null);
        }

        fetchLocationsByZipCode({
            calculateConsumptionWithCsvData: true,
            getValues,
            rate: selectedRate,
            setValue,
            value: zipCode,
        });

        setModalIsOpen(false);
    };
    const onCancel = () => setModalIsOpen(false);

    const rateOptions = isEmpty(groupedRatesForSelect)
        ? []
        : Object.values(groupedRatesForSelect);

    const disabled = isFetchingLocationsByZip;

    return (
        <Dialog
            onClose={(_, reason) => {
                if (reason && reason === 'backdropClick') return;
                onCancel();
            }}
            open={isOpen}
            size="sm"
            title={t('Missing fields')}
        >
            <Typography variant="subtitle1">
                {t(
                    'The following fields are required to continue with the process',
                )}
            </Typography>

            <Grid container mb={2} mt={1}>
                {fields.rate && (
                    <Grid item xs={18}>
                        <ReactHookFormSelect2
                            control={control}
                            disabled={isFetchingRates || disabled}
                            label={t('Rate')}
                            name="rate"
                            options={rateOptions}
                            variant="filled"
                        />
                    </Grid>
                )}

                {fields.zip_code && (
                    <Grid item xs={18}>
                        <ReactHookFormInput
                            control={control}
                            disabled={disabled}
                            fullWidth
                            label={t('Zip code')}
                            name="zip_code"
                            variant="filled"
                        />
                    </Grid>
                )}
            </Grid>
            {hasErrors && (
                <Alert severity="warning">
                    {t('Complete all fields with the correct information')}
                </Alert>
            )}

            <BottomActions sx={{ mt: 2 }}>
                <Box
                    display="flex"
                    gap="16px"
                    mt={2}
                    width="100%"
                    justifyContent="flex-end"
                >
                    <Button
                        disabled={disabled}
                        onClick={onCancel}
                        variant="default"
                    >
                        {t('Cancel')}
                    </Button>

                    <Button disabled={disabled} onClick={handleOnClick}>
                        {t('Continue')}
                    </Button>
                </Box>
            </BottomActions>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    groupedRatesForSelect: selectors.getGroupedRatesForSelect,
    isFetchingLocationsByZip: selectors.getIsFetchingLocationsByZipCode,
    isFetchingRates: selectors.getIsFetchingRates,
    isOpen: selectors.getIsOpenMissingFieldsModal,
    ratesDictionary: selectors.getRatesDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLocationsByZipCode: (config = {}) =>
        dispatch(actions.fetchLocationsByZipCode(config)),
    setModalIsOpen: (isOpen) =>
        dispatch(actions.setIsOpenMissingFieldsModal(isOpen)),
});

MissingFieldsModal.propTypes = {
    control: PropTypes.object,
    fetchLocationsByZipCode: PropTypes.func,
    getValues: PropTypes.func,
    groupedRatesForSelect: PropTypes.object,
    isFetchingLocationsByZip: PropTypes.bool,
    isFetchingRates: PropTypes.bool,
    isOpen: PropTypes.bool,
    ratesDictionary: PropTypes.object,
    setModalIsOpen: PropTypes.func,
    setValue: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MissingFieldsModal);
