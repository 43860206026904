import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import AccessRestrictedBanner from '../analytics/components/AccessRestrictedBanner';

const Container = ({ canView }) => {
    if (!canView) return null;
    return (
        <AccessRestrictedBanner>
            <Grid container sx={{ alignItems: 'center', height: '100%' }}>
                <Grid item sx={{ height: '100%' }} xs={18}>
                    <iframe
                        src="https://lookerstudio.google.com/embed/reporting/ec774983-96b4-42ca-b600-5d4f96f2da63"
                        style={{ border: 0, height: '100%', width: '100%' }}
                    />
                </Grid>
            </Grid>
        </AccessRestrictedBanner>
    );
};

Container.propTypes = {
    canView: PropTypes.bool,
};

export default withPermissions(PERMISSION_LIST.CRE_PERMISSION)(Container);
