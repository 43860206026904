import React from 'react';

import { useGridApiContext } from '@mui/x-data-grid';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';

import IntlNumberInputField from 'common/components/IntlNumberInputField';
import { calculateFinalCost } from 'common/utils/helpers';

const CustomMarginEdit = (props) => {
    const { id, field, row, value } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue) => {
        const cost = row?.cost;
        let margin = newValue;
        if (isNull(margin)) margin = 0;
        if (parseFloat(margin) >= 100) {
            margin = 99;
        }
        const finalCost = calculateFinalCost(cost, margin);
        apiRef.current.setEditCellValue({ id, field, value: margin });
        apiRef.current.setEditCellValue({
            id,
            field: 'final_cost',
            value: finalCost,
        });
    };
    return (
        <IntlNumberInputField
            allowNegativeValue={false}
            append="%"
            max={99}
            min={0}
            onChange={handleValueChange}
            step="any"
            sx={{
                width: 'calc(100% - 10px)',
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: 0,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '0 !important',
                },
            }}
            value={value}
        />
    );
};

CustomMarginEdit.propTypes = {
    field: PropTypes.string,
    id: PropTypes.string,
    row: PropTypes.object,
    value: PropTypes.number,
};

export default CustomMarginEdit;
