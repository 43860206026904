import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_OFFER_SMART_DOCUMENT,
    DELETE_OFFER_SMART_DOCUMENT_FAILURE,
    DELETE_OFFER_SMART_DOCUMENT_SUCCESS,
    DOWNLOAD_DATASHEET,
    DOWNLOAD_DATASHEET_FAILURE,
    DOWNLOAD_DATASHEET_SUCCESS,
    DOWNLOAD_OFFER_CSV,
    DOWNLOAD_OFFER_CSV_FAILURE,
    DOWNLOAD_OFFER_CSV_SUCCESS,
    DOWNLOAD_OFFER_HOURLY_CSV,
    DOWNLOAD_OFFER_HOURLY_CSV_FAILURE,
    DOWNLOAD_OFFER_HOURLY_CSV_SUCCESS,
    DOWNLOAD_OFFER_PDF,
    DOWNLOAD_OFFER_PDF_FAILURE,
    DOWNLOAD_OFFER_PDF_SUCCESS,
    DOWNLOAD_OFFER_SMART_DOCUMENT_CSV,
    DOWNLOAD_OFFER_SMART_DOCUMENT_CSV_FAILURE,
    DOWNLOAD_OFFER_SMART_DOCUMENT_CSV_SUCCESS,
    DOWNLOAD_OFFER_XLS,
    DOWNLOAD_OFFER_XLS_FAILURE,
    DOWNLOAD_OFFER_XLS_SUCCESS,
    DUPLICATE_OFFER_SMART_DOCUMENT,
    DUPLICATE_OFFER_SMART_DOCUMENT_FAILURE,
    DUPLICATE_OFFER_SMART_DOCUMENT_SUCCESS,
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    FETCH_DOCUMENT_TEMPLATES,
    FETCH_DOCUMENT_TEMPLATES_FAILURE,
    FETCH_DOCUMENT_TEMPLATES_SUCCESS,
    FETCH_FINANCING_TEMPLATE,
    FETCH_FINANCING_TEMPLATE_SUCCESS,
    FETCH_FINANCING_TEMPLATE_FAILURE,
    FETCH_OFFER_DATASHEETS,
    FETCH_OFFER_DATASHEETS_FAILURE,
    FETCH_OFFER_DATASHEETS_SUCCESS,
    FETCH_OFFER_SMART_DOCUMENTS,
    FETCH_OFFER_SMART_DOCUMENTS_FAILURE,
    FETCH_OFFER_SMART_DOCUMENTS_SUCCESS,
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_OFFER_SMART_DOCUMENT_FORM,
    INITIALIZE_SUCCESS,
    RENAME_OFFER_SMART_DOCUMENT,
    RENAME_OFFER_SMART_DOCUMENT_FAILURE,
    RENAME_OFFER_SMART_DOCUMENT_SUCCESS,
    RESET,
    RESET_OFFER_SMART_DOCUMENT_FORM,
    REVOKE_APPROVAL,
    REVOKE_APPROVAL_FAILURE,
    REVOKE_APPROVAL_SUCCESS,
    SAVE_OFFER_SMART_DOCUMENT,
    SAVE_OFFER_SMART_DOCUMENT_FAILURE,
    SAVE_OFFER_SMART_DOCUMENT_SUCCESS,
    SET_IS_OPEN_OFFER_SMART_DOCUMENT_MODAL,
    SET_OFFER_SMART_DOCUMENT_FORM_MODE,
    SET_SELECTED_DOCUMENT,
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_FAILURE,
    UPDATE_TEMPLATE_SUCCESS,
} from './actionTypes';
import { NAME, OFFER_SMART_DOCUMENT_FORM_CREATE_MODE } from './constants';

const INITIAL_STATE = {
    deleteOfferSmartDocument: {
        errors: [],
        isDeleting: false,
    },
    documentTemplates: {
        data: [],
        errors: [],
        isFetching: false,
    },
    downloadDatasheet: {
        errors: [],
        file: null,
        isFetching: false,
    },
    downloadOfferCsv: {
        errors: [],
        file: null,
        isFetching: false,
    },
    downloadOfferHourlyCsv: {
        errors: [],
        file: null,
        isFetching: false,
    },
    downloadOfferPdf: {
        errors: [],
        file: null,
        isFetching: false,
    },
    downloadOfferSmartDocumentCsv: {
        errors: [],
        file: null,
        isFetching: false,
    },
    downloadOfferXLS: {
        errors: [],
        file: null,
        isFetching: false,
    },
    duplicateOfferSmartDocument: {
        errors: [],
        isDuplicating: false,
    },
    fetchCustomTemplate: {
        data: null,
        errors: [],
        isFetching: false,
    },
    financingTemplate: {
        data: null,
        errors: [],
        isFetching: false,
    },
    initializing: false,
    isOpenOfferSmartDocumentModal: false,
    offerDatasheets: {
        data: [],
        errors: [],
        isFetching: false,
    },
    offerSmartDocumentFormMode: OFFER_SMART_DOCUMENT_FORM_CREATE_MODE,
    offerSmartDocumentInitialValues: {
        commercial_offer: '',
        custom_offer_template: '',
        id: null,
        name: '',
        project: '',
    },
    offerSmartDocuments: {
        data: [],
        errors: [],
        isFetching: false,
    },
    renameOfferSmartDocument: {
        errors: [],
        isRenaming: false,
    },
    revokeApproval: {
        data: null,
        errors: [],
        isUpdating: false,
    },
    saveOfferSmartDocument: {
        errors: [],
        isSaving: false,
    },
    selectedDocument: {
        id: null,
        name: '',
        type: 0,
    },
    updateCustomTemplate: {
        errors: [],
        isUpdating: false,
    },
};

const multiTemplateReviewSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_OFFER_SMART_DOCUMENT]: (state) => {
            state.deleteOfferSmartDocument = {
                ...state.deleteOfferSmartDocument,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_OFFER_SMART_DOCUMENT_FAILURE]: (state, action) => {
            state.deleteOfferSmartDocument = {
                ...state.deleteOfferSmartDocument,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_OFFER_SMART_DOCUMENT_SUCCESS]: (state) => {
            state.deleteOfferSmartDocument.isDeleting = false;
        },
        [DOWNLOAD_DATASHEET]: (state) => {
            state.downloadDatasheet = {
                errors: [],
                file: null,
                isFetching: false,
            };
        },
        [DOWNLOAD_DATASHEET_FAILURE]: (state, action) => {
            state.downloadOfferCsv = {
                ...state.downloadOfferCsv,
                errors: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_DATASHEET_SUCCESS]: (state, action) => {
            state.downloadDatasheet = {
                ...state.downloadDatasheet,
                file: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_OFFER_CSV]: (state) => {
            state.downloadOfferCsv = {
                ...state.downloadOfferCsv,
                errors: [],
                file: null,
                isFetching: true,
            };
        },
        [DOWNLOAD_OFFER_CSV_FAILURE]: (state, action) => {
            state.downloadOfferCsv = {
                ...state.downloadOfferCsv,
                errors: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_OFFER_CSV_SUCCESS]: (state, action) => {
            state.downloadOfferCsv = {
                ...state.downloadOfferCsv,
                file: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_OFFER_HOURLY_CSV]: (state) => {
            state.downloadOfferHourlyCsv = {
                ...state.downloadOfferHourlyCsv,
                errors: [],
                file: null,
                isFetching: true,
            };
        },
        [DOWNLOAD_OFFER_HOURLY_CSV_FAILURE]: (state, action) => {
            state.downloadOfferHourlyCsv = {
                ...state.downloadOfferHourlyCsv,
                errors: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_OFFER_HOURLY_CSV_SUCCESS]: (state, action) => {
            state.downloadOfferHourlyCsv = {
                ...state.downloadOfferHourlyCsv,
                file: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_OFFER_PDF]: (state) => {
            state.downloadOfferPdf = {
                ...state.downloadOfferPdf,
                errors: [],
                file: null,
                isFetching: true,
            };
        },
        [DOWNLOAD_OFFER_PDF_FAILURE]: (state, action) => {
            state.downloadOfferPdf = {
                ...state.downloadOfferPdf,
                errors: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_OFFER_PDF_SUCCESS]: (state, action) => {
            state.downloadOfferPdf = {
                ...state.downloadOfferPdf,
                file: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_OFFER_SMART_DOCUMENT_CSV]: (state) => {
            state.downloadOfferSmartDocumentCsv = {
                ...state.downloadOfferSmartDocumentCsv,
                errors: [],
                file: null,
                isFetching: true,
            };
        },
        [DOWNLOAD_OFFER_SMART_DOCUMENT_CSV_FAILURE]: (state, action) => {
            state.downloadOfferSmartDocumentCsv = {
                ...state.downloadOfferSmartDocumentCsv,
                errors: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_OFFER_SMART_DOCUMENT_CSV_SUCCESS]: (state, action) => {
            state.downloadOfferSmartDocumentCsv = {
                ...state.downloadOfferSmartDocumentCsv,
                file: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_OFFER_XLS]: (state) => {
            state.downloadOfferXLS = {
                ...state.downloadOfferXLS,
                errors: [],
                file: null,
                isFetching: true,
            };
        },
        [DOWNLOAD_OFFER_XLS_FAILURE]: (state, action) => {
            state.downloadOfferXLS = {
                ...state.downloadOfferXLS,
                errors: action.payload,
                isFetching: false,
            };
        },
        [DOWNLOAD_OFFER_XLS_SUCCESS]: (state, action) => {
            state.downloadOfferXLS = {
                ...state.downloadOfferXLS,
                file: action.payload,
                isFetching: false,
            };
        },
        [DUPLICATE_OFFER_SMART_DOCUMENT]: (state) => {
            state.duplicateOfferSmartDocument = {
                ...state.duplicateOfferSmartDocument,
                errors: [],
                isDuplicating: true,
            };
        },
        [DUPLICATE_OFFER_SMART_DOCUMENT_FAILURE]: (state, action) => {
            state.duplicateOfferSmartDocument = {
                ...state.duplicateOfferSmartDocument,
                errors: action.payload,
                isDuplicating: false,
            };
        },
        [DUPLICATE_OFFER_SMART_DOCUMENT_SUCCESS]: (state) => {
            state.duplicateOfferSmartDocument.isDuplicating = false;
        },
        [FETCH_CUSTOM_TEMPLATE]: (state) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: null,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_FAILURE]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_SUCCESS]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOCUMENT_TEMPLATES]: (state) => {
            state.documentTemplates = {
                ...state.documentTemplates,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_DOCUMENT_TEMPLATES_FAILURE]: (state, action) => {
            state.documentTemplates = {
                ...state.documentTemplates,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOCUMENT_TEMPLATES_SUCCESS]: (state, action) => {
            state.documentTemplates = {
                ...state.documentTemplates,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCING_TEMPLATE]: (state) => {
            state.financingTemplate = {
                ...state.financingTemplate,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FINANCING_TEMPLATE_FAILURE]: (state, action) => {
            state.financingTemplate = {
                ...state.financingTemplate,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCING_TEMPLATE_SUCCESS]: (state, action) => {
            state.financingTemplate = {
                ...state.financingTemplate,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_DATASHEETS]: (state) => {
            state.offerDatasheets = {
                ...state.offerDatasheets,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_DATASHEETS_FAILURE]: (state, action) => {
            state.offerDatasheets = {
                ...state.offerDatasheets,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_DATASHEETS_SUCCESS]: (state, action) => {
            state.offerDatasheets = {
                ...state.offerDatasheets,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_SMART_DOCUMENTS]: (state) => {
            state.offerSmartDocuments = {
                ...state.offerSmartDocuments,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_SMART_DOCUMENTS_FAILURE]: (state, action) => {
            state.offerSmartDocuments = {
                ...state.offerSmartDocuments,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_SMART_DOCUMENTS_SUCCESS]: (state, action) => {
            state.offerSmartDocuments = {
                ...state.offerSmartDocuments,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE]: (state) => {
            state.initializing = true;
        },
        [INITIALIZE_FAILURE]: (state) => {
            state.initializing = false;
        },
        [INITIALIZE_SUCCESS]: (state) => {
            state.initializing = false;
        },
        [INITIALIZE_OFFER_SMART_DOCUMENT_FORM]: (state, action) => {
            state.offerSmartDocumentInitialValues = {
                ...state.offerSmartDocumentInitialValues,
                ...action.payload,
            };
        },
        [SET_IS_OPEN_OFFER_SMART_DOCUMENT_MODAL]: (state, action) => {
            state.isOpenOfferSmartDocumentModal = action.payload;
        },
        [UPDATE_TEMPLATE]: (state) => {
            state.updateCustomTemplate = {
                ...state.updateCustomTemplate,
                errors: [],
                isUpdating: true,
            };
        },
        [UPDATE_TEMPLATE_FAILURE]: (state, action) => {
            state.updateCustomTemplate = {
                ...state.updateCustomTemplate,
                errors: action.payload,
                isUpdating: false,
            };
        },
        [UPDATE_TEMPLATE_SUCCESS]: (state) => {
            state.updateCustomTemplate.isUpdating = false;
        },
        [RENAME_OFFER_SMART_DOCUMENT]: (state) => {
            state.renameOfferSmartDocument = {
                ...state.renameOfferSmartDocument,
                errors: [],
                isRenaming: true,
            };
        },
        [RENAME_OFFER_SMART_DOCUMENT_FAILURE]: (state, action) => {
            state.renameOfferSmartDocument = {
                ...state.renameOfferSmartDocument,
                errors: action.payload,
                isRenaming: false,
            };
        },
        [RENAME_OFFER_SMART_DOCUMENT_SUCCESS]: (state) => {
            state.renameOfferSmartDocument.isRenaming = false;
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_OFFER_SMART_DOCUMENT_FORM]: (state) => {
            state.offerSmartDocumentInitialValues =
                INITIAL_STATE.offerSmartDocumentInitialValues;
        },
        [REVOKE_APPROVAL]: (state) => {
            state.revokeApproval = {
                ...state.revokeApproval,
                errors: [],
                isUpdating: true,
            };
        },
        [REVOKE_APPROVAL_FAILURE]: (state, action) => {
            state.revokeApproval = {
                ...state.revokeApproval,
                errors: action.payload,
                isUpdating: false,
            };
        },
        [REVOKE_APPROVAL_SUCCESS]: (state) => {
            state.revokeApproval.isUpdating = false;
        },
        [SAVE_OFFER_SMART_DOCUMENT]: (state) => {
            state.saveOfferSmartDocument = {
                ...state.saveOfferSmartDocument,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_OFFER_SMART_DOCUMENT_FAILURE]: (state, action) => {
            state.saveOfferSmartDocument = {
                ...state.saveOfferSmartDocument,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_OFFER_SMART_DOCUMENT_SUCCESS]: (state) => {
            state.saveOfferSmartDocument.isSaving = false;
        },
        [SET_OFFER_SMART_DOCUMENT_FORM_MODE]: (state, action) => {
            state.offerSmartDocumentFormMode = action.payload;
        },
        [SET_SELECTED_DOCUMENT]: (state, action) => {
            state.selectedDocument = action.payload;
        },
    },
});

export const multiTemplateReviewActions = multiTemplateReviewSlice.actions;

export default multiTemplateReviewSlice.reducer;
