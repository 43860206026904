import { ONBOARDING_STEPS } from '../constants';

import resetErrors from './resetErrors';
import setCurrentStep from './setCurrentStep';
import updateSection from './updateSection';

export default (values, callback) => (dispatch) => {
    const { panel_brand, panels } = values;
    localStorage.setItem(
        'tempPanelSettings',
        JSON.stringify({ panel_brand, panels }),
    );
    dispatch(resetErrors());
    dispatch(setCurrentStep(values?.step + 1));
    dispatch(updateSection(ONBOARDING_STEPS.INVERTERS));
    if (callback) callback(ONBOARDING_STEPS.INVERTERS);
};
