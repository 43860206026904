import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from './actions';
import CardForm from './components/CardForm';
import * as selectors from './selectors';

const Container = ({
    fetchAlerts,
    fetchAlertsData,
    initialValues,
    initializeAlertsModalForm,
    isFetchingAlerts,
    modalIsOpen,
    rate,
    resetModalForm,
    saveAlerts,
    setModalIsOpen,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (modalIsOpen && rate?.id) fetchAlerts(rate);
    }, [modalIsOpen]);

    useEffect(() => {
        initializeAlertsModalForm(fetchAlertsData, rate);
    }, [fetchAlertsData]);

    return (
        <Dialog
            onClose={() => setModalIsOpen(false)}
            onExited={() => resetModalForm()}
            open={modalIsOpen}
            size="lg"
            title={t('Alert', { count: 2 })}
        >
            <CardForm
                disabled={isFetchingAlerts}
                initialValues={initialValues}
                rate={rate}
                saveAlerts={saveAlerts}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    fetchAlertsData: selectors.getFetchAlertsData,
    isFetchingAlerts: selectors.getIsFetchingAlerts,
    initialValues: selectors.getAlertsModalInitialValues,
    modalIsOpen: selectors.getAlertsModalIsOpen,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAlerts: (rate) => dispatch(actions.fetchAlerts(rate)),
    initializeAlertsModalForm: (data, rate) =>
        dispatch(actions.initializeAlertsModalForm(data, rate)),
    resetModalForm: () => dispatch(actions.resetAlertsModalForm()),
    saveAlerts: (data, rate) => dispatch(actions.saveAlerts(data, rate)),
    setModalIsOpen: (value) => dispatch(actions.setAlertsModalsOpen(value)),
});

Container.propTypes = {
    fetchAlerts: PropTypes.func,
    fetchAlertsData: PropTypes.array,
    initialValues: PropTypes.object,
    initializeAlertsModalForm: PropTypes.func,
    isFetchingAlerts: PropTypes.bool,
    modalIsOpen: PropTypes.bool,
    rate: PropTypes.object,
    resetModalForm: PropTypes.func,
    saveAlerts: PropTypes.func,
    setModalIsOpen: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
