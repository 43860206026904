import { array, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        reasignAgents: array().of(
            object({
                agent: string().required(REQUIRED_TEXT).nullable(),
            }),
        ),
    });
};
