import React, { useContext } from 'react';

import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import AccordionCard from 'common/components/accordions/AccordionCard';
import IconPanelSeeding from 'common/components/icons/IconPanelSeeding';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { LoadingContext } from 'common/utils/contexts';
import { getDefaultProjectLocation } from 'common/utils/helpers';

import { getCompanyPositionLatLng } from '../companyGeneral/selectors';
import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';
import { getPositionData } from '../record/selectors';

import * as actions from './actions';
import PanelLayoutSection from './components/PanelLayoutSection';
import { goToPanelLayout } from './helpers';
import * as selectors from './selectors';

const Container = ({
    canModify,
    companyPositionLatLng,
    contactPosition,
    createParentFieldSegments,
    haveParentFieldSegment,
    history,
    isFetchingOfferProducts,
    isFetchingParentFieldSegment,
    isLoading,
    isLocked = false,
    isSaving,
    parentFieldSegmentCounters,
    prepareDeleteParentFieldSegments,
    projectId,
    projectLocation,
    proposalId,
    segments,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    const handleCreateParentFieldSegments = () => {
        loadingContext.openLoading(t('Creating').concat('...'));
        createParentFieldSegments(proposalId, projectLocation, () => {
            loadingContext.closeLoading();
        });
    };

    const handleDeleteParentFieldSegments = () =>
        prepareDeleteParentFieldSegments(proposalId);

    const isLoadingForm =
        isFetchingOfferProducts ||
        isFetchingParentFieldSegment ||
        isLoading ||
        isSaving;

    const isLockedForm = isLoadingForm || isLocked;

    return (
        <AccordionCard
            cardId="proposal_generator_panel_seeding_card"
            defaultExpanded={true}
            isLoading={isLoadingForm}
            svgIcon={<IconPanelSeeding color={theme.palette.primary.main} />}
            title={t('PV Layout')}
        >
            <PanelLayoutSection
                canModify={canModify}
                defaultProjectLocation={getDefaultProjectLocation(
                    contactPosition,
                    companyPositionLatLng,
                )}
                goToPanelLayout={goToPanelLayout(history, proposalId)}
                handleCreateParentFieldSegments={
                    handleCreateParentFieldSegments
                }
                handleDeleteParentFieldSegments={
                    handleDeleteParentFieldSegments
                }
                haveParentFieldSegment={haveParentFieldSegment}
                isLocked={isLockedForm}
                parentFieldSegmentCounters={parentFieldSegmentCounters}
                projectId={projectId}
                projectLocation={projectLocation}
                proposalId={proposalId}
                segments={segments}
            />
        </AccordionCard>
    );
};

const mapStateToProps = createStructuredSelector({
    companyPositionLatLng: getCompanyPositionLatLng,
    contactPosition: getPositionData,
    haveParentFieldSegment: selectors.getHaveParentFieldSegment,
    isFetchingOfferProducts:
        proposalGeneratorQuoteSelectors.getIsFetchingOfferProducts,
    isFetchingParentFieldSegment: selectors.getIsFetchingParentFieldSegment,
    isSaving: selectors.getIsSaving,
    parentFieldSegmentCounters: selectors.getParentFieldSegmentCounters,
    segments: selectors.getSegments,
});

const mapDispatchToProps = (dispatch) => ({
    createParentFieldSegments: (proposalId, location, callback) =>
        dispatch(
            actions.createParentFieldSegments(proposalId, location, callback),
        ),
    prepareDeleteParentFieldSegments: (proposalId) =>
        dispatch(actions.prepareDeleteParentFieldSegments(proposalId)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    companyPositionLatLng: PropTypes.object,
    contactPosition: PropTypes.object,
    createParentFieldSegments: PropTypes.func,
    haveParentFieldSegment: PropTypes.bool,
    history: PropTypes.object,
    isFetchingOfferProducts: PropTypes.bool,
    isFetchingParentFieldSegment: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    parentFieldSegmentCounters: PropTypes.object,
    prepareDeleteParentFieldSegments: PropTypes.func,
    projectId: PropTypes.string,
    projectLocation: PropTypes.object,
    proposalId: PropTypes.string,
    segments: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PANEL_LAYOUT_PERMISSION),
    withRouter,
)(Container);
