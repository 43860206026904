import React, { useEffect } from 'react';

import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button } from 'sunwise-ui';

import {
    ReactHookFormSwitch,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import * as multiBranchesActions from 'common/modules/multiBranches/actions';
import {
    initProfileConfigurationActions,
    getUserSettings,
    getIanaTimezoneKey,
} from 'common/utils/helpers/multiregion';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const FormContainer = ({
    errors,
    fetchLanguages,
    fetchMultiBranches,
    fetchTimezones,
    handleClickSave,
    initialValues,
    isFetchingLanguages,
    isFetchingTimezones,
    isSaving,
    languages,
    timezones,
    updateConfiguration,
}) => {
    const { i18n, t } = useTranslation();

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    const autoTimezone = watch('auto_timezone');
    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        fetchLanguages();
        fetchTimezones();
    }, [i18n.language]);

    useEffect(() => {
        handleChangeAutodetectField();
    }, [autoTimezone]);

    const handleOnSubmit = (values) => {
        handleClickSave(values, (isLanguageChanged, newLanguage) => {
            if (isLanguageChanged) {
                i18n.changeLanguage(newLanguage);
                fetchMultiBranches();
            }

            initProfileConfigurationActions(updateConfiguration);
        });
    };

    const handleChangeAutodetectField = () => {
        const settings = getUserSettings();
        if (isNil(settings) && !autoTimezone) {
            setValue('timezone', getIanaTimezoneKey());
        }
    };

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <ReactHookFormSelect2
                control={control}
                isLoading={isFetchingLanguages}
                isClearable={false}
                label={t('Language')}
                name="language"
                options={[{ label: '', options: languages }]}
            />

            <ReactHookFormSelect2
                control={control}
                disabled={autoTimezone}
                isLoading={isFetchingTimezones}
                isClearable={false}
                label={t('Timezone')}
                name="timezone"
                options={[{ label: '', options: timezones }]}
            />

            <ReactHookFormSwitch
                control={control}
                label={t('Auto detect')}
                name="auto_timezone"
            />

            <ShowErrors errors={errors} />

            <BottomActions>
                <Box mt={2}>
                    <Button type="submit" disabled={isSaving}>
                        {t('Save')}
                    </Button>
                </Box>
            </BottomActions>
        </form>
    );
};

FormContainer.propTypes = {
    errors: PropTypes.array,
    fetchLanguages: PropTypes.func,
    fetchMultiBranches: PropTypes.func,
    fetchTimezones: PropTypes.func,
    handleClickSave: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingLanguages: PropTypes.bool,
    isFetchingTimezones: PropTypes.bool,
    isSaving: PropTypes.bool,
    languages: PropTypes.array,
    timezones: PropTypes.array,
    updateConfiguration: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    languages: selectors.getLanguagesOptions,
    isFetchingLanguages: selectors.getIsFetchingLanguages,
    timezones: selectors.getTimezonesOptions,
    isFetchingTimezones: selectors.getIsFetchingTimezones,
    isSaving: selectors.getIsSaving,
    errors: selectors.getErrorsSave,
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLanguages: () => dispatch(actions.fetchLanguages()),
    fetchMultiBranches: () => dispatch(multiBranchesActions.fetchBranches()),
    fetchTimezones: () => dispatch(actions.fetchTimezones()),
    handleClickSave: (values, onSuccessCallback) =>
        dispatch(actions.save(values, onSuccessCallback)),
    updateConfiguration: (data) => dispatch(actions.updateConfiguration(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
