import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import renameItem from './renameItem';

export default (item) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Rename'),
        defaultTextResponse: item.name,
        haveTextResponse: true,
        messages: [
            i18next.t(
                'Are you sure you want to change the name of this integration?',
            ),
        ],
        onSuccess: (value) => {
            dispatch(renameItem({ ...item, name: value }));
        },
        title: i18next.t('Rename integration'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
