import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import {
    formatDate,
    formatDistanceDate,
    parseDate,
    zonedDate,
} from 'common/utils/dates';
import { getTimezoneUserSettings } from 'common/utils/helpers/multiregion';

const DateFormatter = ({ row }) => {
    const { t } = useTranslation();
    const { notification_at = '' } = row;

    if (!notification_at) return null;

    const currentDate = zonedDate(new Date(), getTimezoneUserSettings());

    const targetDate = parseDate(notification_at, 'dd/MM/yyyy HH:mm:ss');

    const createdAt = formatDistanceDate(
        targetDate,
        zonedDate(new Date(), getTimezoneUserSettings()),
        { addSuffix: true },
    );

    const trackingDate = formatDate(targetDate, 'dd MMMM yyyy h:mm aa');

    const hasExpired = currentDate > targetDate;
    return (
        <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="caption">{trackingDate}</Typography>
            <Typography variant="caption" color={hasExpired ? 'error' : 'text'}>
                {hasExpired ? t('Expired') : t('Expires')} {createdAt}
            </Typography>
        </Box>
    );
};

DateFormatter.propTypes = {
    row: PropTypes.object,
};

export default DateFormatter;
