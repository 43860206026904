import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Button, Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import IntegrationModal from './components/IntegrationModal';
import IntegrationsList from './components/IntegrationsList';
import * as selectors from './selectors';

const SipsContainer = ({
    canDelete,
    canModify,
    canView,
    fetchIntegrations,
    fetchProviders,
    hasProviders,
    integrations,
    isFetchingIntegrations,
    isFetchingProviders,
    prepareDelete,
    providersDictionary,
    reset,
    setIsOpenFormModal,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchIntegrations();
        fetchProviders();

        return () => reset();
    }, []);

    if (!canView) return null;

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    gap={1}
                    item
                    justifyContent="space-between"
                    xs={18}
                >
                    <TitleIcon
                        fontWeight="bold"
                        title={t('Supplier', { count: 2 })}
                    />

                    {hasProviders && canModify && (
                        <Button
                            endIcon={<AddIcon />}
                            onClick={() => setIsOpenFormModal(true)}
                        >
                            {t('Add integration')}
                        </Button>
                    )}
                </Grid>
            </HeaderContainer>

            <IntegrationsList
                canDelete={canDelete}
                canModify={canModify}
                handleClickCreate={() => setIsOpenFormModal(true)}
                integrations={integrations}
                isFetching={isFetchingIntegrations || isFetchingProviders}
                prepareDeleteKey={prepareDelete}
                providersDictionary={providersDictionary}
            />

            <IntegrationModal />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    hasProviders: selectors.getHasProviders,
    integrations: selectors.getIntegrations,
    isFetchingIntegrations: selectors.getIsFetchingIntegrations,
    isFetchingProviders: selectors.getIsFetchingProviders,
    providersDictionary: selectors.getProvidersDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    fetchIntegrations: () => dispatch(actions.fetchIntegrations()),
    fetchProviders: () => dispatch(actions.fetchProviders()),
    prepareDelete: (id) => dispatch(actions.prepareDelete(id)),
    reset: () => dispatch(actions.reset()),
    setIsOpenFormModal: (isOpen) =>
        dispatch(actions.setIsOpenFormModal(isOpen)),
});

SipsContainer.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    fetchIntegrations: PropTypes.func,
    fetchProviders: PropTypes.func,
    hasProviders: PropTypes.bool,
    integrations: PropTypes.array,
    isFetchingIntegrations: PropTypes.bool,
    isFetchingProviders: PropTypes.bool,
    prepareDelete: PropTypes.func,
    providersDictionary: PropTypes.object,
    reset: PropTypes.func,
    setIsOpenFormModal: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.SUPPLIERS_PERMISSION),
)(SipsContainer);
