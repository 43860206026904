import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from 'sunwise-ui';

import {
    ReactHookFormArrayEmails,
    ReactHookFormArrayPhones,
    ReactHookFormInput,
} from 'common/components/form/bootstrap/';
import ShowErrors from 'common/components/ShowErrors';
import TitleWithDetail from 'common/components/TitleWithDetail';
import yupResolver from 'common/utils/yupResolver';

import validate from '../validate';

const Form = ({
    errors,
    onSubmit,
    handleClickCancel,
    initialValues,
    isUploading,
    phoneCodes,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TitleWithDetail variant="body2">
                {t('Personal information')}
            </TitleWithDetail>

            <ReactHookFormInput
                control={control}
                fullWidth
                label={`${t('Name')} *`}
                maxLength={124}
                name="name"
            />

            <ReactHookFormInput
                control={control}
                fullWidth
                label={t('Last name')}
                maxLength={124}
                name="first_lastname"
            />

            <ReactHookFormInput
                control={control}
                fullWidth
                label={t('Second last name')}
                maxLength={124}
                name="second_lastname"
            />

            <ReactHookFormInput
                control={control}
                fullWidth
                label={t('Business')}
                maxLength={124}
                name="company_name"
            />

            <ReactHookFormInput
                control={control}
                fullWidth
                label={t('Position')}
                maxLength={124}
                name="company_position"
            />

            <ReactHookFormArrayPhones
                codes={phoneCodes}
                control={control}
                fullWidth
                name="telephones"
            />

            <ReactHookFormArrayEmails control={control} name="emails" />

            <ShowErrors errors={errors} />

            <Grid container mt={1}>
                <Grid
                    item
                    xs
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'right' },
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={handleClickCancel}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 2, md: 1 },
                            mr: { xs: 0, md: 2 },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={isUploading}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 1, md: 2 },
                            mb: { xs: 2, md: 0 },
                        }}
                        type="submit"
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

Form.propTypes = {
    errors: PropTypes.array,
    handleClickCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isUploading: PropTypes.bool,
    phoneCodes: PropTypes.object,
};

export default Form;
