import API from 'common/api';
import { getItem } from 'common/api/v1/customRates';

import {
    FETCH_RATE,
    FETCH_RATE_FAILURE,
    FETCH_RATE_SUCCESS,
} from '../actionTypes';
import { getValuesWithCompensationScheme } from '../helpers';
import { ratesActions } from '../reducer';

export default (rateId) => (dispatch) => {
    API.cancelAll();
    dispatch(ratesActions[FETCH_RATE](rateId));
    getItem(rateId)
        .then((response) => {
            const data = getValuesWithCompensationScheme(response?.data?.data);
            dispatch(ratesActions[FETCH_RATE_SUCCESS](data));
        })
        .catch((error) => dispatch(ratesActions[FETCH_RATE_FAILURE](error)));
};
