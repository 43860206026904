import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    align-items: center;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 0px 15px 0px;
    width: 100%;
`;
