import React, { useEffect } from 'react';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Divider, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import * as actions from './actions';
import IndicatorsContent from './components/IndicatorsContent';
import IndicatorsSettingsModal from './components/IndicatorsSettingsModal';
import { getIndicatorStorageValueByProducts } from './helpers';
import * as selectors from './selectors';

const Container = ({
    batteriesOfferValues,
    compensationScheme,
    currencyIso,
    currencyLocale,
    fetchIndicatorsSettings,
    handleClickOpenIndicatorsSettingsModal,
    indicatorsSettingsData,
    irradiationValues,
    isLoading,
    maxDailyConsumption,
    nextRateData,
    offerDetailsComplements,
    offerDetailsConsumption,
    proposalConfiguration,
    proposalId,
    totalsSolarSimulation,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (proposalId) fetchIndicatorsSettings(proposalId);
    }, [proposalId]);

    const grossStoragePrice = getIndicatorStorageValueByProducts({
        indicatorKey: 'storage_price_gross_per_kwh',
        indicatorsSettingsData,
        offerDetailsComplements,
    });

    const netStoragePrice = getIndicatorStorageValueByProducts({
        indicatorKey: 'storage_price_per_kwh',
        indicatorsSettingsData,
        offerDetailsComplements,
        useDoD: true,
    });

    return (
        <>
            <Box display="flex" alignItems="center" p={1}>
                <QueryStatsIcon sx={{ color: 'primary.main' }} />
                <Typography variant="body2" ml={1} fontWeight="bold">
                    {t('Key indicator', { count: 2 })}
                </Typography>
                <DropdownCardMenu
                    items={[
                        {
                            handleClick: () =>
                                handleClickOpenIndicatorsSettingsModal(true),
                            label: t('Set up'),
                        },
                    ]}
                    sxIconButton={{ ml: 'auto' }}
                />
            </Box>

            <Divider sx={{ mb: 1 }} />

            <IndicatorsContent
                batteriesOfferValues={batteriesOfferValues}
                compensationScheme={compensationScheme}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                grossStoragePrice={grossStoragePrice}
                indicatorsSettingsData={indicatorsSettingsData}
                irradiationValues={irradiationValues}
                isLoading={isLoading}
                maxDailyConsumption={maxDailyConsumption}
                netStoragePrice={netStoragePrice}
                nextRateData={nextRateData}
                offerDetailsComplements={offerDetailsComplements}
                offerDetailsConsumption={offerDetailsConsumption}
                proposalConfiguration={proposalConfiguration}
                totalsSolarSimulation={totalsSolarSimulation}
            />

            <IndicatorsSettingsModal proposalId={proposalId} />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    indicatorsSettingsData: selectors.getIndicatorsSettingsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchIndicatorsSettings: (proposalId) =>
        dispatch(actions.fetchIndicatorsSettings(proposalId)),
    handleClickOpenIndicatorsSettingsModal: (value) =>
        dispatch(actions.setIsOpenIndicatorsSettingsModal(value)),
});

Container.propTypes = {
    batteriesOfferValues: PropTypes.object,
    compensationScheme: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    fetchIndicatorsSettings: PropTypes.func,
    handleClickOpenIndicatorsSettingsModal: PropTypes.func,
    indicatorsSettingsData: PropTypes.array,
    irradiationValues: PropTypes.object,
    isLoading: PropTypes.bool,
    maxDailyConsumption: PropTypes.number,
    nextRateData: PropTypes.object,
    offerDetailsComplements: PropTypes.object,
    offerDetailsConsumption: PropTypes.object,
    proposalConfiguration: PropTypes.object,
    proposalId: PropTypes.string,
    totalsSolarSimulation: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
