import { createSelector } from 'reselect';

import { NAME, PROVIDERS } from './constants';

export const getModel = (state) => state[NAME];

export const getIntegrationsModel = createSelector(
    getModel,
    (model) => model?.integrations,
);

export const getIsFetchingIntegrations = createSelector(
    getIntegrationsModel,
    (integrations) => integrations?.isFetching,
);

export const getIntegrations = createSelector(
    getIntegrationsModel,
    (integrations) => integrations?.data || [],
);

export const getSipsIntegration = createSelector(
    getIntegrations,
    (integrations) =>
        integrations?.find(
            (integration) => integration.type === PROVIDERS.SIPS,
        ),
);

export const getZapIntegration = createSelector(
    getIntegrations,
    (integrations) =>
        integrations?.find((integration) => integration.type === PROVIDERS.ZAP),
);

export const getDeleteIntegration = createSelector(
    getModel,
    (model) => model?.deleteIntegration,
);

export const getIsDeletingIntegration = createSelector(
    getDeleteIntegration,
    (deleteIntegration) => deleteIntegration?.isDeleting,
);

export const getSaveIntegration = createSelector(
    getModel,
    (model) => model?.saveIntegration,
);

export const getIsSavingIntegration = createSelector(
    getSaveIntegration,
    (saveIntegration) => saveIntegration?.isSaving,
);

export const getIsOpenFormModal = createSelector(
    getModel,
    (model) => model?.isOpenFormModal,
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model?.initialValues,
);

export const getProvidersModel = createSelector(
    getModel,
    (model) => model?.providers,
);

export const getIsFetchingProviders = createSelector(
    getProvidersModel,
    (providers) => providers?.isFetching,
);

export const getProviders = createSelector(
    getProvidersModel,
    (providers) => providers?.data || [],
);

export const getHasProviders = createSelector(
    getProviders,
    (providers) => providers?.length > 0,
);

export const getProvidersDictionary = createSelector(
    getProviders,
    (providers) =>
        providers?.reduce((acc, provider) => {
            acc[provider.type] = provider;
            return acc;
        }, {}) || {},
);
