import React, { useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, Typography } from 'sunwise-ui';

import ReactHookFormSelect2 from 'common/components/form/bootstrap/ReactHookFormSelect2';
import { calculateFinalCost } from 'common/utils/helpers';

import invertersPlaceholder from 'resources/onboarding/panels.svg';

import * as actions from '../actions';
import { getProductBrandsToSelect } from '../helpers';
import * as selectors from '../selectors';

import ReduxFieldArrayInverters from './ReduxFieldArrayInverters';

const InvertersStep = ({
    control,
    fetchInverterProducts,
    formValues,
    getValues,
    inverterBrands,
    inverterProducts,
    isFetchingInverterBrands,
    isFetchingInverterProducts,
    setValue,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!localStorage.getItem('tempInverterSettings')) {
            const inverters = inverterProducts.map((item) => ({
                cost: item.cost,
                final_cost: calculateFinalCost(item.cost, 0),
                id: item.id,
                margin: 0,
                name: item.name,
                price: 0,
                selected: true,
            }));
            setValue('inverters', inverters);
        }
    }, [inverterProducts]);

    const handleOnChangeBrand = (brandName) => {
        localStorage.removeItem('tempInverterSettings');
        setValue('inverters', []);
        fetchInverterProducts(brandName);
    };

    return (
        <>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    mb: 2,
                    py: 1,
                    width: '100%',
                }}
            >
                <img src={invertersPlaceholder} />
                <Typography variant="h6">
                    {t("Let's complement with inverters")}
                </Typography>
                <Typography variant="body2">
                    {t(
                        'We pre-select the 5 most used inverters depending on the brand you choose, then you will have more options to further customize your product listings',
                    )}
                    .
                </Typography>
            </Box>
            <Grid container mb={2}>
                <Grid item xs={18} md={8}>
                    <Typography fontWeight="bold" mb={1} variant="body2">
                        {t('Inverter brand')}
                    </Typography>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isFetchingInverterBrands}
                        isClearable={false}
                        label={t('Select your favorite')}
                        name="inverter_brand"
                        onChange={(value) => handleOnChangeBrand(value)}
                        options={[
                            {
                                label: '',
                                options:
                                    getProductBrandsToSelect(inverterBrands),
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            {isFetchingInverterProducts ? (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                    }}
                >
                    <CircularProgress />
                    <Typography fontWeight="bold" variant="caption">
                        {`${t('Loading')} ${t('Inverter', { count: 2 })}...`}
                    </Typography>
                </Box>
            ) : (
                <ReduxFieldArrayInverters
                    control={control}
                    disabled={isFetchingInverterProducts}
                    formValues={formValues}
                    getValues={getValues}
                    name="inverters"
                    setValue={setValue}
                />
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    inverterBrands: selectors.getInverterBrandsData,
    inverterProducts: selectors.getInverterProductsData,
    isFetchingInverterBrands: selectors.getIsFetchingInverterBrands,
    isFetchingInverterProducts: selectors.getIsFetchingInverterProducts,
});

const mapDispatchToProps = (dispatch) => ({
    fetchInverterProducts: (brandName) =>
        dispatch(actions.fetchInverterProducts(brandName)),
});

InvertersStep.propTypes = {
    control: PropTypes.object,
    fetchInverterProducts: PropTypes.func,
    formValues: PropTypes.object,
    getValues: PropTypes.func,
    inverterBrands: PropTypes.array,
    inverterProducts: PropTypes.array,
    isFetchingInverterBrands: PropTypes.bool,
    isFetchingInverterProducts: PropTypes.bool,
    setValue: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvertersStep);
