import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import renameProject from './renameProject';

export default (filterData, project) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Change name'),
        defaultTextResponse: project.name,
        haveTextResponse: true,
        messages: [i18next.t('Are you sure you want to rename this element?')],
        onSuccess: (value) => {
            dispatch(renameProject(filterData, { ...project, name: value }));
        },
        title: i18next.t('Change name'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
