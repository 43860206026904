import { transformAfterSalesDateToSunwiseFormat } from '../../afterSalesConsumptionHistory/helpers';
import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import * as selectors from '../selectors';

import fetchDefaultLocation from './fetchDefaultLocation';
import initializeSettingsForm from './initializeSettingsForm';
import setSettingsFormModalOpenStatus from './setSettingsFormModalOpenStatus';

export default (sunwiseProjectId) => (dispatch, getState) => {
    if (!sunwiseProjectId) return;

    const state = getState();
    const powerStationsData = selectors.getPowerStationsData(state);

    const latitude = powerStationsData?.latitude || null;
    const longitude = powerStationsData?.longitude || null;

    const initialValues = {
        address: powerStationsData?.address || null,
        id: powerStationsData?.id || null,
        latitude,
        longitude,
        position: { latitude, longitude },
        time_zone: powerStationsData?.time_zone || null,
    };

    if (powerStationsData?.start_date) {
        initialValues.start_date = transformAfterSalesDateToSunwiseFormat(
            powerStationsData?.start_date,
        );
    } else {
        const startDate =
            afterSalesSettingsSelectors.getAfterSalesStartDate(state);
        initialValues.start_date = startDate;
    }

    dispatch(initializeSettingsForm(initialValues));
    dispatch(setSettingsFormModalOpenStatus(true));
    if (!initialValues?.id) dispatch(fetchDefaultLocation(sunwiseProjectId));
};
