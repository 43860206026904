import React, { useState } from 'react';

import { UiDialog as Dialog } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as ratesActions from '../../rates/actions';
import * as actions from '../actions';
import * as selectors from '../selectors';

import EditRateWarning from './EditRateWarning';
import RateForm from './RateForm';

const RateFormModal = ({
    commercialOfferId,
    countryCurrencySymbol,
    fetchUnits,
    initialValues,
    isOpen,
    resetForm,
    saveRate,
    setModalIsOpen,
}) => {
    const [showMode, setShowMode] = useState(true);
    const { t } = useTranslation();

    const onClose = () => setModalIsOpen(false);

    const onSubmit = (data) => saveRate(commercialOfferId, data, onClose);

    const onExited = () => {
        setShowMode(true);
        resetForm();
    };

    return (
        <Dialog
            onClose={onClose}
            onEnter={fetchUnits}
            onExited={onExited}
            open={isOpen}
            size="md"
            title={`${t('Edit')} ${initialValues?.rate_name}`}
        >
            {showMode && (
                <EditRateWarning onClickEdit={() => setShowMode(false)} />
            )}

            <RateForm
                countryCurrencySymbol={countryCurrencySymbol}
                initialValues={initialValues}
                onSubmit={onSubmit}
                showMode={showMode}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesRateConcepts,
    isOpen: selectors.getIsRateFormModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUnits: () => dispatch(ratesActions.fetchUnits()),
    resetForm: () => dispatch(actions.resetRateForm()),
    saveRate: (commercialOfferId, data, callback) =>
        dispatch(actions.saveRateConcepts(commercialOfferId, data, callback)),
    setModalIsOpen: (isOpen) => dispatch(actions.setRateFormModalOpen(isOpen)),
});

RateFormModal.propTypes = {
    commercialOfferId: PropTypes.string,
    countryCurrencySymbol: PropTypes.string,
    fetchUnits: PropTypes.func,
    initialValues: PropTypes.object,
    isOpen: PropTypes.bool,
    resetForm: PropTypes.func,
    saveRate: PropTypes.func,
    setModalIsOpen: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(RateFormModal);
