import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteGenerationProfile from './deleteGenerationProfile';

export default (id, politicalDivision, politicalDivisionName) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [
            i18next.t(
                'Are you sure to delete the generation profile for {{politicalDivisionName}}? It will be replace by sunwise',
                {
                    politicalDivisionName,
                },
            ),
        ],
        onSuccess: () =>
            dispatch(deleteGenerationProfile(id, politicalDivision)),
        title: `${i18next.t('Delete')} ${i18next
            .t('Generation profile')
            .toLowerCase()}`,
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
