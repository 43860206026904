import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Select } from 'sunwise-ui';

const DropdownCustomCardMenuMap = ({
    canModify,
    handleSelected,
    selected,
    statusToSelect,
}) => {
    const { t } = useTranslation();
    return (
        <FormControl fullWidth size="small">
            <InputLabel>{t('Status')}</InputLabel>
            <Select
                disabled={!canModify}
                value={selected?.id}
                label={t('Status')}
                onChange={(e) => handleSelected({ value: e.target.value })}
            >
                {statusToSelect.map((status, index) => (
                    <MenuItem key={index} value={status?.value}>
                        {status.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

DropdownCustomCardMenuMap.propTypes = {
    canModify: PropTypes.bool,
    handleSelected: PropTypes.func,
    selected: PropTypes.object,
    statusToSelect: PropTypes.array,
};

export default DropdownCustomCardMenuMap;
