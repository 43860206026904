import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import useBreakpoint from 'common/hooks/useBreakpoint';

import AfterSalesConsumptionHistory from '../afterSalesConsumptionHistory';
import afterSalesSettings from '../afterSalesSettings';
import AccessRestrictedBanner from '../afterSalesSettings/components/warnings/AccessRestrictedBanner';
import * as afterSalesSettingsSelectors from '../afterSalesSettings/selectors';
import reportList from '../reportList';

const Container = ({
    afterSalesSettingsDataId,
    canDelete,
    canModify,
    canView,
    contactId,
    fetchAfterSalesSettings,
    selectedProject,
}) => {
    const [height, setHeight] = useState(3050);
    const breakpoint = useBreakpoint();
    const selectedProjectId = selectedProject?.id;

    useEffect(() => {
        fetchAfterSalesSettings(selectedProjectId);
    }, [selectedProjectId]);

    useEffect(() => {
        switch (breakpoint) {
            case 'xs':
                setHeight(4390);
                break;
            case 'sm':
                setHeight(3700);
                break;
            case 'md':
                setHeight(2650);
                break;
            default:
                setHeight(3050);
                break;
        }
    }, [breakpoint]);

    if (!canView) return null;

    if (!afterSalesSettingsDataId)
        return (
            <AccessRestrictedBanner
                minHeight={height}
                redirectTo={`/record/${contactId}?tab=projects&project=${selectedProjectId}&view=monitoring`}
            >
                <afterSalesSettings.Container
                    canModify={canModify}
                    selectedProjectId={selectedProjectId}
                />
            </AccessRestrictedBanner>
        );

    return (
        <AccessRestrictedBanner
            minHeight={height}
            redirectTo={`/record/${contactId}?tab=projects&project=${selectedProjectId}&view=monitoring`}
        >
            <Grid container>
                <Grid item xs={18} md={9}>
                    <AfterSalesConsumptionHistory.Container
                        canDelete={canDelete}
                        canModify={canModify}
                        selectedProject={selectedProject}
                    />
                </Grid>

                <Grid item xs={18} md={9}>
                    <reportList.Container
                        canDelete={canDelete}
                        canModify={canModify}
                        selectedProjectId={selectedProjectId}
                    />
                </Grid>
            </Grid>
        </AccessRestrictedBanner>
    );
};

const mapStateToProps = createStructuredSelector({
    afterSalesSettingsDataId:
        afterSalesSettingsSelectors.getAfterSalesSettingsDataId,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAfterSalesSettings: (projectId) =>
        dispatch(afterSalesSettings.actions.fetchAfterSalesSettings(projectId)),
});

Container.propTypes = {
    afterSalesSettingsDataId: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    contactId: PropTypes.string,
    fetchAfterSalesSettings: PropTypes.func,
    selectedProject: PropTypes.object,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.AFTER_SALES_PERMISSION),
)(Container);
