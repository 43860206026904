import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import * as proposalGeneratorColumn from '../../proposalGeneratorOneColumn/selectors';
import * as proposalGeneratorPanelsLayoutSelectors from '../../proposalGeneratorPanelsLayout/selectors';
import deleteItem from '../../proposalGeneratorPanelTable/actions/delete';
import * as proposalGeneratorQuoteSelectors from '../../proposalGeneratorQuote/selectors';

import initialValues from './initialValues';

export default (id, proposalId) => (dispatch, getState) => {
    const state = getState();
    const offerPanels =
        proposalGeneratorQuoteSelectors.getOfferPanelsData(state);
    const offerDetailsConsumption =
        proposalGeneratorColumn.getOfferDetailsConsumption(state);
    const { energy, system_size } = offerDetailsConsumption;
    const haveParentFieldSegment =
        proposalGeneratorPanelsLayoutSelectors.getHaveParentFieldSegment(state);

    let messages = [
        i18next.t(
            'At least one unit per product is required, if you do not want the product you can remove it',
        ),
    ];

    if (haveParentFieldSegment)
        messages.push(
            i18next.t(
                'If you modify or delete the PV module you will need to redo the PV layout',
            ),
        );

    const dataAlert = {
        cancelText: i18next.t('Cancel'),
        confirmText: i18next.t('Delete'),
        title: i18next.t('Warning'),
        messages,
        onCancel: () =>
            dispatch(initialValues(offerPanels, energy, system_size)),
        onSuccess: () => dispatch(deleteItem(id, proposalId)),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
