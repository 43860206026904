import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';

const CustomAccordion = ({ children, defaultExpanded, title }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const sxExpandMoreIcon = {
        color: isDarkMode === 'dark' ? '#000' : '',
    };

    return (
        <AccordionCard
            customTitle={
                <Typography variant="body2" fontWeight={700}>
                    {title}
                </Typography>
            }
            defaultExpanded={defaultExpanded}
            sxAccordionDetails={{ pt: 2, px: 0 }}
            sxAccordionSummary={{ pl: 0 }}
            sxCard={{ boxShadow: 'none !important' }}
            sxExpandMoreIcon={sxExpandMoreIcon}
        >
            {children}
        </AccordionCard>
    );
};

CustomAccordion.propTypes = {
    children: PropTypes.object,
    defaultExpanded: PropTypes.bool,
    title: PropTypes.string,
};

export default CustomAccordion;
