import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';
import MasterButton from 'common/modules/masterButton';

import * as dashboardActions from '../dashboard/actions';
import * as dashboardSelectors from '../dashboard/selectors';
import quickViewProposals from '../quickViewProposals';
import * as quickViewProposalsActions from '../quickViewProposals/actions';
import * as trackingsActions from '../trackings/actions';
import * as trackingsSelectors from '../trackings/selectors';

import * as actions from './actions';
import ListItems from './components/ListItems';
import { getPastDate, getTodayDate } from './helpers';
import * as selectors from './selectors';

const Container = ({
    fetchRates,
    fetchUsers,
    filterProposals,
    handleClickBulkItems,
    handleClickOpenModal,
    isFetching,
    isFetchingTableSettings,
    paginationItems,
    proposals,
    rates,
    tableSettings,
    users,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        createdBy: 'all',
        endDate: getTodayDate(),
        isArchived: 'False',
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        rateName: 'all',
        searchText: '',
        sortBy: 'desc',
        startDate: getPastDate(30),
        status: 'all',
        storageRange: 'all',
        systemRange: 'all',
    });
    const [currentProposal, setCurrentProposal] = useState(null);
    useEffect(() => {
        filterProposals(filterData);
        fetchRates();
        fetchUsers();
    }, []);

    return (
        <MaterialContainer maxWidth={false} sx={{ mb: 2 }}>
            <HeaderContainer>
                <Grid item xs={18} md={12}>
                    <TitleIcon title={t('Proposal', { count: 2 })} />
                </Grid>
                <Grid item xs={18} md={6} textAlign="right">
                    <MasterButton.Container
                        defaultButton="create-proposal"
                        masterButtonMode={false}
                        onSucccess={() => filterProposals(filterData)}
                    />
                </Grid>
            </HeaderContainer>
            <Grid container>
                <Grid item xs={18}>
                    <ListItems
                        filterData={filterData}
                        filterItems={filterProposals}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickView={(item) => {
                            setCurrentProposal(item);
                            handleClickOpenModal();
                        }}
                        isFetching={isFetching || isFetchingTableSettings}
                        items={proposals}
                        paginationData={paginationItems}
                        rates={rates}
                        setFilterData={setFilterData}
                        tableSettings={tableSettings}
                        users={users}
                    />
                </Grid>
            </Grid>
            <quickViewProposals.Container
                isGenerated={currentProposal?.isGenerated}
                proposalId={currentProposal?.id}
                proposalName={currentProposal?.name}
                setCurrentProposal={setCurrentProposal}
            />
        </MaterialContainer>
    );
};

Container.propTypes = {
    fetchRates: PropTypes.func,
    fetchUsers: PropTypes.func,
    filterProposals: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickOpenModal: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    paginationItems: PropTypes.object,
    proposals: PropTypes.array,
    rates: PropTypes.array,
    tableSettings: PropTypes.array,
    users: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getIsFetchingProposals,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    paginationItems: selectors.getProposalsPagination,
    proposals: selectors.getProposalsData,
    rates: dashboardSelectors.getRatesData,
    tableSettings: customColumnsSelectors.getTableSettingsData,
    users: trackingsSelectors.getUsersData,
});

const mapDispatchToProps = (dispatch) => ({
    filterProposals: (filterData) =>
        dispatch(actions.filterProposals(filterData)),
    fetchRates: () => dispatch(dashboardActions.fetchRates()),
    fetchUsers: () => dispatch(trackingsActions.fetchUsers()),
    handleClickBulkItems: (ids, callback) =>
        dispatch(actions.bulkItems(ids, callback)),
    handleClickOpenModal: () =>
        dispatch(quickViewProposalsActions.setIsOpenModal(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
