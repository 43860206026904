import React from 'react';

import { get, isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import BranchOfficeFormatter from 'common/modules/multiBranches/components/BranchOfficeFormatter';

const ContactColumns = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const columns = [
        {
            align: 'left',
            field: 'name',
            flex: 1,
            headerName: t('Contact'),
            minWidth: 180,
            renderCell: (params) => {
                const { row } = params;
                const { phone_key, telephone } = get(row, 'telephones[0]', {});

                return (
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography variant="caption" fontWeight="bold">
                            {`${row.name} ${row.first_lastname} ${row.second_lastname}`}
                        </Typography>

                        <Typography variant="caption" fontWeight="bold">
                            {phone_key} {telephone}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    if (breakpoint === 'xs') return columns;

    columns.push({
        align: 'left',
        field: 'email',
        flex: 1,
        headerName: t('Mail'),
        minWidth: 250,
        renderCell: (params) => {
            const { row } = params;
            const { email } = get(row, 'emails[0]', {});
            return (
                <Typography variant="caption" fontWeight="bold">
                    {email}
                </Typography>
            );
        },
    });

    if (breakpoint === 'sm') return columns;

    return columns.concat([
        {
            align: 'left',
            field: 'agent',
            flex: 1,
            headerName: t('Solar advisor'),
            minWidth: 180,
            renderCell: (params) => {
                const { row } = params;
                if (isNull(row?.agent)) return t('No advisor assigned');
                return `${row?.agent?.first_name} ${row?.agent?.last_name}`;
            },
        },
        {
            align: 'left',
            field: 'company_name',
            flex: 1,
            headerName: t('Business'),
            minWidth: 180,
        },
        {
            align: 'left',
            field: 'branch_name',
            flex: 1,
            headerName: t('Branch office'),
            minWidth: 180,
            renderCell: (params) => {
                const { row } = params;
                return BranchOfficeFormatter(row.branch_office, row);
            },
        },
    ]);
};

export default ContactColumns;
