import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'projects';

export const archiveOffer = (id) =>
    API.put(`/api/v1/archive-active/project-commercial-offer/${id}`);
export const bulkProjects = (data) =>
    API.put(`/api/v1/${ENTITY}/bulk-status-flag/`, data, config);
export const createOffer = (data) =>
    API.post(`/api/v1/${ENTITY}-offer-parent/`, data);
export const createProject = (data) => API.post(`/api/v1/${ENTITY}/`, data);
export const deleteProject = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const downloadHistory = (id) =>
    API.get(`/api/v1/lines-consumptions-energies-gdmth/${id}`);
export const duplicateOffer = (id, data) =>
    API.put(`/api/v1/duplicate-${ENTITY}-offer-parent/${id}`, data);
export const getConsumptionEnergy = (projectId) =>
    API.get(`/api/v1/${ENTITY}/${projectId}/customers-energies-consumptions/`);
export const getConsumptionEnergyCSV = (data) =>
    API.post(`/api/v1/customers-energies-consumtions-csv/`, data);
export const getProject = (projectId) =>
    API.get(`/api/v1/project-detail/${projectId}`);
export const getProjects = ({
    contactId = '',
    searchText = '',
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/${contactId}`, {
        params: {
            ...(searchText ? { search: searchText } : {}),
            status_flag: status,
        },
    });
export const getProposals = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    projectId = null,
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/proposals/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            project_id: projectId,
            sort: sortBy,
        },
    });
export const getReports = ({
    orderBy = 'created_at',
    page = 1,
    pageSize = 25,
    projectId = null,
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/paginated-reports/`, {
        params: {
            order_by: orderBy,
            page_size: pageSize,
            page: page,
            project_id: projectId,
            sort: sortBy,
        },
    });
export const getSummaries = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    projectId = null,
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/summaries/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            project_id: projectId,
            sort: sortBy,
        },
    });
export const getTemplateCSV = (id) => API.get(`/api/v1/offer-end-csv/${id}`);
export const markAsFavorite = (offerId) =>
    API.get(`/api/v1/${ENTITY}-offer-parent/favorite/${offerId}`);
export const renameOffer = (id, data) =>
    API.put(`/api/v1/${ENTITY}-offer-parent/${id}`, data);
export const renameProject = (projectId, data) =>
    API.put(`/api/v1/${ENTITY}/${projectId}`, data);
export const updateConsumption = (id) =>
    API.get(`/api/v1/update_service_month/${id}`);
export const updateProject = (data, id) =>
    API.put(`/api/v1/${ENTITY}-contacts/${id}`, data);
export const updateType = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, data);
export const uploadCFEFile = (data) =>
    API.post('/api/v1/upload-file-cfe/', data);
export const uploadPDFReceipt = (data) =>
    API.post('/api/v1/upload-pdf-receipt/', data);
