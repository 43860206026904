import get from 'lodash/get';
import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import { percentageToValue, valueToPercentage } from './helpers';

export default (_, props) => {
    const {
        getMinValueText,
        getMaxValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    const { parameters, total_with_dealer_fee } = props.initialValues;
    const isPercentage = get(parameters, 'is_percentage', false);
    const hitch = get(parameters, 'hitch', false);
    const minHitch = percentageToValue(
        isPercentage,
        hitch,
        total_with_dealer_fee,
    );
    const minHitchPercentage = valueToPercentage(
        isPercentage,
        hitch,
        total_with_dealer_fee,
    );
    return object().shape({
        types_business_company: string().required(REQUIRED_TEXT).nullable(),
        financier_product: string().required(REQUIRED_TEXT).nullable(),
        term: string().when('is_serfimex', {
            is: false,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        hitch_percentage: number().when('is_percentage', {
            is: 'true',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(
                        minHitchPercentage,
                        getMinValueText(minHitchPercentage),
                    )
                    .max(100, getMaxValueText(100))
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        hitch: number().when('is_percentage', {
            is: 'false',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(minHitch, getMinValueText(minHitch))
                    .max(
                        total_with_dealer_fee,
                        getMaxValueText(total_with_dealer_fee),
                    )
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
    });
};
