import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button } from 'sunwise-ui';

import {
    getIsMultibranchesAccount,
    getMainBranchOffice,
} from 'common/utils/helpers/session';

import CommercialOfferForm from '../../../../../modules/projectCommercialOfferTable/components/CommercialOfferModal/CommercialOfferForm';
import * as selectors from '../../selectors';

const CustomFooter = ({ hadleOnSubmit, handleSubmit, isSaving }) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, pt: 2 }}>
            <Button
                disabled={isSaving}
                onClick={handleSubmit((values) => hadleOnSubmit(values))}
                sx={{ ml: 'auto' }}
            >
                {t('Create')}
            </Button>
        </Box>
    );
};

CustomFooter.propTypes = {
    hadleOnSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSaving: PropTypes.bool,
};

const Step4 = ({ isSaving, onSave, onSubmit, setStep, values }) => {
    const hadleOnSubmit = (formValues) =>
        onSave({ ...values, proposal: formValues });

    const isMultiBranches = getIsMultibranchesAccount();
    let branchOffice = get(values?.contact, 'branch_office', '');
    if (!isMultiBranches && !branchOffice) branchOffice = getMainBranchOffice();

    const props = {
        consumptionEnergy: values.consumptions,
        initialValues: {
            branch_office: branchOffice,
            is_multibranch: isMultiBranches,
        },
    };

    return (
        <CommercialOfferForm
            {...props}
            CustomFooter={({ getValues, handleSubmit }) => (
                <CustomFooter
                    getValues={getValues}
                    hadleOnSubmit={hadleOnSubmit}
                    handleSubmit={handleSubmit}
                    isSaving={isSaving}
                    onSubmit={onSubmit}
                    setStep={setStep}
                />
            )}
        />
    );
};
const mapStateToProps = createStructuredSelector({
    isSaving: selectors.getIsSaving,
});

Step4.propTypes = {
    isSaving: PropTypes.bool,
    onSave: PropTypes.func,
    onSubmit: PropTypes.func,
    setStep: PropTypes.func,
    values: PropTypes.object,
};

export default connect(mapStateToProps, null)(Step4);
