import React from 'react';

import {
    Box,
    FormControl,
    Pagination,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    gridPageCountSelector,
    gridPageSelector,
    gridPaginationSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

const CustomPagination = () => {
    const { t } = useTranslation();
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const { rowCount = 0 } = useGridSelector(apiRef, gridPaginationSelector);
    const sxBox = {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        m: 1,
    };
    const sxWrapper = {
        alignItems: 'center',
        display: 'flex',
        flexDirection: { gap: 1, md: 'row', xs: 'column' },
        width: { md: 'auto', xs: '100%' },
    };

    return (
        <Box sx={sxWrapper}>
            <Box sx={sxBox}>
                <Tooltip title={t('Total rows')}>
                    <Typography fontWeight="bold" noWrap variant="body2">
                        {t('Total')}:
                    </Typography>
                </Tooltip>
                {rowCount}
            </Box>

            <FormControl sx={{ m: 1 }}>
                <Pagination
                    color="primary"
                    count={pageCount}
                    onChange={(_, value) => apiRef.current.setPage(value - 1)}
                    page={page + 1}
                    size="small"
                />
            </FormControl>
        </Box>
    );
};

CustomPagination.defaultProps = {
    rowsPerPageOptions: [],
};

CustomPagination.propTypes = {};

export default CustomPagination;
