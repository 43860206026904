import { NAME } from './constants';

export const DELETE_ITEM = `${NAME}/DELETE_ITEM`;
export const DELETE_ITEM_FAILURE = `${NAME}/DELETE_ITEM_FAILURE`;
export const DELETE_ITEM_SUCCESS = `${NAME}/DELETE_ITEM_SUCCESS`;

export const FETCH_CATEGORIES_SETTINGS = `${NAME}/FETCH_CATEGORIES_SETTINGS`;
export const FETCH_CATEGORIES_SETTINGS_FAILURE = `${NAME}/FETCH_CATEGORIES_SETTINGS_FAILURE`;
export const FETCH_CATEGORIES_SETTINGS_SUCCESS = `${NAME}/FETCH_CATEGORIES_SETTINGS_SUCCESS`;

export const FETCH_ITEMS = `${NAME}/FETCH_ITEMS`;
export const FETCH_ITEMS_FAILURE = `${NAME}/FETCH_ITEMS_FAILURE`;
export const FETCH_ITEMS_SUCCESS = `${NAME}/FETCH_ITEMS_SUCCESS`;

export const FETCH_ROLE_LEVELS = `${NAME}/FETCH_ROLE_LEVELS`;
export const FETCH_ROLE_LEVELS_FAILURE = `${NAME}/FETCH_ROLE_LEVELS_FAILURE`;
export const FETCH_ROLE_LEVELS_SUCCESS = `${NAME}/FETCH_ROLE_LEVELS_SUCCESS`;

export const FETCH_ROLE_LEVEL_DETAILS = `${NAME}/FETCH_ROLE_LEVEL_DETAILS`;
export const FETCH_ROLE_LEVEL_DETAILS_FAILURE = `${NAME}/FETCH_ROLE_LEVEL_DETAILS_FAILURE`;
export const FETCH_ROLE_LEVEL_DETAILS_SUCCESS = `${NAME}/FETCH_ROLE_LEVEL_DETAILS_SUCCESS`;

export const FETCH_ROLE_DETAILS = `${NAME}/FETCH_ROLE_DETAILS`;
export const FETCH_ROLE_DETAILS_FAILURE = `${NAME}/FETCH_ROLE_DETAILS_FAILURE`;
export const FETCH_ROLE_DETAILS_SUCCESS = `${NAME}/FETCH_ROLE_DETAILS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
