import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_OFFER_PRODUCTS,
    FETCH_OFFER_PRODUCTS_FAILURE,
    FETCH_OFFER_PRODUCTS_SUCCESS,
    FETCH_PROPOSAL_CATALOGS,
    FETCH_PROPOSAL_CATALOGS_FAILURE,
    FETCH_PROPOSAL_CATALOGS_SUCCESS,
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
    RESET_DATA,
    SET_PRODUCTS_TOTALS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    offerProducts: {
        data: {},
        errors: [],
        isFetching: true,
    },
    offerProposalCatalogs: {
        data: {},
        errors: [],
        isFetching: true,
    },
    productsTotals: {
        discount: 0,
        profit: 0,
        profitPercentage: 0,
        subtotal: 0,
        subtotalWithDiscount: 0,
        subtotalWithTaxes: 0,
        total: 0,
        totalCost: 0,
    },
    templates: {
        data: [],
        errors: [],
        isFetching: true,
    },
};

const proposalGeneratorQuoteSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_OFFER_PRODUCTS]: (state) => {
            state.offerProducts = {
                ...state.offerProducts,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_PRODUCTS_FAILURE]: (state, action) => {
            state.offerProducts = {
                ...state.offerProducts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_PRODUCTS_SUCCESS]: (state, action) => {
            state.offerProducts = {
                ...state.offerProducts,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSAL_CATALOGS]: (state) => {
            state.offerProposalCatalogs = {
                ...state.offerProposalCatalogs,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PROPOSAL_CATALOGS_FAILURE]: (state, action) => {
            state.offerProposalCatalogs = {
                ...state.offerProposalCatalogs,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSAL_CATALOGS_SUCCESS]: (state, action) => {
            state.offerProposalCatalogs = {
                ...state.offerProposalCatalogs,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATES]: (state) => {
            state.templates = {
                ...state.templates,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_TEMPLATES_FAILURE]: (state, action) => {
            state.templates = {
                ...state.templates,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATES_SUCCESS]: (state, action) => {
            state.offerProposalCatalogs = {
                ...state.offerProposalCatalogs,
                data: {
                    ...state.offerProposalCatalogs.data,
                    custom_templates: action.payload,
                },
            };
            state.templates = {
                ...state.templates,
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [RESET_DATA]: () => INITIAL_STATE,
        [SET_PRODUCTS_TOTALS]: (state, action) => {
            state.productsTotals = action.payload;
        },
    },
});

export const proposalGeneratorQuoteActions =
    proposalGeneratorQuoteSlice.actions;

export default proposalGeneratorQuoteSlice.reducer;
