import { createSlice } from '@reduxjs/toolkit';

import {
    CONFIRM_SUBSCRIPTION,
    CONFIRM_SUBSCRIPTION_SUCCESS,
    CONFIRM_SUBSCRIPTION_FAILURE,
    FETCH_PRICING,
    FETCH_PRICING_SUCCESS,
    FETCH_PRICING_FAILURE,
    FETCH_SUBSCRIPTION,
    FETCH_SUBSCRIPTION_FAILURE,
    FETCH_SUBSCRIPTION_SUCCESS,
    SAVE_ITEM,
    SAVE_ITEM_SUCCESS,
    SAVE_ITEM_FAILURE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    confirmSubscription: {
        isSaving: false,
        data: {},
        errors: null,
    },
    fetchPricing: {
        isFetching: false,
        data: {},
        errors: null,
    },
    saveItem: {
        isSaving: false,
        data: {},
        errors: null,
    },
    subscription: {
        data: {},
        errors: null,
        isFetching: false,
    },
};

const selectBillingPlanSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [CONFIRM_SUBSCRIPTION]: (state) => {
            state.confirmSubscription = {
                ...state.confirmSubscription,
                errors: [],
                isSaving: true,
            };
        },
        [CONFIRM_SUBSCRIPTION_FAILURE]: (state, action) => {
            state.confirmSubscription = {
                ...state.confirmSubscription,
                errors: action.payload,
                isSaving: false,
            };
        },
        [CONFIRM_SUBSCRIPTION_SUCCESS]: (state, action) => {
            state.confirmSubscription = {
                ...state.confirmSubscription,
                data: action.payload,
                isSaving: false,
            };
        },
        [FETCH_PRICING]: (state) => {
            state.fetchPricing = {
                ...state.fetchPricing,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PRICING_FAILURE]: (state, action) => {
            state.fetchPricing = {
                ...state.fetchPricing,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PRICING_SUCCESS]: (state, action) => {
            state.fetchPricing = {
                ...state.fetchPricing,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUBSCRIPTION]: (state) => {
            state.subscription = {
                ...state.subscription,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_SUBSCRIPTION_FAILURE]: (state, action) => {
            state.subscription = {
                ...state.subscription,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUBSCRIPTION_SUCCESS]: (state, action) => {
            state.subscription = {
                ...state.subscription,
                data: action.payload,
                isFetching: false,
            };
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                data: action.payload,
                isSaving: false,
            };
        },
    },
});

export const selectBillingPlanActions = selectBillingPlanSlice.actions;

export default selectBillingPlanSlice.reducer;
