import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

/* PARENT FIELD SEGMENTS */

export const getParentFieldSegment = createSelector(
    getModel,
    (model) => model.parentFieldSegment,
);

export const getIsFetchingParentFieldSegment = createSelector(
    getParentFieldSegment,
    (value) => value.isFetching,
);

export const getParentFieldSegmentData = createSelector(
    getParentFieldSegment,
    (value) => value.data || {},
);

export const getSegments = createSelector(
    getParentFieldSegmentData,
    (values) => values.segments || [],
);

export const getHaveParentFieldSegment = createSelector(
    getParentFieldSegmentData,
    (values) => values.haveParentFieldSegment,
);

export const getParentFieldSegmentCounters = createSelector(
    getParentFieldSegmentData,
    (values) => ({
        solarModulesDesignNumber: values.solarModulesDesignNumber,
        solarModulesOfferNumber: values.solarModulesOfferNumber,
    }),
);

export const getParentFieldSegmentsErrors = createSelector(
    getParentFieldSegment,
    (value) => value.errors,
);

/* SAVE */

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (value) => value.isSaving);

export const getDataSave = createSelector(getSave, (value) => value.data || []);

export const getErrorsSave = createSelector(getSave, (value) => value.errors);
