import i18next from 'i18next';
import isArray from 'lodash/isArray';

import { updateItem } from 'common/api/v1/users';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { profileActions } from '../reducer';

import fetchData from './fetchProfileData';

export default (values) => (dispatch) => {
    let newValues = {
        birth_date: values.birth_date,
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
    };

    if (isArray(values.profile_image))
        newValues.profile_image =
            values.profile_image && values.profile_image[0];

    dispatch(profileActions[SAVE]());

    const message = i18next.t(
        'An error occurred during the update. Please try later',
    );

    updateItem(newValues, values.id)
        .then((response) => {
            if (!response.data) {
                dispatch(profileActions[SAVE_FAILURE]([message]));

                return;
            }
            dispatch(profileActions[SAVE_SUCCESS](response.data));
            dispatch(fetchData());
            showToast();
        })
        .catch((error) =>
            dispatch(
                profileActions[SAVE_FAILURE](error?.response?.data?.errors),
            ),
        )
        .catch((error) => {
            dispatch(
                profileActions[SAVE_FAILURE](error?.response?.data?.errors),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
