import i18next from 'i18next';

import { deleteItem } from 'common/api/v1/companiesCurrencies';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UNSELECT_PARENT,
    UNSELECT_PARENT_FAILURE,
    UNSELECT_PARENT_SUCCESS,
} from '../actionTypes';
import { multiCurrencyActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(multiCurrencyActions[UNSELECT_PARENT]());

    deleteItem(id)
        .then((response) => {
            dispatch(
                multiCurrencyActions[UNSELECT_PARENT_SUCCESS](response.data),
            );

            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                multiCurrencyActions[UNSELECT_PARENT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
