import { createSlice } from '@reduxjs/toolkit';

import { INITIALIZE, SAVE_IMAGE, SAVE_IMAGE_SUCCESS } from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    saveImage: {
        data: null,
        isSaving: false,
    },
};

const imageUploaderSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIALIZE]: (state) => {
            state.saveImage = {
                ...state.saveImage,
                data: null,
                isSaving: false,
            };
        },
        [SAVE_IMAGE]: (state) => {
            state.saveImage.isSaving = true;
        },
        [SAVE_IMAGE_SUCCESS]: (state, action) => {
            state.saveImage = {
                ...state.saveImage,
                data: action.payload,
                isSaving: false,
            };
        },
    },
});

export const imageUploaderActions = imageUploaderSlice.actions;

export default imageUploaderSlice.reducer;
