import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import EmptyState from 'common/components/EmptyState';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import locationForm from 'common/modules/locationForm';
import { LoadingContext } from 'common/utils/contexts';

import EmptyLayout from 'resources/EmptyLayout.png';

import * as actions from '../actions';

const ProjectLocationSection = ({
    canModify,
    canModifyLayout,
    defaultProjectLocation,
    handleCreateParentFieldSegments,
    isLocked,
    projectId,
    proposalId,
    savePosition,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [isShowLocationForm, showLocationForm] = useState(false);

    if (isShowLocationForm) {
        return (
            <locationForm.Container
                addressPlaceholder={t(
                    'Enter here your project address or zip code',
                )}
                canModify={canModify}
                center={defaultProjectLocation}
                editMode={true}
                handleSubmit={(values) => {
                    loadingContext.openLoading(
                        t('Saving change', { count: 2 }).concat('...'),
                    );
                    savePosition(
                        projectId,
                        proposalId,
                        values,
                        () => {
                            showLocationForm(false);
                            handleCreateParentFieldSegments();
                        },
                        () => {
                            loadingContext.closeLoading();
                        },
                    );
                }}
                isForLayout
                name="location-project"
                setEditMode={showLocationForm}
            />
        );
    }

    return (
        <EmptyState
            canModify={canModifyLayout}
            caption={t(
                'Plan, build confidence and increase your sales closings',
            )}
            disabled={isLocked}
            onClick={() => showLocationForm(true)}
            srcImage={EmptyLayout}
            sxCard={{ border: 0, boxShadow: 'none' }}
            textButton={t('Add')}
            title={t('Generate a preliminary sketch of the project!')}
        />
    );
};

ProjectLocationSection.propTypes = {
    canModify: PropTypes.bool,
    canModifyLayout: PropTypes.bool,
    defaultProjectLocation: PropTypes.object,
    handleCreateParentFieldSegments: PropTypes.func,
    isLocked: PropTypes.bool,
    projectId: PropTypes.string,
    proposalId: PropTypes.string,
    savePosition: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    savePosition: (
        projectId,
        proposalId,
        values,
        successCallback,
        failureCallback,
    ) =>
        dispatch(
            actions.savePosition(
                projectId,
                proposalId,
                values,
                successCallback,
                failureCallback,
            ),
        ),
});

export default compose(
    connect(null, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROJECT_LOCATION_PERMISSION),
)(ProjectLocationSection);
