import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'contacts';

export const auditContact = (id) => API.get(`/api/v1/audit/${ENTITY}/${id}`);
export const bulkContacts = (data) =>
    API.put(`/api/v1/${ENTITY}/bulk-status-flags/`, data, config);
export const bulkReasignAgent = (data) =>
    API.put(`/api/v1/${ENTITY}/bulk-reassign-agent/`, data, config);
export const createContact = (data) => API.post(`/api/v1/${ENTITY}/`, data);
export const exportFile = ({
    agentId = null,
    key,
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    personalized,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/export-upload/`, {
        params: personalized
            ? {
                  agent: agentId,
                  key,
                  order_by: orderBy,
                  page: page,
                  per_page: perPage,
                  personalized,
                  search: searchText,
                  ...(searchText ? { search: searchText } : {}),
                  sort: sortBy,
                  status_flag: status,
              }
            : {},
        headers: { 'Content-Type': 'text/csv' },
    });
export const filterContacts = ({
    agentId = null,
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            agent: agentId,
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            sort: sortBy,
            status_flag: status,
        },
    });

export const getContact = (id) => API.get(`/api/v1/${ENTITY}/${id}`);
export const getContactsCatalog = (searchText) =>
    API.get(`/api/v1/${ENTITY}/catalog/`, {
        params: {
            ...(searchText ? { search: searchText } : {}),
        },
    });
export const getContactsToReassign = (data) =>
    API.post(`/api/v1/${ENTITY}/bulk-reassign-agent/`, data, config);
export const getContactsWithPaginate = (page) =>
    API.get(`/api/v1/${ENTITY}/paginate`, { params: { page: page } });
export const getDuplicatedContact = (email) =>
    API.get(`/api/v1/${ENTITY}/exist-duplicate/`, { params: { email: email } });
export const importFile = (data) =>
    API.post(`/api/v1/${ENTITY}/export-upload/`, data);
export const markFavorite = (data) => API.post(`/api/v1/${ENTITY}-fav/`, data);
export const reassignContacts = (id, data) =>
    API.put(`/api/v1/audit/${ENTITY}/${id}`, data, config);
export const updateContact = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, data);
export const updateContactPartially = (data, id) =>
    API.patch(`/api/v1/contact/${id}/`, data, config);
export const updatePosition = (data, id) =>
    API.put(`/api/v1/${ENTITY}-position/${id}`, data);
export const updateType = (data, id) =>
    API.put(`/api/v1/${ENTITY}-type/${id}`, data);
