import isArray from 'lodash/isArray';

import { updateCompanyBranding } from 'common/api/v1/branding';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { setBranding } from '../../profile/actions';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { actions } from '../reducer';

import initializeForm from './initializeForm';

export default (values) => (dispatch) => {
    dispatch(actions[SAVE]());

    const {
        loading_image,
        logo_image,
        logotype_image,
        restore_to_default = false,
        ...rest
    } = values;
    const newValues = { ...rest };

    if (isArray(loading_image))
        newValues.loading_image =
            values.loading_image && values.loading_image[0];

    if (isArray(logo_image))
        newValues.logo_image = values.logo_image && values.logo_image[0];

    if (isArray(logotype_image))
        newValues.logotype_image =
            values.logotype_image && values.logotype_image[0];

    if (restore_to_default) {
        newValues.logo_image = null;
        newValues.logotype_image = null;
        newValues.loading_image = null;
    }

    updateCompanyBranding(newValues)
        .then((response) => {
            dispatch(actions[SAVE_SUCCESS](response.data));
            showToast();
            dispatch(setBranding(response.data.data));
            dispatch(initializeForm(response.data.data));
        })
        .catch((error) => {
            dispatch(actions[SAVE_FAILURE](error?.response?.data?.errors));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
