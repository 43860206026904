import { getAlliances } from 'common/api/v1/sunpay';

import {
    FETCH_FINANCIERS_INSTALLER,
    FETCH_FINANCIERS_INSTALLER_FAILURE,
    FETCH_FINANCIERS_INSTALLER_SUCCESS,
} from '../actionTypes';
import { alliancesActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(alliancesActions[FETCH_FINANCIERS_INSTALLER]());

    getAlliances()
        .then((response) =>
            dispatch(
                alliancesActions[FETCH_FINANCIERS_INSTALLER_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                alliancesActions[FETCH_FINANCIERS_INSTALLER_FAILURE](error),
            ),
        );
};
