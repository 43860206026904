import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getDeleteProduct = createSelector(
    getModel,
    (model) => model.deleteProduct,
);
export const getIsDeletingProduct = createSelector(
    getDeleteProduct,
    (model) => model.isDeleting,
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getIsOpenProductModal = createSelector(
    getModel,
    (model) => model.isOpenProductModal,
);

export const getProducts = createSelector(getModel, (model) => model.products);
export const getIsFetchingProducts = createSelector(
    getProducts,
    (model) => model.isFetching,
);
export const getProductsData = createSelector(
    getProducts,
    (model) => model?.data?.data,
);
export const getProductsPagination = createSelector(
    getProducts,
    (model) => model?.data?.pagination,
);

export const getSaveProduct = createSelector(
    getModel,
    (model) => model.saveProduct,
);
export const getIsSavingProduct = createSelector(
    getSaveProduct,
    (model) => model.isSaving,
);
export const getSaveProductData = createSelector(
    getSaveProduct,
    (model) => model.data,
);
export const getSaveProductErrors = createSelector(
    getSaveProduct,
    (model) => model.errors,
);

/* Templates */
export const getTemplateCatalogs = createSelector(
    getModel,
    (model) => model.templateCatalogs,
);

export const getIsFetchingTemplateCatalogs = createSelector(
    getTemplateCatalogs,
    (templateCatalogs) => templateCatalogs.isFetching,
);

export const getTemplateCatalogsData = createSelector(
    getTemplateCatalogs,
    (templateCatalogs) => templateCatalogs.data || [],
);

export const getTemplateCatalogsErrors = createSelector(
    getTemplateCatalogs,
    (templateCatalogs) => templateCatalogs.errors || [],
);
