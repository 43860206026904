import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider } from 'sunwise-ui';

import { TitleIcon } from 'common/components';
import { ReactHookFormColorPicker } from 'common/components/form/bootstrap';

const ThemeConfig = ({ control, disabled, group, title }) => {
    const { t } = useTranslation();

    return (
        <>
            <TitleIcon fontWeight="bold" title={title} variant="subtitle" />

            <Divider sx={{ mb: 2, mt: 1 }} />

            <ReactHookFormColorPicker
                control={control}
                disabled={disabled}
                fullWidth
                initialHexColor="#fff"
                label={t('Primary color')}
                name={`${group}_primary_color`}
            />

            <ReactHookFormColorPicker
                control={control}
                disabled={disabled}
                fullWidth
                initialHexColor="#fff"
                label={t('Primary contrast text')}
                name={`${group}_primary_contrast_text_color`}
            />

            <ReactHookFormColorPicker
                control={control}
                disabled={disabled}
                fullWidth
                initialHexColor="#fff"
                label={t('Secondary color')}
                name={`${group}_secondary_color`}
            />

            <ReactHookFormColorPicker
                control={control}
                disabled={disabled}
                fullWidth
                initialHexColor="#fff"
                label={t('Secondary contrast text')}
                name={`${group}_secondary_contrast_text_color`}
            />
        </>
    );
};

ThemeConfig.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    group: PropTypes.string,
    title: PropTypes.string,
};

export default ThemeConfig;
