import React from 'react';

import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import BranchOfficeFormatter from 'common/modules/multiBranches/components/BranchOfficeFormatter';
import {
    accesoryTypeToLabel,
    batteryTypeToLabel,
    inverterTypeToLabel,
    panelTypeToLabel,
} from 'common/utils/helpers';

import CostFormatter from './CostFormatter';
import CustomMarginEdit from './CustomMarginEdit';
import ProductActionsFormatter from './ProductActionsFormatter';

const ProductColumns = ({
    canDelete,
    canModify,
    filterData,
    handleClickBulkItems,
    handleClickDownloadDatasheet,
    handleClickUpdate,
    hiddenColumns,
    loadingContext,
    orders,
    productKey,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const codeColumn = {
        align: 'left',
        editable: canModify,
        field: 'code',
        flex: 1,
        headerName: t('Code'),
        hide: hiddenColumns.includes('code'),
        order: orders?.code,
        renderCell: (params) => {
            const { row } = params;
            return (
                <Typography
                    fontWeight="bold"
                    onClick={() => handleClickUpdate(row, true)}
                    sx={{ cursor: 'pointer' }}
                    variant="caption"
                >
                    {row.code}
                </Typography>
            );
        },
    };

    const nameColumn = {
        align: 'left',
        editable: canModify,
        field: 'name',
        flex: 1,
        headerName: t('Name'),
        hide: hiddenColumns.includes('name'),
        order: orders?.name,
        renderCell: (params) => {
            const { row } = params;
            return (
                <Typography
                    fontWeight="bold"
                    onClick={() => handleClickUpdate(row, true)}
                    sx={{ cursor: 'pointer' }}
                    variant="caption"
                >
                    {row.name}
                </Typography>
            );
        },
    };

    let columns = [
        {
            align: 'left',
            field: 'brand',
            flex: 1,
            headerName: t('Brand'),
            hide: hiddenColumns.includes('brand'),
            order: orders?.brand,
            valueGetter: (params) => params.row?.brand?.name,
        },
        codeColumn,
        {
            align: 'left',
            field: 'product_type',
            flex: 1,
            headerName: t('Type'),
            hide: hiddenColumns.includes('product_type'),
            order: orders?.product_type,
            renderCell: (params) => {
                const { row } = params;
                switch (productKey) {
                    case 'accessories':
                        return accesoryTypeToLabel(row.product_type);
                    case 'batteries':
                        return batteryTypeToLabel(row.product_type);
                    case 'inverters':
                        return inverterTypeToLabel(row.product_type);
                    case 'panels':
                        return panelTypeToLabel(row.product_type);
                    default:
                        return null;
                }
            },
        },
        {
            align: 'left',
            field: 'branch_office',
            flex: 1,
            headerName: t('Branch office'),
            hide: hiddenColumns.includes('branch_office'),
            order: orders?.branch_office,
            renderCell: (params) => {
                const { row } = params;
                return BranchOfficeFormatter(row.branch_office, row);
            },
        },
        nameColumn,
        {
            align: 'left',
            editable: canModify,
            field: 'margin',
            flex: 1,
            headerName: t('Margin'),
            hide: hiddenColumns.includes('margin'),
            order: orders?.margin,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption" fontWeight="bold">
                        {row.margin}
                    </Typography>
                );
            },
            renderEditCell: (params) => {
                return <CustomMarginEdit {...params} />;
            },
        },
        {
            align: 'left',
            editable: canModify,
            field: 'final_cost',
            flex: 1,
            headerName: t('Price'),
            hide: hiddenColumns.includes('final_cost'),
            order: orders?.final_cost,
            renderCell: (params) => {
                const { row } = params;
                return CostFormatter(row.final_cost, row);
            },
            renderEditCell: (params) => {
                const { row } = params;
                return CostFormatter(row.final_cost, row);
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = [codeColumn, nameColumn];
    }

    columns = [
        ...columns,
        {
            align: 'left',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ProductActionsFormatter
                        canDelete={canDelete}
                        canModify={canModify}
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickDownloadDatasheet={
                            handleClickDownloadDatasheet
                        }
                        handleClickUpdate={handleClickUpdate}
                        loadingContext={loadingContext}
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
        },
    ];

    return orderBy(columns, ['order'], ['asc']);
};

ProductColumns.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickDownloadDatasheet: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    hiddenColumns: PropTypes.array,
    loadingContext: PropTypes.object,
    orders: PropTypes.object,
    productKey: PropTypes.string,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductColumns;
