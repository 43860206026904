import React from 'react';

import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
    const { t } = useTranslation();
    return <>{t('Sorry, page not found')}</>;
};

export default PageNotFound;
