import React, { useEffect, useRef } from 'react';

import ListIcon from '@mui/icons-material/List';
import { styled } from '@mui/material/styles';
import { debounce, get } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, Typography } from 'sunwise-ui';

import { ElementNotFound, SwipeableDrawer } from 'common/components';
import AccordionCard from 'common/components/accordions/AccordionCard';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { useScrollTop } from 'common/hooks';
import SidebarIndicators from 'common/modules/proposalIndicators';
import { getCurrencyIso } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';
import { scroll } from 'common/utils/mixins';
import yupResolver from 'common/utils/yupResolver';

import * as profileSelectors from '../profile/selectors';
import ProposalGeneratorAdvancedTimeShiftSettings from '../proposalGeneratorAdvancedTimeShiftSettings';
import * as proposalGeneratorOneColumnSelectors from '../proposalGeneratorOneColumn/selectors';
import TimeShiftingConfiguration from '../timeShiftingConfiguration';

import * as actions from './actions';
import DistributionCard from './components/DistributionCard';
import Header from './components/Header';
import InvertersData from './components/InvertersData';
import StorageForm from './components/StorageForm';
import TooltipTitle from './components/TooltipTitle';
import Warnings from './components/Warnings';
import { DAY_TYPES } from './constants';
import { getDailyAverage, getPercentageByStorageSize } from './helpers';
import * as selectors from './selectors';
import validate from './validate';

const WrapperContent = styled(Grid)(({ theme }) => ({
    height: 'calc(100vh - 56px)',
    marginTop: 1,
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 120px)',
    },
    ...scroll.object,
}));

const FormContainer = ({
    batteries,
    commercialOfferData,
    compensationScheme,
    getPermissionsByCode,
    initialValues,
    initializeView,
    irradiationValues,
    isSavingStorageOffer,
    match,
    maxDailyConsumption,
    nextRateData,
    offerDetailsComplements,
    offerDetailsConsumption,
    offerValuesData,
    offerValuesErrors,
    offerValuesIsLoading,
    proposalConfiguration,
    save,
    subscription,
    totalsSolarSimulation,
    warnings,
}) => {
    const swipeableDrawerRef = useRef(null);
    const { t } = useTranslation();
    useScrollTop();

    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const { canModify: canModifyEnergyBackup } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_ENERGY_BACKUP_PERMISSION,
    );
    const { canModify: canModifyTimeShifting } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_TIMESHIFTING_PERMISSION,
    );

    const currencyIso = getCurrencyIso(
        offerDetailsComplements?.currency || 'USD',
    );
    const currencyLocale = getCountryCurrencyLocale();

    const { original_consumption } = offerDetailsConsumption || {};
    const { battery_offer, days_diff } = offerValuesData || {};

    useEffect(() => {
        if (original_consumption && days_diff && initialValues) {
            const total =
                initialValues?.base_day_type === DAY_TYPES.MAX
                    ? maxDailyConsumption
                    : getDailyAverage({
                          days: days_diff,
                          value: original_consumption,
                      });
            reset({
                ...initialValues,
                daily_storage_percentage: getPercentageByStorageSize({
                    storageSize: initialValues.storage_size,
                    total,
                }),
            });
        }
    }, [original_consumption, initialValues, days_diff, maxDailyConsumption]);

    useEffect(() => {
        if (match.params.id) initializeView(match.params.id);
    }, [match.params.id]);

    const handleOnClickSave = (calculatedValues, context) =>
        handleSubmit((values) =>
            save(match.params.id, { ...values, ...calculatedValues }, context),
        )();

    const handleOnChangeValue = useRef(
        debounce(handleOnClickSave, 1000),
    ).current;

    if (
        (!offerValuesIsLoading && !offerValuesData) ||
        [400, 404].includes(offerValuesErrors?.response?.status)
    )
        return <ElementNotFound buttonLabel={'Back to Dashboard'} />;

    const _disabled =
        offerValuesIsLoading ||
        isSavingStorageOffer ||
        commercialOfferData.status === COMMERCIAL_OFFER_STATUS.FINISHED.key;
    const isDisabled = !canModifyEnergyBackup || _disabled;
    const isDisabledTS = !canModifyTimeShifting || _disabled;

    const dataForCalculations = {
        batteryOffer: battery_offer,
        canModifyTimeShifting,
        diffDays: days_diff,
        maxDailyConsumption,
        originalConsumption: original_consumption,
    };

    const addons = get(subscription, 'addons', []);

    return (
        <>
            <Grid container spacing={0}>
                <WrapperContent item lg={14} md={12} xs={18} px={3}>
                    <Header proposalId={match.params.id} />
                    <Box mb={2}>
                        <AccordionCard
                            defaultExpanded
                            isLoading={
                                offerValuesIsLoading || isSavingStorageOffer
                            }
                            customTitle={
                                <TooltipTitle
                                    svgIcon={
                                        <ListIcon
                                            sx={{ color: 'primary.main' }}
                                        />
                                    }
                                    title={t('Product', { count: 2 })}
                                    tooltipContent={
                                        <Typography variant="body2">
                                            <Trans
                                                components={{
                                                    bold: <strong />,
                                                }}
                                            >
                                                This configurator provides
                                                average times of the duration in
                                                the storage system. Consult with
                                                your equipment provider the
                                                sizing of communication,
                                                voltage, current, power and
                                                maximum demand between{' '}
                                                <bold>
                                                    batteries - inverter.
                                                </bold>
                                            </Trans>
                                        </Typography>
                                    }
                                />
                            }
                        >
                            <Warnings
                                handleOnChangeValue={handleOnChangeValue}
                                initialValues={initialValues}
                                isDisabled={isDisabled}
                                saveContext={dataForCalculations}
                                warnings={warnings}
                            />

                            <InvertersData offerValuesData={offerValuesData} />

                            <StorageForm
                                batteries={batteries}
                                control={control}
                                dataForCalculations={dataForCalculations}
                                handleOnChangeValue={handleOnChangeValue}
                                isDisabled={isDisabled}
                                maxDailyConsumption={maxDailyConsumption}
                                setValue={setValue}
                            />
                        </AccordionCard>
                    </Box>

                    {addons.includes('TS-PS') && (
                        <>
                            <Box mb={2}>
                                <DistributionCard
                                    control={control}
                                    handleOnChangeValue={handleOnChangeValue}
                                    isDisabled={isDisabledTS}
                                    saveContext={dataForCalculations}
                                />
                            </Box>

                            <Box mb={2}>
                                <TimeShiftingConfiguration.Container
                                    isDisabled={isDisabledTS}
                                    initialRate={
                                        proposalConfiguration?.previous_rate_object
                                    }
                                    proposalId={match.params.id}
                                />
                            </Box>

                            <Box mb={2}>
                                <ProposalGeneratorAdvancedTimeShiftSettings.Container
                                    isDisabled={isDisabledTS}
                                    isLoading={offerValuesIsLoading}
                                    proposalId={match.params.id}
                                />
                            </Box>
                        </>
                    )}
                </WrapperContent>

                <WrapperContent
                    item
                    lg={4}
                    md={6}
                    pl={1}
                    pt={2}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                >
                    <SidebarIndicators.Container
                        batteriesOfferValues={offerValuesData}
                        compensationScheme={compensationScheme}
                        currencyIso={currencyIso}
                        currencyLocale={currencyLocale}
                        irradiationValues={irradiationValues}
                        isLoading={offerValuesIsLoading}
                        maxDailyConsumption={maxDailyConsumption}
                        nextRateData={nextRateData}
                        offerDetailsComplements={offerDetailsComplements}
                        offerDetailsConsumption={offerDetailsConsumption}
                        proposalConfiguration={proposalConfiguration}
                        proposalId={match.params.id}
                        totalsSolarSimulation={totalsSolarSimulation}
                    />
                </WrapperContent>
            </Grid>

            <SwipeableDrawer ref={swipeableDrawerRef}>
                <SidebarIndicators.Container
                    batteriesOfferValues={offerValuesData}
                    compensationScheme={compensationScheme}
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    irradiationValues={irradiationValues}
                    isLoading={offerValuesIsLoading}
                    maxDailyConsumption={maxDailyConsumption}
                    nextRateData={nextRateData}
                    offerDetailsComplements={offerDetailsComplements}
                    offerDetailsConsumption={offerDetailsConsumption}
                    proposalConfiguration={proposalConfiguration}
                    proposalId={match.params.id}
                />
            </SwipeableDrawer>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    batteries: selectors.getBatteriesData,
    commercialOfferData:
        proposalGeneratorOneColumnSelectors.getOfferDetailsCommercialOffer,
    compensationScheme:
        proposalGeneratorOneColumnSelectors.getCompensationScheme,
    initialValues: selectors.getInitialValues,
    irradiationValues:
        proposalGeneratorOneColumnSelectors.getDataOfferIrradiationValues,
    isSavingStorageOffer: selectors.getIsSaving,
    maxDailyConsumption:
        proposalGeneratorOneColumnSelectors.getMaxDailyConsumption,
    nextRateData: proposalGeneratorOneColumnSelectors.getNextRateData,
    offerDetailsComplements:
        proposalGeneratorOneColumnSelectors.getOfferDetailsComplements,
    offerDetailsConsumption:
        proposalGeneratorOneColumnSelectors.getOfferDetailsConsumption,
    offerValuesData: selectors.getOfferValuesData,
    offerValuesErrors: selectors.getOfferValuesErrors,
    offerValuesIsLoading: selectors.getOfferValuesIsLoading,
    proposalConfiguration:
        proposalGeneratorOneColumnSelectors.getProposalConfiguration,
    subscription: profileSelectors.getSubscription,
    totalsSolarSimulation:
        proposalGeneratorOneColumnSelectors.getTotalsSolarSimulation,
    warnings: selectors.getOfferValuesWarnings,
});

const mapDispatchToProps = (dispatch) => ({
    initializeView: (commercialOfferId) =>
        dispatch(actions.initializeView(commercialOfferId)),
    save: (commercialOfferId, values, offerValuesData) =>
        dispatch(actions.save(commercialOfferId, values, offerValuesData)),
});

FormContainer.propTypes = {
    batteries: PropTypes.array,
    commercialOfferData: PropTypes.object,
    compensationScheme: PropTypes.object,
    getPermissionsByCode: PropTypes.func,
    initialValues: PropTypes.object,
    initializeView: PropTypes.func,
    irradiationValues: PropTypes.object,
    isSavingStorageOffer: PropTypes.bool,
    match: PropTypes.object,
    maxDailyConsumption: PropTypes.number,
    nextRateData: PropTypes.object,
    offerDetailsComplements: PropTypes.object,
    offerDetailsConsumption: PropTypes.object,
    offerValuesData: PropTypes.object,
    offerValuesErrors: PropTypes.object,
    offerValuesIsLoading: PropTypes.bool,
    proposalConfiguration: PropTypes.object,
    save: PropTypes.func,
    subscription: PropTypes.object,
    totalsSolarSimulation: PropTypes.object,
    warnings: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.PROPOSAL_ENERGY_BACKUP_PERMISSION,
        PERMISSION_LIST.PROPOSAL_TIMESHIFTING_PERMISSION,
    ]),
    withRouter,
)(FormContainer);
