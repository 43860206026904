import { styled } from '@mui/material/styles';
import { Grid } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

export default styled(Grid)`
    overflow-x: hidden;
    overflow-y: scroll;

    ${scroll.custom()}
`;
