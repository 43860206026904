import React, { useState, useEffect } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip, Typography } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import ProjectActionsDropdown from './ProjectActionsDropdown';

const Wrapper = styled(Box)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px;
`,
);

const ProjectResponsiveItem = ({
    canDelete,
    canModify,
    date,
    documents,
    handleClickArchive,
    handleClickRename,
    handleClickView,
    isArchived,
    isSelected,
    rate,
    statusProject,
    title,
}) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState({
        color: 'transparent',
        icon: null,
        key: '',
        label: t('Select status'),
        value: t('Select status'),
    });

    useEffect(() => {
        if (statusProject) {
            setSelected({
                color: statusProject.primary_color,
                key: statusProject.id,
                value: statusProject.name,
            });
        }
    }, []);

    const createdAt = formatDate(
        parseDate(date, 'dd/MM/yyyy HH:mm:ss'),
        getDateFormatByLocale(),
    );

    return (
        <Wrapper
            sx={{
                border: (theme) =>
                    ` 1px solid ${
                        isSelected ? theme.palette.primary.main : 'transparent'
                    }`,
                display: { xs: 'block', md: 'none' },
            }}
        >
            <Grid container pl={2} pb={2}>
                <Grid
                    alignItems="center"
                    item
                    onClick={handleClickView}
                    sx={{ cursor: 'pointer', display: 'flex', gap: 1 }}
                    xs={15}
                >
                    <Tooltip title={selected.value}>
                        <CircleIcon
                            sx={{
                                color: selected.color,
                                height: '11px',
                                width: '11px',
                            }}
                        />
                    </Tooltip>

                    <Tooltip title={title}>
                        <Typography
                            fontWeight="bold"
                            noWrap
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 'auto',
                                fontSize: '14px',
                            }}
                            variant="body2"
                        >
                            {title}
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item textAlign="right" xs={3}>
                    <ProjectActionsDropdown
                        canDelete={canDelete}
                        canModify={canModify}
                        handleClickArchive={handleClickArchive}
                        handleClickRename={handleClickRename}
                        isArchived={isArchived}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'auto',
                            fontSize: '14px',
                        }}
                    >
                        {rate || '----'}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    {documents > 0 ? (
                        <Typography
                            noWrap
                            variant="body2"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontSize: '14px',
                            }}
                        >
                            {t('{{count}} proposal', { count: documents })}
                        </Typography>
                    ) : (
                        <Typography
                            noWrap
                            variant="body2"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 'auto',
                                fontSize: '14px',
                            }}
                        >
                            {t('No proposals')}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2">{createdAt}</Typography>
                </Grid>
            </Grid>
        </Wrapper>
    );
};

ProjectResponsiveItem.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    date: PropTypes.string,
    documents: PropTypes.number,
    handleClickArchive: PropTypes.func,
    handleClickRename: PropTypes.func,
    handleClickView: PropTypes.func,
    isArchived: PropTypes.bool,
    isSelected: PropTypes.bool,
    rate: PropTypes.string,
    statusProject: PropTypes.object,
    title: PropTypes.string,
};

export default ProjectResponsiveItem;
