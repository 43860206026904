import { INITIALIZE_FORM } from '../actionTypes';
import { creditBureauCardActions } from '../reducer';

import resetForm from './resetForm';

export default (values) => (dispatch) => {
    dispatch(resetForm());
    const newValues = {
        city: values.address.city,
        email: values.email,
        first_last_name: values.first_last_name,
        first_name: values.first_name,
        rfc: values.username,
        second_last_name: values.second_last_name,
        second_name: values.second_name,
        state: values.address.state,
        street: values.address.street,
        suburb: values.address.suburb,
        zipcode: values.address.zipcode,
    };

    dispatch(creditBureauCardActions[INITIALIZE_FORM](newValues));
};
