import React from 'react';

import PropTypes from 'prop-types';
import { Box, Card } from 'sunwise-ui';

import DashboardCardHeader from 'common/components/cards/DashboardCardHeader';
import PlaceholderText from 'common/components/placeholder/PlaceholderText';

import LabelAppend from './LabelAppend';
import LabelTotal from './LabelTotal';

const CardKeyValue = ({
    append,
    id,
    prepend,
    readyPlaceholder,
    title,
    value,
    visible = true,
}) => {
    if (!visible) return null;
    return (
        <Card id={id}>
            <Card.Header>
                <DashboardCardHeader title={title} height="auto" />
            </Card.Header>

            <Card.Body>
                <PlaceholderText ready={readyPlaceholder}>
                    <Box sx={{ textAlign: 'center' }}>
                        {prepend !== null && (
                            <LabelAppend>{prepend}</LabelAppend>
                        )}
                        {value !== null && <LabelTotal>{value}</LabelTotal>}
                        {append !== null && <LabelAppend>{append}</LabelAppend>}
                    </Box>
                </PlaceholderText>
            </Card.Body>
        </Card>
    );
};

CardKeyValue.propTypes = {
    append: PropTypes.string,
    id: PropTypes.string,
    prepend: PropTypes.string,
    readyPlaceholder: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.string,
    visible: PropTypes.bool,
};

export default CardKeyValue;
