import React, { useState, useEffect } from 'react';

import { push } from 'connected-react-router';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import reportTemplateReview from '../reportTemplateReview';

import * as actions from './actions';
import * as selectors from './selectors';

const Container = ({
    canModify,
    fetchReportDetails,
    handleClickBreadcrumb,
    isFetchingReportDetails,
    match,
    reportDetails,
}) => {
    const { t } = useTranslation();
    const [editionDisabled, setEditionDisabled] = useState(true);

    useEffect(() => {
        fetchReportDetails(match.params.uid);
    }, []);

    if (isFetchingReportDetails) return null;

    return (
        <reportTemplateReview.Container
            breadcrumbLocations={[
                {
                    label: get(reportDetails, 'project.contact.name', null),
                    action: () =>
                        handleClickBreadcrumb(
                            get(reportDetails, 'project.contact.id', null),
                        ),
                },
                {
                    label: get(reportDetails, 'project.name', null),
                    action: () =>
                        handleClickBreadcrumb(
                            get(reportDetails, 'project.contact.id', null),
                            get(reportDetails, 'project.id', null),
                        ),
                },
                {
                    label: get(reportDetails, 'name', null),
                    action: () =>
                        handleClickBreadcrumb(null, null, match.params.uid),
                },
            ]}
            canModify={canModify}
            currentBreadcrumb={t('Preview')}
            editionDisabled={editionDisabled}
            isFetching={isFetchingReportDetails}
            reportDetails={reportDetails}
            setEditionDisabled={setEditionDisabled}
            templateType={types.REPORT_TYPE ?? 3}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    reportDetails: selectors.getReportDetailsData,
    isFetchingReportDetails: selectors.getIsFetchingReportDetails,
});

const mapDispatchToProps = (dispatch) => ({
    fetchReportDetails: (id) => dispatch(actions.fetchReportDetails(id)),
    handleClickBreadcrumb: (contactId, projectId, reportId) =>
        dispatch(actions.handleClickBreadcrumb(contactId, projectId, reportId)),
    redirect: (link) => dispatch(push(link)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    fetchReportDetails: PropTypes.func,
    handleClickBreadcrumb: PropTypes.func,
    isFetchingReportDetails: PropTypes.bool,
    match: PropTypes.object,
    reportDetails: PropTypes.object,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.AFTER_SALES_PERMISSION),
    withRouter,
)(Container);
