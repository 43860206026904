import React from 'react';

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Tooltip } from 'sunwise-ui';

import StatusIndicatorTooltip from 'common/components/StatusIndicatorTooltip';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';

import StyledName from './StyledName';

const ReportsNameFormatter = ({ row }) => {
    const isGenerated = row.status === COMMERCIAL_OFFER_STATUS.FINISHED.key;
    const to = isGenerated
        ? `/report-review-pro/${row.id}`
        : `/report/${row.id}`;

    return (
        <Link color="inherit" component={RouterLink} to={to} underline="none">
            <Box
                alignItems="center"
                display="flex"
                gap={1}
                sx={{ cursor: 'pointer' }}
            >
                <StatusIndicatorTooltip isGenerated={isGenerated} />
                <Tooltip title={row?.name} placement="top">
                    <StyledName
                        variant="caption"
                        fontWeight="bold"
                        sx={{
                            width: '250px',
                            maxWidth: '250px',
                        }}
                    >
                        {row?.name}
                    </StyledName>
                </Tooltip>
            </Box>
        </Link>
    );
};

ReportsNameFormatter.propTypes = {
    row: PropTypes.object,
};

export default ReportsNameFormatter;
