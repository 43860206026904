import fetchContacts from './fetchContacts';
import fetchProjects from './fetchProjects';
import fetchProposals from './fetchProposals';

export default (values) => (dispatch) => {
    const { context, page, q } = values;
    if (!q) return;

    switch (context) {
        case 'contacts':
            return dispatch(fetchContacts(q, page));
        case 'projects':
            return dispatch(fetchProjects(q, page));
        case 'proposals':
            return dispatch(fetchProposals(q, page));
        default:
            return;
    }
};
