import React from 'react';

import LinkIcon from '@mui/icons-material/Link';
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import { LabelDecorator, TitleWithDetail } from 'common/components';
import AccordionCard from 'common/components/accordions/AccordionCard';
import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';

const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#FF7043',
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: '#FFC107',
    },
}));

const DistributionCard = ({
    control,
    handleOnChangeValue,
    isDisabled,
    saveContext,
}) => {
    const { t } = useTranslation();
    const backupPercentage = useWatch({ control, name: 'storage_percent' });

    const handleOnChangeDistribution = (storage_percent) =>
        handleOnChangeValue({ storage_percent }, saveContext);

    return (
        <AccordionCard
            defaultExpanded
            customTitle={
                <TitleWithDetail variant="body2">
                    {t('Battery energy distribution')}
                </TitleWithDetail>
            }
        >
            <Grid container px={2}>
                <Grid item sm={7} xs={18} textAlign="center">
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        decimalsLimit={2}
                        disabled={isDisabled}
                        fullWidth
                        label={
                            <Box display="flex" alignItems="center" gap={2}>
                                <LabelDecorator color="#FFC107" />
                                {t('Energy backup')}
                            </Box>
                        }
                        min={0}
                        name="storage_percent"
                        onChange={({ target: { value } }) =>
                            handleOnChangeDistribution(value)
                        }
                        variant="standard"
                    />
                </Grid>
                <Grid
                    item
                    mt={1}
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                    textAlign="center"
                    xs={4}
                >
                    <LinkIcon fontSize="medium" />
                </Grid>
                <Grid item sm={7} xs={18} textAlign="center">
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        decimalsLimit={2}
                        disabled={isDisabled}
                        fullWidth
                        label={
                            <Box display="flex" alignItems="center" gap={2}>
                                <LabelDecorator color="#FF7043" />
                                Peak Shaving & Time Shifting
                            </Box>
                        }
                        max={100}
                        min={0}
                        name="time_shifting_percentage"
                        onChange={({ target: { value } }) =>
                            handleOnChangeDistribution(100 - value)
                        }
                        variant="standard"
                    />
                </Grid>
            </Grid>

            <Box p={2} sx={{ flexGrow: 1 }}>
                <BorderLinearProgress
                    variant="determinate"
                    value={Number(backupPercentage)}
                />
            </Box>
        </AccordionCard>
    );
};

DistributionCard.propTypes = {
    control: PropTypes.object,
    handleOnChangeValue: PropTypes.func,
    isDisabled: PropTypes.bool,
    saveContext: PropTypes.object,
};

export default DistributionCard;
