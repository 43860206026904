import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import SingleAutocomplete from '../../../common/components/SingleAutocomplete';

const FilterOptions = ({ handleOnFilter, options }) => {
    const { t } = useTranslation();

    return (
        <Box alignItems="center" display="flex" gap={2} mb={2}>
            <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
                {t('Filter by')}:
            </Typography>

            <SingleAutocomplete
                fullWidth
                label={t('Year')}
                onChange={(option) => handleOnFilter(option?.value)}
                options={options}
                variant="standard"
            />
        </Box>
    );
};

FilterOptions.propTypes = {
    handleOnFilter: PropTypes.func,
    options: PropTypes.array,
};

export default FilterOptions;
