import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import CollapsedBreadcrumbs from 'common/components/CollapsedBreadcrumbs';

const BranchOfficeName = ({
    handleClickBreadcrumb,
    offerDetails,
    offerDetailsPattern,
}) => {
    if (!get(offerDetailsPattern, 'contact.id', null)) return null;
    return (
        <Box sx={{ width: '100%' }}>
            <CollapsedBreadcrumbs
                current={get(offerDetails, 'name', null)}
                locations={[
                    {
                        label: get(offerDetailsPattern, 'contact.name', null),
                        action: () =>
                            handleClickBreadcrumb(
                                get(offerDetailsPattern, 'contact.id', null),
                            ),
                    },
                    {
                        label: get(offerDetailsPattern, 'name', null),
                        action: () =>
                            handleClickBreadcrumb(
                                get(offerDetailsPattern, 'contact.id', null),
                                get(offerDetailsPattern, 'project_id', null),
                            ),
                    },
                ]}
            />
        </Box>
    );
};

BranchOfficeName.propTypes = {
    handleClickBreadcrumb: PropTypes.func,
    offerDetails: PropTypes.object,
    offerDetailsPattern: PropTypes.object,
};

export default BranchOfficeName;
