import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

const AvgConsumptionWarning = ({ hasAvgConsumptionWarning }) => {
    const { t } = useTranslation();
    if (!hasAvgConsumptionWarning) return null;
    return (
        <Alert severity="warning">
            {t(
                'The total consumption is significantly different from the average consumption of the project, we suggest you manually validate this inconsistency',
            )}
        </Alert>
    );
};

AvgConsumptionWarning.propTypes = {
    hasAvgConsumptionWarning: PropTypes.bool,
};

export default AvgConsumptionWarning;
