import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH,
    FETCH_FAILURE,
    FETCH_SUCCESS,
    FETCH_GEOCODE,
    FETCH_GEOCODE_ERROR,
    FETCH_GEOCODE_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_IS_NEW_RECORD,
    SET_SHOW_GEOCODE_RESULTS,
    FETCH_GEOCODE_POINT,
    FETCH_GEOCODE_POINT_FAILURE,
    FETCH_GEOCODE_POINT_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetch: {
        data: {
            currency: null,
        },
        errors: null,
        isFetching: false,
    },
    fetchGeocode: {
        data: [],
        errors: null,
        isLoading: false,
    },
    initialValues: {
        business_activity: '',
        business_name: '',
        city: '',
        contact_cellphone: '',
        contact_email: '',
        contact_telephone: '',
        cp: '',
        currency: '',
        description: '',
        id: '',
        image: '',
        latitude: '',
        longitude: '',
        name: '',
        political_division1: '',
        position: {},
        rfc: '',
        web_page: '',
    },
    isNewRecord: true,
    save: {
        data: {},
        errors: null,
        isSaving: false,
    },
    showGeocodeResults: false,
    fetchGeocodePoint: {
        isFetching: false,
        data: null,
        errors: null,
    },
};

const companyGeneralSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH]: (state) => {
            state.fetch = { ...state.fetch, errors: [], isFetching: true };
        },
        [FETCH_FAILURE]: (state, action) => {
            state.fetch = {
                ...state.fetch,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUCCESS]: (state, action) => {
            state.fetch = {
                ...state.fetch,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_GEOCODE]: (state) => {
            state.fetchGeocode = {
                ...state.fetchGeocode,
                errors: [],
                isLoading: true,
            };
        },
        [FETCH_GEOCODE_ERROR]: (state, action) => {
            state.fetchGeocode = {
                ...state.fetchGeocode,
                data: [],
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_GEOCODE_SUCCESS]: (state, action) => {
            state.fetchGeocode = {
                ...state.fetchGeocode,
                data: action.payload,
                errors: [],
                isLoading: false,
            };
        },
        [FETCH_GEOCODE_POINT]: (state) => {
            state.fetchGeocodePoint = {
                ...state.fetchGeocodePoint,
                data: null,
                isFetching: true,
            };
        },
        [FETCH_GEOCODE_POINT_FAILURE]: (state, action) => {
            state.fetchGeocodePoint = {
                ...state.fetchGeocodePoint,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_GEOCODE_POINT_SUCCESS]: (state, action) => {
            state.fetchGeocodePoint = {
                ...state.fetchGeocodePoint,
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SET_SHOW_GEOCODE_RESULTS]: (state, action) => {
            state.showGeocodeResults = action.payload;
        },
    },
});

export const companyGeneralActions = companyGeneralSlice.actions;

export default companyGeneralSlice.reducer;
