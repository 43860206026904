import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Card } from 'sunwise-ui';

const Wrapper = styled(Card)`
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    padding: 16px;
    position: relative;

    &.active,
    &.selected,
    &:hover {
        box-shadow: 0 0 0 1px #2845ff;
    }
`;

const CatalogsCard = ({ onClick, selected, sxCard, title, visible }) => {
    if (!visible) return null;
    return (
        <Wrapper
            className={selected ? 'selected' : ''}
            onClick={onClick}
            sx={sxCard}
        >
            {title}
        </Wrapper>
    );
};

CatalogsCard.defaultProps = {
    sxCard: {},
    visible: true,
};

CatalogsCard.propTypes = {
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    sxCard: PropTypes.object,
    title: PropTypes.string,
    visible: PropTypes.bool,
};

export default CatalogsCard;
