import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { ColorPicker } from 'common/components/form';

const ReactHookFormColorPicker = ({
    control,
    disabled,
    initialHexColor,
    label,
    name,
}) => (
    <Controller
        control={control}
        name={name}
        render={({ field }) => (
            <ColorPicker
                disabled={disabled}
                fullWidth
                initialHexColor={initialHexColor}
                input={field}
                label={label}
            />
        )}
    />
);

ReactHookFormColorPicker.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    initialHexColor: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.string,
};

export default ReactHookFormColorPicker;
