import get from 'lodash/get';

import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

import {
    getBranchOfficesAvailable,
    getBranchOfficesDictionaryOptions,
    getBranchOfficesToString,
    getBranchOfficesWithoutGlobal,
} from '../helpers';

import initializeForm from './initializeForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModalProduct from './toggleModalProduct';

export default (item) => (dispatch, getState) => {
    const branchOffices = getBranchOfficesAvailable(item?.branch_offices);
    const branchOfficesWG = getBranchOfficesWithoutGlobal(item?.branch_offices);
    const branchSelected = multiBranchesSelectors.getBranchSelected(getState());
    const isMultiBranchesUser = getIsMultibranchesAccount();

    let values = {
        birth_date: item.birth_date,
        company_group: item.company_group,
        company: item.company,
        email: item.email,
        first_name: item.first_name,
        has_all_branchoffices: get(
            item,
            'settings.has_all_branchoffices',
            false,
        ),
        has_limited_contacts: get(item, 'settings.has_limited_contacts', false),
        id: item.id,
        is_enabled: item.is_enabled,
        last_name: item.last_name,
        phone: item.phone,
        position: item.position,
        role_level_initial: item.role_level,
        role_level: item.role_level,
        second_surname: item.second_surname,
        user_branch_offices: getBranchOfficesDictionaryOptions(branchOfficesWG),
    };

    if (isMultiBranchesUser) {
        values.branch_office = item.branch_office || branchOffices[0]?.id;
        values.branch_offices = getBranchOfficesToString(branchOffices);
    } else {
        values.branch_office = item.branch_office || branchSelected;
        values.branch_offices = item.branch_office || branchSelected;
    }

    dispatch(setIsNewRecord(false));
    dispatch(initializeForm(values));
    dispatch(toggleModalProduct(true));
};
