import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

const Container = styled(Box)`
    display: ${({ isVisible = true }) => (isVisible ? 'flex' : 'none')};
    flex-grow: ${({ grow = '0' }) => grow};
    flex-wrap: ${({ wrap = 'nowrap' }) => wrap};
    ${({ padding }) => padding && `padding: ${padding};`}
    ${({ margin }) => margin && `margin: ${margin};`}
    ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
    ${({ justifyContent }) =>
        justifyContent && `justify-content: ${justifyContent};`}
`;

export const FlexRow = styled(Container)`
    flex-direction: row;
`;
