import isEmpty from 'lodash/isEmpty';

import { numberFormat } from 'common/utils/helpers';

const COLORS = ['#0073d0', '#03e396'];

export const getChartSettings = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    labels,
    series,
} = {}) => {
    const data = {
        datasets: series.map(({ data, name }, index) => ({
            backgroundColor: COLORS[index],
            data,
            label: name,
            yAxisID: `y${index}`,
        })),
        labels,
    };

    const options = {
        scales: {
            x: { grid: { display: false } },
            y0: {
                border: { color: COLORS[0] },
                ticks: {
                    callback: (value) =>
                        numberFormat(value, {
                            currency: currencyIso,
                            locale: currencyLocale,
                            style: 'currency',
                            symbol: currencySymbol,
                        }),
                },
            },
            y1: {
                border: { color: COLORS[1] },
                grid: { drawOnChartArea: false },
                position: 'right',
                ticks: {
                    callback: (value) =>
                        numberFormat(value, {
                            decimals: 0,
                            locale: currencyLocale,
                            style: 'decimal',
                            unit: 'kW',
                        }),
                },
            },
        },
    };

    return { data, options };
};

export const getProjectsChartSettings = ({
    currencyLocale,
    labels,
    series,
} = {}) => {
    const data = {
        datasets: [series].map(({ data, name }) => ({
            backgroundColor: COLORS[0],
            data,
            label: name,
        })),
        labels,
    };

    const options = {
        scales: {
            x: { grid: { display: false } },
            y: {
                border: { color: COLORS[0] },
                ticks: {
                    callback: (value) =>
                        numberFormat(value, {
                            decimals: 0,
                            locale: currencyLocale,
                            style: 'decimal',
                        }),
                },
            },
        },
    };

    return { data, options };
};

export const getReadyPlaceholder = (defaultCurrency, isLoading) =>
    isLoading || isEmpty(defaultCurrency);
