import React, { useContext, useEffect } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    BottomActions,
    Box,
    Button,
    Grid,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { LoadingContext } from 'common/utils/contexts';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import ChartJsComponent from './ChartJsComponent';

const ConsumptionProfileForm = ({
    initialValues,
    isSavingConsumptionProfile,
    modalIsOpen,
    resetModalForm,
    saveConsumptionProfile,
    saveConsumptionProfileErrors,
    setModalIsOpen,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    const [consumptionFormatted, year] = watch([
        'consumption_formatted',
        'year',
    ]);
    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingConsumptionProfile)
            loadingContext.openLoading(t('Saving').concat('...'));
        else loadingContext.closeLoading();
    }, [isSavingConsumptionProfile]);

    const onSubmit = (values) => saveConsumptionProfile(values);

    return (
        <Dialog
            onClose={() => setModalIsOpen(false)}
            onExited={() => resetModalForm()}
            open={modalIsOpen}
            size="xl"
            title={`${t('Add')} ${t('Profile').toLocaleLowerCase()}`}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={18} md={12}>
                        <ReactHookFormInput
                            control={control}
                            name="name"
                            label={t('Profile name')}
                        />
                    </Grid>
                    <Grid item xs={18} md={6}>
                        <ReactHookFormSelect
                            control={control}
                            name="usage_type"
                            label={t('Profile type')}
                            options={[
                                {
                                    label: t('Residential'),
                                    value: 0,
                                },
                                {
                                    label: t('Commercial'),
                                    value: 1,
                                },
                                {
                                    label: t('Industrial'),
                                    value: 2,
                                },
                            ]}
                        />
                    </Grid>
                </Grid>

                <Box mb={2}>
                    <ChartJsComponent
                        consumptionProfile={consumptionFormatted}
                        year={year}
                    />
                </Box>

                <ShowErrors errors={saveConsumptionProfileErrors} />

                <BottomActions>
                    <Button sx={{ mt: 2 }} type="submit">
                        {t('Add')}
                    </Button>
                </BottomActions>
            </form>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isSavingConsumptionProfile: selectors.getIsSavingConsumptionProfile,
    modalIsOpen: selectors.getModalIsOpen,
    saveConsumptionProfileErrors: selectors.getSaveConsumptionProfileErrors,
});

const mapDispatchToProps = (dispatch) => ({
    resetModalForm: () => dispatch(actions.resetModalForm()),
    saveConsumptionProfile: (values) =>
        dispatch(actions.saveConsumptionProfile(values)),
    setModalIsOpen: (isOpen) => dispatch(actions.setModalIsOpen(isOpen)),
});

ConsumptionProfileForm.propTypes = {
    initialValues: PropTypes.object,
    isSavingConsumptionProfile: PropTypes.bool,
    modalIsOpen: PropTypes.bool,
    resetModalForm: PropTypes.func,
    saveConsumptionProfile: PropTypes.func,
    saveConsumptionProfileErrors: PropTypes.array,
    setModalIsOpen: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConsumptionProfileForm);
