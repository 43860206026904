import { getTemplates } from 'common/api/v1/templates';

import {
    FETCH_OFFER_TEMPLATES,
    FETCH_OFFER_TEMPLATES_FAILURE,
    FETCH_OFFER_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { proposalSummaryActions } from '../reducer';

export default (branchOffice, type) => (dispatch) => {
    dispatch(proposalSummaryActions[FETCH_OFFER_TEMPLATES]());

    getTemplates({
        branch_office_id: branchOffice,
        orderBy: 'title',
        sortBy: 'asc',
        type,
    })
        .then((response) => {
            const data = response.data.results.map((template) => ({
                ...template,
                id: template.external_uuid,
            }));

            dispatch(
                proposalSummaryActions[FETCH_OFFER_TEMPLATES_SUCCESS](data),
            );
        })
        .catch((error) =>
            dispatch(
                proposalSummaryActions[FETCH_OFFER_TEMPLATES_FAILURE](error),
            ),
        );
};
