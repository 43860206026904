import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import CollapsedBreadcrumbs from 'common/components/CollapsedBreadcrumbs';

const BranchOfficeName = ({ handleClickBreadcrumb, pl, reportDetails }) => {
    return (
        <Box sx={{ width: '100%', pl: { md: pl } }}>
            <CollapsedBreadcrumbs
                current={get(reportDetails, 'name', '')}
                locations={[
                    {
                        label: get(reportDetails, 'project.contact.name', ''),
                        action: () =>
                            handleClickBreadcrumb(
                                get(reportDetails, 'project.contact.id', null),
                            ),
                    },
                    {
                        label: get(reportDetails, 'project.name', ''),
                        action: () =>
                            handleClickBreadcrumb(
                                get(reportDetails, 'project.contact.id', null),
                                get(reportDetails, 'project.id', null),
                            ),
                    },
                ]}
            />
        </Box>
    );
};

BranchOfficeName.propTypes = {
    handleClickBreadcrumb: PropTypes.func,
    pl: PropTypes.number,
    reportDetails: PropTypes.object,
};

export default BranchOfficeName;
