import { saveRateTaxes } from 'common/api/v2/rateTaxes';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import {
    SAVE_RATE_TAXES,
    SAVE_RATE_TAXES_FAILURE,
    SAVE_RATE_TAXES_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (values, commercialOfferId) => (dispatch) => {
    if (!commercialOfferId || !values) return;

    dispatch(actions[SAVE_RATE_TAXES]());

    const newValues = { ...values };

    if (values.showPowerFactor) {
        newValues.apply_desired_power_factor =
            values.apply_desired_power_factor;
        newValues.desired_power_factor = values.desired_power_factor || 0;
    }

    saveRateTaxes(newValues, commercialOfferId)
        .then((response) => {
            const data = response?.data?.data;
            dispatch(eventsAfterUpdate(data, commercialOfferId));
            dispatch(actions[SAVE_RATE_TAXES_SUCCESS](data));
        })
        .catch((error) => {
            dispatch(actions[SAVE_RATE_TAXES_FAILURE](error));

            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
