import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getBranches = createSelector(getModel, (model) => model.branches);

export const getBranchesData = createSelector(
    getBranches,
    (branches) => branches.data,
);

export const getFilteredBranchesData = createSelector(
    getBranchesData,
    (branches) =>
        branches?.filter((item) => !['all', 'company'].includes(item.id)),
);

export const getIsFetchingBranches = createSelector(
    getBranches,
    (branches) => branches.isFetching,
);

export const getBranchSelected = createSelector(
    getModel,
    (model) => model.branchSelected,
);

export const getBranchesItemsData = createSelector(
    getBranchesData,
    getBranchSelected,
    (data, branchSelected) => {
        return data.map((item) => ({
            disabled: item.id === branchSelected,
            label: item.name,
            value: item.id,
        }));
    },
);

export const getBranchesItemsSelect = createSelector(getBranchesData, (data) =>
    data
        .filter((item) => item.id !== 'all')
        .map((item) => ({
            label: item.name,
            value: item.id,
        })),
);

export const getBranchesDictionary = createSelector(getBranchesData, (data) => {
    return data
        .filter((item) => item.id !== 'all')
        .reduce((acc, current) => {
            return { ...acc, [current.id]: current.name };
        }, {});
});
