import BaseModel from './BaseModel';
export default class CommercialOffer extends BaseModel {
    setCreditApplicationStatus(status) {
        return this.update({
            creditApplication: {
                ...this.ref.creditApplication,
                status,
            },
        });
    }
}

CommercialOffer.modelName = 'CommercialOffer';
CommercialOffer.prepareFields({
    createdAt: 'created_at',
    creditApplication: 'credit_application',
    creditApplicationId: 'credit_application_id',
    creditItem: 'credit_item',
    currency: 'currency',
    id: 'id',
    isArchived: 'is_archived',
    proposalSummaryLinked: 'proposal_summary_linked',
    total: 'total',
    type: 'type',
    status: 'status',
});
