import isNil from 'lodash/isNil';
import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (values) => {
    const { NUMBER_TYPE_TEXT, REQUIRED_TEXT, getMinValueText } =
        getValidationTexts();
    return object({
        name: string().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema.required(REQUIRED_TEXT).nullable();
        }),
        opacity: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .nullable();
        }),
        rotate: number().when('id', (value, schema) => {
            if (!isNil(values.idRequired) && value !== values.idRequired) {
                return schema;
            }

            return schema
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .nullable();
        }),
    });
};
