import { createSlice } from '@reduxjs/toolkit';
import {
    templateCoreHelpers,
    orm as templatesORM,
} from 'sunwise-template-core';

import {
    CLEAN_TEMPLATE_PREVIEW,
    FETCH_CLONE_PAGES,
    FETCH_CLONE_PAGES_FAILURE,
    FETCH_CLONE_PAGES_SUCCESS,
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    FETCH_TEMPLATE_PREVIEW,
    FETCH_TEMPLATE_PREVIEW_FAILURE,
    FETCH_TEMPLATE_PREVIEW_SUCCESS,
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_SUCCESS,
    RESET,
    SET_CLONE_PAGES,
    SET_CLONE_PAGES_FAILURE,
    SET_CLONE_PAGES_SUCCESS,
    SET_PAGE_SIZE,
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_FAILURE,
    UPDATE_TEMPLATE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    entities: templatesORM.getEmptyState(),
    fetchClonePages: { data: [], errors: [], isFetching: true },
    fetchCustomTemplate: { data: null, errors: [], isFetching: false },
    fetchTemplatePreview: { data: null, errors: [], isFetching: false },
    isInitialize: true,
    pageSize: 'A4',
    setClonePages: { errors: [], isFetching: true },
    updateCustomTemplate: { errors: [], isUpdating: false, type: null },
};

const editTemplateViewSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [CLEAN_TEMPLATE_PREVIEW]: (state) => {
            state.fetchTemplatePreview = INITIAL_STATE.fetchTemplatePreview;
        },
        [FETCH_CUSTOM_TEMPLATE]: (state) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: null,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_FAILURE]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_SUCCESS]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_PREVIEW]: (state) => {
            state.fetchTemplatePreview = {
                data: null,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_TEMPLATE_PREVIEW_FAILURE]: (state, action) => {
            state.fetchTemplatePreview = {
                ...state.fetchTemplatePreview,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_PREVIEW_SUCCESS]: (state, action) => {
            const session = templatesORM.session(state.entities);
            const { Page, Template, Widget } = session;
            const { pages, ...restData } = action.payload;
            const pagesTemplate = pages.map((page) => ({
                ...page,
                content: JSON.stringify(page.content),
            }));
            const templateModel = Template.create({ ...restData });
            const pageIds = templateModel
                ? templateModel.templatePages.toRefArray().map((p) => p.id)
                : [];
            pagesTemplate.forEach((p) => {
                if (pageIds.includes(p.id))
                    Page.withId(p.id).set('page', p.page);
                else
                    templateCoreHelpers.createPage({
                        imageGallery: state.imageGallery,
                        pageModel: Page,
                        pageSize: restData.page_size,
                        templateId: restData.id,
                        tempPage: p,
                        widgetModel: Widget,
                    });
            });
            state.fetchTemplatePreview = {
                data: action.payload,
                errors: [],
                isFetching: false,
            };
            state.entities = session.state;
        },
        [INITIALIZE]: (state) => {
            state.isInitialize = true;
        },
        [INITIALIZE_FAILURE]: (state) => {
            state.isInitialize = false;
        },
        [INITIALIZE_SUCCESS]: (state) => {
            state.isInitialize = false;
        },
        [UPDATE_TEMPLATE]: (state, action) => {
            state.updateCustomTemplate = {
                ...state.updateCustomTemplate,
                errors: [],
                isUpdating: true,
                type: action.payload,
            };
        },
        [UPDATE_TEMPLATE_FAILURE]: (state, action) => {
            state.updateCustomTemplate = {
                ...state.updateCustomTemplate,
                errors: action.payload,
                isUpdating: false,
                type: null,
            };
        },
        [UPDATE_TEMPLATE_SUCCESS]: (state) => {
            state.updateCustomTemplate = {
                ...state.updateCustomTemplate,
                isUpdating: false,
                type: null,
            };
        },
        [FETCH_CLONE_PAGES]: (state) => {
            state.fetchClonePages = {
                ...state.fetchClonePages,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CLONE_PAGES_FAILURE]: (state, action) => {
            state.fetchClonePages = {
                ...state.fetchClonePages,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CLONE_PAGES_SUCCESS]: (state, action) => {
            state.fetchClonePages = {
                ...state.fetchClonePages,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [SET_CLONE_PAGES]: (state) => {
            state.setClonePages = {
                ...state.setClonePages,
                errors: [],
                isFetching: true,
            };
        },
        [SET_CLONE_PAGES_FAILURE]: (state, action) => {
            state.setClonePages = {
                ...state.setClonePages,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SET_CLONE_PAGES_SUCCESS]: (state) => {
            state.setClonePages.isFetching = false;
        },

        [SET_PAGE_SIZE]: (state, action) => {
            state.pageSize = action.payload;
        },
    },
});

export const editTemplateViewActions = editTemplateViewSlice.actions;

export default editTemplateViewSlice.reducer;
