import { getAvailableBag } from 'common/api/v1/availableBag';

import {
    FETCH_AVAILABLE_BAG,
    FETCH_AVAILABLE_BAG_FAILURE,
    FETCH_AVAILABLE_BAG_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (projectId, date, callback) => (dispatch) => {
    if (!projectId || !date) return;

    dispatch(actions[FETCH_AVAILABLE_BAG]());

    getAvailableBag(projectId, date)
        .then((response) => {
            const data = response?.data?.data;
            dispatch(actions[FETCH_AVAILABLE_BAG_SUCCESS](data));

            if (callback) callback(data?.available_bag);
        })
        .catch((error) =>
            dispatch(actions[FETCH_AVAILABLE_BAG_FAILURE](error)),
        );
};
