import React from 'react';

import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

import Label from './Label';
const TextWithLabel = ({ label, margin = '0', padding = '0', text }) => (
    <Box flexDirection="column" gap={2} m={margin} p={padding}>
        <Label text={label} />
        <Typography fontWeight="bold" variant="body2">
            {text}
        </Typography>
    </Box>
);

TextWithLabel.propTypes = {
    text: PropTypes.string,
    label: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
};

export default TextWithLabel;
