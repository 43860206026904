import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { maximumNumberCharacters, REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        type_document: string().required(REQUIRED_TEXT).nullable(),
        value_user: string()
            .trim()
            .required(REQUIRED_TEXT)
            .max(60, maximumNumberCharacters(60))
            .nullable(),
    });
};
