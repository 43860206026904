import arrayMove from 'array-move';

import { updateOrderItems } from 'common/api/v1/companiesCurrencies';
import showToast from 'common/utils/showToast';

import { FETCH_ITEMS_SUCCESS } from '../actionTypes';
import { multiCurrencyActions } from '../reducer';

import filterItems from './filterItems';

export default (oldIndex, newIndex, items) => (dispatch) => {
    const newItems = arrayMove(items, oldIndex, newIndex);

    dispatch(multiCurrencyActions[FETCH_ITEMS_SUCCESS](newItems));

    let catalogs = [];

    newItems.forEach((item, index) => {
        catalogs.push({
            id: item.id,
            order: index + 1,
        });
    });

    const values = {
        items: JSON.stringify(catalogs),
    };

    updateOrderItems(values)
        .then(() => {
            showToast();
            dispatch(filterItems());
        })
        .catch(() => {});
};
