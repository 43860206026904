import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Box, Grid } from 'sunwise-ui';

import ItemPreviewPage from '../LeftSideMenu/ItemPreviewPage';

const TemplatePagesSelector = ({
    filterLanguageTammplates = true,
    language,
    selectedPages,
    setSelectedPages,
    templatePreviewPages,
}) => {
    const filteredPages = filterLanguageTammplates
        ? templatePreviewPages.filter((page) => page.language === language)
        : templatePreviewPages;

    useEffect(() => {
        const _selectedPages = new Array(filteredPages.length)
            .fill()
            .map((_, i) => i + 1);
        setSelectedPages(_selectedPages);
    }, [templatePreviewPages]);

    const handleClickSelect = (page) => {
        if (selectedPages.includes(page))
            setSelectedPages((prev) =>
                prev.filter((selectedPage) => selectedPage !== page),
            );
        else setSelectedPages((prev) => [...prev, page].sort((a, b) => a - b));
    };

    return (
        <Grid item xs={18}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {filteredPages.map((page) => {
                    return (
                        <ItemPreviewPage
                            handleClickSelect={() => {
                                handleClickSelect(page.page);
                            }}
                            key={page.id}
                            location="pageSelector"
                            page={page}
                            pageSize={page.page_size}
                            selected={selectedPages.includes(page.page)}
                        />
                    );
                })}
            </Box>
        </Grid>
    );
};

TemplatePagesSelector.propTypes = {
    filterLanguageTammplates: PropTypes.bool,
    language: PropTypes.string,
    selectedPages: PropTypes.array,
    setSelectedPages: PropTypes.func,
    templatePreviewPages: PropTypes.array,
};

export default TemplatePagesSelector;
