import { createSlice } from '@reduxjs/toolkit';

import {
    ACTIVATE_ITEM,
    ACTIVATE_ITEM_FAILURE,
    ACTIVATE_ITEM_SUCCESS,
    CHANGE_SEARCH_TEXT,
    DELETE_ITEM,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_SUCCESS,
    FETCH_GEOCODE,
    FETCH_GEOCODE_ERROR,
    FETCH_GEOCODE_SUCCESS,
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
    FETCH_POLITICAL_DIVISIONS,
    FETCH_POLITICAL_DIVISIONS_FAILURE,
    FETCH_POLITICAL_DIVISIONS_SUCCESS,
    INITIALIZE_FORM,
    OPEN_MODAL_PRODUCT,
    RESET_FORM,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_IS_NEW_RECORD,
    SET_SHOW_GEOCODE_RESULTS,
    FETCH_GEOCODE_POINT,
    FETCH_GEOCODE_POINT_FAILURE,
    FETCH_GEOCODE_POINT_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    activateItem: {
        data: [],
        errors: null,
        isFetching: false,
    },
    deleteItem: {
        data: [],
        errors: null,
        isDeleting: false,
    },
    fetchGeocode: {
        isLoading: false,
        data: [],
        errors: null,
    },
    fetchItems: {
        data: [],
        errors: null,
        isFetching: false,
    },
    showGeocodeResults: false,
    initialValues: {
        business_name: '',
        city: '',
        company_size: '',
        contact_cellphone: '',
        contact_email: '',
        contact_name: '',
        contact_telephone: '',
        cp: '',
        currency: '',
        description: '',
        id: '',
        logo: '',
        name: '',
        political_division1: '',
        position: {},
        rfc: '',
        signatory_position: '',
        web_page: '',
    },
    isNewRecord: true,
    isOpenModalProduct: false,
    politicalDivisions: {
        data: [],
        errors: null,
        isLoading: false,
    },
    saveItem: {
        data: {},
        errors: null,
        isSaving: false,
    },
    searchText: '',
    fetchGeocodePoint: {
        isFetching: false,
        data: null,
        errors: null,
    },
};

const branchOfficesSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ACTIVATE_ITEM]: (state) => {
            state.activateItem = {
                ...state.activateItem,
                errors: [],
                isFetching: true,
            };
        },
        [ACTIVATE_ITEM_FAILURE]: (state, action) => {
            state.activateItem = {
                ...state.activateItem,
                errors: action.payload,
                isFetching: false,
            };
        },
        [ACTIVATE_ITEM_SUCCESS]: (state, action) => {
            state.activateItem = {
                ...state.activateItem,
                data: action.payload,
                isFetching: false,
            };
        },
        [CHANGE_SEARCH_TEXT]: (state, action) => {
            state.searchText = action.payload;
        },
        [DELETE_ITEM]: (state) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: [],
                isDeleting: false,
            };
        },
        [DELETE_ITEM_FAILURE]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_ITEM_SUCCESS]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                data: action.payload,
                isDeleting: false,
            };
        },
        [FETCH_GEOCODE]: (state) => {
            state.fetchGeocode = {
                ...state.fetchGeocode,
                errors: [],
                isLoading: true,
            };
        },
        [FETCH_GEOCODE_ERROR]: (state, action) => {
            state.fetchGeocode = {
                ...state.fetchGeocode,
                data: [],
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_GEOCODE_SUCCESS]: (state, action) => {
            state.fetchGeocode = {
                ...state.fetchGeocode,
                data: action.payload,
                errors: [],
                isLoading: false,
            };
        },
        [FETCH_GEOCODE_POINT]: (state) => {
            state.fetchGeocodePoint = {
                ...state.fetchGeocodePoint,
                data: null,
                isFetching: true,
            };
        },
        [FETCH_GEOCODE_POINT_FAILURE]: (state, action) => {
            state.fetchGeocodePoint = {
                ...state.fetchGeocodePoint,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_GEOCODE_POINT_SUCCESS]: (state, action) => {
            state.fetchGeocodePoint = {
                ...state.fetchGeocodePoint,
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [FETCH_ITEMS]: (state) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ITEMS_FAILURE]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_SUCCESS]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS]: (state) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                errors: [],
                isLoading: true,
            };
        },
        [FETCH_POLITICAL_DIVISIONS_FAILURE]: (state, action) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS_SUCCESS]: (state, action) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                data: action.payload,
                isLoading: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [OPEN_MODAL_PRODUCT]: (state, action) => {
            state.isOpenModalProduct = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [SET_SHOW_GEOCODE_RESULTS]: (state, action) => {
            state.showGeocodeResults = action.payload;
        },
    },
});

export const branchOfficesActions = branchOfficesSlice.actions;

export default branchOfficesSlice.reducer;
