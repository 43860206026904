import React from 'react';

import BoltIcon from '@mui/icons-material/Bolt';
import { useTheme, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Card, Typography } from 'sunwise-ui';

import { getColorByStatus } from '../helpers';

import DotIndicator from './DotIndicator';

const StyledTypography = styled(Typography)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const InfoCard = ({
    dotColor,
    handleSelectProject,
    project,
    selectedProjectId,
    showDot = false,
}) => {
    const theme = useTheme();

    const { id, name, address, status } = project || {};
    const isSelected = selectedProjectId === id;
    const sxCard = {
        backgroundColor: theme.palette.mode === 'dark' ? '#282b30' : '',
        cursor: 'pointer',
        border: isSelected ? `1px solid ${theme.palette.primary.main}` : '',
    };

    return (
        <Card sx={sxCard} onClick={() => handleSelectProject(id)}>
            <Card.Body>
                <Box display="flex" alignItems="center">
                    {showDot && <DotIndicator color={dotColor} />}

                    <BoltIcon sx={{ color: getColorByStatus(status) }} />

                    <Typography variant="subtitle2">{name || 'N/A'}</Typography>
                </Box>

                <StyledTypography variant="body2">
                    {address || ''}
                </StyledTypography>
            </Card.Body>
        </Card>
    );
};

InfoCard.propTypes = {
    dotColor: PropTypes.string,
    handleSelectProject: PropTypes.func,
    project: PropTypes.object,
    selectedProjectId: PropTypes.number,
    showDot: PropTypes.bool,
};

export default InfoCard;
