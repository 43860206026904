import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, InputLabel, MenuItem, Select } from 'sunwise-ui';

import { VOLTAGE_TYPE_ITEMS } from '../constants';
import { getVoltageDotColor } from '../helpers';

const TensionDot = styled('div')`
    background: ${({ dotColor }) => dotColor};
    border-radius: 50%;
    height: 14px;
    min-width: 14px;
    width: 14px;
`;

const VoltageTypeFilterDropdown = ({
    disabled,
    selectVoltageType,
    voltageTypeSelected,
}) => {
    const { t } = useTranslation();
    const label = t('Voltage type');
    const handleOnChange = (e) => selectVoltageType(e.target.value);

    return (
        <FormControl disabled={disabled} fullWidth size="small">
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                onChange={handleOnChange}
                value={voltageTypeSelected}
            >
                {Object.keys(VOLTAGE_TYPE_ITEMS).map((tensionTypeItemKey) => {
                    const icon = getVoltageDotColor(tensionTypeItemKey);
                    return (
                        <MenuItem
                            key={`rate-type-filter-dropdown-item-all-${tensionTypeItemKey}`}
                            value={tensionTypeItemKey}
                        >
                            <Box display="flex" alignItems="center" gap="16px">
                                {icon && <TensionDot dotColor={icon} />}
                                {tensionTypeItemKey === 'all'
                                    ? t('All text', {
                                          context: 'male',
                                          count: 2,
                                      })
                                    : t(VOLTAGE_TYPE_ITEMS[tensionTypeItemKey])}
                            </Box>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

VoltageTypeFilterDropdown.propTypes = {
    disabled: PropTypes.bool,
    selectVoltageType: PropTypes.func,
    voltageTypeSelected: PropTypes.string,
};

export default VoltageTypeFilterDropdown;
