import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteTaxes from './deleteTaxes';

export default ({ beforeAction, id }) =>
    (dispatch) => {
        const dataAlert = {
            confirmText: i18next.t('Delete'),
            messages: [i18next.t('Are you sure you want to delete this item?')],
            onSuccess: () => {
                beforeAction();
                dispatch(deleteTaxes(id));
            },
            title: i18next.t('Delete tax'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };

        dispatch(alerts.actions.show(dataAlert));
    };
