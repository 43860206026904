import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from 'sunwise-ui';

import CardContent from './components/CardContent';
import ModalForm from './components/ModalForm';

const Container = ({ canDelete, canModify, selectedProjectId }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Header>
                <Typography variant="body1" fontWeight="bold">
                    {t('Report', { count: 2 })}
                </Typography>
            </Card.Header>

            <CardContent
                canDelete={canDelete}
                canModify={canModify}
                projectId={selectedProjectId}
            />

            <ModalForm selectedProjectId={selectedProjectId} />
        </Card>
    );
};

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    selectedProjectId: PropTypes.string,
};

export default Container;
