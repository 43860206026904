import { importFile } from 'common/api/v1/additional';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { SET_IS_UPLOADING } from '../actionTypes';
import { additionalConfigurationActions } from '../reducer';

import filterItems from './filterItems';

export default (file, filterData) => (dispatch) => {
    dispatch(additionalConfigurationActions[SET_IS_UPLOADING](true));
    importFile({ file })
        .then(() => {
            dispatch(additionalConfigurationActions[SET_IS_UPLOADING](false));
            dispatch(filterItems(filterData));
            showToast();
        })
        .catch((error) => {
            dispatch(additionalConfigurationActions[SET_IS_UPLOADING](false));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
