import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import showToast from 'common/utils/showToast';

import * as actions from '../actions';
import * as selectors from '../selectors';

import Column from './Column';
import ColumnWrapper from './ColumnWrapper';
import PlaceholderColumn from './PlaceholderColumn';

const Container = ({
    canModify,
    fetchFunnelProjects,
    fetchNextPage,
    filterQuery,
    funnelColumns,
    handleToggleDrawer,
    isLoadingProjects,
    isOnline,
    nextPagesIds,
    refreshingIsNeeded,
    setSelectedCreditItem,
    setProjectId,
    statusDictionary,
    updatingStatusProjectId,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!refreshingIsNeeded) return;
        showToast({
            body: t('Changes have been detected. You need to refresh the page'),
            type: 'warning',
            autoClose: false,
        });
    }, [refreshingIsNeeded]);

    useEffect(() => {
        fetchFunnelProjects(filterQuery);
    }, [filterQuery]);

    const sortedColumns = sortBy(
        Object.keys(funnelColumns).map((key) => funnelColumns[key]),
        (o) => o.index,
    );

    return (
        <ColumnWrapper>
            <PlaceholderColumn
                isEmpty={isEmpty(sortedColumns)}
                ready={!isLoadingProjects}
            >
                {sortedColumns.map((sortedColumn) => {
                    const isLoadingCurrentNextPage =
                        nextPagesIds[sortedColumn.id];

                    return (
                        <Column
                            canModify={canModify}
                            fetchNextPage={({ page, status }) =>
                                fetchNextPage({ filterQuery, page, status })
                            }
                            handleToggleDrawer={handleToggleDrawer}
                            id={sortedColumn.id}
                            isLoadingCurrentNextPage={isLoadingCurrentNextPage}
                            isOnline={isOnline}
                            key={sortedColumn.id}
                            nextPage={sortedColumn.nextPage}
                            nextProject={sortedColumn.hasNextPage}
                            projects={sortedColumn.projects}
                            refreshingIsNeeded={refreshingIsNeeded}
                            setProjectId={setProjectId}
                            setSelectedCreditItem={setSelectedCreditItem}
                            statusDictionary={statusDictionary}
                            title={sortedColumn.name}
                            totalRows={sortedColumn.total_rows}
                            updatingStatusProjectId={updatingStatusProjectId}
                        />
                    );
                })}
            </PlaceholderColumn>
        </ColumnWrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    branchOfficesDictionary: multiBranchesSelectors.getBranchesDictionary,
    funnelColumns: selectors.getFunnelColumnsData,
    isLoadingProjects: selectors.getIsFetchingFunnelColumns,
    nextPagesIds: selectors.getNextPagesIds,
    refreshingIsNeeded: selectors.getRefreshingIsNeeded,
    statusDictionary: selectors.getStatusDictionary,
    updatingStatusProjectId: selectors.getUpdatingStatusProjectId,
});

const mapDispatchToProps = (dispatch) => ({
    fetchFunnelProjects: (filterQuery) =>
        dispatch(actions.fetchFunnelProjects(filterQuery)),
    fetchNextPage: ({ filterQuery, page, status }) =>
        dispatch(actions.fetchNextPage({ filterQuery, page, status })),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    fetchFunnelProjects: PropTypes.func,
    fetchNextPage: PropTypes.func,
    filterQuery: PropTypes.object,
    funnelColumns: PropTypes.object,
    handleToggleDrawer: PropTypes.func,
    isLoadingProjects: PropTypes.bool,
    isOnline: PropTypes.bool,
    nextPagesIds: PropTypes.object,
    refreshingIsNeeded: PropTypes.bool,
    setSelectedCreditItem: PropTypes.func,
    setProjectId: PropTypes.func,
    statusDictionary: PropTypes.object,
    updatingStatusProjectId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
