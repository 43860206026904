import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'sunwise-ui';

const TaxesCancelButton = ({ disabled, onClick, visible }) => {
    const { t } = useTranslation();
    if (!visible) return null;
    return (
        <Button
            color="secondary"
            disabled={disabled}
            onClick={onClick}
            sx={{ width: { sx: '100%', md: 'auto' } }}
            variant="text"
        >
            {t('Cancel')}
        </Button>
    );
};

TaxesCancelButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    visible: PropTypes.bool,
};

export default TaxesCancelButton;
