import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SearchInput from './SearchInput';

const Filter = ({ handleChangeSearch }) => {
    const { t } = useTranslation();
    return (
        <SearchInput
            handleOnSearchChange={handleChangeSearch}
            placeholderSearch={t('Filter by name')}
        />
    );
};

Filter.propTypes = {
    handleChangeSearch: PropTypes.func,
};

export default Filter;
