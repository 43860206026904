import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getFetchItems = createSelector(
    getModel,
    (model) => model.fetchItems,
);
export const getIsFetchingItems = createSelector(
    getFetchItems,
    (model) => model.isFetching,
);
export const getFetchItemsData = createSelector(
    getFetchItems,
    (model) => model?.data?.data,
);
export const getFetchItemsPagination = createSelector(
    getFetchItems,
    (model) => model?.data?.pagination,
);

export const getFetchItemsParent = createSelector(
    getModel,
    (model) => model.fetchItemsParent,
);
export const getIsFetchingItemsParent = createSelector(
    getFetchItemsParent,
    (model) => model.isFetching,
);
export const getFetchItemsParentData = createSelector(
    getFetchItemsParent,
    (model) => model?.data?.data,
);
export const getFetchItemsParentPagination = createSelector(
    getFetchItemsParent,
    (model) => model?.data?.pagination,
);

export const getInitialMarginValues = createSelector(
    getModel,
    (model) => model.initialMarginValues,
);
export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getIsDownloading = createSelector(
    getModel,
    (model) => model.isDownloading,
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord,
);

export const getIsOpenModalMargin = createSelector(
    getModel,
    (model) => model.isOpenModalMargin,
);

export const getIsOpenModalProduct = createSelector(
    getModel,
    (model) => model.isOpenModalProduct,
);

export const getIsUploading = createSelector(
    getModel,
    (model) => model.isUploading,
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);
export const getIsSavingItem = createSelector(
    getSaveItem,
    (model) => model.isSaving,
);
export const getSaveItemErrors = createSelector(
    getSaveItem,
    (model) => model.errors,
);

export const getSaveMargin = createSelector(
    getModel,
    (model) => model.saveMargin,
);
export const getIsSavingMargin = createSelector(
    getSaveMargin,
    (model) => model.isSaving,
);
export const getSaveMarginErrors = createSelector(
    getSaveMargin,
    (model) => model.errors,
);
