import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const {
        getMaxValueText,
        getMinValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();

    return object().shape({
        branch_office: string().required(REQUIRED_TEXT).nullable(),
        name: string().required(REQUIRED_TEXT).nullable(),
        isr_simulation_type: number().when('isr', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .max(1, getMaxValueText(1))
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        isr_simulation_annual: number().when(['isr', 'isr_simulation_type'], {
            is: (isr, isr_simulation_type) =>
                isr === true && isr_simulation_type === 1,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(1, getMinValueText(1))
                    .max(50, getMaxValueText(50))
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        isr_simulation_monthly: number().when(['isr', 'isr_simulation_type'], {
            is: (isr, isr_simulation_type) =>
                isr === true && isr_simulation_type === 0,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(1, getMinValueText(1))
                    .max(600, getMaxValueText(600))
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
    });
};
