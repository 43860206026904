import React from 'react';

import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

const TitleIcon = ({ fontWeight, icon, title, variant }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {icon}
        <Typography variant={variant} fontWeight={fontWeight}>
            {title}
        </Typography>
    </Box>
);

TitleIcon.defaultProps = { variant: 'h4' };

TitleIcon.propTypes = {
    fontWeight: PropTypes.string,
    icon: PropTypes.object,
    title: PropTypes.string,
    variant: PropTypes.string,
};

export default TitleIcon;
