import React from 'react';

import PropTypes from 'prop-types';

import { PROPOSAL_SUMMARY_TYPE, STATUS_PROJECT_TYPE } from '../constants';

import CompanyCatalogForm from './CompanyCatalogForm';
import SummaryCatalogForm from './SummaryCatalogForm';

const CatalogsCard = ({ canModify, setDialogTitle, type }) => {
    if (type === PROPOSAL_SUMMARY_TYPE) {
        return (
            <SummaryCatalogForm
                canModify={canModify}
                setDialogTitle={setDialogTitle}
                type={type}
            />
        );
    }

    return (
        <CompanyCatalogForm
            canModify={canModify}
            setDialogTitle={setDialogTitle}
            showColor={type === STATUS_PROJECT_TYPE}
            type={type}
        />
    );
};

CatalogsCard.propTypes = {
    canModify: PropTypes.bool,
    setDialogTitle: PropTypes.func,
    type: PropTypes.number,
};

export default CatalogsCard;
