import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../actions';
import { REASSIGN_BY_DEACTIVATE_TYPE } from '../constants';
import * as selectors from '../selectors';

import ReassignList from './ReassignList';

const ReassignByDeactivateModal = ({
    closeReassignModal,
    reassignAndDeactivate,
    reassignType,
    resetReassignForm,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            onExited={() => resetReassignForm()}
            onHide={() => closeReassignModal()}
            open={reassignType === REASSIGN_BY_DEACTIVATE_TYPE}
            title={t('Are you sure you want to disable the user?')}
        >
            <ReassignList
                confirmButtonText={t('Deactivate user')}
                handleCancel={() => closeReassignModal()}
                handleConfirm={({ userId, reassignBranches, dataToSave }) =>
                    reassignAndDeactivate(userId, reassignBranches, dataToSave)
                }
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    reassignType: selectors.getReassignType,
});

const mapDispatchToProps = (dispatch) => ({
    closeReassignModal: () => dispatch(actions.closeReassignModal()),
    reassignAndDeactivate: (userId, reassignBranches, dataToSave) =>
        dispatch(
            actions.reassignAndDeactivate(userId, reassignBranches, dataToSave),
        ),
    resetReassignForm: () => dispatch(actions.resetReassignForm()),
});

ReassignByDeactivateModal.propTypes = {
    closeReassignModal: PropTypes.func,
    reassignAndDeactivate: PropTypes.func,
    reassignType: PropTypes.number,
    resetReassignForm: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReassignByDeactivateModal);
