import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { DAYS_NAMES } from 'common/constants';
import { getDayLabel, numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import { compareRowsByColumn, getEnergyWarningsRows } from '../helpers';

const WArningsList = ({
    consumptionHistory,
    emptyEnergyIndexes,
    handleGoToDay,
    realIndex,
}) => {
    const { t } = useTranslation();
    if (!emptyEnergyIndexes?.length) return null;

    const countryCurrencyLocale = getCountryCurrencyLocale();
    const rows = getEnergyWarningsRows({ emptyEnergyIndexes, realIndex });
    const translatedDays = DAYS_NAMES.map((day) => getDayLabel(day));

    let columns = [
        {
            field: 'year',
            headerName: t('Year'),
            minWidth: 50,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'year',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
            valueGetter: (params) => {
                const { row } = params;
                return row.year + 1;
            },
        },
        {
            field: 'period',
            headerName: t('Period'),
            minWidth: 100,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'period',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
            valueGetter: (params) => {
                const { row } = params;
                return consumptionHistory?.[row.year]?.[row.period]?.label;
            },
        },
        {
            field: 'week',
            headerName: t('Week'),
            minWidth: 100,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'week',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
        },
        {
            field: 'day',
            headerName: t('Day'),
            minWidth: 100,
            flex: 1,
            valueGetter: (params) => {
                const { row } = params;
                return translatedDays[row.day || 0];
            },
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'day',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
        },
        {
            field: 'hour',
            headerName: t('Hour'),
            minWidth: 60,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'hour',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
        },
        {
            field: 'missingEnergy',
            headerName: t('Missing energy').concat(' (kWh)'),
            minWidth: 200,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'missingEnergy',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
            valueGetter: (params) => {
                const { row } = params;
                return numberFormat(row.missingEnergy, {
                    decimals: 3,
                    locale: countryCurrencyLocale,
                    style: 'decimal',
                });
            },
        },
        {
            field: 'missingPower',
            headerName: t('Missing power').concat(' (kW)'),
            minWidth: 200,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'missingPower',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
            valueGetter: (params) => {
                const { row } = params;
                return numberFormat(row.missingPower, {
                    decimals: 3,
                    locale: countryCurrencyLocale,
                    style: 'decimal',
                });
            },
        },
    ];

    return (
        <DataGrid
            autoHeight
            columns={columns}
            components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
            }}
            disableColumnMenu
            getRowHeight={() => 'auto'}
            pageSize={25}
            rows={rows}
            onRowClick={(params) => {
                const { row } = params;
                handleGoToDay({
                    days: [row.day || 0],
                    option: 3,
                    period: Number(row.period),
                    weeks: [row.week - 1],
                    year: Number(row.year),
                });
            }}
            sx={{ '.MuiDataGrid-cell': { cursor: 'pointer' } }}
        />
    );
};

WArningsList.propTypes = {
    consumptionHistory: PropTypes.array,
    emptyEnergyIndexes: PropTypes.array,
    handleGoToDay: PropTypes.func,
    realIndex: PropTypes.array,
};

export default WArningsList;
