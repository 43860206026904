import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import resetRateConcepts from './resetRateConcepts';

export default (commercialOfferId, rates) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Continue'),
        messages: [i18next.t('Are you sure you want to restore to defaults?')],
        onSuccess: () => dispatch(resetRateConcepts(commercialOfferId, rates)),
        title: i18next.t('Restore'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
