import { createItem } from 'common/api/v1/companiesCurrencies';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SELECT_PARENT,
    SELECT_PARENT_FAILURE,
    SELECT_PARENT_SUCCESS,
} from '../actionTypes';
import { multiCurrencyActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(multiCurrencyActions[SELECT_PARENT]());

    createItem({ parent_currency: id })
        .then((response) => {
            dispatch(
                multiCurrencyActions[SELECT_PARENT_SUCCESS](response.data),
            );

            showToast();
        })
        .catch((error) => {
            dispatch(
                multiCurrencyActions[SELECT_PARENT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
