import React, { useEffect, useState } from 'react';

import AddchartIcon from '@mui/icons-material/Addchart';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Typography } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';
import ChartJs from 'common/components/ChartJs';
import { hexToRgba } from 'common/utils/helpersChart';

import { CHART_COLORS } from '../constants';
import {
    getChartSettings,
    getChartsInfo,
    getPaymentChartSettings,
} from '../helpers';
import * as selectors from '../selectors';

const CHART_HEIGHT = 250;

const ComparativeCard = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    historicalInfo,
    isLoading,
    periodConfig,
    proposalInfo,
    proposalInfoIsBimonthly,
}) => {
    const [chartsInfo, setChartsInfo] = useState({
        consumptionSeries: [],
        energySettings: {},
        generationSeries: [],
        paymentSeries: [],
        paymentSettings: {},
    });
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        if (!periodConfig) return;

        const {
            categories,
            consumptionSeries,
            energyDates,
            generationSeries,
            paymentPeriods,
            paymentSeries,
        } = getChartsInfo({
            historicalInfo,
            periodConfig,
            proposalInfo,
            proposalInfoIsBimonthly,
        });

        const commonConfig = {
            categories,
            currencyIso,
            currencyLocale,
            currencySymbol,
            energyDates,
            themeMode: theme?.palette?.mode,
        };

        const energySettings = getChartSettings(commonConfig);
        const paymentSettings = getPaymentChartSettings({
            categories,
            currencyIso,
            currencyLocale,
            paymentPeriods,
            themeMode: theme?.palette?.mode,
        });

        setChartsInfo({
            consumptionSeries,
            energySettings,
            generationSeries,
            paymentSeries,
            paymentSettings,
        });
    }, [historicalInfo, proposalInfo, periodConfig, theme?.palette?.mode]);

    const totalConsumptionChartConfig = {
        data: {
            datasets: chartsInfo.consumptionSeries.map(
                ({ data, name }, index) => ({
                    backgroundColor: hexToRgba(
                        CHART_COLORS.CONSUMPTION[
                            index % CHART_COLORS.CONSUMPTION.length
                        ],
                        0.8,
                    ),
                    data,
                    label: name,
                }),
            ),
            labels: chartsInfo.energySettings?.options?.xaxis?.categories || [],
        },
        options: chartsInfo.energySettings?.options?.chartJsOptions || {},
    };

    const totalGenerationChartConfig = {
        data: {
            datasets: chartsInfo.generationSeries.map(
                ({ data, name }, index) => ({
                    backgroundColor: hexToRgba(
                        CHART_COLORS.GENERATION[
                            index % CHART_COLORS.GENERATION.length
                        ],
                        0.8,
                    ),
                    data,
                    label: name,
                }),
            ),
            labels: chartsInfo.energySettings?.options?.xaxis?.categories || [],
        },
        options: chartsInfo.energySettings?.options?.chartJsOptions || {},
    };

    const totalCostChartConfig = {
        data: {
            datasets: chartsInfo.paymentSeries.map(({ data, name }, index) => ({
                backgroundColor: hexToRgba(
                    chartsInfo.paymentSettings?.options?.colors[
                        index %
                            chartsInfo.paymentSettings?.options?.colors.length
                    ],
                    0.8,
                ),
                data,
                label: name,
            })),
            labels:
                chartsInfo.paymentSettings?.options?.xaxis?.categories || [],
        },
        options: chartsInfo.paymentSettings?.options?.chartJsOptions || {},
    };

    return (
        <AccordionCard
            defaultExpanded
            isLoading={isLoading}
            svgIcon={<AddchartIcon color="primary" />}
            title={t('Comparative')}
        >
            <Grid container>
                <Grid item md={9} xs={18}>
                    <Typography variant="subtitle2">
                        {t('Total consumption')}
                    </Typography>

                    <ChartJs
                        data={totalConsumptionChartConfig.data}
                        height={`${CHART_HEIGHT}px`}
                        options={totalConsumptionChartConfig.options}
                    />
                </Grid>
                <Grid item md={9} xs={18}>
                    <Typography variant="subtitle2">
                        {t('Total generation')}
                    </Typography>

                    <ChartJs
                        data={totalGenerationChartConfig.data}
                        height={`${CHART_HEIGHT}px`}
                        options={totalGenerationChartConfig.options}
                    />
                </Grid>
                <Grid item xs={18}>
                    <Typography variant="subtitle2">
                        {t('Total cost')}
                    </Typography>

                    <ChartJs
                        data={totalCostChartConfig.data}
                        height={`${CHART_HEIGHT}px`}
                        options={totalCostChartConfig.options}
                    />
                </Grid>
            </Grid>
        </AccordionCard>
    );
};
const mapStateToProps = createStructuredSelector({
    historicalInfo: selectors.getHistoricalInfoData,
    periodConfig: selectors.getPeriodConfigData,
    proposalInfo: selectors.getProjectConfigurationProposalData,
    proposalInfoIsBimonthly: selectors.getProposalInfoIsBimonthly,
});

ComparativeCard.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    historicalInfo: PropTypes.array,
    isLoading: PropTypes.bool,
    periodConfig: PropTypes.object,
    proposalInfo: PropTypes.object,
    proposalInfoIsBimonthly: PropTypes.bool,
};

export default connect(mapStateToProps)(ComparativeCard);
