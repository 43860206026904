export const scroll = {
    custom: () => `
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: #004167;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #004167;
        }

        &::-webkit-scrollbar-thumb:active {
            background-color: #004167;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track:hover,
        &::-webkit-scrollbar-track:active {
            background: transparent;
        }
    `,
    object: {
        '&::-webkit-scrollbar': {
            background: 'transparent',
            height: '8px',
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#004167',
            borderRadius: '4px',
        },
        '#root[aria-hidden] &::-webkit-scrollbar-thumb': {
            background: 'transparent',
        },
    },
};
