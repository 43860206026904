import get from 'lodash/get';

import { getRoleDetails } from 'common/api/v1/companyRoles';

import {
    FETCH_ROLE_DETAILS,
    FETCH_ROLE_DETAILS_FAILURE,
    FETCH_ROLE_DETAILS_SUCCESS,
} from '../actionTypes';
import { getPermissionGroups, getRoleCategories } from '../helpers';
import { rolesConfigurationActions } from '../reducer';

import initializeForm from './initializeForm';
import openModal from './openModal';
import resetForm from './resetForm';
import setIsNewRecord from './setIsNewRecord';

export default (roleId) => (dispatch) => {
    dispatch(rolesConfigurationActions[FETCH_ROLE_DETAILS]());

    getRoleDetails(roleId)
        .then((response) => {
            const data = get(response, 'data', {});
            dispatch(
                rolesConfigurationActions[FETCH_ROLE_DETAILS_SUCCESS](data),
            );
            const categoriesSettings = get(data, 'category_settings', []);
            const { id, is_system_role, maximum_discount = 0, name } = data;
            dispatch(resetForm());
            dispatch(setIsNewRecord(false));
            dispatch(
                initializeForm({
                    categories: getRoleCategories(categoriesSettings),
                    category: categoriesSettings[0]?.id,
                    permissions: getPermissionGroups(categoriesSettings),
                    id,
                    is_system_role,
                    maximum_discount,
                    name,
                }),
            );
            dispatch(openModal(true));
        })
        .catch((error) =>
            dispatch(
                rolesConfigurationActions[FETCH_ROLE_DETAILS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
