import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import cancelCreditApp from './cancelCreditApp';

export default (id, onSuccess = () => {}) =>
    (dispatch) => {
        const dataAlert = {
            confirmText: i18next.t('Cancel'),
            messages: [i18next.t('Are you sure to cancel?')],
            onSuccess: () => dispatch(cancelCreditApp(id, onSuccess)),
            title: i18next.t('Confirmation'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
            haveTextCaptcha: true,
            textToValidate: i18next.t('Cancel'),
        };

        dispatch(alerts.actions.show(dataAlert));
    };
