import { styled } from '@mui/material/styles';
import { ListItemText } from 'sunwise-ui';

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': { fontSize: '10px', textAlign: 'center' },
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    display: 'block',
    [theme.breakpoints.down('md')]: {
        color: '#fff',
        '& .MuiListItemText-primary': { fontSize: '16px', textAlign: 'left' },
    },
}));

export default StyledListItemText;
