import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    FETCH_PDF_LINK,
    FETCH_PDF_LINK_SUCCESS,
    FETCH_PDF_LINK_FAILURE,
    RESET,
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchCustomTemplate: {
        isFetching: true,
        data: null,
        errors: [],
    },
    fetchPdfFile: {
        isFetching: false,
        file: null,
        errors: [],
    },
    updateCustomTemplate: {
        isUpdating: false,
        errors: [],
    },
};

const summaryTemplateReviewSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_CUSTOM_TEMPLATE]: (state) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: null,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_FAILURE]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_SUCCESS]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PDF_LINK]: (state) => {
            state.fetchPdfFile = {
                ...state.fetchPdfFile,
                errors: [],
                file: null,
                isFetching: true,
            };
        },
        [FETCH_PDF_LINK_FAILURE]: (state, action) => {
            state.fetchPdfFile = {
                ...state.fetchPdfFile,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PDF_LINK_SUCCESS]: (state, action) => {
            state.fetchPdfFile = {
                ...state.fetchPdfFile,
                file: action.payload,
                isFetching: false,
            };
        },
        [UPDATE_TEMPLATE]: (state) => {
            state.updateCustomTemplate = {
                ...state.updateCustomTemplate,
                errors: [],
                isUpdating: true,
            };
        },
        [UPDATE_TEMPLATE_FAILURE]: (state, action) => {
            state.updateCustomTemplate = {
                ...state.updateCustomTemplate,
                errors: action.payload,
                isUpdating: false,
            };
        },
        [UPDATE_TEMPLATE_SUCCESS]: (state) => {
            state.updateCustomTemplate.isUpdating = false;
        },
        [RESET]: (state) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: null,
                errors: [],
            };
        },
    },
});

export const summaryTemplateReviewActions = summaryTemplateReviewSlice.actions;

export default summaryTemplateReviewSlice.reducer;
