import React from 'react';

import PropTypes from 'prop-types';

import Indicator from './Indicator';

const IndicatorsContent = ({ indicatorsSettingsData, valuesIndicators }) => (
    <>
        {indicatorsSettingsData.map((indicator) => (
            <Indicator
                showIndicator={indicator.is_active}
                indicatorKey={indicator.name}
                key={indicator.id}
                value={
                    valuesIndicators[
                        indicator.name === 'system_size'
                            ? 'sys_size'
                            : indicator.name
                    ]
                }
            />
        ))}
    </>
);

IndicatorsContent.propTypes = {
    indicatorsSettingsData: PropTypes.array,
    valuesIndicators: PropTypes.object,
};

export default IndicatorsContent;
