import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteParentFieldSegments from './deleteParentFieldSegments';

export default (proposalId) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: [i18next.t('Are you sure to delete the PV layout?')],
        onSuccess: () => {
            dispatch(alerts.actions.close());
            dispatch(deleteParentFieldSegments(proposalId));
        },
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
