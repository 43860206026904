import { getTemplate } from 'common/api/v1/templates';

import {
    FETCH_TEMPLATE_PREVIEW,
    FETCH_TEMPLATE_PREVIEW_SUCCESS,
    FETCH_TEMPLATE_PREVIEW_FAILURE,
} from '../actionTypes';
import { editTemplateViewActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(editTemplateViewActions[FETCH_TEMPLATE_PREVIEW]());

    getTemplate(id)
        .then((response) => {
            dispatch(
                editTemplateViewActions[FETCH_TEMPLATE_PREVIEW_SUCCESS](
                    response.data,
                ),
            );
        })
        .catch((error) => {
            dispatch(
                editTemplateViewActions[FETCH_TEMPLATE_PREVIEW_FAILURE](error),
            );
        });
};
