import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Select } from 'sunwise-ui';

const DropdownYear = ({ disabled, id, onChange, value }) => {
    const { t } = useTranslation();
    const label = t('Year');

    const options = [
        { label: t('Year') + ' 1', value: '1' },
        { label: t('Year') + ' 2', value: '2' },
    ];

    return (
        <FormControl fullWidth size="small">
            <InputLabel>{label}</InputLabel>
            <Select
                disabled={disabled}
                label={label}
                onChange={(e) => {
                    if (onChange) onChange(id, e.target.value);
                }}
                sx={{ textAlign: 'left' }}
                value={value ?? ''}
            >
                {options.map(({ label, value, disabled: optionDisabled }) => (
                    <MenuItem
                        key={`select-${value}-${label}`}
                        value={value}
                        disabled={optionDisabled}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

DropdownYear.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DropdownYear;
