import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, TextField, Typography } from 'sunwise-ui';

import {
    ReactHookFormDragnDropFile,
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
    ReactHookFormSlider,
} from 'common/components/form/bootstrap';
import { FILE_ALLOWED_EXTENSIONS, MAX_FILE_SIZE } from 'common/constants';
import { GeneralContext } from 'common/utils/contexts';
import yupResolver from 'common/utils/yupResolver';

import * as proposalGeneratorQuoteSelectors from '../../proposalGeneratorQuote/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validateImage';

const ObstacleEditForm = ({
    commercialOfferId,
    initialValues,
    mapRef,
    onCloseDrawer,
    resetForm,
    saveImage,
    saveImageIsSaving,
    updateSegment,
}) => {
    const { t } = useTranslation();
    const { google } = useContext(GeneralContext);
    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    const [image, opacity, rotate] = watch(['image', 'opacity', 'rotate']);

    useEffect(() => reset(initialValues), [initialValues]);

    const zoomLevel = mapRef.current ? mapRef.current.getZoomMap() : 20;

    const handleOnSave = (values) =>
        updateSegment({
            callback: () => {
                onCloseDrawer();
                resetForm();
            },
            commercialOfferId,
            google,
            mapValue: mapRef.current.self(),
            values,
            zoomLevel,
        });

    const handleOnChangeImage = (value) =>
        saveImage({ id: initialValues.id, image: value[0] }, setValue);

    return (
        <Box sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit(handleOnSave)}>
                <ReactHookFormInput
                    control={control}
                    fullWidth
                    label={t('Name')}
                    name="name"
                />

                <Box
                    sx={{
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    {image && (
                        <Box
                            sx={{
                                height: 'calc(100% - 16px)',
                                overflow: 'hidden',
                                position: 'absolute',
                                width: '100%',
                                zIndex: 1,
                            }}
                        >
                            <img
                                height="100%"
                                src={image}
                                style={{
                                    objectFit: 'contain',
                                    opacity: opacity / 100,
                                    transform: `rotate(${rotate}deg)`,
                                }}
                                width="100%"
                            />
                        </Box>
                    )}
                    <Box
                        sx={{
                            position: 'relative',
                            zIndex: 2,
                        }}
                    >
                        <ReactHookFormDragnDropFile
                            accept={FILE_ALLOWED_EXTENSIONS.IMAGE}
                            control={control}
                            formats="jpg, jpeg, png"
                            maxSize={MAX_FILE_SIZE}
                            name="file"
                            onChange={handleOnChangeImage}
                            setValue={setValue}
                            showFileName={false}
                        />
                    </Box>
                </Box>

                <ReactHookFormIntlNumberInput
                    append="º"
                    control={control}
                    fullWidth
                    label={t('Rotation')}
                    name="rotate"
                />

                <Grid container>
                    <Grid
                        item
                        sx={{
                            alignContent: 'center',
                            display: 'flex',
                            flexWrap: 'inherit',
                        }}
                        xs={12}
                    >
                        <Typography>{t('Opacity')}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            InputProps={{ readOnly: true }}
                            size="small"
                            sx={{ mb: '0!important' }}
                            value={opacity}
                        />
                    </Grid>

                    <Grid item xs={18}>
                        <ReactHookFormSlider
                            control={control}
                            max={100}
                            min={0}
                            name="opacity"
                            size="small"
                        />
                    </Grid>
                </Grid>

                <Button
                    disabled={saveImageIsSaving}
                    fullWidth
                    sx={{ '&.MuiButton-root': { mb: 0 } }}
                    type="submit"
                >
                    {t('Save')}
                </Button>

                <Button
                    disabled={saveImageIsSaving}
                    fullWidth
                    onClick={() => resetForm()}
                    sx={{ '&.MuiButton-root': { mb: 0 } }}
                    variant="text"
                >
                    {t('Cancel')}
                </Button>
            </form>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    panels: proposalGeneratorQuoteSelectors.getProposalPanelCatalogs,
    saveImageIsSaving: selectors.getSaveImageIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => dispatch(actions.resetForm()),
    saveImage: (values, setValue) =>
        dispatch(actions.saveImage(values, setValue)),
    updateSegment: (config) => dispatch(actions.updateSegment(config)),
});

ObstacleEditForm.propTypes = {
    commercialOfferId: PropTypes.string,
    initialValues: PropTypes.object,
    mapRef: PropTypes.object,
    onCloseDrawer: PropTypes.func,
    resetForm: PropTypes.func,
    saveImage: PropTypes.func,
    saveImageIsSaving: PropTypes.bool,
    updateSegment: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ObstacleEditForm);
