export const NAME = 'after-sales-measurement-source';

export const SOURCES = {
    CSV: '2',
    EXTERNAL: '1',
    REGISTERED_PROVIDER: '0',
};

export const SOURCE_FIELD_KEYS = {
    BATTERY_POWER: 'battery_power',
    CONSUMPTION: 'total_consumption',
    GENERATION: 'generation',
    GRID_CONSUMPTION: 'grid_consumption',
    SELF_CONSUMPTION: 'self_consumption',
};

export const SOURCE_FIELD_NAMES = {
    [SOURCE_FIELD_KEYS.BATTERY_POWER]: 'Battery power',
    [SOURCE_FIELD_KEYS.CONSUMPTION]: 'Total consumption',
    [SOURCE_FIELD_KEYS.GENERATION]: 'Total generation',
    [SOURCE_FIELD_KEYS.GRID_CONSUMPTION]: 'Consumption from Grid',
    [SOURCE_FIELD_KEYS.SELF_CONSUMPTION]: 'Self-consumption',
};
