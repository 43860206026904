import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'taxes-offer';

export const getItems = (offerId) => API.get(`/api/v2/${ENTITY}/${offerId}`);
export const deleteItem = (id, offerId) =>
    API.delete(`/api/v2/taxes-offer/${offerId}?id=${id}`);
export const updateItem = (data, offerId) =>
    API.put(`/api/v2/${ENTITY}/${offerId}`, data, config);
