import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        credential: object().shape({ id: string().required(REQUIRED_TEXT) }),
        power_station: object().shape({
            id: string().required(REQUIRED_TEXT),
            station_name: string().nullable(),
        }),
    });
};
