import React from 'react';

import PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    OutlinedInput,
} from 'sunwise-ui';

const MultiSelect = ({
    allTextValue,
    fullWidth = false,
    handleOnChangeOptionsSelected,
    label,
    multiple = true,
    options,
    optionsSelected,
}) => {
    let otherProps = {};
    let value;

    if (!multiple) {
        value =
            optionsSelected.length === options.length ? 'all' : optionsSelected;
    } else {
        otherProps.renderValue = (selected) => {
            if (selected.length === options.length) return allTextValue;
            return selected.map((item) => options[item]).join(', ');
        };
        value = optionsSelected;
    }

    return (
        <FormControl
            fullWidth={fullWidth}
            size="small"
            sx={{ width: !fullWidth ? '200px' : '100%' }}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                input={<OutlinedInput label={label} />}
                multiple={multiple}
                onChange={(evt) => {
                    if (!multiple) evt.target.value = [evt.target.value];
                    handleOnChangeOptionsSelected(evt);
                }}
                value={value}
                {...otherProps}
            >
                <MenuItem key="multi-select-option-all" value="all">
                    {multiple && (
                        <Checkbox
                            checked={optionsSelected.length === options.length}
                            size="small"
                            sx={{ py: 0.75 }}
                        />
                    )}
                    {allTextValue}
                </MenuItem>
                {options.map((day, index) => (
                    <MenuItem
                        key={`multi-select-option-${index}`}
                        value={index}
                    >
                        {multiple && (
                            <Checkbox
                                checked={optionsSelected.includes(index)}
                                size="small"
                                sx={{ py: 0.75 }}
                            />
                        )}
                        {day}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

MultiSelect.propTypes = {
    allTextValue: PropTypes.string,
    fullWidth: PropTypes.bool,
    handleOnChangeOptionsSelected: PropTypes.func,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    optionsSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default MultiSelect;
