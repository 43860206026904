import { array, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        name: string().required(REQUIRED_TEXT).nullable(),
        custom_offer_template: string().required(REQUIRED_TEXT).nullable(),
        linked_proposals: array()
            .of(
                object().shape({
                    commercial_offer: string()
                        .required(REQUIRED_TEXT)
                        .nullable(),
                    company_catalog: string()
                        .required(REQUIRED_TEXT)
                        .nullable(),
                }),
            )
            .required(REQUIRED_TEXT),
    });
};
