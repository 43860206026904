import { getItems } from 'common/api/v1/panelsCompany';

import {
    FETCH_PANELS,
    FETCH_PANELS_FAILURE,
    FETCH_PANELS_SUCCESS,
} from '../actionTypes';
import { equipmentCompatibilityActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(equipmentCompatibilityActions[FETCH_PANELS]());

    getItems()
        .then((response) =>
            dispatch(
                equipmentCompatibilityActions[FETCH_PANELS_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                equipmentCompatibilityActions[FETCH_PANELS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
