import React from 'react';

import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

const KeyValue = ({ append, title, prepend, value }) => (
    <Box display="flex" flexDirection="column">
        <Typography variant="body2" fontSize={13} sx={{ color: '#848bab' }}>
            {title}
        </Typography>
        <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" fontSize={18}>
                {prepend} {value}
            </Typography>
            <Typography variant="caption" fontWeight="bold">
                {append}
            </Typography>
        </Box>
    </Box>
);

KeyValue.propTypes = {
    append: PropTypes.string,
    title: PropTypes.string,
    prepend: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default KeyValue;
