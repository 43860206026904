import React from 'react';

import PropTypes from 'prop-types';

const IconAftersales = ({
    color = '#848BAB',
    height = '18px',
    width = '18px',
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M38.7396 69.9041C22.8396 69.2472 10.1211 56.0438 10.1211 40.033C10.1211 23.5525 23.492 10.0701 40.0481 9.79491C56.5089 9.79491 69.9752 23.2773 69.9752 39.7578C69.9752 53.3319 60.9036 65.1299 47.9981 68.6894L44.6297 69.6252L44 66.3445L47.0598 65.5922C58.3782 62.407 66.4198 52.0145 66.4198 40.0293C66.4198 31.512 62.399 23.9268 56.1314 19.0572C51.7331 15.593 46.1215 13.5343 40.0445 13.5343C25.4566 13.5343 13.6728 25.4277 13.6728 40.033C13.6728 53.8933 24.2434 65.2216 37.7097 66.3445L38.7396 69.9041Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.1257 7.50848C33.4043 7.41674 33.7818 7.41674 34.0603 7.32133C34.4342 7.22592 34.7164 6.85161 34.6211 6.4773L33.3126 0.580144C33.2173 0.205838 32.8435 -0.0767269 32.4696 0.0186845L30.505 0.488402C30.1312 0.583814 29.8489 0.95812 29.9442 1.33243L31.2527 7.22959C31.3444 7.60389 31.7219 7.88646 32.1874 7.79104C32.4733 7.69563 32.7518 7.60389 33.1257 7.50848Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.551 20.0882L7.50028 16.8112C7.12642 16.6241 6.65727 16.7195 6.47034 16.9984L5.34876 18.6828C5.16183 19.0571 5.25713 19.5268 5.53569 19.7139L10.5865 22.991C10.9603 23.1781 11.4295 23.0864 11.6164 22.7084C11.9903 22.1469 12.2725 21.5855 12.738 21.024C12.9249 20.7451 12.9249 20.2754 12.551 20.0882Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.9649 12.503L63.238 7.44619C63.4249 7.07189 63.3333 6.60217 63.0511 6.41501L61.3687 5.19668C60.9948 5.00953 60.5257 5.10127 60.3387 5.38384L57.0656 10.4406C56.8787 10.8149 56.974 11.2847 57.3479 11.4718C57.9087 11.8461 58.4695 12.2204 59.0302 12.5947C59.2172 12.8773 59.6863 12.7856 59.9649 12.503Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.1286 67.5588L16.8555 72.6156C16.6686 72.9899 16.7602 73.4597 17.0424 73.6468L18.7248 74.7697C19.0987 74.9569 19.5678 74.8651 19.7547 74.5826L23.0278 69.5258C23.2148 69.1515 23.1231 68.6817 22.7456 68.4946C22.1848 68.1203 21.624 67.746 21.0633 67.3717C20.8763 67.0928 20.3155 67.1845 20.1286 67.5588Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.5421 59.978L72.5929 63.255C72.9668 63.4421 73.4359 63.3504 73.6228 63.0678L74.7444 61.3835C74.9313 61.0091 74.8397 60.5394 74.5575 60.3523L69.5067 57.0753C69.1329 56.8881 68.6637 56.9798 68.4768 57.3541C68.1029 57.9156 67.8207 58.4771 67.3552 59.0385C67.1683 59.2294 67.1683 59.6954 67.5421 59.978Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.8744 31.2285C72.5005 31.3239 72.2183 31.6982 72.3136 32.1643C72.4089 32.4468 72.5005 32.8212 72.5005 33.1C72.5958 33.3826 72.5958 33.7569 72.6874 34.0358C72.7827 34.4101 73.1566 34.6927 73.5305 34.5973L79.4206 33.2872C79.7944 33.1918 80.0767 32.8175 79.9814 32.4432L79.5122 30.4762C79.4169 30.1019 79.043 29.8194 78.6692 29.9148C78.7645 29.9184 72.8744 31.2285 72.8744 31.2285Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5948 46.9618C7.4995 46.6829 7.4995 46.305 7.40787 46.0261C7.31258 45.6518 6.94238 45.3692 6.56486 45.4646L0.579451 46.6829C0.205592 46.7783 -0.0766352 47.1527 0.0186622 47.527L0.487818 49.4939C0.583116 49.8682 0.956975 50.1508 1.33083 50.0554L7.22094 48.7453C7.5948 48.6499 7.87703 48.2756 7.78173 47.8095C7.68643 47.6187 7.68643 47.3361 7.5948 46.9618Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.9672 72.4912C46.685 72.5866 46.3111 72.5866 46.0326 72.6784C45.6587 72.7738 45.3765 73.1481 45.4718 73.5224L46.7803 79.4195C46.8756 79.7939 47.2494 80.0764 47.6233 79.981L49.5879 79.5113C49.9618 79.4159 50.244 79.0416 50.1487 78.6673L48.8402 72.8619C48.7449 72.4875 48.371 72.205 47.9055 72.3004C47.6196 72.3958 47.3411 72.4912 46.9672 72.4912Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.016 60.7459L39.5027 61.4831L40.9895 60.7459C45.4933 58.5095 51.5272 54.3093 54.668 47.0179C58.2982 38.5865 56.6256 28.9719 56.5513 28.5693L55.6716 23.6753C55.3223 23.8843 54.9816 24.0912 54.648 24.2939C49.6637 27.3216 46.273 29.3812 39.5027 23C32.1497 29.9299 27.6579 26.5727 24.6744 24.3428C24.2067 23.9933 23.776 23.6714 23.3773 23.4213L22.448 28.5631C22.3737 28.9719 20.701 38.5865 24.3375 47.0179C27.4783 54.3093 33.506 58.5095 38.016 60.7459ZM28.7049 33.5571C28.6244 36.4935 28.8786 40.6622 30.4767 44.3664C32.4962 49.056 36.2566 52.0915 39.4965 53.9562C42.7427 52.0915 46.4969 49.056 48.5164 44.3664C50.1209 40.6371 50.3811 36.4802 50.2944 33.5562C48.8881 33.7668 47.1907 33.8288 45.2331 33.5872C42.625 33.2589 40.7789 32.6889 39.4904 32.0942C38.2018 32.6889 36.3557 33.2589 33.7476 33.5872C31.7991 33.8284 30.1038 33.767 28.7049 33.5571ZM38.8832 58.9927L39.5027 59.3024L40.1222 58.9927C44.3348 56.8988 49.9722 52.9836 52.8777 46.2373C56.2725 38.3387 54.699 29.2879 54.6308 28.9038L54.2591 26.9152C49.4704 29.7835 43.7401 30.2481 39.5709 25.67C35.445 29.9941 29.8386 29.9817 24.734 26.8718L24.3685 28.91C24.3003 29.2941 22.7268 38.3449 26.1278 46.2373C29.0333 52.9898 34.6707 56.8988 38.8832 58.9927ZM28.6863 45.1408C26.4562 39.968 26.6296 34.027 26.896 31.1526H26.9084C28.358 31.611 30.6006 32.0198 33.5246 31.6543C36.6716 31.2641 38.4992 30.5021 39.5089 29.9012C40.5187 30.5021 42.3462 31.2579 45.4933 31.6543C48.4173 32.0198 50.6599 31.611 52.1095 31.1526C52.3759 34.027 52.5555 39.9494 50.3191 45.1408C47.8845 50.7906 43.2135 54.2288 39.5027 56.1802C35.7858 54.235 31.121 50.7906 28.6863 45.1408Z"
            fill={color}
        />
        <path
            d="M39.5027 28.1604C39.5027 28.1604 40.7789 29.6596 45.6605 30.2667C50.5422 30.8738 53.2618 29.1578 53.2618 29.1578C53.2618 29.1578 54.8724 38.0909 51.5953 45.6921C48.6713 52.4756 42.7737 56.1244 39.5027 57.7475C36.2318 56.1244 30.328 52.4756 27.4102 45.6921C24.1392 38.0909 25.7437 29.1578 25.7437 29.1578C25.7437 29.1578 28.4633 30.88 33.345 30.2667C38.2266 29.6534 39.5027 28.1604 39.5027 28.1604Z"
            fill={color}
        />
    </svg>
);

IconAftersales.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconAftersales;
