import * as selectors from '../selectors';

import filterItems from './filterItems';

export default (maxNumChar = 3) =>
    (dispatch, getState) => {
        const state = getState();
        const searchText = selectors.getSearchText(state);

        if (searchText.length >= maxNumChar)
            return dispatch(filterItems(searchText));

        if (!searchText) return dispatch(filterItems());
    };
