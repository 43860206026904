import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
} from 'sunwise-ui';

const ReactHookFormCheckboxButtonGroup = ({
    control,
    disabled,
    items,
    name,
    row,
}) => (
    <Controller
        control={control}
        name={name}
        render={({
            field: { name, onChange, value: selectedValue },
            fieldState: { error },
        }) => (
            <FormControl error={Boolean(error)}>
                <FormGroup row={row}>
                    {items.map(
                        ({ label, value, hidden = false }, index) =>
                            !hidden && (
                                <FormControlLabel
                                    key={`checkbox-field-${value}-${index}`}
                                    control={
                                        <Checkbox
                                            checked={
                                                selectedValue[value] == true
                                            }
                                            name={name}
                                            onChange={(e) => {
                                                const newValue = Object.assign(
                                                    {},
                                                    selectedValue,
                                                    {
                                                        [value]:
                                                            e.target.checked,
                                                    },
                                                );

                                                onChange(newValue);
                                            }}
                                            value={value}
                                        />
                                    }
                                    disabled={disabled}
                                    label={label}
                                />
                            ),
                    )}{' '}
                </FormGroup>
                {Boolean(error) && (
                    <FormHelperText>{error?.message}</FormHelperText>
                )}
            </FormControl>
        )}
    />
);

ReactHookFormCheckboxButtonGroup.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    items: PropTypes.array,
    name: PropTypes.string,
    row: PropTypes.bool,
};

export default ReactHookFormCheckboxButtonGroup;
