import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

import { HIGH_TENSION_RATES } from 'common/constants/rates';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import { RATE_ALERT_TYPES } from '../../rateAlerts/constants';
import { getAlertMessage, getTotalProductPower } from '../helpers';

const PanelNumberWarning = ({
    contractedDemand,
    initialRate,
    limitKw,
    offerInverters,
    rateAlertsData,
    systemSize,
}) => {
    const { t } = useTranslation();
    const countryCurrencyLocale = getCountryCurrencyLocale();

    const kWLimitDC = rateAlertsData?.find(
        (alert) => alert.alert_type === RATE_ALERT_TYPES.KW_LIMIT_DC,
    );
    const kWLimitAC = rateAlertsData?.find(
        (alert) => alert.alert_type === RATE_ALERT_TYPES.KW_LIMIT_AC,
    );
    const alertContractedDemand = rateAlertsData?.find(
        (alert) => alert.alert_type === RATE_ALERT_TYPES.CONTRACTED_DEMAND,
    );

    const powerInstalled = Number(systemSize) || 0;
    const totalInverterPower = getTotalProductPower(offerInverters);

    const hasDefaultContractedDemandAlerts =
        !alertContractedDemand && HIGH_TENSION_RATES.indexOf(initialRate) > -1;

    const contractedDemandValue = hasDefaultContractedDemandAlerts
        ? contractedDemand
        : (contractedDemand * (alertContractedDemand?.value || 0)) / 100;
    const kWLimitDCValue = kWLimitDC?.value ?? limitKw;

    const getMessage = (msg, value) =>
        getAlertMessage(msg, value, countryCurrencyLocale);

    let alerts = [];

    if (
        (hasDefaultContractedDemandAlerts || alertContractedDemand?.status) &&
        powerInstalled > contractedDemandValue
    )
        alerts.push(
            getMessage(
                alertContractedDemand?.message ??
                    t('You have exceeded the limit of your contracted demand'),
                contractedDemandValue,
            ),
        );

    if (kWLimitDC?.status !== false && powerInstalled > kWLimitDCValue)
        alerts.push(
            getMessage(
                kWLimitDC?.message ??
                    t('You have exceeded the kW limit established by law'),
                kWLimitDCValue,
            ),
        );

    if (kWLimitAC?.status && totalInverterPower > kWLimitAC?.value)
        alerts.push(getMessage(kWLimitAC?.message, kWLimitAC?.value));

    return alerts.map((message, index) => (
        <Alert key={index} severity="warning">
            {message}
        </Alert>
    ));
};

PanelNumberWarning.propTypes = {
    contratedDemand: PropTypes.number,
    initialRate: PropTypes.string,
    limitKw: PropTypes.number,
    offerInverters: PropTypes.array,
    rateAlertsData: PropTypes.array,
    systemSize: PropTypes.number,
};

export default PanelNumberWarning;
