import { fullNameBuild } from 'common/utils/helpers';

import { ONE_TO_MANY } from '../constants';

import BaseModel from './BaseModel';
export default class InteractiveProject extends BaseModel {
    get customerName() {
        if (!this.ref.customerInfo) {
            return '';
        }
        const { first_lastname, name, second_lastname } = this.ref.customerInfo;
        return fullNameBuild({
            firstName: name,
            lastName: first_lastname,
            secondSurname: second_lastname,
        });
    }

    get installerName() {
        if (!this.ref.installerInfo) {
            return '';
        }
        const { first_name, last_name } = this.ref.installerInfo;
        return fullNameBuild({
            firstName: first_name,
            lastName: last_name,
        });
    }

    get signatureName() {
        if (!this.ref.signature) {
            return '';
        }
        const { data_signature, id, interactive_project, updated_at } =
            this.ref.signature;
        return fullNameBuild({
            dataSignature: data_signature,
            id: id,
            interactiveProject: interactive_project,
            updatedAt: updated_at,
        });
    }

    get installerEmail() {
        if (!this.ref.installerInfo) {
            return '';
        }
        return this.ref.installerInfo.username;
    }

    get hasSignature() {
        return this.ref.signature?.data_signature !== null;
    }
}

InteractiveProject.modelName = 'InteractiveProject';
InteractiveProject.prepareFields({
    createdAt: 'created_at',
    customerInfo: 'customer_info',
    id: 'id',
    installerInfo: 'installer_info',
    isDataSheetsNeed: 'is_data_sheets_need',
    isSmartDocumentsNeed: 'is_smart_documents_need',
    language: 'language',
    link: 'link',
    name: 'name',
    proposalSelected: 'proposal_selected',
    signature: 'signature',
    status: 'status',
    timelineId: {
        key: 'timeline_id',
        relationType: ONE_TO_MANY,
        options: {
            to: 'Timeline',
            as: 'timeline',
            relatedName: 'timeline',
        },
    },
    views: 'views',
});
