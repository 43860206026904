import React from 'react';

import PropTypes from 'prop-types';
import { Pagination, Skeleton } from 'sunwise-ui';

import PowerStationCard from './PowerStationCard';

const SKELETONS = [0, 1, 2, 3, 4];

const PowerStationCardList = ({
    canModify,
    credentialId,
    filterData,
    handleOpenAssignProjectModal,
    prepareUnassignProject,
    isFetching,
    onChangeFilter,
    paginationData,
    powerStations,
    projectsDictionary,
}) => {
    if (isFetching)
        return SKELETONS.map((index) => (
            <Skeleton
                height={100}
                key={index}
                sx={{ mb: 1 }}
                variant="rounded"
            />
        ));

    return (
        <>
            {powerStations.map((powerStation) => (
                <PowerStationCard
                    canModify={canModify}
                    credentialId={credentialId}
                    filterData={filterData}
                    handleOpenAssignProjectModal={handleOpenAssignProjectModal}
                    prepareUnassignProject={prepareUnassignProject}
                    key={powerStation.id}
                    powerStation={powerStation}
                    projectsDictionary={projectsDictionary}
                />
            ))}

            <Pagination
                color="primary"
                count={paginationData?.pages || 0}
                onChange={(_, page) => onChangeFilter('pageNo', page)}
                page={filterData.pageNo}
                sx={{ '& .MuiPagination-ul': { justifyContent: 'center' } }}
            />
        </>
    );
};

PowerStationCardList.propTypes = {
    canModify: PropTypes.bool,
    credentialId: PropTypes.string,
    filterData: PropTypes.object,
    handleOpenAssignProjectModal: PropTypes.func,
    prepareUnassignProject: PropTypes.func,
    isFetching: PropTypes.bool,
    onChangeFilter: PropTypes.func,
    paginationData: PropTypes.object,
    powerStations: PropTypes.array,
    projectsDictionary: PropTypes.object,
};

export default PowerStationCardList;
