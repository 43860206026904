export const NAME = 'rateAlerts';

export const RATE_ALERT_TYPES = {
    KW_LIMIT_DC: 0,
    CONTRACTED_DEMAND: 1,
    KW_LIMIT_AC: 2,
};

export const ALERT_TYPE_ITEMS = [
    {
        alertType: RATE_ALERT_TYPES.KW_LIMIT_DC,
        currentType: 'DC',
        defaultMessage: 'You have exceeded the kW limit established by law',
        defaultValue: 500,
        title: 'kW limit',
        unit: 'kW',
        unitLabel: 'Limit',
    },
    {
        alertType: RATE_ALERT_TYPES.KW_LIMIT_AC,
        currentType: 'AC',
        defaultMessage: 'You have exceeded the kW limit established by law',
        defaultValue: 500,
        title: 'kW limit',
        unit: 'kW',
        unitLabel: 'Limit',
    },
    {
        alertType: RATE_ALERT_TYPES.CONTRACTED_DEMAND,
        defaultMessage: 'You have exceeded the limit of your contracted demand',
        defaultValue: 100,
        title: 'Contracted demand',
        unit: '%',
        unitLabel: 'Percentage',
    },
];
