import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_CURRENCIES,
    FETCH_CURRENCIES_FAILURE,
    FETCH_CURRENCIES_SUCCESS,
    FETCH_INDICATORS_SETTINGS,
    FETCH_INDICATORS_SETTINGS_FAILURE,
    FETCH_INDICATORS_SETTINGS_SUCCESS,
    RESET,
    SAVE_INDICATORS_SETTINGS,
    SAVE_INDICATORS_SETTINGS_FAILURE,
    SAVE_INDICATORS_SETTINGS_SUCCESS,
    SET_IS_OPEN_INDICATORS_SETTINGS_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    currencies: {
        data: [],
        errors: [],
        isFetching: true,
    },
    indicatorsSettings: {
        data: [],
        errors: [],
        isFetching: true,
    },
    isOpenIndicatorsSettingsModal: false,
    saveIndicatorsSettings: {
        data: {},
        errors: [],
        isSaving: false,
    },
};

const proposalIndicatorsSlice = createSlice({
    name: NAME,
    initialState: {},
    reducers: {
        [FETCH_CURRENCIES]: (state) => {
            state.currencies = {
                ...state.currencies,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CURRENCIES_FAILURE]: (state, action) => {
            state.currencies = {
                ...state.currencies,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CURRENCIES_SUCCESS]: (state, action) => {
            state.currencies = {
                ...state.currencies,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INDICATORS_SETTINGS]: (state) => {
            state.indicatorsSettings = {
                ...state.indicatorsSettings,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_INDICATORS_SETTINGS_FAILURE]: (state, action) => {
            state.indicatorsSettings = {
                ...state.indicatorsSettings,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INDICATORS_SETTINGS_SUCCESS]: (state, action) => {
            state.indicatorsSettings = {
                ...state.indicatorsSettings,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [SAVE_INDICATORS_SETTINGS]: (state) => {
            state.saveIndicatorsSettings = {
                ...state.saveIndicatorsSettings,
                errors: null,
                isFetching: true,
            };
        },
        [SAVE_INDICATORS_SETTINGS_FAILURE]: (state, action) => {
            state.saveIndicatorsSettings = {
                ...state.saveIndicatorsSettings,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SAVE_INDICATORS_SETTINGS_SUCCESS]: (state, action) => {
            state.saveIndicatorsSettings = {
                ...state.saveIndicatorsSettings,
                data: action.payload,
                isFetching: false,
            };
        },
        [SET_IS_OPEN_INDICATORS_SETTINGS_MODAL]: (state, action) => {
            state.isOpenIndicatorsSettingsModal = action.payload;
        },
    },
});

export const proposalIndicatorsActions = proposalIndicatorsSlice.actions;

export default proposalIndicatorsSlice.reducer;
