import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Typography } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormInputPassword,
} from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { PROVIDERS_SETTINGS, VISIBLE_USER_FIELDS } from '../constants';
import { getFieldLabel } from '../helpers';
import validate from '../validate';

import ModalButtons from './ModalButtons';

const Form = ({ isSaving, onCancel, provider, resetForm, saveIntegration }) => {
    const { control, handleSubmit, reset } = useForm({
        context: { fields: provider?.fields },
        defaultValues: {},
        resolver: yupResolver(validate),
    });
    const { t } = useTranslation();

    useEffect(() => () => resetForm(), []);

    useEffect(() => {
        const newFields = provider?.fields?.reduce((acc, field) => {
            acc[field] = '';
            return acc;
        }, {});

        reset(newFields ?? {});
    }, [provider?.fields]);

    if (!provider?.fields?.length) return null;

    const onSubmit = (data) => saveIntegration(data, provider);

    const signUpLink = PROVIDERS_SETTINGS[provider.type];

    return (
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            {provider.fields.map((field) =>
                VISIBLE_USER_FIELDS[field] ? (
                    <ReactHookFormInput
                        control={control}
                        key={field}
                        label={getFieldLabel(field)}
                        name={field}
                    />
                ) : (
                    <ReactHookFormInputPassword
                        control={control}
                        key={field}
                        label={getFieldLabel(field)}
                        name={field}
                    />
                ),
            )}

            {signUpLink && (
                <Typography
                    variant="subtitle2"
                    sx={{
                        my: 1,
                        '& a': {
                            color: signUpLink.color,
                            textDecoration: 'none',
                            '&:hover': { textDecoration: 'underline' },
                        },
                    }}
                >
                    {t("Don't have an account?")}{' '}
                    <a href={signUpLink.url} target="__blank">
                        {t('Sign up')}
                    </a>
                </Typography>
            )}

            <ModalButtons
                cancelText={t('Cancel')}
                disabledCancel={isSaving}
                disabledNext={isSaving}
                nextButtonType="submit"
                nextText={t('Save')}
                onCancel={onCancel}
            />
        </form>
    );
};

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => dispatch(actions.resetForm()),
    saveIntegration: (data, provider) =>
        dispatch(actions.saveIntegration(data, provider)),
});

Form.propTypes = {
    isSaving: PropTypes.bool,
    onCancel: PropTypes.func,
    provider: PropTypes.object,
    resetForm: PropTypes.func,
    saveIntegration: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Form);
