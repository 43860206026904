import React, { useContext, useEffect } from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { LoadingContext } from 'common/utils/contexts';

import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';

import * as actions from './actions';
import SidebarForm from './components/SidebarForm';
import { getCustomOfferTemplatesToSelect } from './helpers';
import * as selectors from './selectors';

const Container = ({
    canModify,
    customTemplates,
    financialProductParameters,
    handleClickRefresh,
    initialize,
    isFetchingProposalCatalogs,
    isGenerated,
    isLoading,
    isRefreshingFinancing,
    isSaving,
    isSavingOfferEnd,
    offerDetails,
    offerPlanId,
    offerTemplateId,
    proposalId,
    reverseBtn,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        initialize(offerPlanId, offerTemplateId);
    }, [offerTemplateId, offerPlanId]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSaving || isSavingOfferEnd)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...'),
            );
        else loadingContext.closeLoading();
    }, [isSaving, isSavingOfferEnd]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isRefreshingFinancing)
            loadingContext.openLoading(t('Updating').concat('...'));
        else loadingContext.closeLoading();
    }, [isRefreshingFinancing]);

    const creditId = get(financialProductParameters, 'id', '');
    const isEditingProposal = get(
        financialProductParameters,
        'is_editing',
        false,
    );
    const isLoadingForm = isFetchingProposalCatalogs || isLoading;

    return (
        <SidebarForm
            canModify={canModify}
            creditId={creditId}
            handleClickRefresh={handleClickRefresh}
            isEditingProposal={isEditingProposal}
            isGenerated={isGenerated}
            isLoading={isLoadingForm}
            offerDetails={offerDetails}
            proposalId={proposalId}
            reverseBtn={reverseBtn}
            templates={getCustomOfferTemplatesToSelect(customTemplates)}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    customTemplates:
        proposalGeneratorQuoteSelectors.getProposalTemplateCatalogs,
    isFetchingProposalCatalogs:
        proposalGeneratorQuoteSelectors.getIsFetchingProposalCatalogs,
    isRefreshingFinancing: selectors.getIsRefreshingFinancing,
    isSaving: selectors.getIsSaving,
    isSavingOfferEnd: selectors.getIsSavingOfferEnd,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickRefresh: (creditId, proposalId) =>
        dispatch(actions.refreshFinancingData(creditId, proposalId)),
    initialize: (offerPlanId, offerTemplateId) =>
        dispatch(actions.initialValues(offerPlanId, offerTemplateId)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    customTemplates: PropTypes.array,
    financialProductParameters: PropTypes.object,
    handleClickRefresh: PropTypes.func,
    initialize: PropTypes.func,
    isFetchingProposalCatalogs: PropTypes.bool,
    isGenerated: PropTypes.bool,
    isLoading: PropTypes.bool,
    isRefreshingFinancing: PropTypes.bool,
    isSaving: PropTypes.bool,
    isSavingOfferEnd: PropTypes.bool,
    offerDetails: PropTypes.object,
    offerPlanId: PropTypes.string,
    offerTemplateId: PropTypes.string,
    proposalId: PropTypes.string,
    reverseBtn: PropTypes.bool,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION),
)(Container);
