import React from 'react';

import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

import { POINTER_CURSOR } from '../constants';

const Rate = styled(Box)`
    cursor: ${POINTER_CURSOR};
`;

const RateFormatter = (params) => {
    const { row } = params;
    return <Rate>{row.rate}</Rate>;
};

RateFormatter.propTypes = {};

export default RateFormatter;
