import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Alert,
    Box,
    Button,
    Card,
    Container,
    Grid,
    Typography,
} from 'sunwise-ui';

import {
    CollapsedBreadcrumbs,
    EmptyMessage,
    HeaderContainer,
    TitleIcon,
} from 'common/components';
import {
    ReactHookFormCheckboxButtonGroup,
    ReactHookFormColorPicker,
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import MainBranchOfficeSelectorRHF from 'common/modules/multiBranches/components/MainBranchOfficeSelectorRHF';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { getCurrencyIso } from 'common/utils/helpers';
import { getIsMexicanAccount } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import { getUserOptions } from '../../../contactForm/helpers';
import * as integrationsSelectors from '../../selectors';
import * as actions from '../actions';
import { getFormConfig } from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

import DummyFormContainer from './DummyFormContainer';

const StyledTitle = styled(Typography)`
    font-size: 23px;
    font-weight: bold;
    line-height: 28px;
`;

const StyledH1 = styled(Typography)`
    font-size: 23px;
    font-weight: bold;
    line-height: 28px;
`;

const StyledLabel = styled(Typography)`
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    margin: 22px 0;
`;

const StyledH3 = styled(Typography)`
    color: #828aa5;
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
    line-height: 31px;
    margin: 11px 0;
`;

const IntegrationForm = ({
    agents,
    branchOffices,
    canModify,
    defaultCurrency,
    fetchAgents,
    fetchTemplates,
    handleClickBack,
    initialValues,
    integration,
    isFetchingAgents,
    isFetchingIntegration,
    isFetchingOrigins,
    isFetchingStatusProject,
    isFetchingTemplates,
    isSaving,
    match,
    originContactOptions = [],
    save,
    setShowModal,
    statusProjectsOptions = [],
    templatesOptions = [],
}) => {
    const { t } = useTranslation();
    const currencyIso = getCurrencyIso(defaultCurrency);
    const isMexicanAccount = getIsMexicanAccount();

    const {
        control,
        formState: { isValid, isSubmitted },
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const [branchOffice, isGlobal] = watch(['branch_office', 'is_global']);

    const handleBranchOfficeFieldChange = (value) => {
        if (!value) return;
        setValue('agent', '');
        setValue('external_custom_template_id', '');
    };

    useEffect(() => {
        if (branchOffice) {
            fetchAgents(branchOffice);
            fetchTemplates(branchOffice);
            if (branchOffice !== initialValues?.branch_office)
                handleBranchOfficeFieldChange(branchOffice);
        }
    }, [branchOffice]);

    const handleSave = (values) => {
        save({ ...values, application: match.params.uidParent }, () => {
            setShowModal(true);
        });
    };

    return (
        <Container maxWidth={false} sx={{ mb: 2 }}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon title={t('CRM configurator')} />
                    <Box sx={{ pl: { md: 2 } }}>
                        <CollapsedBreadcrumbs
                            current={integration?.name || t('CRM configurator')}
                            locations={[
                                {
                                    label: t('Web quoter'),
                                    action: () =>
                                        handleClickBack('/web-quoter'),
                                },
                            ]}
                        />
                    </Box>
                </Grid>
            </HeaderContainer>

            <Grid container sx={{ mb: 2 }}>
                <Grid item xs>
                    <ReactHookFormSelect
                        control={control}
                        disabled={!canModify || isFetchingStatusProject}
                        label={t('Project status').concat(' *')}
                        name="status_project"
                        options={statusProjectsOptions}
                    />
                </Grid>

                <Grid item xs>
                    <ReactHookFormSelect
                        control={control}
                        disabled={!canModify || isFetchingOrigins}
                        label={t('Contact origin').concat(' *')}
                        name="contact_origin"
                        options={originContactOptions}
                    />
                </Grid>

                <Grid item xs>
                    <MainBranchOfficeSelectorRHF
                        control={control}
                        disabled={branchOffices?.length === 1}
                        hideCompany={isGlobal ? false : true}
                        label={t('Branch office')}
                        name="branch_office"
                    />
                </Grid>

                <Grid item xs>
                    <ReactHookFormSelect
                        control={control}
                        disabled={!canModify || isFetchingAgents}
                        label={t('User')}
                        name="agent"
                        options={getUserOptions(agents)}
                    />
                </Grid>

                <Grid item xs sm>
                    <ReactHookFormSelect
                        control={control}
                        disabled={
                            !branchOffice || !canModify || isFetchingTemplates
                        }
                        label={t('Template').concat(' *')}
                        name="external_custom_template_id"
                        options={templatesOptions}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={18} lg={7}>
                    <Card>
                        <Card.Header>
                            <StyledTitle component="h1">
                                {t('Form settings')}
                            </StyledTitle>
                        </Card.Header>
                        <Card.Body>
                            <StyledH3 component="h3">
                                {t('User input')}
                            </StyledH3>

                            <StyledLabel component="label">
                                {t('Service number')}
                            </StyledLabel>

                            <div>
                                <ReactHookFormCheckboxButtonGroup
                                    control={control}
                                    disabled={!canModify}
                                    items={[
                                        {
                                            label: t('Service number'),
                                            value: '1',
                                        },
                                    ]}
                                    name="service_number"
                                />
                            </div>

                            <StyledLabel component="label">
                                {t('Method for obtaining consumptions')} *
                            </StyledLabel>

                            <div>
                                <ReactHookFormCheckboxButtonGroup
                                    control={control}
                                    disabled={!canModify}
                                    items={[
                                        {
                                            label: t('Energy').concat(' (kWh)'),
                                            value: 'ENERGY',
                                        },
                                        {
                                            label: `${t(
                                                'Money',
                                            )} (${currencyIso})`,
                                            value: 'MONEY',
                                            hidden: !isMexicanAccount,
                                        },
                                    ]}
                                    name="method_consumtion"
                                    row
                                />
                            </div>

                            <StyledLabel component="label">
                                {t('Calculation by means of')} *
                            </StyledLabel>

                            <div>
                                <ReactHookFormCheckboxButtonGroup
                                    control={control}
                                    disabled={!canModify}
                                    items={[
                                        {
                                            label: t('Rate'),
                                            value: 'RATE',
                                            hidden: !isMexicanAccount,
                                        },
                                        {
                                            label: t('Type of installation'),
                                            value: 'INSTALLATION_TYPE',
                                        },
                                    ]}
                                    name="install_type"
                                    row
                                />
                            </div>

                            <StyledH3 component="h3">
                                {t('Title format')}
                            </StyledH3>

                            <Grid container>
                                <Grid item md={9} xs={18}>
                                    <ReactHookFormIntlNumberInput
                                        allowDecimals={false}
                                        allowNegativeValue={false}
                                        append="px"
                                        control={control}
                                        disabled={!canModify}
                                        fullWidth
                                        label={t('Size')}
                                        name="style.h1.fontSize"
                                        placeholder={t('Size')}
                                    />
                                </Grid>
                                <Grid item md={9} xs={18}>
                                    <ReactHookFormColorPicker
                                        control={control}
                                        disabled={!canModify}
                                        fullWidth
                                        initialHexColor="#fff"
                                        label={t('Color')}
                                        name="style.h1.color"
                                    />
                                </Grid>
                            </Grid>

                            <StyledH3 component="h3">
                                {t('Subtitle format')}
                            </StyledH3>

                            <Grid container>
                                <Grid item md={9} xs={18}>
                                    <ReactHookFormIntlNumberInput
                                        allowDecimals={false}
                                        allowNegativeValue={false}
                                        append="px"
                                        control={control}
                                        disabled={!canModify}
                                        fullWidth
                                        label={t('Size')}
                                        name="style.h2.fontSize"
                                        placeholder={t('Size')}
                                    />
                                </Grid>
                                <Grid item md={9} xs={18}>
                                    <ReactHookFormColorPicker
                                        control={control}
                                        disabled={!canModify}
                                        fullWidth
                                        initialHexColor="#fff"
                                        label={t('Color')}
                                        name="style.h2.color"
                                    />
                                </Grid>
                            </Grid>

                            <StyledH3 component="h3">
                                {t('Button format')}
                            </StyledH3>

                            <Grid container>
                                <Grid item md={9} xs={18}>
                                    <ReactHookFormIntlNumberInput
                                        allowDecimals={false}
                                        allowNegativeValue={false}
                                        append="px"
                                        control={control}
                                        disabled={!canModify}
                                        fullWidth
                                        label={t('Border radius')}
                                        name="style.button.borderRadius"
                                        placeholder={t('Border radius')}
                                    />
                                </Grid>
                                <Grid item md={9} xs={18}>
                                    <ReactHookFormColorPicker
                                        control={control}
                                        disabled={!canModify}
                                        fullWidth
                                        initialHexColor="#fff"
                                        label={t('Background color')}
                                        name="style.button.backgroundColor"
                                    />
                                </Grid>
                                <Grid item md={9} xs={18}>
                                    <ReactHookFormColorPicker
                                        control={control}
                                        disabled={!canModify}
                                        fullWidth
                                        initialHexColor="#fff"
                                        label={t('Text color')}
                                        name="style.button.color"
                                    />
                                </Grid>

                                <Grid item md={9} xs={18}>
                                    <ReactHookFormInput
                                        control={control}
                                        disabled={!canModify}
                                        fullWidth
                                        label={t('Text')}
                                        name="style.button.text"
                                        placeholder={t('Text')}
                                    />
                                </Grid>
                                <Grid item md={9} xs={18}>
                                    <ReactHookFormIntlNumberInput
                                        allowDecimals={false}
                                        allowNegativeValue={false}
                                        append="px"
                                        control={control}
                                        disabled={!canModify}
                                        fullWidth
                                        label={t('Text size')}
                                        name="style.button.fontSize"
                                        placeholder={t('Text size')}
                                    />
                                </Grid>
                            </Grid>
                        </Card.Body>
                    </Card>
                </Grid>

                <Grid item xs={18} lg={11}>
                    <Card>
                        <Card.Header>
                            <StyledH1 component="h1">
                                {t('Form preview')}
                            </StyledH1>
                        </Card.Header>
                        <Card.Body>
                            {isFetchingIntegration ? (
                                <EmptyMessage
                                    text={t('Loading').concat('...')}
                                />
                            ) : (
                                <DummyFormContainer
                                    config={getFormConfig(watch())}
                                    currencyIso={currencyIso}
                                    isFetchingIntegration={
                                        isFetchingIntegration
                                    }
                                />
                            )}
                        </Card.Body>
                    </Card>

                    {isSubmitted && !isValid && (
                        <Alert severity="warning">
                            <p>
                                {t('Complete all fields marked as mandatory')}
                            </p>
                        </Alert>
                    )}

                    <Box sx={{ mt: 2 }}>
                        <Button
                            disabled={isSubmitted && !isValid}
                            fullWidth
                            type="button"
                            onClick={handleSubmit(handleSave)}
                            visible={canModify}
                        >
                            <strong>
                                {isSaving
                                    ? t('Saving').concat('...')
                                    : t('Save settings')}
                            </strong>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

IntegrationForm.propTypes = {
    agents: PropTypes.array,
    branchOffices: PropTypes.array,
    canModify: PropTypes.bool,
    defaultCurrency: PropTypes.object,
    fetchAgents: PropTypes.func,
    fetchTemplates: PropTypes.func,
    handleClickBack: PropTypes.func,
    initialValues: PropTypes.object,
    integration: PropTypes.object,
    isFetchingAgents: PropTypes.bool,
    isFetchingIntegration: PropTypes.bool,
    isFetchingOrigins: PropTypes.bool,
    isFetchingStatusProject: PropTypes.bool,
    isFetchingTemplates: PropTypes.bool,
    isSaving: PropTypes.bool,
    match: PropTypes.object,
    originContactOptions: PropTypes.array,
    save: PropTypes.func,
    setShowModal: PropTypes.func,
    statusProjectsOptions: PropTypes.array,
    templatesOptions: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    branchOffices: multiBranchesSelectors.getFilteredBranchesData,
    initialValues: selectors.getInitialValuesForm,
    integration: integrationsSelectors.getIntegrationData,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickBack: (tab) => dispatch(actions.handleClickBack(tab)),
    save: (values, onSuccessCallback) =>
        dispatch(actions.save(values, onSuccessCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationForm);
