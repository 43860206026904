import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getSummaryInfoPatern = createSelector(
    getModel,
    (model) => model.summaryInfoPatern,
);

export const getIsFetchingSummaryInfoPatern = createSelector(
    getSummaryInfoPatern,
    (value) => value.isFetching,
);

export const getDataSummaryrInfoPatern = createSelector(
    getSummaryInfoPatern,
    (value) => value.data,
);
