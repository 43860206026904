import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'aftersales-project-configuration';

export const create = (data) => API.post(`/api/v1/${ENTITY}/`, data, config);
export const fetchData = (uuid) => API.get(`/api/v1/${ENTITY}/${uuid}`);
export const update = (uuid, data) =>
    API.put(`/api/v1/${ENTITY}/${uuid}`, data, config);
export const deleteItem = (uuid) => API.delete(`/api/v1/${ENTITY}/${uuid}`);
