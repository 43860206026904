import i18next from 'i18next';

import { bulkProjects } from 'common/api/v1/contactProject';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_PROJECT,
    DELETE_PROJECT_FAILURE,
    DELETE_PROJECT_SUCCESS,
} from '../actionTypes';
import { projectActions } from '../reducer';

export default (id, status, callback) => (dispatch) => {
    dispatch(projectActions[DELETE_PROJECT]());

    bulkProjects({
        projects: [id],
        status_flag: status,
    })
        .then(() => {
            dispatch(projectActions[DELETE_PROJECT_SUCCESS]());
            dispatch(alerts.actions.close());
            if (callback) callback();
            showToast({ body: i18next.t('Successfully deleted') });
        })
        .catch((error) => {
            dispatch(
                projectActions[DELETE_PROJECT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
