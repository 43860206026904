import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_INTEGRATION,
    DELETE_INTEGRATION_FAILURE,
    DELETE_INTEGRATION_SUCCESS,
    FETCH_INTEGRATIONS,
    FETCH_INTEGRATIONS_FAILURE,
    FETCH_INTEGRATIONS_SUCCESS,
    FETCH_PROVIDERS,
    FETCH_PROVIDERS_FAILURE,
    FETCH_PROVIDERS_SUCCESS,
    INITIALIZE_FORM,
    RESET,
    RESET_FORM,
    SAVE_INTEGRATION,
    SAVE_INTEGRATION_FAILURE,
    SAVE_INTEGRATION_SUCCESS,
    SET_IS_OPEN_FORM_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    deleteIntegration: { error: null, isDeleting: false },
    initialValues: {},
    integrations: { data: null, error: null, isFetching: false },
    isOpenFormModal: false,
    providers: { data: null, error: null, isFetching: false },
    saveIntegration: { error: null, isSaving: false },
};

const supplierIntegrationsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_INTEGRATION]: (state) => {
            state.deleteIntegration.error = null;
            state.deleteIntegration.isDeleting = true;
        },
        [DELETE_INTEGRATION_FAILURE]: (state, action) => {
            state.deleteIntegration.error = action.payload;
            state.deleteIntegration.isDeleting = false;
        },
        [DELETE_INTEGRATION_SUCCESS]: (state) => {
            state.deleteIntegration.error = null;
            state.deleteIntegration.isDeleting = false;
        },
        [FETCH_INTEGRATIONS]: (state) => {
            state.integrations.error = null;
            state.integrations.isFetching = true;
        },
        [FETCH_INTEGRATIONS_FAILURE]: (state, action) => {
            state.integrations.error = action.payload;
            state.integrations.isFetching = false;
        },
        [FETCH_INTEGRATIONS_SUCCESS]: (state, action) => {
            state.integrations.data = action.payload;
            state.integrations.isFetching = false;
        },
        [FETCH_PROVIDERS]: (state) => {
            state.providers.error = null;
            state.providers.isFetching = true;
        },
        [FETCH_PROVIDERS_FAILURE]: (state, action) => {
            state.providers.error = action.payload;
            state.providers.isFetching = false;
        },
        [FETCH_PROVIDERS_SUCCESS]: (state, action) => {
            state.providers.data = action.payload;
            state.providers.isFetching = false;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_INTEGRATION]: (state) => {
            state.saveIntegration.error = null;
            state.saveIntegration.isSaving = true;
        },
        [SAVE_INTEGRATION_FAILURE]: (state, action) => {
            state.saveIntegration.error = action.payload;
            state.saveIntegration.isSaving = false;
        },
        [SAVE_INTEGRATION_SUCCESS]: (state) => {
            state.saveIntegration.error = null;
            state.saveIntegration.isSaving = false;
        },
        [SET_IS_OPEN_FORM_MODAL]: (state, action) => {
            state.isOpenFormModal = action.payload;
        },
    },
});

export const supplierIntegrationsActions = supplierIntegrationsSlice.actions;

export default supplierIntegrationsSlice.reducer;
