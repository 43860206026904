import React from 'react';

import PropTypes from 'prop-types';
import { useFieldArray, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TableRow } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import { MONTHS } from 'common/constants';
import { getMonthLabel } from 'common/utils/helpers';

import StyledTableCell from './StyledTableCell';

const MonthsFieldRHF = ({ breakpoint, control, label, name, setValue }) => {
    const { t } = useTranslation();
    const { fields } = useFieldArray({ control, name });
    const { errors } = useFormState({ control });
    return (
        <>
            <TableRow>
                <StyledTableCell>{label}</StyledTableCell>
                {fields.map((month, index) => (
                    <StyledTableCell key={`month-${month.id}`}>
                        <ReactHookFormIntlNumberInput
                            control={control}
                            label={
                                breakpoint === 'xs'
                                    ? getMonthLabel(MONTHS[index])
                                    : null
                            }
                            name={`${name}.${index}.value`}
                            step={0.01}
                            onChange={(e) => {
                                if (label === t('Hr Sun')) {
                                    setValue(
                                        `kwhkw.${index}.value`,
                                        parseFloat(e.target.value) * 365,
                                    );
                                } else {
                                    setValue(
                                        `hrsSol.${index}.value`,
                                        parseFloat(e.target.value) / 365,
                                    );
                                }
                            }}
                        />
                    </StyledTableCell>
                ))}
            </TableRow>

            {errors?.[name]?.message && (
                <TableRow>
                    <StyledTableCell colSpan="13">
                        {errors?.[name]?.message}
                    </StyledTableCell>
                </TableRow>
            )}
        </>
    );
};

MonthsFieldRHF.propTypes = {
    breakpoint: PropTypes.string,
    control: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    setValue: PropTypes.func,
};

export default MonthsFieldRHF;
