import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListIcon from '@mui/icons-material/List';
import TableChartIcon from '@mui/icons-material/TableChart';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, ListItemIcon, Menu, MenuItem } from 'sunwise-ui';

const ViewModeSelector = ({ viewMode, handleSetViewMode }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);

    return (
        <>
            <Button
                color="secondary"
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}
                variant="text"
            >
                {viewMode === 'list' ? (
                    <ListIcon fontSize="small" sx={{ color: '#848bab' }} />
                ) : (
                    <TableChartIcon
                        fontSize="small"
                        sx={{ color: '#848bab' }}
                    />
                )}
            </Button>

            <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
                <MenuItem
                    onClick={() => {
                        handleSetViewMode('column');
                        handleClose();
                    }}
                >
                    <ListItemIcon sx={{ minWidth: '24px !important' }}>
                        <TableChartIcon
                            fontSize="small"
                            sx={{ color: '#848bab' }}
                        />
                    </ListItemIcon>
                    Funnel
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleSetViewMode('list');
                        handleClose();
                    }}
                >
                    <ListItemIcon sx={{ minWidth: '24px !important' }}>
                        <ListIcon fontSize="small" sx={{ color: '#848bab' }} />
                    </ListItemIcon>
                    {t('List')}
                </MenuItem>
            </Menu>
        </>
    );
};

ViewModeSelector.propTypes = {
    viewMode: PropTypes.string,
    handleSetViewMode: PropTypes.func,
};

export default ViewModeSelector;
