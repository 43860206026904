import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import { getCustomOfferTemplatesToSelect } from '../helpers';
import * as selectors from '../selectors';

import SidebarForm from './SidebarForm';

const Sidebar = ({
    canModify,
    isGenerated,
    reportId,
    reverseBtn,
    templates,
}) => {
    return (
        <SidebarForm
            canModify={canModify}
            isGenerated={isGenerated}
            reportId={reportId}
            reverseBtn={reverseBtn}
            templates={getCustomOfferTemplatesToSelect(templates)}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    templates: selectors.getTemplatesData,
    isSaving: selectors.getSaveIsSaving,
});

const mapDispatchToProps = () => ({});

Sidebar.propTypes = {
    canModify: PropTypes.bool,
    isGenerated: PropTypes.bool,
    reportId: PropTypes.string,
    reverseBtn: PropTypes.bool,
    templates: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.AFTER_SALES_PERMISSION),
)(Sidebar);
