import { deleteRole } from 'common/api/v1/companyRoles';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_ITEM,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_SUCCESS,
} from '../actionTypes';
import { rolesConfigurationActions } from '../reducer';

import fetchItems from './fetchItems';
import openModal from './openModal';
import resetForm from './resetForm';

export default (id) => (dispatch) => {
    dispatch(rolesConfigurationActions[DELETE_ITEM]());

    deleteRole(id)
        .then(() => {
            dispatch(rolesConfigurationActions[DELETE_ITEM_SUCCESS]());
            dispatch(alerts.actions.close());
            dispatch(fetchItems());
            dispatch(openModal(false));
            dispatch(resetForm());

            showToast({ body: 'Se eliminó exitosamente' });
        })
        .catch((error) => {
            dispatch(
                rolesConfigurationActions[DELETE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
