import { patchItem } from 'common/api/v1/panelModules';

import {
    CHANGE_MODULE_STATUS,
    CHANGE_MODULE_STATUS_FAILURE,
    CHANGE_MODULE_STATUS_SUCCESS,
} from '../actionTypes';
import {
    getDictionaryById,
    getModulesGroups,
    getQuantityEnabledModules,
} from '../helpers';
import { panelLayoutV2Actions } from '../reducer';
import * as selectors from '../selectors';

import updateSegments from './updateSegments';

export default (moduleObject) => (dispatch, getState) => {
    const state = getState();
    const segments = selectors.getSegmentsData(state);
    const segmentsDictionary = getDictionaryById(segments);
    const parentSegment = segmentsDictionary[moduleObject.parentId];
    const currentValue = moduleObject.moduleEnable;
    const newValue = { is_enable: !currentValue };

    const { cell, col, group, row } = moduleObject;
    const { grouped_cells, solar_modules } = parentSegment;

    let grouped = [];
    let modules_groups = [];

    if (grouped_cells.length) {
        grouped = JSON.parse(JSON.stringify(grouped_cells));
        grouped[group][cell][row][col] = {
            ...grouped_cells[group][cell][row][col],
            enable: !currentValue,
        };

        modules_groups = getModulesGroups(grouped);
    }

    moduleObject.handleModuleEnableStatus(newValue.is_enable);

    dispatch(panelLayoutV2Actions[CHANGE_MODULE_STATUS]());

    patchItem(moduleObject.id, newValue)
        .then((response) => {
            const mutatedSolarModules = solar_modules.map((module) =>
                module.id === moduleObject.id
                    ? { ...module, enabled: !currentValue }
                    : module,
            );
            const quantityEnabledModules =
                getQuantityEnabledModules(mutatedSolarModules);
            const updatedSegment = {
                ...parentSegment,
                grouped_cells: grouped,
                quantity_enabled_modules: quantityEnabledModules,
                solar_modules: mutatedSolarModules,
                modules_groups,
            };
            const newValuesSegments = segments.map((segment) =>
                segment.id === updatedSegment.id
                    ? { ...segment, ...updatedSegment }
                    : segment,
            );

            dispatch(updateSegments(newValuesSegments));

            dispatch(
                panelLayoutV2Actions[CHANGE_MODULE_STATUS_SUCCESS](response),
            );
        })
        .catch((error) => {
            moduleObject.handleModuleEnableStatus(currentValue);
            dispatch(panelLayoutV2Actions[CHANGE_MODULE_STATUS_FAILURE](error));
        });
};
