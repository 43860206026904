import * as selectors from '../selectors';

import initialValues from './initialValues';
import resetForm from './resetForm';
import setIsOpenModal from './setIsOpenModal';

export default () => (dispatch, getState) => {
    const state = getState();
    const offerComplements = selectors.getOfferComplementsData(state);

    dispatch(resetForm());
    dispatch(initialValues(offerComplements));
    dispatch(setIsOpenModal(false));
};
