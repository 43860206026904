import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Skeleton } from 'sunwise-ui';

const ColWrapper = styled(Box)`
    flex: 0 0 auto;
    margin: 0 8px 0 0;
    overflow: hidden;
    width: 316px;
`;
const ProjectContainer = styled(Box)`
    height: calc(100% - 42px);
`;

const Title = styled(Box)`
    display: block;
    margin-bottom: 16px;
`;

const PlaceholderColumn = ({ children, isEmpty, ready }) => {
    const { t } = useTranslation();

    if (ready) {
        if (isEmpty) return <p>{t('There are no projects')}</p>;
        return children;
    }

    return [1, 2, 3, 4, 5, 6].map((key) => {
        return (
            <ColWrapper key={key}>
                <Title>
                    <Skeleton variant="rounded" />
                </Title>

                <ProjectContainer>
                    <Card sx={{ borderRadius: '4px' }}>
                        <Card.Body>
                            <Skeleton variant="rounded" />
                        </Card.Body>
                    </Card>
                </ProjectContainer>
            </ColWrapper>
        );
    });
};

PlaceholderColumn.propTypes = {
    children: PropTypes.node,
    isEmpty: PropTypes.bool,
    ready: PropTypes.bool,
};

export default PlaceholderColumn;
