import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

import * as credentialIntegrationsActions from '../../credentialIntegrations/actions';
import * as credentialIntegrationsSelectors from '../../credentialIntegrations/selectors';
import * as actions from '../actions';
import { getProjectsWithoutInfo } from '../helpers';
import * as selectors from '../selectors';

import Historical from './Historical';

const ContentWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 0 16px;

    ${scroll.custom()};
`;

const DrawerContent = ({
    closeDrawer,
    fetchProjectsInfo,
    fetchProviders,
    isLoadingProjectsInfo,
    logsData,
    projectsInfo,
    providersDictionary,
}) => {
    const wrapperRef = React.useRef(null);
    useEffect(() => {
        fetchProviders();
    }, []);

    useEffect(() => {
        if (isLoadingProjectsInfo) return;

        const projectsWithoutInfo = getProjectsWithoutInfo({
            logsData,
            projectsInfo,
        });

        if (projectsWithoutInfo?.length > 0)
            fetchProjectsInfo(projectsWithoutInfo);
    }, [logsData, isLoadingProjectsInfo]);

    return (
        <ContentWrapper role="presentation">
            <Historical
                closeDrawer={closeDrawer}
                logsData={logsData}
                projectsInfo={projectsInfo}
                providersDictionary={providersDictionary}
                wrapperRef={wrapperRef}
            />
        </ContentWrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    isLoadingProjectsInfo: selectors.getProjectsInfoLoading,
    logsData: selectors.getLogsData,
    projectsInfo: selectors.getProjectsInfoData,
    providersDictionary: credentialIntegrationsSelectors.getProvidersDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjectsInfo: (projects) =>
        dispatch(actions.fetchProjectsInfo(projects)),
    fetchProviders: () =>
        dispatch(credentialIntegrationsActions.fetchProviders()),
});

DrawerContent.propTypes = {
    closeDrawer: PropTypes.func,
    fetchProjectsInfo: PropTypes.func,
    fetchProviders: PropTypes.func,
    isLoadingProjectsInfo: PropTypes.bool,
    logsData: PropTypes.array,
    projectsInfo: PropTypes.object,
    providersDictionary: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContent);
