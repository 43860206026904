import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import { getDictionaryById } from '../helpers';
import * as selectors from '../selectors';

import deleteSegment from './deleteSegment';

export default (
        callback,
        commercialOfferId,
        id,
        google,
        checkCallback,
        checkCallbackError,
    ) =>
    (dispatch, getState) => {
        const state = getState();
        const segments = selectors.getSegmentsData(state);
        const segmentsDictionary = getDictionaryById(segments);
        const dataAlert = {
            confirmText: i18next.t('Accept'),
            messages: [
                i18next.t('Are you sure to delete the item "{{name}}"?', {
                    name: segmentsDictionary[id].name,
                }),
            ],
            onSuccess: () =>
                dispatch(
                    deleteSegment(
                        callback,
                        commercialOfferId,
                        id,
                        google,
                        checkCallback,
                        checkCallbackError,
                    ),
                ),
            title: i18next.t('Delete segment'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };

        dispatch(alerts.actions.show(dataAlert));
    };
