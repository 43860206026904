import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { GridRowModes } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip } from 'sunwise-ui';

import { Switch } from 'common/components/form';

const ProductActionsFormatter = ({
    canModify,
    handleChangeSwitch,
    row,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();

    const isInEditMode = rowModesModel[row?.id]?.mode === GridRowModes.Edit;

    const handleClickSave = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
        });
        setSelectedRowId(null);
    };

    const handleClickCancel = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        setSelectedRowId(null);
    };

    const renderOptions = () => {
        if (isInEditMode) {
            return (
                <>
                    <Tooltip title={t('Save changes')}>
                        <IconButton onClick={handleClickSave(selectedRowId)}>
                            <SaveIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Cancel')}>
                        <IconButton onClick={handleClickCancel(selectedRowId)}>
                            <CancelIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            );
        }

        return (
            <Switch
                checked={row?.enabled}
                onChange={() => handleChangeSwitch(row.id)}
                sx={{ marginRight: '0!important' }}
                visible={canModify}
            />
        );
    };

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '4px',
                justifyContent: 'end',
                width: '100%',
            }}
        >
            {renderOptions()}
        </Box>
    );
};

ProductActionsFormatter.propTypes = {
    canModify: PropTypes.bool,
    handleChangeSwitch: PropTypes.func,
    row: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.string,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductActionsFormatter;
