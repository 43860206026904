import API from '../..';
const ENTITY = 'dashboards';

export const getContactsAgentsTrackings = (id) =>
    API.get(`/api/v1/${ENTITY}/tracking/${id}`);
export const getContactsTrackings = (userId = '') =>
    API.get(`/api/v1/${ENTITY}/tracking/${userId}`);
export const getLastCommercialOffers = () =>
    API.get(`/api/v1/${ENTITY}/last_commercial_offer/`);
export const getLastCommercialOffersByRate = (id) =>
    API.post(`/api/v1/${ENTITY}/last_commercial_offer/`, { rate: id });
export const getProjectsCounters = (initialDate, finalDate, agentId = null) =>
    API.get(`/api/v1/${ENTITY}/projects/`, {
        params: {
            agent_id: agentId,
            final_date: finalDate,
            initial_date: initialDate,
        },
    });
export const getProjectsSalesStage = () =>
    API.get(`/api/v1/${ENTITY}/projects_sales_stage/`);
export const getProposalsTotals = (initialDate, finalDate, agentId = null) =>
    API.get(`/api/v1/${ENTITY}/proposals_totals/`, {
        params: {
            agent_id: agentId,
            final_date: finalDate,
            initial_date: initialDate,
        },
    });
