export const DOMESTIC_RATES = ['1', '1A', '1B', '1C', '1D', '1E', '1F', 'DAC'];
export const COMMERCIAL_LOW_TENSION_RATES = ['PDBT', 'RABT', 'APBT'];
export const HIGH_TENSION_RATES = [
    'GDMTO',
    'GDMTH',
    'GDBT',
    'RAMT',
    'APMT',
    'DIST',
    'DIT',
];
export const HOURLY_RATES = ['DIST', 'DIT', 'GDMTH'];
export const SPAIN_RATES = [
    '2.0 TD',
    '3.0 TD',
    '6.1 TD',
    '6.2 TD',
    '6.3 TD',
    '6.4 TD',
];
export const ALL_CERTIFIED_RATES = [
    ...COMMERCIAL_LOW_TENSION_RATES,
    ...DOMESTIC_RATES,
    ...HIGH_TENSION_RATES,
];
export const LOW_TENSION_RATES = [
    ...COMMERCIAL_LOW_TENSION_RATES,
    ...DOMESTIC_RATES,
];
export const RATES_WITH_DIVISION = [
    ...COMMERCIAL_LOW_TENSION_RATES,
    ...HIGH_TENSION_RATES,
];
export const RATES_WITH_POWER_FACTOR = [
    'DIST',
    'DIT',
    'GDBT',
    'GDMTH',
    'GDMTO',
    'RAMT',
];
export const RATES_FC = {
    APBT: 0.5,
    APMT: 0.5,
    DIST: 0.74,
    DIT: 0.71,
    GDBT: 0.49,
    GDMTH: 0.57,
    GDMTO: 0.55,
    PDBT: 0.58,
    RABT: 0.5,
    RAMT: 0.5,
};

export const TIER_TYPES = {
    DEMAND: 1,
    ENERGY: 0,
};

export const DEFAULT_SCHEDULE_RATE_CONFIGURATION = {
    tiers_energy_distribution: [
        {
            distribution_type: 0,
            saturday_distribution: new Array(288).fill(0),
            sunday_distribution: new Array(288).fill(0),
            tiers: [{ identifier: 0, name: 'Unique' }],
            weekday_distribution: new Array(288).fill(0),
        },
    ],
};

// COMPENSATION SCHEMES
export const COMPENSATION_SCHEMES = [
    {
        backendKey: 'NETMET',
        certifiedRates: ['GDMTO'],
        description:
            'In this system, excess energy generated during a billing period is not carried over as kilowatt-hours (kWh), but rather as a monetary credit based on the value of the kWh during the month when the excess was created',
        howItWorks: [
            'Energy consumers generate their own electricity using renewable energy systems and feed the surplus back into the grid',
            'At the end of the billing period, the net energy usage is calculated by subtracting the energy generated by the consumer from the energy consumed',
            'If there is excess net energy, it is converted into a monetary credit based on the kWh value during the month of generation. This credit is then saved in an economic pool',
            "The credits accumulated in the economic pool can be applied to future billing cycles, reducing the consumer's energy costs",
        ],
        label: 'Net metering with economic Pool',
        value: 'NETMET_ECONOMIC_POOL',
    },
    {
        backendKey: 'NETMET',
        certifiedRates: ['GDMTH'],
        description:
            'In this system, excess energy generated during specific hourly tiers is not carried over as kilowatt-hours (kWh), but rather as separate monetary credits based on the value of the kWh during the month when the excess was created in each corresponding tier. These independent credits per hourly tiers can be used in future billing cycles',
        howItWorks: [
            'Energy consumers generate their own electricity using renewable energy systems and feed the surplus back into the grid',
            'At the end of the billing period, the net energy usage is calculated on an hourly basis, creating separate tiers for peak, day, and morning hours',
            'If there is excess net energy in any hourly tier, it is converted into a monetary credit based on the kWh value during the month of generation in that specific tier. These credits are saved in an economic pool as independent credits per hourly tier',
            "In future billing cycles, the accumulated credits are applied to the consumer's energy costs, with the order of discounting starting from peak hours, then moving to day hours, and finally morning hours",
        ],
        label: 'Net metering with hourly economic Pool',
        value: 'NETMET_HOURLY_ECONOMIC_POOL',
    },
    {
        backendKey: 'NETMET',
        certifiedRates: [...LOW_TENSION_RATES, 'APMT', 'GDBT', 'RAMT'],
        customRates: ['Fixed', 'Range'],
        description:
            'In this system, if there is excess net energy at the end of a billing period, it is carried over to the next period as kilowatt-hours (kWh) and may continue to accumulate for up to 12 months',
        howItWorks: [
            'Energy consumers generate their own electricity using renewable energy systems and feed the surplus back into the grid',
            'At the end of the billing period, the net energy usage is calculated by subtracting the energy generated by the consumer from the energy consumed',
            'If there is excess net energy, it is carried over to the next billing period as kWh, creating a pool of unused energy',
            "The accumulated kWh in the pool can be used in future billing cycles, reducing the consumer's energy costs. This pool may continue to grow with each billing cycle if there is a surplus of generated energy",
            'The pool of unused energy expires after 12 months, at which point any remaining kWh are no longer available for use',
        ],
        label: 'Net metering with Pool',
        value: 'NETMET_POOL',
    },
    {
        backendKey: 'NETMETS',
        certifiedRates: ALL_CERTIFIED_RATES,
        customRates: ['Fixed', 'Range', 'Schedule'],
        description:
            'In this system, rather than carrying over excess energy as a credit for future billing cycles, the excess net energy is paid out to the consumer at a predefined surplus price at the end of the billing period',
        howItWorks: [
            'Energy consumers generate their own electricity using renewable energy systems and feed the surplus back into the grid',
            'At the end of the billing period, the net energy usage is calculated by subtracting the energy generated by the consumer from the energy consumed',
            'If there is excess net energy, instead of converting it into a credit, the consumer is paid for the surplus energy at a predetermined price defined by the user. This surplus price could be set by the consumer, utility company, or local regulations, depending on the specific net metering policy',
            'The consumer receives a payment for their excess energy generation, which can be used as they wish, without any restrictions tied to future energy consumption or billing cycles',
        ],
        label: 'Net metering with Surplus Sale',
        value: 'NETMET_SS',
    },
    {
        backendKey: 'SELFCON',
        certifiedRates: ALL_CERTIFIED_RATES,
        customRates: ['Fixed', 'Range', 'Schedule'],
        description:
            'In this system, energy consumption from the grid and exported energy to the grid are accounted for separately during the billing period. At the end of the billing period, any excess energy that was exported to the grid is paid to the consumer at a price set by the utility company or the regulator',
        howItWorks: [
            'Energy consumers generate their own electricity using renewable energy systems and use it primarily for self-consumption',
            "During the billing period, any surplus energy generated that exceeds the consumer's needs is exported back to the grid",
            "The energy provider separately tracks the consumer's energy consumption from the grid and the energy exported to the grid",
            'At the end of the billing period, the consumer is billed for the energy consumed from the grid at the standard rate',
            'The exported energy to the grid is paid to the consumer at a predetermined price defined by the user. This surplus price could be set by the consumer, utility company, or local regulations, depending on the specific policy',
            'The consumer may receive payment for their excess energy generation, which can be used as they wish, without any restrictions tied to future energy consumption or billing cycles.  Or may be applied directly to the Energy Bill',
        ],
        label: 'Self-consumption with Surplus Sale',
        value: 'SELFCON',
    },
    {
        backendKey: 'SELFVB',
        certifiedRates: ALL_CERTIFIED_RATES,
        customRates: ['Fixed', 'Range', 'Schedule'],
        description:
            'In this system, consumers use a "virtual battery" to symbolically store the economic value generated by excess solar energy. It\'s not a physical battery, but rather an accounting mechanism that keeps track of the energy generated and not immediately consumed. At the end of the billing period, the accumulated surplus can be applied to offset future energy consumption or billing cycles',
        howItWorks: [
            'Energy consumers generate their own electricity using renewable energy systems and use it primarily for self-consumption',
            'During the billing period, any energy that is generated and not immediately consumed is considered excess',
            'This surplus is "stored" virtually in what is called a virtual battery. The utility company or an accounting system keeps a record of these surpluses',
            'At the end of the billing period, instead of paying the consumer for the excess energy, this value is stored as credit in the virtual battery',
            'In future billing cycles, consumers can use the credits accumulated in their virtual battery to offset the cost of energy consumed from the grid',
            "The way these credits are handled and their validity may vary according to the utility company's policies and local regulations",
        ],
        label: 'Self-consumption with Virtual Battery',
        value: 'SELFVB',
    },
    {
        backendKey: 'SELFDNS',
        certifiedRates: ALL_CERTIFIED_RATES,
        customRates: ['Fixed', 'Range', 'Schedule'],
        description:
            'In this system, consumers use a netting mechanism to account for three types of energy concepts Self-Consumption, Netted Exported Generation, and Excess Exported Generation. Financial settlements are conducted at the end of the billing cycle, accounting for the various components of exported and consumed energy',
        howItWorks: [
            'Energy consumers generate their own electricity using renewable energy systems and use it primarily for self-consumption',
            'Self-consumption is the energy generated and consumed each day by the consumer',
            'Netted Exported Generation (Component 1) is the energy exported which is less than the total energy consumed for the period, and it is paid out at a predetermined price defined by the user',
            'Excess Exported Generation (Component 2) occurs if the consumer exports more energy than they consume from the grid during the period. This excess energy is paid out at a different predetermined price defined by the user',
            'All financial settlements are conducted at the end of the billing cycle, accounting for the various components of exported and consumed energy',
            'The predetermined prices for both components can be set by the consumer, utility company, or local regulations, depending on the specific policy',
        ],
        label: 'Self-consumption with Net Metering and Surplus Sale',
        value: 'SELFDNS',
    },
];

export const COMPENSATION_SCHEMES_WITH_EXPORT_PRICE_BY_BACKEND_KEY = [
    'NETMETS',
    'SELFCON',
    'SELFVB',
    'SELFDNS',
];
