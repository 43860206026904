import React from 'react';

import PropTypes from 'prop-types';

import SelectionAreaComponent from 'common/components/SelectionArea';

import { BATTERY_STATUS } from '../constants';

const HourlyDistribution = ({
    disabled,
    getValues,
    hourlyDistribution,
    setHourlyDistribution,
    setValue,
    weekDay,
}) => {
    const onStart = () =>
        setHourlyDistribution((prev) => ({
            ...prev,
            optionSelected: {
                ...(prev.optionSelected || {}),
                [weekDay]: getValues(`select.${weekDay}`),
            },
        }));

    const onMove = ({ added, removed }) => {
        if (!added?.length && !removed?.length) return;

        setHourlyDistribution((prev) => {
            if (
                !prev?.optionSelected?.[weekDay] &&
                prev?.optionSelected?.[weekDay] !== 0
            )
                return prev;
            let newValues = { ...prev };
            added.forEach(({ key }) => {
                const elementAdded = newValues[weekDay][key];
                const batteryStatus = newValues.optionSelected[weekDay];
                newValues[weekDay][key] = {
                    color: BATTERY_STATUS[batteryStatus]?.color,
                    oldState: elementAdded,
                    value: elementAdded?.value || 0,
                };
            });

            removed.forEach(({ key }) => {
                const elementRemoved = newValues[weekDay][key];
                newValues[weekDay][key] = {
                    value: elementRemoved?.oldState?.value || 1,
                    color: elementRemoved?.oldState?.color,
                };
            });

            return newValues;
        });
    };

    const onStop = () =>
        setHourlyDistribution((prev) => {
            setValue('battery_hourly_status', prev);
            return prev;
        });

    return (
        <SelectionAreaComponent
            columns={24}
            isSelectable={!disabled}
            onMove={onMove}
            onStart={onStart}
            onStop={onStop}
            selected={hourlyDistribution}
            selectionAreaClass="selection-area-blue"
        />
    );
};

HourlyDistribution.propTypes = {
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    hourlyDistribution: PropTypes.array,
    setHourlyDistribution: PropTypes.func,
    setValue: PropTypes.func,
    weekDay: PropTypes.string,
};

export default HourlyDistribution;
