import { NAME } from './constants';

export const DELETE = `${NAME}/DELETE`;
export const DELETE_FAILURE = `${NAME}/DELETE_FAILURE`;
export const DELETE_SUCCESS = `${NAME}/DELETE_SUCCESS`;

export const FETCH_INTEGRATION = `${NAME}/FETCH_INTEGRATION`;
export const FETCH_INTEGRATION_FAILURE = `${NAME}/FETCH_INTEGRATION_FAILURE`;
export const FETCH_INTEGRATION_SUCCESS = `${NAME}/FETCH_INTEGRATION_SUCCESS`;

export const FETCH_INTEGRATIONS = `${NAME}/FETCH_INTEGRATIONS`;
export const FETCH_INTEGRATIONS_FAILURE = `${NAME}/FETCH_INTEGRATIONS_FAILURE`;
export const FETCH_INTEGRATIONS_SUCCESS = `${NAME}/FETCH_INTEGRATIONS_SUCCESS`;

export const FETCH_CHARGEBEE_INTEGRATION = `${NAME}/FETCH_CHARGEBEE_INTEGRATION`;
export const FETCH_CHARGEBEE_INTEGRATION_FAILURE = `${NAME}/FETCH_CHARGEBEE_INTEGRATION_FAILURE`;
export const FETCH_CHARGEBEE_INTEGRATION_SUCCESS = `${NAME}/FETCH_CHARGEBEE_INTEGRATION_SUCCESS`;

export const RENAME_ITEM = `${NAME}/RENAME_ITEM`;
export const RENAME_ITEM_FAILURE = `${NAME}/RENAME_ITEM_FAILURE`;
export const RENAME_ITEM_SUCCESS = `${NAME}/RENAME_ITEM_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SET_SELECTED_INTEGRATION = `${NAME}/SET_SELECTED_INTEGRATION`;
