import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UiDialog as Dialog } from 'sunwise-ui';

const ModalTermsConditions = ({
    catalogRequirements,
    setShowModal,
    showModal,
}) => {
    const { t } = useTranslation();
    return (
        <Dialog
            onClose={() => setShowModal(false)}
            open={showModal}
            title={t('Terms and conditions')}
        >
            <>
                {catalogRequirements ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: catalogRequirements.content,
                        }}
                    />
                ) : null}
            </>
        </Dialog>
    );
};

ModalTermsConditions.propTypes = {
    catalogRequirements: PropTypes.object,
    setShowModal: PropTypes.func,
    showModal: PropTypes.bool,
};

export default ModalTermsConditions;
