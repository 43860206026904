import { push } from 'connected-react-router';

import { createContact, updateContact } from 'common/api/v1/contacts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_CONTACT,
    SAVE_CONTACT_FAILURE,
    SAVE_CONTACT_SUCCESS,
} from '../actionTypes';
import { buildPayload } from '../helpers';
import { contactFormActions } from '../reducer';

import resetForm from './resetForm';
import showModal from './showModal';

export default (values, handleAfterSave = null, redirectToRecord = true) =>
    (dispatch) => {
        const newValues = buildPayload(values);

        dispatch(contactFormActions[SAVE_CONTACT](null));

        const save = values.id
            ? () => updateContact({ ...values, ...newValues }, values.id)
            : () => createContact({ ...values, ...newValues });

        save()
            .then((response) => {
                dispatch(
                    contactFormActions[SAVE_CONTACT_SUCCESS](response.data),
                );
                dispatch(showModal(false));
                dispatch(resetForm());

                if (handleAfterSave !== null) handleAfterSave();

                showToast();

                if (redirectToRecord && !values.id)
                    dispatch(push(`/record/${response.data.id}?tab=projects`));
            })
            .catch((error) => {
                dispatch(
                    contactFormActions[SAVE_CONTACT_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
