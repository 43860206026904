import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import * as selectors from '../selectors';

import clonePosition from './clonePosition';

export default () => (dispatch, getState) => {
    const state = getState();
    const project = selectors.getSelectedProject(state);
    const message = i18next.t(
        "Are you sure to copy the contact's location to this project?",
    );
    const copy = i18next.t('Copy');

    const dataAlert = {
        confirmText: copy,
        messages: [message],
        onSuccess: () => dispatch(clonePosition(project.id)),
        title: copy,
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'info',
    };

    dispatch(alerts.actions.show(dataAlert));
};
