import { object, string, ref } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';
import { emailRegex } from 'common/utils/helpers';

export default () => {
    const {
        REQUIRED_TEXT,
        EMAIL_VALIDATION_TEXT,
        EMAILS_DONT_MATCH,
        getBetweenValueText,
        getNumberDigitsText,
    } = getValidationTexts();

    return object().shape({
        first_name: string().required(REQUIRED_TEXT).nullable(),
        last_name: string().required(REQUIRED_TEXT).nullable(),
        business_name: string().required(REQUIRED_TEXT).nullable(),
        email: string()
            .required(REQUIRED_TEXT)
            .matches(emailRegex, EMAIL_VALIDATION_TEXT)
            .nullable(),
        confirm_email: string()
            .required(REQUIRED_TEXT)
            .matches(emailRegex, EMAIL_VALIDATION_TEXT)
            .oneOf([ref('email'), null], EMAILS_DONT_MATCH)
            .nullable(),
        country: string().required(REQUIRED_TEXT).nullable(),
        dayOfBirth: string()
            .required(REQUIRED_TEXT)
            .test('len', getNumberDigitsText(2), (value) => {
                if (value) {
                    return value.toString().length === 2;
                }
            })
            .test('interval', getBetweenValueText(1, 31), (value) => {
                if (value) {
                    return parseInt(value) >= 1 && parseInt(value) <= 31;
                }
            })
            .nullable(),
        monthOfBirth: string()
            .required(REQUIRED_TEXT)
            .test('len', getNumberDigitsText(2), (value) => {
                if (value) {
                    return value.toString().length === 2;
                }
            })
            .test('interval', getBetweenValueText(1, 12), (value) => {
                if (value) {
                    return parseInt(value) >= 1 && parseInt(value) <= 12;
                }
            })
            .nullable(),
        yearOfBirth: string()
            .required(REQUIRED_TEXT)
            .test('len', getNumberDigitsText(4), (value) => {
                if (value) {
                    return value.toString().length === 4;
                }
            })
            .test(
                'interval',
                getBetweenValueText(
                    new Date().getFullYear() - 120,
                    new Date().getFullYear() - 18,
                ),
                (value) => {
                    if (value) {
                        return (
                            parseInt(value) >= new Date().getFullYear() - 120 &&
                            parseInt(value) <= new Date().getFullYear() - 18
                        );
                    }
                },
            )
            .nullable(),
    });
};
