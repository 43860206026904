import React, { useEffect, useState } from 'react';

import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Typography } from 'sunwise-ui';

import * as contactActions from '../../contact/actions';
import * as contactSelectors from '../../contact/selectors';
import * as actions from '../actions';
import { getSelectedPowerStation } from '../selectors';
import * as selectors from '../selectors';

import ContactsSection from './ContactsSection';
import ProjectsSection from './ProjectsSection';

const steps = [
    { label: 'Contact', value: 'contact' },
    { label: 'Project', value: 'project' },
];

const TitleValue = ({ title, value }) => (
    <Box
        alignItems="center"
        display="flex"
        flexWrap="wrap"
        gap={1}
        justifyContent="center"
        textAlign="center"
    >
        <Typography variant="subtitle2">{title}:</Typography>
        <Typography variant="body2">{value || '--'}</Typography>
    </Box>
);

TitleValue.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
};

const AssignProjectModal = ({
    agents,
    assignProject,
    contacts,
    contactsPaginationData,
    credentialId,
    fetchAgents,
    fetchProjects,
    filterContacts,
    isFetchingContacts,
    isFetchingProjects,
    isSaving,
    onClose,
    powerStation,
    projects,
}) => {
    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [contactFilterData, setContactFilterData] = useState({
        agentId: '',
        orderBy: 'created_at',
        page: 0,
        pageSize: 15,
        searchText: '',
        sortBy: 'desc',
        status: 'active',
    });

    const { t } = useTranslation();

    useEffect(() => {
        fetchAgents();
        filterContacts(contactFilterData);
    }, []);

    const handleNext = () => {
        setCurrentStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setCurrentStep((prevActiveStep) => prevActiveStep - 1);
        setSelectedProject(null);
    };

    const handleOnSave = () => {
        assignProject({
            credentialId,
            powerStation,
            sunwiseProject: selectedProject.id,
        });
    };

    return (
        <>
            <Stepper activeStep={currentStep} alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={step.value}>
                        <StepButton
                            disabled={isSaving}
                            color="inherit"
                            onClick={() => {
                                if (index > currentStep) return;
                                setCurrentStep(index);
                                setSelectedProject(null);
                            }}
                        >
                            <StepLabel>{t(step.label, { count: 2 })}</StepLabel>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>

            <Box
                display="flex"
                flexWrap="wrap"
                gap={3}
                justifyContent="center"
                mt={2}
            >
                <TitleValue
                    title={t('Power station')}
                    value={powerStation?.data?.id}
                />

                <TitleValue
                    title={t('Contact')}
                    value={selectedContact?.name}
                />

                <TitleValue
                    title={t('Project')}
                    value={selectedProject?.name}
                />
            </Box>

            {currentStep === 0 && (
                <ContactsSection
                    agents={agents}
                    contacts={contacts}
                    disabled={isSaving}
                    filterData={contactFilterData}
                    filterItems={filterContacts}
                    isFetching={isFetchingContacts}
                    onCancelSelection={onClose}
                    onConfirmSelection={handleNext}
                    paginationData={contactsPaginationData}
                    selectedContact={selectedContact}
                    setFilterData={setContactFilterData}
                    setSelectedContact={setSelectedContact}
                />
            )}

            {currentStep === 1 && (
                <ProjectsSection
                    contactId={selectedContact?.id}
                    disabled={isSaving}
                    fetchProjects={fetchProjects}
                    isFetching={isFetchingProjects}
                    onCancelSelection={handleBack}
                    onSave={handleOnSave}
                    projects={projects}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                />
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    agents: contactSelectors.getAgentsData,
    contacts: contactSelectors.getContactsData,
    contactsPaginationData: contactSelectors.getContactsPagination,
    isFetchingContacts: contactSelectors.getIsFetchingContacts,
    isFetchingProjects: selectors.getIsFetchingAfterSalesProjects,
    isSaving: selectors.getIsSavingToggleAssignProject,
    powerStation: getSelectedPowerStation,
    projects: selectors.getAfterSalesProjects,
});

const mapDispatchToProps = (dispatch) => ({
    assignProject: (values) => dispatch(actions.assignProject(values)),
    fetchAgents: () => dispatch(contactActions.fetchAgents()),
    fetchProjects: (contactId) =>
        dispatch(actions.fetchAfterSalesProjects(contactId)),
    filterContacts: (filterData) =>
        dispatch(contactActions.filterContacts(filterData)),
});

AssignProjectModal.propTypes = {
    agents: PropTypes.array,
    assignProject: PropTypes.func,
    contacts: PropTypes.array,
    contactsPaginationData: PropTypes.object,
    credentialId: PropTypes.string,
    fetchAgents: PropTypes.func,
    fetchProjects: PropTypes.func,
    filterContacts: PropTypes.func,
    isFetchingContacts: PropTypes.bool,
    isFetchingProjects: PropTypes.bool,
    isSaving: PropTypes.bool,
    onClose: PropTypes.func,
    powerStation: PropTypes.object,
    projects: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignProjectModal);
