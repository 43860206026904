import i18next from 'i18next';
import get from 'lodash/get';

import { deleteItem } from 'common/api/v1/proposalsSummary';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_SUMMARY,
    DELETE_SUMMARY_FAILURE,
    DELETE_SUMMARY_SUCCESS,
} from '../actionTypes';
import { proposalSummaryActions } from '../reducer';

import fetchSummaries from './fetchSummaries';

export default (values, isArchivedFilter) => (dispatch) => {
    const id = get(values, 'id', null);
    const projectId = get(values.project, 'id', null);

    dispatch(proposalSummaryActions[DELETE_SUMMARY]());

    deleteItem(id)
        .then(() => {
            dispatch(proposalSummaryActions[DELETE_SUMMARY_SUCCESS]());
            dispatch(alerts.actions.close());
            dispatch(fetchSummaries(projectId, isArchivedFilter));

            showToast({
                body: i18next.t('It was successfully removed'),
            });
        })
        .catch((error) => {
            dispatch(
                proposalSummaryActions[DELETE_SUMMARY_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
