import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';

import initializeForm from './initializeForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModalProduct from './toggleModalProduct';

const getBrachOffice = (branchSelected) => {
    if (['company', 'all'].includes(branchSelected)) {
        return '';
    }
    return branchSelected;
};

export default (companyId) => (dispatch, getState) => {
    const branchSelected = multiBranchesSelectors.getBranchSelected(getState());
    const values = {
        branch_office: getBrachOffice(branchSelected),
        branch_offices: getBrachOffice(branchSelected),
        company: companyId,
    };
    dispatch(setIsNewRecord(true));
    dispatch(initializeForm(values));
    dispatch(toggleModalProduct(true));
};
