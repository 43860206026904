import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SingleAutocomplete from 'common/components/SingleAutocomplete';
import { getAgentOptions } from 'common/utils/helpers';

import * as dashboardActions from '../../dashboard/actions';
import * as dashboardSelectors from '../../dashboard/selectors';
import * as projectSelectors from '../../project/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';

const FilterDropdown = ({
    agents,
    fetchAgents,
    fetchContacts,
    fetchRates,
    filterItemSelected,
    isLoadingAgents,
    isLoadingProjects,
    isLoadingRates,
    isLoadingStatusProject,
    rates,
    selectFilterItem,
    statusProjectsToSelect,
}) => {
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState({ value: '' });

    useEffect(() => {
        setSelectedValue({ value: '' });
        switch (filterItemSelected.type) {
            case 'agent':
                fetchAgents();
                break;
            case 'contact':
                fetchContacts(1);
                break;
            case 'rate':
                fetchRates();
                break;
            default:
                break;
        }
    }, [filterItemSelected.type]);

    switch (filterItemSelected.type) {
        case 'agent':
            return (
                <SingleAutocomplete
                    disableClearable
                    fullWidth
                    id="single-autocomplete-agent"
                    isDisabled={isLoadingProjects || isLoadingRates}
                    label={t('User', { count: 2 })}
                    options={getAgentOptions(agents)}
                    onChange={(e) => {
                        setSelectedValue(e);
                        selectFilterItem({
                            ...filterItemSelected,
                            value: e.value,
                        });
                    }}
                    value={selectedValue}
                    variant="standard"
                />
            );
        case 'rate':
            return (
                <SingleAutocomplete
                    disableClearable
                    fullWidth
                    id="single-autocomplete-rate"
                    isDisabled={isLoadingProjects || isLoadingAgents}
                    label={t('Rate', { count: 2 })}
                    options={rates}
                    onChange={(e) => {
                        setSelectedValue(e);
                        selectFilterItem({
                            ...filterItemSelected,
                            value: e.value,
                        });
                    }}
                    value={selectedValue}
                    variant="standard"
                />
            );
        case 'status':
            return (
                <SingleAutocomplete
                    disableClearable
                    fullWidth
                    id="single-autocomplete-status"
                    isDisabled={isLoadingProjects || isLoadingStatusProject}
                    label={t('Status')}
                    options={statusProjectsToSelect}
                    onChange={(e) => {
                        setSelectedValue(e);
                        selectFilterItem({
                            ...filterItemSelected,
                            value: e.value,
                        });
                    }}
                    value={selectedValue}
                    variant="standard"
                />
            );
        default:
            return null;
    }
};

const mapStateToProps = createStructuredSelector({
    agents: selectors.getAgentsData,
    hasNextPage: selectors.getHasNextPage,
    isLoadingAgents: selectors.getIsFetchingAgents,
    isLoadingRates: dashboardSelectors.getIsLoadingRates,
    isLoadingStatusProject: projectSelectors.getIsLoadingStatusProject,
    rates: dashboardSelectors.getRatesForSelectFromFunnel,
    statusProjectsToSelect:
        projectSelectors.getStatusProjectToSelectForFilterView,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAgents: () => dispatch(actions.fetchAgents()),
    fetchRates: () => dispatch(dashboardActions.fetchRates()),
});

FilterDropdown.propTypes = {
    agents: PropTypes.array,
    fetchContacts: PropTypes.func,
    fetchRates: PropTypes.func,
    filterItemSelected: PropTypes.object,
    fetchAgents: PropTypes.func,
    isLoadingAgents: PropTypes.bool,
    isLoadingProjects: PropTypes.bool,
    isLoadingRates: PropTypes.bool,
    isLoadingStatusProject: PropTypes.bool,
    rates: PropTypes.array,
    selectFilterItem: PropTypes.func,
    statusProjectsToSelect: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDropdown);
