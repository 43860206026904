import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { TextField } from 'sunwise-ui';

const TextResponse = ({
    defaultTextResponse,
    onSetTextResponse,
    textResponse,
}) => {
    useEffect(() => {
        onSetTextResponse(defaultTextResponse);

        return () => {
            onSetTextResponse('');
        };
    }, []);

    return (
        <TextField
            fullWidth
            onChange={(event) => onSetTextResponse(event.target.value)}
            value={textResponse}
        />
    );
};

TextResponse.propTypes = {
    defaultTextResponse: PropTypes.string,
    onSetTextResponse: PropTypes.func,
    textResponse: PropTypes.string,
};

export default TextResponse;
