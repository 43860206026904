import { createSlice } from '@reduxjs/toolkit';

import {
    DOWNLOAD_REPORT_CSV,
    DOWNLOAD_REPORT_CSV_FAILURE,
    DOWNLOAD_REPORT_CSV_SUCCESS,
    DOWNLOAD_REPORT_PDF,
    DOWNLOAD_REPORT_PDF_FAILURE,
    DOWNLOAD_REPORT_PDF_SUCCESS,
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_SUCCESS,
    RESET,
    SET_SELECTED_DOCUMENT,
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_FAILURE,
    UPDATE_TEMPLATE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    downloadReportCsv: { errors: [], file: null, isFetching: false },
    downloadReportPdf: { errors: [], file: null, isFetching: false },
    fetchCustomTemplate: { data: null, errors: [], isFetching: false },
    initializing: false,
    updateCustomTemplate: { errors: [], isUpdating: false },
};

const reportTemplateSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DOWNLOAD_REPORT_CSV]: (state) => {
            state.downloadReportCsv.errors = [];
            state.downloadReportCsv.file = null;
            state.downloadReportCsv.isFetching = true;
        },
        [DOWNLOAD_REPORT_CSV_FAILURE]: (state, action) => {
            state.downloadReportCsv.errors = action.payload;
            state.downloadReportCsv.isFetching = false;
        },
        [DOWNLOAD_REPORT_CSV_SUCCESS]: (state, action) => {
            state.downloadReportCsv.file = action.payload;
            state.downloadReportCsv.isFetching = false;
        },
        [DOWNLOAD_REPORT_PDF]: (state) => {
            state.downloadReportPdf.errors = [];
            state.downloadReportPdf.file = null;
            state.downloadReportPdf.isFetching = true;
        },
        [DOWNLOAD_REPORT_PDF_FAILURE]: (state, action) => {
            state.downloadReportPdf.errors = action.payload;
            state.downloadReportPdf.isFetching = false;
        },
        [DOWNLOAD_REPORT_PDF_SUCCESS]: (state, action) => {
            state.downloadReportPdf.file = action.payload;
            state.downloadReportPdf.isFetching = false;
        },
        [FETCH_CUSTOM_TEMPLATE]: (state) => {
            state.fetchCustomTemplate.data = null;
            state.fetchCustomTemplate.errors = [];
            state.fetchCustomTemplate.isFetching = true;
        },
        [FETCH_CUSTOM_TEMPLATE_FAILURE]: (state, action) => {
            state.fetchCustomTemplate.errors = action.payload;
            state.fetchCustomTemplate.isFetching = false;
        },
        [FETCH_CUSTOM_TEMPLATE_SUCCESS]: (state, action) => {
            state.fetchCustomTemplate.data = action.payload;
            state.fetchCustomTemplate.isFetching = false;
        },
        [INITIALIZE]: (state) => {
            state.initializing = true;
        },
        [INITIALIZE_FAILURE]: (state) => {
            state.initializing = false;
        },
        [INITIALIZE_SUCCESS]: (state) => {
            state.initializing = false;
        },
        [UPDATE_TEMPLATE]: (state) => {
            state.updateCustomTemplate.errors = [];
            state.updateCustomTemplate.isUpdating = true;
        },
        [UPDATE_TEMPLATE_FAILURE]: (state, action) => {
            state.updateCustomTemplate.errors = action.payload;
            state.updateCustomTemplate.isUpdating = false;
        },
        [UPDATE_TEMPLATE_SUCCESS]: (state) => {
            state.updateCustomTemplate.isUpdating = false;
        },
        [RESET]: () => INITIAL_STATE,
        [SET_SELECTED_DOCUMENT]: (state, action) => {
            state.selectedDocument = action.payload;
        },
    },
});

export const reportTemplateActions = reportTemplateSlice.actions;

export default reportTemplateSlice.reducer;
