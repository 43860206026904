import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_COMPATIBILITY_DATA,
    FETCH_COMPATIBILITY_DATA_FAILURE,
    FETCH_COMPATIBILITY_DATA_SUCCESS,
    FETCH_DATASHEET_INVERTER,
    FETCH_DATASHEET_INVERTER_FAILURE,
    FETCH_DATASHEET_INVERTER_SUCCESS,
    FETCH_DATASHEET_PANEL,
    FETCH_DATASHEET_PANEL_FAILURE,
    FETCH_DATASHEET_PANEL_SUCCESS,
    FETCH_INVERTERS,
    FETCH_INVERTERS_FAILURE,
    FETCH_INVERTERS_SUCCESS,
    FETCH_PANELS,
    FETCH_PANELS_FAILURE,
    FETCH_PANELS_SUCCESS,
    FETCH_POLITICAL_DIVISIONS,
    FETCH_POLITICAL_DIVISIONS_FAILURE,
    FETCH_POLITICAL_DIVISIONS_SUCCESS,
    FETCH_POLITICAL_DIVISIONS_BY_PARENT,
    FETCH_POLITICAL_DIVISIONS_BY_PARENT_FAILURE,
    FETCH_POLITICAL_DIVISIONS_BY_PARENT_SUCCESS,
    INITIALIZE_FORM,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchCompatibility: {
        isFetching: false,
        data: null,
        errors: null,
    },
    fetchInverters: {
        isFetching: false,
        data: [],
        errors: null,
    },
    fetchDatasheetInverter: {
        isFetching: false,
        data: null,
        errors: null,
    },
    fetchDatasheetPanel: {
        isFetching: false,
        data: null,
        errors: null,
    },
    fetchPanels: {
        isFetching: false,
        data: [],
        errors: null,
    },
    initialValues: {
        oversize: 0,
        political_division1: null,
        political_division2: null,
        product_inverter: null,
        product_panel: null,
        temp: [25, 75],
        apply_settings: false,
    },
    politicalDivisions: {
        isLoading: false,
        data: [],
        errors: null,
    },
    politicalDivisionsByParent: {
        isLoading: false,
        data: [],
        errors: null,
    },
    saveItem: {
        isSaving: false,
        data: {},
        errors: null,
    },
};

const equipmentCompatibilitySlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_COMPATIBILITY_DATA]: (state) => {
            state.fetchCompatibility = {
                ...state.fetchCompatibility,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_COMPATIBILITY_DATA_FAILURE]: (state, action) => {
            state.fetchCompatibility = {
                ...state.fetchCompatibility,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMPATIBILITY_DATA_SUCCESS]: (state, action) => {
            state.fetchCompatibility = {
                ...state.fetchCompatibility,
                data: action.payload,
                isFetching: false,
                isSaving: false,
            };
        },
        [FETCH_DATASHEET_INVERTER]: (state) => {
            state.fetchDatasheetInverter = {
                ...state.fetchDatasheetInverter,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_DATASHEET_INVERTER_FAILURE]: (state, action) => {
            state.fetchDatasheetInverter = {
                ...state.fetchDatasheetInverter,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DATASHEET_INVERTER_SUCCESS]: (state, action) => {
            state.fetchDatasheetInverter = {
                ...state.fetchDatasheetInverter,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DATASHEET_PANEL]: (state) => {
            state.fetchDatasheetPanel = {
                ...state.fetchDatasheetPanel,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_DATASHEET_PANEL_FAILURE]: (state, action) => {
            state.fetchDatasheetPanel = {
                ...state.fetchDatasheetPanel,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DATASHEET_PANEL_SUCCESS]: (state, action) => {
            state.fetchDatasheetPanel = {
                ...state.fetchDatasheetPanel,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTERS]: (state) => {
            state.fetchInverters = {
                ...state.fetchInverters,
                data: [],
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_INVERTERS_FAILURE]: (state, action) => {
            state.fetchInverters = {
                ...state.fetchInverters,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTERS_SUCCESS]: (state, action) => {
            state.fetchInverters = {
                ...state.fetchInverters,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANELS]: (state) => {
            state.fetchPanels = {
                ...state.fetchPanels,
                data: [],
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PANELS_FAILURE]: (state, action) => {
            state.fetchPanels = {
                ...state.fetchPanels,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANELS_SUCCESS]: (state, action) => {
            state.fetchPanels = {
                ...state.fetchPanels,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS]: (state) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                errors: [],
                isLoading: true,
            };
        },
        [FETCH_POLITICAL_DIVISIONS_FAILURE]: (state, action) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS_SUCCESS]: (state, action) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS_BY_PARENT]: (state) => {
            state.politicalDivisionsByParent = {
                ...state.politicalDivisionsByParent,
                errors: [],
                isLoading: true,
            };
        },
        [FETCH_POLITICAL_DIVISIONS_BY_PARENT_FAILURE]: (state, action) => {
            state.politicalDivisionsByParent = {
                ...state.politicalDivisionsByParent,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS_BY_PARENT_SUCCESS]: (state, action) => {
            state.politicalDivisionsByParent = {
                ...state.politicalDivisionsByParent,
                data: action.payload,
                isLoading: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                data: action.payload,
                isSaving: false,
            };
        },
    },
});

export const equipmentCompatibilityActions =
    equipmentCompatibilitySlice.actions;

export default equipmentCompatibilitySlice.reducer;
