import { NAME } from './constants';

export const DELETE_CREDENTIAL = `${NAME}/DELETE_CREDENTIAL`;
export const DELETE_CREDENTIAL_FAILURE = `${NAME}/DELETE_CREDENTIAL_FAILURE`;
export const DELETE_CREDENTIAL_SUCCESS = `${NAME}/DELETE_CREDENTIAL_SUCCESS`;

export const FETCH_CREDENTIALS = `${NAME}/FETCH_CREDENTIALS`;
export const FETCH_CREDENTIALS_FAILURE = `${NAME}/FETCH_CREDENTIALS_FAILURE`;
export const FETCH_CREDENTIALS_SUCCESS = `${NAME}/FETCH_CREDENTIALS_SUCCESS`;

export const FETCH_PROVIDERS = `${NAME}/FETCH_PROVIDERS`;
export const FETCH_PROVIDERS_FAILURE = `${NAME}/FETCH_PROVIDERS_FAILURE`;
export const FETCH_PROVIDERS_SUCCESS = `${NAME}/FETCH_PROVIDERS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_CREDENTIAL = `${NAME}/SAVE_CREDENTIAL`;
export const SAVE_CREDENTIAL_FAILURE = `${NAME}/SAVE_CREDENTIAL_FAILURE`;
export const SAVE_CREDENTIAL_SUCCESS = `${NAME}/SAVE_CREDENTIAL_SUCCESS`;

export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;
