import { push } from 'connected-react-router';

import { getContact } from 'common/api/v1/contacts';
import { CONTACT_BRANCH_ACCESS_DENIED } from 'common/constants';
import showToast from 'common/utils/showToast';

import {
    FETCH_CONTACT,
    FETCH_CONTACT_FAILURE,
    FETCH_CONTACT_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import prepareFormContact from './prepareFormContact';

export default (uid, displayLoading = true, initForm = false) =>
    (dispatch) => {
        if (displayLoading) {
            dispatch(actions[FETCH_CONTACT]());
        }

        getContact(uid)
            .then((response) => {
                dispatch(actions[FETCH_CONTACT_SUCCESS](response.data));
                if (initForm) dispatch(prepareFormContact());
            })
            .catch((error) => {
                showToast(CONTACT_BRANCH_ACCESS_DENIED);
                dispatch(push('/contacts'));
                dispatch(actions[FETCH_CONTACT_FAILURE](error));
            });
    };
