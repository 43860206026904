import React, { useEffect, useState } from 'react';

import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Typography } from 'sunwise-ui';

import yupResolver from 'common/utils/yupResolver';

import * as credentialsActions from '../../../credentialIntegrations/actions';
import * as credentialsSelectors from '../../../credentialIntegrations/selectors';
import * as powerStationsActions from '../../../powerStations/actions';
import * as powerStationsSelectors from '../../../powerStations/selectors';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import validateApiSource from '../../validateApiSource';

import CredentialsList from './CredentialsList';
import PowerStationsList from './PowerStationsList';
import ReviewApiInfo from './ReviewApiInfo';

const steps = [
    { label: 'Credential', value: 'credential' },
    { label: 'Power station', value: 'power_station' },
];

const TitleValue = ({ title, value }) => (
    <Box
        alignItems="center"
        display="flex"
        flexWrap="wrap"
        gap={1}
        justifyContent="center"
        textAlign="center"
    >
        <Typography variant="subtitle2">{title}:</Typography>
        <Typography variant="body2">{value || '--'}</Typography>
    </Box>
);

TitleValue.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
};

const ApiSourceForm = ({
    assignPowerStation,
    credentials,
    fetchCredentials,
    fetchPowerStationsByCredential,
    fetchProjectPowerStations,
    handleOnClose,
    initialValues,
    isFetchingCredentials,
    isFetchingPowerStations,
    isSaving,
    powerStationPaginationData,
    powerStations,
    powerStationsColumns,
    providersDictionary,
    resetPowerStations,
    sunwiseProjectId,
}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateApiSource),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnSave = (values) =>
        assignPowerStation({
            successCallback: () => {
                handleOnClose();
                fetchProjectPowerStations(sunwiseProjectId);
            },
            sunwiseProjectId,
            values,
        });

    const handleCancelSelection = () =>
        setCurrentStep((prevStep) => prevStep - 1);

    const handleContinue = () => setCurrentStep((prevStep) => prevStep + 1);

    const disabled =
        isSaving || isFetchingCredentials || isFetchingPowerStations;
    return (
        <form onSubmit={handleSubmit(handleOnSave)}>
            <Stepper activeStep={currentStep} alternativeLabel sx={{ mb: 2 }}>
                {steps.map((step, index) => (
                    <Step key={step.value}>
                        <StepButton
                            color="inherit"
                            disabled={disabled}
                            onClick={() => setCurrentStep(index)}
                        >
                            <StepLabel>{t(step.label, { count: 2 })}</StepLabel>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>

            {currentStep == 0 && (
                <CredentialsList
                    control={control}
                    credentials={credentials}
                    disabled={disabled}
                    fetchCredentials={fetchCredentials}
                    isFetching={isFetchingCredentials}
                    onCancelSelection={handleOnClose}
                    onContinue={handleContinue}
                    providersDictionary={providersDictionary}
                    setValue={setValue}
                />
            )}

            {currentStep == 1 && (
                <PowerStationsList
                    control={control}
                    disabled={disabled}
                    fetchPowerStations={fetchPowerStationsByCredential}
                    isFetching={isFetchingPowerStations}
                    onCancelSelection={handleCancelSelection}
                    onContinue={handleContinue}
                    paginationData={powerStationPaginationData}
                    powerStations={powerStations}
                    powerStationsColumns={powerStationsColumns}
                    resetPowerStations={resetPowerStations}
                    setValue={setValue}
                />
            )}

            {currentStep == 2 && (
                <ReviewApiInfo
                    control={control}
                    disabled={disabled}
                    onCancel={handleCancelSelection}
                    providersDictionary={providersDictionary}
                />
            )}
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    credentials: credentialsSelectors.getCredentialsData,
    defaultLocation: selectors.getDefaultLocationData,
    initialValues: selectors.getInitialValuesApiSource,
    isFetchingCredentials: credentialsSelectors.getIsFetchingCredentials,
    isFetchingPowerStations: powerStationsSelectors.getPowerStationsIsFetching,
    isSaving: selectors.getIsSavingData,
    powerStationPaginationData:
        powerStationsSelectors.getPowerStationsPagination,
    powerStations: powerStationsSelectors.getPowerStationsData,
    powerStationsColumns: powerStationsSelectors.getPowerStationsColumns,
    providersDictionary: credentialsSelectors.getProvidersDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    assignPowerStation: (params) =>
        dispatch(actions.assignPowerStation(params)),
    fetchCredentials: () => dispatch(credentialsActions.fetchCredentials()),
    fetchPowerStationsByCredential: (accessKeyId, filter) =>
        dispatch(powerStationsActions.fetchPowerStations(accessKeyId, filter)),
    fetchProjectPowerStations: (sunwiseProjectId) =>
        dispatch(actions.fetchPowerStations(sunwiseProjectId)),
    resetPowerStations: () => dispatch(powerStationsActions.reset()),
});

ApiSourceForm.propTypes = {
    assignPowerStation: PropTypes.func,
    credentials: PropTypes.array,
    fetchCredentials: PropTypes.func,
    fetchPowerStationsByCredential: PropTypes.func,
    fetchProjectPowerStations: PropTypes.func,
    handleOnClose: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingCredentials: PropTypes.bool,
    isFetchingPowerStations: PropTypes.bool,
    isSaving: PropTypes.bool,
    powerStationPaginationData: PropTypes.object,
    powerStations: PropTypes.array,
    powerStationsColumns: PropTypes.array,
    providersDictionary: PropTypes.object,
    resetPowerStations: PropTypes.func,
    sunwiseProjectId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiSourceForm);
