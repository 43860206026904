import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

import BranchOfficesDropdown from 'common/modules/multiBranches/components/BranchOfficesDropdown';
import { COMPANY_BRANCH_NAME } from 'common/modules/multiBranches/constants';
import {
    getIsMultibranchesAccount,
    getSessionLocalStorage,
} from 'common/utils/helpers/session';

const BranchOfficeFormatter = ({ branchOfficesDictionary = [], row }) => {
    const isMultiBranches = getIsMultibranchesAccount();
    const session = getSessionLocalStorage();
    const mainBranchOffice = get(session, 'main_branch_office', {});
    const { branch_office_ids: branch_offices = [], id, is_global } = row;
    if (is_global)
        return <Typography variant="body2">{COMPANY_BRANCH_NAME}</Typography>;
    if (isMultiBranches) {
        if (branch_offices.length === 1) {
            const branch = branchOfficesDictionary?.filter(
                (branch) => branch.id === branch_offices[0],
            );
            return <Typography variant="body2">{branch[0]?.name}</Typography>;
        }
        if (branch_offices.length > 0) {
            return (
                <BranchOfficesDropdown
                    branchOffices={branchOfficesDictionary.filter((branch) =>
                        branch_offices.includes(branch.id),
                    )}
                    keyName={`branch-office-name-${id}`}
                />
            );
        }
    }
    if (branch_offices[0] === mainBranchOffice?.id)
        return (
            <Typography variant="body2">{mainBranchOffice?.name}</Typography>
        );
    return '---';
};

BranchOfficeFormatter.propTypes = {
    branchOfficesDictionary: PropTypes.array,
    row: PropTypes.object,
};

export default BranchOfficeFormatter;
