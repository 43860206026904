import { object, boolean } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        allow_staff_support: boolean().required(REQUIRED_TEXT).nullable(),
    });
};
