import React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemText,
    Tab,
    Tabs,
    Typography,
} from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import * as notificationActions from '../../../modules/notifications/actions';
import NavNotificationsList from '../../../modules/notifications/components/NavNotificationsList';
import notificationsSettings from '../../../modules/notificationsSettings';
import * as notificationsSettingsActions from '../../../modules/notificationsSettings/actions';

const StyledButtonText = styled(ListItemText)`
    & .MuiListItemText-primary {
        font-weight: 500;
        text-align: center;
    }
`;

const DrawerHeader = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const drawerWidth = 621;

const NotificationsDrawer = ({
    fetchNavNotifications,
    handleClickOpenModal,
    handleToggleDrawer,
    isOpenDrawer,
    markAllNotificationsAsRead,
    notificationTab,
    redirect,
    setIsOpenDrawer,
    setNotificationTab,
}) => {
    const { t } = useTranslation();
    const handleChange = (_, newValue) => {
        setNotificationTab(newValue);
        fetchNavNotifications(newValue === 'mentions');
    };
    return (
        <React.Fragment>
            <Drawer
                anchor="right"
                open={isOpenDrawer}
                onClose={handleToggleDrawer(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: { xs: '100%', md: drawerWidth },
                    },
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={handleToggleDrawer(false)}>
                        <ChevronRightIcon />
                    </IconButton>
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        justifyContent="center"
                        sx={{ width: '85%' }}
                    >
                        <NotificationsIcon fontSize="small" />
                        <Typography fontWeight="bold" variant="body1">
                            {t('Your notifications')}
                        </Typography>
                    </Box>
                </DrawerHeader>
                <Divider sx={{ mx: '1rem' }} />
                <Box sx={{ alignItems: 'center', display: 'flex', mx: '1rem' }}>
                    <Tabs value={notificationTab} onChange={handleChange}>
                        <Tab label={t('System')} value="system" />
                        <Tab label={t('Mentions')} value="mentions" />
                    </Tabs>
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: () => markAllNotificationsAsRead(),
                                label: t('Mark all as read'),
                            },
                            {
                                handleClick: () => {
                                    setIsOpenDrawer(false);
                                    handleClickOpenModal();
                                },
                                label: t('Notification settings'),
                            },
                        ]}
                        sxIconButton={{ ml: 'auto' }}
                    />
                </Box>
                <NavNotificationsList setIsOpenDrawer={setIsOpenDrawer} />
                <ListItem disablePadding sx={{ mt: 'auto' }}>
                    <ListItemButton
                        onClick={() => {
                            setIsOpenDrawer(false);
                            redirect('/notifications');
                        }}
                        sx={{ padding: '1rem' }}
                    >
                        <StyledButtonText
                            primary={t('See all notifications')}
                            sx={{ color: 'primary.main' }}
                        />
                    </ListItemButton>
                </ListItem>
            </Drawer>
            <notificationsSettings.Container />
        </React.Fragment>
    );
};

NotificationsDrawer.propTypes = {
    fetchNavNotifications: PropTypes.func,
    handleClickOpenModal: PropTypes.func,
    handleToggleDrawer: PropTypes.func,
    isOpenDrawer: PropTypes.bool,
    markAllNotificationsAsRead: PropTypes.func,
    notificationTab: PropTypes.string,
    redirect: PropTypes.func,
    setIsOpenDrawer: PropTypes.func,
    setNotificationTab: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    fetchNavNotifications: (showMentions) =>
        dispatch(notificationActions.fetchNavNotifications(showMentions)),
    handleClickOpenModal: () =>
        dispatch(notificationsSettingsActions.setIsOpenModal(true)),
    markAllNotificationsAsRead: () =>
        dispatch(notificationActions.markAllNotificationsAsRead()),
});

export default connect(null, mapDispatchToProps)(NotificationsDrawer);
