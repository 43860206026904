import React, { useEffect, useState } from 'react';

import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Button,
    Card,
    DataGrid,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import PlaceholderCardContactsDashboard from 'common/components/placeholder/PlaceholderCardContactsDashboard';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { getAgentOptions } from 'common/utils/helpers';
import { getIsOnlyContacts } from 'common/utils/helpers/session';

import CompactColumns from '../../followUps/components/CompactColumns';
import * as trackingsActions from '../../trackings/actions';
import * as trackingsSelectors from '../../trackings/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';

const Container = ({
    agents,
    contactsTrackingsData,
    fetchContactsTrackings,
    fetchUsers,
    isLoading,
    trackingsByAgent,
    users,
    visible = true,
}) => {
    const { t } = useTranslation();
    const [agentSelected, setAgentSelected] = useState('');
    const isOnlyContacts = getIsOnlyContacts();

    useEffect(() => {
        fetchContactsTrackings();
        fetchUsers();
    }, []);

    const handleSelected = (item) => {
        if (item.value === null) {
            fetchContactsTrackings();
            setAgentSelected('');
        } else {
            trackingsByAgent(item.value);
            setAgentSelected(item.value);
        }
    };

    if (!visible) return null;

    return (
        <Card id="card-tracking-contacts">
            <Card.Header id="card-header-tracking-contacts">
                <Grid alignContent="center" alignItems="center" container>
                    <Grid item xs={18} lg>
                        <TitleWithDetail variant="body2">
                            {t('Follow-up', { count: 2 })}
                        </TitleWithDetail>
                    </Grid>
                    {!isOnlyContacts && (
                        <Grid item xs={18} lg={5}>
                            <FormControl fullWidth size="small">
                                <InputLabel>{t('User')}</InputLabel>
                                <Select
                                    label={t('User')}
                                    onChange={(e) => handleSelected(e.target)}
                                    value={agentSelected}
                                >
                                    {getAgentOptions(agents).map(
                                        ({ label, value, disabled }) => (
                                            <MenuItem
                                                disabled={disabled}
                                                key={`agent-select-${value}-${label}`}
                                                value={value}
                                            >
                                                {label}
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </Card.Header>

            <PlaceholderCardContactsDashboard ready={isLoading}>
                <Card.Body
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 600,
                    }}
                >
                    <DataGrid
                        autoHeight
                        columns={CompactColumns({ users })}
                        disableColumnMenu
                        disableSelectionOnClick
                        getRowHeight={() => 'auto'}
                        pageSize={5}
                        rows={contactsTrackingsData}
                        rowsPerPageOptions={[5]}
                        sx={{
                            '& .MuiDataGrid-virtualScroller': {
                                height: '420px !important',
                            },
                            '& .MuiDataGrid-root': {
                                height: '100%',
                            },
                        }}
                    />
                    <Box my="auto" py={1}>
                        <Button
                            color="secondary"
                            component={RouterLink}
                            fullWidth
                            to="/follow-ups"
                            variant="text"
                            sx={{ textTransform: 'none' }}
                        >
                            {t('See more follow-ups')}
                        </Button>
                    </Box>
                </Card.Body>
            </PlaceholderCardContactsDashboard>
        </Card>
    );
};

Container.propTypes = {
    agents: PropTypes.array,
    contactsTrackingsData: PropTypes.array,
    fetchContactsTrackings: PropTypes.func,
    fetchUsers: PropTypes.func,
    isLoading: PropTypes.bool,
    trackingsByAgent: PropTypes.func,
    users: PropTypes.array,
    visible: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    contactsTrackingsData: selectors.getContactsTrackingData,
    isLoading: selectors.getContactsTrackingDataLoading,
    users: trackingsSelectors.getUsersData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchContactsTrackings: () => dispatch(actions.fetchContactsTrackings()),
    fetchUsers: () => dispatch(trackingsActions.fetchUsers()),
    redirect: (link) => dispatch(push(link)),
    trackingsByAgent: (id) => dispatch(actions.trackingsByAgent(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
