import React from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

import ProjectForm from '../../../../../modules/createProjectModal/components/ProjectForm';

const CustomFooter = ({ control, handleOnSubmit, handleSubmit, setStep }) => {
    const { t } = useTranslation();
    const type = useWatch({ control, name: 'type' });

    return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, pt: 2 }}>
            <Button onClick={() => setStep(1)} sx={{ ml: 'auto' }}>
                {t('Previous')}
            </Button>

            <Button onClick={handleSubmit(handleOnSubmit)}>
                {type === 'without_consumption' ? t('Create') : t('Next')}
            </Button>
        </Box>
    );
};

CustomFooter.propTypes = {
    control: PropTypes.object,
    handleOnSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    setStep: PropTypes.func,
};

const Step2 = ({ onSave, onSubmit, setStep, values }) => {
    const handleOnSubmit = (formValues) => {
        const { type } = formValues;

        if (type === 'without_consumption') {
            onSave({ ...values, project: formValues });
            return;
        }

        const callback = (values) => {
            onSubmit('project', { ...formValues, id: values.id });
            onSubmit('contact', { id: values.contact.id });
            setStep(3);
        };

        onSave({ ...values, project: formValues }, callback, false);
    };

    const props = !isEmpty(values.project)
        ? { initialValues: values.project }
        : {};

    return (
        <ProjectForm
            {...props}
            CustomFooter={({ control, handleSubmit }) => (
                <CustomFooter
                    control={control}
                    handleOnSubmit={handleOnSubmit}
                    handleSubmit={handleSubmit}
                    setStep={setStep}
                />
            )}
        />
    );
};

Step2.propTypes = {
    onSave: PropTypes.func,
    onSubmit: PropTypes.func,
    setStep: PropTypes.func,
    values: PropTypes.object,
};

export default Step2;
