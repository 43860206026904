import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Button, Snackbar } from 'sunwise-ui';

import { getIsGuest } from 'common/utils/helpers/session';

import {
    getFirebaseToken,
    getIsNotificationIsSupported,
    requestPermission,
} from '../../firebaseInit';

const ToastAskPermission = () => {
    const NotificationIsSupported = getIsNotificationIsSupported();

    const { t } = useTranslation();
    const [showGrantPermission, setShowGrantPermission] = useState(false);
    const [token, setToken] = useState(null);
    const isGuest = getIsGuest();
    const permissionInNotDenied =
        NotificationIsSupported && Notification?.permission !== 'denied';

    useEffect(() => {
        if (!isGuest && NotificationIsSupported && permissionInNotDenied)
            getFirebaseToken(setToken, setShowGrantPermission);
    }, [isGuest]);

    if (isGuest || !NotificationIsSupported || token || !permissionInNotDenied)
        return null;

    const Action = () => (
        <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
                color="inherit"
                onClick={() => setShowGrantPermission(false)}
                size="small"
            >
                {t('Dismiss')}
            </Button>
            <Button onClick={() => requestPermission(setToken)} size="small">
                {t('Allow')}
            </Button>
        </Box>
    );

    return (
        <Snackbar
            action={<Action />}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            message={t('Allow notifications')}
            open={showGrantPermission}
        />
    );
};

export default ToastAskPermission;
