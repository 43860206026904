import React from 'react';

import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const Container = styled('div')`
    align-items: center;
    display: inline-flex;
`;

const Dot = styled('div')`
    background-color: ${({ background }) => background};
    border-radius: 100%;
    height: 20px;
    margin-right: 10px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
`;

const StyledImage = styled('img')`
    margin-right: 10px;
`;

const Label = styled('span')`
    color: ${({ color }) => color};
    font-size: ${({ fontSize }) => fontSize} !important;
    font-weight: ${({ fontWeight }) => fontWeight};
    ${({ separatorRight, separatorRightWidth, separatorRightColor }) =>
        separatorRight &&
        `
        border-right: ${separatorRightWidth} solid ${separatorRightColor};
        padding-right: 15px;
        `}

    ${({ separatorLeft, separatorLeftWidth, separatorLeftColor }) =>
        separatorLeft &&
        `
        border-left: ${separatorLeftWidth} solid ${separatorLeftColor};
        padding-left: 15px;
        `}
    ${({ variant }) =>
        variant === 'bold' &&
        `
        font-size: 14px;
        font-weight: 600;`}
`;

const getCircleColor = (color = '#848BAB') => {
    switch (color) {
        case 'bittersweet':
            return '#FA6968';
        case 'blue':
            return '#1695FF';
        case 'dark':
            return '#000000';
        case 'green':
            return '#09E1A1';
        case 'gray_1':
            return '#B6BBC1';
        case 'gray_2':
            return '#84878B';
        case 'gray_3':
            return '#545759';
        case 'orange':
            return '#ff9a00';
        case 'purple':
            return '#DE09E1';
        case 'transparent':
            return 'transparent';
        default:
            return color;
    }
};

const TextVariant = ({
    as,
    className,
    dot,
    fontSize = '13px',
    fontWeight = '500',
    icon,
    iconWidth = '19',
    separatorLeft,
    separatorLeftColor = '#eff1fb',
    separatorLeftWidth = '1px',
    separatorRight,
    separatorRightColor = '#eff1fb',
    separatorRightWidth = '1px',
    text,
    variant,
}) => {
    return (
        <Container className={className}>
            {isNil(dot) ? null : <Dot background={getCircleColor(dot)} />}
            {isNil(icon) ? null : (
                <StyledImage src={icon} alt="" width={iconWidth} />
            )}
            <Typography>
                <Label
                    as={as}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    separatorLeft={separatorLeft}
                    separatorLeftColor={separatorLeftColor}
                    separatorLeftWidth={separatorLeftWidth}
                    separatorRight={separatorRight}
                    separatorRightColor={separatorRightColor}
                    separatorRightWidth={separatorRightWidth}
                    title={text}
                    variant={variant}
                >
                    {text}
                </Label>
            </Typography>
        </Container>
    );
};

TextVariant.propTypes = {
    as: PropTypes.elementType,
    className: PropTypes.string,
    dot: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    icon: PropTypes.string,
    iconWidth: PropTypes.string,
    separatorLeft: PropTypes.bool,
    separatorLeftColor: PropTypes.string,
    separatorLeftWidth: PropTypes.string,
    separatorRight: PropTypes.bool,
    separatorRightColor: PropTypes.string,
    separatorRightWidth: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    variant: PropTypes.string,
};

export default TextVariant;
