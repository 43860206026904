import { getProposalsTotals } from 'common/api/v1/dashboards';

import {
    FETCH_PROPOSALS_TOTALS,
    FETCH_PROPOSALS_TOTALS_FAILURE,
    FETCH_PROPOSALS_TOTALS_SUCCESS,
} from '../actionTypes';
import { dashboardActions } from '../reducer';

import initializeForm from './initializeForm';

export default (values) => (dispatch) => {
    const initialDate = values.initial_date;
    const finalDate = values.final_date;
    const agent = values.agent;

    dispatch(dashboardActions[FETCH_PROPOSALS_TOTALS]());

    dispatch(initializeForm(values));

    getProposalsTotals(initialDate, finalDate, agent)
        .then((response) =>
            dispatch(
                dashboardActions[FETCH_PROPOSALS_TOTALS_SUCCESS](
                    response.data.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                dashboardActions[FETCH_PROPOSALS_TOTALS_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
