import React, { useEffect, useRef, useState } from 'react';

import BarChartIcon from '@mui/icons-material/BarChart';
import ListIcon from '@mui/icons-material/List';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import {
    Alert,
    Box,
    Button,
    Grid,
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
} from '@sunwisesoftware/sunwise-ui';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    ReactHookFormInputDatePicker,
    ReactHookFormMultiSelect,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { formatDate, parseDate } from 'common/utils/dates';
import yupResolver from 'common/utils/yupResolver';

import * as afterSalesMeasurementSourceSelectors from '../../afterSalesMeasurementSource/selectors';
import { DEFAULT_DATE_FORMAT } from '../../afterSalesSettings/constants';
import * as actions from '../actions';
import { DATE_OPTIONS, DEBOUNCE_TIME, VIEW_OPTIONS } from '../constants';
import {
    getDefaultChartType,
    getMaxDate,
    getMeasurementSourceChartConfig,
} from '../helpers';
import * as selectors from '../selectors';
import { measurementSourceValidation } from '../validations';

import ChartJs from './ChartJs';
import SyncIndicator from './SyncIndicator';

const chartTypes = [
    { icon: <ShowChartIcon />, value: 'area' },
    { icon: <BarChartIcon />, value: 'bar' },
    { icon: <ListIcon />, value: 'table' },
];

const MeasurementSourceChart = ({
    countryCurrencyLocale,
    handleFetchProjectMeasuredEnergyByDate,
    hasFetchedFillEmptyReads,
    initialValues,
    initializeMeasurementSource,
    isFetchingMeasuredEnergy,
    isLoadingFillEmptyReads,
    loadedData,
    measuredEnergy,
    powerStations,
    prepareFillEmptyReads,
    resetMeasurementSourceData,
    selectedProjectId,
    setLoadedData,
}) => {
    const [chartConfig, setChartConfig] = useState({
        categories: [],
        options: {},
        series: [],
    });
    const [chartType, setChartType] = useState('area');
    const [maxDate, setMaxDate] = useState();
    const {
        control,
        formState: { isDirty },
        getValues,
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(measurementSourceValidation),
    });
    const { t } = useTranslation();
    const viewOption = watch('view_option');

    const resetForm = (values) => {
        setLoadedData(true);
        if (!values) return;
        reset(values);
    };

    const handleChartChange = (config) => {
        const newConfig = getMeasurementSourceChartConfig({
            config,
            countryCurrencyLocale,
            data: measuredEnergy,
            date: config?.date || initialValues?.date,
        });
        setChartConfig(newConfig);
    };

    const handleFetchGeneration = useRef(
        debounce((...params) => {
            setLoadedData(false);
            handleFetchProjectMeasuredEnergyByDate(...params);
        }, DEBOUNCE_TIME),
    ).current;

    useEffect(() => {
        setMaxDate(getMaxDate());

        return () => resetMeasurementSourceData();
    }, []);

    useEffect(() => {
        const power_stations = powerStations.map((station) => station.id);

        const samePowerStations =
            initialValues?.power_stations?.length === powerStations.length &&
            initialValues?.power_stations?.every((station) =>
                power_stations.includes(station),
            );
        if (samePowerStations) return;

        initializeMeasurementSource({ ...initialValues, power_stations });
    }, [powerStations?.length]);

    useEffect(() => {
        handleFetchGeneration(selectedProjectId, initialValues, resetForm);
    }, [initialValues]);

    useEffect(() => {
        const newChartType = getDefaultChartType(initialValues?.view_option);
        setChartType(newChartType);
        handleChartChange({
            date: initialValues?.date,
            chartType: newChartType,
            viewOption: initialValues?.view_option,
        });
    }, [measuredEnergy]);

    const onChangeViewOption = (e) => {
        const oldFormat =
            DATE_OPTIONS[viewOption]?.format || DEFAULT_DATE_FORMAT;
        const newFormat =
            DATE_OPTIONS[e?.target?.value]?.format || DEFAULT_DATE_FORMAT;

        if (oldFormat === newFormat) return;

        const date = getValues('date');
        const parsedDate = date ? parseDate(date, oldFormat) : null;
        const newDate = formatDate(parsedDate || maxDate, newFormat);
        setValue('date', newDate);
    };

    const handleOnChangeChartType = (value) => {
        setChartType(value);
        handleChartChange({
            chartType: value,
            viewOption: initialValues?.view_option,
        });
    };

    const optionsForSelect = powerStations?.map((station) => ({
        label: station.name || station?.read_requirements?.station_id,
        value: station.id,
    }));

    const isSyncing = true;

    return (
        <Grid container alignItems="flex-end" px={2}>
            {powerStations?.length > 0 && (
                <>
                    <Grid item xs={18} sm={9} md={4}>
                        <ReactHookFormSelect
                            control={control}
                            disabled={isFetchingMeasuredEnergy}
                            label={t('Time range')}
                            name="view_option"
                            onChange={onChangeViewOption}
                            options={[
                                {
                                    label: t('Annual'),
                                    value: VIEW_OPTIONS.ANNUAL,
                                },
                                {
                                    label: t('Monthly'),
                                    value: VIEW_OPTIONS.MONTHLY,
                                },
                                {
                                    label: `${t('Daily')} (${t('Hour')})`,
                                    value: VIEW_OPTIONS.DAILY_HOUR,
                                },
                                {
                                    label: `${t('Daily')} (5 ${t('Minute', {
                                        count: 2,
                                    })})`,
                                    value: VIEW_OPTIONS.DAILY_5_MINUTES,
                                },
                            ]}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item xs={18} sm={9} md={4}>
                        <ReactHookFormInputDatePicker
                            control={control}
                            disabled={isFetchingMeasuredEnergy}
                            disableFuture
                            format={DATE_OPTIONS[viewOption]?.format}
                            inputFormat={DATE_OPTIONS[viewOption]?.inputFormat}
                            label={t('Date')}
                            maxDate={maxDate}
                            name="date"
                            variant="standard"
                            views={DATE_OPTIONS[viewOption]?.views}
                        />
                    </Grid>

                    <Grid item xs={13} sm={8} md={4}>
                        <ReactHookFormMultiSelect
                            control={control}
                            disabled={isFetchingMeasuredEnergy}
                            disableFuture
                            label={t('Power station', { count: 2 })}
                            name="power_stations"
                            options={optionsForSelect || []}
                            variant="standard"
                        />
                    </Grid>

                    <Grid
                        alignItems="center"
                        display="flex"
                        gap={1}
                        item
                        sm={3}
                        xs={5}
                    >
                        <IconButton
                            disabled={isFetchingMeasuredEnergy || !isDirty}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'text.primary',
                                '&:hover': { backgroundColor: 'primary.dark' },
                            }}
                            onClick={handleSubmit(initializeMeasurementSource)}
                        >
                            <RefreshIcon />
                        </IconButton>

                        <SyncIndicator isSyncing={isSyncing} />
                    </Grid>

                    <Grid item xs={18} sm={7} md={3} textAlign="right">
                        <ToggleButtonGroup
                            exclusive
                            onChange={(_, value) =>
                                value !== null && handleOnChangeChartType(value)
                            }
                            size="small"
                            value={chartType}
                        >
                            {chartTypes.map((type) => (
                                <ToggleButton
                                    key={type.value}
                                    value={type.value}
                                >
                                    {type.icon}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Grid>
                </>
            )}

            <Grid item xs={18}>
                {chartConfig?.hasTimestampGaps && (
                    <Alert
                        severity="warning"
                        sx={{ '.MuiAlert-message': { width: '100%' } }}
                    >
                        {t(
                            'There are gaps in the monitoring data. This can be due to missing data or data that is not available for the selected time range',
                        )}

                        {!hasFetchedFillEmptyReads && (
                            <Box width="100%">
                                <Button
                                    disabled={isLoadingFillEmptyReads}
                                    onClick={() =>
                                        prepareFillEmptyReads(
                                            selectedProjectId,
                                            initialValues,
                                        )
                                    }
                                    sx={{ mt: 1 }}
                                >
                                    {t('Update')}
                                </Button>
                            </Box>
                        )}
                    </Alert>
                )}

                {chartConfig?.hasEstimatedData && chartType !== 'table' && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                        {t(
                            'There is estimated data, resulting from converting daily totals into 5-minute intervals. You can view this data by hovering over the chart',
                        )}
                    </Alert>
                )}

                <ChartJs
                    chartConfig={chartConfig}
                    chartType={chartType}
                    countryCurrencyLocale={countryCurrencyLocale}
                    emptyDescription={t(
                        'Make sure you have a measurement source assigned to the project',
                    )}
                    formConfig={initialValues}
                    isLoading={isFetchingMeasuredEnergy || !loadedData}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    hasFetchedFillEmptyReads: selectors.getHasFetchedFillEmptyReads,
    initialValues: selectors.getInitialValuesMeasurementSource,
    isFetchingMeasuredEnergy: selectors.getIsFetchingMeasuredEnergy,
    isFetchingPowerStations:
        afterSalesMeasurementSourceSelectors.getIsFetchingPowerStations,
    isLoadingFillEmptyReads: selectors.getIsLoadingFillEmptyReads,
    measuredEnergy: selectors.getMeasuredEnergyData,
    powerStations: afterSalesMeasurementSourceSelectors.getPowerStationsList,
});

const mapDispatchToProps = (dispatch) => ({
    handleFetchProjectMeasuredEnergyByDate: (projectId, date, callback) =>
        dispatch(
            actions.handleFetchProjectMeasuredEnergyByDate(
                projectId,
                date,
                callback,
            ),
        ),
    initializeMeasurementSource: (values) =>
        dispatch(actions.initializeMeasurementSource(values)),
    prepareFillEmptyReads: (sunwiseProjectId, data) =>
        dispatch(actions.prepareFillEmptyReads(sunwiseProjectId, data)),
    resetMeasurementSourceData: () =>
        dispatch(actions.resetMeasurementSourceData()),
});

MeasurementSourceChart.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    handleFetchProjectMeasuredEnergyByDate: PropTypes.func,
    hasFetchedFillEmptyReads: PropTypes.bool,
    initialValues: PropTypes.object,
    initializeMeasurementSource: PropTypes.func,
    isFetchingMeasuredEnergy: PropTypes.bool,
    isLoadingFillEmptyReads: PropTypes.bool,
    loadedData: PropTypes.bool,
    measuredEnergy: PropTypes.object,
    powerStations: PropTypes.array,
    prepareFillEmptyReads: PropTypes.func,
    resetMeasurementSourceData: PropTypes.func,
    selectedProjectId: PropTypes.string,
    setLoadedData: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MeasurementSourceChart);
