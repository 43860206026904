import { formatDate, parseDate } from 'common/utils/dates';

export const buildFilterQuery = (filterItemSelected) => {
    const { aftersales, final_date, initial_date, project_name, type, value } =
        filterItemSelected;
    let query = {
        creation_range: `${formatDate(
            parseDate(initial_date, 'dd/MM/yyyy'),
            'yyyy-MM-dd',
        )},${formatDate(parseDate(final_date, 'dd/MM/yyyy'), 'yyyy-MM-dd')}`,
    };
    if (project_name) query.project_name = project_name;
    if (aftersales !== undefined && aftersales !== '')
        query.aftersales = aftersales;
    if (type && value) query[type] = value;
    return query;
};
