import { getAccessKeys } from 'common/api/afterSales/accessConfig';

import {
    FETCH_CREDENTIALS,
    FETCH_CREDENTIALS_FAILURE,
    FETCH_CREDENTIALS_SUCCESS,
} from '../actionTypes';
import { credentialIntegrationsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(credentialIntegrationsActions[FETCH_CREDENTIALS]());

    getAccessKeys()
        .then((response) => {
            dispatch(
                credentialIntegrationsActions[FETCH_CREDENTIALS_SUCCESS](
                    response?.data,
                ),
            );
        })
        .catch((error) => {
            dispatch(
                credentialIntegrationsActions[FETCH_CREDENTIALS_FAILURE](
                    error?.response?.data?.detail,
                ),
            );
        });
};
