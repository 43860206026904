import React, { useEffect, useMemo, useState } from 'react';

import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';
import {
    Box,
    Button,
    Tab,
    TabPanel,
    Tabs,
    TextField,
    UiDialog as Dialog,
} from 'sunwise-ui';

import IntlNumberInputField from 'common/components/IntlNumberInputField';

import * as selectors from '../../selectors';

import CustomTemplatesTab from './CustomTemplatesTab';
import DefaultTemplatesTab from './DefaultTemplatesTab';

function formatArray(arr) {
    let result = [];
    let rangeStart = arr[0];
    for (let i = 1; i <= arr.length; i++) {
        if (arr[i] !== arr[i - 1] + 1) {
            if (rangeStart === arr[i - 1]) result.push(rangeStart.toString());
            else result.push(`${rangeStart}-${arr[i - 1]}`);
            rangeStart = arr[i];
        }
    }
    return result.join(',');
}

const NewPageModal = ({
    handleClickClose,
    isOpen,
    onAddPage,
    onClonePage,
    onEnter,
    proposalsNumber,
    templatePreviewData,
    templatePreviewPages,
    templateType,
}) => {
    const { t } = useTranslation();
    const [currentKey, setCurrentKey] = useState('default-pages-templates-tab');
    const [categorySelected, selectCategory] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedPages, setSelectedPages] = useState([]);
    const [toPage, setToPage] = useState(1);
    const isDefaultTab = currentKey === 'custom-pages-templates-tab';

    const isForSummaryTemplate = useMemo(
        () => templateType === types.MULTIPROPOSAL_TYPE,
        [templateType],
    );

    useEffect(() => {
        if (isDefaultTab && !isNull(categorySelected)) selectCategory(null);
        if (!isNull(selectedTemplate)) setSelectedTemplate(null);
    }, [currentKey]);

    const handleToPageValue = (value) =>
        setToPage(value === '' ? '' : parseInt(value));

    const handleSelectTemplate = (template) => setSelectedTemplate(template);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedTemplate !== null && selectedTemplate.id === 'blank-page') {
            onAddPage(toPage);
        } else {
            onClonePage(selectedTemplate.id, selectedPages.join());
        }
        handleClickClose();
        setSelectedTemplate(null);
    };

    const handleChange = (_, newValue) => setCurrentKey(newValue);

    const pageSelectorValue =
        selectedPages.length === 0 ? '' : formatArray(selectedPages);

    return (
        <Dialog
            onClose={() => {
                handleClickClose();
                setSelectedTemplate(null);
            }}
            onEnter={onEnter}
            onExited={() => {
                setCurrentKey('default-pages-templates-tab');
                selectCategory(null);
                setSelectedTemplate(null);
                setToPage(1);
            }}
            open={isOpen}
            title={t('New page')}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentKey} onChange={handleChange}>
                    <Tab
                        id="tab-default-pages-templates-tab"
                        key="tab-default-pages-templates-tab"
                        label={t('Default', { context: 'female', count: 2 })}
                        value="default-pages-templates-tab"
                    />

                    <Tab
                        id="tab-custom-pages-templates-tab"
                        key="tab-custom-pages-templates-tab"
                        label={t('My template', { count: 2 })}
                        value="custom-pages-templates-tab"
                    />
                </Tabs>
            </Box>

            <TabPanel
                key="tab-default-pages-templates-tab"
                selectedTab={currentKey}
                value="default-pages-templates-tab"
            >
                <DefaultTemplatesTab
                    categorySelected={categorySelected}
                    handleSelectTemplate={handleSelectTemplate}
                    isForSummaryTemplate={isForSummaryTemplate}
                    proposalsNumber={proposalsNumber}
                    selectCategory={selectCategory}
                    selectedPages={selectedPages}
                    selectedTemplate={selectedTemplate}
                    setSelectedPages={setSelectedPages}
                    setSelectedTemplate={setSelectedTemplate}
                    templateBaseType={templateType}
                />
            </TabPanel>

            <TabPanel
                key="tab-custom-pages-templates-tab"
                selectedTab={currentKey}
                value="custom-pages-templates-tab"
            >
                <CustomTemplatesTab
                    handleSelectTemplate={handleSelectTemplate}
                    proposalsNumber={proposalsNumber}
                    selectedPages={selectedPages}
                    selectedTemplate={selectedTemplate}
                    setSelectedPages={setSelectedPages}
                    setSelectedTemplate={setSelectedTemplate}
                    templateBaseType={templateType}
                />
            </TabPanel>

            <Box display="flex" alignItems="center">
                {selectedTemplate && (
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        sx={{ '.MuiFormControl-root': { mb: 0 } }}
                    >
                        {selectedTemplate.id === 'blank-page' ? (
                            <IntlNumberInputField
                                label="Cantidad de hojas"
                                onBlur={(event) => {
                                    if (
                                        event.target.value === '' ||
                                        parseInt(event.target.value) < 1
                                    )
                                        handleToPageValue(1);
                                }}
                                onChange={handleToPageValue}
                                value={toPage}
                            />
                        ) : templatePreviewData && templatePreviewPages ? (
                            <TextField
                                disabled={true}
                                InputProps={{ readOnly: true }}
                                label={t('Page', { count: 2 })}
                                placeholder="ej: 1-5, 8, 11-13"
                                sx={{ textAlign: 'center' }}
                                value={pageSelectorValue}
                            />
                        ) : null}
                    </Box>
                )}

                <Box sx={{ ml: 'auto' }}>
                    <Button
                        disabled={selectedTemplate === null}
                        onClick={handleSubmit}
                        type="button"
                    >
                        {t('Add')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    templatePreviewData: selectors.getTemplatePreviewData,
    templatePreviewPages: selectors.getTemplatePreviewPages,
});

NewPageModal.propTypes = {
    handleClickClose: PropTypes.func,
    isOpen: PropTypes.bool,
    onAddPage: PropTypes.func,
    onClonePage: PropTypes.func,
    onEnter: PropTypes.func,
    proposalsNumber: PropTypes.number,
    templateType: PropTypes.number,
    templatePreviewData: PropTypes.object,
    templatePreviewPages: PropTypes.array,
};

export default connect(mapStateToProps, null)(NewPageModal);
