import { getFinarcierInstallerData } from 'common/api/v1/sunpay';

import {
    FETCH_FINANCIER_INSTALLER_DOCUMENTS,
    FETCH_FINANCIER_INSTALLER_DOCUMENTS_FAILURE,
    FETCH_FINANCIER_INSTALLER_DOCUMENTS_SUCCESS,
} from '../actionTypes';
import { alliancesActions } from '../reducer';

export default (uuid) => (dispatch) => {
    dispatch(alliancesActions[FETCH_FINANCIER_INSTALLER_DOCUMENTS]());

    getFinarcierInstallerData(uuid)
        .then((response) => {
            dispatch(
                alliancesActions[FETCH_FINANCIER_INSTALLER_DOCUMENTS_SUCCESS](
                    response.data.data,
                ),
            );
        })
        .catch((error) => {
            dispatch(
                alliancesActions[FETCH_FINANCIER_INSTALLER_DOCUMENTS_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
        });
};
