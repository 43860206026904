import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import CompletedPlaceholder from 'resources/onboarding/completed.svg';

const FinishStep = () => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                py: 1,
                width: '100%',
            }}
        >
            <Typography variant="h6">{t('You made it!')}</Typography>
            <Typography variant="body2">
                {t(
                    "Now you will be able to have a more personalized experience according to your company's needs. Remember that you will be able to configure this data later",
                )}
                .
            </Typography>
            <img src={CompletedPlaceholder} />
        </Box>
    );
};

FinishStep.propTypes = {};

export default FinishStep;
