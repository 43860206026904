import initializeForm from './initializeForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModalProduct from './toggleModalProduct';

export default (branchSelected) => (dispatch) => {
    const newValues = {
        branch_office: !['all', 'company'].includes(branchSelected)
            ? branchSelected
            : '',
    };

    dispatch(setIsNewRecord(true));
    dispatch(initializeForm(newValues));
    dispatch(toggleModalProduct(true));
};
