import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

// CREATE

export const getCreate = createSelector(getModel, (model) => model.create);

export const getIsCreating = createSelector(
    getCreate,
    (value) => value.isCreating,
);

export const getCreateData = createSelector(
    getCreate,
    (value) => value.data || {},
);

export const getCreateErrors = createSelector(
    getCreate,
    (value) => value.errors,
);

// DELETE

export const getDelete = createSelector(getModel, (model) => model.delete);

export const getIsDeleting = createSelector(
    getDelete,
    (value) => value.isDeleting,
);

export const getDeleteData = createSelector(
    getDelete,
    (value) => value.data || {},
);

export const getDeleteErrors = createSelector(
    getDelete,
    (value) => value.errors,
);

// FETCH OFFER WORKFORCE

export const getOfferWorkforce = createSelector(
    getModel,
    (model) => model.offerWorkforce,
);

export const getIsFetchingOfferWorkforce = createSelector(
    getOfferWorkforce,
    (value) => value.isFetching,
);

export const getOfferWorkforceData = createSelector(
    getOfferWorkforce,
    (value) => value.data || [],
);

export const getOfferWorkforceErrors = createSelector(
    getOfferWorkforce,
    (value) => value.errors,
);

// SAVE

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (value) => value.isSaving);

export const getSaveData = createSelector(getSave, (value) => value.data || {});

export const getSaveErrors = createSelector(getSave, (value) => value.errors);

export const getIsUpdating = createSelector(
    getIsCreating,
    getIsDeleting,
    getIsSaving,
    (isCreating, isDeleting, isSaving) => isCreating || isDeleting || isSaving,
);
