import React from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import { Box, IconButton } from 'sunwise-ui';

import ProductBranchOfficesFormatter from 'common/modules/multiBranches/components/ProductBranchOfficesFormatter';

const AddProductActionsFormatter = ({
    branchOfficesDictionary,
    branchSelected,
    canModify,
    handleAddProductToBranches,
    productSelected,
    row,
    selectProduct,
    setProductBranchOfficesModalPosition,
}) => (
    <Box
        sx={{
            alignItems: 'center',
            display: 'flex',
            gap: '4px',
            justifyContent: 'end',
            textAlign: 'right',
            width: '100%',
        }}
    >
        <ProductBranchOfficesFormatter
            branchOfficesDictionary={branchOfficesDictionary}
            branchSelected={branchSelected}
            canModify={canModify}
            openModal={(evt) => {
                setProductBranchOfficesModalPosition({
                    x: evt.clientX > 277 ? evt.clientX - 277 : 0,
                    y: evt.clientY,
                });
                selectProduct(row);
            }}
            product={row}
            productIsSelected={
                productSelected ? productSelected.id === row.id : false
            }
            submitChanges={(branches) =>
                handleAddProductToBranches(row.id, branches)
            }
        />

        <IconButton
            disabled={!row.data_sheet}
            onClick={() => window.open(row.data_sheet, '_blank')}
        >
            <VisibilityIcon fontSize="small" />
        </IconButton>
    </Box>
);

AddProductActionsFormatter.propTypes = {
    branchOfficesDictionary: PropTypes.object,
    branchSelected: PropTypes.string,
    canModify: PropTypes.bool,
    handleAddProductToBranches: PropTypes.func,
    productSelected: PropTypes.object,
    row: PropTypes.object,
    selectProduct: PropTypes.func,
    setProductBranchOfficesModalPosition: PropTypes.func,
};

export default AddProductActionsFormatter;
