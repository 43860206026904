import { styled } from '@mui/material/styles';
import { Accordion } from 'sunwise-ui';

export default styled(Accordion)`
    border-bottom: 1px solid rgba(145, 158, 171, 0.24);
    border-radius: 0 !important;
    &.Mui-expanded {
        border-bottom: 0;
    }
`;
