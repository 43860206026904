import { getSolarSimulationData } from 'common/utils/helpersChart';

import { SET_SOLAR_SIMULATION_DATA } from '../actionTypes';
import { proposalGeneratorOneColumnActions } from '../reducer';

export default (data) => (dispatch) => {
    const solarSimulationData = getSolarSimulationData(data);
    dispatch(
        proposalGeneratorOneColumnActions[SET_SOLAR_SIMULATION_DATA](
            solarSimulationData,
        ),
    );
};
