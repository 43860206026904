import i18next from 'i18next';

import { readXML } from 'common/api/v2/readXML';
import { URL_TYPES } from 'common/constants';
import alerts from 'common/modules/alerts';

import {
    UPLOAD_FILE,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS,
} from '../actionTypes';
import { CONSUMPTIONS_CAPTURE_MODE } from '../constants';
import { projectConsumptionModalActions } from '../reducer';
import * as selectors from '../selectors';

import handleConsumptionIntegration from './handleConsumptionIntegration';
export default ({ file, getValues, index, setValue }) =>
    (dispatch, getState) => {
        const project = selectors.getSelectedProject(getState());

        if (!file || !project?.id) return;

        dispatch(
            alerts.actions.show({
                confirmText: i18next.t('Back'),
                hideButtons: true,
                messages: [
                    i18next.t(
                        'Please wait a moment as the information is processed',
                    ),
                ],
                title: i18next.t('Loading').concat('...'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'info',
            }),
        );

        dispatch(projectConsumptionModalActions[UPLOAD_FILE]());

        readXML(project.id, { file })
            .then(({ data }) => {
                const rawData = data?.data?.map((item) => ({
                    completion: item,
                    file: item?.url,
                    status: 0, // STATUS_SUCCESS,
                    url_type: URL_TYPES.SUNWISE,
                }));

                dispatch(
                    projectConsumptionModalActions[UPLOAD_FILE_SUCCESS](
                        data || {},
                    ),
                );

                dispatch(alerts.actions.close());

                dispatch(
                    handleConsumptionIntegration({
                        consumptionsCaptureMode: CONSUMPTIONS_CAPTURE_MODE.XML,
                        fileInfo: rawData[0],
                        fileOrigin: index,
                        getValues,
                        rawData,
                        setValue,
                    }),
                );
            })
            .catch((error) => {
                dispatch(alerts.actions.close());
                dispatch(
                    projectConsumptionModalActions[UPLOAD_FILE_FAILURE](
                        error?.response?.data?.detail,
                    ),
                );
            });
    };
