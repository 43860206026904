import { ONE_TO_MANY } from '../constants';

import BaseModel from './BaseModel';
export default class TimelineEvent extends BaseModel {}

TimelineEvent.modelName = 'TimelineEvent';
TimelineEvent.prepareFields({
    id: 'id',
    description: 'description',
    duration: 'duration',
    name: 'name',
    order: 'order',
    status: 'status',
    timeline: {
        key: 'timeline',
        relationType: ONE_TO_MANY,
        options: {
            to: 'Timeline',
            as: 'timeline',
            relatedName: 'timelineEvents',
        },
    },
});
