import React from 'react';

import SendIcon from '@mui/icons-material/Send';
import ShareIcon from '@mui/icons-material/Share';
import { styled } from '@mui/material/styles';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RWebShare } from 'react-web-share';
import {
    Box,
    Card,
    Grid,
    IconButton,
    Switch,
    TextField,
    Typography,
} from 'sunwise-ui';

import { SHARED_OPTIONS } from 'common/constants';

import * as actions from '../actions';
import { getShortedLink } from '../helpers';

import DownloadFormatDrowpdown from './DownloadFormatDrowpdown';

const TextFieldLink = styled(TextField)(
    ({ theme }) => `
    background: ${theme.palette.mode === 'dark' ? '#282b30' : '#eef4fa'};
    border-radius: 8px;
    & .Mui-disabled {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
    }
    & .MuiOutlinedInput-notchedOutline {
        border: 0 !important;
    }
`,
);

const ShareCard = ({
    canModify,
    downloadFormat,
    handleClickDownload,
    isDisabled,
    isEnablePermalink,
    permalink,
    reportName,
    selectedDocument,
    setDownloadFormat,
}) => {
    const { t } = useTranslation();

    const handleSelectDownloadFormat = (e) => {
        const format = e.target.value;
        localStorage.setItem('reportDownloadFormat', format);
        setDownloadFormat(format);
        e.preventDefault();
    };

    return (
        <Card sx={{ mb: '16px !important' }}>
            <Card.Header
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: '8px 16px !important',
                }}
            >
                <SendIcon sx={{ color: 'primary.main' }} />
                <Typography variant="body2" ml={1} fontWeight="bold">
                    {t('Share')}
                </Typography>
            </Card.Header>
            <Card.Body sx={{ pt: 1 }}>
                {!isNull(permalink) && (
                    <>
                        <Grid container spacing={0}>
                            <Grid
                                item
                                xs={18}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <Typography
                                    className="__userguiding_share_interactive_link"
                                    variant="body2"
                                >
                                    {t('Interactive link')}
                                </Typography>
                                <Box sx={{ ml: 'auto' }}>
                                    <Switch
                                        checked={isEnablePermalink}
                                        disabled={!canModify || isDisabled}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Box alignItems="center" display="flex" gap={1} mt={1}>
                            <TextFieldLink
                                disabled
                                fontWeight="bold"
                                fullWidth
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                value={getShortedLink(permalink)}
                            />
                            <RWebShare
                                closeText={t('Close')}
                                data={{
                                    text: reportName,
                                    url: permalink,
                                    title: reportName,
                                }}
                                disableNative
                                sites={SHARED_OPTIONS}
                            >
                                <IconButton
                                    disabled={!isEnablePermalink}
                                    sx={{ ml: 'auto' }}
                                >
                                    <ShareIcon />
                                </IconButton>
                            </RWebShare>
                        </Box>
                    </>
                )}
                <Grid
                    container
                    spacing={0}
                    sx={{ alignItems: 'center', mt: 1 }}
                >
                    <Grid item xs>
                        <Typography variant="body2">
                            {t('Download format', { context: 'noun' })}
                        </Typography>

                        <DownloadFormatDrowpdown
                            downloadFormat={downloadFormat}
                            handleClickDownloadFormat={
                                handleSelectDownloadFormat
                            }
                            handleClickDownload={handleClickDownload}
                            isDisabled={isDisabled}
                            selectedDocument={selectedDocument}
                        />
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setEnablePermalink: (reportId, value) =>
        dispatch(actions.setEnablePermalink(reportId, value)),
});

ShareCard.propTypes = {
    canModify: PropTypes.bool,
    downloadFormat: PropTypes.number,
    handleClickDownload: PropTypes.func,
    isDisabled: PropTypes.bool,
    isEnablePermalink: PropTypes.bool,
    permalink: PropTypes.string,
    reportName: PropTypes.string,
    selectedDocument: PropTypes.object,
    setDownloadFormat: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ShareCard);
