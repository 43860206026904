import { NAME } from './constants';

export const FETCH = `${NAME}/FETCH`;
export const FETCH_FAILURE = `${NAME}/FETCH_FAILURE`;
export const FETCH_SUCCESS = `${NAME}/FETCH_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SET_BRANDING = `${NAME}/SET_BRANDING`;
export const SET_CURRENT_VALUES = `${NAME}/SET_CURRENT_VALUES`;
export const SET_PREFERENCES = `${NAME}/SET_PREFERENCES`;
export const SET_THEME = `${NAME}/SET_THEME`;
