import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
} from 'sunwise-ui';

import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

const formattedValue = (value) => {
    return typeof value === 'string' ? value.split(',') : value;
};

const getOptionsIds = (branchOfficesDictionary, hiddeGlobal) => {
    let optionsIds = Object.keys(branchOfficesDictionary).filter(
        (branchKey) => branchKey !== 'all',
    );
    if (hiddeGlobal)
        optionsIds = optionsIds.filter((branchKey) => branchKey !== 'company');
    return optionsIds.map((branchKey) => branchKey);
};

const ProductBranchOfficesInputRHF = ({
    branchOfficesDictionary,
    control,
    disabled,
    hiddeGlobal = false,
    id,
    label,
    name,
    onChange,
    required,
    value = '',
}) => {
    const { t } = useTranslation();
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const isMultiBranches = getIsMultibranchesAccount();
    const optionsIds = getOptionsIds(branchOfficesDictionary, hiddeGlobal);

    useEffect(() => {
        if (isEmpty(selectedBranch) && value) {
            setSelectedBranch(formattedValue(value));
        }
    }, [value]);

    useEffect(() => {
        setIsAllSelected(optionsIds.length === selectedBranch.length);
    }, [selectedBranch]);

    const handleChange = (event, onChangeField) => {
        const {
            target: { value },
        } = event;

        let newSelectedBranch;

        if (value.includes('all')) {
            if (isAllSelected) newSelectedBranch = [];
            else newSelectedBranch = optionsIds;
        } else {
            newSelectedBranch = formattedValue(value);
        }

        setSelectedBranch(newSelectedBranch);
        const isAllSelectedNow = optionsIds.length === newSelectedBranch.length;
        setIsAllSelected(isAllSelectedNow);

        onChangeField(newSelectedBranch.join(), isAllSelectedNow);
        if (onChange) onChange(newSelectedBranch.join(), isAllSelectedNow);
    };

    const isIncomplete = selectedBranch.length > 0 && !isAllSelected;

    if (!isMultiBranches) return null;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange }, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} size="small">
                    <InputLabel>{label}</InputLabel>
                    <Select
                        disabled={disabled}
                        id={id}
                        input={<OutlinedInput label={label} />}
                        multiple
                        onChange={(e) => handleChange(e, onChange)}
                        renderValue={(selected) => {
                            return selected
                                .map((item) => branchOfficesDictionary[item])
                                .join(', ');
                        }}
                        required={required}
                        value={selectedBranch}
                    >
                        <MenuItem key={`product-branchs-all`} value="all">
                            <Checkbox
                                checked={isAllSelected}
                                indeterminate={isIncomplete}
                            />
                            {t('All text', { context: 'female', count: 2 })}
                        </MenuItem>
                        {optionsIds.map((branchKey) => (
                            <MenuItem
                                disabled={
                                    branchKey === 'company' && hiddeGlobal
                                }
                                key={`product-branchs-${branchKey}`}
                                value={branchKey}
                            >
                                <Checkbox
                                    checked={
                                        selectedBranch.indexOf(branchKey) > -1
                                    }
                                />
                                {branchOfficesDictionary[branchKey]}
                            </MenuItem>
                        ))}
                    </Select>

                    {Boolean(error) && (
                        <FormHelperText>{error?.message}</FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};

ProductBranchOfficesInputRHF.propTypes = {
    branchOfficesDictionary: PropTypes.object,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    hiddeGlobal: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.string,
};

export default ProductBranchOfficesInputRHF;
