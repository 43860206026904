import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from 'sunwise-ui';

const ContactFormatter = ({ row }) => {
    const { contact = {} } = get(row, 'project', {});
    return (
        <Link
            color="inherit"
            component={RouterLink}
            to={`/record/${contact?.id}?tab=projects`}
            underline="none"
        >
            <Typography fontWeight={600} variant="caption">
                {contact?.name} {contact?.first_lastname}{' '}
                {contact?.second_lastname}
            </Typography>
        </Link>
    );
};

ContactFormatter.propTypes = {
    row: PropTypes.object,
};

export default ContactFormatter;
