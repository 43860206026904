import { createSlice } from '@reduxjs/toolkit';

import {
    CLONE_POSITION,
    CLONE_POSITION_ERROR,
    CLONE_POSITION_SUCCESS,
    FETCH_LOCATION,
    FETCH_LOCATION_ERROR,
    FETCH_LOCATION_SUCCESS,
    SAVE_POSITION,
    SAVE_POSITION_ERROR,
    SAVE_POSITION_SUCCESS,
    SELECT_PROJECT,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchPositions: {
        isLoading: false,
        data: {},
        error: null,
    },
    savePosition: {
        isLoading: false,
        data: {},
        errors: null,
    },
    selectedProject: {},
    clonePosition: {
        isCloning: false,
        data: [],
        errors: null,
    },
};

const projectLocationSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_LOCATION]: (state) => {
            state.fetchPositions = {
                ...state.fetchPositions,
                data: {},
                error: [],
                isLoading: true,
            };
        },
        [FETCH_LOCATION_ERROR]: (state, action) => {
            state.fetchPositions = {
                ...state.fetchPositions,
                data: {},
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_LOCATION_SUCCESS]: (state, action) => {
            state.fetchPositions = {
                ...state.fetchPositions,
                data: action.payload,
                error: null,
                isLoading: false,
            };
        },
        [SAVE_POSITION]: (state) => {
            state.savePosition.isLoading = true;
        },
        [SAVE_POSITION_ERROR]: (state, action) => {
            state.savePosition = {
                ...state.savePosition,
                errors: action.payload,
                isLoading: false,
            };
        },
        [SAVE_POSITION_SUCCESS]: (state, action) => {
            state.savePosition = {
                ...state.savePosition,
                data: action.payload,
                errors: null,
                isLoading: false,
            };
        },
        [SELECT_PROJECT]: (state, action) => {
            state.selectedProject = action.payload;
        },
        [CLONE_POSITION]: (state) => {
            state.clonePosition = {
                ...state.clonePosition,
                errors: [],
                isCloning: true,
            };
        },
        [CLONE_POSITION_ERROR]: (state, action) => {
            state.clonePosition = {
                ...state.clonePosition,
                errors: action.payload,
                isCloning: false,
            };
        },
        [CLONE_POSITION_SUCCESS]: (state, action) => {
            state.clonePosition = {
                ...state.clonePosition,
                data: action.payload,
                isCloning: false,
            };
        },
    },
});

export const projectLocationActions = projectLocationSlice.actions;

export default projectLocationSlice.reducer;
