import { NAME } from './constants';

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const DELETE_ITEM = `${NAME}/DELETE_ITEM`;
export const DELETE_ITEM_SUCCESS = `${NAME}/DELETE_ITEM_SUCCESS`;
export const DELETE_ITEM_FAILURE = `${NAME}/DELETE_ITEM_FAILURE`;

export const FETCH_ADDITIONALS = `${NAME}/FETCH_ADDITIONALS`;
export const FETCH_ADDITIONALS_SUCCESS = `${NAME}/FETCH_ADDITIONALS_SUCCESS`;
export const FETCH_ADDITIONALS_FAILURE = `${NAME}/FETCH_ADDITIONALS_FAILURE`;

export const FETCH_ACCESSORIES = `${NAME}/FETCH_ACCESSORIES`;
export const FETCH_ACCESSORIES_SUCCESS = `${NAME}/FETCH_ACCESSORIES_SUCCESS`;
export const FETCH_ACCESSORIES_FAILURE = `${NAME}/FETCH_ACCESSORIES_FAILURE`;

export const FETCH_BATTERIES = `${NAME}/FETCH_BATTERIES`;
export const FETCH_BATTERIES_FAILURE = `${NAME}/FETCH_BATTERIES_FAILURE`;
export const FETCH_BATTERIES_SUCCESS = `${NAME}/FETCH_BATTERIES_SUCCESS`;

export const FETCH_TEMPLATES = `${NAME}/FETCH_TEMPLATES`;
export const FETCH_TEMPLATES_SUCCESS = `${NAME}/FETCH_TEMPLATES_SUCCESS`;
export const FETCH_TEMPLATES_FAILURE = `${NAME}/FETCH_TEMPLATES_FAILURE`;

export const FETCH_ITEMS = `${NAME}/FETCH_ITEMS`;
export const FETCH_ITEMS_SUCCESS = `${NAME}/FETCH_ITEMS_SUCCESS`;
export const FETCH_ITEMS_FAILURE = `${NAME}/FETCH_ITEMS_FAILURE`;

export const FETCH_INVERTERS = `${NAME}/FETCH_INVERTERS`;
export const FETCH_INVERTERS_SUCCESS = `${NAME}/FETCH_INVERTERS_SUCCESS`;
export const FETCH_INVERTERS_FAILURE = `${NAME}/FETCH_INVERTERS_FAILURE`;

export const FETCH_PANELS = `${NAME}/FETCH_PANELS`;
export const FETCH_PANELS_SUCCESS = `${NAME}/FETCH_PANELS_SUCCESS`;
export const FETCH_PANELS_FAILURE = `${NAME}/FETCH_PANELS_FAILURE`;

export const FETCH_PLAN = `${NAME}/FETCH_PLAN`;
export const FETCH_PLAN_SUCCESS = `${NAME}/FETCH_PLAN_SUCCESS`;
export const FETCH_PLAN_FAILURE = `${NAME}/FETCH_PLAN_FAILURE`;

export const FETCH_STRUCTURES = `${NAME}/FETCH_STRUCTURES`;
export const FETCH_STRUCTURES_SUCCESS = `${NAME}/FETCH_STRUCTURES_SUCCESS`;
export const FETCH_STRUCTURES_FAILURE = `${NAME}/FETCH_STRUCTURES_FAILURE`;

export const FETCH_WORKFORCE = `${NAME}/FETCH_WORKFORCE`;
export const FETCH_WORKFORCE_SUCCESS = `${NAME}/FETCH_WORKFORCE_SUCCESS`;
export const FETCH_WORKFORCE_FAILURE = `${NAME}/FETCH_WORKFORCE_FAILURE`;

export const INITIALIZE = `${NAME}/INITIALIZE`;
export const INITIALIZE_SUCCESS = `${NAME}/INITIALIZE_SUCCESS`;
export const INITIALIZE_FAILURE = `${NAME}/INITIALIZE_FAILURE`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const OPEN_MODAL_PRODUCT = `${NAME}/OPEN_MODAL_PRODUCT`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;

export const UPDATE_ITEMS_SUCCESS = `${NAME}/UPDATE_ITEMS_SUCCESS`;
